unit ufrChat;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Imaging.jpeg,
  Vcl.Imaging.pngimage, Vcl.Controls, WEBLib.ExtCtrls, WEBLib.WebCtrls,
  XData.Web.Client, WEBLib.WebSocketClient, uTb, uGlobal;

type

  TCBDelete = procedure(AHolder, AUser:integer) of object;

  TfrChat = class(TFrame)
    WebHTMLDiv1: THTMLDiv;
    WebHTMLDiv11: THTMLDiv;
    btnClose: TImageControl;
    WebHTMLDiv2: THTMLDiv;
    imgAvatar: TImageControl;
    divHolder: THTMLDiv;
    Client: TXDataWebClient;
    ChatClient: TSocketClient;
    divCenter: THTMLDiv;
    WebHTMLDiv71: THTMLDiv;
    edEingabe: TEdit;
    btnSend: TButton;
    procedure ChatClientMessageReceived(Sender: TObject; AMessage: string);
    [async] procedure btnSendClick(Sender: TObject); async;
    procedure btnCloseClick(Sender: TObject);
    [async] function GetMsg(AHolder, AUser:integer):THtmlDiv; async;
  private
    FNickname:string;
    FLast,
    FHolder,
    FUser:integer;
    FCBDelete:TCBDelete;
    function  WriteLine(AName, AText, Time:string):THtmlDiv;
    procedure GoToLast(AContainer, ALast:string);
  public
    property CBDelete: TCBDelete read FCBDelete write FCBDelete;
    //[async] procedure Delete(AHolder, AUser:integer);
    [async] procedure init(AHostName: string; APort, AHolder, AUser:integer; AText: string = ''); async;
  protected procedure LoadDFMValues; override; end;

  TChats = class(THTMLDiv)
  private
    FBox: array of TfrChat;
    FCount:integer;
    FContainer:THTMLDiv;
    FHostName:string;
    FPort:integer;
    function  GetCount:integer;
    function  GetBox(Index: Integer): TfrChat;
    procedure SetBox(Index: Integer; Value: TfrChat);
  public
    property HostName:string read FHostname write FHostname;
    property Port:integer read FPort write FPort;
    function Find(AHolder, AUser:integer):integer;
    property Count: integer read GetCount;
    property Boxen[index:integer]: TfrChat read GetBox write SetBox;
    procedure Add(AHolder, AUser:integer; AText:string = '');
    procedure DeleteChat(AHolder, AUser:integer);
    constructor Create(AOwner:TComponent; AHostname:string; APort:integer); overload;
  end;

var
  frChat: TfrChat;

implementation
  uses uMain, uDatenmodul;

{$R *.dfm}

procedure TfrChat.GoToLast(AContainer, ALast:string);
begin
  asm
    var oberDiv = document.getElementById(AContainer);
    var neueUnterDiv = document.getElementById(ALast);

    if (oberDiv && neueUnterDiv) {
        // Berechne die Höhe des sichtbaren Bereichs von oberDiv
        var oberDivHeight = oberDiv.clientHeight;
        var oberDivScrollTop = oberDiv.scrollTop;

        // Berechne die Position des neuen Unter-Divs relativ zum oberen Rand des oberDivs
        var neueUnterDivOffsetTop = neueUnterDiv.offsetTop;

        // Berechne die Position des neuen Unter-Divs relativ zum sichtbaren Bereich von oberDiv
        var relativeOffsetTop = neueUnterDivOffsetTop - oberDivScrollTop;

        // Überprüfe, ob das neue Unter-Div im sichtbaren Bereich von oberDiv liegt
        if (relativeOffsetTop < 0 || relativeOffsetTop > oberDivHeight) {
            // Bewege das neue Unter-Div in den sichtbaren Bereich von oberDiv
            oberDiv.scrollTop = neueUnterDivOffsetTop;
        }
    }
  end;
end;

procedure TChats.DeleteChat(AHolder, AUser:integer);
var
  i, idx:integer;
  a:array of TfrChat;

begin
  idx := Find(AHolder, AUser);

  if idx > -1
  then begin
    for i := 0 to FCount do
    begin
      if i <> idx
      then begin
        Setlength(a, length(a) +1);
        a[length(a)-1] := FBox[i];
      end;

      FBox := a;
      FCount := length(FBox);
    end;
  end;
end;

function TChats.GetCount:integer;
begin
  result := Length(FBox);
end;

function TChats.GetBox(Index: Integer): TfrChat;
begin
  result := FBox[Index];
end;

procedure TChats.SetBox(Index: Integer; Value: TfrChat);
begin
//
end;

function TChats.Find(AHolder, AUser:integer):integer;
Var
  i:Integer;
begin
  result := -1;
  for i := 0 to count - 1 do
  begin
    if ((Fbox[i].FHolder = AHolder) and (Fbox[i].FUser = AUser  )) or
       ((Fbox[i].FHolder = AUser  ) and (Fbox[i].FUser = AHolder))
    then result := i;
  end;
end;

procedure TChats.Add(AHolder, AUser:integer; AText:string = '');
Var
  chat:TfrChat;
  idx:integer;
begin
  idx := Find(AHolder, AUser);

  if (idx = -1) or (not assigned(FBox[idx]))
  then begin
    chat := TfrChat.Create(Self.owner);
    chat.LoadFromForm;
    chat.parent := FContainer;
    THTMLDiv(chat).ElementHandle.style.removeProperty('position');
    THTMLDiv(chat).ElementHandle.style.RemoveProperty('font-family');
    THTMLDiv(chat).ElementHandle.style.RemoveProperty('font-size');
    THTMLDiv(chat).ElementHandle.style.RemoveProperty('font-style');
    THTMLDiv(chat).ElementHandle.style.RemoveProperty('height');
    THTMLDiv(chat).Elementhandle.Style.removeProperty('width');
    THTMLDiv(chat).ElementHandle.style.SetProperty('margin-bottom', '10px');
    THTMLDiv(chat).ElementHandle.style.SetProperty('min-height', '400px');

    Setlength(FBox, length(FBox)+1);
    FBox[length(FBox)-1] := chat;

    chat.CBDelete := @DeleteChat;
    chat.init(FHostname, FPort, AHolder, AUser, AText);
  end;

end;

constructor TChats.Create(AOwner:TComponent; AHostname:string; APort:integer);overload;
begin
  inherited;

  FContainer:=THTMLDiv.Create(AOwner);
  FContainer.Parent := TWinControl(AOwner);
  FContainer.ElementClassName := 'chat-container';
  FContainer.Elementhandle.Style.removeProperty('left');
  FContainer.Elementhandle.Style.removeProperty('top');
  FContainer.Elementhandle.Style.removeProperty('height');
  FContainer.Elementhandle.Style.removeProperty('width');

  FHostname := AHostname;
  FPort     := APort;

end;

procedure TfrChat.init(AHostName: string; APort, AHolder, AUser:integer; AText:string='');
var
  J:TJ;
  i:integer;
  lastDiv:THtmlDiv;
  sOber, sUnter:string;

begin
  FHolder := AHolder;
  FUser   := AUser;

  Chatclient.Port     := APort;
  Chatclient.HostName := AHostName;
  Chatclient.Active   := true;

  j := TJ.create(await(datenmodul.EasySQL('select AVATAR_MEDIEN_ID, NICKNAME ' +
                                          '  from User ' +
                                          ' where ID = ' + AHolder.ToString)));

  FNickname := J.Value('NICKNAME');
  divHolder.html.text := FNickname;
  imgAvatar.URL := await(datenmodul.GetMedia(J.Integer('AVATAR_MEDIEN_ID'), _Size3));

  LastDiv := await(GetMsg(FHolder, FUser));
  if AText > ''
  then LastDiv := WriteLine(FNickName,AText,'');

  GotoLast(divCenter.ElementID, LastDiv.ElementID);


//  showmessage(lastdiv.ElementID);
//  sOber := divCenter.ElementID;
//  sUnter:= LastDiv.ElementID;
//  asm
//    var oberDiv = document.getElementById(sOber);
//    var neueUnterDiv = document.getElementById(sUnter);
//
//    if (oberDiv && neueUnterDiv) {
//        // Berechne die Höhe des sichtbaren Bereichs von oberDiv
//        var oberDivHeight = oberDiv.clientHeight;
//        var oberDivScrollTop = oberDiv.scrollTop;
//
//        // Berechne die Position des neuen Unter-Divs relativ zum oberen Rand des oberDivs
//        var neueUnterDivOffsetTop = neueUnterDiv.offsetTop;
//
//        // Berechne die Position des neuen Unter-Divs relativ zum sichtbaren Bereich von oberDiv
//        var relativeOffsetTop = neueUnterDivOffsetTop - oberDivScrollTop;
//
//        // Überprüfe, ob das neue Unter-Div im sichtbaren Bereich von oberDiv liegt
//        if (relativeOffsetTop < 0 || relativeOffsetTop > oberDivHeight) {
//            // Bewege das neue Unter-Div in den sichtbaren Bereich von oberDiv
//            oberDiv.scrollTop = neueUnterDivOffsetTop;
//        }
//    }
//  end;

end;

procedure TfrChat.btnCloseClick(Sender: TObject);
var
  fr:TfrChat;
begin
  if assigned(CBDelete)
  then CBDelete(FHolder, FUser);

  fr:=TfrChat(self);
  fr.Free;

end;

procedure TfrChat.btnSendClick(Sender: TObject);
begin
  await(datenmodul.EasyInsert('insert into msg (Holder, User_ID, Text) ' +
               ' VALUES(' + FHolder.toString + ',' + FUser.ToSTring + ',''' + chkStr(edEingabe.Text) + ''')'));
  ChatClient.Send(FHolder.tostring + '|' + FUser.ToString + '|' + edEingabe.text);
  edEingabe.Text := '';
  edEingabe.SetFocus;
end;

procedure TfrChat.ChatClientMessageReceived(Sender: TObject; AMessage: string);
Var
  sHolder, sUser, sText, s:string;
  LastDiv:THtmlDiv;
begin
  s := aMessage;
  sHolder := copy(s,1, Pos('|', s)-1);
  s := copy(s,length(sHolder)+2,length(s));
  sUser := copy(s,1, Pos('|', s)-1);
  s := copy(s,length(sUser)+2,length(s));
  sText := copy(s,1, length(s));

  if ((FHolder.ToString = sHolder) and (FUser.ToString = sUser)) or
     ((FHolder.ToString = sUser)   and (FUser.ToString = sHolder))
  then begin
    if Mainform.ME.ID.tostring = sUser
    then LastDiv := writeLine('Du', sText,'')
    else LastDiv := writeLine(FNickname, sText,'');

    GotoLast(divCenter.ElementID, LastDiv.ElementID);
  end;

end;

function TfrChat.GetMsg(AHolder, AUser:integer):THtmlDiv;
Var
  J:TJ;
  i:integer;
begin
  j := TJ.create(await(datenmodul.EasySQL('select * from msg ' +
                                          ' where ((Holder = ' + AHolder.ToString + ' AND USER_ID = ' + AUser.tostring + ') ' +
                                          '    or  (Holder = ' + AUser.ToString   + ' AND USER_ID = ' + AHolder.tostring + ')) ' +
                                          '   AND ID > ' + FLast.tostring)));

  for i := 0 to j.length-1 do
  begin
    j.Index:=i;
    if Mainform.ME.ID = j.Integer('USER_ID')
    then result := writeLine('Du', j.Text('TEXT'),'')
    else result := writeLine(FNickname, j.Text('TEXT'),'');
    FLast := J.integer('ID');
  end;

end;

function TfrChat.WriteLine(AName, AText, Time:string):THtmlDiv;
//var
//  d:TWebHTMLDiv;
begin
  result:=THTMLDiv.Create(self);
  result.Parent:= divCenter;
  result.ElementPosition := epRelative;
  result.ElementClassName := 'msg-line';
  result.HTML.Text := '<font size="3">' + AName+ ': </font>' + AText;
end;

procedure TfrChat.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  WebHTMLDiv11 := THTMLDiv.Create(Self);
  btnClose := TImageControl.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  imgAvatar := TImageControl.Create(Self);
  divHolder := THTMLDiv.Create(Self);
  divCenter := THTMLDiv.Create(Self);
  WebHTMLDiv71 := THTMLDiv.Create(Self);
  edEingabe := TEdit.Create(Self);
  btnSend := TButton.Create(Self);
  Client := TXDataWebClient.Create(Self);
  ChatClient := TSocketClient.Create(Self);

  WebHTMLDiv1.BeforeLoadDFMValues;
  WebHTMLDiv11.BeforeLoadDFMValues;
  btnClose.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  imgAvatar.BeforeLoadDFMValues;
  divHolder.BeforeLoadDFMValues;
  divCenter.BeforeLoadDFMValues;
  WebHTMLDiv71.BeforeLoadDFMValues;
  edEingabe.BeforeLoadDFMValues;
  btnSend.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  ChatClient.BeforeLoadDFMValues;
  try
    Name := 'frChat';
    Left := 0;
    Top := 0;
    Width := 403;
    Height := 456;
    TabOrder := 0;
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 16;
    WebHTMLDiv1.Top := 8;
    WebHTMLDiv1.Width := 369;
    WebHTMLDiv1.Height := 425;
    WebHTMLDiv1.ElementClassName := 'msg';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv11.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv11.Name := 'WebHTMLDiv11';
    WebHTMLDiv11.Left := 15;
    WebHTMLDiv11.Top := 11;
    WebHTMLDiv11.Width := 334;
    WebHTMLDiv11.Height := 41;
    WebHTMLDiv11.ElementClassName := 'me_headline';
    WebHTMLDiv11.HeightStyle := ssAuto;
    WebHTMLDiv11.WidthStyle := ssAuto;
    WebHTMLDiv11.ElementPosition := epRelative;
    WebHTMLDiv11.ElementFont := efCSS;
    WebHTMLDiv11.Role := '';
    btnClose.SetParentComponent(WebHTMLDiv11);
    btnClose.Name := 'btnClose';
    btnClose.Left := 297;
    btnClose.Top := -3;
    btnClose.Width := 46;
    btnClose.Height := 44;
    btnClose.ElementClassName := 'albumedit_close';
    btnClose.HeightStyle := ssAuto;
    btnClose.WidthStyle := ssAuto;
    btnClose.HeightPercent := 100.000000000000000000;
    btnClose.WidthPercent := 100.000000000000000000;
    btnClose.Anchors := [];
    btnClose.ChildOrder := 1;
    btnClose.ElementFont := efCSS;
    btnClose.ElementPosition := epIgnore;
    SetEvent(btnClose, Self, 'OnClick', 'btnCloseClick');
    btnClose.Picture.LoadFromFile('ufrChat.WebHTMLDiv11.btnClose.Picture.png');
    WebHTMLDiv2.SetParentComponent(WebHTMLDiv11);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 3;
    WebHTMLDiv2.Top := -8;
    WebHTMLDiv2.Width := 214;
    WebHTMLDiv2.Height := 49;
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ChildOrder := 1;
    WebHTMLDiv2.ElementPosition := epIgnore;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.Role := '';
    imgAvatar.SetParentComponent(WebHTMLDiv2);
    imgAvatar.Name := 'imgAvatar';
    imgAvatar.Left := -1;
    imgAvatar.Top := 5;
    imgAvatar.Width := 53;
    imgAvatar.Height := 41;
    imgAvatar.ElementClassName := 'main_content_avatar';
    imgAvatar.HeightStyle := ssAuto;
    imgAvatar.WidthStyle := ssAuto;
    imgAvatar.HeightPercent := 100.000000000000000000;
    imgAvatar.WidthPercent := 100.000000000000000000;
    imgAvatar.Anchors := [];
    imgAvatar.ElementFont := efCSS;
    imgAvatar.ElementPosition := epIgnore;
    imgAvatar.Picture.LoadFromFile('ufrChat.WebHTMLDiv2.imgAvatar.Picture.jpg');
    divHolder.SetParentComponent(WebHTMLDiv2);
    divHolder.Name := 'divHolder';
    divHolder.Left := 58;
    divHolder.Top := 6;
    divHolder.Width := 137;
    divHolder.Height := 41;
    divHolder.HeightStyle := ssAuto;
    divHolder.WidthStyle := ssAuto;
    divHolder.ChildOrder := 1;
    divHolder.ElementPosition := epIgnore;
    divHolder.ElementFont := efCSS;
    divHolder.Role := '';
    divCenter.SetParentComponent(WebHTMLDiv1);
    divCenter.Name := 'divCenter';
    divCenter.Left := 16;
    divCenter.Top := 58;
    divCenter.Width := 333;
    divCenter.Height := 321;
    divCenter.ElementClassName := 'msg-center';
    divCenter.HeightStyle := ssAuto;
    divCenter.WidthStyle := ssAuto;
    divCenter.ChildOrder := 2;
    divCenter.ElementPosition := epIgnore;
    divCenter.ElementFont := efCSS;
    divCenter.Role := '';
    WebHTMLDiv71.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv71.Name := 'WebHTMLDiv71';
    WebHTMLDiv71.Left := 18;
    WebHTMLDiv71.Top := 385;
    WebHTMLDiv71.Width := 334;
    WebHTMLDiv71.Height := 30;
    WebHTMLDiv71.ElementClassName := 'msg-edit-line';
    WebHTMLDiv71.HeightStyle := ssAuto;
    WebHTMLDiv71.WidthStyle := ssAuto;
    WebHTMLDiv71.ChildOrder := 2;
    WebHTMLDiv71.ElementPosition := epIgnore;
    WebHTMLDiv71.ElementFont := efCSS;
    WebHTMLDiv71.Role := '';
    edEingabe.SetParentComponent(WebHTMLDiv71);
    edEingabe.Name := 'edEingabe';
    edEingabe.Left := 3;
    edEingabe.Top := 3;
    edEingabe.Width := 121;
    edEingabe.Height := 22;
    edEingabe.ElementFont := efCSS;
    edEingabe.ElementPosition := epIgnore;
    edEingabe.HeightStyle := ssAuto;
    edEingabe.HeightPercent := 100.000000000000000000;
    edEingabe.TextHint := 'schreibe eine Nachricht...';
    edEingabe.WidthStyle := ssAuto;
    edEingabe.WidthPercent := 100.000000000000000000;
    btnSend.SetParentComponent(WebHTMLDiv71);
    btnSend.Name := 'btnSend';
    btnSend.Left := 294;
    btnSend.Top := 5;
    btnSend.Width := 40;
    btnSend.Height := 25;
    btnSend.Caption := '...';
    btnSend.ChildOrder := 1;
    btnSend.ElementClassName := 'msg-btn-punkte';
    btnSend.ElementFont := efCSS;
    btnSend.ElementPosition := epIgnore;
    btnSend.HeightStyle := ssAuto;
    btnSend.HeightPercent := 100.000000000000000000;
    btnSend.WidthStyle := ssAuto;
    btnSend.WidthPercent := 100.000000000000000000;
    SetEvent(btnSend, Self, 'OnClick', 'btnSendClick');
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 40;
    Client.Top := 72;
    ChatClient.SetParentComponent(Self);
    ChatClient.Name := 'ChatClient';
    SetEvent(ChatClient, Self, 'OnMessageReceived', 'ChatClientMessageReceived');
    ChatClient.Left := 40;
    ChatClient.Top := 144;
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    WebHTMLDiv11.AfterLoadDFMValues;
    btnClose.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    imgAvatar.AfterLoadDFMValues;
    divHolder.AfterLoadDFMValues;
    divCenter.AfterLoadDFMValues;
    WebHTMLDiv71.AfterLoadDFMValues;
    edEingabe.AfterLoadDFMValues;
    btnSend.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
    ChatClient.AfterLoadDFMValues;
  end;
end;

end.