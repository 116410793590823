unit ufrKurs;

interface

uses
  System.SysUtils, System.Variants, System.Classes,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, WEBLib.Controls,
  WEBLib.WebCtrls, Vcl.Imaging.pngimage, WEBLib.ExtCtrls;

type
  TfrKurs = class(TFrame)
    divContainer: THTMLDiv;
    WebHTMLDiv1: THTMLDiv;
    imgLock: TImageControl;
    WebHTMLDiv2: THTMLDiv;
    imgDone: TImageControl;
    lblTitel: THTMLDiv;
  private
      FVor,
      FID, FStatus:integer;
  published
    Pfad: array of string;
  public
    property Status:integer read FStatus write FStatus;
    property ID:integer read FId write FId;
    property VOR:integer read FVor write FVor;
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

procedure TfrKurs.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divContainer := THTMLDiv.Create(Self);
  WebHTMLDiv1 := THTMLDiv.Create(Self);
  imgLock := TImageControl.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  imgDone := TImageControl.Create(Self);
  lblTitel := THTMLDiv.Create(Self);

  divContainer.BeforeLoadDFMValues;
  WebHTMLDiv1.BeforeLoadDFMValues;
  imgLock.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  imgDone.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  try
    Name := 'frKurs';
    Left := 0;
    Top := 0;
    Width := 640;
    Height := 91;
    TabOrder := 0;
    divContainer.SetParentComponent(Self);
    divContainer.Name := 'divContainer';
    divContainer.Left := 3;
    divContainer.Top := 16;
    divContainer.Width := 625;
    divContainer.Height := 57;
    divContainer.ElementClassName := 'frkurs_content';
    divContainer.HeightStyle := ssAuto;
    divContainer.WidthStyle := ssAuto;
    divContainer.ChildOrder := 1;
    divContainer.ElementPosition := epIgnore;
    divContainer.ElementFont := efCSS;
    divContainer.Role := '';
    WebHTMLDiv1.SetParentComponent(divContainer);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 16;
    WebHTMLDiv1.Top := 3;
    WebHTMLDiv1.Width := 65;
    WebHTMLDiv1.Height := 41;
    WebHTMLDiv1.ElementClassName := 'kurs_status_container';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    imgLock.SetParentComponent(WebHTMLDiv1);
    imgLock.Name := 'imgLock';
    imgLock.Left := 16;
    imgLock.Top := 0;
    imgLock.Width := 49;
    imgLock.Height := 41;
    imgLock.ElementClassName := 'kurs_status_img';
    imgLock.HeightStyle := ssAuto;
    imgLock.WidthStyle := ssAuto;
    imgLock.HeightPercent := 100.000000000000000000;
    imgLock.WidthPercent := 100.000000000000000000;
    imgLock.ChildOrder := 1;
    imgLock.ElementFont := efCSS;
    imgLock.ElementPosition := epIgnore;
    imgLock.Picture.LoadFromFile('ufrKurs.WebHTMLDiv1.imgLock.Picture.png');
    WebHTMLDiv2.SetParentComponent(divContainer);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 87;
    WebHTMLDiv2.Top := 3;
    WebHTMLDiv2.Width := 100;
    WebHTMLDiv2.Height := 41;
    WebHTMLDiv2.ElementClassName := 'kurs_status_container';
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ChildOrder := 1;
    WebHTMLDiv2.ElementPosition := epIgnore;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.Role := '';
    imgDone.SetParentComponent(WebHTMLDiv2);
    imgDone.Name := 'imgDone';
    imgDone.Left := 51;
    imgDone.Top := 0;
    imgDone.Width := 49;
    imgDone.Height := 41;
    imgDone.ElementClassName := 'kurs_status_img';
    imgDone.HeightStyle := ssAuto;
    imgDone.WidthStyle := ssAuto;
    imgDone.HeightPercent := 100.000000000000000000;
    imgDone.WidthPercent := 100.000000000000000000;
    imgDone.ChildOrder := 1;
    imgDone.ElementFont := efCSS;
    imgDone.ElementPosition := epIgnore;
    imgDone.Picture.LoadFromFile('ufrKurs.WebHTMLDiv2.imgDone.Picture.png');
    lblTitel.SetParentComponent(divContainer);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 296;
    lblTitel.Top := 3;
    lblTitel.Width := 100;
    lblTitel.Height := 41;
    lblTitel.ElementClassName := 'kl_content_titel';
    lblTitel.HeightStyle := ssAuto;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.ChildOrder := 2;
    lblTitel.ElementPosition := epIgnore;
    lblTitel.ElementFont := efCSS;
    lblTitel.Role := '';
  finally
    divContainer.AfterLoadDFMValues;
    WebHTMLDiv1.AfterLoadDFMValues;
    imgLock.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    imgDone.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
  end;
end;

end.
