unit uEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.ComCtrls,
  Vcl.Controls, WEBLib.WebCtrls, utb, uGlobal;

type
  TEditCollection = record
    Edit:TWinControl;
    Caption:string;
    Required:boolean;
    Typ:integer;
    max_length:integer;
  end;

type
  TFieldCollection = record
    Caption:string;
    Text:string;
    Required:boolean;
    Typ:integer;
    max_length:integer;
    Sql:string;
  end;

type
  TfrmEditor = class(TForm)
    divWrapper: THTMLDiv;
    divTitel: THTMLDiv;
    divEdit: THTMLDiv;
    pFooter: THTMLDiv;
    btnSave: TButton;
    btnCAncel: TButton;
    procedure WebFormCreate(Sender: TObject);
    [async] procedure btnSaveClick(Sender: TObject); async;
    procedure btnCancelClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
  private
    FCollection: array of TEditCollection;
    FCallback:TCallbackArray;
    FContainer:THtmlDiv;
  public
    class function Exec(ATitel, ABtnText:string; AEdit: array of TFieldCollection;  ACallbackArray:TCallbackArray):integer;
  protected procedure LoadDFMValues; override; end;

var
  frmEditor: TfrmEditor;

implementation
   uses uMain, uDatenmodul;
{$R *.dfm}

procedure TfrmEditor.btnCancelClick(Sender: TObject);
begin
  close;
end;

procedure TfrmEditor.btnSaveClick(Sender: TObject);
Var
  i:integer;
  a : array of string;
begin
  if assigned(FCallback)
  then begin
    for i := 0 to length(FCollection) -1 do
    begin
      if (FCollection[i].Edit is TEdit)
      then begin

        if (FCollection[i].Required) and (TEdit(FCollection[i].Edit).Text = '')
        then begin
          await(Meldung('Das Feld "' + FCollection[i].Caption + '" muss ausgefüllt werden...'));
          exit;
        end;

        if (FCollection[i].max_length > 0) and (length(TEdit(FCollection[i].Edit).Text) > FCollection[i].max_length)
        then begin
          await(Meldung('Text in "' + FCollection[i].Caption + '" ist zu lang (max. ' + FCollection[i].max_length.ToString +')...'));
          exit;
        end;

        case FCollection[i].Typ of
          1:  begin //Email
                if (not IsEmailValid(TEdit(FCollection[i].Edit).Text))
                then begin
                  await(Meldung('Ungültige E-Mail!'));
                  exit;
                end;
              end;
        end;

        setlength(a,i+1);
        a[i] := TEdit(FCollection[i].Edit).Text;
      end
      else begin

        if (FCollection[i].Required) and (TComboBox(FCollection[i].Edit).ItemIndex = -1)
        then begin
          await(Meldung('Das Feld "' + FCollection[i].Caption + '" muss ausgefüllt werden...'));
          exit;
        end;

        setlength(a,i+1);
        a[i] := TComboBox(FCollection[i].Edit).Values[TComboBox(FCollection[i].Edit).ItemIndex];;

      end;
    end;

    FCallback(a);
  end;

  close;

end;

//class function TfrmEditor.Exec(ATitel, ABtnText:string; AHeader, ATexte, ARequired : array of string; ACallbackArray:TCallbackArray):integer;
class function TfrmEditor.Exec(ATitel, ABtnText:string; AEdit: array of TFieldCollection; ACallbackArray:TCallbackArray):integer;

  [async] procedure AfterCreate(AForm: TObject); async;
  Var
    s:string;
    i:integer;
    divHeader:THTMLDiv;
    edText: TEdit;
    cb:TComboBox;
  begin

    TfrmEditor(AForm).ElementHandle.style.removeProperty('position');
    TfrmEditor(AForm).divWrapper.ElementHandle.style.removeProperty('height');

    if ATitel > ''
    then TfrmEditor(AForm).divTitel.HTMl.Text := ATitel;

    for i := 0 to length(aEdit) - 1 do
    begin
      if AEdit[i].Caption > ''
      then begin
        divHeader := THTMLDiv.Create(TfrmEditor(AForm));
        divHeader.Parent := TfrmEditor(AForm).divEdit;
        divHeader.ElementClassName := 'editor-header';
        divHeader.ElementFont      := efCss;
        divHeader.ParentFont       := true;
        divHeader.ElementPosition  := epIgnore;
        divHeader.HeightStyle      := ssAuto;
        divHeader.WidthStyle       := ssAuto;
        divHeader.HTML.Text        := AEdit[i].Caption;
        divHeader.Visible          := AEdit[i].max_length <> -1;
      end;

      Setlength(TfrmEditor(AForm).FCollection, i + 1);

      if AEdit[i].Typ <> 2
      then begin
        edText := TEdit.Create(TfrmEditor(AForm));
        edText.Parent := TfrmEditor(AForm).divEdit;
        edText.ElementClassName := 'profil-input';
        edText.ElementFont      := efCss;
        edText.ElementPosition  := epIgnore;
        edText.HeightStyle      := ssAuto;
        edText.WidthStyle       := ssAuto;
        edText.ParentFont       := true;
        edText.Text             := AEdit[i].Text;
        edText.ElementHandle.style.removeProperty('position');
        TfrmEditor(AForm).FCollection[i].Edit := edText;
        edText.Visible := AEdit[i].max_length <> -1;

      end
      else begin // 2 = Listbox
        cb := TComboBox.Create(TfrmEditor(AForm));
        cb.Parent := TfrmEditor(AForm).divEdit;
        cb.ElementClassName := 'profil-input';
        cb.ElementFont      := efCss;
        cb.ElementPosition  := epIgnore;
        cb.HeightStyle      := ssAuto;
        cb.WidthStyle       := ssAuto;
        cb.ParentFont       := true;
        cb.ElementHandle.style.removeProperty('position');
        tbFillCombobox(cb, 'ID','TEXT', await(datenmodul.EasySQL(AEdit[i].SQL)));
        cb.ItemIndex := -1;
        TfrmEditor(AForm).FCollection[i].Edit := cb;
        cb.Visible := AEdit[i].max_length <> -1;

      end;

      TfrmEditor(AForm).FCollection[i].Caption   := AEdit[i].Caption;
      TfrmEditor(AForm).FCollection[i].Required  := AEdit[i].Required;
      TfrmEditor(AForm).FCollection[i].Typ       := AEdit[i].Typ;
      TfrmEditor(AForm).FCollection[i].max_length:= AEdit[i].max_length;

    end;

    TfrmEditor(AForm).btnSave.Caption := ABtnText;

  end;

Var
  frm:TfrmEditor;
  FConti:THtmlDiv;
begin
  FConti := THtmlDiv.Create(Mainform);
  FConti.Parent := Mainform;
  FConti.ElementClassName := 'editor_transparent';
  FConti.HeightStyle:= ssAuto;
  FConti.WidthStyle := ssAuto;
  FConti.ElementFont := efCSS;
  FConti.ElementHandle.style.removeProperty('height');
  frm := TfrmEditor.CreateNew(FConti.ElementID, @AfterCreate);
  frm.FContainer := FConti;
  frm.FCallback  := ACallbackArray;
end;

procedure TfrmEditor.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  FreeAndNil(FContainer);
end;

procedure TfrmEditor.WebFormCreate(Sender: TObject);
 var
  el: TJSHTMLElement;
begin
  inherited;
  TControl(self).ElementPosition := epIgnore;
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-style');
  el.style.removeProperty('font-size');
end;

procedure TfrmEditor.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divWrapper := THTMLDiv.Create(Self);
  divTitel := THTMLDiv.Create(Self);
  divEdit := THTMLDiv.Create(Self);
  pFooter := THTMLDiv.Create(Self);
  btnSave := TButton.Create(Self);
  btnCAncel := TButton.Create(Self);

  divWrapper.BeforeLoadDFMValues;
  divTitel.BeforeLoadDFMValues;
  divEdit.BeforeLoadDFMValues;
  pFooter.BeforeLoadDFMValues;
  btnSave.BeforeLoadDFMValues;
  btnCAncel.BeforeLoadDFMValues;
  try
    Name := 'frmEditor';
    Width := 490;
    Height := 210;
    ElementFont := efCSS;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    divWrapper.SetParentComponent(Self);
    divWrapper.Name := 'divWrapper';
    divWrapper.Left := 9;
    divWrapper.Top := 8;
    divWrapper.Width := 473;
    divWrapper.Height := 193;
    divWrapper.ElementClassName := 'editor-container';
    divWrapper.HeightStyle := ssAuto;
    divWrapper.WidthStyle := ssAuto;
    divWrapper.ElementPosition := epIgnore;
    divWrapper.ElementFont := efCSS;
    divWrapper.Role := '';
    divTitel.SetParentComponent(divWrapper);
    divTitel.Name := 'divTitel';
    divTitel.Left := 16;
    divTitel.Top := 11;
    divTitel.Width := 441;
    divTitel.Height := 38;
    divTitel.ElementClassName := 'edit-header';
    divTitel.HeightStyle := ssAuto;
    divTitel.WidthStyle := ssAuto;
    divTitel.ElementPosition := epIgnore;
    divTitel.ElementFont := efCSS;
    divTitel.HTML.BeginUpdate;
    try
      divTitel.HTML.Clear;
      divTitel.HTML.Add('Titel');
    finally
      divTitel.HTML.EndUpdate;
    end;
    divTitel.Role := '';
    divEdit.SetParentComponent(divWrapper);
    divEdit.Name := 'divEdit';
    divEdit.Left := 16;
    divEdit.Top := 56;
    divEdit.Width := 441;
    divEdit.Height := 41;
    divEdit.ElementClassName := 'editor-edit-container';
    divEdit.HeightStyle := ssAuto;
    divEdit.WidthStyle := ssAuto;
    divEdit.ChildOrder := 1;
    divEdit.ElementPosition := epIgnore;
    divEdit.ElementFont := efCSS;
    divEdit.Role := '';
    pFooter.SetParentComponent(divWrapper);
    pFooter.Name := 'pFooter';
    pFooter.Left := 16;
    pFooter.Top := 103;
    pFooter.Width := 441;
    pFooter.Height := 73;
    pFooter.ElementClassName := 'editor-footer-div';
    pFooter.HeightStyle := ssAuto;
    pFooter.WidthStyle := ssAuto;
    pFooter.ChildOrder := 5;
    pFooter.ElementPosition := epIgnore;
    pFooter.ElementFont := efCSS;
    pFooter.Role := '';
    btnSave.SetParentComponent(pFooter);
    btnSave.Name := 'btnSave';
    btnSave.Left := 170;
    btnSave.Top := 24;
    btnSave.Width := 96;
    btnSave.Height := 25;
    btnSave.Caption := 'Speichern';
    btnSave.ElementClassName := 'start_button-lets-go';
    btnSave.ElementFont := efCSS;
    btnSave.ElementPosition := epIgnore;
    btnSave.HeightStyle := ssAuto;
    btnSave.HeightPercent := 100.000000000000000000;
    btnSave.WidthStyle := ssAuto;
    btnSave.WidthPercent := 100.000000000000000000;
    SetEvent(btnSave, Self, 'OnClick', 'btnSaveClick');
    btnCAncel.SetParentComponent(pFooter);
    btnCAncel.Name := 'btnCAncel';
    btnCAncel.Left := 272;
    btnCAncel.Top := 24;
    btnCAncel.Width := 96;
    btnCAncel.Height := 25;
    btnCAncel.Caption := 'Abbrechen';
    btnCAncel.ChildOrder := 2;
    btnCAncel.ElementClassName := 'start_button-lets-go';
    btnCAncel.ElementFont := efCSS;
    btnCAncel.ElementPosition := epIgnore;
    btnCAncel.HeightStyle := ssAuto;
    btnCAncel.HeightPercent := 100.000000000000000000;
    btnCAncel.WidthStyle := ssAuto;
    btnCAncel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCAncel, Self, 'OnClick', 'btnCAncelClick');
  finally
    divWrapper.AfterLoadDFMValues;
    divTitel.AfterLoadDFMValues;
    divEdit.AfterLoadDFMValues;
    pFooter.AfterLoadDFMValues;
    btnSave.AfterLoadDFMValues;
    btnCAncel.AfterLoadDFMValues;
  end;
end;

end.