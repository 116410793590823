unit uFotoEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.ComCtrls,
  Vcl.Controls, WEBLib.WebCtrls, XData.Web.Client, utb, uGlobal;

type
  TfrmFotoEdit = class(TForm)
    WebHTMLDiv1: THTMLDiv;
    Client: TXDataWebClient;
    divTitel: THTMLDiv;
    divHeader1: THTMLDiv;
    re1: TRichEdit;
    divHeader2: THTMLDiv;
    re2: TRichEdit;
    pFooter: THTMLDiv;
    btnSave: TButton;
    btnCAncel: TButton;
    procedure WebFormCreate(Sender: TObject);
    procedure btnSaveClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure btnCAncelClick(Sender: TObject);
  private
    FCallbackArray:TCallbackArray;
    FContainer:THtmlDiv;
  public
    [async] class function Exec(ATitel:string; AHeader, ATexte: array of string; ACallbackArray:TCallbackArray):integer; async;
  protected procedure LoadDFMValues; override; end;

var
  frmFotoEdit: TfrmFotoEdit;

implementation
   uses uMain, uDatenmodul;
{$R *.dfm}

procedure TfrmFotoEdit.btnCAncelClick(Sender: TObject);
begin
  close;
end;

procedure TfrmFotoEdit.btnSaveClick(Sender: TObject);
begin
  if assigned(FCallbackArray)
  then FCallBackArray([re1.GetContent, re2.GetContent]);

  close;
end;

class function TfrmFotoEdit.Exec(ATitel:string; AHeader, ATexte : array of string; ACallbackArray:TCallbackArray):integer;

  [async] procedure AfterCreate(AForm: TObject); async;
  Var
    Response: TXDataClientResponse;
    s:string;
    J:TJ;
  begin

    TfrmFotoEdit(AForm).ElementHandle.style.removeProperty('position');
    if ATitel > ''
    then TfrmFotoEdit(AForm).divTitel.HTMl.Text := ATitel;

    TfrmFotoEdit(AForm).re1.visible        := length(ATexte) >= 1;
    TfrmFotoEdit(AForm).divHeader1.visible := length(ATexte) >= 1;
    TfrmFotoEdit(AForm).re2.visible        := length(ATexte) >= 2;
    TfrmFotoEdit(AForm).divHeader2.visible := length(ATexte) >= 2;

    if AHeader[0] > ''
    then TfrmFotoEdit(AForm).divHeader1.HTML.Text := AHeader[0];
    if AHeader[1] > ''
    then TfrmFotoEdit(AForm).divHeader2.HTML.Text := AHeader[1];

    if ATexte[0] > ''
    then TfrmFotoEdit(AForm).re1.Lines.Add(ATexte[0]);
    if ATexte[1] > ''
    then TfrmFotoEdit(AForm).re2.Lines.Add(ATexte[1]);

  end;

Var
  frm:TfrmFotoEdit;
  FConti:THtmlDiv;
begin
  FConti := THtmlDiv.Create(Mainform);
  FConti.Parent := Mainform;
  FConti.ElementClassName := 'editor_transparent';
  FConti.HeightStyle:= ssAuto;
  FConti.WidthStyle := ssAuto;
  FConti.ElementFont := efCSS;
  FConti.ElementHandle.style.removeProperty('height');
  frm := TfrmFotoEdit.CreateNew(FConti.ElementID, @AfterCreate);
  frm.FContainer    := FConti;
  frm.FCallbackArray:= ACallbackArray;

end;

procedure TfrmFotoEdit.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  FreeAndNil(FContainer);
end;

procedure TfrmFotoEdit.WebFormCreate(Sender: TObject);
begin
  re1.ElementHandle.style.removeProperty('position');
  re2.ElementHandle.style.removeProperty('position');

end;

procedure TfrmFotoEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  divTitel := THTMLDiv.Create(Self);
  divHeader1 := THTMLDiv.Create(Self);
  re1 := TRichEdit.Create(Self);
  divHeader2 := THTMLDiv.Create(Self);
  re2 := TRichEdit.Create(Self);
  pFooter := THTMLDiv.Create(Self);
  btnSave := TButton.Create(Self);
  btnCAncel := TButton.Create(Self);
  Client := TXDataWebClient.Create(Self);

  WebHTMLDiv1.BeforeLoadDFMValues;
  divTitel.BeforeLoadDFMValues;
  divHeader1.BeforeLoadDFMValues;
  re1.BeforeLoadDFMValues;
  divHeader2.BeforeLoadDFMValues;
  re2.BeforeLoadDFMValues;
  pFooter.BeforeLoadDFMValues;
  btnSave.BeforeLoadDFMValues;
  btnCAncel.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frmFotoEdit';
    Width := 500;
    Height := 450;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 8;
    WebHTMLDiv1.Top := 8;
    WebHTMLDiv1.Width := 473;
    WebHTMLDiv1.Height := 426;
    WebHTMLDiv1.ElementClassName := 'input_container';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    divTitel.SetParentComponent(WebHTMLDiv1);
    divTitel.Name := 'divTitel';
    divTitel.Left := 16;
    divTitel.Top := 11;
    divTitel.Width := 441;
    divTitel.Height := 38;
    divTitel.ElementClassName := 'edit-header';
    divTitel.HeightStyle := ssAuto;
    divTitel.WidthStyle := ssAuto;
    divTitel.ElementPosition := epIgnore;
    divTitel.ElementFont := efCSS;
    divTitel.HTML.BeginUpdate;
    try
      divTitel.HTML.Clear;
      divTitel.HTML.Add('Titel');
    finally
      divTitel.HTML.EndUpdate;
    end;
    divTitel.Role := '';
    divHeader1.SetParentComponent(WebHTMLDiv1);
    divHeader1.Name := 'divHeader1';
    divHeader1.Left := 16;
    divHeader1.Top := 55;
    divHeader1.Width := 441;
    divHeader1.Height := 38;
    divHeader1.ElementClassName := 'edit-header';
    divHeader1.HeightStyle := ssAuto;
    divHeader1.WidthStyle := ssAuto;
    divHeader1.ChildOrder := 1;
    divHeader1.ElementPosition := epIgnore;
    divHeader1.ElementFont := efCSS;
    divHeader1.HTML.BeginUpdate;
    try
      divHeader1.HTML.Clear;
      divHeader1.HTML.Add('Titel');
    finally
      divHeader1.HTML.EndUpdate;
    end;
    divHeader1.Role := '';
    re1.SetParentComponent(WebHTMLDiv1);
    re1.Name := 're1';
    re1.Left := 16;
    re1.Top := 99;
    re1.Width := 441;
    re1.Height := 46;
    re1.ElementClassName := 'input_input';
    re1.HeightStyle := ssAuto;
    re1.WidthStyle := ssAuto;
    re1.BorderStyle := bsSingle;
    re1.ChildOrder := 1;
    re1.Color := clWhite;
    divHeader2.SetParentComponent(WebHTMLDiv1);
    divHeader2.Name := 'divHeader2';
    divHeader2.Left := 16;
    divHeader2.Top := 151;
    divHeader2.Width := 441;
    divHeader2.Height := 42;
    divHeader2.ElementClassName := 'edit-header';
    divHeader2.HeightStyle := ssAuto;
    divHeader2.WidthStyle := ssAuto;
    divHeader2.ChildOrder := 3;
    divHeader2.ElementPosition := epIgnore;
    divHeader2.ElementFont := efCSS;
    divHeader2.HTML.BeginUpdate;
    try
      divHeader2.HTML.Clear;
      divHeader2.HTML.Add('Beschreibung');
    finally
      divHeader2.HTML.EndUpdate;
    end;
    divHeader2.Role := '';
    re2.SetParentComponent(WebHTMLDiv1);
    re2.Name := 're2';
    re2.Left := 16;
    re2.Top := 199;
    re2.Width := 441;
    re2.Height := 95;
    re2.ElementClassName := 'input_input';
    re2.HeightStyle := ssAuto;
    re2.WidthStyle := ssAuto;
    re2.BorderStyle := bsSingle;
    re2.ChildOrder := 3;
    re2.Color := clWhite;
    pFooter.SetParentComponent(WebHTMLDiv1);
    pFooter.Name := 'pFooter';
    pFooter.Left := 16;
    pFooter.Top := 300;
    pFooter.Width := 441;
    pFooter.Height := 73;
    pFooter.ElementClassName := 'editor-footer-div';
    pFooter.HeightStyle := ssAuto;
    pFooter.WidthStyle := ssAuto;
    pFooter.ChildOrder := 5;
    pFooter.ElementPosition := epIgnore;
    pFooter.ElementFont := efCSS;
    pFooter.Role := '';
    btnSave.SetParentComponent(pFooter);
    btnSave.Name := 'btnSave';
    btnSave.Left := 170;
    btnSave.Top := 24;
    btnSave.Width := 96;
    btnSave.Height := 25;
    btnSave.Caption := 'Speichern';
    btnSave.ElementClassName := 'start_button-lets-go';
    btnSave.ElementFont := efCSS;
    btnSave.ElementPosition := epIgnore;
    btnSave.HeightStyle := ssAuto;
    btnSave.HeightPercent := 100.000000000000000000;
    btnSave.WidthStyle := ssAuto;
    btnSave.WidthPercent := 100.000000000000000000;
    SetEvent(btnSave, Self, 'OnClick', 'btnSaveClick');
    btnCAncel.SetParentComponent(pFooter);
    btnCAncel.Name := 'btnCAncel';
    btnCAncel.Left := 272;
    btnCAncel.Top := 24;
    btnCAncel.Width := 96;
    btnCAncel.Height := 25;
    btnCAncel.Caption := 'Abbrechen';
    btnCAncel.ChildOrder := 2;
    btnCAncel.ElementClassName := 'start_button-lets-go';
    btnCAncel.ElementFont := efCSS;
    btnCAncel.ElementPosition := epIgnore;
    btnCAncel.HeightStyle := ssAuto;
    btnCAncel.HeightPercent := 100.000000000000000000;
    btnCAncel.WidthStyle := ssAuto;
    btnCAncel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCAncel, Self, 'OnClick', 'btnCAncelClick');
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 40;
    Client.Top := 128;
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    divTitel.AfterLoadDFMValues;
    divHeader1.AfterLoadDFMValues;
    re1.AfterLoadDFMValues;
    divHeader2.AfterLoadDFMValues;
    re2.AfterLoadDFMValues;
    pFooter.AfterLoadDFMValues;
    btnSave.AfterLoadDFMValues;
    btnCAncel.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.