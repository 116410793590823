unit uDatenModul;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Modules, XData.Web.Connection,
  Weblib.JSON, XData.Web.Client, Data.DB, XData.Web.JsonDataset,WEBLib.WebCtrls,
  XData.Web.Dataset, WEBLib.Dialogs,WEBLib.ExtCtrls, DateUtils, utb, uCrypt,
  Vcl.Controls, WebLib.StdCtrls, uMain, WEBLib.EditAutocomplete;

type
  TDone = Record
    Done:integer;
    Complete:integer;
    Percent:integer;
  end;

  TDatenModul = class(TDataModule)
    Connection: TXDataWebConnection;
    Client: TXDataWebClient;
    [async] procedure WebDataModuleCreate(Sender: TObject); async;
    procedure WebDataModuleDestroy(Sender: TObject);
  private
  public
    function FindControlByName(AOwner: TComponent; AName: string): TControl;
    [async] function GetMedia( Medien_Id, ASize:integer ):string; async;
    [async] function GetPercentDone(AUser_id, AKurs_Id: integer): TDone; async;
    function GetComponentByElementID(AOwner:TComponent; ElementID:string):TControl;
    [async] procedure Update(ATabelle, AIDFeld, AIDValue, AFeld, AValue: string); async;
    //User
//    [async] function GetMe(AUserID:integer):TMe;
//    [async] function GetLoginUser(AUserID:integer):TMe;
    [async] function Avatar(AUser, AItem, ATyp: integer): integer; async;
    [async] function TitelBild(AUser, AItem, ATyp: integer): integer; async;
    //Freundschaftsanfragen
    [async] procedure FSAnfrage(AHolder, AUser: integer); async;
    [async] function  FSCount(AHolder, AStatus: integer): integer; async;
    [async] function  FSUserList(AHolder, AAnfrage:integer):TJ; async;
    [async] function  FSAccept(AHolder, AUser:integer):integer; async;
    [async] function  FSReject(AHolder, AUser:integer):integer; async;
    //Post
    [async] function  PostDel(AID:integer):integer; async;
    //Easy
    [async] function  EasySQL(ASql:string):TXDataClientResponse; async;
    [async] function  EasyExec(ASql:string):TXDataClientResponse; async;
    [async] function  EasyCount(ASql:string):integer; async;
    [async] function  EasyInsert(ASql:string):integer; async;
    //Like
    [async] function LikeSet(AUser, AItem, ATyp, AStatus:integer):integer; async;
    [async] function LikeCount(AItem, ATyp, AStatus:integer):integer; async;
    [async] function LikeUserList(AItem, ATyp, AStatus:integer): TXDataClientResponse; async;

  protected procedure LoadDFMValues; override; end;

  [async] procedure GetLikeCount(AID, ATyp, AStatus: integer; divLikeWrapper, divLikeUser, divLikeCount: THTMLDiv); async;
  [async] function KurseCount(AID, AUser:integer): string; async;
  [async] procedure FillWithHashTags(Aed:TEditAutoComplete); async;
  [async] function Connected(AHolder, AUser:integer):integer; async;
  [async] procedure WelcomePost(AUser, AAdmin, AVorname, AText:string); async;
  [async] function GetViewCount(AForm, AModul, AItem:string):string; async;


var
  DatenModul: TDatenModul;

implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}

{$R *.dfm}

{
https://www.segler-portal.de:10002/DBService/EasySQL/?ASQL=%27select%20*%20from%20Blog%20Where%20LEVEL%20%3D%201%20AND%20BLOGKAT_ID%20%3D%20389%27
}



function GetViewCount(AForm, AModul, AItem:string):string;
var
  j:TJ;
begin
  j := TJ.Create(await(datenmodul.EasySQL('SELECT COUNT(*) AS ANZ FROM login ' +
                                               ' WHERE form_id  = ' + AForm +
                                               '   AND modul_id = ' + AModul +
                                               '   AND item_id  = ' + AItem)));
                                               //'   AND holder_id <> user_id
  result := j.Value('ANZ');
  j.Free;
end;


procedure WelcomePost(AUser, AAdmin, AVorname, AText:string);
Var
  sPost:string;
begin
  //Begrüßungstext vom Segler-Portal als Post
  AText := StringReplace(AText,'%VORNAME%',AVorname, [rfReplaceAll]);

  sPost := await(datenmodul.EasyInsert('Insert into Post (STATUS, HOLDER, USER_ID, TEXT) ' +
            ' VALUES(1,' + AUser + ',' + AAdmin + ',''' +  AText + ''')')).ToString;
  await(datenmodul.EasyExec('UPDATE Post Set Post_ID = ' + sPost + ' WHERE ID = ' + sPost ));
end;

function Connected(AHolder, AUser:integer):integer;
//gibt den Status aus meiner (User) Sicht zurück

Var
  j:TJ;
begin

  if AHolder = AUser
  then begin
     result := -1 // Ich bin es selbst
  end
  else begin

    j := TJ.create(await(datenmodul.EasySQL('select STATUS, EMPFAENGER, SENDER from friends ' +
                                            ' where (    EMPFAENGER = ' + AHolder.ToString +
                                            '        and SENDER = ' + AUser.ToString + ')' +
                                            ' OR ' +
                                            '       (    EMPFAENGER = ' + AUser.ToString +
                                            '        and SENDER = ' + AHolder.ToString + ')' )));

    if j.hasValue
    then begin
      if j.Integer('STATUS') = 0 //Anfrage ist noch nicht bestätigt
      then begin

        if j.Integer('EMPFAENGER') = AHolder //Holder hat die Anfrage gestellt - ich muss bestätigen
        then result := _FSA_Pending       //Der Holder wartet auf Antwort
        else begin
          if j.Integer('EMPFAENGER') = AUser //Ich habe die Anfrage gestellt - der Holder muss bestätigen
          then result := _FSA_Commit;   //Ich warte auf Antwort
        end;
      end
      else result := _FSA_Drop; //Anfrage ist bestätigt und könnte gelöscht werden
    end
    else result := _FSA_Add;  //es gibt keinen Status -  Anfrage kann gestellt werden;

    j.Free;
  end;
end;

procedure FillWithHashTags(Aed:TEditAutoComplete);
Var
  J:TJ;
  i:integer;
  s:string;
begin
  J := TJ.create(Await(Datenmodul.easySQL('SELECT TEXT, ID, ANZ FROM blogKat')));

  for i := 0 to j.Length -1 do
  begin
    j.index := i;
    s := s + j.Value('TEXT') + ',';
  end;
  Aed.BeginUpdate;
  Aed.Items.StrictDelimiter := true;
  Aed.Items.Delimiter := ',';
  Aed.Items.DelimitedText := s;
  Aed.EndUpdate;
end;


function KurseCount(AID, AUser:integer): string;
Var
  Res: TXDataClientResponse;
  J:TJ;
begin

  res := await(datenmodul.EasySQL('CALL P_kurse_count(1,' + AID.ToString + ',' + AUser.ToString + ')'));
  j := TJ.create(Res);
  result := '  (' + j.Value('ANZ') + ' / ';

  res := await(datenmodul.EasySQL('CALL P_kurse_count(0,' + AID.ToString + ',' + AUser.ToString + ')'));
  j.Response := res;
  result := result + j.Value('ANZ') + ')';

end;

procedure GetLikeCount(AId, ATyp, AStatus: integer; divLikeWrapper, divLikeUser, divLikeCount: THTMLDiv);

    function CreateAvatar(AID:integer; AOwner:TComponent; AParent: TWinControl):TImageControl;
    begin
      result := TImageControl.Create(AParent.Owner);
      result.Parent           := AParent;
      result.ElementPosition  := epRelative;
      result.ElementClassName := 'like_avatar';
      result.WidthStyle       := ssAuto;
      result.heightStyle      := ssAuto;
      result.Tag              := AID;
      result.ElementID        := 'avatar' + AId.ToString;
      result.Name             := result.ElementID;
    end;

Var
  j:TJ;
  s:string;
  i:integer;
  img : TImageControl;
begin

  //Alle löschen um sie dann wieder zu erstellen...
  for i := divLikeWrapper.Owner.ComponentCount - 1 downto 0 do
  begin
    if divLikeWrapper.Owner.Components[i] is TImageControl
    then begin
      img := TImageControl(divLikeWrapper.Owner.Components[i]);
      if copy(img.Name,0,6) = 'avatar'
      then FreeAndNil(img);
    end;
  end;

  J := TJ.create(await(datenmodul.LikeUserList(AID, ATyp, integer(ltLike))));
  s:='';

  if j.hasValue
  then begin
    for i := 0 to j.Length -1 do
    begin
      j.Index := i;

      img := CreateAvatar((AID.ToString + j.value('ID')).tointeger, nil, divLikeWrapper);
      img.URL := await(datenmodul.GetMedia(j.Integer('AVATAR_MEDIEN_ID'),_SIZE3));

      if i <= 1
      then begin

        if j.Integer('ID') = mainform.ME.id
        then s := s + ', Dir'
        else s := s + ', ' + J.Value('NICKNAME');

      end;
    end;

    divLikeCount.HTML.Text := (i+1).ToString;
    s := '<font-weight=bolder>' + copy(s,2,s.length) + '</font>';

    if (j.length - 2 > 0)
    then s := s + ' und ' + (j.length - 2).ToString
    else s := stringreplace(s,', ', ' und ',[]);

    divLikeUser.HTML.Text := s + ' gefällt das';

  end
  else begin
    divLikeCount.HTML.Text := '';
    divLikeUser.HTML.Text  := '';
  end;

end;

function TDatenmodul.EasyInsert(ASql:string):integer;
var
  Response: TXDataClientResponse;
  j : TJ;
begin
  ASQL := Crypt(ASQL,16);
  Response := Await( Client.RawInvokeAsync( 'IDBService.EasyINSERT',[ASql]));
  j := TJ.create(Response);
  result := j.Integer('ID');
end;

function TDatenmodul.EasySQL(ASql:string):TXDataClientResponse;
begin
  if Connection.Connected = false
  then await(connection.OpenAsync);

  if (pos('DELETE',Uppercase(ASQL)) = 0) and (pos('UPDATE',UpperCase(ASQL)) = 0)
  then begin
    ASQL := crypt(ASQL,16);
    result := Await( Client.RawInvokeAsync( 'IDBService.EasySQL',[ASql]));
  end;
end;

function TDatenmodul.EasyExec(ASql:string):TXDataClientResponse;
begin
  ASQL := Crypt(ASQL,16);
  result := Await( Client.RawInvokeAsync( 'IDBService.EasyExec',[ASql]));
end;

function TDatenmodul.EasyCount(ASql:string):integer;
var
  Response: TXDataClientResponse;
  j : TJ;
begin
  ASQL := Crypt(ASQL,16);
  Response := Await( Client.RawInvokeAsync( 'IDBService.EasySQL',[ASql]));
  j := TJ.create(Response);
  result := j.Integer('ANZ');
end;


function TDatenmodul.PostDel(AID:integer):integer;
var
  Response: TXDataClientResponse;
  j : TJ;
begin
  Response := Await( Client.RawInvokeAsync( 'IDBService.PostDel',[AID]));
  j := TJ.create(Response);
  result := j.Integer('STATUS');
end;

function TDatenmodul.FSAccept(AHolder, AUser:integer):integer;
var
  Response: TXDataClientResponse;
begin
  Response := Await( Client.RawInvokeAsync( 'IDBService.FSAccept',[AHolder, AUser]));
  result   := 1;
end;

function TDatenmodul.FSReject(AHolder, AUser:integer):integer;
var
  Response: TXDataClientResponse;
begin
  Response := Await( Client.RawInvokeAsync( 'IDBService.FSReject',[AHolder, AUser]));
  result   := 1;
end;

function TDatenmodul.FSUserList(AHolder, AAnfrage:integer):TJ;
var
  Response: TXDataClientResponse;
begin
  Response := Await( Client.RawInvokeAsync( 'IDBService.FSUserList',[AHolder, AAnfrage]));
  result := TJ.create(response);
end;

function TDatenmodul.FSCount(AHolder, AStatus:integer):integer;
var
  Response: TXDataClientResponse;
  j : TJ;
begin
  Response := Await( Client.RawInvokeAsync( 'IDBService.FSCount',[AHolder, AStatus]));
  j := TJ.create(Response);
  result := j.Integer('ANZ');
end;

procedure TDatenModul.FSAnfrage(AHolder, AUser: integer);
var
  Response: TXDataClientResponse;
begin
  Response := Await( Client.RawInvokeAsync( 'IDBService.FSAnfrage',[AHolder, AUser]));
end;

// -- Like
function TDatenModul.LikeUserList(AItem, ATyp, AStatus:integer): TXDataClientResponse;
begin
  Result := Await( Client.RawInvokeAsync( 'IDBService.LikeUserList',[AItem, ATyp, AStatus]));
end;

function TDatenmodul.LikeCount(AItem, ATyp, AStatus:integer):integer;
var
  Response: TXDataClientResponse;
  j : TJ;
begin
  Response := Await( Client.RawInvokeAsync( 'IDBService.LikeCount',[AItem, ATyp, AStatus]));
  j := TJ.create(Response);
  result := j.Integer('ANZ');
end;

function TDatenmodul.LikeSet(AUser, AItem, ATyp, AStatus:integer):integer;
var
  Response: TXDataClientResponse;
  j : TJ;
begin
  Response := Await( Client.RawInvokeAsync( 'IDBService.LikeSet',[AUser, AItem, ATyp, AStatus]));
  j := TJ.create(Response);
  result := j.Integer('ID');
end;

function TDatenmodul.TitelBild(AUser, AItem, ATyp:integer):integer;
begin
  Update('User', 'ID', AUser.ToString, 'TITEL_MEDIEN_ID', AItem.ToString);
end;

function TDatenmodul.Avatar(AUser, AItem, ATyp:integer):integer;
begin
  Update('User', 'ID', AUser.ToString, 'AVATAR_MEDIEN_ID', AItem.ToString);
end;

procedure TDatenModul.Update(ATabelle, AIDFeld, AIDValue, AFeld, AValue: string);
var
  Response: TXDataClientResponse;
begin
  Response := Await( Client.RawInvokeAsync( 'IDBService.Update',[ATabelle, AIDFeld, AIDValue, AFeld, AValue]));
end;

procedure TDatenModul.WebDataModuleCreate(Sender: TObject);
begin

  connection.connected := true;
// http://localhost:10000
// https://www.segler-portal.de:10002
end;

procedure TDatenModul.WebDataModuleDestroy(Sender: TObject);
begin
  connection.Close;
end;

function TDatenmodul.GetComponentByElementID(AOwner:TComponent; ElementID:string):TControl;
var
  i:integer;
begin
  result := nil;
  //showmessage(AOwner.name);
  for i := 0 to AOwner.ComponentCount -1 do
  begin
    if (AOwner.Components[i] is TControl) and
       (TControl(AOwner.Components[i]).ElementID = ElementId )
    then begin
      result := TControl(AOwner.Components[i]);
      Exit;
    end;
  end;
end;

function TDatenModul.GetPercentDone(AUser_id, AKurs_Id:integer): TDone;
Var
  sDone, sComplete:string;
  Response: TXDataClientResponse;
  a:TJSArray;
  o: TJSObject;
begin

  Response := Await( Client.RawInvokeAsync( 'IDBService.GetPercentDone',[AUser_id, AKurs_Id]));
  a := TJSArray(TJSObject(Response.Result)['value']);

   // s := TJSJson.stringify(a[0]);
  o := TJSObject(TObject(a[0]));

  sComplete := String(o.Properties['COMPLETE']);
  sDone := String(o.Properties['DONE']);

  result.Complete := sComplete.ToInteger;
  result.Done     := sDone.ToInteger;
  result.Percent  := (result.Complete div result.Done) * 10;

//  result := sDone + ' von ' + sComplete + ' erledigt';
end;

function TDatenModul.GetMedia(Medien_Id, ASize:integer):string;
var
  s: string;
  Response: TXDataClientResponse;
  j:TJ;
  sSize:string;
begin

  Result := '';
  if Medien_Id > 0
  then begin
    try
      result := Connection.url + '/DBService/GetImage?Mediaid='+ Medien_Id.ToString +'&Size=' + ASize.ToString;
//      result := 'https://www.segler-portal.de:10002/DBService/GetImage?Mediaid='+ Medien_Id.ToString +'&Size=' + ASize.ToString;

//      Response := TAwait.Exec<TXDataClientResponse>( Client.RawInvokeAsync('IDBService.MediaGet',[Medien_Id, ASize]));
    except
     on E : Exception do
     begin
       //ShowMessage('Exception message = '+E.Message);
     end;
    end;

//    j := TJ.create(Response);
//
//    sSize := 'SIZE' + ASize.ToString;
//    if (not j.isEmpty)
//    then result := j.Value(sSize)
//    else result := '';
  end;

end;

function TDatenModul.FindControlByName(AOwner:TComponent; AName:string):TControl;
var
  i:integer;
begin
  result := nil;
  for i := 0 to AOwner.ComponentCount -1 do
  begin
    if AOwner.Components[i].Name = AName
    then begin
      result := TControl(AOwner.Components[i]);
      //showmessage('gefunden ' + result.name);
      Exit;
    end;
  end;
end;

procedure TDatenModul.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Connection := TXDataWebConnection.Create(Self);
  Client := TXDataWebClient.Create(Self);

  Connection.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'DatenModul';
    SetEvent(Self, 'OnCreate', 'WebDataModuleCreate');
    SetEvent(Self, 'OnDestroy', 'WebDataModuleDestroy');
    Height := 232;
    Width := 539;
    Connection.SetParentComponent(Self);
    Connection.Name := 'Connection';
    Connection.URL := 'https://www.segler-portal.de:10002';
    Connection.DesignData.Persist := True;
    Connection.Left := 192;
    Connection.Top := 144;
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := Connection;
    Client.Left := 104;
    Client.Top := 56;
  finally
    Connection.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.
