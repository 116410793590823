unit uSlider;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.WebCtrls, uTB,uGlobal,
  XData.Web.Client, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Imaging.pngimage,
  WEBLib.ExtCtrls, Vcl.Forms, ufrFotosListe, ufrComments, ufrVideoListe;

type
  TfrmSlider = class(TForm)
    WebHTMLDiv1: THTMLDiv;
    Client: TXDataWebClient;
    WebHTMLDiv11: THTMLDiv;
    btnClose: TImageControl;
    lblTitel: TLabel;
    divCenter: THTMLDiv;
    divFotos: THTMLDiv;
    frFotosliste1: TfrFotosliste;
    imgBig: TImageControl;
    divVideos: THTMLDiv;
    frVideoListe1: TfrVideoListe;
    WebHTMLDiv4: THTMLDiv;
    divComments: THTMLDiv;
    WebHTMLDiv2: THTMLDiv;
    divTitel: THTMLDiv;
    divText: THTMLDiv;
    divNickname: THTMLDiv;
    lblNickName: TLinkLabel;
    WebHTMLDiv3: THTMLDiv;
    WebHTMLDiv5: THTMLDiv;
    divName: THTMLDiv;
    lblMedienTitel: THTMLDiv;
    WebHTMLDiv6: THTMLDiv;
    btnNew: TImageControl;
    btnDel: TImageControl;
    divYoutube: THTMLDiv;
    WebHTMLDiv7: THTMLDiv;
    WebHTMLDiv8: THTMLDiv;
    divDatum: THTMLDiv;
    procedure WebFormCreate(Sender: TObject);
    procedure btnCloseClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    [async] procedure lblNickNameLinkClick(Sender: TObject; Link: string;
      LinkType: TSysLinkType); async;
    procedure lblNameLinkClick(Sender: TObject; Link: string;
      LinkType: TSysLinkType);
    [async] procedure divNameClick(Sender: TObject); async;
    procedure divTitelClick(Sender: TObject);
    [async] procedure btnDelClick(Sender: TObject); async;
  private
    FEdit:boolean;
    FCallback:TCallback;
    FContainer:THtmlDiv;
    FOwner : THTMLDiv;
    FBlogID, FAlbumID, FPostID, FKursID:integer;
    FMedienID:integer;
    FComments: TfrComments;
    FHolder, FMe:integer;
    bTitel:boolean;
    FID:integer;
    Flinklbl:TLinkLabel;
  public
    class function Fotos( AHolder, AMe, AAlbumID, APostID, ABlogID, AKursID, AStartID:integer; ACallback:TCallback; AAddImage:integer): integer;
    class function Videos(AHolder, AMe, AAlbumID, APostID, ABlogID, AKursID, AStartID:integer; ACallback:TCallback; AAddImage:integer): integer;
    [async] procedure CallbackEdit(aArray: array of string); async;
    [async] procedure SetFoto(s:string); async;
    [async] procedure SetVideo(s:string); async;
    [async] procedure SetComments(ACommentID:string); async;
    property AlbumId : integer read FAlbumID write FAlbumID;
    procedure CommentCallBack(s:string);
  protected procedure LoadDFMValues; override; end;

var
  frmSlider: TfrmSlider;

implementation
  uses uEdit, uDatenmodul, uMain;
{$R *.dfm}

class function TfrmSlider.Fotos(AHolder, AMe, AAlbumID, APostID, ABlogID, AKursID, AStartID:integer; ACallback:TCallback; AAddImage:integer): integer;

  [async] procedure AfterCreate(AForm: TObject); async;
  Var
    Response: TXDataClientResponse;
    s:string;
    J:TJ;
  begin
    TfrmSlider(AForm).ElementHandle.style.removeProperty('position');
    TfrmSlider(AForm).Parent   := Mainform;
    TfrmSlider(AForm).FHolder  := AHolder;
    TfrmSlider(AForm).FMe      := AMe;
    TfrmSlider(AForm).FAlbumID := AAlbumID;
    TfrmSlider(AForm).FPostID  := APostID;
    TfrmSlider(AForm).FBlogID  := ABlogID;
    TfrmSlider(AForm).FKursID  := AKursID;
    TfrmSlider(AForm).frFotosliste1.img.elementhandle.style.setProperty('filter', 'invert(1)');
    TfrmSlider(AForm).frFotosliste1.Init(mainform.Holder.ID, mainform.ME.ID,  AAlbumID, APostID, ABlogID, AKursID, AStartID, 1, @TfrmSlider(AForm).SetFoto, true, AAddImage);
    TfrmSlider(AForm).divFotos.Visible := true;
    TfrmSlider(AForm).FCallback := ACallback;
  end;

Var
  frm:TfrmSlider;
  FConti:THtmlDiv;
begin
  FConti := THtmlDiv.Create(Mainform);
  FConti.Parent := Mainform;
  FConti.ElementClassName := 'editor_transparent';
  FConti.HeightStyle:= ssAuto;
  FConti.WidthStyle := ssAuto;
  FConti.ElementFont := efCSS;
  FConti.ElementHandle.style.removeProperty('height');
  frm := TfrmSlider.CreateNew(FConti.ElementID, @AfterCreate);
  frm.FContainer := FConti;

end;

procedure TfrmSlider.btnDelClick(Sender: TObject);
Var
  sArt, s,s2:string;
  j:TJ;
begin
  sArt := IIF(frFotosliste1.visible, 'Foto', 'Video');
  s := FMedienID.ToString;

  //Feststellen ob das Foto in seinem 'Home' - Album angezeigt wird, oder ob es sich nur um einen Verweis handelt
  j := TJ.create(await(datenmodul.EasySQL('SELECT ALBUM_ID FROM medien WHERE id = ' + s )));

  if j.integer('ALBUM_ID') <> FAlbumID
  then begin //Wenn nur Verweis, dann nur diesen Verweis löschen
    if await(Frage('Bist Du sicher, dass du dieses ' + sArt + ' entfernen möchtest?')) = mrYes
    then begin
      await(datenmodul.EasyExec('delete from medien2holder where Medien_Typ = 1 and medien_id = ' + s + ' AND Holder_typ = ' + _HT_Album  + ' and holder_id = ' + FAlbumId.tostring));

      if frFotosliste1.visible
      then await(frFotosliste1.refresh)
      else await(frVideoliste1.refresh);

      await(Meldung(sArt + ' wurde entfernt...'));

    end;
  end
  else begin //Wenn im Home-Album, dann überall löschen wenn möglich
    if await(Frage('Bist Du sicher, dass dieses ' + sArt + ' unwiderruflich gelöscht und alle Kommentare entfernt werden sollen?')) = mrYes
    then begin
  //    showmessage(s);
      j.response := await(datenmodul.EasySQL('select ID from medien2Holder where HOLDER_TYP = ' + _HT_Post + ' AND MEDIEN_ID = ' + s));
      if j.Value('ID') > ''
      then begin
         await(Meldung(sArt + ' kann nicht gelöscht werden, da es bereits in einem oder mehreren Beiträgen verwendet wird!'));
         exit;
      end;
      j.response := await(datenmodul.EasySQL('select ID from medien2Holder where HOLDER_TYP = ' + _HT_Blog + ' AND MEDIEN_ID = ' + s));
      if j.Value('ID') > ''
      then begin
         await(Meldung(sArt + '  kann nicht gelöscht werden, da es bereits in einem oder mehreren Blogs verwendet wird!'));
         exit;
      end;
      j.response := await(datenmodul.EasySQL('select ID from medien2Holder where HOLDER_TYP = ' + _HT_Kurs + ' AND MEDIEN_ID = ' + s));
      if j.Value('ID') > ''
      then begin
         await(Meldung(sArt + '  kann nicht gelöscht werden, da es bereits in einem oder mehreren Kursen verwendet wird!'));
         exit;
      end;
      j.response := await(datenmodul.EasySQL('select ID from Kurse where Upper(TEXT) LIKE ''%{BILD=' + s + '}%'''));
      if j.Value('ID') > ''
      then begin
         await(Meldung(sArt + '  kann nicht gelöscht werden, da es bereits in einem oder mehreren Kursen verwendet wird!'));
         exit;
      end;
      j.response := await(datenmodul.EasySQL('select ID from Kurse where MEDIEN_ID = ' + s));
      if j.Value('ID') > ''
      then begin
         await(Meldung(sArt + '  kann nicht gelöscht werden, da es bereits in einem oder mehreren Kursen verwendet wird!'));
         exit;
      end;
      j.response := await(datenmodul.EasySQL('select ID from User where AVATAR_MEDIEN_ID = ' + s + ' OR TITEL_MEDIEN_ID = ' + s));
      if j.Value('ID') > ''
      then begin
         await(Meldung(sArt + '  kann nicht gelöscht werden, da es bereits als Profil- oder Titelbild verwendet wird!'));
         exit;
      end;

      j.response := await(datenmodul.EasySQL('select ID from SYSTEM'));
      if (j.Value('AVATAR_MEDIEN_ID') = s) or (j.Value('TITEL_MEDIEN_ID') = s) or
         (j.Value('EVENT_MEDIEN_ID') = s) or (j.Value('LOGO_MEDIEN_ID') = s) or
         (j.Value('DEFAULT_MEDIEN_ID') = s)
      then begin
         await(Meldung(sArt + '  kann nicht gelöscht werden, da es bereits als "Default"-Foto in der Systemsteuerung hinterlegt wurde!'));
         exit;
      end;

      j.response := await(datenmodul.EasySQL('Select HOLDER_ID from medien2holder where Medien_id = ' +s));
      if j.Value('HOLDER_ID') > ''
      then begin
        //Album merken
        j.response := await(datenmodul.EasySQL('SELECT HOLDER_ID FROM medien2holder WHERE holder_typ = ' + _HT_Album + ' AND medien_id = ' +s));
        s2 := j.value('HOLDER_ID');

        //Alle Einträge in Post löschen
        await(datenmodul.EasyExec('Delete from Post where EXISTS (select holder_id from medien2holder where medien_id = ' + s + ' AND holder_id = post_id AND holder_typ IN (' + _HT_Post + ',' + _HT_Media + '))'));
        //Alle Verweise löschen
        await(datenmodul.EasyExec('Delete from medien2Holder where Medien_ID = ' + s));
        //Das Foto selbst löschen
        await(datenmodul.EasyExec('Delete from medien where ID = ' + s));

        //Anzahl Bilder/Videos im Album bereinigen
        await(datenmodul.EasyExec('update Alben Set STANDARD_MEDIA_ID = 0 where STANDARD_MEDIA_ID = ' + s));
      end;

      if frFotosliste1.visible
      then await(frFotosliste1.refresh)
      else await(frVideoliste1.refresh);

      await(Meldung(sArt + ' wurde gelöscht...'));
    end;

  end;

end;

procedure TfrmSlider.CallbackEdit(aArray: array of string);
begin
// showmessage('Aktualisieren...');
  lblMedienTitel.HTML.Text := aArray[0];
  divText.HTML.Text  := aArray[1];
  await(datenmodul.easyExec('Update MEDIEN Set Titel = ''' + aArray[0] + ''', Text = ''' + aArray[1] + ''' where ID = ' + FMedienID.ToString));
  await(SetComments(FMedienID.ToString));
end;

procedure TfrmSlider.btnCloseClick(Sender: TObject);
begin
  close;
end;

class function TfrmSlider.Videos(AHolder, AMe, AAlbumID, APostID, ABlogID, AKursID, AStartID:integer; ACallback:TCallback; AAddImage:integer): integer;

  [async] procedure AfterCreate(AForm: TObject); async;
  Var
    Response: TXDataClientResponse;
    s:string;
    J:TJ;
  begin
    TfrmSlider(AForm).ElementHandle.style.removeProperty('position');
    TfrmSlider(AForm).Parent   := Mainform;
    TfrmSlider(AForm).FHolder  := AHolder;
    TfrmSlider(AForm).FMe      := AMe;
    TfrmSlider(AForm).FAlbumID := AAlbumID;
    TfrmSlider(AForm).FPostID  := APostID;
    TfrmSlider(AForm).FBlogID  := ABlogID;
    TfrmSlider(AForm).FKursID  := AKursID;
    TfrmSlider(AForm).divYoutube.Visible := true;
    TfrmSlider(AForm).frVideoliste1.img.elementhandle.style.setProperty('filter', 'invert(1)');
    TfrmSlider(AForm).frVideoliste1.Init(mainform.Holder.ID, mainform.ME.ID,  AAlbumID, APostID, ABlogID, AKursID, AStartID, 1, @TfrmSlider(AForm).SetVideo, true, AAddImage);
    TfrmSlider(AForm).divVideos.Visible  := true;
  end;

Var
  frm:TfrmSlider;
  FConti:THtmlDiv;

begin

  FConti := THtmlDiv.Create(Mainform);
  FConti.Parent := Mainform;
  FConti.ElementClassName := 'editor_transparent';
  FConti.HeightStyle:= ssAuto;
  FConti.WidthStyle := ssAuto;
  FConti.ElementFont := efCSS;
  FConti.ElementHandle.style.removeProperty('height');

  frm := TfrmSlider.CreateNew(FConti.ElementID, @AfterCreate);
  frm.FContainer := FConti;
  frm.FCallback  := ACallback;

end;

procedure TfrmSlider.divNameClick(Sender: TObject);
begin
  if await(Frage('Galerie schließen und zu <b>' +  THtmlDiv(sender).HTML.Text +  's</b> Seite wechseln?')) = mrYes
  then begin
//    mainform.HolderID := TWebHtmlDiv(sender).Tag;
    close;
    openurl(Mainform.ME.ID.ToString, THtmlDiv(sender).Tag.ToString, _call_ME,  '1','0');
  end;
end;

procedure TfrmSlider.divTitelClick(Sender: TObject);
var
  a:array[0..1] of TFieldCollection;
  sArt:string;
begin

  sArt := IIF(frFotosliste1.visible, 'Foto', 'Video');

  a[0].Caption := sArt + '-Name'; a[0].Text := lblMedienTitel.HTML.Text; a[0].Required:= true;  a[0].Typ := 0; a[0].max_length := 50;
  a[1].Caption := 'Beschreibung'; a[1].Text := divText.HTML.Text;        a[1].Required:= false; a[1].Typ := 0;

  TfrmEditor.Exec('Gib uns ein paar Infos zu deinem ' + sArt, 'Fertig...', a, @CallbackEdit);
end;


procedure TfrmSlider.CommentCallBack(s:string);
begin
  SetComments(FMedienID.ToString);
end;

procedure TfrmSlider.SetComments(ACommentID:string);

  function GetCommentByID(AOwner:TComponent; AID:integer):TfrComments;
  //sucht "TfrComments" auf dem "AOwner" und gibt TfrComments zurück
  //indem der Tag (wird beim erzeugen von TfrComments zugewiesen)
  //von TfrmComments mit der ID verglichen wird
  var
    i:integer;
  begin
    result := nil;

    for i := 0 to AOwner.ComponentCount -1 do
    begin
      if (AOwner.Components[i] is TfrComments) and
         (TfrComments(AOwner.Components[i]).ID = AId )
      then begin
        result := TfrComments(AOwner.Components[i]);
        Exit;
      end;
    end;

  end;

  procedure DeleteAllComments;
  //Alle Comments löschen
  Var
    i:integer;
    o:TfrComments;
  begin
//    mTitel.text := '';
    for i := self.ComponentCount -1 downto 0 do
    begin
      if self.Components[i] is TfrComments
      then begin
        o := TfrComments(self.Components[i]);
        o.Free;
      end;
    end;
  end;

Var
  Response : TXDataClientResponse;
  J:TJ;
  i, iID:integer;
  last,
  fCom:TfrComments;
  el : TJSHTMLElement;
  lFirst:boolean;
  sText,
  s1:string;
begin
  //alle alten Anzeige-Comments löschen...
  LblMedienTitel.HTML.Text  := '';
  divText.HTML.Text   := '';

  if assigned(FLinklbl)
  then freeAndNil(FLinkLbl);

  DeleteAllComments;
  j := TJ.create(await(datenmodul.EasySQL('Select HOLDER, NICKNAME, TITEL, TEXT, Medien.DATUM, USER.HOLDER_ID from Medien ' +
                 ' left join User on medien.holder = user.id where Medien.ID = ' + ACommentID )));

  LblMedienTitel.HTML.Text  := J.Value('TITEL');
  divText.HTML.Text   := J.Value('TEXT');
  divName.HTML.Text   := J.Value('NICKNAME');
  divName.Tag         := J.Integer('HOLDER');
  divDatum.HTML.Text  := J.value('DATUM');

  btnNew.Visible := J.Integer('HOLDER') = mainform.ME.ID;
  btnDel.Visible := ((J.Integer('HOLDER') = mainform.ME.ID) or //entweder das Bild gehört mir oder
                     (J.Integer('HOLDER_ID') = mainform.ME.ID)); // es gehört einer Gruppe die mir gehört

//                     showmessage( J.value('HOLDER') + ' - ' +
//                                  J.value('HOLDER_ID') + ' - ' +
//                                  mainform.ME.ID.tostring);
//  s1 := 'a';
//  if j.Value('NICKNAME') > ''
////  then s1 := 'Erstellt von: <a href=''USER=' + J.Value('HOLDER') +  '''>' + '' + '</a>';
//  then s1 := 'Erstellt von: <a href="USER=' + J.Value('HOLDER') +  '" >' + J.Value('NICKNAME') + '</a>';
//  showmessage(s1);
//  FLinklbl.Caption := s1;

  j.response := await(datenmodul.EasySQL('Select HOLDER_ID from medien2holder where MEDIEN_ID = ' + ACommentID + ' AND HOLDER_TYP = ' + _HT_Media));

  //Alle Kommentare zum Foto holen (Holder_ID entspricht der Post_ID)
  ACommentID := J.value('HOLDER_ID');
  if ACommentID > ''
  then begin
    j.response:= await(datenmodul.EasySQL('Select post.*, u.NICKNAME from POST join User u on (post.user_id = u.ID ) where POST_ID = ' + ACommentID));

    //...neue Comments erzeugen
    lFirst := false; //true; //erster Eintrag wird anders behandelt da es sich um den "Titel" handelt (btn anzeigen usw.);

    for i := 0 to j.length-1 do
    begin

      j.Index := i;
      last := GetCommentByID(Self, j.integer('VOR'));

      if j.Value('TEXT') > ''
      then sText := '<a href="USER=' + J.Value('USER_ID') +  '">' + J.Value('NICKNAME') + '</a> meint: ' + j.value('TEXT')
      else sText := '';

      if Last = nil
      then TfrComments.Add(divComments, divComments,      FHolder, FMe, j.integer('POST_ID'), j.integer('ID'), j.integer('VOR'), sText, lFirst, @CommentCallback)
      else TfrComments.Add(divComments, last.divComments, FHolder, FMe, j.integer('POST_ID'), j.integer('ID'), j.integer('VOR'), sText, lFirst, @CommentCallback);
      lFirst := false;

    end;
  end;

end;

procedure TfrmSlider.SetFoto(s:string);
//wird von Fotosliste augerufen wenn ein Foto angeklickt wurde
Var
  j:TJ;
  i:integer;
  lRefresh :boolean;
begin
  FMedienID := s.ToInteger;
  Mainform.Wait := 1;
  J := TJ.create(await(datenmodul.easySQL('Select HOLDER from Medien where ID = ' + s)));

  if FKursID = 0 //wenn kein Kurs dann Fotos evtl. verschwommen anzeigen
  then Mainform.SetBlur(imgBig, j.Integer('HOLDER'), Mainform.ME.HideFotos);

  imgBig.URL := datenmodul.Connection.URL + '/DBService/GetImage?Mediaid='+ s +'&Size=1';

  //Kommentare anzeigen
  await(SetComments(s));

  if (s.tointeger > 0) and (assigned(FCallback))
  then begin
    await(FCallback(s));

    lRefresh := true;
    frFotosliste1.Start := s.ToInteger;

    for i := 0 to frFotosliste1.ThumbsCount -1 do
      if frFotosliste1.Thumbs[i].Id = s.ToInteger
      then begin
        lRefresh := false;
        break;
      end;

    if lRefresh
    then frFotosliste1.Refresh;
  end;

  Mainform.Wait := -1;
end;

procedure TfrmSlider.SetVideo(s:string);
var
  j : TJ;
begin

  FMedienID := s.ToInteger;
  Mainform.Wait := 1;
  imgBig.visible := false;

  J := TJ.create(await(datenmodul.easySQL('Select HOLDER from Medien where ID = ' + s)));
  Mainform.SetBlur(divYouTube, j.Integer('HOLDER'), Mainform.ME.HideFotos);

//  s := await(datenmodul.GetMedia(s.ToInteger, _SIZE1));
  j.response := await(datenmodul.EasySQL('SELECT SIZE2 from Medien where ID = ' + s));
  s := j.value('SIZE2');

  if s > ''
  then begin

    s := s + '?autoplay=1&rel=0&controls=1';

    divYoutube.HTML.Text := '<iframe width="auto" height="auto"' + ' src="' + s + '" title="' + 'Titel' +
                            ' frameborder="0"; autoplay="1"; allow="accelerometer; clipboard-write; encrypted-media; gyroscope; ' +
                            ' picture-in-picture" allowfullscreen></iframe>';

    divYoutube.visible := true;
    Mainform.SetBlur(divYoutube,0, Mainform.ME.Rechte.Blur[TRechteIdx(ord(riTutorials))]);
    await(SetComments(FMedienID.ToString));
  end;
  Mainform.Wait := -1;
end;

procedure TfrmSlider.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  FContainer.free;
end;

procedure TfrmSlider.WebFormCreate(Sender: TObject);
begin
  TControl(self).elementfont := efCSS;
end;

procedure TfrmSlider.lblNameLinkClick(Sender: TObject; Link: string;
  LinkType: TSysLinkType);
begin
//showmessage(link);
end;

procedure TfrmSlider.lblNickNameLinkClick(Sender: TObject; Link: string; LinkType: TSysLinkType);
Var s:string;
begin
  showmessage(link);
  s := copy(link,pos('=',link) + 1);
  if await(Frage('Bildergalerie schließen und zur Seite des Mitglieds wechseln?')) = mrYes
  then begin
    mainform.HolderID := s.ToInteger;
//    close;
  end;

end;

procedure TfrmSlider.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  WebHTMLDiv11 := THTMLDiv.Create(Self);
  btnClose := TImageControl.Create(Self);
  lblTitel := TLabel.Create(Self);
  divCenter := THTMLDiv.Create(Self);
  imgBig := TImageControl.Create(Self);
  divYoutube := THTMLDiv.Create(Self);
  WebHTMLDiv4 := THTMLDiv.Create(Self);
  divComments := THTMLDiv.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  divTitel := THTMLDiv.Create(Self);
  lblMedienTitel := THTMLDiv.Create(Self);
  WebHTMLDiv6 := THTMLDiv.Create(Self);
  btnNew := TImageControl.Create(Self);
  btnDel := TImageControl.Create(Self);
  divText := THTMLDiv.Create(Self);
  divNickname := THTMLDiv.Create(Self);
  lblNickName := TLinkLabel.Create(Self);
  WebHTMLDiv3 := THTMLDiv.Create(Self);
  WebHTMLDiv5 := THTMLDiv.Create(Self);
  divName := THTMLDiv.Create(Self);
  WebHTMLDiv7 := THTMLDiv.Create(Self);
  WebHTMLDiv8 := THTMLDiv.Create(Self);
  divDatum := THTMLDiv.Create(Self);
  divFotos := THTMLDiv.Create(Self);
  frFotosliste1 := TfrFotosliste.Create(Self);
  divVideos := THTMLDiv.Create(Self);
  frVideoListe1 := TfrVideoListe.Create(Self);
  Client := TXDataWebClient.Create(Self);

  frFotosliste1.LoadFromForm;
  frVideoListe1.LoadFromForm;
  WebHTMLDiv1.BeforeLoadDFMValues;
  WebHTMLDiv11.BeforeLoadDFMValues;
  btnClose.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  divCenter.BeforeLoadDFMValues;
  imgBig.BeforeLoadDFMValues;
  divYoutube.BeforeLoadDFMValues;
  WebHTMLDiv4.BeforeLoadDFMValues;
  divComments.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  divTitel.BeforeLoadDFMValues;
  lblMedienTitel.BeforeLoadDFMValues;
  WebHTMLDiv6.BeforeLoadDFMValues;
  btnNew.BeforeLoadDFMValues;
  btnDel.BeforeLoadDFMValues;
  divText.BeforeLoadDFMValues;
  divNickname.BeforeLoadDFMValues;
  lblNickName.BeforeLoadDFMValues;
  WebHTMLDiv3.BeforeLoadDFMValues;
  WebHTMLDiv5.BeforeLoadDFMValues;
  divName.BeforeLoadDFMValues;
  WebHTMLDiv7.BeforeLoadDFMValues;
  WebHTMLDiv8.BeforeLoadDFMValues;
  divDatum.BeforeLoadDFMValues;
  divFotos.BeforeLoadDFMValues;
  frFotosliste1.BeforeLoadDFMValues;
  frFotosliste1.WebHTMLDiv1.BeforeLoadDFMValues;
  frFotosliste1.divWrapper.BeforeLoadDFMValues;
  frFotosliste1.img.BeforeLoadDFMValues;
  divVideos.BeforeLoadDFMValues;
  frVideoListe1.BeforeLoadDFMValues;
  frVideoListe1.WebHTMLDiv1.BeforeLoadDFMValues;
  frVideoListe1.divWrapper.BeforeLoadDFMValues;
  frVideoListe1.img.BeforeLoadDFMValues;
  frVideoListe1.Client.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frmSlider';
    Width := 894;
    Height := 830;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 8;
    WebHTMLDiv1.Top := 8;
    WebHTMLDiv1.Width := 873;
    WebHTMLDiv1.Height := 809;
    WebHTMLDiv1.ElementClassName := 'slider-container';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv11.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv11.Name := 'WebHTMLDiv11';
    WebHTMLDiv11.Left := 19;
    WebHTMLDiv11.Top := 3;
    WebHTMLDiv11.Width := 838;
    WebHTMLDiv11.Height := 41;
    WebHTMLDiv11.ElementClassName := 'slider-header';
    WebHTMLDiv11.HeightStyle := ssAuto;
    WebHTMLDiv11.WidthStyle := ssAuto;
    WebHTMLDiv11.ChildOrder := 1;
    WebHTMLDiv11.ElementPosition := epRelative;
    WebHTMLDiv11.ElementFont := efCSS;
    WebHTMLDiv11.Role := '';
    btnClose.SetParentComponent(WebHTMLDiv11);
    btnClose.Name := 'btnClose';
    btnClose.Left := 776;
    btnClose.Top := -3;
    btnClose.Width := 46;
    btnClose.Height := 44;
    btnClose.ElementClassName := 'slider_close';
    btnClose.HeightStyle := ssAuto;
    btnClose.WidthStyle := ssAuto;
    btnClose.HeightPercent := 100.000000000000000000;
    btnClose.WidthPercent := 100.000000000000000000;
    btnClose.Anchors := [];
    btnClose.ChildOrder := 1;
    btnClose.ElementFont := efCSS;
    btnClose.ElementPosition := epIgnore;
    SetEvent(btnClose, Self, 'OnClick', 'btnCloseClick');
    btnClose.Picture.LoadFromFile('uSlider.WebHTMLDiv11.btnClose.Picture.png');
    lblTitel.SetParentComponent(WebHTMLDiv11);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 16;
    lblTitel.Top := 9;
    lblTitel.Width := 35;
    lblTitel.Height := 15;
    lblTitel.Caption := 'lblTitel';
    lblTitel.ElementLabelClassName := 'editor_titel';
    lblTitel.ElementFont := efCSS;
    lblTitel.HeightStyle := ssAuto;
    lblTitel.HeightPercent := 100.000000000000000000;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.WidthPercent := 100.000000000000000000;
    divCenter.SetParentComponent(WebHTMLDiv1);
    divCenter.Name := 'divCenter';
    divCenter.Left := 19;
    divCenter.Top := 69;
    divCenter.Width := 838;
    divCenter.Height := 389;
    divCenter.ElementClassName := 'slider-center';
    divCenter.HeightStyle := ssAuto;
    divCenter.WidthStyle := ssAuto;
    divCenter.ChildOrder := 1;
    divCenter.ElementPosition := epIgnore;
    divCenter.ElementFont := efCSS;
    divCenter.Role := '';
    imgBig.SetParentComponent(divCenter);
    imgBig.Name := 'imgBig';
    imgBig.Left := 3;
    imgBig.Top := 3;
    imgBig.Width := 223;
    imgBig.Height := 118;
    imgBig.ElementClassName := 'slider-big';
    imgBig.HeightStyle := ssAuto;
    imgBig.WidthStyle := ssAuto;
    imgBig.HeightPercent := 100.000000000000000000;
    imgBig.WidthPercent := 100.000000000000000000;
    imgBig.ElementFont := efCSS;
    imgBig.ElementPosition := epIgnore;
    divYoutube.SetParentComponent(divCenter);
    divYoutube.Name := 'divYoutube';
    divYoutube.Left := 3;
    divYoutube.Top := 127;
    divYoutube.Width := 165;
    divYoutube.Height := 121;
    divYoutube.ElementClassName := 'youtube';
    divYoutube.HeightStyle := ssAuto;
    divYoutube.WidthStyle := ssAuto;
    divYoutube.ChildOrder := 1;
    divYoutube.ElementPosition := epIgnore;
    divYoutube.ElementFont := efCSS;
    divYoutube.Role := '';
    divYoutube.Visible := False;
    WebHTMLDiv4.SetParentComponent(divCenter);
    WebHTMLDiv4.Name := 'WebHTMLDiv4';
    WebHTMLDiv4.Left := 424;
    WebHTMLDiv4.Top := 19;
    WebHTMLDiv4.Width := 411;
    WebHTMLDiv4.Height := 358;
    WebHTMLDiv4.ElementClassName := 'slider-text-container';
    WebHTMLDiv4.HeightStyle := ssAuto;
    WebHTMLDiv4.WidthStyle := ssAuto;
    WebHTMLDiv4.ChildOrder := 2;
    WebHTMLDiv4.ElementPosition := epIgnore;
    WebHTMLDiv4.ElementFont := efCSS;
    WebHTMLDiv4.Role := '';
    divComments.SetParentComponent(WebHTMLDiv4);
    divComments.Name := 'divComments';
    divComments.Left := 24;
    divComments.Top := 304;
    divComments.Width := 369;
    divComments.Height := 33;
    divComments.HeightStyle := ssAuto;
    divComments.WidthStyle := ssAuto;
    divComments.ChildOrder := 1;
    divComments.ElementPosition := epIgnore;
    divComments.ElementFont := efCSS;
    divComments.Role := '';
    WebHTMLDiv2.SetParentComponent(WebHTMLDiv4);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 24;
    WebHTMLDiv2.Top := 17;
    WebHTMLDiv2.Width := 369;
    WebHTMLDiv2.Height := 264;
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ElementPosition := epIgnore;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.Role := '';
    divTitel.SetParentComponent(WebHTMLDiv2);
    divTitel.Name := 'divTitel';
    divTitel.Left := 3;
    divTitel.Top := 3;
    divTitel.Width := 350;
    divTitel.Height := 41;
    divTitel.ElementClassName := 'slider-titel';
    divTitel.HeightStyle := ssAuto;
    divTitel.WidthStyle := ssAuto;
    divTitel.ElementPosition := epRelative;
    divTitel.ElementFont := efCSS;
    divTitel.Role := '';
    lblMedienTitel.SetParentComponent(divTitel);
    lblMedienTitel.Name := 'lblMedienTitel';
    lblMedienTitel.Left := 0;
    lblMedienTitel.Top := 3;
    lblMedienTitel.Width := 100;
    lblMedienTitel.Height := 35;
    lblMedienTitel.HeightStyle := ssAuto;
    lblMedienTitel.WidthStyle := ssAuto;
    lblMedienTitel.ElementPosition := epIgnore;
    lblMedienTitel.ElementFont := efCSS;
    lblMedienTitel.Role := '';
    WebHTMLDiv6.SetParentComponent(divTitel);
    WebHTMLDiv6.Name := 'WebHTMLDiv6';
    WebHTMLDiv6.Left := 200;
    WebHTMLDiv6.Top := 3;
    WebHTMLDiv6.Width := 147;
    WebHTMLDiv6.Height := 41;
    WebHTMLDiv6.ElementClassName := 'pos-right';
    WebHTMLDiv6.HeightStyle := ssAuto;
    WebHTMLDiv6.WidthStyle := ssAuto;
    WebHTMLDiv6.ChildOrder := 1;
    WebHTMLDiv6.ElementPosition := epIgnore;
    WebHTMLDiv6.ElementFont := efCSS;
    WebHTMLDiv6.Role := '';
    btnNew.SetParentComponent(WebHTMLDiv6);
    btnNew.Name := 'btnNew';
    btnNew.Left := 18;
    btnNew.Top := 3;
    btnNew.Width := 38;
    btnNew.Height := 44;
    btnNew.ElementClassName := 'comment_menu_btn';
    btnNew.HeightStyle := ssAuto;
    btnNew.WidthStyle := ssAuto;
    btnNew.HeightPercent := 100.000000000000000000;
    btnNew.WidthPercent := 100.000000000000000000;
    btnNew.Anchors := [];
    btnNew.ChildOrder := 1;
    btnNew.ElementFont := efCSS;
    btnNew.ElementPosition := epIgnore;
    btnNew.Visible := False;
    SetEvent(btnNew, Self, 'OnClick', 'divTitelClick');
    btnNew.Picture.LoadFromFile('uSlider.WebHTMLDiv6.btnNew.Picture.png');
    btnDel.SetParentComponent(WebHTMLDiv6);
    btnDel.Name := 'btnDel';
    btnDel.Left := 98;
    btnDel.Top := -6;
    btnDel.Width := 46;
    btnDel.Height := 44;
    btnDel.ElementClassName := 'comment_menu_btn';
    btnDel.HeightStyle := ssAuto;
    btnDel.WidthStyle := ssAuto;
    btnDel.HeightPercent := 100.000000000000000000;
    btnDel.WidthPercent := 100.000000000000000000;
    btnDel.Anchors := [];
    btnDel.ChildOrder := 1;
    btnDel.ElementFont := efCSS;
    btnDel.ElementPosition := epIgnore;
    btnDel.Visible := False;
    SetEvent(btnDel, Self, 'OnClick', 'btnDelClick');
    btnDel.Picture.LoadFromFile('uSlider.WebHTMLDiv6.btnDel.Picture.png');
    divText.SetParentComponent(WebHTMLDiv2);
    divText.Name := 'divText';
    divText.Left := 3;
    divText.Top := 50;
    divText.Width := 350;
    divText.Height := 41;
    divText.ElementClassName := 'slider-titel';
    divText.HeightStyle := ssAuto;
    divText.WidthStyle := ssAuto;
    divText.ChildOrder := 1;
    divText.ElementPosition := epRelative;
    divText.ElementFont := efCSS;
    divText.Role := '';
    divNickname.SetParentComponent(WebHTMLDiv2);
    divNickname.Name := 'divNickname';
    divNickname.Left := 3;
    divNickname.Top := 97;
    divNickname.Width := 350;
    divNickname.Height := 41;
    divNickname.ElementClassName := 'slider-titel';
    divNickname.HeightStyle := ssAuto;
    divNickname.WidthStyle := ssAuto;
    divNickname.ChildOrder := 2;
    divNickname.ElementPosition := epRelative;
    divNickname.ElementFont := efCSS;
    divNickname.Role := '';
    divNickname.Visible := False;
    lblNickName.SetParentComponent(divNickname);
    lblNickName.Name := 'lblNickName';
    lblNickName.Left := 11;
    lblNickName.Top := 13;
    lblNickName.Width := 44;
    lblNickName.Height := 15;
    lblNickName.ElementClassName := 'slider-nickname';
    lblNickName.ElementFont := efCSS;
    lblNickName.HeightStyle := ssAuto;
    lblNickName.WidthStyle := ssAuto;
    lblNickName.HeightPercent := 100.000000000000000000;
    lblNickName.WidthPercent := 100.000000000000000000;
    SetEvent(lblNickName, Self, 'OnLinkClick', 'lblNickNameLinkClick');
    lblNickName.Caption := 'linklabel';
    WebHTMLDiv3.SetParentComponent(WebHTMLDiv2);
    WebHTMLDiv3.Name := 'WebHTMLDiv3';
    WebHTMLDiv3.Left := 3;
    WebHTMLDiv3.Top := 144;
    WebHTMLDiv3.Width := 350;
    WebHTMLDiv3.Height := 49;
    WebHTMLDiv3.ElementClassName := 'slider-titel-bottomline';
    WebHTMLDiv3.HeightStyle := ssAuto;
    WebHTMLDiv3.WidthStyle := ssAuto;
    WebHTMLDiv3.ChildOrder := 3;
    WebHTMLDiv3.ElementPosition := epRelative;
    WebHTMLDiv3.ElementFont := efCSS;
    WebHTMLDiv3.Role := '';
    WebHTMLDiv5.SetParentComponent(WebHTMLDiv3);
    WebHTMLDiv5.Name := 'WebHTMLDiv5';
    WebHTMLDiv5.Left := 3;
    WebHTMLDiv5.Top := 3;
    WebHTMLDiv5.Width := 100;
    WebHTMLDiv5.Height := 41;
    WebHTMLDiv5.HeightStyle := ssAuto;
    WebHTMLDiv5.WidthStyle := ssAuto;
    WebHTMLDiv5.ElementPosition := epIgnore;
    WebHTMLDiv5.ElementFont := efCSS;
    WebHTMLDiv5.HTML.BeginUpdate;
    try
      WebHTMLDiv5.HTML.Clear;
      WebHTMLDiv5.HTML.Add('Erstellt von: ');
    finally
      WebHTMLDiv5.HTML.EndUpdate;
    end;
    WebHTMLDiv5.Role := '';
    divName.SetParentComponent(WebHTMLDiv3);
    divName.Name := 'divName';
    divName.Left := 114;
    divName.Top := 3;
    divName.Width := 100;
    divName.Height := 41;
    divName.ElementClassName := 'slider-erstellt';
    divName.HeightStyle := ssAuto;
    divName.WidthStyle := ssAuto;
    divName.ChildOrder := 1;
    divName.ElementPosition := epIgnore;
    divName.ElementFont := efCSS;
    divName.Role := '';
    SetEvent(divName, Self, 'OnClick', 'divNameClick');
    WebHTMLDiv7.SetParentComponent(WebHTMLDiv2);
    WebHTMLDiv7.Name := 'WebHTMLDiv7';
    WebHTMLDiv7.Left := 3;
    WebHTMLDiv7.Top := 199;
    WebHTMLDiv7.Width := 350;
    WebHTMLDiv7.Height := 49;
    WebHTMLDiv7.ElementClassName := 'slider-titel-bottomline';
    WebHTMLDiv7.HeightStyle := ssAuto;
    WebHTMLDiv7.WidthStyle := ssAuto;
    WebHTMLDiv7.ChildOrder := 4;
    WebHTMLDiv7.ElementPosition := epRelative;
    WebHTMLDiv7.ElementFont := efCSS;
    WebHTMLDiv7.Role := '';
    WebHTMLDiv8.SetParentComponent(WebHTMLDiv7);
    WebHTMLDiv8.Name := 'WebHTMLDiv8';
    WebHTMLDiv8.Left := 3;
    WebHTMLDiv8.Top := 3;
    WebHTMLDiv8.Width := 100;
    WebHTMLDiv8.Height := 41;
    WebHTMLDiv8.HeightStyle := ssAuto;
    WebHTMLDiv8.WidthStyle := ssAuto;
    WebHTMLDiv8.ElementPosition := epIgnore;
    WebHTMLDiv8.ElementFont := efCSS;
    WebHTMLDiv8.HTML.BeginUpdate;
    try
      WebHTMLDiv8.HTML.Clear;
      WebHTMLDiv8.HTML.Add('Erstellt am: ');
    finally
      WebHTMLDiv8.HTML.EndUpdate;
    end;
    WebHTMLDiv8.Role := '';
    divDatum.SetParentComponent(WebHTMLDiv7);
    divDatum.Name := 'divDatum';
    divDatum.Left := 114;
    divDatum.Top := 3;
    divDatum.Width := 100;
    divDatum.Height := 41;
    divDatum.ElementClassName := 'slider-erstellt';
    divDatum.HeightStyle := ssAuto;
    divDatum.WidthStyle := ssAuto;
    divDatum.ChildOrder := 1;
    divDatum.ElementPosition := epIgnore;
    divDatum.ElementFont := efCSS;
    divDatum.Role := '';
    divFotos.SetParentComponent(WebHTMLDiv1);
    divFotos.Name := 'divFotos';
    divFotos.Left := 19;
    divFotos.Top := 631;
    divFotos.Width := 838;
    divFotos.Height := 156;
    divFotos.HeightStyle := ssAuto;
    divFotos.WidthStyle := ssAuto;
    divFotos.ChildOrder := 2;
    divFotos.ElementPosition := epIgnore;
    divFotos.ElementFont := efCSS;
    divFotos.Role := '';
    divFotos.Visible := False;
    frFotosliste1.SetParentComponent(divFotos);
    frFotosliste1.Name := 'frFotosliste1';
    frFotosliste1.Left := 27;
    frFotosliste1.Top := 0;
    frFotosliste1.Width := 230;
    frFotosliste1.Height := 145;
    frFotosliste1.Margins.Left := 0;
    frFotosliste1.Margins.Top := 0;
    frFotosliste1.Margins.Right := 0;
    frFotosliste1.Margins.Bottom := 0;
    frFotosliste1.TabOrder := 0;
    frFotosliste1.WebHTMLDiv1.Top := 16;
    frFotosliste1.WebHTMLDiv1.Width := 206;
    frFotosliste1.WebHTMLDiv1.Height := 121;
    frFotosliste1.divWrapper.Left := 11;
    frFotosliste1.divWrapper.Top := 10;
    frFotosliste1.divWrapper.Width := 182;
    frFotosliste1.divWrapper.Height := 103;
    frFotosliste1.img.Top := 4;
    divVideos.SetParentComponent(WebHTMLDiv1);
    divVideos.Name := 'divVideos';
    divVideos.Left := 19;
    divVideos.Top := 464;
    divVideos.Width := 838;
    divVideos.Height := 161;
    divVideos.HeightStyle := ssAuto;
    divVideos.WidthStyle := ssAuto;
    divVideos.ChildOrder := 4;
    divVideos.ElementPosition := epIgnore;
    divVideos.ElementFont := efCSS;
    divVideos.Role := '';
    divVideos.Visible := False;
    frVideoListe1.SetParentComponent(divVideos);
    frVideoListe1.Name := 'frVideoListe1';
    frVideoListe1.Left := 16;
    frVideoListe1.Top := 21;
    frVideoListe1.Width := 227;
    frVideoListe1.Height := 124;
    frVideoListe1.Margins.Left := 0;
    frVideoListe1.Margins.Top := 0;
    frVideoListe1.Margins.Right := 0;
    frVideoListe1.Margins.Bottom := 0;
    frVideoListe1.TabOrder := 0;
    frVideoListe1.WebHTMLDiv1.Width := 201;
    frVideoListe1.WebHTMLDiv1.Height := 121;
    frVideoListe1.divWrapper.Left := 8;
    frVideoListe1.divWrapper.Top := 8;
    frVideoListe1.divWrapper.Width := 169;
    frVideoListe1.img.Left := 30;
    frVideoListe1.img.Top := -8;
    frVideoListe1.Client.Left := 16;
    frVideoListe1.Client.Top := 16;
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 56;
    Client.Top := 112;
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    WebHTMLDiv11.AfterLoadDFMValues;
    btnClose.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    divCenter.AfterLoadDFMValues;
    imgBig.AfterLoadDFMValues;
    divYoutube.AfterLoadDFMValues;
    WebHTMLDiv4.AfterLoadDFMValues;
    divComments.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    divTitel.AfterLoadDFMValues;
    lblMedienTitel.AfterLoadDFMValues;
    WebHTMLDiv6.AfterLoadDFMValues;
    btnNew.AfterLoadDFMValues;
    btnDel.AfterLoadDFMValues;
    divText.AfterLoadDFMValues;
    divNickname.AfterLoadDFMValues;
    lblNickName.AfterLoadDFMValues;
    WebHTMLDiv3.AfterLoadDFMValues;
    WebHTMLDiv5.AfterLoadDFMValues;
    divName.AfterLoadDFMValues;
    WebHTMLDiv7.AfterLoadDFMValues;
    WebHTMLDiv8.AfterLoadDFMValues;
    divDatum.AfterLoadDFMValues;
    divFotos.AfterLoadDFMValues;
    frFotosliste1.AfterLoadDFMValues;
    frFotosliste1.WebHTMLDiv1.AfterLoadDFMValues;
    frFotosliste1.divWrapper.AfterLoadDFMValues;
    frFotosliste1.img.AfterLoadDFMValues;
    divVideos.AfterLoadDFMValues;
    frVideoListe1.AfterLoadDFMValues;
    frVideoListe1.WebHTMLDiv1.AfterLoadDFMValues;
    frVideoListe1.divWrapper.AfterLoadDFMValues;
    frVideoListe1.img.AfterLoadDFMValues;
    frVideoListe1.Client.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.

