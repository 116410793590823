unit ufrEventsNeuste;

interface

uses
  System.SysUtils, System.Variants, System.Classes,JS,Web,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.Imaging.jpeg,
  WEBLib.Controls, WEBLib.ExtCtrls, WEBLib.WebCtrls, Data.DB, uTB, uGlobal,
  XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Client, WEBLib.CDS,
  WEBLib.Graphics, WEBLib.Forms, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.Lists;

type
  TfrEventsNeuste = class(TFrame)
    divWrapper: THTMLDiv;
    WebHTMLDiv2: THTMLDiv;
    Client: TXDataWebClient;
    divContent: THTMLDiv;
    wl: TListControl;
    [async]
    procedure wlItemClick(Sender: TObject; AListItem: TListItem); async;
  private
  protected
    procedure Loaded(); override;
  public
    public constructor Create(AOwner:TComponent);Override;
    [async] procedure Init; async;
  protected procedure LoadDFMValues; override; end;

implementation
   Uses uMain, uDatenmodul, uBlog;
{$R *.dfm}

constructor TfrEventsNeuste.Create(AOwner: TComponent);
begin
  inherited;
end;

procedure TfrEventsNeuste.Init;
Var
  Response: TXDataClientResponse;
  i, id:integer;
  img:TImageControl;
  J:TJ;
  client1 : TXDataWebClient;
  d:THTMLDiv;
  li: TListItem;
  s:string;
begin
  client1 := TXDataWebClient.Create(self);
  client1.Connection := datenmodul.Connection;
  J := TJ.create(Await( Client.RawInvokeAsync('IDBService.UserGet',[Mainform.ME.id, _UT_Event, _FS_Alle, 6, 0,0,''])));
  client1.Free;

  divContent.ElementHandle.style.setProperty('font-size', '75%');

  wl.Items.Clear;
  for i := 0 to J.Length -1 do
  begin
    j.index := i;
    li := wl.Items.Add;

    s:= '';
    s := s + j.Text('NICKNAME');
//    if j.Text('MAINHEADER') > ''
//    then s := s + '<font size="1"> (' + j.Text('MAINHEADER') + ')</font>';
//    s := s + j.Text('HEADER');

    li.Text := s + '<br><font size="1"><font color="gray">' + j.Text('ABOUT') {+ '  -  ' + TDatum.Get(j.Value('LASTEDIT')).Zeitraum} + '</font>';
    li.Tag  := j.integer('ID');
  end;
end;

procedure TfrEventsNeuste.Loaded();
var
  el: TJSHTMLElement;
begin
  inherited;

  TControl(self).ElementPosition := epIgnore;
  TControl(self).ElementID := 'frEventsNeuste';
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.setProperty('height','');
  el.style.setProperty('width','');
  el.style.setProperty('font-family','');
  el.style.setProperty('font-style','');
  el.style.setProperty('font-size','');

  //init;
end;

procedure TfrEventsNeuste.wlItemClick(Sender: TObject; AListItem: TListItem);
begin
//meldung(AListItem.Tag.ToString);
  Mainform.HolderID := AListItem.Tag;
//  TfrmBlog.Show(_BLOGKAPITEL, AListItem.Tag, '', 0,false, Mainform.frmME);
end;

procedure TfrEventsNeuste.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divWrapper := THTMLDiv.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  divContent := THTMLDiv.Create(Self);
  wl := TListControl.Create(Self);
  Client := TXDataWebClient.Create(Self);

  divWrapper.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  divContent.BeforeLoadDFMValues;
  wl.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frEventsNeuste';
    Left := 0;
    Top := 0;
    Width := 346;
    Height := 89;
    Margins.Left := 0;
    Margins.Top := 0;
    Margins.Right := 0;
    Margins.Bottom := 0;
    TabOrder := 0;
    divWrapper.SetParentComponent(Self);
    divWrapper.Name := 'divWrapper';
    divWrapper.Left := 3;
    divWrapper.Top := 0;
    divWrapper.Width := 334;
    divWrapper.Height := 81;
    divWrapper.ElementClassName := 'me_frame_container';
    divWrapper.HeightStyle := ssAuto;
    divWrapper.WidthStyle := ssAuto;
    divWrapper.ChildOrder := 9;
    divWrapper.ElementPosition := epIgnore;
    divWrapper.ElementFont := efCSS;
    divWrapper.Role := '';
    WebHTMLDiv2.SetParentComponent(divWrapper);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 3;
    WebHTMLDiv2.Top := 3;
    WebHTMLDiv2.Width := 318;
    WebHTMLDiv2.Height := 49;
    WebHTMLDiv2.ElementClassName := 'me_headline';
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ChildOrder := 1;
    WebHTMLDiv2.ElementPosition := epRelative;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.HTML.BeginUpdate;
    try
      WebHTMLDiv2.HTML.Clear;
      WebHTMLDiv2.HTML.Add('Neueste Veranstaltungen');
      WebHTMLDiv2.HTML.Add('');
    finally
      WebHTMLDiv2.HTML.EndUpdate;
    end;
    WebHTMLDiv2.Role := '';
    divContent.SetParentComponent(divWrapper);
    divContent.Name := 'divContent';
    divContent.Left := 3;
    divContent.Top := 58;
    divContent.Width := 318;
    divContent.Height := 311;
    divContent.ElementClassName := 'me_frame';
    divContent.HeightStyle := ssAuto;
    divContent.WidthStyle := ssAuto;
    divContent.ChildOrder := 1;
    divContent.ElementPosition := epIgnore;
    divContent.ElementFont := efCSS;
    divContent.Role := '';
    wl.SetParentComponent(divContent);
    wl.Name := 'wl';
    wl.Left := 16;
    wl.Top := 16;
    wl.Width := 300;
    wl.Height := 169;
    wl.HeightStyle := ssAuto;
    wl.WidthStyle := ssAuto;
    wl.HeightPercent := 100.000000000000000000;
    wl.WidthPercent := 100.000000000000000000;
    wl.ElementFont := efCSS;
    wl.ElementPosition := epIgnore;
    wl.Items.Clear;
    with wl.Items.Add do
    begin
    end;
    SetEvent(wl, Self, 'OnItemClick', 'wlItemClick');
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 16;
    Client.Top := 12;
  finally
    divWrapper.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    divContent.AfterLoadDFMValues;
    wl.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.
