unit ufrVideosNeuste;

interface

uses
  System.SysUtils, System.Variants, System.Classes,JS,Web, uTB,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.Imaging.jpeg,
  WEBLib.Controls, WEBLib.ExtCtrls, WEBLib.WebCtrls, Data.DB, uGlobal,
  XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Client, WEBLib.CDS,
  WEBLib.Graphics, WEBLib.Forms;

type
  TfrVideosNeuste = class(TFrame)
    divWrapper: THTMLDiv;
    WebHTMLDiv2: THTMLDiv;
    Client: TXDataWebClient;
    divContent: THTMLDiv;
    img1: TImageControl;
    img2: TImageControl;
    img3: TImageControl;
    img4: TImageControl;
    img5: TImageControl;
    img6: TImageControl;
    img7: TImageControl;
    img8: TImageControl;
    img9: TImageControl;
    img10: TImageControl;
    procedure img1Click(Sender: TObject);
  private
  protected
    procedure Loaded(); override;
  public
    public constructor Create(AOwner:TComponent);Override;
    [async] procedure Init; async;
  protected procedure LoadDFMValues; override; end;

implementation
   Uses uMain, uDatenmodul, uSlider;
{$R *.dfm}

constructor TfrVideosNeuste.Create(AOwner: TComponent);
begin
  inherited;
end;

procedure TfrVideosNeuste.Init;
Var
  i :integer;
  img:TImageControl;
  J:TJ;
  s:string;
begin

  divWrapper.Visible := false;
//  J := TJ.create(TAwait.Exec<TXDataClientResponse>( Client1.RawInvokeAsync('IDBService.AlbumGetMedia',[FAlbum,0,0,0,Ord(medFoto),2,_Limit])));

  J := TJ.create(Await( Client.RawInvokeAsync('IDBService.GetNewestMedia',[medVideo, Mainform.MaxNewVideos])));

  for i := 0 to J.Length -1 do
  begin
    j.index := i;
    img := TImageControl(FindControlByName(Self, 'img' + (i+1).tostring));
    if img <> nil
    then begin
      s := await(Datenmodul.GetMedia(J.Integer('ID'), integer(medVideo)));
      img.url:= s;//GetYoutubeThumb(s);

      img.Tag := J.Integer('ID');
      img.Visible := true;

    end;
  end;
  divWrapper.Visible := true;

end;

procedure TfrVideosNeuste.Loaded();
var
  el: TJSHTMLElement;
begin
  inherited;

  TControl(self).ElementPosition := epIgnore;
  TControl(self).ElementID := 'frVideosNeuste';
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.setProperty('height','');
  el.style.setProperty('width','');
  el.style.setProperty('font-family','');
  el.style.setProperty('font-style','');
  el.style.setProperty('font-size','');

  //init;
end;

procedure TfrVideosNeuste.img1Click(Sender: TObject);
begin
  TfrmSlider.Videos(Mainform.Holder.ID, Mainform.Me.ID, _Neuste_Medien, 0, 0, 0,TImageControl(sender).tag, nil, 0);
end;

procedure TfrVideosNeuste.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divWrapper := THTMLDiv.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  divContent := THTMLDiv.Create(Self);
  img1 := TImageControl.Create(Self);
  img2 := TImageControl.Create(Self);
  img3 := TImageControl.Create(Self);
  img4 := TImageControl.Create(Self);
  img5 := TImageControl.Create(Self);
  img6 := TImageControl.Create(Self);
  img7 := TImageControl.Create(Self);
  img8 := TImageControl.Create(Self);
  img9 := TImageControl.Create(Self);
  img10 := TImageControl.Create(Self);
  Client := TXDataWebClient.Create(Self);

  divWrapper.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  divContent.BeforeLoadDFMValues;
  img1.BeforeLoadDFMValues;
  img2.BeforeLoadDFMValues;
  img3.BeforeLoadDFMValues;
  img4.BeforeLoadDFMValues;
  img5.BeforeLoadDFMValues;
  img6.BeforeLoadDFMValues;
  img7.BeforeLoadDFMValues;
  img8.BeforeLoadDFMValues;
  img9.BeforeLoadDFMValues;
  img10.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frVideosNeuste';
    Left := 0;
    Top := 0;
    Width := 354;
    Height := 543;
    Margins.Left := 0;
    Margins.Top := 0;
    Margins.Right := 0;
    Margins.Bottom := 0;
    TabOrder := 0;
    divWrapper.SetParentComponent(Self);
    divWrapper.Name := 'divWrapper';
    divWrapper.Left := 3;
    divWrapper.Top := 3;
    divWrapper.Width := 334;
    divWrapper.Height := 526;
    divWrapper.ElementClassName := 'me_frame_container';
    divWrapper.HeightStyle := ssAuto;
    divWrapper.WidthStyle := ssAuto;
    divWrapper.ChildOrder := 9;
    divWrapper.ElementPosition := epIgnore;
    divWrapper.ElementFont := efCSS;
    divWrapper.Role := '';
    WebHTMLDiv2.SetParentComponent(divWrapper);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 3;
    WebHTMLDiv2.Top := 3;
    WebHTMLDiv2.Width := 318;
    WebHTMLDiv2.Height := 49;
    WebHTMLDiv2.ElementClassName := 'me_headline';
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ChildOrder := 1;
    WebHTMLDiv2.ElementPosition := epRelative;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.HTML.BeginUpdate;
    try
      WebHTMLDiv2.HTML.Clear;
      WebHTMLDiv2.HTML.Add('Neueste Videos');
      WebHTMLDiv2.HTML.Add('');
    finally
      WebHTMLDiv2.HTML.EndUpdate;
    end;
    WebHTMLDiv2.Role := '';
    divContent.SetParentComponent(divWrapper);
    divContent.Name := 'divContent';
    divContent.Left := 3;
    divContent.Top := 58;
    divContent.Width := 318;
    divContent.Height := 447;
    divContent.ElementClassName := 'me_frame';
    divContent.HeightStyle := ssAuto;
    divContent.WidthStyle := ssAuto;
    divContent.ChildOrder := 1;
    divContent.ElementPosition := epIgnore;
    divContent.ElementFont := efCSS;
    divContent.Role := '';
    img1.SetParentComponent(divContent);
    img1.Name := 'img1';
    img1.Left := 20;
    img1.Top := 12;
    img1.Width := 88;
    img1.Height := 88;
    img1.ElementClassName := 'me_video_eckig';
    img1.HeightStyle := ssAuto;
    img1.WidthStyle := ssAuto;
    img1.HeightPercent := 100.000000000000000000;
    img1.WidthPercent := 100.000000000000000000;
    img1.ChildOrder := 1;
    img1.ElementFont := efCSS;
    img1.ElementPosition := epIgnore;
    img1.Visible := False;
    SetEvent(img1, Self, 'OnClick', 'img1Click');
    img1.Picture.LoadFromFile('ufrVideosNeuste.divContent.img1.Picture.jpg');
    img2.SetParentComponent(divContent);
    img2.Name := 'img2';
    img2.Left := 114;
    img2.Top := 12;
    img2.Width := 88;
    img2.Height := 88;
    img2.ElementClassName := 'me_video_eckig';
    img2.HeightStyle := ssAuto;
    img2.WidthStyle := ssAuto;
    img2.HeightPercent := 100.000000000000000000;
    img2.WidthPercent := 100.000000000000000000;
    img2.ChildOrder := 1;
    img2.ElementFont := efCSS;
    img2.ElementPosition := epIgnore;
    img2.Visible := False;
    SetEvent(img2, Self, 'OnClick', 'img1Click');
    img3.SetParentComponent(divContent);
    img3.Name := 'img3';
    img3.Left := 209;
    img3.Top := 12;
    img3.Width := 88;
    img3.Height := 88;
    img3.ElementClassName := 'me_video_eckig';
    img3.HeightStyle := ssAuto;
    img3.WidthStyle := ssAuto;
    img3.HeightPercent := 100.000000000000000000;
    img3.WidthPercent := 100.000000000000000000;
    img3.ChildOrder := 1;
    img3.ElementFont := efCSS;
    img3.ElementPosition := epIgnore;
    img3.Visible := False;
    SetEvent(img3, Self, 'OnClick', 'img1Click');
    img4.SetParentComponent(divContent);
    img4.Name := 'img4';
    img4.Left := 20;
    img4.Top := 106;
    img4.Width := 88;
    img4.Height := 88;
    img4.ElementClassName := 'me_video_eckig';
    img4.HeightStyle := ssAuto;
    img4.WidthStyle := ssAuto;
    img4.HeightPercent := 100.000000000000000000;
    img4.WidthPercent := 100.000000000000000000;
    img4.ChildOrder := 1;
    img4.ElementFont := efCSS;
    img4.ElementPosition := epIgnore;
    img4.Visible := False;
    SetEvent(img4, Self, 'OnClick', 'img1Click');
    img5.SetParentComponent(divContent);
    img5.Name := 'img5';
    img5.Left := 114;
    img5.Top := 106;
    img5.Width := 88;
    img5.Height := 88;
    img5.ElementClassName := 'me_video_eckig';
    img5.HeightStyle := ssAuto;
    img5.WidthStyle := ssAuto;
    img5.HeightPercent := 100.000000000000000000;
    img5.WidthPercent := 100.000000000000000000;
    img5.ChildOrder := 1;
    img5.ElementFont := efCSS;
    img5.ElementPosition := epIgnore;
    img5.Visible := False;
    SetEvent(img5, Self, 'OnClick', 'img1Click');
    img6.SetParentComponent(divContent);
    img6.Name := 'img6';
    img6.Left := 209;
    img6.Top := 106;
    img6.Width := 88;
    img6.Height := 88;
    img6.ElementClassName := 'me_video_eckig';
    img6.HeightStyle := ssAuto;
    img6.WidthStyle := ssAuto;
    img6.HeightPercent := 100.000000000000000000;
    img6.WidthPercent := 100.000000000000000000;
    img6.ChildOrder := 1;
    img6.ElementFont := efCSS;
    img6.ElementPosition := epIgnore;
    img6.Visible := False;
    SetEvent(img6, Self, 'OnClick', 'img1Click');
    img7.SetParentComponent(divContent);
    img7.Name := 'img7';
    img7.Left := 20;
    img7.Top := 201;
    img7.Width := 88;
    img7.Height := 88;
    img7.ElementClassName := 'me_video_eckig';
    img7.HeightStyle := ssAuto;
    img7.WidthStyle := ssAuto;
    img7.HeightPercent := 100.000000000000000000;
    img7.WidthPercent := 100.000000000000000000;
    img7.ChildOrder := 1;
    img7.ElementFont := efCSS;
    img7.ElementPosition := epIgnore;
    img7.Visible := False;
    SetEvent(img7, Self, 'OnClick', 'img1Click');
    img8.SetParentComponent(divContent);
    img8.Name := 'img8';
    img8.Left := 114;
    img8.Top := 201;
    img8.Width := 88;
    img8.Height := 88;
    img8.ElementClassName := 'me_video_eckig';
    img8.HeightStyle := ssAuto;
    img8.WidthStyle := ssAuto;
    img8.HeightPercent := 100.000000000000000000;
    img8.WidthPercent := 100.000000000000000000;
    img8.ChildOrder := 1;
    img8.ElementFont := efCSS;
    img8.ElementPosition := epIgnore;
    img8.Visible := False;
    SetEvent(img8, Self, 'OnClick', 'img1Click');
    img9.SetParentComponent(divContent);
    img9.Name := 'img9';
    img9.Left := 209;
    img9.Top := 201;
    img9.Width := 88;
    img9.Height := 88;
    img9.ElementClassName := 'me_video_eckig';
    img9.HeightStyle := ssAuto;
    img9.WidthStyle := ssAuto;
    img9.HeightPercent := 100.000000000000000000;
    img9.WidthPercent := 100.000000000000000000;
    img9.ChildOrder := 1;
    img9.ElementFont := efCSS;
    img9.ElementPosition := epIgnore;
    img9.Visible := False;
    SetEvent(img9, Self, 'OnClick', 'img1Click');
    img10.SetParentComponent(divContent);
    img10.Name := 'img10';
    img10.Left := 20;
    img10.Top := 295;
    img10.Width := 88;
    img10.Height := 88;
    img10.ElementClassName := 'me_video_eckig';
    img10.HeightStyle := ssAuto;
    img10.WidthStyle := ssAuto;
    img10.HeightPercent := 100.000000000000000000;
    img10.WidthPercent := 100.000000000000000000;
    img10.ChildOrder := 1;
    img10.ElementFont := efCSS;
    img10.ElementPosition := epIgnore;
    img10.Visible := False;
    SetEvent(img10, Self, 'OnClick', 'img1Click');
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 16;
    Client.Top := 12;
  finally
    divWrapper.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    divContent.AfterLoadDFMValues;
    img1.AfterLoadDFMValues;
    img2.AfterLoadDFMValues;
    img3.AfterLoadDFMValues;
    img4.AfterLoadDFMValues;
    img5.AfterLoadDFMValues;
    img6.AfterLoadDFMValues;
    img7.AfterLoadDFMValues;
    img8.AfterLoadDFMValues;
    img9.AfterLoadDFMValues;
    img10.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.
