unit ufrFreundeListe;

interface

uses
  System.SysUtils, System.Variants, System.Classes,JS, uGlobal,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.Imaging.jpeg,
  WEBLib.Controls, WEBLib.ExtCtrls, WEBLib.WebCtrls, Data.DB,
  XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Client,
  Vcl.Imaging.pngimage, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.CSS,
  Web, WEBLib.Graphics, WEBLib.ComCtrls, system.Types, uTB,
  Vcl.Menus, WEBLib.Menus, WEBLib.Dialogs, WEBLib.Forms;

type
  TfrFreundeListe = class(TFrame)
    Client: TXDataWebClient;

    divWrapper: THTMLDiv;
    [async]
    WebHTMLDiv1: THTMLDiv;
    WebHTMLDiv3: THTMLDiv;
    imgNew: TImageControl;
    divShowMore: THTMLDiv;
    btnMore: TButton;
    procedure btnMoreClick(Sender: TObject);
  protected
    procedure Loaded(); override;
  private
    FBlockSize,
    FPosition:integer;
    FStatus,
    FHolder,
    FUser:integer;
    FParentTyp,
    FParentID:integer;
    FCollapsedHeight:integer;
    procedure SetUserID(const Value: integer);
  public
    procedure Update(s:string);
    [async] procedure HeaderClick(Sender: TObject); async;
    [async] function Init(AParentTyp, AHolder, AUser, AStatus: integer; ANext:boolean = false): integer; async;
    property User: integer read FUser write FUser;
    property Holder: integer read FHolder write FHolder;
    property ParentTyp: integer read FParentTyp write FParentTyp;
    property ParentID: integer read FParentID write FParentID;
    property CollapsedHeight: integer read FCollapsedHeight write FCollapsedHeight;
  protected procedure LoadDFMValues; override; end;

const
  _Sql_Member = 'SELECT DISTINCT u.*, u.ID as USER_ID,  f.ID as F_ID, f.SENDER, f.EMPFAENGER, m1.SIZE2 as TITEL, m2.SIZE2 as AVATAR' +
                '  FROM v_user u ' +
                '  left JOIN friends f ON ((u.id = f.SENDER OR u.id = f.EMPFAENGER) AND (f.Status = 1))' +
                '  left JOIN Medien m1 on u.TITEL_MEDIEN_ID = m1.id ' +
                '  left JOIN Medien m2 on u.AVATAR_MEDIEN_ID = m2.id ' +
                ' Where u.typ = 0' + //_UT_User.ToString +
                ' Limit %d, %d';

  _Sql_Friends = 'SELECT DISTINCT u.*,  u.ID as USER_ID, f.ID as F_ID, f.SENDER, f.EMPFAENGER, m1.SIZE2 as TITEL, m2.SIZE2 as AVATAR' +
                 '  FROM friends f ' +
                 '  JOIN v_user u ON (u.id = IF(f.SENDER = :Sender, f.EMPFAENGER, f.SENDER)) :Typ ' +
                 '  left JOIN Medien m1 on u.TITEL_MEDIEN_ID = m1.id ' +
                 '  left JOIN Medien m2 on u.AVATAR_MEDIEN_ID = m2.id ' +
                 ' WHERE (f.SENDER = :Sender OR f.EMPFAENGER = :Empfaenger) AND f.status = :Status' +
                 ' Limit %d, %d';

implementation
   uses uDatenmodul, uMain, ufrFreundeThumb;
{$R *.dfm}


function TfrFreundeListe.Init(AParentTyp, AHolder, AUser, AStatus: integer; ANext:boolean = false): integer;
Var
  i:integer;
  Response: TXDataClientResponse;
  sSql, sBild:string;
  J:TJ;
  sShowID, sStatus, s, sWhere:string;
  o:TfrFreundeThumb;
  sHolder, sUser,
  sTyp:string;
begin
  mainform.Wait := 1;

  //Beim ersten Aufruf ist ANext = 0 -  bei weiteren ist aNext die Startposition ab der abgerufen werden soll
  if ANext = false
  then FPosition := 0;

  FBlockSize:= 12;
  FUser     := AUser;
  FHolder   := AHolder;
  FParentTyp:= AParentTyp;
  FStatus   := AStatus;

  if FStatus = _FS_Freunde
  then begin
    sStatus := '1';
    sTyp := '  AND (u.TYP = ' + _UT_User.tostring + ')'
  end
  else sStatus := '0';

  if FHolder > 0
  then begin
    sSQl := _Sql_Friends;
    sSQl := StringReplace(sSQl, ':Sender',FHolder.ToString, [rfReplaceAll]);
    sSQl := StringReplace(sSQl, ':Empfaenger', Mainform.ME.ID.ToString, [rfReplaceAll]);
    sSQl := StringReplace(sSQl, ':Status',sStatus, [rfReplaceAll]);
    sSQl := StringReplace(sSQl, ':Typ', sTyp, [rfReplaceAll]);
    sHolder:= 'EMPFAENGER';
    sUser  := 'USER_ID';
  end
  else begin
    sSQL := _SQL_Member;
    sUser  := 'EMPFAENGER';
    sHolder:= 'USER_ID';
  end;

  //immer einen Satz mehr holen als angezeigt werden soll.
  //dadurch feststellen ob noch weitere Datensätze vorhanden sind
  sSQl := Format(sSQl, [FPosition, FBlockSize + 1]);

  //nächste Startposition merken
  inc(FPosition, FBlockSize);

  j := TJ.create(await(datenmodul.easySQL(sSQL)));

  divShowMore.visible := (j.length > FBlockSize);

  sShowID := '';
  for i := 0 to j.length-1 do
  begin
    //den letzten Datensatz nicht anzeigen wenn noch weitere Datensätze vorhanden sind
    if i >= FBlockSize
    then break;

    j.Index := i;

    o := await(TfrFreundeThumb.Add(divWrapper, FParentTyp, J.Integer('F_ID'), J.Integer(sHolder),J.Integer(sUser), J.Integer('ALBUM_ID'), J.Integer('TYP')));

    if i = 0 //erste "Karte" merken um sie anzuzeigen
    then sShowID := o.ElementID;

    await(FillHashTags(o.divHobbies,  o.User.ToString, '', false, [],'1', false));
    await(FillHashTags(o.divReviere,  o.User.ToString, '', false, [],'2', false));
    await(FillHashTags(o.divExpertise,o.User.ToString, '', false, [],'3', false));

    o.lblUserName.HTML.Text := J.Value('NICKNAME');
    o.lblUserOrt.HTML.Text  := J.Value('ORT');
    o.lblAbout.HTML.Text    := J.Value('ABOUT');
    o.lblFotos.HTML.Text    := J.Value('FOTOS');
    o.lblVideos.HTML.Text   := J.Value('VIDEOS');
    o.lblDoks.HTML.Text     := '0';//J.Value('DOKS');
    o.imgTitel.URL          := j.value('TITEL');
    o.imgAvatar.URL         := j.Value('AVATAR');
    o.divOnline.Visible     := isOnline(J.Value('LOGOUT'));

    o.btnDelete.Visible  := (Mainform.Meins) and (AStatus = _FS_Freunde);
    o.divInquiry.Visible := (Mainform.Meins) and (AStatus = _FS_FremdeAnfragen);

    o.divAboutContainer.Visible := j.value('ABOUT') > '';

  end;
  mainform.Wait := -1;

  if sShowID > ''
  then begin
    asm
      document.getElementById(sShowID).scrollIntoView(false);
    end;
  end;

end;

procedure TfrFreundeListe.Update(s:string);
begin
end;

procedure TfrFreundeListe.SetUserID(const Value: integer);
begin
  FUser := Value;
//  init(FUserId, FAlbumID, FBlockID);
end;

procedure TfrFreundeListe.Loaded();
var
  el: TJSHTMLElement;
begin
  inherited;
  TControl(self).ElementPosition := epIgnore;
  TControl(self).ElementID := 'frFreundeListe';
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.setProperty('height','');
  el.style.setProperty('width','');
  el.style.setProperty('font-family','');
  el.style.setProperty('font-style','');
  el.style.setProperty('font-size','');
end;

procedure TfrFreundeListe.btnMoreClick(Sender: TObject);
begin
  Init(FParentTyp, FHolder, FUser, FStatus, true)
end;

procedure TfrFreundeListe.HeaderClick(Sender: TObject);
begin
end;

procedure TfrFreundeListe.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divWrapper := THTMLDiv.Create(Self);
  WebHTMLDiv1 := THTMLDiv.Create(Self);
  WebHTMLDiv3 := THTMLDiv.Create(Self);
  imgNew := TImageControl.Create(Self);
  divShowMore := THTMLDiv.Create(Self);
  btnMore := TButton.Create(Self);
  Client := TXDataWebClient.Create(Self);

  divWrapper.BeforeLoadDFMValues;
  WebHTMLDiv1.BeforeLoadDFMValues;
  WebHTMLDiv3.BeforeLoadDFMValues;
  imgNew.BeforeLoadDFMValues;
  divShowMore.BeforeLoadDFMValues;
  btnMore.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frFreundeListe';
    Left := 0;
    Top := 0;
    Width := 553;
    Height := 316;
    Margins.Left := 0;
    Margins.Top := 0;
    Margins.Right := 0;
    Margins.Bottom := 0;
    TabOrder := 0;
    divWrapper.SetParentComponent(Self);
    divWrapper.Name := 'divWrapper';
    divWrapper.Left := 3;
    divWrapper.Top := 8;
    divWrapper.Width := 534;
    divWrapper.Height := 241;
    divWrapper.ElementClassName := 'alben_content_wrapper';
    divWrapper.HeightStyle := ssAuto;
    divWrapper.WidthStyle := ssAuto;
    divWrapper.ChildOrder := 1;
    divWrapper.ElementPosition := epIgnore;
    divWrapper.ElementFont := efCSS;
    divWrapper.Role := '';
    WebHTMLDiv1.SetParentComponent(divWrapper);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 16;
    WebHTMLDiv1.Top := 16;
    WebHTMLDiv1.Width := 217;
    WebHTMLDiv1.Height := 209;
    WebHTMLDiv1.ElementClassName := 'alben_frame';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ChildOrder := 1;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv1.Visible := False;
    WebHTMLDiv3.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv3.Name := 'WebHTMLDiv3';
    WebHTMLDiv3.Left := 16;
    WebHTMLDiv3.Top := 13;
    WebHTMLDiv3.Width := 177;
    WebHTMLDiv3.Height := 180;
    WebHTMLDiv3.ElementClassName := 'alben_content';
    WebHTMLDiv3.HeightStyle := ssAuto;
    WebHTMLDiv3.WidthStyle := ssAuto;
    WebHTMLDiv3.ChildOrder := 2;
    WebHTMLDiv3.ElementPosition := epIgnore;
    WebHTMLDiv3.ElementFont := efCSS;
    WebHTMLDiv3.HTML.BeginUpdate;
    try
      WebHTMLDiv3.HTML.Clear;
      WebHTMLDiv3.HTML.Add('');
    finally
      WebHTMLDiv3.HTML.EndUpdate;
    end;
    WebHTMLDiv3.Role := '';
    imgNew.SetParentComponent(WebHTMLDiv3);
    imgNew.Name := 'imgNew';
    imgNew.Left := 35;
    imgNew.Top := 35;
    imgNew.Width := 102;
    imgNew.Height := 78;
    imgNew.ElementClassName := 'alben_foto';
    imgNew.HeightStyle := ssAuto;
    imgNew.WidthStyle := ssAuto;
    imgNew.HeightPercent := 100.000000000000000000;
    imgNew.WidthPercent := 100.000000000000000000;
    imgNew.ChildOrder := 1;
    imgNew.ElementFont := efCSS;
    imgNew.ElementPosition := epIgnore;
    imgNew.Picture.LoadFromFile('ufrFreundeListe.WebHTMLDiv3.imgNew.Picture.png');
    divShowMore.SetParentComponent(Self);
    divShowMore.Name := 'divShowMore';
    divShowMore.Left := 3;
    divShowMore.Top := 263;
    divShowMore.Width := 534;
    divShowMore.Height := 41;
    divShowMore.ElementClassName := 'editor-footer-div';
    divShowMore.HeightStyle := ssAuto;
    divShowMore.WidthStyle := ssAuto;
    divShowMore.ChildOrder := 50;
    divShowMore.ElementPosition := epIgnore;
    divShowMore.ElementFont := efCSS;
    divShowMore.Role := '';
    divShowMore.Visible := False;
    btnMore.SetParentComponent(divShowMore);
    btnMore.Name := 'btnMore';
    btnMore.Left := 16;
    btnMore.Top := 13;
    btnMore.Width := 144;
    btnMore.Height := 25;
    btnMore.Caption := 'zeige mehr...';
    btnMore.ChildOrder := 1;
    btnMore.ElementClassName := 'showmore-btn';
    btnMore.ElementFont := efCSS;
    btnMore.ElementPosition := epIgnore;
    btnMore.HeightStyle := ssAuto;
    btnMore.HeightPercent := 100.000000000000000000;
    btnMore.WidthStyle := ssAuto;
    btnMore.WidthPercent := 100.000000000000000000;
    SetEvent(btnMore, Self, 'OnClick', 'btnMoreClick');
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 112;
    Client.Top := 16;
  finally
    divWrapper.AfterLoadDFMValues;
    WebHTMLDiv1.AfterLoadDFMValues;
    WebHTMLDiv3.AfterLoadDFMValues;
    imgNew.AfterLoadDFMValues;
    divShowMore.AfterLoadDFMValues;
    btnMore.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.

