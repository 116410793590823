unit ufrFotosListe;

interface

uses
  System.SysUtils, System.Variants, System.Classes,JS,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.Imaging.jpeg,
  WEBLib.Controls, WEBLib.ExtCtrls, WEBLib.WebCtrls, Data.DB,
  XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Client,
  Vcl.Imaging.pngimage, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.CSS,
  Web, WEBLib.Graphics, WEBLib.ComCtrls, system.Types,WEBLib.WebTools,
  Vcl.Menus, WEBLib.Menus, WEBLib.Dialogs, uTB, uGlobal, ufrFotosThumb,
  WEBLib.Forms;

type
  TfrFotosliste = class(TFrame)
    WebHTMLDiv1: THTMLDiv;
    divWrapper: THTMLDiv;
    img: TImageControl;
    Client: TXDataWebClient;
    procedure imgClick(Sender: TObject);
  protected
    procedure Loaded(); override;
  private
    FStart,
    FKursID, FStatus,
    FBlogID,
    FAlbumID:integer;
    FVorschau,
    FHolder, FMe,
    FPostID,
    FParentID,
    FNewID:integer;
    FImageClick:TCallback;
    FShowMarked,
    FDoClick:boolean;
    FAddImage:integer;
    FThumbs: array of TfrFotosThumb;
    procedure SetAlbumID(const Value: integer);
    function GetThumb(Index: Integer): TfrFotosThumb;
    procedure SetThumb(Index: Integer; Value: TfrFotosThumb);
    function GetThumbsCount:integer;

  public
    [async] function Init(AHolder, AMe, AAlbumID, APostID, ABlogID, AKursID, ASTART, AStatus: integer; AImageClick:TCallback; ADoClick:boolean = true; AAddImage:integer = 0; AShowMarked:boolean = false):integer; async;
    property AlbumId: integer read FAlbumId write SetAlbumId;
    property Holder: integer read FHolder write FHolder;
    property PostID: integer read FPostID write FPostID;
    property ParentID: integer read FParentID write FParentID;
    property Vorschau: integer read FVorschau write FVorschau;
    property Start: integer read FStart write FStart;
    procedure thumbClick(s:string);
    function GetMarked:array of string;
    property Thumbs[Index:integer]:TfrFotosThumb read GetThumb write SetThumb;
    property ThumbsCount :integer read GetThumbsCount;
    [async] procedure Refresh; async;
    [async] function AddThumb(AID:integer):TfrFotosThumb; async;
  protected procedure LoadDFMValues; override; end;

implementation
   uses uEdit, uMain, uSlider, uMedienAuswahl, uDatenmodul;
{$R *.dfm}

function TfrFotosliste.GetThumbsCount:integer;
begin
  Result := Length(FThumbs)
end;

function TfrFotosliste.GetThumb(Index: Integer): TfrFotosThumb;
begin
  Result := FThumbs[Index];
end;

procedure TfrFotosliste.SetThumb(Index: Integer; Value: TfrFotosThumb);
begin
  FThumbs[Index] := Value;
end;

function TfrFotosliste.GetMarked:array of string;
Var
  i:integer;
begin
  Setlength(result, 0);

  for i := 0 to length(FThumbs) - 1 do
  begin
    if FThumbs[i].Marked.Checked
    then begin
      Setlength(result, length(result) + 1);
      result[length(result)-1] := FThumbs[i].Id.ToString;
    end;
  end;
end;

procedure TfrFotosliste.refresh;
begin
  await(Init(FHolder, FMe, FAlbumID, FPostID, FBlogID, FKursID, FStart, FStatus, FImageClick, FDoClick, FAddImage, FShowMarked));
end;

procedure TfrFotosliste.ThumbClick(s:string);
begin
  if assigned(FImageClick)
  then FImageClick(s)
  else TfrmSlider.Fotos(Mainform.Holder.ID, Mainform.ME.ID, FAlbumId, FPostId, FBlogID, FKursID, s.ToInteger, nil, FAddImage);
end;

function TfrFotosliste.AddThumb(AId:integer):TfrFotosThumb;
begin
  result := await(TfrFotosThumb.Add(divWrapper, AID, datenmodul.connection.url+'/DBService/GetImage?Mediaid='+ AID.toString +'&Size=2', @ThumbClick, FShowMarked));
end;

function TfrFotosliste.Init(AHolder, AMe, AAlbumID, APostID, ABlogID, AKursID, AStart, AStatus: integer; AImageClick:TCallback; ADoClick:boolean = true; AAddImage:integer = 0; AShowMarked:boolean = false):integer;
Var
  i:integer;
  Response: TXDataClientResponse;
  s: string;
  sNewAlbum:string;
  iStart, iB, iA, iP, iK:integer;
  J:TJ;
  f, fFirst : TfrFotosThumb;
  o:TControl;
begin

  FVorschau   := 0;
  FAlbumID    := AAlbumID;
  FHolder     := AHolder;
  FMe         := AMe;
  FPostID     := APostID;
  FBlogID     := ABlogID;
  FKursID     := AKursID;
  FStatus     := AStatus;
  FAlbumID    := AAlbumID;
  FDoClick    := ADoClick;
  FShowMarked := AShowMarked;
  FStart      := AStart;
  FAddImage   := AAddImage;
  FImageClick := AImageClick;
  Setlength(FThumbs, 0);

  iB := 0; iA := 0; iP := 0; iK := 0;

//  if ACaption > ''
//  then divTitel.HTML.Text := ACaption;

  //festellen wofür die Fotos geholt werden
  if FPostID > 0
  then iP := FPostID  //für einen Post
  else begin
    if FBlogID > 0 //für einen Blog
    then iB := FBlogID
    else  if FAlbumID > 0
          then iA := FAlbumID // für ein Album
          else iK := FKursID; // für einen Kurs
  end;
  //evtl. bereits bestehende Images entfernen
  for i := divWrapper.Owner.ComponentCount - 1 downto 0 do
  begin
    o := TControl(divWrapper.Owner.components[i]);
    if (o.Parent = divWrapper) and  (o is TfrFotosThumb)
    then o.Free;
  end;

  //Image zum hinzufügen verstecken - falls NewestMedia geholt werden sollen
  img.Visible := false;

  //Fotos je nach Anlass holen
  if AAlbumID = _Neuste_Medien
  then Response := Await( Client.RawInvokeAsync('IDBService.AlbumGetMedia',[_Neuste_Medien,0,0,0,medFoto,2,Mainform.MaxNewFotos]))
  else begin
    //wenn es "meine" bilder sind dann Image zum hinzufügen anzeigen
    img.Visible := (FAddImage <> 0);// and (FHolder = FMe));

    if AStatus = -1
    then Response := Await( Client.RawInvokeAsync('IDBService.AlbumGetMedia',[iA, iP, iB, iK, medFoto, 2, -1]))
    else Response := Await( Client.RawInvokeAsync('IDBService.AlbumGetMedia',[iA, iP, iB, iK, medFoto, 2,  0]));

  end;
  J:=TJ.create(Response);

  //Fotos anzeigen
  for i := 0 to j.Length-1 do
  begin
    j.Index := i;
    if j.integer('MEDIEN_TYP') = integer(medFoto)
    then begin
//     f := await(TfrFotosThumb.Add(divWrapper, j.integer('ID'), datenmodul.connection.url+'/DBService/GetImage?Mediaid='+ j.value('ID')+'&Size=2', @ThumbClick, FShowMarked));
     f := await(AddThumb(j.integer('ID')));

     if FKursID = 0 //wenn kein Kurs dann Fotos evtl. verschwommen anzeigen
     then Mainform.SetBlur(f.img, j.integer('HOLDER'), Mainform.ME.hidefotos);

     Setlength(FThumbs,length(FThumbs)+1);
     fThumbs[length(FThumbs)-1] := f;

     //Wenn keine "Start"- Vorgabe dann soll das erste Foto angezeigt werden
     if FStart = 0
     then iStart := f.Id
     else begin
       //prüfen ob das Bild(FStart) da ist. könnte gelöscht worden sein
       if FStart = j.Integer('ID')
       then iStart := j.Integer('ID'); //wenn iStart > 0, dann ist das Bild da
     end;

    end;
  end;

  if iStart > 0 //...
  then FStart := iStart
  else begin //wenn nicht da, auf das erste Bild springen
    j.Index := 0;
    FStart := J.Integer('ID');
  end;

  if FDoClick
  then ThumbClick(FStart.ToString);

  result := j.Length;
end;

procedure TfrFotosliste.imgClick(Sender: TObject);

  [async]
  procedure Callback(s:string); async;
  begin
    //showmessage('Callback');
    if assigned(FImageClick)
    then await(FImageClick(s));

//    Start := s.ToInteger;
//    refresh;
  end;

begin
  TfrmMedienAuswahl.Fotos(FHolder, Mainform.ME.id, Mainform.ME.Album, FPostID, FBlogID, FKursID, FStart, @callback, FAddImage);
//  TfrmMedienAuswahl.Fotos(FHolder, Mainform.ME.id, Mainform.ME.Album, FPostID, FBlogID, FKursID, FStart, FImageClick, FAddImage);
end;

procedure TfrFotosliste.SetAlbumID(const Value: integer);
begin
  FAlbumID := value;
  init(FHolder, FMe, FAlbumID, FPostID, FBlogID, FKursID,0, FStatus,nil);
end;

procedure TfrFotosliste.Loaded();
var
  el: TJSHTMLElement;
begin
  inherited;
  TControl(self).ElementPosition := epIgnore;
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-style');
  el.style.removeProperty('font-size');
end;

procedure TfrFotosliste.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  divWrapper := THTMLDiv.Create(Self);
  img := TImageControl.Create(Self);
  Client := TXDataWebClient.Create(Self);

  WebHTMLDiv1.BeforeLoadDFMValues;
  divWrapper.BeforeLoadDFMValues;
  img.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frFotosliste';
    Left := 0;
    Top := 0;
    Width := 188;
    Height := 128;
    Margins.Left := 0;
    Margins.Top := 0;
    Margins.Right := 0;
    Margins.Bottom := 0;
    TabOrder := 0;
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 3;
    WebHTMLDiv1.Top := 3;
    WebHTMLDiv1.Width := 174;
    WebHTMLDiv1.Height := 110;
    WebHTMLDiv1.ElementClassName := 'alben_center_wrapper';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ChildOrder := 1;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    divWrapper.SetParentComponent(WebHTMLDiv1);
    divWrapper.Name := 'divWrapper';
    divWrapper.Left := 16;
    divWrapper.Top := 16;
    divWrapper.Width := 129;
    divWrapper.Height := 73;
    divWrapper.ElementClassName := 'media_content_wrapper';
    divWrapper.HeightStyle := ssAuto;
    divWrapper.WidthStyle := ssAuto;
    divWrapper.ChildOrder := 1;
    divWrapper.ElementPosition := epIgnore;
    divWrapper.ElementFont := efCSS;
    divWrapper.Role := '';
    img.SetParentComponent(divWrapper);
    img.Name := 'img';
    img.Left := 24;
    img.Top := 3;
    img.Width := 57;
    img.Height := 53;
    img.ElementClassName := 'media_new';
    img.HeightStyle := ssAuto;
    img.WidthStyle := ssAuto;
    img.HeightPercent := 100.000000000000000000;
    img.WidthPercent := 100.000000000000000000;
    img.ElementFont := efCSS;
    img.ElementPosition := epIgnore;
    SetEvent(img, Self, 'OnClick', 'imgClick');
    img.Picture.LoadFromFile('ufrFotosListe.divWrapper.img.Picture.png');
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 104;
    Client.Top := 56;
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    divWrapper.AfterLoadDFMValues;
    img.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.

