unit uHashTags;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.ComCtrls,
  Vcl.Controls, WEBLib.WebCtrls, utb, uGlobal;

type
  TfrmHashTags = class(TForm)
    divWrapper: THTMLDiv;
    divHeader: THTMLDiv;
    pFooter: THTMLDiv;
    btnSave: TButton;
    btnCAncel: TButton;
    divHashTags: THTMLDiv;
    btnNew: TButton;
    divTitel: THTMLDiv;
    procedure WebFormCreate(Sender: TObject);
    [async] procedure btnSaveClick(Sender: TObject); async;
    procedure btnCancelClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure btnNewClick(Sender: TObject);
  private
    FOld:array of integer;
    FBlogKat,
    FSub, FSubText,
    FID:string;
    FCallback:TCallbackArray;
    FContainer:THtmlDiv;
  public
    [async] class function Exec(ABlogKat, AID:string; AAlle:boolean; ASub, ASubText:string; aChecked:array of integer;  ACallbackArray:TCallbackArray):integer; async;
  protected procedure LoadDFMValues; override; end;

  procedure HashSaveStatus(AOwner:THTMLDiv; var AOld:array of integer);

var
  frmHashTags: TfrmHashTags;

implementation
   uses uDatenmodul, uMain, uEdit;
{$R *.dfm}

procedure HashSaveStatus(AOwner:THTMLDiv; var AOld:array of integer);

var
  i:integer;
  lHasChild:boolean;
begin

  setlength(AOld, 0);

  for i := 0 to AOwner.ControlCount - 1 do
  begin
    if (AOwner.controls[i] is THTMLDiv)
    then begin
      lHasChild:=true;
      Checked2Array(THTMLDiv(AOwner.controls[i]), AOld);
    end;
  end;

  if (not lHasChild)
  then Checked2Array(AOwner, AOld);

end;

class function TfrmHashTags.Exec(ABlogKat, AID: string; AAlle:boolean; ASub, ASubText:string; aChecked:array of integer; ACallbackArray:TCallbackArray):integer;

  [async]
  procedure AfterCreate(AForm: TObject); async;
  Var
    s:string;
    i:integer;
    divHeader:THTMLDiv;
    edText: TEdit;
  begin

    TfrmHashTags(AForm).ElementHandle.style.removeProperty('position');
    TfrmHashTags(AForm).divWrapper.ElementHandle.style.removeProperty('height');
    TfrmHashTags(AForm).FID := AID;
    TfrmHashTags(AForm).FBlogKat := ABlogKat;
    TfrmHashTags(AForm).FSub := ASub;
    TfrmHashTags(AForm).FSubText := ASubText;

    if aSub > ''
    then begin
      TfrmHashTags(AForm).divTitel.HTML.Text := ASubText + ' zuweisen...';
      TfrmHashTags(AForm).divTitel.ElementClassName := 'blogkat-header';
      TfrmHashTags(AForm).btnNew.caption := 'neu anlegen';
    end;

    await( FillHashTags(TfrmHashTags(AForm).divHashTags, AID, ABlogKat, AAlle, aChecked, ASub));

  end;

Var
  frm:TfrmHashTags;
  FConti:THtmlDiv;
begin
  FConti := THtmlDiv.Create(Mainform);
  FConti.Parent := Mainform;
  FConti.ElementClassName := 'editor_transparent';
  FConti.HeightStyle:= ssAuto;
  FConti.WidthStyle := ssAuto;
  FConti.ElementFont := efCSS;
  FConti.ElementHandle.style.removeProperty('height');
  frm := TfrmHashTags.CreateNew(FConti.ElementID, @AfterCreate);
  frm.FContainer := FConti;
  frm.FCallback  := ACallbackArray;
end;

procedure TfrmHashTags.btnCancelClick(Sender: TObject);
begin
  close;
end;

procedure TfrmHashTags.btnNewClick(Sender: TObject);

  [async]
  procedure callback(a:array of string); async;
  var
    j:TJ;
    k,i:integer;
    sVor:string;
  begin
    //Alte Markierungen merken
    HashSaveStatus(divHashTags, FOld);

    if FSub > ''
    then begin
      j := TJ.create(await(datenmodul.EasySQL('select ID from BlogKat where VOR = ' + FBlogKat + ' AND SEQ = ' + FSub)));
      sVor := j.Value('ID');
      j.Free;
    end
    else sVor := a[1];

    j := TJ.create(await(datenmodul.EasySQL('SELECT ID from BlogKat where Typ = 0 and level = 0 and VOR = ' + sVor + ' and Upper(TEXT) = ''' + Uppercase(a[0]) + '''')));

    if j.isEmpty
    then k := await(datenmodul.EasyInsert('Insert into BlogKat (TYP, LEVEL, TEXT, VOR) Value(0,0,''' + a[0] + ''',' + sVor + ')'))
    else k := j.Integer('ID');

    await(RemoveAllChildControls(divHashTags));
    await(FillHashTags(divHashTags, FID, FBlogKat, true, FOld, FSub, true, k));

  end;

var
  a:array[0..1] of TFieldCollection;
  sLabel:string;
  sSQL:string;
begin

  if FSubText = ''
  then sLabel := 'Suchbegriff';

  a[0].Caption := sLabel; a[0].Text := ''; a[0].Required:= true; a[0].Typ := 0;

  sSql := 'Select ID, TEXT from BlogKat Where Level = 2 AND VOR = ' + FBlogKat;

  if FSub > ''
  then begin
    sSQl := sSQl + ' AND SEQ = ' + FSub;
    a[1].max_length := -1;
  end;

  sSQL := sSQL + ' ORDER BY SEQ, TEXT';

  a[1].Caption := 'Kategorie:'; a[1].Text := ''; a[1].Required:= false; a[1].Typ := 2;
  a[1].SQl := sSql;

  TfrmEditor.Exec( FSubText + ' eingeben', 'Übernehmen', a, @Callback);
end;

procedure TfrmHashTags.btnSaveClick(Sender: TObject);
Var
  j,i:integer;
  a : array of string;
begin

  if assigned(FCallback)
  then begin

    HashSaveStatus(divHashTags, FOld);

    setlength(a,length(FOld));

    for i := 0 to length(FOld) - 1 do
      a[i] := FOld[i].ToString;

    FCallback(a);
  end;

  close;

end;

procedure TfrmHashTags.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  FreeAndNil(FContainer);
end;

procedure TfrmHashTags.WebFormCreate(Sender: TObject);
 var
  el: TJSHTMLElement;
begin
  inherited;
  TControl(self).ElementPosition := epIgnore;
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-style');
  el.style.removeProperty('font-size');
end;

procedure TfrmHashTags.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divWrapper := THTMLDiv.Create(Self);
  divHeader := THTMLDiv.Create(Self);
  btnNew := TButton.Create(Self);
  divTitel := THTMLDiv.Create(Self);
  pFooter := THTMLDiv.Create(Self);
  btnSave := TButton.Create(Self);
  btnCAncel := TButton.Create(Self);
  divHashTags := THTMLDiv.Create(Self);

  divWrapper.BeforeLoadDFMValues;
  divHeader.BeforeLoadDFMValues;
  btnNew.BeforeLoadDFMValues;
  divTitel.BeforeLoadDFMValues;
  pFooter.BeforeLoadDFMValues;
  btnSave.BeforeLoadDFMValues;
  btnCAncel.BeforeLoadDFMValues;
  divHashTags.BeforeLoadDFMValues;
  try
    Name := 'frmHashTags';
    Width := 491;
    Height := 519;
    ElementFont := efCSS;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    divWrapper.SetParentComponent(Self);
    divWrapper.Name := 'divWrapper';
    divWrapper.Left := 8;
    divWrapper.Top := 8;
    divWrapper.Width := 473;
    divWrapper.Height := 497;
    divWrapper.ElementClassName := 'editor-container';
    divWrapper.HeightStyle := ssAuto;
    divWrapper.WidthStyle := ssAuto;
    divWrapper.ElementPosition := epIgnore;
    divWrapper.ElementFont := efCSS;
    divWrapper.Role := '';
    divHeader.SetParentComponent(divWrapper);
    divHeader.Name := 'divHeader';
    divHeader.Left := 16;
    divHeader.Top := 11;
    divHeader.Width := 441;
    divHeader.Height := 38;
    divHeader.ElementClassName := 'hashtag-header';
    divHeader.HeightStyle := ssAuto;
    divHeader.WidthStyle := ssAuto;
    divHeader.ElementPosition := epIgnore;
    divHeader.ElementFont := efCSS;
    divHeader.HTML.BeginUpdate;
    try
      divHeader.HTML.Clear;
      divHeader.HTML.Add('');
    finally
      divHeader.HTML.EndUpdate;
    end;
    divHeader.Role := '';
    btnNew.SetParentComponent(divHeader);
    btnNew.Name := 'btnNew';
    btnNew.Left := 272;
    btnNew.Top := 10;
    btnNew.Width := 166;
    btnNew.Height := 25;
    btnNew.Caption := 'neuen Suchbegriff anlegen';
    btnNew.ElementClassName := 'hashtag-btn-new';
    btnNew.ElementFont := efCSS;
    btnNew.ElementPosition := epIgnore;
    btnNew.HeightStyle := ssAuto;
    btnNew.HeightPercent := 100.000000000000000000;
    btnNew.WidthStyle := ssAuto;
    btnNew.WidthPercent := 100.000000000000000000;
    SetEvent(btnNew, Self, 'OnClick', 'btnNewClick');
    divTitel.SetParentComponent(divHeader);
    divTitel.Name := 'divTitel';
    divTitel.Left := 3;
    divTitel.Top := 8;
    divTitel.Width := 166;
    divTitel.Height := 27;
    divTitel.HeightStyle := ssAuto;
    divTitel.WidthStyle := ssAuto;
    divTitel.ChildOrder := 1;
    divTitel.ElementPosition := epIgnore;
    divTitel.ElementFont := efCSS;
    divTitel.HTML.BeginUpdate;
    try
      divTitel.HTML.Clear;
      divTitel.HTML.Add('Suchbegriff ausw'#228'hlen');
    finally
      divTitel.HTML.EndUpdate;
    end;
    divTitel.Role := '';
    pFooter.SetParentComponent(divWrapper);
    pFooter.Name := 'pFooter';
    pFooter.Left := 16;
    pFooter.Top := 439;
    pFooter.Width := 441;
    pFooter.Height := 42;
    pFooter.ElementClassName := 'editor-footer-div';
    pFooter.HeightStyle := ssAuto;
    pFooter.WidthStyle := ssAuto;
    pFooter.ChildOrder := 5;
    pFooter.ElementPosition := epIgnore;
    pFooter.ElementFont := efCSS;
    pFooter.Role := '';
    btnSave.SetParentComponent(pFooter);
    btnSave.Name := 'btnSave';
    btnSave.Left := 170;
    btnSave.Top := 3;
    btnSave.Width := 96;
    btnSave.Height := 25;
    btnSave.Caption := #220'bernehmen';
    btnSave.ElementClassName := 'start_button-lets-go';
    btnSave.ElementFont := efCSS;
    btnSave.ElementPosition := epIgnore;
    btnSave.HeightStyle := ssAuto;
    btnSave.HeightPercent := 100.000000000000000000;
    btnSave.WidthStyle := ssAuto;
    btnSave.WidthPercent := 100.000000000000000000;
    SetEvent(btnSave, Self, 'OnClick', 'btnSaveClick');
    btnCAncel.SetParentComponent(pFooter);
    btnCAncel.Name := 'btnCAncel';
    btnCAncel.Left := 304;
    btnCAncel.Top := 3;
    btnCAncel.Width := 96;
    btnCAncel.Height := 25;
    btnCAncel.Caption := 'Abbrechen';
    btnCAncel.ChildOrder := 2;
    btnCAncel.ElementClassName := 'start_button-lets-go';
    btnCAncel.ElementFont := efCSS;
    btnCAncel.ElementPosition := epIgnore;
    btnCAncel.HeightStyle := ssAuto;
    btnCAncel.HeightPercent := 100.000000000000000000;
    btnCAncel.WidthStyle := ssAuto;
    btnCAncel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCAncel, Self, 'OnClick', 'btnCAncelClick');
    divHashTags.SetParentComponent(divWrapper);
    divHashTags.Name := 'divHashTags';
    divHashTags.Left := 16;
    divHashTags.Top := 55;
    divHashTags.Width := 441;
    divHashTags.Height := 378;
    divHashTags.ElementClassName := 'blog-hashtag-div';
    divHashTags.HeightStyle := ssAuto;
    divHashTags.WidthStyle := ssAuto;
    divHashTags.ChildOrder := 1;
    divHashTags.ElementPosition := epIgnore;
    divHashTags.ElementFont := efCSS;
    divHashTags.Role := '';
  finally
    divWrapper.AfterLoadDFMValues;
    divHeader.AfterLoadDFMValues;
    btnNew.AfterLoadDFMValues;
    divTitel.AfterLoadDFMValues;
    pFooter.AfterLoadDFMValues;
    btnSave.AfterLoadDFMValues;
    btnCAncel.AfterLoadDFMValues;
    divHashTags.AfterLoadDFMValues;
  end;
end;

end.