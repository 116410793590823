unit ufraAlben;

interface

uses
  System.SysUtils, System.Variants, System.Classes,JS,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.Imaging.jpeg,
  WEBLib.Controls, WEBLib.ExtCtrls, WEBLib.WebCtrls, Data.DB,
  XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Client,
  Vcl.Imaging.pngimage, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.CSS,

  Web, WEBLib.Graphics,
  WEBLib.ComCtrls,
  system.Types,
  Vcl.Menus, WEBLib.Menus, ufrAlbumThumb;

type
  TfrAlben = class(TFrame)
    WebHTMLDiv1: THTMLDiv;
    divWrapper: THTMLDiv;
    WebHTMLDiv3: THTMLDiv;
    img1: TImageControl;
  private
    FUserID:integer;
    FBeitragID,
    FParentID:integer;
    FCollapsedHeight:integer;
  public
    procedure Init(const Value: integer);
    property UserId: integer read FUserId write Init;
    property BeitragID: integer read FBeitragID write FBeitragID;
    property ParentID: integer read FParentID write FParentID;
    property CollapsedHeight: integer read FCollapsedHeight write FCollapsedHeight;
  protected procedure LoadDFMValues; override; end;

implementation
   uses uDatenmodul, uMain;
{$R *.dfm}

procedure TfrAlben.Init(const Value: integer);
Var
  f: TfrAlbumThumb;
  el: TJSHTMLElement;
  i:integer;
begin
  FUserId := Value;

  for i := 0 to 4 do
  begin
    f := TfrAlbumThumb.Create(divWrapper.owner);
    f.LoadFromForm;
    f.parent := divWrapper;

{    TWebControl(f).elementPosition := epIgnore;
    TwebHTMlDiv(f).ElementID := 'alben' + i.ToString;
}    //TWebControl(f).ElementClassName := 'alben_content';

    el := TJSHTMLElement(THTMLDiv(f).ElementHandle);
    el.style.setProperty('width','auto');
    el.style.setProperty('height','auto');
  end;

end;

procedure TfrAlben.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  divWrapper := THTMLDiv.Create(Self);
  WebHTMLDiv3 := THTMLDiv.Create(Self);
  img1 := TImageControl.Create(Self);

  WebHTMLDiv1.BeforeLoadDFMValues;
  divWrapper.BeforeLoadDFMValues;
  WebHTMLDiv3.BeforeLoadDFMValues;
  img1.BeforeLoadDFMValues;
  try
    Name := 'frAlben';
    Left := 0;
    Top := 0;
    Width := 784;
    Height := 377;
    Margins.Left := 0;
    Margins.Top := 0;
    Margins.Right := 0;
    Margins.Bottom := 0;
    TabOrder := 0;
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 0;
    WebHTMLDiv1.Top := 0;
    WebHTMLDiv1.Width := 777;
    WebHTMLDiv1.Height := 369;
    WebHTMLDiv1.ElementClassName := 'alben_center_wrapper';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ChildOrder := 4;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.Role := '';
    divWrapper.SetParentComponent(WebHTMLDiv1);
    divWrapper.Name := 'divWrapper';
    divWrapper.Left := 3;
    divWrapper.Top := 3;
    divWrapper.Width := 766;
    divWrapper.Height := 350;
    divWrapper.ElementClassName := 'alben_content_wrapper';
    divWrapper.HeightStyle := ssAuto;
    divWrapper.WidthStyle := ssAuto;
    divWrapper.ChildOrder := 1;
    divWrapper.ElementPosition := epIgnore;
    divWrapper.Role := '';
    WebHTMLDiv3.SetParentComponent(divWrapper);
    WebHTMLDiv3.Name := 'WebHTMLDiv3';
    WebHTMLDiv3.Left := 24;
    WebHTMLDiv3.Top := 37;
    WebHTMLDiv3.Width := 177;
    WebHTMLDiv3.Height := 209;
    WebHTMLDiv3.ElementClassName := 'alben_content';
    WebHTMLDiv3.HeightStyle := ssAuto;
    WebHTMLDiv3.WidthStyle := ssAuto;
    WebHTMLDiv3.ChildOrder := 2;
    WebHTMLDiv3.ElementPosition := epIgnore;
    WebHTMLDiv3.ElementFont := efCSS;
    WebHTMLDiv3.HTML.BeginUpdate;
    try
      WebHTMLDiv3.HTML.Clear;
      WebHTMLDiv3.HTML.Add('');
    finally
      WebHTMLDiv3.HTML.EndUpdate;
    end;
    WebHTMLDiv3.Role := '';
    img1.SetParentComponent(WebHTMLDiv3);
    img1.Name := 'img1';
    img1.Left := 36;
    img1.Top := 60;
    img1.Width := 88;
    img1.Height := 88;
    img1.ElementClassName := 'blk_content_thumb';
    img1.HeightStyle := ssAuto;
    img1.WidthStyle := ssAuto;
    img1.HeightPercent := 100.000000000000000000;
    img1.WidthPercent := 100.000000000000000000;
    img1.ChildOrder := 1;
    img1.ElementPosition := epIgnore;
    img1.Visible := False;
    img1.Picture.LoadFromFile('ufraAlben.WebHTMLDiv3.img1.Picture.png');
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    divWrapper.AfterLoadDFMValues;
    WebHTMLDiv3.AfterLoadDFMValues;
    img1.AfterLoadDFMValues;
  end;
end;

end.

