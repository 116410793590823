unit ufrSuchen;

interface

uses
  System.SysUtils, System.Variants, System.Classes,JS,Web,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.Imaging.jpeg,
  WEBLib.Controls, WEBLib.ExtCtrls, WEBLib.WebCtrls, Data.DB, uTB,uGlobal,
  XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Client, WEBLib.CDS,
  WEBLib.Graphics, WEBLib.Forms, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.Lists,
  WEBLib.EditAutocomplete, Vcl.Imaging.pngimage, WEBLib.Buttons;

type
  TfrSuchen = class(TFrame)
    divWrapper: THTMLDiv;
    divSuchen: THTMLDiv;
    Client: TXDataWebClient;
    divUser: THTMLDiv;
    wlUser: TListControl;
    [async]
    WebHTMLDiv1: THTMLDiv;
    divLektionen: THTMLDiv;
    wlLektionen: TListControl;
    WebHTMLDiv4: THTMLDiv;
    divGruppen: THTMLDiv;
    wlGruppen: TListControl;
    WebHTMLDiv5: THTMLDiv;
    divBlogs: THTMLDiv;
    wlBlogs: TListControl;
    WebHTMLDiv6: THTMLDiv;
    WebHTMLDiv22: THTMLDiv;
    WebHTMLDiv71: THTMLDiv;
    WebButton1: TButton;
    edSuchen: TEditAutoComplete;
    divEvents: THTMLDiv;
    wlEvents: TListControl;
    WebHTMLDiv3: THTMLDiv;
    divTutorials: THTMLDiv;
    wlTutorials: TListControl;
    WebHTMLDiv8: THTMLDiv;
    btnShowResult: TButton;
    procedure wlUserItemClick(Sender: TObject; AListItem: TListItem);
    [async] procedure edSuchenBtnClick(Sender: TObject); async;
    procedure btnShowResultClick(Sender: TObject);
  private
    FUser:integer;
  protected
    procedure Loaded(); override;
  public
    public constructor Create(AOwner:TComponent);Override;
    [async] procedure Init(AUser:integer); async;
  protected procedure LoadDFMValues; override; end;

implementation
   Uses uMain, uDatenmodul, uBlog, uKurse;
{$R *.dfm}

procedure TfrSuchen.btnShowResultClick(Sender: TObject);
begin
  if divWrapper.ElementHandle.style.GetPropertyValue('height') = ''
  then divWrapper.ElementHandle.style.setProperty('height','50px')
  else divWrapper.ElementHandle.style.removeProperty('height');
end;

constructor TfrSuchen.Create(AOwner: TComponent);
begin
  inherited;
end;

procedure TfrSuchen.Init(AUser:integer);
begin
  FUser := AUser
end;

procedure TfrSuchen.Loaded();
var
  el: TJSHTMLElement;
begin
  inherited;

  divSuchen.ElementHandle.style.setProperty('border-bottom-style', 'none');

  TControl(self).ElementPosition := epIgnore;
  TControl(self).ElementID := 'frSuchen';
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-style');
  el.style.removeProperty('font-size');

end;

procedure TfrSuchen.edSuchenBtnClick(Sender: TObject);
Var
  j:TJ;
  li: TListItem;
  i:integer;
  sTrainer, s:string;
begin

  if length(edSuchen.Text) < 3
  then begin
    await(Meldung('mindestens 3 Zeichen eingeben...'));
    exit;
  end;

  divWrapper.ElementHandle.style.removeProperty('height');

  divSuchen.ElementHandle.style.setProperty('border-bottom-style', 'solid');

  //Mitglieder------------------
  j := TJ.create(await(datenmodul.EasySQL('Select ID, NICKNAME from User ' +
                    ' where GAST = 0 AND TYP = ' + _UT_User.ToString + ' AND STATUS_ID <= 2 AND Nickname LIKE ''' + edSuchen.text + '%''')));

  wlUser.Items.Clear;
  for i := 0 to J.Length -1 do
  begin
    j.index := i;
    li := wlUser.Items.Add;
    li.Text := j.Text('NICKNAME');
    li.Tag  := j.integer('ID');
  end;
  divUser.Visible := wlUser.Items.Count > 0;

  //Lektionen------------------
  if Mainform.ME.Trainer
  then sTrainer := '1'
  else sTrainer := '0';

  j.Response := await(datenmodul.EasySQL('Select DISTINCT ID, HEADER from Kurse ' +
                     '  where Art=1 AND TRAINER <= ' + sTrainer + ' AND (Header LIKE ''%' + edSuchen.text + '%'' OR TEXT LIKE ''%' + edSuchen.Text + '%'')'));

  wlLektionen.Items.Clear;
  for i := 0 to J.Length -1 do
  begin
    j.index := i;
    li := wlLektionen.Items.Add;
    li.Text := j.Text('HEADER');
    li.Tag  := j.integer('ID');
  end;
  divLektionen.Visible := wlLektionen.Items.Count > 0;

  //Anleitungen------------------
  j.Response := await(datenmodul.EasySQL('Select DISTINCT ID, HEADER from Kurse ' +
                      '  where ART=2 AND TRAINER <= ' + sTrainer + ' AND (Header LIKE ''%' + edSuchen.text + '%'' OR TEXT LIKE ''%' + edSuchen.Text + '%'')'));

  wlTutorials.Items.Clear;
  for i := 0 to J.Length -1 do
  begin
    j.index := i;
    li := wlTutorials.Items.Add;
    li.Text := j.Text('HEADER');
    li.Tag  := j.integer('ID');
  end;
  divTutorials.Visible := wlTutorials.Items.Count > 0;

  //Gruppen------------------
  j.Response := Await( Client.RawInvokeAsync('IDBService.UserGet',[FUser, _UT_Gruppe, _FS_Alle, 0, 0,0,' AND (Nickname LIKE ''' + edSuchen.text + '%'')']));

  wlGruppen.Items.Clear;
  for i := 0 to J.Length -1 do
  begin
    j.index := i;
    li := wlGruppen.Items.Add;
    li.Text := j.Text('NICKNAME');
    li.Tag  := j.integer('ID');
  end;
  divGruppen.Visible := wlGruppen.Items.Count > 0;

  //Events-------------------
  j.Response := Await( Client.RawInvokeAsync('IDBService.UserGet',[FUser, _UT_Event, _FS_Alle, 0, 0,0,' AND (Nickname LIKE ''' + edSuchen.text + '%'')']));

  wlEvents.Items.Clear;
  for i := 0 to J.Length -1 do
  begin
    j.index := i;
    li := wlEvents.Items.Add;
    li.Text := j.Text('NICKNAME');
    li.Tag  := j.integer('ID');
  end;
  divEvents.Visible := wlEvents.Items.Count > 0;

  //Blogs--------------------
  j.Response := await(datenmodul.EasySQL('Select DISTINCT ID, HEADER from Blog ' +
                     '  where Header LIKE ''%' + edSuchen.text + '%'' OR TEXT LIKE ''%' + edSuchen.Text + '%'''));

  wlBlogs.Items.Clear;
  for i := 0 to J.Length -1 do
  begin
    j.index := i;
    li := wlBlogs.Items.Add;
    li.Text := j.Text('HEADER');
    li.Tag  := j.integer('ID');
  end;
  divBlogs.Visible := wlBlogs.Items.Count > 0;

  j.Free;
end;

procedure TfrSuchen.wlUserItemClick(Sender: TObject; AListItem: TListItem);
begin
  if sender = wlUser
  then openURL(Mainform.ME.ID.ToString, AListItem.Tag.ToString, _call_ME, '1',AListItem.Tag.tostring);

  if sender = wlGruppen
  then openURL(Mainform.ME.ID.ToString, AListItem.Tag.ToString, _call_ME,  '2',AListItem.Tag.tostring);

  if sender = wlEvents
  then openURL(Mainform.ME.ID.ToString, AListItem.Tag.ToString, _call_ME,  '3',AListItem.Tag.tostring);

  if sender = wlLektionen
  then openURL(Mainform.ME.ID.ToString, AListItem.Tag.ToString, _call_Kurse, _KA_Lektion.ToString, AListItem.Tag.tostring);

  if sender = wlTutorials
  then openURL(Mainform.ME.ID.ToString, AListItem.Tag.ToString, _call_Kurse, _KA_Tutorial.ToString, AListItem.Tag.tostring);

  if sender = wlBlogs
  then begin
//    if assigned(Mainform.frmME)
//    then TfrmBlog.Show(_BLOGKAPITEL, AListItem.Tag, '', 0,false, Mainform.frmME);
  end;

end;

procedure TfrSuchen.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divWrapper := THTMLDiv.Create(Self);
  divSuchen := THTMLDiv.Create(Self);
  WebHTMLDiv22 := THTMLDiv.Create(Self);
  WebHTMLDiv71 := THTMLDiv.Create(Self);
  WebButton1 := TButton.Create('SearchBtn');
  btnShowResult := TButton.Create(Self);
  edSuchen := TEditAutoComplete.Create('edSuchen');
  divUser := THTMLDiv.Create(Self);
  wlUser := TListControl.Create(Self);
  WebHTMLDiv1 := THTMLDiv.Create(Self);
  divLektionen := THTMLDiv.Create(Self);
  wlLektionen := TListControl.Create(Self);
  WebHTMLDiv4 := THTMLDiv.Create(Self);
  divGruppen := THTMLDiv.Create(Self);
  wlGruppen := TListControl.Create(Self);
  WebHTMLDiv5 := THTMLDiv.Create(Self);
  divBlogs := THTMLDiv.Create(Self);
  wlBlogs := TListControl.Create(Self);
  WebHTMLDiv6 := THTMLDiv.Create(Self);
  divEvents := THTMLDiv.Create(Self);
  wlEvents := TListControl.Create(Self);
  WebHTMLDiv3 := THTMLDiv.Create(Self);
  divTutorials := THTMLDiv.Create(Self);
  wlTutorials := TListControl.Create(Self);
  WebHTMLDiv8 := THTMLDiv.Create(Self);
  Client := TXDataWebClient.Create(Self);

  divWrapper.BeforeLoadDFMValues;
  divSuchen.BeforeLoadDFMValues;
  WebHTMLDiv22.BeforeLoadDFMValues;
  WebHTMLDiv71.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  btnShowResult.BeforeLoadDFMValues;
  edSuchen.BeforeLoadDFMValues;
  divUser.BeforeLoadDFMValues;
  wlUser.BeforeLoadDFMValues;
  WebHTMLDiv1.BeforeLoadDFMValues;
  divLektionen.BeforeLoadDFMValues;
  wlLektionen.BeforeLoadDFMValues;
  WebHTMLDiv4.BeforeLoadDFMValues;
  divGruppen.BeforeLoadDFMValues;
  wlGruppen.BeforeLoadDFMValues;
  WebHTMLDiv5.BeforeLoadDFMValues;
  divBlogs.BeforeLoadDFMValues;
  wlBlogs.BeforeLoadDFMValues;
  WebHTMLDiv6.BeforeLoadDFMValues;
  divEvents.BeforeLoadDFMValues;
  wlEvents.BeforeLoadDFMValues;
  WebHTMLDiv3.BeforeLoadDFMValues;
  divTutorials.BeforeLoadDFMValues;
  wlTutorials.BeforeLoadDFMValues;
  WebHTMLDiv8.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frSuchen';
    Left := 0;
    Top := 0;
    Width := 350;
    Height := 390;
    Margins.Left := 0;
    Margins.Top := 0;
    Margins.Right := 0;
    Margins.Bottom := 0;
    TabOrder := 0;
    divWrapper.SetParentComponent(Self);
    divWrapper.Name := 'divWrapper';
    divWrapper.Left := 13;
    divWrapper.Top := 3;
    divWrapper.Width := 334;
    divWrapper.Height := 384;
    divWrapper.ElementClassName := 'me_frame_container';
    divWrapper.HeightStyle := ssAuto;
    divWrapper.WidthStyle := ssAuto;
    divWrapper.ChildOrder := 9;
    divWrapper.ElementPosition := epIgnore;
    divWrapper.ElementFont := efCSS;
    divWrapper.Role := '';
    divSuchen.SetParentComponent(divWrapper);
    divSuchen.Name := 'divSuchen';
    divSuchen.Left := 3;
    divSuchen.Top := 3;
    divSuchen.Width := 318;
    divSuchen.Height := 49;
    divSuchen.ElementClassName := 'me_headline';
    divSuchen.HeightStyle := ssAuto;
    divSuchen.WidthStyle := ssAuto;
    divSuchen.ChildOrder := 1;
    divSuchen.ElementPosition := epRelative;
    divSuchen.ElementFont := efCSS;
    divSuchen.HTML.BeginUpdate;
    try
      divSuchen.HTML.Clear;
      divSuchen.HTML.Add('');
    finally
      divSuchen.HTML.EndUpdate;
    end;
    divSuchen.Role := '';
    WebHTMLDiv22.SetParentComponent(divSuchen);
    WebHTMLDiv22.Name := 'WebHTMLDiv22';
    WebHTMLDiv22.Left := 15;
    WebHTMLDiv22.Top := 6;
    WebHTMLDiv22.Width := 288;
    WebHTMLDiv22.Height := 40;
    WebHTMLDiv22.ElementClassName := 'search-line';
    WebHTMLDiv22.HeightStyle := ssAuto;
    WebHTMLDiv22.WidthStyle := ssAuto;
    WebHTMLDiv22.ChildOrder := 1;
    WebHTMLDiv22.ElementPosition := epIgnore;
    WebHTMLDiv22.ElementFont := efCSS;
    WebHTMLDiv22.Role := '';
    WebHTMLDiv71.SetParentComponent(WebHTMLDiv22);
    WebHTMLDiv71.Name := 'WebHTMLDiv71';
    WebHTMLDiv71.Left := 122;
    WebHTMLDiv71.Top := 3;
    WebHTMLDiv71.Width := 163;
    WebHTMLDiv71.Height := 30;
    WebHTMLDiv71.ElementClassName := 'profil_sichtbar';
    WebHTMLDiv71.HeightStyle := ssAuto;
    WebHTMLDiv71.WidthStyle := ssAuto;
    WebHTMLDiv71.ChildOrder := 2;
    WebHTMLDiv71.ElementPosition := epIgnore;
    WebHTMLDiv71.ElementFont := efCSS;
    WebHTMLDiv71.Role := '';
    WebButton1.SetParentComponent(WebHTMLDiv71);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 3;
    WebButton1.Top := 2;
    WebButton1.Width := 96;
    WebButton1.Height := 25;
    WebButton1.Caption := 'suchen...';
    WebButton1.ElementClassName := 'search-btn';
    WebButton1.ElementFont := efCSS;
    WebButton1.ElementPosition := epIgnore;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthStyle := ssAuto;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'edSuchenBtnClick');
    btnShowResult.SetParentComponent(WebHTMLDiv71);
    btnShowResult.Name := 'btnShowResult';
    btnShowResult.Left := 120;
    btnShowResult.Top := 3;
    btnShowResult.Width := 40;
    btnShowResult.Height := 25;
    btnShowResult.Caption := '...';
    btnShowResult.ChildOrder := 1;
    btnShowResult.ElementClassName := 'search-btn-punkte';
    btnShowResult.ElementFont := efCSS;
    btnShowResult.ElementPosition := epIgnore;
    btnShowResult.HeightStyle := ssAuto;
    btnShowResult.HeightPercent := 100.000000000000000000;
    btnShowResult.WidthStyle := ssAuto;
    btnShowResult.WidthPercent := 100.000000000000000000;
    SetEvent(btnShowResult, Self, 'OnClick', 'btnShowResultClick');
    edSuchen.SetParentComponent(WebHTMLDiv22);
    edSuchen.Name := 'edSuchen';
    edSuchen.Left := 18;
    edSuchen.Top := 10;
    edSuchen.Width := 60;
    edSuchen.Height := 25;
    edSuchen.Hint := 'MEI% findet alles was mit Mei anf'#228'ngt z.B. Meier, Meister...; %LLE% findet  alles was LLE enth'#228'lt z.B.Keller, Propeller';
    edSuchen.Margins.Left := 0;
    edSuchen.Margins.Top := 0;
    edSuchen.Margins.Right := 0;
    edSuchen.Margins.Bottom := 0;
    edSuchen.ElementClassName := 'profil_edit';
    edSuchen.HeightStyle := ssAuto;
    edSuchen.WidthStyle := ssAuto;
    edSuchen.AutoSelect := True;
    edSuchen.BorderStyle := bsSingle;
    edSuchen.Color := clWhite;
    edSuchen.ElementFont := efCSS;
    edSuchen.ElementPosition := epRelative;
    edSuchen.ShowHint := True;
    edSuchen.TextHint := '% als Platzhalter';
    edSuchen.TabStop := True;
    edSuchen.TabOrder := 1;
    edSuchen.Items.BeginUpdate;
    try
      edSuchen.Items.Clear;
      edSuchen.Items.Add('test 1');
      edSuchen.Items.Add('test 2');
      edSuchen.Items.Add('test 3');
    finally
      edSuchen.Items.EndUpdate;
    end;
    edSuchen.Text := '';
    divUser.SetParentComponent(divWrapper);
    divUser.Name := 'divUser';
    divUser.Left := 3;
    divUser.Top := 58;
    divUser.Width := 318;
    divUser.Height := 103;
    divUser.ElementClassName := 'search-list';
    divUser.HeightStyle := ssAuto;
    divUser.WidthStyle := ssAuto;
    divUser.ChildOrder := 1;
    divUser.ElementPosition := epIgnore;
    divUser.ElementFont := efCSS;
    divUser.Role := '';
    divUser.Visible := False;
    wlUser.SetParentComponent(divUser);
    wlUser.Name := 'wlUser';
    wlUser.Left := 15;
    wlUser.Top := 64;
    wlUser.Width := 300;
    wlUser.Height := 33;
    wlUser.HeightStyle := ssAuto;
    wlUser.WidthStyle := ssAuto;
    wlUser.HeightPercent := 100.000000000000000000;
    wlUser.WidthPercent := 100.000000000000000000;
    wlUser.ChildOrder := 1;
    wlUser.ElementFont := efCSS;
    wlUser.ElementPosition := epIgnore;
    wlUser.Items.Clear;
    with wlUser.Items.Add do
    begin
    end;
    SetEvent(wlUser, Self, 'OnItemClick', 'wlUserItemClick');
    WebHTMLDiv1.SetParentComponent(divUser);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 3;
    WebHTMLDiv1.Top := 3;
    WebHTMLDiv1.Width := 100;
    WebHTMLDiv1.Height := 41;
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.HTML.BeginUpdate;
    try
      WebHTMLDiv1.HTML.Clear;
      WebHTMLDiv1.HTML.Add('Mitglieder');
    finally
      WebHTMLDiv1.HTML.EndUpdate;
    end;
    WebHTMLDiv1.Role := '';
    divLektionen.SetParentComponent(divWrapper);
    divLektionen.Name := 'divLektionen';
    divLektionen.Left := 13;
    divLektionen.Top := 167;
    divLektionen.Width := 318;
    divLektionen.Height := 103;
    divLektionen.ElementClassName := 'search-list';
    divLektionen.HeightStyle := ssAuto;
    divLektionen.WidthStyle := ssAuto;
    divLektionen.ChildOrder := 2;
    divLektionen.ElementPosition := epIgnore;
    divLektionen.ElementFont := efCSS;
    divLektionen.Role := '';
    divLektionen.Visible := False;
    wlLektionen.SetParentComponent(divLektionen);
    wlLektionen.Name := 'wlLektionen';
    wlLektionen.Left := 15;
    wlLektionen.Top := 67;
    wlLektionen.Width := 300;
    wlLektionen.Height := 33;
    wlLektionen.HeightStyle := ssAuto;
    wlLektionen.WidthStyle := ssAuto;
    wlLektionen.HeightPercent := 100.000000000000000000;
    wlLektionen.WidthPercent := 100.000000000000000000;
    wlLektionen.ChildOrder := 1;
    wlLektionen.ElementFont := efCSS;
    wlLektionen.ElementPosition := epIgnore;
    wlLektionen.Items.Clear;
    with wlLektionen.Items.Add do
    begin
    end;
    SetEvent(wlLektionen, Self, 'OnItemClick', 'wlUserItemClick');
    WebHTMLDiv4.SetParentComponent(divLektionen);
    WebHTMLDiv4.Name := 'WebHTMLDiv4';
    WebHTMLDiv4.Left := 3;
    WebHTMLDiv4.Top := 3;
    WebHTMLDiv4.Width := 100;
    WebHTMLDiv4.Height := 41;
    WebHTMLDiv4.HeightStyle := ssAuto;
    WebHTMLDiv4.WidthStyle := ssAuto;
    WebHTMLDiv4.ElementPosition := epIgnore;
    WebHTMLDiv4.ElementFont := efCSS;
    WebHTMLDiv4.HTML.BeginUpdate;
    try
      WebHTMLDiv4.HTML.Clear;
      WebHTMLDiv4.HTML.Add('Lektionen');
    finally
      WebHTMLDiv4.HTML.EndUpdate;
    end;
    WebHTMLDiv4.Role := '';
    divGruppen.SetParentComponent(divWrapper);
    divGruppen.Name := 'divGruppen';
    divGruppen.Left := 3;
    divGruppen.Top := 385;
    divGruppen.Width := 318;
    divGruppen.Height := 103;
    divGruppen.ElementClassName := 'search-list';
    divGruppen.HeightStyle := ssAuto;
    divGruppen.WidthStyle := ssAuto;
    divGruppen.ChildOrder := 3;
    divGruppen.ElementPosition := epIgnore;
    divGruppen.ElementFont := efCSS;
    divGruppen.Role := '';
    divGruppen.Visible := False;
    wlGruppen.SetParentComponent(divGruppen);
    wlGruppen.Name := 'wlGruppen';
    wlGruppen.Left := 15;
    wlGruppen.Top := 67;
    wlGruppen.Width := 300;
    wlGruppen.Height := 33;
    wlGruppen.HeightStyle := ssAuto;
    wlGruppen.WidthStyle := ssAuto;
    wlGruppen.HeightPercent := 100.000000000000000000;
    wlGruppen.WidthPercent := 100.000000000000000000;
    wlGruppen.ChildOrder := 1;
    wlGruppen.ElementFont := efCSS;
    wlGruppen.ElementPosition := epIgnore;
    wlGruppen.Items.Clear;
    with wlGruppen.Items.Add do
    begin
    end;
    SetEvent(wlGruppen, Self, 'OnItemClick', 'wlUserItemClick');
    WebHTMLDiv5.SetParentComponent(divGruppen);
    WebHTMLDiv5.Name := 'WebHTMLDiv5';
    WebHTMLDiv5.Left := 3;
    WebHTMLDiv5.Top := 3;
    WebHTMLDiv5.Width := 100;
    WebHTMLDiv5.Height := 41;
    WebHTMLDiv5.HeightStyle := ssAuto;
    WebHTMLDiv5.WidthStyle := ssAuto;
    WebHTMLDiv5.ElementPosition := epIgnore;
    WebHTMLDiv5.ElementFont := efCSS;
    WebHTMLDiv5.HTML.BeginUpdate;
    try
      WebHTMLDiv5.HTML.Clear;
      WebHTMLDiv5.HTML.Add('Gruppen');
    finally
      WebHTMLDiv5.HTML.EndUpdate;
    end;
    WebHTMLDiv5.Role := '';
    divBlogs.SetParentComponent(divWrapper);
    divBlogs.Name := 'divBlogs';
    divBlogs.Left := 3;
    divBlogs.Top := 603;
    divBlogs.Width := 318;
    divBlogs.Height := 103;
    divBlogs.ElementClassName := 'search-list';
    divBlogs.HeightStyle := ssAuto;
    divBlogs.WidthStyle := ssAuto;
    divBlogs.ChildOrder := 6;
    divBlogs.ElementPosition := epIgnore;
    divBlogs.ElementFont := efCSS;
    divBlogs.Role := '';
    divBlogs.Visible := False;
    wlBlogs.SetParentComponent(divBlogs);
    wlBlogs.Name := 'wlBlogs';
    wlBlogs.Left := 3;
    wlBlogs.Top := 50;
    wlBlogs.Width := 300;
    wlBlogs.Height := 33;
    wlBlogs.HeightStyle := ssAuto;
    wlBlogs.WidthStyle := ssAuto;
    wlBlogs.HeightPercent := 100.000000000000000000;
    wlBlogs.WidthPercent := 100.000000000000000000;
    wlBlogs.ChildOrder := 1;
    wlBlogs.ElementFont := efCSS;
    wlBlogs.ElementPosition := epIgnore;
    wlBlogs.Items.Clear;
    with wlBlogs.Items.Add do
    begin
    end;
    SetEvent(wlBlogs, Self, 'OnItemClick', 'wlUserItemClick');
    WebHTMLDiv6.SetParentComponent(divBlogs);
    WebHTMLDiv6.Name := 'WebHTMLDiv6';
    WebHTMLDiv6.Left := 3;
    WebHTMLDiv6.Top := 3;
    WebHTMLDiv6.Width := 100;
    WebHTMLDiv6.Height := 41;
    WebHTMLDiv6.HeightStyle := ssAuto;
    WebHTMLDiv6.WidthStyle := ssAuto;
    WebHTMLDiv6.ElementPosition := epIgnore;
    WebHTMLDiv6.ElementFont := efCSS;
    WebHTMLDiv6.HTML.BeginUpdate;
    try
      WebHTMLDiv6.HTML.Clear;
      WebHTMLDiv6.HTML.Add('Blogs');
    finally
      WebHTMLDiv6.HTML.EndUpdate;
    end;
    WebHTMLDiv6.Role := '';
    divEvents.SetParentComponent(divWrapper);
    divEvents.Name := 'divEvents';
    divEvents.Left := 3;
    divEvents.Top := 491;
    divEvents.Width := 318;
    divEvents.Height := 103;
    divEvents.ElementClassName := 'search-list';
    divEvents.HeightStyle := ssAuto;
    divEvents.WidthStyle := ssAuto;
    divEvents.ChildOrder := 5;
    divEvents.ElementPosition := epIgnore;
    divEvents.ElementFont := efCSS;
    divEvents.Role := '';
    divEvents.Visible := False;
    wlEvents.SetParentComponent(divEvents);
    wlEvents.Name := 'wlEvents';
    wlEvents.Left := 15;
    wlEvents.Top := 67;
    wlEvents.Width := 300;
    wlEvents.Height := 33;
    wlEvents.HeightStyle := ssAuto;
    wlEvents.WidthStyle := ssAuto;
    wlEvents.HeightPercent := 100.000000000000000000;
    wlEvents.WidthPercent := 100.000000000000000000;
    wlEvents.ChildOrder := 1;
    wlEvents.ElementFont := efCSS;
    wlEvents.ElementPosition := epIgnore;
    wlEvents.Items.Clear;
    with wlEvents.Items.Add do
    begin
    end;
    SetEvent(wlEvents, Self, 'OnItemClick', 'wlUserItemClick');
    WebHTMLDiv3.SetParentComponent(divEvents);
    WebHTMLDiv3.Name := 'WebHTMLDiv3';
    WebHTMLDiv3.Left := 3;
    WebHTMLDiv3.Top := 3;
    WebHTMLDiv3.Width := 100;
    WebHTMLDiv3.Height := 41;
    WebHTMLDiv3.HeightStyle := ssAuto;
    WebHTMLDiv3.WidthStyle := ssAuto;
    WebHTMLDiv3.ElementPosition := epIgnore;
    WebHTMLDiv3.ElementFont := efCSS;
    WebHTMLDiv3.HTML.BeginUpdate;
    try
      WebHTMLDiv3.HTML.Clear;
      WebHTMLDiv3.HTML.Add('Veranstaltungen');
    finally
      WebHTMLDiv3.HTML.EndUpdate;
    end;
    WebHTMLDiv3.Role := '';
    divTutorials.SetParentComponent(divWrapper);
    divTutorials.Name := 'divTutorials';
    divTutorials.Left := 3;
    divTutorials.Top := 276;
    divTutorials.Width := 318;
    divTutorials.Height := 103;
    divTutorials.ElementClassName := 'search-list';
    divTutorials.HeightStyle := ssAuto;
    divTutorials.WidthStyle := ssAuto;
    divTutorials.ChildOrder := 3;
    divTutorials.ElementPosition := epIgnore;
    divTutorials.ElementFont := efCSS;
    divTutorials.Role := '';
    divTutorials.Visible := False;
    wlTutorials.SetParentComponent(divTutorials);
    wlTutorials.Name := 'wlTutorials';
    wlTutorials.Left := 15;
    wlTutorials.Top := 67;
    wlTutorials.Width := 300;
    wlTutorials.Height := 33;
    wlTutorials.HeightStyle := ssAuto;
    wlTutorials.WidthStyle := ssAuto;
    wlTutorials.HeightPercent := 100.000000000000000000;
    wlTutorials.WidthPercent := 100.000000000000000000;
    wlTutorials.ChildOrder := 1;
    wlTutorials.ElementFont := efCSS;
    wlTutorials.ElementPosition := epIgnore;
    wlTutorials.Items.Clear;
    with wlTutorials.Items.Add do
    begin
    end;
    SetEvent(wlTutorials, Self, 'OnItemClick', 'wlUserItemClick');
    WebHTMLDiv8.SetParentComponent(divTutorials);
    WebHTMLDiv8.Name := 'WebHTMLDiv8';
    WebHTMLDiv8.Left := 3;
    WebHTMLDiv8.Top := 3;
    WebHTMLDiv8.Width := 100;
    WebHTMLDiv8.Height := 41;
    WebHTMLDiv8.HeightStyle := ssAuto;
    WebHTMLDiv8.WidthStyle := ssAuto;
    WebHTMLDiv8.ElementPosition := epIgnore;
    WebHTMLDiv8.ElementFont := efCSS;
    WebHTMLDiv8.HTML.BeginUpdate;
    try
      WebHTMLDiv8.HTML.Clear;
      WebHTMLDiv8.HTML.Add('Anleitungen');
    finally
      WebHTMLDiv8.HTML.EndUpdate;
    end;
    WebHTMLDiv8.Role := '';
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 152;
    Client.Top := 124;
  finally
    divWrapper.AfterLoadDFMValues;
    divSuchen.AfterLoadDFMValues;
    WebHTMLDiv22.AfterLoadDFMValues;
    WebHTMLDiv71.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    btnShowResult.AfterLoadDFMValues;
    edSuchen.AfterLoadDFMValues;
    divUser.AfterLoadDFMValues;
    wlUser.AfterLoadDFMValues;
    WebHTMLDiv1.AfterLoadDFMValues;
    divLektionen.AfterLoadDFMValues;
    wlLektionen.AfterLoadDFMValues;
    WebHTMLDiv4.AfterLoadDFMValues;
    divGruppen.AfterLoadDFMValues;
    wlGruppen.AfterLoadDFMValues;
    WebHTMLDiv5.AfterLoadDFMValues;
    divBlogs.AfterLoadDFMValues;
    wlBlogs.AfterLoadDFMValues;
    WebHTMLDiv6.AfterLoadDFMValues;
    divEvents.AfterLoadDFMValues;
    wlEvents.AfterLoadDFMValues;
    WebHTMLDiv3.AfterLoadDFMValues;
    divTutorials.AfterLoadDFMValues;
    wlTutorials.AfterLoadDFMValues;
    WebHTMLDiv8.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.
