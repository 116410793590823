unit uGlobal;

interface
  uses  SysUtils, StrUtils, vcl.Dialogs;

const //Staus zur Anzeiger der User
  _FS_MeineAnfragen  = 0;
  _FS_FremdeAnfragen = 1;
  _FS_AlleAnfragen   = 2;
  _FS_Freunde        = 3;
  _FS_Alle           = 4;

const  //Usertypen
  _UT_User   = 0;
  _UT_Gruppe = 1;
  _UT_Event  = 2;

const
  _GRP_Offen       = 1;
  _GRP_geschlossen = 2;
  _GRP_Geheim      = 3;

const
  _Neuste_Medien = -10;


const // Holder_typ für Medien
  _HT_Album = '1';
  _HT_Post  = '2';
  _HT_Kurs  = '3';
  _HT_Blog  = '4';
  _HT_Media = '5';
  _HT_User  = '6';
  _HT_Gruppe= '7';
  _HT_Event = '8';

type
  TEMailData = record
    Text_ID,
    ID,
    Name,
    Vorname,
    Nickname,
    EMail,
    Passwort,
    Login,
    Event,
    Von,
    Bis:string;
end;


function IsValidEmailCharacters(const Email: string): Boolean;
function IsEmailValid(const Email: string): Boolean;
function str2JSon(const s:string):string;

implementation


function CreateParameterizedInsertStatement(const InsertStatement: string): string;
var
  PosValuesStart, PosValuesEnd: Integer;
  TableName, FieldNames, ValueNames: string;
begin
  // Finden der Positionen von 'VALUES' im Insert-Statement
  PosValuesStart := Pos('VALUES', UpperCase(InsertStatement));
  if PosValuesStart = 0 then
    Exit('');

  // Extrahieren des Tabellennamens und der Feldnamen aus dem Insert-Statement
  TableName := Trim(Copy(InsertStatement, 1, PosValuesStart - 1));
  FieldNames := Copy(InsertStatement, Length(TableName) + 1, PosValuesStart - Length(TableName) - 1);
  FieldNames := Trim(Copy(FieldNames, Pos('(', FieldNames) + 1, Length(FieldNames)));
  FieldNames := Trim(Copy(FieldNames, 1, Pos(')', FieldNames) - 1));

  // Extrahieren der Werte aus dem Insert-Statement
  ValueNames := Trim(Copy(InsertStatement, PosValuesStart + 6, Length(InsertStatement) - PosValuesStart - 5));
  PosValuesEnd := Pos(')', ValueNames);
  if PosValuesEnd > 0 then
    ValueNames := Copy(ValueNames, 1, PosValuesEnd - 1);

  // Erstellen des parameterisierten Insert-Statements
  Result := 'INSERT INTO ' + TableName + ' (' + FieldNames + ') VALUES (' + StringReplace(ValueNames, ',', ', :', [rfReplaceAll]) + ')';
end;

function str2JSon(const s:string):string;
begin
  result := StringReplace(s, '\', '\\', [rfReplaceAll]);
  result := StringReplace(result, '"', '\"', [rfReplaceAll]);
  result := StringReplace(result, #10, '\n', [rfReplaceAll]);
  result := StringReplace(result, #13, '\r', [rfReplaceAll]);
end;

function IsValidEmailCharacters(const Email: string): Boolean;
const
  ValidChars = ['A'..'Z', 'a'..'z', '0'..'9', '!', '#', '$', '%', '&', '''', '*', '+', '-', '/', '=', '?', '^', '_', '`', '{', '|', '}', '~', '.', '@'];
var
  i: Integer;
begin
  Result := False;
  for i := 1 to Length(Email) do
  begin
    if not (Email[i] in ValidChars) then
      Exit; // Zeichen gefunden, das nicht erlaubt ist
  end;
  Result := True; // Alle Zeichen sind gültig
end;

function IsEmailValid(const Email: string): Boolean;
var
  AtPos, DotPos: Integer;
begin

  if (not IsValidEmailCharacters(Email))
  then begin
    Result := False;
    Exit;
  end;

  // Überprüfe, ob die E-Mail-Adresse ein @-Symbol enthält
  AtPos := Pos('@', Email);
  if AtPos <= 1
  then begin
    Result := False;
    Exit;
  end;

  // Überprüfe, ob die E-Mail-Adresse mindestens einen Punkt nach dem @-Symbol enthält
  DotPos := PosEx('.', Email, AtPos);
  if DotPos <= (AtPos + 1)
  then begin
    Result := False;
    Exit;
  end;

  // Überprüfe, ob der Teil nach dem letzten Punkt mindestens 2 Zeichen lang ist
  if Length(Email) - DotPos < 2
  then begin
    Result := False;
    Exit;
  end;


  // Überprüfe, ob der Teil nach dem letzten Punkt maximal 3 Zeichen lang ist
//  if Length(Email) - DotPos > 3
//  then begin
//    Result := False;
//    Exit;
//  end;


  // Überprüfe, ob die E-Mail-Adresse keine Leerzeichen enthält
  if Pos(' ', Email) > 0
  then begin
    Result := False;
    Exit;
  end;
showmessage('6');

  // Wenn alle Bedingungen erfüllt sind, ist die E-Mail-Adresse wahrscheinlich gültig
  Result := True;
end;

end.
