unit ufrImgPosition;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Imaging.pngimage, Vcl.Controls,
  WEBLib.ExtCtrls, WEBLib.WebCtrls;

type
  TfrImgPosition = class(TFrame)
    WebHTMLDiv1: THTMLDiv;
    WebHTMLDiv2: THTMLDiv;
    divUp: TImageControl;
    WebHTMLDiv3: THTMLDiv;
    divLeft: TImageControl;
    divOK: TImageControl;
    divRight: TImageControl;
    WebHTMLDiv4: THTMLDiv;
    divDown: TImageControl;

    Timer: TTimer;
    [async] procedure Click(Sender: TObject); async;
    procedure TimerTimer(Sender: TObject);
    procedure WebFrameMouseLeave(Sender: TObject);
    procedure WebFrameMouseEnter(Sender: TObject);
  protected
    procedure Loaded(); override;
  private
    FContainer:TControl;
    FImg:TImageControl;
    FID, FTab, FField:string;
  public
    class function Show(AOwner, AParent:TControl; AImg:TImageControl; ATab, AField, AID:string):TfrImgPosition;
  protected procedure LoadDFMValues; override; end;

var
  frImgPosition: TfrImgPosition;

implementation
   uses uDatenmodul;

{$R *.dfm}
class function TfrImgPosition.Show(AOwner, AParent:TControl; AImg:TImageControl; ATab, AField, AID:string):TfrImgPosition;
Var
  iTop, iHeight:integer;
  s:string;
begin

  result := TfrImgPosition.Create(AOwner);
  result.Parent:= AParent;
  result.LoadFromForm;

  iTop := AImg.top + AImg.Height - 110;

  THTMLDiv(result).elementhandle.style.setProperty('position', 'absolute');
  THTMLDiv(result).elementhandle.style.setProperty('width','110px');
  THTMLDiv(result).elementhandle.style.setProperty('height','110px');
//  TWebHTMLDiv(result).elementhandle.style.setProperty('bottom', '0px');
  THTMLDiv(result).elementhandle.style.setProperty('top', iTop.tostring + 'px');
  THTMLDiv(result).elementhandle.style.setProperty('right', '15px');
  THTMLDiv(result).elementhandle.style.removeProperty('left');
//  TWebHTMLDiv(result).elementhandle.style.removeProperty('top');

  result.FImg   := AImg;
  result.FID    := AId;
  result.FTab   := ATab;
  result.FField := AField;

end;

procedure TfrImgPosition.TimerTimer(Sender: TObject);
begin
  self.Visible := false;
end;

procedure TfrImgPosition.WebFrameMouseEnter(Sender: TObject);
begin
  Timer.Enabled := false;
end;

procedure TfrImgPosition.WebFrameMouseLeave(Sender: TObject);
begin
  Timer.Enabled := true;
end;

procedure TfrImgPosition.Click(Sender: TObject);
Var
  s:string;
  ileft, iTop:integer;
  F:TForm;
begin
  s := THTMLDiv(FImg).ElementHandle.style.getPropertyValue('object-position');
  if s > ''
  then begin
    s := copy(s, 5, length(s)-1);
    ileft := strtoint(copy(s,0, pos('px',s)-1));
    s := copy(s,pos('px',s)+2);
    s := copy(s,5, pos('px',s)-1);
    iTop := s.ToInteger;
  end
  else begin
    iTop := 0;
    iLeft:= 0;
  end;

  if sender = divUp
  then iTop := iTop - 5;
  if sender = divDown
  then iTop := iTop + 5;
  if sender = divLeft
  then ileft := iLeft - 5;
  if sender = divRight
  then iLeft := ileft + 5;

  s := 'left ' + iLeft.ToString + 'px top ' + iTop.tostring + 'px';

  THTMLDiv(FImg).ElementHandle.style.setProperty('object-position', s);

  if sender = divOK
  then begin
    await(datenmodul.easyExec('UPDATE ' + FTab + ' set ' + FField + ' = ''' + s + ''' WHERE ID = ' + fID));
    timer.enabled := true;
    visible:= false;
  end;

end;

procedure TfrImgPosition.Loaded();
var
  el: TJSHTMLElement;
begin
  inherited;
  TControl(self).ElementPosition := epAbsolute;
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);

end;

procedure TfrImgPosition.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  divUp := TImageControl.Create(Self);
  WebHTMLDiv3 := THTMLDiv.Create(Self);
  divLeft := TImageControl.Create(Self);
  divOK := TImageControl.Create(Self);
  divRight := TImageControl.Create(Self);
  WebHTMLDiv4 := THTMLDiv.Create(Self);
  divDown := TImageControl.Create(Self);
  Timer := TTimer.Create(Self);

  WebHTMLDiv1.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  divUp.BeforeLoadDFMValues;
  WebHTMLDiv3.BeforeLoadDFMValues;
  divLeft.BeforeLoadDFMValues;
  divOK.BeforeLoadDFMValues;
  divRight.BeforeLoadDFMValues;
  WebHTMLDiv4.BeforeLoadDFMValues;
  divDown.BeforeLoadDFMValues;
  Timer.BeforeLoadDFMValues;
  try
    Name := 'frImgPosition';
    Left := 0;
    Top := 0;
    Width := 357;
    Height := 250;
    TabOrder := 0;
    SetEvent(Self, 'OnMouseEnter', 'WebFrameMouseEnter');
    SetEvent(Self, 'OnMouseLeave', 'WebFrameMouseLeave');
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 3;
    WebHTMLDiv1.Top := 3;
    WebHTMLDiv1.Width := 337;
    WebHTMLDiv1.Height := 225;
    WebHTMLDiv1.ElementClassName := 'imgposition';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ChildOrder := 4;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv2.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 19;
    WebHTMLDiv2.Top := 3;
    WebHTMLDiv2.Width := 294;
    WebHTMLDiv2.Height := 78;
    WebHTMLDiv2.ElementClassName := 'imgpositionquer';
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ElementPosition := epIgnore;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.Role := '';
    divUp.SetParentComponent(WebHTMLDiv2);
    divUp.Name := 'divUp';
    divUp.Left := 3;
    divUp.Top := 0;
    divUp.Width := 63;
    divUp.Height := 28;
    divUp.ElementClassName := 'imgpositionbtn';
    divUp.HeightStyle := ssAuto;
    divUp.WidthStyle := ssAuto;
    divUp.HeightPercent := 100.000000000000000000;
    divUp.WidthPercent := 100.000000000000000000;
    divUp.ElementFont := efCSS;
    divUp.ElementPosition := epIgnore;
    SetEvent(divUp, Self, 'OnClick', 'Click');
    divUp.Picture.LoadFromFile('ufrImgPosition.WebHTMLDiv2.divUp.Picture.png');
    WebHTMLDiv3.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv3.Name := 'WebHTMLDiv3';
    WebHTMLDiv3.Left := 16;
    WebHTMLDiv3.Top := 94;
    WebHTMLDiv3.Width := 305;
    WebHTMLDiv3.Height := 63;
    WebHTMLDiv3.ElementClassName := 'imgpositionquer';
    WebHTMLDiv3.HeightStyle := ssAuto;
    WebHTMLDiv3.WidthStyle := ssAuto;
    WebHTMLDiv3.ChildOrder := 1;
    WebHTMLDiv3.ElementPosition := epIgnore;
    WebHTMLDiv3.ElementFont := efCSS;
    WebHTMLDiv3.Role := '';
    divLeft.SetParentComponent(WebHTMLDiv3);
    divLeft.Name := 'divLeft';
    divLeft.Left := 3;
    divLeft.Top := 3;
    divLeft.Width := 63;
    divLeft.Height := 28;
    divLeft.ElementClassName := 'imgpositionbtn';
    divLeft.HeightStyle := ssAuto;
    divLeft.WidthStyle := ssAuto;
    divLeft.HeightPercent := 100.000000000000000000;
    divLeft.WidthPercent := 100.000000000000000000;
    divLeft.ElementFont := efCSS;
    divLeft.ElementPosition := epIgnore;
    SetEvent(divLeft, Self, 'OnClick', 'Click');
    divLeft.Picture.LoadFromFile('ufrImgPosition.WebHTMLDiv3.divLeft.Picture.png');
    divOK.SetParentComponent(WebHTMLDiv3);
    divOK.Name := 'divOK';
    divOK.Left := 115;
    divOK.Top := 3;
    divOK.Width := 63;
    divOK.Height := 28;
    divOK.ElementClassName := 'imgpositionbtn';
    divOK.HeightStyle := ssAuto;
    divOK.WidthStyle := ssAuto;
    divOK.HeightPercent := 100.000000000000000000;
    divOK.WidthPercent := 100.000000000000000000;
    divOK.ChildOrder := 1;
    divOK.ElementFont := efCSS;
    divOK.ElementPosition := epIgnore;
    SetEvent(divOK, Self, 'OnClick', 'Click');
    divOK.Picture.LoadFromFile('ufrImgPosition.WebHTMLDiv3.divOK.Picture.png');
    divRight.SetParentComponent(WebHTMLDiv3);
    divRight.Name := 'divRight';
    divRight.Left := 219;
    divRight.Top := 3;
    divRight.Width := 63;
    divRight.Height := 28;
    divRight.ElementClassName := 'imgpositionbtn';
    divRight.HeightStyle := ssAuto;
    divRight.WidthStyle := ssAuto;
    divRight.HeightPercent := 100.000000000000000000;
    divRight.WidthPercent := 100.000000000000000000;
    divRight.ChildOrder := 2;
    divRight.ElementFont := efCSS;
    divRight.ElementPosition := epIgnore;
    SetEvent(divRight, Self, 'OnClick', 'Click');
    divRight.Picture.LoadFromFile('ufrImgPosition.WebHTMLDiv3.divRight.Picture.png');
    WebHTMLDiv4.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv4.Name := 'WebHTMLDiv4';
    WebHTMLDiv4.Left := 16;
    WebHTMLDiv4.Top := 163;
    WebHTMLDiv4.Width := 305;
    WebHTMLDiv4.Height := 59;
    WebHTMLDiv4.ElementClassName := 'imgpositionquer';
    WebHTMLDiv4.HeightStyle := ssAuto;
    WebHTMLDiv4.WidthStyle := ssAuto;
    WebHTMLDiv4.ChildOrder := 2;
    WebHTMLDiv4.ElementPosition := epIgnore;
    WebHTMLDiv4.ElementFont := efCSS;
    WebHTMLDiv4.Role := '';
    divDown.SetParentComponent(WebHTMLDiv4);
    divDown.Name := 'divDown';
    divDown.Left := 115;
    divDown.Top := 11;
    divDown.Width := 63;
    divDown.Height := 28;
    divDown.ElementClassName := 'imgpositionbtn';
    divDown.HeightStyle := ssAuto;
    divDown.WidthStyle := ssAuto;
    divDown.HeightPercent := 100.000000000000000000;
    divDown.WidthPercent := 100.000000000000000000;
    divDown.ElementFont := efCSS;
    divDown.ElementPosition := epIgnore;
    SetEvent(divDown, Self, 'OnClick', 'Click');
    divDown.Picture.LoadFromFile('ufrImgPosition.WebHTMLDiv4.divDown.Picture.png');
    Timer.SetParentComponent(Self);
    Timer.Name := 'Timer';
    SetEvent(Timer, Self, 'OnTimer', 'TimerTimer');
    Timer.Left := 259;
    Timer.Top := 163;
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    divUp.AfterLoadDFMValues;
    WebHTMLDiv3.AfterLoadDFMValues;
    divLeft.AfterLoadDFMValues;
    divOK.AfterLoadDFMValues;
    divRight.AfterLoadDFMValues;
    WebHTMLDiv4.AfterLoadDFMValues;
    divDown.AfterLoadDFMValues;
    Timer.AfterLoadDFMValues;
  end;
end;

end.
