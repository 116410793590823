unit uRichEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.ComCtrls,
  Vcl.Controls, WEBLib.WebCtrls, utb, uGlobal, WEBLib.Buttons, WebLib.Modules,
  WEBLib.EditAutocomplete, Vcl.Imaging.pngimage, WEBLib.ExtCtrls, WebLib.Clipboard;

type
  TEditCollection = record
    Edit:TEdit;
    Caption:string;
    Required:boolean;
    Typ:integer;
    max_length:integer;
  end;

type
  TFieldCollection = record
    Caption:string;
    Text:string;
    Required:boolean;
    Typ:integer;
    max_length:integer;
  end;

type
  TfrmRichEdit = class(TForm)
    divWrapper: THTMLDiv;
    WebHTMLDiv11: THTMLDiv;
    btnClose: TImageControl;
    lblTitel: TLabel;
    divTitel: THTMLDiv;
    EditButtons: TRichEditToolBar;
    reText: TRichEdit;
    btnFoto: TImageControl;
    WebHTMLDiv1: THTMLDiv;
    pFooter: THTMLDiv;
    btnSave: TButton;
    btnCAncel: TButton;
    WebHTMLDiv3: THTMLDiv;
    WebLabel2: TLabel;
    WebHTMLDiv4: THTMLDiv;
    edHeight: TEditAutoComplete;
    WebHTMLDiv5: THTMLDiv;
    WebLabel3: TLabel;
    WebHTMLDiv6: THTMLDiv;
    edWidth: TEditAutoComplete;
    WebClipboard1: TClipboard;
    btnVideo: TImageControl;
    WebYoutube1: TYoutube;
    procedure WebFormCreate(Sender: TObject);
    [async] procedure btnSaveClick(Sender: TObject); async;
    procedure btnCancelClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    [async] procedure btnFotoClick(Sender: TObject); async;
    procedure btnVideoClick(Sender: TObject);
  private
    FID:integer;
    FImage:integer;
    FCallback:TCallbackArray;
    FContainer:THtmlDiv;
  public
    class function Exec(AId:integer; AText:string; AImage:integer; AHeight, AWidth:string; ACallbackArray:TCallbackArray):integer;
  protected procedure LoadDFMValues; override; end;

var
  frmRichEdit: TfrmRichEdit;

implementation
   uses uMain, uMedienAuswahl, uDatenmodul;
{$R *.dfm}

procedure TfrmRichEdit.btnCancelClick(Sender: TObject);
begin
  close;
end;

procedure TfrmRichEdit.btnFotoClick(Sender: TObject);
  [async]
  procedure Callback(s:string); async;
  Var
    j:TJ;
  begin

    j := TJ.create(await(datenmodul.easysql('Select TITEL from Medien where ID = ' + s )));

    s := '{Bild=' + s + ';W=100%;}';
    s := s + '<div><font size="3">';

    if j.hasValue
    then s := s + j.value('TITEL')
    else s := s + 'Beschreibung...';

    s:= s + '</font></div>';

    reText.SetFocus;
    retext.lines.Text := retext.lines.Text + s;

  end;
var
  s:string;
  i:integer;
begin
  reText.SetFocus;

  TfrmMedienAuswahl.Fotos(Mainform.Holder.ID, Mainform.ME.ID, Mainform.ME.Album, 0, 0, 0, 0, @Callback, 2)
end;

procedure TfrmRichEdit.btnSaveClick(Sender: TObject);
begin
  if assigned(FCallback)
  then FCallback([FID.ToString, reText.GetContent, '0', edHeight.Text, edWidth.Text]);

  close;

end;

procedure TfrmRichEdit.btnVideoClick(Sender: TObject);
  [async]
  procedure CBVideo(s:string); async;
  Var
    j:TJ;
  begin

    j := TJ.create(await(datenmodul.easysql('Select TITEL from Medien where ID = ' + s )));

    s := '{Video=' + s + ';W=100%;H=100%;}';
    s := s + '<div><font size="3">';

    if j.hasValue
    then s := s + j.value('TITEL')
    else s := s + 'Beschreibung...';

    s:= s + '</font></div>';

    reText.SetFocus;
    retext.lines.Text := retext.lines.Text + s;

  end;
var
  s:string;
  i:integer;
begin
  reText.SetFocus;

//  TfrmMedienAuswahl.Fotos(Mainform.Holder.ID,  Mainform.ME.ID, Mainform.ME.Album, 0, 0, 0, 0, @Callback, 2)
  TfrmMedienAuswahl.Videos(Mainform.Holder.ID, Mainform.ME.ID, Mainform.ME.Album, 0, 0, 0, 0, @CBVideo, 2);

end;

class function TfrmRichEdit.Exec(AId:integer; AText:string; AImage:integer; AHeight, AWidth:string; ACallbackArray:TCallbackArray):integer;

  [async] procedure AfterCreate(AForm: TObject); async;
  Var
    s:string;
    i:integer;
    divHeader:THTMLDiv;
    edText: TEdit;
  begin
    TfrmRichEdit(AForm).ElementHandle.style.removeProperty('position');
    TfrmRichEdit(AForm).divWrapper.ElementHandle.style.removeProperty('height');
    TfrmRichEdit(AForm).FID := AId;
    TfrmRichEdit(AForm).FImage := AImage;
    TfrmRichEdit(AForm).reText.Lines.Text := AText;
    TfrmRichEdit(AForm).edHeight.Text := AHeight;
    TfrmRichEdit(AForm).edWidth.Text := AWidth;
  end;

Var
  frm:TfrmRichEdit;
  FConti:THtmlDiv;
begin
  FConti := THtmlDiv.Create(Mainform);
  FConti.Parent := Mainform;
  FConti.ElementClassName := 'editor_transparent';
  FConti.HeightStyle:= ssAuto;
  FConti.WidthStyle := ssAuto;
  FConti.ElementFont := efCSS;
  FConti.ElementHandle.style.removeProperty('height');
  frm := TfrmRichEdit.CreateNew(FConti.ElementID, @AfterCreate);
  frm.FContainer := FConti;
  frm.FCallback  := ACallbackArray;
end;

procedure TfrmRichEdit.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  FreeAndNil(FContainer);
end;

procedure TfrmRichEdit.WebFormCreate(Sender: TObject);
 var
  el: TJSHTMLElement;
begin
  inherited;
  TControl(self).ElementPosition := epIgnore;
  reText.ElementHandle.style.removeProperty('position');

  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-style');
  el.style.removeProperty('font-size');
end;

procedure TfrmRichEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divWrapper := THTMLDiv.Create(Self);
  WebHTMLDiv11 := THTMLDiv.Create(Self);
  btnClose := TImageControl.Create(Self);
  lblTitel := TLabel.Create(Self);
  divTitel := THTMLDiv.Create(Self);
  btnFoto := TImageControl.Create(Self);
  btnVideo := TImageControl.Create(Self);
  EditButtons := TRichEditToolBar.Create('');
  WebYoutube1 := TYoutube.Create(Self);
  reText := TRichEdit.Create(Self);
  WebHTMLDiv1 := THTMLDiv.Create(Self);
  WebHTMLDiv3 := THTMLDiv.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebHTMLDiv4 := THTMLDiv.Create(Self);
  edHeight := TEditAutoComplete.Create(Self);
  WebHTMLDiv5 := THTMLDiv.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebHTMLDiv6 := THTMLDiv.Create(Self);
  edWidth := TEditAutoComplete.Create(Self);
  pFooter := THTMLDiv.Create(Self);
  btnSave := TButton.Create(Self);
  btnCAncel := TButton.Create(Self);
  WebClipboard1 := TClipboard.Create(Self);

  divWrapper.BeforeLoadDFMValues;
  WebHTMLDiv11.BeforeLoadDFMValues;
  btnClose.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  divTitel.BeforeLoadDFMValues;
  btnFoto.BeforeLoadDFMValues;
  btnVideo.BeforeLoadDFMValues;
  EditButtons.BeforeLoadDFMValues;
  WebYoutube1.BeforeLoadDFMValues;
  reText.BeforeLoadDFMValues;
  WebHTMLDiv1.BeforeLoadDFMValues;
  WebHTMLDiv3.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebHTMLDiv4.BeforeLoadDFMValues;
  edHeight.BeforeLoadDFMValues;
  WebHTMLDiv5.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebHTMLDiv6.BeforeLoadDFMValues;
  edWidth.BeforeLoadDFMValues;
  pFooter.BeforeLoadDFMValues;
  btnSave.BeforeLoadDFMValues;
  btnCAncel.BeforeLoadDFMValues;
  WebClipboard1.BeforeLoadDFMValues;
  try
    Name := 'frmRichEdit';
    Width := 671;
    Height := 584;
    ElementFont := efCSS;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    divWrapper.SetParentComponent(Self);
    divWrapper.Name := 'divWrapper';
    divWrapper.Left := 8;
    divWrapper.Top := 8;
    divWrapper.Width := 641;
    divWrapper.Height := 561;
    divWrapper.ElementClassName := 'editor-container';
    divWrapper.HeightStyle := ssAuto;
    divWrapper.WidthStyle := ssAuto;
    divWrapper.ElementPosition := epIgnore;
    divWrapper.ElementFont := efCSS;
    divWrapper.Role := '';
    WebHTMLDiv11.SetParentComponent(divWrapper);
    WebHTMLDiv11.Name := 'WebHTMLDiv11';
    WebHTMLDiv11.Left := 14;
    WebHTMLDiv11.Top := 16;
    WebHTMLDiv11.Width := 603;
    WebHTMLDiv11.Height := 41;
    WebHTMLDiv11.ElementClassName := 'input_header';
    WebHTMLDiv11.HeightStyle := ssAuto;
    WebHTMLDiv11.WidthStyle := ssAuto;
    WebHTMLDiv11.ChildOrder := 1;
    WebHTMLDiv11.ElementPosition := epRelative;
    WebHTMLDiv11.ElementFont := efCSS;
    WebHTMLDiv11.HTML.BeginUpdate;
    try
      WebHTMLDiv11.HTML.Clear;
      WebHTMLDiv11.HTML.Add('');
    finally
      WebHTMLDiv11.HTML.EndUpdate;
    end;
    WebHTMLDiv11.Role := '';
    btnClose.SetParentComponent(WebHTMLDiv11);
    btnClose.Name := 'btnClose';
    btnClose.Left := 551;
    btnClose.Top := -3;
    btnClose.Width := 46;
    btnClose.Height := 44;
    btnClose.ElementClassName := 'input_close';
    btnClose.HeightStyle := ssAuto;
    btnClose.WidthStyle := ssAuto;
    btnClose.HeightPercent := 100.000000000000000000;
    btnClose.WidthPercent := 100.000000000000000000;
    btnClose.Anchors := [];
    btnClose.ChildOrder := 1;
    btnClose.ElementFont := efCSS;
    btnClose.ElementPosition := epIgnore;
    SetEvent(btnClose, Self, 'OnClick', 'btnCancelClick');
    btnClose.Picture.LoadFromFile('uRichEdit.WebHTMLDiv11.btnClose.Picture.png');
    lblTitel.SetParentComponent(WebHTMLDiv11);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 16;
    lblTitel.Top := 9;
    lblTitel.Width := 73;
    lblTitel.Height := 15;
    lblTitel.Caption := 'Text eingeben';
    lblTitel.ElementLabelClassName := 'editor_titel';
    lblTitel.ElementFont := efCSS;
    lblTitel.HeightStyle := ssAuto;
    lblTitel.HeightPercent := 100.000000000000000000;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.WidthPercent := 100.000000000000000000;
    divTitel.SetParentComponent(divWrapper);
    divTitel.Name := 'divTitel';
    divTitel.Left := 14;
    divTitel.Top := 63;
    divTitel.Width := 603;
    divTitel.Height := 38;
    divTitel.ElementClassName := 'edit-header';
    divTitel.HeightStyle := ssAuto;
    divTitel.WidthStyle := ssAuto;
    divTitel.ChildOrder := 2;
    divTitel.ElementPosition := epIgnore;
    divTitel.ElementFont := efCSS;
    divTitel.Role := '';
    btnFoto.SetParentComponent(divTitel);
    btnFoto.Name := 'btnFoto';
    btnFoto.Left := 486;
    btnFoto.Top := -6;
    btnFoto.Width := 46;
    btnFoto.Height := 44;
    btnFoto.Hint := 'F'#252'ge ein Foto hinzu...';
    btnFoto.ElementClassName := 'blk_side_menu_btn';
    btnFoto.HeightStyle := ssAuto;
    btnFoto.WidthStyle := ssAuto;
    btnFoto.HeightPercent := 100.000000000000000000;
    btnFoto.WidthPercent := 100.000000000000000000;
    btnFoto.Anchors := [];
    btnFoto.ChildOrder := 3;
    btnFoto.ElementFont := efCSS;
    btnFoto.ElementPosition := epIgnore;
    btnFoto.ShowHint := True;
    SetEvent(btnFoto, Self, 'OnClick', 'btnFotoClick');
    btnFoto.Picture.LoadFromFile('uRichEdit.divTitel.btnFoto.Picture.png');
    btnVideo.SetParentComponent(divTitel);
    btnVideo.Name := 'btnVideo';
    btnVideo.Left := 538;
    btnVideo.Top := -6;
    btnVideo.Width := 46;
    btnVideo.Height := 44;
    btnVideo.ElementClassName := 'blk_side_menu_btn';
    btnVideo.HeightStyle := ssAuto;
    btnVideo.WidthStyle := ssAuto;
    btnVideo.HeightPercent := 100.000000000000000000;
    btnVideo.WidthPercent := 100.000000000000000000;
    btnVideo.Anchors := [];
    btnVideo.ChildOrder := 6;
    btnVideo.ElementFont := efCSS;
    btnVideo.ElementPosition := epIgnore;
    btnVideo.ShowHint := True;
    SetEvent(btnVideo, Self, 'OnClick', 'btnVideoClick');
    btnVideo.Picture.LoadFromFile('uRichEdit.divTitel.btnVideo.Picture.png');
    EditButtons.SetParentComponent(divTitel);
    EditButtons.Name := 'EditButtons';
    EditButtons.Left := 3;
    EditButtons.Top := 0;
    EditButtons.Width := 462;
    EditButtons.Height := 35;
    EditButtons.ElementClassName := 'editbuttons';
    EditButtons.HeightStyle := ssAuto;
    EditButtons.WidthStyle := ssAuto;
    EditButtons.Color := clBtnFace;
    EditButtons.ElementFont := efCSS;
    EditButtons.ElementPosition := epRelative;
    EditButtons.Hints.BeginUpdate;
    try
      EditButtons.Hints.Clear;
      EditButtons.Hints.Add('Bold');
      EditButtons.Hints.Add('Italic');
      EditButtons.Hints.Add('Underline');
      EditButtons.Hints.Add('Strike Throught');
      EditButtons.Hints.Add('Text Color');
      EditButtons.Hints.Add('Background Color');
      EditButtons.Hints.Add('Align lef');
      EditButtons.Hints.Add('Align center');
      EditButtons.Hints.Add('Align right');
      EditButtons.Hints.Add('Numbered list');
      EditButtons.Hints.Add('List');
    finally
      EditButtons.Hints.EndUpdate;
    end;
    EditButtons.RichEdit := reText;
    EditButtons.VisibleButtons := [reFontSize,reBold,reItalic,reUnderline,reStrikeThrough,reAlignLeft,reAlignCenter,reAlignRight,reUnorderedList,reOrderedList,reForegroundColor,reHyperlink];
    WebYoutube1.SetParentComponent(divTitel);
    WebYoutube1.Name := 'WebYoutube1';
    WebYoutube1.Left := 552;
    WebYoutube1.Top := 16;
    WebYoutube1.Width := 480;
    WebYoutube1.Height := 360;
    WebYoutube1.HeightStyle := ssAuto;
    WebYoutube1.WidthStyle := ssAuto;
    WebYoutube1.AllowFullScreen := False;
    WebYoutube1.AutoPlay := False;
    WebYoutube1.ChildOrder := 3;
    reText.SetParentComponent(divWrapper);
    reText.Name := 'reText';
    reText.Left := 14;
    reText.Top := 107;
    reText.Width := 603;
    reText.Height := 286;
    reText.ElementClassName := 'blog_input';
    reText.HeightStyle := ssAuto;
    reText.WidthStyle := ssAuto;
    reText.BorderStyle := bsSingle;
    reText.ChildOrder := 2;
    reText.Color := clWhite;
    reText.WantTabs := True;
    WebHTMLDiv1.SetParentComponent(divWrapper);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 14;
    WebHTMLDiv1.Top := 399;
    WebHTMLDiv1.Width := 603;
    WebHTMLDiv1.Height := 73;
    WebHTMLDiv1.ElementClassName := 'editor-footer-div';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ChildOrder := 4;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv3.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv3.Name := 'WebHTMLDiv3';
    WebHTMLDiv3.Left := 3;
    WebHTMLDiv3.Top := 3;
    WebHTMLDiv3.Width := 214;
    WebHTMLDiv3.Height := 40;
    WebHTMLDiv3.ElementClassName := 'profil_line';
    WebHTMLDiv3.HeightStyle := ssAuto;
    WebHTMLDiv3.WidthStyle := ssAuto;
    WebHTMLDiv3.ChildOrder := 2;
    WebHTMLDiv3.ElementPosition := epRelative;
    WebHTMLDiv3.ElementFont := efCSS;
    WebHTMLDiv3.Role := '';
    WebLabel2.SetParentComponent(WebHTMLDiv3);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 20;
    WebLabel2.Top := 11;
    WebLabel2.Width := 29;
    WebLabel2.Height := 15;
    WebLabel2.Caption := 'H'#246'he';
    WebLabel2.ElementClassName := 'profil_label';
    WebLabel2.ElementFont := efCSS;
    WebLabel2.ElementPosition := epIgnore;
    WebLabel2.HeightStyle := ssAuto;
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthStyle := ssAuto;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebHTMLDiv4.SetParentComponent(WebHTMLDiv3);
    WebHTMLDiv4.Name := 'WebHTMLDiv4';
    WebHTMLDiv4.Left := 100;
    WebHTMLDiv4.Top := 3;
    WebHTMLDiv4.Width := 100;
    WebHTMLDiv4.Height := 30;
    WebHTMLDiv4.ElementClassName := 'profil_edit_container';
    WebHTMLDiv4.HeightStyle := ssAuto;
    WebHTMLDiv4.WidthStyle := ssAuto;
    WebHTMLDiv4.ChildOrder := 2;
    WebHTMLDiv4.ElementPosition := epIgnore;
    WebHTMLDiv4.ElementFont := efCSS;
    WebHTMLDiv4.Role := '';
    edHeight.SetParentComponent(WebHTMLDiv4);
    edHeight.Name := 'edHeight';
    edHeight.Left := 10;
    edHeight.Top := 2;
    edHeight.Width := 60;
    edHeight.Height := 25;
    edHeight.Margins.Left := 0;
    edHeight.Margins.Top := 0;
    edHeight.Margins.Right := 0;
    edHeight.Margins.Bottom := 0;
    edHeight.ElementClassName := 'profil_edit';
    edHeight.HeightStyle := ssAuto;
    edHeight.WidthStyle := ssAuto;
    edHeight.AutoSelect := True;
    edHeight.BorderStyle := bsSingle;
    edHeight.Color := clWhite;
    edHeight.ElementFont := efCSS;
    edHeight.ElementPosition := epRelative;
    edHeight.ShowHint := True;
    edHeight.TabStop := True;
    edHeight.TabOrder := 0;
    edHeight.Items.BeginUpdate;
    try
      edHeight.Items.Clear;
      edHeight.Items.Add('test 1');
      edHeight.Items.Add('test 2');
      edHeight.Items.Add('test 3');
    finally
      edHeight.Items.EndUpdate;
    end;
    edHeight.Text := '';
    WebHTMLDiv5.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv5.Name := 'WebHTMLDiv5';
    WebHTMLDiv5.Left := 376;
    WebHTMLDiv5.Top := 17;
    WebHTMLDiv5.Width := 217;
    WebHTMLDiv5.Height := 40;
    WebHTMLDiv5.ElementClassName := 'profil_line';
    WebHTMLDiv5.HeightStyle := ssAuto;
    WebHTMLDiv5.WidthStyle := ssAuto;
    WebHTMLDiv5.ChildOrder := 3;
    WebHTMLDiv5.ElementPosition := epRelative;
    WebHTMLDiv5.ElementFont := efCSS;
    WebHTMLDiv5.Role := '';
    WebLabel3.SetParentComponent(WebHTMLDiv5);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 20;
    WebLabel3.Top := 11;
    WebLabel3.Width := 33;
    WebLabel3.Height := 15;
    WebLabel3.Caption := 'Breite:';
    WebLabel3.ElementClassName := 'profil_label';
    WebLabel3.ElementFont := efCSS;
    WebLabel3.ElementPosition := epIgnore;
    WebLabel3.HeightStyle := ssAuto;
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthStyle := ssAuto;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebHTMLDiv6.SetParentComponent(WebHTMLDiv5);
    WebHTMLDiv6.Name := 'WebHTMLDiv6';
    WebHTMLDiv6.Left := 100;
    WebHTMLDiv6.Top := 3;
    WebHTMLDiv6.Width := 100;
    WebHTMLDiv6.Height := 30;
    WebHTMLDiv6.ElementClassName := 'profil_edit_container';
    WebHTMLDiv6.HeightStyle := ssAuto;
    WebHTMLDiv6.WidthStyle := ssAuto;
    WebHTMLDiv6.ChildOrder := 2;
    WebHTMLDiv6.ElementPosition := epIgnore;
    WebHTMLDiv6.ElementFont := efCSS;
    WebHTMLDiv6.Role := '';
    edWidth.SetParentComponent(WebHTMLDiv6);
    edWidth.Name := 'edWidth';
    edWidth.Left := 10;
    edWidth.Top := 2;
    edWidth.Width := 60;
    edWidth.Height := 25;
    edWidth.Margins.Left := 0;
    edWidth.Margins.Top := 0;
    edWidth.Margins.Right := 0;
    edWidth.Margins.Bottom := 0;
    edWidth.ElementClassName := 'profil_edit';
    edWidth.HeightStyle := ssAuto;
    edWidth.WidthStyle := ssAuto;
    edWidth.AutoSelect := True;
    edWidth.BorderStyle := bsSingle;
    edWidth.Color := clWhite;
    edWidth.ElementFont := efCSS;
    edWidth.ElementPosition := epRelative;
    edWidth.ShowHint := True;
    edWidth.TabStop := True;
    edWidth.TabOrder := 0;
    edWidth.Items.BeginUpdate;
    try
      edWidth.Items.Clear;
      edWidth.Items.Add('test 1');
      edWidth.Items.Add('test 2');
      edWidth.Items.Add('test 3');
    finally
      edWidth.Items.EndUpdate;
    end;
    edWidth.Text := '';
    pFooter.SetParentComponent(divWrapper);
    pFooter.Name := 'pFooter';
    pFooter.Left := 14;
    pFooter.Top := 478;
    pFooter.Width := 603;
    pFooter.Height := 73;
    pFooter.ElementClassName := 'editor-footer-div';
    pFooter.HeightStyle := ssAuto;
    pFooter.WidthStyle := ssAuto;
    pFooter.ChildOrder := 4;
    pFooter.ElementPosition := epIgnore;
    pFooter.ElementFont := efCSS;
    pFooter.Role := '';
    btnSave.SetParentComponent(pFooter);
    btnSave.Name := 'btnSave';
    btnSave.Left := 170;
    btnSave.Top := 24;
    btnSave.Width := 96;
    btnSave.Height := 25;
    btnSave.Caption := 'Speichern';
    btnSave.ElementClassName := 'start_button-lets-go';
    btnSave.ElementFont := efCSS;
    btnSave.ElementPosition := epIgnore;
    btnSave.HeightStyle := ssAuto;
    btnSave.HeightPercent := 100.000000000000000000;
    btnSave.WidthStyle := ssAuto;
    btnSave.WidthPercent := 100.000000000000000000;
    SetEvent(btnSave, Self, 'OnClick', 'btnSaveClick');
    btnCAncel.SetParentComponent(pFooter);
    btnCAncel.Name := 'btnCAncel';
    btnCAncel.Left := 288;
    btnCAncel.Top := 24;
    btnCAncel.Width := 96;
    btnCAncel.Height := 25;
    btnCAncel.Caption := 'Abbrechen';
    btnCAncel.ChildOrder := 2;
    btnCAncel.ElementClassName := 'start_button-lets-go';
    btnCAncel.ElementFont := efCSS;
    btnCAncel.ElementPosition := epIgnore;
    btnCAncel.HeightStyle := ssAuto;
    btnCAncel.HeightPercent := 100.000000000000000000;
    btnCAncel.WidthStyle := ssAuto;
    btnCAncel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCAncel, Self, 'OnClick', 'btnCancelClick');
    WebClipboard1.SetParentComponent(Self);
    WebClipboard1.Name := 'WebClipboard1';
    WebClipboard1.Left := 224;
    WebClipboard1.Top := 200;
  finally
    divWrapper.AfterLoadDFMValues;
    WebHTMLDiv11.AfterLoadDFMValues;
    btnClose.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    divTitel.AfterLoadDFMValues;
    btnFoto.AfterLoadDFMValues;
    btnVideo.AfterLoadDFMValues;
    EditButtons.AfterLoadDFMValues;
    WebYoutube1.AfterLoadDFMValues;
    reText.AfterLoadDFMValues;
    WebHTMLDiv1.AfterLoadDFMValues;
    WebHTMLDiv3.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebHTMLDiv4.AfterLoadDFMValues;
    edHeight.AfterLoadDFMValues;
    WebHTMLDiv5.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebHTMLDiv6.AfterLoadDFMValues;
    edWidth.AfterLoadDFMValues;
    pFooter.AfterLoadDFMValues;
    btnSave.AfterLoadDFMValues;
    btnCAncel.AfterLoadDFMValues;
    WebClipboard1.AfterLoadDFMValues;
  end;
end;

end.
