unit utb;

interface
 uses WEBLib.StdCtrls, WEBLib.Controls, JS, Web, XData.Web.Client,
      DateUtils, System.SysUtils, system.Classes, WEBLib.Forms, WEBLib.Buttons,
      WEBLib.Dialogs, WEBLib.WebCtrls, WEBLib.ExtCtrls, WEBLib.WebTools, uCrypt,
      WEBLib.EditAutocomplete, WEBLib.CheckLst, uGlobal, XData.Web.Connection;

const
  _blog_Edit = 2;
  _blog_Content = 3;

const //Email-Typ
  _ET_NeuesPasswort = 1;
  _ET_GastZugang    = 2;
  _ET_EinladungGruppe=3;
  _ET_Freundschaftsanfrage=4;

const //Kurs-Arten
  _KA_Lektion = 1;
  _KA_Tutorial= 2;

const //Menübutton-Status
  _FSA_Add     = 0;  //Freund hinzufügen
  _FSA_Pending = 1;  //ich habe die Anfrage gestellt - muß bestätigt werden
  _FSA_Commit  = 2;  //ich muss die Anfrage bestätigen
  _FSA_Drop    = 3;  //Freund entfernen

const //User-Status
  _US_GAST     = 3;
  _US_MITGLIED = 2;
  _US_FREUND   = 1;

const //Felder - Wer darf was von mir sehen
  //nur ich darf das sehen
  //Freunde dürfen das sehen
  //Mitglieder dürfen das sehen
  //jeder darf das sehen
  _ShowAnrede   =  1;
  _ShowName     =  2;
  _ShowVorname  =  3;
  _ShowStrasse  =  4;
  _ShowLand     =  5;
  _ShowBLand    =  6;
  _ShowPLZ      =  7;
  _ShowOrt      =  8;
  _ShowTelefon  =  9;
  _ShowEMail    = 10;
  _ShowNickname = 11;
  _ShowWebseite = 12;
  _ShowGebDatum = 13;
  _ShowAbout    = 14;
  _ShowHobbies  = 15;
  _ShowExpertise= 16;
  _ShowReviere  = 17;
  _ShowFreunde  = 18;  //Wer darf meine Freunde sehen

  _ShowCount    = 19;

type
  TFieldStatusIdx = (fsidxIch, fsidxFreunde, fsidxMitglieder, fsidxJeder);

const //Rechte
  _RECHT_SHW = 1;
  _RECHT_NEW = 2;
  _RECHT_EDT = 3;
  _RECHT_DEL = 4;
  _RECHT_CMT = 5;

const
  _LIMIT_POST = 20;
  _LIMIT_BLOG = 20;
  _AllowAddImage = true;

const
  _DATE_SAVE = 'yyyy.mm.dd';
  _DATETIME_SAVE = 'yyyy.mm.dd hh:nn:ss';

const  //Bildergrößen
  _SIZE1 = 1;
  _SIZE2 = 2;
  _SIZE3 = 3;

const
  _call_ME    = '1';
  _call_Kurse = '2';
  _call_Blogs = '3';

const
  _KURSE = 0;
  _KURS  = 1;
  _KURSKAPITEL1 = 2;
  _KURSKAPITEL2 = 3;
  _KURSKAPITEL3 = 4;
  _KURSTEXT = 5;
  _CHRONIK = 6;
  _ALBEN = 7;
  _FREUNDE = 8;
  _GRUPPEN = 9;
  _USER = 10;
  _BLOG = 11;
  _BLOGKAPITEL = 12;
  _PROFIL = 13;
  _EVENTS = 14;
  _TUTORIAL = 15;
  _MAXCENTER = 16;

  _Tab_Kurse = 1;

Type
  TCallback = Procedure(Parameter1:string) of object;
  TCallbackArray = Procedure(Parameter1:array of string) of object;

type
  TEditTyp  = (etKurs, etKursInhalt, etKursText, etPost, etBlog, etFoto, etVideo);
  TMediaTyp = (medUndefiend, medFoto, medVideo, medYoutube, medPDF, medTutorial, medLektion);
  TLikeTyp  = (ltUndefiend, ltLike, ltDislike);
  TFSAnfrage= (fsaOffenAnMich,fsaOffeneVonMir,fsaFreunde);

type
  TNodeData = class
  private
    FIndexHint: Integer;
  public
    constructor Create(IndexHint: Integer);
    property IndexHint: Integer read FIndexHint;
  end;

type
  TBlogItem = record
    ID:integer;
    Row:integer;
    Text:string;
    Image:integer;
    Height:string;
    Width:string;
    BlogTyp:integer;
    CanDelete:Boolean;
  end;

type
  TRechteIdx = (riBlog, riPost, riFreunde, riAlben, riLektionen, riGruppen, riProfil, riEvents, riTutorials);

  TRechte = class
  private
      FBlog, FPost, FProfil,
      FAlben, FKurse,
      FFreunde, FGruppen:string;
      FID:integer;
      [async] procedure SetRechte; async;
      procedure SetID(value:integer);
      FAccess:Array[TRechteIdx] of string;
      function GetShow(AIdx:TRechteIdx):boolean;
      function GetNew(AIdx:TRechteIdx):boolean;
      function GetEdit(AIdx:TRechteIdx):boolean;
      function GetDel(AIdx:TRechteIdx):boolean;
      function GetBlur(AIdx:TRechteIdx):boolean;
      function GetComment(AIdx:TRechteIdx):boolean;
      procedure SetShow(AIdx:TRechteIdx; AValue:string);
      function GetAccess(AIdx:TRechteIdx):string;
  public
    property ID : integer read FID write SetID;
    property Fill[Index: TRechteIdx]: string write SetShow;
    property Show[Index: TRechteIdx]:boolean read GetShow;
    property New[Index: TRechteIdx]:boolean read GetNew;
    property Edit[Index: TRechteIdx]:boolean read GetEdit;
    property Delete[Index: TRechteIdx]:boolean read GetDel;
    property Comment[Index: TRechteIdx]:boolean read GetComment;
    property Blur[Index: TRechteIdx]:boolean read GetBlur;
    property Access[Index: TRechteIdx]:string read GetAccess;
  end;

type
  TMe = class
  private
    FHideFotos:boolean;
    FBlurFoto,
    FBlurText,
    FID:integer;
    FEMail,
    FAbout, FHobbies, FReviere,
    FAvatarPos,FTitelPos, FLand,
    FBLand, FOrt, FName, FExpertise,
    FVorname, FNickName, FFieldStatus:string;
    FTyp, FAvatar, FTitel, FSichtbar, FAlbum:integer;
    FHolder, FGast, FIsFriend,  FUserState:integer;
    FRechte:TRechte;
    FConnect, FStatus, FAnrede:integer;
    FTrainer,FAdmin, FOrganizer, FIsOnline:boolean;
  public
    constructor create;
    property ID: integer read FID write FID;
    property Holder: integer read FHolder write FHolder;
    property Name: string read FName write FName;
    property Vorname: string read FVorname write FVorname;
    property Nickname: string read FNickname write FNickname;
    property Land: string read FLand write FLand;
    property BLand: string read FBLand write FBLand;
    property Ort: string read FOrt write FOrt;
    property Album: integer read FAlbum write FAlbum;
    property About:string read FAbout  write FAbout;
    property Hobbies:string read FHobbies  write FHobbies;
    property Reviere:string read FReviere  write FReviere;
    property Expertise:string read FExpertise  write FExpertise;
    property FieldStatus:string read FFieldStatus  write FFieldStatus;
    property Admin: boolean read FAdmin write FAdmin;
    property Trainer: boolean read FTrainer write FTrainer;
    property Organizer: boolean read FOrganizer write FOrganizer;
    property Avatar:  integer read FAvatar   write FAvatar;
    property AvatarPos:string read FAvatarPos  write FAvatarPos;
    property Titel:   integer read FTitel    write FTitel;
    property TitelPos: string read FTitelPos  write FTitelPos;
    property Sichtbar:integer read FSichtbar write FSichtbar;
    property Rechte:TRechte read FRechte write FRechte;
    property Gast :integer read FGast write FGast;
    property HideFotos :boolean read FHideFotos write FHideFotos;
    property UserState :integer read FUserState write FUserState;
    property Typ :integer read FTyp write FTyp;
    property BlurFoto :integer read FBlurFoto write FBlurFoto;
    property BlurText :integer read FBlurText write FBlurText;
    property Anrede : integer read FAnrede write FAnrede;
    property Status : integer read FStatus write FStatus;
    property Connect:integer read FConnect write FConnect;
    property EMail:string read FEMail write FEMail;
  end;

type
  TJ = class
    FResponse: TXDataClientResponse;
    FIndex:integer;
    FUserState:integer;
    FFieldState:string;
    FSql:string;
    FConnection:TXDataWebConnection;
  private
    procedure SetResponse(AValue: TXDataClientResponse);
    procedure SetSQL(AValue: string);
  public
    constructor create(AResponse:TXDataClientResponse);
    property Connection:TXDataWebConnection read FConnection write FConnection;
    property Response: TXDataClientResponse read FResponse write SetResponse;
    property Index:integer read FIndex write FIndex;
    property UserState:integer read FUserState write FUserState;
    property Sql:string read FSQL write SetSQL;
    property FieldState:string read FFieldState write FFieldState;
    function WideString(AName:string):WideString;
    function Value(AName:string):string;
    function Maybe(AName:string; Aidx:integer):string;
    function Text(AName:string):WideString;
    function isTrue(AName:string):boolean;
    function Date2Str(AName:string):string;
    function DateTime(AName:string):string;
    function Integer(AName:string):integer;
    function Length:integer;
    function isEmpty:boolean;
    function hasValue:boolean;
    function GetIndex(AName, AValue:string):integer;
    [async] procedure Open; async;
    [async] procedure Refresh; async;

  end;

type
  TDatum = class
    private
      FMinuten,
      FStunden,
      FTage,
      FWochen,
      FMonate,
      FJahre:integer;
      FZeitraum:string;
    public
      class function Get(d:TDateTime):TDatum; overload;
      class function Get(s:string):TDatum; overload;
      property Minuten : integer read FMinuten;
      property Stunden : integer read FStunden;
      property Tage    : integer read FTage;
      property Wochen  : integer read FWochen;
      property Monate  : integer read FMonate;
      property Jahre   : integer read FJahre;
      property Zeitraum : string read FZeitraum;
  end;

type
  TBread = record
    ID:integer;
    Button:TButton;
    Caption: string;
    Container: THtmlDiv;
    Form:TForm;
  end;

  TBreadCrumb = class
    private
      id:integer;
      Text:string;
      FCount:integer;
      FOwner:THtmlDiv;
      FItems:array[0.._MAXCENTER] of TBread;
      procedure onClick(Sender: TObject);
      function NewDiv(AOwner:THtmlDiv; AIdx:integer):THtmlDiv;
    public
      constructor create(AOwner:THtmlDiv);
      property Count : integer read FCount write FCount;
      procedure SetCaption(AForm:TForm; AParent:TControl; AIdx:integer; ACaption:string); overload;
      procedure SetCaption(AIdx:integer; ACaption:string); overload;
      function  GetButton(AIdx:integer):TButton;
      procedure Show(AParent:TControl);
      procedure Clear();
      function DivToFront(AIdx:integer):THtmlDiv;

    end;

type
  TImg = class
    FFaktor:double;

    FNewWidth, FNewHeight,
    FWidth, FHeight,
    FMaxWidth, FMaxHeight:integer;
  private
    procedure SetMaxWidth(Value:integer);
  public
    property MaxWidth:integer read FMaxWidth write SetMaxWidth;
    property Width:integer read FWidth write FWidth;
    property Height:integer read FHeight write FHeight;
    property NewWidth:integer read FNewWidth write FNewWidth;
    property NewHeight:integer read FNewHeight write FNewHeight;
  end;

[async] function SavePicture(FHolder, FAlbumId, FPostID, FBlogID,FKursID, MedienTyp, FStatus:integer;  AImg: TImageControl; AName, FSize1, FSize2, FSize3:string):integer; async;

[async]function tbComboBoxFillAndGet(Acb:TComboBox; ASQL, AID, AText, AValue:string):integer; async;
[async] function tbCreateHashTags(AOwner, AParent:TControl; AIDs, AElementClass: string; onClick:TNotifyEvent):string; async;
[async] procedure tbFillChkListBox(Acb:TCheckListBox; AID, AText, AChecked: string; Response: TXDataClientResponse); async;

[async] function Meldung(ACaption:string):integer; async;
[async] function Frage(ACaption:string):TModalResult; async;
[async] function GetKursLevel1(AID:integer):integer; async;
[async] function KursStatus(AKurs, ARechte, AUser:integer):integer; async;
[async] function KursDone(AKurs, AUser:integer):integer; async;
[async] function InsertImage(AText:string; ASize:Integer; ABlur:boolean; AAttribute:string = ''):string; async;
[async] procedure GetEMailBody(AHolder, AEMail:integer; var ABetreff, ABody:string); async;
[async] procedure Anfragemail(ATyp:integer; AID, AName, AVorname, AEmail, AEvent, AVon, ABis:string); async;
[async] function GetAndShowImages(ADiv:THtmlDiv; AHolder_Typ, AHolder_ID:string; ABlur:boolean): array of string; async;
[async] procedure ShowImages(AParent: THtmlDiv; AImages:Array of string; ABlur:boolean); async;
[async] procedure FillAutoComplete(Aed:TEditAutoComplete; AFeld, ASQl:string); async;
[async] function FillHashTags(AParent:THTMLDiv; ABlogID, ABlogKat:string; AAlle:boolean; AChecked:array of integer; ASub:string = ''; ACheckbox:boolean = true; AHashTag:integer=0;  AButtonCLick: TNotifyEvent=nil):array of string; async;

procedure tbFillCombobox(Acb:TComboBox; AID, AText: string; Response: TXDataClientResponse);
function GetComboIndex(ACb:TComboBox; AValue:string):integer;
function GetYoutubeThumb(ALink:string):string;
function FindControlByName(AOwner:TComponent; AName:string):TControl;
function FindControlByElementID(AOwner:TComponent; AElementID:string):TControl;
function chkstr(Str:string):string;
procedure CreateLabel(ACaption:string; AOwner, AParent:TControl;  AElementClass: string; onClick:TNotifyEvent);
function isNumber(AVal:string):boolean;
function isOnline(ADate:string):boolean;
function IIF(AValue:boolean; AValue1, AValue2:string):string;
function IFInteger(AValue:boolean; AValue1, AValue2:integer):integer;
function Warten(AOwner:TComponent):THtmlDiv;
procedure RemoveAllChildControls(parentControl: TWinControl);
procedure OpenURL(const AUser, AHolder, AForm, AModul, AID:string; AFilter:string='');
function InArray(a:array of integer; AValue:integer):boolean;
procedure DeleteElement<T>(var arr: array of T; index: Integer);
[async] procedure BlogRow(AParent: TWinControl; ARow: array of TBlogItem; AShowTyp:integer; ABlur:boolean; ButtonCLick: TNotifyEvent); async;
[async] procedure Array2BlogRows(AParent:TWinControl; ARows: array of TBlogItem; AShowTyp:integer; ABlur:boolean; ButtonClick: TNotifyEvent); async;
[async] procedure ShowBlogContent(AParent:TWinControl; AID: string; AShowTyp:integer; ABlur:boolean; ButtonClick:TNotifyEvent); async;
function Gender(AGender:integer; AText:string):string;
procedure Checked2Array(AOwner:THTMLDiv; var AOld:array of integer);

implementation
  uses uDatenModul, uMain;

procedure DeleteElement<T>(var arr: array of T; index: Integer);
var
  i: Integer;
begin
  if (index < Low(arr)) or (index > High(arr))
  then Exit;

  for i := index to High(arr) - 1 do
    arr[i] := arr[i + 1];

  SetLength(arr, Length(arr) - 1);
end;

procedure ShowBlogContent(AParent:TWinControl; AID: string; AShowTyp:integer; ABlur:boolean; ButtonClick:TNotifyEvent);
Var
  aRows : array of TBlogItem;
  j:Tj;
  i:integer;
begin
  //Inhalte in Array
  j := TJ.create(await(datenmodul.EasySQL('Select * from BlogContent where BLOG_ID = ' + AID + ' ORDER BY ROW, ID')));

  SetLength(aRows, j.Length);

  for i := 0 to J.Length - 1 do
  begin
    j.Index := i;

    aRows[i].ID        := i;
    aRows[i].Row       := j.integer('ROW');
    aRows[i].Text      := j.Value('TEXT');
    aRows[i].Image     := j.integer('IMAGE');
    aRows[i].Height    := j.Value('HEIGHT');
    aRows[i].Width     := j.value('WIDTH');
    aRows[i].CanDelete := j.isTrue('CANDELETE');
  end;

  Array2BlogRows(AParent, aRows, AShowTyp, ABlur, Buttonclick);

end;

procedure Array2BlogRows(AParent:TWinControl; ARows: array of TBlogItem; AShowTyp:integer; ABlur:boolean; ButtonClick: TNotifyEvent);
Var
  k,i:integer;
  a:array of TBlogItem;
begin

  RemoveAllChildControls(AParent);

  k := ARows[0].Row;
  setlength(a,0);

  for i := 0 to length(ARows) - 1 do
  begin


    if ARows[i].Row <> k
    then begin
      if a[0].Row > 0
      then await(BlogRow(AParent, a, AShowTyp, ABlur, ButtonClick));

      k := ARows[i].Row;
      setlength(a,0);
    end;

    setlength(a,length(a)+1);

    a[high(a)].ID        := ARows[i].ID;
    a[high(a)].Row       := ARows[i].Row;
    a[high(a)].Text      := ARows[i].Text;
    a[high(a)].Image     := ARows[i].Image;
    a[high(a)].Height    := ARows[i].Height;
    a[high(a)].Width     := ARows[i].Width;
    a[high(a)].CanDelete := ARows[i].CanDelete;

    //showmessage(a[high(a)].Text);
  end;

  if a[0].Row > 0
  then await(BlogRow(AParent, a, AShowTyp, ABlur, ButtonClick));

end;

procedure BlogRow(AParent: TWinControl; ARow: array of TBlogItem; AShowTyp:integer; ABlur:boolean; ButtonClick: TNotifyEvent);
var
  J:TJ;
  i:integer;
  s:string;
  btn: TSpeedButton;
  divInnen,divAussen:THTMLDiv;
begin

//  if ARow[0].Row = -1
//  then exit;

  divAussen := THTMLDiv.Create(AParent.owner);
  divAussen.Parent := AParent;
  divAussen.elementfont := efCSS;
  divAussen.ElementPosition := epIgnore;
  divAussen.ElementClassName := 'blog-aussen';
  divAussen.Tag := ARow[0].Row;
  divAussen.ElementHandle.style.setProperty('width','100%');

  if AShowTyp = _Blog_Edit
  then begin
    divInnen := THTMLDiv.Create(AParent.owner);
    divInnen.Parent := divAussen;
    divInnen.elementfont := efCSS;
    divInnen.ElementPosition := epIgnore;
    divInnen.ElementClassName := 'blog-innen';
    divInnen.ElementHandle.style.setProperty('display','flex');
    divInnen.ElementHandle.style.setProperty('flex-direction','column');
    divInnen.ElementHandle.style.setProperty('gap','3px');

    btn := TSpeedButton.Create(AParent.owner);
    btn.Parent := divInnen;
    btn.elementposition:= epRelative;
    btn.ElementClassName := 'blog-edit-button';
    btn.OnClick := ButtonClick;
    btn.Tag := ARow[0].Row * -1;
    btn.Enabled := ARow[0].CanDelete;
    btn.Name := 'btnUp' + ARow[0].Row.tostring;
//    btn.Caption := 'hoch';
    btn.MaterialGlyph := 'arrow_circle_up';
    btn.MaterialGlyphSize := 35;
//    btn.MaterialGlyphColor := 'arrow_circle_down';

    btn := TSpeedButton.Create(AParent.owner);
    btn.Parent := divInnen;
    btn.elementposition:= epRelative;
    btn.ElementClassName := 'blog-edit-button';
    btn.OnClick := ButtonClick;
    btn.Tag := ARow[0].Row * -1;
    btn.Enabled := ARow[0].CanDelete;
    btn.Name := 'btnDel' + ARow[0].Row.tostring;
//    btn.Caption := 'Löschen';
    btn.MaterialGlyph := 'delete';
    btn.MaterialGlyphSize := 55;
//    btn.MaterialGlyphColor := 'arrow_circle_down';

    btn := TSpeedButton.Create(AParent.owner);
    btn.Parent := divInnen;
    btn.elementposition:= epRelative;
    btn.ElementClassName := 'blog-edit-button';
    btn.OnClick := ButtonClick;
    btn.Tag := ARow[0].Row * -1;
    btn.Enabled := ARow[0].CanDelete;
    btn.Name := 'btnDown' + ARow[0].Row.tostring;
//    btn.Caption := 'runter';
    btn.MaterialGlyph := 'arrow_circle_down';
    btn.MaterialGlyphSize := 35;
//    btn.MaterialGlyphColor := 'arrow_circle_down';
  end;

  for i := 0 to length(ARow) -1 do
  begin
    divInnen := THTMLDiv.Create(AParent.owner);
    divInnen.Parent := divAussen;
    divInnen.elementfont := efCSS;
    divInnen.ElementPosition := epIgnore;
    divInnen.ElementClassName := 'blog-innen';
    divInnen.widthStyle  := ssAuto;
    divInnen.heightStyle := ssAuto;
    divInnen.Tag := aRow[i].ID;

    if aRow[i].width > ''
    then begin
      divInnen.ElementHandle.style.removeProperty('width');
      divInnen.ElementHandle.style.setProperty('width',aRow[i].width + '%');
    end;
    if aRow[i].height > ''
    then begin
      divInnen.ElementHandle.style.removeProperty('height');
      divInnen.ElementHandle.style.setProperty('height',aRow[i].height + '%');
    end;

    if AShowTyp = _Blog_Content
    then begin

      divInnen.ElementHandle.style.SetProperty('border-style','none');

      if ABlur = false
      then ABlur := Mainform.ME.HideFotos;

      divInnen.HTML.Text := await(InsertImage(aRow[i].Text, _Size1, ABlur))
      //divInnen.HTML.Text := await(InsertImage(aRow[i].Text, _Size1, Mainform.ME.HideFotos))
    end
    else begin
      btn := TSpeedButton.Create(AParent.owner);
      btn.Parent := divInnen;
      btn.elementposition:= epRelative;
      btn.ElementClassName := 'blog-edit-button';
      btn.Caption := copy(aRow[i].Text,1,100) + '...';
      btn.OnClick := ButtonClick;
      btn.Tag := aRow[i].ID;
    end;

  end;

end;


function InArray(a:array of integer; AValue:integer):boolean;
Var
  i:integer;
begin
  result := false;
  for i := 0 to length(a) - 1 do
  begin
    if a[i] = AValue
    then begin
      result := true;
      break;
    end;
  end;
end;

function FillHashTags(AParent:THTMLDiv; ABlogID, ABlogKat:string; AAlle:boolean; AChecked:array of integer; ASub:string = ''; ACheckbox:boolean = true; AHashTag:integer = 0; AButtonCLick: TNotifyEvent = nil):array of string;
Var
  j:TJ;
  k, i:integer;
  d,d1:THTMLDiv;
  cb:TCheckBox;
  lbl:TLabel;
  s, sSQL: string;
  lAuswahl:boolean;
  btn:TSpeedbutton;
begin

  if (not AAlle)   //erster Aufruf in Hashtags , zeigt alle zugewiesenen
  then begin

    if length(aChecked) = 0
    then begin //alle, und die zugewiesenen -> Checked=true
      sSQL := 'Select BlogKat.VOR, BlogKat.ID, BlogKat.TEXT, if(BlogX.ID > 0, 1, 0) as CHECKED ' +
              '  from BlogKat ' +
              '  join BlogX on BlogKat.id = blogx.blogkat_id AND BlogX.Blog_ID = '+ ABlogID +
              '  JOIN blogkat b1 ON b1.ID = blogkat.VOR' +
              ' where blogkat.Text > "" ';

      if ASub > ''
      then sSQL := sSQl + ' AND b1.SEQ = ' + ASub;

      sSQL := sSQl + ' order by blogkat.TEXT';
    end
    else begin
      s:='-1'; //nur zugewiesenen
      for i := 0 to length(aChecked) -1 do
        s := s + ',' + aChecked[i].ToString;

      sSQL := 'Select BlogKat.VOR, BlogKat.ID, BlogKat.TEXT, 1 as CHECKED ' +
              '  from BlogKat ' +
              ' where ID IN (' + s + ')' +
              ' order by TEXT';
    end;

  end
  else begin //Auswahl-Dialog
    s:='-1';
    for i := 0 to length(aChecked) -1 do
      s := s + ',' + aChecked[i].ToString;

    sSQl := 'SELECT b1.TEXT AS KATEGORIE, B2.*, if(FIND_IN_SET(B2.ID, ''' + s + '''),1,0) as CHECKED ' +
            '  FROM blogkat b1' +
            '  JOIN blogkat b2 ON b1.ID = b2.VOR' +
            ' WHERE b1.VOR = ' + ABlogKat ;

    if ASub > ''
    then sSQL := sSQl + ' AND b1.SEQ = ' + ASub;

    if assigned(AButtonClick)
    then sSQl := sSQL + ' AND b2.ID IN (SELECT BlogKat_ID from Blogx) ';

//  --  sSQl := sSQL + ' ORDER BY b2.VOR, b2.Text';

    sSQl := sSQL + ' ORDER BY CASE WHEN b1.Seq = 0 THEN 1 ELSE 0 END, b1.SEQ, b2.VOR, ' +
                   '          CASE WHEN b2.Seq = 0 THEN 1 ELSE 0 END, b2.SEQ, Text';

   // showmessage(sSQL);
    lAuswahl := true;
  end;

  //showmessage(ssql);
  j := TJ.create(await(datenmodul.EasySQL(sSQL)));
  s := '';
  d:= AParent;

  //showmessage('hier 1 ' + j.Length.ToString);
  for i := 0 to j.Length-1 do
  begin
   j.Index := i;

   if (s <> j.Value('VOR')) and (lAuswahl)
   then begin
     d := THTMLDiv.create(AParent.Owner);
     d.Parent := AParent;
     d.ElementPosition := epIgnore;
     d.heightStyle := ssAuto;
     d.widthStyle := ssAuto;
     d.ElementHandle.style.setProperty('width','100%');
     d.ElementClassName := 'blog-hashtag-div';
     d.ElementHandle.style.setProperty('font-size','inherit');

     if aSub = ''
     then begin
       d1 := THTMLDiv.create(AParent.Owner);
       d1.Parent := d;
       d1.ElementPosition := epIgnore;
       d1.heightStyle := ssAuto;
       d1.widthStyle := ssAuto;
       d1.ElementClassName := 'blogkat-header';
       d1.HTML.text := j.Value('KATEGORIE');
     end;

     s := j.Value('VOR');
   end;

   if ACheckbox
   then begin
     cb := TCheckBox.Create(AParent.Owner);
     cb.Parent  := d;
     cb.Caption := j.Value('TEXT');
     cb.Tag     := j.integer('ID');
     cb.Checked := (j.isTrue('CHECKED') or (AHashTag = cb.Tag));
     cb.ElementPosition := epRelative;

     cb.ElementHandle.style.setProperty('font-size','inherit');
     cb.ElementHandle.style.setProperty('text-overflow','ellipsis');
     cb.ElementHandle.style.setProperty('white-space','nowrap');

     if Assigned(AButtonClick)
     then cb.ElementHandle.style.setProperty('width','250px')
     else cb.ElementHandle.style.setProperty('width','150px');

     cb.Visible := true;
   end
   else begin
     lbl := TLabel.Create(AParent.Owner);
     lbl.Parent  := d;
     lbl.Caption := j.Value('TEXT');
     lbl.Tag     := j.integer('ID');
     lbl.ElementPosition := epRelative;
     lbl.ElementFont     := efCSS;

//     lbl.ElementHandle.style.setProperty('font-size','inherit');
//     lbl.ElementHandle.style.setProperty('text-overflow','ellipsis');
//     lbl.ElementHandle.style.setProperty('white-space','nowrap');

//     if Assigned(AButtonClick)
//     then lbl.ElementHandle.style.setProperty('width','250px')
//     else lbl.ElementHandle.style.setProperty('width','150px');

     lbl.Visible := true;
   end;

   if Assigned(AButtonClick)
   then begin

     j.Index := j.Index + 1;

     if ((i = j.Length-1) or (s <> j.Value('VOR'))) and (lAuswahl)
     then begin
       d1 := THTMLDiv.create(AParent.Owner);
       d1.Parent := d;
       d1.ElementPosition := epIgnore;
       d1.heightStyle := ssAuto;
       d1.widthStyle := ssAuto;
       d1.ElementClassName := 'blogkat-header';
       d1.ElementHandle.style.setProperty('background-color','unset');

       btn:= TSpeedbutton.create(AParent.Owner);
       btn.Parent := d1;
       btn.ElementClassName := 'blog-hashtag-button';
       btn.ElementPosition := epIgnore;
       btn.heightStyle := ssAuto;
       btn.widthStyle := ssAuto;
       btn.Caption := 'Filter anwenden';
       btn.OnClick := AButtonClick;
     end;

     j.Index := j.Index - 1;
   end;
  end;

end;

procedure Checked2Array(AOwner:THTMLDiv; var AOld:array of integer);
var
  i,j:integer;
begin
  for i := 0 to AOwner.controlCount - 1 do
  begin
    if AOwner.controls[i] is TCheckBox
    then begin
      if TCheckBox(AOwner.controls[i]).Checked
      then begin
        setlength(AOld, length(AOld) + 1);
        AOld[high(AOld)] := TCheckBox(AOwner.controls[i]).tag;
//        showmessage(TWebCheckBox(AOwner.controls[i]).Caption + ' - ' + inttostr(high(AOld)));
      end;
    end;
  end;
end;

procedure FillAutoComplete(Aed:TEditAutoComplete; AFeld, ASQl:string);
Var
  J:TJ;
  i:integer;
  s:string;
  Acb:TCheckListBox;
begin
  J := TJ.create(Await(Datenmodul.EasySQL(ASQL)));

  for i := 0 to j.Length -1 do
  begin
    j.index := i;
    s := s + j.Value(AFeld) + ',';
  end;
  Aed.BeginUpdate;
  Aed.Items.StrictDelimiter := true;
  Aed.Items.Delimiter := ',';
  Aed.Items.DelimitedText := s;
  Aed.EndUpdate;
//  meldung(aed.Items.DelimitedText);
  freeAndNil(j);
end;


procedure OpenURL(const AUser, AHolder, AForm, AModul, AID: string; AFilter:string='');
begin
  asm
    function reloadWithNewParameter(pUser, pHolder, pForm, pModul, pId, pFilter) {
        // Aktuelle URL abrufen
        var currentURL = new URL(window.location.href);

        // Alle vorhandenen Parameter löschen
        currentURL.search = '';

        // Neuen Parameter hinzufügen
        currentURL.searchParams.set('p1', pUser);
        currentURL.searchParams.set('p2', pHolder);
        currentURL.searchParams.set('p3', pForm);
        currentURL.searchParams.set('p4', pModul);
        currentURL.searchParams.set('p5', pId);
        currentURL.searchParams.set('p6', pFilter);

        // Seite mit der aktualisierten URL neu laden
        window.location.href = currentURL.href;
    }
   reloadWithNewParameter(AUser, AHolder, AForm, AModul, AID, AFilter);
 end;

end;


procedure RemoveAllChildControls(parentControl: TWinControl);
var
  i: Integer;
  o: TControl;
begin
  for i := parentControl.ControlCount - 1 downto 0 do
  begin
    if parentControl.Controls[i] is TWinControl
    then RemoveAllChildControls(TWinControl(parentControl.Controls[i]));

    o := parentControl.Controls[i];
    o.Free;
  end;
end;

function GetAndShowImages(ADiv:THtmlDiv; AHolder_Typ, AHolder_ID:string; ABlur:boolean): array of string;
var
  AImages:Array of string;
  jImg:TJ;
  k:integer;
begin
  //Images anzeigen

//  showmessage('select MEDIEN_ID from medien2Holder ' +
//                                            ' where MEDIEN_TYP = 1 AND HOLDER_TYP = ' + AHolder_Typ +
//                                              ' AND HOLDER_ID = ' + AHolder_Id);

  jImg := TJ.create(await(datenmodul.easySQL('select MEDIEN_ID from medien2Holder ' +
                                            ' where MEDIEN_TYP = 1 AND HOLDER_TYP = ' + AHolder_Typ +
                                              ' AND HOLDER_ID = ' + AHolder_Id)));
  if jImg.hasValue
  then begin
    setlength(aImages, jImg.Length -1);

    for k := 0 to jImg.Length -1 do
    begin
      jImg.Index := k;
      aImages[k] := jImg.value('MEDIEN_ID');
    end;
    await(ShowImages(ADiv, aImages, ABlur));

    result := AImages;
  end;
end;

procedure ShowImages(AParent: THtmlDiv; AImages:Array of string; ABlur:boolean);
Var
  k, iMax, iCount, iFaktor:integer;
  div1, div2, div3, div4:THTMLDiv;
  sAttr :string;
begin

  RemoveAllChildControls(AParent);

  sAttr := 'style=object-fit:cover;width:100%;height:100%;';
  if ABlur
  then sAttr := sAttr + 'filter:blur(8px);';

  iCount := length(AImages);

  if iCount <= 5
  then iMax := iCount
  else iMax := 3;

  iFaktor := 50;
  case iCount of
    2: iFaktor := 100;
    3: iFaktor :=  50;
    4: iFaktor :=  33;
    5: iFaktor :=  25;
    6: iFaktor :=  50; //Bei 6 werden nur zwei images angezeigt!
  end;

  //erste Zeile mit erstem Bild = komplette Breite
  AParent.elementhandle.style.setProperty('display','flex');
  AParent.elementhandle.style.setProperty('flex-direction','column');
  AParent.elementhandle.style.setProperty('gap','7px');
  AParent.elementhandle.style.removeProperty('font-family');
  AParent.elementhandle.style.removeProperty('font-size');

  div1 := THTMLDiv.Create(AParent.Owner);
  div1.Parent         := AParent;
  div1.Heightstyle    := ssAuto;
  div1.widthstyle     := ssAuto;
  div1.ElementPosition:= epIgnore;
  div1.Tag            := aImages[0].ToInteger;
  div1.OnClick        := AParent.OnClick;
  div1.elementhandle.style.setProperty('display','flex');
  div1.HTML.Text := '<img src="' + await(datenmodul.GetMedia(aImages[0].toInteger, _Size1)) + '" ' + sAttr + '>';

  //zweite Zeile
  div2 := THTMLDiv.Create(AParent.Owner);
  div2.Parent         := AParent;
  div2.HeightStyle    := ssAuto;
  div2.WidthStyle     := ssAuto;
  div2.ElementPosition:= epIgnore;
  div2.elementhandle.style.setProperty('display','flex');
  div2.elementhandle.style.setProperty('gap','10px');
  div2.elementhandle.style.setProperty('max-height','133px');
  div2.elementhandle.style.setProperty('overflow','hidden');
  div2.elementhandle.style.setProperty('margin-bottom','5px');

  for k := 1 to iMax - 1 do
  begin

    div3 := THTMLDiv.Create(AParent.Owner);
    div3.Parent := div2;

    div3.HeightStyle    := ssAuto;
    div3.WidthStyle     := ssAuto;
    div3.ElementPosition:= epIgnore;
    div3.Tag            := aImages[k].ToInteger;
    div3.OnClick        := AParent.OnClick;
    div3.elementhandle.style.setProperty('min-height','133px');
    div3.elementhandle.style.setProperty('max-height','133px');
    div3.elementhandle.style.setProperty('display','flex');
    div3.elementhandle.style.setProperty('object-fit','cover');
    div3.elementhandle.style.setProperty('position','relative');
    div3.elementhandle.style.setProperty('min-width','calc(' + iFaktor.ToString + '% - 5px)');

    div3.HTML.Text := '<img src="' + await(datenmodul.GetMedia(aImages[k].toInteger, _Size2)) +'"' + sAttr + '>';
  end;

  if iMax < iCount
  then begin
    //Anzeigen wieviele Fotos es noch gibt, die hier nicht direkt angezeigt werden
    div4 := THTMLDiv.Create(AParent.Owner);
    div4.Parent          := div3;
    div4.Heightstyle     := ssAuto;
    div4.widthstyle      := ssAuto;
    div4.ElementPosition := epIgnore;
    div4.ElementClassName:= 'text-transparent';
    div4.HTML.Text       := '+ ' + (iCount-iMax).Tostring;
    div3.OnClick         := AParent.OnClick;
  end;

end;

procedure Anfragemail(ATyp:integer; AID, AName, AVorname, AEmail, AEvent, AVon, ABis:string);
Var
  JSObj: TJSObject;
  JSString:string;
begin
  JSString:= '{"EMAIL":   "' + AEmail    + '",' +
              '"ID":       ' + AID       + ',' +
              '"NAME":"'     + AName     + '",' +
              '"VORNAME": "' + AVorname  + '",' +
              '"EVENT": "'   + AEvent    + '",' +
              '"VON": "'     + AVon      + '",' +
              '"BIS": "'     + ABis      + '",' +
              '"URL": "'     + datenmodul.connection.URL + '",' +
              '"TEXT_ID": '  + ATyp.ToString + ','+
              '"ANHANG":  ""}';

  JSObj:=TJSJSON.parseObject(JSString);
  Await( datenModul.Client.RawInvokeAsync( 'IDBService.SendEMail',[JSObj]));
end;


procedure GetEMailBody(AHolder, AEMail:integer; var ABetreff, ABody:string);
var
  j : TJ;
  sImage, sHeader, sFooter:string;
begin
  j := TJ.create(await(datenModul.EasySQL('SELECT LOGO_MEDIEN_ID, HEADER, FOOTER from SYSTEM')));
  sImage:= '"' + datenmodul.Connection.URL + '/DBService/GetImage?Mediaid=' + j.Value('LOGO_MEDIEN_ID') + '&Size=2" width="60" alt="Segler-Portal-Logo!"';
  sHeader := j.Value('HEADER');
  sFooter := j.Value('FOOTER');

  j.response := await(datenModul.EasySQL('SELECT TITEL, TEXT from TEXTE WHERE HOLDER = ' + AHolder.tostring + ' AND TYP = ' + AEMail.ToString));
  ABody    := sHeader + ' ' + j.Value('TEXT') + ' ' + sFooter;
  ABetreff := j.Value('TITEL');
  ABetreff := StringReplace(ABetreff, #10, '', [rfReplaceAll]);
  ABetreff := StringReplace(ABetreff, #13, '', [rfReplaceAll]);

  ABody := stringreplace(ABody,'%BETREFF%', ABetreff,  [rfReplaceAll]);
  ABody := stringreplace(ABody,'%IMG1%',    sImage,    [rfReplaceAll]);
end;


function InsertImage(AText:string; ASize:integer; ABlur:boolean; AAttribute:string = ''):string;
Var
  iMedienArt, iW, ih, iStart, iLaenge:integer;
  sAttr, s, sA, sW, sh, sBild, sPlatzhalter:string;
  j:TJ;
begin

  result := AText;
  sAttr:= '';

  for iMedienArt := 0 to 1 do
  begin

    while True do
    begin
      //Platzhalter suchen z.B. {Bild=1234;W=100;H=200;A=left;}
      if iMedienArt = 0
      then iStart := pos('{BILD=', UpperCase(result))
      else iStart := pos('{VIDEO=', UpperCase(result));

  //          c := result[i];
  //          if (not c.IsLetter)and (not c.IsDigit)
  //          then break


      if iStart > 0
      then begin
        //inkl. {
        iStart := iStart;
        //ab { bis zum Ende des Textes
        sPlatzhalter := copy(result, iStart, length(result));

        //  } suchen um die Länge zu ermitteln
        iLaenge := pos('}', sPlatzhalter) + 1;

        //den bereich inkl. der Klammern kopieren
        sPlatzhalter := copy(result, iStart, iLaenge-1);

        // {Bild=1234;W=100;H=200;}

        //Bild-Nr. (kommt nach dem ersten "=" ) auslesen inkl. evtl Bemaßung
        iStart := pos('=', sPlatzhalter) + 1;
        //Startposition hinter dem ersten "="
        iLaenge:= pos('}', sPlatzhalter) - iStart ;
        s      := copy(sPlatzhalter, iStart, iLaenge);

        iStart := pos(';',s);
        if iStart = 0
        then iStart := length(s)
        else iStart := iStart -1;

        sBild := copy(s, 1, iStart);
        iStart := pos(';W=',s);

        if iStart > 0
        then begin
          sW := copy(s,iStart+3, length(s));

          sW := copy(sW,1, pos(';',sW)-1);
          sAttr := sAttr + 'width="' + sW + '" ';
        end;

        iStart := pos(';H=',s);
        if iStart > 0
        then begin
          sH := copy(s,iStart+3, length(s));
          sH := copy(sH,1, pos(';',sH)-1);
          sAttr := sAttr + 'height="' + sH + '" ';
        end;

        iStart := pos(';A=',s);
        if iStart > 0
        then begin
          sA := copy(s,iStart+3, length(s));
          sA := copy(sA,1, pos(';',sA)-1);
          sAttr := sAttr + 'align="' + sA + '" ';
        end;

        if ABlur
        then sAttr := sAttr + ' style=filter:blur(8px)';

        if AAttribute > ''
        then sAttr := sAttr + ' style=' + AAttribute;

        if iMedienArt = 0
        then result := StringReplace(result, sPlatzhalter, '<img src="' + await(datenmodul.GetMedia(sBild.toInteger, ASize)) + '" ' + sAttr + '>',[])
        else begin
          j := TJ.create(await(datenmodul.easySQL('Select SIZE1 from Medien where ID = ' + sBild)));
          s := J.Value('SIZE1');
          s := '<iframe ' + sAttr + ' src="' + s + '" title="' + 'Titel' +
                   ' frameborder="0"; autoplay="1"; allow="accelerometer; clipboard-write; encrypted-media; gyroscope; ' +
                   ' picture-in-picture" allowfullscreen>' +
               '</iframe>';

          result := StringReplace(result, sPlatzhalter, s,[]);
        end;
      end
      else break;
    end;
  end;

end;


constructor TNodeData.Create(IndexHint: Integer);
begin
  FIndexHint := IndexHint;
end;


function Warten(AOwner:TComponent):THtmlDiv;
Var
  d : THTMLDiv;
  FConti:THtmlDiv;
begin

  result := THtmlDiv.Create(AOwner);
  result.Parent := Mainform;
  result.ElementClassName := 'editor_transparent';

  d := THTMLDiv.Create(AOwner);
  d.Parent := result;
  d.ElementPosition := epAbsolute;
  d.HeightStyle := ssAuto;
  d.WidthStyle := ssAuto;
  d.ElementHandle.style.setProperty('margin','0 auto');

  d.ElementClassName := 'loader';

end;

function isOnline(ADate:string):boolean;
Var
  dt:TDateTime;
begin
  if ADate > ''
  then begin

    dt := StrToDateTime(ADate);
    dt := incMilliSecond(dt, Mainform.AutoRefresh);

    result:= (dt >= now);
  end
  else result := false;
end;

function isNumber(AVal:string):boolean;
begin
  result := true;
  try
    StrToInt(AVal);
  except
    result := false;
  end;
end;

function IIF(AValue:boolean; AValue1, AValue2:string):string;
begin
  if AValue
  then result := AValue1
  else result := AValue2;
end;

function IFInteger(AValue:boolean; AValue1, AValue2:integer):integer;
begin
  if AValue
  then result := AValue1
  else result := AValue2;
end;

procedure tbFillChkListBox(Acb:TCheckListBox; AID, AText, AChecked: string; Response: TXDataClientResponse);
Var
  J:TJ;
  i:integer;
begin
  Acb.Items.BeginUpdate;

  Acb.items.clear;
  J := TJ.create(Response);
  for i := 0 to j.Length -1 do
  begin
    j.index := i;
    Acb.items.Add(j.Value(AText));
    if pos(',' + j.Value(AID) + ',', AChecked, 0) > 0
    then Acb.Checked[i] := true;

  end;

  Acb.Items.EndUpdate;
  freeAndNil(j);
end;

constructor TME.Create;
begin
  inherited;
  FRechte := TRechte.Create;
end;

procedure TRechte.SetID(value:integer);
begin
  FID := Value;
  SetRechte;
end;

procedure TRechte.SetRechte;
var
  j:TJ;
begin
  J := TJ.create(Await(datenmodul.Client.RawInvokeAsync( 'IDBService.RechteGetByID',[FID])));
  FAccess[riBlog]     := j.Value('BLOG');
  FAccess[riPost]     := j.Value('POST');
  FAccess[riAlben]    := j.Value('ALBEN');
  FAccess[riFreunde]  := j.Value('FREUNDE');
  FAccess[riGruppen]  := j.Value('GRUPPEN');
  FAccess[riProfil]   := j.Value('PROFIL');
  FAccess[riLektionen]:= j.Value('KURSE');
  FAccess[riEvents]   := j.Value('EVENTS');
  FAccess[riTutorials]:= j.Value('TUTORIALS');
//  showmessage('zugewiesen : '+ FAccess[riLektionen]);
  j.free;

end;

function TRechte.GetAccess(AIdx:TRechteIdx):string;
begin
  result := FAccess[AIdx];
end;

function TRechte.GetShow(AIdx:TRechteIdx):boolean;
begin
  //ab 1 wird der Text grundsätzlich gezeigt
  result := (StrToInt(FAccess[AIdx][_RECHT_SHW]) > 0);
end;

function TRechte.GetBlur(AIdx:TRechteIdx):boolean;
begin
  //ab 1 wird der Text verwischt gezeigt
  //ab 2 wird der Text unverwischt gezeigt

  result := (StrToInt(FAccess[AIdx][_RECHT_SHW]) < 2);
end;

function TRechte.GetNew(AIdx:TRechteIdx):boolean;
begin
  result := (StrToInt(FAccess[AIdx][_RECHT_NEW]) > 0);
end;

function TRechte.GetDel(AIdx:TRechteIdx):boolean;
begin
  result := (StrToInt(FAccess[AIdx][_RECHT_DEL]) > 0);
end;

function TRechte.GetEdit(AIdx:TRechteIdx):boolean;
begin
  result := (StrToInt(FAccess[AIdx][_RECHT_EDT]) > 0);
end;

function TRechte.GetComment(AIdx:TRechteIdx):boolean;
begin
  result := (StrToInt(FAccess[AIdx][_RECHT_CMT]) > 0);
end;

procedure TRechte.SetShow(AIdx:TRechteIdx; AValue:string);
begin
  FAccess[AIdx]:= AValue;
end;


function tbCreateHashTags(AOwner, AParent:TControl; AIDs, AElementClass: string; onClick:TNotifyEvent):string;
Var
  i:integer;
  J:TJ;
  a:Tcontrol;
begin

  for i := AOwner.Componentcount -1 downto 0 do
  begin
    if AOwner.Components[i] is TLabel
    then begin
      a := TControl(AOwner.Components[i]);
      FreeAndNil(a);
    end;
  end;

  if AIDs > ','
  then begin

//    AIDs := copy(AIDs,2,length(AIDs)-2);
    AIDs := '0' + AIDs + '0';// copy(AIDs,2,length(AIDs)-2);

    j := TJ.create(await(datenmodul.EasySQL('SElect * from blogkat where ID in (' + AIDs + ')')));

    for i := 0 to j.Length -1 do
    begin
      j.Index:=i;
      if AElementClass > ''
      then CreateLabel(j.Text('TEXT'), AOwner, AParent,AElementClass, onClick );

      result := result + j.Text('TEXT')+ ', ';
    end;

    j.Free;
  end;
end;

procedure CreateLabel(ACaption:string; AOwner, AParent:TControl; AElementClass: string; onClick:TNotifyEvent);
Var
  o:TLabel;
begin
  o:=TLabel.Create(AOwner);
  o.Parent := AParent;
  o.Caption := ACaption;
  o.elementposition := epRelative;
  o.ElementFont := efCSS;
  o.AutoSize := true;
  o.HeightStyle := ssAuto;
  o.WidthStyle := ssAuto;
  o.ElementClassName := AElementClass;
//  o.ElementClassName := 'blog_hashtag';
  o.OnClick := onClick;
//  o.OnClick := lblHashTagClick;
end;

function chkstr(Str:string):string;
begin
  //Format('Mein Name ist %s und ich bin %d Jahre alt',['Tom',20]);
  Result := 'ERR000';

  Str := StringReplace(Str,'\','\\',[rfReplaceAll]);
  Str := StringReplace(Str,'"','\"',[rfReplaceAll]);
  // \\\r\\n\
  Str := StringReplace(Str,#13#10,'\r\n',[rfReplaceAll]);
  Str := Trim(Str);
  Str := StringReplace(Str,#39,'\' + #39,[rfReplaceAll]);
  Str := StringReplace(Str,'`','\`',[rfReplaceAll]);
  Str := StringReplace(Str,';','\;',[rfReplaceAll]);
  Str := StringReplace(Str,'.','\.',[rfReplaceAll]);
  Str := StringReplace(Str,':','\:',[rfReplaceAll]);
  Str := StringReplace(Str,'=','\=',[rfReplaceAll]);
  Str := StringReplace(Str,'-','\-',[rfReplaceAll]);
  Str := StringReplace(Str,'/','\/',[rfReplaceAll]);
  Str := StringReplace(Str,' # ','',[rfReplaceAll]);

//  if (pos('#',Str) > 0) and (pos('#13#10',Str) = 0) and
//     (pos('#39',Str) = 0) and (pos('color="#',Str) = 0)
//  then Result := 'ERR001#'
//  else
Result := Str;

end;


function GetComboIndex(ACb:TComboBox; AValue:string):integer;
var
  i:integer;
begin
  result := -1;
  for i := 0 to ACb.items.count - 1 do
  begin
    if ACb.Values[i] = AValue
    then begin
      result := i;
//      meldung('idx: ' + i.tostring);
      break;
    end;

  end;

end;

function SavePicture(FHolder, FAlbumId, FPostID, FBlogID,FKursID, MedienTyp, FStatus:integer;  AImg: TImageControl; AName, FSize1, FSize2, FSize3:string):integer;
var
  JSObj: TJSObject;
  JSString:string;
  j:TJ;

  Size1Width,
  Size1Height,
  Size2Height,
  Size2Width,
  Size3Height,
  Size3Width : integer;
  nFaktor:double;
  iBreite, iHoehe:integer;
  im:TImg;
  client : TXdataWebClient;
  sHolder_Typ:string;
  sParent:string;
begin

  iBreite := Aimg.Picture.Width;
  iHoehe  := Aimg.Picture.height;
  nFaktor := iBreite / iHoehe;

  im := TImg.Create;
  im.Width := Aimg.Picture.Width;
  im.Height:= Aimg.Picture.Height;

  im.MaxWidth := 1280;
  Size1Width  := im.NewWidth;
  Size1Height := im.NewHeight;

  im.MaxWidth := 300;
  Size2Width  := im.NewWidth;
  Size2Height := im.NewHeight;

  im.MaxWidth := 60;
  Size3Width  := im.NewWidth;
  Size3Height := im.NewHeight;

  AImg.Tag := 2;
  FSize1 := 'data:image/png;base64,' + GetBase64Image(AImg.ElementHandle, Size1Width, Size1Height);
  FSize2 := 'data:image/png;base64,' + GetBase64Image(AImg.ElementHandle, Size2Width, Size2Height);
  FSize3 := 'data:image/png;base64,' + GetBase64Image(AImg.ElementHandle, Size3Width, Size3Height);

  if AImg.Tag = 2
  then begin

    Try

      if FPostID > 0
      then begin
        sHolder_Typ := _HT_Post;
        sParent:= FPostID.ToString;
      end
      else  if FKursID > 0
            then begin
              sHolder_Typ := _HT_Kurs;
              sParent:= FKursID.ToString;
            end
            else  if FBlogID > 0
                  then begin
                    sHolder_Typ := _HT_Blog;
                    sParent:= FBlogID.ToString;
                  end
                  else begin
                    sHolder_Typ := _HT_Album;
                    sParent:= FAlbumID.ToString;
                  end;

      JSString:= '{"ALBUM": '    + FAlbumID.ToString + ', ' +
                  '"PARENT_ID":' + sParent + ', ' +
                  '"HOLDER_ID":' + FHolder.ToString + ', ' +
                  '"HOLDER_TYP":'+ sHolder_Typ + ', ' +
                  '"MEDIEN_TYP":'+ integer(medFoto).ToString + ', ' +
                  '"NAME": "'    + AName + '", ' +
                  '"STATUS": '   + FStatus.ToString + ', ' +
                  '"SIZE1": "'   + FSize1 + '"' + ',' +
                  '"SIZE2": "'   + FSize2 + '"' + ',' +
                  '"SIZE3": "'   + FSize3 + '"' +
                  '}';

      JSObj:=TJSJSON.parseObject(JSString);
      client := TXDataWebClient.Create(nil);
      client.Connection := datenmodul.Connection;
      J := TJ.create( Await( Client.RawInvokeAsync('IDBService.MediaNew',[JSObj])));
      result := J.integer('ID');

      freeAndNil(client);

    Except
      MessageDlg('Es hat einen Fehler beim Speichern gegeben',WEBLib.Dialogs.mtError, [mbOK]);
      raise;
      Exit;
    End;
  end;
  FreeAndNil(im);
end;


//------------------------------------------------------------------------------
procedure Timg.SetMaxWidth(Value: Integer);
begin

  FMaxWidth := Value;
  FMaxHeight := trunc((FMaxWidth / 3 ) * 2);

  if FWidth > FHeight
  then begin
    FFaktor    := FWidth / FHeight;
    FNewWidth  := FMaxWidth;
    FNewHeight := trunc(FNewWidth / FFaktor);
  end
  else begin
    FFaktor    := FHeight / FWidth;
    FNewHeight := FMaxHeight;
    FNewWidth  := trunc(FNewHeight / FFaktor);
  end;

end;
//------------------------------------------------------------------------------

function FindControlByName(AOwner:TComponent; AName:string):TControl;
var
  i:integer;
begin
  result := nil;
//  showmessage( 'xxx');
//  showmessage( AOwner.name);
//  showmessage( AName);
  for i := 0 to AOwner.ComponentCount -1 do
  begin
//   showmessage('for i: ' +  AOwner.Components[i].Name + '  = ' + AName);
    if AOwner.Components[i].Name = AName
    then begin
      result := TControl(AOwner.Components[i]);
//      showmessage('gefunden ' + result.name);
      Exit;
    end;
  end;
end;

function FindControlByElementID(AOwner:TComponent; AElementID:string):TControl;
var
  i:integer;
begin
  result := nil;
//  showmessage( 'xxx');
//  showmessage( AOwner.name);
//  showmessage( AName);
  for i := 0 to AOwner.ComponentCount -1 do
  begin
//   showmessage('for i: ' +  TWebControl(AOwner.Components[i]).ElementID + '  = ' + AElementID);
    if TControl(AOwner.Components[i]).ElementID = AElementID
    then begin
      result := TControl(AOwner.Components[i]);
//      showmessage('gefunden ' + result.name);
      Exit;
    end;
  end;
end;

function GetYoutubeThumb(ALink:string):string;
begin
  result := copy(ALink,pos('embed/',ALink)+6,length(ALink));
  result := copy(result,0,pos('?',result)-1);
  result := 'https://img.youtube.com/vi/' + result + '/0.jpg';
end;

function GetKursLevel1(AID:integer):integer;
Var
  j:TJ;
  i:integer;
begin
  i := AID;

  j := TJ.create;

  while true do
  begin
    j.response := await(datenmodul.EasySQL('select VOR, ID from Kurse where VOR > 0 AND ID = ' + i.tostring));

    if j.Value('VOR') > ''
    then begin
      i      := j.integer('VOR');
      result := j.integer('ID');
    end
    else break;

  end;

  j.Free;
end;

function KursStatus(AKurs, ARechte, AUser:integer):integer;
var
  J:TJ;
begin
  result := 0;
  j := TJ.create(await(datenmodul.EasySQL('select ID from kurse2rechte ' +
               ' where (RECHTE2_ID = ' + ARechte.ToString +
                   ' OR USER_ID=' + AUser.ToString + ') AND KURSE_ID = ' + await(GetKursLevel1(AKurs)).ToString)));

  if j.Value('ID') > ''
  then result := 1;

  j.free;
end;

function KursDone(AKurs, AUser:integer):integer;
var
  J:TJ;
begin
  result := 0;

  j := TJ.create(await(datenmodul.EasySQL('select ID from kursedone ' +
                                  ' where USER_ID=' + AUser.ToString + ' AND KURSE_ID = ' + AKurs.ToString)));

  if j.Value('ID') > ''
  then result := 1;

  j.free;
end;

function Frage(ACaption:string):TModalResult;
Var
  dlg : TMessageDlg;
begin
  dlg := TMessageDlg.Create(nil);
  result := await(TModalResult, dlg.ShowDialog(ACaption, WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]))
end;

function Meldung(ACaption:string):integer;
Var
  dlg : TMessageDlg;
begin
  dlg := TMessageDlg.Create(nil);
  await(TModalResult, dlg.ShowDialog(ACaption, WEBLib.Dialogs.mtInformation,[mbOK]));
  result := 0;
end;

constructor TBreadCrumb.create(AOwner: THtmlDiv);
var
  i:integer;
begin
  inherited;
  FOwner := AOwner;

  for i := 0 to _MAXCENTER do
  begin
    FItems[i].Container := NewDiv(FOwner, i);
    FItems[i].Container.Visible := false;
  end;

end;

function TBreadCrumb.GetButton(AIdx:integer):TButton;
begin
  result := FItems[AIdx].Button;
end;

procedure TBreadCrumb.SetCaption(AForm:TForm; AParent:TControl; AIdx:integer; ACaption:string);
var
  i:integer;
begin

  FItems[AIdx].ID      := AIdx;
  FItems[AIdx].Caption := ACaption;
  FItems[AIdx].Form    := AForm;

  for i := AIdx + 1 to _MAXCENTER do
  begin
    FItems[i].caption := '';
  end;

  Show(AParent);
end;

procedure TBreadCrumb.SetCaption(AIdx:integer; ACaption:string);
begin
  if AIdx = 0
  then FItems[AIdx].Button.Caption := ACaption
  else FItems[AIdx].Button.Caption := '- ' + ACaption;
end;

function TBreadCrumb.NewDiv(AOwner:THtmlDiv; AIdx:integer):THtmlDiv;
begin
  result := THtmlDiv.create(AOwner.Owner);
  result.Parent := AOwner;
  result.Name := 'container' + AIdx.tostring;
  result.ElementClassName := 'me_container';
  result.ElementID := 'container' + AIdx.tostring;
  result.ElementFont := AOwner.ElementFont;
  result.ElementPosition := AOwner.ElementPosition;
  result.HeightStyle := AOwner.HeightStyle;
  result.WidthStyle := AOwner.WidthStyle;
//    result.HTML.Text := result.Name;

  result.Visible := true;
end;

function TBreadCrumb.DivToFront(AIdx:integer):THtmlDiv;
Var
  i:integer;
begin

  if (FItems[aIdx].Container = nil)
  then FItems[aIdx].Container := NewDiv(FOwner, AIdx);

  for i := 0 to length(FItems)-1 do
    if assigned(FItems[i].Container) and (i <> aIdx)
    then FItems[i].Container.Visible := false;

  FItems[aIdx].Container.Visible := true;
  result := FItems[AIdx].Container;
  //showmessage(AIdx.ToString);
end;

procedure TBreadCrumb.onClick(Sender: TObject);
var
  i:integer;
begin
  for i := FItems[TButton(sender).tag].id + 1 to _MAXCENTER do
  begin
    FItems[i].caption := '';
  end;

   divToFront(FItems[TButton(sender).tag].id);
end;

procedure TBreadCrumb.Show(AParent:TControl);
Var
  btn : TButton;
  i:integer;
begin

  for i := 0 to _MAXCENTER do
  begin
    if FItems[i].Caption > ''
    then begin
      btn                  := TButton.Create(AParent.owner);
      btn.Parent           := AParent;
      btn.ElementFont      := efCSS;
      btn.HeightStyle      := ssAuto;
      btn.WidthStyle       := ssAuto;
      btn.ElementClassName := 'me_btn_breadcrumb';
      btn.ElementPosition  := epIgnore;
      btn.tag              := FItems[i].ID;
      btn.onClick          := onClick;

      if i = 0 //beim ersten Element keinen Trennstrich
      then btn.Caption := FItems[i].Caption
      else btn.Caption := '- ' + FItems[i].Caption;

      FItems[i].Button := btn;
    end;
  end;

end;

procedure TBreadCrumb.Clear;
var
  i:integer;
  o:THTMLDiv;
begin

  for i := 0 to _MAXCENTER do
  begin
    FItems[i].Caption := '';
    FItems[i].ID := 0;
    FItems[i].Button := nil;
    o := FItems[i].Container;
    if assigned(o)
    then freeandnil(o);
  end;

end;

//------------------------------------------------------------------------------
procedure TJ.SetResponse(AValue: TXDataClientResponse);
begin
  FIndex:= 0;
  FResponse := AValue;
end;

procedure TJ.SetSQL(AValue: string);
begin
  FSQL := AValue;
//  FSQL := Crypt(AValue,16);
end;

constructor TJ.create(AResponse: TXDataClientResponse);
begin
  FResponse := AResponse;
  Findex    := 0;
end;

function TJ.Date2Str(AName:string):string;
var
  a: TJSArray;
  o: TJSObject;
  s:string;
begin
  a := TJSArray(TJSObject(FResponse.Result)['value']);
  o := TJSObject(TObject(a[FIndex]));
  if self.Length > 0
  then begin
    s := String(o.Properties[AName]);

    if s > ''
    then result := FormatDateTime('dd.mm.yyyy', strtodate(s))
    else result := '';
  end
  else result := '';
end;

function TJ.DateTime(AName:string):string;
var
  a: TJSArray;
  o: TJSObject;
  s:string;
begin
  a := TJSArray(TJSObject(FResponse.Result)['value']);
  o := TJSObject(TObject(a[FIndex]));
  if self.Length > 0
  then begin
    s := String(o.Properties[AName]);

    if s > ''
    then result := FormatDateTime('dd.mm.yyyy hh:nn:ss', strtodatetime(s))
    else result := '';
  end
  else result := '';
end;

procedure TJ.Open;
var
  idx:integer;
  Client:TXDataWebClient;
begin
  Client:= TXDataWebClient.Create(nil);
  Client.Connection := FConnection;

  FResponse := Await( Client.RawInvokeAsync( 'IDBService.EasySQL',[crypt(FSQL,16)]));
  Findex := 0;
  Client.Free;
end;

procedure TJ.Refresh;
var
  idx:integer;
  Client:TXDataWebClient;
begin
  idx := FIndex;
  Client:= TXDataWebClient.Create(nil);
  Client.Connection := FConnection;
  FResponse := Await( Client.RawInvokeAsync( 'IDBService.EasySQL',[crypt(FSQL,16)]));
  Findex := idx;
  Client.Free;
end;

function TJ.GetIndex(AName, AValue: string): integer;
Var
  i:integer;
begin
  result := -1;
  for i := 0 to self.Length - 1 do
  begin
    self.Index := i;
    if self.Value(AName) = AValue
    then begin
      //showmessage('gefunden : ' + i.ToString);
      result := i;
      exit;
    end;
  end;
end;

function TJ.Value(AName:string):string;
var
  a: TJSArray;
  o: TJSObject;
begin

  if self.Length > 0
  then begin
    a := TJSArray(TJSObject(FResponse.Result)['value']);
    o := TJSObject(TObject(a[FIndex]));

    result := String(o.Properties[AName]);
  end
  else result := '';
end;

function TJ.WideString(AName:string):WideString;
var
  a: TJSArray;
  o: TJSObject;
begin

  if self.Length > 0
  then begin
    a := TJSArray(TJSObject(FResponse.Result)['value']);
    o := TJSObject(TObject(a[FIndex]));

    result := String(o.Properties[AName]);
  end
  else result := '';

end;

function TJ.ISTrue(AName:string):boolean;
var
  a: TJSArray;
  o: TJSObject;
begin

  if self.Length > 0
  then begin
    a := TJSArray(TJSObject(FResponse.Result)['value']);
    o := TJSObject(TObject(a[FIndex]));

    result := (String(o.Properties[AName]) = '1');
  end
  else result := false;
end;

function TJ.Maybe(AName:string; Aidx:integer):string;
Var
  iShow:integer;
begin
  result := Text(AName);

  //festellen wann angezeigt werden soll
  iShow := Copy(FFieldState,AIdx,1).ToInteger;

  //festellen welchen Status der "Anzeigende-User' hat
//  meldung(ishow.tostring + ' - - ' + FUserState.ToString);
  if iShow < FUserState
  then result := '';

end;

function TJ.Text(AName:string):widestring;
  function FilterText(AText:string):widestring;
  Var
    stWorte : TStrings;
    i:integer;
    s : string;
  begin
    s := '';//mainform.SetUp.Worte;

    stWorte := TStringList.Create;

    i := 2;
    while i > 1 do
    begin
      i := Pos(';',s);
//      showmessage(i.tostring + ' - '  +s);

      if i = 0
      then break;

      stWorte.Add(s.Substring(0, i-1));

      s := s.Substring(i);
    end;

    for i := 0 to stWorte.Count -1 do
      AText := StringReplace(AText,stWorte.Strings[i], StringOfChar('*', stWorte.Strings[i].Length), [rfReplaceAll, rfIgnoreCase]);

    result := AText;

    freeAndNil(stWorte);
  end;

Var
  a: TJSArray;
  o: TJSObject;
begin
  a := TJSArray(TJSObject(FResponse.Result)['value']);
  o := TJSObject(TObject(a[FIndex]));

  result := FilterText(String(o.Properties[AName]));

end;

function TJ.Integer(AName:string):integer;
var
  a: TJSArray;
  o: TJSObject;
  s:String;
begin
  result := 0;
  if self.Length > 0
  then begin
    a := TJSArray(TJSObject(FResponse.Result)['value']);
    o := TJSObject(TObject(a[FIndex]));

    s := String(o.Properties[AName]);

    if s > ''
    then result := s.ToInteger
  end;
end;

function TJ.Length():integer;
var
  a: TJSArray;
begin
  a := TJSArray(TJSObject(FResponse.Result)['value']);
  result := a.length;
end;

function TJ.isEmpty:boolean;
var
  a: TJSArray;
begin
  a := TJSArray(TJSObject(FResponse.Result)['value']);
  result := (a.length = 0);
end;

function TJ.hasValue:boolean;
var
  a: TJSArray;
begin
  a := TJSArray(TJSObject(FResponse.Result)['value']);
  result := (a.length > 0);
end;

//------------------------------------------------------------------------------
class function TDatum.Get(s:string):TDatum;
Var
  d:TDateTime;
begin
  d := StrToDateTime(s);
  result := TDatum.Get(d);
end;

class function TDatum.Get(d:TDateTime):TDatum;
  function Plural(wert:integer; wort1, wort2:string):string;
  begin
    if Wert = 1
    then result := Wert.ToString + ' ' + wort1
    else result := Wert.ToString + ' ' + wort2;
  end;
Var
  s:string;
begin
  result := TDatum.Create;
  result.FTage   := DaysBetween(Now, d);
  result.FWochen := WeeksBetween(Now, d);
  result.FStunden:= HoursBetween(Now, d);
  result.FMinuten:= MinutesBetween(Now, d);
  result.FMonate := MonthsBetween(Now, d);

  if result.Minuten <= 10
  then s := 'gerade'
  else  if result.Minuten <= 60
        then s := 'vor ' + Plural(result.Minuten,'Minute','Minuten')
        else  if result.Stunden <= 24
              then s := 'vor ' + Plural(result.Stunden,'Stunde','Stunden')
              else  if result.Tage = 1
                    then s := 'gestern'
                    else if result.Tage <= 7
                         then s := 'vor ' + Plural(result.Tage,'Tag','Tagen')
                         else  if result.Wochen <= 4
                               then s := 'vor ' + Plural(result.Wochen,'Woche','Wochen')
                               else  if result.Monate <= 12
                                     then s := 'vor ' + Plural(result.Monate,'Monat','Monaten')
                                     else s := 'am ' + FormatDateTime('dd/mm/yyyy',d);

  result.FZeitraum := s;

//  result.FZeitraum := FormatDateTime('dd/mm/yyyy',d);
end;

procedure tbFillCombobox(Acb:TComboBox; AID, AText: string; Response: TXDataClientResponse);
Var
  J:TJ;
  i:integer;
begin
  Acb.Items.BeginUpdate;

  Acb.items.clear;
  J := TJ.create(Response);
  for i := 0 to j.Length -1 do
  begin
    j.index := i;
//    showmessage(j.Value(AText) + '=' + j.Value(AID));
    Acb.items.Add(j.Value(AText) + '=' + j.Value(AID));
  end;

  Acb.Items.EndUpdate;

end;

function tbComboBoxFillAndGet(Acb:TComboBox; ASQL, AID, AText, AValue:string):integer;
Var
  Response: TXDataClientResponse;
  J:TJ;
  i:integer;
begin
  Response := await(datenmodul.EasySQL(ASQL));

  J := TJ.create(Response);

  Acb.Clear;

  for i := 0 to j.Length -1 do
  begin
    j.index := i;
    Acb.items.Add(j.Value(ATEXT) + '=' + j.Value(AID));
  end;

  result := -1;
  for i := 0 to Acb.items.count - 1 do
  begin
    if Acb.Values[i] = AValue
    then begin
      result := i;
      exit;
    end;

  end;

end;

function Gender(AGender:integer; AText:string):string;
begin
  result := AText;
  if (AText = 'seine') and (AGender = 3) then result := 'ihre';
  if (AText = 'den Autor') and (AGender = 3) then result := 'die Autorin';
  if (AText = 'der Erste') and (AGender = 3) then result := 'die Erste';
end;

end.
