program sp;

uses
  Vcl.Forms,
  WEBLib.Forms,
  uMain in 'uMain.pas'{*.html},
  utb in 'utb.pas',
  uDatenModul in 'uDatenModul.pas',
  uDocViewer in 'uDocViewer.pas'{*.html},
  uKurse in 'uKurse.pas'{*.html},
  uKurs in 'uKurs.pas'{*.html},
  uKursKapitel1 in 'uKursKapitel1.pas'{*.html},
  uKursText in 'uKursText.pas'{*.html},
  uKursEdit in 'uKursEdit.pas'{*.html},
  uStart in 'uStart.pas'{*.html},
  uGruppenEdit in 'uGruppenEdit.pas'{*.html},
  ufrVideosThumb in 'ufrVideosThumb.pas',
  uSlider in 'uSlider.pas'{*.html},
  uMedienAuswahl in 'uMedienAuswahl.pas'{*.html},
  uProfil in 'uProfil.pas',
  uPosts in 'uPosts.pas',
  uModal in 'uModal.pas'{*.html},
  uBlog in 'uBlog.pas',
  uCrypt in 'uCrypt.pas',
  uAlben in 'uAlben.pas',
  uAlbumEdit in 'uAlbumEdit.pas',
  uEvents in 'uEvents.pas'{*.html},
  uGruppen in 'uGruppen.pas',
  uGlobal in 'uGlobal.pas',
  uEingabe in 'uEingabe.pas',
  uCode in 'uCode.pas',
  uTextEdit in 'uTextEdit.pas'{*.html},
  uFotoEdit in 'uFotoEdit.pas',
  ufraAlben in 'ufraAlben.pas',
  ufrAlbumListe in 'ufrAlbumListe.pas',
  ufrAlbumThumb in 'ufrAlbumThumb.pas',
  ufrAngebot in 'ufrAngebot.pas',
  ufrBlog in 'ufrBlog.pas',
  ufrBlogsNeuste in 'ufrBlogsNeuste.pas',
  ufrComments in 'ufrComments.pas',
  ufrEdit in 'ufrEdit.pas'{*.html},
  uFreunde in 'uFreunde.pas',
  ufrEventsListe in 'ufrEventsListe.pas',
  ufrEventsNeuste in 'ufrEventsNeuste.pas',
  ufrBlogThumb in 'ufrBlogThumb.pas',
  ufrFotosListe in 'ufrFotosListe.pas',
  ufrFotosNeuste in 'ufrFotosNeuste.pas',
  ufrFotosThumb in 'ufrFotosThumb.pas',
  ufrFreunde in 'ufrFreunde.pas',
  ufrFreundeListe in 'ufrFreundeListe.pas',
  ufrFreundeThumb in 'ufrFreundeThumb.pas',
  ufrFreundeWG in 'ufrFreundeWG.pas',
  ufrFriends in 'ufrFriends.pas',
  ufrGruppenListe in 'ufrGruppenListe.pas',
  ufrGruppenStuff in 'ufrGruppenStuff.pas',
  ufrGruppenThumb in 'ufrGruppenThumb.pas',
  ufrHeader in 'ufrHeader.pas',
  ufrImgPosition in 'ufrImgPosition.pas',
  ufrKurs in 'ufrKurs.pas',
  ufrKurse in 'ufrKurse.pas',
  ufrMyStuff in 'ufrMyStuff.pas',
  ufrPost in 'ufrPost.pas',
  ufrSuchen in 'ufrSuchen.pas',
  ufrVideoListe in 'ufrVideoListe.pas',
  ufrVideosNeuste in 'ufrVideosNeuste.pas',
  uMe in 'uMe.pas',
  uMeBlogs in 'uMeBlogs.pas'{*.html},
  uMsg in 'uMsg.pas'{*.html},
  ufrChat in 'ufrChat.pas'{*.html},
  uTipAuswahl in 'uTipAuswahl.pas'{*.html},
  uMeKurse in 'uMeKurse.pas',
  ufrEventsThumb in 'ufrEventsThumb.pas',
  uSelection in 'uSelection.pas'{*.html},
  uBlogEdit in 'uBlogEdit.pas'{*.html},
  uPrint in 'uPrint.pas',
  uMeldung in 'uMeldung.pas'{*.html},
  ufrGruppenNeuste in 'ufrGruppenNeuste.pas',
  ufrDoks in 'ufrDoks.pas',
  uRichEdit in 'uRichEdit.pas',
  uHashTags in 'uHashTags.pas',
  uEdit in 'uEdit.pas',
  uComment in 'uComment.pas'{*.html},
  uPostEdit in 'uPostEdit.pas';

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  if not Application.Route
  then begin
    Application.CreateFormDirect(TDatenModul, DatenModul);
    Application.CreateForm(TMainform, Mainform);
  end;

  Application.Run;
end.
