unit uMe;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Imaging.jpeg, Vcl.Controls, WEBLib.ExtCtrls,
  WEBLib.WebCtrls, Vcl.Forms, ufrHeader, ufrMyStuff, ufrVideosNeuste,
  ufrFotosNeuste, utb, Vcl.StdCtrls, WEBLib.StdCtrls, uGlobal,
  WEBLib.ComCtrls, ufrBlogsNeuste, ufrSuchen, ufrFreundeWG,
  Vcl.Menus, ufrEventsNeuste, ufrGruppenNeuste, ufrDoks;

type
  TfrmMe = class(TForm)
    divMain: THTMLDiv;
    divLeft: THTMLDiv;
    divMECenter: THTMLDiv;
    divRight: THTMLDiv;
    frHeader1: TfrHeader;
    divR1: THTMLDiv;
    divr2: THTMLDiv;
    divR3: THTMLDiv;
    divR4: THTMLDiv;
    frFreundeWG1: TfrFreundeWG;
    frFotosNeuste1: TfrFotosNeuste;
    frVideosNeuste1: TfrVideosNeuste;
    WebHTMLDiv1: THTMLDiv;
    frSuchen1: TfrSuchen;
    WebHTMLDiv2: THTMLDiv;
    frMyStuff1: TfrMyStuff;
    WebHTMLDiv3: THTMLDiv;
    WebHTMLDiv4: THTMLDiv;
    frEventsNeuste1: TfrEventsNeuste;
    WebHTMLDiv5: THTMLDiv;
    frBlogsNeuste1: TfrBlogsNeuste;
    frGruppenNeuste1: TfrGruppenNeuste;
    frDoks1: TfrDoks;
    procedure MeineEventsClick(Sender: TObject);
    procedure MeineGruppenClick(Sender: TObject);
    procedure Gruppen;
    procedure Events;
    procedure Chronik;
    procedure Alben;
    procedure btnBlogClick(Sender: TObject);
    procedure Freunde;
    procedure Anfragen;
    procedure Profil;
    procedure Member;
    [async] procedure WebFormCreate(Sender: TObject); async;
    procedure frHeader1btnFreundeClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
  private
    FTutorial:TForm;
    FFreunde:TForm;
    FEvents:TForm;
    FGruppen:TForm;
    FPosts:TForm;
    FKurs:TForm;
    FKurse:TForm;
    FKursKapitel1:TForm;
    FKursKapitel2:TForm;
    FKursKapitel3:TForm;
    FKursText : TForm;
    FAlben: TForm;
    FBlog:TForm;
    FProfil:TForm;
    FBreadCrumb: TBreadCrumb;
  public
    property Kurs:TForm read FKurs write FKurs;
    property Kurse:TForm read FKurse write FKurse;
    property KursKapitel1:TForm read FKursKapitel1 write FKursKapitel1;
    property KursKapitel2:TForm read FKursKapitel2 write FKursKapitel2;
    property KursKapitel3:TForm read FKursKapitel3 write FKursKapitel3;
    property KursText : TForm read FKursText write FKursText;

    property BreadCrumb:TBreadCrumb read FBreadCrumb write FBreadCrumb;
    property Blog: TForm read FBlog write FBlog;
//    property Profil: TWebForm read FProfil write FProfil;
    [async] procedure init(AHolder, AModul:integer); async;
    [async] procedure LektionDirekt(AID:integer); async;
    [async] procedure Lektionen(Sender: TObject); async;
    [async] procedure Tutorials(Sender: TObject); async;
    [async] procedure TutorialDirekt(AID:integer); async;
  protected procedure LoadDFMValues; override; end;

var
  frmME: TfrmME;

implementation
  uses uProfil, uFreunde, uPosts, uKurse, uAlben, uBlog, uMain, uGruppen, uEvents;

{$R *.dfm}

procedure TfrmMe.Alben;

  procedure AfterCreate(AForm: TObject);
  begin
    TfrmAlben(AForm).init;
  end;

var
  o:THtmlDiv;
begin

  if Mainform.ME.Rechte.Show[riAlben]
  then begin
   if assigned(FAlben)
    then FreeAndNil(FAlben);
    o := FBreadCrumb.DivToFront(_ALBEN);
    FAlben := TfrmAlben.CreateNew(o.ElementID, @AfterCreate);

    frHeader1.SetActiveButton(frHeader1.btnAlben);
  end
  else meldung('Aufgrund deines Mitgliedsstatus hast Du leider keinen Zugriff auf diesen Bereich...');
end;

procedure TfrmMe.Profil;

  procedure AfterCreate(AForm: TObject);
  begin
    TfrmProfil(AForm).init(Mainform.ME.ID);
  end;

var
  o:THtmlDiv;
begin

  if assigned(FProfil)
  then FreeAndNil(FProfil);

  o := FBreadCrumb.DivToFront(_PROFIL);

  FProfil := TfrmProfil.CreateNew(o.ElementID, @AfterCreate);
end;


procedure TfrmMe.Chronik;

  procedure AfterCreate(AForm: TObject);
  begin
    TfrmPosts(AForm).init(0);
  end;

var
  o:THtmlDiv;
begin

  if assigned(FPosts)
  then FreeAndNil(FPosts);

  o := FBreadCrumb.DivToFront(_CHRONIK);

  FPosts := TfrmPosts.CreateNew(o.ElementID, @AfterCreate);

   frHeader1.SetActiveButton(frHeader1.btnChronik);
end;

procedure TfrmMe.LektionDirekt(AID:integer);

  procedure AfterCreate(AForm: TObject);
  begin
  //  TfrmKurse(AForm).Parent := self;
    TfrmKurse(AForm).Container := self;
    TfrmKurse(AForm).init(AID, _KA_Lektion);
  end;

var
  o:THtmlDiv;
begin

  if Mainform.ME.Rechte.Show[riLektionen]
  then begin

    if assigned(FKurse)
    then FreeAndNil(FKurse);

    o := FBreadCrumb.DivToFront(_KURSE);

    FKurse := TfrmKurse.CreateNew(o.ElementID, @AfterCreate);

    frHeader1.SetActiveButton(frHeader1.btnMessanger);
  end
  else meldung('Aufgrund deines Mitgliedstatus hast Du leider keinen Zugriff auf diesen Bereich...');
end;

procedure TfrmMe.Lektionen(Sender: TObject);

  procedure AfterCreate(AForm: TObject);
  begin
  //  TfrmKurse(AForm).Parent := self;
    TfrmKurse(AForm).Container := self;
    TfrmKurse(AForm).init(0, _KA_Lektion);
  end;

var
  o:THtmlDiv;
begin

  if Mainform.ME.Rechte.Show[riLektionen]
  then begin

    if assigned(FKurse)
    then FreeAndNil(FKurse);

    o := FBreadCrumb.DivToFront(_KURSE);

    FKurse := TfrmKurse.CreateNew(o.ElementID, @AfterCreate);

    frHeader1.SetActiveButton(frHeader1.btnMessanger);
  end
  else meldung('Aufgrund deines Mitgliedstatus hast Du leider keinen Zugriff auf diesen Bereich...');
end;

procedure TfrmMe.TutorialDirekt(AID:integer);

  procedure AfterCreate(AForm: TObject);
  begin
  //  TfrmKurse(AForm).Parent := self;
    TfrmKurse(AForm).Container := self;
    TfrmKurse(AForm).init(AID, _KA_Tutorial);
  end;

var
  o:THtmlDiv;
begin

  if Mainform.ME.Rechte.Show[riTutorials]
  then begin

    if assigned(FKurse)
    then FreeAndNil(FKurse);

    o := FBreadCrumb.DivToFront(_KURSE);

    FKurse := TfrmKurse.CreateNew(o.ElementID, @AfterCreate);

    frHeader1.SetActiveButton(frHeader1.btnMessanger);
  end
  else meldung('Aufgrund deines Mitgliedstatus hast Du leider keinen Zugriff auf diesen Bereich...');
end;

procedure TfrmMe.Tutorials(Sender: TObject);

  procedure AfterCreate(AForm: TObject);
  begin
  //  TfrmKurse(AForm).Parent := self;
    TfrmKurse(AForm).Container := self;
    TfrmKurse(AForm).init(0, _KA_Tutorial);
  end;

var
  o:THtmlDiv;
begin
  if Mainform.ME.Rechte.Show[riTutorials]
  then begin

    if assigned(FTutorial)
    then FreeAndNil(FTutorial);

    o := FBreadCrumb.DivToFront(_KURSE);

    FTutorial := TfrmKurse.CreateNew(o.ElementID, @AfterCreate);

    frHeader1.SetActiveButton(frHeader1.btnMessanger);
  end
  else meldung('Aufgrund deines Mitgliedstatus hast Du leider keinen Zugriff auf diesen Bereich...');
end;

procedure TfrmMe.Member;

  procedure AfterCreate(AForm: TObject);
  begin
    TfrmFreunde(AForm).init(_FS_Alle);
  end;

var
  o:THtmlDiv;
begin

  if Mainform.ME.Rechte.Show[riFreunde]
  then begin
    if assigned(FFreunde)
    then FreeAndNil(FFreunde);

    o := FBreadCrumb.DivToFront(_FREUNDE);
    FFreunde := TfrmFreunde.CreateNew(o.ElementID, @AfterCreate);
  end
  else meldung('Aufgrund deines Mitgliedsstatus hast Du leider keinen Zugriff auf diesen Bereich...');
end;


procedure TfrmMe.Anfragen;

  procedure AfterCreate(AForm: TObject);
  begin
    TfrmFreunde(AForm).init(_FS_FremdeAnfragen);
  end;

var
  o:THtmlDiv;
begin
  if Mainform.ME.Rechte.Show[riFreunde]
  then begin
    if assigned(FFreunde)
    then FreeAndNil(FFreunde);

    o := FBreadCrumb.DivToFront(_FREUNDE);
    FFreunde := TfrmFreunde.CreateNew(o.ElementID, @AfterCreate);

    frHeader1.SetActiveButton(frHeader1.btnFreunde);
  end
  else meldung('Aufgrund deines Mitgliedsstatus hast Du leider keinen Zugriff auf diesen Bereich...');
end;

procedure TfrmMe.Freunde;

  procedure AfterCreate(AForm: TObject);
  begin

    TfrmFreunde(AForm).init(_FS_Freunde);
  end;

var
  o:THtmlDiv;
begin

  if Mainform.ME.Rechte.Show[riFreunde]
  then begin
    if assigned(FFreunde)
    then FreeAndNil(FFreunde);
    o := FBreadCrumb.DivToFront(_FREUNDE);
    FFreunde := TfrmFreunde.CreateNew(o.ElementID, @AfterCreate);
  end
  else meldung('Aufgrund deines Mitgliedsstatus hast Du leider keinen Zugriff auf diesen Bereich...');
end;

procedure TfrmMe.frHeader1btnFreundeClick(Sender: TObject);
begin
  frHeader1.btnFreundeClick(Sender);
end;

procedure TfrmMe.Gruppen;

  procedure AfterCreate(AForm: TObject);
  begin
    TfrmGruppen(AForm).init(_FS_Alle);
  end;

var
  o:THtmlDiv;
begin

  if Mainform.ME.Rechte.Show[riGruppen]
  then begin
    if assigned(FGruppen)
    then FreeAndNil(FGruppen);

    o := FBreadCrumb.DivToFront(_GRUPPEN);
    FGruppen := TfrmGruppen.CreateNew(o.ElementID, @AfterCreate);

    frHeader1.SetActiveButton(frHeader1.btnGruppen);
  end
  else meldung('Aufgrund deines Mitgliedsstatus hast Du leider keinen Zugriff auf diesen Bereich...');

end;

procedure TfrmMe.MeineGruppenClick(Sender: TObject);

  procedure AfterCreate(AForm: TObject);
  begin
    TfrmGruppen(AForm).init(_FS_Freunde);
  end;

var
  o:THtmlDiv;
begin
  if Mainform.ME.Rechte.Show[riGruppen]
  then begin
    if assigned(FGruppen)
    then FreeAndNil(FGruppen);
    o := FBreadCrumb.DivToFront(_GRUPPEN);
    FGruppen := TfrmGruppen.CreateNew(o.ElementID, @AfterCreate);

    frHeader1.SetActiveButton(frHeader1.btnGruppen);
  end
  else meldung('Aufgrund deines Mitgliedsstatus hast Du leider keinen Zugriff auf diesen Bereich...');

end;

procedure TfrmMe.Events;

  procedure AfterCreate(AForm: TObject);
  begin
    TfrmEvents(AForm).init(_FS_Alle);
  end;

var
  o:THtmlDiv;
begin

  if Mainform.ME.Rechte.Show[riEvents]
  then begin
    if assigned(FEvents)
    then FreeAndNil(FEvents);
    o := FBreadCrumb.DivToFront(_EVENTS);
    FEvents := TfrmEvents.CreateNew(o.ElementID, @AfterCreate);

    frHeader1.SetActiveButton(frHeader1.btnEvents);
  end
  else meldung('Aufgrund deines Mitgliedsstatus hast Du leider keinen Zugriff auf diesen Bereich...');

end;

procedure TfrmMe.MeineEventsClick(Sender: TObject);

  procedure AfterCreate(AForm: TObject);
  begin
    TfrmEvents(AForm).init(_FS_Freunde);
  end;

var
  o:THtmlDiv;
begin
  if Mainform.ME.Rechte.Show[riEvents]
  then begin

    if assigned(FEvents)
    then FreeAndNil(FEvents);

    o := FBreadCrumb.DivToFront(_EVENTS);
    FEvents := TfrmEvents.CreateNew(o.ElementID, @AfterCreate);

    frHeader1.SetActiveButton(frHeader1.btnEvents);
  end
  else meldung('Aufgrund deines Mitgliedsstatus hast Du leider keinen Zugriff auf diesen Bereich...');

end;

procedure TfrmMe.btnBlogClick(Sender: TObject);
begin
//  TfrmBlog.Show(_BLOG, 0,'', 0,true, self);
end;

procedure TfrmMe.init(AHolder, AModul:integer);
begin
  mainform.Wait := 1;
  await(frHeader1.Init(AHolder));
  await(frMyStuff1.Init(AHolder));
  await(frGruppenNeuste1.Init);
  await(frDoks1.Init);
  await(frEventsNeuste1.Init);
  await(frBlogsNeuste1.Init);
  await(frFreundeWG1.Init);
  await(frVideosNeuste1.Init);
  await(frFotosNeuste1.Init(Mainform.Holder));
  await(frSuchen1.Init(Mainform.ME.id));

//  frHeader1.btnGruppen.onClick  := MeineGruppenClick;
//  frHeader1.btnEvents.onClick   := MeineEventsClick;
  //frHeader1.btnChronik.onClick  := btnChronikClick;
  frHeader1.miLektionen.onClick := Lektionen;
  frHeader1.miTutorials.onClick := Tutorials;
//  frHeader1.btnAlben.onClick    := btnAlbenClick;
//  frHeader1.btnFreunde.onClick  := Freunde;
//  frHeader1.btnAnfragen.onClick := Anfragen;
  mainform.Wait := -1;

  case AModul of
    1: Chronik;
    2: MeineGruppenClick(self);
    3: MeineEventsClick(self);
    4: Lektionen(self);
    5: Tutorials(self);
    6: Alben;
    7: Freunde;
    8: Anfragen;
    9: Profil;
   10: Member;
   11: Gruppen;
   14: Events;
  end;

end;

procedure TfrmMe.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
 // Action := caFree;
end;

procedure TfrmMe.WebFormCreate(Sender: TObject);
var
  el: TJSHTMLElement;
begin
  FBreadCrumb := TBreadCrumb.create(divMECenter);

  el := TJSHTMLElement(divMECenter.ElementHandle);
  el.style.removeProperty('height');
end;

//initialization
//  RegisterClass(TfrmMe);

procedure TfrmMe.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divMain := THTMLDiv.Create('divmain');
  divLeft := THTMLDiv.Create('divleft');
  WebHTMLDiv1 := THTMLDiv.Create(Self);
  frSuchen1 := TfrSuchen.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  frMyStuff1 := TfrMyStuff.Create(Self);
  WebHTMLDiv3 := THTMLDiv.Create(Self);
  frGruppenNeuste1 := TfrGruppenNeuste.Create(Self);
  WebHTMLDiv4 := THTMLDiv.Create(Self);
  frEventsNeuste1 := TfrEventsNeuste.Create(Self);
  WebHTMLDiv5 := THTMLDiv.Create(Self);
  frBlogsNeuste1 := TfrBlogsNeuste.Create(Self);
  divMECenter := THTMLDiv.Create(Self);
  divRight := THTMLDiv.Create('divright');
  divR1 := THTMLDiv.Create(Self);
  frFreundeWG1 := TfrFreundeWG.Create(Self);
  divr2 := THTMLDiv.Create(Self);
  frFotosNeuste1 := TfrFotosNeuste.Create(Self);
  divR3 := THTMLDiv.Create(Self);
  frVideosNeuste1 := TfrVideosNeuste.Create(Self);
  divR4 := THTMLDiv.Create(Self);
  frDoks1 := TfrDoks.Create(Self);
  frHeader1 := TfrHeader.Create(Self);

  frSuchen1.LoadFromForm;
  frMyStuff1.LoadFromForm;
  frGruppenNeuste1.LoadFromForm;
  frEventsNeuste1.LoadFromForm;
  frBlogsNeuste1.LoadFromForm;
  frFreundeWG1.LoadFromForm;
  frFotosNeuste1.LoadFromForm;
  frVideosNeuste1.LoadFromForm;
  frDoks1.LoadFromForm;
  frHeader1.LoadFromForm;
  divMain.BeforeLoadDFMValues;
  divLeft.BeforeLoadDFMValues;
  WebHTMLDiv1.BeforeLoadDFMValues;
  frSuchen1.BeforeLoadDFMValues;
  frSuchen1.divWrapper.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  frMyStuff1.BeforeLoadDFMValues;
  frMyStuff1.divWrapper.BeforeLoadDFMValues;
  WebHTMLDiv3.BeforeLoadDFMValues;
  frGruppenNeuste1.BeforeLoadDFMValues;
  WebHTMLDiv4.BeforeLoadDFMValues;
  frEventsNeuste1.BeforeLoadDFMValues;
  WebHTMLDiv5.BeforeLoadDFMValues;
  frBlogsNeuste1.BeforeLoadDFMValues;
  divMECenter.BeforeLoadDFMValues;
  divRight.BeforeLoadDFMValues;
  divR1.BeforeLoadDFMValues;
  frFreundeWG1.BeforeLoadDFMValues;
  divr2.BeforeLoadDFMValues;
  frFotosNeuste1.BeforeLoadDFMValues;
  frFotosNeuste1.divWrapper.BeforeLoadDFMValues;
  frFotosNeuste1.WebHTMLDiv2.BeforeLoadDFMValues;
  frFotosNeuste1.divContent.BeforeLoadDFMValues;
  frFotosNeuste1.img9.BeforeLoadDFMValues;
  divR3.BeforeLoadDFMValues;
  frVideosNeuste1.BeforeLoadDFMValues;
  frVideosNeuste1.divWrapper.BeforeLoadDFMValues;
  frVideosNeuste1.WebHTMLDiv2.BeforeLoadDFMValues;
  frVideosNeuste1.divContent.BeforeLoadDFMValues;
  divR4.BeforeLoadDFMValues;
  frDoks1.BeforeLoadDFMValues;
  frDoks1.divWrapper.BeforeLoadDFMValues;
  frDoks1.WebHTMLDiv2.BeforeLoadDFMValues;
  frDoks1.divLektionen.BeforeLoadDFMValues;
  frHeader1.BeforeLoadDFMValues;
  frHeader1.divWrapper.BeforeLoadDFMValues;
  frHeader1.divUser.BeforeLoadDFMValues;
  frHeader1.divOnlinePunkt.BeforeLoadDFMValues;
  frHeader1.divHeaderLeft.BeforeLoadDFMValues;
  frHeader1.btnFreunde.BeforeLoadDFMValues;
  frHeader1.btnChronik.BeforeLoadDFMValues;
  frHeader1.btnGruppen.BeforeLoadDFMValues;
  frHeader1.btnBeitreten.BeforeLoadDFMValues;
  frHeader1.btnEdit.BeforeLoadDFMValues;
  frHeader1.divHeaderRight.BeforeLoadDFMValues;
  frHeader1.btnMessanger.BeforeLoadDFMValues;
  frHeader1.btnEvents.BeforeLoadDFMValues;
  frHeader1.mnuCampus.BeforeLoadDFMValues;
  try
    Name := 'frmMe';
    Width := 1155;
    Height := 973;
    ElementFont := efCSS;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    divMain.SetParentComponent(Self);
    divMain.Name := 'divMain';
    divMain.Left := 27;
    divMain.Top := 335;
    divMain.Width := 1118;
    divMain.Height := 626;
    divMain.ElementClassName := 'me_main';
    divMain.HeightStyle := ssAuto;
    divMain.WidthStyle := ssAuto;
    divMain.ChildOrder := 1;
    divMain.ElementPosition := epIgnore;
    divMain.ElementFont := efCSS;
    divMain.Role := '';
    divLeft.SetParentComponent(divMain);
    divLeft.Name := 'divLeft';
    divLeft.Left := 23;
    divLeft.Top := 17;
    divLeft.Width := 382;
    divLeft.Height := 592;
    divLeft.ElementClassName := 'me_left';
    divLeft.HeightStyle := ssAuto;
    divLeft.WidthStyle := ssAuto;
    divLeft.ElementPosition := epIgnore;
    divLeft.ElementFont := efCSS;
    divLeft.Role := '';
    WebHTMLDiv1.SetParentComponent(divLeft);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 14;
    WebHTMLDiv1.Top := 496;
    WebHTMLDiv1.Width := 355;
    WebHTMLDiv1.Height := 81;
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    frSuchen1.SetParentComponent(WebHTMLDiv1);
    frSuchen1.Name := 'frSuchen1';
    frSuchen1.Left := 0;
    frSuchen1.Top := 0;
    frSuchen1.Width := 350;
    frSuchen1.Height := 71;
    frSuchen1.Margins.Left := 0;
    frSuchen1.Margins.Top := 0;
    frSuchen1.Margins.Right := 0;
    frSuchen1.Margins.Bottom := 0;
    frSuchen1.TabOrder := 0;
    frSuchen1.divWrapper.Left := 0;
    frSuchen1.divWrapper.Top := 6;
    WebHTMLDiv2.SetParentComponent(divLeft);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 3;
    WebHTMLDiv2.Top := 3;
    WebHTMLDiv2.Width := 355;
    WebHTMLDiv2.Height := 166;
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ChildOrder := 1;
    WebHTMLDiv2.ElementPosition := epIgnore;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.Role := '';
    frMyStuff1.SetParentComponent(WebHTMLDiv2);
    frMyStuff1.Name := 'frMyStuff1';
    frMyStuff1.Left := 3;
    frMyStuff1.Top := 3;
    frMyStuff1.Width := 319;
    frMyStuff1.Height := 142;
    frMyStuff1.TabOrder := 0;
    frMyStuff1.divWrapper.Left := 3;
    frMyStuff1.divWrapper.Top := 3;
    WebHTMLDiv3.SetParentComponent(divLeft);
    WebHTMLDiv3.Name := 'WebHTMLDiv3';
    WebHTMLDiv3.Left := 3;
    WebHTMLDiv3.Top := 187;
    WebHTMLDiv3.Width := 355;
    WebHTMLDiv3.Height := 97;
    WebHTMLDiv3.HeightStyle := ssAuto;
    WebHTMLDiv3.WidthStyle := ssAuto;
    WebHTMLDiv3.ChildOrder := 2;
    WebHTMLDiv3.ElementPosition := epIgnore;
    WebHTMLDiv3.ElementFont := efCSS;
    WebHTMLDiv3.Role := '';
    frGruppenNeuste1.SetParentComponent(WebHTMLDiv3);
    frGruppenNeuste1.Name := 'frGruppenNeuste1';
    frGruppenNeuste1.Left := 9;
    frGruppenNeuste1.Top := 6;
    frGruppenNeuste1.Width := 346;
    frGruppenNeuste1.Height := 121;
    frGruppenNeuste1.Margins.Left := 0;
    frGruppenNeuste1.Margins.Top := 0;
    frGruppenNeuste1.Margins.Right := 0;
    frGruppenNeuste1.Margins.Bottom := 0;
    frGruppenNeuste1.TabOrder := 0;
    WebHTMLDiv4.SetParentComponent(divLeft);
    WebHTMLDiv4.Name := 'WebHTMLDiv4';
    WebHTMLDiv4.Left := 11;
    WebHTMLDiv4.Top := 290;
    WebHTMLDiv4.Width := 352;
    WebHTMLDiv4.Height := 95;
    WebHTMLDiv4.HeightStyle := ssAuto;
    WebHTMLDiv4.WidthStyle := ssAuto;
    WebHTMLDiv4.ChildOrder := 3;
    WebHTMLDiv4.ElementPosition := epIgnore;
    WebHTMLDiv4.ElementFont := efCSS;
    WebHTMLDiv4.Role := '';
    frEventsNeuste1.SetParentComponent(WebHTMLDiv4);
    frEventsNeuste1.Name := 'frEventsNeuste1';
    frEventsNeuste1.Left := 6;
    frEventsNeuste1.Top := 0;
    frEventsNeuste1.Width := 346;
    frEventsNeuste1.Height := 89;
    frEventsNeuste1.Margins.Left := 0;
    frEventsNeuste1.Margins.Top := 0;
    frEventsNeuste1.Margins.Right := 0;
    frEventsNeuste1.Margins.Bottom := 0;
    frEventsNeuste1.TabOrder := 0;
    WebHTMLDiv5.SetParentComponent(divLeft);
    WebHTMLDiv5.Name := 'WebHTMLDiv5';
    WebHTMLDiv5.Left := 16;
    WebHTMLDiv5.Top := 392;
    WebHTMLDiv5.Width := 347;
    WebHTMLDiv5.Height := 81;
    WebHTMLDiv5.HeightStyle := ssAuto;
    WebHTMLDiv5.WidthStyle := ssAuto;
    WebHTMLDiv5.ChildOrder := 4;
    WebHTMLDiv5.ElementPosition := epIgnore;
    WebHTMLDiv5.ElementFont := efCSS;
    WebHTMLDiv5.Role := '';
    frBlogsNeuste1.SetParentComponent(WebHTMLDiv5);
    frBlogsNeuste1.Name := 'frBlogsNeuste1';
    frBlogsNeuste1.Left := 0;
    frBlogsNeuste1.Top := 4;
    frBlogsNeuste1.Width := 346;
    frBlogsNeuste1.Height := 83;
    frBlogsNeuste1.Margins.Left := 0;
    frBlogsNeuste1.Margins.Top := 0;
    frBlogsNeuste1.Margins.Right := 0;
    frBlogsNeuste1.Margins.Bottom := 0;
    frBlogsNeuste1.TabOrder := 0;
    divMECenter.SetParentComponent(divMain);
    divMECenter.Name := 'divMECenter';
    divMECenter.Left := 411;
    divMECenter.Top := 17;
    divMECenter.Width := 314;
    divMECenter.Height := 592;
    divMECenter.ElementClassName := 'me_center';
    divMECenter.HeightStyle := ssAuto;
    divMECenter.WidthStyle := ssAuto;
    divMECenter.ChildOrder := 1;
    divMECenter.ElementPosition := epIgnore;
    divMECenter.ElementFont := efCSS;
    divMECenter.Role := '';
    divRight.SetParentComponent(divMain);
    divRight.Name := 'divRight';
    divRight.Left := 731;
    divRight.Top := 17;
    divRight.Width := 378;
    divRight.Height := 592;
    divRight.ElementClassName := 'me_right';
    divRight.HeightStyle := ssAuto;
    divRight.WidthStyle := ssAuto;
    divRight.ChildOrder := 2;
    divRight.ElementPosition := epIgnore;
    divRight.ElementFont := efCSS;
    divRight.Role := '';
    divR1.SetParentComponent(divRight);
    divR1.Name := 'divR1';
    divR1.Left := 35;
    divR1.Top := 15;
    divR1.Width := 318;
    divR1.Height := 50;
    divR1.HeightStyle := ssAuto;
    divR1.WidthStyle := ssAuto;
    divR1.ElementPosition := epIgnore;
    divR1.ElementFont := efCSS;
    divR1.Role := '';
    frFreundeWG1.SetParentComponent(divR1);
    frFreundeWG1.Name := 'frFreundeWG1';
    frFreundeWG1.Left := -25;
    frFreundeWG1.Top := -222;
    frFreundeWG1.Width := 346;
    frFreundeWG1.Height := 116;
    frFreundeWG1.Margins.Left := 0;
    frFreundeWG1.Margins.Top := 0;
    frFreundeWG1.Margins.Right := 0;
    frFreundeWG1.Margins.Bottom := 0;
    frFreundeWG1.TabOrder := 0;
    divr2.SetParentComponent(divRight);
    divr2.Name := 'divr2';
    divr2.Left := 35;
    divr2.Top := 71;
    divr2.Width := 318;
    divr2.Height := 194;
    divr2.HeightStyle := ssAuto;
    divr2.WidthStyle := ssAuto;
    divr2.ChildOrder := 1;
    divr2.ElementPosition := epIgnore;
    divr2.ElementFont := efCSS;
    divr2.Role := '';
    frFotosNeuste1.SetParentComponent(divr2);
    frFotosNeuste1.Name := 'frFotosNeuste1';
    frFotosNeuste1.Left := -1;
    frFotosNeuste1.Top := -3;
    frFotosNeuste1.Width := 258;
    frFotosNeuste1.Height := 188;
    frFotosNeuste1.Margins.Left := 0;
    frFotosNeuste1.Margins.Top := 0;
    frFotosNeuste1.Margins.Right := 0;
    frFotosNeuste1.Margins.Bottom := 0;
    frFotosNeuste1.TabOrder := 0;
    frFotosNeuste1.divWrapper.Left := 13;
    frFotosNeuste1.divWrapper.Top := 11;
    frFotosNeuste1.divWrapper.Width := 236;
    frFotosNeuste1.divWrapper.Height := 174;
    frFotosNeuste1.WebHTMLDiv2.Width := 214;
    frFotosNeuste1.divContent.Width := 214;
    frFotosNeuste1.divContent.Height := 103;
    frFotosNeuste1.img9.Left := 208;
    frFotosNeuste1.img9.Top := 200;
    divR3.SetParentComponent(divRight);
    divR3.Name := 'divR3';
    divR3.Left := 35;
    divR3.Top := 271;
    divR3.Width := 318;
    divR3.Height := 210;
    divR3.HeightStyle := ssAuto;
    divR3.WidthStyle := ssAuto;
    divR3.ChildOrder := 2;
    divR3.ElementPosition := epIgnore;
    divR3.ElementFont := efCSS;
    divR3.Role := '';
    frVideosNeuste1.SetParentComponent(divR3);
    frVideosNeuste1.Name := 'frVideosNeuste1';
    frVideosNeuste1.Left := 0;
    frVideosNeuste1.Top := 9;
    frVideosNeuste1.Width := 257;
    frVideosNeuste1.Height := 192;
    frVideosNeuste1.Margins.Left := 0;
    frVideosNeuste1.Margins.Top := 0;
    frVideosNeuste1.Margins.Right := 0;
    frVideosNeuste1.Margins.Bottom := 0;
    frVideosNeuste1.TabOrder := 0;
    frVideosNeuste1.divWrapper.Left := 11;
    frVideosNeuste1.divWrapper.Width := 222;
    frVideosNeuste1.divWrapper.Height := 180;
    frVideosNeuste1.WebHTMLDiv2.Left := 6;
    frVideosNeuste1.WebHTMLDiv2.Width := 203;
    frVideosNeuste1.divContent.Left := 2;
    frVideosNeuste1.divContent.Width := 207;
    frVideosNeuste1.divContent.Height := 111;
    divR4.SetParentComponent(divRight);
    divR4.Name := 'divR4';
    divR4.Left := 36;
    divR4.Top := 392;
    divR4.Width := 317;
    divR4.Height := 173;
    divR4.HeightStyle := ssAuto;
    divR4.WidthStyle := ssAuto;
    divR4.ChildOrder := 3;
    divR4.ElementPosition := epIgnore;
    divR4.ElementFont := efCSS;
    divR4.Role := '';
    frDoks1.SetParentComponent(divR4);
    frDoks1.Name := 'frDoks1';
    frDoks1.Left := 13;
    frDoks1.Top := 16;
    frDoks1.Width := 236;
    frDoks1.Height := 177;
    frDoks1.Margins.Left := 0;
    frDoks1.Margins.Top := 0;
    frDoks1.Margins.Right := 0;
    frDoks1.Margins.Bottom := 0;
    frDoks1.TabOrder := 0;
    frDoks1.divWrapper.Top := 3;
    frDoks1.divWrapper.Width := 225;
    frDoks1.divWrapper.Height := 146;
    frDoks1.WebHTMLDiv2.Left := 14;
    frDoks1.WebHTMLDiv2.Width := 190;
    frDoks1.divLektionen.Left := 11;
    frDoks1.divLektionen.Width := 206;
    frDoks1.divLektionen.Height := 71;
    frHeader1.SetParentComponent(Self);
    frHeader1.Name := 'frHeader1';
    frHeader1.Left := 24;
    frHeader1.Top := 14;
    frHeader1.Width := 1145;
    frHeader1.Height := 315;
    frHeader1.TabOrder := 1;
    frHeader1.divOnlinePunkt.Left := 112;
    frHeader1.divOnlinePunkt.Width := 38;
    frHeader1.divHeaderLeft.Left := 15;
    frHeader1.divHeaderLeft.Top := 141;
    SetEvent(frHeader1.btnFreunde, Self, 'OnClick', 'frHeader1btnFreundeClick');
    frHeader1.btnChronik.Left := 105;
    frHeader1.btnChronik.Top := 117;
    frHeader1.btnGruppen.Left := 105;
    frHeader1.btnGruppen.Top := 86;
    frHeader1.btnBeitreten.Left := 207;
    frHeader1.btnBeitreten.Top := 120;
    frHeader1.btnEdit.Left := 207;
    frHeader1.btnEdit.Top := 89;
    frHeader1.btnMessanger.Left := 113;
    frHeader1.btnMessanger.Top := 117;
    frHeader1.btnEvents.Left := 215;
    frHeader1.btnEvents.Top := 118;
    frHeader1.mnuCampus.Top := 157;
  finally
    divMain.AfterLoadDFMValues;
    divLeft.AfterLoadDFMValues;
    WebHTMLDiv1.AfterLoadDFMValues;
    frSuchen1.AfterLoadDFMValues;
    frSuchen1.divWrapper.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    frMyStuff1.AfterLoadDFMValues;
    frMyStuff1.divWrapper.AfterLoadDFMValues;
    WebHTMLDiv3.AfterLoadDFMValues;
    frGruppenNeuste1.AfterLoadDFMValues;
    WebHTMLDiv4.AfterLoadDFMValues;
    frEventsNeuste1.AfterLoadDFMValues;
    WebHTMLDiv5.AfterLoadDFMValues;
    frBlogsNeuste1.AfterLoadDFMValues;
    divMECenter.AfterLoadDFMValues;
    divRight.AfterLoadDFMValues;
    divR1.AfterLoadDFMValues;
    frFreundeWG1.AfterLoadDFMValues;
    divr2.AfterLoadDFMValues;
    frFotosNeuste1.AfterLoadDFMValues;
    frFotosNeuste1.divWrapper.AfterLoadDFMValues;
    frFotosNeuste1.WebHTMLDiv2.AfterLoadDFMValues;
    frFotosNeuste1.divContent.AfterLoadDFMValues;
    frFotosNeuste1.img9.AfterLoadDFMValues;
    divR3.AfterLoadDFMValues;
    frVideosNeuste1.AfterLoadDFMValues;
    frVideosNeuste1.divWrapper.AfterLoadDFMValues;
    frVideosNeuste1.WebHTMLDiv2.AfterLoadDFMValues;
    frVideosNeuste1.divContent.AfterLoadDFMValues;
    divR4.AfterLoadDFMValues;
    frDoks1.AfterLoadDFMValues;
    frDoks1.divWrapper.AfterLoadDFMValues;
    frDoks1.WebHTMLDiv2.AfterLoadDFMValues;
    frDoks1.divLektionen.AfterLoadDFMValues;
    frHeader1.AfterLoadDFMValues;
    frHeader1.divWrapper.AfterLoadDFMValues;
    frHeader1.divUser.AfterLoadDFMValues;
    frHeader1.divOnlinePunkt.AfterLoadDFMValues;
    frHeader1.divHeaderLeft.AfterLoadDFMValues;
    frHeader1.btnFreunde.AfterLoadDFMValues;
    frHeader1.btnChronik.AfterLoadDFMValues;
    frHeader1.btnGruppen.AfterLoadDFMValues;
    frHeader1.btnBeitreten.AfterLoadDFMValues;
    frHeader1.btnEdit.AfterLoadDFMValues;
    frHeader1.divHeaderRight.AfterLoadDFMValues;
    frHeader1.btnMessanger.AfterLoadDFMValues;
    frHeader1.btnEvents.AfterLoadDFMValues;
    frHeader1.mnuCampus.AfterLoadDFMValues;
  end;
end;

end.

