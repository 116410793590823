{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2021                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCHTMLImageContainer;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}
{$IFDEF CMNLIB}
{$DEFINE CMNWEBLIB}
{$ENDIF}

interface

uses
  Classes,
  {$IFDEF LCLLIB}
  Controls,
  {$ENDIF}
  {$IFNDEF LCLLIB}
  Types,
  {$IFNDEF WEBLIB}
  UITypes,
  {$ENDIF}
  {$ENDIF}
  {$IFDEF WEBLIB}
  WEBLib.Controls,
  {$ENDIF}
  {$IFDEF VCLLIB}
  VCL.Graphics,
  {$ENDIF}
  WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCBitmapContainer, WEBLib.TMSFNCTypes,
  WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes, WEBLib.TMSFNCUtils, WEBLib.TMSFNCStyles;

type
  TTMSFNCHTMLImageContainerWidgetPosition = (hwpLeft, hwpRight);

  TTMSFNCHTMLImageContainerBeforeDrawWidgetEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AWidgetPosition: TTMSFNCHTMLImageContainerWidgetPosition; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCHTMLImageContainerAfterDrawWidgetEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AWidgetPosition: TTMSFNCHTMLImageContainerWidgetPosition) of object;
  TTMSFNCHTMLImageContainerBeforeDrawTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCHTMLImageContainerAfterDrawTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string) of object;
  TTMSFNCHTMLImageContainerAnchorClickEvent = procedure(Sender: TObject; AAnchor: string) of object;
  TTMSFNCHTMLImageContainerCheckedStateChangedEvent = procedure(Sender: TOBject; AChecked: Boolean) of object;

  TTMSFNCHTMLImageContainer = class(TTMSFNCCustomControl, ITMSFNCBitmapContainer)
  private
    FBitmapContainer: TTMSFNCBitmapContainer;
    FChecked: Boolean;
    FText: string;
    FWordWrapping: Boolean;
    FTrimming: TTMSFNCGraphicsTextTrimming;
    FVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FOnChange: TNotifyEvent;
    FWidgetPosition: TTMSFNCHTMLImageContainerWidgetPosition;
    FOnAfterDrawText: TTMSFNCHTMLImageContainerAfterDrawTextEvent;
    FOnAfterDrawWidget: TTMSFNCHTMLImageContainerAfterDrawWidgetEvent;
    FOnBeforeDrawText: TTMSFNCHTMLImageContainerBeforeDrawTextEvent;
    FOnBeforeDrawWidget: TTMSFNCHTMLImageContainerBeforeDrawWidgetEvent;
    FBitmapName: string;
    FWidgetImage: TTMSFNCBitmap;
    FOnAnchorClick: TTMSFNCHTMLImageContainerAnchorClickEvent;
    FOnCheckedStateChanged: TTMSFNCHTMLImageContainerCheckedStateChangedEvent;
    FFont: TTMSFNCGraphicsFont;
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    procedure SetText(const Value: string);
    procedure SetTrimming(const Value: TTMSFNCGraphicsTextTrimming);
    procedure SetWordWrapping(const Value: Boolean);
    procedure SetHorizontalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetWidgetPosition(const Value: TTMSFNCHTMLImageContainerWidgetPosition);
    procedure SetBitmapName(const Value: string);
    procedure SetWidgetImage(const Value: TTMSFNCBitmap);
    procedure SetFnt(const Value: TTMSFNCGraphicsFont);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    function IsTextStored: Boolean;
  protected
    AnchorText: string;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure Draw({%H-}AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF); override;
    procedure HandleMouseMove({%H-}Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseUp({%H-}Button: TTMSFNCMouseButton; {%H-}Shift: TShiftState; {%H-}X, {%H-}Y: Single); override;
    procedure DoDrawWidget({%H-}AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF); virtual;
    procedure DoDrawText({%H-}AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF); virtual;
    procedure DoExit; override;
    procedure DoEnter; override;
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure ApplyStyle; override;
    procedure ResetToDefaultStyle; override;
    procedure DoChange; virtual;
    procedure DoBeforeDrawWidget(AGraphics: TTMSFNCGraphics; ARect: TRectF; AWidgetPosition: TTMSFNCHTMLImageContainerWidgetPosition; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawWidget(AGraphics: TTMSFNCGraphics; ARect: TRectF; AWidgetPosition: TTMSFNCHTMLImageContainerWidgetPosition); virtual;
    procedure DoBeforeDrawText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string); virtual;
    procedure DoAnchorClick(AAnchor: string); virtual;
    procedure DoCheckedStateChanged(AChecked: Boolean);
    procedure SetChecked(const Value: Boolean); virtual;
    {$IFDEF FMXLIB}
    procedure SetEnabled(const Value: Boolean); override;
    {$ENDIF}
    {$IFDEF CMNWEBLIB}
    procedure SetEnabled(Value: Boolean); override;
    {$ENDIF}
    function XYToAnchor(AX, AY: Single): string;
    function GetWidgetImage(AName: string): TTMSFNCBitmap;
    function GetTextRect: TRectF;
    function GetWidgetAreaRect: TRectF;
    function GetWidgetRectangle(ARect: TrectF): TRectF;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
    property WidgetImage: TTMSFNCBitmap read FWidgetImage write SetWidgetImage;
    property BitmapName: string read FBitmapName write SetBitmapName;
    property Checked: Boolean read FChecked write SetChecked default False;
    property Text: string read FText write SetText stored IsTextStored;
    property Font: TTMSFNCGraphicsFont read FFont write SetFnt;
    property WordWrapping: Boolean read FWordWrapping write SetWordWrapping default False;
    property Trimming: TTMSFNCGraphicsTextTrimming read FTrimming write SetTrimming default gttNone;
    property HorizontalTextAlign: TTMSFNCGraphicsTextAlign read FHorizontalTextAlign write SetHorizontalTextAlign default gtaLeading;
    property VerticalTextAlign: TTMSFNCGraphicsTextAlign read FVerticalTextAlign write SetVerticalTextAlign default gtaCenter;
    property WidgetPosition: TTMSFNCHTMLImageContainerWidgetPosition read FWidgetPosition write SetWidgetPosition default hwpLeft;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnBeforeDrawWidget: TTMSFNCHTMLImageContainerBeforeDrawWidgetEvent read FOnBeforeDrawWidget write FOnBeforeDrawWidget;
    property OnAfterDrawWidget: TTMSFNCHTMLImageContainerAfterDrawWidgetEvent read FOnAfterDrawWidget write FOnAfterDrawWidget;
    property OnBeforeDrawText: TTMSFNCHTMLImageContainerBeforeDrawTextEvent read FOnBeforeDrawText write FOnBeforeDrawText;
    property OnAfterDrawText: TTMSFNCHTMLImageContainerAfterDrawTextEvent read FOnAfterDrawText write FOnAfterDrawText;
    property OnAnchorClick: TTMSFNCHTMLImageContainerAnchorClickEvent read FOnAnchorClick write FOnAnchorClick;
    property OnCheckedStateChanged: TTMSFNCHTMLImageContainerCheckedStateChangedEvent read FOnCheckedStateChanged write FOnCheckedStateChanged;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  end;

implementation

const
  WIDGETWIDTH = 25;
  TXTMARGIN = 0;

{ TTMSFNCHTMLImageContainer }

procedure TTMSFNCHTMLImageContainer.ApplyStyle;
var
  c: TTMSFNCGraphicsColor;
begin
  inherited;
  Fill.Kind := gfkNone;
  c := gcNull;
  if TTMSFNCStyles.GetStyleTextFontColor(c) then
    Font.Color := c;
end;

procedure TTMSFNCHTMLImageContainer.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCHTMLImageContainer) then
  begin
    FChecked := (Source as TTMSFNCHTMLImageContainer).Checked;
    FText := (Source as TTMSFNCHTMLImageContainer).Text;
    FWordWrapping := (Source as TTMSFNCHTMLImageContainer).WordWrapping;
    FTrimming := (Source as TTMSFNCHTMLImageContainer).Trimming;
    FHorizontalTextAlign := (Source as TTMSFNCHTMLImageContainer).HorizontalTextAlign;
    FVerticalTextAlign := (Source as TTMSFNCHTMLImageContainer).VerticalTextAlign;
    FBitmapName := (Source as TTMSFNCHTMLImageContainer).BitmapName;
    Height := (Source as TTMSFNCHTMLImageContainer).Height;
    Width := (Source as TTMSFNCHTMLImageContainer).Width;
    FFont.AssignSource((Source as TTMSFNCHTMLImageContainer).Font);
    FWidgetPosition := (Source as TTMSFNCHTMLImageContainer).WidgetPosition;
  end
  else
    inherited;
end;

procedure TTMSFNCHTMLImageContainer.ChangeDPIScale(M, D: Integer);
begin
  inherited;
  Font.Height := TTMSFNCUtils.MulDivInt(Font.Height, M, D);
end;

constructor TTMSFNCHTMLImageContainer.Create(AOwner: TComponent);
begin
  inherited;
  {$IFDEF LCLLIB}
  {$IFDEF MSWINDOWS}
  NativeCanvas := True;
  TextQuality := gtqClearType;
  {$ENDIF}
  {$ENDIF}
  FChecked := False;
  FWordWrapping := False;
  FTrimming := gttNone;
  FHorizontalTextAlign := gtaLeading;
  FVerticalTextAlign := gtaCenter;
  FBitmapName := '';
  FWidgetImage := nil;
  FWidgetPosition := hwpLeft;
  Height := 22;
  Width := 150;
  Stroke.Kind := gskNone;
  Stroke.Color := gcNull;
  Fill.Color := gcNull;
  FFont := TTMSFNCGraphicsFont.Create;
  Transparent := True;
end;

destructor TTMSFNCHTMLImageContainer.Destroy;
begin
  FFont.Free;
  inherited;
end;

procedure TTMSFNCHTMLImageContainer.DoAfterDrawText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AText: string);
begin
  if Assigned(OnAfterDrawText) then
    OnAfterDrawText(Self, AGraphics, ARect, AText);
end;

procedure TTMSFNCHTMLImageContainer.DoAfterDrawWidget(
  AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AWidgetPosition: TTMSFNCHTMLImageContainerWidgetPosition);
begin
  if Assigned(OnAfterDrawWidget) then
    OnAfterDrawWidget(Self, AGraphics, ARect, AWidgetPosition);
end;

procedure TTMSFNCHTMLImageContainer.DoAnchorClick(AAnchor: string);
begin
  if Assigned(OnAnchorClick) then
    OnAnchorClick(Self, AAnchor)
  else
    TTMSFNCUtils.OpenURL(AAnchor);
end;

procedure TTMSFNCHTMLImageContainer.DoBeforeDrawText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; AText: string; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawText) then
    OnBeforeDrawText(Self, AGraphics, ARect, AText, AAllow, ADefaultDraw);
end;

procedure TTMSFNCHTMLImageContainer.DoBeforeDrawWidget(
  AGraphics: TTMSFNCGraphics; ARect: TRectF;
  AWidgetPosition: TTMSFNCHTMLImageContainerWidgetPosition; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawWidget) then
    OnBeforeDrawWidget(Self, AGraphics, ARect, AWidgetPosition, AAllow, ADefaultDraw);
end;

procedure TTMSFNCHTMLImageContainer.DoChange;
begin
  if Assigned(OnChange) then
    OnChange(Self);

  Invalidate;
end;

procedure TTMSFNCHTMLImageContainer.DoCheckedStateChanged(AChecked: Boolean);
begin
  if Assigned(OnCheckedStateChanged) then
    OnCheckedStateChanged(Self, AChecked);
end;

procedure TTMSFNCHTMLImageContainer.DoDrawText(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
var
  b, df: Boolean;
begin
  AGraphics.Font.Assign(FFont);
  if not Enabled then
    AGraphics.Font.Color := gcMedGray;

  b := True;
  df := True;

  DoBeforeDrawText(AGraphics, ARect, FText, b, df);
  if b then
  begin
    if df then
      AGraphics.DrawText(ARect, FText, FWordWrapping, FHorizontalTextAlign, FVerticalTextAlign, FTrimming);

    DoAfterDrawText(AGraphics, ARect, FText);
  end;
end;

procedure TTMSFNCHTMLImageContainer.DoDrawWidget(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
begin
  if Assigned(FWidgetImage) then
    AGraphics.DrawBitmap(ARect, FWidgetImage);
end;

procedure TTMSFNCHTMLImageContainer.DoEnter;
begin
  inherited;
  Invalidate;
end;

procedure TTMSFNCHTMLImageContainer.DoExit;
begin
  inherited;
  Invalidate;
end;

procedure TTMSFNCHTMLImageContainer.Draw(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
var
  wr, tr: TRectF;
  b, df: Boolean;
begin
  inherited;
  b := True;
  df := True;

  AGraphics.BitmapContainer := BitmapContainer;

  wr := GetWidgetAreaRect;
  tr := GetTextRect;

  DoBeforeDrawWidget(AGraphics, Arect, FWidgetPosition, b, df);
  if b then
  begin
    if df then
      DoDrawWidget(AGraphics, wr);

    DoAfterDrawWidget(AGraphics, ARect, FWidgetPosition);
  end;

  DoDrawText(AGraphics, tr);
end;

function TTMSFNCHTMLImageContainer.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FBitmapContainer;
end;

function TTMSFNCHTMLImageContainer.GetTextRect: TRectF;
begin
  if Assigned(FWidgetImage) then
  begin
    if FWidgetPosition = hwpLeft then
      Result := RectF(FWidgetImage.Width + TXTMARGIN, 0, Width, Height)
    else
      Result := RectF(0, 0, Width - FWidgetImage.Width - TXTMARGIN, Height);
  end
  else
  begin
    if FWidgetPosition = hwpLeft then
      Result := RectF(ScalePaintValue(WIDGETWIDTH) + ScalePaintValue(TXTMARGIN), 0, Width, Height)
    else
      Result := RectF(0, 0, Width - ScalePaintValue(WIDGETWIDTH) - ScalePaintValue(TXTMARGIN), Height);
  end;
end;

function TTMSFNCHTMLImageContainer.GetWidgetImage(AName: string): TTMSFNCBitmap;
var
  I: Integer;
begin
  Result := nil;
  if not Assigned(BitmapContainer) then
    Exit;

  for I := 0 to BitmapContainer.ItemCount - 1 do
  begin
    if BitmapContainer.BitmapNames[I] = FBitmapName then
    begin
      Result := BitmapContainer.Bitmaps[I];
      Break;
    end;
  end;
end;

function TTMSFNCHTMLImageContainer.GetWidgetRectangle(ARect: TrectF): TRectF;
var
  l, t: Single;
begin
  l := (ARect.Right - ARect.Left - 16) / 2;
  t := (ARect.Bottom - ARect.Top - 16) / 2;
  Result := RectF(ARect.Left + l, ARect.Top + t, ARect.Left + l + 16, ARect.Top + t + 16);
end;

function TTMSFNCHTMLImageContainer.GetWidgetAreaRect: TRectF;
begin
  FWidgetImage := GetWidgetImage(FBitmapName);
  if Assigned(FWidgetImage) then
  begin
    if FWidgetPosition = hwpLeft then
      Result := RectF(0, 0, FWidgetImage.Width, Height)
    else
      Result := RectF(Width - FWidgetImage.Width, 0, Width, Height);
  end
  else
  begin
    if FWidgetPosition = hwpLeft then
      Result := RectF(0, 0, ScalePaintValue(WIDGETWIDTH), Height)
    else
      Result := RectF(Width - ScalePaintValue(WIDGETWIDTH), 0, Width, Height);
  end;
end;

procedure TTMSFNCHTMLImageContainer.HandleMouseMove(Shift: TShiftState; X,
  Y: Single);
begin
  inherited;
  AnchorText := XYToAnchor(X, Y);

  if AnchorText <> '' then
    Cursor := crHandPoint
  else
    Cursor := crDefault;
end;

procedure TTMSFNCHTMLImageContainer.HandleMouseUp(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
begin
  inherited;
  SetFocus;
  AnchorText := XYToAnchor(X, Y);
  if AnchorText <> '' then
    DoAnchorClick(AnchorText);
end;

function TTMSFNCHTMLImageContainer.IsTextStored: Boolean;
begin
  Result := FText <> '';
end;

procedure TTMSFNCHTMLImageContainer.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;

  if (Operation = opRemove) and (AComponent = FBitmapContainer) then
    FBitmapContainer := nil;
end;

procedure TTMSFNCHTMLImageContainer.ResetToDefaultStyle;
begin
  inherited;
  Fill.Color := gcNull;
  Fill.Kind := gfkNone;
  Font.Color := gcBlack
end;

procedure TTMSFNCHTMLImageContainer.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
begin
  FBitmapContainer := Value;
  Invalidate;
end;

procedure TTMSFNCHTMLImageContainer.SetBitmapName(const Value: string);
begin
  if FBitmapName <> Value then
  begin
    FBitmapName := Value;
    DoChange;
  end;
end;

procedure TTMSFNCHTMLImageContainer.SetChecked(const Value: Boolean);
begin
  if FChecked <> Value then
  begin
    FChecked := Value;
    DoCheckedStateChanged(FChecked);
    DoChange;
  end;
end;

procedure TTMSFNCHTMLImageContainer.SetFnt(const Value: TTMSFNCGraphicsFont);
begin
  FFont.AssignSource(Value);
end;

procedure TTMSFNCHTMLImageContainer.SetHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FHorizontalTextAlign <> Value then
  begin
    FHorizontalTextAlign := Value;
    DoChange;
  end;
end;

procedure TTMSFNCHTMLImageContainer.SetText(const Value: string);
begin
  if FText <> Value then
  begin
    FText := Value;
    DoChange;
  end;
end;

procedure TTMSFNCHTMLImageContainer.SetTrimming(
  const Value: TTMSFNCGraphicsTextTrimming);
begin
  if FTrimming <> Value then
  begin
    FTrimming := Value;
    DoChange;
  end;
end;

procedure TTMSFNCHTMLImageContainer.SetVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FVerticalTextAlign <> Value then
  begin
    FVerticalTextAlign := Value;
    DoChange;
  end;
end;

procedure TTMSFNCHTMLImageContainer.SetWidgetImage(const Value: TTMSFNCBitmap);
begin
  FWidgetImage.Assign(Value);
end;

procedure TTMSFNCHTMLImageContainer.SetWidgetPosition(
  const Value: TTMSFNCHTMLImageContainerWidgetPosition);
begin
  if FWidgetPosition <> Value then
  begin
    FWidgetPosition := Value;
    DoChange;
  end;
end;

procedure TTMSFNCHTMLImageContainer.SetWordWrapping(const Value: Boolean);
begin
  if FWordWrapping <> Value then
  begin
    FWordWrapping := Value;
    DoChange;
  end;
end;

{$IFDEF FMXLIB}
procedure TTMSFNCHTMLImageContainer.SetEnabled(const Value: Boolean);
{$ENDIF}
{$IFDEF CMNWEBLIB}
procedure TTMSFNCHTMLImageContainer.SetEnabled(Value: Boolean);
{$ENDIF}
begin
  inherited;
  Invalidate;
end;

function TTMSFNCHTMLImageContainer.XYToAnchor(AX, AY: Single): string;
var
  r: TRectF;
  g: TTMSFNCGraphics;
begin
  Result := '';
  r := GetTextRect;

  g := TTMSFNCGraphics.CreateBitmapCanvas;
  g.BeginScene;
  g.BitmapContainer := BitmapContainer;
  try
    g.Font.Assign(FFont);
    Result := g.DrawText(r, FText, FWordWrapping, FHorizontalTextAlign, FVerticalTextAlign, FTrimming, 0, -1, -1, True, True, AX, AY);
  finally
    g.EndScene;
    g.Free;
  end;
end;

end.
