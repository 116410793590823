unit ufrFreunde;

interface

uses
  System.SysUtils, System.Variants, System.Classes,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.Imaging.jpeg,
  WEBLib.Controls, WEBLib.ExtCtrls, WEBLib.WebCtrls;

type
  TFrame2 = class(TFrame)
    divFotos: THTMLDiv;
    img1: TImageControl;
    img2: TImageControl;
    img3: TImageControl;
    img4: TImageControl;
    img5: TImageControl;
    img6: TImageControl;
    img7: TImageControl;
    img8: TImageControl;
    img9: TImageControl;
    WebHTMLDiv2: THTMLDiv;
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

procedure TFrame2.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divFotos := THTMLDiv.Create(Self);
  img1 := TImageControl.Create(Self);
  img2 := TImageControl.Create(Self);
  img3 := TImageControl.Create(Self);
  img4 := TImageControl.Create(Self);
  img5 := TImageControl.Create(Self);
  img6 := TImageControl.Create(Self);
  img7 := TImageControl.Create(Self);
  img8 := TImageControl.Create(Self);
  img9 := TImageControl.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);

  divFotos.BeforeLoadDFMValues;
  img1.BeforeLoadDFMValues;
  img2.BeforeLoadDFMValues;
  img3.BeforeLoadDFMValues;
  img4.BeforeLoadDFMValues;
  img5.BeforeLoadDFMValues;
  img6.BeforeLoadDFMValues;
  img7.BeforeLoadDFMValues;
  img8.BeforeLoadDFMValues;
  img9.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  try
    Name := 'Frame2';
    Left := 0;
    Top := 0;
    Width := 292;
    Height := 342;
    AutoSize := True;
    TabOrder := 0;
    divFotos.SetParentComponent(Self);
    divFotos.Name := 'divFotos';
    divFotos.Left := 0;
    divFotos.Top := 0;
    divFotos.Width := 292;
    divFotos.Height := 342;
    divFotos.ElementClassName := 'frame_fotos_wrapper';
    divFotos.ChildOrder := 9;
    divFotos.Role := '';
    img1.SetParentComponent(divFotos);
    img1.Name := 'img1';
    img1.Left := 7;
    img1.Top := 56;
    img1.Width := 88;
    img1.Height := 88;
    img1.ElementClassName := 'blk_content_thumb';
    img1.HeightPercent := 100.000000000000000000;
    img1.WidthPercent := 100.000000000000000000;
    img1.ChildOrder := 1;
    img1.Picture.LoadFromFile('ufrFreunde.divFotos.img1.Picture.jpg');
    img2.SetParentComponent(divFotos);
    img2.Name := 'img2';
    img2.Left := 101;
    img2.Top := 56;
    img2.Width := 88;
    img2.Height := 88;
    img2.ElementClassName := 'blk_content_thumb';
    img2.HeightPercent := 100.000000000000000000;
    img2.WidthPercent := 100.000000000000000000;
    img2.ChildOrder := 1;
    img2.Picture.LoadFromFile('ufrFreunde.divFotos.img2.Picture.jpg');
    img3.SetParentComponent(divFotos);
    img3.Name := 'img3';
    img3.Left := 195;
    img3.Top := 56;
    img3.Width := 88;
    img3.Height := 88;
    img3.ElementClassName := 'blk_content_thumb';
    img3.HeightPercent := 100.000000000000000000;
    img3.WidthPercent := 100.000000000000000000;
    img3.ChildOrder := 1;
    img3.Picture.LoadFromFile('ufrFreunde.divFotos.img3.Picture.jpg');
    img4.SetParentComponent(divFotos);
    img4.Name := 'img4';
    img4.Left := 7;
    img4.Top := 150;
    img4.Width := 88;
    img4.Height := 88;
    img4.ElementClassName := 'blk_content_thumb';
    img4.HeightPercent := 100.000000000000000000;
    img4.WidthPercent := 100.000000000000000000;
    img4.ChildOrder := 1;
    img4.Picture.LoadFromFile('ufrFreunde.divFotos.img4.Picture.jpg');
    img5.SetParentComponent(divFotos);
    img5.Name := 'img5';
    img5.Left := 101;
    img5.Top := 150;
    img5.Width := 88;
    img5.Height := 88;
    img5.ElementClassName := 'blk_content_thumb';
    img5.HeightPercent := 100.000000000000000000;
    img5.WidthPercent := 100.000000000000000000;
    img5.ChildOrder := 1;
    img5.Picture.LoadFromFile('ufrFreunde.divFotos.img5.Picture.jpg');
    img6.SetParentComponent(divFotos);
    img6.Name := 'img6';
    img6.Left := 195;
    img6.Top := 150;
    img6.Width := 88;
    img6.Height := 88;
    img6.ElementClassName := 'blk_content_thumb';
    img6.HeightPercent := 100.000000000000000000;
    img6.WidthPercent := 100.000000000000000000;
    img6.ChildOrder := 1;
    img6.Picture.LoadFromFile('ufrFreunde.divFotos.img6.Picture.jpg');
    img7.SetParentComponent(divFotos);
    img7.Name := 'img7';
    img7.Left := 7;
    img7.Top := 244;
    img7.Width := 88;
    img7.Height := 88;
    img7.ElementClassName := 'blk_content_thumb';
    img7.HeightPercent := 100.000000000000000000;
    img7.WidthPercent := 100.000000000000000000;
    img7.ChildOrder := 1;
    img7.Picture.LoadFromFile('ufrFreunde.divFotos.img7.Picture.jpg');
    img8.SetParentComponent(divFotos);
    img8.Name := 'img8';
    img8.Left := 101;
    img8.Top := 244;
    img8.Width := 88;
    img8.Height := 88;
    img8.ElementClassName := 'blk_content_thumb';
    img8.HeightPercent := 100.000000000000000000;
    img8.WidthPercent := 100.000000000000000000;
    img8.ChildOrder := 1;
    img8.Picture.LoadFromFile('ufrFreunde.divFotos.img8.Picture.jpg');
    img9.SetParentComponent(divFotos);
    img9.Name := 'img9';
    img9.Left := 195;
    img9.Top := 244;
    img9.Width := 88;
    img9.Height := 88;
    img9.ElementClassName := 'blk_content_thumb';
    img9.HeightPercent := 100.000000000000000000;
    img9.WidthPercent := 100.000000000000000000;
    img9.ChildOrder := 1;
    img9.Picture.LoadFromFile('ufrFreunde.divFotos.img9.Picture.jpg');
    WebHTMLDiv2.SetParentComponent(divFotos);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 3;
    WebHTMLDiv2.Top := 0;
    WebHTMLDiv2.Width := 286;
    WebHTMLDiv2.Height := 49;
    WebHTMLDiv2.ElementClassName := 'frame_titel';
    WebHTMLDiv2.ChildOrder := 1;
    WebHTMLDiv2.HTML.BeginUpdate;
    try
      WebHTMLDiv2.HTML.Clear;
      WebHTMLDiv2.HTML.Add('Neueste Fotos');
      WebHTMLDiv2.HTML.Add('');
    finally
      WebHTMLDiv2.HTML.EndUpdate;
    end;
    WebHTMLDiv2.Role := '';
  finally
    divFotos.AfterLoadDFMValues;
    img1.AfterLoadDFMValues;
    img2.AfterLoadDFMValues;
    img3.AfterLoadDFMValues;
    img4.AfterLoadDFMValues;
    img5.AfterLoadDFMValues;
    img6.AfterLoadDFMValues;
    img7.AfterLoadDFMValues;
    img8.AfterLoadDFMValues;
    img9.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
  end;
end;

end.
