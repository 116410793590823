unit ufrKurse;

interface

uses
  System.SysUtils, System.Variants, System.Classes,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.Imaging.jpeg,
  WEBLib.Controls, WEBLib.ExtCtrls, WEBLib.WebCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Web, ufrImgPosition, Vcl.Imaging.pngimage;

type
  TfrKurse = class(TFrame)
    divContainer: THTMLDiv;
    imgHeader: TImageControl;
    lblHeader: THTMLDiv;
    lblText: THTMLDiv;
    lblTitel: THTMLDiv;
    procedure imgHeaderMouseEnter(Sender: TObject);
  protected
    procedure Loaded(); override;
  private
    FKurs:integer;
    FPosition:TfrImgPosition;
  published
    Pfad:array of string;
  public
    property Kurs: integer read FKurs write FKurs;
  protected procedure LoadDFMValues; override; end;

implementation
  Uses uMain;

{$R *.dfm}

procedure TfrKurse.imgHeaderMouseEnter(Sender: TObject);
begin
  if Mainform.ME.Admin
  then begin
    if assigned(FPosition)
    then FPosition.Free;

    FPosition := TfrImgPosition.show(self, divContainer,  imgHeader, 'KURSE', 'MEDIEN_POSITION', FKurs.ToString);
  end;
end;

procedure TfrKurse.Loaded();
var
  el: TJSHTMLElement;
begin
  inherited;
  TControl(self).ElementPosition := epIgnore;
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  //lblText.HTML.Text := '';
end;

procedure TfrKurse.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divContainer := THTMLDiv.Create(Self);
  imgHeader := TImageControl.Create(Self);
  lblHeader := THTMLDiv.Create(Self);
  lblTitel := THTMLDiv.Create(Self);
  lblText := THTMLDiv.Create(Self);

  divContainer.BeforeLoadDFMValues;
  imgHeader.BeforeLoadDFMValues;
  lblHeader.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  lblText.BeforeLoadDFMValues;
  try
    Name := 'frKurse';
    Left := 0;
    Top := 0;
    Width := 406;
    Height := 278;
    TabOrder := 0;
    divContainer.SetParentComponent(Self);
    divContainer.Name := 'divContainer';
    divContainer.Left := 0;
    divContainer.Top := 0;
    divContainer.Width := 394;
    divContainer.Height := 265;
    divContainer.ElementClassName := 'kurse_content';
    divContainer.HeightStyle := ssAuto;
    divContainer.WidthStyle := ssAuto;
    divContainer.ChildOrder := 1;
    divContainer.ElementPosition := epIgnore;
    divContainer.ElementFont := efCSS;
    divContainer.Role := '';
    imgHeader.SetParentComponent(divContainer);
    imgHeader.Name := 'imgHeader';
    imgHeader.Left := 24;
    imgHeader.Top := 25;
    imgHeader.Width := 100;
    imgHeader.Height := 42;
    imgHeader.ElementClassName := 'kurse_image';
    imgHeader.HeightStyle := ssAuto;
    imgHeader.WidthStyle := ssAuto;
    imgHeader.HeightPercent := 100.000000000000000000;
    imgHeader.WidthPercent := 100.000000000000000000;
    imgHeader.Anchors := [];
    imgHeader.ChildOrder := 1;
    imgHeader.ElementFont := efCSS;
    imgHeader.ElementPosition := epIgnore;
    SetEvent(imgHeader, Self, 'OnMouseEnter', 'imgHeaderMouseEnter');
    lblHeader.SetParentComponent(divContainer);
    lblHeader.Name := 'lblHeader';
    lblHeader.Left := 24;
    lblHeader.Top := 105;
    lblHeader.Width := 353;
    lblHeader.Height := 56;
    lblHeader.ElementClassName := 'kurse_titel';
    lblHeader.HeightStyle := ssAuto;
    lblHeader.WidthStyle := ssAuto;
    lblHeader.ChildOrder := 2;
    lblHeader.ElementPosition := epIgnore;
    lblHeader.ElementFont := efCSS;
    lblHeader.Role := '';
    lblTitel.SetParentComponent(lblHeader);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 13;
    lblTitel.Top := 11;
    lblTitel.Width := 100;
    lblTitel.Height := 41;
    lblTitel.ElementClassName := 'kl_content_titel';
    lblTitel.HeightStyle := ssAuto;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.ChildOrder := 2;
    lblTitel.ElementPosition := epIgnore;
    lblTitel.ElementFont := efCSS;
    lblTitel.Role := '';
    lblText.SetParentComponent(divContainer);
    lblText.Name := 'lblText';
    lblText.Left := 16;
    lblText.Top := 167;
    lblText.Width := 361;
    lblText.Height := 82;
    lblText.ElementClassName := 'kurse_text';
    lblText.HeightStyle := ssAuto;
    lblText.WidthStyle := ssAuto;
    lblText.ChildOrder := 3;
    lblText.ElementPosition := epIgnore;
    lblText.ElementFont := efCSS;
    lblText.HTML.BeginUpdate;
    try
      lblText.HTML.Clear;
      lblText.HTML.Add('Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam ');
      lblText.HTML.Add('voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. ');
      lblText.HTML.Add('Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam ');
      lblText.HTML.Add('voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.');
    finally
      lblText.HTML.EndUpdate;
    end;
    lblText.Role := '';
  finally
    divContainer.AfterLoadDFMValues;
    imgHeader.AfterLoadDFMValues;
    lblHeader.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    lblText.AfterLoadDFMValues;
  end;
end;

end.
