unit uEingabe;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  XData.Web.Client, WEBLib.WebCtrls;


type

  TfrmEingabe = class(TForm)
    btnSave: TButton;
    WebButton2: TButton;
    edEingabe: TEdit;
    WebHTMLDiv1: THTMLDiv;
    procedure WebButton2Click(Sender: TObject);
    [async] procedure btnSaveClick(Sender: TObject); async;
  private
    FResultValue:string;
    FUserId:integer;
    procedure SaveAlbum(AlbumName: string);
 public
    [async] class function show(ACaption:string):string; async;
    property ResultValue:string read FResultValue write FResultValue;
    property UserId:integer read FUserId write FUserId;
  protected procedure LoadDFMValues; override; end;



var
  frmEingabe: TfrmEingabe;

implementation
  //  uDatenmodul;
{$R *.dfm}

class function TfrmEingabe.show(ACaption:string):string;
var
  newform: TfrmEingabe;
begin

  newform := TfrmEingabe.Create(application.MainForm);
  newform.Caption := ACaption;
  newform.Popup := true;

  newform.Border := fbDialogSizeable;

  window.location.hash := ACaption;

  await(TfrmEingabe, newform.Load());

  try

    await(TModalResult, newform.Execute);
    result := TfrmEingabe(newform).edEingabe.Text;
  finally
    newform.Free;
  end;

end;


procedure TfrmEingabe.btnSaveClick(Sender: TObject);
begin
//  AWait(SaveAlbum(edEingabe.Text));

  close;
end;

procedure TfrmEingabe.SaveAlbum(AlbumName:string);
begin
end;

procedure TfrmEingabe.WebButton2Click(Sender: TObject);
begin
  Close;
end;

procedure TfrmEingabe.LoadDFMValues;
begin
  inherited LoadDFMValues;

  btnSave := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  edEingabe := TEdit.Create(Self);
  WebHTMLDiv1 := THTMLDiv.Create(Self);

  btnSave.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  edEingabe.BeforeLoadDFMValues;
  WebHTMLDiv1.BeforeLoadDFMValues;
  try
    Name := 'frmEingabe';
    Width := 566;
    Height := 209;
    btnSave.SetParentComponent(Self);
    btnSave.Name := 'btnSave';
    btnSave.Left := 328;
    btnSave.Top := 168;
    btnSave.Width := 96;
    btnSave.Height := 25;
    btnSave.Caption := 'Speichern';
    btnSave.ElementClassName := 'start_button-lets-go';
    btnSave.ElementFont := efCSS;
    btnSave.HeightPercent := 100.000000000000000000;
    btnSave.WidthPercent := 100.000000000000000000;
    SetEvent(btnSave, Self, 'OnClick', 'btnSaveClick');
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 448;
    WebButton2.Top := 168;
    WebButton2.Width := 96;
    WebButton2.Height := 25;
    WebButton2.Caption := 'Abbrechen';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementClassName := 'start_button-lets-go';
    WebButton2.ElementFont := efCSS;
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    edEingabe.SetParentComponent(Self);
    edEingabe.Name := 'edEingabe';
    edEingabe.Left := 24;
    edEingabe.Top := 100;
    edEingabe.Width := 520;
    edEingabe.Height := 62;
    edEingabe.ChildOrder := 3;
    edEingabe.HeightPercent := 100.000000000000000000;
    edEingabe.TextHint := 'hier einf'#252'gen...';
    edEingabe.WidthPercent := 100.000000000000000000;
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 24;
    WebHTMLDiv1.Top := 8;
    WebHTMLDiv1.Width := 520;
    WebHTMLDiv1.Height := 86;
    WebHTMLDiv1.ChildOrder := 3;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.HTML.BeginUpdate;
    try
      WebHTMLDiv1.HTML.Clear;
      WebHTMLDiv1.HTML.Add('1. Video bei Youtube '#246'ffnen<BR>');
      WebHTMLDiv1.HTML.Add('2. Unter dem Video auf "Teilen" klicken<BR>');
      WebHTMLDiv1.HTML.Add('3. Dann auf "Einbetten" klicken<BR>');
      WebHTMLDiv1.HTML.Add('4. Es '#246'ffnet sich ein neues Fenster mit dem Titel "Video einbetten". den darin enthaltenen Text - beginnend mit "<B>iframe width=</B>" komplett ');
      WebHTMLDiv1.HTML.Add('markieren<BR>');
      WebHTMLDiv1.HTML.Add('5. Hier in das Eingabefeld einf'#252'gen<BR>');
      WebHTMLDiv1.HTML.Add('6. Speichern klicken<BR>');
    finally
      WebHTMLDiv1.HTML.EndUpdate;
    end;
    WebHTMLDiv1.Role := '';
  finally
    btnSave.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    edEingabe.AfterLoadDFMValues;
    WebHTMLDiv1.AfterLoadDFMValues;
  end;
end;

end.
