unit ufrPost;

interface

uses
  WEBLib.ExtCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Controls, WEBLib.Controls, System.Classes,
  WEBLib.WebCtrls,Vcl.Forms, Vcl.Imaging.jpeg, Vcl.Imaging.pngimage,
  XData.Web.Client, JS,web, WEBLib.Dialogs, System.SysUtils, ufrVideoListe,
  WEBLib.Forms, ufrFotosListe, System.Types,
  WEBLib.Graphics, uTB;

type
  TfrPost = class(TFrame)
    divContainer: THTMLDiv;
    XDataWebClient1: TXDataWebClient;
    WebHTMLDiv1: THTMLDiv;
    imgAvatar: TImageControl;
    divPinned: THTMLDiv;
    imgPinned: TImageControl;
    lblText1: THTMLDiv;
    lblText: TLinkLabel;
    lblName: TLinkLabel;
    lblDatum: TLabel;
    divImg: THTMLDiv;
    divImages: THTMLDiv;
    frFotosliste1: TfrFotosliste;
    divVideos: THTMLDiv;
    frVideoListe1: TfrVideoListe;
    divMenue: THTMLDiv;
    btnEdit: TImageControl;
    btnPin: TImageControl;
    btnFoto: TImageControl;
    btnVideo: TImageControl;
    btnDelete: TImageControl;
    btnHide: TImageControl;
    divLikeWrapper: THTMLDiv;
    btnLike: TImageControl;
    divLikeCount: THTMLDiv;
    divlikeUser: THTMLDiv;
    divComment: THTMLDiv;
    btnComment: TImageControl;
    procedure lblNameClick(Sender: TObject);
    [async] procedure btnDeleteClick(Sender: TObject); async;
    [async] procedure btnLikeClick(Sender: TObject); async;
    procedure divContainerMouseEnter(Sender: TObject);
    procedure divContainerMouseLeave(Sender: TObject);
    procedure lblTextLinkClick(Sender: TObject; Link: string; LinkType: TSysLinkType);
    [async] procedure btnHideClick(Sender: TObject); async;
    procedure divImgClick(Sender: TObject);
  private
    FID,
    FUser,
    FHolder:integer;
    FPostID,
    FParentID:integer;
  public
    property Id: integer read FId write FID;
    property Holder: integer read FHolder write FHolder;
    property User: integer read FUser write FUser;
    property PostID: integer read FPostID write FPostID;
    property ParentID: integer read FParentID write FParentID;
  protected procedure LoadDFMValues; override; end;

implementation
   uses uSlider, uDatenmodul, uPosts, uMain, uPostEdit;

{$R *.dfm}


procedure TfrPost.btnDeleteClick(Sender: TObject);
var
  o: TfrPost;
begin

  if FUser = mainform.me.ID
  then begin
    if await(Frage('Beitrag endgültig löschen?')) = mrYes
    then begin
       o := TfrPost(self);

       if await(datenmodul.PostDel(ID)) = 1
       then o.Free
       else await(Meldung('Beitrag kann nicht gelöscht werden, da bereits Kommentare existieren...'));
    end;
  end

end;

procedure TfrPost.btnLikeClick(Sender: TObject);
Var
  i,j:integer;
begin
  if Mainform.ME.Rechte.Comment[riPost]
  then begin
    i := await(datenmodul.LikeSet(Mainform.ME.id, FID, integer(etPost), integer(ltLike) ));
    GetLikeCount(FID, integer(etPost), integer(ltLike), divLikeWrapper, divLikeUser, divLikeCount);
  end
  else Meldung('Aufgrund deines Mitgliedstatus kannst Du den Beitrag leider nicht mit "gefällt mir" markieren...');
end;

procedure TfrPost.divContainerMouseEnter(Sender: TObject);
begin
  THTMLDiv(self).ElementHandle.style.setProperty('box-shadow','3px 3px 7px #888');
end;

procedure TfrPost.divContainerMouseLeave(Sender: TObject);
begin
  THTMLDiv(self).ElementHandle.style.removeProperty('box-shadow');
end;

procedure TfrPost.divImgClick(Sender: TObject);
begin
  TfrmSlider.Fotos(FHolder, FUser, 0, FPostID, 0, 0, THTMLDiv(sender).Tag, nil, 0);
end;

procedure TfrPost.lblNameClick(Sender: TObject);
begin
  if (Mainform.Holder.ID <> FUser)
  then Mainform.HolderID := FUser;
end;

procedure TfrPost.lblTextLinkClick(Sender: TObject; Link: string; LinkType: TSysLinkType);
var
  s:string;
begin
  if (pos('USR=',Link) = 1) or (pos('GRP=',Link) = 1)
  then begin
    s := copy(Link, pos('=',Link)+1);
    openurl(Mainform.ME.ID.ToString, s, _call_ME,  '1','0');
  end
  else begin
    asm
      window.open(Link, '_blank');
    end;
  end;
end;

procedure TfrPost.btnHideClick(Sender: TObject);
var
  o: TfrPost;
begin
  if await(Frage('Diesen Beitrag nicht mehr anzeigen?')) = mrYes
  then begin
     o := TfrPost(self);
     if await(datenmodul.easyInsert('INSERT INTO Hide (ITEM_ID, USER_ID) VALUES (' + FID.ToString + ',' + Mainform.ME.ID.ToString + ')')) > 0
     then o.Free;
  end;
end;

procedure TfrPost.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divContainer := THTMLDiv.Create(Self);
  WebHTMLDiv1 := THTMLDiv.Create(Self);
  imgAvatar := TImageControl.Create(Self);
  lblName := TLinkLabel.Create(Self);
  lblDatum := TLabel.Create(Self);
  divPinned := THTMLDiv.Create(Self);
  imgPinned := TImageControl.Create(Self);
  lblText1 := THTMLDiv.Create(Self);
  lblText := TLinkLabel.Create(Self);
  divImg := THTMLDiv.Create(Self);
  divImages := THTMLDiv.Create(Self);
  frFotosliste1 := TfrFotosliste.Create(Self);
  divVideos := THTMLDiv.Create(Self);
  frVideoListe1 := TfrVideoListe.Create(Self);
  divMenue := THTMLDiv.Create(Self);
  btnEdit := TImageControl.Create(Self);
  btnPin := TImageControl.Create(Self);
  btnFoto := TImageControl.Create(Self);
  btnVideo := TImageControl.Create(Self);
  btnDelete := TImageControl.Create(Self);
  btnHide := TImageControl.Create(Self);
  btnComment := TImageControl.Create(Self);
  divLikeWrapper := THTMLDiv.Create(Self);
  btnLike := TImageControl.Create(Self);
  divLikeCount := THTMLDiv.Create(Self);
  divlikeUser := THTMLDiv.Create(Self);
  divComment := THTMLDiv.Create(Self);
  XDataWebClient1 := TXDataWebClient.Create(Self);

  frFotosliste1.LoadFromForm;
  frVideoListe1.LoadFromForm;
  divContainer.BeforeLoadDFMValues;
  WebHTMLDiv1.BeforeLoadDFMValues;
  imgAvatar.BeforeLoadDFMValues;
  lblName.BeforeLoadDFMValues;
  lblDatum.BeforeLoadDFMValues;
  divPinned.BeforeLoadDFMValues;
  imgPinned.BeforeLoadDFMValues;
  lblText1.BeforeLoadDFMValues;
  lblText.BeforeLoadDFMValues;
  divImg.BeforeLoadDFMValues;
  divImages.BeforeLoadDFMValues;
  frFotosliste1.BeforeLoadDFMValues;
  frFotosliste1.WebHTMLDiv1.BeforeLoadDFMValues;
  frFotosliste1.divWrapper.BeforeLoadDFMValues;
  frFotosliste1.img.BeforeLoadDFMValues;
  divVideos.BeforeLoadDFMValues;
  frVideoListe1.BeforeLoadDFMValues;
  frVideoListe1.WebHTMLDiv1.BeforeLoadDFMValues;
  frVideoListe1.divWrapper.BeforeLoadDFMValues;
  frVideoListe1.img.BeforeLoadDFMValues;
  frVideoListe1.Client.BeforeLoadDFMValues;
  divMenue.BeforeLoadDFMValues;
  btnEdit.BeforeLoadDFMValues;
  btnPin.BeforeLoadDFMValues;
  btnFoto.BeforeLoadDFMValues;
  btnVideo.BeforeLoadDFMValues;
  btnDelete.BeforeLoadDFMValues;
  btnHide.BeforeLoadDFMValues;
  btnComment.BeforeLoadDFMValues;
  divLikeWrapper.BeforeLoadDFMValues;
  btnLike.BeforeLoadDFMValues;
  divLikeCount.BeforeLoadDFMValues;
  divlikeUser.BeforeLoadDFMValues;
  divComment.BeforeLoadDFMValues;
  XDataWebClient1.BeforeLoadDFMValues;
  try
    Name := 'frPost';
    Left := 0;
    Top := 0;
    Width := 809;
    Height := 659;
    TabOrder := 0;
    divContainer.SetParentComponent(Self);
    divContainer.Name := 'divContainer';
    divContainer.Left := 0;
    divContainer.Top := 3;
    divContainer.Width := 801;
    divContainer.Height := 646;
    divContainer.ElementClassName := 'beitrag_content';
    divContainer.HeightStyle := ssAuto;
    divContainer.WidthStyle := ssAuto;
    divContainer.ElementPosition := epIgnore;
    divContainer.ElementFont := efCSS;
    divContainer.Role := '';
    SetEvent(divContainer, Self, 'OnMouseEnter', 'divContainerMouseEnter');
    SetEvent(divContainer, Self, 'OnMouseLeave', 'divContainerMouseLeave');
    WebHTMLDiv1.SetParentComponent(divContainer);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 18;
    WebHTMLDiv1.Top := 3;
    WebHTMLDiv1.Width := 767;
    WebHTMLDiv1.Height := 65;
    WebHTMLDiv1.ElementClassName := 'beitrag_content_header';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    imgAvatar.SetParentComponent(WebHTMLDiv1);
    imgAvatar.Name := 'imgAvatar';
    imgAvatar.Left := 3;
    imgAvatar.Top := 3;
    imgAvatar.Width := 65;
    imgAvatar.Height := 41;
    imgAvatar.ElementClassName := 'beitrag_content_avatar';
    imgAvatar.HeightStyle := ssAuto;
    imgAvatar.WidthStyle := ssAuto;
    imgAvatar.HeightPercent := 100.000000000000000000;
    imgAvatar.WidthPercent := 100.000000000000000000;
    imgAvatar.ElementFont := efCSS;
    imgAvatar.ElementPosition := epIgnore;
    lblName.SetParentComponent(WebHTMLDiv1);
    lblName.Name := 'lblName';
    lblName.Left := 97;
    lblName.Top := 21;
    lblName.Width := 45;
    lblName.Height := 15;
    lblName.ChildOrder := 1;
    lblName.ElementClassName := 'beitrag_about_header';
    lblName.ElementFont := efCSS;
    lblName.HeightStyle := ssAuto;
    lblName.WidthStyle := ssAuto;
    lblName.HeightPercent := 100.000000000000000000;
    lblName.WidthPercent := 100.000000000000000000;
    SetEvent(lblName, Self, 'OnLinkClick', 'lblTextLinkClick');
    lblName.Caption := 'lblName';
    lblDatum.SetParentComponent(WebHTMLDiv1);
    lblDatum.Name := 'lblDatum';
    lblDatum.Left := 598;
    lblDatum.Top := 34;
    lblDatum.Width := 49;
    lblDatum.Height := 15;
    lblDatum.Caption := 'lblDatum';
    lblDatum.ChildOrder := 1;
    lblDatum.Color := clBtnFace;
    lblDatum.ElementClassName := 'beitrag_about_text';
    lblDatum.ElementFont := efCSS;
    lblDatum.ElementPosition := epIgnore;
    lblDatum.HeightStyle := ssAuto;
    lblDatum.HeightPercent := 100.000000000000000000;
    lblDatum.WidthStyle := ssAuto;
    lblDatum.WidthPercent := 100.000000000000000000;
    divPinned.SetParentComponent(WebHTMLDiv1);
    divPinned.Name := 'divPinned';
    divPinned.Left := 701;
    divPinned.Top := 21;
    divPinned.Width := 46;
    divPinned.Height := 41;
    divPinned.ElementClassName := 'beitrag_pinned';
    divPinned.HeightStyle := ssAuto;
    divPinned.WidthStyle := ssAuto;
    divPinned.ChildOrder := 3;
    divPinned.ElementPosition := epIgnore;
    divPinned.ElementFont := efCSS;
    divPinned.Role := '';
    divPinned.Visible := False;
    imgPinned.SetParentComponent(divPinned);
    imgPinned.Name := 'imgPinned';
    imgPinned.Left := 9;
    imgPinned.Top := -5;
    imgPinned.Width := 34;
    imgPinned.Height := 33;
    imgPinned.ElementClassName := 'post_pin';
    imgPinned.HeightStyle := ssAuto;
    imgPinned.WidthStyle := ssAuto;
    imgPinned.HeightPercent := 100.000000000000000000;
    imgPinned.WidthPercent := 100.000000000000000000;
    imgPinned.Anchors := [];
    imgPinned.ChildOrder := 1;
    imgPinned.ElementFont := efCSS;
    imgPinned.ElementPosition := epIgnore;
    imgPinned.Picture.LoadFromFile('ufrPost.divPinned.imgPinned.Picture.png');
    lblText1.SetParentComponent(divContainer);
    lblText1.Name := 'lblText1';
    lblText1.Left := 18;
    lblText1.Top := 74;
    lblText1.Width := 767;
    lblText1.Height := 95;
    lblText1.HeightStyle := ssAuto;
    lblText1.WidthStyle := ssAuto;
    lblText1.ChildOrder := 1;
    lblText1.ElementPosition := epRelative;
    lblText1.ElementFont := efCSS;
    lblText1.HTML.BeginUpdate;
    try
      lblText1.HTML.Clear;
      lblText1.HTML.Add('');
    finally
      lblText1.HTML.EndUpdate;
    end;
    lblText1.Role := '';
    lblText.SetParentComponent(lblText1);
    lblText.Name := 'lblText';
    lblText.Left := 15;
    lblText.Top := 16;
    lblText.Width := 34;
    lblText.Height := 15;
    lblText.ElementClassName := 'beitrag_text';
    lblText.ElementFont := efCSS;
    lblText.HeightStyle := ssAuto;
    lblText.WidthStyle := ssAuto;
    lblText.HeightPercent := 100.000000000000000000;
    lblText.WidthPercent := 100.000000000000000000;
    SetEvent(lblText, Self, 'OnLinkClick', 'lblTextLinkClick');
    lblText.Caption := 'lblText';
    divImg.SetParentComponent(divContainer);
    divImg.Name := 'divImg';
    divImg.Left := 18;
    divImg.Top := 175;
    divImg.Width := 767;
    divImg.Height := 106;
    divImg.HeightStyle := ssAuto;
    divImg.WidthStyle := ssAuto;
    divImg.ChildOrder := 2;
    divImg.ElementPosition := epRelative;
    divImg.ElementFont := efCSS;
    divImg.Role := '';
    SetEvent(divImg, Self, 'OnClick', 'divImgClick');
    divImages.SetParentComponent(divContainer);
    divImages.Name := 'divImages';
    divImages.Left := 18;
    divImages.Top := 287;
    divImages.Width := 767;
    divImages.Height := 106;
    divImages.ElementClassName := 'beitrag_content_images';
    divImages.HeightStyle := ssAuto;
    divImages.WidthStyle := ssAuto;
    divImages.ChildOrder := 2;
    divImages.ElementPosition := epIgnore;
    divImages.ElementFont := efCSS;
    divImages.Role := '';
    divImages.Visible := False;
    frFotosliste1.SetParentComponent(divImages);
    frFotosliste1.Name := 'frFotosliste1';
    frFotosliste1.Left := 11;
    frFotosliste1.Top := 14;
    frFotosliste1.Width := 230;
    frFotosliste1.Height := 171;
    frFotosliste1.Margins.Left := 0;
    frFotosliste1.Margins.Top := 0;
    frFotosliste1.Margins.Right := 0;
    frFotosliste1.Margins.Bottom := 0;
    frFotosliste1.TabOrder := 0;
    frFotosliste1.WebHTMLDiv1.Left := 1;
    frFotosliste1.WebHTMLDiv1.Width := 206;
    frFotosliste1.WebHTMLDiv1.Height := 121;
    frFotosliste1.divWrapper.Left := 11;
    frFotosliste1.divWrapper.Top := 10;
    frFotosliste1.divWrapper.Width := 182;
    frFotosliste1.divWrapper.Height := 103;
    frFotosliste1.img.Left := 49;
    frFotosliste1.img.Top := 20;
    frFotosliste1.img.Visible := False;
    divVideos.SetParentComponent(divContainer);
    divVideos.Name := 'divVideos';
    divVideos.Left := 21;
    divVideos.Top := 399;
    divVideos.Width := 767;
    divVideos.Height := 106;
    divVideos.ElementClassName := 'beitrag_content_images';
    divVideos.HeightStyle := ssAuto;
    divVideos.WidthStyle := ssAuto;
    divVideos.ChildOrder := 4;
    divVideos.ElementPosition := epIgnore;
    divVideos.ElementFont := efCSS;
    divVideos.Role := '';
    divVideos.Visible := False;
    frVideoListe1.SetParentComponent(divVideos);
    frVideoListe1.Name := 'frVideoListe1';
    frVideoListe1.Left := 13;
    frVideoListe1.Top := 0;
    frVideoListe1.Width := 301;
    frVideoListe1.Height := 172;
    frVideoListe1.Margins.Left := 0;
    frVideoListe1.Margins.Top := 0;
    frVideoListe1.Margins.Right := 0;
    frVideoListe1.Margins.Bottom := 0;
    frVideoListe1.TabOrder := 0;
    frVideoListe1.WebHTMLDiv1.Top := 16;
    frVideoListe1.WebHTMLDiv1.Height := 161;
    frVideoListe1.divWrapper.Left := 11;
    frVideoListe1.divWrapper.Width := 233;
    frVideoListe1.divWrapper.Height := 137;
    frVideoListe1.img.Left := 24;
    frVideoListe1.img.Top := 16;
    frVideoListe1.img.Width := 65;
    frVideoListe1.img.Height := 57;
    frVideoListe1.Client.Left := 16;
    frVideoListe1.Client.Top := 16;
    divMenue.SetParentComponent(divContainer);
    divMenue.Name := 'divMenue';
    divMenue.Left := 18;
    divMenue.Top := 511;
    divMenue.Width := 766;
    divMenue.Height := 48;
    divMenue.ElementClassName := 'beitrag_side_menu_wrapper';
    divMenue.HeightStyle := ssAuto;
    divMenue.WidthStyle := ssAuto;
    divMenue.ChildOrder := 4;
    divMenue.ElementPosition := epIgnore;
    divMenue.ElementFont := efCSS;
    divMenue.Role := '';
    btnEdit.SetParentComponent(divMenue);
    btnEdit.Name := 'btnEdit';
    btnEdit.Left := 15;
    btnEdit.Top := 4;
    btnEdit.Width := 38;
    btnEdit.Height := 44;
    btnEdit.Hint := 'diesen Beitrag '#228'ndern';
    btnEdit.ElementClassName := 'blk_side_menu_btn';
    btnEdit.HeightStyle := ssAuto;
    btnEdit.WidthStyle := ssAuto;
    btnEdit.HeightPercent := 100.000000000000000000;
    btnEdit.WidthPercent := 100.000000000000000000;
    btnEdit.Anchors := [];
    btnEdit.ElementFont := efCSS;
    btnEdit.ElementPosition := epIgnore;
    btnEdit.ShowHint := True;
    btnEdit.Picture.LoadFromFile('ufrPost.divMenue.btnEdit.Picture.png');
    btnPin.SetParentComponent(divMenue);
    btnPin.Name := 'btnPin';
    btnPin.Left := 261;
    btnPin.Top := 4;
    btnPin.Width := 38;
    btnPin.Height := 44;
    btnPin.Hint := 'pinne diese Beitrag dauerhaft an die oberste Position';
    btnPin.ElementClassName := 'blk_side_menu_btn';
    btnPin.HeightStyle := ssAuto;
    btnPin.WidthStyle := ssAuto;
    btnPin.HeightPercent := 100.000000000000000000;
    btnPin.WidthPercent := 100.000000000000000000;
    btnPin.Anchors := [];
    btnPin.ChildOrder := 3;
    btnPin.ElementFont := efCSS;
    btnPin.ElementPosition := epIgnore;
    btnPin.ShowHint := True;
    btnPin.Picture.LoadFromFile('ufrPost.divMenue.btnPin.Picture.png');
    btnFoto.SetParentComponent(divMenue);
    btnFoto.Name := 'btnFoto';
    btnFoto.Left := 305;
    btnFoto.Top := 3;
    btnFoto.Width := 46;
    btnFoto.Height := 44;
    btnFoto.ElementClassName := 'blk_side_menu_btn';
    btnFoto.HeightStyle := ssAuto;
    btnFoto.WidthStyle := ssAuto;
    btnFoto.HeightPercent := 100.000000000000000000;
    btnFoto.WidthPercent := 100.000000000000000000;
    btnFoto.Anchors := [];
    btnFoto.ChildOrder := 3;
    btnFoto.ElementFont := efCSS;
    btnFoto.ElementPosition := epIgnore;
    btnFoto.Visible := False;
    btnFoto.Picture.LoadFromFile('ufrPost.divMenue.btnFoto.Picture.png');
    btnVideo.SetParentComponent(divMenue);
    btnVideo.Name := 'btnVideo';
    btnVideo.Left := 357;
    btnVideo.Top := 4;
    btnVideo.Width := 46;
    btnVideo.Height := 44;
    btnVideo.ElementClassName := 'blk_side_menu_btn';
    btnVideo.HeightStyle := ssAuto;
    btnVideo.WidthStyle := ssAuto;
    btnVideo.HeightPercent := 100.000000000000000000;
    btnVideo.WidthPercent := 100.000000000000000000;
    btnVideo.Anchors := [];
    btnVideo.ChildOrder := 5;
    btnVideo.ElementFont := efCSS;
    btnVideo.ElementPosition := epIgnore;
    btnVideo.Visible := False;
    btnVideo.Picture.LoadFromFile('ufrPost.divMenue.btnVideo.Picture.png');
    btnDelete.SetParentComponent(divMenue);
    btnDelete.Name := 'btnDelete';
    btnDelete.Left := 137;
    btnDelete.Top := 0;
    btnDelete.Width := 46;
    btnDelete.Height := 44;
    btnDelete.Hint := 'l'#246'schen diesen Beitrag';
    btnDelete.ElementClassName := 'blk_side_menu_btn';
    btnDelete.HeightStyle := ssAuto;
    btnDelete.WidthStyle := ssAuto;
    btnDelete.HeightPercent := 100.000000000000000000;
    btnDelete.WidthPercent := 100.000000000000000000;
    btnDelete.Anchors := [];
    btnDelete.ChildOrder := 1;
    btnDelete.ElementFont := efCSS;
    btnDelete.ElementPosition := epIgnore;
    btnDelete.ShowHint := True;
    btnDelete.Visible := False;
    SetEvent(btnDelete, Self, 'OnClick', 'btnDeleteClick');
    btnDelete.Picture.LoadFromFile('ufrPost.divMenue.btnDelete.Picture.png');
    btnHide.SetParentComponent(divMenue);
    btnHide.Name := 'btnHide';
    btnHide.Left := 409;
    btnHide.Top := 0;
    btnHide.Width := 46;
    btnHide.Height := 44;
    btnHide.Hint := 'verberge diesen Beitrag ';
    btnHide.ElementClassName := 'blk_side_menu_btn';
    btnHide.HeightStyle := ssAuto;
    btnHide.WidthStyle := ssAuto;
    btnHide.HeightPercent := 100.000000000000000000;
    btnHide.WidthPercent := 100.000000000000000000;
    btnHide.Anchors := [];
    btnHide.ChildOrder := 6;
    btnHide.ElementFont := efCSS;
    btnHide.ElementPosition := epIgnore;
    btnHide.ShowHint := True;
    SetEvent(btnHide, Self, 'OnClick', 'btnHideClick');
    btnHide.Picture.LoadFromFile('ufrPost.divMenue.btnHide.Picture.png');
    btnComment.SetParentComponent(divMenue);
    btnComment.Name := 'btnComment';
    btnComment.Left := 70;
    btnComment.Top := 4;
    btnComment.Width := 46;
    btnComment.Height := 44;
    btnComment.Hint := 'schreibe einen Kommentar';
    btnComment.ElementClassName := 'blk_side_menu_btn';
    btnComment.HeightStyle := ssAuto;
    btnComment.WidthStyle := ssAuto;
    btnComment.HeightPercent := 100.000000000000000000;
    btnComment.WidthPercent := 100.000000000000000000;
    btnComment.Anchors := [];
    btnComment.ChildOrder := 1;
    btnComment.ElementFont := efCSS;
    btnComment.ElementPosition := epIgnore;
    btnComment.ShowHint := True;
    btnComment.Picture.LoadFromFile('ufrPost.divMenue.btnComment.Picture.png');
    divLikeWrapper.SetParentComponent(divMenue);
    divLikeWrapper.Name := 'divLikeWrapper';
    divLikeWrapper.Left := 520;
    divLikeWrapper.Top := -11;
    divLikeWrapper.Width := 187;
    divLikeWrapper.Height := 56;
    divLikeWrapper.ElementClassName := 'like_wrapper';
    divLikeWrapper.HeightStyle := ssAuto;
    divLikeWrapper.WidthStyle := ssAuto;
    divLikeWrapper.ChildOrder := 10;
    divLikeWrapper.ElementPosition := epIgnore;
    divLikeWrapper.ElementFont := efCSS;
    divLikeWrapper.Role := '';
    btnLike.SetParentComponent(divLikeWrapper);
    btnLike.Name := 'btnLike';
    btnLike.Left := 67;
    btnLike.Top := 20;
    btnLike.Width := 34;
    btnLike.Height := 33;
    btnLike.ElementClassName := 'blk_side_menu_btn';
    btnLike.HeightStyle := ssAuto;
    btnLike.WidthStyle := ssAuto;
    btnLike.HeightPercent := 100.000000000000000000;
    btnLike.WidthPercent := 100.000000000000000000;
    btnLike.ChildOrder := 1;
    btnLike.ElementFont := efCSS;
    btnLike.ElementPosition := epIgnore;
    SetEvent(btnLike, Self, 'OnClick', 'btnLikeClick');
    btnLike.Picture.LoadFromFile('ufrPost.divLikeWrapper.btnLike.Picture.png');
    divLikeCount.SetParentComponent(divLikeWrapper);
    divLikeCount.Name := 'divLikeCount';
    divLikeCount.Left := 21;
    divLikeCount.Top := 12;
    divLikeCount.Width := 40;
    divLikeCount.Height := 41;
    divLikeCount.ElementClassName := 'like_count';
    divLikeCount.HeightStyle := ssAuto;
    divLikeCount.WidthStyle := ssAuto;
    divLikeCount.ChildOrder := 1;
    divLikeCount.ElementPosition := epIgnore;
    divLikeCount.ElementFont := efCSS;
    divLikeCount.Role := '';
    divlikeUser.SetParentComponent(divLikeWrapper);
    divlikeUser.Name := 'divlikeUser';
    divlikeUser.Left := 117;
    divlikeUser.Top := 15;
    divlikeUser.Width := 40;
    divlikeUser.Height := 41;
    divlikeUser.ElementClassName := 'like_count';
    divlikeUser.HeightStyle := ssAuto;
    divlikeUser.WidthStyle := ssAuto;
    divlikeUser.ChildOrder := 2;
    divlikeUser.ElementPosition := epIgnore;
    divlikeUser.ElementFont := efCSS;
    divlikeUser.Role := '';
    divComment.SetParentComponent(divContainer);
    divComment.Name := 'divComment';
    divComment.Left := 20;
    divComment.Top := 565;
    divComment.Width := 767;
    divComment.Height := 55;
    divComment.ElementClassName := 'beitrag_comment_wrapper';
    divComment.HeightStyle := ssAuto;
    divComment.WidthStyle := ssAuto;
    divComment.ChildOrder := 6;
    divComment.ElementPosition := epIgnore;
    divComment.ElementFont := efCSS;
    divComment.Role := '';
    XDataWebClient1.SetParentComponent(Self);
    XDataWebClient1.Name := 'XDataWebClient1';
    XDataWebClient1.Left := 80;
    XDataWebClient1.Top := 185;
  finally
    divContainer.AfterLoadDFMValues;
    WebHTMLDiv1.AfterLoadDFMValues;
    imgAvatar.AfterLoadDFMValues;
    lblName.AfterLoadDFMValues;
    lblDatum.AfterLoadDFMValues;
    divPinned.AfterLoadDFMValues;
    imgPinned.AfterLoadDFMValues;
    lblText1.AfterLoadDFMValues;
    lblText.AfterLoadDFMValues;
    divImg.AfterLoadDFMValues;
    divImages.AfterLoadDFMValues;
    frFotosliste1.AfterLoadDFMValues;
    frFotosliste1.WebHTMLDiv1.AfterLoadDFMValues;
    frFotosliste1.divWrapper.AfterLoadDFMValues;
    frFotosliste1.img.AfterLoadDFMValues;
    divVideos.AfterLoadDFMValues;
    frVideoListe1.AfterLoadDFMValues;
    frVideoListe1.WebHTMLDiv1.AfterLoadDFMValues;
    frVideoListe1.divWrapper.AfterLoadDFMValues;
    frVideoListe1.img.AfterLoadDFMValues;
    frVideoListe1.Client.AfterLoadDFMValues;
    divMenue.AfterLoadDFMValues;
    btnEdit.AfterLoadDFMValues;
    btnPin.AfterLoadDFMValues;
    btnFoto.AfterLoadDFMValues;
    btnVideo.AfterLoadDFMValues;
    btnDelete.AfterLoadDFMValues;
    btnHide.AfterLoadDFMValues;
    btnComment.AfterLoadDFMValues;
    divLikeWrapper.AfterLoadDFMValues;
    btnLike.AfterLoadDFMValues;
    divLikeCount.AfterLoadDFMValues;
    divlikeUser.AfterLoadDFMValues;
    divComment.AfterLoadDFMValues;
    XDataWebClient1.AfterLoadDFMValues;
  end;
end;

end.


