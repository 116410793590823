unit ufrFreundeThumb;

interface

uses
  System.SysUtils, System.Variants, System.Classes,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, WEBLib.Controls,
  WEBLib.WebCtrls, Vcl.Imaging.jpeg, WEBLib.ExtCtrls, JS, web,uTb,
  Vcl.Imaging.pngimage, WEBLib.Dialogs,uGlobal,
  WEBLib.Graphics, WEBLib.Forms, Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TfrFreundeThumb = class(TFrame)
    p0: THTMLDiv;
    WebMessageDlg1: TMessageDlg;
    WebHTMLDiv2: THTMLDiv;
    WebHTMLDiv1: THTMLDiv;
    imgTitel: TImageControl;
    imgAvatar: TImageControl;
    lblUserName: THTMLDiv;
    lblUserOrt: THTMLDiv;
    divAboutContainer: THTMLDiv;
    WebHTMLDiv3: THTMLDiv;
    lblAbout: THTMLDiv;
    divHobbiesContainer: THTMLDiv;
    WebHTMLDiv6: THTMLDiv;
    divHobbies: THTMLDiv;
    divReviereContainer: THTMLDiv;
    WebHTMLDiv9: THTMLDiv;
    divReviere: THTMLDiv;
    divExpertiseContainer: THTMLDiv;
    WebHTMLDiv4: THTMLDiv;
    divExpertise: THTMLDiv;
    divOnline: THTMLDiv;
    divInquiry: THTMLDiv;
    btnAccept: TButton;
    btnReject: TButton;
    WebHTMLDiv5: THTMLDiv;
    WebHTMLDiv12: THTMLDiv;
    WebHTMLDiv13: THTMLDiv;
    lblFotos: THTMLDiv;
    WebHTMLDiv15: THTMLDiv;
    WebHTMLDiv16: THTMLDiv;
    lblVideos: THTMLDiv;
    WebHTMLDiv18: THTMLDiv;
    WebHTMLDiv19: THTMLDiv;
    lblDoks: THTMLDiv;
    WebHTMLDiv20: THTMLDiv;
    divMenu: THTMLDiv;
    btnNew: TImageControl;
    btnFoto: TImageControl;
    btnVideo: TImageControl;
    btnDelete: TImageControl;
    WebImageControl1: TImageControl;
    procedure lblUserNameClick(Sender: TObject);
    procedure btnFotoClick(Sender: TObject);
    [async] procedure btnDeleteClick(Sender: TObject); async;
    [async] procedure btnVideoClick(Sender: TObject); async;
    [async] procedure btnAcceptClick(Sender: TObject); async;
    [async] procedure btnRejectClick(Sender: TObject); async;
  private
    FParentTyp,
    FFriendID,
    FHolder,
    FUser:integer;
    FAlbum:integer;
    FTyp,
    FID:integer;
  protected
    procedure Loaded; override;
  public
    [async] class function Add(AParent:TCustomControl; AParentTyp, AFriendId, AHolder, AUser, AAlbum, ATyp:integer):TfrFreundeThumb; async;
    property Holder : integer read FHolder write FHolder;
    property User   : integer read FUser   write FUser;
    property FriendID:integer read FFriendID write FFriendID;
    property Album   :integer read FAlbum write FAlbum;
    property Typ     :integer read FTyp write FTyp;
    property ParentTyp:integer read FParentTyp write FParentTyp;
  protected procedure LoadDFMValues; override; end;

implementation
   uses uMain, uDatenmodul, uSlider;
{$R *.dfm}

class function TfrFreundeThumb.Add(AParent:TCustomControl; AParentTyp, AFriendId, AHolder, AUser, AALbum, ATyp:integer):TfrFreundeThumb;
var
  el: TJSHTMLElement;
  j:TJ;
begin
  result := TfrFreundeThumb.Create(AParent.owner);
  result.LoadFromForm;
  result.parent := AParent;

  TControl(result).elementPosition := epIgnore;
  THTMlDiv(result).ElementID := 'FS' + AFriendID.ToString;
  THTMlDiv(result).ElementClassName := 'fs_frame';

  result.Holder  := AHolder;
  result.User    := AUser;
  result.FriendID:= AFriendID;
  result.Album   := AAlbum;
  result.Typ     := ATyp;
  result.ParentTyp:= AParentTyp;

  el := TJSHTMLElement(THTMLDiv(result).ElementHandle);
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-style');
  el.style.removeProperty('font-size');

  Mainform.SetBlur(result.imgTitel, result.FUser, Mainform.ME.HideFotos);
  Mainform.SetBlur(result.imgAvatar, result.FUser, Mainform.ME.HideFotos);

  j.free;
end;

procedure TfrFreundeThumb.btnAcceptClick(Sender: TObject);
var
  o: TObject;
  s:string;
begin
  if FParentTyp = _UT_User
  then s:= ' Freundschaftsanfrage von ' + lblUserName.HTML.Text + ' annehmen?'
  else s:= ' Einladung in die Gruppe "' + lblUserName.HTML.Text + '" annehmen?';

  if await(Frage(s)) = mrYes
  then begin
    await(datenmodul.EasyExec('UPDATE Friends SET STATUS = 1 Where ID = ' + FFriendID.ToString ));

    if FParentTyp = _UT_User
    then s:= ' Du bist jetzt mit ' + lblUserName.HTML.Text + ' befreundet.'
    else s:= ' Du bist jetzt Mitglied der Gruppe "' + lblUserName.HTML.Text + '".';
    o := self;
    freeAndNil(o);
    Await(Meldung(s));
  end;

end;

procedure TfrFreundeThumb.btnDeleteClick(Sender: TObject);
var
  s:string;
  o: TObject;
begin

  case FParentTyp of
    _UT_User:   s:= ' als Freund entfernen?';
    _UT_Gruppe: s:= ' als Mitglied aus der Gruppe entfernen?';
    _UT_Event:  s:= ' als Teilnehmer der Veranstaltung entfernen?';
  end;

  if await(Frage(lblUserName.HTML.Text + s)) = mrYes
  then begin
    await(datenmodul.EasyExec('DELETE FROM Friends Where ID = ' + FFriendID.ToString ));
    s := lblUserName.HTML.Text + ' wurde entfernt...';
    o := self;
    freeAndNil(o);
    Await(Meldung(s));
  end;

end;

procedure TfrFreundeThumb.btnFotoClick(Sender: TObject);
begin
  TfrmSlider.Fotos(FHolder, FUser, FAlbum, 0,0,0, 0, nil, 2);
end;

procedure TfrFreundeThumb.btnRejectClick(Sender: TObject);
var
  o: TObject;
  s:string;
begin
  if FTyp = _UT_User
  then s:= ' Freundschaftsanfrage von ' + lblUserName.HTML.Text + ' ablehnen?'
  else s:= ' Einladung in die Gruppe "' + lblUserName.HTML.Text + '" ablehnen?';

  if await(Frage(s)) = mrYes
  then begin
    await(datenmodul.EasyExec('DELETE FROM Friends Where ID = ' + FFriendID.ToString ));
    s := 'Die Anfrage wurde entfernt...';
    o := self;
    freeAndNil(o);
    Await(Meldung(s));
  end;

end;

procedure TfrFreundeThumb.btnVideoClick(Sender: TObject);
begin
  TfrmSlider.Videos(FHolder, FUser, FAlbum, 0,0,0, 0, nil, 2);
end;

procedure TfrFreundeThumb.lblUserNameClick(Sender: TObject);
begin
  openURL(mainform.ME.ID.ToString, FUser.tostring, _call_ME,  '1','0');
end;

procedure TfrFreundeThumb.Loaded();
begin
  inherited;

end;

procedure TfrFreundeThumb.LoadDFMValues;
begin
  inherited LoadDFMValues;

  p0 := THTMLDiv.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  WebHTMLDiv1 := THTMLDiv.Create(Self);
  imgTitel := TImageControl.Create(Self);
  imgAvatar := TImageControl.Create(Self);
  divOnline := THTMLDiv.Create(Self);
  lblUserName := THTMLDiv.Create(Self);
  lblUserOrt := THTMLDiv.Create(Self);
  divAboutContainer := THTMLDiv.Create(Self);
  WebHTMLDiv3 := THTMLDiv.Create(Self);
  lblAbout := THTMLDiv.Create(Self);
  divHobbiesContainer := THTMLDiv.Create(Self);
  WebHTMLDiv6 := THTMLDiv.Create(Self);
  divHobbies := THTMLDiv.Create(Self);
  divReviereContainer := THTMLDiv.Create(Self);
  WebHTMLDiv9 := THTMLDiv.Create(Self);
  divReviere := THTMLDiv.Create(Self);
  divExpertiseContainer := THTMLDiv.Create(Self);
  WebHTMLDiv4 := THTMLDiv.Create(Self);
  divExpertise := THTMLDiv.Create(Self);
  divInquiry := THTMLDiv.Create(Self);
  btnAccept := TButton.Create(Self);
  btnReject := TButton.Create(Self);
  WebHTMLDiv5 := THTMLDiv.Create(Self);
  WebHTMLDiv12 := THTMLDiv.Create(Self);
  WebHTMLDiv13 := THTMLDiv.Create(Self);
  lblFotos := THTMLDiv.Create(Self);
  WebHTMLDiv15 := THTMLDiv.Create(Self);
  WebHTMLDiv16 := THTMLDiv.Create(Self);
  lblVideos := THTMLDiv.Create(Self);
  WebHTMLDiv18 := THTMLDiv.Create(Self);
  WebHTMLDiv19 := THTMLDiv.Create(Self);
  lblDoks := THTMLDiv.Create(Self);
  WebHTMLDiv20 := THTMLDiv.Create(Self);
  divMenu := THTMLDiv.Create(Self);
  btnNew := TImageControl.Create(Self);
  btnFoto := TImageControl.Create(Self);
  btnVideo := TImageControl.Create(Self);
  btnDelete := TImageControl.Create(Self);
  WebImageControl1 := TImageControl.Create(Self);
  WebMessageDlg1 := TMessageDlg.Create(Self);

  p0.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  WebHTMLDiv1.BeforeLoadDFMValues;
  imgTitel.BeforeLoadDFMValues;
  imgAvatar.BeforeLoadDFMValues;
  divOnline.BeforeLoadDFMValues;
  lblUserName.BeforeLoadDFMValues;
  lblUserOrt.BeforeLoadDFMValues;
  divAboutContainer.BeforeLoadDFMValues;
  WebHTMLDiv3.BeforeLoadDFMValues;
  lblAbout.BeforeLoadDFMValues;
  divHobbiesContainer.BeforeLoadDFMValues;
  WebHTMLDiv6.BeforeLoadDFMValues;
  divHobbies.BeforeLoadDFMValues;
  divReviereContainer.BeforeLoadDFMValues;
  WebHTMLDiv9.BeforeLoadDFMValues;
  divReviere.BeforeLoadDFMValues;
  divExpertiseContainer.BeforeLoadDFMValues;
  WebHTMLDiv4.BeforeLoadDFMValues;
  divExpertise.BeforeLoadDFMValues;
  divInquiry.BeforeLoadDFMValues;
  btnAccept.BeforeLoadDFMValues;
  btnReject.BeforeLoadDFMValues;
  WebHTMLDiv5.BeforeLoadDFMValues;
  WebHTMLDiv12.BeforeLoadDFMValues;
  WebHTMLDiv13.BeforeLoadDFMValues;
  lblFotos.BeforeLoadDFMValues;
  WebHTMLDiv15.BeforeLoadDFMValues;
  WebHTMLDiv16.BeforeLoadDFMValues;
  lblVideos.BeforeLoadDFMValues;
  WebHTMLDiv18.BeforeLoadDFMValues;
  WebHTMLDiv19.BeforeLoadDFMValues;
  lblDoks.BeforeLoadDFMValues;
  WebHTMLDiv20.BeforeLoadDFMValues;
  divMenu.BeforeLoadDFMValues;
  btnNew.BeforeLoadDFMValues;
  btnFoto.BeforeLoadDFMValues;
  btnVideo.BeforeLoadDFMValues;
  btnDelete.BeforeLoadDFMValues;
  WebImageControl1.BeforeLoadDFMValues;
  WebMessageDlg1.BeforeLoadDFMValues;
  try
    Name := 'frFreundeThumb';
    Left := 0;
    Top := 0;
    Width := 411;
    Height := 988;
    TabOrder := 0;
    p0.SetParentComponent(Self);
    p0.Name := 'p0';
    p0.Left := 0;
    p0.Top := 3;
    p0.Width := 401;
    p0.Height := 974;
    p0.ElementClassName := 'alben_content';
    p0.HeightStyle := ssAuto;
    p0.WidthStyle := ssAuto;
    p0.ChildOrder := 1;
    p0.ElementPosition := epIgnore;
    p0.ElementFont := efCSS;
    p0.Role := '';
    WebHTMLDiv2.SetParentComponent(p0);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 16;
    WebHTMLDiv2.Top := 19;
    WebHTMLDiv2.Width := 369;
    WebHTMLDiv2.Height := 638;
    WebHTMLDiv2.ElementClassName := 'friends-card-top';
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ElementPosition := epIgnore;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.Role := '';
    WebHTMLDiv1.SetParentComponent(WebHTMLDiv2);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 11;
    WebHTMLDiv1.Top := 11;
    WebHTMLDiv1.Width := 341;
    WebHTMLDiv1.Height := 102;
    WebHTMLDiv1.ElementClassName := 'card_header';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ChildOrder := 8;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    imgTitel.SetParentComponent(WebHTMLDiv1);
    imgTitel.Name := 'imgTitel';
    imgTitel.Left := 3;
    imgTitel.Top := 0;
    imgTitel.Width := 244;
    imgTitel.Height := 86;
    imgTitel.ElementClassName := 'alben_foto';
    imgTitel.HeightStyle := ssAuto;
    imgTitel.WidthStyle := ssAuto;
    imgTitel.HeightPercent := 100.000000000000000000;
    imgTitel.WidthPercent := 100.000000000000000000;
    imgTitel.ElementFont := efCSS;
    imgTitel.ElementPosition := epIgnore;
    imgAvatar.SetParentComponent(WebHTMLDiv1);
    imgAvatar.Name := 'imgAvatar';
    imgAvatar.Left := 67;
    imgAvatar.Top := 16;
    imgAvatar.Width := 100;
    imgAvatar.Height := 57;
    imgAvatar.ElementClassName := 'card_avatar';
    imgAvatar.HeightStyle := ssAuto;
    imgAvatar.WidthStyle := ssAuto;
    imgAvatar.HeightPercent := 100.000000000000000000;
    imgAvatar.WidthPercent := 100.000000000000000000;
    imgAvatar.ChildOrder := 1;
    imgAvatar.ElementFont := efCSS;
    imgAvatar.ElementPosition := epIgnore;
    imgAvatar.Picture.LoadFromFile('ufrFreundeThumb.WebHTMLDiv1.imgAvatar.Picture.jpg');
    divOnline.SetParentComponent(WebHTMLDiv1);
    divOnline.Name := 'divOnline';
    divOnline.Left := 234;
    divOnline.Top := 16;
    divOnline.Width := 100;
    divOnline.Height := 41;
    divOnline.ElementClassName := 'card-online-punkt';
    divOnline.HeightStyle := ssAuto;
    divOnline.WidthStyle := ssAuto;
    divOnline.ChildOrder := 4;
    divOnline.ElementPosition := epIgnore;
    divOnline.ElementFont := efCSS;
    divOnline.HTML.BeginUpdate;
    try
      divOnline.HTML.Clear;
      divOnline.HTML.Add('');
    finally
      divOnline.HTML.EndUpdate;
    end;
    divOnline.Role := '';
    divOnline.Visible := False;
    lblUserName.SetParentComponent(WebHTMLDiv2);
    lblUserName.Name := 'lblUserName';
    lblUserName.Left := 11;
    lblUserName.Top := 119;
    lblUserName.Width := 341;
    lblUserName.Height := 41;
    lblUserName.ElementClassName := 'alben_content_header';
    lblUserName.HeightStyle := ssAuto;
    lblUserName.WidthStyle := ssAuto;
    lblUserName.ChildOrder := 1;
    lblUserName.ElementPosition := epIgnore;
    lblUserName.ElementFont := efCSS;
    lblUserName.Role := '';
    SetEvent(lblUserName, Self, 'OnClick', 'lblUserNameClick');
    lblUserOrt.SetParentComponent(WebHTMLDiv2);
    lblUserOrt.Name := 'lblUserOrt';
    lblUserOrt.Left := 11;
    lblUserOrt.Top := 166;
    lblUserOrt.Width := 341;
    lblUserOrt.Height := 41;
    lblUserOrt.ElementClassName := 'alben_content_header';
    lblUserOrt.HeightStyle := ssAuto;
    lblUserOrt.WidthStyle := ssAuto;
    lblUserOrt.ChildOrder := 2;
    lblUserOrt.ElementPosition := epIgnore;
    lblUserOrt.ElementFont := efCSS;
    lblUserOrt.Role := '';
    divAboutContainer.SetParentComponent(WebHTMLDiv2);
    divAboutContainer.Name := 'divAboutContainer';
    divAboutContainer.Left := 11;
    divAboutContainer.Top := 213;
    divAboutContainer.Width := 341;
    divAboutContainer.Height := 108;
    divAboutContainer.ElementClassName := 'alben_content_text';
    divAboutContainer.HeightStyle := ssAuto;
    divAboutContainer.WidthStyle := ssAuto;
    divAboutContainer.ChildOrder := 3;
    divAboutContainer.ElementPosition := epIgnore;
    divAboutContainer.ElementFont := efCSS;
    divAboutContainer.Role := '';
    WebHTMLDiv3.SetParentComponent(divAboutContainer);
    WebHTMLDiv3.Name := 'WebHTMLDiv3';
    WebHTMLDiv3.Left := 14;
    WebHTMLDiv3.Top := 5;
    WebHTMLDiv3.Width := 315;
    WebHTMLDiv3.Height := 41;
    WebHTMLDiv3.ElementClassName := 'card_about_header';
    WebHTMLDiv3.HeightStyle := ssAuto;
    WebHTMLDiv3.WidthStyle := ssAuto;
    WebHTMLDiv3.ChildOrder := 4;
    WebHTMLDiv3.ElementPosition := epIgnore;
    WebHTMLDiv3.ElementFont := efCSS;
    WebHTMLDiv3.HTML.BeginUpdate;
    try
      WebHTMLDiv3.HTML.Clear;
      WebHTMLDiv3.HTML.Add(#220'ber mich');
    finally
      WebHTMLDiv3.HTML.EndUpdate;
    end;
    WebHTMLDiv3.Role := '';
    WebHTMLDiv3.Visible := False;
    lblAbout.SetParentComponent(divAboutContainer);
    lblAbout.Name := 'lblAbout';
    lblAbout.Left := 14;
    lblAbout.Top := 52;
    lblAbout.Width := 315;
    lblAbout.Height := 41;
    lblAbout.ElementClassName := 'me_about_text';
    lblAbout.HeightStyle := ssAuto;
    lblAbout.WidthStyle := ssAuto;
    lblAbout.ChildOrder := 1;
    lblAbout.ElementPosition := epIgnore;
    lblAbout.ElementFont := efCSS;
    lblAbout.HTML.BeginUpdate;
    try
      lblAbout.HTML.Clear;
      lblAbout.HTML.Add('');
    finally
      lblAbout.HTML.EndUpdate;
    end;
    lblAbout.Role := '';
    divHobbiesContainer.SetParentComponent(WebHTMLDiv2);
    divHobbiesContainer.Name := 'divHobbiesContainer';
    divHobbiesContainer.Left := 11;
    divHobbiesContainer.Top := 327;
    divHobbiesContainer.Width := 341;
    divHobbiesContainer.Height := 82;
    divHobbiesContainer.ElementClassName := 'me_about_container';
    divHobbiesContainer.HeightStyle := ssAuto;
    divHobbiesContainer.WidthStyle := ssAuto;
    divHobbiesContainer.ChildOrder := 4;
    divHobbiesContainer.ElementPosition := epIgnore;
    divHobbiesContainer.ElementFont := efCSS;
    divHobbiesContainer.Role := '';
    WebHTMLDiv6.SetParentComponent(divHobbiesContainer);
    WebHTMLDiv6.Name := 'WebHTMLDiv6';
    WebHTMLDiv6.Left := 14;
    WebHTMLDiv6.Top := 3;
    WebHTMLDiv6.Width := 315;
    WebHTMLDiv6.Height := 34;
    WebHTMLDiv6.ElementClassName := 'card_about_header';
    WebHTMLDiv6.HeightStyle := ssAuto;
    WebHTMLDiv6.WidthStyle := ssAuto;
    WebHTMLDiv6.ChildOrder := 5;
    WebHTMLDiv6.ElementPosition := epIgnore;
    WebHTMLDiv6.ElementFont := efCSS;
    WebHTMLDiv6.HTML.BeginUpdate;
    try
      WebHTMLDiv6.HTML.Clear;
      WebHTMLDiv6.HTML.Add('Meine Hobbies');
    finally
      WebHTMLDiv6.HTML.EndUpdate;
    end;
    WebHTMLDiv6.Role := '';
    divHobbies.SetParentComponent(divHobbiesContainer);
    divHobbies.Name := 'divHobbies';
    divHobbies.Left := 14;
    divHobbies.Top := 43;
    divHobbies.Width := 315;
    divHobbies.Height := 34;
    divHobbies.ElementClassName := 'me_about_text';
    divHobbies.HeightStyle := ssAuto;
    divHobbies.WidthStyle := ssAuto;
    divHobbies.ChildOrder := 5;
    divHobbies.ElementPosition := epIgnore;
    divHobbies.ElementFont := efCSS;
    divHobbies.Role := '';
    divReviereContainer.SetParentComponent(WebHTMLDiv2);
    divReviereContainer.Name := 'divReviereContainer';
    divReviereContainer.Left := 12;
    divReviereContainer.Top := 415;
    divReviereContainer.Width := 340;
    divReviereContainer.Height := 100;
    divReviereContainer.ElementClassName := 'me_about_container';
    divReviereContainer.HeightStyle := ssAuto;
    divReviereContainer.WidthStyle := ssAuto;
    divReviereContainer.ChildOrder := 5;
    divReviereContainer.ElementPosition := epIgnore;
    divReviereContainer.ElementFont := efCSS;
    divReviereContainer.Role := '';
    WebHTMLDiv9.SetParentComponent(divReviereContainer);
    WebHTMLDiv9.Name := 'WebHTMLDiv9';
    WebHTMLDiv9.Left := 13;
    WebHTMLDiv9.Top := 3;
    WebHTMLDiv9.Width := 316;
    WebHTMLDiv9.Height := 42;
    WebHTMLDiv9.ElementClassName := 'card_about_header';
    WebHTMLDiv9.HeightStyle := ssAuto;
    WebHTMLDiv9.WidthStyle := ssAuto;
    WebHTMLDiv9.ChildOrder := 6;
    WebHTMLDiv9.ElementPosition := epIgnore;
    WebHTMLDiv9.ElementFont := efCSS;
    WebHTMLDiv9.HTML.BeginUpdate;
    try
      WebHTMLDiv9.HTML.Clear;
      WebHTMLDiv9.HTML.Add('Meine Lieblings-Reviere');
    finally
      WebHTMLDiv9.HTML.EndUpdate;
    end;
    WebHTMLDiv9.Role := '';
    divReviere.SetParentComponent(divReviereContainer);
    divReviere.Name := 'divReviere';
    divReviere.Left := 13;
    divReviere.Top := 51;
    divReviere.Width := 316;
    divReviere.Height := 42;
    divReviere.ElementClassName := 'me_about_text';
    divReviere.HeightStyle := ssAuto;
    divReviere.WidthStyle := ssAuto;
    divReviere.ChildOrder := 6;
    divReviere.ElementPosition := epIgnore;
    divReviere.ElementFont := efCSS;
    divReviere.Role := '';
    divExpertiseContainer.SetParentComponent(WebHTMLDiv2);
    divExpertiseContainer.Name := 'divExpertiseContainer';
    divExpertiseContainer.Left := 12;
    divExpertiseContainer.Top := 521;
    divExpertiseContainer.Width := 340;
    divExpertiseContainer.Height := 106;
    divExpertiseContainer.ElementClassName := 'me_about_container';
    divExpertiseContainer.HeightStyle := ssAuto;
    divExpertiseContainer.WidthStyle := ssAuto;
    divExpertiseContainer.ChildOrder := 6;
    divExpertiseContainer.ElementPosition := epIgnore;
    divExpertiseContainer.ElementFont := efCSS;
    divExpertiseContainer.Role := '';
    WebHTMLDiv4.SetParentComponent(divExpertiseContainer);
    WebHTMLDiv4.Name := 'WebHTMLDiv4';
    WebHTMLDiv4.Left := 13;
    WebHTMLDiv4.Top := 3;
    WebHTMLDiv4.Width := 316;
    WebHTMLDiv4.Height := 42;
    WebHTMLDiv4.ElementClassName := 'card_about_header';
    WebHTMLDiv4.HeightStyle := ssAuto;
    WebHTMLDiv4.WidthStyle := ssAuto;
    WebHTMLDiv4.ChildOrder := 6;
    WebHTMLDiv4.ElementPosition := epIgnore;
    WebHTMLDiv4.ElementFont := efCSS;
    WebHTMLDiv4.HTML.BeginUpdate;
    try
      WebHTMLDiv4.HTML.Clear;
      WebHTMLDiv4.HTML.Add('Meine Expertise');
    finally
      WebHTMLDiv4.HTML.EndUpdate;
    end;
    WebHTMLDiv4.Role := '';
    divExpertise.SetParentComponent(divExpertiseContainer);
    divExpertise.Name := 'divExpertise';
    divExpertise.Left := 13;
    divExpertise.Top := 51;
    divExpertise.Width := 316;
    divExpertise.Height := 42;
    divExpertise.ElementClassName := 'me_about_text';
    divExpertise.HeightStyle := ssAuto;
    divExpertise.WidthStyle := ssAuto;
    divExpertise.ChildOrder := 6;
    divExpertise.ElementPosition := epIgnore;
    divExpertise.ElementFont := efCSS;
    divExpertise.Role := '';
    divInquiry.SetParentComponent(p0);
    divInquiry.Name := 'divInquiry';
    divInquiry.Left := 16;
    divInquiry.Top := 663;
    divInquiry.Width := 369;
    divInquiry.Height := 53;
    divInquiry.ElementClassName := 'me_about_container';
    divInquiry.HeightStyle := ssAuto;
    divInquiry.WidthStyle := ssAuto;
    divInquiry.ChildOrder := 1;
    divInquiry.ElementPosition := epIgnore;
    divInquiry.ElementFont := efCSS;
    divInquiry.Role := '';
    btnAccept.SetParentComponent(divInquiry);
    btnAccept.Name := 'btnAccept';
    btnAccept.Left := 28;
    btnAccept.Top := 16;
    btnAccept.Width := 96;
    btnAccept.Height := 25;
    btnAccept.Caption := 'Anfrage annehmen';
    btnAccept.ElementClassName := 'fs-btn';
    btnAccept.ElementFont := efCSS;
    btnAccept.ElementPosition := epIgnore;
    btnAccept.HeightStyle := ssAuto;
    btnAccept.HeightPercent := 100.000000000000000000;
    btnAccept.WidthStyle := ssAuto;
    btnAccept.WidthPercent := 100.000000000000000000;
    SetEvent(btnAccept, Self, 'OnClick', 'btnAcceptClick');
    btnReject.SetParentComponent(divInquiry);
    btnReject.Name := 'btnReject';
    btnReject.Left := 210;
    btnReject.Top := 16;
    btnReject.Width := 96;
    btnReject.Height := 25;
    btnReject.Caption := 'Anfrage ablehnen';
    btnReject.ChildOrder := 1;
    btnReject.ElementClassName := 'fs-btn';
    btnReject.ElementFont := efCSS;
    btnReject.ElementPosition := epIgnore;
    btnReject.HeightStyle := ssAuto;
    btnReject.HeightPercent := 100.000000000000000000;
    btnReject.WidthStyle := ssAuto;
    btnReject.WidthPercent := 100.000000000000000000;
    SetEvent(btnReject, Self, 'OnClick', 'btnRejectClick');
    WebHTMLDiv5.SetParentComponent(p0);
    WebHTMLDiv5.Name := 'WebHTMLDiv5';
    WebHTMLDiv5.Left := 16;
    WebHTMLDiv5.Top := 722;
    WebHTMLDiv5.Width := 369;
    WebHTMLDiv5.Height := 233;
    WebHTMLDiv5.HeightStyle := ssAuto;
    WebHTMLDiv5.WidthStyle := ssAuto;
    WebHTMLDiv5.ChildOrder := 2;
    WebHTMLDiv5.ElementPosition := epIgnore;
    WebHTMLDiv5.ElementFont := efCSS;
    WebHTMLDiv5.Role := '';
    WebHTMLDiv12.SetParentComponent(WebHTMLDiv5);
    WebHTMLDiv12.Name := 'WebHTMLDiv12';
    WebHTMLDiv12.Left := 5;
    WebHTMLDiv12.Top := 3;
    WebHTMLDiv12.Width := 361;
    WebHTMLDiv12.Height := 160;
    WebHTMLDiv12.ElementClassName := 'alben_statistik_container';
    WebHTMLDiv12.HeightStyle := ssAuto;
    WebHTMLDiv12.WidthStyle := ssAuto;
    WebHTMLDiv12.ChildOrder := 10;
    WebHTMLDiv12.ElementPosition := epIgnore;
    WebHTMLDiv12.ElementFont := efCSS;
    WebHTMLDiv12.Role := '';
    WebHTMLDiv13.SetParentComponent(WebHTMLDiv12);
    WebHTMLDiv13.Name := 'WebHTMLDiv13';
    WebHTMLDiv13.Left := 3;
    WebHTMLDiv13.Top := 3;
    WebHTMLDiv13.Width := 105;
    WebHTMLDiv13.Height := 142;
    WebHTMLDiv13.ElementClassName := 'alben_statistik';
    WebHTMLDiv13.HeightStyle := ssAuto;
    WebHTMLDiv13.WidthStyle := ssAuto;
    WebHTMLDiv13.ChildOrder := 1;
    WebHTMLDiv13.ElementPosition := epIgnore;
    WebHTMLDiv13.ElementFont := efCSS;
    WebHTMLDiv13.Role := '';
    lblFotos.SetParentComponent(WebHTMLDiv13);
    lblFotos.Name := 'lblFotos';
    lblFotos.Left := 2;
    lblFotos.Top := 26;
    lblFotos.Width := 100;
    lblFotos.Height := 41;
    lblFotos.ElementClassName := 'alben_statistik_text';
    lblFotos.HeightStyle := ssAuto;
    lblFotos.WidthStyle := ssAuto;
    lblFotos.ElementPosition := epIgnore;
    lblFotos.ElementFont := efCSS;
    lblFotos.HTML.BeginUpdate;
    try
      lblFotos.HTML.Clear;
      lblFotos.HTML.Add('25');
    finally
      lblFotos.HTML.EndUpdate;
    end;
    lblFotos.Role := '';
    WebHTMLDiv15.SetParentComponent(WebHTMLDiv13);
    WebHTMLDiv15.Name := 'WebHTMLDiv15';
    WebHTMLDiv15.Left := 3;
    WebHTMLDiv15.Top := 73;
    WebHTMLDiv15.Width := 100;
    WebHTMLDiv15.Height := 41;
    WebHTMLDiv15.ElementClassName := 'alben_statistik_header';
    WebHTMLDiv15.HeightStyle := ssAuto;
    WebHTMLDiv15.WidthStyle := ssAuto;
    WebHTMLDiv15.ChildOrder := 1;
    WebHTMLDiv15.ElementPosition := epIgnore;
    WebHTMLDiv15.ElementFont := efCSS;
    WebHTMLDiv15.HTML.BeginUpdate;
    try
      WebHTMLDiv15.HTML.Clear;
      WebHTMLDiv15.HTML.Add('Fotos');
    finally
      WebHTMLDiv15.HTML.EndUpdate;
    end;
    WebHTMLDiv15.Role := '';
    WebHTMLDiv16.SetParentComponent(WebHTMLDiv12);
    WebHTMLDiv16.Name := 'WebHTMLDiv16';
    WebHTMLDiv16.Left := 114;
    WebHTMLDiv16.Top := 3;
    WebHTMLDiv16.Width := 105;
    WebHTMLDiv16.Height := 142;
    WebHTMLDiv16.ElementClassName := 'alben_statistik';
    WebHTMLDiv16.HeightStyle := ssAuto;
    WebHTMLDiv16.WidthStyle := ssAuto;
    WebHTMLDiv16.ChildOrder := 1;
    WebHTMLDiv16.ElementPosition := epIgnore;
    WebHTMLDiv16.ElementFont := efCSS;
    WebHTMLDiv16.Role := '';
    lblVideos.SetParentComponent(WebHTMLDiv16);
    lblVideos.Name := 'lblVideos';
    lblVideos.Left := 2;
    lblVideos.Top := 26;
    lblVideos.Width := 100;
    lblVideos.Height := 41;
    lblVideos.ElementClassName := 'alben_statistik_text';
    lblVideos.HeightStyle := ssAuto;
    lblVideos.WidthStyle := ssAuto;
    lblVideos.ElementPosition := epIgnore;
    lblVideos.ElementFont := efCSS;
    lblVideos.HTML.BeginUpdate;
    try
      lblVideos.HTML.Clear;
      lblVideos.HTML.Add('7');
    finally
      lblVideos.HTML.EndUpdate;
    end;
    lblVideos.Role := '';
    WebHTMLDiv18.SetParentComponent(WebHTMLDiv16);
    WebHTMLDiv18.Name := 'WebHTMLDiv18';
    WebHTMLDiv18.Left := 3;
    WebHTMLDiv18.Top := 73;
    WebHTMLDiv18.Width := 100;
    WebHTMLDiv18.Height := 41;
    WebHTMLDiv18.ElementClassName := 'alben_statistik_header';
    WebHTMLDiv18.HeightStyle := ssAuto;
    WebHTMLDiv18.WidthStyle := ssAuto;
    WebHTMLDiv18.ChildOrder := 1;
    WebHTMLDiv18.ElementPosition := epIgnore;
    WebHTMLDiv18.ElementFont := efCSS;
    WebHTMLDiv18.HTML.BeginUpdate;
    try
      WebHTMLDiv18.HTML.Clear;
      WebHTMLDiv18.HTML.Add('Videos');
    finally
      WebHTMLDiv18.HTML.EndUpdate;
    end;
    WebHTMLDiv18.Role := '';
    WebHTMLDiv19.SetParentComponent(WebHTMLDiv12);
    WebHTMLDiv19.Name := 'WebHTMLDiv19';
    WebHTMLDiv19.Left := 234;
    WebHTMLDiv19.Top := 3;
    WebHTMLDiv19.Width := 105;
    WebHTMLDiv19.Height := 142;
    WebHTMLDiv19.ElementClassName := 'alben_statistik';
    WebHTMLDiv19.HeightStyle := ssAuto;
    WebHTMLDiv19.WidthStyle := ssAuto;
    WebHTMLDiv19.ChildOrder := 1;
    WebHTMLDiv19.ElementPosition := epIgnore;
    WebHTMLDiv19.ElementFont := efCSS;
    WebHTMLDiv19.Role := '';
    lblDoks.SetParentComponent(WebHTMLDiv19);
    lblDoks.Name := 'lblDoks';
    lblDoks.Left := 2;
    lblDoks.Top := 26;
    lblDoks.Width := 100;
    lblDoks.Height := 41;
    lblDoks.ElementClassName := 'alben_statistik_text';
    lblDoks.HeightStyle := ssAuto;
    lblDoks.WidthStyle := ssAuto;
    lblDoks.ElementPosition := epIgnore;
    lblDoks.ElementFont := efCSS;
    lblDoks.HTML.BeginUpdate;
    try
      lblDoks.HTML.Clear;
      lblDoks.HTML.Add('7');
    finally
      lblDoks.HTML.EndUpdate;
    end;
    lblDoks.Role := '';
    WebHTMLDiv20.SetParentComponent(WebHTMLDiv19);
    WebHTMLDiv20.Name := 'WebHTMLDiv20';
    WebHTMLDiv20.Left := 3;
    WebHTMLDiv20.Top := 73;
    WebHTMLDiv20.Width := 100;
    WebHTMLDiv20.Height := 41;
    WebHTMLDiv20.ElementClassName := 'alben_statistik_header';
    WebHTMLDiv20.HeightStyle := ssAuto;
    WebHTMLDiv20.WidthStyle := ssAuto;
    WebHTMLDiv20.ChildOrder := 1;
    WebHTMLDiv20.ElementPosition := epIgnore;
    WebHTMLDiv20.ElementFont := efCSS;
    WebHTMLDiv20.HTML.BeginUpdate;
    try
      WebHTMLDiv20.HTML.Clear;
      WebHTMLDiv20.HTML.Add('Dokumente');
    finally
      WebHTMLDiv20.HTML.EndUpdate;
    end;
    WebHTMLDiv20.Role := '';
    divMenu.SetParentComponent(WebHTMLDiv5);
    divMenu.Name := 'divMenu';
    divMenu.Left := 3;
    divMenu.Top := 169;
    divMenu.Width := 361;
    divMenu.Height := 57;
    divMenu.ElementClassName := 'alben_footer';
    divMenu.HeightStyle := ssAuto;
    divMenu.WidthStyle := ssAuto;
    divMenu.ChildOrder := 20;
    divMenu.ElementPosition := epIgnore;
    divMenu.ElementFont := efCSS;
    divMenu.Role := '';
    btnNew.SetParentComponent(divMenu);
    btnNew.Name := 'btnNew';
    btnNew.Left := 22;
    btnNew.Top := 0;
    btnNew.Width := 38;
    btnNew.Height := 44;
    btnNew.ElementClassName := 'blk_side_menu_btn';
    btnNew.HeightStyle := ssAuto;
    btnNew.WidthStyle := ssAuto;
    btnNew.HeightPercent := 100.000000000000000000;
    btnNew.WidthPercent := 100.000000000000000000;
    btnNew.Anchors := [];
    btnNew.ElementFont := efCSS;
    btnNew.ElementPosition := epIgnore;
    btnNew.Visible := False;
    btnNew.Picture.LoadFromFile('ufrFreundeThumb.divMenu.btnNew.Picture.png');
    btnFoto.SetParentComponent(divMenu);
    btnFoto.Name := 'btnFoto';
    btnFoto.Left := 87;
    btnFoto.Top := 0;
    btnFoto.Width := 46;
    btnFoto.Height := 44;
    btnFoto.ElementClassName := 'blk_side_menu_btn';
    btnFoto.HeightStyle := ssAuto;
    btnFoto.WidthStyle := ssAuto;
    btnFoto.HeightPercent := 100.000000000000000000;
    btnFoto.WidthPercent := 100.000000000000000000;
    btnFoto.Anchors := [];
    btnFoto.ChildOrder := 1;
    btnFoto.ElementFont := efCSS;
    btnFoto.ElementPosition := epIgnore;
    SetEvent(btnFoto, Self, 'OnClick', 'btnFotoClick');
    btnFoto.Picture.LoadFromFile('ufrFreundeThumb.divMenu.btnFoto.Picture.png');
    btnVideo.SetParentComponent(divMenu);
    btnVideo.Name := 'btnVideo';
    btnVideo.Left := 163;
    btnVideo.Top := 3;
    btnVideo.Width := 46;
    btnVideo.Height := 44;
    btnVideo.ElementClassName := 'blk_side_menu_btn';
    btnVideo.HeightStyle := ssAuto;
    btnVideo.WidthStyle := ssAuto;
    btnVideo.HeightPercent := 100.000000000000000000;
    btnVideo.WidthPercent := 100.000000000000000000;
    btnVideo.Anchors := [];
    btnVideo.ChildOrder := 2;
    btnVideo.ElementFont := efCSS;
    btnVideo.ElementPosition := epIgnore;
    SetEvent(btnVideo, Self, 'OnClick', 'btnVideoClick');
    btnVideo.Picture.LoadFromFile('ufrFreundeThumb.divMenu.btnVideo.Picture.png');
    btnDelete.SetParentComponent(divMenu);
    btnDelete.Name := 'btnDelete';
    btnDelete.Left := 230;
    btnDelete.Top := 0;
    btnDelete.Width := 46;
    btnDelete.Height := 44;
    btnDelete.ElementClassName := 'blk_side_menu_btn';
    btnDelete.HeightStyle := ssAuto;
    btnDelete.WidthStyle := ssAuto;
    btnDelete.HeightPercent := 100.000000000000000000;
    btnDelete.WidthPercent := 100.000000000000000000;
    btnDelete.Anchors := [];
    btnDelete.ChildOrder := 2;
    btnDelete.ElementFont := efCSS;
    btnDelete.ElementPosition := epIgnore;
    SetEvent(btnDelete, Self, 'OnClick', 'btnDeleteClick');
    btnDelete.Picture.LoadFromFile('ufrFreundeThumb.divMenu.btnDelete.Picture.png');
    WebImageControl1.SetParentComponent(divMenu);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 282;
    WebImageControl1.Top := 0;
    WebImageControl1.Width := 46;
    WebImageControl1.Height := 44;
    WebImageControl1.ElementClassName := 'blk_side_menu_btn';
    WebImageControl1.HeightStyle := ssAuto;
    WebImageControl1.WidthStyle := ssAuto;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 100.000000000000000000;
    WebImageControl1.Anchors := [];
    WebImageControl1.ChildOrder := 2;
    WebImageControl1.ElementFont := efCSS;
    WebImageControl1.ElementPosition := epIgnore;
    WebImageControl1.Visible := False;
    WebImageControl1.Picture.LoadFromFile('ufrFreundeThumb.divMenu.WebImageControl1.Picture.png');
    WebMessageDlg1.SetParentComponent(Self);
    WebMessageDlg1.Name := 'WebMessageDlg1';
    WebMessageDlg1.Left := 432;
    WebMessageDlg1.Top := 152;
    WebMessageDlg1.Width := 24;
    WebMessageDlg1.Height := 24;
    WebMessageDlg1.HeightStyle := ssAuto;
    WebMessageDlg1.WidthStyle := ssAuto;
    WebMessageDlg1.Buttons := [mbYes,mbNo];
    WebMessageDlg1.Opacity := 0.200000000000000000;
  finally
    p0.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    WebHTMLDiv1.AfterLoadDFMValues;
    imgTitel.AfterLoadDFMValues;
    imgAvatar.AfterLoadDFMValues;
    divOnline.AfterLoadDFMValues;
    lblUserName.AfterLoadDFMValues;
    lblUserOrt.AfterLoadDFMValues;
    divAboutContainer.AfterLoadDFMValues;
    WebHTMLDiv3.AfterLoadDFMValues;
    lblAbout.AfterLoadDFMValues;
    divHobbiesContainer.AfterLoadDFMValues;
    WebHTMLDiv6.AfterLoadDFMValues;
    divHobbies.AfterLoadDFMValues;
    divReviereContainer.AfterLoadDFMValues;
    WebHTMLDiv9.AfterLoadDFMValues;
    divReviere.AfterLoadDFMValues;
    divExpertiseContainer.AfterLoadDFMValues;
    WebHTMLDiv4.AfterLoadDFMValues;
    divExpertise.AfterLoadDFMValues;
    divInquiry.AfterLoadDFMValues;
    btnAccept.AfterLoadDFMValues;
    btnReject.AfterLoadDFMValues;
    WebHTMLDiv5.AfterLoadDFMValues;
    WebHTMLDiv12.AfterLoadDFMValues;
    WebHTMLDiv13.AfterLoadDFMValues;
    lblFotos.AfterLoadDFMValues;
    WebHTMLDiv15.AfterLoadDFMValues;
    WebHTMLDiv16.AfterLoadDFMValues;
    lblVideos.AfterLoadDFMValues;
    WebHTMLDiv18.AfterLoadDFMValues;
    WebHTMLDiv19.AfterLoadDFMValues;
    lblDoks.AfterLoadDFMValues;
    WebHTMLDiv20.AfterLoadDFMValues;
    divMenu.AfterLoadDFMValues;
    btnNew.AfterLoadDFMValues;
    btnFoto.AfterLoadDFMValues;
    btnVideo.AfterLoadDFMValues;
    btnDelete.AfterLoadDFMValues;
    WebImageControl1.AfterLoadDFMValues;
    WebMessageDlg1.AfterLoadDFMValues;
  end;
end;

end.
