unit uSelection;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, uGlobal, uTb, XData.Web.Client, WEBLib.WebCtrls,
  Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls, Vcl.CheckLst, WEBLib.CheckLst,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls;

type
  TfrmSelection = class(TForm)
    Client: TXDataWebClient;
    WebHTMLDiv1: THTMLDiv;
    WebHTMLDiv11: THTMLDiv;
    btnClose: TImageControl;
    lblTitel: TLabel;
    divFotos: THTMLDiv;
    clAuswahl: TCheckListBox;
    divSuchen: THTMLDiv;
    edSuchen: TEdit;
    btnSearch: TButton;
    divInfo: THTMLDiv;
    WebHTMLDiv21: THTMLDiv;
    btnSave: TButton;
    btnCancel: TButton;
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure btnCancelClick(Sender: TObject);
    [async] procedure btnSearchClick(Sender: TObject); async;
    [async] procedure btnSaveClick(Sender: TObject); async;
    [async] procedure btnSaveKurse(Sender: TObject); async;
  private
    FEdit:boolean;
    FCallback:TCallback;
    FContainer:THtmlDiv;
    FOwner : THTMLDiv;
    FTyp, FID:integer;
    J:TJ;
    class var FFrage:string;
    class var FInfo:string;
    class var FTitel:string;
  public
    class function Exec(AID:integer; ATyp:integer = 0): integer;
    class function Freunde(AHolder:integer): integer;
    class function Gruppe(AHolder:integer): integer;
    class function Event(AHolder:integer): integer;
    class function Kurse(AHolder, ATyp:integer): integer;

  protected procedure LoadDFMValues; override; end;

var
  frmSelection: TfrmSelection;

implementation
  Uses uMain, uDatenmodul;

{$R *.dfm}

class function TfrmSelection.Freunde(AHolder:integer): integer;
begin
  TfrmSelection.FTitel := 'Freundschaftsanfrage versenden';
  TfrmSelection.FFrage := '  Markierten Mitgliedern eine Freundschafts-Anfrage senden?  ';
  TfrmSelection.FInfo  := 'Wähle die Mitglieder aus, denen du eine Freundschaftsanfrage senden möchtest. <br>' +
                          'Klicke dann "Auswählen" um die Anfrage zu senden...';
  TfrmSelection.Exec(AHolder);

end;

class function TfrmSelection.Gruppe(AHolder:integer): integer;
begin
  TfrmSelection.FTitel := 'Einladungen zur Teilnahme an einer Gruppe versenden';
  TfrmSelection.FFrage := ' Markierte Mitglieder in deine Gruppe einladen?  ';
  TfrmSelection.FInfo  := 'Wähle die Mitglieder aus, die du zu deiner Gruppe einladen möchtest. <br>' +
                          'Klicke dann "Auswählen" um ihnen eine Einladung zur Teilnahme zu senden...';

  TfrmSelection.Exec(AHolder);
end;

class function TfrmSelection.Event(AHolder:integer): integer;
begin
  TfrmSelection.FTitel := 'Einladungen zur Teilnahme an einer Veranstaltung versenden';
  TfrmSelection.FFrage := ' Markierte Mitglieder zu deiner Veranstaltung einladen?  ';
  TfrmSelection.FInfo  := 'Wähle die Mitglieder aus, die du zu deiner Veranstaltung einladen möchtest. <br>' +
                          'Klicke dann "Auswählen" um ihnen eine Einladung zur Teilnahme zu senden...';
  TfrmSelection.Exec(AHolder);
end;

class function TfrmSelection.Kurse(AHolder, ATyp:integer): integer;
begin
  if ATyp = _KA_Tutorial
  then begin
    TfrmSelection.FTitel := 'Anleitungen auswählen';
    TfrmSelection.FFrage := ' Markierte Anleitungen übernehmen?  ';
    TfrmSelection.FInfo  := 'Wähle die Anleitungen aus, die du deiner Veranstaltung hinzufügen möchtest. <br>' +
                            'Klicke dann "Auswählen" um sie zu übernehmen...';

    TfrmSelection.Exec(AHolder, ATyp);
  end;
end;

class function TfrmSelection.Exec(AID:integer; ATyp:integer = 0): integer;
  [async] procedure AfterCreate(AForm: TObject); async;
  Var
    Response: TXDataClientResponse;
    s:string;
    j:TJ;
  begin
    TfrmSelection(AForm).FID := AID;
    TfrmSelection(AForm).FTyp:= ATyp;
    TfrmSelection(AForm).FFrage            := FFrage;
    TfrmSelection(AForm).divInfo.HTML.Text := FInfo;
    TfrmSelection(AForm).lblTitel.Caption  := FTitel;

    TfrmSelection(AForm).j := TJ.create;

    TfrmSelection(AForm).ElementHandle.style.removeProperty('position');
    TfrmSelection(AForm).Parent := Mainform;

    if TfrmSelection(AForm).FTyp = _KA_Tutorial
    then begin
      TfrmSelection(AForm).btnSave.OnClick := TfrmSelection(AForm).btnSaveKurse;
      TfrmSelection(AForm).divSuchen.visible := false;
      TfrmSelection(AForm).btnSearchClick(nil)
    end;
//    freeAndNil(j);
  end;
var
  frm:TfrmSelection;
  FConti:THtmlDiv;
begin
  FConti := THtmlDiv.Create(Mainform);
  FConti.Parent := Mainform;
  FConti.ElementClassName := 'editor_transparent';
  frm := TfrmSelection.CreateNew(FConti.ElementID, @AfterCreate);

  frm.FContainer := FConti;
//  frm.FCallback  := ACallback;
//  showmessage('1');
end;

procedure TfrmSelection.btnCancelClick(Sender: TObject);
begin
  close;
end;


procedure TfrmSelection.btnSaveKurse(Sender: TObject);
Var
  iTyp, i:integer;
  js:TJ;
begin
  if await(Frage(FFrage)) = mrYes
  then begin
    mainform.Wait := 1;
    js := TJ.create;

    for i := 0 to clAuswahl.items.Count -1 do
    begin
      j.Index := i;
      if clAuswahl.checked[i]
      then begin
        //prüfen ob schon zugefügt
        Js.Response := await(datenmodul.EasySQL( 'select ID from Medien2Holder ' +
                                                 ' Where HOLDER_ID = ' + FID.ToString + ' AND ' +
                                                 '       HOLDER_TYP= 7 AND ' +
                                                 '       MEDIEN_ID = ' + j.Value('ID') + ' AND ' +
                                                 '       MEDIEN_TYP= ' + inttostr(ord(medTutorial))));
        //Wenn nicht zugefügt, dann zufügen
        if JS.isEmpty
        then begin
          await(datenmodul.EasyInsert('Insert Medien2Holder (HOLDER_TYP, HOLDER_ID, MEDIEN_ID, MEDIEN_TYP) ' +
                                      ' VALUES(7,' + FID.toString + ',' + j.Value('ID') + ',' + inttostr(ord(medTutorial)) +')'));
        end;
      end;
    end;
    mainform.Wait := -1;

    freeAndNil(j);
    AWait(Meldung('Alle ausgewählten Anleitungen wurden übernommen...'));

    close;
  end;

end;

procedure TfrmSelection.btnSaveClick(Sender: TObject);
Var
  iTyp, i:integer;
  js:TJ;

  sName, sVon, sBis:string;
begin
  if await(Frage(FFrage)) = mrYes
  then begin
    mainform.Wait := 1;

    js := TJ.create(await(datenmodul.EasySQL('Select ID FROM TEXTE WHERE HOLDER = 0 AND TYP = ' + _ET_EinladungGruppe.ToString )));
    iTyp := js.integer('ID');

    js.Response := await(datenmodul.EasySQL('Select VON, BIS, NICKNAME FROM USER WHERE ID = ' + Mainform.Holder.ID.ToString));
    sName:= js.Value('NICKNAME');
    sVon := js.Date2Str('VON');
    sBis := js.Date2Str('BIS');

    //Bei Gruppen und Events prüfen ob sie nur einen oder mehrere Tage dauern
    if sVon < sBis
    then begin
      sVon := ' vom ' + sVon;
      sBis := ' bis zum ' + sBis;
    end
    else begin //nur ein Tag
      sBis := '';
      if sVon > ''
      then sVon := ' am ' + sVon
      else sVon := '';
    end;

    for i := 0 to clAuswahl.items.Count -1 do
    begin
      j.Index := i;
      if clAuswahl.checked[i]
      then begin
        //prüfen ob schon eingeladen
        js.Response := await(datenmodul.EasySQL('Select ID, STATUS FROM Friends ' +
                              ' Where (SENDER = ' + FID.ToString + ' AND EMPFAENGER = ' + j.Value('ID') + ') OR ' +
                              '       (EMPFAENGER= ' + FID.ToString + ' AND SENDER  = ' + j.Value('ID') + ')'));

        //Wenn nicht eingeladen, dann einladen
        if JS.integer('ID') = 0
        then begin
          await(datenmodul.EasyInsert('Insert into Friends (SENDER, EMPFAENGER) Values(' + FID.ToString +',' + j.Value('ID') + ')' ));

          //und EMAil schicken
          Anfragemail(iTyp, j.Value('ID'), j.Value('NAME'), j.Value('VORNAME'), j.Value('EMAIL'), sName, sVon, sBis);
        end;
      end;
    end;
    mainform.Wait := -1;

    freeAndNil(j);
    AWait(Meldung('Alle ausgewählten Mitglieder wurden benachrichtigt...'));
    close;
  end;

end;

procedure TfrmSelection.btnSearchClick(Sender: TObject);
Var
  i:integer;
  dt:TDateTime;
  sText:string;
begin

  if FTyp = 0
  then begin

    j.Response := await(datenmodul.EasySQL('select distinct v_user.* from v_User ' +
                                           ' where v_user.id <> '+ Mainform.Holder.ID.ToString +
                                           '   AND v_user.NickName like ''%' + edSuchen.Text + '%'''));
    j.index := 0;

    clAuswahl.Items.BeginUpdate;

    clAuswahl.items.clear;
    for i := 0 to j.Length -1 do
    begin
      j.index := i;
      sText := j.Value('NICKNAME') + ', ' + j.Value('ORT') + ', ' + j.Value('BLAND');
      clAuswahl.items.Add(sText );
      //clAuswahl.Checked[i] := (j.integer('FRIEND') = 1);

    end;

  end
  else begin
    showmessage('select ID, HEADER, TEXT from Kurse ' +
                                           ' where ART = 2 and Typ = '+ FTyp.ToString +
                                           '   AND Header like ''%' + edSuchen.Text + '%''' +
                                           ' ORDER BY HEADER');

    j.Response := await(datenmodul.EasySQL('select ID, HEADER, TEXT from Kurse ' +
                                           ' where ART = 2 and Typ = '+ FTyp.ToString +
                                           '   AND Header like ''%' + edSuchen.Text + '%''' +
                                           ' ORDER BY HEADER'));
    showmessage('search 2');
    j.index := 0;

    clAuswahl.Items.BeginUpdate;

    clAuswahl.items.clear;
    for i := 0 to j.Length -1 do
    begin
      j.index := i;
      sText := j.Value('HEADER');
      clAuswahl.items.Add(sText );
      //clAuswahl.Checked[i] := (j.integer('FRIEND') = 1);

    end;
    showmessage('search 3');

  end;
  clAuswahl.Items.EndUpdate;
end;

procedure TfrmSelection.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  FContainer.free;
end;

procedure TfrmSelection.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  WebHTMLDiv11 := THTMLDiv.Create(Self);
  btnClose := TImageControl.Create(Self);
  lblTitel := TLabel.Create(Self);
  divFotos := THTMLDiv.Create(Self);
  clAuswahl := TCheckListBox.Create(Self);
  divSuchen := THTMLDiv.Create(Self);
  edSuchen := TEdit.Create(Self);
  btnSearch := TButton.Create(Self);
  divInfo := THTMLDiv.Create(Self);
  WebHTMLDiv21 := THTMLDiv.Create(Self);
  btnSave := TButton.Create(Self);
  btnCancel := TButton.Create(Self);
  Client := TXDataWebClient.Create(Self);

  WebHTMLDiv1.BeforeLoadDFMValues;
  WebHTMLDiv11.BeforeLoadDFMValues;
  btnClose.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  divFotos.BeforeLoadDFMValues;
  clAuswahl.BeforeLoadDFMValues;
  divSuchen.BeforeLoadDFMValues;
  edSuchen.BeforeLoadDFMValues;
  btnSearch.BeforeLoadDFMValues;
  divInfo.BeforeLoadDFMValues;
  WebHTMLDiv21.BeforeLoadDFMValues;
  btnSave.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frmSelection';
    Width := 889;
    Height := 564;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 8;
    WebHTMLDiv1.Top := 8;
    WebHTMLDiv1.Width := 873;
    WebHTMLDiv1.Height := 545;
    WebHTMLDiv1.ElementClassName := 'gruppen_edit_wrapper';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv11.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv11.Name := 'WebHTMLDiv11';
    WebHTMLDiv11.Left := 19;
    WebHTMLDiv11.Top := 3;
    WebHTMLDiv11.Width := 838;
    WebHTMLDiv11.Height := 41;
    WebHTMLDiv11.ElementClassName := 'slider_header';
    WebHTMLDiv11.HeightStyle := ssAuto;
    WebHTMLDiv11.WidthStyle := ssAuto;
    WebHTMLDiv11.ElementPosition := epRelative;
    WebHTMLDiv11.ElementFont := efCSS;
    WebHTMLDiv11.Role := '';
    btnClose.SetParentComponent(WebHTMLDiv11);
    btnClose.Name := 'btnClose';
    btnClose.Left := 776;
    btnClose.Top := -3;
    btnClose.Width := 46;
    btnClose.Height := 44;
    btnClose.ElementClassName := 'slider_close';
    btnClose.HeightStyle := ssAuto;
    btnClose.WidthStyle := ssAuto;
    btnClose.HeightPercent := 100.000000000000000000;
    btnClose.WidthPercent := 100.000000000000000000;
    btnClose.Anchors := [];
    btnClose.ChildOrder := 1;
    btnClose.ElementFont := efCSS;
    btnClose.ElementPosition := epIgnore;
    btnClose.Picture.LoadFromFile('uSelection.WebHTMLDiv11.btnClose.Picture.png');
    lblTitel.SetParentComponent(WebHTMLDiv11);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 25;
    lblTitel.Top := 16;
    lblTitel.Width := 35;
    lblTitel.Height := 15;
    lblTitel.Caption := 'lblTitel';
    lblTitel.ElementLabelClassName := 'editor_titel';
    lblTitel.ElementFont := efCSS;
    lblTitel.HeightStyle := ssAuto;
    lblTitel.HeightPercent := 100.000000000000000000;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.WidthPercent := 100.000000000000000000;
    divFotos.SetParentComponent(WebHTMLDiv1);
    divFotos.Name := 'divFotos';
    divFotos.Left := 19;
    divFotos.Top := 203;
    divFotos.Width := 838;
    divFotos.Height := 214;
    divFotos.HeightStyle := ssAuto;
    divFotos.WidthStyle := ssAuto;
    divFotos.ChildOrder := 3;
    divFotos.ElementPosition := epRelative;
    divFotos.ElementFont := efCSS;
    divFotos.Role := '';
    clAuswahl.SetParentComponent(divFotos);
    clAuswahl.Name := 'clAuswahl';
    clAuswahl.Left := 3;
    clAuswahl.Top := 19;
    clAuswahl.Width := 526;
    clAuswahl.Height := 62;
    clAuswahl.Columns := 1;
    clAuswahl.ItemHeight := 15;
    clAuswahl.TabOrder := 0;
    clAuswahl.ElementClassName := 'auswahl_chklist';
    clAuswahl.ElementFont := efCSS;
    clAuswahl.ElementPosition := epIgnore;
    clAuswahl.HeightStyle := ssAuto;
    clAuswahl.HeightPercent := 100.000000000000000000;
    clAuswahl.WidthStyle := ssAuto;
    clAuswahl.WidthPercent := 100.000000000000000000;
    divSuchen.SetParentComponent(WebHTMLDiv1);
    divSuchen.Name := 'divSuchen';
    divSuchen.Left := 19;
    divSuchen.Top := 151;
    divSuchen.Width := 838;
    divSuchen.Height := 46;
    divSuchen.ElementClassName := 'auswahl_line';
    divSuchen.HeightStyle := ssAuto;
    divSuchen.WidthStyle := ssAuto;
    divSuchen.ChildOrder := 2;
    divSuchen.ElementPosition := epRelative;
    divSuchen.ElementFont := efCSS;
    divSuchen.HTML.BeginUpdate;
    try
      divSuchen.HTML.Clear;
      divSuchen.HTML.Add('');
    finally
      divSuchen.HTML.EndUpdate;
    end;
    divSuchen.Role := '';
    edSuchen.SetParentComponent(divSuchen);
    edSuchen.Name := 'edSuchen';
    edSuchen.Left := 16;
    edSuchen.Top := 16;
    edSuchen.Width := 121;
    edSuchen.Height := 22;
    edSuchen.ChildOrder := 1;
    edSuchen.ElementFont := efCSS;
    edSuchen.ElementPosition := epIgnore;
    edSuchen.HeightStyle := ssAuto;
    edSuchen.HeightPercent := 100.000000000000000000;
    edSuchen.TextHint := 'Mitgliedsnamen eingeben';
    edSuchen.WidthStyle := ssAuto;
    edSuchen.WidthPercent := 100.000000000000000000;
    btnSearch.SetParentComponent(divSuchen);
    btnSearch.Name := 'btnSearch';
    btnSearch.Left := 143;
    btnSearch.Top := 18;
    btnSearch.Width := 96;
    btnSearch.Height := 25;
    btnSearch.Caption := 'Suchen';
    btnSearch.ChildOrder := 1;
    btnSearch.ElementClassName := 'selection-search-btn';
    btnSearch.ElementFont := efCSS;
    btnSearch.ElementPosition := epIgnore;
    btnSearch.HeightStyle := ssAuto;
    btnSearch.HeightPercent := 100.000000000000000000;
    btnSearch.WidthStyle := ssAuto;
    btnSearch.WidthPercent := 100.000000000000000000;
    SetEvent(btnSearch, Self, 'OnClick', 'btnSearchClick');
    divInfo.SetParentComponent(WebHTMLDiv1);
    divInfo.Name := 'divInfo';
    divInfo.Left := 16;
    divInfo.Top := 50;
    divInfo.Width := 838;
    divInfo.Height := 95;
    divInfo.ElementClassName := 'auswahl_info';
    divInfo.HeightStyle := ssAuto;
    divInfo.WidthStyle := ssAuto;
    divInfo.ChildOrder := 1;
    divInfo.ElementPosition := epRelative;
    divInfo.ElementFont := efCSS;
    divInfo.HTML.BeginUpdate;
    try
      divInfo.HTML.Clear;
      divInfo.HTML.Add('W'#228'hle die Mitglieder aus, die du deiner Gruppe hinzuf'#252'gen m'#246'chtest. <br>');
      divInfo.HTML.Add('Klicke dann "Ausw'#228'hlen" um ihnen eine Einladung zur  Teilnahme zu senden...');
    finally
      divInfo.HTML.EndUpdate;
    end;
    divInfo.Role := '';
    WebHTMLDiv21.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv21.Name := 'WebHTMLDiv21';
    WebHTMLDiv21.Left := 22;
    WebHTMLDiv21.Top := 440;
    WebHTMLDiv21.Width := 795;
    WebHTMLDiv21.Height := 41;
    WebHTMLDiv21.ElementClassName := 'editor-footer-div';
    WebHTMLDiv21.HeightStyle := ssAuto;
    WebHTMLDiv21.WidthStyle := ssAuto;
    WebHTMLDiv21.ChildOrder := 4;
    WebHTMLDiv21.ElementPosition := epIgnore;
    WebHTMLDiv21.ElementFont := efCSS;
    WebHTMLDiv21.Role := '';
    btnSave.SetParentComponent(WebHTMLDiv21);
    btnSave.Name := 'btnSave';
    btnSave.Left := 9;
    btnSave.Top := 3;
    btnSave.Width := 144;
    btnSave.Height := 25;
    btnSave.Caption := 'Ausw'#228'hlen';
    btnSave.ChildOrder := 1;
    btnSave.ElementClassName := 'start_button-lets-go';
    btnSave.ElementFont := efCSS;
    btnSave.ElementPosition := epIgnore;
    btnSave.HeightStyle := ssAuto;
    btnSave.HeightPercent := 100.000000000000000000;
    btnSave.WidthStyle := ssAuto;
    btnSave.WidthPercent := 100.000000000000000000;
    SetEvent(btnSave, Self, 'OnClick', 'btnSaveClick');
    btnCancel.SetParentComponent(WebHTMLDiv21);
    btnCancel.Name := 'btnCancel';
    btnCancel.Left := 159;
    btnCancel.Top := 3;
    btnCancel.Width := 144;
    btnCancel.Height := 25;
    btnCancel.Caption := 'Abbrechen';
    btnCancel.ChildOrder := 2;
    btnCancel.ElementClassName := 'start_button-lets-go';
    btnCancel.ElementFont := efCSS;
    btnCancel.ElementPosition := epIgnore;
    btnCancel.HeightStyle := ssAuto;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthStyle := ssAuto;
    btnCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCancel, Self, 'OnClick', 'btnCancelClick');
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 24;
    Client.Top := 24;
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    WebHTMLDiv11.AfterLoadDFMValues;
    btnClose.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    divFotos.AfterLoadDFMValues;
    clAuswahl.AfterLoadDFMValues;
    divSuchen.AfterLoadDFMValues;
    edSuchen.AfterLoadDFMValues;
    btnSearch.AfterLoadDFMValues;
    divInfo.AfterLoadDFMValues;
    WebHTMLDiv21.AfterLoadDFMValues;
    btnSave.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.
