unit uKursEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, XData.Web.Client, WEBLib.WebCtrls, Vcl.Controls,
  Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Imaging.pngimage, WEBLib.ExtCtrls, uTB, uGlobal,
  WEBLib.Buttons, Vcl.Forms, ufrFotosListe, WEBLib.ComCtrls, ufrVideoListe;

type
  TfrmKursEdit = class(TForm)
    Client: TXDataWebClient;
    WebHTMLDiv1: THTMLDiv;
    WebHTMLDiv2: THTMLDiv;
    WebHTMLDiv11: THTMLDiv;
    btnClose: TImageControl;
    WebHTMLDiv4: THTMLDiv;
    cbItems1: TComboBox;
    WebHTMLDiv5: THTMLDiv;
    cbItems2: TComboBox;
    WebHTMLDiv6: THTMLDiv;
    cbItems3: TComboBox;
    lblTitel: TLabel;
    divHeader: THTMLDiv;
    EditButtons: TRichEditToolBar;
    WebHTMLDiv3: THTMLDiv;
    reTitel: TRichEdit;

    WebHTMLDiv8: THTMLDiv;
    frFotosliste1: TfrFotosliste;
    OpenDlg: TOpenDialog;
    WebHTMLDiv9: THTMLDiv;
    cbItems4: TComboBox;
    WebHTMLDiv10: THTMLDiv;
    cbItems5: TComboBox;
    WebHTMLDiv13: THTMLDiv;
    WebHTMLDiv17: THTMLDiv;
    frVideoListe1: TfrVideoListe;
    WebHTMLDiv7: THTMLDiv;
    WebHTMLDiv18: THTMLDiv;
    WebHTMLDiv19: THTMLDiv;
    WebHTMLDiv20: THTMLDiv;
    cbStatus: TComboBox;
    WebHTMLDiv21: THTMLDiv;
    btnSave: TButton;
    btnCancel: TButton;
    btnEdit2: TButton;
    btnNew2: TButton;
    btnDelete2: TButton;
    WebHTMLDiv12: THTMLDiv;
    btnEdit1: TButton;
    btnNew1: TButton;
    WebHTMLDiv14: THTMLDiv;
    btnEdit3: TButton;
    btnNew3: TButton;
    btnDelete3: TButton;
    WebHTMLDiv15: THTMLDiv;
    btnEdit4: TButton;
    btnNew4: TButton;
    btnDelete4: TButton;
    WebHTMLDiv16: THTMLDiv;
    btnEdit5: TButton;
    btnNew5: TButton;
    btnDelete5: TButton;
    btnDelete1: TButton;
    WebHTMLDiv22: THTMLDiv;
    img1: TImageControl;
    WebHTMLDiv23: THTMLDiv;
    btnFotoAdd: TButton;
    btnVideoAdd: TButton;
    btnFotosDel: TButton;
    btnVideosDel: TButton;
    btnToolTip: TSpeedButton;
    spHtml: TSpeedButton;
    WebHTMLDiv24: THTMLDiv;
    Memo: TMemo;
    reText: TRichEdit;
    WebHTMLDiv25: THTMLDiv;
    WebButton2: TButton;
    WebButton1: TButton;
    procedure btnCloseClick(Sender: TObject);
    procedure reTitelEnter(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure cbItems1Change(Sender: TObject);
    procedure btnEdit1Click(Sender: TObject);
    [async] procedure btnNew1Click(Sender: TObject); async;
    [async] procedure WebFormCreate(Sender: TObject); async;
    [async] procedure btnSaveClick(Sender: TObject); async;
    [async] procedure img1Click(Sender: TObject); async;

    [async] procedure btnDeleteClick(Sender: TObject); async;
    procedure btnFotoAddClick(Sender: TObject);
    procedure btnVideoAddClick(Sender: TObject);
    [async] procedure btnFotosDelClick(Sender: TObject); async;
    [async] procedure btnVideosDelClick(Sender: TObject); async;
    procedure btnToolTipClick(Sender: TObject);
    procedure spHtmlClick(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);

  private
    FNewTitel:boolean;
    FMedien_id:integer;
    FContainer:THtmlDiv;
    FOwner : THTMLDiv;
    FKursArt,
    FMainId,
    FID:integer;
    procedure SetId(Value:integer);
    procedure InsertFoto(s:string);
    procedure InsertTip(s:string);
    [async] procedure GetItem(AID:integer); async;
    [async] procedure FillCombobox(Acb:TComboBox; AID:integer); async;
    [async] procedure ImageButtonClick(sender:TObject); async;
  public
    property ID:integer read FID write SetID;
    property Container:THtmlDiv read FContainer write FContainer;
    class function Exec(AKursArt:integer):integer;//(AHolder, AMe, ABlock, AParent:integer; ATyp: TEditTyp; ACallback:TCallback): integer;
    [async] procedure Callback(s:string); async;
    [async] procedure CBFotos(S:string); async;
    [async] procedure CBVideos(S:string); async;
  protected procedure LoadDFMValues; override; end;

var
  frmKursEdit: TfrmKursEdit;

implementation
   uses uDatenmodul, uMain, uMedienAuswahl, uTipAuswahl;
{$R *.dfm}

procedure TfrmKursEdit.InsertFoto(s:string);
begin
//showmessage('XX');
  retext.SetFocus;
  retext.InsertHTML('{bild=' + s + '}');
end;

procedure TfrmKursEdit.ImageButtonClick(sender:TObject);
begin
  TfrmMedienAuswahl.Fotos( Mainform.Holder.Id,
                           Mainform.ME.ID,
                           mainform.ME.Album,
                           0, 0, 0, 0,
                           @InsertFoto, 2);

end;

class function TfrmKursEdit.Exec(AKursArt:integer): integer;

  [async] procedure AfterCreate(AForm: TObject); async;
  Var
    Response: TXDataClientResponse;
    s:string;
    j:TJ;
    i:integer;
  begin
    TfrmKursEdit(AForm).ElementHandle.style.removeProperty('position');
    TfrmKursEdit(AForm).Parent := Mainform;
//    showmessage('aftercreate-1');

    TfrmKursEdit(AForm).cbStatus.Items.Clear;

    j := TJ.create(await(datenmodul.EasySQL('SELECT * from Rechte')));

    for i := 0 to j.Length -1 do
    begin
      j.index := i;
      TfrmKursEdit(AForm).cbStatus.items.Add(j.Value('TEXT') + '=' + j.Value('ID'));
    end;

    j.response := await(datenmodul.EasySQL('select ID, ART from Kurse where VOR = 0 AND ART = ' + AKursArt.tostring + ' limit 1'));
    TfrmKursEdit(AForm).FKursArt := AKursArt;

    TfrmKursEdit(AForm).FMainID := j.Integer('ID');
    TfrmKursEdit(AForm).FillCombobox(TfrmKursEdit(AForm).cbItems1, TfrmKursEdit(AForm).FMainID);

    if j.Integer('ART') = _KA_Lektion
    then TfrmKursEdit(AForm).lblTitel.Caption := 'LEKTION BEARBEITEN'
    else TfrmKursEdit(AForm).lblTitel.Caption := 'ANLEITUNG BEARBEITEN';

    TfrmKursEdit(AForm).frFotosliste1.img.Visible := false;
    TfrmKursEdit(AForm).frVideoliste1.img.Visible := false;

    TfrmKursEdit(AForm).editbuttons.ImageButton.OnClick := TfrmKursEdit(AForm).ImageButtonClick;
    freeAndNil(j);
  end;

var
  frm:TfrmKursEdit;
  FConti:THtmlDiv;
begin
  FConti := THtmlDiv.Create(Mainform);
  FConti.Parent := Mainform;
  FConti.ElementClassName := 'editor_transparent';
  frm := TfrmKursEdit.CreateNew(FConti.ElementID, @AfterCreate);

  frm.FContainer := FConti;
//  frm.FCallback  := ACallback;

end;

procedure TfrmKursEdit.GetItem(AID: integer);
var
  Response: TXDataClientResponse;
  J:TJ;
  i:integer;
begin
  FID := AID;

  if AID > 0
  then begin
    Response := Await( Client.RawInvokeAsync('IDBService.KurseGetByID',[AID, Mainform.ME.ID]));

    J := TJ.create(Response);
    reTitel.lines.Text := J.Value('HEADER');
    reText.lines.Text  := J.Value('TEXT');
  end
  else begin
    reTitel.lines.Text := '';
    reText.lines.Text  := '';
  end;

  //Titelbild holen...
  FnewTitel := false;
  if j.Integer('MEDIEN_ID') > 0
  then img1.URL := await(datenmodul.GetMedia(j.Integer('MEDIEN_ID'), _SIZE2))
  else img1.URL := '';

  //Status
  cbStatus.ItemIndex := -1;
  for i := 0 to cbStatus.items.count - 1 do
  begin
    if cbStatus.Values[i] = j.Value('STATUS')
    then begin
      cbStatus.ItemIndex := i;
      break;
    end;
  end;
  frFotosliste1.Init(Mainform.Holder.ID, mainform.ME.ID, 0, 0,0, AId, 0,-1, nil, false,  0, true);
  frVideoliste1.Init(Mainform.Holder.ID, mainform.ME.ID, 0, 0,0, AId, 0,-1, nil, false,  0, true);

end;

procedure TfrmKursEdit.Callback(s:string);
//Var
//  j:TJ;
begin
  FMedien_ID := s.ToInteger;
  img1.URL := await(datenmodul.Getmedia(FMedien_ID, _SIZE2));
end;

procedure TfrmKursEdit.img1Click(Sender: TObject);
begin
  TfrmMedienAuswahl.Fotos(Mainform.Holder.id, mainform.ME.id, Mainform.Holder.Album, 0, 0, FID, FMedien_ID, @Callback, 2);
//  TfrmMedienAuswahl.Fotos(Mainform.Holder.id, mainform.ME.id, Mainform.Holder.Album, 0, 0, FID, FMedien_ID, @Callback, _AllowAddImage);
end;

procedure TfrmKursEdit.SetId(Value: integer);
begin
  FID := value;
end;

procedure TfrmKursEdit.spHtmlClick(Sender: TObject);
begin
  Memo.Text := retext.GetContent;
  Memo.Visible   := not Memo.Visible;
  reText.Visible := not Memo.Visible;

end;

procedure TfrmKursEdit.FillCombobox(Acb:TComboBox; AID:integer);
Var
  Response: TXDataClientResponse;
  J:TJ;
  i:integer;
  s:string;
  o:TComponent;
begin
  Acb.items.clear;
//  showmessage('Fillcombibox-1');

  Response := Await( Client.RawInvokeAsync('IDBService.KurseGet',[AId, Mainform.ME.ID, 10, FKursArt, Mainform.ME.Trainer]));

  J := TJ.create(Response);
  for i := 0 to j.Length -1 do
  begin
    j.index := i;
    Acb.items.Add(j.Value('HEADER') + '=' + j.Value('ID'));
  end;

  Acb.Enabled := (Acb.items.count > 0);

end;

procedure TfrmKursEdit.btnCloseClick(Sender: TObject);
begin
  close;
end;

procedure TfrmKursEdit.btnDeleteClick(Sender: TObject);
Var
  j:TJ;
begin
  if sender = btnDelete1
  then GetItem(cbItems1.values[cbItems1.ItemIndex].toInteger);
  if sender = btnDelete2
  then GetItem(cbItems2.values[cbItems2.ItemIndex].toInteger);
  if sender = btnDelete3
  then GetItem(cbItems3.values[cbItems3.ItemIndex].toInteger);
  if sender = btnDelete4
  then GetItem(cbItems4.values[cbItems4.ItemIndex].toInteger);
  if sender = btnDelete5
  then GetItem(cbItems5.values[cbItems5.ItemIndex].toInteger);

  J := TJ.create(await(datenmodul.EasySQL('Select HEADER from Kurse where ID = ' + FID.ToString)));

  if await(Frage('Aktuellen Eintrag "' + J.Value('HEADER') +  '" löschen?')) = mrYes
  then begin

    J.response := await(datenmodul.EasySQL('Select count(ID) as ANZ from Kurse where VOR = ' + FID.ToString));
    if J.Integer('ANZ') = 0
    then begin

      await(datenmodul.EasyExec('Delete from kursedone where KURSE_ID = ' + FID.ToString));
      await(datenmodul.EasyExec('Delete from medien2Holder where HOLDER_TYP = ' + _HT_Kurs + ' AND ID = ' + FID.ToString));
      await(datenmodul.EasyExec('Delete from Kurse where ID = ' + FID.ToString));
  //    await(datenmodul.EasyExec('UPDATE Kurse Set Header = concat(HEADER,''XX'') where ID = ' + FID.ToString));

      FillCombobox(cbItems1, FMainID);
      cbItems1Change(cbItems1);

      await(Meldung('Eintrag gelöscht...'));

    end
    else await(Meldung('Löschen nicht möglich, da noch Unterkapitel vorhanden sind...'));
  end;

  j.Free;
end;

procedure TfrmKursEdit.btnEdit1Click(Sender: TObject);
begin
  cbStatus.Enabled:=false;
  if sender = btnEdit1
  then begin
    cbStatus.Enabled:=true;
    GetItem(cbItems1.values[cbItems1.ItemIndex].toInteger);
  end;
  if sender = btnEdit2
  then GetItem(cbItems2.values[cbItems2.ItemIndex].toInteger);
  if sender = btnEdit3
  then GetItem(cbItems3.values[cbItems3.ItemIndex].toInteger);
  if sender = btnEdit4
  then GetItem(cbItems4.values[cbItems4.ItemIndex].toInteger);
  if sender = btnEdit5
  then GetItem(cbItems5.values[cbItems5.ItemIndex].toInteger);
end;

procedure TfrmKursEdit.btnNew1Click(Sender: TObject);
Var
  J : TJ;
begin

  if sender = btnNew1
  then begin
    J := TJ.create( Await( Client.RawInvokeAsync('IDBService.KurseNew',[FMainID, 0, 'Überschrift', 'Text'])));
    cbItems1.Items.add('Überschrift=' + J.Value('ID'));
    cbItems1.ItemIndex := cbItems1.items.count-1;
    btnEdit1Click(btnEdit1);
  end;
  if sender = btnNew2
  then begin
    J := TJ.create( Await( Client.RawInvokeAsync('IDBService.KurseNew',[cbItems1.values[cbItems1.ItemIndex].toInteger, 0, 'Überschrift', 'Text'])));
    cbItems2.Items.add('Überschrift=' + J.Value('ID'));
    cbItems2.ItemIndex := cbItems2.items.count-1;
    btnEdit1Click(btnEdit2);
  end;
  if sender = btnNew3
  then begin
    J := TJ.create( Await( Client.RawInvokeAsync('IDBService.KurseNew',[cbItems2.values[cbItems2.ItemIndex].toInteger, 0, 'Überschrift', 'Text'])));
    cbItems3.Items.add('Überschrift=' + J.Value('ID'));
    cbItems3.ItemIndex := cbItems3.items.count-1;
    btnEdit1Click(btnEdit3);
  end;

  if sender = btnNew4
  then begin
    J := TJ.create( Await( Client.RawInvokeAsync('IDBService.KurseNew',[cbItems3.values[cbItems3.ItemIndex].toInteger, 0, 'Überschrift', 'Text'])));
    cbItems4.Items.add('Überschrift=' + J.Value('ID'));
    cbItems4.ItemIndex := cbItems4.items.count-1;
    btnEdit1Click(btnEdit4);
  end;

  if sender = btnNew5
  then begin
    J := TJ.create( Await( Client.RawInvokeAsync('IDBService.KurseNew',[cbItems4.values[cbItems4.ItemIndex].toInteger, 0, 'Überschrift', 'Text'])));
    cbItems5.Items.add('Überschrift=' + J.Value('ID'));
    cbItems5.ItemIndex := cbItems5.items.count-1;
    btnEdit1Click(btnEdit5);
  end;

  cbStatus.ItemIndex := 0;

  FID := J.Integer('ID');
end;

procedure TfrmKursEdit.btnSaveClick(Sender: TObject);
  function CBoxReplace(Acb:TCombobox; ATitel, AValue:string):integer;
  var
    i:integer;
  begin
    result := -1;
    for i := 0 to Acb.Items.count -1 do
    begin
      if Acb.values[i] = AValue
      then begin
        Acb.Items[i] := ATitel + '=' + AValue;
        result := i;
        exit;
      end;
    end;
  end;

Var
  FSize1, FSize2, FSize3:string;
  sStatus,
  sSQl, sPreis,
  sTitel, sText, sFarbe:string;
  i:integer;
  Response: TXDataClientResponse;
  J : TJ;

  JSObj: TJSObject;
  JSString:string;
begin

  if (cbStatus.enabled ) and (cbStatus.Values[cbStatus.ItemIndex] = '')
  then begin
    await(meldung('Feld "Für wen ist dieser Kurs kostenlos" muß ausgefüllt werden...'));
    exit;
  end;

  sTitel := reTitel.GetContent;
  sText  := reText.GetContent;

  if CBoxReplace(cbItems1, sTitel, FID.toString) = -1
  then if CBoxReplace(cbItems2, sTitel, FID.toString) = -1
       then if CBoxReplace(cbItems3, sTitel, FID.toString) = -1
            then  if CBoxReplace(cbItems4, sTitel, FID.toString) = -1
                  then CBoxReplace(cbItems5, sTitel, FID.toString);

  if cbStatus.Enabled
  then sStatus := cbStatus.Values[cbStatus.ItemIndex]
  else sStatus := '0';

  //Neues Titelfoto speichern

//  sSQL:= 'Delete from medien2holder where holder_TYP = ' + _HT_Kurs + ' AND holder_ID = ' + FID.ToString + ' AND Medien_typ = ' + inttostr(ord(medFoto));
//  J := TJ.create(await(datenmodul.EasyExec(sSQL)));
//
//  sSQL := 'INSERT into medien2holder (holder_TYP, HOLDER_ID, MEDIEN_TYP, MEDIEN_ID) ' +
//          '     Values(' + _HT_Kurs + ',' + FID.ToString + ',' + inttostr(ord(medFoto)) + ',' + FMedien_ID.tostring + ')';

//  await(datenmodul.EasyInsert(sSQL));
//  sText := chkStr(sText);

//  showmessage(sTitel);
//  showmessage(sText);
//  sText := ChkStr(sText);
//  sTitel:= ChkStr(sTitel);

  if (pos('ERR001', sTitel) = 0) and (pos('ERR001', sText) = 0)
  then begin

    sSql := 'Update Kurse Set MEDIEN_ID = ' + FMedien_ID.tostring + ', ART=' + FKursArt.ToString + ', Header = ''' + sTitel + ''', Status = ' + sStatus + ', Text = ''' + sText + ''' where ID = ' + FID.ToString;

    await(datenmodul.EasyExec(sSql));
//    showmessage('ssql');

    response := Await(Client.RawInvokeAsync('IDBService.KurseSetCount', [FID]));
    await(meldung('gespeichert...'));
  end
  else await(meldung('Ungültige Zeichen im Text oder Titel...'));

end;

procedure TfrmKursEdit.InsertTip(s:string);
begin
  reText.SetFocus;
  retext.InsertHTML('{Tip=' + s + '}');

end;

procedure TfrmKursEdit.btnToolTipClick(Sender: TObject);
begin
  TfrmTipAuswahl.exec(@InsertTip);
end;

procedure TfrmKursEdit.cbItems1Change(Sender: TObject);
Var
  i:integer;
begin

  i := TCombobox(sender).values[TCombobox(sender).ItemIndex].toInteger;

  if Sender = cbItems1
  then begin
    cbItems2.ItemIndex := -1;
    cbItems3.ItemIndex := -1;
    cbItems4.ItemIndex := -1;
    cbItems5.ItemIndex := -1;

    FillComboBox(cbItems2, i);

    cbItems3.items.clear;
    cbItems4.items.clear;
    cbItems5.items.clear;
    cbItems3.Enabled := false;
    cbItems4.Enabled := false;
    cbItems5.Enabled := false;
  end;

  if Sender = cbItems2
  then begin
    cbItems3.ItemIndex := -1;
    cbItems4.ItemIndex := -1;
    cbItems5.ItemIndex := -1;

    FillComboBox(cbItems3, i);

    cbItems4.items.clear;
    cbItems5.items.clear;
    cbItems4.Enabled := false;
    cbItems5.Enabled := false;
  end;

  if Sender = cbItems3
  then begin
    cbItems4.ItemIndex := -1;
    cbItems5.ItemIndex := -1;

    FillComboBox(cbItems4, i);
    cbItems5.items.clear;
    cbItems5.Enabled := false;
  end;

  if Sender = cbItems4
  then begin
    cbItems5.ItemIndex := -1;
    FillComboBox(cbItems5, i);
  end;

  btnEdit1.enabled  := (cbItems1.ItemIndex >= 0);
  btnDelete1.enabled:= (cbItems1.ItemIndex >= 0);

  btnNew2.enabled   := (cbItems1.ItemIndex >= 0);
  btnEdit2.enabled  := (cbItems2.ItemIndex >= 0);
  btnDelete2.enabled:= (cbItems2.ItemIndex >= 0);

  btnNew3.enabled   := (cbItems2.ItemIndex >= 0);
  btnEdit3.Enabled  := (cbItems3.ItemIndex >= 0);
  btnDelete3.enabled:= (cbItems3.ItemIndex >= 0);

  btnNew4.enabled   := (cbItems3.ItemIndex >= 0);
  btnEdit4.Enabled  := (cbItems4.ItemIndex >= 0);
  btnDelete4.enabled:= (cbItems4.ItemIndex >= 0);

  btnNew5.enabled   := (cbItems4.ItemIndex >= 0);
  btnEdit5.Enabled  := (cbItems5.ItemIndex >= 0);
  btnDelete5.enabled:= (cbItems5.ItemIndex >= 0);
end;

procedure TfrmKursEdit.CBFotos(S:string);
begin
  await(datenmodul.EasyExec('Insert into medien2holder (Holder_typ, Holder_ID, Medien_typ, Medien_ID) ' +
                            'VALUES(' + _HT_Kurs + ',' + FID.ToString + ',' + inttostr(ord(medFoto)) + ',' +  s + ')'));

  await(frFotosliste1.refresh);   //Init(Mainform.Holder.ID, mainform.ME.ID, 0, 0,0, FID, 0, -1, nil, false,  not _AllowAddImage));
end;

procedure TfrmKursEdit.CBVideos(S:string);
begin
  await(datenmodul.EasyExec('Insert into medien2holder (Holder_typ, Holder_ID, Medien_typ, Medien_ID) ' +
                            'VALUES(' + _HT_Kurs + ',' + FID.ToString + ',' + inttostr(ord(medVideo)) + ',' +  s + ')'));
  await(frVideoliste1.refresh);// frVideoliste1.Init(Mainform.Holder.ID, mainform.ME.ID, 0, 0,0, FID, 0, -1, nil, false,  not _AllowAddImage);
end;

procedure TfrmKursEdit.btnFotoAddClick(Sender: TObject);
begin
  if FID > 0
  then TfrmMedienAuswahl.Fotos(Mainform.Holder.id, mainform.ME.id, Mainform.Holder.Album, 0, 0, FID, FMedien_ID, @CBFotos, 2);
end;

procedure TfrmKursEdit.btnVideoAddClick(Sender: TObject);
begin
  if FID > 0
  then TfrmMedienAuswahl.Videos(Mainform.Holder.id, mainform.ME.id, Mainform.Holder.Album, 0, 0, FID, FMedien_ID, @CBVideos, 2);
end;

procedure TfrmKursEdit.btnVideosDelClick(Sender: TObject);
Var
  a : array of string;
  i:integer;
  s:string;
begin
  a := frVideoListe1.GetMarked;

  for i := 0 to length(a)-1 do
     s := s + ',' + a[i];

  if s > ''
  then begin
    if await(Frage('Bist du sicher, dass du alle markierten Videos aus dem Kurs entfernt möchtest? <br><br>' +
                   'Beachte, dass die Videos weiterhin in deinem Album vorhanden sind!')) = mrYes
    then begin

      //führendes Komma entfernen
      s := copy(s,2,length(s));

      await(datenmodul.EasyExec('Delete from medien2holder ' +
                                ' where medien_typ = ' + inttostr(ord(medVideo)) +
                                '   and Holder_TYP = ' + _HT_Kurs +
                                '   and Holder_ID  = ' + FID.ToString +
                                '   and medien_id in (' + s + ')'));

      await(frVideoListe1.Refresh);
    end;
  end;
end;

procedure TfrmKursEdit.btnFotosDelClick(Sender: TObject);
Var
  a : array of string;
  i:integer;
  s:string;
begin
  a := frFotosListe1.GetMarked;

  for i := 0 to length(a)-1 do
     s := s + ',' + a[i];

  if s > ''
  then begin
    if await(Frage('Bist du sicher, dass du alle markierten Fotos aus dem Kurs entfernt möchtest? <br><br>' +
                   'Beachte, dass die Fotos weiterhin in deinem Album vorhanden sind!')) = mrYes
    then begin

      //führendes Komma entfernen
      s := copy(s,2,length(s));

      await(datenmodul.EasyExec('Delete from medien2holder ' +
                                ' where medien_typ = ' + inttostr(ord(medFoto)) +
                                '   and Holder_TYP = ' + _HT_Kurs +
                                '   and Holder_ID  = ' + FID.ToString +
                                '   and medien_id in (' + s + ')'));

      await(frFotosListe1.Refresh);
    end;
  end;

end;

procedure TfrmKursEdit.WebButton1Click(Sender: TObject);
begin
  FMedien_ID := 0;
  img1.URL := '';
end;

procedure TfrmKursEdit.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  FreeAndNil(FContainer);
end;

procedure TfrmKursEdit.WebFormCreate(Sender: TObject);
Var
  el : TJSHTMLElement;
  Response: TXDataClientResponse;
  J:TJ;
  i:integer;
  s:string;
begin

  TControl(self).elementfont := efCSS;

  TControl(reTitel).elementPosition := epIgnore;
  el := TJSHTMLElement(THTMLDiv(reTitel).ElementHandle);
  el.style.removeProperty('position');
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-size');
  el.style.removeProperty('font-style');

  TControl(reText).elementPosition := epIgnore;
  el := TJSHTMLElement(THTMLDiv(reText).ElementHandle);
  el.style.removeProperty('position');
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-size');
  el.style.removeProperty('font-style');

  TControl(Memo).elementPosition := epIgnore;
  el := TJSHTMLElement(THTMLDiv(Memo).ElementHandle);
  el.style.removeProperty('position');
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-size');
  el.style.removeProperty('font-style');
end;

procedure TfrmKursEdit.reTitelEnter(Sender: TObject);
begin
  EditButtons.RichEdit := TRichEdit(Sender);
end;

procedure TfrmKursEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  WebHTMLDiv11 := THTMLDiv.Create(Self);
  btnClose := TImageControl.Create(Self);
  lblTitel := TLabel.Create(Self);
  WebHTMLDiv4 := THTMLDiv.Create(Self);
  cbItems1 := TComboBox.Create(Self);
  WebHTMLDiv12 := THTMLDiv.Create(Self);
  btnEdit1 := TButton.Create(Self);
  btnNew1 := TButton.Create(Self);
  btnDelete1 := TButton.Create(Self);
  WebHTMLDiv5 := THTMLDiv.Create(Self);
  cbItems2 := TComboBox.Create(Self);
  WebHTMLDiv13 := THTMLDiv.Create(Self);
  btnEdit2 := TButton.Create(Self);
  btnNew2 := TButton.Create(Self);
  btnDelete2 := TButton.Create(Self);
  WebHTMLDiv6 := THTMLDiv.Create(Self);
  cbItems3 := TComboBox.Create(Self);
  WebHTMLDiv14 := THTMLDiv.Create(Self);
  btnEdit3 := TButton.Create(Self);
  btnNew3 := TButton.Create(Self);
  btnDelete3 := TButton.Create(Self);
  WebHTMLDiv9 := THTMLDiv.Create(Self);
  cbItems4 := TComboBox.Create(Self);
  WebHTMLDiv15 := THTMLDiv.Create(Self);
  btnEdit4 := TButton.Create(Self);
  btnNew4 := TButton.Create(Self);
  btnDelete4 := TButton.Create(Self);
  WebHTMLDiv10 := THTMLDiv.Create(Self);
  cbItems5 := TComboBox.Create(Self);
  WebHTMLDiv16 := THTMLDiv.Create(Self);
  btnEdit5 := TButton.Create(Self);
  btnNew5 := TButton.Create(Self);
  btnDelete5 := TButton.Create(Self);
  divHeader := THTMLDiv.Create(Self);
  EditButtons := TRichEditToolBar.Create('');
  btnToolTip := TSpeedButton.Create(Self);
  spHtml := TSpeedButton.Create(Self);
  WebHTMLDiv3 := THTMLDiv.Create(Self);
  reTitel := TRichEdit.Create(Self);
  WebHTMLDiv8 := THTMLDiv.Create(Self);
  frFotosliste1 := TfrFotosliste.Create(Self);
  WebHTMLDiv17 := THTMLDiv.Create('E1');
  frVideoListe1 := TfrVideoListe.Create(Self);
  WebHTMLDiv22 := THTMLDiv.Create(Self);
  img1 := TImageControl.Create(Self);
  WebHTMLDiv25 := THTMLDiv.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebHTMLDiv23 := THTMLDiv.Create(Self);
  btnFotoAdd := TButton.Create(Self);
  btnVideoAdd := TButton.Create(Self);
  btnFotosDel := TButton.Create(Self);
  btnVideosDel := TButton.Create(Self);
  WebHTMLDiv24 := THTMLDiv.Create(Self);
  Memo := TMemo.Create(Self);
  reText := TRichEdit.Create(Self);
  WebHTMLDiv7 := THTMLDiv.Create(Self);
  WebHTMLDiv18 := THTMLDiv.Create(Self);
  WebHTMLDiv19 := THTMLDiv.Create(Self);
  WebHTMLDiv20 := THTMLDiv.Create(Self);
  cbStatus := TComboBox.Create(Self);
  WebHTMLDiv21 := THTMLDiv.Create(Self);
  btnSave := TButton.Create(Self);
  btnCancel := TButton.Create(Self);
  Client := TXDataWebClient.Create(Self);
  OpenDlg := TOpenDialog.Create(Self);

  frFotosliste1.LoadFromForm;
  frVideoListe1.LoadFromForm;
  WebHTMLDiv1.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  WebHTMLDiv11.BeforeLoadDFMValues;
  btnClose.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  WebHTMLDiv4.BeforeLoadDFMValues;
  cbItems1.BeforeLoadDFMValues;
  WebHTMLDiv12.BeforeLoadDFMValues;
  btnEdit1.BeforeLoadDFMValues;
  btnNew1.BeforeLoadDFMValues;
  btnDelete1.BeforeLoadDFMValues;
  WebHTMLDiv5.BeforeLoadDFMValues;
  cbItems2.BeforeLoadDFMValues;
  WebHTMLDiv13.BeforeLoadDFMValues;
  btnEdit2.BeforeLoadDFMValues;
  btnNew2.BeforeLoadDFMValues;
  btnDelete2.BeforeLoadDFMValues;
  WebHTMLDiv6.BeforeLoadDFMValues;
  cbItems3.BeforeLoadDFMValues;
  WebHTMLDiv14.BeforeLoadDFMValues;
  btnEdit3.BeforeLoadDFMValues;
  btnNew3.BeforeLoadDFMValues;
  btnDelete3.BeforeLoadDFMValues;
  WebHTMLDiv9.BeforeLoadDFMValues;
  cbItems4.BeforeLoadDFMValues;
  WebHTMLDiv15.BeforeLoadDFMValues;
  btnEdit4.BeforeLoadDFMValues;
  btnNew4.BeforeLoadDFMValues;
  btnDelete4.BeforeLoadDFMValues;
  WebHTMLDiv10.BeforeLoadDFMValues;
  cbItems5.BeforeLoadDFMValues;
  WebHTMLDiv16.BeforeLoadDFMValues;
  btnEdit5.BeforeLoadDFMValues;
  btnNew5.BeforeLoadDFMValues;
  btnDelete5.BeforeLoadDFMValues;
  divHeader.BeforeLoadDFMValues;
  EditButtons.BeforeLoadDFMValues;
  btnToolTip.BeforeLoadDFMValues;
  spHtml.BeforeLoadDFMValues;
  WebHTMLDiv3.BeforeLoadDFMValues;
  reTitel.BeforeLoadDFMValues;
  WebHTMLDiv8.BeforeLoadDFMValues;
  frFotosliste1.BeforeLoadDFMValues;
  frFotosliste1.WebHTMLDiv1.BeforeLoadDFMValues;
  frFotosliste1.divWrapper.BeforeLoadDFMValues;
  frFotosliste1.img.BeforeLoadDFMValues;
  WebHTMLDiv17.BeforeLoadDFMValues;
  frVideoListe1.BeforeLoadDFMValues;
  frVideoListe1.WebHTMLDiv1.BeforeLoadDFMValues;
  frVideoListe1.divWrapper.BeforeLoadDFMValues;
  frVideoListe1.img.BeforeLoadDFMValues;
  frVideoListe1.Client.BeforeLoadDFMValues;
  WebHTMLDiv22.BeforeLoadDFMValues;
  img1.BeforeLoadDFMValues;
  WebHTMLDiv25.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebHTMLDiv23.BeforeLoadDFMValues;
  btnFotoAdd.BeforeLoadDFMValues;
  btnVideoAdd.BeforeLoadDFMValues;
  btnFotosDel.BeforeLoadDFMValues;
  btnVideosDel.BeforeLoadDFMValues;
  WebHTMLDiv24.BeforeLoadDFMValues;
  Memo.BeforeLoadDFMValues;
  reText.BeforeLoadDFMValues;
  WebHTMLDiv7.BeforeLoadDFMValues;
  WebHTMLDiv18.BeforeLoadDFMValues;
  WebHTMLDiv19.BeforeLoadDFMValues;
  WebHTMLDiv20.BeforeLoadDFMValues;
  cbStatus.BeforeLoadDFMValues;
  WebHTMLDiv21.BeforeLoadDFMValues;
  btnSave.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  OpenDlg.BeforeLoadDFMValues;
  try
    Name := 'frmKursEdit';
    Width := 930;
    Height := 1042;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    Visible := True;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 9;
    WebHTMLDiv1.Top := 8;
    WebHTMLDiv1.Width := 913;
    WebHTMLDiv1.Height := 1025;
    WebHTMLDiv1.ElementClassName := 'kursedit_container';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv2.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 28;
    WebHTMLDiv2.Top := 7;
    WebHTMLDiv2.Width := 857;
    WebHTMLDiv2.Height := 286;
    WebHTMLDiv2.ElementClassName := 'editor_header_wrapper';
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ChildOrder := 1;
    WebHTMLDiv2.ElementPosition := epRelative;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.Role := '';
    WebHTMLDiv11.SetParentComponent(WebHTMLDiv2);
    WebHTMLDiv11.Name := 'WebHTMLDiv11';
    WebHTMLDiv11.Left := 14;
    WebHTMLDiv11.Top := 6;
    WebHTMLDiv11.Width := 827;
    WebHTMLDiv11.Height := 41;
    WebHTMLDiv11.ElementClassName := 'kursedit_header';
    WebHTMLDiv11.HeightStyle := ssAuto;
    WebHTMLDiv11.WidthStyle := ssAuto;
    WebHTMLDiv11.ChildOrder := 1;
    WebHTMLDiv11.ElementPosition := epRelative;
    WebHTMLDiv11.ElementFont := efCSS;
    WebHTMLDiv11.Role := '';
    btnClose.SetParentComponent(WebHTMLDiv11);
    btnClose.Name := 'btnClose';
    btnClose.Left := 765;
    btnClose.Top := -3;
    btnClose.Width := 46;
    btnClose.Height := 44;
    btnClose.ElementClassName := 'kursedit_close';
    btnClose.HeightStyle := ssAuto;
    btnClose.WidthStyle := ssAuto;
    btnClose.HeightPercent := 100.000000000000000000;
    btnClose.WidthPercent := 100.000000000000000000;
    btnClose.Anchors := [];
    btnClose.ChildOrder := 1;
    btnClose.ElementFont := efCSS;
    btnClose.ElementPosition := epIgnore;
    SetEvent(btnClose, Self, 'OnClick', 'btnCloseClick');
    btnClose.Picture.LoadFromFile('uKursEdit.WebHTMLDiv11.btnClose.Picture.png');
    lblTitel.SetParentComponent(WebHTMLDiv11);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 16;
    lblTitel.Top := 9;
    lblTitel.Width := 35;
    lblTitel.Height := 15;
    lblTitel.Caption := 'lblTitel';
    lblTitel.ElementLabelClassName := 'editor_titel';
    lblTitel.ElementFont := efCSS;
    lblTitel.HeightStyle := ssAuto;
    lblTitel.HeightPercent := 100.000000000000000000;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.WidthPercent := 100.000000000000000000;
    WebHTMLDiv4.SetParentComponent(WebHTMLDiv2);
    WebHTMLDiv4.Name := 'WebHTMLDiv4';
    WebHTMLDiv4.Left := 14;
    WebHTMLDiv4.Top := 53;
    WebHTMLDiv4.Width := 827;
    WebHTMLDiv4.Height := 41;
    WebHTMLDiv4.ElementClassName := 'kursedit_line';
    WebHTMLDiv4.HeightStyle := ssAuto;
    WebHTMLDiv4.WidthStyle := ssAuto;
    WebHTMLDiv4.ChildOrder := 1;
    WebHTMLDiv4.ElementPosition := epRelative;
    WebHTMLDiv4.ElementFont := efCSS;
    WebHTMLDiv4.Role := '';
    cbItems1.SetParentComponent(WebHTMLDiv4);
    cbItems1.Name := 'cbItems1';
    cbItems1.Left := 12;
    cbItems1.Top := 3;
    cbItems1.Width := 273;
    cbItems1.Height := 23;
    cbItems1.ElementClassName := 'editor_combobox';
    cbItems1.ElementFont := efCSS;
    cbItems1.ElementPosition := epIgnore;
    cbItems1.HeightStyle := ssAuto;
    cbItems1.HeightPercent := 100.000000000000000000;
    cbItems1.Text := 'cbItems1';
    cbItems1.WidthStyle := ssAuto;
    cbItems1.WidthPercent := 100.000000000000000000;
    SetEvent(cbItems1, Self, 'OnChange', 'cbItems1Change');
    cbItems1.ItemIndex := -1;
    WebHTMLDiv12.SetParentComponent(WebHTMLDiv4);
    WebHTMLDiv12.Name := 'WebHTMLDiv12';
    WebHTMLDiv12.Left := 453;
    WebHTMLDiv12.Top := 0;
    WebHTMLDiv12.Width := 337;
    WebHTMLDiv12.Height := 41;
    WebHTMLDiv12.ElementClassName := 'kursedit_btn_container';
    WebHTMLDiv12.HeightStyle := ssAuto;
    WebHTMLDiv12.WidthStyle := ssAuto;
    WebHTMLDiv12.ChildOrder := 3;
    WebHTMLDiv12.ElementPosition := epIgnore;
    WebHTMLDiv12.ElementFont := efCSS;
    WebHTMLDiv12.Role := '';
    btnEdit1.SetParentComponent(WebHTMLDiv12);
    btnEdit1.Name := 'btnEdit1';
    btnEdit1.Left := 3;
    btnEdit1.Top := 13;
    btnEdit1.Width := 94;
    btnEdit1.Height := 25;
    btnEdit1.Caption := 'Bearbeiten';
    btnEdit1.ChildOrder := 1;
    btnEdit1.ElementClassName := 'editor_button';
    btnEdit1.ElementFont := efCSS;
    btnEdit1.ElementPosition := epIgnore;
    btnEdit1.Enabled := False;
    btnEdit1.HeightStyle := ssAuto;
    btnEdit1.HeightPercent := 100.000000000000000000;
    btnEdit1.WidthStyle := ssAuto;
    btnEdit1.WidthPercent := 100.000000000000000000;
    SetEvent(btnEdit1, Self, 'OnClick', 'btnEdit1Click');
    btnNew1.SetParentComponent(WebHTMLDiv12);
    btnNew1.Name := 'btnNew1';
    btnNew1.Left := 111;
    btnNew1.Top := 13;
    btnNew1.Width := 94;
    btnNew1.Height := 25;
    btnNew1.Caption := 'Neu';
    btnNew1.ChildOrder := 2;
    btnNew1.ElementClassName := 'editor_button';
    btnNew1.ElementFont := efCSS;
    btnNew1.ElementPosition := epIgnore;
    btnNew1.HeightStyle := ssAuto;
    btnNew1.HeightPercent := 100.000000000000000000;
    btnNew1.WidthStyle := ssAuto;
    btnNew1.WidthPercent := 100.000000000000000000;
    SetEvent(btnNew1, Self, 'OnClick', 'btnNew1Click');
    btnDelete1.SetParentComponent(WebHTMLDiv12);
    btnDelete1.Name := 'btnDelete1';
    btnDelete1.Left := 231;
    btnDelete1.Top := 16;
    btnDelete1.Width := 94;
    btnDelete1.Height := 25;
    btnDelete1.Caption := 'L'#246'schen';
    btnDelete1.ChildOrder := 3;
    btnDelete1.ElementClassName := 'editor_button';
    btnDelete1.ElementFont := efCSS;
    btnDelete1.ElementPosition := epIgnore;
    btnDelete1.Enabled := False;
    btnDelete1.HeightStyle := ssAuto;
    btnDelete1.HeightPercent := 100.000000000000000000;
    btnDelete1.WidthStyle := ssAuto;
    btnDelete1.WidthPercent := 100.000000000000000000;
    SetEvent(btnDelete1, Self, 'OnClick', 'btnDeleteClick');
    WebHTMLDiv5.SetParentComponent(WebHTMLDiv2);
    WebHTMLDiv5.Name := 'WebHTMLDiv5';
    WebHTMLDiv5.Left := 14;
    WebHTMLDiv5.Top := 97;
    WebHTMLDiv5.Width := 827;
    WebHTMLDiv5.Height := 41;
    WebHTMLDiv5.ElementClassName := 'kursedit_line';
    WebHTMLDiv5.HeightStyle := ssAuto;
    WebHTMLDiv5.WidthStyle := ssAuto;
    WebHTMLDiv5.ChildOrder := 2;
    WebHTMLDiv5.ElementPosition := epRelative;
    WebHTMLDiv5.ElementFont := efCSS;
    WebHTMLDiv5.Role := '';
    cbItems2.SetParentComponent(WebHTMLDiv5);
    cbItems2.Name := 'cbItems2';
    cbItems2.Left := 11;
    cbItems2.Top := 3;
    cbItems2.Width := 273;
    cbItems2.Height := 23;
    cbItems2.ElementClassName := 'editor_combobox';
    cbItems2.ElementFont := efCSS;
    cbItems2.ElementPosition := epIgnore;
    cbItems2.Enabled := False;
    cbItems2.HeightStyle := ssAuto;
    cbItems2.HeightPercent := 100.000000000000000000;
    cbItems2.Text := 'cbItems';
    cbItems2.WidthStyle := ssAuto;
    cbItems2.WidthPercent := 100.000000000000000000;
    SetEvent(cbItems2, Self, 'OnChange', 'cbItems1Change');
    cbItems2.ItemIndex := -1;
    WebHTMLDiv13.SetParentComponent(WebHTMLDiv5);
    WebHTMLDiv13.Name := 'WebHTMLDiv13';
    WebHTMLDiv13.Left := 452;
    WebHTMLDiv13.Top := 3;
    WebHTMLDiv13.Width := 337;
    WebHTMLDiv13.Height := 41;
    WebHTMLDiv13.ElementClassName := 'kursedit_btn_container';
    WebHTMLDiv13.HeightStyle := ssAuto;
    WebHTMLDiv13.WidthStyle := ssAuto;
    WebHTMLDiv13.ChildOrder := 3;
    WebHTMLDiv13.ElementPosition := epIgnore;
    WebHTMLDiv13.ElementFont := efCSS;
    WebHTMLDiv13.Role := '';
    btnEdit2.SetParentComponent(WebHTMLDiv13);
    btnEdit2.Name := 'btnEdit2';
    btnEdit2.Left := 3;
    btnEdit2.Top := 13;
    btnEdit2.Width := 94;
    btnEdit2.Height := 25;
    btnEdit2.Caption := 'Bearbeiten';
    btnEdit2.ChildOrder := 1;
    btnEdit2.ElementClassName := 'editor_button';
    btnEdit2.ElementFont := efCSS;
    btnEdit2.ElementPosition := epIgnore;
    btnEdit2.Enabled := False;
    btnEdit2.HeightStyle := ssAuto;
    btnEdit2.HeightPercent := 100.000000000000000000;
    btnEdit2.WidthStyle := ssAuto;
    btnEdit2.WidthPercent := 100.000000000000000000;
    SetEvent(btnEdit2, Self, 'OnClick', 'btnEdit1Click');
    btnNew2.SetParentComponent(WebHTMLDiv13);
    btnNew2.Name := 'btnNew2';
    btnNew2.Left := 111;
    btnNew2.Top := 13;
    btnNew2.Width := 94;
    btnNew2.Height := 25;
    btnNew2.Caption := 'Neu';
    btnNew2.ChildOrder := 2;
    btnNew2.ElementClassName := 'editor_button';
    btnNew2.ElementFont := efCSS;
    btnNew2.ElementPosition := epIgnore;
    btnNew2.Enabled := False;
    btnNew2.HeightStyle := ssAuto;
    btnNew2.HeightPercent := 100.000000000000000000;
    btnNew2.WidthStyle := ssAuto;
    btnNew2.WidthPercent := 100.000000000000000000;
    SetEvent(btnNew2, Self, 'OnClick', 'btnNew1Click');
    btnDelete2.SetParentComponent(WebHTMLDiv13);
    btnDelete2.Name := 'btnDelete2';
    btnDelete2.Left := 223;
    btnDelete2.Top := 13;
    btnDelete2.Width := 94;
    btnDelete2.Height := 25;
    btnDelete2.Caption := 'L'#246'schen';
    btnDelete2.ChildOrder := 3;
    btnDelete2.ElementClassName := 'editor_button';
    btnDelete2.ElementFont := efCSS;
    btnDelete2.ElementPosition := epIgnore;
    btnDelete2.Enabled := False;
    btnDelete2.HeightStyle := ssAuto;
    btnDelete2.HeightPercent := 100.000000000000000000;
    btnDelete2.WidthStyle := ssAuto;
    btnDelete2.WidthPercent := 100.000000000000000000;
    SetEvent(btnDelete2, Self, 'OnClick', 'btnDeleteClick');
    WebHTMLDiv6.SetParentComponent(WebHTMLDiv2);
    WebHTMLDiv6.Name := 'WebHTMLDiv6';
    WebHTMLDiv6.Left := 14;
    WebHTMLDiv6.Top := 147;
    WebHTMLDiv6.Width := 827;
    WebHTMLDiv6.Height := 41;
    WebHTMLDiv6.ElementClassName := 'kursedit_line';
    WebHTMLDiv6.HeightStyle := ssAuto;
    WebHTMLDiv6.WidthStyle := ssAuto;
    WebHTMLDiv6.ChildOrder := 3;
    WebHTMLDiv6.ElementPosition := epRelative;
    WebHTMLDiv6.ElementFont := efCSS;
    WebHTMLDiv6.Role := '';
    cbItems3.SetParentComponent(WebHTMLDiv6);
    cbItems3.Name := 'cbItems3';
    cbItems3.Left := 15;
    cbItems3.Top := 15;
    cbItems3.Width := 273;
    cbItems3.Height := 23;
    cbItems3.ElementClassName := 'editor_combobox';
    cbItems3.ElementFont := efCSS;
    cbItems3.ElementPosition := epIgnore;
    cbItems3.Enabled := False;
    cbItems3.HeightStyle := ssAuto;
    cbItems3.HeightPercent := 100.000000000000000000;
    cbItems3.Text := 'cbItems';
    cbItems3.WidthStyle := ssAuto;
    cbItems3.WidthPercent := 100.000000000000000000;
    SetEvent(cbItems3, Self, 'OnChange', 'cbItems1Change');
    cbItems3.ItemIndex := -1;
    WebHTMLDiv14.SetParentComponent(WebHTMLDiv6);
    WebHTMLDiv14.Name := 'WebHTMLDiv14';
    WebHTMLDiv14.Left := 454;
    WebHTMLDiv14.Top := 0;
    WebHTMLDiv14.Width := 337;
    WebHTMLDiv14.Height := 41;
    WebHTMLDiv14.ElementClassName := 'kursedit_btn_container';
    WebHTMLDiv14.HeightStyle := ssAuto;
    WebHTMLDiv14.WidthStyle := ssAuto;
    WebHTMLDiv14.ChildOrder := 3;
    WebHTMLDiv14.ElementPosition := epIgnore;
    WebHTMLDiv14.ElementFont := efCSS;
    WebHTMLDiv14.Role := '';
    btnEdit3.SetParentComponent(WebHTMLDiv14);
    btnEdit3.Name := 'btnEdit3';
    btnEdit3.Left := 3;
    btnEdit3.Top := 13;
    btnEdit3.Width := 94;
    btnEdit3.Height := 25;
    btnEdit3.Caption := 'Bearbeiten';
    btnEdit3.ChildOrder := 1;
    btnEdit3.ElementClassName := 'editor_button';
    btnEdit3.ElementFont := efCSS;
    btnEdit3.ElementPosition := epIgnore;
    btnEdit3.Enabled := False;
    btnEdit3.HeightStyle := ssAuto;
    btnEdit3.HeightPercent := 100.000000000000000000;
    btnEdit3.WidthStyle := ssAuto;
    btnEdit3.WidthPercent := 100.000000000000000000;
    SetEvent(btnEdit3, Self, 'OnClick', 'btnEdit1Click');
    btnNew3.SetParentComponent(WebHTMLDiv14);
    btnNew3.Name := 'btnNew3';
    btnNew3.Left := 111;
    btnNew3.Top := 13;
    btnNew3.Width := 94;
    btnNew3.Height := 25;
    btnNew3.Caption := 'Neu';
    btnNew3.ChildOrder := 2;
    btnNew3.ElementClassName := 'editor_button';
    btnNew3.ElementFont := efCSS;
    btnNew3.ElementPosition := epIgnore;
    btnNew3.Enabled := False;
    btnNew3.HeightStyle := ssAuto;
    btnNew3.HeightPercent := 100.000000000000000000;
    btnNew3.WidthStyle := ssAuto;
    btnNew3.WidthPercent := 100.000000000000000000;
    SetEvent(btnNew3, Self, 'OnClick', 'btnNew1Click');
    btnDelete3.SetParentComponent(WebHTMLDiv14);
    btnDelete3.Name := 'btnDelete3';
    btnDelete3.Left := 223;
    btnDelete3.Top := 13;
    btnDelete3.Width := 94;
    btnDelete3.Height := 25;
    btnDelete3.Caption := 'L'#246'schen';
    btnDelete3.ChildOrder := 3;
    btnDelete3.ElementClassName := 'editor_button';
    btnDelete3.ElementFont := efCSS;
    btnDelete3.ElementPosition := epIgnore;
    btnDelete3.Enabled := False;
    btnDelete3.HeightStyle := ssAuto;
    btnDelete3.HeightPercent := 100.000000000000000000;
    btnDelete3.WidthStyle := ssAuto;
    btnDelete3.WidthPercent := 100.000000000000000000;
    SetEvent(btnDelete3, Self, 'OnClick', 'btnDeleteClick');
    WebHTMLDiv9.SetParentComponent(WebHTMLDiv2);
    WebHTMLDiv9.Name := 'WebHTMLDiv9';
    WebHTMLDiv9.Left := 14;
    WebHTMLDiv9.Top := 191;
    WebHTMLDiv9.Width := 827;
    WebHTMLDiv9.Height := 41;
    WebHTMLDiv9.ElementClassName := 'kursedit_line';
    WebHTMLDiv9.HeightStyle := ssAuto;
    WebHTMLDiv9.WidthStyle := ssAuto;
    WebHTMLDiv9.ChildOrder := 4;
    WebHTMLDiv9.ElementPosition := epRelative;
    WebHTMLDiv9.ElementFont := efCSS;
    WebHTMLDiv9.Role := '';
    cbItems4.SetParentComponent(WebHTMLDiv9);
    cbItems4.Name := 'cbItems4';
    cbItems4.Left := 16;
    cbItems4.Top := 15;
    cbItems4.Width := 273;
    cbItems4.Height := 23;
    cbItems4.ElementClassName := 'editor_combobox';
    cbItems4.ElementFont := efCSS;
    cbItems4.ElementPosition := epIgnore;
    cbItems4.Enabled := False;
    cbItems4.HeightStyle := ssAuto;
    cbItems4.HeightPercent := 100.000000000000000000;
    cbItems4.Text := 'cbItems';
    cbItems4.WidthStyle := ssAuto;
    cbItems4.WidthPercent := 100.000000000000000000;
    SetEvent(cbItems4, Self, 'OnChange', 'cbItems1Change');
    cbItems4.ItemIndex := -1;
    WebHTMLDiv15.SetParentComponent(WebHTMLDiv9);
    WebHTMLDiv15.Name := 'WebHTMLDiv15';
    WebHTMLDiv15.Left := 454;
    WebHTMLDiv15.Top := 0;
    WebHTMLDiv15.Width := 337;
    WebHTMLDiv15.Height := 41;
    WebHTMLDiv15.ElementClassName := 'kursedit_btn_container';
    WebHTMLDiv15.HeightStyle := ssAuto;
    WebHTMLDiv15.WidthStyle := ssAuto;
    WebHTMLDiv15.ChildOrder := 3;
    WebHTMLDiv15.ElementPosition := epIgnore;
    WebHTMLDiv15.ElementFont := efCSS;
    WebHTMLDiv15.Role := '';
    btnEdit4.SetParentComponent(WebHTMLDiv15);
    btnEdit4.Name := 'btnEdit4';
    btnEdit4.Left := 3;
    btnEdit4.Top := 13;
    btnEdit4.Width := 94;
    btnEdit4.Height := 25;
    btnEdit4.Caption := 'Bearbeiten';
    btnEdit4.ChildOrder := 1;
    btnEdit4.ElementClassName := 'editor_button';
    btnEdit4.ElementFont := efCSS;
    btnEdit4.ElementPosition := epIgnore;
    btnEdit4.Enabled := False;
    btnEdit4.HeightStyle := ssAuto;
    btnEdit4.HeightPercent := 100.000000000000000000;
    btnEdit4.WidthStyle := ssAuto;
    btnEdit4.WidthPercent := 100.000000000000000000;
    SetEvent(btnEdit4, Self, 'OnClick', 'btnEdit1Click');
    btnNew4.SetParentComponent(WebHTMLDiv15);
    btnNew4.Name := 'btnNew4';
    btnNew4.Left := 111;
    btnNew4.Top := 13;
    btnNew4.Width := 94;
    btnNew4.Height := 25;
    btnNew4.Caption := 'Neu';
    btnNew4.ChildOrder := 2;
    btnNew4.ElementClassName := 'editor_button';
    btnNew4.ElementFont := efCSS;
    btnNew4.ElementPosition := epIgnore;
    btnNew4.Enabled := False;
    btnNew4.HeightStyle := ssAuto;
    btnNew4.HeightPercent := 100.000000000000000000;
    btnNew4.WidthStyle := ssAuto;
    btnNew4.WidthPercent := 100.000000000000000000;
    SetEvent(btnNew4, Self, 'OnClick', 'btnNew1Click');
    btnDelete4.SetParentComponent(WebHTMLDiv15);
    btnDelete4.Name := 'btnDelete4';
    btnDelete4.Left := 223;
    btnDelete4.Top := 13;
    btnDelete4.Width := 94;
    btnDelete4.Height := 25;
    btnDelete4.Caption := 'L'#246'schen';
    btnDelete4.ChildOrder := 3;
    btnDelete4.ElementClassName := 'editor_button';
    btnDelete4.ElementFont := efCSS;
    btnDelete4.ElementPosition := epIgnore;
    btnDelete4.Enabled := False;
    btnDelete4.HeightStyle := ssAuto;
    btnDelete4.HeightPercent := 100.000000000000000000;
    btnDelete4.WidthStyle := ssAuto;
    btnDelete4.WidthPercent := 100.000000000000000000;
    SetEvent(btnDelete4, Self, 'OnClick', 'btnDeleteClick');
    WebHTMLDiv10.SetParentComponent(WebHTMLDiv2);
    WebHTMLDiv10.Name := 'WebHTMLDiv10';
    WebHTMLDiv10.Left := 14;
    WebHTMLDiv10.Top := 238;
    WebHTMLDiv10.Width := 827;
    WebHTMLDiv10.Height := 41;
    WebHTMLDiv10.ElementClassName := 'kursedit_line';
    WebHTMLDiv10.HeightStyle := ssAuto;
    WebHTMLDiv10.WidthStyle := ssAuto;
    WebHTMLDiv10.ChildOrder := 5;
    WebHTMLDiv10.ElementPosition := epRelative;
    WebHTMLDiv10.ElementFont := efCSS;
    WebHTMLDiv10.Role := '';
    cbItems5.SetParentComponent(WebHTMLDiv10);
    cbItems5.Name := 'cbItems5';
    cbItems5.Left := 16;
    cbItems5.Top := 15;
    cbItems5.Width := 273;
    cbItems5.Height := 23;
    cbItems5.ElementClassName := 'editor_combobox';
    cbItems5.ElementFont := efCSS;
    cbItems5.ElementPosition := epIgnore;
    cbItems5.Enabled := False;
    cbItems5.HeightStyle := ssAuto;
    cbItems5.HeightPercent := 100.000000000000000000;
    cbItems5.Text := 'cbItems';
    cbItems5.WidthStyle := ssAuto;
    cbItems5.WidthPercent := 100.000000000000000000;
    SetEvent(cbItems5, Self, 'OnChange', 'cbItems1Change');
    cbItems5.ItemIndex := -1;
    WebHTMLDiv16.SetParentComponent(WebHTMLDiv10);
    WebHTMLDiv16.Name := 'WebHTMLDiv16';
    WebHTMLDiv16.Left := 454;
    WebHTMLDiv16.Top := 0;
    WebHTMLDiv16.Width := 334;
    WebHTMLDiv16.Height := 41;
    WebHTMLDiv16.ElementClassName := 'kursedit_btn_container';
    WebHTMLDiv16.HeightStyle := ssAuto;
    WebHTMLDiv16.WidthStyle := ssAuto;
    WebHTMLDiv16.ChildOrder := 3;
    WebHTMLDiv16.ElementPosition := epIgnore;
    WebHTMLDiv16.ElementFont := efCSS;
    WebHTMLDiv16.Role := '';
    btnEdit5.SetParentComponent(WebHTMLDiv16);
    btnEdit5.Name := 'btnEdit5';
    btnEdit5.Left := 3;
    btnEdit5.Top := 13;
    btnEdit5.Width := 94;
    btnEdit5.Height := 25;
    btnEdit5.Caption := 'Bearbeiten';
    btnEdit5.ChildOrder := 1;
    btnEdit5.ElementClassName := 'editor_button';
    btnEdit5.ElementFont := efCSS;
    btnEdit5.ElementPosition := epIgnore;
    btnEdit5.Enabled := False;
    btnEdit5.HeightStyle := ssAuto;
    btnEdit5.HeightPercent := 100.000000000000000000;
    btnEdit5.WidthStyle := ssAuto;
    btnEdit5.WidthPercent := 100.000000000000000000;
    SetEvent(btnEdit5, Self, 'OnClick', 'btnEdit1Click');
    btnNew5.SetParentComponent(WebHTMLDiv16);
    btnNew5.Name := 'btnNew5';
    btnNew5.Left := 103;
    btnNew5.Top := 13;
    btnNew5.Width := 94;
    btnNew5.Height := 25;
    btnNew5.Caption := 'Neu';
    btnNew5.ChildOrder := 2;
    btnNew5.ElementClassName := 'editor_button';
    btnNew5.ElementFont := efCSS;
    btnNew5.ElementPosition := epIgnore;
    btnNew5.Enabled := False;
    btnNew5.HeightStyle := ssAuto;
    btnNew5.HeightPercent := 100.000000000000000000;
    btnNew5.WidthStyle := ssAuto;
    btnNew5.WidthPercent := 100.000000000000000000;
    SetEvent(btnNew5, Self, 'OnClick', 'btnNew1Click');
    btnDelete5.SetParentComponent(WebHTMLDiv16);
    btnDelete5.Name := 'btnDelete5';
    btnDelete5.Left := 223;
    btnDelete5.Top := 23;
    btnDelete5.Width := 94;
    btnDelete5.Height := 25;
    btnDelete5.Caption := 'L'#246'schen';
    btnDelete5.ChildOrder := 2;
    btnDelete5.ElementClassName := 'editor_button';
    btnDelete5.ElementFont := efCSS;
    btnDelete5.ElementPosition := epIgnore;
    btnDelete5.Enabled := False;
    btnDelete5.HeightStyle := ssAuto;
    btnDelete5.HeightPercent := 100.000000000000000000;
    btnDelete5.WidthStyle := ssAuto;
    btnDelete5.WidthPercent := 100.000000000000000000;
    SetEvent(btnDelete5, Self, 'OnClick', 'btnDeleteClick');
    divHeader.SetParentComponent(WebHTMLDiv1);
    divHeader.Name := 'divHeader';
    divHeader.Left := 28;
    divHeader.Top := 295;
    divHeader.Width := 857;
    divHeader.Height := 42;
    divHeader.ElementClassName := 'kursedit_line';
    divHeader.HeightStyle := ssAuto;
    divHeader.WidthStyle := ssAuto;
    divHeader.ChildOrder := 2;
    divHeader.ElementPosition := epRelative;
    divHeader.ElementFont := efCSS;
    divHeader.Role := '';
    EditButtons.SetParentComponent(divHeader);
    EditButtons.Name := 'EditButtons';
    EditButtons.Left := 3;
    EditButtons.Top := 4;
    EditButtons.Width := 723;
    EditButtons.Height := 35;
    EditButtons.ElementClassName := '';
    EditButtons.HeightStyle := ssAuto;
    EditButtons.WidthStyle := ssAuto;
    EditButtons.Color := clBtnFace;
    EditButtons.ElementPosition := epRelative;
    EditButtons.Hints.BeginUpdate;
    try
      EditButtons.Hints.Clear;
      EditButtons.Hints.Add('Bold');
      EditButtons.Hints.Add('Italic');
      EditButtons.Hints.Add('Underline');
      EditButtons.Hints.Add('Strike Throught');
      EditButtons.Hints.Add('Text Color');
      EditButtons.Hints.Add('Background Color');
      EditButtons.Hints.Add('Align lef');
      EditButtons.Hints.Add('Align center');
      EditButtons.Hints.Add('Align right');
      EditButtons.Hints.Add('Numbered list');
      EditButtons.Hints.Add('List');
    finally
      EditButtons.Hints.EndUpdate;
    end;
    btnToolTip.SetParentComponent(divHeader);
    btnToolTip.Name := 'btnToolTip';
    btnToolTip.Left := 732;
    btnToolTip.Top := 3;
    btnToolTip.Width := 41;
    btnToolTip.Height := 31;
    btnToolTip.Caption := 'Tip';
    btnToolTip.Color := clNone;
    btnToolTip.ElementFont := efCSS;
    btnToolTip.ElementPosition := epRelative;
    btnToolTip.HeightPercent := 100.000000000000000000;
    btnToolTip.TabOrder := 1;
    btnToolTip.WidthPercent := 100.000000000000000000;
    SetEvent(btnToolTip, Self, 'OnClick', 'btnToolTipClick');
    spHtml.SetParentComponent(divHeader);
    spHtml.Name := 'spHtml';
    spHtml.Left := 779;
    spHtml.Top := 3;
    spHtml.Width := 54;
    spHtml.Height := 31;
    spHtml.Caption := 'HTML';
    spHtml.Color := clNone;
    spHtml.ElementFont := efCSS;
    spHtml.ElementPosition := epRelative;
    spHtml.HeightPercent := 100.000000000000000000;
    spHtml.TabOrder := 2;
    spHtml.WidthPercent := 100.000000000000000000;
    SetEvent(spHtml, Self, 'OnClick', 'spHtmlClick');
    WebHTMLDiv3.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv3.Name := 'WebHTMLDiv3';
    WebHTMLDiv3.Left := 28;
    WebHTMLDiv3.Top := 343;
    WebHTMLDiv3.Width := 857;
    WebHTMLDiv3.Height := 554;
    WebHTMLDiv3.ElementClassName := 'kursedit_text_container';
    WebHTMLDiv3.HeightStyle := ssAuto;
    WebHTMLDiv3.WidthStyle := ssAuto;
    WebHTMLDiv3.ChildOrder := 3;
    WebHTMLDiv3.ElementPosition := epRelative;
    WebHTMLDiv3.ElementFont := efCSS;
    WebHTMLDiv3.Role := '';
    reTitel.SetParentComponent(WebHTMLDiv3);
    reTitel.Name := 'reTitel';
    reTitel.Left := 16;
    reTitel.Top := 74;
    reTitel.Width := 825;
    reTitel.Height := 46;
    reTitel.ElementClassName := 'kursedit_titel';
    reTitel.HeightStyle := ssAuto;
    reTitel.WidthStyle := ssAuto;
    reTitel.BorderStyle := bsSingle;
    reTitel.ChildOrder := 1;
    reTitel.Color := clWhite;
    SetEvent(reTitel, Self, 'OnEnter', 'reTitelEnter');
    WebHTMLDiv8.SetParentComponent(WebHTMLDiv3);
    WebHTMLDiv8.Name := 'WebHTMLDiv8';
    WebHTMLDiv8.Left := 14;
    WebHTMLDiv8.Top := 311;
    WebHTMLDiv8.Width := 827;
    WebHTMLDiv8.Height := 114;
    WebHTMLDiv8.ElementClassName := 'ktext_img_container';
    WebHTMLDiv8.HeightStyle := ssAuto;
    WebHTMLDiv8.WidthStyle := ssAuto;
    WebHTMLDiv8.ChildOrder := 3;
    WebHTMLDiv8.ElementPosition := epIgnore;
    WebHTMLDiv8.ElementFont := efCSS;
    WebHTMLDiv8.Role := '';
    frFotosliste1.SetParentComponent(WebHTMLDiv8);
    frFotosliste1.Name := 'frFotosliste1';
    frFotosliste1.Left := 0;
    frFotosliste1.Top := 0;
    frFotosliste1.Width := 345;
    frFotosliste1.Height := 129;
    frFotosliste1.Margins.Left := 0;
    frFotosliste1.Margins.Top := 0;
    frFotosliste1.Margins.Right := 0;
    frFotosliste1.Margins.Bottom := 0;
    frFotosliste1.TabOrder := 0;
    frFotosliste1.WebHTMLDiv1.Left := 12;
    frFotosliste1.WebHTMLDiv1.Width := 267;
    frFotosliste1.WebHTMLDiv1.Height := 102;
    frFotosliste1.divWrapper.Left := 14;
    frFotosliste1.divWrapper.Top := 0;
    frFotosliste1.divWrapper.Height := 89;
    frFotosliste1.img.Top := 16;
    frFotosliste1.img.Width := 49;
    frFotosliste1.img.Height := 54;
    WebHTMLDiv17.SetParentComponent(WebHTMLDiv3);
    WebHTMLDiv17.Name := 'WebHTMLDiv17';
    WebHTMLDiv17.Left := 16;
    WebHTMLDiv17.Top := 431;
    WebHTMLDiv17.Width := 825;
    WebHTMLDiv17.Height := 113;
    WebHTMLDiv17.ElementClassName := 'ktext_img_container';
    WebHTMLDiv17.HeightStyle := ssAuto;
    WebHTMLDiv17.WidthStyle := ssAuto;
    WebHTMLDiv17.ChildOrder := 5;
    WebHTMLDiv17.ElementPosition := epIgnore;
    WebHTMLDiv17.ElementFont := efCSS;
    WebHTMLDiv17.Role := '';
    frVideoListe1.SetParentComponent(WebHTMLDiv17);
    frVideoListe1.Name := 'frVideoListe1';
    frVideoListe1.Left := 0;
    frVideoListe1.Top := 3;
    frVideoListe1.Width := 301;
    frVideoListe1.Height := 117;
    frVideoListe1.Margins.Left := 0;
    frVideoListe1.Margins.Top := 0;
    frVideoListe1.Margins.Right := 0;
    frVideoListe1.Margins.Bottom := 0;
    frVideoListe1.TabOrder := 0;
    frVideoListe1.WebHTMLDiv1.Width := 273;
    frVideoListe1.WebHTMLDiv1.Height := 105;
    frVideoListe1.divWrapper.Left := 24;
    frVideoListe1.divWrapper.Top := 8;
    frVideoListe1.divWrapper.Width := 233;
    frVideoListe1.divWrapper.Height := 89;
    frVideoListe1.img.Left := 24;
    frVideoListe1.img.Top := 16;
    frVideoListe1.img.Width := 65;
    frVideoListe1.img.Height := 57;
    frVideoListe1.Client.Left := 16;
    frVideoListe1.Client.Top := 16;
    WebHTMLDiv22.SetParentComponent(WebHTMLDiv3);
    WebHTMLDiv22.Name := 'WebHTMLDiv22';
    WebHTMLDiv22.Left := 14;
    WebHTMLDiv22.Top := 8;
    WebHTMLDiv22.Width := 827;
    WebHTMLDiv22.Height := 60;
    WebHTMLDiv22.ElementClassName := 'kursedit-titelbild-container';
    WebHTMLDiv22.HeightStyle := ssAuto;
    WebHTMLDiv22.WidthStyle := ssAuto;
    WebHTMLDiv22.ElementPosition := epIgnore;
    WebHTMLDiv22.ElementFont := efCSS;
    WebHTMLDiv22.Role := '';
    img1.SetParentComponent(WebHTMLDiv22);
    img1.Name := 'img1';
    img1.Left := 19;
    img1.Top := -5;
    img1.Width := 100;
    img1.Height := 65;
    img1.ElementClassName := 'kursedit_img';
    img1.HeightStyle := ssAuto;
    img1.WidthStyle := ssAuto;
    img1.HeightPercent := 100.000000000000000000;
    img1.WidthPercent := 100.000000000000000000;
    img1.ChildOrder := 1;
    img1.ElementFont := efCSS;
    img1.ElementPosition := epIgnore;
    SetEvent(img1, Self, 'OnClick', 'img1Click');
    WebHTMLDiv25.SetParentComponent(WebHTMLDiv22);
    WebHTMLDiv25.Name := 'WebHTMLDiv25';
    WebHTMLDiv25.Left := 125;
    WebHTMLDiv25.Top := -8;
    WebHTMLDiv25.Width := 193;
    WebHTMLDiv25.Height := 68;
    WebHTMLDiv25.ElementClassName := 'kursedit-btn-container';
    WebHTMLDiv25.HeightStyle := ssAuto;
    WebHTMLDiv25.WidthStyle := ssAuto;
    WebHTMLDiv25.ChildOrder := 3;
    WebHTMLDiv25.ElementPosition := epIgnore;
    WebHTMLDiv25.ElementFont := efCSS;
    WebHTMLDiv25.Role := '';
    WebButton2.SetParentComponent(WebHTMLDiv25);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 49;
    WebButton2.Top := 4;
    WebButton2.Width := 144;
    WebButton2.Height := 25;
    WebButton2.Caption := 'Titelfoto hinzuf'#252'gen';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementClassName := 'start_button-lets-go';
    WebButton2.ElementFont := efCSS;
    WebButton2.ElementPosition := epIgnore;
    WebButton2.HeightStyle := ssAuto;
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthStyle := ssAuto;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'img1Click');
    WebButton1.SetParentComponent(WebHTMLDiv25);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 49;
    WebButton1.Top := 35;
    WebButton1.Width := 144;
    WebButton1.Height := 25;
    WebButton1.Caption := 'Titelfoto l'#246'schen';
    WebButton1.ChildOrder := 3;
    WebButton1.ElementClassName := 'start_button-lets-go';
    WebButton1.ElementFont := efCSS;
    WebButton1.ElementPosition := epIgnore;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthStyle := ssAuto;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebHTMLDiv23.SetParentComponent(WebHTMLDiv3);
    WebHTMLDiv23.Name := 'WebHTMLDiv23';
    WebHTMLDiv23.Left := 14;
    WebHTMLDiv23.Top := 264;
    WebHTMLDiv23.Width := 827;
    WebHTMLDiv23.Height := 41;
    WebHTMLDiv23.ElementClassName := 'editor-footer-div';
    WebHTMLDiv23.HeightStyle := ssAuto;
    WebHTMLDiv23.WidthStyle := ssAuto;
    WebHTMLDiv23.ChildOrder := 3;
    WebHTMLDiv23.ElementPosition := epIgnore;
    WebHTMLDiv23.ElementFont := efCSS;
    WebHTMLDiv23.Role := '';
    btnFotoAdd.SetParentComponent(WebHTMLDiv23);
    btnFotoAdd.Name := 'btnFotoAdd';
    btnFotoAdd.Left := 9;
    btnFotoAdd.Top := 0;
    btnFotoAdd.Width := 144;
    btnFotoAdd.Height := 25;
    btnFotoAdd.Caption := 'Fotos hinzuf'#252'gen';
    btnFotoAdd.ChildOrder := 1;
    btnFotoAdd.ElementClassName := 'start_button-lets-go';
    btnFotoAdd.ElementFont := efCSS;
    btnFotoAdd.ElementPosition := epIgnore;
    btnFotoAdd.HeightStyle := ssAuto;
    btnFotoAdd.HeightPercent := 100.000000000000000000;
    btnFotoAdd.WidthStyle := ssAuto;
    btnFotoAdd.WidthPercent := 100.000000000000000000;
    SetEvent(btnFotoAdd, Self, 'OnClick', 'btnFotoAddClick');
    btnVideoAdd.SetParentComponent(WebHTMLDiv23);
    btnVideoAdd.Name := 'btnVideoAdd';
    btnVideoAdd.Left := 159;
    btnVideoAdd.Top := 3;
    btnVideoAdd.Width := 144;
    btnVideoAdd.Height := 25;
    btnVideoAdd.Caption := 'Video hinzuf'#252'gen';
    btnVideoAdd.ChildOrder := 2;
    btnVideoAdd.ElementClassName := 'start_button-lets-go';
    btnVideoAdd.ElementFont := efCSS;
    btnVideoAdd.ElementPosition := epIgnore;
    btnVideoAdd.HeightStyle := ssAuto;
    btnVideoAdd.HeightPercent := 100.000000000000000000;
    btnVideoAdd.WidthStyle := ssAuto;
    btnVideoAdd.WidthPercent := 100.000000000000000000;
    SetEvent(btnVideoAdd, Self, 'OnClick', 'btnVideoAddClick');
    btnFotosDel.SetParentComponent(WebHTMLDiv23);
    btnFotosDel.Name := 'btnFotosDel';
    btnFotosDel.Left := 321;
    btnFotosDel.Top := 0;
    btnFotosDel.Width := 144;
    btnFotosDel.Height := 25;
    btnFotosDel.Caption := 'Foto(s) l'#246'schen';
    btnFotosDel.ChildOrder := 3;
    btnFotosDel.ElementClassName := 'start_button-lets-go';
    btnFotosDel.ElementFont := efCSS;
    btnFotosDel.ElementPosition := epIgnore;
    btnFotosDel.HeightStyle := ssAuto;
    btnFotosDel.HeightPercent := 100.000000000000000000;
    btnFotosDel.WidthStyle := ssAuto;
    btnFotosDel.WidthPercent := 100.000000000000000000;
    SetEvent(btnFotosDel, Self, 'OnClick', 'btnFotosDelClick');
    btnVideosDel.SetParentComponent(WebHTMLDiv23);
    btnVideosDel.Name := 'btnVideosDel';
    btnVideosDel.Left := 471;
    btnVideosDel.Top := 3;
    btnVideosDel.Width := 144;
    btnVideosDel.Height := 25;
    btnVideosDel.Caption := 'Video(s) l'#246'schen';
    btnVideosDel.ChildOrder := 4;
    btnVideosDel.ElementClassName := 'start_button-lets-go';
    btnVideosDel.ElementFont := efCSS;
    btnVideosDel.ElementPosition := epIgnore;
    btnVideosDel.HeightStyle := ssAuto;
    btnVideosDel.HeightPercent := 100.000000000000000000;
    btnVideosDel.WidthStyle := ssAuto;
    btnVideosDel.WidthPercent := 100.000000000000000000;
    SetEvent(btnVideosDel, Self, 'OnClick', 'btnVideosDelClick');
    WebHTMLDiv24.SetParentComponent(WebHTMLDiv3);
    WebHTMLDiv24.Name := 'WebHTMLDiv24';
    WebHTMLDiv24.Left := 16;
    WebHTMLDiv24.Top := 128;
    WebHTMLDiv24.Width := 825;
    WebHTMLDiv24.Height := 133;
    WebHTMLDiv24.HeightStyle := ssAuto;
    WebHTMLDiv24.WidthStyle := ssAuto;
    WebHTMLDiv24.ChildOrder := 2;
    WebHTMLDiv24.ElementPosition := epRelative;
    WebHTMLDiv24.ElementFont := efCSS;
    WebHTMLDiv24.Role := '';
    Memo.SetParentComponent(WebHTMLDiv24);
    Memo.Name := 'Memo';
    Memo.Left := 3;
    Memo.Top := 3;
    Memo.Width := 389;
    Memo.Height := 123;
    Memo.AutoSize := False;
    Memo.ChildOrder := 2;
    Memo.ElementClassName := 'kursedit_input';
    Memo.ElementFont := efCSS;
    Memo.ElementPosition := epIgnore;
    Memo.HeightStyle := ssAuto;
    Memo.HeightPercent := 100.000000000000000000;
    Memo.Lines.BeginUpdate;
    try
      Memo.Lines.Clear;
      Memo.Lines.Add('Memo');
    finally
      Memo.Lines.EndUpdate;
    end;
    Memo.SelLength := 0;
    Memo.SelStart := 0;
    Memo.Visible := False;
    Memo.WidthStyle := ssAuto;
    Memo.WidthPercent := 100.000000000000000000;
    reText.SetParentComponent(WebHTMLDiv24);
    reText.Name := 'reText';
    reText.Left := 441;
    reText.Top := 3;
    reText.Width := 373;
    reText.Height := 122;
    reText.ElementClassName := 'kursedit_input';
    reText.HeightStyle := ssAuto;
    reText.WidthStyle := ssAuto;
    reText.BorderStyle := bsSingle;
    reText.ChildOrder := 2;
    reText.Color := clWhite;
    SetEvent(reText, Self, 'OnEnter', 'reTitelEnter');
    WebHTMLDiv7.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv7.Name := 'WebHTMLDiv7';
    WebHTMLDiv7.Left := 28;
    WebHTMLDiv7.Top := 903;
    WebHTMLDiv7.Width := 857;
    WebHTMLDiv7.Height := 114;
    WebHTMLDiv7.ElementClassName := 'albumedit_footer';
    WebHTMLDiv7.HeightStyle := ssAuto;
    WebHTMLDiv7.WidthStyle := ssAuto;
    WebHTMLDiv7.ChildOrder := 6;
    WebHTMLDiv7.ElementPosition := epRelative;
    WebHTMLDiv7.ElementFont := efCSS;
    WebHTMLDiv7.Role := '';
    WebHTMLDiv18.SetParentComponent(WebHTMLDiv7);
    WebHTMLDiv18.Name := 'WebHTMLDiv18';
    WebHTMLDiv18.Left := 14;
    WebHTMLDiv18.Top := 11;
    WebHTMLDiv18.Width := 499;
    WebHTMLDiv18.Height := 102;
    WebHTMLDiv18.ElementClassName := 'albumedit_combo_container';
    WebHTMLDiv18.HeightStyle := ssAuto;
    WebHTMLDiv18.WidthStyle := ssAuto;
    WebHTMLDiv18.ElementPosition := epIgnore;
    WebHTMLDiv18.ElementFont := efCSS;
    WebHTMLDiv18.Role := '';
    WebHTMLDiv19.SetParentComponent(WebHTMLDiv18);
    WebHTMLDiv19.Name := 'WebHTMLDiv19';
    WebHTMLDiv19.Left := 12;
    WebHTMLDiv19.Top := 3;
    WebHTMLDiv19.Width := 390;
    WebHTMLDiv19.Height := 41;
    WebHTMLDiv19.HeightStyle := ssAuto;
    WebHTMLDiv19.WidthStyle := ssAuto;
    WebHTMLDiv19.ChildOrder := 2;
    WebHTMLDiv19.ElementPosition := epIgnore;
    WebHTMLDiv19.ElementFont := efCSS;
    WebHTMLDiv19.HTML.BeginUpdate;
    try
      WebHTMLDiv19.HTML.Clear;
      WebHTMLDiv19.HTML.Add('F'#252'r wen ist dieser Kurs kostenlos verf'#252'gbar?');
    finally
      WebHTMLDiv19.HTML.EndUpdate;
    end;
    WebHTMLDiv19.Role := '';
    WebHTMLDiv20.SetParentComponent(WebHTMLDiv18);
    WebHTMLDiv20.Name := 'WebHTMLDiv20';
    WebHTMLDiv20.Left := 8;
    WebHTMLDiv20.Top := 56;
    WebHTMLDiv20.Width := 489;
    WebHTMLDiv20.Height := 41;
    WebHTMLDiv20.HeightStyle := ssAuto;
    WebHTMLDiv20.WidthStyle := ssAuto;
    WebHTMLDiv20.ChildOrder := 2;
    WebHTMLDiv20.ElementPosition := epIgnore;
    WebHTMLDiv20.ElementFont := efCSS;
    WebHTMLDiv20.Role := '';
    cbStatus.SetParentComponent(WebHTMLDiv20);
    cbStatus.Name := 'cbStatus';
    cbStatus.Left := 3;
    cbStatus.Top := 3;
    cbStatus.Width := 273;
    cbStatus.Height := 23;
    cbStatus.ElementClassName := 'albumedit_combobox';
    cbStatus.ElementFont := efCSS;
    cbStatus.ElementPosition := epIgnore;
    cbStatus.Enabled := False;
    cbStatus.HeightStyle := ssAuto;
    cbStatus.HeightPercent := 100.000000000000000000;
    cbStatus.WidthStyle := ssAuto;
    cbStatus.WidthPercent := 100.000000000000000000;
    cbStatus.ItemIndex := -1;
    cbStatus.Items.BeginUpdate;
    try
      cbStatus.Items.Clear;
      cbStatus.Items.Add('unsichtbar');
      cbStatus.Items.Add('Admin');
      cbStatus.Items.Add('Gruppe 1');
      cbStatus.Items.Add('Gruppe 2');
      cbStatus.Items.Add('Gruppe 3');
    finally
      cbStatus.Items.EndUpdate;
    end;
    WebHTMLDiv21.SetParentComponent(WebHTMLDiv7);
    WebHTMLDiv21.Name := 'WebHTMLDiv21';
    WebHTMLDiv21.Left := 519;
    WebHTMLDiv21.Top := 14;
    WebHTMLDiv21.Width := 316;
    WebHTMLDiv21.Height := 41;
    WebHTMLDiv21.ElementClassName := 'editor-footer-div';
    WebHTMLDiv21.HeightStyle := ssAuto;
    WebHTMLDiv21.WidthStyle := ssAuto;
    WebHTMLDiv21.ChildOrder := 1;
    WebHTMLDiv21.ElementPosition := epIgnore;
    WebHTMLDiv21.ElementFont := efCSS;
    WebHTMLDiv21.Role := '';
    btnSave.SetParentComponent(WebHTMLDiv21);
    btnSave.Name := 'btnSave';
    btnSave.Left := 9;
    btnSave.Top := 3;
    btnSave.Width := 144;
    btnSave.Height := 25;
    btnSave.Caption := 'Speichern';
    btnSave.ChildOrder := 1;
    btnSave.ElementClassName := 'start_button-lets-go';
    btnSave.ElementFont := efCSS;
    btnSave.ElementPosition := epIgnore;
    btnSave.HeightStyle := ssAuto;
    btnSave.HeightPercent := 100.000000000000000000;
    btnSave.WidthStyle := ssAuto;
    btnSave.WidthPercent := 100.000000000000000000;
    SetEvent(btnSave, Self, 'OnClick', 'btnSaveClick');
    btnCancel.SetParentComponent(WebHTMLDiv21);
    btnCancel.Name := 'btnCancel';
    btnCancel.Left := 159;
    btnCancel.Top := 3;
    btnCancel.Width := 144;
    btnCancel.Height := 25;
    btnCancel.Caption := 'Verwerfen';
    btnCancel.ChildOrder := 2;
    btnCancel.ElementClassName := 'start_button-lets-go';
    btnCancel.ElementFont := efCSS;
    btnCancel.ElementPosition := epIgnore;
    btnCancel.HeightStyle := ssAuto;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthStyle := ssAuto;
    btnCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCancel, Self, 'OnClick', 'btnCloseClick');
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 224;
    Client.Top := 272;
    OpenDlg.SetParentComponent(Self);
    OpenDlg.Name := 'OpenDlg';
    OpenDlg.Left := 160;
    OpenDlg.Top := 272;
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    WebHTMLDiv11.AfterLoadDFMValues;
    btnClose.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    WebHTMLDiv4.AfterLoadDFMValues;
    cbItems1.AfterLoadDFMValues;
    WebHTMLDiv12.AfterLoadDFMValues;
    btnEdit1.AfterLoadDFMValues;
    btnNew1.AfterLoadDFMValues;
    btnDelete1.AfterLoadDFMValues;
    WebHTMLDiv5.AfterLoadDFMValues;
    cbItems2.AfterLoadDFMValues;
    WebHTMLDiv13.AfterLoadDFMValues;
    btnEdit2.AfterLoadDFMValues;
    btnNew2.AfterLoadDFMValues;
    btnDelete2.AfterLoadDFMValues;
    WebHTMLDiv6.AfterLoadDFMValues;
    cbItems3.AfterLoadDFMValues;
    WebHTMLDiv14.AfterLoadDFMValues;
    btnEdit3.AfterLoadDFMValues;
    btnNew3.AfterLoadDFMValues;
    btnDelete3.AfterLoadDFMValues;
    WebHTMLDiv9.AfterLoadDFMValues;
    cbItems4.AfterLoadDFMValues;
    WebHTMLDiv15.AfterLoadDFMValues;
    btnEdit4.AfterLoadDFMValues;
    btnNew4.AfterLoadDFMValues;
    btnDelete4.AfterLoadDFMValues;
    WebHTMLDiv10.AfterLoadDFMValues;
    cbItems5.AfterLoadDFMValues;
    WebHTMLDiv16.AfterLoadDFMValues;
    btnEdit5.AfterLoadDFMValues;
    btnNew5.AfterLoadDFMValues;
    btnDelete5.AfterLoadDFMValues;
    divHeader.AfterLoadDFMValues;
    EditButtons.AfterLoadDFMValues;
    btnToolTip.AfterLoadDFMValues;
    spHtml.AfterLoadDFMValues;
    WebHTMLDiv3.AfterLoadDFMValues;
    reTitel.AfterLoadDFMValues;
    WebHTMLDiv8.AfterLoadDFMValues;
    frFotosliste1.AfterLoadDFMValues;
    frFotosliste1.WebHTMLDiv1.AfterLoadDFMValues;
    frFotosliste1.divWrapper.AfterLoadDFMValues;
    frFotosliste1.img.AfterLoadDFMValues;
    WebHTMLDiv17.AfterLoadDFMValues;
    frVideoListe1.AfterLoadDFMValues;
    frVideoListe1.WebHTMLDiv1.AfterLoadDFMValues;
    frVideoListe1.divWrapper.AfterLoadDFMValues;
    frVideoListe1.img.AfterLoadDFMValues;
    frVideoListe1.Client.AfterLoadDFMValues;
    WebHTMLDiv22.AfterLoadDFMValues;
    img1.AfterLoadDFMValues;
    WebHTMLDiv25.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebHTMLDiv23.AfterLoadDFMValues;
    btnFotoAdd.AfterLoadDFMValues;
    btnVideoAdd.AfterLoadDFMValues;
    btnFotosDel.AfterLoadDFMValues;
    btnVideosDel.AfterLoadDFMValues;
    WebHTMLDiv24.AfterLoadDFMValues;
    Memo.AfterLoadDFMValues;
    reText.AfterLoadDFMValues;
    WebHTMLDiv7.AfterLoadDFMValues;
    WebHTMLDiv18.AfterLoadDFMValues;
    WebHTMLDiv19.AfterLoadDFMValues;
    WebHTMLDiv20.AfterLoadDFMValues;
    cbStatus.AfterLoadDFMValues;
    WebHTMLDiv21.AfterLoadDFMValues;
    btnSave.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
    OpenDlg.AfterLoadDFMValues;
  end;
end;

end.

