unit ufrGruppenThumb;

interface

uses
  System.SysUtils, System.Variants, System.Classes,web,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.Imaging.pngimage,
  Vcl.Imaging.jpeg, WEBLib.Controls, WEBLib.ExtCtrls, WEBLib.WebCtrls, utb,
  uGlobal, WEBLib.Dialogs, js, XData.Web.Client, WEBLib.Graphics, WEBLib.Forms;

type
  TfrGruppenThumb = class(TFrame)
    p0: THTMLDiv;
    img: TImageControl;
    lblHeader: THTMLDiv;
    lblText: THTMLDiv;
    Client: TXDataWebClient;
    XDataWebClient1: TXDataWebClient;
    WebHTMLDiv1: THTMLDiv;
    WebHTMLDiv5: THTMLDiv;
    lblFotos: THTMLDiv;
    WebHTMLDiv7: THTMLDiv;
    WebHTMLDiv8: THTMLDiv;
    lblVideos: THTMLDiv;
    WebHTMLDiv10: THTMLDiv;
    divMenu: THTMLDiv;
    btnEdit: TImageControl;
    btnFoto: TImageControl;
    btnVideo: TImageControl;
    btnDelete: TImageControl;
    WebHTMLDiv2: THTMLDiv;
    lblDoks: THTMLDiv;
    WebHTMLDiv4: THTMLDiv;
    WebMessageDlg1: TMessageDlg;
    btnUserAdd: TImageControl;
    procedure btnFotoClick(Sender: TObject);
    procedure btnVideoClick(Sender: TObject);
    procedure btnEditClick(Sender: TObject);
    procedure imgClick(Sender: TObject);
    [async] procedure btnDeleteClick(Sender: TObject); async;
    procedure lblHeaderClick(Sender: TObject);
    procedure btnUserAddClick(Sender: TObject);
  protected
    procedure Loaded(); override;
  private
    FElementID : string;
    FAlbum, FGruppenId,
    FHolder, FUser:integer;
    [async] procedure HeaderClick(Sender: TObject); async;
  public
    [async] class function Add(AParent:TCustomControl; AGruppenId, AHolder, AUser, AAlbum:integer; AName, AText, AFotos, AVideos, ATitel:string):TfrGruppenThumb; async;
    [async] procedure UpdateGruppe(s: string); async;

    property ElementID : string read FElementID write FElementID;
    property Holder : integer read FHolder write FHolder;
    property User : integer read FUser write FUser;
    property Album: integer read FAlbum write FAlbum;
    property GruppenID : integer read FGruppenID write FGruppenID;
  protected procedure LoadDFMValues; override; end;

implementation
   uses uMain, uDatenmodul, uGruppenEdit, uSlider, uSelection;
{$R *.dfm}

class function TfrGruppenThumb.Add(AParent:TCustomControl; AGruppenId, AHolder, AUser, AAlbum:integer; AName, AText, AFotos, AVideos, ATitel:string):TfrGruppenThumb;
var
  el: TJSHTMLElement;
begin
  result := TfrGruppenThumb.Create(AParent.owner);
  result.LoadFromForm;
  result.parent := AParent;

  TControl(result).elementPosition := epIgnore;
  THTMlDiv(result).ElementID := 'gruppe' + AGruppenID.ToString;
  THTMlDiv(result).ElementClassName := 'gruppen_frame';

  el := TJSHTMLElement(THTMLDiv(result).ElementHandle);
  el.style.removeProperty('overflow');
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-style');
  el.style.removeProperty('font-size');

//  if AStandard > 0
//  then result.img.url := await(Datenmodul.GetMedia(AStandard));

  result.img.url := ATitel;

  result.Holder  := AHolder;
  result.User    := AUser;
  result.Album   := AAlbum;
  result.GruppenID := AGruppenId;
  result.lblHeader.HTML.Text := AName;
  result.lblText.HTML.Text   := AText;
  result.lblFotos.HTML.Text  := AFotos;
  result.lblVideos.HTML.Text := AVideos;
  result.lblDoks.HTML.Text   := '-';
  result.btnEdit.Visible     := (AGruppenID = AUser);
  result.btnDelete.Visible   := (AGruppenID = AUser);

end;

procedure TfrGruppenThumb.UpdateGruppe(s:string);
var
  J: TJ;
begin
  J := TJ.Create(await(datenmodul.EasySQL('SELECT * from Gruppen where ID = ' + s)));
  img.URL := await(datenmodul.GetMedia(j.Integer('MEDIEN_ID'), _SIZE2));
  lblHeader.HTML.Text := J.value('TITEL');
  lblText.HTML.Text := J.value('TEXT');
end;

procedure TfrGruppenThumb.HeaderClick(Sender: TObject);
begin
end;

procedure TfrGruppenThumb.imgClick(Sender: TObject);
begin
//  showmessage('edit')
end;

procedure TfrGruppenThumb.lblHeaderClick(Sender: TObject);
begin
   openUrl(Mainform.ME.ID.ToString, FGruppenID.tostring, _Call_ME, '1','0');
//   Mainform.HolderID := FGruppenID;
end;

procedure TfrGruppenThumb.btnDeleteClick(Sender: TObject);
var
  mr: TModalResult;
  o: TfrGruppenThumb;
begin
  o := TfrGruppenThumb(self);
//  if FAlbumID <> mainform.me.Album
//  then begin
//    mr := await(TModalResult, WebMessageDlg1.ShowDialog('Gruppe "' + lblHeader.HTML.Text + '" endgültig löschen?', WEBLib.Dialogs.mtConfirmation,[mbYes, mbNo]));
//    if mr = mrYes
//    then begin
//       await(datenmodul.EasySQL('delete from Medien where ALBUM_ID = ' + FAlbumID.ToString));
//       await(datenmodul.EasySQL('delete from GRUPPEN where ID = ' + FAlbumID.ToString));
//
//       o.Free;
//    end;
//  end
//  else  await(TModalResult, WebMessageDlg1.ShowDialog('Das Standard-Album kann nicht gelöscht werden...', WEBLib.Dialogs.mtInformation,[mbClose]));

end;

procedure TfrGruppenThumb.btnFotoClick(Sender: TObject);
begin
//showmessage('XX');
  TfrmSlider.Fotos(FHolder, FUser, FAlbum,0,0,0, 0, nil, 2);
end;

procedure TfrGruppenThumb.btnUserAddClick(Sender: TObject);
begin
  TfrmSelection.Gruppe(FGruppenID);
end;

procedure TfrGruppenThumb.btnEditClick(Sender: TObject);
begin
  TfrmGruppenEdit.exec(FGruppenID, _UT_GRUPPE, FHolder, FHolder, FAlbum, @UpdateGruppe);
end;

procedure TfrGruppenThumb.btnVideoClick(Sender: TObject);
begin
//  TfrmSlider.Videos(Holder, User, FAlbumID,0,0, 0, 0, nil, _AllowAddImage);
end;

procedure TfrGruppenThumb.Loaded();
begin
  inherited;

//  TWebControl(self).ElementPosition := epIgnore;
//  el := TJSHTMLElement(TWebHTMLDiv(self).ElementHandle);
//  el.style.removeProperty('height');
//  el.style.removeProperty('width');
//  el.style.removeProperty('font-family');
//  el.style.removeProperty('font-style');
//  el.style.removeProperty('font-size');

  //init;
end;

procedure TfrGruppenThumb.LoadDFMValues;
begin
  inherited LoadDFMValues;

  p0 := THTMLDiv.Create(Self);
  img := TImageControl.Create(Self);
  lblHeader := THTMLDiv.Create(Self);
  lblText := THTMLDiv.Create(Self);
  WebHTMLDiv1 := THTMLDiv.Create(Self);
  WebHTMLDiv5 := THTMLDiv.Create(Self);
  lblFotos := THTMLDiv.Create(Self);
  WebHTMLDiv7 := THTMLDiv.Create(Self);
  WebHTMLDiv8 := THTMLDiv.Create(Self);
  lblVideos := THTMLDiv.Create(Self);
  WebHTMLDiv10 := THTMLDiv.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  lblDoks := THTMLDiv.Create(Self);
  WebHTMLDiv4 := THTMLDiv.Create(Self);
  divMenu := THTMLDiv.Create(Self);
  btnEdit := TImageControl.Create(Self);
  btnFoto := TImageControl.Create(Self);
  btnVideo := TImageControl.Create(Self);
  btnDelete := TImageControl.Create(Self);
  btnUserAdd := TImageControl.Create(Self);
  WebMessageDlg1 := TMessageDlg.Create(Self);
  Client := TXDataWebClient.Create(Self);
  XDataWebClient1 := TXDataWebClient.Create(Self);

  p0.BeforeLoadDFMValues;
  img.BeforeLoadDFMValues;
  lblHeader.BeforeLoadDFMValues;
  lblText.BeforeLoadDFMValues;
  WebHTMLDiv1.BeforeLoadDFMValues;
  WebHTMLDiv5.BeforeLoadDFMValues;
  lblFotos.BeforeLoadDFMValues;
  WebHTMLDiv7.BeforeLoadDFMValues;
  WebHTMLDiv8.BeforeLoadDFMValues;
  lblVideos.BeforeLoadDFMValues;
  WebHTMLDiv10.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  lblDoks.BeforeLoadDFMValues;
  WebHTMLDiv4.BeforeLoadDFMValues;
  divMenu.BeforeLoadDFMValues;
  btnEdit.BeforeLoadDFMValues;
  btnFoto.BeforeLoadDFMValues;
  btnVideo.BeforeLoadDFMValues;
  btnDelete.BeforeLoadDFMValues;
  btnUserAdd.BeforeLoadDFMValues;
  WebMessageDlg1.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  XDataWebClient1.BeforeLoadDFMValues;
  try
    Name := 'frGruppenThumb';
    Left := 0;
    Top := 0;
    Width := 447;
    Height := 531;
    TabOrder := 0;
    p0.SetParentComponent(Self);
    p0.Name := 'p0';
    p0.Left := 0;
    p0.Top := 7;
    p0.Width := 444;
    p0.Height := 521;
    p0.ElementClassName := 'alben_content';
    p0.HeightStyle := ssAuto;
    p0.WidthStyle := ssAuto;
    p0.ChildOrder := 1;
    p0.ElementPosition := epIgnore;
    p0.ElementFont := efCSS;
    p0.Role := '';
    img.SetParentComponent(p0);
    img.Name := 'img';
    img.Left := 3;
    img.Top := 3;
    img.Width := 244;
    img.Height := 134;
    img.ElementClassName := 'alben_foto';
    img.HeightStyle := ssAuto;
    img.WidthStyle := ssAuto;
    img.HeightPercent := 100.000000000000000000;
    img.WidthPercent := 100.000000000000000000;
    img.ElementFont := efCSS;
    img.ElementPosition := epIgnore;
    SetEvent(img, Self, 'OnClick', 'imgClick');
    lblHeader.SetParentComponent(p0);
    lblHeader.Name := 'lblHeader';
    lblHeader.Left := 3;
    lblHeader.Top := 143;
    lblHeader.Width := 249;
    lblHeader.Height := 50;
    lblHeader.ElementClassName := 'alben_content_header';
    lblHeader.HeightStyle := ssAuto;
    lblHeader.WidthStyle := ssAuto;
    lblHeader.ChildOrder := 3;
    lblHeader.ElementPosition := epIgnore;
    lblHeader.ElementFont := efCSS;
    lblHeader.HTML.BeginUpdate;
    try
      lblHeader.HTML.Clear;
      lblHeader.HTML.Add('Kroatien Sommer 2023');
    finally
      lblHeader.HTML.EndUpdate;
    end;
    lblHeader.Role := '';
    SetEvent(lblHeader, Self, 'OnClick', 'lblHeaderClick');
    lblText.SetParentComponent(p0);
    lblText.Name := 'lblText';
    lblText.Left := 3;
    lblText.Top := 199;
    lblText.Width := 247;
    lblText.Height := 82;
    lblText.ElementClassName := 'alben_content_text';
    lblText.HeightStyle := ssAuto;
    lblText.WidthStyle := ssAuto;
    lblText.ChildOrder := 3;
    lblText.ElementPosition := epIgnore;
    lblText.ElementFont := efCSS;
    lblText.HTML.BeginUpdate;
    try
      lblText.HTML.Clear;
      lblText.HTML.Add('Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam ');
      lblText.HTML.Add('voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. ');
      lblText.HTML.Add('Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam ');
      lblText.HTML.Add('voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.');
    finally
      lblText.HTML.EndUpdate;
    end;
    lblText.Role := '';
    WebHTMLDiv1.SetParentComponent(p0);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 3;
    WebHTMLDiv1.Top := 287;
    WebHTMLDiv1.Width := 398;
    WebHTMLDiv1.Height := 160;
    WebHTMLDiv1.ElementClassName := 'alben_statistik_container';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ChildOrder := 4;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv5.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv5.Name := 'WebHTMLDiv5';
    WebHTMLDiv5.Left := 3;
    WebHTMLDiv5.Top := 0;
    WebHTMLDiv5.Width := 105;
    WebHTMLDiv5.Height := 142;
    WebHTMLDiv5.ElementClassName := 'alben_statistik';
    WebHTMLDiv5.HeightStyle := ssAuto;
    WebHTMLDiv5.WidthStyle := ssAuto;
    WebHTMLDiv5.ChildOrder := 1;
    WebHTMLDiv5.ElementPosition := epIgnore;
    WebHTMLDiv5.ElementFont := efCSS;
    WebHTMLDiv5.Role := '';
    SetEvent(WebHTMLDiv5, Self, 'OnClick', 'btnFotoClick');
    lblFotos.SetParentComponent(WebHTMLDiv5);
    lblFotos.Name := 'lblFotos';
    lblFotos.Left := 2;
    lblFotos.Top := 26;
    lblFotos.Width := 100;
    lblFotos.Height := 41;
    lblFotos.ElementClassName := 'alben_statistik_text';
    lblFotos.HeightStyle := ssAuto;
    lblFotos.WidthStyle := ssAuto;
    lblFotos.ElementPosition := epIgnore;
    lblFotos.ElementFont := efCSS;
    lblFotos.HTML.BeginUpdate;
    try
      lblFotos.HTML.Clear;
      lblFotos.HTML.Add('25');
    finally
      lblFotos.HTML.EndUpdate;
    end;
    lblFotos.Role := '';
    WebHTMLDiv7.SetParentComponent(WebHTMLDiv5);
    WebHTMLDiv7.Name := 'WebHTMLDiv7';
    WebHTMLDiv7.Left := 3;
    WebHTMLDiv7.Top := 73;
    WebHTMLDiv7.Width := 100;
    WebHTMLDiv7.Height := 41;
    WebHTMLDiv7.ElementClassName := 'alben_statistik_header';
    WebHTMLDiv7.HeightStyle := ssAuto;
    WebHTMLDiv7.WidthStyle := ssAuto;
    WebHTMLDiv7.ChildOrder := 1;
    WebHTMLDiv7.ElementPosition := epIgnore;
    WebHTMLDiv7.ElementFont := efCSS;
    WebHTMLDiv7.HTML.BeginUpdate;
    try
      WebHTMLDiv7.HTML.Clear;
      WebHTMLDiv7.HTML.Add('Fotos');
    finally
      WebHTMLDiv7.HTML.EndUpdate;
    end;
    WebHTMLDiv7.Role := '';
    WebHTMLDiv8.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv8.Name := 'WebHTMLDiv8';
    WebHTMLDiv8.Left := 114;
    WebHTMLDiv8.Top := 3;
    WebHTMLDiv8.Width := 105;
    WebHTMLDiv8.Height := 142;
    WebHTMLDiv8.ElementClassName := 'alben_statistik';
    WebHTMLDiv8.HeightStyle := ssAuto;
    WebHTMLDiv8.WidthStyle := ssAuto;
    WebHTMLDiv8.ChildOrder := 1;
    WebHTMLDiv8.ElementPosition := epIgnore;
    WebHTMLDiv8.ElementFont := efCSS;
    WebHTMLDiv8.Role := '';
    SetEvent(WebHTMLDiv8, Self, 'OnClick', 'btnVideoClick');
    lblVideos.SetParentComponent(WebHTMLDiv8);
    lblVideos.Name := 'lblVideos';
    lblVideos.Left := 2;
    lblVideos.Top := 26;
    lblVideos.Width := 100;
    lblVideos.Height := 41;
    lblVideos.ElementClassName := 'alben_statistik_text';
    lblVideos.HeightStyle := ssAuto;
    lblVideos.WidthStyle := ssAuto;
    lblVideos.ElementPosition := epIgnore;
    lblVideos.ElementFont := efCSS;
    lblVideos.HTML.BeginUpdate;
    try
      lblVideos.HTML.Clear;
      lblVideos.HTML.Add('7');
    finally
      lblVideos.HTML.EndUpdate;
    end;
    lblVideos.Role := '';
    WebHTMLDiv10.SetParentComponent(WebHTMLDiv8);
    WebHTMLDiv10.Name := 'WebHTMLDiv10';
    WebHTMLDiv10.Left := 3;
    WebHTMLDiv10.Top := 73;
    WebHTMLDiv10.Width := 100;
    WebHTMLDiv10.Height := 41;
    WebHTMLDiv10.ElementClassName := 'alben_statistik_header';
    WebHTMLDiv10.HeightStyle := ssAuto;
    WebHTMLDiv10.WidthStyle := ssAuto;
    WebHTMLDiv10.ChildOrder := 1;
    WebHTMLDiv10.ElementPosition := epIgnore;
    WebHTMLDiv10.ElementFont := efCSS;
    WebHTMLDiv10.HTML.BeginUpdate;
    try
      WebHTMLDiv10.HTML.Clear;
      WebHTMLDiv10.HTML.Add('Videos');
    finally
      WebHTMLDiv10.HTML.EndUpdate;
    end;
    WebHTMLDiv10.Role := '';
    WebHTMLDiv2.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 234;
    WebHTMLDiv2.Top := 3;
    WebHTMLDiv2.Width := 105;
    WebHTMLDiv2.Height := 142;
    WebHTMLDiv2.ElementClassName := 'alben_statistik';
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ChildOrder := 1;
    WebHTMLDiv2.ElementPosition := epIgnore;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.Role := '';
    lblDoks.SetParentComponent(WebHTMLDiv2);
    lblDoks.Name := 'lblDoks';
    lblDoks.Left := 2;
    lblDoks.Top := 26;
    lblDoks.Width := 100;
    lblDoks.Height := 41;
    lblDoks.ElementClassName := 'alben_statistik_text';
    lblDoks.HeightStyle := ssAuto;
    lblDoks.WidthStyle := ssAuto;
    lblDoks.ElementPosition := epIgnore;
    lblDoks.ElementFont := efCSS;
    lblDoks.HTML.BeginUpdate;
    try
      lblDoks.HTML.Clear;
      lblDoks.HTML.Add('7');
    finally
      lblDoks.HTML.EndUpdate;
    end;
    lblDoks.Role := '';
    WebHTMLDiv4.SetParentComponent(WebHTMLDiv2);
    WebHTMLDiv4.Name := 'WebHTMLDiv4';
    WebHTMLDiv4.Left := 3;
    WebHTMLDiv4.Top := 73;
    WebHTMLDiv4.Width := 100;
    WebHTMLDiv4.Height := 41;
    WebHTMLDiv4.ElementClassName := 'alben_statistik_header';
    WebHTMLDiv4.HeightStyle := ssAuto;
    WebHTMLDiv4.WidthStyle := ssAuto;
    WebHTMLDiv4.ChildOrder := 1;
    WebHTMLDiv4.ElementPosition := epIgnore;
    WebHTMLDiv4.ElementFont := efCSS;
    WebHTMLDiv4.HTML.BeginUpdate;
    try
      WebHTMLDiv4.HTML.Clear;
      WebHTMLDiv4.HTML.Add('Dokumente');
    finally
      WebHTMLDiv4.HTML.EndUpdate;
    end;
    WebHTMLDiv4.Role := '';
    divMenu.SetParentComponent(p0);
    divMenu.Name := 'divMenu';
    divMenu.Left := 16;
    divMenu.Top := 464;
    divMenu.Width := 249;
    divMenu.Height := 57;
    divMenu.ElementClassName := 'alben_footer';
    divMenu.HeightStyle := ssAuto;
    divMenu.WidthStyle := ssAuto;
    divMenu.ChildOrder := 5;
    divMenu.ElementPosition := epIgnore;
    divMenu.ElementFont := efCSS;
    divMenu.Role := '';
    btnEdit.SetParentComponent(divMenu);
    btnEdit.Name := 'btnEdit';
    btnEdit.Left := 9;
    btnEdit.Top := 0;
    btnEdit.Width := 38;
    btnEdit.Height := 44;
    btnEdit.ElementClassName := 'blk_side_menu_btn';
    btnEdit.HeightStyle := ssAuto;
    btnEdit.WidthStyle := ssAuto;
    btnEdit.HeightPercent := 100.000000000000000000;
    btnEdit.WidthPercent := 100.000000000000000000;
    btnEdit.Anchors := [];
    btnEdit.ElementFont := efCSS;
    btnEdit.ElementPosition := epIgnore;
    SetEvent(btnEdit, Self, 'OnClick', 'btnEditClick');
    btnEdit.Picture.LoadFromFile('ufrGruppenThumb.divMenu.btnEdit.Picture.png');
    btnFoto.SetParentComponent(divMenu);
    btnFoto.Name := 'btnFoto';
    btnFoto.Left := 53;
    btnFoto.Top := 0;
    btnFoto.Width := 46;
    btnFoto.Height := 44;
    btnFoto.ElementClassName := 'blk_side_menu_btn';
    btnFoto.HeightStyle := ssAuto;
    btnFoto.WidthStyle := ssAuto;
    btnFoto.HeightPercent := 100.000000000000000000;
    btnFoto.WidthPercent := 100.000000000000000000;
    btnFoto.Anchors := [];
    btnFoto.ChildOrder := 1;
    btnFoto.ElementFont := efCSS;
    btnFoto.ElementPosition := epIgnore;
    SetEvent(btnFoto, Self, 'OnClick', 'btnFotoClick');
    btnFoto.Picture.LoadFromFile('ufrGruppenThumb.divMenu.btnFoto.Picture.png');
    btnVideo.SetParentComponent(divMenu);
    btnVideo.Name := 'btnVideo';
    btnVideo.Left := 105;
    btnVideo.Top := 3;
    btnVideo.Width := 46;
    btnVideo.Height := 44;
    btnVideo.ElementClassName := 'blk_side_menu_btn';
    btnVideo.HeightStyle := ssAuto;
    btnVideo.WidthStyle := ssAuto;
    btnVideo.HeightPercent := 100.000000000000000000;
    btnVideo.WidthPercent := 100.000000000000000000;
    btnVideo.Anchors := [];
    btnVideo.ChildOrder := 2;
    btnVideo.ElementFont := efCSS;
    btnVideo.ElementPosition := epIgnore;
    SetEvent(btnVideo, Self, 'OnClick', 'btnVideoClick');
    btnVideo.Picture.LoadFromFile('ufrGruppenThumb.divMenu.btnVideo.Picture.png');
    btnDelete.SetParentComponent(divMenu);
    btnDelete.Name := 'btnDelete';
    btnDelete.Left := 157;
    btnDelete.Top := 3;
    btnDelete.Width := 46;
    btnDelete.Height := 44;
    btnDelete.ElementClassName := 'blk_side_menu_btn';
    btnDelete.HeightStyle := ssAuto;
    btnDelete.WidthStyle := ssAuto;
    btnDelete.HeightPercent := 100.000000000000000000;
    btnDelete.WidthPercent := 100.000000000000000000;
    btnDelete.Anchors := [];
    btnDelete.ChildOrder := 2;
    btnDelete.ElementFont := efCSS;
    btnDelete.ElementPosition := epIgnore;
    SetEvent(btnDelete, Self, 'OnClick', 'btnDeleteClick');
    btnDelete.Picture.LoadFromFile('ufrGruppenThumb.divMenu.btnDelete.Picture.png');
    btnUserAdd.SetParentComponent(divMenu);
    btnUserAdd.Name := 'btnUserAdd';
    btnUserAdd.Left := 209;
    btnUserAdd.Top := 3;
    btnUserAdd.Width := 46;
    btnUserAdd.Height := 44;
    btnUserAdd.ElementClassName := 'blk_side_menu_btn';
    btnUserAdd.HeightStyle := ssAuto;
    btnUserAdd.WidthStyle := ssAuto;
    btnUserAdd.HeightPercent := 100.000000000000000000;
    btnUserAdd.WidthPercent := 100.000000000000000000;
    btnUserAdd.Anchors := [];
    btnUserAdd.ChildOrder := 2;
    btnUserAdd.ElementFont := efCSS;
    btnUserAdd.ElementPosition := epIgnore;
    SetEvent(btnUserAdd, Self, 'OnClick', 'btnUserAddClick');
    btnUserAdd.Picture.LoadFromFile('ufrGruppenThumb.divMenu.btnUserAdd.Picture.png');
    WebMessageDlg1.SetParentComponent(p0);
    WebMessageDlg1.Name := 'WebMessageDlg1';
    WebMessageDlg1.Left := 304;
    WebMessageDlg1.Top := 152;
    WebMessageDlg1.Width := 24;
    WebMessageDlg1.Height := 24;
    WebMessageDlg1.HeightStyle := ssAuto;
    WebMessageDlg1.WidthStyle := ssAuto;
    WebMessageDlg1.Buttons := [mbYes,mbNo];
    WebMessageDlg1.Opacity := 0.200000000000000000;
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 83;
    Client.Top := 215;
    XDataWebClient1.SetParentComponent(Self);
    XDataWebClient1.Name := 'XDataWebClient1';
    XDataWebClient1.Left := 99;
    XDataWebClient1.Top := 167;
  finally
    p0.AfterLoadDFMValues;
    img.AfterLoadDFMValues;
    lblHeader.AfterLoadDFMValues;
    lblText.AfterLoadDFMValues;
    WebHTMLDiv1.AfterLoadDFMValues;
    WebHTMLDiv5.AfterLoadDFMValues;
    lblFotos.AfterLoadDFMValues;
    WebHTMLDiv7.AfterLoadDFMValues;
    WebHTMLDiv8.AfterLoadDFMValues;
    lblVideos.AfterLoadDFMValues;
    WebHTMLDiv10.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    lblDoks.AfterLoadDFMValues;
    WebHTMLDiv4.AfterLoadDFMValues;
    divMenu.AfterLoadDFMValues;
    btnEdit.AfterLoadDFMValues;
    btnFoto.AfterLoadDFMValues;
    btnVideo.AfterLoadDFMValues;
    btnDelete.AfterLoadDFMValues;
    btnUserAdd.AfterLoadDFMValues;
    WebMessageDlg1.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
    XDataWebClient1.AfterLoadDFMValues;
  end;
end;

end.
