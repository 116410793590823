unit uKurs;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ComCtrls, WEBLib.ExtCtrls,
  Vcl.StdCtrls, WEBLib.StdCtrls, Data.DB, XData.Web.JsonDataset, uTB,
  XData.Web.Dataset, XData.Web.Client, Vcl.Imaging.jpeg, WEBLib.WebCtrls,
  uKursKapitel1;

type
  TfrmKurs = class(TForm)
    divContent: THTMLDiv;
    lblTitel: THTMLDiv;
    imgTitel: TImageControl;
    lblText: THTMLDiv;
    Client: TXDataWebClient;
    [async]
    procedure btnKursInhaltClick(Sender: TObject); async;
    procedure WebFormCreate(Sender: TObject);

  private
    FArt,
    FHolder,
    FID:integer;
    FContainer:TForm;
    FKursInhalt: TfrmKursKapitel1;
    FbtnPrior:TButton;

    [async] procedure KursInhalt(AResponse: TXDataClientResponse; APfad: array of string); async;
  public
    [async] procedure refresh; async;
    property Container:TForm read FContainer write FContainer;
    [async] procedure Init(AID:integer; APfad: array of string); async;
  protected procedure LoadDFMValues; override; end;

var
  frmKurs: TfrmKurs;

implementation
  Uses uMain, uME, ufrKurs, uKursText, uDatenmodul;

{$R *.dfm}

procedure TfrmKurs.refresh;
Var
  Response: TXDataClientResponse;
  i,k:integer;
  j: TJ;
begin
  j := TJ.create(Await( Client.RawInvokeAsync('IDBService.KurseGet',[ FID, FHolder,100, FArt, Mainform.ME.Trainer])));

  for k := 0 to j.length-1 do
  begin

    j.index := k;

    for i := 0 to self.ComponentCount -1  do
    begin
      if self.components[i] is TfrKurs
      then begin
        if TfrKurs(self.components[i]).ID = J.Integer('ID')
        then begin
          TfrKurs(self.components[i]).imgDone.visible    := (j.integer('DONE') >= J.integer('SUB'));
          TfrKurs(self.components[i]).lblTitel.Html.Text := J.Value('HEADER') + '  (' + J.Value('DONE') + ' / ' + J.Value('SUB') + ')';
        end;
      end;

    end;

  end;

end;

procedure TfrmKurs.Init(AID:integer; APfad: array of string);
Var
  Response: TXDataClientResponse;
  j:TJ;
  i:integer;
  s:string;
begin
  FID := AID;
  FHolder := Mainform.ME.ID;
  lblTitel.HTML.Text := '';
  mainform.Wait := 1;
  j := TJ.create(await(datenmodul.EasySQL('SELECT HEADER, TEXT, MEDIEN_ID, ART From Kurse Where ID = ' + AId.ToString)));
  FArt := j.Integer('ART');

  TfrmME(FContainer).BreadCrumb.SetCaption(self, lblTitel, _KURS, J.Value('HEADER') );
  FBtnPrior := TfrmME(FContainer).BreadCrumb.GetButton(_KURS);
  lblText.Html.Text  := J.Value('TEXT');
  imgTitel.URL := await(datenmodul.GetMedia(J.Integer('MEDIEN_ID'),1));
  imgTitel.Visible := (imgTitel.URL > '');
  Response := Await( Client.RawInvokeAsync('IDBService.KurseGet',[ FID, FHolder,100, FArt, Mainform.ME.Trainer]));
  await(KursInhalt(Response, APfad));
  mainform.Wait := -1;
end;

procedure TfrmKurs.KursInhalt(AResponse: TXDataClientResponse; APfad: array of string);
Var
  j:TJ;
  FOpen, f: TfrKurs;
  iStatus, i:integer;
  el: TJSHTMLElement;
begin
  J := TJ.create(AResponse);

  for i := 0 to j.Length -1 do
  begin
    j.Index := i;

    f := TfrKurs.Create(divContent.Owner);
    f.LoadFromForm;
    f.Parent := divContent;

    f.ID := J.Integer('ID');
    f.VOR:= J.Integer('VOR');

    f.imgLock.visible    := await(KursStatus(f.ID, Mainform.ME.Rechte.ID, Mainform.ME.id)) = 0;
    f.imgDone.visible    := (j.integer('DONE') >= J.integer('SUB'));
    f.lblTitel.Html.Text := J.Value('HEADER') + '  (' + J.Value('DONE') + ' / ' + J.Value('SUB') + ')';

    f.lblTitel.OnClick   := btnKursInhaltClick;
    TControl(f).ElementID       := 'kurs' + J.Value('ID');
    TControl(f).elementPosition := epIgnore;
    TControl(f).elementClassName:= 'kursliste_kapitel';

    el := TJSHTMLElement(THTMLDiv(f).ElementHandle);
    el.style.removeProperty('height');
    el.style.removeProperty('width');

    if (length(APfad) > 0) and (f.ID = StrToInt(APfad[length(APfad)-1]))
    then begin
       FOpen := f;
       SetLength(APfad, length(APfad)-1);
       FOpen.Pfad := APfad;
    end;

  end;

  if assigned(FOpen)
  then begin
    btnKursInhaltClick(fOpen.lblTitel);
  end;

end;

procedure TfrmKurs.btnKursInhaltClick(Sender: TObject);

  procedure AfterCreate1(AForm: TObject);
  begin
    TfrmKursKapitel1(AForm).Container := FContainer;
    TfrmKursKapitel1(AForm).init(TfrKurs(TControl(sender).owner).ID,
                                 TfrKurs(TControl(sender).owner).VOR,
                                 _KURSKAPITEL1,
                                 TfrKurs(TControl(sender).owner).Pfad );
  end;

  procedure AfterCreate2(AForm: TObject);
  begin
    TfrmKursText(AForm).Container := FContainer;
    TfrmKursText(AForm).init(TfrKurs(TControl(sender).owner).ID,
                             TfrKurs(TControl(sender).owner).VOR,
                             FBtnPrior, true);
  end;

var
  j:TJ;
  s:string;
  o:THtmlDiv;
  f:TForm;
begin
  s := TfrKurs(TControl(sender).owner).ID.tostring;
  J := TJ.create(await(Datenmodul.EasySQL('CALL P_kurse_count(0,' + s + ',0)')));

  if j.integer('ANZ') > 0 //gibt es weitere Unterteilungen...
  then begin
    if assigned(TfrmME(FContainer).KursKapitel1)
    then begin
      f := TfrmME(FContainer).KursKapitel1;
      freeAndNil(f);
    end;

    o := TfrmME(FContainer).BreadCrumb.DivToFront( _KURSKAPITEL1);

    TfrmME(FContainer).KursKapitel1 := TfrmKursKapitel1.CreateNew(o.ElementID, @AfterCreate1);

  end
  else begin //... wenn nicht, sofort Text anzeigen
    if assigned(TfrmME(FContainer).KursText)
    then begin
      f := TfrmME(FContainer).KursText;
      freeAndNil(f);
    end;
    o := TfrmME(FContainer).BreadCrumb.DivToFront( _KURSTEXT);
    TfrmME(FContainer).KursText := TfrmKursText.CreateNew(o.ElementID, @AfterCreate2);
  end;

end;

procedure TfrmKurs.WebFormCreate(Sender: TObject);
var
  el: TJSHTMLElement;
begin
  inherited;
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.removeProperty('height');
end;


procedure TfrmKurs.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divContent := THTMLDiv.Create(Self);
  imgTitel := TImageControl.Create(Self);
  lblTitel := THTMLDiv.Create(Self);
  lblText := THTMLDiv.Create(Self);
  Client := TXDataWebClient.Create(Self);

  divContent.BeforeLoadDFMValues;
  imgTitel.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  lblText.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frmKurs';
    Width := 824;
    Height := 402;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    divContent.SetParentComponent(Self);
    divContent.Name := 'divContent';
    divContent.Left := 8;
    divContent.Top := 8;
    divContent.Width := 797;
    divContent.Height := 385;
    divContent.ElementClassName := 'kursliste_content_wrapper';
    divContent.HeightStyle := ssAuto;
    divContent.WidthStyle := ssAuto;
    divContent.ChildOrder := 1;
    divContent.ElementPosition := epIgnore;
    divContent.ElementFont := efCSS;
    divContent.Role := '';
    imgTitel.SetParentComponent(divContent);
    imgTitel.Name := 'imgTitel';
    imgTitel.Left := 20;
    imgTitel.Top := 38;
    imgTitel.Width := 244;
    imgTitel.Height := 134;
    imgTitel.ElementClassName := 'kursliste_foto';
    imgTitel.HeightStyle := ssAuto;
    imgTitel.WidthStyle := ssAuto;
    imgTitel.HeightPercent := 100.000000000000000000;
    imgTitel.WidthPercent := 100.000000000000000000;
    imgTitel.ChildOrder := 1;
    imgTitel.ElementFont := efCSS;
    imgTitel.ElementPosition := epIgnore;
    lblTitel.SetParentComponent(divContent);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 0;
    lblTitel.Top := 2;
    lblTitel.Width := 774;
    lblTitel.Height := 30;
    lblTitel.ElementClassName := 'me_headline';
    lblTitel.HeightStyle := ssAuto;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.ElementPosition := epIgnore;
    lblTitel.ElementFont := efCSS;
    lblTitel.HTML.BeginUpdate;
    try
      lblTitel.HTML.Clear;
      lblTitel.HTML.Add(#220'berschrift');
    finally
      lblTitel.HTML.EndUpdate;
    end;
    lblTitel.Role := '';
    lblText.SetParentComponent(divContent);
    lblText.Name := 'lblText';
    lblText.Left := 20;
    lblText.Top := 187;
    lblText.Width := 774;
    lblText.Height := 30;
    lblText.ElementClassName := 'kursliste_text';
    lblText.HeightStyle := ssAuto;
    lblText.WidthStyle := ssAuto;
    lblText.ChildOrder := 2;
    lblText.ElementPosition := epIgnore;
    lblText.ElementFont := efCSS;
    lblText.HTML.BeginUpdate;
    try
      lblText.HTML.Clear;
      lblText.HTML.Add('Text');
    finally
      lblText.HTML.EndUpdate;
    end;
    lblText.Role := '';
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 32;
    Client.Top := 260;
  finally
    divContent.AfterLoadDFMValues;
    imgTitel.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    lblText.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.
