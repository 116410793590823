unit uModal;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.WebCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls;

type
  TfrmModal = class(TForm)
    WebHTMLDiv1: THTMLDiv;
    WebHTMLDiv7: THTMLDiv;
    WebHTMLDiv4: THTMLDiv;
    btnSave: TButton;
    btnCancel: TButton;
  private
    { Private-Deklarationen }
  public
    [async] class function execute(ACaption, AText:string):string; async;
  protected procedure LoadDFMValues; override; end;

var
  frmModal: TfrmModal;

implementation

{$R *.dfm}

class function TfrmModal.execute(ACaption, AText:string):string;
var
  newform: TfrmModal;
begin

  newform := TfrmModal.Create(application.MainForm);
  newform.Caption := ACaption;
  newform.Popup := true;

  newform.Border := fbDialogSizeable;

  window.location.hash := ACaption;

  await(TfrmModal, newform.Load());
//  newform.edEingabe.Text := AText;

  try

    await(TModalResult, newform.Execute);
//    result := TfrmModal(newform).edEingabe.Text;
  finally
    newform.Free;
  end;

end;

procedure TfrmModal.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  WebHTMLDiv7 := THTMLDiv.Create(Self);
  WebHTMLDiv4 := THTMLDiv.Create(Self);
  btnSave := TButton.Create(Self);
  btnCancel := TButton.Create(Self);

  WebHTMLDiv1.BeforeLoadDFMValues;
  WebHTMLDiv7.BeforeLoadDFMValues;
  WebHTMLDiv4.BeforeLoadDFMValues;
  btnSave.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  try
    Name := 'frmModal';
    Width := 640;
    Height := 480;
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 8;
    WebHTMLDiv1.Top := 8;
    WebHTMLDiv1.Width := 609;
    WebHTMLDiv1.Height := 457;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv7.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv7.Name := 'WebHTMLDiv7';
    WebHTMLDiv7.Left := 3;
    WebHTMLDiv7.Top := 404;
    WebHTMLDiv7.Width := 591;
    WebHTMLDiv7.Height := 50;
    WebHTMLDiv7.ElementClassName := 'albumedit_footer';
    WebHTMLDiv7.HeightStyle := ssAuto;
    WebHTMLDiv7.WidthStyle := ssAuto;
    WebHTMLDiv7.ChildOrder := 6;
    WebHTMLDiv7.ElementPosition := epRelative;
    WebHTMLDiv7.ElementFont := efCSS;
    WebHTMLDiv7.Role := '';
    WebHTMLDiv4.SetParentComponent(WebHTMLDiv7);
    WebHTMLDiv4.Name := 'WebHTMLDiv4';
    WebHTMLDiv4.Left := 243;
    WebHTMLDiv4.Top := 3;
    WebHTMLDiv4.Width := 334;
    WebHTMLDiv4.Height := 41;
    WebHTMLDiv4.ElementClassName := 'input_btn_container';
    WebHTMLDiv4.HeightStyle := ssAuto;
    WebHTMLDiv4.WidthStyle := ssAuto;
    WebHTMLDiv4.ChildOrder := 1;
    WebHTMLDiv4.ElementPosition := epIgnore;
    WebHTMLDiv4.ElementFont := efCSS;
    WebHTMLDiv4.Role := '';
    btnSave.SetParentComponent(WebHTMLDiv4);
    btnSave.Name := 'btnSave';
    btnSave.Left := 9;
    btnSave.Top := 3;
    btnSave.Width := 144;
    btnSave.Height := 25;
    btnSave.Caption := 'Speichern';
    btnSave.ChildOrder := 1;
    btnSave.ElementClassName := 'editor_button';
    btnSave.ElementFont := efCSS;
    btnSave.ElementPosition := epIgnore;
    btnSave.HeightStyle := ssAuto;
    btnSave.HeightPercent := 100.000000000000000000;
    btnSave.WidthStyle := ssAuto;
    btnSave.WidthPercent := 100.000000000000000000;
    btnCancel.SetParentComponent(WebHTMLDiv4);
    btnCancel.Name := 'btnCancel';
    btnCancel.Left := 159;
    btnCancel.Top := 3;
    btnCancel.Width := 144;
    btnCancel.Height := 25;
    btnCancel.Caption := 'Verwerfen';
    btnCancel.ChildOrder := 2;
    btnCancel.ElementClassName := 'editor_button';
    btnCancel.ElementFont := efCSS;
    btnCancel.ElementPosition := epIgnore;
    btnCancel.HeightStyle := ssAuto;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthStyle := ssAuto;
    btnCancel.WidthPercent := 100.000000000000000000;
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    WebHTMLDiv7.AfterLoadDFMValues;
    WebHTMLDiv4.AfterLoadDFMValues;
    btnSave.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
  end;
end;

end.