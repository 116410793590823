unit uKursKapitel1;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ComCtrls, WEBLib.ExtCtrls,
  Vcl.StdCtrls, WEBLib.StdCtrls, Data.DB, XData.Web.JsonDataset, uTB,
  XData.Web.Dataset, XData.Web.Client, Vcl.Imaging.jpeg, WEBLib.WebCtrls,
  Vcl.Forms, Vcl.Imaging.pngimage, WEBLib.JSON, uMain;

type
  TfrmKursKapitel1 = class(TForm)
    divContent: THTMLDiv;
    lblTitel: THTMLDiv;
    imgTitel: TImageControl;
    lblText: THTMLDiv;
    Client: TXDataWebClient;
    [async] procedure btnKursTextClick(Sender: TObject); async;
    procedure WebFormCreate(Sender: TObject);
  private
    FbtnPrior:TButton;
    FPfad:array of string;
    FHolder,
    FArt, FID, FVor, FKapitel, FStatus:integer;
    FContainer:TForm;
    [async] procedure KursInhalt(AResponse: TXDataClientResponse; APfad: array of string); async;
  published
    Pfad: array of string;
  public
    property Status:integer read FStatus write FStatus;
    property Vor:integer read FVor write FVor;
    property Kapitel:integer read FKapitel write FKapitel;
    property Container:TForm read FContainer write FContainer;
    [async] procedure Init(AID, aVor, AKapitel:integer; APfad: array of string); async;
    [async] procedure refresh; async;
  protected procedure LoadDFMValues; override; end;

var
  frmKursKapitel1: TfrmKursKapitel1;

implementation
  Uses uMe, ufrKurs, uKursText, uDatenmodul;
{$R *.dfm}

procedure TfrmKursKapitel1.refresh;
Var
  Response: TXDataClientResponse;
  i,k:integer;
  j: TJ;
begin
  j := TJ.create(Await( Client.RawInvokeAsync('IDBService.KurseGet',[ FID, FHolder,100, FArt, Mainform.ME.Trainer])));

  for k := 0 to j.length-1 do
  begin

    j.index := k;

    for i := 0 to self.ComponentCount -1  do
    begin
      if self.components[i] is TfrKurs
      then begin
        if TfrKurs(self.components[i]).ID = J.Integer('ID')
        then begin
          TfrKurs(self.components[i]).imgDone.visible    := (j.integer('DONE') >= J.integer('SUB'));
          TfrKurs(self.components[i]).lblTitel.Html.Text := J.Value('HEADER') + ' (' + J.Value('DONE') + ' / ' + J.Value('SUB') + ')';
        end;
      end;

    end;

  end;

end;

procedure TfrmKursKapitel1.Init(AID, AVor, AKapitel:integer; APfad: array of string);
Var
  Response: TXDataClientResponse;
  j:TJ;
  i:integer;
  s:string;
  btn:TButton;
begin
  mainform.Wait := 1;

  FHolder := Mainform.ME.ID;
  FVor    := AVor;
  FKapitel:= AKapitel;
  FPfad   := APfad;
  FID     := AID;

  j := TJ.create(await(datenmodul.EasySQL('SELECT HEADER, TEXT, MEDIEN_ID, ART From Kurse Where ID = ' + AId.ToString)));
  FArt := j.Integer('ART');

  lblTitel.Html.Text := '';
  TfrmME(FContainer).BreadCrumb.SetCaption(self, lblTitel, AKapitel, J.Value('HEADER') );
  FBtnPrior := TfrmME(FContainer).BreadCrumb.GetButton(AKapitel);

  imgTitel.URL := await(datenmodul.GetMedia(J.Integer('MEDIEN_ID'),2));
  lblText.HTML.Text := j.Value('TEXT');

  Response := Await( Client.RawInvokeAsync('IDBService.KurseGet',[ AID, FHolder,100, j.Integer('ART'), Mainform.ME.Trainer]));

  await(KursInhalt(Response, APfad));
  mainform.Wait := -1;

end;

procedure TfrmKursKapitel1.KursInhalt(AResponse: TXDataClientResponse; APfad: array of string);
Var
  j,Done:TJ;
  fOpen, f: TfrKurs;
  iStatus, i:integer;
  el: TJSHTMLElement;
  sKurse:string;
begin
  J := TJ.create(AResponse);
  Done := TJ.create;

  for i := 0 to j.Length -1 do
  begin
    j.Index := i;

    f := TfrKurs.Create(divContent.Owner);
    f.LoadFromForm;
    f.Parent := divContent;

    f.ID    := J.Integer('ID');
    f.Vor   := J.Integer('VOR');
    f.Status:= J.Integer('STATUS');

    f.imgLock.visible := (await(KursStatus(f.ID, Mainform.ME.Rechte.ID, Mainform.ME.id)) = 0);
    f.imgDone.visible := (j.integer('DONE')> 0) and (j.integer('DONE') >= J.integer('SUB'));

    f.lblTitel.Html.Text := J.Value('HEADER') + ' (' + J.Value('DONE') + ' / ' + J.Value('SUB') + ')';
    f.lblTitel.OnClick := btnKursTextClick;

    TControl(f).ElementID := 'kursinhalt' + J.Value('ID');
    TControl(f).elementPosition := epIgnore;
    TControl(f).elementClassName:= 'kursinhalt_kapitel';

    el := TJSHTMLElement(THTMLDiv(f).ElementHandle);
    el.style.removeProperty('height');
    el.style.removeProperty('width');

    if (length(APfad) > 0) and (f.ID = StrToInt(APfad[length(APfad)-1]))
    then begin
       FOpen := f;

       if length(APfad) > 1
       then SetLength(APfad, length(APfad)-1);

       FOpen.Pfad := APfad;
    end;

  end;

  if assigned(FOpen)
  then begin
    btnKursTextClick(fOpen.lblTitel);
  end;

end;

procedure TfrmKursKapitel1.btnKursTextClick(Sender: TObject);

  procedure AfterCreate1(AForm: TObject);
  begin
    TfrmKursKapitel1(AForm).Container := FContainer;

    TfrmKursKapitel1(AForm).init(TfrKurs(TControl(sender).owner).ID,
                                 TfrKurs(TControl(sender).owner).Vor, FKapitel + 1,
                                 TfrKurs(TControl(sender).owner).Pfad);
  end;

  procedure AfterCreate2(AForm: TObject);
  begin
    TfrmKursText(AForm).Container := FContainer;

    TfrmKursText(AForm).init(TfrKurs(TControl(sender).owner).ID,
                             TfrKurs(TControl(sender).owner).Vor, FBtnPrior);
  end;

Var
  o:THTMLDiv;
  f:Tform;
  s:string;
  j:TJ;
begin
  s := TfrKurs(TControl(sender).owner).ID.tostring;
  J := TJ.create(await(Datenmodul.EasySQL('CALL P_kurse_count(0,' + s + ',0)')));

  if j.integer('ANZ') > 0 //gibt es weitere Unterteilungen...
  then begin

    case FKapitel of
      _KURSKAPITEL1:
        begin
          if assigned(TfrmME(FContainer).KursKapitel2)
          then begin
            f := TfrmME(FContainer).KursKapitel2;
            freeAndNil(f);
          end;

          o := TfrmME(FContainer).BreadCrumb.DivToFront( FKapitel + 1);

          TfrmME(FContainer).KursKapitel2 := TfrmKursKapitel1.CreateNew(o.ElementID, @AfterCreate1);
        end;

      _KURSKAPITEL2:
        begin
          if assigned(TfrmME(FContainer).KursKapitel3)
          then begin
            f := TfrmME(FContainer).KursKapitel3;
            freeAndNil(f);
          end;

          o := TfrmME(FContainer).BreadCrumb.DivToFront( FKapitel + 1);

          TfrmME(FContainer).KursKapitel3 := TfrmKursKapitel1.CreateNew(o.ElementID, @AfterCreate1);
        end;
    end;

  end
  else begin //... wenn nicht, Text anzeigen

    f := TfrmME(FContainer).KursText;

    if assigned(f)
    then freeandnil(f);

    o := TfrmME(FContainer).BreadCrumb.DivToFront( _KURSTEXT);

    TfrmME(FContainer).KursText := TfrmKursText.CreateNew(o.ElementID, @AfterCreate2);

  end;
end;

procedure TfrmKursKapitel1.WebFormCreate(Sender: TObject);
var
  el: TJSHTMLElement;
begin
  inherited;
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.removeProperty('height');
end;

procedure TfrmKursKapitel1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divContent := THTMLDiv.Create(Self);
  imgTitel := TImageControl.Create(Self);
  lblTitel := THTMLDiv.Create(Self);
  lblText := THTMLDiv.Create(Self);
  Client := TXDataWebClient.Create(Self);

  divContent.BeforeLoadDFMValues;
  imgTitel.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  lblText.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frmKursKapitel1';
    Width := 827;
    Height := 424;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    divContent.SetParentComponent(Self);
    divContent.Name := 'divContent';
    divContent.Left := 8;
    divContent.Top := 8;
    divContent.Width := 797;
    divContent.Height := 385;
    divContent.ElementClassName := 'kursinhalt_content_wrapper';
    divContent.HeightStyle := ssAuto;
    divContent.WidthStyle := ssAuto;
    divContent.ChildOrder := 1;
    divContent.ElementPosition := epIgnore;
    divContent.ElementFont := efCSS;
    divContent.Role := '';
    imgTitel.SetParentComponent(divContent);
    imgTitel.Name := 'imgTitel';
    imgTitel.Left := 3;
    imgTitel.Top := 38;
    imgTitel.Width := 244;
    imgTitel.Height := 134;
    imgTitel.ElementClassName := 'kursinhalt_foto';
    imgTitel.HeightStyle := ssAuto;
    imgTitel.WidthStyle := ssAuto;
    imgTitel.HeightPercent := 100.000000000000000000;
    imgTitel.WidthPercent := 100.000000000000000000;
    imgTitel.ChildOrder := 1;
    imgTitel.ElementFont := efCSS;
    imgTitel.ElementPosition := epIgnore;
    lblTitel.SetParentComponent(divContent);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 2;
    lblTitel.Top := 2;
    lblTitel.Width := 774;
    lblTitel.Height := 30;
    lblTitel.ElementClassName := 'me_headline';
    lblTitel.HeightStyle := ssAuto;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.ElementPosition := epIgnore;
    lblTitel.ElementFont := efCSS;
    lblTitel.HTML.BeginUpdate;
    try
      lblTitel.HTML.Clear;
      lblTitel.HTML.Add(#220'berschrift');
    finally
      lblTitel.HTML.EndUpdate;
    end;
    lblTitel.Role := '';
    lblText.SetParentComponent(divContent);
    lblText.Name := 'lblText';
    lblText.Left := 2;
    lblText.Top := 195;
    lblText.Width := 774;
    lblText.Height := 30;
    lblText.ElementClassName := 'kursinhalt_text';
    lblText.HeightStyle := ssAuto;
    lblText.WidthStyle := ssAuto;
    lblText.ChildOrder := 2;
    lblText.ElementPosition := epIgnore;
    lblText.ElementFont := efCSS;
    lblText.HTML.BeginUpdate;
    try
      lblText.HTML.Clear;
      lblText.HTML.Add('Text');
    finally
      lblText.HTML.EndUpdate;
    end;
    lblText.Role := '';
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 32;
    Client.Top := 260;
  finally
    divContent.AfterLoadDFMValues;
    imgTitel.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    lblText.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.
