{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2020                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCCheckBox;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$ENDIF}

interface

uses
  Classes, 
  {$IFNDEF LCLLIB}
  Types,
  {$ENDIF}
  {$IFDEF WEBLIB}
  WEBLib.Controls,
  {$ENDIF}
  WEBLib.TMSFNCHTMLImageContainer, WEBLib.TMSFNCTypes, WEBLib.TMSFNCGraphics,
  WEBLib.TMSFNCUtils;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 0; // Release nr.
  BLD_VER = 1; // Build nr.

  //Version history
  //v1.0.0.0 : First Release
  //v1.0.0.1 : Fixed : Checkbox value does not change when you click on anchor text

type
  TTMSFNCCustomCheckBox = class(TTMSFNCHTMLImageContainer)
  private
    FStartSize: Integer;
    FMouseHover: Boolean;
  protected
    procedure DoDrawWidget({%H-}AGraphics: TTMSFNCGraphics; {%H-}ARect: TRectF); override;
    procedure HandleMouseUp({%H-}Button: TTMSFNCMouseButton; {%H-}Shift: TShiftState; {%H-}X, {%H-}Y: Single); override;
    procedure HandleMouseLeave; override;
    procedure HandleMouseEnter; override;
    procedure HandleKeyDown(var {%H-}Key: Word; {%H-}Shift: TShiftState); override;
    function GetVersion: string; override;
  public
    constructor Create(AOwner: TComponent); override;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCCheckBox = class(TTMSFNCCustomCheckBox)
  published
    property BitmapContainer;
    property BitmapName;
    property Checked;
    property WidgetPosition;
    property WordWrapping;
    property Trimming;
    property Text;
    property Font;
    property HorizontalTextAlign;
    property VerticalTextAlign;
    property OnChange;
    property OnCheckedStateChanged;
    property OnAnchorClick;
    property OnBeforeDrawWidget;
    property OnAfterDrawWidget;
    property OnBeforeDrawText;
    property OnAfterDrawText;
  end;

implementation

{ TTMSFNCCustomCheckBox }

constructor TTMSFNCCustomCheckBox.Create(AOwner: TComponent);
var
  s: Single;
begin
  inherited;
  FMouseHover := False;
  Text := 'TMSFNCCheckBox';
  s := TTMSFNCUtils.GetDPIScale(Self, 96);
  if (s = 1) then
    FStartSize := 15
  else
    FStartSize := 13;
end;

procedure TTMSFNCCustomCheckBox.DoDrawWidget(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
var
  {$IFNDEF LCLWEBLIB}
  bmp: TTMSFNCBitmapHelperClass;
  {$ENDIF}
  b: Boolean;
  r: TRectF;
  {$IFDEF VCLLIB}
  s: Integer;
  {$ENDIF}
begin
  inherited;
  if not Assigned(WidgetImage) then
  begin
    b := IsFocused or FMouseHover;
    {$IFNDEF LCLWEBLIB}
    {$IFDEF VCLLIB}
    s := ScalePaintValue(FStartSize);
    r := RectF(ARect.Left + (ARect.Right - ARect.Left - s) / 2, ARect.Top + (ARect.Bottom - ARect.Top - s) / 2, ARect.Left + (ARect.Right - ARect.Left - s) / 2 + s, ARect.Top + (ARect.Bottom - ARect.Top - s) / 2 + s);
    {$ELSE}
    r := ARect;
    {$ENDIF}
    bmp := GetCheckBoxBitmap(Checked, b, Enabled);
    AGraphics.DrawBitmap(r, bmp);
    {$ENDIF}
    {$IFDEF LCLWEBLIB}
    r := GetWidgetRectangle(ARect);
    AGraphics.DrawCheckBox(r, Checked, b, Enabled);
    {$ENDIF}
  end;
end;

function TTMSFNCCustomCheckBox.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCCustomCheckBox.HandleKeyDown(var Key: Word;
  Shift: TShiftState);
begin
  inherited;
  if (Key = KEY_SPACE) or (Key = KEY_RETURN) then
    Checked := not Checked;
end;

procedure TTMSFNCCustomCheckBox.HandleMouseEnter;
begin
  inherited;
  if Enabled then
  begin
    FMouseHover := True;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomCheckBox.HandleMouseLeave;
begin
  inherited;
  if Enabled then
  begin
    FMouseHover := False;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomCheckBox.HandleMouseUp(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
begin
  inherited;

  if Enabled and (AnchorText = '') then
    Checked := not Checked;
end;

end.
