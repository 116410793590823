unit ufrBlog;

interface

uses
  WEBLib.ExtCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Controls, WEBLib.Controls, System.Classes,
  WEBLib.WebCtrls,Vcl.Forms, Vcl.Imaging.jpeg, Vcl.Imaging.pngimage,
  XData.Web.Client, JS,web, WEBLib.Dialogs, System.SysUtils,
  WEBLib.Graphics, WEBLib.Forms, utb, ufrFotosListe, ufrVideoListe,
  WEBLib.Buttons;

type
  TfrBlog = class(TFrame)
    divContainer: THTMLDiv;
    lblTitel: THTMLDiv;
    lblText: THTMLDiv;
    WebHTMLDiv1: THTMLDiv;
    imgAvatar: TImageControl;
    lblName: TLabel;
    lblDatum: TLabel;
    divPinned: THTMLDiv;
    imgPinned: TImageControl;
    WebHTMLDiv2: THTMLDiv;
    btnEdit: TImageControl;
    btnPin: TImageControl;
    btnFoto: TImageControl;
    btnVideo: TImageControl;
    btnDelete: TImageControl;
    divLikeWrapper: THTMLDiv;
    btnLike: TImageControl;
    divLikeCount: THTMLDiv;
    divlikeUser: THTMLDiv;
    divComment: THTMLDiv;
    btnNew: TImageControl;
    imgTitel: TImageControl;
    btnComment: TImageControl;
    lblHeader: THTMLDiv;
    lblMainHeader: THTMLDiv;
    divImg: THTMLDiv;
    btnBack: TSpeedButton;
    btnForward: TSpeedButton;
    lblViews: TLabel;
    procedure lblNameClick(Sender: TObject);
    [async] procedure btnDeleteClick(Sender: TObject); async;
    [async] procedure btnLikeClick(Sender: TObject); async;
    procedure divContainerMouseEnter(Sender: TObject);
    procedure divContainerMouseLeave(Sender: TObject);
    procedure lblTextClick(Sender: TObject);
    [async] procedure btnNewClick(Sender: TObject); async;
    [async] procedure btnEditClick(Sender: TObject); async;
    [async] procedure btnCommentClick(Sender: TObject); async;
    procedure lblMainHeaderClick(Sender: TObject);
    [async] procedure btnForwardClick(Sender: TObject); async;
    procedure btnBackClick(Sender: TObject);
  private
    FAllowReadMore:boolean;
    FBlogKat, FBlogTyp, FLevel, FMainID,
    FID, FVor, FSichtbar,
    FUser,
    FMedienID,
    FHolder:integer;
    FBlog:integer;
    FGotoNextBlog:TCallbackArray;
    FReadMore, FGotoParent: TCallback;
    FSichtbar_ab, FTitel, FText:string;
  public
    property Titel: string read FTitel write FTitel;
    property Sichtbar: integer read FSichtbar write FSichtbar;
    property Sichtbar_ab: string read FSichtbar_ab write FSichtbar_ab;
    property Text: string read FText write FText;
    property Id: integer read FId write FID;
    property AllowReadMore: boolean read FAllowReadMore write FAllowReadMore;
    property MainId: integer read FMainId write FMainID;
    property Holder: integer read FHolder write FHolder;
    property User: integer read FUser write FUser;
    property MedienID: integer read FMedienID write FMedienID;
    property Blog: integer read FBlog write FBlog;
    property Level: integer read FLevel write FLevel;
    property BlogKat: integer read FBlogKat write FBlogKat;
    property BlogTyp: integer read FBlogTyp write FBlogTyp;
    property Vor: integer read FVor write FVor;
    property ReadMore: TCallback read FReadMore write FReadMore;
    property GotoParent: TCallback read FGotoParent write FGotoParent;
    property GotoNextBlog: TCallbackArray read FGotoNextBlog write FGotoNextBlog;
  protected procedure LoadDFMValues; override; end;

implementation
   uses uDatenmodul, uBlog, uMain, uBlogEdit, uComment;

{$R *.dfm}

procedure TfrBlog.btnDeleteClick(Sender: TObject);
var
  o: TfrBlog;
begin
  if Mainform.ME.Rechte.Delete[riBlog]
  then begin
    if FUser = mainform.me.ID
    then begin
      if await(Frage('Kompletten Blog endgültig löschen?')) = mrYes
      then begin
        o := TfrBlog(self);
        await(datenmodul.EasyExec('delete from BlogX where Blog_ID = ' + o.FID.ToString));
        await(datenmodul.EasyExec('delete from BlogContent where Blog_ID = ' + o.FID.ToString));
        await(datenmodul.EasyExec('delete from Blog where ID = ' + o.FID.ToString));
        FreeAndNil(o);
      end;
    end;
  end
  else meldung('Aufgrund deines Mitgliedstatus kannst Du den Blog leider nicht löschen...');
end;

procedure TfrBlog.btnEditClick(Sender: TObject);
begin

//showmessage(FText);

  if Mainform.ME.Rechte.Edit[riBlog]
  then TfrmBlogEdit.Blog(FHolder, Mainform.ME.id, FID, FVor, FBlog, FMedienID, FTitel, FText, FLevel, FBlogKat, FBlogTyp, FSichtbar, FSichtbar_ab, nil)
  else meldung('Aufgrund deines Mitgliedstatus kannst Du den Blog leider nicht bearbeiten...');
end;

procedure TfrBlog.btnNewClick(Sender: TObject);
begin
  if Mainform.ME.Rechte.New[riBlog]
  then TfrmBlogEdit.Blog(FHolder, Mainform.ME.id, 0, FID, FBlog, 0, '','', FLevel, FBlogKat, FBlogTyp, FSichtbar, FSichtbar_ab, nil) //@NewID)
  else meldung('Aufgrund deines Mitgliedstatus kannst Du leider keinen neuen Blog anlegen...');
end;

procedure TfrBlog.btnLikeClick(Sender: TObject);
Var
  i,j:integer;
begin
  if Mainform.ME.Rechte.Comment[riBlog]
  then begin
    i := await(datenmodul.LikeSet(Mainform.ME.id, FID, integer(etBlog), integer(ltLike) ));
    GetLikeCount(FID, integer(etBlog), integer(ltLike), divLikeWrapper, divLikeUser, divLikeCount);
  end
  else meldung('Aufgrund deines Mitgliedstatus kannst Du den Blog leider nicht mit "gefällt mir" markieren...');
end;

procedure TfrBlog.divContainerMouseEnter(Sender: TObject);
begin
  THTMLDiv(self).ElementHandle.style.setProperty('box-shadow','3px 3px 7px #888');
end;

procedure TfrBlog.divContainerMouseLeave(Sender: TObject);
begin
  THTMLDiv(self).ElementHandle.style.removeProperty('box-shadow');
end;

procedure TfrBlog.lblMainHeaderClick(Sender: TObject);
begin
//  if assigned(FGotoParent) and (FParentID > 0)
//  then FGotoParent(FParentID.ToString);
end;

procedure TfrBlog.lblNameClick(Sender: TObject);
begin
  if (Mainform.Holder.ID <> FUser)
  then Mainform.HolderID := FUser;
end;

procedure TfrBlog.lblTextClick(Sender: TObject);
begin
  if assigned(FReadMore) and (FMainID > 0)
  then FReadMore(FID.ToString);
end;

procedure TfrBlog.btnBackClick(Sender: TObject);
begin
  if assigned(FGotoNextBlog)
  then FGotoNextBlog([FID.ToString,'-']);
end;

procedure TfrBlog.btnForwardClick(Sender: TObject);
begin
  if assigned(FGotoNextBlog)
  then FGotoNextBlog([FID.ToString,'+']);
end;

procedure TfrBlog.btnCommentClick(Sender: TObject);
begin
  if Mainform.ME.Rechte.Comment[riBlog]
  then TfrmComment.Comment(FHolder, Mainform.ME.ID, FID, FBlog, nil)
  else meldung('Aufgrund deines Mitgliedstatus kannst Du leider keinen Kommentar hinterlassen...');
end;

procedure TfrBlog.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divContainer := THTMLDiv.Create(Self);
  imgTitel := TImageControl.Create(Self);
  lblTitel := THTMLDiv.Create(Self);
  lblHeader := THTMLDiv.Create(Self);
  lblMainHeader := THTMLDiv.Create(Self);
  btnBack := TSpeedButton.Create(Self);
  btnForward := TSpeedButton.Create(Self);
  lblText := THTMLDiv.Create(Self);
  WebHTMLDiv1 := THTMLDiv.Create(Self);
  imgAvatar := TImageControl.Create(Self);
  lblName := TLabel.Create(Self);
  lblDatum := TLabel.Create(Self);
  lblViews := TLabel.Create(Self);
  divPinned := THTMLDiv.Create(Self);
  imgPinned := TImageControl.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  btnEdit := TImageControl.Create(Self);
  btnPin := TImageControl.Create(Self);
  btnFoto := TImageControl.Create(Self);
  btnVideo := TImageControl.Create(Self);
  btnDelete := TImageControl.Create(Self);
  btnNew := TImageControl.Create(Self);
  btnComment := TImageControl.Create(Self);
  divLikeWrapper := THTMLDiv.Create(Self);
  btnLike := TImageControl.Create(Self);
  divLikeCount := THTMLDiv.Create(Self);
  divlikeUser := THTMLDiv.Create(Self);
  divComment := THTMLDiv.Create(Self);
  divImg := THTMLDiv.Create(Self);

  divContainer.BeforeLoadDFMValues;
  imgTitel.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  lblHeader.BeforeLoadDFMValues;
  lblMainHeader.BeforeLoadDFMValues;
  btnBack.BeforeLoadDFMValues;
  btnForward.BeforeLoadDFMValues;
  lblText.BeforeLoadDFMValues;
  WebHTMLDiv1.BeforeLoadDFMValues;
  imgAvatar.BeforeLoadDFMValues;
  lblName.BeforeLoadDFMValues;
  lblDatum.BeforeLoadDFMValues;
  lblViews.BeforeLoadDFMValues;
  divPinned.BeforeLoadDFMValues;
  imgPinned.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  btnEdit.BeforeLoadDFMValues;
  btnPin.BeforeLoadDFMValues;
  btnFoto.BeforeLoadDFMValues;
  btnVideo.BeforeLoadDFMValues;
  btnDelete.BeforeLoadDFMValues;
  btnNew.BeforeLoadDFMValues;
  btnComment.BeforeLoadDFMValues;
  divLikeWrapper.BeforeLoadDFMValues;
  btnLike.BeforeLoadDFMValues;
  divLikeCount.BeforeLoadDFMValues;
  divlikeUser.BeforeLoadDFMValues;
  divComment.BeforeLoadDFMValues;
  divImg.BeforeLoadDFMValues;
  try
    Name := 'frBlog';
    Left := 0;
    Top := 0;
    Width := 805;
    Height := 545;
    TabOrder := 0;
    divContainer.SetParentComponent(Self);
    divContainer.Name := 'divContainer';
    divContainer.Left := 0;
    divContainer.Top := 3;
    divContainer.Width := 798;
    divContainer.Height := 526;
    divContainer.ElementClassName := 'beitrag_content';
    divContainer.HeightStyle := ssAuto;
    divContainer.WidthStyle := ssAuto;
    divContainer.ElementPosition := epIgnore;
    divContainer.ElementFont := efCSS;
    divContainer.Role := '';
    SetEvent(divContainer, Self, 'OnMouseEnter', 'divContainerMouseEnter');
    SetEvent(divContainer, Self, 'OnMouseLeave', 'divContainerMouseLeave');
    imgTitel.SetParentComponent(divContainer);
    imgTitel.Name := 'imgTitel';
    imgTitel.Left := 20;
    imgTitel.Top := 20;
    imgTitel.Width := 244;
    imgTitel.Height := 69;
    imgTitel.ElementClassName := 'blog_foto';
    imgTitel.HeightStyle := ssAuto;
    imgTitel.WidthStyle := ssAuto;
    imgTitel.HeightPercent := 100.000000000000000000;
    imgTitel.WidthPercent := 100.000000000000000000;
    imgTitel.ChildOrder := 1;
    imgTitel.ElementFont := efCSS;
    imgTitel.ElementPosition := epIgnore;
    lblTitel.SetParentComponent(divContainer);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 18;
    lblTitel.Top := 113;
    lblTitel.Width := 767;
    lblTitel.Height := 41;
    lblTitel.ElementClassName := 'blog_header';
    lblTitel.HeightStyle := ssAuto;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.ElementPosition := epIgnore;
    lblTitel.ElementFont := efCSS;
    lblTitel.Role := '';
    lblHeader.SetParentComponent(lblTitel);
    lblHeader.Name := 'lblHeader';
    lblHeader.Left := 3;
    lblHeader.Top := 10;
    lblHeader.Width := 382;
    lblHeader.Height := 28;
    lblHeader.HeightStyle := ssAuto;
    lblHeader.WidthStyle := ssAuto;
    lblHeader.ChildOrder := 1;
    lblHeader.ElementPosition := epIgnore;
    lblHeader.ElementFont := efCSS;
    lblHeader.Role := '';
    lblMainHeader.SetParentComponent(lblTitel);
    lblMainHeader.Name := 'lblMainHeader';
    lblMainHeader.Left := 528;
    lblMainHeader.Top := 3;
    lblMainHeader.Width := 236;
    lblMainHeader.Height := 41;
    lblMainHeader.ElementClassName := 'blog_mainheader';
    lblMainHeader.HeightStyle := ssAuto;
    lblMainHeader.WidthStyle := ssAuto;
    lblMainHeader.ChildOrder := 2;
    lblMainHeader.ElementPosition := epIgnore;
    lblMainHeader.ElementFont := efCSS;
    lblMainHeader.HTML.BeginUpdate;
    try
      lblMainHeader.HTML.Clear;
      lblMainHeader.HTML.Add('');
    finally
      lblMainHeader.HTML.EndUpdate;
    end;
    lblMainHeader.Role := '';
    SetEvent(lblMainHeader, Self, 'OnClick', 'lblMainHeaderClick');
    btnBack.SetParentComponent(lblMainHeader);
    btnBack.Name := 'btnBack';
    btnBack.Left := 16;
    btnBack.Top := 3;
    btnBack.Width := 42;
    btnBack.Height := 35;
    btnBack.Color := clNone;
    btnBack.ElementFont := efCSS;
    btnBack.ElementPosition := epIgnore;
    btnBack.HeightPercent := 100.000000000000000000;
    btnBack.MaterialGlyph := 'arrow_back';
    btnBack.MaterialGlyphSize := 30;
    btnBack.TabOrder := 0;
    btnBack.WidthPercent := 100.000000000000000000;
    SetEvent(btnBack, Self, 'OnClick', 'btnBackClick');
    btnForward.SetParentComponent(lblMainHeader);
    btnForward.Name := 'btnForward';
    btnForward.Left := 64;
    btnForward.Top := 3;
    btnForward.Width := 41;
    btnForward.Height := 35;
    btnForward.Color := clNone;
    btnForward.ElementFont := efCSS;
    btnForward.ElementPosition := epIgnore;
    btnForward.HeightPercent := 100.000000000000000000;
    btnForward.MaterialGlyph := 'arrow_forward';
    btnForward.MaterialGlyphSize := 30;
    btnForward.TabOrder := 1;
    btnForward.WidthPercent := 100.000000000000000000;
    SetEvent(btnForward, Self, 'OnClick', 'btnForwardClick');
    lblText.SetParentComponent(divContainer);
    lblText.Name := 'lblText';
    lblText.Left := 16;
    lblText.Top := 160;
    lblText.Width := 767;
    lblText.Height := 41;
    lblText.ElementClassName := 'kurse_text ';
    lblText.HeightStyle := ssAuto;
    lblText.WidthStyle := ssAuto;
    lblText.ChildOrder := 1;
    lblText.ElementPosition := epIgnore;
    lblText.ElementFont := efCSS;
    lblText.Role := '';
    SetEvent(lblText, Self, 'OnClick', 'lblTextClick');
    WebHTMLDiv1.SetParentComponent(divContainer);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 18;
    WebHTMLDiv1.Top := 319;
    WebHTMLDiv1.Width := 767;
    WebHTMLDiv1.Height := 65;
    WebHTMLDiv1.ElementClassName := 'blog_user_info';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ChildOrder := 1;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    imgAvatar.SetParentComponent(WebHTMLDiv1);
    imgAvatar.Name := 'imgAvatar';
    imgAvatar.Left := 3;
    imgAvatar.Top := 3;
    imgAvatar.Width := 65;
    imgAvatar.Height := 41;
    imgAvatar.ElementClassName := 'beitrag_content_avatar';
    imgAvatar.HeightStyle := ssAuto;
    imgAvatar.WidthStyle := ssAuto;
    imgAvatar.HeightPercent := 100.000000000000000000;
    imgAvatar.WidthPercent := 100.000000000000000000;
    imgAvatar.ElementFont := efCSS;
    imgAvatar.ElementPosition := epIgnore;
    lblName.SetParentComponent(WebHTMLDiv1);
    lblName.Name := 'lblName';
    lblName.Left := 85;
    lblName.Top := 21;
    lblName.Width := 45;
    lblName.Height := 15;
    lblName.Caption := 'lblName';
    lblName.ChildOrder := 1;
    lblName.ElementClassName := 'beitrag_about_header';
    lblName.ElementFont := efCSS;
    lblName.ElementPosition := epIgnore;
    lblName.HeightStyle := ssAuto;
    lblName.HeightPercent := 100.000000000000000000;
    lblName.WidthStyle := ssAuto;
    lblName.WidthPercent := 100.000000000000000000;
    SetEvent(lblName, Self, 'OnClick', 'lblNameClick');
    lblDatum.SetParentComponent(WebHTMLDiv1);
    lblDatum.Name := 'lblDatum';
    lblDatum.Left := 376;
    lblDatum.Top := 21;
    lblDatum.Width := 49;
    lblDatum.Height := 15;
    lblDatum.Caption := 'lblDatum';
    lblDatum.ChildOrder := 2;
    lblDatum.ElementClassName := 'beitrag_about_text';
    lblDatum.ElementFont := efCSS;
    lblDatum.ElementPosition := epIgnore;
    lblDatum.HeightStyle := ssAuto;
    lblDatum.HeightPercent := 100.000000000000000000;
    lblDatum.WidthStyle := ssAuto;
    lblDatum.WidthPercent := 100.000000000000000000;
    lblViews.SetParentComponent(WebHTMLDiv1);
    lblViews.Name := 'lblViews';
    lblViews.Left := 480;
    lblViews.Top := 21;
    lblViews.Width := 32;
    lblViews.Height := 15;
    lblViews.Caption := 'views:';
    lblViews.ChildOrder := 3;
    lblViews.ElementClassName := 'beitrag_about_text';
    lblViews.ElementFont := efCSS;
    lblViews.ElementPosition := epIgnore;
    lblViews.HeightStyle := ssAuto;
    lblViews.HeightPercent := 100.000000000000000000;
    lblViews.WidthStyle := ssAuto;
    lblViews.WidthPercent := 100.000000000000000000;
    divPinned.SetParentComponent(WebHTMLDiv1);
    divPinned.Name := 'divPinned';
    divPinned.Left := 701;
    divPinned.Top := 21;
    divPinned.Width := 46;
    divPinned.Height := 41;
    divPinned.ElementClassName := 'beitrag_pinned';
    divPinned.HeightStyle := ssAuto;
    divPinned.WidthStyle := ssAuto;
    divPinned.ChildOrder := 3;
    divPinned.ElementPosition := epIgnore;
    divPinned.ElementFont := efCSS;
    divPinned.Role := '';
    divPinned.Visible := False;
    imgPinned.SetParentComponent(divPinned);
    imgPinned.Name := 'imgPinned';
    imgPinned.Left := 9;
    imgPinned.Top := -5;
    imgPinned.Width := 34;
    imgPinned.Height := 33;
    imgPinned.ElementClassName := 'post_pin';
    imgPinned.HeightStyle := ssAuto;
    imgPinned.WidthStyle := ssAuto;
    imgPinned.HeightPercent := 100.000000000000000000;
    imgPinned.WidthPercent := 100.000000000000000000;
    imgPinned.Anchors := [];
    imgPinned.ChildOrder := 1;
    imgPinned.ElementFont := efCSS;
    imgPinned.ElementPosition := epIgnore;
    imgPinned.Picture.LoadFromFile('ufrBlog.divPinned.imgPinned.Picture.png');
    WebHTMLDiv2.SetParentComponent(divContainer);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 15;
    WebHTMLDiv2.Top := 390;
    WebHTMLDiv2.Width := 766;
    WebHTMLDiv2.Height := 48;
    WebHTMLDiv2.ElementClassName := 'beitrag_side_menu_wrapper';
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ChildOrder := 4;
    WebHTMLDiv2.ElementPosition := epIgnore;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.Role := '';
    btnEdit.SetParentComponent(WebHTMLDiv2);
    btnEdit.Name := 'btnEdit';
    btnEdit.Left := 47;
    btnEdit.Top := 3;
    btnEdit.Width := 38;
    btnEdit.Height := 44;
    btnEdit.Hint := 'hier klicken um den Beitrag zu '#228'ndern...';
    btnEdit.ElementClassName := 'blk_side_menu_btn';
    btnEdit.HeightStyle := ssAuto;
    btnEdit.WidthStyle := ssAuto;
    btnEdit.HeightPercent := 100.000000000000000000;
    btnEdit.WidthPercent := 100.000000000000000000;
    btnEdit.Anchors := [];
    btnEdit.ChildOrder := 1;
    btnEdit.ElementFont := efCSS;
    btnEdit.ElementPosition := epIgnore;
    btnEdit.ShowHint := True;
    SetEvent(btnEdit, Self, 'OnClick', 'btnEditClick');
    btnEdit.Picture.LoadFromFile('ufrBlog.WebHTMLDiv2.btnEdit.Picture.png');
    btnPin.SetParentComponent(WebHTMLDiv2);
    btnPin.Name := 'btnPin';
    btnPin.Left := 274;
    btnPin.Top := 1;
    btnPin.Width := 38;
    btnPin.Height := 44;
    btnPin.ElementClassName := 'blk_side_menu_btn';
    btnPin.HeightStyle := ssAuto;
    btnPin.WidthStyle := ssAuto;
    btnPin.HeightPercent := 100.000000000000000000;
    btnPin.WidthPercent := 100.000000000000000000;
    btnPin.Anchors := [];
    btnPin.ChildOrder := 4;
    btnPin.ElementFont := efCSS;
    btnPin.ElementPosition := epIgnore;
    btnPin.ShowHint := True;
    btnPin.Picture.LoadFromFile('ufrBlog.WebHTMLDiv2.btnPin.Picture.png');
    btnFoto.SetParentComponent(WebHTMLDiv2);
    btnFoto.Name := 'btnFoto';
    btnFoto.Left := 318;
    btnFoto.Top := 1;
    btnFoto.Width := 46;
    btnFoto.Height := 44;
    btnFoto.ElementClassName := 'blk_side_menu_btn';
    btnFoto.HeightStyle := ssAuto;
    btnFoto.WidthStyle := ssAuto;
    btnFoto.HeightPercent := 100.000000000000000000;
    btnFoto.WidthPercent := 100.000000000000000000;
    btnFoto.Anchors := [];
    btnFoto.ChildOrder := 4;
    btnFoto.ElementFont := efCSS;
    btnFoto.ElementPosition := epIgnore;
    btnFoto.ShowHint := True;
    btnFoto.Visible := False;
    btnFoto.Picture.LoadFromFile('ufrBlog.WebHTMLDiv2.btnFoto.Picture.png');
    btnVideo.SetParentComponent(WebHTMLDiv2);
    btnVideo.Name := 'btnVideo';
    btnVideo.Left := 361;
    btnVideo.Top := 1;
    btnVideo.Width := 46;
    btnVideo.Height := 44;
    btnVideo.ElementClassName := 'blk_side_menu_btn';
    btnVideo.HeightStyle := ssAuto;
    btnVideo.WidthStyle := ssAuto;
    btnVideo.HeightPercent := 100.000000000000000000;
    btnVideo.WidthPercent := 100.000000000000000000;
    btnVideo.Anchors := [];
    btnVideo.ChildOrder := 6;
    btnVideo.ElementFont := efCSS;
    btnVideo.ElementPosition := epIgnore;
    btnVideo.ShowHint := True;
    btnVideo.Visible := False;
    btnVideo.Picture.LoadFromFile('ufrBlog.WebHTMLDiv2.btnVideo.Picture.png');
    btnDelete.SetParentComponent(WebHTMLDiv2);
    btnDelete.Name := 'btnDelete';
    btnDelete.Left := 222;
    btnDelete.Top := 0;
    btnDelete.Width := 46;
    btnDelete.Height := 44;
    btnDelete.Hint := 'hier klicken um den aktellen Beitrag zu entfenen...';
    btnDelete.ElementClassName := 'blk_side_menu_btn';
    btnDelete.HeightStyle := ssAuto;
    btnDelete.WidthStyle := ssAuto;
    btnDelete.HeightPercent := 100.000000000000000000;
    btnDelete.WidthPercent := 100.000000000000000000;
    btnDelete.Anchors := [];
    btnDelete.ChildOrder := 2;
    btnDelete.ElementFont := efCSS;
    btnDelete.ElementPosition := epIgnore;
    btnDelete.ShowHint := True;
    btnDelete.Visible := False;
    SetEvent(btnDelete, Self, 'OnClick', 'btnDeleteClick');
    btnDelete.Picture.LoadFromFile('ufrBlog.WebHTMLDiv2.btnDelete.Picture.png');
    btnNew.SetParentComponent(WebHTMLDiv2);
    btnNew.Name := 'btnNew';
    btnNew.Left := 3;
    btnNew.Top := 0;
    btnNew.Width := 38;
    btnNew.Height := 44;
    btnNew.Hint := 'hier klicken um einen neuen Beitrag zu schreiben';
    btnNew.ElementClassName := 'blk_side_menu_btn';
    btnNew.HeightStyle := ssAuto;
    btnNew.WidthStyle := ssAuto;
    btnNew.HeightPercent := 100.000000000000000000;
    btnNew.WidthPercent := 100.000000000000000000;
    btnNew.Anchors := [];
    btnNew.ElementFont := efCSS;
    btnNew.ElementPosition := epIgnore;
    btnNew.ShowHint := True;
    SetEvent(btnNew, Self, 'OnClick', 'btnNewClick');
    btnNew.Picture.LoadFromFile('ufrBlog.WebHTMLDiv2.btnNew.Picture.png');
    btnComment.SetParentComponent(WebHTMLDiv2);
    btnComment.Name := 'btnComment';
    btnComment.Left := 91;
    btnComment.Top := -3;
    btnComment.Width := 38;
    btnComment.Height := 44;
    btnComment.Hint := 'hier klicken um einen Kommentar zu hinterlassen...';
    btnComment.ElementClassName := 'blk_side_menu_btn';
    btnComment.HeightStyle := ssAuto;
    btnComment.WidthStyle := ssAuto;
    btnComment.HeightPercent := 100.000000000000000000;
    btnComment.WidthPercent := 100.000000000000000000;
    btnComment.Anchors := [];
    btnComment.ChildOrder := 2;
    btnComment.ElementFont := efCSS;
    btnComment.ElementPosition := epIgnore;
    btnComment.ShowHint := True;
    SetEvent(btnComment, Self, 'OnClick', 'btnCommentClick');
    btnComment.Picture.LoadFromFile('ufrBlog.WebHTMLDiv2.btnComment.Picture.png');
    divLikeWrapper.SetParentComponent(WebHTMLDiv2);
    divLikeWrapper.Name := 'divLikeWrapper';
    divLikeWrapper.Left := 558;
    divLikeWrapper.Top := 3;
    divLikeWrapper.Width := 205;
    divLikeWrapper.Height := 56;
    divLikeWrapper.ElementClassName := 'like_wrapper';
    divLikeWrapper.HeightStyle := ssAuto;
    divLikeWrapper.WidthStyle := ssAuto;
    divLikeWrapper.ChildOrder := 6;
    divLikeWrapper.ElementPosition := epIgnore;
    divLikeWrapper.ElementFont := efCSS;
    divLikeWrapper.Role := '';
    btnLike.SetParentComponent(divLikeWrapper);
    btnLike.Name := 'btnLike';
    btnLike.Left := 67;
    btnLike.Top := 20;
    btnLike.Width := 34;
    btnLike.Height := 33;
    btnLike.ElementClassName := 'blk_side_menu_btn';
    btnLike.HeightStyle := ssAuto;
    btnLike.WidthStyle := ssAuto;
    btnLike.HeightPercent := 100.000000000000000000;
    btnLike.WidthPercent := 100.000000000000000000;
    btnLike.ChildOrder := 1;
    btnLike.ElementFont := efCSS;
    btnLike.ElementPosition := epIgnore;
    SetEvent(btnLike, Self, 'OnClick', 'btnLikeClick');
    btnLike.Picture.LoadFromFile('ufrBlog.divLikeWrapper.btnLike.Picture.png');
    divLikeCount.SetParentComponent(divLikeWrapper);
    divLikeCount.Name := 'divLikeCount';
    divLikeCount.Left := 21;
    divLikeCount.Top := 12;
    divLikeCount.Width := 40;
    divLikeCount.Height := 41;
    divLikeCount.ElementClassName := 'like_count';
    divLikeCount.HeightStyle := ssAuto;
    divLikeCount.WidthStyle := ssAuto;
    divLikeCount.ChildOrder := 1;
    divLikeCount.ElementPosition := epIgnore;
    divLikeCount.ElementFont := efCSS;
    divLikeCount.Role := '';
    divlikeUser.SetParentComponent(divLikeWrapper);
    divlikeUser.Name := 'divlikeUser';
    divlikeUser.Left := 117;
    divlikeUser.Top := 15;
    divlikeUser.Width := 40;
    divlikeUser.Height := 41;
    divlikeUser.ElementClassName := 'like_count';
    divlikeUser.HeightStyle := ssAuto;
    divlikeUser.WidthStyle := ssAuto;
    divlikeUser.ChildOrder := 2;
    divlikeUser.ElementPosition := epIgnore;
    divlikeUser.ElementFont := efCSS;
    divlikeUser.Role := '';
    divComment.SetParentComponent(divContainer);
    divComment.Name := 'divComment';
    divComment.Left := 15;
    divComment.Top := 455;
    divComment.Width := 767;
    divComment.Height := 55;
    divComment.ElementClassName := 'beitrag_comment_wrapper';
    divComment.HeightStyle := ssAuto;
    divComment.WidthStyle := ssAuto;
    divComment.ChildOrder := 5;
    divComment.ElementPosition := epIgnore;
    divComment.ElementFont := efCSS;
    divComment.Role := '';
    divImg.SetParentComponent(divContainer);
    divImg.Name := 'divImg';
    divImg.Left := 14;
    divImg.Top := 207;
    divImg.Width := 767;
    divImg.Height := 106;
    divImg.ElementClassName := 'blog-edit-images-div';
    divImg.HeightStyle := ssAuto;
    divImg.WidthStyle := ssAuto;
    divImg.ChildOrder := 2;
    divImg.ElementPosition := epRelative;
    divImg.ElementFont := efCSS;
    divImg.Role := '';
  finally
    divContainer.AfterLoadDFMValues;
    imgTitel.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    lblHeader.AfterLoadDFMValues;
    lblMainHeader.AfterLoadDFMValues;
    btnBack.AfterLoadDFMValues;
    btnForward.AfterLoadDFMValues;
    lblText.AfterLoadDFMValues;
    WebHTMLDiv1.AfterLoadDFMValues;
    imgAvatar.AfterLoadDFMValues;
    lblName.AfterLoadDFMValues;
    lblDatum.AfterLoadDFMValues;
    lblViews.AfterLoadDFMValues;
    divPinned.AfterLoadDFMValues;
    imgPinned.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    btnEdit.AfterLoadDFMValues;
    btnPin.AfterLoadDFMValues;
    btnFoto.AfterLoadDFMValues;
    btnVideo.AfterLoadDFMValues;
    btnDelete.AfterLoadDFMValues;
    btnNew.AfterLoadDFMValues;
    btnComment.AfterLoadDFMValues;
    divLikeWrapper.AfterLoadDFMValues;
    btnLike.AfterLoadDFMValues;
    divLikeCount.AfterLoadDFMValues;
    divlikeUser.AfterLoadDFMValues;
    divComment.AfterLoadDFMValues;
    divImg.AfterLoadDFMValues;
  end;
end;

end.

