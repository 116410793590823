unit uAlben;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.WebCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.ExtCtrls, Vcl.Forms, ufrFotosListe, ufrAlbumListe,
  uTB, Vcl.Imaging.pngimage;

type
  TfrmAlben = class(TForm)
    divAlbenContent: THTMLDiv;
    divHeader: THTMLDiv;
    btnTitel: TImageControl;
    lblTitel: THTMLDiv;
    frAlbumListe1: TfrAlbumListe;
    procedure frAlbumListe1imgNewAlbumClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure btnTitelClick(Sender: TObject);
  private
    FHolder : integer;
    FUser   :integer;
    FParent : TForm;
  public
    property Holder : integer read FHolder write FHolder;
    [async] procedure Init; async;
    property Parent : TForm read FParent write FParent;
  protected procedure LoadDFMValues; override; end;

implementation
   Uses uDatenmodul, uMain;

{$R *.dfm}

procedure TfrmAlben.btnTitelClick(Sender: TObject);
begin
//  ich darf grundsätzlich Alben anlegen (Rechte)
//  es ist mein Album (ich bin der Holder)
//  das Album gehört zu einer Gruppe und mir gehört die Gruppe (ich bin Holder der Gruppe)

  if ((Mainform.ME.Rechte.New[riAlben]) and (Mainform.Meins))
  then frAlbumListe1.imgNewAlbumClick(Sender)
  else meldung('Aufgrund deines Mitgliedstatus kannst Du leider keine neuen Alben anlegen...');
end;

procedure TfrmAlben.frAlbumListe1imgNewAlbumClick(Sender: TObject);
begin
//  frAlbumListe1.imgNewAlbumClick(Sender);
end;

procedure TfrmAlben.Init;
Var
  sName:string;
begin

  if Mainform.Meins
  then sName := 'DEINE '
  else begin

    sName := mainform.Holder.Nickname;
    if copy(sname,length(sName)-1,1) <> 's'
    then sName := sName + 's';
  end;

  lblTitel.HTML.Text := sName + ' ALBEN';
  Mainform.Wait := 1;
  AWait(frAlbumliste1.Init(Mainform.Holder.ID, Mainform.ME.id));
  Mainform.Wait := -1;
end;

procedure TfrmAlben.WebFormCreate(Sender: TObject);
var
  el: TJSHTMLElement;
begin
  inherited;

  TControl(self).ElementPosition := epIgnore;
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-style');
  el.style.removeProperty('font-size');
end;

procedure TfrmAlben.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divAlbenContent := THTMLDiv.Create(Self);
  divHeader := THTMLDiv.Create(Self);
  btnTitel := TImageControl.Create(Self);
  lblTitel := THTMLDiv.Create(Self);
  frAlbumListe1 := TfrAlbumListe.Create(Self);

  frAlbumListe1.LoadFromForm;
  divAlbenContent.BeforeLoadDFMValues;
  divHeader.BeforeLoadDFMValues;
  btnTitel.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  frAlbumListe1.BeforeLoadDFMValues;
  frAlbumListe1.divWrapper.BeforeLoadDFMValues;
  try
    Name := 'frmAlben';
    Width := 885;
    Height := 417;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    divAlbenContent.SetParentComponent(Self);
    divAlbenContent.Name := 'divAlbenContent';
    divAlbenContent.Left := 8;
    divAlbenContent.Top := 8;
    divAlbenContent.Width := 797;
    divAlbenContent.Height := 385;
    divAlbenContent.ElementClassName := 'alben_wrapper';
    divAlbenContent.HeightStyle := ssAuto;
    divAlbenContent.WidthStyle := ssAuto;
    divAlbenContent.ChildOrder := 1;
    divAlbenContent.ElementPosition := epIgnore;
    divAlbenContent.ElementFont := efCSS;
    divAlbenContent.Role := '';
    divHeader.SetParentComponent(divAlbenContent);
    divHeader.Name := 'divHeader';
    divHeader.Left := 2;
    divHeader.Top := 2;
    divHeader.Width := 774;
    divHeader.Height := 63;
    divHeader.ElementClassName := 'me_headline';
    divHeader.HeightStyle := ssAuto;
    divHeader.WidthStyle := ssAuto;
    divHeader.ChildOrder := 2;
    divHeader.ElementPosition := epIgnore;
    divHeader.ElementFont := efCSS;
    divHeader.HTML.BeginUpdate;
    try
      divHeader.HTML.Clear;
      divHeader.HTML.Add('');
    finally
      divHeader.HTML.EndUpdate;
    end;
    divHeader.Role := '';
    btnTitel.SetParentComponent(divHeader);
    btnTitel.Name := 'btnTitel';
    btnTitel.Left := 723;
    btnTitel.Top := 3;
    btnTitel.Width := 38;
    btnTitel.Height := 44;
    btnTitel.ElementClassName := 'alben_menu_btn';
    btnTitel.HeightStyle := ssAuto;
    btnTitel.WidthStyle := ssAuto;
    btnTitel.HeightPercent := 100.000000000000000000;
    btnTitel.WidthPercent := 100.000000000000000000;
    btnTitel.Anchors := [];
    btnTitel.ChildOrder := 2;
    btnTitel.ElementFont := efCSS;
    btnTitel.ElementPosition := epIgnore;
    SetEvent(btnTitel, Self, 'OnClick', 'btnTitelClick');
    btnTitel.Picture.LoadFromFile('uAlben.divHeader.btnTitel.Picture.png');
    lblTitel.SetParentComponent(divHeader);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 16;
    lblTitel.Top := 3;
    lblTitel.Width := 100;
    lblTitel.Height := 41;
    lblTitel.HeightStyle := ssAuto;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.ChildOrder := 1;
    lblTitel.ElementPosition := epIgnore;
    lblTitel.ElementFont := efCSS;
    lblTitel.Role := '';
    frAlbumListe1.SetParentComponent(divAlbenContent);
    frAlbumListe1.Name := 'frAlbumListe1';
    frAlbumListe1.Left := 0;
    frAlbumListe1.Top := 68;
    frAlbumListe1.Width := 553;
    frAlbumListe1.Height := 264;
    frAlbumListe1.Margins.Left := 0;
    frAlbumListe1.Margins.Top := 0;
    frAlbumListe1.Margins.Right := 0;
    frAlbumListe1.Margins.Bottom := 0;
    frAlbumListe1.TabOrder := 1;
    frAlbumListe1.divWrapper.Left := 27;
    frAlbumListe1.divWrapper.Top := 20;
  finally
    divAlbenContent.AfterLoadDFMValues;
    divHeader.AfterLoadDFMValues;
    btnTitel.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    frAlbumListe1.AfterLoadDFMValues;
    frAlbumListe1.divWrapper.AfterLoadDFMValues;
  end;
end;

end.
