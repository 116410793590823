unit uMeKurse;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Forms, WEBLib.WebCtrls, Vcl.Imaging.jpeg, WEBLib.ExtCtrls, WEBLib.Lists,
  WEBLib.TreeNodes, Vcl.ComCtrls, WEBLib.ComCtrls, XData.Web.Client, utb, uGlobal,
  ufrFotosListe, ufrVideoListe, Weblib.JSON, System.Character;

type
  TfrmMEKurse = class(TForm)
    WebHTMLDiv1: THTMLDiv;
    divWrapper: THTMLDiv;
    imgPageTitel: TImageControl;
    divMain: THTMLDiv;
    divLeft: THTMLDiv;
    divMECenter: THTMLDiv;
    divRight: THTMLDiv;
    wtvKurse: TTreeView;
    Client: TXDataWebClient;
    divHeader: THTMLDiv;
    divTitelFoto: THTMLDiv;
    imgTitel: TImageControl;
    lblText: THTMLDiv;
    divFotos: THTMLDiv;
    frFotosliste1: TfrFotosliste;
    divVideos: THTMLDiv;
    frVideoListe1: TfrVideoListe;
    pFooter: THTMLDiv;
    btnBack: TButton;
    btnKlar: TButton;
    btnUnKlar: TButton;
    btnForward: TButton;
    btnBookmark: TButton;
    lblTitel: THTMLDiv;
    procedure btnForwardClick(Sender: TObject);
    procedure btnBackClick(Sender: TObject);
    procedure wtvKurseKeyDown(Sender: TObject; var Key: Word;  Shift: TShiftState);
    [async] procedure btnUnKlarClick(Sender: TObject); async;
    [async] procedure CallbackArray(AArray : array of string); async;
    [async] procedure btnKlarClick(Sender: TObject); async;
    [async] procedure wtvKurseClickNode(Sender: TObject; ANode: TTreeNode); async;
    [async] procedure btnBookmarkClick(Sender: TObject); async;
  private
    FBlur,FUser, FHolder, FKursArt, FKursID :integer;
    FJ:TJ;
  public
    [async] procedure init(AKursID, AKursArt:integer); async;
  protected procedure LoadDFMValues; override; end;

var
  frmMEKurse: TfrmMEKurse;

implementation
   uses  uDatenmodul, uMain, uFotoEdit, uMeldung;
{$R *.dfm}


procedure TfrmMEKurse.btnBackClick(Sender: TObject);
var
  Prev: TTreeNode;
begin
  if wtvKurse.Selected <> nil then
  begin
    // Den nächsten Knoten auswählen
    prev := wtvKurse.Selected.GetPrev;

    // Überprüfen, ob es einen nächsten Knoten gibt
    if prev <> nil then
    begin
      // Den nächsten Knoten auswählen
      wtvKurse.Selected := prev;

      // Den ausgewählten Knoten sichtbar machen
      wtvKurse.Selected.Expand(true);
      wtvKurseClickNode(sender,wtvKurse.Selected);
    end;
  end;
end;

procedure TfrmMEKurse.btnKlarClick(Sender: TObject);

  function SetValue(FIndex: Integer; Feldname, NeuerWert: string): Boolean;
  var
    i: Integer;
    a: TJSArray;
    o: TJSObject;
  begin
    Result := False;

    try
      a := TJSArray(TJSObject(Fj.Response.Result)['value']);
      o := TJSObject(TObject(a[FIndex]));

      o.Properties[Feldname] := NeuerWert;
      result := true;
    except
    end;

  end;

var
  Response: TXDataClientResponse;
  iStatus,
  idx:integer;
  Data: TNodeData;
  s:string;
  j:TJ;
begin
  Data := TNodeData(wtvKurse.selected.Data);
  if Assigned(Data)
  then begin
    s := (wtvKurse.selected.Level + 1).ToString;
    idx := Fj.GetIndex('ID' + s, Data.IndexHint.ToString);
    fj.index := idx;
  end;

  if Sender = btnKlar
  then iStatus := 1
  else iStatus := 2;

  s := fj.value('ID' + s);
  j := TJ.create( Await( datenmodul.Client.RawInvokeAsync( 'IDBService.KurseDone',[Mainform.ME.ID, s.tointeger, iStatus])));

  if j.integer('OK') = 2
  then begin
    await(Meldung( 'Herzlichen Glückwunsch!<br>Du hast die "Sicherheitseinweisung" komplett durchgearbeitet.<br><br>' +
                   'Unter dem Menüpunkt "Konto -> Listen & Protokolle -> Sicherheitseinweisung" kannst du dir dein Protokoll zur Bestätigung ausdrucken oder herunterladen...'));
  end;

  TButton(Sender).ElementClassname := 'start-button-enabled';

  btnForwardClick(sender);
end;

procedure TfrmMEKurse.btnBookmarkClick(Sender: TObject);
Var
  j:TJ;
begin
  j := TJ.create(await(datenmodul.EasySQL('SELECT ID FROM Bookmark where TYP = ' + FKursArt.ToString + ' AND USER_ID = ' + mainform.ME.ID.toString)));
  if j.Integer('ID') > 0
  then begin
     await(datenmodul.EasyEXEC('UPDATE Bookmark Set ITEM_ID = ' + FKursID.toString +
                               ' where TYP = 1 AND USER_ID = ' + mainform.ME.ID.toString));
  end
  else begin
     await(datenmodul.EasyInsert('INSERT INTO Bookmark (TYP, ITEM_ID, USER_ID) ' +
                                 ' VALUES ('+FKursArt.ToString+',' + FKursID.toString + ',' + mainform.ME.ID.toString + ')'));
  end;

  await(Meldung('Lesezeichen wurde gesetzt...'));


end;

procedure TfrmMEKurse.btnForwardClick(Sender: TObject);
var
  Next: TTreeNode;
begin
  if wtvKurse.Selected <> nil then
  begin
    // Den nächsten Knoten auswählen
    Next := wtvKurse.Selected.GetNext;

    // Überprüfen, ob es einen nächsten Knoten gibt
    if Next <> nil
    then begin
      // Den nächsten Knoten auswählen
      wtvKurse.Selected := Next;

      // Den ausgewählten Knoten sichtbar machen
      wtvKurse.Selected.Expand(true);
      wtvKurseClickNode(sender,wtvKurse.Selected);
    end;
  end;

end;

procedure TfrmMEKurse.CallbackArray(AArray : array of string);
Var
  j:TJ;
begin
  if aArray[0] > ''
  then begin
    J := TJ.create(await(datenmodul.easySQL('SELECT ID from faq ' +
                     ' WHERE TYP = ' + _Tab_Kurse.ToString +
                     '   AND USER_ID = ' + Mainform.ME.ID.ToString +
                     '   AND ITEM_ID = ' + FKursID.ToString)));

    if j.Integer('ID') > 0
    then begin
      await(datenmodul.easyExec('UPDATE faq SET QUESTION = ''' + ChkStr(aArray[0]) + ''',' +
                                              ' ANSWER   = ''' + ChkStr(aArray[1]) + '''' +
                                ' WHERE ID = ' + j.Value('ID')));


    end
    else begin
      await(datenmodul.easyINSERT('INSERT INTO faq (TYP, USER_ID, ITEM_ID, QUESTION, ANSWER) ' +
                                          '  VALUES(' + _Tab_Kurse.ToString + ',' +
                                                        Mainform.ME.ID.ToString +  ',' +
                                                        FKursID.ToString + ',' +
                                                 '''' + ChkStr(aArray[0]) + ''',' +
                                                 '''' + ChkStr(aArray[1]) + ''')' ));
    end;

    btnKlarClick(btnUnklar);
  end;
end;

procedure TfrmMEKurse.btnUnKlarClick(Sender: TObject);
Var
  j:TJ;
  a:array of string;
begin
  J := TJ.create(await(datenmodul.easySQL('SELECT * from faq ' +
                   ' WHERE TYP = ' + _Tab_Kurse.ToString +
                   '   AND USER_ID = ' + Mainform.ME.ID.ToString +
                   '   AND ITEM_ID = ' + FKursID.ToString)));

  setlength(a,1);
  a[0] := j.Value('QUESTION');

  if j.Integer('ID') > 0
  then begin
    setlength(a,2);
    a[1] := j.Value('ANSWER');
  end;

  TfrmFotoEdit.Exec('Meine Frage zum Thema:' , [lblTitel.HTML.Text,'Antwort:'], a, @CallbackArray);
end;

procedure TfrmMeKurse.init(AKursID, AKursArt:integer);
  [async]
  procedure cbMeldung( a:array of string); async;
  begin
    if a[0] = '1'
    then await(datenmodul.easyExec('INSERT INTO hide (TYP, ITEM_ID, USER_ID) ' +
                                  ' VALUES (2,1,' + Mainform.ME.ID.ToString + ')'));
  end;

var
  prev, sel, tn,sn,wn: TTreeNode;
  l1,l2,l3,l4,l5:TTreeNode;
  sTrainer, s, s1,s2,s3,s4, s5:string;

  iSelected, i,k:integer;
  Response: TXDataClientResponse;
  J:TJ;
begin
  mainform.Wait := 1;

  FHolder  := Mainform.Holder.ID;
  FUser    := Mainform.ME.ID;
  FKursArt := AKursArt;
  FKursID  := AKursID;
  FBlur    := IFInteger(AKursArt = _KA_Lektion, ord(riLektionen), ord(riTutorials));

  if FKursID = 0 //Wenn kein bestimmter Punkt angesprungen werden soll, dann evtl. Bookmark anspringen
  then begin
    j := TJ.create(await(datenmodul.EasySQL('SELECT ITEM_ID FROM Bookmark where TYP = ' + FKursArt.ToString + ' AND USER_ID = ' + mainform.ME.ID.toString)));
    if j.Integer('ITEM_ID') > 0
    then FKursID := j.Integer('ITEM_ID');
  end;

  Fj := TJ.create;
  //Überschrift ("Lektionen" bzw. "Anleitungen") aus der Tabelle holen
  Fj.Response := await(datenmodul.EasySQL('select ID from Kurse Where VOR = 0 AND ART = ' + AKursArt.tostring + ' limit 1'));

  if (not Mainform.ME.Trainer)
  then sTrainer := ' AND (K1.Trainer = 0)';
//  then sTrainer := ' AND (K1.Trainer = 0 AND K2.Trainer = 0 AND K3.Trainer = 0 AND ' +
//                   '      K4.Trainer = 0 AND K5.Trainer = 0)';


  //alle Kurse zur Überschrift holen (entweder 'Lektionen' oder 'Anleitungen')
  FJ.Connection := datenmodul.Connection;
  FJ.Sql := 'SELECT k1.header as H1, K1.TEXT as T1, k1.ID as ID1, k1.MEDIEN_ID as M1, k1.MEDIEN_POSITION as P1, ' +
            '       k2.header as H2, K2.TEXT as T2, k2.ID as ID2, k2.MEDIEN_ID as M2, k2.MEDIEN_POSITION as P2, ' +
            '       k3.header as H3, K3.TEXT as T3, k3.ID as ID3, k3.MEDIEN_ID as M3, k3.MEDIEN_POSITION as P3, ' +
            '       k4.header as H4, K4.TEXT as T4, k4.ID as ID4, k4.MEDIEN_ID as M4, k4.MEDIEN_POSITION as P4, ' +
            '       k5.header as H5, K5.TEXT as T5, k5.ID as ID5, k5.MEDIEN_ID as M5, k5.MEDIEN_POSITION as P5  ' +
            '  from kurse k1 ' +
            '  left JOIN kurse k2 ON (k1.ID = k2.vor AND k1.art = ' + AKursArt.ToString + ') ' +
            '  left JOIN kurse k3 ON (k2.ID = k3.vor AND k1.art = ' + AKursArt.ToString + ') ' +
            '  left JOIN kurse k4 ON (k3.ID = k4.vor AND k1.art = ' + AKursArt.ToString + ') ' +
            '  left JOIN kurse k5 ON (k4.ID = k5.vor AND k1.art = ' + AKursArt.ToString + ') ' +
            ' WHERE k1.art = ' + AKursArt.ToString + ' AND k1.VOR =  ' + fj.Value('ID') + sTrainer +
            ' ORDER BY k1.vor, k1.ID, k2.vor,k2.ID, k3.vor,k3.ID, k4.vor, k4.ID, k5.vor, k5.ID';

  await(FJ.open);


  wtvKurse.BeginUpdate;
//      tn := webtreeview1.Items.Add(nil,'<font color="#E5413C" style="font-size:10pt">&#x25FC;</font> VCL');

  //Titelbild
  imgPageTitel.URL := await(datenmodul.GetMedia(fj.Integer('M1'), _SIZE1));


  for i := 0 to  Fj.Length-1 do
  begin
    Fj.index := i;

    if s1 <> Fj.value('H1')
    then begin
      s1 := Fj.value('H1');
      l1 := wtvKurse.Items.Add(nil, Fj.value('H1'));
      l1.Data := TNodeData.Create(Fj.integer('ID1'));
      if Fj.integer('ID1') = FKursID
      then Sel := l1;
    end;

    if (s2 <> Fj.value('H2')) and (Fj.value('H2')>'')
    then begin
      s2 := Fj.value('H2');
      l2 := wtvKurse.Items.AddChild(l1, Fj.value('H2'));
      l2.Data := TNodeData.Create(Fj.integer('ID2'));
      if Fj.integer('ID2') = FKursID
      then Sel := l2;
    end;

    if (s3 <> Fj.value('H3')) and (Fj.value('H3')>'')
    then begin
      s3 := Fj.value('H3');
      l3 := wtvKurse.Items.AddChild(l2, Fj.value('H3'));
      l3.Data := TNodeData.Create(Fj.integer('ID3'));
      if Fj.integer('ID3') = FKursID
      then Sel := l3;
    end;

    if (s4 <> Fj.value('H4')) and (Fj.value('H4')>'')
    then begin
      s4 := Fj.value('H4');
      l4 := wtvKurse.Items.AddChild(l3, Fj.value('H4'));
      l4.Data := TNodeData.Create(Fj.integer('ID4'));
      if Fj.integer('ID4') = FKursID
      then Sel := l4;
    end;

    if (s5 <> Fj.value('H5')) and (Fj.value('H5')>'')
    then begin
      s5 := Fj.value('H5');
      l5 := wtvKurse.Items.AddChild(l4, Fj.value('H5'));
      l5.Data := TNodeData.Create(Fj.integer('ID5'));
      if Fj.integer('ID5') = FKursID
      then Sel := l5;
    end;
  end;

  wtvKurse.EndUpdate;

  //Wenn kein spezieller Knoten gesucht wird, den ersten Knoten verwenden
  if not assigned(sel)
  then Sel := wtvKurse.Items[0];

  wtvKurse.Selected := sel;

  //solange es vorgänger gibt, diese expandieren
  while (wtvKurse.Selected.Parent <> nil) do
  begin
    // Den vorherigen Knoten auswählen
    wtvKurse.Selected := wtvKurse.Selected.Parent;

    // Den ausgewählten Knoten sichtbar machen
    wtvKurse.Selected.Expand(true);
//    showmessage(wtvKurse.selected.Text);

  end;

  //gesuchten Knoten selektieren
  wtvKurse.Selected := sel;
//  wtvKurse.Selected := wtvKurse.Items[iSelected];
  wtvKurse.Selected.Expand(true);
  wtvKurseClickNode(wtvKurse,wtvKurse.Selected);

  mainform.Wait := -1;

  if AKursArt = _KA_Lektion
  then begin
    j.Response := await(datenmodul.easySQL('SELECT ID from hide ' +
                                           ' WHERE TYP = 2 AND ' +
                                           '       ITEM_ID = 1 AND ' +
                                           '       USER_ID = ' + Mainform.ME.ID.ToString));
    if j.isEmpty
    then begin
      TfrmMeldung.Exec('Info zur Sicherheitseinweisung!',
                       'Um das Protokoll zur Sicherheitseinweisung unter <br><br>"Konto/Listen + Protokolle/Sicherheitseinweisung"' +
                       '<br><br>ausdrucken zu können, musst du jeden Artikel, nachdem du ihn gelesen und die jeweiligen Fotos und Videos angesehen hast, ' +
                       'bestätigen.<br>Klicke dazu entweder auf "klar" wenn der Inhalt des Artikel für dich verständlich ist und klicke auf ' +
                       '"unklar" wenn du Fragen dazu hast. Notiere deine Frage dann in dem sich öffnenden Fenster.' +
                       '<br><br>Erst wenn alle Artikel mit "klar" oder "unklar" bestätigt sind, kannst du das Protokoll ausdrucken!<br><br>' +
                       'Die Tasten "vorherige" und "nächste" dienen nur der Navigation und haben ansonsten keine weitere Bedeutung!',
                       ['Hinweis nicht mehr zeigen', 'beim nächsten mal wieder zeigen'], @cbMeldung);
    end;

  end;

end;

procedure TfrmMEKurse.wtvKurseClickNode(Sender: TObject; ANode: TTreeNode);

  function CountLevels(TreeNode: TTreeNode; Level: Integer): Integer;
  var
    ChildNode: TTreeNode;
    MaxChildLevel, ChildLevel: Integer;
  begin
    Result := Level;
    MaxChildLevel := Level;
    ChildNode := TreeNode.GetFirstChild;
    while ChildNode <> nil do
    begin
      ChildLevel := CountLevels(ChildNode, Level + 1);
      if ChildLevel > MaxChildLevel
      then MaxChildLevel := ChildLevel;

      ChildNode := TreeNode.GetNextChild(ChildNode);
    end;
    Result := MaxChildLevel;
  end;

  [async]
  function InsertTip(AText:string):string; async;
  Var
    iStart, i, iLaenge:integer;
    sWort, sTip, sPlatzhalter:string;
    j:TJ;
    c:Char;

  begin

    result := AText;
    J := TJ.create;

    while True do
    begin
      //Platzhalter suchen z.B. {Tip=1234;}
      iStart := pos('{TIP=', UpperCase(result));

      i := iStart-1;
      sWort := '';

      if iStart > 0
      then begin
        //Das Wort links vom Platzhalter bis zum ersten Leerzeichen
        while i > 0 do
        begin
          c := result[i];
          if (not c.IsLetter)and (not c.IsDigit)
          then break
          else i := i - 1;
        end;

        sWort := copy(result,i+1, iStart - i-1);

        iLaenge:= pos('}', result) + 1 - iStart ;
        sPlatzhalter := copy(result, iStart, iLaenge);

        //Tip-Nr. auslesen
        iStart := pos('=', sPlatzhalter) + 1;
        iLaenge:= pos('}', sPlatzhalter) - iStart ;
        sTip := copy(sPlatzhalter, iStart, iLaenge);
        j.response := await(datenmodul.EasySQL('select * from wiki where ID = ' + sTip));

        sTip := '<span class="tooltip" title="' + j.Value('TEXT') + '">' + sWort + '</span>';
        Result := Stringreplace(Result, sWort + sPlatzhalter, sTip, []);
      end
      else break;
    end;

  end;

  [async]
  function Done(AKursID, AUserID:string):integer; async;
    var j : TJ;
  begin
    j := TJ.create(await(datenmodul.EasySQL('select ID, STATUS from KurseDone where KURSE_id = ' + AKursID + ' AND USER_ID = ' + AUserID)));
    result := j.Integer('STATUS');
    j.Free;
  end;

Var
  j:TJ;
  NodeData: TNodeData;
  idx:integer;
  sHint,
  s:string;
  sText:string;
begin
  NodeData := TNodeData(ANode.Data);
  if Assigned(NodeData)
  then begin

    s := (ANode.Level + 1).ToString;
    idx := Fj.GetIndex('ID' + s, NodeData.IndexHint.ToString);

    fj.Index := idx;

    //Titelfoto setzen
    if ANode.Level = 0
    then imgPageTitel.URL := await(datenmodul.GetMedia(fj.Integer('M' + s), _SIZE1))
    else imgTitel.URL := await(datenmodul.GetMedia(fj.Integer('M' + s), _SIZE1));
    divTitelFoto.Visible := imgTitel.URL > '';

    lblTitel.HTML.Text := fj.Value('H' + s);
    sText := fj.Value('T' + s);
    sText := await(InsertImage(sText,_Size2, Mainform.ME.Rechte.Blur[TRechteIdx(FBlur)]));
    sText := await(InsertTip(sText));
    lblText.HTML.Text := sText;

    Mainform.SetBlur(lblText,0, Mainform.ME.Rechte.Blur[TRechteIdx(FBlur)]);

//    btnDone.Enabled := (fj.Integer('D' + s) = 0);

    FKursID := fj.integer('ID' + s);

    btnKlar.ElementClassname := 'start_button-lets-go';
    btnUnKlar.ElementClassname := 'start_button-lets-go';

    if (not ANode.HasChildren)
    then begin
      btnKlar.visible := true;

      case await(Done(fj.value('ID' + s), FUser.Tostring)) of
        0:  begin //unbearbeited
              btnKlar.ElementClassname := 'start_button-lets-go';
              btnUnKlar.ElementClassname := 'start_button-lets-go';
            end;
        1:  begin // klar
              btnKlar.ElementClassname := 'start-button-enabled'
            end;
        2:  begin // Unklar
              btnUnKlar.ElementClassname := 'start-button-enabled'
            end;
      end;

    end
    else btnKlar.visible := false;

    btnUnKlar.visible := btnKlar.visible;

    if CountLevels(ANode, 0) = 0
    then begin
      divFotos.visible := (await(frFotosliste1.Init(mainform.Holder.ID, mainform.ME.ID, 0, 0, 0, fj.Integer('ID' + s), 0, -1,  nil, false, 0)) > 0);
      divVideos.visible := (await(frVideoliste1.Init(mainform.Holder.ID, mainform.ME.ID, 0, 0, 0, fj.Integer('ID' + s), 0, -1,  nil, false, 0)) > 0);
    end
    else begin
      divFotos.visible := false;
      divVideos.visible:= false;
    end;
  end;

end;

procedure TfrmMEKurse.wtvKurseKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
begin

  if key = vk_return
  then wtvKurseClickNode(wtvKurse, wtvKurse.Selected);

end;

//initialization
//  RegisterClass(TfrmMeKurse);

procedure TfrmMEKurse.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  divWrapper := THTMLDiv.Create('divheader');
  imgPageTitel := TImageControl.Create('header_image');
  divMain := THTMLDiv.Create('divmain');
  divLeft := THTMLDiv.Create('divleft');
  wtvKurse := TTreeView.Create(Self);
  divMECenter := THTMLDiv.Create(Self);
  divHeader := THTMLDiv.Create(Self);
  btnBookmark := TButton.Create(Self);
  lblTitel := THTMLDiv.Create(Self);
  divTitelFoto := THTMLDiv.Create(Self);
  imgTitel := TImageControl.Create('header_image');
  lblText := THTMLDiv.Create(Self);
  divFotos := THTMLDiv.Create(Self);
  frFotosliste1 := TfrFotosliste.Create(Self);
  divVideos := THTMLDiv.Create(Self);
  frVideoListe1 := TfrVideoListe.Create(Self);
  pFooter := THTMLDiv.Create(Self);
  btnBack := TButton.Create(Self);
  btnKlar := TButton.Create(Self);
  btnUnKlar := TButton.Create(Self);
  btnForward := TButton.Create(Self);
  divRight := THTMLDiv.Create('divright');
  Client := TXDataWebClient.Create(Self);

  frFotosliste1.LoadFromForm;
  frVideoListe1.LoadFromForm;
  WebHTMLDiv1.BeforeLoadDFMValues;
  divWrapper.BeforeLoadDFMValues;
  imgPageTitel.BeforeLoadDFMValues;
  divMain.BeforeLoadDFMValues;
  divLeft.BeforeLoadDFMValues;
  wtvKurse.BeforeLoadDFMValues;
  divMECenter.BeforeLoadDFMValues;
  divHeader.BeforeLoadDFMValues;
  btnBookmark.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  divTitelFoto.BeforeLoadDFMValues;
  imgTitel.BeforeLoadDFMValues;
  lblText.BeforeLoadDFMValues;
  divFotos.BeforeLoadDFMValues;
  frFotosliste1.BeforeLoadDFMValues;
  frFotosliste1.WebHTMLDiv1.BeforeLoadDFMValues;
  frFotosliste1.divWrapper.BeforeLoadDFMValues;
  frFotosliste1.img.BeforeLoadDFMValues;
  divVideos.BeforeLoadDFMValues;
  frVideoListe1.BeforeLoadDFMValues;
  frVideoListe1.WebHTMLDiv1.BeforeLoadDFMValues;
  frVideoListe1.divWrapper.BeforeLoadDFMValues;
  frVideoListe1.img.BeforeLoadDFMValues;
  pFooter.BeforeLoadDFMValues;
  btnBack.BeforeLoadDFMValues;
  btnKlar.BeforeLoadDFMValues;
  btnUnKlar.BeforeLoadDFMValues;
  btnForward.BeforeLoadDFMValues;
  divRight.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frmMEKurse';
    Width := 1216;
    Height := 972;
    ElementFont := efCSS;
    Visible := True;
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 8;
    WebHTMLDiv1.Top := 8;
    WebHTMLDiv1.Width := 1193;
    WebHTMLDiv1.Height := 217;
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epRelative;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    divWrapper.SetParentComponent(WebHTMLDiv1);
    divWrapper.Name := 'divWrapper';
    divWrapper.Left := 27;
    divWrapper.Top := 16;
    divWrapper.Width := 1118;
    divWrapper.Height := 177;
    divWrapper.HeightStyle := ssAuto;
    divWrapper.WidthStyle := ssAuto;
    divWrapper.ChildOrder := 1;
    divWrapper.ElementPosition := epRelative;
    divWrapper.ElementFont := efCSS;
    divWrapper.Role := '';
    imgPageTitel.SetParentComponent(divWrapper);
    imgPageTitel.Name := 'imgPageTitel';
    imgPageTitel.Left := 366;
    imgPageTitel.Top := 24;
    imgPageTitel.Width := 372;
    imgPageTitel.Height := 121;
    imgPageTitel.ElementClassName := 'header_image';
    imgPageTitel.HeightStyle := ssAuto;
    imgPageTitel.WidthStyle := ssAuto;
    imgPageTitel.HeightPercent := 100.000000000000000000;
    imgPageTitel.WidthPercent := 100.000000000000000000;
    imgPageTitel.ChildOrder := 1;
    imgPageTitel.ElementFont := efCSS;
    imgPageTitel.ElementPosition := epIgnore;
    divMain.SetParentComponent(Self);
    divMain.Name := 'divMain';
    divMain.Left := 8;
    divMain.Top := 231;
    divMain.Width := 1193;
    divMain.Height := 730;
    divMain.ElementClassName := 'me_main';
    divMain.HeightStyle := ssAuto;
    divMain.WidthStyle := ssAuto;
    divMain.ChildOrder := 1;
    divMain.ElementPosition := epIgnore;
    divMain.ElementFont := efCSS;
    divMain.Role := '';
    divLeft.SetParentComponent(divMain);
    divLeft.Name := 'divLeft';
    divLeft.Left := 21;
    divLeft.Top := 21;
    divLeft.Width := 316;
    divLeft.Height := 524;
    divLeft.ElementClassName := 'kurse_left';
    divLeft.HeightStyle := ssAuto;
    divLeft.WidthStyle := ssAuto;
    divLeft.ElementPosition := epIgnore;
    divLeft.ElementFont := efCSS;
    divLeft.Role := '';
    wtvKurse.SetParentComponent(divLeft);
    wtvKurse.Name := 'wtvKurse';
    wtvKurse.Left := 20;
    wtvKurse.Top := 3;
    wtvKurse.Width := 269;
    wtvKurse.Height := 473;
    wtvKurse.ElementClassName := 'tree';
    wtvKurse.ElementPosition := epRelative;
    wtvKurse.ElementFont := efCSS;
    wtvKurse.HeightStyle := ssAuto;
    wtvKurse.HeightPercent := 100.000000000000000000;
    wtvKurse.WidthPercent := 100.000000000000000000;
    SetEvent(wtvKurse, Self, 'OnClickNode', 'wtvKurseClickNode');
    SetEvent(wtvKurse, Self, 'OnKeyDown', 'wtvKurseKeyDown');
    divMECenter.SetParentComponent(divMain);
    divMECenter.Name := 'divMECenter';
    divMECenter.Left := 367;
    divMECenter.Top := 21;
    divMECenter.Width := 482;
    divMECenter.Height := 652;
    divMECenter.ElementClassName := 'kurse-center';
    divMECenter.HeightStyle := ssAuto;
    divMECenter.WidthStyle := ssAuto;
    divMECenter.ChildOrder := 1;
    divMECenter.ElementPosition := epIgnore;
    divMECenter.ElementFont := efCSS;
    divMECenter.Role := '';
    divHeader.SetParentComponent(divMECenter);
    divHeader.Name := 'divHeader';
    divHeader.Left := 18;
    divHeader.Top := 11;
    divHeader.Width := 375;
    divHeader.Height := 30;
    divHeader.ElementClassName := 'me_headline';
    divHeader.HeightStyle := ssAuto;
    divHeader.WidthStyle := ssAuto;
    divHeader.ChildOrder := 1;
    divHeader.ElementPosition := epIgnore;
    divHeader.ElementFont := efCSS;
    divHeader.HTML.BeginUpdate;
    try
      divHeader.HTML.Clear;
      divHeader.HTML.Add('');
    finally
      divHeader.HTML.EndUpdate;
    end;
    divHeader.Role := '';
    btnBookmark.SetParentComponent(divHeader);
    btnBookmark.Name := 'btnBookmark';
    btnBookmark.Left := 276;
    btnBookmark.Top := 2;
    btnBookmark.Width := 96;
    btnBookmark.Height := 25;
    btnBookmark.Caption := 'merken';
    btnBookmark.ElementClassName := 'bookmark-btn';
    btnBookmark.ElementFont := efCSS;
    btnBookmark.ElementPosition := epIgnore;
    btnBookmark.HeightStyle := ssAuto;
    btnBookmark.HeightPercent := 100.000000000000000000;
    btnBookmark.WidthStyle := ssAuto;
    btnBookmark.WidthPercent := 100.000000000000000000;
    SetEvent(btnBookmark, Self, 'OnClick', 'btnBookmarkClick');
    lblTitel.SetParentComponent(divHeader);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 3;
    lblTitel.Top := 0;
    lblTitel.Width := 100;
    lblTitel.Height := 41;
    lblTitel.HeightStyle := ssAuto;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.ChildOrder := 1;
    lblTitel.ElementPosition := epIgnore;
    lblTitel.ElementFont := efCSS;
    lblTitel.Role := '';
    divTitelFoto.SetParentComponent(divMECenter);
    divTitelFoto.Name := 'divTitelFoto';
    divTitelFoto.Left := 18;
    divTitelFoto.Top := 47;
    divTitelFoto.Width := 375;
    divTitelFoto.Height := 87;
    divTitelFoto.HeightStyle := ssAuto;
    divTitelFoto.WidthStyle := ssAuto;
    divTitelFoto.ChildOrder := 2;
    divTitelFoto.ElementPosition := epIgnore;
    divTitelFoto.ElementFont := efCSS;
    divTitelFoto.Role := '';
    imgTitel.SetParentComponent(divTitelFoto);
    imgTitel.Name := 'imgTitel';
    imgTitel.Left := 10;
    imgTitel.Top := 3;
    imgTitel.Width := 343;
    imgTitel.Height := 77;
    imgTitel.ElementClassName := 'header_image';
    imgTitel.HeightStyle := ssAuto;
    imgTitel.WidthStyle := ssAuto;
    imgTitel.HeightPercent := 100.000000000000000000;
    imgTitel.WidthPercent := 100.000000000000000000;
    imgTitel.ChildOrder := 1;
    imgTitel.ElementFont := efCSS;
    imgTitel.ElementPosition := epIgnore;
    lblText.SetParentComponent(divMECenter);
    lblText.Name := 'lblText';
    lblText.Left := 18;
    lblText.Top := 140;
    lblText.Width := 374;
    lblText.Height := 94;
    lblText.ElementClassName := 'kurstext_text';
    lblText.HeightStyle := ssAuto;
    lblText.WidthStyle := ssAuto;
    lblText.ChildOrder := 3;
    lblText.ElementPosition := epIgnore;
    lblText.ElementFont := efCSS;
    lblText.HTML.BeginUpdate;
    try
      lblText.HTML.Clear;
      lblText.HTML.Add('Text');
    finally
      lblText.HTML.EndUpdate;
    end;
    lblText.Role := '';
    divFotos.SetParentComponent(divMECenter);
    divFotos.Name := 'divFotos';
    divFotos.Left := 18;
    divFotos.Top := 240;
    divFotos.Width := 375;
    divFotos.Height := 137;
    divFotos.ElementClassName := 'ktext_img_container';
    divFotos.HeightStyle := ssAuto;
    divFotos.WidthStyle := ssAuto;
    divFotos.ChildOrder := 4;
    divFotos.ElementPosition := epIgnore;
    divFotos.ElementFont := efCSS;
    divFotos.Role := '';
    divFotos.Visible := False;
    frFotosliste1.SetParentComponent(divFotos);
    frFotosliste1.Name := 'frFotosliste1';
    frFotosliste1.Left := 45;
    frFotosliste1.Top := 6;
    frFotosliste1.Width := 230;
    frFotosliste1.Height := 131;
    frFotosliste1.Margins.Left := 0;
    frFotosliste1.Margins.Top := 0;
    frFotosliste1.Margins.Right := 0;
    frFotosliste1.Margins.Bottom := 0;
    frFotosliste1.TabOrder := 0;
    frFotosliste1.WebHTMLDiv1.Width := 206;
    frFotosliste1.WebHTMLDiv1.Height := 121;
    frFotosliste1.divWrapper.Left := 11;
    frFotosliste1.divWrapper.Top := 10;
    frFotosliste1.divWrapper.Width := 182;
    frFotosliste1.divWrapper.Height := 103;
    frFotosliste1.img.Top := 4;
    divVideos.SetParentComponent(divMECenter);
    divVideos.Name := 'divVideos';
    divVideos.Left := 18;
    divVideos.Top := 392;
    divVideos.Width := 375;
    divVideos.Height := 157;
    divVideos.ElementClassName := 'ktext_img_container';
    divVideos.HeightStyle := ssAuto;
    divVideos.WidthStyle := ssAuto;
    divVideos.ChildOrder := 5;
    divVideos.ElementPosition := epIgnore;
    divVideos.ElementFont := efCSS;
    divVideos.Role := '';
    divVideos.Visible := False;
    frVideoListe1.SetParentComponent(divVideos);
    frVideoListe1.Name := 'frVideoListe1';
    frVideoListe1.Left := 11;
    frVideoListe1.Top := 10;
    frVideoListe1.Width := 214;
    frVideoListe1.Height := 127;
    frVideoListe1.Margins.Left := 0;
    frVideoListe1.Margins.Top := 0;
    frVideoListe1.Margins.Right := 0;
    frVideoListe1.Margins.Bottom := 0;
    frVideoListe1.TabOrder := 0;
    frVideoListe1.WebHTMLDiv1.Left := 3;
    frVideoListe1.WebHTMLDiv1.Width := 195;
    frVideoListe1.WebHTMLDiv1.Height := 118;
    frVideoListe1.divWrapper.Left := 8;
    frVideoListe1.divWrapper.Top := 8;
    frVideoListe1.divWrapper.Width := 166;
    frVideoListe1.img.Left := 8;
    frVideoListe1.img.Top := 8;
    frVideoListe1.img.Height := 74;
    pFooter.SetParentComponent(divMECenter);
    pFooter.Name := 'pFooter';
    pFooter.Left := 18;
    pFooter.Top := 555;
    pFooter.Width := 455;
    pFooter.Height := 73;
    pFooter.ElementClassName := 'editor-footer-div';
    pFooter.HeightStyle := ssAuto;
    pFooter.WidthStyle := ssAuto;
    pFooter.ChildOrder := 6;
    pFooter.ElementPosition := epIgnore;
    pFooter.ElementFont := efCSS;
    pFooter.Role := '';
    btnBack.SetParentComponent(pFooter);
    btnBack.Name := 'btnBack';
    btnBack.Left := 16;
    btnBack.Top := 24;
    btnBack.Width := 96;
    btnBack.Height := 25;
    btnBack.Caption := 'vorherige';
    btnBack.ElementClassName := 'start_button-lets-go';
    btnBack.ElementFont := efCSS;
    btnBack.ElementPosition := epIgnore;
    btnBack.HeightStyle := ssAuto;
    btnBack.HeightPercent := 100.000000000000000000;
    btnBack.WidthStyle := ssAuto;
    btnBack.WidthPercent := 100.000000000000000000;
    SetEvent(btnBack, Self, 'OnClick', 'btnBackClick');
    btnKlar.SetParentComponent(pFooter);
    btnKlar.Name := 'btnKlar';
    btnKlar.Left := 118;
    btnKlar.Top := 24;
    btnKlar.Width := 96;
    btnKlar.Height := 25;
    btnKlar.Caption := 'klar';
    btnKlar.ChildOrder := 1;
    btnKlar.ElementClassName := 'start_button-lets-go';
    btnKlar.ElementFont := efCSS;
    btnKlar.ElementPosition := epIgnore;
    btnKlar.HeightStyle := ssAuto;
    btnKlar.HeightPercent := 100.000000000000000000;
    btnKlar.Visible := False;
    btnKlar.WidthStyle := ssAuto;
    btnKlar.WidthPercent := 100.000000000000000000;
    SetEvent(btnKlar, Self, 'OnClick', 'btnKlarClick');
    btnUnKlar.SetParentComponent(pFooter);
    btnUnKlar.Name := 'btnUnKlar';
    btnUnKlar.Left := 220;
    btnUnKlar.Top := 24;
    btnUnKlar.Width := 96;
    btnUnKlar.Height := 25;
    btnUnKlar.Caption := 'unklar';
    btnUnKlar.ChildOrder := 2;
    btnUnKlar.ElementClassName := 'start_button-lets-go';
    btnUnKlar.ElementFont := efCSS;
    btnUnKlar.ElementPosition := epIgnore;
    btnUnKlar.HeightStyle := ssAuto;
    btnUnKlar.HeightPercent := 100.000000000000000000;
    btnUnKlar.WidthStyle := ssAuto;
    btnUnKlar.WidthPercent := 100.000000000000000000;
    SetEvent(btnUnKlar, Self, 'OnClick', 'btnUnKlarClick');
    btnForward.SetParentComponent(pFooter);
    btnForward.Name := 'btnForward';
    btnForward.Left := 322;
    btnForward.Top := 24;
    btnForward.Width := 96;
    btnForward.Height := 25;
    btnForward.Caption := 'n'#228'chste';
    btnForward.ChildOrder := 3;
    btnForward.ElementClassName := 'start_button-lets-go';
    btnForward.ElementFont := efCSS;
    btnForward.ElementPosition := epIgnore;
    btnForward.HeightStyle := ssAuto;
    btnForward.HeightPercent := 100.000000000000000000;
    btnForward.WidthStyle := ssAuto;
    btnForward.WidthPercent := 100.000000000000000000;
    SetEvent(btnForward, Self, 'OnClick', 'btnForwardClick');
    divRight.SetParentComponent(divMain);
    divRight.Name := 'divRight';
    divRight.Left := 847;
    divRight.Top := 21;
    divRight.Width := 298;
    divRight.Height := 524;
    divRight.ElementClassName := 'me_right';
    divRight.HeightStyle := ssAuto;
    divRight.WidthStyle := ssAuto;
    divRight.ChildOrder := 2;
    divRight.ElementPosition := epIgnore;
    divRight.ElementFont := efCSS;
    divRight.Role := '';
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 56;
    Client.Top := 60;
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    divWrapper.AfterLoadDFMValues;
    imgPageTitel.AfterLoadDFMValues;
    divMain.AfterLoadDFMValues;
    divLeft.AfterLoadDFMValues;
    wtvKurse.AfterLoadDFMValues;
    divMECenter.AfterLoadDFMValues;
    divHeader.AfterLoadDFMValues;
    btnBookmark.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    divTitelFoto.AfterLoadDFMValues;
    imgTitel.AfterLoadDFMValues;
    lblText.AfterLoadDFMValues;
    divFotos.AfterLoadDFMValues;
    frFotosliste1.AfterLoadDFMValues;
    frFotosliste1.WebHTMLDiv1.AfterLoadDFMValues;
    frFotosliste1.divWrapper.AfterLoadDFMValues;
    frFotosliste1.img.AfterLoadDFMValues;
    divVideos.AfterLoadDFMValues;
    frVideoListe1.AfterLoadDFMValues;
    frVideoListe1.WebHTMLDiv1.AfterLoadDFMValues;
    frVideoListe1.divWrapper.AfterLoadDFMValues;
    frVideoListe1.img.AfterLoadDFMValues;
    pFooter.AfterLoadDFMValues;
    btnBack.AfterLoadDFMValues;
    btnKlar.AfterLoadDFMValues;
    btnUnKlar.AfterLoadDFMValues;
    btnForward.AfterLoadDFMValues;
    divRight.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.


