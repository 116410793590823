unit ufrVideoListe;

interface

uses
  System.SysUtils, System.Variants, System.Classes,JS,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.Imaging.jpeg,
  WEBLib.Controls, WEBLib.ExtCtrls, WEBLib.WebCtrls, Data.DB,
  XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Client, uTB, uGlobal,
  Vcl.Imaging.pngimage, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.CSS,
  Web, WEBLib.Graphics, WEBLib.ComCtrls, system.Types,WEBLib.WebTools,
  Vcl.Menus, WEBLib.Menus, WEBLib.Dialogs, ufrVideosThumb;

type
  TfrVideoListe = class(TFrame)
    WebHTMLDiv1: THTMLDiv;
    divWrapper: THTMLDiv;
    Client: TXDataWebClient;
    img: TImageControl;
    [async] procedure imgClick(Sender: TObject); async;
  protected
    procedure Loaded(); override;
  private
    FImageClick:TCallback;
    FResize:boolean;
    Fimg: TImageControl;
    FVorschau,
    FSize:integer;
    FAlbumID:integer;
    FHolder, FME, FStart,
    FBlogID, FPostID,
    FKursID, FStatus,
    FParentID:integer;
    FClass,
    FContainerClass:string;
    FDoClick,
    FShowMarked:boolean;
    FAddImage:integer;
    FItems: array of TfrVideosThumb;
    procedure Save(AHolder, AAlbumID, APostId, ABlogID, AKursID, AMedienTyp, AStatus:integer; AName, AData:string);
  public
    [async] function Init(AHolder, AMe, AAlbumID, APostID, ABlogID, AKursID, AStart, AStatus: integer; AImageClick:TCallback; ADoClick:boolean = true; AAddImage:integer = 0; AShowMarked:boolean=false):integer; async;
    property AlbumId: integer read FAlbumId write FAlbumId;
    property Holder: integer read FHolder write FHolder;
    property BlogID: integer read FBlogID write FBlogID;
    property ParentID: integer read FParentID write FParentID;
    property Vorschau: integer read FVorschau write FVorschau;
    procedure thumbClick(s:string);
    [async] procedure refresh; async;
    function GetMarked:array of string;
  protected procedure LoadDFMValues; override; end;

implementation
   uses uDatenmodul, uEingabe, uSlider, uMain;
{$R *.dfm}

function TfrVideoliste.GetMarked:array of string;
Var
  i:integer;
begin
  Setlength(result, 0);

  for i := 0 to length(FItems) - 1 do
  begin
    if FItems[i].Marked.Checked
    then begin
      Setlength(result, length(result) + 1);
      result[length(result)-1] := FItems[i].Id.ToString;
    end;
  end;
end;

procedure TfrVideoliste.refresh;
begin
  await(Init(FHolder, FMe, FAlbumID, FPostID, FBlogID, FKursID, FStart, FStatus, FImageClick, FDoClick, FAddImage, FShowMarked));
end;

procedure TfrVideoliste.ThumbClick(s:string);
begin
//showmessage('thumbclick');
  if assigned(FImageClick)
  then FImageClick(s)
  else TfrmSlider.Videos(Mainform.Holder.ID, Mainform.ME.ID, FAlbumId, FPostID, FBlogId, FKursID, s.ToInteger, nil, FAddImage);
end;

function TfrVideoliste.Init(AHolder, AMe, AAlbumID, APostID, ABlogID, AKursID, AStart, AStatus: integer; AImageClick:TCallback; ADoClick:boolean = true; AAddImage:integer = 0; AShowMarked:boolean=false):integer;
Var
  i:integer;
  Response: TXDataClientResponse;
  iP, iA, iB, iK:integer;
  J:TJ;
  f: TfrVideosThumb;
  o : TControl;
begin
  FVorschau   := 0;
  FAlbumID    := AAlbumID;
  FHolder     := AHolder;
  FMe         := AMe;
  FPostID     := APostID;
  FBlogID     := ABlogID;
  FKursID     := AKursID;
  FStatus     := AStatus;
  FAlbumID    := AAlbumID;
  FDoClick    := ADoClick;
  FShowMarked := AShowMarked;
  FStart      := AStart;
  FAddImage   := AAddImage;
  FImageClick   := AImageClick;
  Setlength(FItems, 0);
  iP:=0; iA := 0; IB := 0; iK := 0;

//  if ACaption > ''
//  then divTitel.HTML.Text := ACaption;

  //festellen wofür die Videos geholt werden
  if FPostID > 0
  then iP := FPostID  //für einen Post
  else begin
    if FBlogID > 0 //für einen Blog
    then iB := FBlogID
    else  if FAlbumID > 0
          then iA := FAlbumID // für ein Album
          else iK := FKursID; // für einen Kurs
  end;

  //evtl. bereits bestehende Images entfernen
  for i := divWrapper.Owner.ComponentCount - 1 downto 0 do
  begin
    o := TControl(divWrapper.Owner.components[i]);
    if (o.Parent = divWrapper) and (o is TfrVideosThumb)
    then o.Free;
  end;

  //Image zum hinzufügen verstecken - falls NewestMedia geholt werden sollen
  img.Visible := false;

  //Videos je nach Anlass holen
  if AAlbumID = _Neuste_Medien
  then begin
    Response := Await( Client.RawInvokeAsync('IDBService.GetNewestMedia',[medVideo, Mainform.MaxNewVideos]));
  end
  else begin
    //wenn es "meine" Videos sind dann Image zum hinzufügen anzeigen
    img.Visible := (FAddImage <> 0);// and (FHolder = FMe));
    //Response := TAwait.Exec<TXDataClientResponse>( Client.RawInvokeAsync('IDBService.AlbumGetMedia',[iA, iP, iB, iK, medVideo,2, 0]));
    if AStatus = -1
    then Response := Await( Client.RawInvokeAsync('IDBService.AlbumGetMedia',[iA, iP, iB, iK, medVideo, 2, -1]))
    else Response := Await( Client.RawInvokeAsync('IDBService.AlbumGetMedia',[iA, iP, iB, iK, medVideo, 2,  0]));
  end;

  J:=TJ.create(Response);
  for i := 0 to j.Length-1 do
  begin
    j.Index := i;

    if j.integer('MEDIEN_TYP') = integer(medVideo)
    then begin
      f := await(TfrVideosThumb.Add(divWrapper, j.integer('ID'), @ThumbClick, FShowMarked));
      Setlength(FItems,length(FItems)+1);
      fItems[length(FItems)-1] := f;

      if (FVorschau = 0) or (f.Id = AStart)
      then FVorschau := f.Id;

    end;
  end;

  if FDoClick
  then ThumbClick(FStart.ToString);

  result := j.Length;

end;

//Plus geklickt
procedure TfrVideoListe.imgClick(Sender: TObject);
Var
  s:string;
begin
  s := await(TfrmEingabe.show('Youtube-Link eingeben: '));
  s := s.Substring(pos('src="',s) + 4, length(s));
  s := s.Substring(0, pos('"',s)-1);
  if s > ''
  then await(Save(FHolder, FAlbumId, FPostID, FBlogID, FKursID, integer(medVideo),      1, 'AName', s));
end;

procedure TfrVideoListe.Save(AHolder, AAlbumID, APostID, ABlogID, AKursID, AMedienTyp, AStatus:integer; AName, AData:string);

  procedure OnResponse(Response: TXDataClientResponse);
  Var
    j:TJ;
  begin
    j := TJ.create(Response);
    TfrVideosThumb.Add(divWrapper, J.integer('ID'), @ThumbClick, FShowMarked);
  end;

var
  JSVal: JSValue;
  JSObj: TJSObject;
  JSString:string;
  iOwner, iAlbum, iGrp:integer;
  sHolder_typ, sParent:string;
begin

  Try

      if APostID > 0
      then begin
        sHolder_Typ := _HT_Post;
        sParent:= FPostID.ToString;
      end
      else  if AKursID > 0
            then begin
              sHolder_Typ := _HT_Kurs;
              sParent:= FKursID.ToString;
            end
            else  if ABlogID > 0
                  then begin
                    sHolder_Typ := _HT_Blog;
                    sParent:= FBlogID.ToString;
                  end
                  else begin
                    sHolder_Typ := _HT_Album;
                    sParent:= AAlbumID.ToString;
                  end;


    JSString:= '{"ALBUM": '    + AAlbumID.ToString + ', ' +
                '"PARENT_ID":' + sParent + ', ' +
                '"HOLDER_ID":' + AHolder.ToString + ', ' +
                '"HOLDER_TYP":'+ sHolder_Typ + ', ' +
                '"MEDIEN_TYP":'+ AMedienTyp.ToString + ', ' +
                '"NAME": "'    + AName + '", ' +
                '"STATUS": '   + AStatus.ToString + ', ' +
                '"SIZE1": "'   + AData + '"' + ',' +
                '"SIZE2": "'   + AData + '"' + ',' +
                '"SIZE3": "'   + AData + '"' +
                '}';

    JSObj:=TJSJSON.parseObject(JSString);
    Client.RawInvoke('IDBService.MediaNew',[JSObj], @OnResponse);
  Except
    MessageDlg('Es hat einen Fehler beim Speichern gegeben',WEBLib.Dialogs.mtError, [mbOK]);
    Exit;
  End;

end;

procedure TfrVideoListe.Loaded();
var
  el: TJSHTMLElement;
begin
  inherited;
  TControl(self).ElementPosition := epIgnore;
//  TWebControl(self).ElementID := 'frVideoListe';
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.setProperty('height','');
  el.style.setProperty('width','');
  el.style.setProperty('font-family','');
  el.style.setProperty('font-style','');
  el.style.setProperty('font-size','');
end;

procedure TfrVideoListe.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  divWrapper := THTMLDiv.Create(Self);
  img := TImageControl.Create(Self);
  Client := TXDataWebClient.Create(Self);

  WebHTMLDiv1.BeforeLoadDFMValues;
  divWrapper.BeforeLoadDFMValues;
  img.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frVideoListe';
    Left := 0;
    Top := 0;
    Width := 188;
    Height := 137;
    Margins.Left := 0;
    Margins.Top := 0;
    Margins.Right := 0;
    Margins.Bottom := 0;
    TabOrder := 0;
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 0;
    WebHTMLDiv1.Top := 3;
    WebHTMLDiv1.Width := 177;
    WebHTMLDiv1.Height := 126;
    WebHTMLDiv1.ElementClassName := 'alben_center_wrapper';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ChildOrder := 1;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    divWrapper.SetParentComponent(WebHTMLDiv1);
    divWrapper.Name := 'divWrapper';
    divWrapper.Left := 16;
    divWrapper.Top := 16;
    divWrapper.Width := 137;
    divWrapper.Height := 97;
    divWrapper.ElementClassName := 'media_content_wrapper';
    divWrapper.HeightStyle := ssAuto;
    divWrapper.WidthStyle := ssAuto;
    divWrapper.ChildOrder := 1;
    divWrapper.ElementPosition := epIgnore;
    divWrapper.ElementFont := efCSS;
    divWrapper.Role := '';
    img.SetParentComponent(divWrapper);
    img.Name := 'img';
    img.Left := 3;
    img.Top := 3;
    img.Width := 130;
    img.Height := 90;
    img.ElementClassName := 'media_new';
    img.HeightStyle := ssAuto;
    img.WidthStyle := ssAuto;
    img.HeightPercent := 100.000000000000000000;
    img.WidthPercent := 100.000000000000000000;
    img.ChildOrder := 1;
    img.ElementFont := efCSS;
    img.ElementPosition := epIgnore;
    SetEvent(img, Self, 'OnClick', 'imgClick');
    img.Picture.LoadFromFile('ufrVideoListe.divWrapper.img.Picture.png');
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 64;
    Client.Top := 32;
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    divWrapper.AfterLoadDFMValues;
    img.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.

