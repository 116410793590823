unit ufrDoks;

interface

uses
  System.SysUtils, System.Variants, System.Classes,JS,Web,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.Imaging.jpeg,
  WEBLib.Controls, WEBLib.ExtCtrls, WEBLib.WebCtrls, Data.DB, uTB, uGlobal,
  XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Client, WEBLib.CDS,
  WEBLib.Graphics, WEBLib.Forms, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.Lists;

type
  TfrDoks = class(TFrame)
    divWrapper: THTMLDiv;
    WebHTMLDiv2: THTMLDiv;
    [async]
    divLektionen: THTMLDiv;
    wlLektionen: TListControl;
    WebHTMLDiv1: THTMLDiv;
    divTutorial: THTMLDiv;
    wlTutorial: TListControl;
    WebHTMLDiv4: THTMLDiv;
    procedure wlItemClick(Sender: TObject; AListItem: TListItem);
  private
  protected
    procedure Loaded(); override;
  public
    public constructor Create(AOwner:TComponent);Override;
    [async] procedure Init; async;
  protected procedure LoadDFMValues; override; end;

implementation
   Uses uMain, uDatenmodul, uBlog;
{$R *.dfm}

constructor TfrDoks.Create(AOwner: TComponent);
begin
  inherited;
end;

procedure TfrDoks.Init;
Var
  Response: TXDataClientResponse;
  i, id:integer;
  img:TImageControl;
  J:TJ;
  client1 : TXDataWebClient;
  d:THTMLDiv;
  li: TListItem;
  s:string;

begin

  J := TJ.create(await(datenmodul.EasySQL('select Kurse.ID, Kurse.HEADER from Medien2Holder ' +
                                          '  JOIN Kurse on Kurse.ID = medien2Holder.MEDIEN_ID ' +
                                          ' Where HOLDER_ID = ' + Mainform.Holder.ID.ToString + ' AND ' +
                                          '       HOLDER_TYP= ' + (MainForm.Holder.Typ + 6).ToString + ' AND ' +
                                          '       MEDIEN_TYP= ' + inttostr(ord(medLektion)) +
                                          ' ORDER BY HOLDER_TYP' )));
  wlLektionen.Items.Clear;
  for i := 0 to J.Length -1 do
  begin
    j.index := i;
    li := wlLektionen.Items.Add;
    li.Text := j.Text('HEADER');
    li.Tag  := j.integer('ID');
  end;
  divLektionen.Visible := wlLektionen.Items.Count > 0;

  J.Response := await(datenmodul.EasySQL('select Kurse.ID, Kurse.HEADER from Medien2Holder ' +
                                         '  JOIN Kurse on Kurse.ID = medien2Holder.MEDIEN_ID ' +
                                         ' Where HOLDER_ID = ' + Mainform.Holder.ID.ToString + ' AND ' +
                                         '       HOLDER_TYP= ' + (MainForm.Holder.Typ + 6).ToString + ' AND ' +
                                         '       MEDIEN_TYP= ' + inttostr(ord(medTutorial)) +
                                         ' ORDER BY HOLDER_TYP' ));

  wlTutorial.Items.Clear;
  for i := 0 to J.Length -1 do
  begin
    j.index := i;
    li := wlTutorial.Items.Add;
    li.Text := j.Text('HEADER');
    li.Tag  := j.integer('ID');
  end;
  divTutorial.Visible := wlTutorial.Items.Count > 0;

end;

procedure TfrDoks.Loaded();
var
  el: TJSHTMLElement;
begin
  inherited;

  TControl(self).ElementPosition := epIgnore;
  TControl(self).ElementID := 'frDoks';
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.setProperty('height','');
  el.style.setProperty('width','');
  el.style.setProperty('font-family','');
  el.style.setProperty('font-style','');
  el.style.setProperty('font-size','');

  //init;
end;

procedure TfrDoks.wlItemClick(Sender: TObject; AListItem: TListItem);
begin
  if sender = wlLektionen
  then openUrl(mainform.ME.ID.ToString, mainform.Holder.ID.ToString, _call_Kurse, _KA_Lektion.ToString, AListItem.Tag.ToString)
  else openUrl(mainform.ME.ID.ToString, mainform.Holder.ID.ToString, _call_Kurse, _KA_Tutorial.ToString, AListItem.Tag.ToString);
end;

procedure TfrDoks.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divWrapper := THTMLDiv.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  divLektionen := THTMLDiv.Create(Self);
  wlLektionen := TListControl.Create(Self);
  WebHTMLDiv1 := THTMLDiv.Create(Self);
  divTutorial := THTMLDiv.Create(Self);
  wlTutorial := TListControl.Create(Self);
  WebHTMLDiv4 := THTMLDiv.Create(Self);

  divWrapper.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  divLektionen.BeforeLoadDFMValues;
  wlLektionen.BeforeLoadDFMValues;
  WebHTMLDiv1.BeforeLoadDFMValues;
  divTutorial.BeforeLoadDFMValues;
  wlTutorial.BeforeLoadDFMValues;
  WebHTMLDiv4.BeforeLoadDFMValues;
  try
    Name := 'frDoks';
    Left := 0;
    Top := 0;
    Width := 346;
    Height := 364;
    Margins.Left := 0;
    Margins.Top := 0;
    Margins.Right := 0;
    Margins.Bottom := 0;
    TabOrder := 0;
    divWrapper.SetParentComponent(Self);
    divWrapper.Name := 'divWrapper';
    divWrapper.Left := 3;
    divWrapper.Top := 0;
    divWrapper.Width := 334;
    divWrapper.Height := 321;
    divWrapper.ElementClassName := 'me_frame_container';
    divWrapper.HeightStyle := ssAuto;
    divWrapper.WidthStyle := ssAuto;
    divWrapper.ChildOrder := 9;
    divWrapper.ElementPosition := epIgnore;
    divWrapper.ElementFont := efCSS;
    divWrapper.Role := '';
    WebHTMLDiv2.SetParentComponent(divWrapper);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 3;
    WebHTMLDiv2.Top := 3;
    WebHTMLDiv2.Width := 318;
    WebHTMLDiv2.Height := 49;
    WebHTMLDiv2.ElementClassName := 'me_headline';
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ChildOrder := 1;
    WebHTMLDiv2.ElementPosition := epRelative;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.HTML.BeginUpdate;
    try
      WebHTMLDiv2.HTML.Clear;
      WebHTMLDiv2.HTML.Add('Meine Dokumente');
      WebHTMLDiv2.HTML.Add('');
    finally
      WebHTMLDiv2.HTML.EndUpdate;
    end;
    WebHTMLDiv2.Role := '';
    divLektionen.SetParentComponent(divWrapper);
    divLektionen.Name := 'divLektionen';
    divLektionen.Left := 3;
    divLektionen.Top := 58;
    divLektionen.Width := 318;
    divLektionen.Height := 55;
    divLektionen.ElementClassName := 'search-list';
    divLektionen.HeightStyle := ssAuto;
    divLektionen.WidthStyle := ssAuto;
    divLektionen.ChildOrder := 1;
    divLektionen.ElementPosition := epIgnore;
    divLektionen.ElementFont := efCSS;
    divLektionen.Role := '';
    wlLektionen.SetParentComponent(divLektionen);
    wlLektionen.Name := 'wlLektionen';
    wlLektionen.Left := 3;
    wlLektionen.Top := 31;
    wlLektionen.Width := 300;
    wlLektionen.Height := 23;
    wlLektionen.HeightStyle := ssAuto;
    wlLektionen.WidthStyle := ssAuto;
    wlLektionen.HeightPercent := 100.000000000000000000;
    wlLektionen.WidthPercent := 100.000000000000000000;
    wlLektionen.ChildOrder := 1;
    wlLektionen.ElementFont := efCSS;
    wlLektionen.ElementPosition := epIgnore;
    wlLektionen.Items.Clear;
    with wlLektionen.Items.Add do
    begin
    end;
    SetEvent(wlLektionen, Self, 'OnItemClick', 'wlItemClick');
    WebHTMLDiv1.SetParentComponent(divLektionen);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 3;
    WebHTMLDiv1.Top := 3;
    WebHTMLDiv1.Width := 100;
    WebHTMLDiv1.Height := 22;
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.HTML.BeginUpdate;
    try
      WebHTMLDiv1.HTML.Clear;
      WebHTMLDiv1.HTML.Add('Lektionen');
    finally
      WebHTMLDiv1.HTML.EndUpdate;
    end;
    WebHTMLDiv1.Role := '';
    divTutorial.SetParentComponent(divWrapper);
    divTutorial.Name := 'divTutorial';
    divTutorial.Left := 3;
    divTutorial.Top := 119;
    divTutorial.Width := 318;
    divTutorial.Height := 55;
    divTutorial.ElementClassName := 'search-list';
    divTutorial.HeightStyle := ssAuto;
    divTutorial.WidthStyle := ssAuto;
    divTutorial.ChildOrder := 2;
    divTutorial.ElementPosition := epIgnore;
    divTutorial.ElementFont := efCSS;
    divTutorial.Role := '';
    wlTutorial.SetParentComponent(divTutorial);
    wlTutorial.Name := 'wlTutorial';
    wlTutorial.Left := 0;
    wlTutorial.Top := 31;
    wlTutorial.Width := 300;
    wlTutorial.Height := 23;
    wlTutorial.HeightStyle := ssAuto;
    wlTutorial.WidthStyle := ssAuto;
    wlTutorial.HeightPercent := 100.000000000000000000;
    wlTutorial.WidthPercent := 100.000000000000000000;
    wlTutorial.ChildOrder := 1;
    wlTutorial.ElementFont := efCSS;
    wlTutorial.ElementPosition := epIgnore;
    wlTutorial.Items.Clear;
    with wlTutorial.Items.Add do
    begin
    end;
    SetEvent(wlTutorial, Self, 'OnItemClick', 'wlItemClick');
    WebHTMLDiv4.SetParentComponent(divTutorial);
    WebHTMLDiv4.Name := 'WebHTMLDiv4';
    WebHTMLDiv4.Left := 3;
    WebHTMLDiv4.Top := 3;
    WebHTMLDiv4.Width := 100;
    WebHTMLDiv4.Height := 22;
    WebHTMLDiv4.HeightStyle := ssAuto;
    WebHTMLDiv4.WidthStyle := ssAuto;
    WebHTMLDiv4.ElementPosition := epIgnore;
    WebHTMLDiv4.ElementFont := efCSS;
    WebHTMLDiv4.HTML.BeginUpdate;
    try
      WebHTMLDiv4.HTML.Clear;
      WebHTMLDiv4.HTML.Add('Anleitungen');
    finally
      WebHTMLDiv4.HTML.EndUpdate;
    end;
    WebHTMLDiv4.Role := '';
  finally
    divWrapper.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    divLektionen.AfterLoadDFMValues;
    wlLektionen.AfterLoadDFMValues;
    WebHTMLDiv1.AfterLoadDFMValues;
    divTutorial.AfterLoadDFMValues;
    wlTutorial.AfterLoadDFMValues;
    WebHTMLDiv4.AfterLoadDFMValues;
  end;
end;

end.
