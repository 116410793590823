unit uPrint;

interface
  uses   System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, uTb, XData.Web.Client, Vcl.Controls,
  WEBLib.WebCtrls, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Imaging.pngimage,
  WEBLib.ExtCtrls, WEBLib.Buttons, WEBLib.ComCtrls;

const
  _CenterB = '<div style="text-align: center;">';
  _CenterE = '</div>';
  _ItalicB = 'font-style: italic;';

var
  ImageURLs: TArray<integer>;

type
  TPrint = class
      FHTML:String;
      FImg : TImageControl;
      procedure imgLoaded(Sender: TObject);
    private
      [async] procedure printIt(ATitel, AText:string); async;
    public
      [async] class procedure printProtokoll(AUser:integer; AName:string; ACallback:TCallback = nil); async;
      property HTML:String read FHTML write FHTML;
  end;


implementation
  uses uDatenmodul, uMain;

procedure TPrint.PrintIt(ATitel, AText:string);
begin
//  asm
//    /*var sContent = document.getElementById('IDTextText').innerHTML;*/
//
//    var sContent = AText;
//    var printWindow = document.createElement('iframe');
//
//    printWindow.style.position = 'fixed';
//    printWindow.style.top = '-1000px';
//    printWindow.style.left = '-1000px';
//    document.body.appendChild(printWindow);
//
//    printWindow.contentDocument.write('<html><head><title>' + ATitel + '</title>');
//    printWindow.contentDocument.write('<style>');
//    printWindow.contentDocument.write('.overline { position: relative; display: inline-block; }');
//    printWindow.contentDocument.write('.overline::before {content: ""; position: absolute; top: 50%; left: -5px; right: -5px; border-top: 1px solid black;}');
//
//    printWindow.contentDocument.write('</style></head><body>');
//
//    printWindow.contentDocument.write(sContent);
//    printWindow.contentDocument.write('</body></html>');
//    printWindow.contentDocument.close();
//    printWindow.contentWindow.focus();
//    printWindow.contentWindow.print();
//    document.body.removeChild(printWindow);
//  end;
//showmessage('Druck');
//  asm
//    var printWindow = window.open('', '_blank');
//    printWindow.document.write('<html><head><title>' + ATitel + '</title>');
//    printWindow.document.write('<style>');
//    printWindow.document.write('.overline { position: relative; display: inline-block; }');
//    printWindow.document.write('.overline::before {content: ""; position: absolute; top: 50%; left: -5px; right: -5px; border-top: 1px solid black;}');
//    printWindow.document.write('</style></head><body>');
//    printWindow.document.write(AText);
//    printWindow.document.write('</body></html>');
//    printWindow.document.close();
//
//    // Setze die Position des Fensters außerhalb des Bildschirms
//    printWindow.moveTo(-1000, -1000);
//
//    printWindow.focus();
//    printWindow.print();
//    printWindow.close();
//  end;
//end;
  asm
    var printFrame = document.createElement('iframe');
    printFrame.style.position = 'fixed';
    printFrame.style.top = '-1000px';
    printFrame.style.left = '-1000px';
    document.body.appendChild(printFrame);

    var printDocument = printFrame.contentDocument || printFrame.contentWindow.document;
    printDocument.write('<html><head><title>' + ATitel + '</title>');
    printDocument.write('<style>');
    printDocument.write('.overline { position: relative; display: inline-block; }');
    printDocument.write('.overline::before {content: ""; position: absolute; top: 50%; left: -5px; right: -5px; border-top: 1px solid black;}');
    printDocument.write('</style></head><body>');
    printDocument.write(AText);
    printDocument.write('</body></html>');
    printDocument.close();

    printFrame.contentWindow.focus();
    printFrame.contentWindow.print();

    // Verzögerung, um sicherzustellen, dass der Druckvorgang abgeschlossen ist, bevor das iframe entfernt wird
    setTimeout(function() {
        document.body.removeChild(printFrame);
    }, 1000); // Ändern Sie die Verzögerung bei Bedarf
  end;

end;

procedure TPrint.imgLoaded(Sender: TObject);
begin
end;

class procedure TPrint.PrintProtokoll(AUser:integer; AName:string; ACallback:TCallback = nil);
Var J:TJ;
    i:integer;
    sLine, sLvl1, slvl2,
    sSQl, sLogo, s,
    sImage, sKursArt, sVor:string;
    oPrint:TPrint;
//    sText:TStringList;

begin
  oPrint := TPrint.Create;

  j := TJ.create(await(datenModul.EasySQL('SELECT LOGO_MEDIEN_ID from SYSTEM')));

  oPrint.FImg:=TImageControl.Create(mainform);
  oPrint.Fimg.Parent := Mainform;
  oPrint.FImg.Visible := false;
  //Fimg.OnLoaded := imgLoaded;
  sImage := J.value('LOGO_MEDIEN_ID');
//  oPrint.Fimg.url := await(datenmodul.GetMedia(j.Integer('LOGO_MEDIEN_ID'),_Size2));
//  showmessage(sImage);
  sKursArt := '1';
  sVor     := '2';

  sSQl := 'SELECT k1.header as H1, K1.TEXT as T1, k1.ID as ID1, k1.MEDIEN_ID as M1, k1.MEDIEN_POSITION as P1, ' +
          '       k2.header as H2, K2.TEXT as T2, k2.ID as ID2, k2.MEDIEN_ID as M2, k2.MEDIEN_POSITION as P2, ' +
          '       k3.header as H3, K3.TEXT as T3, k3.ID as ID3, k3.MEDIEN_ID as M3, k3.MEDIEN_POSITION as P3, ' +
          '       k4.header as H4, K4.TEXT as T4, k4.ID as ID4, k4.MEDIEN_ID as M4, k4.MEDIEN_POSITION as P4, ' +
          '       k5.header as H5, K5.TEXT as T5, k5.ID as ID5, k5.MEDIEN_ID as M5, k5.MEDIEN_POSITION as P5  ' +
          '  from kurse k1 ' +
          '  left JOIN kurse k2 ON (k1.ID = k2.vor AND k1.art = ' + sKursArt + ') ' +
          '  left JOIN kurse k3 ON (k2.ID = k3.vor AND k1.art = ' + sKursArt + ') ' +
          '  left JOIN kurse k4 ON (k3.ID = k4.vor AND k1.art = ' + sKursArt + ') ' +
          '  left JOIN kurse k5 ON (k4.ID = k5.vor AND k1.art = ' + sKursArt + ') ' +
          ' WHERE k1.art = ' + sKursArt + ' AND k1.VOR =  ' + sVor +
          ' ORDER BY k1.vor, k1.ID, k2.vor,k2.ID, k3.vor,k3.ID, k4.vor, k4.ID, k5.vor, k5.ID';

  J.response := await(datenModul.EasySQL(sSQL));
// <img src="https://www.segler-portal.de:10002/DBService/GetImage?Mediaid=1222&Size=2" style="float: left;" width="60" height="60">

//  s := '<img src="' + sImage + '" style="float: left;" width="60" height="60">';
  sImage := '"https://www.segler-portal.de:10002/DBService/GetImage?Mediaid=' + sImage + '&Size=2" width="60" height="60"';
  s := '<img src=' + sImage + '>';
//  <img src="https://www.segler-portal.de:10002/DBService/GetImage?Mediaid=1222&Size=2" align=left width="60" height="60">

  s := s + _CenterB + '<span style="' + _ItalicB +' font-size: 18px;">Protokoll zur</span>' + _CenterE;
  s := s + _CenterB + '<span style="' + _ItalicB +' font-size: 20px;">Online-Sicherheitseinweisung</span>'+ _CenterE;
  s := s + _CenterB + '<span style="' + _ItalicB +' font-size: 18px;">bei Segler-Portal.de</span>'+ _CenterE;
  s := s + _CenterB + '<span style="' + _ItalicB +' font-size: 17px;">für ' + AName + '</span>'+ _CenterE;
  s := s + '<br>';
//  showmessage(s);

  for i := 0 to j.Length-1 do
  begin
    j.Index := i;

    if sLvl1 <> j.Value('H1')
    then begin
      if sLine > ''
      then s := s +  _CenterB + '<span style="font-size: 15px;">' + sLine + '</span>'+ _CenterE + '<br>';

      sLine := '';

      sLvl1 := j.Value('H1');
      s := s + _CenterB + '<span style="font-size: 17px;">' + slvl1 + ':</span>'+ _CenterE;

      sLvl2 := j.Value('H2');
      sLine := j.Value('H2');

    end;

    if sLvl2 <> j.Value('H2')
    then begin
      sLvl2 := j.Value('H2');
      sLine := sLine + ', ' + j.Value('H2');
    end;

  end;

  if (sLvl2 <> j.Value('H2')) and (sLine > '')
  then s := s + _CenterB + '<span style="font-size: 15px;">' + sLine + '</span>'+ _CenterE + '<br>';

  //gibt es Fragen
  J.response := await(datenModul.EasySQL('SELECT * from faq ' +
                                         '  join Kurse on kurse.ID = ITEM_ID' +
                                         ' WHERE User_ID = ' + AUser.tostring));

  if j.hasValue //wenn ja- dann drucken
  then begin
    s := s + _CenterB + '<br><br><span style="font-size: 15px;"><strong>Fragen und Unklarheiten:</strong></span>'+ _CenterE + '<br>';

    for i := 0 to j.Length-1 do
    begin
      j.Index := i;
      s := s + _CenterB + '<span style="font-size: 17px;">' + j.Value('HEADER') + ':</span>'+ _CenterE;
      s := s + _CenterB + '<span style="font-size: 15px;">' + j.Value('QUESTION') + '</span>'+ _CenterE + '<br>';
    end;

    s := s + '<br>';
  end;

  s := s + _CenterB + '<span style="' + _ItalicB +' font-size: 12px;">' +
            'Mit meiner Unterschrift bestätige ich, alle Themen verstanden ' +
            'und mir die jeweiligen Videos komplett angeschaut zu haben. ' +
            'Eventuelle Unklarheiten und offene Fragen wurden mir vom verantwortlichen ' +
            'Schiffsführer vollständig und verständlich beantwortet.</span>'+ _CenterE;

  s := s + '<br><br><br><br>' +  _CenterB + '<span style="text-decoration: overline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+ AName + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' + _CenterE;
  oPrint.PrintIt('Protokoll', s);
  oPrint.HTML := s;
  FreeAndNil(oPrint.FImg);

  if assigned(ACallback)
  then ACallback(sVor);

end;

end.
