{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2021 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCWXPDFViewer.Common;

{$I WEBLib.TMSFNCDefines.inc}

interface

const
  // HTML TAG
  LB = #13;
  METACHARSETTAG = '<meta charset="UTF-8">';
  HTMLLANGTAG = '<html lang="en">';

  CONTROLID = '#TTMSFNCWXPDFVIEWERID#';
  DATA_OBJECT = '#TTMSFNCWXPDFVIEWERDATAOBJECT#';

  //PDF VIEWER CSS
  PDFVIEWERCSS =  '#' + CONTROLID + '_pdf-main-container {' + LB +
    '  width: 100%;' + LB +
    '  height: 100%;' + LB +
    '  margin: auto;' + LB +
    '}' + LB +
    LB +
    '#' + CONTROLID + '_pdf-contents {' + LB +
    '  display: none;' + LB +
    '}' + LB +
    LB +
    '#' + CONTROLID + '_pdf-canvas {' + LB +
    '  border: 1px solid rgba(0,0,0,0.2);' + LB +
    '  box-sizing: border-box;' + LB +
    '  position: relative;' + LB +
    '}' + LB +
    LB +
    '#' +  CONTROLID+ '_annotation-layer {' + LB +
    '  position: absolute;' + LB +
    '  left: 0;' + LB +
    '  top: 0;' + LB +
    '  right: 0;' + LB +
    '  bottom: 0;' + LB +
    '  overflow: hidden;' + LB +
    '  opacity: 0.2;' + LB +
    '  line-height: 1.0;' + LB +
    '}' + LB +
    LB +
    '#' + CONTROLID + '_annotation-layer > section {' + LB +
    '  color: transparent;' + LB +
    '  position: absolute;' + LB +
    '  white-space: pre;' + LB +
    '  cursor: text;' + LB +
    '  transform-origin: 0% 0%;' + LB +
    '}' + LB +
    LB +
    '#' + CONTROLID + '_annotation-layer > .linkAnnotation > a {' + LB +
    '  position: absolute;' + LB +
    '    font-size: 1em;' + LB +
    '    top: 0;' + LB +
    '    left: 0;' + LB +
    '    width: 100%;' + LB +
    '    height: 100%;' + LB +
    '}';

  // PDF VIEWER INFOS
  PDFVIEWERHTMLCODE =  '<div id="' + CONTROLID + '_pdf-main-container">' + LB +
    '  <div id="' + CONTROLID + '_pdf-contents">' + LB +
    '    <canvas id="' + CONTROLID + '_pdf-canvas" style="border: 1px solid black; direction: ltr; margin-right: auto; margin-left: auto; display: block;"></canvas>' + LB +
    '    <div id="' + CONTROLID + '_annotation-layer"></div>' + LB +
    '  </div>' + LB +
    '</div>';

  // PDF VIEWER EVENTS
  ONPAGELOADEDEVENT = 'ONPAGELOADEDEVENT';
  ONPDFLOADEDEVENT = 'ONPDFLOADEDEVENT';
  ONPAGERENDEREDEVENT = 'ONPAGERENDEREDEVENT';
  ONPDFLOADERROREVENT = 'ONPDFLOADERROREVENT';
  ONGENERATETHUMBNAILEVENT = 'ONGENERATETHUMBNAILEVENT';
  ONHYPERLINKCLICKEVENT = 'ONHYPERLINKCLICKEVENT';
  ONMOUSEDOWNEVENT = 'ONMOUSEDOWNEVENT';
  ONMOUSEUPEVENT = 'ONMOUSEUPEVENT';
  ONMOUSEMOVEEVENT = 'ONMOUSEMOVEEVENT';
  ONFOUNDSEARCHTEXT = 'ONFOUNDSEARCHTEXT';
  ONGENERATEIMAGEPAGE = 'ONGENERATEIMAGEPAGE';
  ONTHUMBNAILPROGRESSEVENT = 'ONTHUMBNAILPROGRESSEVENT';
  ONNOTFOUNDSEARCHTEXT = 'ONNOTFOUNDSEARCHTEXT';
  ONFOUNDALLSEARCHTEXT = 'ONFOUNDALLSEARCHTEXT';
  ONBROWSERSCROLLEVENT = 'ONBROWSERSCROLLEVENT';
  ONPDFPAGEGETSTRING = 'ONPDFPAGEGETSTRING';
  ONPAGESIZESEVENT = 'ONPDFPAGESIZES';
  ONGETFORMFIELDS = 'ONGETFORMFIELDS';

  // PDF VIEWER JS CODE
  PDFVIEWERJSVAR = 'var ' + CONTROLID + '__PDF_DOC,' + LB +
    '  ' + CONTROLID + '__THUMBNAILS_LOADED,' + LB +
    '  testHeight, testwidth,' + LB +
    '  ' + CONTROLID + '__CURRENT_PAGE,' + LB +
    '  ' + CONTROLID + '__TOTAL_PAGES,' + LB +
    '  ' + CONTROLID + '__PAGE_RENDERING_IN_PROGRESS = 0,' + LB +
    '  ' + CONTROLID + '__CANVAS,' + LB +
    '  ' + CONTROLID + '__CANVAS_CTX;' + LB +
    '   var ' + CONTROLID + 'myEventBus, ' + CONTROLID + 'myLinkService;' + LB;

  THUMBNAILSIZETAG = '%THUMBNAILSIZE%';

  PDFVIEWERJSMAKETHUMB = '  function ' + CONTROLID + 'makeThumb(page) {' + LB +
    '    var vp = page.getViewport({scale: 1});' + LB +
    '    var canvas = document.createElement("canvas");' + LB +
    '    if (vp.height > vp.width) {' + LB +
    '      canvas.height = ' + THUMBNAILSIZETAG + ';' + LB +
    '      canvas.width = canvas.height * (vp.width / vp.height);' + LB +
    '    } else {' + LB +
    '      canvas.width = ' + THUMBNAILSIZETAG + ';' + LB +
    '      canvas.height = canvas.width * (vp.height / vp.width);' + LB +
    '    }' + LB +
    '    var vpscale = Math.min(canvas.width / vp.width, canvas.height / vp.height);' + LB +
    '    return page.render({canvasContext: canvas.getContext("2d"), viewport: page.getViewport({scale: vpscale})}).promise.then(function () {' + LB +
    '      return canvas;' + LB +
    '    });' + LB +
    '  }' + LB;

  PDFVIEWERJSGETFORMFIELDS = '  function ' + CONTROLID + 'getFormFields() {' + LB +
    '    ' + CONTROLID + '__PDF_DOC.getFieldObjects().then(function (fields) {' + LB +
    '      var o = ' + DATA_OBJECT + ';' + LB +
    '      o["EventName"] = "' + ONGETFORMFIELDS + '";' + LB +
    '      var customdata = [fields]' + LB +
    '      var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '      ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '    });' + LB +
    '  }' + LB;


  PDFVIEWERJSIMAGEPAGE = '  function ' + CONTROLID + 'GenerateImageActualPage() {' + LB +
    '    var dataURL = ' + CONTROLID + '__CANVAS.toDataURL();' + LB +
    '    var o = ' + DATA_OBJECT + ';' + LB +
    '    o["EventName"] = "' + ONGENERATEIMAGEPAGE + '";' + LB +
    '    var customdata = [' + CONTROLID + '__CANVAS.width, ' + CONTROLID + '__CANVAS.height, dataURL]' + LB +
    '    var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '    ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '  }' + LB;

  PDFVIEWERJSSEARCHTEXT = '  function ' + CONTROLID + 'searchPage(pageNumber, TextToSearch) {' + LB +
    '     return ' + CONTROLID + '__PDF_DOC.getPage(pageNumber).then(function (page) {' + LB +
    '       return page.getTextContent();' + LB +
    '     }).then(function (content) {' + LB +
    '       var text = content.items.map(function (i) { return i.str; }).join('''');' + LB +
    '       var re = new RegExp("(.{0,20})" + TextToSearch + "(.{0,20})", "gi"), m;' + LB +
    '       var lines = [];' + LB +
    '       while (m = re.exec(text)) {' + LB +
    '         var line = (m[1] ? "..." : "") + m[0] + (m[2] ? "..." : "");' + LB +
    '         lines.push(line);' + LB +
    '       }' + LB +
    '       return {page: pageNumber, items: lines};' + LB +
    '     });' + LB +
    '   }' + LB +
    '  function ' + CONTROLID + 'SearchText(TextToSearch) {' + LB +
    '    var results = [];' + LB +
    '    for (var i = 1; i <= ' + CONTROLID + '__PDF_DOC.numPages; i++) {' + LB +
    '      results.push(' + CONTROLID + 'searchPage(i, TextToSearch));' + LB +
    '    }' + LB +
    '    Promise.all(results).then((searchResults) => {' + LB +
    '      if (searchResults.length == 1 && searchResults[0].items.length == 0) {' + LB +
    '        var o = ' + DATA_OBJECT + ';' + LB +
    '        o["EventName"] = "' + ONNOTFOUNDSEARCHTEXT + '";' + LB +
    '        ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o));' + LB +
    '      } else {' + LB +
    '        var firstMatch = searchResults[0].page;' + LB +
    '        var allMatches = 0;' + LB +
    '        searchResults.forEach(function (result) {' + LB +
    '          if (firstMatch > result.page) { firstMatch = result.page }' + LB +
    '          allMatches += result.items.length;' + LB +
    '          var o = ' + DATA_OBJECT + ';' + LB +
    '          o["EventName"] = "' + ONFOUNDSEARCHTEXT + '";' + LB +
    '          var customdata = [result.page, JSON.stringify(result.items)]' + LB +
    '          var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '          ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '        });' + LB +
    '        var o = ' + DATA_OBJECT + ';' + LB +
    '        o["EventName"] = "' + ONFOUNDALLSEARCHTEXT + '";' + LB +
    '        var customdata = [allMatches, firstMatch]' + LB +
    '        var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '        ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '      }' + LB +
    '    });' + LB +
    '  }' + LB;

  PDFVIEWERMOUSEZOOM =
    '  function ' + CONTROLID + 'StopWheelZoom(event) {' + LB +
    '    if (event.ctrlKey == true) {' + LB +
    '      event.preventDefault();' + LB +
    '    }' + LB +
    '    var o = ' + DATA_OBJECT + ';' + LB +
    '    o["EventName"] = "' + ONBROWSERSCROLLEVENT + '";' + LB +
    '    var customdata = [event.altKey, event.ctrlKey, event.shiftKey, event.wheelDelta]' + LB +
    '    var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '    ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '  }' + LB +
    '  document.addEventListener("mousewheel", ' + CONTROLID + 'StopWheelZoom, { passive: false });' + LB +
	  '  document.addEventListener("DOMMouseScroll", ' + CONTROLID + 'StopWheelZoom, { passive: false });' + LB;

  PDFVIEWERJSGENERATETHUMBNAILS =
    '  function ' + CONTROLID + 'generateThumbnails() {' + LB +
    '    ' + CONTROLID + '__THUMBNAILS_LOADED = 0;' + LB +
    '    var pages = []; while (pages.length < ' + CONTROLID + '__TOTAL_PAGES) pages.push(pages.length + 1);' + LB +
    '      pages.map(function (num) {' + LB +
    '        return Promise.all([' + CONTROLID + '__PDF_DOC.getPage(num).then(' + CONTROLID + 'makeThumb)' + LB +
    '          .then(function (canvas) {' + LB +
    '            ' + CONTROLID + '__THUMBNAILS_LOADED++;' + LB +
    '            var dataURL = canvas.toDataURL();' + LB +
    '            var o = ' + DATA_OBJECT + ';' + LB +
    '            o["EventName"] = "' + ONGENERATETHUMBNAILEVENT + '";' + LB +
    '            var customdata = [num, dataURL]' + LB +
    '            var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '            ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '            o["EventName"] = "' + ONTHUMBNAILPROGRESSEVENT + '";' + LB +
    '            customdata = [' + CONTROLID + '__THUMBNAILS_LOADED, ' + CONTROLID + '__TOTAL_PAGES]' + LB +
    '            cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '            ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '        })]);' + LB +
    '      })' + LB +
    '  };' + LB;

  PDFVIEWERJSGENERATETHUMBNAILRANGE =
    '  function ' + CONTROLID + 'generateThumbnailRange(startPage, endPage) {' + LB +
    '    ' + CONTROLID + '__THUMBNAILS_LOADED = 0;' + LB +
    '    var pages = [];' + LB +
    '      for (var i= startPage; i<=endPage; i++) pages.push(i);' + LB +
    '      pages.map(function (num) {' + LB +
    '        return Promise.all([' + CONTROLID + '__PDF_DOC.getPage(num).then(' + CONTROLID + 'makeThumb)' + LB +
    '          .then(function (canvas) {' + LB +
    '            ' + CONTROLID + '__THUMBNAILS_LOADED++;' + LB +
    '            var dataURL = canvas.toDataURL();' + LB +
    '            var o = ' + DATA_OBJECT + ';' + LB +
    '            o["EventName"] = "' + ONGENERATETHUMBNAILEVENT + '";' + LB +
    '            var customdata = [num, dataURL]' + LB +
    '            var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '            ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '            o["EventName"] = "' + ONTHUMBNAILPROGRESSEVENT + '";' + LB +
    '            customdata = [' + CONTROLID + '__THUMBNAILS_LOADED, ' + CONTROLID + '__TOTAL_PAGES]' + LB +
    '            cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '            ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '        })]);' + LB +
    '      })' + LB +
    '  };' + LB;

  PDFVIEWERJSGETPAGEJSON = '  function ' + CONTROLID + 'GetPageString(page_nr, as_json, filename = "") {' + LB +
    '    ' + CONTROLID + '__PDF_DOC.getPage(page_nr).then(function(page) {' + LB +
    '      page.getTextContent().then(function(content) {' + LB +
    '        var customdata;' + LB +
    '        if (as_json) {' + LB +
    '          customdata = [JSON.stringify(content.items), as_json, filename]' + LB +
    '        } else {' + LB +
    '          let res = content.items.map(function (s) {' + LB +
    '            if (s.hasEOL) {' + LB +
    '              return s.str + "\n"' + LB +
    '            } else { ' + LB +
    '              return s.str' + LB +
    '            }' + LB +
    '          }).join("");' + LB +
    '          customdata = [res, as_json]' + LB +
    '        }' + LB +
    '        var o = ' + DATA_OBJECT + ';' + LB +
    '        o["EventName"] = "' + ONPDFPAGEGETSTRING + '";' + LB +
    '        var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '        ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '      });' + LB +
    '    });' + LB +
    '  };' + LB;

  PDFVIEWERJSSHOWPDFURL = '  function ' + CONTROLID + 'showPDFUrl(pdf_url,page_no,zoom_pdf,rotate_angle,calc_thumb, pw) {' + LB +
    '    ' + CONTROLID + '__THUMBNAILS_LOADED = 0;' + LB +
    '    ' + CONTROLID + '__CANVAS = document.getElementById("' + CONTROLID + '_pdf-canvas"),' + LB +
    '    ' + CONTROLID + '__CANVAS_CTX = ' + CONTROLID + '__CANVAS.getContext("2d");' + LB +
    // GETDocument
    '    let connectionObject;' + LB +
    '    if (pw) { ' + LB+
    '      connectionObject = {url: pdf_url, password: pw};' + LB +
    '    } else {' + LB +
    '      connectionObject = pdf_url' + LB +
    '    }' + LB +
    '    pdfjsLib.getDocument(connectionObject).promise.then(function(pdf_doc) {' + LB +
    '      ' + CONTROLID + '__PDF_DOC = pdf_doc;' + LB +
    '      ' + CONTROLID + '__TOTAL_PAGES = ' + CONTROLID + '__PDF_DOC.numPages;' + LB +
    '      var o = ' + DATA_OBJECT + ';' + LB +
    '      o["EventName"] = "' + ONPDFLOADEDEVENT + '";' + LB +
    '      var customdata = [' + CONTROLID + '__TOTAL_PAGES]' + LB +
    '      var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '      ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    // CALL MAKETHUMB FOR ALL THE PAGES
    '      if (calc_thumb) {' + LB +
    '      var pages = []; while (pages.length < ' + CONTROLID + '__TOTAL_PAGES) pages.push(pages.length + 1);' + LB +
    '        pages.map(function (num) {' + LB +
    '          return Promise.all([' + CONTROLID + '__PDF_DOC.getPage(num).then(' + CONTROLID + 'makeThumb)' + LB +
    '            .then(function (canvas) {' + LB +
    '              ' + CONTROLID + '__THUMBNAILS_LOADED++;' + LB +
    '              var dataURL = canvas.toDataURL();' + LB +
    '              var o = ' + DATA_OBJECT + ';' + LB +
    '              o["EventName"] = "' + ONGENERATETHUMBNAILEVENT + '";' + LB +
    '              var customdata = [num, dataURL]' + LB +
    '              var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '              ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '              o["EventName"] = "' + ONTHUMBNAILPROGRESSEVENT + '";' + LB +
    '              customdata = [' + CONTROLID + '__THUMBNAILS_LOADED, ' + CONTROLID + '__TOTAL_PAGES]' + LB +
    '              cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '              ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '          })]);' + LB +
    '        })' + LB +
    '      }' + LB +
    // CALL SHOWPAGE FOR THE SELECTED PAGE
    '      $("#' + CONTROLID + '_pdf-contents").show();' + LB +
    '      ' + CONTROLID + 'showPage(page_no,zoom_pdf,rotate_angle,true);' + LB +
    '    }).catch(function(error) {' + LB +
    '      var o = ' + DATA_OBJECT + ';' + LB +
    '      o["EventName"] = "' + ONPDFLOADERROREVENT + '";' + LB +
    '      var customdata = [error.message]' + LB +
    '      var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '      ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '    });' + LB +
    '  }' + LB;

  PDFVIEWERJSSHOWPDFBASE64 = '  function ' + CONTROLID + 'showPDFBase64(pdf_data,page_no,zoom_pdf,rotate_angle,calc_thumb,pw) {' + LB +
    '    ' + CONTROLID + '__THUMBNAILS_LOADED = 0;' + LB +
    '    ' + CONTROLID + '__CANVAS = document.getElementById("' + CONTROLID + '_pdf-canvas"),' + LB +
    '    ' + CONTROLID + '__CANVAS_CTX = ' + CONTROLID + '__CANVAS.getContext("2d");' + LB +
    '    var pdfData = atob(pdf_data);' + LB +
    // GETDocument
    '    let connectionObject;' + LB +
    '    if (pw) { ' + LB+
    '      connectionObject = {data: pdfData, password: pw};' + LB +
    '    } else {' + LB +
    '      connectionObject = {data: pdfData}' + LB +
    '    }' + LB +
    '    pdfjsLib.getDocument(connectionObject).promise.then(function(pdf_doc) {' + LB +
    '      ' + CONTROLID + '__PDF_DOC = pdf_doc;' + LB +
    '      ' + CONTROLID + '__TOTAL_PAGES = ' + CONTROLID + '__PDF_DOC.numPages;' + LB +
    '      var o = ' + DATA_OBJECT + ';' + LB +
    '      o["EventName"] = "' + ONPDFLOADEDEVENT + '";' + LB +
    '      var customdata = [' + CONTROLID + '__TOTAL_PAGES]' + LB +
    '      var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '      ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    // CALL MAKETHUMB FOR ALL THE PAGES
    '      if (calc_thumb) {' + LB +
    '        var pages = []; while (pages.length < ' + CONTROLID + '__TOTAL_PAGES) pages.push(pages.length + 1);' + LB +
    '        pages.map(function (num) {' + LB +
    '          return Promise.all([' + CONTROLID + '__PDF_DOC.getPage(num).then(' + CONTROLID + 'makeThumb)' + LB +
    '            .then(function (canvas) {' + LB +
    '              ' + CONTROLID + '__THUMBNAILS_LOADED++;' + LB +
    '              var dataURL = canvas.toDataURL();' + LB +
    '              var o = ' + DATA_OBJECT + ';' + LB +
    '              o["EventName"] = "' + ONGENERATETHUMBNAILEVENT + '";' + LB +
    '              var customdata = [num, dataURL]' + LB +
    '              var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '              ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '              o["EventName"] = "' + ONTHUMBNAILPROGRESSEVENT + '";' + LB +
    '              customdata = [' + CONTROLID + '__THUMBNAILS_LOADED, ' + CONTROLID + '__TOTAL_PAGES]' + LB +
    '              cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '              ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '            })' + LB +
    '          ]);' + LB +
    '        })' + LB +
    '      }' + LB +
    // CALL SHOWPAGE FOR THE SELECTED PAGE
    '      $("#' + CONTROLID + '_pdf-contents").show();' + LB +
    '      ' + CONTROLID + 'showPage(page_no,zoom_pdf,rotate_angle,true);' + LB +
    '    }).catch(function(error) {' + LB +
    '      var o = ' + DATA_OBJECT + ';' + LB +
    '      o["EventName"] = "' + ONPDFLOADERROREVENT + '";' + LB +
    '      var customdata = [error.message]' + LB +
    '      var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '      ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '    });' + LB +
    '  }' + LB;

  PDFVIEWERJSSHOWPAGE = '  function ' + CONTROLID + 'showPage(page_no, zoom_pdf, rotate_angle, trigger_event) {' + LB +
    '    ' + CONTROLID + 'myEventBus = new pdfjsViewer.EventBus();' + LB +
    '    ' + CONTROLID + 'myLinkService = new pdfjsViewer.PDFLinkService({' + CONTROLID + 'myEventBus, externalLinkTarget: pdfjsLib.LinkTarget.BLANK});' + LB +
    '    ' + CONTROLID + '__PAGE_RENDERING_IN_PROGRESS = 1;' + LB +
    '    ' + CONTROLID + '__CURRENT_PAGE = page_no;' + LB +
    '    ' + CONTROLID + '__CANVAS = document.getElementById("' + CONTROLID + '_pdf-canvas");' + LB +
    '    let parent = ' + CONTROLID + '__CANVAS.parentNode;' + LB +
    '    parent.removeChild(' + CONTROLID + '__CANVAS);' + LB +
    '    ' + CONTROLID + '__CANVAS = document.createElement("canvas");' + LB +
    '    ' + CONTROLID + '__CANVAS.id = "' + CONTROLID + '_pdf-canvas";' + LB +
    '    ' + CONTROLID + '__CANVAS.style = "border: 1px solid black; direction: ltr; margin-right: auto; margin-left: auto; display: block;";' + LB +
    '    parent.insertBefore(' + CONTROLID + '__CANVAS, parent.childNodes[0]);' + LB +
    '    ' + CONTROLID + '__CANVAS_CTX = ' + CONTROLID + '__CANVAS.getContext("2d");' + LB +
    '    $("#' + CONTROLID + '_annotation-layer").hide()' + LB +
    '    $("#' + CONTROLID + '_pdf-current-page").text(page_no);' + LB +
    '    ' + CONTROLID + '__PDF_DOC.getPage(page_no).then(function(page) {' + LB +
    '      if (trigger_event) {' + LB +
    '        var o = ' + DATA_OBJECT + ';' + LB +
    '        o["EventName"] = "' + ONPAGELOADEDEVENT + '";' + LB +
    '        ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o));' + LB +
    '      };' + LB +
    '      var outputScale = window.devicePixelRatio || 1.5;' + LB +
    '      if (outputScale === 1) { outputScale = 1.3 };' + LB +
    '      var scale_required = zoom_pdf;' + LB +
    '      if (rotate_angle != undefined ) {' + LB +
    '       var rotatedeg = rotate_angle;' + LB +
    '       var viewport = page.getViewport({scale: scale_required, rotation: rotatedeg});' + LB +
    '      } else { ' + LB +
    '       var viewport = page.getViewport({scale: scale_required});' + LB +
    '      };' + LB +
    '      var vpHeight, vpWidth;' + LB +
    '      if (!viewport.height) {' + LB +
    '        vpHeight = viewport.viewBox[3];' + LB +
    '      } else {' + LB +
    '        vpHeight = viewport.height;' + LB +
    '      }' + LB +
    '      if (!viewport.width) {' + LB +
    '        vpWidth = viewport.viewBox[2];' + LB +
    '      } else {' + LB +
    '        vpWidth = viewport.width;' + LB +
    '      }' + LB +
    '      ' + CONTROLID + '__CANVAS.height = Math.floor(vpHeight * outputScale);' + LB +
    '      ' + CONTROLID + '__CANVAS.width = Math.floor(vpWidth * outputScale);' + LB +
    '      ' + CONTROLID + '__CANVAS.style.height = Math.floor(vpHeight) + "px";' + LB +
    '      ' + CONTROLID + '__CANVAS.style.width = Math.floor(vpWidth) + "px";' + LB +
    '      var transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;' + LB +
    '      var renderContext = {' + LB +
    '        canvasContext: ' + CONTROLID + '__CANVAS_CTX,' + LB +
    '        transform: transform,' + LB +
    '        viewport: viewport' + LB +
    '      };' + LB +
    '      page.render(renderContext).promise.then(function() {' + LB +
    '        return page.getAnnotations();' + LB +
    '      }).then(function(annotationData) {' + LB +
    '        if(annotationData.length == 0) {' + LB +
    '          var o = ' + DATA_OBJECT + ';' + LB +
    '          if (trigger_event) {' + LB +
    '            o["EventName"] = "' + ONPAGERENDEREDEVENT + '";' + LB +
    '          } else {' + LB +
    '            o["EventName"] = "' + ONPAGESIZESEVENT + '";' + LB +
    '          }' + LB +
    '          var customdata = [' + CONTROLID + '__CANVAS.width, ' + CONTROLID + '__CANVAS.height]' + LB +
    '          var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '          ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '          return;' + LB +
    '        }' + LB +
    '        var canvas_offset = $("#' + CONTROLID + '_pdf-canvas").offset();' + LB +
    {$IFDEF WEBLIB}
    '        var div_offset = $("#' + CONTROLID + '_pdf-contents").offset();' + LB +
    '        var left_offset = 0;' + LB +
    '        var top_offset = 0;' + LB +
    '        if (canvas_offset.left !== div_offset.left) {' + LB +
    '          if (div_offset.left == 0) {' + LB +
    '            left_offset = Math.max(canvas_offset.left, div_offset.left);' + LB +
    '          } else {' + LB +
    '            left_offset = canvas_offset.left - div_offset.left;' + LB +
    '          }' + LB +
    '        }' + LB +
    {$ENDIF}
    {$IFNDEF WEBLIB}
    '        var left_offset = canvas_offset.left;' + LB +
    '        var top_offset = canvas_offset.top;' + LB +
    {$ENDIF}
    '        $("#' + CONTROLID + '_annotation-layer").html("").show();' + LB +
    '        $("#' + CONTROLID + '_annotation-layer").css({ left: left_offset + ''px'', top: top_offset + ''px'', height: ' + CONTROLID + '__CANVAS.style.height, width: ' + CONTROLID + '__CANVAS.style.width });' + LB +
    '        pdfjsLib.AnnotationLayer.render({' + LB +
    '          linkService: ' + CONTROLID + 'myLinkService,' + LB +
    '          viewport: viewport.clone({ dontFlip: true}),' + LB +
    '          div: document.getElementById("' + CONTROLID + '_annotation-layer"),' + LB +
    '          annotations: annotationData,' + LB +
    '          page: page' + LB +
    '        });' + LB +
    // OnMouseDown and OnMouseUp Event
    '        var annotlayer = document.getElementById("' + CONTROLID + '_annotation-layer");' + LB +
    '        annotlayer.addEventListener("mousedown", e => {' + LB +
    '          var o = ' + DATA_OBJECT + ';' + LB +
    '          o["EventName"] = "' + ONMOUSEDOWNEVENT + '";' + LB +
    '          var customdata = [e.button,e.shiftKey,e.altKey,e.ctrlKey,e.clientX,e.clientY]' + LB +
    '          var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '          ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '        });' + LB +
    '        annotlayer.addEventListener("mouseup", e => {' + LB +
    '          var o = ' + DATA_OBJECT + ';' + LB +
    '          o["EventName"] = "' + ONMOUSEUPEVENT + '";' + LB +
    '          var customdata = [e.button,e.shiftKey,e.altKey,e.ctrlKey,e.clientX,e.clientY]' + LB +
    '          var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '          ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '        });' + LB +
    '        annotlayer.addEventListener("mousemove", e => {' + LB +
    '          var o = ' + DATA_OBJECT + ';' + LB +
    '          o["EventName"] = "' + ONMOUSEMOVEEVENT + '";' + LB +
    '          var customdata = [e.button,e.shiftKey,e.altKey,e.ctrlKey,e.clientX,e.clientY]' + LB +
    '          var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '          ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '        });' + LB +

    // LINK HYPERLINK TO ONHYPERLINKCLICK EVENT
    '        for (let i = 0; i < annotationData.length; i++) {' + LB +
    '          var iddata = ''[data-annotation-id="'' + annotationData[i].id + ''"]'';' + LB +
    '          var element = document.querySelector(iddata);' + LB +
    '          if (element) {' + LB +
    '            element.addEventListener("click", function (event) {' + LB +
    '              var o = ' + DATA_OBJECT + ';' + LB +
    '              o["EventName"] = "' + ONHYPERLINKCLICKEVENT + '";' + LB +
    '              var customdata = [annotationData[i].id, annotationData[i].subtype, annotationData[i].url]' + LB +
    '              var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '              ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '              event.preventDefault();' + LB +
    '              return false;' + LB +
    '            });' + LB +
    '          }' + LB +
    '        }' + LB +
    '        var o = ' + DATA_OBJECT + ';' + LB +
    '        if (trigger_event) {' + LB +
    '          o["EventName"] = "' + ONPAGERENDEREDEVENT + '";' + LB +
    '        } else {' + LB +
    '          o["EventName"] = "' + ONPAGESIZESEVENT + '";' + LB +
    '        }' + LB +
    '        var customdata = [' + CONTROLID + '__CANVAS.width, ' + CONTROLID + '__CANVAS.height]' + LB +
    '        var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
    '        ' + CONTROLID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
    '        ' + CONTROLID + '__PAGE_RENDERING_IN_PROGRESS = 0;' + LB +
    '      });' + LB +
    '    });' + LB +
    '  }';

  DEFAULT_PDF_BASE64 =
    'JVBERi0xLjcKJeLjz9MKNCAwIG9iago8PAovRmlsdGVyIC9GbGF0ZURlY29kZQovTGVuZ3RoIDQ5MQo+PgpzdHJlYW0KeJwVURG0LDEMXSxGg4vF6sfBYvDp4GJwtTg4GC0OLgarxY+D1Ysf9+f13JOT3pOkN7ff7/cRx+78zADoyW/Vq/chW8WqgspWGYO3ITSYLHgZlbXyMN5MyJiE' +
    'N2E62gkjUF/37e4q7Njr4SX/wR38wm3oJpOkEU/iFi10TNq27VyL06a8ZSFlSkL7Tx1zvn5eeO34KeCCvMMKSse1QzvqjrTj2CE9pUR5gy1v5JN8/auOLNvTVgrB2yn7jluhy/HBHUNCjHPk3Xl8DlGZRVrhWbiVIxDXqd4gLaK2+eGprYGbHlPZzIcplrYuIOnEIO50BEC4BRIPnRwY' +
    'p0zxNqVFlDZPjrRNbnJM4belsS7BJaa/QIy+GBebMpJYQuhHYktBtn7JuERVhrJePC5W5ZFEk4zEmjg+jXWs00xsVXxaV/jygTo+3orPgFaz9v7J7+WdHPSK/3xH8Rmr1HH6lPo2x986/p5jhSLvYXDEUFekl9E7o3AvDR9GGADuevRyIBDyPZb3PqVHlPZrgrQ+ucuB2Ne197qf2dcU' +
    'TLHzF1FkkzHZojqLZawsyGw5yGiWMUVPGSfr5DFZw9YsmmVk1hxb71yl4kXIhEpI5L04St1eqS18/+DrOVF5Uuo3vt/U/z3T49HvR3r8B9dufD0KZW5kc3RyZWFtCmVuZG9iagozIDAgb2JqCjw8Ci9UeXBlIC9YT2JqZWN0Ci9TdWJ0eXBlIC9JbWFnZQovV2lkdGggNDAwCi9IZWln' +
    'aHQgMzAwCi9CaXRzUGVyQ29tcG9uZW50IDgKL0NvbG9yU3BhY2UgWy9JbmRleGVkIC9EZXZpY2VSR0IgMjU1IDQgMCBSXQovRmlsdGVyIC9GbGF0ZURlY29kZQovTGVuZ3RoIDYgMCBSCj4+CnN0cmVhbQp4nO2dSXKjShPHQUxnIAIW2uOV4QJspHCETiiO4ZP4Huy/kBSqjxxqAA3t' +
    'fm27rfb/9yYoaoDMysyqUr+qKAIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwD2qfBzLv/0SQEmzmGn+9ot8T9Kc+ML2YouJovqLG38A0tgYE39de73TRxdF5dR4/IWNPwDcX/sva64WXWQ9q6Hkmy9r/BFIv1YkDTeXRsduiKCPK1RfK5LEhKEc+rjgpn2kaXidXjyuLpMuc11k' +
    'S6i1nb0TfRx/64WvNu2fzV7ndj7H8XjZ/LU0ffCOTH9CnrCA4mSCe+1OrqqREgfOU48Ug9dDUKzeUZJpfFpacs/P8jbINrB36pvCJuy6nnIlz92uq6a2Mtd4qU2PmpOutf2o5RutOM2nNzb9VJxT7VdwyWJt7GBtysbvM+W7zfH1bTXxsg9F+7Q9UOL21aVs3l6mv6JoPz047Dnrka6p' +
    '4C/k+7vsdOhJw5yMEhq+Gno/R2jseChzvW10Y6Re53V15kaxa5vNp8WZCDM1frAbV7ZmQ39Ng62Cmo5FfpWhV9JhRkc3ep0bW2gwbmy2o6skXU//Vn3kfhh3e56zP5PgV/TvNyvYzctqpamHjSQdN5wvPcmDw5R1vzpJwcPTHwj/CrvYw/pYxzOayAvVjcKSIINh+VVhGc1WzipivaV9' +
    'kFIdm+Aut55TFKwal5q4lBjOVCSsw+qDX7+PnT4SE2TJbnitlzNJVf62wn9aHWzK9I92/ye5W2nmQ7QVJXLa5sN0QXRLOTbxnJl+1lym0OzysZwUpvRiC8OiIvI9aZhQhW2RffC9kTYyX0iVXS9fN9BHkMz6SOZ5slvqsEyCPRwobXPyKcSec2445byyBbbWOrjgh+rjYv6x1p7VdKPr' +
    'iOtdlwVfz9d5GqVF08epr6OllalM/YNaTPac76RsT4bUtiyqpm3rlkrOx1eiQkpvtWUOD7l7wzrmt8vyctf3wRt5K+9zLsDZkrFrJNe1xbI9CfR8Omy3WxY3NXsUR3Xa7qfwQNZz5t7/pPp522/Fpih0bPdvn2Egy/GVRGDu4yoUw9E48ZdBgSr3glQ/XUoASHxv1e47+vRn29pcH+LO' +
    'qI087P6GLI9rF812nDe51Eem4wbRDN/oYtnlZx+5d585aB9fVyuOA6yZw5Yz7Oly9UaXGzEZzntY+Tx71tP23aJ+F4v5h9gHOwf1JyLT1F+L/YROWTxYEaRIdhtKG2M7vnj2xXjXlWqsTbigNdj2WtfyWlswS330aVipjqtEs/pkGjkdxAWJebi+fRShso5eIpd5giKIxA8pyLZy1jzs' +
    '8mz+D+KqfYTfoh/G1yxI7Zl97StR6Y1OSWUohjACXJt/uGpIr2Yq5YcHzdxdBe+jIZ8vxQCtVyJFaRiyYwoJaW/i/fX6smtv2FvZkdbxRFmeOP2smlG7skp9W1FQuS/f36UKFBCpfWgAnAlVBE5XbWxHL4lViQ+guYjrmfL4WjNnXGwfN/yVjqTqYGg29XlvaV4z7sUD+7DmEVnlt36k' +
    'zi8lLmp1ZsEeqPdv5tMH8T/u9sVqT+KH08fZ2dV2XuBDuGYfqo8hfMSfLDHAzk4m0a61t669BFns3HnXrlbSFw+hrtpHOss3OSxWn8TiIvVZdvOus9BHNkuvSufzGu01Gx0esTCvhWIJH+6WzenNFbT6OK1Cfaw+Wh/X4ofMei/1YQVZ+ZGqFU/hpwWkkDH21UQqZ9HHvfihAyhRQc2N' +
    'jLnP0t3VRzJL92/jJujijk4iTDKP01IfB+rv7pb0cVL7cOkyBLP6OBzCAh/C79hHPLpSpZW/k61XUmQ9uquVV1fYv98bX7lWSOy9Nk/dXExLEpw+6oWirukjC2IcheuTMwByRufXaMYrxwl3+2JnIDIrUd929pPHz7OPRfzQT5tJK54JciIV+YeTrUJmBSmtfdCFXcuS8Rff3bcPN/3h' +
    'iOFNTvq4dB27Zpbcsg9bRy9jQK8SmdeJLLfnSbAn++TIiZvDyfd9juJiTE9sE0E8XwX2sTrfkux/w/tn/uhL+7iI56mVyODyFtYpOMH3gZp5EmAk3l6zD3qgrdiJIKtgba/7sPZe2qqX412nD6nDrqyVvRuHH4/7rV0BfD2RMcgA65geDurETj5QvLiosQmTVzP7OH24feigPp2iNN2F' +
    '46sb8WO046rKiiGNTS4CtfahUwL2F7X082BlydlHbbWcZ2Ez2mxhjcUu2nbGVVpezM99tOq9Pqrpe0yw5OwHVDKxe5tcVsozDXryenbS3rycaSzLA9unwC0d2ek5+/h4fyUSMNMU2K0h3Y8fHHOzpixkDZVkxf0/6Yph7UvYrp2p4FRcc/tQl0RuTt2KXTre+afe79n1SFfp1fhhfxPu' +
    'kyQz1tYu2Mjgl9Zx/TrUQUPMy0FWpw5Hn/Vq/Dh/vD5K57L743viRzwn8st5hv+WcdR8LdfPDub2ES4pSoIak3hJHbL6EFW76malFvahduSmSX7cHbLXRVxdzmXXdRSrmbR0CnTwNBvv0mNvH6ePjh/h3KF4j31USfix3HXDZVdjv74KVur9mvfCPoLlfO3EajF8rdGk89mDYXV/Wx9+' +
    '/St2gzP5oD724f3psHLYGfeRlkB0cff8omKn8dXB6+P0qeOriCbTIjN61fv2IR27cKMg/6OdlZPJnYv2ib3/I1YL+3AWklifIoEnDxqduRs3qm6CgfqFPqLa/QKyDqIHdbBgBJxurXVs/Uxkf1ITOe1t0tI+VqtPnH8QVdk0nbxoXRdFoe9cFXSjeQq6sd9W1fm4Hsvwt9l22E2VFLPf' +
    'ftI6b5oxb4NErj8sF1VTnuBX3oob0hI13yxedqCmK30jaZuu6kW2YnqfXTELHQt9TAOuzX673T7Nl02mtLftPlhLSTeEFuFrfTZdPW0Wk0rwfpb6iGjA9Z6fwOnPLhz9zbzMJ/yxhh/CFX2Avwj08b2APr4X0Mf3Avr4XkAf34tH0Eea/ByyR9CH+SnI+sl318fsj97+AL67PtJff8I/' +
    'xXfXBwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADw7ajunQbwldw5UeInsdi7pb2aqf58WeXBpjw/iib4v+sa0keggur6/7zSmev7038keXxLHyO/6U73mFnznd/YJrff8qjmFZ4EkEz66L0+jtV8C3BLfuO8gI/ktn0kuvFWQ4acuv8rUDdLHI2R59/F7/4nGt6QirapmdlH1F71' +
    'V2n9+R972z4S2l+0LQ3vWJ3y+6Z1Z3Sr+DHu6DMe1TqUxu2rG9rH3+SefbApFKIJawiDWsg425H2UWnchm30fUWeq5saZGPXtuzywHENpdwMeVeG8aUa8l05d3B1ubMli3yXD7YCPpiTHqRTEamjmGJAVe4Gka+zj6mGYRbEEtl/N+3Jl1Z0yo4WYM2Ms63nHpVQH3VDDnjUO/pGOd3E' +
    'n8I08NO0Yd/tD/TSLQvDcyhk6z2qWzdTT/SIg3akKseo4ORBig+yPaDciX2kstvoLqhT9RFl9F9nH1Fl2ED+FX1Y7zB9fFbSxuml3FW0pWdfp1VpXG7Rx+Spq8lx+93C42ao2yGL/VaJZbxu07qh6J8keV0V6hcT08c5NTLGTTEl8t6VuUnipi4Sw2M6tY8k7tqqTPw25aoH3umyFn0c' +
    'w28Yb8adR6IxXh+8Q2Uum0OyPvJ4cd7vwLaSLYcw6naM31z12VuPbnMo208mMo6erKc5Ws9D5qURgIQv9lGKIqpwlKHxo+MdS719TLU1XLppmf8kh+9CaB8sVhUcf+2wPDBO7COJ82v7BrbBYLi7CMo9iy+RQwIL3ZN3ZAlrF6DUyNpH5s7p8W5xareth1GOTgo0JeXtbsqzvUYfjjGw' +
    'D3t8XWXvqt6sZ3IVfUzOvrvohVU7BFvz15NHKsKHtYgvsUqXrDtuPVfPL11e7GMKJgWxDkxU9+tNCp9ZauFdj8c4a5pmHB/ba43uey/0ceRjHU0WnFCmXoTi9xgOfUqZWwZHRdO5LXqsZ6Hb5rI+zFwfseijs6/Q6p3fWcUE+/fG6y63Q64qjB8a1f5YGn+fu/YRiTB9SLUOjM62Cybw' +
    'o0nKoS5nR3entKG7nEDX50Ph7INL/dI+qskp1UxgiIkJzm0N7CPj40XGf2Ld69I+zEwffNKAM4XB66bzW6OL33dCjnxuOZ9Lz0e75q9m9lHw1u1yZ64seyThGo63j9LOP/KLEo/Hr+wjoiGY81iBPip/lkon8qwvjrYn6Rcm0eqv6ONZ7MPIjuGyOpabHWe8lG4W6sPZR5vJS/0j+rgd' +
    'PyahyLE2oX00tMM4XRZ+IJPzqm+bBPrgk5u4qlr20x/vxg8+G6yOdbTVcfV6pl6ggiv2kbYlnSY8/5J1Evi1B+PCPtJAH+t4LPMkOLOB7WMa1+ZjEpxEOg2Rm5yOVfX66OOk7Ljjpkmc5HnS97P40c/jx1R2bPR4BJ2fN1RpvusDv7W0j7jPgmUBp4/WPLClPN+zDznMYOclIvGczy5I' +
    'gmEX5TNj2nt90InoRoZGcpx6O171V2ofTZHE9ugDG01knWUdNDOzDxn9/m+9s8br9FH1D6wPvz6dpuF/9a6q57+e2qOeluvuVR3UsMyS1u6UqHkjmpiz1bmBlH+jRePR8i49Xn+KXe7/iPyfWAn8d/ixv5h/U27/Igj+BuX4wPEXAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA' +
    'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABE/wfIRmIKCmVuZHN0cmVhbQpl' +
    'bmRvYmoKNiAwIG9iagozNjg4CmVuZG9iago3IDAgb2JqCjw8Ci9GaWx0ZXIgL0ZsYXRlRGVjb2RlCi9MZW5ndGggMzIKPj4Kc3RyZWFtCnicK+QyNjBQAEEjI1MwnZzLpR9hoOCSzxXIBQBZ4gXxCmVuZHN0cmVhbQplbmRvYmoKNSAwIG9iago8PAovVHlwZSAvUGFnZQovTWVkaWFC' +
    'b3ggWzAgMCAzMDAgMjI1XQovUmVzb3VyY2VzIDw8Ci9YT2JqZWN0IDw8Ci9YMCAzIDAgUgo+Pgo+PgovQ29udGVudHMgNyAwIFIKL1BhcmVudCAyIDAgUgo+PgplbmRvYmoKMiAwIG9iago8PAovVHlwZSAvUGFnZXMKL0tpZHMgWzUgMCBSXQovQ291bnQgMQo+PgplbmRvYmoKMSAw' +
    'IG9iago8PAovVHlwZSAvQ2F0YWxvZwovUGFnZXMgMiAwIFIKPj4KZW5kb2JqCjggMCBvYmoKPDwKL0NyZWF0b3IgKDMtSGVpZ2h0c1wyMjIgSW1hZ2UgdG8gUERGIENvbnZlcnRlciA2LjE2LjAuMiBcKHd3dy5wZGYtdG9vbHMuY29tXCkpCi9Nb2REYXRlIChEOjIwMjEwOTEwMTUy' +
    'NTU5KzAyJzAwJykKL0NyZWF0aW9uRGF0ZSAoRDoyMDIxMDkxMDE1MjU1OSswMicwMCcpCi9Qcm9kdWNlciAoMy1IZWlnaHRzXDIyMiBJbWFnZSB0byBQREYgQ29udmVydGVyIFNoZWxsIDYuMTYuMC4yIFwoaHR0cDovL3d3dy5wZGYtdG9vbHMuY29tXCkpCj4+CmVuZG9iagp4cmVm' +
    'CjAgOQowMDAwMDAwMDAwIDY1NTM1IGYNCjAwMDAwMDQ3NjcgMDAwMDAgbg0KMDAwMDAwNDcxMCAwMDAwMCBuDQowMDAwMDAwNTc4IDAwMDAwIG4NCjAwMDAwMDAwMTUgMDAwMDAgbg0KMDAwMDAwNDU4MSAwMDAwMCBuDQowMDAwMDA0NDU4IDAwMDAwIG4NCjAwMDAwMDQ0NzggMDAw' +
    'MDAgbg0KMDAwMDAwNDgxNiAwMDAwMCBuDQp0cmFpbGVyCjw8Ci9TaXplIDkKL1Jvb3QgMSAwIFIKL0luZm8gOCAwIFIKL0lEIFs8NUJEN0Q4MDgxNUFBRTE2QTIyMDdDNTA2NkNGRDJBRkQ+IDw1MUNDNzVDNTFBRkU3MzI0QzQ4MUI0NDQ4MkU5MTdCRj5dCj4+CnN0YXJ0eHJlZgo1' +
    'MDg0CiUlRU9GCg==';

implementation

end.


