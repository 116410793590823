unit ufrEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.WebCtrls, WEBLib.ComCtrls,
  Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TfrEdit = class(TFrame)
    WebHTMLDiv1: THTMLDiv;
    WebHTMLDiv2: THTMLDiv;
    pFooter: THTMLDiv;
    btnSave: TButton;
    btnCAncel: TButton;
    WebRichEdit1: TRichEdit;
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

var
  frEdit: TfrEdit;

implementation

{$R *.dfm}


procedure TfrEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  WebRichEdit1 := TRichEdit.Create(Self);
  pFooter := THTMLDiv.Create(Self);
  btnSave := TButton.Create(Self);
  btnCAncel := TButton.Create(Self);

  WebHTMLDiv1.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  WebRichEdit1.BeforeLoadDFMValues;
  pFooter.BeforeLoadDFMValues;
  btnSave.BeforeLoadDFMValues;
  btnCAncel.BeforeLoadDFMValues;
  try
    Name := 'frEdit';
    Left := 0;
    Top := 0;
    Width := 605;
    Height := 322;
    TabOrder := 0;
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 16;
    WebHTMLDiv1.Top := 3;
    WebHTMLDiv1.Width := 577;
    WebHTMLDiv1.Height := 310;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv2.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 16;
    WebHTMLDiv2.Top := 18;
    WebHTMLDiv2.Width := 513;
    WebHTMLDiv2.Height := 153;
    WebHTMLDiv2.ChildOrder := 1;
    WebHTMLDiv2.Role := '';
    WebRichEdit1.SetParentComponent(WebHTMLDiv2);
    WebRichEdit1.Name := 'WebRichEdit1';
    WebRichEdit1.Left := 8;
    WebRichEdit1.Top := 3;
    WebRichEdit1.Width := 441;
    WebRichEdit1.Height := 95;
    WebRichEdit1.BorderStyle := bsSingle;
    WebRichEdit1.Color := clWhite;
    pFooter.SetParentComponent(WebHTMLDiv1);
    pFooter.Name := 'pFooter';
    pFooter.Left := 24;
    pFooter.Top := 205;
    pFooter.Width := 465;
    pFooter.Height := 73;
    pFooter.ElementClassName := 'editor-footer-div';
    pFooter.HeightStyle := ssAuto;
    pFooter.WidthStyle := ssAuto;
    pFooter.ChildOrder := 4;
    pFooter.ElementPosition := epIgnore;
    pFooter.ElementFont := efCSS;
    pFooter.Role := '';
    btnSave.SetParentComponent(pFooter);
    btnSave.Name := 'btnSave';
    btnSave.Left := 170;
    btnSave.Top := 24;
    btnSave.Width := 96;
    btnSave.Height := 25;
    btnSave.Caption := 'Speichern';
    btnSave.ElementClassName := 'start_button-lets-go';
    btnSave.ElementFont := efCSS;
    btnSave.ElementPosition := epIgnore;
    btnSave.HeightStyle := ssAuto;
    btnSave.HeightPercent := 100.000000000000000000;
    btnSave.WidthStyle := ssAuto;
    btnSave.WidthPercent := 100.000000000000000000;
    btnCAncel.SetParentComponent(pFooter);
    btnCAncel.Name := 'btnCAncel';
    btnCAncel.Left := 272;
    btnCAncel.Top := 24;
    btnCAncel.Width := 96;
    btnCAncel.Height := 25;
    btnCAncel.Caption := 'Verwerfen';
    btnCAncel.ChildOrder := 2;
    btnCAncel.ElementClassName := 'start_button-lets-go';
    btnCAncel.ElementFont := efCSS;
    btnCAncel.ElementPosition := epIgnore;
    btnCAncel.HeightStyle := ssAuto;
    btnCAncel.HeightPercent := 100.000000000000000000;
    btnCAncel.WidthStyle := ssAuto;
    btnCAncel.WidthPercent := 100.000000000000000000;
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    WebRichEdit1.AfterLoadDFMValues;
    pFooter.AfterLoadDFMValues;
    btnSave.AfterLoadDFMValues;
    btnCAncel.AfterLoadDFMValues;
  end;
end;

end.