unit uProfil;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.WebCtrls,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, XData.Web.Client, utb,
  WEBLib.ComCtrls, Vcl.StdCtrls, WEBLib.StdCtrls, uMe, WEBLib.EditAutocomplete,
  Vcl.CheckLst, WEBLib.CheckLst, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, VCL.TMSFNCCustomControl,
  VCL.TMSFNCWebBrowser, VCL.TMSFNCCustomWEBControl, VCL.TMSFNCMemo,
  VCL.TMSFNCHTMLImageContainer, VCL.TMSFNCCheckBox, ufrImgPosition,
  WEBLib.Buttons;

type
  TfrmProfil = class(TForm)
    divContent: THTMLDiv;
    Client: TXDataWebClient;
    divHeader: THTMLDiv;
    lblTitel: THTMLDiv;
    WebHTMLDiv1: THTMLDiv;
    WebHTMLDiv2: THTMLDiv;
    WebLabel1: TLabel;
    WebHTMLDiv8: THTMLDiv;
    WebHTMLDiv11: THTMLDiv;
    WebHTMLDiv12: THTMLDiv;
    WebLabel10: TLabel;
    WebHTMLDiv13: THTMLDiv;
    WebLabel11: TLabel;
    WebHTMLDiv14: THTMLDiv;
    WebLabel12: TLabel;
    WebHTMLDiv27: THTMLDiv;
    WebHTMLDiv30: THTMLDiv;
    WebHTMLDiv31: THTMLDiv;
    WebLabel21: TLabel;
    WebHTMLDiv33: THTMLDiv;
    WebLabel23: TLabel;
    WebLabel7: TLabel;
    WebHTMLDiv34: THTMLDiv;
    WebHTMLDiv3: THTMLDiv;
    WebLabel2: TLabel;
    WebHTMLDiv4: THTMLDiv;
    edName: TEditAutoComplete;
    cbAnrede: TComboBox;
    WebHTMLDiv5: THTMLDiv;
    WebLabel3: TLabel;
    WebHTMLDiv6: THTMLDiv;
    edVorname: TEditAutoComplete;
    WebHTMLDiv7: THTMLDiv;
    WebLabel4: TLabel;
    WebHTMLDiv9: THTMLDiv;
    WebHTMLDiv10: THTMLDiv;
    WebLabel5: TLabel;
    WebHTMLDiv37: THTMLDiv;
    WebHTMLDiv38: THTMLDiv;
    WebLabel6: TLabel;
    WebHTMLDiv39: THTMLDiv;
    edStrasse: TEditAutoComplete;
    WebHTMLDiv40: THTMLDiv;
    WebLabel8: TLabel;
    WebHTMLDiv41: THTMLDiv;
    edPLZ: TEditAutoComplete;
    WebHTMLDiv42: THTMLDiv;
    WebLabel9: TLabel;
    WebHTMLDiv43: THTMLDiv;
    WebHTMLDiv44: THTMLDiv;
    edNickname: TEditAutoComplete;
    WebHTMLDiv56: THTMLDiv;
    clScheine: TCheckListBox;
    WebHTMLDiv32: THTMLDiv;
    WebLabel22: TLabel;
    WebHTMLDiv53: THTMLDiv;
    clFunk: TCheckListBox;
    WebHTMLDiv28: THTMLDiv;
    WebHTMLDiv54: THTMLDiv;
    WebLabel24: TLabel;
    WebHTMLDiv55: THTMLDiv;
    edGebDatum: TDateTimePicker;
    WebHTMLDiv59: THTMLDiv;
    WebHTMLDiv60: THTMLDiv;
    WebLabel26: TLabel;
    WebHTMLDiv61: THTMLDiv;
    WebLabel27: TLabel;
    WebHTMLDiv62: THTMLDiv;
    clFunktion: TCheckListBox;
    WebHTMLDiv65: THTMLDiv;
    chkEigner: TCheckBox;
    WebHTMLDiv66: THTMLDiv;
    chkVerein: TCheckBox;
    WebHTMLDiv67: THTMLDiv;
    WebLabel28: TLabel;
    WebHTMLDiv68: THTMLDiv;
    cbVerein: TComboBox;
    WebHTMLDiv69: THTMLDiv;
    chkCharter: TCheckBox;
    WebHTMLDiv70: THTMLDiv;
    chkEinhand: TCheckBox;
    divWrapper: THTMLDiv;
    imgAvatar: TImageControl;
    cbLand: TComboBox;
    cbBLand: TComboBox;
    edOrt: TEditAutoComplete;
    WebHTMLDiv36: THTMLDiv;
    cbShowLand: TComboBox;
    WebHTMLDiv19: THTMLDiv;
    cbShowAnrede: TComboBox;
    WebHTMLDiv25: THTMLDiv;
    cbShowName: TComboBox;
    WebHTMLDiv26: THTMLDiv;
    cbShowVorname: TComboBox;
    WebHTMLDiv35: THTMLDiv;
    cbShowBLand: TComboBox;
    WebHTMLDiv45: THTMLDiv;
    cbShowStrasse: TComboBox;
    WebHTMLDiv46: THTMLDiv;
    cbShowPLZ: TComboBox;
    WebHTMLDiv63: THTMLDiv;
    cbShowOrt: TComboBox;
    WebHTMLDiv64: THTMLDiv;
    cbShowGebDatum: TComboBox;
    WebHTMLDiv78: THTMLDiv;
    cbShowNickname: TComboBox;
    WebHTMLDiv84: THTMLDiv;
    WebHTMLDiv86: THTMLDiv;
    WebLabel32: TLabel;
    WebHTMLDiv87: THTMLDiv;
    chkInfoGruppen: TCheckBox;
    WebHTMLDiv88: THTMLDiv;
    chkInfoEvents: TCheckBox;
    WebHTMLDiv89: THTMLDiv;
    chkInfoUsern: TCheckBox;
    WebHTMLDiv90: THTMLDiv;
    chkInfoKurse: TCheckBox;
    WebHTMLDiv15: THTMLDiv;
    WebLabel13: TLabel;
    WebHTMLDiv18: THTMLDiv;
    imgTitel: TImageControl;
    divIntern: THTMLDiv;
    WebHTMLDiv92: THTMLDiv;
    WebLabel33: TLabel;
    WebHTMLDiv93: THTMLDiv;
    chkAdmin: TCheckBox;
    WebHTMLDiv94: THTMLDiv;
    chkTrainer: TCheckBox;
    WebHTMLDiv91: THTMLDiv;
    chkOrganizer: TCheckBox;
    WebHTMLDiv17: THTMLDiv;
    btnPersSave: TButton;
    btnPersCancel: TButton;
    WebHTMLDiv96: THTMLDiv;
    chkRegatta: TCheckBox;
    WebHTMLDiv98: THTMLDiv;
    chkFahrten: TCheckBox;
    WebHTMLDiv99: THTMLDiv;
    WebLabel18: TLabel;
    WebHTMLDiv74: THTMLDiv;
    cbShowAbout: TComboBox;
    edAbout: TMemo;
    WebHTMLDiv47: THTMLDiv;
    WebLabel19: TLabel;
    spHobbies: TSpeedButton;
    WebHTMLDiv75: THTMLDiv;
    cbShowHobbies: TComboBox;
    divHobbies: THTMLDiv;
    WebHTMLDiv29: THTMLDiv;
    WebHTMLDiv48: THTMLDiv;
    WebLabel20: TLabel;
    spReviere: TSpeedButton;
    WebHTMLDiv49: THTMLDiv;
    cbShowReviere: TComboBox;
    divReviere: THTMLDiv;
    WebHTMLDiv58: THTMLDiv;
    WebHTMLDiv76: THTMLDiv;
    WebLabel25: TLabel;
    spExpertise: TSpeedButton;
    WebHTMLDiv77: THTMLDiv;
    cbShowExpertise: TComboBox;
    divExpertise: THTMLDiv;
    WebHTMLDiv21: THTMLDiv;
    WebLabel14: TLabel;
    WebHTMLDiv22: THTMLDiv;
    WebLabel15: TLabel;
    WebHTMLDiv50: THTMLDiv;
    edEMAil: TEditAutoComplete;
    WebHTMLDiv71: THTMLDiv;
    cbShowEMail: TComboBox;
    WebHTMLDiv82: THTMLDiv;
    WebLabel30: TLabel;
    WebHTMLDiv83: THTMLDiv;
    edPasswort1: TEdit;
    WebHTMLDiv79: THTMLDiv;
    WebLabel29: TLabel;
    WebHTMLDiv80: THTMLDiv;
    edPasswort2: TEdit;
    WebHTMLDiv23: THTMLDiv;
    WebLabel16: TLabel;
    WebHTMLDiv51: THTMLDiv;
    edTelefon: TEditAutoComplete;
    WebHTMLDiv72: THTMLDiv;
    cbShowTelefon: TComboBox;
    WebHTMLDiv24: THTMLDiv;
    WebLabel17: TLabel;
    WebHTMLDiv52: THTMLDiv;
    edWebSeite: TEditAutoComplete;
    WebHTMLDiv73: THTMLDiv;
    cbShowWebseite: TComboBox;
    WebHTMLDiv81: THTMLDiv;
    WebLabel31: TLabel;
    WebHTMLDiv85: THTMLDiv;
    cbFreundschaft: TComboBox;
    WebHTMLDiv95: THTMLDiv;
    WebCheckBox1: TCheckBox;
    WebHTMLDiv16: THTMLDiv;
    WebLabel34: TLabel;
    WebHTMLDiv97: THTMLDiv;
    cbShowFreunde: TComboBox;
    procedure WebFormCreate(Sender: TObject);
    procedure imgAvatarClick(Sender: TObject);
    procedure imgAvatarMouseEnter(Sender: TObject);
    [async] procedure cbLandChange(Sender: TObject); async;
    [async] procedure btnPersSaveClick(Sender: TObject); async;
    [async] procedure spHobbiesClick(Sender: TObject); async;
  private
    FPosition:TfrImgPosition;
    FParent : TForm;
    FContainer:TForm;
    [async] procedure FillAutoComplete(Aed: TEditAutoComplete; AFeld, ASQl: string); async;
    procedure lblHashTagClick(Sender: TObject);
  public
    property Container:TForm read FContainer write FContainer;
    property Parent : TForm read FParent write FParent;
    [async] procedure Init(AUserId:integer); async;
    [async] class procedure Show(AUserID:integer; AME:TfrmMe); async;
    [async] function GetIDStringFromHashTags(ADivParent:THTMLDiv):string; async;
    [async] function GetIDStringFromString(AText:String):string; async;
    [async] procedure SetAvatar(s: string); async;
    [async] procedure SetTitel(s: string); async;
  protected procedure LoadDFMValues; override; end;

var
  frmProfil: TfrmProfil;

implementation
  Uses uDatenmodul, uMain, uMedienAuswahl, uHashTags;
{$R *.dfm}

[async] function TfrmProfil.GetIDStringFromString(AText:String):string; async;
Var
  sText, sID:string;
  j:TJ;
  i:integer;
begin
  J := TJ.create;

  result := ',';

  if (AText > '') and (copy(AText, length(AText)-1,1) <> ',')
  then AText := AText + ',';

  for i := 0 to AText.CountChar(',')-1 do
  begin
    sText := Trim(AText.Split([','])[i]);
    sText := copy(sText,0,length(sText));
    //prüfen ob es den Text bereits als HashTag-Text gibt...
    J.response := await(datenmodul.EasySQL('SELECT ID from BlogKat where Upper(TEXT) = ''' + UpperCase(sText) + ''''));
    sID := j.Value('ID');
    if sID = '' //...wenn nicht dann anlegen
    then sID := await(datenmodul.EasyInsert('INSERT INTO blogKAT (TEXT) VALUES(''' + sText + ''')')).ToString;

    result := result + sID + ',';
  end;

  freeAndNil(j);
end;

[async] function TfrmProfil.GetIDStringFromHashTags(ADivParent:THTMLDiv):string; async;
Var
  sText, sID:string;
  j:TJ;
  i:integer;
begin
//  meldung('save1');
  J := TJ.create;
  //alle Componenten durchlaufen

  result := ',';
  for i := 0 to self.componentcount -1 do
  begin
    //wenn HashTag-Label...
    if (self.Components[i] is TLabel) and (TLabel(self.Components[i]).Parent = ADivParent)
    then begin
      //Text merken
      sText := TLabel(self.Components[i]).Caption;

      //prüfen ob es den Text bereits als HashTag-Text gibt...
      J.response := await(datenmodul.EasySQL('SELECT ID from BlogKat where TEXT = ''' + sText + ''''));
      sID := j.Value('ID');

      if sID = '' //...wenn nicht dann anlegen
      then begin
        await(datenmodul.EasyInsert('INSERT INTO blogKAT (TEXT) VALUES(''' + sText + ''')'));
        J.response := await(datenmodul.EasySQL('SELECT LAST_INSERT_ID() AS ID'));
        sID := j.Value('ID');
      end;

      result := result + sID + ',';
    end;
  end;
  freeAndNil(j);
end;

class procedure TfrmProfil.Show(AUserID:integer; AME:TfrmMe);

  procedure AfterCreate(AForm: TObject);
  begin
    TfrmProfil(AForm).Container := AMe;
    TfrmProfil(AForm).init(AUserID);
    Mainform.Wait := -1;
  end;

var
  o:THtmlDiv;
  s:string;
begin
  if (not mainform.ME.Rechte.Show[riProfil])
  then begin
    meldung('Aufgrund deines Mitgliedsstatus hast Du leider keinen Zugriff auf diesen Bereich...');
    exit;
  end;
end;

procedure TfrmProfil.spHobbiesClick(Sender: TObject);
  [async]
  procedure Callback(a:Array of string); async;
  Var
    i:integer;
    ai:array of integer;
    d:THTMLDiv;
  begin
    setlength(ai, length(a));
    for i := 0 to length(a) - 1 do
      ai[i] := a[i].ToInteger;

    case TSpeedButton(Sender).Tag of
      1: d := divHobbies;
      2: d := divReviere;
      3: d := divExpertise;
    end;

    await(RemoveAllChildControls(d));
    await(FillHashTags(d, Mainform.ME.ID.ToString, TSpeedButton(Sender).Tag.ToString, false, ai));

  end;

var
  a:array of integer;
  j:TJ;
  sSub:string;
begin

  HashSaveStatus(divHobbies,a);

  j := TJ.create(await(datenmodul.EasySQL('SELECT ID From blogkat WHERE LEVEL = 3 AND TYP = 1')));

  case TSpeedButton(Sender).Tag of
    1: sSub := 'Hobby';
    2: sSub := 'Revier';
    3: sSub := 'Expertise';
  end;

  TfrmHashTags.Exec( j.value('ID'), Mainform.ME.ID.ToString, true, TSpeedButton(Sender).Tag.tostring, sSub, a, @Callback);
end;

procedure TfrmProfil.lblHashTagClick(Sender: TObject);
begin
end;

procedure TfrmProfil.btnPersSaveClick(Sender: TObject);

  [async] function GetIDFromTab(AFeld, ASQL:string):integer; async;
  begin
     result := TJ.create(await(datenmodul.EasySQL(ASQL))).Integer(AFeld);
  end;

  [async] function GetIDStringFromSugg(AList:TCheckListbox; ATyp:integer):string; async;
  Var
    i:integer;
  begin
    result := ',';
    for i := 0 to AList.Items.Count -1  do
    begin
      if AList.checked[i]
      then result := result + inttostr(await(GetIDFromTab('ID', 'SELECT ID FROM SUGG Where TYP = ' + ATyp.tostring + ' AND TEXT = ''' + AList.Items[i] + ''''))) + ',';
    end;
  end;

  function bool2string(value:boolean):string;
  begin
    if Value
    then result := '1'
    else result := '0';
  end;

  function Chk(AEdit: TEdit; AName:string):boolean;
  begin

    if AEdit.Text = ''
    then begin
      meldung('Feld "' + AName + '" muß ausgefüllt werden...');
      AEdit.SetFocus;
      result := false;
    end
    else result := true;

  end;

  procedure SetStatus(AValue, Aidx:integer; Var AString :string );
  begin
    delete(AString,Aidx,1);
    //insert('x', 'yyyyy', 2);
    system.insert(AValue.ToString, AString, AIdx);
  end;

var
  i:integer;
  sSQL:string;
  j:TJ;
  sEigner, sVerein,
  sExpertise, sDatum,
  sVereinID, sFunktion,
  sHobbies, sReviere,
  sScheine, sFunk, sFieldStatus,
  sOrtID, sLandID, sBLandID:string;
  a:Array of integer;
begin

  if (not Chk(TEdit(cbLand), 'Land')) then exit;
  if (not Chk(TEdit(edPLZ), 'PLZ')) then exit;
  if (not Chk(TEdit(edOrt), 'Ort')) then exit;
  if (not Chk(TEdit(edNickname), 'Nickname')) then exit;
  if (not Chk(TEdit(edEMail), 'E-Mail')) then exit;
  if (not Chk(TEdit(edPasswort1), 'Passwort1')) then exit;

  if edNickName.Text = (edVorName.Text + ' ' + edName.Text)
  then  begin
    if await(Frage('Bitte beachte, dass dein Nickname für jedes Mitglied sichtbar ist und auch zur Suche verwendet werden kann.<br><br>' +
                   'Wenn du deinen echten Namen nicht öffentlich machen möchtest, solltet du deinen Nicknamen ändern.<br><br>' +
                   'Möchtest Du deinen Nicknamen nochmal ändern?')) = mrYes
    then begin
      j.Free;
      exit;
    end;
  end;

  j := TJ.create(await(datenmodul.EasySQL('select ID from User where upper(EMail) = ''' + Uppercase(edEMail.Text) + '''')));
  if (j.integer('ID') > 0) and (j.integer('ID') <> Mainform.ME.ID)
  then  begin
    meldung('Die EMail-Adresse wird bereit verwendet ...');
    j.Free;
    exit;
  end;

  j.response := await(datenmodul.EasySQL('select PWLEN from SYSTEM'));

  if edPasswort1.Text.length < j.Integer('PWLEN')
  then  begin
    meldung('Das Passwort muß mindestens ' + j.Value('PWLEN') + ' Zeichen lang sein...');
    j.Free;
    exit;
  end;

  if edPasswort1.Text <> edPasswort2.Text
  then  begin
    meldung('Das Passwort muß mit der Wiederholung übereinstimmen...');
    j.Free;
    exit;
  end;

  mainform.Wait := 1;

  sVereinID:= cbVerein.Values[cbVerein.ItemIndex];
  if sVereinID = ''
  then sVereinID := '0';

  sLandID:= cbLand.Values[cbLand.ItemIndex];
  if sLandID = ''
  then sLandID := '0';

  sBLandID:= cbBLand.Values[cbBLand.ItemIndex];
  if sBLandID = ''
  then sBLandID := '0';

  j.response := await(datenmodul.EasySQL('select ID from ORT where TEXT = ''' + edOrt.Text + ''' and LAND_ID = ' + sLandID ));
  if j.Integer('ID') > 0
  then sOrtID := j.value('ID')
  else sOrtID := await(datenmodul.EasyInsert('INSERT INTO ORT (TEXT, LAND_ID) VALUES(''' + edOrt.Text + ''',' + sLandID + ')')).toString;

  sScheine  := await(GetIDStringFromSugg(clScheine, 2));
  sFunk     := await(GetIDStringFromSugg(clFunk, 3));
  sFunktion := await(GetIDStringFromSugg(clFunktion, 4));
//  sHobbies  := aWait(GetIDStringFromString(edHobbies.Text));
//  sExpertise:= aWait(GetIDStringFromString(edExpertise.Text));
//  sReviere  := aWait(GetIDStringFromString(edReviere.Text));

  for i := 0 to _showcount do
    sFieldStatus := sFieldStatus + _US_GAST.ToString;

  SetStatus(cbShowAnrede.ItemIndex, _ShowAnrede, sFieldStatus);
  SetStatus(cbShowName.ItemIndex, _ShowName, sFieldStatus);
  SetStatus(cbShowVorname.ItemIndex, _ShowVorname, sFieldStatus);
  SetStatus(cbShowStrasse.ItemIndex, _ShowStrasse, sFieldStatus);
  SetStatus(cbShowLand.ItemIndex, _ShowLand, sFieldStatus);
  SetStatus(cbShowBLand.ItemIndex, _ShowBLand, sFieldStatus);
  SetStatus(cbShowEMail.ItemIndex, _ShowEMail, sFieldStatus);
  SetStatus(cbShowTelefon.ItemIndex, _ShowTelefon, sFieldStatus);
  SetStatus(cbShowWebseite.ItemIndex, _ShowWebseite, sFieldStatus);
  SetStatus(cbShowPLZ.ItemIndex, _ShowPLZ, sFieldStatus);
  SetStatus(cbShowOrt.ItemIndex, _ShowOrt, sFieldStatus);
  SetStatus(cbShowTelefon.ItemIndex, _ShowTelefon, sFieldStatus);
  SetStatus(cbShowNickname.ItemIndex, _ShowNickname, sFieldStatus);
  SetStatus(cbShowWebseite.ItemIndex, _ShowWebseite, sFieldStatus);
  SetStatus(cbShowGebDatum.ItemIndex, _ShowGebDatum, sFieldStatus);

  SetStatus(cbShowAbout.ItemIndex, _ShowAbout, sFieldStatus);
  SetStatus(cbShowHobbies.ItemIndex, _ShowHobbies, sFieldStatus);
  SetStatus(cbShowExpertise.ItemIndex, _ShowExpertise, sFieldStatus);
  SetStatus(cbShowReviere.ItemIndex, _ShowReviere, sFieldStatus);
  SetStatus(cbShowFreunde.ItemIndex, _ShowFreunde, sFieldStatus);

  if edGebDatum.Text > ''
  then sDatum := '''' + FormatDateTime( _Date_Save, edGebDatum.Date) + ''''
  else sDatum := 'NULL';

  //erstmal alle HashTags löschen
  await(datenmodul.EasyExec('DELETE from BlogX where TYP = 1 AND Blog_id = ' + Mainform.ME.ID.toString));

  //HashTags speichern
  HashSaveStatus(divHobbies, a);
  for i := 0 to length(a) - 1 do
     await(datenmodul.EasyInsert('INSERT INTO BlogX (TYP, BLOG_ID, BLOGKAT_ID) ' +
                                 '           VALUES (1,' + Mainform.ME.ID.toString + ',' + a[i].tostring + ')'));
  HashSaveStatus(divReviere, a);
  for i := 0 to length(a) - 1 do
     await(datenmodul.EasyInsert('INSERT INTO BlogX (TYP, BLOG_ID, BLOGKAT_ID) ' +
                                 '           VALUES (1,' + Mainform.ME.ID.toString + ',' + a[i].tostring + ')'));

  HashSaveStatus(divExpertise, a);
  for i := 0 to length(a) - 1 do
     await(datenmodul.EasyInsert('INSERT INTO BlogX (TYP, BLOG_ID, BLOGKAT_ID) ' +
                                 '           VALUES (1,' + Mainform.ME.ID.toString + ',' + a[i].tostring + ')'));

  sSQL := 'UPDATE USER   SET STATUS = 0, ' +
                            'NAME = ''' + edName.Text + ''', ' +
                            'VORNAME = ''' + edVorName.Text + ''', ' +
                            'NICKNAME = ''' + edNickname.Text + ''', ' +
                            'PLZ = ''' + edPLZ.Text + ''', ' +
                            'STRASSE = ''' + edStrasse.Text + ''', ' +
                            'LAND_ID = ' + sLandID + ', ' +
                            'BLAND_ID = ' + sBLandID  + ', ' +
                            'ORT_ID = ' + sOrtID + ', ' +
                            'ANREDE_ID = ' + cbAnrede.ItemIndex.ToString  + ', ' +
                            'GDATUM = ' + sDatum + ', ' +
                            'EMAIL = ''' + edEmail.Text + ''', ' +
                            'AVATAR_MEDIEN_ID = ' + imgAvatar.Tag.ToString + ', ' +
                            'TITEL_MEDIEN_ID = ' + imgTitel.Tag.ToString + ', ' +
                            'INFO_KURSE = ' + bool2String(chkInfoKurse.Checked) + ', ' +
                            'INFO_USER = ' + bool2String(chkInfoUsern.Checked) + ', ' +
                            'INFO_GRUPPEN = ' + bool2String(chkInfoGruppen.Checked) + ', ' +
                            'INFO_EVENTS = ' + bool2String(chkInfoEvents.Checked) + ', ' +
                            'ADMIN = ' + bool2String(chkAdmin.Checked) + ', ' +
                            'TRAINER = ' + bool2String(chkTrainer.Checked) + ', ' +
                            'CHARTER = ' + bool2String(chkCharter.Checked) + ', ' +
                            'EINHAND = ' + bool2String(chkEinhand.Checked) + ', ' +
                            'EIGNER = ' + bool2String(chkEigner.Checked) + ', ' +
                            'REGATTA = ' + bool2String(chkRegatta.Checked) + ', ' +
                            'FAHRTEN = ' + bool2String(chkFahrten.Checked) + ', ' +
                            'VEREIN = ' + bool2String(chkVerein.Checked) + ', ' +
                            'VEREIN_ID = ' + sVereinID + ', ' +
                            'FUNKTION = ''' + sFunktion + ''', ' +
                            'ABOUT = ''' + edAbout.Text + ''', ' +
                            'HOBBIES = ''' + sHobbies + ''', ' +
                            'REVIERE = ''' + sReviere + ''', ' +
                            'SCHEINE = ''' + sScheine + ''', ' +
                            'EXPERTISE = ''' + sExpertise + ''', ' +
                            'FUNK = ''' + sFunk + ''', ' +
                            'WEBSEITE = ''' + edWebseite.Text + ''', ' +
                            'TELEFON = ''' + edTelefon.Text + ''', ' +
                            'PASSWORD = ''' + edPasswort2.Text + ''', ' +
                            'FSCONFIRM = ' + cbFreundschaft.ItemIndex.ToString  + ', ' +
                            'FIELDSTATUS = ''' + sFieldStatus + ''' ' +
                     ' WHERE ID = ' + Mainform.ME.ID.toString;

  if Pos('ERR',ChkStr(sSQL)) = 1
  then begin
    mainform.Wait := -1;
    await(Meldung('Das Zeichen "#" darf nicht verwendet werden...'));
  end
  else begin
    try
      await(datenmodul.EasyExec(sSQL));
    except
        on E : Exception do
        begin
          meldung(E.Message);
        end;
    end;
    freeAndNil(j);

    await(meldung('Änderungen wurde gespeichert...'));

    Mainform.HolderID := Mainform.Holder.ID;

    mainform.Wait := -1;

  end;

end;

//procedure TfrmProfil.ConfirmEMail(aDaten:Array of string);
//var
//  j : TJ;
//  JSObj: TJSObject;
//  JSString, sImage,
//  sheader, sFooter, sBetreff, sText:string;
//begin
//
//  j := TJ.create(await(datenModul.EasySQL('SELECT LOGO_MEDIEN_ID, TXT_NEUES_PASSWORT, HEADER, FOOTER from SYSTEM')));
//  sImage:= '"' + datenmodul.Connection.URL + '/DBService/GetImage?Mediaid=' + j.Value('LOGO_MEDIEN_ID') + '&Size=2" width="60" alt="Segler-Portal-Logo!"';
//  sText := j.Value('TXT_NEUES_PASSWORT');
//  sHeader := j.Value('HEADER');
//  sFooter := j.Value('FOOTER');
//
//  j.response := await(datenModul.EasySQL('SELECT TITEL, TEXT from TEXTE WHERE ID = ' + sText));
//  sText      := sHeader + ' ' + j.Value('TEXT') + ' ' + sFooter;
//  sBetreff   := j.Value('TITEL');
//  sBetreff := StringReplace(sBetreff, #10, '', [rfReplaceAll]);
//  sBetreff := StringReplace(sBetreff, #13, '', [rfReplaceAll]);
//
//  sText := stringreplace(sText,'%BETREFF%', sBetreff, [rfReplaceAll]);
//  sText := stringreplace(sText,'%VORNAME%', aDaten[1], [rfReplaceAll]);
//  sText := stringreplace(sText,'%NAME%',    aDaten[0], [rfReplaceAll]);
//  sText := stringreplace(sText,'%PASSWORT%',aDaten[3], [rfReplaceAll]);
//  sText := stringreplace(sText,'%IMG1%',    sImage,    [rfReplaceAll]);
//
//  sText := str2JSon(sText);
//
//  JSString:= '{"EMAIL":   "' + aDaten[2] + '",' +
//              '"ID":       ' + aDaten[4] + ','  +
//              '"PASSWORT":"' + aDaten[3] + '",' +
//              '"BETREFF": "' + sBetreff  + '",' +
//              '"TEXT":    "' + sText     + '", '+
//              '"ANHANG":  ""}';
//
//  JSObj:=TJSJSON.parseObject(JSString);
//
//  TAwait.Exec<TXDataClientResponse>( Client.RawInvokeAsync( 'IDBService.SendEMail',[JSObj]));
//
//end;

procedure TfrmProfil.cbLandChange(Sender: TObject);
begin
  if cbLand.values[cbLand.ItemIndex].ToInteger > 0
  then begin
    FillAutoComplete(edOrt, 'TEXT',  'Select TEXT from Ort where Land_id = ' + cbLand.values[cbLand.ItemIndex]);
    edOrt.Text := '';
    tbFillCombobox(cbBLand, 'ID','TEXT', await(datenmodul.EasySQL('Select ID, TEXT from BLand where LAND_ID = ' + cbLand.values[cbLand.ItemIndex])));
    cbBLand.ItemIndex := -1;

  end;
end;

procedure TfrmProfil.FillAutoComplete(Aed:TEditAutoComplete; AFeld, ASQl:string);
Var
  J:TJ;
  i:integer;
  s:string;
  Acb:TCheckListBox;
begin
  J := TJ.create(Await(Datenmodul.EasySQL(ASQL)));

  for i := 0 to j.Length -1 do
  begin
    j.index := i;
    s := s + j.Value(AFeld) + ',';
  end;
  Aed.BeginUpdate;
  Aed.Items.StrictDelimiter := true;
  Aed.Items.Delimiter := ',';
  Aed.Items.DelimitedText := s;
  Aed.EndUpdate;
//  meldung(aed.Items.DelimitedText);
  freeAndNil(j);
end;

procedure TfrmProfil.Init(AUserID:integer);


    [async] function tbCreateStringFromIDs(AIDs: string):string; async;
    Var
      i:integer;
      J:TJ;
    begin
    //meldung(copy(AIDs,2,length(AIDs)-2));
      if AIDS > ''
      then begin
        AIDs := '0' + AIDs + '0';
        j := TJ.create(await(datenmodul.EasySQL('SElect * from blogkat where ID in (' + AIDs + ')')));

  //      meldung(j.Length.ToString);
        for i := 0 to j.Length -1 do
        begin
          j.Index:=i;
          if j.Text('TEXT') > ''
          then result := result + j.Text('TEXT') + ', ';
        end;

        freeAndNil(j);
      end;
    end;


    function GetComboBoxIndex(ACB:TCombobox; AValue:string):integer;
    var
      i:integer;
    begin
      result := -1;
      for i := 0 to ACB.items.count - 1 do
      begin
        if ACB.Values[i] = AValue
        then begin
          result := i;
          break;
        end;
      end;
    end;

    function showStatus(AFeld:string; AIDX:integer):integer;
    begin
       result := copy(AFeld,AIdx,1).tointeger;
       //meldung(AFeld + ' - ' +result.ToString);
    end;

Var
  j:TJ;
  i:integer;
  sFieldStatus,
  sSQL, sHobbies, sReviere,
  sKat:string;
  iAvatar, iTitel:integer;
begin

  divIntern.Visible := Mainform.ME.Admin;
  j := TJ.create(await(datenmodul.EasySQL('Select * from SYSTEM')));
  iAvatar := j.integer('AVATAR_MEDIEN_ID');
  iTitel  := j.integer('TITEL_MEDIEN_ID');

  j.response := await(datenmodul.EasySQL('Select * from V_User where ID = ' + AUserID.tostring));
  cbShowAnrede.ItemIndex   := ShowStatus(j.Value('FIELDSTATUS'), _ShowAnrede);
  cbShowName.ItemIndex     := ShowStatus(j.Value('FIELDSTATUS'), _ShowName);
  cbShowVorname.ItemIndex  := ShowStatus(j.Value('FIELDSTATUS'), _ShowVorname);
  cbShowStrasse.ItemIndex  := ShowStatus(j.Value('FIELDSTATUS'), _ShowStrasse);
  cbShowLand.ItemIndex     := ShowStatus(j.Value('FIELDSTATUS'), _ShowLand);
  cbShowBLand.ItemIndex    := ShowStatus(j.Value('FIELDSTATUS'), _ShowBLand);
  cbShowPLZ.ItemIndex      := ShowStatus(j.Value('FIELDSTATUS'), _ShowPLZ);
  cbShowOrt.ItemIndex      := ShowStatus(j.Value('FIELDSTATUS'), _ShowOrt);
  cbShowStrasse.ItemIndex  := ShowStatus(j.Value('FIELDSTATUS'), _ShowStrasse);
  cbShowEMail.ItemIndex    := ShowStatus(j.Value('FIELDSTATUS'), _ShowEMail);
  cbShowTelefon.ItemIndex  := ShowStatus(j.Value('FIELDSTATUS'), _ShowTelefon);
  cbShowNickname.ItemIndex := ShowStatus(j.Value('FIELDSTATUS'), _ShowNickname);
  cbShowWebseite.ItemIndex := ShowStatus(j.Value('FIELDSTATUS'), _ShowWebseite);
  cbShowGebDatum.ItemIndex := ShowStatus(j.Value('FIELDSTATUS'), _ShowGebDatum);
  cbShowAbout.ItemIndex    := ShowStatus(j.Value('FIELDSTATUS'), _ShowAbout);
  cbShowHobbies.ItemIndex  := ShowStatus(j.Value('FIELDSTATUS'), _ShowHobbies);
  cbShowExpertise.ItemIndex:= ShowStatus(j.Value('FIELDSTATUS'), _ShowExpertise);
  cbShowReviere.ItemIndex  := ShowStatus(j.Value('FIELDSTATUS'), _ShowReviere);
  cbShowFreunde.ItemIndex  := ShowStatus(j.Value('FIELDSTATUS'), _ShowFreunde);

  cbAnrede.ItemIndex := j.Integer('ANREDE_ID');
  cbFreundschaft.ItemIndex := j.Integer('FSCONFIRM');
  edName.Text     := j.Value('NAME');
  edVorname.Text  := j.Value('VORNAME');
  edPLZ.Text      := j.Value('PLZ');
  edStrasse.Text  := j.Value('STRASSE');
  edOrt.Text      := j.Value('ORT');
  edNickname.Text := j.Value('NICKNAME');
  edAbout.Text    := j.Value('ABOUT');
  edTelefon.Text  := j.Value('TELEFON');
  edWebseite.Text := j.Value('WEBSEITE');
  edPasswort1.Text:= j.Value('PASSWORD');
  edPasswort2.Text:= j.Value('PASSWORD');

  //wenn nicht null- dann ist die EMail mehrfach vorhanden.
  //durch anmeldung bei Stripe
  if J.integer('STATUS') = 0
  then edEMail.Text := j.Value('EMAIL');

  if j.Value('GDATUM') > ''
  then edGebDatum.Date := strtodate(j.Date2Str('GDATUM'))
  else edGebDatum.clear;

  chkVerein.Checked := j.isTrue('VEREIN');
  chkEigner.Checked := j.isTrue('EIGNER');
  chkCharter.Checked:= j.isTrue('CHARTER');
  chkEinhand.Checked:= j.isTrue('EINHAND');
  chkRegatta.Checked:= j.isTrue('REGATTA');
  chkFahrten.Checked:= j.isTrue('FAHRTEN');

  chkInfoKurse.Checked  := j.isTrue('INFO_KURSE');
  chkInfoUsern.Checked  := j.isTrue('INFO_USER');
  chkInfoGruppen.Checked:= j.isTrue('INFO_GRUPPEN');
  chkInfoEvents.Checked := j.isTrue('INFO_EVENTS');
  chkAdmin.Checked      := j.isTrue('ADMIN');
  chkTrainer.Checked    := j.isTrue('TRAINER');
  chkOrganizer.Checked  := j.isTrue('ORGANIZER');

  if (j.Integer('AVATAR_MEDIEN_ID') > 0)
  then begin
    imgAvatar.Tag := j.Integer('AVATAR_MEDIEN_ID');

    //nur anzeigen wenn nicht Standard
    if iAvatar <> j.integer('AVATAR_MEDIEN_ID')
    then imgAvatar.URL := await(Datenmodul.GetMedia(imgAvatar.Tag, _SIZE2));
  end;

  if (j.Integer('TITEL_MEDIEN_ID') > 0)
  then begin
    imgTitel.Tag := j.Integer('TITEL_MEDIEN_ID');

    //nur anzeigen wenn nicht Standard
    if iTitel <> j.Integer('TITEL_MEDIEN_ID')
    then imgTitel.URL := await(Datenmodul.GetMedia(imgTitel.Tag, _SIZE2));
  end;

  tbFillCombobox(cbLand, 'ID','TEXT',await(datenmodul.EasySQL('Select ID, TEXT from Land')));
  cbLand.ItemIndex := GetComboBoxIndex(cbLand, j.Value('LAND_ID'));
  tbFillCombobox(cbBLand, 'ID','TEXT',await(datenmodul.EasySQL('Select ID, TEXT from BLand WHERE LAND_ID = ' + j.Value('LAND_ID'))));
  cbBLand.ItemIndex := GetComboBoxIndex(cbBLand, j.Value('BLAND_ID'));
  tbFillCombobox(cbAnrede, 'ID','TEXT',await(datenmodul.EasySQL('Select * from sugg where Parent > 0 and Typ = 1 order by lfnr')));

  tbFillCombobox(cbVerein, 'ID','NAME',await(datenmodul.EasySQL('Select ID, NAME from verein')));
  cbVerein.ItemIndex := GetComboBoxIndex(cbVerein, j.Value('VEREIN_ID'));

  tbFillChkListBox(clScheine, 'ID','TEXT', j.Value('SCHEINE'), await(datenmodul.EasySQL('Select * from sugg where Parent > 0 and Typ = 2 order by lfnr')));
  tbFillChkListBox(clFunk, 'ID','TEXT',j.Value('FUNK'), await(datenmodul.EasySQL('Select * from sugg where Parent > 0 and Typ = 3 order by lfnr')));
  tbFillChkListBox(clFunktion, 'ID','TEXT',j.Value('FUNKTION'), await(datenmodul.EasySQL('Select * from sugg where Parent > 0 and Typ = 4 order by lfnr')));

//  await(RemoveAllChildControls(d));

  spHobbies.Tag  := 1; spReviere.Tag  := 2; spExpertise.Tag:= 3;

  await(FillHashTags(divHobbies,   Mainform.ME.ID.ToString, '', false, [],'1'));
//        FillHashTags(AParent       ABlogID,            ABlogKat AAlle; ACheckedarray of integer; ASub:string = ''; AHashTag:integer = 0; AButtonCLick: TNotifyEvent = nil):array of string;
  await(FillHashTags(divReviere,   Mainform.ME.ID.ToString, '', false, [],'2'));
  await(FillHashTags(divExpertise, Mainform.ME.ID.ToString, '', false, [],'3'));

  freeAndNil(j);

end;


procedure TfrmProfil.WebFormCreate(Sender: TObject);
var
  el: TJSHTMLElement;
begin
  inherited;
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.removeProperty('height');

  el := TJSHTMLElement(THTMLDiv(edGebDatum).ElementHandle);
  el.style.removeProperty('font-size');
end;

procedure TfrmProfil.SetAvatar(s: string);
begin
//  await(datenmodul.EasyExec('update User set AVATAR_MEDIEN_ID = ' + s + ' where ID = ' + Mainform.Holder.ID.tostring));
  imgAvatar.Tag := s.ToInteger;
  imgAvatar.URL := await(Datenmodul.GetMedia(s.tointeger, _SIZE2));
  Mainform.ME.Album := TJ.create(await(datenmodul.EasySQL('Select ALBUM_ID FROM User where ID = ' + Mainform.ME.ID.tostring))).integer('ALBUM_ID');
end;

procedure TfrmProfil.SetTitel(s: string);
begin
//  await(datenmodul.EasySQL('update User set AVATAR_MEDIEN_ID = ' + s + ' where ID = ' + Mainform.Holder.ID.tostring));
  imgTitel.Tag := s.ToInteger;
  imgTitel.URL := await(Datenmodul.GetMedia(s.tointeger, _SIZE2));
  Mainform.ME.Album := TJ.create(await(datenmodul.EasySQL('Select ALBUM_ID FROM User where ID = ' + Mainform.ME.ID.tostring))).integer('ALBUM_ID');
end;

procedure TfrmProfil.imgAvatarClick(Sender: TObject);
begin
  if Sender = imgAvatar
  then TfrmMedienAuswahl.Fotos(Mainform.ME.ID, Mainform.ME.id, Mainform.ME.Album,0, 0, 0, 0, @SetAvatar, 2)
  else TfrmMedienAuswahl.Fotos(Mainform.ME.ID, Mainform.ME.id, Mainform.ME.Album,0, 0, 0, 0, @SetTitel, 2)
end;

procedure TfrmProfil.imgAvatarMouseEnter(Sender: TObject);
begin
//  if assigned(FPosition)
//  then FPosition.Free;
//
//  FPosition := TfrImgPosition.show(self, divWrapper,  imgAvatar, 'USER', 'AVATAR_MEDIEN_POSITION', Mainform.Holder.ID.tostring);
end;

procedure TfrmProfil.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divContent := THTMLDiv.Create(Self);
  divHeader := THTMLDiv.Create(Self);
  lblTitel := THTMLDiv.Create(Self);
  WebHTMLDiv1 := THTMLDiv.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebHTMLDiv8 := THTMLDiv.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebHTMLDiv34 := THTMLDiv.Create(Self);
  cbAnrede := TComboBox.Create(Self);
  WebHTMLDiv19 := THTMLDiv.Create(Self);
  cbShowAnrede := TComboBox.Create(Self);
  WebHTMLDiv3 := THTMLDiv.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebHTMLDiv4 := THTMLDiv.Create(Self);
  edName := TEditAutoComplete.Create(Self);
  WebHTMLDiv25 := THTMLDiv.Create(Self);
  cbShowName := TComboBox.Create(Self);
  WebHTMLDiv5 := THTMLDiv.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebHTMLDiv6 := THTMLDiv.Create(Self);
  edVorname := TEditAutoComplete.Create(Self);
  WebHTMLDiv26 := THTMLDiv.Create(Self);
  cbShowVorname := TComboBox.Create(Self);
  WebHTMLDiv7 := THTMLDiv.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebHTMLDiv9 := THTMLDiv.Create(Self);
  cbLand := TComboBox.Create(Self);
  WebHTMLDiv36 := THTMLDiv.Create(Self);
  cbShowLand := TComboBox.Create(Self);
  WebHTMLDiv10 := THTMLDiv.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebHTMLDiv37 := THTMLDiv.Create(Self);
  cbBLand := TComboBox.Create(Self);
  WebHTMLDiv35 := THTMLDiv.Create(Self);
  cbShowBLand := TComboBox.Create(Self);
  WebHTMLDiv38 := THTMLDiv.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebHTMLDiv39 := THTMLDiv.Create(Self);
  edStrasse := TEditAutoComplete.Create(Self);
  WebHTMLDiv45 := THTMLDiv.Create(Self);
  cbShowStrasse := TComboBox.Create(Self);
  WebHTMLDiv40 := THTMLDiv.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebHTMLDiv41 := THTMLDiv.Create(Self);
  edPLZ := TEditAutoComplete.Create(Self);
  WebHTMLDiv46 := THTMLDiv.Create(Self);
  cbShowPLZ := TComboBox.Create(Self);
  WebHTMLDiv42 := THTMLDiv.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebHTMLDiv43 := THTMLDiv.Create(Self);
  edOrt := TEditAutoComplete.Create(Self);
  WebHTMLDiv63 := THTMLDiv.Create(Self);
  cbShowOrt := TComboBox.Create(Self);
  WebHTMLDiv54 := THTMLDiv.Create(Self);
  WebLabel24 := TLabel.Create(Self);
  WebHTMLDiv55 := THTMLDiv.Create(Self);
  edGebDatum := TDateTimePicker.Create(Self);
  WebHTMLDiv64 := THTMLDiv.Create(Self);
  cbShowGebDatum := TComboBox.Create(Self);
  WebHTMLDiv21 := THTMLDiv.Create(Self);
  WebLabel14 := TLabel.Create(Self);
  WebHTMLDiv22 := THTMLDiv.Create(Self);
  WebLabel15 := TLabel.Create(Self);
  WebHTMLDiv50 := THTMLDiv.Create(Self);
  edEMAil := TEditAutoComplete.Create(Self);
  WebHTMLDiv71 := THTMLDiv.Create(Self);
  cbShowEMail := TComboBox.Create(Self);
  WebHTMLDiv82 := THTMLDiv.Create(Self);
  WebLabel30 := TLabel.Create(Self);
  WebHTMLDiv83 := THTMLDiv.Create(Self);
  edPasswort1 := TEdit.Create(Self);
  WebHTMLDiv79 := THTMLDiv.Create(Self);
  WebLabel29 := TLabel.Create(Self);
  WebHTMLDiv80 := THTMLDiv.Create(Self);
  edPasswort2 := TEdit.Create(Self);
  WebHTMLDiv23 := THTMLDiv.Create(Self);
  WebLabel16 := TLabel.Create(Self);
  WebHTMLDiv51 := THTMLDiv.Create(Self);
  edTelefon := TEditAutoComplete.Create(Self);
  WebHTMLDiv72 := THTMLDiv.Create(Self);
  cbShowTelefon := TComboBox.Create(Self);
  WebHTMLDiv24 := THTMLDiv.Create(Self);
  WebLabel17 := TLabel.Create(Self);
  WebHTMLDiv52 := THTMLDiv.Create(Self);
  edWebSeite := TEditAutoComplete.Create(Self);
  WebHTMLDiv73 := THTMLDiv.Create(Self);
  cbShowWebseite := TComboBox.Create(Self);
  WebHTMLDiv81 := THTMLDiv.Create(Self);
  WebLabel31 := TLabel.Create(Self);
  WebHTMLDiv85 := THTMLDiv.Create(Self);
  cbFreundschaft := TComboBox.Create(Self);
  WebHTMLDiv95 := THTMLDiv.Create(Self);
  WebCheckBox1 := TCheckBox.Create(Self);
  WebHTMLDiv16 := THTMLDiv.Create(Self);
  WebLabel34 := TLabel.Create(Self);
  WebHTMLDiv97 := THTMLDiv.Create(Self);
  cbShowFreunde := TComboBox.Create(Self);
  WebHTMLDiv11 := THTMLDiv.Create(Self);
  WebHTMLDiv12 := THTMLDiv.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebHTMLDiv13 := THTMLDiv.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebHTMLDiv44 := THTMLDiv.Create(Self);
  edNickname := TEditAutoComplete.Create(Self);
  WebHTMLDiv78 := THTMLDiv.Create(Self);
  cbShowNickname := TComboBox.Create(Self);
  WebHTMLDiv14 := THTMLDiv.Create(Self);
  WebLabel12 := TLabel.Create(Self);
  divWrapper := THTMLDiv.Create(Self);
  imgAvatar := TImageControl.Create(Self);
  WebHTMLDiv27 := THTMLDiv.Create(Self);
  WebHTMLDiv99 := THTMLDiv.Create(Self);
  WebLabel18 := TLabel.Create(Self);
  WebHTMLDiv74 := THTMLDiv.Create(Self);
  cbShowAbout := TComboBox.Create(Self);
  edAbout := TMemo.Create(Self);
  WebHTMLDiv28 := THTMLDiv.Create(Self);
  WebHTMLDiv47 := THTMLDiv.Create(Self);
  WebLabel19 := TLabel.Create(Self);
  spHobbies := TSpeedButton.Create(Self);
  WebHTMLDiv75 := THTMLDiv.Create(Self);
  cbShowHobbies := TComboBox.Create(Self);
  divHobbies := THTMLDiv.Create(Self);
  WebHTMLDiv15 := THTMLDiv.Create(Self);
  WebLabel13 := TLabel.Create(Self);
  WebHTMLDiv18 := THTMLDiv.Create(Self);
  imgTitel := TImageControl.Create(Self);
  WebHTMLDiv29 := THTMLDiv.Create(Self);
  WebHTMLDiv48 := THTMLDiv.Create(Self);
  WebLabel20 := TLabel.Create(Self);
  spReviere := TSpeedButton.Create(Self);
  WebHTMLDiv49 := THTMLDiv.Create(Self);
  cbShowReviere := TComboBox.Create(Self);
  divReviere := THTMLDiv.Create(Self);
  WebHTMLDiv58 := THTMLDiv.Create(Self);
  WebHTMLDiv76 := THTMLDiv.Create(Self);
  WebLabel25 := TLabel.Create(Self);
  spExpertise := TSpeedButton.Create(Self);
  WebHTMLDiv77 := THTMLDiv.Create(Self);
  cbShowExpertise := TComboBox.Create(Self);
  divExpertise := THTMLDiv.Create(Self);
  WebHTMLDiv30 := THTMLDiv.Create(Self);
  WebHTMLDiv31 := THTMLDiv.Create(Self);
  WebLabel21 := TLabel.Create(Self);
  WebHTMLDiv33 := THTMLDiv.Create(Self);
  WebLabel23 := TLabel.Create(Self);
  WebHTMLDiv56 := THTMLDiv.Create(Self);
  clScheine := TCheckListBox.Create(Self);
  WebHTMLDiv32 := THTMLDiv.Create(Self);
  WebLabel22 := TLabel.Create(Self);
  WebHTMLDiv53 := THTMLDiv.Create(Self);
  clFunk := TCheckListBox.Create(Self);
  WebHTMLDiv59 := THTMLDiv.Create(Self);
  WebHTMLDiv60 := THTMLDiv.Create(Self);
  WebLabel26 := TLabel.Create(Self);
  WebHTMLDiv61 := THTMLDiv.Create(Self);
  WebLabel27 := TLabel.Create(Self);
  WebHTMLDiv62 := THTMLDiv.Create(Self);
  clFunktion := TCheckListBox.Create(Self);
  WebHTMLDiv65 := THTMLDiv.Create(Self);
  chkEigner := TCheckBox.Create(Self);
  WebHTMLDiv66 := THTMLDiv.Create(Self);
  chkVerein := TCheckBox.Create(Self);
  WebHTMLDiv67 := THTMLDiv.Create(Self);
  WebLabel28 := TLabel.Create(Self);
  WebHTMLDiv68 := THTMLDiv.Create(Self);
  cbVerein := TComboBox.Create(Self);
  WebHTMLDiv69 := THTMLDiv.Create(Self);
  chkCharter := TCheckBox.Create(Self);
  WebHTMLDiv70 := THTMLDiv.Create(Self);
  chkEinhand := TCheckBox.Create(Self);
  WebHTMLDiv96 := THTMLDiv.Create(Self);
  chkRegatta := TCheckBox.Create(Self);
  WebHTMLDiv98 := THTMLDiv.Create(Self);
  chkFahrten := TCheckBox.Create(Self);
  WebHTMLDiv84 := THTMLDiv.Create(Self);
  WebHTMLDiv86 := THTMLDiv.Create(Self);
  WebLabel32 := TLabel.Create(Self);
  WebHTMLDiv87 := THTMLDiv.Create(Self);
  chkInfoGruppen := TCheckBox.Create(Self);
  WebHTMLDiv88 := THTMLDiv.Create(Self);
  chkInfoEvents := TCheckBox.Create(Self);
  WebHTMLDiv89 := THTMLDiv.Create(Self);
  chkInfoUsern := TCheckBox.Create(Self);
  WebHTMLDiv90 := THTMLDiv.Create(Self);
  chkInfoKurse := TCheckBox.Create(Self);
  divIntern := THTMLDiv.Create(Self);
  WebHTMLDiv92 := THTMLDiv.Create(Self);
  WebLabel33 := TLabel.Create(Self);
  WebHTMLDiv93 := THTMLDiv.Create(Self);
  chkAdmin := TCheckBox.Create(Self);
  WebHTMLDiv94 := THTMLDiv.Create(Self);
  chkTrainer := TCheckBox.Create(Self);
  WebHTMLDiv91 := THTMLDiv.Create(Self);
  chkOrganizer := TCheckBox.Create(Self);
  WebHTMLDiv17 := THTMLDiv.Create(Self);
  btnPersSave := TButton.Create(Self);
  btnPersCancel := TButton.Create(Self);
  Client := TXDataWebClient.Create(Self);

  divContent.BeforeLoadDFMValues;
  divHeader.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  WebHTMLDiv1.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebHTMLDiv8.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebHTMLDiv34.BeforeLoadDFMValues;
  cbAnrede.BeforeLoadDFMValues;
  WebHTMLDiv19.BeforeLoadDFMValues;
  cbShowAnrede.BeforeLoadDFMValues;
  WebHTMLDiv3.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebHTMLDiv4.BeforeLoadDFMValues;
  edName.BeforeLoadDFMValues;
  WebHTMLDiv25.BeforeLoadDFMValues;
  cbShowName.BeforeLoadDFMValues;
  WebHTMLDiv5.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebHTMLDiv6.BeforeLoadDFMValues;
  edVorname.BeforeLoadDFMValues;
  WebHTMLDiv26.BeforeLoadDFMValues;
  cbShowVorname.BeforeLoadDFMValues;
  WebHTMLDiv7.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebHTMLDiv9.BeforeLoadDFMValues;
  cbLand.BeforeLoadDFMValues;
  WebHTMLDiv36.BeforeLoadDFMValues;
  cbShowLand.BeforeLoadDFMValues;
  WebHTMLDiv10.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebHTMLDiv37.BeforeLoadDFMValues;
  cbBLand.BeforeLoadDFMValues;
  WebHTMLDiv35.BeforeLoadDFMValues;
  cbShowBLand.BeforeLoadDFMValues;
  WebHTMLDiv38.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebHTMLDiv39.BeforeLoadDFMValues;
  edStrasse.BeforeLoadDFMValues;
  WebHTMLDiv45.BeforeLoadDFMValues;
  cbShowStrasse.BeforeLoadDFMValues;
  WebHTMLDiv40.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebHTMLDiv41.BeforeLoadDFMValues;
  edPLZ.BeforeLoadDFMValues;
  WebHTMLDiv46.BeforeLoadDFMValues;
  cbShowPLZ.BeforeLoadDFMValues;
  WebHTMLDiv42.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebHTMLDiv43.BeforeLoadDFMValues;
  edOrt.BeforeLoadDFMValues;
  WebHTMLDiv63.BeforeLoadDFMValues;
  cbShowOrt.BeforeLoadDFMValues;
  WebHTMLDiv54.BeforeLoadDFMValues;
  WebLabel24.BeforeLoadDFMValues;
  WebHTMLDiv55.BeforeLoadDFMValues;
  edGebDatum.BeforeLoadDFMValues;
  WebHTMLDiv64.BeforeLoadDFMValues;
  cbShowGebDatum.BeforeLoadDFMValues;
  WebHTMLDiv21.BeforeLoadDFMValues;
  WebLabel14.BeforeLoadDFMValues;
  WebHTMLDiv22.BeforeLoadDFMValues;
  WebLabel15.BeforeLoadDFMValues;
  WebHTMLDiv50.BeforeLoadDFMValues;
  edEMAil.BeforeLoadDFMValues;
  WebHTMLDiv71.BeforeLoadDFMValues;
  cbShowEMail.BeforeLoadDFMValues;
  WebHTMLDiv82.BeforeLoadDFMValues;
  WebLabel30.BeforeLoadDFMValues;
  WebHTMLDiv83.BeforeLoadDFMValues;
  edPasswort1.BeforeLoadDFMValues;
  WebHTMLDiv79.BeforeLoadDFMValues;
  WebLabel29.BeforeLoadDFMValues;
  WebHTMLDiv80.BeforeLoadDFMValues;
  edPasswort2.BeforeLoadDFMValues;
  WebHTMLDiv23.BeforeLoadDFMValues;
  WebLabel16.BeforeLoadDFMValues;
  WebHTMLDiv51.BeforeLoadDFMValues;
  edTelefon.BeforeLoadDFMValues;
  WebHTMLDiv72.BeforeLoadDFMValues;
  cbShowTelefon.BeforeLoadDFMValues;
  WebHTMLDiv24.BeforeLoadDFMValues;
  WebLabel17.BeforeLoadDFMValues;
  WebHTMLDiv52.BeforeLoadDFMValues;
  edWebSeite.BeforeLoadDFMValues;
  WebHTMLDiv73.BeforeLoadDFMValues;
  cbShowWebseite.BeforeLoadDFMValues;
  WebHTMLDiv81.BeforeLoadDFMValues;
  WebLabel31.BeforeLoadDFMValues;
  WebHTMLDiv85.BeforeLoadDFMValues;
  cbFreundschaft.BeforeLoadDFMValues;
  WebHTMLDiv95.BeforeLoadDFMValues;
  WebCheckBox1.BeforeLoadDFMValues;
  WebHTMLDiv16.BeforeLoadDFMValues;
  WebLabel34.BeforeLoadDFMValues;
  WebHTMLDiv97.BeforeLoadDFMValues;
  cbShowFreunde.BeforeLoadDFMValues;
  WebHTMLDiv11.BeforeLoadDFMValues;
  WebHTMLDiv12.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebHTMLDiv13.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebHTMLDiv44.BeforeLoadDFMValues;
  edNickname.BeforeLoadDFMValues;
  WebHTMLDiv78.BeforeLoadDFMValues;
  cbShowNickname.BeforeLoadDFMValues;
  WebHTMLDiv14.BeforeLoadDFMValues;
  WebLabel12.BeforeLoadDFMValues;
  divWrapper.BeforeLoadDFMValues;
  imgAvatar.BeforeLoadDFMValues;
  WebHTMLDiv27.BeforeLoadDFMValues;
  WebHTMLDiv99.BeforeLoadDFMValues;
  WebLabel18.BeforeLoadDFMValues;
  WebHTMLDiv74.BeforeLoadDFMValues;
  cbShowAbout.BeforeLoadDFMValues;
  edAbout.BeforeLoadDFMValues;
  WebHTMLDiv28.BeforeLoadDFMValues;
  WebHTMLDiv47.BeforeLoadDFMValues;
  WebLabel19.BeforeLoadDFMValues;
  spHobbies.BeforeLoadDFMValues;
  WebHTMLDiv75.BeforeLoadDFMValues;
  cbShowHobbies.BeforeLoadDFMValues;
  divHobbies.BeforeLoadDFMValues;
  WebHTMLDiv15.BeforeLoadDFMValues;
  WebLabel13.BeforeLoadDFMValues;
  WebHTMLDiv18.BeforeLoadDFMValues;
  imgTitel.BeforeLoadDFMValues;
  WebHTMLDiv29.BeforeLoadDFMValues;
  WebHTMLDiv48.BeforeLoadDFMValues;
  WebLabel20.BeforeLoadDFMValues;
  spReviere.BeforeLoadDFMValues;
  WebHTMLDiv49.BeforeLoadDFMValues;
  cbShowReviere.BeforeLoadDFMValues;
  divReviere.BeforeLoadDFMValues;
  WebHTMLDiv58.BeforeLoadDFMValues;
  WebHTMLDiv76.BeforeLoadDFMValues;
  WebLabel25.BeforeLoadDFMValues;
  spExpertise.BeforeLoadDFMValues;
  WebHTMLDiv77.BeforeLoadDFMValues;
  cbShowExpertise.BeforeLoadDFMValues;
  divExpertise.BeforeLoadDFMValues;
  WebHTMLDiv30.BeforeLoadDFMValues;
  WebHTMLDiv31.BeforeLoadDFMValues;
  WebLabel21.BeforeLoadDFMValues;
  WebHTMLDiv33.BeforeLoadDFMValues;
  WebLabel23.BeforeLoadDFMValues;
  WebHTMLDiv56.BeforeLoadDFMValues;
  clScheine.BeforeLoadDFMValues;
  WebHTMLDiv32.BeforeLoadDFMValues;
  WebLabel22.BeforeLoadDFMValues;
  WebHTMLDiv53.BeforeLoadDFMValues;
  clFunk.BeforeLoadDFMValues;
  WebHTMLDiv59.BeforeLoadDFMValues;
  WebHTMLDiv60.BeforeLoadDFMValues;
  WebLabel26.BeforeLoadDFMValues;
  WebHTMLDiv61.BeforeLoadDFMValues;
  WebLabel27.BeforeLoadDFMValues;
  WebHTMLDiv62.BeforeLoadDFMValues;
  clFunktion.BeforeLoadDFMValues;
  WebHTMLDiv65.BeforeLoadDFMValues;
  chkEigner.BeforeLoadDFMValues;
  WebHTMLDiv66.BeforeLoadDFMValues;
  chkVerein.BeforeLoadDFMValues;
  WebHTMLDiv67.BeforeLoadDFMValues;
  WebLabel28.BeforeLoadDFMValues;
  WebHTMLDiv68.BeforeLoadDFMValues;
  cbVerein.BeforeLoadDFMValues;
  WebHTMLDiv69.BeforeLoadDFMValues;
  chkCharter.BeforeLoadDFMValues;
  WebHTMLDiv70.BeforeLoadDFMValues;
  chkEinhand.BeforeLoadDFMValues;
  WebHTMLDiv96.BeforeLoadDFMValues;
  chkRegatta.BeforeLoadDFMValues;
  WebHTMLDiv98.BeforeLoadDFMValues;
  chkFahrten.BeforeLoadDFMValues;
  WebHTMLDiv84.BeforeLoadDFMValues;
  WebHTMLDiv86.BeforeLoadDFMValues;
  WebLabel32.BeforeLoadDFMValues;
  WebHTMLDiv87.BeforeLoadDFMValues;
  chkInfoGruppen.BeforeLoadDFMValues;
  WebHTMLDiv88.BeforeLoadDFMValues;
  chkInfoEvents.BeforeLoadDFMValues;
  WebHTMLDiv89.BeforeLoadDFMValues;
  chkInfoUsern.BeforeLoadDFMValues;
  WebHTMLDiv90.BeforeLoadDFMValues;
  chkInfoKurse.BeforeLoadDFMValues;
  divIntern.BeforeLoadDFMValues;
  WebHTMLDiv92.BeforeLoadDFMValues;
  WebLabel33.BeforeLoadDFMValues;
  WebHTMLDiv93.BeforeLoadDFMValues;
  chkAdmin.BeforeLoadDFMValues;
  WebHTMLDiv94.BeforeLoadDFMValues;
  chkTrainer.BeforeLoadDFMValues;
  WebHTMLDiv91.BeforeLoadDFMValues;
  chkOrganizer.BeforeLoadDFMValues;
  WebHTMLDiv17.BeforeLoadDFMValues;
  btnPersSave.BeforeLoadDFMValues;
  btnPersCancel.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frmProfil';
    Width := 1197;
    Height := 1100;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    divContent.SetParentComponent(Self);
    divContent.Name := 'divContent';
    divContent.Left := 20;
    divContent.Top := 8;
    divContent.Width := 1177;
    divContent.Height := 1196;
    divContent.ElementClassName := 'profil_wrapper';
    divContent.HeightStyle := ssAuto;
    divContent.WidthStyle := ssAuto;
    divContent.ChildOrder := 1;
    divContent.ElementPosition := epIgnore;
    divContent.ElementFont := efCSS;
    divContent.Role := '';
    divHeader.SetParentComponent(divContent);
    divHeader.Name := 'divHeader';
    divHeader.Left := 4;
    divHeader.Top := 0;
    divHeader.Width := 774;
    divHeader.Height := 55;
    divHeader.ElementClassName := 'me_headline';
    divHeader.HeightStyle := ssAuto;
    divHeader.WidthStyle := ssAuto;
    divHeader.ElementPosition := epIgnore;
    divHeader.ElementFont := efCSS;
    divHeader.HTML.BeginUpdate;
    try
      divHeader.HTML.Clear;
      divHeader.HTML.Add('');
    finally
      divHeader.HTML.EndUpdate;
    end;
    divHeader.Role := '';
    lblTitel.SetParentComponent(divHeader);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 16;
    lblTitel.Top := 3;
    lblTitel.Width := 100;
    lblTitel.Height := 41;
    lblTitel.HeightStyle := ssAuto;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.ChildOrder := 1;
    lblTitel.ElementPosition := epIgnore;
    lblTitel.ElementFont := efCSS;
    lblTitel.HTML.BeginUpdate;
    try
      lblTitel.HTML.Clear;
      lblTitel.HTML.Add('MEIN PROFIL');
    finally
      lblTitel.HTML.EndUpdate;
    end;
    lblTitel.Role := '';
    WebHTMLDiv1.SetParentComponent(divContent);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 3;
    WebHTMLDiv1.Top := 50;
    WebHTMLDiv1.Width := 377;
    WebHTMLDiv1.Height := 990;
    WebHTMLDiv1.ElementClassName := 'profil_container';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ChildOrder := 1;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv2.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 10;
    WebHTMLDiv2.Top := 16;
    WebHTMLDiv2.Width := 345;
    WebHTMLDiv2.Height := 40;
    WebHTMLDiv2.ElementClassName := 'profil_header';
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ElementPosition := epIgnore;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.Role := '';
    WebLabel1.SetParentComponent(WebHTMLDiv2);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 16;
    WebLabel1.Top := 16;
    WebLabel1.Width := 66;
    WebLabel1.Height := 15;
    WebLabel1.Caption := 'Pers'#246'nliches';
    WebLabel1.ElementFont := efCSS;
    WebLabel1.ElementPosition := epRelative;
    WebLabel1.HeightStyle := ssAuto;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthStyle := ssAuto;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebHTMLDiv8.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv8.Name := 'WebHTMLDiv8';
    WebHTMLDiv8.Left := 10;
    WebHTMLDiv8.Top := 63;
    WebHTMLDiv8.Width := 345;
    WebHTMLDiv8.Height := 40;
    WebHTMLDiv8.ElementClassName := 'profil_line';
    WebHTMLDiv8.HeightStyle := ssAuto;
    WebHTMLDiv8.WidthStyle := ssAuto;
    WebHTMLDiv8.ChildOrder := 1;
    WebHTMLDiv8.ElementPosition := epRelative;
    WebHTMLDiv8.ElementFont := efCSS;
    WebHTMLDiv8.Role := '';
    WebLabel7.SetParentComponent(WebHTMLDiv8);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 20;
    WebLabel7.Top := 11;
    WebLabel7.Width := 41;
    WebLabel7.Height := 15;
    WebLabel7.Caption := 'Anrede:';
    WebLabel7.ElementClassName := 'profil_label';
    WebLabel7.ElementFont := efCSS;
    WebLabel7.ElementPosition := epIgnore;
    WebLabel7.HeightStyle := ssAuto;
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.WidthStyle := ssAuto;
    WebLabel7.WidthPercent := 100.000000000000000000;
    WebHTMLDiv34.SetParentComponent(WebHTMLDiv8);
    WebHTMLDiv34.Name := 'WebHTMLDiv34';
    WebHTMLDiv34.Left := 100;
    WebHTMLDiv34.Top := 3;
    WebHTMLDiv34.Width := 100;
    WebHTMLDiv34.Height := 30;
    WebHTMLDiv34.ElementClassName := 'profil_edit_container';
    WebHTMLDiv34.HeightStyle := ssAuto;
    WebHTMLDiv34.WidthStyle := ssAuto;
    WebHTMLDiv34.ChildOrder := 2;
    WebHTMLDiv34.ElementPosition := epIgnore;
    WebHTMLDiv34.ElementFont := efCSS;
    WebHTMLDiv34.Role := '';
    cbAnrede.SetParentComponent(WebHTMLDiv34);
    cbAnrede.Name := 'cbAnrede';
    cbAnrede.Left := 3;
    cbAnrede.Top := 4;
    cbAnrede.Width := 60;
    cbAnrede.Height := 23;
    cbAnrede.ChildOrder := 1;
    cbAnrede.ElementClassName := 'profil_edit';
    cbAnrede.ElementFont := efCSS;
    cbAnrede.ElementPosition := epIgnore;
    cbAnrede.HeightStyle := ssAuto;
    cbAnrede.HeightPercent := 100.000000000000000000;
    cbAnrede.WidthStyle := ssAuto;
    cbAnrede.WidthPercent := 100.000000000000000000;
    cbAnrede.ItemIndex := -1;
    cbAnrede.Items.BeginUpdate;
    try
      cbAnrede.Items.Clear;
      cbAnrede.Items.Add('');
      cbAnrede.Items.Add('Herr');
      cbAnrede.Items.Add('Frau');
    finally
      cbAnrede.Items.EndUpdate;
    end;
    WebHTMLDiv19.SetParentComponent(WebHTMLDiv8);
    WebHTMLDiv19.Name := 'WebHTMLDiv19';
    WebHTMLDiv19.Left := 250;
    WebHTMLDiv19.Top := 3;
    WebHTMLDiv19.Width := 79;
    WebHTMLDiv19.Height := 30;
    WebHTMLDiv19.ElementClassName := 'profil_sichtbar';
    WebHTMLDiv19.HeightStyle := ssAuto;
    WebHTMLDiv19.WidthStyle := ssAuto;
    WebHTMLDiv19.ChildOrder := 2;
    WebHTMLDiv19.ElementPosition := epIgnore;
    WebHTMLDiv19.ElementFont := efCSS;
    WebHTMLDiv19.Role := '';
    cbShowAnrede.SetParentComponent(WebHTMLDiv19);
    cbShowAnrede.Name := 'cbShowAnrede';
    cbShowAnrede.Left := 3;
    cbShowAnrede.Top := 3;
    cbShowAnrede.Width := 68;
    cbShowAnrede.Height := 23;
    cbShowAnrede.ChildOrder := 1;
    cbShowAnrede.ElementClassName := 'profil_edit';
    cbShowAnrede.ElementFont := efCSS;
    cbShowAnrede.ElementPosition := epIgnore;
    cbShowAnrede.HeightStyle := ssAuto;
    cbShowAnrede.HeightPercent := 100.000000000000000000;
    cbShowAnrede.WidthStyle := ssAuto;
    cbShowAnrede.WidthPercent := 100.000000000000000000;
    cbShowAnrede.ItemIndex := -1;
    cbShowAnrede.Items.BeginUpdate;
    try
      cbShowAnrede.Items.Clear;
      cbShowAnrede.Items.Add('nur ich darf das sehen');
      cbShowAnrede.Items.Add('Freunde d'#252'rfen das sehen');
      cbShowAnrede.Items.Add('Mitglieder d'#252'rfen das sehen');
      cbShowAnrede.Items.Add('jeder darf das sehen');
    finally
      cbShowAnrede.Items.EndUpdate;
    end;
    WebHTMLDiv3.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv3.Name := 'WebHTMLDiv3';
    WebHTMLDiv3.Left := 10;
    WebHTMLDiv3.Top := 109;
    WebHTMLDiv3.Width := 345;
    WebHTMLDiv3.Height := 40;
    WebHTMLDiv3.ElementClassName := 'profil_line';
    WebHTMLDiv3.HeightStyle := ssAuto;
    WebHTMLDiv3.WidthStyle := ssAuto;
    WebHTMLDiv3.ChildOrder := 2;
    WebHTMLDiv3.ElementPosition := epRelative;
    WebHTMLDiv3.ElementFont := efCSS;
    WebHTMLDiv3.Role := '';
    WebLabel2.SetParentComponent(WebHTMLDiv3);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 20;
    WebLabel2.Top := 11;
    WebLabel2.Width := 35;
    WebLabel2.Height := 15;
    WebLabel2.Caption := 'Name:';
    WebLabel2.ElementClassName := 'profil_label';
    WebLabel2.ElementFont := efCSS;
    WebLabel2.ElementPosition := epIgnore;
    WebLabel2.HeightStyle := ssAuto;
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthStyle := ssAuto;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebHTMLDiv4.SetParentComponent(WebHTMLDiv3);
    WebHTMLDiv4.Name := 'WebHTMLDiv4';
    WebHTMLDiv4.Left := 100;
    WebHTMLDiv4.Top := 3;
    WebHTMLDiv4.Width := 100;
    WebHTMLDiv4.Height := 30;
    WebHTMLDiv4.ElementClassName := 'profil_edit_container';
    WebHTMLDiv4.HeightStyle := ssAuto;
    WebHTMLDiv4.WidthStyle := ssAuto;
    WebHTMLDiv4.ChildOrder := 2;
    WebHTMLDiv4.ElementPosition := epIgnore;
    WebHTMLDiv4.ElementFont := efCSS;
    WebHTMLDiv4.Role := '';
    edName.SetParentComponent(WebHTMLDiv4);
    edName.Name := 'edName';
    edName.Left := 10;
    edName.Top := 2;
    edName.Width := 60;
    edName.Height := 25;
    edName.Margins.Left := 0;
    edName.Margins.Top := 0;
    edName.Margins.Right := 0;
    edName.Margins.Bottom := 0;
    edName.ElementClassName := 'profil_edit';
    edName.HeightStyle := ssAuto;
    edName.WidthStyle := ssAuto;
    edName.AutoSelect := True;
    edName.BorderStyle := bsSingle;
    edName.Color := clWhite;
    edName.ElementFont := efCSS;
    edName.ElementPosition := epRelative;
    edName.ShowHint := True;
    edName.TabStop := True;
    edName.TabOrder := 0;
    edName.Items.BeginUpdate;
    try
      edName.Items.Clear;
      edName.Items.Add('test 1');
      edName.Items.Add('test 2');
      edName.Items.Add('test 3');
    finally
      edName.Items.EndUpdate;
    end;
    edName.Text := '';
    WebHTMLDiv25.SetParentComponent(WebHTMLDiv3);
    WebHTMLDiv25.Name := 'WebHTMLDiv25';
    WebHTMLDiv25.Left := 250;
    WebHTMLDiv25.Top := 3;
    WebHTMLDiv25.Width := 79;
    WebHTMLDiv25.Height := 30;
    WebHTMLDiv25.ElementClassName := 'profil_sichtbar';
    WebHTMLDiv25.HeightStyle := ssAuto;
    WebHTMLDiv25.WidthStyle := ssAuto;
    WebHTMLDiv25.ChildOrder := 2;
    WebHTMLDiv25.ElementPosition := epIgnore;
    WebHTMLDiv25.ElementFont := efCSS;
    WebHTMLDiv25.Role := '';
    cbShowName.SetParentComponent(WebHTMLDiv25);
    cbShowName.Name := 'cbShowName';
    cbShowName.Left := 3;
    cbShowName.Top := 3;
    cbShowName.Width := 68;
    cbShowName.Height := 23;
    cbShowName.ChildOrder := 1;
    cbShowName.ElementClassName := 'profil_edit';
    cbShowName.ElementFont := efCSS;
    cbShowName.ElementPosition := epIgnore;
    cbShowName.HeightStyle := ssAuto;
    cbShowName.HeightPercent := 100.000000000000000000;
    cbShowName.WidthStyle := ssAuto;
    cbShowName.WidthPercent := 100.000000000000000000;
    cbShowName.ItemIndex := -1;
    cbShowName.Items.BeginUpdate;
    try
      cbShowName.Items.Clear;
      cbShowName.Items.Add('nur ich darf das sehen');
      cbShowName.Items.Add('Freunde d'#252'rfen das sehen');
      cbShowName.Items.Add('Mitglieder d'#252'rfen das sehen');
      cbShowName.Items.Add('jeder darf das sehen');
    finally
      cbShowName.Items.EndUpdate;
    end;
    WebHTMLDiv5.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv5.Name := 'WebHTMLDiv5';
    WebHTMLDiv5.Left := 10;
    WebHTMLDiv5.Top := 155;
    WebHTMLDiv5.Width := 345;
    WebHTMLDiv5.Height := 40;
    WebHTMLDiv5.ElementClassName := 'profil_line';
    WebHTMLDiv5.HeightStyle := ssAuto;
    WebHTMLDiv5.WidthStyle := ssAuto;
    WebHTMLDiv5.ChildOrder := 3;
    WebHTMLDiv5.ElementPosition := epRelative;
    WebHTMLDiv5.ElementFont := efCSS;
    WebHTMLDiv5.Role := '';
    WebLabel3.SetParentComponent(WebHTMLDiv5);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 20;
    WebLabel3.Top := 11;
    WebLabel3.Width := 50;
    WebLabel3.Height := 15;
    WebLabel3.Caption := 'Vorname:';
    WebLabel3.ElementClassName := 'profil_label';
    WebLabel3.ElementFont := efCSS;
    WebLabel3.ElementPosition := epIgnore;
    WebLabel3.HeightStyle := ssAuto;
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthStyle := ssAuto;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebHTMLDiv6.SetParentComponent(WebHTMLDiv5);
    WebHTMLDiv6.Name := 'WebHTMLDiv6';
    WebHTMLDiv6.Left := 100;
    WebHTMLDiv6.Top := 3;
    WebHTMLDiv6.Width := 100;
    WebHTMLDiv6.Height := 30;
    WebHTMLDiv6.ElementClassName := 'profil_edit_container';
    WebHTMLDiv6.HeightStyle := ssAuto;
    WebHTMLDiv6.WidthStyle := ssAuto;
    WebHTMLDiv6.ChildOrder := 2;
    WebHTMLDiv6.ElementPosition := epIgnore;
    WebHTMLDiv6.ElementFont := efCSS;
    WebHTMLDiv6.Role := '';
    edVorname.SetParentComponent(WebHTMLDiv6);
    edVorname.Name := 'edVorname';
    edVorname.Left := 10;
    edVorname.Top := 2;
    edVorname.Width := 60;
    edVorname.Height := 25;
    edVorname.Margins.Left := 0;
    edVorname.Margins.Top := 0;
    edVorname.Margins.Right := 0;
    edVorname.Margins.Bottom := 0;
    edVorname.ElementClassName := 'profil_edit';
    edVorname.HeightStyle := ssAuto;
    edVorname.WidthStyle := ssAuto;
    edVorname.AutoSelect := True;
    edVorname.BorderStyle := bsSingle;
    edVorname.Color := clWhite;
    edVorname.ElementFont := efCSS;
    edVorname.ElementPosition := epRelative;
    edVorname.ShowHint := True;
    edVorname.TabStop := True;
    edVorname.TabOrder := 0;
    edVorname.Items.BeginUpdate;
    try
      edVorname.Items.Clear;
      edVorname.Items.Add('test 1');
      edVorname.Items.Add('test 2');
      edVorname.Items.Add('test 3');
    finally
      edVorname.Items.EndUpdate;
    end;
    edVorname.Text := '';
    WebHTMLDiv26.SetParentComponent(WebHTMLDiv5);
    WebHTMLDiv26.Name := 'WebHTMLDiv26';
    WebHTMLDiv26.Left := 250;
    WebHTMLDiv26.Top := 3;
    WebHTMLDiv26.Width := 79;
    WebHTMLDiv26.Height := 30;
    WebHTMLDiv26.ElementClassName := 'profil_sichtbar';
    WebHTMLDiv26.HeightStyle := ssAuto;
    WebHTMLDiv26.WidthStyle := ssAuto;
    WebHTMLDiv26.ChildOrder := 2;
    WebHTMLDiv26.ElementPosition := epIgnore;
    WebHTMLDiv26.ElementFont := efCSS;
    WebHTMLDiv26.Role := '';
    cbShowVorname.SetParentComponent(WebHTMLDiv26);
    cbShowVorname.Name := 'cbShowVorname';
    cbShowVorname.Left := 3;
    cbShowVorname.Top := 3;
    cbShowVorname.Width := 68;
    cbShowVorname.Height := 23;
    cbShowVorname.ChildOrder := 1;
    cbShowVorname.ElementClassName := 'profil_edit';
    cbShowVorname.ElementFont := efCSS;
    cbShowVorname.ElementPosition := epIgnore;
    cbShowVorname.HeightStyle := ssAuto;
    cbShowVorname.HeightPercent := 100.000000000000000000;
    cbShowVorname.WidthStyle := ssAuto;
    cbShowVorname.WidthPercent := 100.000000000000000000;
    cbShowVorname.ItemIndex := -1;
    cbShowVorname.Items.BeginUpdate;
    try
      cbShowVorname.Items.Clear;
      cbShowVorname.Items.Add('nur ich darf das sehen');
      cbShowVorname.Items.Add('Freunde d'#252'rfen das sehen');
      cbShowVorname.Items.Add('Mitglieder d'#252'rfen das sehen');
      cbShowVorname.Items.Add('jeder darf das sehen');
    finally
      cbShowVorname.Items.EndUpdate;
    end;
    WebHTMLDiv7.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv7.Name := 'WebHTMLDiv7';
    WebHTMLDiv7.Left := 10;
    WebHTMLDiv7.Top := 204;
    WebHTMLDiv7.Width := 345;
    WebHTMLDiv7.Height := 40;
    WebHTMLDiv7.ElementClassName := 'profil_line';
    WebHTMLDiv7.HeightStyle := ssAuto;
    WebHTMLDiv7.WidthStyle := ssAuto;
    WebHTMLDiv7.ChildOrder := 4;
    WebHTMLDiv7.ElementPosition := epRelative;
    WebHTMLDiv7.ElementFont := efCSS;
    WebHTMLDiv7.Role := '';
    WebLabel4.SetParentComponent(WebHTMLDiv7);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 20;
    WebLabel4.Top := 11;
    WebLabel4.Width := 29;
    WebLabel4.Height := 15;
    WebLabel4.Caption := 'Land:';
    WebLabel4.ElementClassName := 'profil_label';
    WebLabel4.ElementFont := efCSS;
    WebLabel4.ElementPosition := epIgnore;
    WebLabel4.HeightStyle := ssAuto;
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthStyle := ssAuto;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebHTMLDiv9.SetParentComponent(WebHTMLDiv7);
    WebHTMLDiv9.Name := 'WebHTMLDiv9';
    WebHTMLDiv9.Left := 100;
    WebHTMLDiv9.Top := 3;
    WebHTMLDiv9.Width := 100;
    WebHTMLDiv9.Height := 30;
    WebHTMLDiv9.ElementClassName := 'profil_edit_container';
    WebHTMLDiv9.HeightStyle := ssAuto;
    WebHTMLDiv9.WidthStyle := ssAuto;
    WebHTMLDiv9.ChildOrder := 2;
    WebHTMLDiv9.ElementPosition := epIgnore;
    WebHTMLDiv9.ElementFont := efCSS;
    WebHTMLDiv9.Role := '';
    cbLand.SetParentComponent(WebHTMLDiv9);
    cbLand.Name := 'cbLand';
    cbLand.Left := 10;
    cbLand.Top := 2;
    cbLand.Width := 60;
    cbLand.Height := 23;
    cbLand.ChildOrder := 1;
    cbLand.ElementClassName := 'profil_edit';
    cbLand.ElementFont := efCSS;
    cbLand.ElementPosition := epIgnore;
    cbLand.HeightStyle := ssAuto;
    cbLand.HeightPercent := 100.000000000000000000;
    cbLand.WidthStyle := ssAuto;
    cbLand.WidthPercent := 100.000000000000000000;
    SetEvent(cbLand, Self, 'OnChange', 'cbLandChange');
    cbLand.ItemIndex := -1;
    cbLand.Items.BeginUpdate;
    try
      cbLand.Items.Clear;
      cbLand.Items.Add('');
      cbLand.Items.Add('Herr');
      cbLand.Items.Add('Frau');
    finally
      cbLand.Items.EndUpdate;
    end;
    WebHTMLDiv36.SetParentComponent(WebHTMLDiv7);
    WebHTMLDiv36.Name := 'WebHTMLDiv36';
    WebHTMLDiv36.Left := 250;
    WebHTMLDiv36.Top := 3;
    WebHTMLDiv36.Width := 79;
    WebHTMLDiv36.Height := 30;
    WebHTMLDiv36.ElementClassName := 'profil_sichtbar';
    WebHTMLDiv36.HeightStyle := ssAuto;
    WebHTMLDiv36.WidthStyle := ssAuto;
    WebHTMLDiv36.ChildOrder := 2;
    WebHTMLDiv36.ElementPosition := epIgnore;
    WebHTMLDiv36.ElementFont := efCSS;
    WebHTMLDiv36.Role := '';
    cbShowLand.SetParentComponent(WebHTMLDiv36);
    cbShowLand.Name := 'cbShowLand';
    cbShowLand.Left := 3;
    cbShowLand.Top := 3;
    cbShowLand.Width := 68;
    cbShowLand.Height := 23;
    cbShowLand.ChildOrder := 1;
    cbShowLand.ElementClassName := 'profil_edit';
    cbShowLand.ElementFont := efCSS;
    cbShowLand.ElementPosition := epIgnore;
    cbShowLand.HeightStyle := ssAuto;
    cbShowLand.HeightPercent := 100.000000000000000000;
    cbShowLand.WidthStyle := ssAuto;
    cbShowLand.WidthPercent := 100.000000000000000000;
    cbShowLand.ItemIndex := -1;
    cbShowLand.Items.BeginUpdate;
    try
      cbShowLand.Items.Clear;
      cbShowLand.Items.Add('nur ich darf das sehen');
      cbShowLand.Items.Add('Freunde d'#252'rfen das sehen');
      cbShowLand.Items.Add('Mitglieder d'#252'rfen das sehen');
      cbShowLand.Items.Add('jeder darf das sehen');
    finally
      cbShowLand.Items.EndUpdate;
    end;
    WebHTMLDiv10.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv10.Name := 'WebHTMLDiv10';
    WebHTMLDiv10.Left := 10;
    WebHTMLDiv10.Top := 247;
    WebHTMLDiv10.Width := 345;
    WebHTMLDiv10.Height := 40;
    WebHTMLDiv10.ElementClassName := 'profil_line';
    WebHTMLDiv10.HeightStyle := ssAuto;
    WebHTMLDiv10.WidthStyle := ssAuto;
    WebHTMLDiv10.ChildOrder := 5;
    WebHTMLDiv10.ElementPosition := epRelative;
    WebHTMLDiv10.ElementFont := efCSS;
    WebHTMLDiv10.Role := '';
    WebLabel5.SetParentComponent(WebHTMLDiv10);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 20;
    WebLabel5.Top := 11;
    WebLabel5.Width := 65;
    WebLabel5.Height := 15;
    WebLabel5.Caption := 'Bundesland:';
    WebLabel5.ElementClassName := 'profil_label';
    WebLabel5.ElementFont := efCSS;
    WebLabel5.ElementPosition := epIgnore;
    WebLabel5.HeightStyle := ssAuto;
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.WidthStyle := ssAuto;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebHTMLDiv37.SetParentComponent(WebHTMLDiv10);
    WebHTMLDiv37.Name := 'WebHTMLDiv37';
    WebHTMLDiv37.Left := 100;
    WebHTMLDiv37.Top := 3;
    WebHTMLDiv37.Width := 100;
    WebHTMLDiv37.Height := 30;
    WebHTMLDiv37.ElementClassName := 'profil_edit_container';
    WebHTMLDiv37.HeightStyle := ssAuto;
    WebHTMLDiv37.WidthStyle := ssAuto;
    WebHTMLDiv37.ChildOrder := 2;
    WebHTMLDiv37.ElementPosition := epIgnore;
    WebHTMLDiv37.ElementFont := efCSS;
    WebHTMLDiv37.Role := '';
    cbBLand.SetParentComponent(WebHTMLDiv37);
    cbBLand.Name := 'cbBLand';
    cbBLand.Left := 10;
    cbBLand.Top := 2;
    cbBLand.Width := 60;
    cbBLand.Height := 23;
    cbBLand.ChildOrder := 1;
    cbBLand.ElementClassName := 'profil_edit';
    cbBLand.ElementFont := efCSS;
    cbBLand.ElementPosition := epIgnore;
    cbBLand.HeightStyle := ssAuto;
    cbBLand.HeightPercent := 100.000000000000000000;
    cbBLand.WidthStyle := ssAuto;
    cbBLand.WidthPercent := 100.000000000000000000;
    cbBLand.ItemIndex := -1;
    cbBLand.Items.BeginUpdate;
    try
      cbBLand.Items.Clear;
      cbBLand.Items.Add('');
      cbBLand.Items.Add('Herr');
      cbBLand.Items.Add('Frau');
    finally
      cbBLand.Items.EndUpdate;
    end;
    WebHTMLDiv35.SetParentComponent(WebHTMLDiv10);
    WebHTMLDiv35.Name := 'WebHTMLDiv35';
    WebHTMLDiv35.Left := 250;
    WebHTMLDiv35.Top := 3;
    WebHTMLDiv35.Width := 79;
    WebHTMLDiv35.Height := 30;
    WebHTMLDiv35.ElementClassName := 'profil_sichtbar';
    WebHTMLDiv35.HeightStyle := ssAuto;
    WebHTMLDiv35.WidthStyle := ssAuto;
    WebHTMLDiv35.ChildOrder := 2;
    WebHTMLDiv35.ElementPosition := epIgnore;
    WebHTMLDiv35.ElementFont := efCSS;
    WebHTMLDiv35.Role := '';
    cbShowBLand.SetParentComponent(WebHTMLDiv35);
    cbShowBLand.Name := 'cbShowBLand';
    cbShowBLand.Left := 3;
    cbShowBLand.Top := 3;
    cbShowBLand.Width := 68;
    cbShowBLand.Height := 23;
    cbShowBLand.ChildOrder := 1;
    cbShowBLand.ElementClassName := 'profil_edit';
    cbShowBLand.ElementFont := efCSS;
    cbShowBLand.ElementPosition := epIgnore;
    cbShowBLand.HeightStyle := ssAuto;
    cbShowBLand.HeightPercent := 100.000000000000000000;
    cbShowBLand.WidthStyle := ssAuto;
    cbShowBLand.WidthPercent := 100.000000000000000000;
    cbShowBLand.ItemIndex := -1;
    cbShowBLand.Items.BeginUpdate;
    try
      cbShowBLand.Items.Clear;
      cbShowBLand.Items.Add('nur ich darf das sehen');
      cbShowBLand.Items.Add('Freunde d'#252'rfen das sehen');
      cbShowBLand.Items.Add('Mitglieder d'#252'rfen das sehen');
      cbShowBLand.Items.Add('jeder darf das sehen');
    finally
      cbShowBLand.Items.EndUpdate;
    end;
    WebHTMLDiv38.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv38.Name := 'WebHTMLDiv38';
    WebHTMLDiv38.Left := 10;
    WebHTMLDiv38.Top := 293;
    WebHTMLDiv38.Width := 345;
    WebHTMLDiv38.Height := 40;
    WebHTMLDiv38.ElementClassName := 'profil_line';
    WebHTMLDiv38.HeightStyle := ssAuto;
    WebHTMLDiv38.WidthStyle := ssAuto;
    WebHTMLDiv38.ChildOrder := 6;
    WebHTMLDiv38.ElementPosition := epRelative;
    WebHTMLDiv38.ElementFont := efCSS;
    WebHTMLDiv38.Role := '';
    WebLabel6.SetParentComponent(WebHTMLDiv38);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 20;
    WebLabel6.Top := 11;
    WebLabel6.Width := 60;
    WebLabel6.Height := 15;
    WebLabel6.Caption := 'Stra'#223'e / Nr:';
    WebLabel6.ElementClassName := 'profil_label';
    WebLabel6.ElementFont := efCSS;
    WebLabel6.ElementPosition := epIgnore;
    WebLabel6.HeightStyle := ssAuto;
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.WidthStyle := ssAuto;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebHTMLDiv39.SetParentComponent(WebHTMLDiv38);
    WebHTMLDiv39.Name := 'WebHTMLDiv39';
    WebHTMLDiv39.Left := 100;
    WebHTMLDiv39.Top := 3;
    WebHTMLDiv39.Width := 100;
    WebHTMLDiv39.Height := 30;
    WebHTMLDiv39.ElementClassName := 'profil_edit_container';
    WebHTMLDiv39.HeightStyle := ssAuto;
    WebHTMLDiv39.WidthStyle := ssAuto;
    WebHTMLDiv39.ChildOrder := 2;
    WebHTMLDiv39.ElementPosition := epIgnore;
    WebHTMLDiv39.ElementFont := efCSS;
    WebHTMLDiv39.Role := '';
    edStrasse.SetParentComponent(WebHTMLDiv39);
    edStrasse.Name := 'edStrasse';
    edStrasse.Left := 10;
    edStrasse.Top := 2;
    edStrasse.Width := 60;
    edStrasse.Height := 25;
    edStrasse.Margins.Left := 0;
    edStrasse.Margins.Top := 0;
    edStrasse.Margins.Right := 0;
    edStrasse.Margins.Bottom := 0;
    edStrasse.ElementClassName := 'profil_edit';
    edStrasse.HeightStyle := ssAuto;
    edStrasse.WidthStyle := ssAuto;
    edStrasse.AutoSelect := True;
    edStrasse.BorderStyle := bsSingle;
    edStrasse.Color := clWhite;
    edStrasse.ElementFont := efCSS;
    edStrasse.ElementPosition := epRelative;
    edStrasse.ShowHint := True;
    edStrasse.TabStop := True;
    edStrasse.TabOrder := 0;
    edStrasse.Items.BeginUpdate;
    try
      edStrasse.Items.Clear;
      edStrasse.Items.Add('test 1');
      edStrasse.Items.Add('test 2');
      edStrasse.Items.Add('test 3');
    finally
      edStrasse.Items.EndUpdate;
    end;
    edStrasse.Text := '';
    WebHTMLDiv45.SetParentComponent(WebHTMLDiv38);
    WebHTMLDiv45.Name := 'WebHTMLDiv45';
    WebHTMLDiv45.Left := 250;
    WebHTMLDiv45.Top := 3;
    WebHTMLDiv45.Width := 79;
    WebHTMLDiv45.Height := 30;
    WebHTMLDiv45.ElementClassName := 'profil_sichtbar';
    WebHTMLDiv45.HeightStyle := ssAuto;
    WebHTMLDiv45.WidthStyle := ssAuto;
    WebHTMLDiv45.ChildOrder := 2;
    WebHTMLDiv45.ElementPosition := epIgnore;
    WebHTMLDiv45.ElementFont := efCSS;
    WebHTMLDiv45.Role := '';
    cbShowStrasse.SetParentComponent(WebHTMLDiv45);
    cbShowStrasse.Name := 'cbShowStrasse';
    cbShowStrasse.Left := 3;
    cbShowStrasse.Top := 3;
    cbShowStrasse.Width := 68;
    cbShowStrasse.Height := 23;
    cbShowStrasse.ChildOrder := 1;
    cbShowStrasse.ElementClassName := 'profil_edit';
    cbShowStrasse.ElementFont := efCSS;
    cbShowStrasse.ElementPosition := epIgnore;
    cbShowStrasse.HeightStyle := ssAuto;
    cbShowStrasse.HeightPercent := 100.000000000000000000;
    cbShowStrasse.WidthStyle := ssAuto;
    cbShowStrasse.WidthPercent := 100.000000000000000000;
    cbShowStrasse.ItemIndex := -1;
    cbShowStrasse.Items.BeginUpdate;
    try
      cbShowStrasse.Items.Clear;
      cbShowStrasse.Items.Add('nur ich darf das sehen');
      cbShowStrasse.Items.Add('Freunde d'#252'rfen das sehen');
      cbShowStrasse.Items.Add('Mitglieder d'#252'rfen das sehen');
      cbShowStrasse.Items.Add('jeder darf das sehen');
    finally
      cbShowStrasse.Items.EndUpdate;
    end;
    WebHTMLDiv40.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv40.Name := 'WebHTMLDiv40';
    WebHTMLDiv40.Left := 10;
    WebHTMLDiv40.Top := 339;
    WebHTMLDiv40.Width := 345;
    WebHTMLDiv40.Height := 40;
    WebHTMLDiv40.ElementClassName := 'profil_line';
    WebHTMLDiv40.HeightStyle := ssAuto;
    WebHTMLDiv40.WidthStyle := ssAuto;
    WebHTMLDiv40.ChildOrder := 7;
    WebHTMLDiv40.ElementPosition := epRelative;
    WebHTMLDiv40.ElementFont := efCSS;
    WebHTMLDiv40.Role := '';
    WebLabel8.SetParentComponent(WebHTMLDiv40);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 20;
    WebLabel8.Top := 11;
    WebLabel8.Width := 20;
    WebLabel8.Height := 15;
    WebLabel8.Caption := 'PLZ';
    WebLabel8.ElementClassName := 'profil_label';
    WebLabel8.ElementFont := efCSS;
    WebLabel8.ElementPosition := epIgnore;
    WebLabel8.HeightStyle := ssAuto;
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.WidthStyle := ssAuto;
    WebLabel8.WidthPercent := 100.000000000000000000;
    WebHTMLDiv41.SetParentComponent(WebHTMLDiv40);
    WebHTMLDiv41.Name := 'WebHTMLDiv41';
    WebHTMLDiv41.Left := 100;
    WebHTMLDiv41.Top := 3;
    WebHTMLDiv41.Width := 100;
    WebHTMLDiv41.Height := 30;
    WebHTMLDiv41.ElementClassName := 'profil_edit_container';
    WebHTMLDiv41.HeightStyle := ssAuto;
    WebHTMLDiv41.WidthStyle := ssAuto;
    WebHTMLDiv41.ChildOrder := 2;
    WebHTMLDiv41.ElementPosition := epIgnore;
    WebHTMLDiv41.ElementFont := efCSS;
    WebHTMLDiv41.Role := '';
    edPLZ.SetParentComponent(WebHTMLDiv41);
    edPLZ.Name := 'edPLZ';
    edPLZ.Left := 10;
    edPLZ.Top := 2;
    edPLZ.Width := 60;
    edPLZ.Height := 25;
    edPLZ.Margins.Left := 0;
    edPLZ.Margins.Top := 0;
    edPLZ.Margins.Right := 0;
    edPLZ.Margins.Bottom := 0;
    edPLZ.ElementClassName := 'profil_edit';
    edPLZ.HeightStyle := ssAuto;
    edPLZ.WidthStyle := ssAuto;
    edPLZ.AutoSelect := True;
    edPLZ.BorderStyle := bsSingle;
    edPLZ.Color := clWhite;
    edPLZ.ElementFont := efCSS;
    edPLZ.ElementPosition := epRelative;
    edPLZ.ShowHint := True;
    edPLZ.TabStop := True;
    edPLZ.TabOrder := 0;
    edPLZ.Items.BeginUpdate;
    try
      edPLZ.Items.Clear;
      edPLZ.Items.Add('test 1');
      edPLZ.Items.Add('test 2');
      edPLZ.Items.Add('test 3');
    finally
      edPLZ.Items.EndUpdate;
    end;
    edPLZ.Text := '';
    WebHTMLDiv46.SetParentComponent(WebHTMLDiv40);
    WebHTMLDiv46.Name := 'WebHTMLDiv46';
    WebHTMLDiv46.Left := 250;
    WebHTMLDiv46.Top := 3;
    WebHTMLDiv46.Width := 79;
    WebHTMLDiv46.Height := 30;
    WebHTMLDiv46.ElementClassName := 'profil_sichtbar';
    WebHTMLDiv46.HeightStyle := ssAuto;
    WebHTMLDiv46.WidthStyle := ssAuto;
    WebHTMLDiv46.ChildOrder := 2;
    WebHTMLDiv46.ElementPosition := epIgnore;
    WebHTMLDiv46.ElementFont := efCSS;
    WebHTMLDiv46.Role := '';
    cbShowPLZ.SetParentComponent(WebHTMLDiv46);
    cbShowPLZ.Name := 'cbShowPLZ';
    cbShowPLZ.Left := 3;
    cbShowPLZ.Top := 3;
    cbShowPLZ.Width := 68;
    cbShowPLZ.Height := 23;
    cbShowPLZ.ChildOrder := 1;
    cbShowPLZ.ElementClassName := 'profil_edit';
    cbShowPLZ.ElementFont := efCSS;
    cbShowPLZ.ElementPosition := epIgnore;
    cbShowPLZ.HeightStyle := ssAuto;
    cbShowPLZ.HeightPercent := 100.000000000000000000;
    cbShowPLZ.WidthStyle := ssAuto;
    cbShowPLZ.WidthPercent := 100.000000000000000000;
    cbShowPLZ.ItemIndex := -1;
    cbShowPLZ.Items.BeginUpdate;
    try
      cbShowPLZ.Items.Clear;
      cbShowPLZ.Items.Add('nur ich darf das sehen');
      cbShowPLZ.Items.Add('Freunde d'#252'rfen das sehen');
      cbShowPLZ.Items.Add('Mitglieder d'#252'rfen das sehen');
      cbShowPLZ.Items.Add('jeder darf das sehen');
    finally
      cbShowPLZ.Items.EndUpdate;
    end;
    WebHTMLDiv42.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv42.Name := 'WebHTMLDiv42';
    WebHTMLDiv42.Left := 10;
    WebHTMLDiv42.Top := 385;
    WebHTMLDiv42.Width := 345;
    WebHTMLDiv42.Height := 40;
    WebHTMLDiv42.ElementClassName := 'profil_line';
    WebHTMLDiv42.HeightStyle := ssAuto;
    WebHTMLDiv42.WidthStyle := ssAuto;
    WebHTMLDiv42.ChildOrder := 8;
    WebHTMLDiv42.ElementPosition := epRelative;
    WebHTMLDiv42.ElementFont := efCSS;
    WebHTMLDiv42.Role := '';
    WebLabel9.SetParentComponent(WebHTMLDiv42);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 20;
    WebLabel9.Top := 11;
    WebLabel9.Width := 20;
    WebLabel9.Height := 15;
    WebLabel9.Caption := 'Ort:';
    WebLabel9.ElementClassName := 'profil_label';
    WebLabel9.ElementFont := efCSS;
    WebLabel9.ElementPosition := epIgnore;
    WebLabel9.HeightStyle := ssAuto;
    WebLabel9.HeightPercent := 100.000000000000000000;
    WebLabel9.WidthStyle := ssAuto;
    WebLabel9.WidthPercent := 100.000000000000000000;
    WebHTMLDiv43.SetParentComponent(WebHTMLDiv42);
    WebHTMLDiv43.Name := 'WebHTMLDiv43';
    WebHTMLDiv43.Left := 100;
    WebHTMLDiv43.Top := 3;
    WebHTMLDiv43.Width := 100;
    WebHTMLDiv43.Height := 30;
    WebHTMLDiv43.ElementClassName := 'profil_edit_container';
    WebHTMLDiv43.HeightStyle := ssAuto;
    WebHTMLDiv43.WidthStyle := ssAuto;
    WebHTMLDiv43.ChildOrder := 2;
    WebHTMLDiv43.ElementPosition := epIgnore;
    WebHTMLDiv43.ElementFont := efCSS;
    WebHTMLDiv43.Role := '';
    edOrt.SetParentComponent(WebHTMLDiv43);
    edOrt.Name := 'edOrt';
    edOrt.Left := 10;
    edOrt.Top := 2;
    edOrt.Width := 60;
    edOrt.Height := 25;
    edOrt.Margins.Left := 0;
    edOrt.Margins.Top := 0;
    edOrt.Margins.Right := 0;
    edOrt.Margins.Bottom := 0;
    edOrt.ElementClassName := 'profil_edit';
    edOrt.HeightStyle := ssAuto;
    edOrt.WidthStyle := ssAuto;
    edOrt.AutoSelect := True;
    edOrt.BorderStyle := bsSingle;
    edOrt.Color := clWhite;
    edOrt.ElementFont := efCSS;
    edOrt.ElementPosition := epRelative;
    edOrt.ShowHint := True;
    edOrt.TabStop := True;
    edOrt.TabOrder := 0;
    edOrt.Items.BeginUpdate;
    try
      edOrt.Items.Clear;
      edOrt.Items.Add('test 1');
      edOrt.Items.Add('test 2');
      edOrt.Items.Add('test 3');
    finally
      edOrt.Items.EndUpdate;
    end;
    edOrt.Text := '';
    WebHTMLDiv63.SetParentComponent(WebHTMLDiv42);
    WebHTMLDiv63.Name := 'WebHTMLDiv63';
    WebHTMLDiv63.Left := 250;
    WebHTMLDiv63.Top := 3;
    WebHTMLDiv63.Width := 79;
    WebHTMLDiv63.Height := 30;
    WebHTMLDiv63.ElementClassName := 'profil_sichtbar';
    WebHTMLDiv63.HeightStyle := ssAuto;
    WebHTMLDiv63.WidthStyle := ssAuto;
    WebHTMLDiv63.ChildOrder := 2;
    WebHTMLDiv63.ElementPosition := epIgnore;
    WebHTMLDiv63.ElementFont := efCSS;
    WebHTMLDiv63.Role := '';
    cbShowOrt.SetParentComponent(WebHTMLDiv63);
    cbShowOrt.Name := 'cbShowOrt';
    cbShowOrt.Left := 3;
    cbShowOrt.Top := 3;
    cbShowOrt.Width := 68;
    cbShowOrt.Height := 23;
    cbShowOrt.ChildOrder := 1;
    cbShowOrt.ElementClassName := 'profil_edit';
    cbShowOrt.ElementFont := efCSS;
    cbShowOrt.ElementPosition := epIgnore;
    cbShowOrt.HeightStyle := ssAuto;
    cbShowOrt.HeightPercent := 100.000000000000000000;
    cbShowOrt.WidthStyle := ssAuto;
    cbShowOrt.WidthPercent := 100.000000000000000000;
    cbShowOrt.ItemIndex := -1;
    cbShowOrt.Items.BeginUpdate;
    try
      cbShowOrt.Items.Clear;
      cbShowOrt.Items.Add('nur ich darf das sehen');
      cbShowOrt.Items.Add('Freunde d'#252'rfen das sehen');
      cbShowOrt.Items.Add('Mitglieder d'#252'rfen das sehen');
      cbShowOrt.Items.Add('jeder darf das sehen');
    finally
      cbShowOrt.Items.EndUpdate;
    end;
    WebHTMLDiv54.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv54.Name := 'WebHTMLDiv54';
    WebHTMLDiv54.Left := 10;
    WebHTMLDiv54.Top := 431;
    WebHTMLDiv54.Width := 345;
    WebHTMLDiv54.Height := 40;
    WebHTMLDiv54.ElementClassName := 'profil_line';
    WebHTMLDiv54.HeightStyle := ssAuto;
    WebHTMLDiv54.WidthStyle := ssAuto;
    WebHTMLDiv54.ChildOrder := 9;
    WebHTMLDiv54.ElementPosition := epRelative;
    WebHTMLDiv54.ElementFont := efCSS;
    WebHTMLDiv54.Role := '';
    WebLabel24.SetParentComponent(WebHTMLDiv54);
    WebLabel24.Name := 'WebLabel24';
    WebLabel24.Left := 20;
    WebLabel24.Top := 11;
    WebLabel24.Width := 79;
    WebLabel24.Height := 15;
    WebLabel24.Caption := 'Geburtsdatum:';
    WebLabel24.ElementClassName := 'profil_label';
    WebLabel24.ElementFont := efCSS;
    WebLabel24.ElementPosition := epIgnore;
    WebLabel24.HeightStyle := ssAuto;
    WebLabel24.HeightPercent := 100.000000000000000000;
    WebLabel24.WidthStyle := ssAuto;
    WebLabel24.WidthPercent := 100.000000000000000000;
    WebHTMLDiv55.SetParentComponent(WebHTMLDiv54);
    WebHTMLDiv55.Name := 'WebHTMLDiv55';
    WebHTMLDiv55.Left := 100;
    WebHTMLDiv55.Top := 3;
    WebHTMLDiv55.Width := 100;
    WebHTMLDiv55.Height := 30;
    WebHTMLDiv55.ElementClassName := 'profil_edit_container';
    WebHTMLDiv55.HeightStyle := ssAuto;
    WebHTMLDiv55.WidthStyle := ssAuto;
    WebHTMLDiv55.ChildOrder := 2;
    WebHTMLDiv55.ElementPosition := epIgnore;
    WebHTMLDiv55.ElementFont := efCSS;
    WebHTMLDiv55.Role := '';
    edGebDatum.SetParentComponent(WebHTMLDiv55);
    edGebDatum.Name := 'edGebDatum';
    edGebDatum.Left := 10;
    edGebDatum.Top := 2;
    edGebDatum.Width := 60;
    edGebDatum.Height := 22;
    edGebDatum.ElementClassName := 'profil_edit';
    edGebDatum.HeightStyle := ssAuto;
    edGebDatum.WidthStyle := ssAuto;
    edGebDatum.BorderStyle := bsSingle;
    edGebDatum.ChildOrder := 1;
    edGebDatum.Color := clWhite;
    edGebDatum.Date := 45280.619621018520000000;
    edGebDatum.ElementFont := efCSS;
    edGebDatum.ElementPosition := epRelative;
    edGebDatum.Font.Charset := DEFAULT_CHARSET;
    edGebDatum.Font.Color := clWindowText;
    edGebDatum.Font.Height := -12;
    edGebDatum.Font.Name := 'Segoe UI';
    edGebDatum.Font.Style := [];
    edGebDatum.ParentFont := False;
    edGebDatum.Role := '';
    edGebDatum.ShowSeconds := False;
    edGebDatum.Text := '';
    WebHTMLDiv64.SetParentComponent(WebHTMLDiv54);
    WebHTMLDiv64.Name := 'WebHTMLDiv64';
    WebHTMLDiv64.Left := 250;
    WebHTMLDiv64.Top := 3;
    WebHTMLDiv64.Width := 79;
    WebHTMLDiv64.Height := 30;
    WebHTMLDiv64.ElementClassName := 'profil_sichtbar';
    WebHTMLDiv64.HeightStyle := ssAuto;
    WebHTMLDiv64.WidthStyle := ssAuto;
    WebHTMLDiv64.ChildOrder := 2;
    WebHTMLDiv64.ElementPosition := epIgnore;
    WebHTMLDiv64.ElementFont := efCSS;
    WebHTMLDiv64.Role := '';
    cbShowGebDatum.SetParentComponent(WebHTMLDiv64);
    cbShowGebDatum.Name := 'cbShowGebDatum';
    cbShowGebDatum.Left := 3;
    cbShowGebDatum.Top := 3;
    cbShowGebDatum.Width := 68;
    cbShowGebDatum.Height := 23;
    cbShowGebDatum.ChildOrder := 1;
    cbShowGebDatum.ElementClassName := 'profil_edit';
    cbShowGebDatum.ElementFont := efCSS;
    cbShowGebDatum.ElementPosition := epIgnore;
    cbShowGebDatum.HeightStyle := ssAuto;
    cbShowGebDatum.HeightPercent := 100.000000000000000000;
    cbShowGebDatum.WidthStyle := ssAuto;
    cbShowGebDatum.WidthPercent := 100.000000000000000000;
    cbShowGebDatum.ItemIndex := -1;
    cbShowGebDatum.Items.BeginUpdate;
    try
      cbShowGebDatum.Items.Clear;
      cbShowGebDatum.Items.Add('nur ich darf das sehen');
      cbShowGebDatum.Items.Add('Freunde d'#252'rfen das sehen');
      cbShowGebDatum.Items.Add('Mitglieder d'#252'rfen das sehen');
      cbShowGebDatum.Items.Add('jeder darf das sehen');
    finally
      cbShowGebDatum.Items.EndUpdate;
    end;
    WebHTMLDiv21.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv21.Name := 'WebHTMLDiv21';
    WebHTMLDiv21.Left := 9;
    WebHTMLDiv21.Top := 488;
    WebHTMLDiv21.Width := 345;
    WebHTMLDiv21.Height := 40;
    WebHTMLDiv21.ElementClassName := 'profil_header';
    WebHTMLDiv21.HeightStyle := ssAuto;
    WebHTMLDiv21.WidthStyle := ssAuto;
    WebHTMLDiv21.ChildOrder := 10;
    WebHTMLDiv21.ElementPosition := epIgnore;
    WebHTMLDiv21.ElementFont := efCSS;
    WebHTMLDiv21.Role := '';
    WebLabel14.SetParentComponent(WebHTMLDiv21);
    WebLabel14.Name := 'WebLabel14';
    WebLabel14.Left := 16;
    WebLabel14.Top := 16;
    WebLabel14.Width := 86;
    WebLabel14.Height := 15;
    WebLabel14.Caption := 'Kommunikation';
    WebLabel14.ElementFont := efCSS;
    WebLabel14.ElementPosition := epRelative;
    WebLabel14.HeightStyle := ssAuto;
    WebLabel14.HeightPercent := 100.000000000000000000;
    WebLabel14.WidthStyle := ssAuto;
    WebLabel14.WidthPercent := 100.000000000000000000;
    WebHTMLDiv22.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv22.Name := 'WebHTMLDiv22';
    WebHTMLDiv22.Left := 9;
    WebHTMLDiv22.Top := 535;
    WebHTMLDiv22.Width := 345;
    WebHTMLDiv22.Height := 40;
    WebHTMLDiv22.ElementClassName := 'profil_line';
    WebHTMLDiv22.HeightStyle := ssAuto;
    WebHTMLDiv22.WidthStyle := ssAuto;
    WebHTMLDiv22.ChildOrder := 11;
    WebHTMLDiv22.ElementPosition := epIgnore;
    WebHTMLDiv22.ElementFont := efCSS;
    WebHTMLDiv22.Role := '';
    WebLabel15.SetParentComponent(WebHTMLDiv22);
    WebLabel15.Name := 'WebLabel15';
    WebLabel15.Left := 20;
    WebLabel15.Top := 11;
    WebLabel15.Width := 32;
    WebLabel15.Height := 15;
    WebLabel15.Caption := 'EMail:';
    WebLabel15.ElementClassName := 'profil_label';
    WebLabel15.ElementFont := efCSS;
    WebLabel15.ElementPosition := epIgnore;
    WebLabel15.HeightStyle := ssAuto;
    WebLabel15.HeightPercent := 100.000000000000000000;
    WebLabel15.WidthStyle := ssAuto;
    WebLabel15.WidthPercent := 100.000000000000000000;
    WebHTMLDiv50.SetParentComponent(WebHTMLDiv22);
    WebHTMLDiv50.Name := 'WebHTMLDiv50';
    WebHTMLDiv50.Left := 100;
    WebHTMLDiv50.Top := 3;
    WebHTMLDiv50.Width := 100;
    WebHTMLDiv50.Height := 30;
    WebHTMLDiv50.ElementClassName := 'profil_edit_container';
    WebHTMLDiv50.HeightStyle := ssAuto;
    WebHTMLDiv50.WidthStyle := ssAuto;
    WebHTMLDiv50.ChildOrder := 2;
    WebHTMLDiv50.ElementPosition := epIgnore;
    WebHTMLDiv50.ElementFont := efCSS;
    WebHTMLDiv50.Role := '';
    edEMAil.SetParentComponent(WebHTMLDiv50);
    edEMAil.Name := 'edEMAil';
    edEMAil.Left := 10;
    edEMAil.Top := 2;
    edEMAil.Width := 60;
    edEMAil.Height := 25;
    edEMAil.Margins.Left := 0;
    edEMAil.Margins.Top := 0;
    edEMAil.Margins.Right := 0;
    edEMAil.Margins.Bottom := 0;
    edEMAil.ElementClassName := 'profil_edit';
    edEMAil.HeightStyle := ssAuto;
    edEMAil.WidthStyle := ssAuto;
    edEMAil.AutoSelect := True;
    edEMAil.BorderStyle := bsSingle;
    edEMAil.Color := clWhite;
    edEMAil.ElementFont := efCSS;
    edEMAil.ElementPosition := epRelative;
    edEMAil.ShowHint := True;
    edEMAil.TabStop := True;
    edEMAil.TabOrder := 0;
    edEMAil.Items.BeginUpdate;
    try
      edEMAil.Items.Clear;
      edEMAil.Items.Add('test 1');
      edEMAil.Items.Add('test 2');
      edEMAil.Items.Add('test 3');
    finally
      edEMAil.Items.EndUpdate;
    end;
    edEMAil.Text := '';
    WebHTMLDiv71.SetParentComponent(WebHTMLDiv22);
    WebHTMLDiv71.Name := 'WebHTMLDiv71';
    WebHTMLDiv71.Left := 250;
    WebHTMLDiv71.Top := 3;
    WebHTMLDiv71.Width := 79;
    WebHTMLDiv71.Height := 30;
    WebHTMLDiv71.ElementClassName := 'profil_sichtbar';
    WebHTMLDiv71.HeightStyle := ssAuto;
    WebHTMLDiv71.WidthStyle := ssAuto;
    WebHTMLDiv71.ChildOrder := 2;
    WebHTMLDiv71.ElementPosition := epIgnore;
    WebHTMLDiv71.ElementFont := efCSS;
    WebHTMLDiv71.Role := '';
    cbShowEMail.SetParentComponent(WebHTMLDiv71);
    cbShowEMail.Name := 'cbShowEMail';
    cbShowEMail.Left := 3;
    cbShowEMail.Top := 3;
    cbShowEMail.Width := 68;
    cbShowEMail.Height := 23;
    cbShowEMail.ChildOrder := 1;
    cbShowEMail.ElementClassName := 'profil_edit';
    cbShowEMail.ElementFont := efCSS;
    cbShowEMail.ElementPosition := epIgnore;
    cbShowEMail.HeightStyle := ssAuto;
    cbShowEMail.HeightPercent := 100.000000000000000000;
    cbShowEMail.WidthStyle := ssAuto;
    cbShowEMail.WidthPercent := 100.000000000000000000;
    cbShowEMail.ItemIndex := -1;
    cbShowEMail.Items.BeginUpdate;
    try
      cbShowEMail.Items.Clear;
      cbShowEMail.Items.Add('nur ich darf das sehen');
      cbShowEMail.Items.Add('Freunde d'#252'rfen das sehen');
      cbShowEMail.Items.Add('Mitglieder d'#252'rfen das sehen');
      cbShowEMail.Items.Add('jeder darf das sehen');
    finally
      cbShowEMail.Items.EndUpdate;
    end;
    WebHTMLDiv82.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv82.Name := 'WebHTMLDiv82';
    WebHTMLDiv82.Left := 3;
    WebHTMLDiv82.Top := 573;
    WebHTMLDiv82.Width := 345;
    WebHTMLDiv82.Height := 36;
    WebHTMLDiv82.ElementClassName := 'profil_line';
    WebHTMLDiv82.HeightStyle := ssAuto;
    WebHTMLDiv82.WidthStyle := ssAuto;
    WebHTMLDiv82.ChildOrder := 12;
    WebHTMLDiv82.ElementPosition := epIgnore;
    WebHTMLDiv82.ElementFont := efCSS;
    WebHTMLDiv82.Role := '';
    WebLabel30.SetParentComponent(WebHTMLDiv82);
    WebLabel30.Name := 'WebLabel30';
    WebLabel30.Left := 20;
    WebLabel30.Top := 11;
    WebLabel30.Width := 50;
    WebLabel30.Height := 15;
    WebLabel30.Caption := 'Passwort:';
    WebLabel30.ElementClassName := 'profil_label';
    WebLabel30.ElementFont := efCSS;
    WebLabel30.ElementPosition := epIgnore;
    WebLabel30.HeightStyle := ssAuto;
    WebLabel30.HeightPercent := 100.000000000000000000;
    WebLabel30.WidthStyle := ssAuto;
    WebLabel30.WidthPercent := 100.000000000000000000;
    WebHTMLDiv83.SetParentComponent(WebHTMLDiv82);
    WebHTMLDiv83.Name := 'WebHTMLDiv83';
    WebHTMLDiv83.Left := 100;
    WebHTMLDiv83.Top := 3;
    WebHTMLDiv83.Width := 221;
    WebHTMLDiv83.Height := 30;
    WebHTMLDiv83.ElementClassName := 'profil_edit_container';
    WebHTMLDiv83.HeightStyle := ssAuto;
    WebHTMLDiv83.WidthStyle := ssAuto;
    WebHTMLDiv83.ChildOrder := 2;
    WebHTMLDiv83.ElementPosition := epIgnore;
    WebHTMLDiv83.ElementFont := efCSS;
    WebHTMLDiv83.Role := '';
    edPasswort1.SetParentComponent(WebHTMLDiv83);
    edPasswort1.Name := 'edPasswort1';
    edPasswort1.Left := 10;
    edPasswort1.Top := 5;
    edPasswort1.Width := 121;
    edPasswort1.Height := 22;
    edPasswort1.ElementClassName := 'profil_edit';
    edPasswort1.ElementFont := efCSS;
    edPasswort1.ElementPosition := epIgnore;
    edPasswort1.HeightStyle := ssAuto;
    edPasswort1.HeightPercent := 100.000000000000000000;
    edPasswort1.MaxLength := 30;
    edPasswort1.PasswordChar := '*';
    edPasswort1.Text := 'edPasswort1';
    edPasswort1.WidthStyle := ssAuto;
    edPasswort1.WidthPercent := 100.000000000000000000;
    WebHTMLDiv79.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv79.Name := 'WebHTMLDiv79';
    WebHTMLDiv79.Left := 3;
    WebHTMLDiv79.Top := 615;
    WebHTMLDiv79.Width := 345;
    WebHTMLDiv79.Height := 36;
    WebHTMLDiv79.ElementClassName := 'profil_line';
    WebHTMLDiv79.HeightStyle := ssAuto;
    WebHTMLDiv79.WidthStyle := ssAuto;
    WebHTMLDiv79.ChildOrder := 13;
    WebHTMLDiv79.ElementPosition := epIgnore;
    WebHTMLDiv79.ElementFont := efCSS;
    WebHTMLDiv79.Role := '';
    WebLabel29.SetParentComponent(WebHTMLDiv79);
    WebLabel29.Name := 'WebLabel29';
    WebLabel29.Left := 20;
    WebLabel29.Top := 11;
    WebLabel29.Width := 68;
    WebLabel29.Height := 15;
    WebLabel29.Caption := 'wiederholen:';
    WebLabel29.ElementClassName := 'profil_label';
    WebLabel29.ElementFont := efCSS;
    WebLabel29.ElementPosition := epIgnore;
    WebLabel29.HeightStyle := ssAuto;
    WebLabel29.HeightPercent := 100.000000000000000000;
    WebLabel29.WidthStyle := ssAuto;
    WebLabel29.WidthPercent := 100.000000000000000000;
    WebHTMLDiv80.SetParentComponent(WebHTMLDiv79);
    WebHTMLDiv80.Name := 'WebHTMLDiv80';
    WebHTMLDiv80.Left := 94;
    WebHTMLDiv80.Top := 3;
    WebHTMLDiv80.Width := 221;
    WebHTMLDiv80.Height := 30;
    WebHTMLDiv80.ElementClassName := 'profil_edit_container';
    WebHTMLDiv80.HeightStyle := ssAuto;
    WebHTMLDiv80.WidthStyle := ssAuto;
    WebHTMLDiv80.ChildOrder := 2;
    WebHTMLDiv80.ElementPosition := epIgnore;
    WebHTMLDiv80.ElementFont := efCSS;
    WebHTMLDiv80.Role := '';
    edPasswort2.SetParentComponent(WebHTMLDiv80);
    edPasswort2.Name := 'edPasswort2';
    edPasswort2.Left := 18;
    edPasswort2.Top := 8;
    edPasswort2.Width := 121;
    edPasswort2.Height := 22;
    edPasswort2.ElementClassName := 'profil_edit';
    edPasswort2.ElementFont := efCSS;
    edPasswort2.ElementPosition := epIgnore;
    edPasswort2.HeightStyle := ssAuto;
    edPasswort2.HeightPercent := 100.000000000000000000;
    edPasswort2.MaxLength := 30;
    edPasswort2.PasswordChar := '*';
    edPasswort2.Text := 'WebEdit1';
    edPasswort2.WidthStyle := ssAuto;
    edPasswort2.WidthPercent := 100.000000000000000000;
    WebHTMLDiv23.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv23.Name := 'WebHTMLDiv23';
    WebHTMLDiv23.Left := 3;
    WebHTMLDiv23.Top := 657;
    WebHTMLDiv23.Width := 345;
    WebHTMLDiv23.Height := 40;
    WebHTMLDiv23.ElementClassName := 'profil_line';
    WebHTMLDiv23.HeightStyle := ssAuto;
    WebHTMLDiv23.WidthStyle := ssAuto;
    WebHTMLDiv23.ChildOrder := 14;
    WebHTMLDiv23.ElementPosition := epIgnore;
    WebHTMLDiv23.ElementFont := efCSS;
    WebHTMLDiv23.Role := '';
    WebLabel16.SetParentComponent(WebHTMLDiv23);
    WebLabel16.Name := 'WebLabel16';
    WebLabel16.Left := 20;
    WebLabel16.Top := 11;
    WebLabel16.Width := 59;
    WebLabel16.Height := 15;
    WebLabel16.Caption := 'Telefon-Nr.';
    WebLabel16.ElementClassName := 'profil_label';
    WebLabel16.ElementFont := efCSS;
    WebLabel16.ElementPosition := epIgnore;
    WebLabel16.HeightStyle := ssAuto;
    WebLabel16.HeightPercent := 100.000000000000000000;
    WebLabel16.WidthStyle := ssAuto;
    WebLabel16.WidthPercent := 100.000000000000000000;
    WebHTMLDiv51.SetParentComponent(WebHTMLDiv23);
    WebHTMLDiv51.Name := 'WebHTMLDiv51';
    WebHTMLDiv51.Left := 100;
    WebHTMLDiv51.Top := 3;
    WebHTMLDiv51.Width := 100;
    WebHTMLDiv51.Height := 30;
    WebHTMLDiv51.ElementClassName := 'profil_edit_container';
    WebHTMLDiv51.HeightStyle := ssAuto;
    WebHTMLDiv51.WidthStyle := ssAuto;
    WebHTMLDiv51.ChildOrder := 2;
    WebHTMLDiv51.ElementPosition := epIgnore;
    WebHTMLDiv51.ElementFont := efCSS;
    WebHTMLDiv51.Role := '';
    edTelefon.SetParentComponent(WebHTMLDiv51);
    edTelefon.Name := 'edTelefon';
    edTelefon.Left := 10;
    edTelefon.Top := 2;
    edTelefon.Width := 60;
    edTelefon.Height := 25;
    edTelefon.Margins.Left := 0;
    edTelefon.Margins.Top := 0;
    edTelefon.Margins.Right := 0;
    edTelefon.Margins.Bottom := 0;
    edTelefon.ElementClassName := 'profil_edit';
    edTelefon.HeightStyle := ssAuto;
    edTelefon.WidthStyle := ssAuto;
    edTelefon.AutoSelect := True;
    edTelefon.BorderStyle := bsSingle;
    edTelefon.Color := clWhite;
    edTelefon.ElementFont := efCSS;
    edTelefon.ElementPosition := epRelative;
    edTelefon.ShowHint := True;
    edTelefon.TabStop := True;
    edTelefon.TabOrder := 0;
    edTelefon.Items.BeginUpdate;
    try
      edTelefon.Items.Clear;
      edTelefon.Items.Add('test 1');
      edTelefon.Items.Add('test 2');
      edTelefon.Items.Add('test 3');
    finally
      edTelefon.Items.EndUpdate;
    end;
    edTelefon.Text := '';
    WebHTMLDiv72.SetParentComponent(WebHTMLDiv23);
    WebHTMLDiv72.Name := 'WebHTMLDiv72';
    WebHTMLDiv72.Left := 250;
    WebHTMLDiv72.Top := 3;
    WebHTMLDiv72.Width := 79;
    WebHTMLDiv72.Height := 30;
    WebHTMLDiv72.ElementClassName := 'profil_sichtbar';
    WebHTMLDiv72.HeightStyle := ssAuto;
    WebHTMLDiv72.WidthStyle := ssAuto;
    WebHTMLDiv72.ChildOrder := 2;
    WebHTMLDiv72.ElementPosition := epIgnore;
    WebHTMLDiv72.ElementFont := efCSS;
    WebHTMLDiv72.Role := '';
    cbShowTelefon.SetParentComponent(WebHTMLDiv72);
    cbShowTelefon.Name := 'cbShowTelefon';
    cbShowTelefon.Left := 3;
    cbShowTelefon.Top := 3;
    cbShowTelefon.Width := 68;
    cbShowTelefon.Height := 23;
    cbShowTelefon.ChildOrder := 1;
    cbShowTelefon.ElementClassName := 'profil_edit';
    cbShowTelefon.ElementFont := efCSS;
    cbShowTelefon.ElementPosition := epIgnore;
    cbShowTelefon.HeightStyle := ssAuto;
    cbShowTelefon.HeightPercent := 100.000000000000000000;
    cbShowTelefon.WidthStyle := ssAuto;
    cbShowTelefon.WidthPercent := 100.000000000000000000;
    cbShowTelefon.ItemIndex := -1;
    cbShowTelefon.Items.BeginUpdate;
    try
      cbShowTelefon.Items.Clear;
      cbShowTelefon.Items.Add('nur ich darf das sehen');
      cbShowTelefon.Items.Add('Freunde d'#252'rfen das sehen');
      cbShowTelefon.Items.Add('Mitglieder d'#252'rfen das sehen');
      cbShowTelefon.Items.Add('jeder darf das sehen');
    finally
      cbShowTelefon.Items.EndUpdate;
    end;
    WebHTMLDiv24.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv24.Name := 'WebHTMLDiv24';
    WebHTMLDiv24.Left := 3;
    WebHTMLDiv24.Top := 703;
    WebHTMLDiv24.Width := 345;
    WebHTMLDiv24.Height := 40;
    WebHTMLDiv24.ElementClassName := 'profil_line';
    WebHTMLDiv24.HeightStyle := ssAuto;
    WebHTMLDiv24.WidthStyle := ssAuto;
    WebHTMLDiv24.ChildOrder := 15;
    WebHTMLDiv24.ElementPosition := epIgnore;
    WebHTMLDiv24.ElementFont := efCSS;
    WebHTMLDiv24.Role := '';
    WebLabel17.SetParentComponent(WebHTMLDiv24);
    WebLabel17.Name := 'WebLabel17';
    WebLabel17.Left := 20;
    WebLabel17.Top := 11;
    WebLabel17.Width := 51;
    WebLabel17.Height := 15;
    WebLabel17.Caption := 'Webseite:';
    WebLabel17.ElementClassName := 'profil_label';
    WebLabel17.ElementFont := efCSS;
    WebLabel17.ElementPosition := epIgnore;
    WebLabel17.HeightStyle := ssAuto;
    WebLabel17.HeightPercent := 100.000000000000000000;
    WebLabel17.WidthStyle := ssAuto;
    WebLabel17.WidthPercent := 100.000000000000000000;
    WebHTMLDiv52.SetParentComponent(WebHTMLDiv24);
    WebHTMLDiv52.Name := 'WebHTMLDiv52';
    WebHTMLDiv52.Left := 100;
    WebHTMLDiv52.Top := 3;
    WebHTMLDiv52.Width := 100;
    WebHTMLDiv52.Height := 30;
    WebHTMLDiv52.ElementClassName := 'profil_edit_container';
    WebHTMLDiv52.HeightStyle := ssAuto;
    WebHTMLDiv52.WidthStyle := ssAuto;
    WebHTMLDiv52.ChildOrder := 2;
    WebHTMLDiv52.ElementPosition := epIgnore;
    WebHTMLDiv52.ElementFont := efCSS;
    WebHTMLDiv52.Role := '';
    edWebSeite.SetParentComponent(WebHTMLDiv52);
    edWebSeite.Name := 'edWebSeite';
    edWebSeite.Left := 10;
    edWebSeite.Top := 2;
    edWebSeite.Width := 60;
    edWebSeite.Height := 25;
    edWebSeite.Margins.Left := 0;
    edWebSeite.Margins.Top := 0;
    edWebSeite.Margins.Right := 0;
    edWebSeite.Margins.Bottom := 0;
    edWebSeite.ElementClassName := 'profil_edit';
    edWebSeite.HeightStyle := ssAuto;
    edWebSeite.WidthStyle := ssAuto;
    edWebSeite.AutoSelect := True;
    edWebSeite.BorderStyle := bsSingle;
    edWebSeite.Color := clWhite;
    edWebSeite.ElementFont := efCSS;
    edWebSeite.ElementPosition := epRelative;
    edWebSeite.ShowHint := True;
    edWebSeite.TabStop := True;
    edWebSeite.TabOrder := 0;
    edWebSeite.Items.BeginUpdate;
    try
      edWebSeite.Items.Clear;
      edWebSeite.Items.Add('test 1');
      edWebSeite.Items.Add('test 2');
      edWebSeite.Items.Add('test 3');
    finally
      edWebSeite.Items.EndUpdate;
    end;
    edWebSeite.Text := '';
    WebHTMLDiv73.SetParentComponent(WebHTMLDiv24);
    WebHTMLDiv73.Name := 'WebHTMLDiv73';
    WebHTMLDiv73.Left := 250;
    WebHTMLDiv73.Top := 3;
    WebHTMLDiv73.Width := 79;
    WebHTMLDiv73.Height := 30;
    WebHTMLDiv73.ElementClassName := 'profil_sichtbar';
    WebHTMLDiv73.HeightStyle := ssAuto;
    WebHTMLDiv73.WidthStyle := ssAuto;
    WebHTMLDiv73.ChildOrder := 2;
    WebHTMLDiv73.ElementPosition := epIgnore;
    WebHTMLDiv73.ElementFont := efCSS;
    WebHTMLDiv73.Role := '';
    cbShowWebseite.SetParentComponent(WebHTMLDiv73);
    cbShowWebseite.Name := 'cbShowWebseite';
    cbShowWebseite.Left := 3;
    cbShowWebseite.Top := 3;
    cbShowWebseite.Width := 68;
    cbShowWebseite.Height := 23;
    cbShowWebseite.ChildOrder := 1;
    cbShowWebseite.ElementClassName := 'profil_edit';
    cbShowWebseite.ElementFont := efCSS;
    cbShowWebseite.ElementPosition := epIgnore;
    cbShowWebseite.HeightStyle := ssAuto;
    cbShowWebseite.HeightPercent := 100.000000000000000000;
    cbShowWebseite.WidthStyle := ssAuto;
    cbShowWebseite.WidthPercent := 100.000000000000000000;
    cbShowWebseite.ItemIndex := -1;
    cbShowWebseite.Items.BeginUpdate;
    try
      cbShowWebseite.Items.Clear;
      cbShowWebseite.Items.Add('nur ich darf das sehen');
      cbShowWebseite.Items.Add('Freunde d'#252'rfen das sehen');
      cbShowWebseite.Items.Add('Mitglieder d'#252'rfen das sehen');
      cbShowWebseite.Items.Add('jeder darf das sehen');
    finally
      cbShowWebseite.Items.EndUpdate;
    end;
    WebHTMLDiv81.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv81.Name := 'WebHTMLDiv81';
    WebHTMLDiv81.Left := 3;
    WebHTMLDiv81.Top := 749;
    WebHTMLDiv81.Width := 345;
    WebHTMLDiv81.Height := 40;
    WebHTMLDiv81.ElementClassName := 'profil_line';
    WebHTMLDiv81.HeightStyle := ssAuto;
    WebHTMLDiv81.WidthStyle := ssAuto;
    WebHTMLDiv81.ChildOrder := 16;
    WebHTMLDiv81.ElementPosition := epIgnore;
    WebHTMLDiv81.ElementFont := efCSS;
    WebHTMLDiv81.Role := '';
    WebLabel31.SetParentComponent(WebHTMLDiv81);
    WebLabel31.Name := 'WebLabel31';
    WebLabel31.Left := 20;
    WebLabel31.Top := 11;
    WebLabel31.Width := 124;
    WebLabel31.Height := 15;
    WebLabel31.Caption := 'Freundschaftsanfragen ';
    WebLabel31.ElementClassName := 'profil_label';
    WebLabel31.ElementFont := efCSS;
    WebLabel31.ElementPosition := epIgnore;
    WebLabel31.HeightStyle := ssAuto;
    WebLabel31.HeightPercent := 100.000000000000000000;
    WebLabel31.WidthStyle := ssAuto;
    WebLabel31.WidthPercent := 100.000000000000000000;
    WebHTMLDiv85.SetParentComponent(WebHTMLDiv81);
    WebHTMLDiv85.Name := 'WebHTMLDiv85';
    WebHTMLDiv85.Left := 250;
    WebHTMLDiv85.Top := 3;
    WebHTMLDiv85.Width := 79;
    WebHTMLDiv85.Height := 30;
    WebHTMLDiv85.ElementClassName := 'profil_sichtbar2';
    WebHTMLDiv85.HeightStyle := ssAuto;
    WebHTMLDiv85.WidthStyle := ssAuto;
    WebHTMLDiv85.ChildOrder := 2;
    WebHTMLDiv85.ElementPosition := epIgnore;
    WebHTMLDiv85.ElementFont := efCSS;
    WebHTMLDiv85.Role := '';
    cbFreundschaft.SetParentComponent(WebHTMLDiv85);
    cbFreundschaft.Name := 'cbFreundschaft';
    cbFreundschaft.Left := 3;
    cbFreundschaft.Top := 3;
    cbFreundschaft.Width := 68;
    cbFreundschaft.Height := 23;
    cbFreundschaft.ChildOrder := 1;
    cbFreundschaft.ElementClassName := 'profil_edit';
    cbFreundschaft.ElementFont := efCSS;
    cbFreundschaft.ElementPosition := epIgnore;
    cbFreundschaft.HeightStyle := ssAuto;
    cbFreundschaft.HeightPercent := 100.000000000000000000;
    cbFreundschaft.WidthStyle := ssAuto;
    cbFreundschaft.WidthPercent := 100.000000000000000000;
    cbFreundschaft.ItemIndex := -1;
    cbFreundschaft.Items.BeginUpdate;
    try
      cbFreundschaft.Items.Clear;
      cbFreundschaft.Items.Add('ich muss best'#228'tigen=2');
      cbFreundschaft.Items.Add('ohne Best'#228'tigung, direkt annehmen=1');
    finally
      cbFreundschaft.Items.EndUpdate;
    end;
    WebHTMLDiv95.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv95.Name := 'WebHTMLDiv95';
    WebHTMLDiv95.Left := 3;
    WebHTMLDiv95.Top := 795;
    WebHTMLDiv95.Width := 345;
    WebHTMLDiv95.Height := 40;
    WebHTMLDiv95.ElementClassName := 'profil_line';
    WebHTMLDiv95.HeightStyle := ssAuto;
    WebHTMLDiv95.WidthStyle := ssAuto;
    WebHTMLDiv95.ChildOrder := 17;
    WebHTMLDiv95.ElementPosition := epRelative;
    WebHTMLDiv95.ElementFont := efCSS;
    WebHTMLDiv95.Role := '';
    WebHTMLDiv95.Visible := False;
    WebCheckBox1.SetParentComponent(WebHTMLDiv95);
    WebCheckBox1.Name := 'WebCheckBox1';
    WebCheckBox1.Left := 18;
    WebCheckBox1.Top := 15;
    WebCheckBox1.Width := 331;
    WebCheckBox1.Height := 22;
    WebCheckBox1.Caption := 'Andere k'#246'nnen mich sehen und ich kann andere sehen';
    WebCheckBox1.ChildOrder := 1;
    WebCheckBox1.ElementLabelClassName := 'profil_label';
    WebCheckBox1.ElementFont := efCSS;
    WebCheckBox1.ElementPosition := epRelative;
    WebCheckBox1.HeightStyle := ssAuto;
    WebCheckBox1.HeightPercent := 100.000000000000000000;
    WebCheckBox1.WidthStyle := ssAuto;
    WebCheckBox1.WidthPercent := 100.000000000000000000;
    WebHTMLDiv16.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv16.Name := 'WebHTMLDiv16';
    WebHTMLDiv16.Left := 3;
    WebHTMLDiv16.Top := 841;
    WebHTMLDiv16.Width := 345;
    WebHTMLDiv16.Height := 40;
    WebHTMLDiv16.ElementClassName := 'profil_line';
    WebHTMLDiv16.HeightStyle := ssAuto;
    WebHTMLDiv16.WidthStyle := ssAuto;
    WebHTMLDiv16.ChildOrder := 18;
    WebHTMLDiv16.ElementPosition := epIgnore;
    WebHTMLDiv16.ElementFont := efCSS;
    WebHTMLDiv16.Role := '';
    WebLabel34.SetParentComponent(WebHTMLDiv16);
    WebLabel34.Name := 'WebLabel34';
    WebLabel34.Left := 20;
    WebLabel34.Top := 11;
    WebLabel34.Width := 79;
    WebLabel34.Height := 15;
    WebLabel34.Caption := 'Meine Freunde';
    WebLabel34.ElementClassName := 'profil_label';
    WebLabel34.ElementFont := efCSS;
    WebLabel34.ElementPosition := epIgnore;
    WebLabel34.HeightStyle := ssAuto;
    WebLabel34.HeightPercent := 100.000000000000000000;
    WebLabel34.WidthStyle := ssAuto;
    WebLabel34.WidthPercent := 100.000000000000000000;
    WebHTMLDiv97.SetParentComponent(WebHTMLDiv16);
    WebHTMLDiv97.Name := 'WebHTMLDiv97';
    WebHTMLDiv97.Left := 250;
    WebHTMLDiv97.Top := 3;
    WebHTMLDiv97.Width := 79;
    WebHTMLDiv97.Height := 30;
    WebHTMLDiv97.ElementClassName := 'profil_sichtbar';
    WebHTMLDiv97.HeightStyle := ssAuto;
    WebHTMLDiv97.WidthStyle := ssAuto;
    WebHTMLDiv97.ChildOrder := 2;
    WebHTMLDiv97.ElementPosition := epIgnore;
    WebHTMLDiv97.ElementFont := efCSS;
    WebHTMLDiv97.Role := '';
    cbShowFreunde.SetParentComponent(WebHTMLDiv97);
    cbShowFreunde.Name := 'cbShowFreunde';
    cbShowFreunde.Left := 3;
    cbShowFreunde.Top := 3;
    cbShowFreunde.Width := 68;
    cbShowFreunde.Height := 23;
    cbShowFreunde.ChildOrder := 1;
    cbShowFreunde.ElementClassName := 'profil_edit';
    cbShowFreunde.ElementFont := efCSS;
    cbShowFreunde.ElementPosition := epIgnore;
    cbShowFreunde.HeightStyle := ssAuto;
    cbShowFreunde.HeightPercent := 100.000000000000000000;
    cbShowFreunde.WidthStyle := ssAuto;
    cbShowFreunde.WidthPercent := 100.000000000000000000;
    cbShowFreunde.ItemIndex := -1;
    cbShowFreunde.Items.BeginUpdate;
    try
      cbShowFreunde.Items.Clear;
      cbShowFreunde.Items.Add('nur ich darf das sehen');
      cbShowFreunde.Items.Add('Freunde d'#252'rfen das sehen');
      cbShowFreunde.Items.Add('Mitglieder d'#252'rfen das sehen');
      cbShowFreunde.Items.Add('jeder darf das sehen');
    finally
      cbShowFreunde.Items.EndUpdate;
    end;
    WebHTMLDiv11.SetParentComponent(divContent);
    WebHTMLDiv11.Name := 'WebHTMLDiv11';
    WebHTMLDiv11.Left := 401;
    WebHTMLDiv11.Top := 61;
    WebHTMLDiv11.Width := 377;
    WebHTMLDiv11.Height := 852;
    WebHTMLDiv11.ElementClassName := 'profil_container';
    WebHTMLDiv11.HeightStyle := ssAuto;
    WebHTMLDiv11.WidthStyle := ssAuto;
    WebHTMLDiv11.ChildOrder := 2;
    WebHTMLDiv11.ElementPosition := epIgnore;
    WebHTMLDiv11.ElementFont := efCSS;
    WebHTMLDiv11.Role := '';
    WebHTMLDiv12.SetParentComponent(WebHTMLDiv11);
    WebHTMLDiv12.Name := 'WebHTMLDiv12';
    WebHTMLDiv12.Left := 10;
    WebHTMLDiv12.Top := 16;
    WebHTMLDiv12.Width := 345;
    WebHTMLDiv12.Height := 40;
    WebHTMLDiv12.ElementClassName := 'profil_header';
    WebHTMLDiv12.HeightStyle := ssAuto;
    WebHTMLDiv12.WidthStyle := ssAuto;
    WebHTMLDiv12.ElementPosition := epIgnore;
    WebHTMLDiv12.ElementFont := efCSS;
    WebHTMLDiv12.Role := '';
    WebLabel10.SetParentComponent(WebHTMLDiv12);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Left := 16;
    WebLabel10.Top := 16;
    WebLabel10.Width := 60;
    WebLabel10.Height := 15;
    WebLabel10.Caption := 'Darstellung';
    WebLabel10.ElementFont := efCSS;
    WebLabel10.ElementPosition := epRelative;
    WebLabel10.HeightStyle := ssAuto;
    WebLabel10.HeightPercent := 100.000000000000000000;
    WebLabel10.WidthStyle := ssAuto;
    WebLabel10.WidthPercent := 100.000000000000000000;
    WebHTMLDiv13.SetParentComponent(WebHTMLDiv11);
    WebHTMLDiv13.Name := 'WebHTMLDiv13';
    WebHTMLDiv13.Left := 10;
    WebHTMLDiv13.Top := 64;
    WebHTMLDiv13.Width := 345;
    WebHTMLDiv13.Height := 40;
    WebHTMLDiv13.ElementClassName := 'profil_line';
    WebHTMLDiv13.HeightStyle := ssAuto;
    WebHTMLDiv13.WidthStyle := ssAuto;
    WebHTMLDiv13.ChildOrder := 1;
    WebHTMLDiv13.ElementPosition := epIgnore;
    WebHTMLDiv13.ElementFont := efCSS;
    WebHTMLDiv13.Role := '';
    WebLabel11.SetParentComponent(WebHTMLDiv13);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Left := 20;
    WebLabel11.Top := 11;
    WebLabel11.Width := 57;
    WebLabel11.Height := 15;
    WebLabel11.Caption := 'Nickname:';
    WebLabel11.ElementClassName := 'profil_label';
    WebLabel11.ElementFont := efCSS;
    WebLabel11.ElementPosition := epIgnore;
    WebLabel11.HeightStyle := ssAuto;
    WebLabel11.HeightPercent := 100.000000000000000000;
    WebLabel11.WidthStyle := ssAuto;
    WebLabel11.WidthPercent := 100.000000000000000000;
    WebHTMLDiv44.SetParentComponent(WebHTMLDiv13);
    WebHTMLDiv44.Name := 'WebHTMLDiv44';
    WebHTMLDiv44.Left := 100;
    WebHTMLDiv44.Top := 3;
    WebHTMLDiv44.Width := 100;
    WebHTMLDiv44.Height := 30;
    WebHTMLDiv44.ElementClassName := 'profil_edit_container';
    WebHTMLDiv44.HeightStyle := ssAuto;
    WebHTMLDiv44.WidthStyle := ssAuto;
    WebHTMLDiv44.ChildOrder := 2;
    WebHTMLDiv44.ElementPosition := epIgnore;
    WebHTMLDiv44.ElementFont := efCSS;
    WebHTMLDiv44.Role := '';
    edNickname.SetParentComponent(WebHTMLDiv44);
    edNickname.Name := 'edNickname';
    edNickname.Left := 10;
    edNickname.Top := 2;
    edNickname.Width := 60;
    edNickname.Height := 25;
    edNickname.Margins.Left := 0;
    edNickname.Margins.Top := 0;
    edNickname.Margins.Right := 0;
    edNickname.Margins.Bottom := 0;
    edNickname.ElementClassName := 'profil_edit';
    edNickname.HeightStyle := ssAuto;
    edNickname.WidthStyle := ssAuto;
    edNickname.AutoSelect := True;
    edNickname.BorderStyle := bsSingle;
    edNickname.Color := clWhite;
    edNickname.ElementFont := efCSS;
    edNickname.ElementPosition := epRelative;
    edNickname.ShowHint := True;
    edNickname.TabStop := True;
    edNickname.TabOrder := 0;
    edNickname.Items.BeginUpdate;
    try
      edNickname.Items.Clear;
      edNickname.Items.Add('test 1');
      edNickname.Items.Add('test 2');
      edNickname.Items.Add('test 3');
    finally
      edNickname.Items.EndUpdate;
    end;
    edNickname.Text := '';
    WebHTMLDiv78.SetParentComponent(WebHTMLDiv13);
    WebHTMLDiv78.Name := 'WebHTMLDiv78';
    WebHTMLDiv78.Left := 250;
    WebHTMLDiv78.Top := 3;
    WebHTMLDiv78.Width := 79;
    WebHTMLDiv78.Height := 30;
    WebHTMLDiv78.ElementClassName := 'profil_sichtbar';
    WebHTMLDiv78.HeightStyle := ssAuto;
    WebHTMLDiv78.WidthStyle := ssAuto;
    WebHTMLDiv78.ChildOrder := 2;
    WebHTMLDiv78.ElementPosition := epIgnore;
    WebHTMLDiv78.ElementFont := efCSS;
    WebHTMLDiv78.Role := '';
    cbShowNickname.SetParentComponent(WebHTMLDiv78);
    cbShowNickname.Name := 'cbShowNickname';
    cbShowNickname.Left := 3;
    cbShowNickname.Top := 3;
    cbShowNickname.Width := 68;
    cbShowNickname.Height := 23;
    cbShowNickname.ChildOrder := 1;
    cbShowNickname.ElementClassName := 'profil_edit';
    cbShowNickname.ElementFont := efCSS;
    cbShowNickname.ElementPosition := epIgnore;
    cbShowNickname.HeightStyle := ssAuto;
    cbShowNickname.HeightPercent := 100.000000000000000000;
    cbShowNickname.WidthStyle := ssAuto;
    cbShowNickname.WidthPercent := 100.000000000000000000;
    cbShowNickname.ItemIndex := -1;
    cbShowNickname.Items.BeginUpdate;
    try
      cbShowNickname.Items.Clear;
      cbShowNickname.Items.Add('nur ich darf das sehen');
      cbShowNickname.Items.Add('Freunde d'#252'rfen das sehen');
      cbShowNickname.Items.Add('Mitglieder d'#252'rfen das sehen');
      cbShowNickname.Items.Add('jeder darf das sehen');
    finally
      cbShowNickname.Items.EndUpdate;
    end;
    WebHTMLDiv14.SetParentComponent(WebHTMLDiv11);
    WebHTMLDiv14.Name := 'WebHTMLDiv14';
    WebHTMLDiv14.Left := 3;
    WebHTMLDiv14.Top := 110;
    WebHTMLDiv14.Width := 345;
    WebHTMLDiv14.Height := 139;
    WebHTMLDiv14.ElementClassName := 'profil_line';
    WebHTMLDiv14.HeightStyle := ssAuto;
    WebHTMLDiv14.WidthStyle := ssAuto;
    WebHTMLDiv14.ChildOrder := 2;
    WebHTMLDiv14.ElementPosition := epIgnore;
    WebHTMLDiv14.ElementFont := efCSS;
    WebHTMLDiv14.Role := '';
    WebLabel12.SetParentComponent(WebHTMLDiv14);
    WebLabel12.Name := 'WebLabel12';
    WebLabel12.Left := 16;
    WebLabel12.Top := 15;
    WebLabel12.Width := 81;
    WebLabel12.Height := 15;
    WebLabel12.Caption := 'mein Profilbild:';
    WebLabel12.ElementClassName := 'profil_label';
    WebLabel12.ElementFont := efCSS;
    WebLabel12.ElementPosition := epIgnore;
    WebLabel12.HeightStyle := ssAuto;
    WebLabel12.HeightPercent := 100.000000000000000000;
    WebLabel12.WidthStyle := ssAuto;
    WebLabel12.WidthPercent := 100.000000000000000000;
    divWrapper.SetParentComponent(WebHTMLDiv14);
    divWrapper.Name := 'divWrapper';
    divWrapper.Left := 109;
    divWrapper.Top := 7;
    divWrapper.Width := 233;
    divWrapper.Height := 129;
    divWrapper.ElementClassName := 'media_content_wrapper';
    divWrapper.HeightStyle := ssAuto;
    divWrapper.WidthStyle := ssAuto;
    divWrapper.ChildOrder := 1;
    divWrapper.ElementPosition := epIgnore;
    divWrapper.ElementFont := efCSS;
    divWrapper.Role := '';
    imgAvatar.SetParentComponent(divWrapper);
    imgAvatar.Name := 'imgAvatar';
    imgAvatar.Left := 88;
    imgAvatar.Top := 36;
    imgAvatar.Width := 57;
    imgAvatar.Height := 53;
    imgAvatar.ElementClassName := 'header_avatar';
    imgAvatar.HeightStyle := ssAuto;
    imgAvatar.WidthStyle := ssAuto;
    imgAvatar.HeightPercent := 100.000000000000000000;
    imgAvatar.WidthPercent := 100.000000000000000000;
    imgAvatar.ElementFont := efCSS;
    imgAvatar.ElementPosition := epIgnore;
    SetEvent(imgAvatar, Self, 'OnClick', 'imgAvatarClick');
    SetEvent(imgAvatar, Self, 'OnMouseEnter', 'imgAvatarMouseEnter');
    imgAvatar.Picture.LoadFromFile('uProfil.divWrapper.imgAvatar.Picture.png');
    WebHTMLDiv27.SetParentComponent(WebHTMLDiv11);
    WebHTMLDiv27.Name := 'WebHTMLDiv27';
    WebHTMLDiv27.Left := 3;
    WebHTMLDiv27.Top := 438;
    WebHTMLDiv27.Width := 345;
    WebHTMLDiv27.Height := 119;
    WebHTMLDiv27.ElementClassName := 'profil-memo-container';
    WebHTMLDiv27.HeightStyle := ssAuto;
    WebHTMLDiv27.WidthStyle := ssAuto;
    WebHTMLDiv27.ChildOrder := 4;
    WebHTMLDiv27.ElementPosition := epIgnore;
    WebHTMLDiv27.ElementFont := efCSS;
    WebHTMLDiv27.Role := '';
    WebHTMLDiv99.SetParentComponent(WebHTMLDiv27);
    WebHTMLDiv99.Name := 'WebHTMLDiv99';
    WebHTMLDiv99.Left := 16;
    WebHTMLDiv99.Top := 16;
    WebHTMLDiv99.Width := 305;
    WebHTMLDiv99.Height := 41;
    WebHTMLDiv99.ElementClassName := 'profil-memo-header';
    WebHTMLDiv99.HeightStyle := ssAuto;
    WebHTMLDiv99.WidthStyle := ssAuto;
    WebHTMLDiv99.ElementPosition := epIgnore;
    WebHTMLDiv99.ElementFont := efCSS;
    WebHTMLDiv99.Role := '';
    WebLabel18.SetParentComponent(WebHTMLDiv99);
    WebLabel18.Name := 'WebLabel18';
    WebLabel18.Left := 20;
    WebLabel18.Top := 11;
    WebLabel18.Width := 58;
    WebLabel18.Height := 15;
    WebLabel18.Caption := #220'ber mich:';
    WebLabel18.ElementClassName := 'profil_label';
    WebLabel18.ElementFont := efCSS;
    WebLabel18.ElementPosition := epIgnore;
    WebLabel18.HeightStyle := ssAuto;
    WebLabel18.HeightPercent := 100.000000000000000000;
    WebLabel18.WidthStyle := ssAuto;
    WebLabel18.WidthPercent := 100.000000000000000000;
    WebHTMLDiv74.SetParentComponent(WebHTMLDiv99);
    WebHTMLDiv74.Name := 'WebHTMLDiv74';
    WebHTMLDiv74.Left := 226;
    WebHTMLDiv74.Top := 3;
    WebHTMLDiv74.Width := 79;
    WebHTMLDiv74.Height := 30;
    WebHTMLDiv74.ElementClassName := 'profil_sichtbar';
    WebHTMLDiv74.HeightStyle := ssAuto;
    WebHTMLDiv74.WidthStyle := ssAuto;
    WebHTMLDiv74.ChildOrder := 2;
    WebHTMLDiv74.ElementPosition := epIgnore;
    WebHTMLDiv74.ElementFont := efCSS;
    WebHTMLDiv74.Role := '';
    cbShowAbout.SetParentComponent(WebHTMLDiv74);
    cbShowAbout.Name := 'cbShowAbout';
    cbShowAbout.Left := 3;
    cbShowAbout.Top := 3;
    cbShowAbout.Width := 68;
    cbShowAbout.Height := 23;
    cbShowAbout.ChildOrder := 1;
    cbShowAbout.ElementClassName := 'profil_edit';
    cbShowAbout.ElementFont := efCSS;
    cbShowAbout.ElementPosition := epIgnore;
    cbShowAbout.HeightStyle := ssAuto;
    cbShowAbout.HeightPercent := 100.000000000000000000;
    cbShowAbout.WidthStyle := ssAuto;
    cbShowAbout.WidthPercent := 100.000000000000000000;
    cbShowAbout.ItemIndex := -1;
    cbShowAbout.Items.BeginUpdate;
    try
      cbShowAbout.Items.Clear;
      cbShowAbout.Items.Add('nur ich darf das sehen');
      cbShowAbout.Items.Add('Freunde d'#252'rfen das sehen');
      cbShowAbout.Items.Add('Mitglieder d'#252'rfen das sehen');
      cbShowAbout.Items.Add('jeder darf das sehen');
    finally
      cbShowAbout.Items.EndUpdate;
    end;
    edAbout.SetParentComponent(WebHTMLDiv27);
    edAbout.Name := 'edAbout';
    edAbout.Left := 16;
    edAbout.Top := 63;
    edAbout.Width := 305;
    edAbout.Height := 42;
    edAbout.AutoSize := False;
    edAbout.ChildOrder := 1;
    edAbout.ElementClassName := 'profil-memo';
    edAbout.ElementFont := efCSS;
    edAbout.ElementPosition := epIgnore;
    edAbout.HeightStyle := ssAuto;
    edAbout.HeightPercent := 100.000000000000000000;
    edAbout.Lines.BeginUpdate;
    try
      edAbout.Lines.Clear;
      edAbout.Lines.Add('edAbout');
    finally
      edAbout.Lines.EndUpdate;
    end;
    edAbout.SelLength := 0;
    edAbout.SelStart := 0;
    edAbout.WidthStyle := ssAuto;
    edAbout.WidthPercent := 100.000000000000000000;
    WebHTMLDiv28.SetParentComponent(WebHTMLDiv11);
    WebHTMLDiv28.Name := 'WebHTMLDiv28';
    WebHTMLDiv28.Left := 3;
    WebHTMLDiv28.Top := 566;
    WebHTMLDiv28.Width := 345;
    WebHTMLDiv28.Height := 95;
    WebHTMLDiv28.ElementClassName := 'profil-memo-container';
    WebHTMLDiv28.HeightStyle := ssAuto;
    WebHTMLDiv28.WidthStyle := ssAuto;
    WebHTMLDiv28.ChildOrder := 5;
    WebHTMLDiv28.ElementPosition := epIgnore;
    WebHTMLDiv28.ElementFont := efCSS;
    WebHTMLDiv28.Role := '';
    WebHTMLDiv47.SetParentComponent(WebHTMLDiv28);
    WebHTMLDiv47.Name := 'WebHTMLDiv47';
    WebHTMLDiv47.Left := 3;
    WebHTMLDiv47.Top := 3;
    WebHTMLDiv47.Width := 326;
    WebHTMLDiv47.Height := 41;
    WebHTMLDiv47.ElementClassName := 'profil-memo-header';
    WebHTMLDiv47.HeightStyle := ssAuto;
    WebHTMLDiv47.WidthStyle := ssAuto;
    WebHTMLDiv47.ChildOrder := 3;
    WebHTMLDiv47.ElementPosition := epIgnore;
    WebHTMLDiv47.ElementFont := efCSS;
    WebHTMLDiv47.Role := '';
    WebLabel19.SetParentComponent(WebHTMLDiv47);
    WebLabel19.Name := 'WebLabel19';
    WebLabel19.Left := 3;
    WebLabel19.Top := 10;
    WebLabel19.Width := 83;
    WebLabel19.Height := 15;
    WebLabel19.Caption := 'Meine Hobbies:';
    WebLabel19.ElementClassName := 'profil_label';
    WebLabel19.ElementFont := efCSS;
    WebLabel19.ElementPosition := epIgnore;
    WebLabel19.HeightStyle := ssAuto;
    WebLabel19.HeightPercent := 100.000000000000000000;
    WebLabel19.WidthStyle := ssAuto;
    WebLabel19.WidthPercent := 100.000000000000000000;
    spHobbies.SetParentComponent(WebHTMLDiv47);
    spHobbies.Name := 'spHobbies';
    spHobbies.Left := 109;
    spHobbies.Top := 3;
    spHobbies.Width := 30;
    spHobbies.Height := 30;
    spHobbies.ChildOrder := 1;
    spHobbies.Color := clNone;
    spHobbies.ElementFont := efCSS;
    spHobbies.ElementPosition := epRelative;
    spHobbies.HeightPercent := 100.000000000000000000;
    spHobbies.MaterialGlyph := 'search';
    spHobbies.MaterialGlyphSize := 25;
    spHobbies.TabOrder := 0;
    spHobbies.WidthPercent := 100.000000000000000000;
    SetEvent(spHobbies, Self, 'OnClick', 'spHobbiesClick');
    WebHTMLDiv75.SetParentComponent(WebHTMLDiv47);
    WebHTMLDiv75.Name := 'WebHTMLDiv75';
    WebHTMLDiv75.Left := 247;
    WebHTMLDiv75.Top := 11;
    WebHTMLDiv75.Width := 79;
    WebHTMLDiv75.Height := 30;
    WebHTMLDiv75.ElementClassName := 'profil_sichtbar';
    WebHTMLDiv75.HeightStyle := ssAuto;
    WebHTMLDiv75.WidthStyle := ssAuto;
    WebHTMLDiv75.ChildOrder := 2;
    WebHTMLDiv75.ElementPosition := epIgnore;
    WebHTMLDiv75.ElementFont := efCSS;
    WebHTMLDiv75.Role := '';
    cbShowHobbies.SetParentComponent(WebHTMLDiv75);
    cbShowHobbies.Name := 'cbShowHobbies';
    cbShowHobbies.Left := 3;
    cbShowHobbies.Top := 3;
    cbShowHobbies.Width := 68;
    cbShowHobbies.Height := 23;
    cbShowHobbies.ChildOrder := 1;
    cbShowHobbies.ElementClassName := 'profil_edit';
    cbShowHobbies.ElementFont := efCSS;
    cbShowHobbies.ElementPosition := epIgnore;
    cbShowHobbies.HeightStyle := ssAuto;
    cbShowHobbies.HeightPercent := 100.000000000000000000;
    cbShowHobbies.WidthStyle := ssAuto;
    cbShowHobbies.WidthPercent := 100.000000000000000000;
    cbShowHobbies.ItemIndex := -1;
    cbShowHobbies.Items.BeginUpdate;
    try
      cbShowHobbies.Items.Clear;
      cbShowHobbies.Items.Add('nur ich darf das sehen');
      cbShowHobbies.Items.Add('Freunde d'#252'rfen das sehen');
      cbShowHobbies.Items.Add('Mitglieder d'#252'rfen das sehen');
      cbShowHobbies.Items.Add('jeder darf das sehen');
    finally
      cbShowHobbies.Items.EndUpdate;
    end;
    divHobbies.SetParentComponent(WebHTMLDiv28);
    divHobbies.Name := 'divHobbies';
    divHobbies.Left := 8;
    divHobbies.Top := 48;
    divHobbies.Width := 321;
    divHobbies.Height := 41;
    divHobbies.ElementClassName := 'profil-hash';
    divHobbies.HeightStyle := ssAuto;
    divHobbies.WidthStyle := ssAuto;
    divHobbies.ChildOrder := 1;
    divHobbies.ElementPosition := epIgnore;
    divHobbies.ElementFont := efCSS;
    divHobbies.Role := '';
    WebHTMLDiv15.SetParentComponent(WebHTMLDiv11);
    WebHTMLDiv15.Name := 'WebHTMLDiv15';
    WebHTMLDiv15.Left := 0;
    WebHTMLDiv15.Top := 255;
    WebHTMLDiv15.Width := 345;
    WebHTMLDiv15.Height := 177;
    WebHTMLDiv15.ElementClassName := 'profil_line';
    WebHTMLDiv15.HeightStyle := ssAuto;
    WebHTMLDiv15.WidthStyle := ssAuto;
    WebHTMLDiv15.ChildOrder := 3;
    WebHTMLDiv15.ElementPosition := epIgnore;
    WebHTMLDiv15.ElementFont := efCSS;
    WebHTMLDiv15.Role := '';
    WebLabel13.SetParentComponent(WebHTMLDiv15);
    WebLabel13.Name := 'WebLabel13';
    WebLabel13.Left := 16;
    WebLabel13.Top := 16;
    WebLabel13.Width := 75;
    WebLabel13.Height := 15;
    WebLabel13.Caption := 'mein Titelbild:';
    WebLabel13.ElementClassName := 'profil_label';
    WebLabel13.ElementFont := efCSS;
    WebLabel13.ElementPosition := epIgnore;
    WebLabel13.HeightStyle := ssAuto;
    WebLabel13.HeightPercent := 100.000000000000000000;
    WebLabel13.WidthStyle := ssAuto;
    WebLabel13.WidthPercent := 100.000000000000000000;
    WebHTMLDiv18.SetParentComponent(WebHTMLDiv15);
    WebHTMLDiv18.Name := 'WebHTMLDiv18';
    WebHTMLDiv18.Left := 112;
    WebHTMLDiv18.Top := 15;
    WebHTMLDiv18.Width := 233;
    WebHTMLDiv18.Height := 129;
    WebHTMLDiv18.ElementClassName := 'media_content_wrapper';
    WebHTMLDiv18.HeightStyle := ssAuto;
    WebHTMLDiv18.WidthStyle := ssAuto;
    WebHTMLDiv18.ChildOrder := 1;
    WebHTMLDiv18.ElementPosition := epIgnore;
    WebHTMLDiv18.ElementFont := efCSS;
    WebHTMLDiv18.Role := '';
    imgTitel.SetParentComponent(WebHTMLDiv18);
    imgTitel.Name := 'imgTitel';
    imgTitel.Left := 88;
    imgTitel.Top := 36;
    imgTitel.Width := 57;
    imgTitel.Height := 53;
    imgTitel.ElementClassName := 'media_new';
    imgTitel.HeightStyle := ssAuto;
    imgTitel.WidthStyle := ssAuto;
    imgTitel.HeightPercent := 100.000000000000000000;
    imgTitel.WidthPercent := 100.000000000000000000;
    imgTitel.ElementFont := efCSS;
    imgTitel.ElementPosition := epIgnore;
    SetEvent(imgTitel, Self, 'OnClick', 'imgAvatarClick');
    imgTitel.Picture.LoadFromFile('uProfil.WebHTMLDiv18.imgTitel.Picture.png');
    WebHTMLDiv29.SetParentComponent(WebHTMLDiv11);
    WebHTMLDiv29.Name := 'WebHTMLDiv29';
    WebHTMLDiv29.Left := 3;
    WebHTMLDiv29.Top := 667;
    WebHTMLDiv29.Width := 345;
    WebHTMLDiv29.Height := 95;
    WebHTMLDiv29.ElementClassName := 'profil-memo-container';
    WebHTMLDiv29.HeightStyle := ssAuto;
    WebHTMLDiv29.WidthStyle := ssAuto;
    WebHTMLDiv29.ChildOrder := 6;
    WebHTMLDiv29.ElementPosition := epIgnore;
    WebHTMLDiv29.ElementFont := efCSS;
    WebHTMLDiv29.Role := '';
    WebHTMLDiv48.SetParentComponent(WebHTMLDiv29);
    WebHTMLDiv48.Name := 'WebHTMLDiv48';
    WebHTMLDiv48.Left := 3;
    WebHTMLDiv48.Top := 3;
    WebHTMLDiv48.Width := 326;
    WebHTMLDiv48.Height := 41;
    WebHTMLDiv48.ElementClassName := 'profil-memo-header';
    WebHTMLDiv48.HeightStyle := ssAuto;
    WebHTMLDiv48.WidthStyle := ssAuto;
    WebHTMLDiv48.ChildOrder := 3;
    WebHTMLDiv48.ElementPosition := epIgnore;
    WebHTMLDiv48.ElementFont := efCSS;
    WebHTMLDiv48.Role := '';
    WebLabel20.SetParentComponent(WebHTMLDiv48);
    WebLabel20.Name := 'WebLabel20';
    WebLabel20.Left := 3;
    WebLabel20.Top := 10;
    WebLabel20.Width := 90;
    WebLabel20.Height := 15;
    WebLabel20.Caption := 'Lieblings-Reviere';
    WebLabel20.ElementClassName := 'profil_label';
    WebLabel20.ElementFont := efCSS;
    WebLabel20.ElementPosition := epIgnore;
    WebLabel20.HeightStyle := ssAuto;
    WebLabel20.HeightPercent := 100.000000000000000000;
    WebLabel20.WidthStyle := ssAuto;
    WebLabel20.WidthPercent := 100.000000000000000000;
    spReviere.SetParentComponent(WebHTMLDiv48);
    spReviere.Name := 'spReviere';
    spReviere.Left := 109;
    spReviere.Top := 3;
    spReviere.Width := 30;
    spReviere.Height := 30;
    spReviere.ChildOrder := 1;
    spReviere.Color := clNone;
    spReviere.ElementFont := efCSS;
    spReviere.ElementPosition := epRelative;
    spReviere.HeightPercent := 100.000000000000000000;
    spReviere.MaterialGlyph := 'search';
    spReviere.MaterialGlyphSize := 25;
    spReviere.TabOrder := 0;
    spReviere.WidthPercent := 100.000000000000000000;
    SetEvent(spReviere, Self, 'OnClick', 'spHobbiesClick');
    WebHTMLDiv49.SetParentComponent(WebHTMLDiv48);
    WebHTMLDiv49.Name := 'WebHTMLDiv49';
    WebHTMLDiv49.Left := 247;
    WebHTMLDiv49.Top := 11;
    WebHTMLDiv49.Width := 79;
    WebHTMLDiv49.Height := 30;
    WebHTMLDiv49.ElementClassName := 'profil_sichtbar';
    WebHTMLDiv49.HeightStyle := ssAuto;
    WebHTMLDiv49.WidthStyle := ssAuto;
    WebHTMLDiv49.ChildOrder := 2;
    WebHTMLDiv49.ElementPosition := epIgnore;
    WebHTMLDiv49.ElementFont := efCSS;
    WebHTMLDiv49.Role := '';
    cbShowReviere.SetParentComponent(WebHTMLDiv49);
    cbShowReviere.Name := 'cbShowReviere';
    cbShowReviere.Left := 3;
    cbShowReviere.Top := 3;
    cbShowReviere.Width := 68;
    cbShowReviere.Height := 23;
    cbShowReviere.ChildOrder := 1;
    cbShowReviere.ElementClassName := 'profil_edit';
    cbShowReviere.ElementFont := efCSS;
    cbShowReviere.ElementPosition := epIgnore;
    cbShowReviere.HeightStyle := ssAuto;
    cbShowReviere.HeightPercent := 100.000000000000000000;
    cbShowReviere.Text := 'Freunde d'#252'rfen das sehen';
    cbShowReviere.WidthStyle := ssAuto;
    cbShowReviere.WidthPercent := 100.000000000000000000;
    cbShowReviere.ItemIndex := 1;
    cbShowReviere.Items.BeginUpdate;
    try
      cbShowReviere.Items.Clear;
      cbShowReviere.Items.Add('nur ich darf das sehen');
      cbShowReviere.Items.Add('Freunde d'#252'rfen das sehen');
      cbShowReviere.Items.Add('Mitglieder d'#252'rfen das sehen');
      cbShowReviere.Items.Add('jeder darf das sehen');
    finally
      cbShowReviere.Items.EndUpdate;
    end;
    divReviere.SetParentComponent(WebHTMLDiv29);
    divReviere.Name := 'divReviere';
    divReviere.Left := 8;
    divReviere.Top := 48;
    divReviere.Width := 321;
    divReviere.Height := 41;
    divReviere.ElementClassName := 'profil-hash';
    divReviere.HeightStyle := ssAuto;
    divReviere.WidthStyle := ssAuto;
    divReviere.ChildOrder := 1;
    divReviere.ElementPosition := epIgnore;
    divReviere.ElementFont := efCSS;
    divReviere.Role := '';
    WebHTMLDiv58.SetParentComponent(WebHTMLDiv11);
    WebHTMLDiv58.Name := 'WebHTMLDiv58';
    WebHTMLDiv58.Left := 3;
    WebHTMLDiv58.Top := 768;
    WebHTMLDiv58.Width := 345;
    WebHTMLDiv58.Height := 95;
    WebHTMLDiv58.ElementClassName := 'profil-memo-container';
    WebHTMLDiv58.HeightStyle := ssAuto;
    WebHTMLDiv58.WidthStyle := ssAuto;
    WebHTMLDiv58.ChildOrder := 7;
    WebHTMLDiv58.ElementPosition := epIgnore;
    WebHTMLDiv58.ElementFont := efCSS;
    WebHTMLDiv58.Role := '';
    WebHTMLDiv76.SetParentComponent(WebHTMLDiv58);
    WebHTMLDiv76.Name := 'WebHTMLDiv76';
    WebHTMLDiv76.Left := 3;
    WebHTMLDiv76.Top := 3;
    WebHTMLDiv76.Width := 326;
    WebHTMLDiv76.Height := 41;
    WebHTMLDiv76.ElementClassName := 'profil-memo-header';
    WebHTMLDiv76.HeightStyle := ssAuto;
    WebHTMLDiv76.WidthStyle := ssAuto;
    WebHTMLDiv76.ChildOrder := 3;
    WebHTMLDiv76.ElementPosition := epIgnore;
    WebHTMLDiv76.ElementFont := efCSS;
    WebHTMLDiv76.Role := '';
    WebLabel25.SetParentComponent(WebHTMLDiv76);
    WebLabel25.Name := 'WebLabel25';
    WebLabel25.Left := 3;
    WebLabel25.Top := 10;
    WebLabel25.Width := 47;
    WebLabel25.Height := 15;
    WebLabel25.Caption := 'Expertise';
    WebLabel25.ElementClassName := 'profil_label';
    WebLabel25.ElementFont := efCSS;
    WebLabel25.ElementPosition := epIgnore;
    WebLabel25.HeightStyle := ssAuto;
    WebLabel25.HeightPercent := 100.000000000000000000;
    WebLabel25.WidthStyle := ssAuto;
    WebLabel25.WidthPercent := 100.000000000000000000;
    spExpertise.SetParentComponent(WebHTMLDiv76);
    spExpertise.Name := 'spExpertise';
    spExpertise.Left := 109;
    spExpertise.Top := 3;
    spExpertise.Width := 30;
    spExpertise.Height := 30;
    spExpertise.ChildOrder := 1;
    spExpertise.Color := clNone;
    spExpertise.ElementFont := efCSS;
    spExpertise.ElementPosition := epRelative;
    spExpertise.HeightPercent := 100.000000000000000000;
    spExpertise.MaterialGlyph := 'search';
    spExpertise.MaterialGlyphSize := 25;
    spExpertise.TabOrder := 0;
    spExpertise.WidthPercent := 100.000000000000000000;
    SetEvent(spExpertise, Self, 'OnClick', 'spHobbiesClick');
    WebHTMLDiv77.SetParentComponent(WebHTMLDiv76);
    WebHTMLDiv77.Name := 'WebHTMLDiv77';
    WebHTMLDiv77.Left := 247;
    WebHTMLDiv77.Top := 11;
    WebHTMLDiv77.Width := 79;
    WebHTMLDiv77.Height := 30;
    WebHTMLDiv77.ElementClassName := 'profil_sichtbar';
    WebHTMLDiv77.HeightStyle := ssAuto;
    WebHTMLDiv77.WidthStyle := ssAuto;
    WebHTMLDiv77.ChildOrder := 2;
    WebHTMLDiv77.ElementPosition := epIgnore;
    WebHTMLDiv77.ElementFont := efCSS;
    WebHTMLDiv77.Role := '';
    cbShowExpertise.SetParentComponent(WebHTMLDiv77);
    cbShowExpertise.Name := 'cbShowExpertise';
    cbShowExpertise.Left := 3;
    cbShowExpertise.Top := 3;
    cbShowExpertise.Width := 68;
    cbShowExpertise.Height := 23;
    cbShowExpertise.ChildOrder := 1;
    cbShowExpertise.ElementClassName := 'profil_edit';
    cbShowExpertise.ElementFont := efCSS;
    cbShowExpertise.ElementPosition := epIgnore;
    cbShowExpertise.HeightStyle := ssAuto;
    cbShowExpertise.HeightPercent := 100.000000000000000000;
    cbShowExpertise.WidthStyle := ssAuto;
    cbShowExpertise.WidthPercent := 100.000000000000000000;
    cbShowExpertise.ItemIndex := -1;
    cbShowExpertise.Items.BeginUpdate;
    try
      cbShowExpertise.Items.Clear;
      cbShowExpertise.Items.Add('nur ich darf das sehen');
      cbShowExpertise.Items.Add('Freunde d'#252'rfen das sehen');
      cbShowExpertise.Items.Add('Mitglieder d'#252'rfen das sehen');
      cbShowExpertise.Items.Add('jeder darf das sehen');
    finally
      cbShowExpertise.Items.EndUpdate;
    end;
    divExpertise.SetParentComponent(WebHTMLDiv58);
    divExpertise.Name := 'divExpertise';
    divExpertise.Left := 8;
    divExpertise.Top := 48;
    divExpertise.Width := 321;
    divExpertise.Height := 41;
    divExpertise.ElementClassName := 'profil-hash';
    divExpertise.HeightStyle := ssAuto;
    divExpertise.WidthStyle := ssAuto;
    divExpertise.ChildOrder := 1;
    divExpertise.ElementPosition := epIgnore;
    divExpertise.ElementFont := efCSS;
    divExpertise.Role := '';
    WebHTMLDiv30.SetParentComponent(divContent);
    WebHTMLDiv30.Name := 'WebHTMLDiv30';
    WebHTMLDiv30.Left := 880;
    WebHTMLDiv30.Top := 18;
    WebHTMLDiv30.Width := 377;
    WebHTMLDiv30.Height := 168;
    WebHTMLDiv30.ElementClassName := 'profil_container';
    WebHTMLDiv30.HeightStyle := ssAuto;
    WebHTMLDiv30.WidthStyle := ssAuto;
    WebHTMLDiv30.ChildOrder := 4;
    WebHTMLDiv30.ElementPosition := epIgnore;
    WebHTMLDiv30.ElementFont := efCSS;
    WebHTMLDiv30.Role := '';
    WebHTMLDiv31.SetParentComponent(WebHTMLDiv30);
    WebHTMLDiv31.Name := 'WebHTMLDiv31';
    WebHTMLDiv31.Left := 3;
    WebHTMLDiv31.Top := 16;
    WebHTMLDiv31.Width := 345;
    WebHTMLDiv31.Height := 40;
    WebHTMLDiv31.ElementClassName := 'profil_header';
    WebHTMLDiv31.HeightStyle := ssAuto;
    WebHTMLDiv31.WidthStyle := ssAuto;
    WebHTMLDiv31.ElementPosition := epIgnore;
    WebHTMLDiv31.ElementFont := efCSS;
    WebHTMLDiv31.Role := '';
    WebLabel21.SetParentComponent(WebHTMLDiv31);
    WebLabel21.Name := 'WebLabel21';
    WebLabel21.Left := 16;
    WebLabel21.Top := 16;
    WebLabel21.Width := 121;
    WebLabel21.Height := 15;
    WebLabel21.Caption := 'Bef'#228'higungsnachweise';
    WebLabel21.ElementFont := efCSS;
    WebLabel21.ElementPosition := epRelative;
    WebLabel21.HeightStyle := ssAuto;
    WebLabel21.HeightPercent := 100.000000000000000000;
    WebLabel21.WidthStyle := ssAuto;
    WebLabel21.WidthPercent := 100.000000000000000000;
    WebHTMLDiv33.SetParentComponent(WebHTMLDiv30);
    WebHTMLDiv33.Name := 'WebHTMLDiv33';
    WebHTMLDiv33.Left := 10;
    WebHTMLDiv33.Top := 62;
    WebHTMLDiv33.Width := 345;
    WebHTMLDiv33.Height := 40;
    WebHTMLDiv33.ElementClassName := 'profil_chklist_line';
    WebHTMLDiv33.HeightStyle := ssAuto;
    WebHTMLDiv33.WidthStyle := ssAuto;
    WebHTMLDiv33.ChildOrder := 2;
    WebHTMLDiv33.ElementPosition := epIgnore;
    WebHTMLDiv33.ElementFont := efCSS;
    WebHTMLDiv33.Role := '';
    WebLabel23.SetParentComponent(WebHTMLDiv33);
    WebLabel23.Name := 'WebLabel23';
    WebLabel23.Left := 20;
    WebLabel23.Top := 11;
    WebLabel23.Width := 102;
    WebLabel23.Height := 15;
    WebLabel23.Caption := 'Bootsf'#252'hrerscheine';
    WebLabel23.ElementClassName := 'profil_chklist_label';
    WebLabel23.ElementFont := efCSS;
    WebLabel23.ElementPosition := epIgnore;
    WebLabel23.HeightStyle := ssAuto;
    WebLabel23.HeightPercent := 100.000000000000000000;
    WebLabel23.WidthStyle := ssAuto;
    WebLabel23.WidthPercent := 100.000000000000000000;
    WebHTMLDiv56.SetParentComponent(WebHTMLDiv33);
    WebHTMLDiv56.Name := 'WebHTMLDiv56';
    WebHTMLDiv56.Left := 136;
    WebHTMLDiv56.Top := 3;
    WebHTMLDiv56.Width := 190;
    WebHTMLDiv56.Height := 30;
    WebHTMLDiv56.ElementClassName := 'profil_chklist_container';
    WebHTMLDiv56.HeightStyle := ssAuto;
    WebHTMLDiv56.WidthStyle := ssAuto;
    WebHTMLDiv56.ChildOrder := 2;
    WebHTMLDiv56.ElementPosition := epIgnore;
    WebHTMLDiv56.ElementFont := efCSS;
    WebHTMLDiv56.Role := '';
    clScheine.SetParentComponent(WebHTMLDiv56);
    clScheine.Name := 'clScheine';
    clScheine.Left := 3;
    clScheine.Top := 2;
    clScheine.Width := 140;
    clScheine.Height := 25;
    clScheine.Columns := 1;
    clScheine.ItemHeight := 15;
    clScheine.TabOrder := 0;
    clScheine.ElementClassName := 'profil_chklist';
    clScheine.ElementFont := efCSS;
    clScheine.ElementPosition := epIgnore;
    clScheine.HeightStyle := ssAuto;
    clScheine.HeightPercent := 100.000000000000000000;
    clScheine.WidthStyle := ssAuto;
    clScheine.WidthPercent := 100.000000000000000000;
    WebHTMLDiv32.SetParentComponent(WebHTMLDiv30);
    WebHTMLDiv32.Name := 'WebHTMLDiv32';
    WebHTMLDiv32.Left := 10;
    WebHTMLDiv32.Top := 108;
    WebHTMLDiv32.Width := 345;
    WebHTMLDiv32.Height := 40;
    WebHTMLDiv32.ElementClassName := 'profil_chklist_line';
    WebHTMLDiv32.HeightStyle := ssAuto;
    WebHTMLDiv32.WidthStyle := ssAuto;
    WebHTMLDiv32.ChildOrder := 2;
    WebHTMLDiv32.ElementPosition := epIgnore;
    WebHTMLDiv32.ElementFont := efCSS;
    WebHTMLDiv32.Role := '';
    WebLabel22.SetParentComponent(WebHTMLDiv32);
    WebLabel22.Name := 'WebLabel22';
    WebLabel22.Left := 20;
    WebLabel22.Top := 11;
    WebLabel22.Width := 66;
    WebLabel22.Height := 15;
    WebLabel22.Caption := 'Funkscheine';
    WebLabel22.ElementClassName := 'profil_chklist_label';
    WebLabel22.ElementFont := efCSS;
    WebLabel22.ElementPosition := epIgnore;
    WebLabel22.HeightStyle := ssAuto;
    WebLabel22.HeightPercent := 100.000000000000000000;
    WebLabel22.WidthStyle := ssAuto;
    WebLabel22.WidthPercent := 100.000000000000000000;
    WebHTMLDiv53.SetParentComponent(WebHTMLDiv32);
    WebHTMLDiv53.Name := 'WebHTMLDiv53';
    WebHTMLDiv53.Left := 136;
    WebHTMLDiv53.Top := 3;
    WebHTMLDiv53.Width := 190;
    WebHTMLDiv53.Height := 30;
    WebHTMLDiv53.ElementClassName := 'profil_chklist_container';
    WebHTMLDiv53.HeightStyle := ssAuto;
    WebHTMLDiv53.WidthStyle := ssAuto;
    WebHTMLDiv53.ChildOrder := 2;
    WebHTMLDiv53.ElementPosition := epIgnore;
    WebHTMLDiv53.ElementFont := efCSS;
    WebHTMLDiv53.Role := '';
    clFunk.SetParentComponent(WebHTMLDiv53);
    clFunk.Name := 'clFunk';
    clFunk.Left := 3;
    clFunk.Top := 3;
    clFunk.Width := 140;
    clFunk.Height := 25;
    clFunk.Columns := 1;
    clFunk.ItemHeight := 15;
    clFunk.TabOrder := 0;
    clFunk.ElementClassName := 'profil_chklist';
    clFunk.ElementFont := efCSS;
    clFunk.ElementPosition := epIgnore;
    clFunk.HeightStyle := ssAuto;
    clFunk.HeightPercent := 100.000000000000000000;
    clFunk.WidthStyle := ssAuto;
    clFunk.WidthPercent := 100.000000000000000000;
    WebHTMLDiv59.SetParentComponent(divContent);
    WebHTMLDiv59.Name := 'WebHTMLDiv59';
    WebHTMLDiv59.Left := 784;
    WebHTMLDiv59.Top := 80;
    WebHTMLDiv59.Width := 377;
    WebHTMLDiv59.Height := 472;
    WebHTMLDiv59.ElementClassName := 'profil_container';
    WebHTMLDiv59.HeightStyle := ssAuto;
    WebHTMLDiv59.WidthStyle := ssAuto;
    WebHTMLDiv59.ChildOrder := 5;
    WebHTMLDiv59.ElementPosition := epIgnore;
    WebHTMLDiv59.ElementFont := efCSS;
    WebHTMLDiv59.Role := '';
    WebHTMLDiv60.SetParentComponent(WebHTMLDiv59);
    WebHTMLDiv60.Name := 'WebHTMLDiv60';
    WebHTMLDiv60.Left := 10;
    WebHTMLDiv60.Top := 16;
    WebHTMLDiv60.Width := 345;
    WebHTMLDiv60.Height := 40;
    WebHTMLDiv60.ElementClassName := 'profil_header';
    WebHTMLDiv60.HeightStyle := ssAuto;
    WebHTMLDiv60.WidthStyle := ssAuto;
    WebHTMLDiv60.ElementPosition := epIgnore;
    WebHTMLDiv60.ElementFont := efCSS;
    WebHTMLDiv60.Role := '';
    WebLabel26.SetParentComponent(WebHTMLDiv60);
    WebLabel26.Name := 'WebLabel26';
    WebLabel26.Left := 16;
    WebLabel26.Top := 16;
    WebLabel26.Width := 90;
    WebLabel26.Height := 15;
    WebLabel26.Caption := 'Segelspezifisches';
    WebLabel26.ElementFont := efCSS;
    WebLabel26.ElementPosition := epRelative;
    WebLabel26.HeightStyle := ssAuto;
    WebLabel26.HeightPercent := 100.000000000000000000;
    WebLabel26.WidthStyle := ssAuto;
    WebLabel26.WidthPercent := 100.000000000000000000;
    WebHTMLDiv61.SetParentComponent(WebHTMLDiv59);
    WebHTMLDiv61.Name := 'WebHTMLDiv61';
    WebHTMLDiv61.Left := 10;
    WebHTMLDiv61.Top := 62;
    WebHTMLDiv61.Width := 345;
    WebHTMLDiv61.Height := 40;
    WebHTMLDiv61.ElementClassName := 'profil_chklist_line';
    WebHTMLDiv61.HeightStyle := ssAuto;
    WebHTMLDiv61.WidthStyle := ssAuto;
    WebHTMLDiv61.ChildOrder := 1;
    WebHTMLDiv61.ElementPosition := epIgnore;
    WebHTMLDiv61.ElementFont := efCSS;
    WebHTMLDiv61.Role := '';
    WebLabel27.SetParentComponent(WebHTMLDiv61);
    WebLabel27.Name := 'WebLabel27';
    WebLabel27.Left := 20;
    WebLabel27.Top := 11;
    WebLabel27.Width := 140;
    WebLabel27.Height := 15;
    WebLabel27.Caption := 'Meine Funktionen an Bord';
    WebLabel27.ElementClassName := 'profil_chklist_label';
    WebLabel27.ElementFont := efCSS;
    WebLabel27.ElementPosition := epIgnore;
    WebLabel27.HeightStyle := ssAuto;
    WebLabel27.HeightPercent := 100.000000000000000000;
    WebLabel27.WidthStyle := ssAuto;
    WebLabel27.WidthPercent := 100.000000000000000000;
    WebHTMLDiv62.SetParentComponent(WebHTMLDiv61);
    WebHTMLDiv62.Name := 'WebHTMLDiv62';
    WebHTMLDiv62.Left := 136;
    WebHTMLDiv62.Top := 3;
    WebHTMLDiv62.Width := 190;
    WebHTMLDiv62.Height := 30;
    WebHTMLDiv62.ElementClassName := 'profil_chklist_container';
    WebHTMLDiv62.HeightStyle := ssAuto;
    WebHTMLDiv62.WidthStyle := ssAuto;
    WebHTMLDiv62.ChildOrder := 2;
    WebHTMLDiv62.ElementPosition := epIgnore;
    WebHTMLDiv62.ElementFont := efCSS;
    WebHTMLDiv62.Role := '';
    clFunktion.SetParentComponent(WebHTMLDiv62);
    clFunktion.Name := 'clFunktion';
    clFunktion.Left := 3;
    clFunktion.Top := 3;
    clFunktion.Width := 140;
    clFunktion.Height := 25;
    clFunktion.Columns := 1;
    clFunktion.ItemHeight := 15;
    clFunktion.TabOrder := 0;
    clFunktion.ElementClassName := 'profil_chklist';
    clFunktion.ElementFont := efCSS;
    clFunktion.ElementPosition := epIgnore;
    clFunktion.HeightStyle := ssAuto;
    clFunktion.HeightPercent := 100.000000000000000000;
    clFunktion.WidthStyle := ssAuto;
    clFunktion.WidthPercent := 100.000000000000000000;
    WebHTMLDiv65.SetParentComponent(WebHTMLDiv59);
    WebHTMLDiv65.Name := 'WebHTMLDiv65';
    WebHTMLDiv65.Left := 10;
    WebHTMLDiv65.Top := 108;
    WebHTMLDiv65.Width := 345;
    WebHTMLDiv65.Height := 40;
    WebHTMLDiv65.ElementClassName := 'profil_line';
    WebHTMLDiv65.HeightStyle := ssAuto;
    WebHTMLDiv65.WidthStyle := ssAuto;
    WebHTMLDiv65.ChildOrder := 2;
    WebHTMLDiv65.ElementPosition := epRelative;
    WebHTMLDiv65.ElementFont := efCSS;
    WebHTMLDiv65.Role := '';
    chkEigner.SetParentComponent(WebHTMLDiv65);
    chkEigner.Name := 'chkEigner';
    chkEigner.Left := 32;
    chkEigner.Top := 3;
    chkEigner.Width := 153;
    chkEigner.Height := 22;
    chkEigner.Caption := 'Ich bin Bootseigner';
    chkEigner.ChildOrder := 1;
    chkEigner.ElementLabelClassName := 'profil_label';
    chkEigner.ElementFont := efCSS;
    chkEigner.ElementPosition := epRelative;
    chkEigner.HeightStyle := ssAuto;
    chkEigner.HeightPercent := 100.000000000000000000;
    chkEigner.WidthStyle := ssAuto;
    chkEigner.WidthPercent := 100.000000000000000000;
    WebHTMLDiv66.SetParentComponent(WebHTMLDiv59);
    WebHTMLDiv66.Name := 'WebHTMLDiv66';
    WebHTMLDiv66.Left := 18;
    WebHTMLDiv66.Top := 370;
    WebHTMLDiv66.Width := 345;
    WebHTMLDiv66.Height := 76;
    WebHTMLDiv66.ElementClassName := 'profil_chklist_line';
    WebHTMLDiv66.HeightStyle := ssAuto;
    WebHTMLDiv66.WidthStyle := ssAuto;
    WebHTMLDiv66.ChildOrder := 7;
    WebHTMLDiv66.ElementPosition := epRelative;
    WebHTMLDiv66.ElementFont := efCSS;
    WebHTMLDiv66.Role := '';
    chkVerein.SetParentComponent(WebHTMLDiv66);
    chkVerein.Name := 'chkVerein';
    chkVerein.Left := 40;
    chkVerein.Top := 3;
    chkVerein.Width := 230;
    chkVerein.Height := 22;
    chkVerein.Caption := 'Ich bin Mitglied in einem Segelverein';
    chkVerein.ChildOrder := 1;
    chkVerein.ElementButtonClassName := 'profil_chk_box';
    chkVerein.ElementLabelClassName := 'profil_label';
    chkVerein.ElementFont := efCSS;
    chkVerein.ElementPosition := epRelative;
    chkVerein.HeightStyle := ssAuto;
    chkVerein.HeightPercent := 100.000000000000000000;
    chkVerein.WidthStyle := ssAuto;
    chkVerein.WidthPercent := 100.000000000000000000;
    WebHTMLDiv67.SetParentComponent(WebHTMLDiv66);
    WebHTMLDiv67.Name := 'WebHTMLDiv67';
    WebHTMLDiv67.Left := -10;
    WebHTMLDiv67.Top := 31;
    WebHTMLDiv67.Width := 345;
    WebHTMLDiv67.Height := 40;
    WebHTMLDiv67.ElementClassName := 'profil_line';
    WebHTMLDiv67.HeightStyle := ssAuto;
    WebHTMLDiv67.WidthStyle := ssAuto;
    WebHTMLDiv67.ChildOrder := 1;
    WebHTMLDiv67.ElementPosition := epRelative;
    WebHTMLDiv67.ElementFont := efCSS;
    WebHTMLDiv67.Role := '';
    WebLabel28.SetParentComponent(WebHTMLDiv67);
    WebLabel28.Name := 'WebLabel28';
    WebLabel28.Left := 20;
    WebLabel28.Top := 11;
    WebLabel28.Width := 65;
    WebLabel28.Height := 15;
    WebLabel28.Caption := 'Mein Verein:';
    WebLabel28.ElementClassName := 'profil_label';
    WebLabel28.ElementFont := efCSS;
    WebLabel28.ElementPosition := epIgnore;
    WebLabel28.HeightStyle := ssAuto;
    WebLabel28.HeightPercent := 100.000000000000000000;
    WebLabel28.WidthStyle := ssAuto;
    WebLabel28.WidthPercent := 100.000000000000000000;
    WebHTMLDiv68.SetParentComponent(WebHTMLDiv67);
    WebHTMLDiv68.Name := 'WebHTMLDiv68';
    WebHTMLDiv68.Left := 136;
    WebHTMLDiv68.Top := 3;
    WebHTMLDiv68.Width := 190;
    WebHTMLDiv68.Height := 30;
    WebHTMLDiv68.ElementClassName := 'profil_edit_container';
    WebHTMLDiv68.HeightStyle := ssAuto;
    WebHTMLDiv68.WidthStyle := ssAuto;
    WebHTMLDiv68.ChildOrder := 2;
    WebHTMLDiv68.ElementPosition := epIgnore;
    WebHTMLDiv68.ElementFont := efCSS;
    WebHTMLDiv68.Role := '';
    cbVerein.SetParentComponent(WebHTMLDiv68);
    cbVerein.Name := 'cbVerein';
    cbVerein.Left := 10;
    cbVerein.Top := 3;
    cbVerein.Width := 60;
    cbVerein.Height := 23;
    cbVerein.ChildOrder := 1;
    cbVerein.ElementClassName := 'profil_edit';
    cbVerein.ElementFont := efCSS;
    cbVerein.ElementPosition := epIgnore;
    cbVerein.HeightStyle := ssAuto;
    cbVerein.HeightPercent := 100.000000000000000000;
    cbVerein.WidthStyle := ssAuto;
    cbVerein.WidthPercent := 100.000000000000000000;
    cbVerein.ItemIndex := 0;
    cbVerein.Items.BeginUpdate;
    try
      cbVerein.Items.Clear;
      cbVerein.Items.Add('');
      cbVerein.Items.Add('Herr');
      cbVerein.Items.Add('Frau');
    finally
      cbVerein.Items.EndUpdate;
    end;
    WebHTMLDiv69.SetParentComponent(WebHTMLDiv59);
    WebHTMLDiv69.Name := 'WebHTMLDiv69';
    WebHTMLDiv69.Left := 10;
    WebHTMLDiv69.Top := 154;
    WebHTMLDiv69.Width := 345;
    WebHTMLDiv69.Height := 40;
    WebHTMLDiv69.ElementClassName := 'profil_line';
    WebHTMLDiv69.HeightStyle := ssAuto;
    WebHTMLDiv69.WidthStyle := ssAuto;
    WebHTMLDiv69.ChildOrder := 3;
    WebHTMLDiv69.ElementPosition := epRelative;
    WebHTMLDiv69.ElementFont := efCSS;
    WebHTMLDiv69.Role := '';
    chkCharter.SetParentComponent(WebHTMLDiv69);
    chkCharter.Name := 'chkCharter';
    chkCharter.Left := 32;
    chkCharter.Top := 3;
    chkCharter.Width := 153;
    chkCharter.Height := 22;
    chkCharter.Caption := 'Ich chartere';
    chkCharter.ChildOrder := 1;
    chkCharter.ElementLabelClassName := 'profil_label';
    chkCharter.ElementFont := efCSS;
    chkCharter.ElementPosition := epRelative;
    chkCharter.HeightStyle := ssAuto;
    chkCharter.HeightPercent := 100.000000000000000000;
    chkCharter.WidthStyle := ssAuto;
    chkCharter.WidthPercent := 100.000000000000000000;
    WebHTMLDiv70.SetParentComponent(WebHTMLDiv59);
    WebHTMLDiv70.Name := 'WebHTMLDiv70';
    WebHTMLDiv70.Left := 10;
    WebHTMLDiv70.Top := 200;
    WebHTMLDiv70.Width := 345;
    WebHTMLDiv70.Height := 40;
    WebHTMLDiv70.ElementClassName := 'profil_line';
    WebHTMLDiv70.HeightStyle := ssAuto;
    WebHTMLDiv70.WidthStyle := ssAuto;
    WebHTMLDiv70.ChildOrder := 4;
    WebHTMLDiv70.ElementPosition := epRelative;
    WebHTMLDiv70.ElementFont := efCSS;
    WebHTMLDiv70.Role := '';
    chkEinhand.SetParentComponent(WebHTMLDiv70);
    chkEinhand.Name := 'chkEinhand';
    chkEinhand.Left := 32;
    chkEinhand.Top := 3;
    chkEinhand.Width := 249;
    chkEinhand.Height := 22;
    chkEinhand.Caption := 'Ich bin h'#228'ufig "Einhand" unterwegs';
    chkEinhand.ChildOrder := 1;
    chkEinhand.ElementLabelClassName := 'profil_label';
    chkEinhand.ElementFont := efCSS;
    chkEinhand.ElementPosition := epRelative;
    chkEinhand.HeightStyle := ssAuto;
    chkEinhand.HeightPercent := 100.000000000000000000;
    chkEinhand.WidthStyle := ssAuto;
    chkEinhand.WidthPercent := 100.000000000000000000;
    WebHTMLDiv96.SetParentComponent(WebHTMLDiv59);
    WebHTMLDiv96.Name := 'WebHTMLDiv96';
    WebHTMLDiv96.Left := 10;
    WebHTMLDiv96.Top := 246;
    WebHTMLDiv96.Width := 345;
    WebHTMLDiv96.Height := 40;
    WebHTMLDiv96.ElementClassName := 'profil_line';
    WebHTMLDiv96.HeightStyle := ssAuto;
    WebHTMLDiv96.WidthStyle := ssAuto;
    WebHTMLDiv96.ChildOrder := 5;
    WebHTMLDiv96.ElementPosition := epRelative;
    WebHTMLDiv96.ElementFont := efCSS;
    WebHTMLDiv96.Role := '';
    chkRegatta.SetParentComponent(WebHTMLDiv96);
    chkRegatta.Name := 'chkRegatta';
    chkRegatta.Left := 32;
    chkRegatta.Top := 3;
    chkRegatta.Width := 249;
    chkRegatta.Height := 22;
    chkRegatta.Caption := 'Ich bin Regattasegler';
    chkRegatta.ChildOrder := 1;
    chkRegatta.ElementLabelClassName := 'profil_label';
    chkRegatta.ElementFont := efCSS;
    chkRegatta.ElementPosition := epRelative;
    chkRegatta.HeightStyle := ssAuto;
    chkRegatta.HeightPercent := 100.000000000000000000;
    chkRegatta.WidthStyle := ssAuto;
    chkRegatta.WidthPercent := 100.000000000000000000;
    WebHTMLDiv98.SetParentComponent(WebHTMLDiv59);
    WebHTMLDiv98.Name := 'WebHTMLDiv98';
    WebHTMLDiv98.Left := 10;
    WebHTMLDiv98.Top := 292;
    WebHTMLDiv98.Width := 345;
    WebHTMLDiv98.Height := 40;
    WebHTMLDiv98.ElementClassName := 'profil_line';
    WebHTMLDiv98.HeightStyle := ssAuto;
    WebHTMLDiv98.WidthStyle := ssAuto;
    WebHTMLDiv98.ChildOrder := 6;
    WebHTMLDiv98.ElementPosition := epRelative;
    WebHTMLDiv98.ElementFont := efCSS;
    WebHTMLDiv98.Role := '';
    chkFahrten.SetParentComponent(WebHTMLDiv98);
    chkFahrten.Name := 'chkFahrten';
    chkFahrten.Left := 32;
    chkFahrten.Top := 3;
    chkFahrten.Width := 249;
    chkFahrten.Height := 22;
    chkFahrten.Caption := 'Ich bin Fahrtensegler';
    chkFahrten.ChildOrder := 1;
    chkFahrten.ElementLabelClassName := 'profil_label';
    chkFahrten.ElementFont := efCSS;
    chkFahrten.ElementPosition := epRelative;
    chkFahrten.HeightStyle := ssAuto;
    chkFahrten.HeightPercent := 100.000000000000000000;
    chkFahrten.WidthStyle := ssAuto;
    chkFahrten.WidthPercent := 100.000000000000000000;
    WebHTMLDiv84.SetParentComponent(divContent);
    WebHTMLDiv84.Name := 'WebHTMLDiv84';
    WebHTMLDiv84.Left := 784;
    WebHTMLDiv84.Top := 571;
    WebHTMLDiv84.Width := 377;
    WebHTMLDiv84.Height := 372;
    WebHTMLDiv84.ElementClassName := 'profil_container';
    WebHTMLDiv84.HeightStyle := ssAuto;
    WebHTMLDiv84.WidthStyle := ssAuto;
    WebHTMLDiv84.ChildOrder := 6;
    WebHTMLDiv84.ElementPosition := epIgnore;
    WebHTMLDiv84.ElementFont := efCSS;
    WebHTMLDiv84.Role := '';
    WebHTMLDiv86.SetParentComponent(WebHTMLDiv84);
    WebHTMLDiv86.Name := 'WebHTMLDiv86';
    WebHTMLDiv86.Left := 10;
    WebHTMLDiv86.Top := 16;
    WebHTMLDiv86.Width := 345;
    WebHTMLDiv86.Height := 40;
    WebHTMLDiv86.ElementClassName := 'profil_header';
    WebHTMLDiv86.HeightStyle := ssAuto;
    WebHTMLDiv86.WidthStyle := ssAuto;
    WebHTMLDiv86.ElementPosition := epIgnore;
    WebHTMLDiv86.ElementFont := efCSS;
    WebHTMLDiv86.Role := '';
    WebLabel32.SetParentComponent(WebHTMLDiv86);
    WebLabel32.Name := 'WebLabel32';
    WebLabel32.Left := 16;
    WebLabel32.Top := 16;
    WebLabel32.Width := 107;
    WebLabel32.Height := 15;
    WebLabel32.Caption := 'Benachrichtigungen';
    WebLabel32.ElementFont := efCSS;
    WebLabel32.ElementPosition := epRelative;
    WebLabel32.HeightStyle := ssAuto;
    WebLabel32.HeightPercent := 100.000000000000000000;
    WebLabel32.WidthStyle := ssAuto;
    WebLabel32.WidthPercent := 100.000000000000000000;
    WebHTMLDiv87.SetParentComponent(WebHTMLDiv84);
    WebHTMLDiv87.Name := 'WebHTMLDiv87';
    WebHTMLDiv87.Left := 10;
    WebHTMLDiv87.Top := 62;
    WebHTMLDiv87.Width := 345;
    WebHTMLDiv87.Height := 40;
    WebHTMLDiv87.ElementClassName := 'profil_line';
    WebHTMLDiv87.HeightStyle := ssAuto;
    WebHTMLDiv87.WidthStyle := ssAuto;
    WebHTMLDiv87.ChildOrder := 4;
    WebHTMLDiv87.ElementPosition := epRelative;
    WebHTMLDiv87.ElementFont := efCSS;
    WebHTMLDiv87.Role := '';
    chkInfoGruppen.SetParentComponent(WebHTMLDiv87);
    chkInfoGruppen.Name := 'chkInfoGruppen';
    chkInfoGruppen.Left := 16;
    chkInfoGruppen.Top := 3;
    chkInfoGruppen.Width := 305;
    chkInfoGruppen.Height := 22;
    chkInfoGruppen.Caption := 'bei neuen Gruppen';
    chkInfoGruppen.ChildOrder := 1;
    chkInfoGruppen.ElementLabelClassName := 'profil_label';
    chkInfoGruppen.ElementFont := efCSS;
    chkInfoGruppen.ElementPosition := epRelative;
    chkInfoGruppen.HeightStyle := ssAuto;
    chkInfoGruppen.HeightPercent := 100.000000000000000000;
    chkInfoGruppen.WidthStyle := ssAuto;
    chkInfoGruppen.WidthPercent := 100.000000000000000000;
    WebHTMLDiv88.SetParentComponent(WebHTMLDiv84);
    WebHTMLDiv88.Name := 'WebHTMLDiv88';
    WebHTMLDiv88.Left := 10;
    WebHTMLDiv88.Top := 108;
    WebHTMLDiv88.Width := 345;
    WebHTMLDiv88.Height := 40;
    WebHTMLDiv88.ElementClassName := 'profil_line';
    WebHTMLDiv88.HeightStyle := ssAuto;
    WebHTMLDiv88.WidthStyle := ssAuto;
    WebHTMLDiv88.ChildOrder := 4;
    WebHTMLDiv88.ElementPosition := epRelative;
    WebHTMLDiv88.ElementFont := efCSS;
    WebHTMLDiv88.Role := '';
    chkInfoEvents.SetParentComponent(WebHTMLDiv88);
    chkInfoEvents.Name := 'chkInfoEvents';
    chkInfoEvents.Left := 16;
    chkInfoEvents.Top := 3;
    chkInfoEvents.Width := 249;
    chkInfoEvents.Height := 22;
    chkInfoEvents.Caption := 'bei neuen Veranstaltungen';
    chkInfoEvents.ChildOrder := 1;
    chkInfoEvents.ElementLabelClassName := 'profil_label';
    chkInfoEvents.ElementFont := efCSS;
    chkInfoEvents.ElementPosition := epRelative;
    chkInfoEvents.HeightStyle := ssAuto;
    chkInfoEvents.HeightPercent := 100.000000000000000000;
    chkInfoEvents.WidthStyle := ssAuto;
    chkInfoEvents.WidthPercent := 100.000000000000000000;
    WebHTMLDiv89.SetParentComponent(WebHTMLDiv84);
    WebHTMLDiv89.Name := 'WebHTMLDiv89';
    WebHTMLDiv89.Left := 10;
    WebHTMLDiv89.Top := 154;
    WebHTMLDiv89.Width := 345;
    WebHTMLDiv89.Height := 40;
    WebHTMLDiv89.ElementClassName := 'profil_line';
    WebHTMLDiv89.HeightStyle := ssAuto;
    WebHTMLDiv89.WidthStyle := ssAuto;
    WebHTMLDiv89.ChildOrder := 4;
    WebHTMLDiv89.ElementPosition := epRelative;
    WebHTMLDiv89.ElementFont := efCSS;
    WebHTMLDiv89.Role := '';
    chkInfoUsern.SetParentComponent(WebHTMLDiv89);
    chkInfoUsern.Name := 'chkInfoUsern';
    chkInfoUsern.Left := 16;
    chkInfoUsern.Top := 3;
    chkInfoUsern.Width := 249;
    chkInfoUsern.Height := 22;
    chkInfoUsern.Caption := 'bei neuen Mitgliedern';
    chkInfoUsern.ChildOrder := 1;
    chkInfoUsern.ElementLabelClassName := 'profil_label';
    chkInfoUsern.ElementFont := efCSS;
    chkInfoUsern.ElementPosition := epRelative;
    chkInfoUsern.HeightStyle := ssAuto;
    chkInfoUsern.HeightPercent := 100.000000000000000000;
    chkInfoUsern.WidthStyle := ssAuto;
    chkInfoUsern.WidthPercent := 100.000000000000000000;
    WebHTMLDiv90.SetParentComponent(WebHTMLDiv84);
    WebHTMLDiv90.Name := 'WebHTMLDiv90';
    WebHTMLDiv90.Left := 10;
    WebHTMLDiv90.Top := 200;
    WebHTMLDiv90.Width := 345;
    WebHTMLDiv90.Height := 40;
    WebHTMLDiv90.ElementClassName := 'profil_line';
    WebHTMLDiv90.HeightStyle := ssAuto;
    WebHTMLDiv90.WidthStyle := ssAuto;
    WebHTMLDiv90.ChildOrder := 4;
    WebHTMLDiv90.ElementPosition := epRelative;
    WebHTMLDiv90.ElementFont := efCSS;
    WebHTMLDiv90.Role := '';
    chkInfoKurse.SetParentComponent(WebHTMLDiv90);
    chkInfoKurse.Name := 'chkInfoKurse';
    chkInfoKurse.Left := 16;
    chkInfoKurse.Top := 3;
    chkInfoKurse.Width := 249;
    chkInfoKurse.Height := 22;
    chkInfoKurse.Caption := 'bei neuen Kursen';
    chkInfoKurse.ChildOrder := 1;
    chkInfoKurse.ElementLabelClassName := 'profil_label';
    chkInfoKurse.ElementFont := efCSS;
    chkInfoKurse.ElementPosition := epRelative;
    chkInfoKurse.HeightStyle := ssAuto;
    chkInfoKurse.HeightPercent := 100.000000000000000000;
    chkInfoKurse.WidthStyle := ssAuto;
    chkInfoKurse.WidthPercent := 100.000000000000000000;
    divIntern.SetParentComponent(divContent);
    divIntern.Name := 'divIntern';
    divIntern.Left := 401;
    divIntern.Top := 924;
    divIntern.Width := 377;
    divIntern.Height := 200;
    divIntern.ElementClassName := 'profil_container';
    divIntern.HeightStyle := ssAuto;
    divIntern.WidthStyle := ssAuto;
    divIntern.ChildOrder := 7;
    divIntern.ElementPosition := epIgnore;
    divIntern.ElementFont := efCSS;
    divIntern.Role := '';
    WebHTMLDiv92.SetParentComponent(divIntern);
    WebHTMLDiv92.Name := 'WebHTMLDiv92';
    WebHTMLDiv92.Left := 10;
    WebHTMLDiv92.Top := 16;
    WebHTMLDiv92.Width := 345;
    WebHTMLDiv92.Height := 40;
    WebHTMLDiv92.ElementClassName := 'profil_header';
    WebHTMLDiv92.HeightStyle := ssAuto;
    WebHTMLDiv92.WidthStyle := ssAuto;
    WebHTMLDiv92.ElementPosition := epIgnore;
    WebHTMLDiv92.ElementFont := efCSS;
    WebHTMLDiv92.Role := '';
    WebLabel33.SetParentComponent(WebHTMLDiv92);
    WebLabel33.Name := 'WebLabel33';
    WebLabel33.Left := 16;
    WebLabel33.Top := 16;
    WebLabel33.Width := 42;
    WebLabel33.Height := 15;
    WebLabel33.Caption := 'Internes';
    WebLabel33.ElementFont := efCSS;
    WebLabel33.ElementPosition := epRelative;
    WebLabel33.HeightStyle := ssAuto;
    WebLabel33.HeightPercent := 100.000000000000000000;
    WebLabel33.WidthStyle := ssAuto;
    WebLabel33.WidthPercent := 100.000000000000000000;
    WebHTMLDiv93.SetParentComponent(divIntern);
    WebHTMLDiv93.Name := 'WebHTMLDiv93';
    WebHTMLDiv93.Left := 10;
    WebHTMLDiv93.Top := 62;
    WebHTMLDiv93.Width := 345;
    WebHTMLDiv93.Height := 40;
    WebHTMLDiv93.ElementClassName := 'profil_line';
    WebHTMLDiv93.HeightStyle := ssAuto;
    WebHTMLDiv93.WidthStyle := ssAuto;
    WebHTMLDiv93.ChildOrder := 4;
    WebHTMLDiv93.ElementPosition := epRelative;
    WebHTMLDiv93.ElementFont := efCSS;
    WebHTMLDiv93.Role := '';
    chkAdmin.SetParentComponent(WebHTMLDiv93);
    chkAdmin.Name := 'chkAdmin';
    chkAdmin.Left := 16;
    chkAdmin.Top := 3;
    chkAdmin.Width := 305;
    chkAdmin.Height := 22;
    chkAdmin.Caption := 'Aministrator';
    chkAdmin.ChildOrder := 1;
    chkAdmin.ElementLabelClassName := 'profil_label';
    chkAdmin.ElementFont := efCSS;
    chkAdmin.ElementPosition := epRelative;
    chkAdmin.HeightStyle := ssAuto;
    chkAdmin.HeightPercent := 100.000000000000000000;
    chkAdmin.WidthStyle := ssAuto;
    chkAdmin.WidthPercent := 100.000000000000000000;
    WebHTMLDiv94.SetParentComponent(divIntern);
    WebHTMLDiv94.Name := 'WebHTMLDiv94';
    WebHTMLDiv94.Left := 10;
    WebHTMLDiv94.Top := 108;
    WebHTMLDiv94.Width := 345;
    WebHTMLDiv94.Height := 40;
    WebHTMLDiv94.ElementClassName := 'profil_line';
    WebHTMLDiv94.HeightStyle := ssAuto;
    WebHTMLDiv94.WidthStyle := ssAuto;
    WebHTMLDiv94.ChildOrder := 4;
    WebHTMLDiv94.ElementPosition := epRelative;
    WebHTMLDiv94.ElementFont := efCSS;
    WebHTMLDiv94.Role := '';
    chkTrainer.SetParentComponent(WebHTMLDiv94);
    chkTrainer.Name := 'chkTrainer';
    chkTrainer.Left := 16;
    chkTrainer.Top := 3;
    chkTrainer.Width := 249;
    chkTrainer.Height := 22;
    chkTrainer.Caption := 'Trainer';
    chkTrainer.ChildOrder := 1;
    chkTrainer.ElementLabelClassName := 'profil_label';
    chkTrainer.ElementFont := efCSS;
    chkTrainer.ElementPosition := epRelative;
    chkTrainer.HeightStyle := ssAuto;
    chkTrainer.HeightPercent := 100.000000000000000000;
    chkTrainer.WidthStyle := ssAuto;
    chkTrainer.WidthPercent := 100.000000000000000000;
    WebHTMLDiv91.SetParentComponent(divIntern);
    WebHTMLDiv91.Name := 'WebHTMLDiv91';
    WebHTMLDiv91.Left := 10;
    WebHTMLDiv91.Top := 154;
    WebHTMLDiv91.Width := 345;
    WebHTMLDiv91.Height := 40;
    WebHTMLDiv91.ElementClassName := 'profil_line';
    WebHTMLDiv91.HeightStyle := ssAuto;
    WebHTMLDiv91.WidthStyle := ssAuto;
    WebHTMLDiv91.ChildOrder := 4;
    WebHTMLDiv91.ElementPosition := epRelative;
    WebHTMLDiv91.ElementFont := efCSS;
    WebHTMLDiv91.Role := '';
    chkOrganizer.SetParentComponent(WebHTMLDiv91);
    chkOrganizer.Name := 'chkOrganizer';
    chkOrganizer.Left := 16;
    chkOrganizer.Top := 3;
    chkOrganizer.Width := 249;
    chkOrganizer.Height := 22;
    chkOrganizer.Caption := 'Organisator';
    chkOrganizer.ChildOrder := 1;
    chkOrganizer.ElementLabelClassName := 'profil_label';
    chkOrganizer.ElementFont := efCSS;
    chkOrganizer.ElementPosition := epRelative;
    chkOrganizer.HeightStyle := ssAuto;
    chkOrganizer.HeightPercent := 100.000000000000000000;
    chkOrganizer.WidthStyle := ssAuto;
    chkOrganizer.WidthPercent := 100.000000000000000000;
    WebHTMLDiv17.SetParentComponent(divContent);
    WebHTMLDiv17.Name := 'WebHTMLDiv17';
    WebHTMLDiv17.Left := 20;
    WebHTMLDiv17.Top := 1058;
    WebHTMLDiv17.Width := 574;
    WebHTMLDiv17.Height := 41;
    WebHTMLDiv17.ElementClassName := 'editor-footer-div';
    WebHTMLDiv17.HeightStyle := ssAuto;
    WebHTMLDiv17.WidthStyle := ssAuto;
    WebHTMLDiv17.ChildOrder := 50;
    WebHTMLDiv17.ElementPosition := epIgnore;
    WebHTMLDiv17.ElementFont := efCSS;
    WebHTMLDiv17.Role := '';
    btnPersSave.SetParentComponent(WebHTMLDiv17);
    btnPersSave.Name := 'btnPersSave';
    btnPersSave.Left := 277;
    btnPersSave.Top := 0;
    btnPersSave.Width := 144;
    btnPersSave.Height := 25;
    btnPersSave.Caption := 'Speichern';
    btnPersSave.ChildOrder := 1;
    btnPersSave.ElementClassName := 'start_button-lets-go';
    btnPersSave.ElementFont := efCSS;
    btnPersSave.ElementPosition := epIgnore;
    btnPersSave.HeightStyle := ssAuto;
    btnPersSave.HeightPercent := 100.000000000000000000;
    btnPersSave.WidthStyle := ssAuto;
    btnPersSave.WidthPercent := 100.000000000000000000;
    SetEvent(btnPersSave, Self, 'OnClick', 'btnPersSaveClick');
    btnPersCancel.SetParentComponent(WebHTMLDiv17);
    btnPersCancel.Name := 'btnPersCancel';
    btnPersCancel.Left := 427;
    btnPersCancel.Top := 3;
    btnPersCancel.Width := 144;
    btnPersCancel.Height := 25;
    btnPersCancel.Caption := 'Verwerfen';
    btnPersCancel.ChildOrder := 2;
    btnPersCancel.ElementClassName := 'start_button-lets-go';
    btnPersCancel.ElementFont := efCSS;
    btnPersCancel.ElementPosition := epIgnore;
    btnPersCancel.HeightStyle := ssAuto;
    btnPersCancel.HeightPercent := 100.000000000000000000;
    btnPersCancel.WidthStyle := ssAuto;
    btnPersCancel.WidthPercent := 100.000000000000000000;
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 147;
    Client.Top := 19;
  finally
    divContent.AfterLoadDFMValues;
    divHeader.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    WebHTMLDiv1.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebHTMLDiv8.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebHTMLDiv34.AfterLoadDFMValues;
    cbAnrede.AfterLoadDFMValues;
    WebHTMLDiv19.AfterLoadDFMValues;
    cbShowAnrede.AfterLoadDFMValues;
    WebHTMLDiv3.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebHTMLDiv4.AfterLoadDFMValues;
    edName.AfterLoadDFMValues;
    WebHTMLDiv25.AfterLoadDFMValues;
    cbShowName.AfterLoadDFMValues;
    WebHTMLDiv5.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebHTMLDiv6.AfterLoadDFMValues;
    edVorname.AfterLoadDFMValues;
    WebHTMLDiv26.AfterLoadDFMValues;
    cbShowVorname.AfterLoadDFMValues;
    WebHTMLDiv7.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebHTMLDiv9.AfterLoadDFMValues;
    cbLand.AfterLoadDFMValues;
    WebHTMLDiv36.AfterLoadDFMValues;
    cbShowLand.AfterLoadDFMValues;
    WebHTMLDiv10.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebHTMLDiv37.AfterLoadDFMValues;
    cbBLand.AfterLoadDFMValues;
    WebHTMLDiv35.AfterLoadDFMValues;
    cbShowBLand.AfterLoadDFMValues;
    WebHTMLDiv38.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebHTMLDiv39.AfterLoadDFMValues;
    edStrasse.AfterLoadDFMValues;
    WebHTMLDiv45.AfterLoadDFMValues;
    cbShowStrasse.AfterLoadDFMValues;
    WebHTMLDiv40.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebHTMLDiv41.AfterLoadDFMValues;
    edPLZ.AfterLoadDFMValues;
    WebHTMLDiv46.AfterLoadDFMValues;
    cbShowPLZ.AfterLoadDFMValues;
    WebHTMLDiv42.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebHTMLDiv43.AfterLoadDFMValues;
    edOrt.AfterLoadDFMValues;
    WebHTMLDiv63.AfterLoadDFMValues;
    cbShowOrt.AfterLoadDFMValues;
    WebHTMLDiv54.AfterLoadDFMValues;
    WebLabel24.AfterLoadDFMValues;
    WebHTMLDiv55.AfterLoadDFMValues;
    edGebDatum.AfterLoadDFMValues;
    WebHTMLDiv64.AfterLoadDFMValues;
    cbShowGebDatum.AfterLoadDFMValues;
    WebHTMLDiv21.AfterLoadDFMValues;
    WebLabel14.AfterLoadDFMValues;
    WebHTMLDiv22.AfterLoadDFMValues;
    WebLabel15.AfterLoadDFMValues;
    WebHTMLDiv50.AfterLoadDFMValues;
    edEMAil.AfterLoadDFMValues;
    WebHTMLDiv71.AfterLoadDFMValues;
    cbShowEMail.AfterLoadDFMValues;
    WebHTMLDiv82.AfterLoadDFMValues;
    WebLabel30.AfterLoadDFMValues;
    WebHTMLDiv83.AfterLoadDFMValues;
    edPasswort1.AfterLoadDFMValues;
    WebHTMLDiv79.AfterLoadDFMValues;
    WebLabel29.AfterLoadDFMValues;
    WebHTMLDiv80.AfterLoadDFMValues;
    edPasswort2.AfterLoadDFMValues;
    WebHTMLDiv23.AfterLoadDFMValues;
    WebLabel16.AfterLoadDFMValues;
    WebHTMLDiv51.AfterLoadDFMValues;
    edTelefon.AfterLoadDFMValues;
    WebHTMLDiv72.AfterLoadDFMValues;
    cbShowTelefon.AfterLoadDFMValues;
    WebHTMLDiv24.AfterLoadDFMValues;
    WebLabel17.AfterLoadDFMValues;
    WebHTMLDiv52.AfterLoadDFMValues;
    edWebSeite.AfterLoadDFMValues;
    WebHTMLDiv73.AfterLoadDFMValues;
    cbShowWebseite.AfterLoadDFMValues;
    WebHTMLDiv81.AfterLoadDFMValues;
    WebLabel31.AfterLoadDFMValues;
    WebHTMLDiv85.AfterLoadDFMValues;
    cbFreundschaft.AfterLoadDFMValues;
    WebHTMLDiv95.AfterLoadDFMValues;
    WebCheckBox1.AfterLoadDFMValues;
    WebHTMLDiv16.AfterLoadDFMValues;
    WebLabel34.AfterLoadDFMValues;
    WebHTMLDiv97.AfterLoadDFMValues;
    cbShowFreunde.AfterLoadDFMValues;
    WebHTMLDiv11.AfterLoadDFMValues;
    WebHTMLDiv12.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebHTMLDiv13.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebHTMLDiv44.AfterLoadDFMValues;
    edNickname.AfterLoadDFMValues;
    WebHTMLDiv78.AfterLoadDFMValues;
    cbShowNickname.AfterLoadDFMValues;
    WebHTMLDiv14.AfterLoadDFMValues;
    WebLabel12.AfterLoadDFMValues;
    divWrapper.AfterLoadDFMValues;
    imgAvatar.AfterLoadDFMValues;
    WebHTMLDiv27.AfterLoadDFMValues;
    WebHTMLDiv99.AfterLoadDFMValues;
    WebLabel18.AfterLoadDFMValues;
    WebHTMLDiv74.AfterLoadDFMValues;
    cbShowAbout.AfterLoadDFMValues;
    edAbout.AfterLoadDFMValues;
    WebHTMLDiv28.AfterLoadDFMValues;
    WebHTMLDiv47.AfterLoadDFMValues;
    WebLabel19.AfterLoadDFMValues;
    spHobbies.AfterLoadDFMValues;
    WebHTMLDiv75.AfterLoadDFMValues;
    cbShowHobbies.AfterLoadDFMValues;
    divHobbies.AfterLoadDFMValues;
    WebHTMLDiv15.AfterLoadDFMValues;
    WebLabel13.AfterLoadDFMValues;
    WebHTMLDiv18.AfterLoadDFMValues;
    imgTitel.AfterLoadDFMValues;
    WebHTMLDiv29.AfterLoadDFMValues;
    WebHTMLDiv48.AfterLoadDFMValues;
    WebLabel20.AfterLoadDFMValues;
    spReviere.AfterLoadDFMValues;
    WebHTMLDiv49.AfterLoadDFMValues;
    cbShowReviere.AfterLoadDFMValues;
    divReviere.AfterLoadDFMValues;
    WebHTMLDiv58.AfterLoadDFMValues;
    WebHTMLDiv76.AfterLoadDFMValues;
    WebLabel25.AfterLoadDFMValues;
    spExpertise.AfterLoadDFMValues;
    WebHTMLDiv77.AfterLoadDFMValues;
    cbShowExpertise.AfterLoadDFMValues;
    divExpertise.AfterLoadDFMValues;
    WebHTMLDiv30.AfterLoadDFMValues;
    WebHTMLDiv31.AfterLoadDFMValues;
    WebLabel21.AfterLoadDFMValues;
    WebHTMLDiv33.AfterLoadDFMValues;
    WebLabel23.AfterLoadDFMValues;
    WebHTMLDiv56.AfterLoadDFMValues;
    clScheine.AfterLoadDFMValues;
    WebHTMLDiv32.AfterLoadDFMValues;
    WebLabel22.AfterLoadDFMValues;
    WebHTMLDiv53.AfterLoadDFMValues;
    clFunk.AfterLoadDFMValues;
    WebHTMLDiv59.AfterLoadDFMValues;
    WebHTMLDiv60.AfterLoadDFMValues;
    WebLabel26.AfterLoadDFMValues;
    WebHTMLDiv61.AfterLoadDFMValues;
    WebLabel27.AfterLoadDFMValues;
    WebHTMLDiv62.AfterLoadDFMValues;
    clFunktion.AfterLoadDFMValues;
    WebHTMLDiv65.AfterLoadDFMValues;
    chkEigner.AfterLoadDFMValues;
    WebHTMLDiv66.AfterLoadDFMValues;
    chkVerein.AfterLoadDFMValues;
    WebHTMLDiv67.AfterLoadDFMValues;
    WebLabel28.AfterLoadDFMValues;
    WebHTMLDiv68.AfterLoadDFMValues;
    cbVerein.AfterLoadDFMValues;
    WebHTMLDiv69.AfterLoadDFMValues;
    chkCharter.AfterLoadDFMValues;
    WebHTMLDiv70.AfterLoadDFMValues;
    chkEinhand.AfterLoadDFMValues;
    WebHTMLDiv96.AfterLoadDFMValues;
    chkRegatta.AfterLoadDFMValues;
    WebHTMLDiv98.AfterLoadDFMValues;
    chkFahrten.AfterLoadDFMValues;
    WebHTMLDiv84.AfterLoadDFMValues;
    WebHTMLDiv86.AfterLoadDFMValues;
    WebLabel32.AfterLoadDFMValues;
    WebHTMLDiv87.AfterLoadDFMValues;
    chkInfoGruppen.AfterLoadDFMValues;
    WebHTMLDiv88.AfterLoadDFMValues;
    chkInfoEvents.AfterLoadDFMValues;
    WebHTMLDiv89.AfterLoadDFMValues;
    chkInfoUsern.AfterLoadDFMValues;
    WebHTMLDiv90.AfterLoadDFMValues;
    chkInfoKurse.AfterLoadDFMValues;
    divIntern.AfterLoadDFMValues;
    WebHTMLDiv92.AfterLoadDFMValues;
    WebLabel33.AfterLoadDFMValues;
    WebHTMLDiv93.AfterLoadDFMValues;
    chkAdmin.AfterLoadDFMValues;
    WebHTMLDiv94.AfterLoadDFMValues;
    chkTrainer.AfterLoadDFMValues;
    WebHTMLDiv91.AfterLoadDFMValues;
    chkOrganizer.AfterLoadDFMValues;
    WebHTMLDiv17.AfterLoadDFMValues;
    btnPersSave.AfterLoadDFMValues;
    btnPersCancel.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.

