unit ufrFotosThumb;

interface

uses
  System.SysUtils, System.Variants, System.Classes,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Web, JS, WEBLib.WebCtrls,
  Vcl.Imaging.jpeg, WEBLib.Controls, WEBLib.ExtCtrls, utb,
  WEBLib.Graphics, WEBLib.Forms, Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TfrFotosThumb = class(TFrame)
    img: TImageControl;
    divMarked: THTMLDiv;
    Marked: TCheckBox;
    procedure imgClick(Sender: TObject);
    procedure MarkedClick(Sender: TObject);
  protected
    procedure Loaded(); override;

  private
    FVorschau,
    FID,
    FHolder : integer;
    FImageClick:TCallback;
  public
    property Id : integer read FId write FId;
    property Holder : integer read FHolder write FHolder;
    [async] procedure Init(AHolder:integer); async;
    [async]class function Add(AParent:TCustomControl; AID:integer; AMedia:string; AImageClick:TCallBack; AShowMarked:boolean):TfrFotosThumb; async;
    property Vorschau: integer read FVorschau write FVorschau;
  protected procedure LoadDFMValues; override; end;

implementation
   Uses uDatenmodul, uMain;

{$R *.dfm}

class function TfrFotosThumb.Add(AParent:TCustomControl; AID:integer; AMedia:string; AImageClick:TCallBack;AShowMarked:boolean):TfrFotosThumb;
Var
  el: TJSHTMLElement;
  f: TfrFotosThumb;
begin
  f := TfrFotosThumb.Create(AParent.owner);
  f.LoadFromForm;
  f.parent            := AParent;
  f.ID                := AId;
  f.img.url           := AMedia;
  f.FImageClick       := AImageClick;
  f.divMarked.Visible := AShowMarked;

  TControl(f).elementPosition := epIgnore;
  el := TJSHTMLElement(THTMLDiv(f).ElementHandle);
  el.style.setProperty('position','relative');
  el.style.setProperty('overflow','');
  el.style.setProperty('width','');
  el.style.setProperty('height','');
  el.style.removeProperty('width');
  el.style.removeProperty('height');

  result := f;
end;

procedure TfrFotosThumb.imgClick(Sender: TObject);
begin
  if assigned(FImageClick)
  then FImageClick(FId.ToString);
end;

procedure TfrFotosThumb.Init(AHolder : integer);
begin
//
end;

procedure TfrFotosThumb.Loaded();
var
  el: TJSHTMLElement;
begin
  inherited;
  TControl(self).ElementPosition := epIgnore;
//  TWebControl(self).ElementID := 'frTest';
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.setProperty('height','');
  el.style.setProperty('width','');
  el.style.setProperty('font-family','');
  el.style.setProperty('font-style','');
  el.style.setProperty('font-size','');
end;
procedure TfrFotosThumb.MarkedClick(Sender: TObject);
begin
  //meldung(FId.ToString );
end;

procedure TfrFotosThumb.LoadDFMValues;
begin
  inherited LoadDFMValues;

  img := TImageControl.Create(Self);
  divMarked := THTMLDiv.Create(Self);
  Marked := TCheckBox.Create(Self);

  img.BeforeLoadDFMValues;
  divMarked.BeforeLoadDFMValues;
  Marked.BeforeLoadDFMValues;
  try
    Name := 'frFotosThumb';
    Left := 0;
    Top := 0;
    Width := 200;
    Height := 144;
    TabOrder := 0;
    img.SetParentComponent(Self);
    img.Name := 'img';
    img.Left := 3;
    img.Top := 3;
    img.Width := 189;
    img.Height := 134;
    img.ElementClassName := 'fotos_foto';
    img.HeightStyle := ssAuto;
    img.WidthStyle := ssAuto;
    img.HeightPercent := 100.000000000000000000;
    img.WidthPercent := 100.000000000000000000;
    img.ChildOrder := 1;
    img.ElementFont := efCSS;
    img.ElementPosition := epIgnore;
    SetEvent(img, Self, 'OnClick', 'imgClick');
    divMarked.SetParentComponent(Self);
    divMarked.Name := 'divMarked';
    divMarked.Left := 152;
    divMarked.Top := 3;
    divMarked.Width := 40;
    divMarked.Height := 134;
    divMarked.ElementClassName := 'medien-auswahl';
    divMarked.WidthStyle := ssAuto;
    divMarked.ChildOrder := 1;
    divMarked.ElementPosition := epIgnore;
    divMarked.ElementFont := efCSS;
    divMarked.Role := '';
    divMarked.Visible := False;
    Marked.SetParentComponent(divMarked);
    Marked.Name := 'Marked';
    Marked.Left := 8;
    Marked.Top := 8;
    Marked.Width := 113;
    Marked.Height := 22;
    Marked.ElementClassName := 'auswahl-checkbox';
    Marked.ElementFont := efCSS;
    Marked.ElementPosition := epIgnore;
    Marked.HeightStyle := ssAuto;
    Marked.HeightPercent := 100.000000000000000000;
    Marked.WidthStyle := ssAuto;
    Marked.WidthPercent := 100.000000000000000000;
    SetEvent(Marked, Self, 'OnClick', 'MarkedClick');
  finally
    img.AfterLoadDFMValues;
    divMarked.AfterLoadDFMValues;
    Marked.AfterLoadDFMValues;
  end;
end;

end.
