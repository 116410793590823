unit ufrVideosThumb;

interface

uses
  System.SysUtils, System.Variants, System.Classes,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Web, JS, WEBLib.WebCtrls,
  Vcl.Imaging.jpeg, WEBLib.Controls, WEBLib.ExtCtrls,WEBLib.Graphics, WEBLib.Forms,
  uTB, Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TfrVideosThumb = class(TFrame)
    divContainer: THTMLDiv;
    img: TImageControl;
    divMarked: THTMLDiv;
    Marked: TCheckBox;
    procedure imgClick(Sender: TObject);
  protected
    procedure Loaded(); override;

  private
    FID,
    FHolder : integer;
    FImageClick:TCallback;
  public
    property Id : integer read FId write FId;
    property Holder : integer read FHolder write FHolder;
    [async] procedure Init(AHolder:integer); async;
    [async]class function Add(AParent:TCustomControl; AID:integer; AImageClick:TCallBack;AShowMarked:boolean):TfrVideosThumb; async;
  protected procedure LoadDFMValues; override; end;

implementation
   Uses uDatenmodul, uMain;//, uMedien;

{$R *.dfm}

class function TfrVideosThumb.Add(AParent:TCustomControl; AID:integer; AImageClick:TCallBack;AShowMarked:boolean):TfrVideosThumb;
Var
  el: TJSHTMLElement;
  f: TfrVideosThumb;
  s,s1:string;
  d:THtmlDiv;
begin
  f := TfrVideosThumb.Create(AParent.owner);
  f.LoadFromForm;

  f.parent            := AParent;
  f.ID                := AId;
  f.FImageClick       := AImageClick;
  f.divMarked.Visible := AShowMarked;

  TControl(f).elementPosition := epIgnore;

  el := TJSHTMLElement(THTMLDiv(f).ElementHandle);
  el.style.setProperty('overflow','');
  el.style.setProperty('width','');
  el.style.setProperty('height','');
  el.style.removeProperty('width');
  el.style.removeProperty('height');
  el.style.removeProperty('position');

  s := await(Datenmodul.GetMedia(AID, _SIZE2));
  f.img.url:= s;//GetYoutubeThumb(s);

  result := f;

end;

procedure TfrVideosThumb.imgClick(Sender: TObject);
//Var
//  f:TWebForm;
begin
//  f := TWebForm(TWebcontrol(sender).owner.owner.owner);
//thb  TfrmMedien(f).showVideo(FId);
  if assigned(FImageClick)
  then FImageClick(FId.ToString);

end;

procedure TfrVideosThumb.Init(AHolder : integer);
begin
//
end;

procedure TfrVideosThumb.Loaded();
var
  el: TJSHTMLElement;
begin
  inherited;
  TControl(self).ElementPosition := epIgnore;
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.setProperty('height','');
  el.style.setProperty('width','');
  el.style.setProperty('font-family','');
  el.style.setProperty('font-style','');
  el.style.setProperty('font-size','');
  el.style.removeProperty('position');

end;

procedure TfrVideosThumb.LoadDFMValues;
begin
  inherited LoadDFMValues;

  img := TImageControl.Create(Self);
  divContainer := THTMLDiv.Create(Self);
  divMarked := THTMLDiv.Create(Self);
  Marked := TCheckBox.Create(Self);

  img.BeforeLoadDFMValues;
  divContainer.BeforeLoadDFMValues;
  divMarked.BeforeLoadDFMValues;
  Marked.BeforeLoadDFMValues;
  try
    Name := 'frVideosThumb';
    Left := 0;
    Top := 0;
    Width := 210;
    Height := 155;
    TabOrder := 0;
    img.SetParentComponent(Self);
    img.Name := 'img';
    img.Left := 3;
    img.Top := 3;
    img.Width := 189;
    img.Height := 134;
    img.ElementClassName := 'fotos_foto';
    img.HeightStyle := ssAuto;
    img.WidthStyle := ssAuto;
    img.HeightPercent := 100.000000000000000000;
    img.WidthPercent := 100.000000000000000000;
    img.ChildOrder := 1;
    img.ElementFont := efCSS;
    img.ElementPosition := epIgnore;
    SetEvent(img, Self, 'OnClick', 'imgClick');
    divContainer.SetParentComponent(Self);
    divContainer.Name := 'divContainer';
    divContainer.Left := 496;
    divContainer.Top := 536;
    divContainer.Width := 200;
    divContainer.Height := 145;
    divContainer.ElementClassName := 'video_content';
    divContainer.HeightStyle := ssAuto;
    divContainer.WidthStyle := ssAuto;
    divContainer.ChildOrder := 1;
    divContainer.ElementPosition := epIgnore;
    divContainer.ElementFont := efCSS;
    divContainer.Role := '';
    divContainer.Visible := False;
    SetEvent(divContainer, Self, 'OnClick', 'imgClick');
    divMarked.SetParentComponent(Self);
    divMarked.Name := 'divMarked';
    divMarked.Left := 152;
    divMarked.Top := 3;
    divMarked.Width := 40;
    divMarked.Height := 134;
    divMarked.ElementClassName := 'medien-auswahl';
    divMarked.WidthStyle := ssAuto;
    divMarked.ChildOrder := 1;
    divMarked.ElementPosition := epIgnore;
    divMarked.ElementFont := efCSS;
    divMarked.Role := '';
    divMarked.Visible := False;
    Marked.SetParentComponent(divMarked);
    Marked.Name := 'Marked';
    Marked.Left := 8;
    Marked.Top := 8;
    Marked.Width := 113;
    Marked.Height := 22;
    Marked.ElementClassName := 'auswahl-checkbox';
    Marked.ElementFont := efCSS;
    Marked.ElementPosition := epIgnore;
    Marked.HeightStyle := ssAuto;
    Marked.HeightPercent := 100.000000000000000000;
    Marked.WidthStyle := ssAuto;
    Marked.WidthPercent := 100.000000000000000000;
  finally
    img.AfterLoadDFMValues;
    divContainer.AfterLoadDFMValues;
    divMarked.AfterLoadDFMValues;
    Marked.AfterLoadDFMValues;
  end;
end;

end.
