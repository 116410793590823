unit uGruppenEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.WebCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Imaging.pngimage, WEBLib.ExtCtrls, XData.Web.Client,
  Vcl.Forms, ufrFotosListe, WEBLib.ComCtrls, utb, uGlobal, ufrVideoListe;

type
  TfrmGruppenEdit = class(TForm)
    WebHTMLDiv1: THTMLDiv;
    WebHTMLDiv11: THTMLDiv;
    btnClose: TImageControl;
    lblTitel: TLabel;
    Client: TXDataWebClient;
    WebHTMLDiv3: THTMLDiv;
    WebHTMLDiv14: THTMLDiv;
    img1: TImageControl;
    WebHTMLDiv10: THTMLDiv;
    divTitel: THTMLDiv;
    reTitel: TRichEdit;
    WebHTMLDiv13: THTMLDiv;
    reText: TRichEdit;
    WebHTMLDiv8: THTMLDiv;
    frFotosliste1: TfrFotosliste;
    WebHTMLDiv17: THTMLDiv;
    frVideoListe1: TfrVideoListe;
    WebHTMLDiv7: THTMLDiv;
    WebHTMLDiv2: THTMLDiv;
    WebHTMLDiv5: THTMLDiv;
    WebHTMLDiv6: THTMLDiv;
    cbStatus: TComboBox;
    WebHTMLDiv4: THTMLDiv;
    btnSave: TButton;
    btnCancel: TButton;
    WebHTMLDiv9: THTMLDiv;
    WebHTMLDiv67: THTMLDiv;
    WebLabel28: TLabel;
    WebHTMLDiv68: THTMLDiv;
    cbZweck: TComboBox;
    WebHTMLDiv12: THTMLDiv;
    btnWelcomePost: TButton;
    btnSA: TButton;
    WebButton2: TButton;
    WebButton3: TButton;
    WebButton4: TButton;
    WebHTMLDiv19: THTMLDiv;
    WebHTMLDiv20: THTMLDiv;
    WebHTMLDiv21: THTMLDiv;
    edVon: TDateTimePicker;
    WebHTMLDiv22: THTMLDiv;
    edBis: TDateTimePicker;
    WebButton1: TButton;
    WebButton5: TButton;
    procedure WebFormCreate(Sender: TObject);
    [async] procedure btnSaveClick(Sender: TObject); async;
    [async] procedure btnCloseClick(Sender: TObject); async;
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure btnWelcomePostClick(Sender: TObject);
    procedure img1Click(Sender: TObject);
    [async] procedure btnSAClick(Sender: TObject); async;
    [async] procedure WebButton1Click(Sender: TObject); async;
    procedure WebButton5Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
  private
    FTitelbild:integer;
    FCallback:TCallback;
    FContainer:THtmlDiv;
    FHolder, FUser, FAlbum,
    FID, FNewID:integer;
    procedure SetId(Value:integer);
    procedure SetTitelbild(Value:integer);
//    [async] procedure GetItem(AID:integer);
//    [async] procedure FillCombobox(Acb:TWebComboBox; AID:integer);
  public
    [async] procedure SetTitelFoto(s:string); async;
    property ID:integer read FID write SetID;
    property Titelbild:integer read FTitelbild write SetTitelbild;
    property Container:THtmlDiv read FContainer write FContainer;
    class function Exec(AID, ATyp:integer; AHolder, AUser, AAlbum:integer; ACallback:TCallback):integer;
  protected procedure LoadDFMValues; override; end;

var
  frmGruppenEdit: TfrmGruppenEdit;

implementation
  uses uDatenmodul, uMain, uMedienAuswahl, uTextEdit, uSelection;

{$R *.dfm}

class function TfrmGruppenEdit.Exec(AID, ATyp:integer; AHolder, AUser, AAlbum:integer; ACallback:TCallback): integer;

  [async] procedure AfterCreate(AForm: TObject); async;
  Var
    Response: TXDataClientResponse;
    J:TJ;
  begin
    TfrmGruppenEdit(AForm).ElementHandle.style.removeProperty('position');
    TfrmGruppenEdit(AForm).Parent := Mainform;

    TfrmGruppenEdit(AForm).FHolder:= AHolder;
    TfrmGruppenEdit(AForm).FUser  := AUser;
    TfrmGruppenEdit(AForm).FAlbum := AAlbum;

    if ATyp = _UT_Gruppe
    then TfrmGruppenEdit(AForm).lblTitel.Caption := 'GRUPPE BEARBEITEN'
    else TfrmGruppenEdit(AForm).lblTitel.Caption := 'VERANSTALTUNG BEARBEITEN';

    TfrmGruppenEdit(AForm).FNewID := 0;

    tbFillCombobox(TfrmGruppenEdit(AForm).cbZweck, 'ID','TEXT',await(datenmodul.EasySQL('Select ID, TEXT from Zweck')));
    tbFillCombobox(TfrmGruppenEdit(AForm).cbStatus, 'ID','TEXT',await(datenmodul.EasySQL('Select ID, TEXT from Status')));

    if AID > 0
    then begin
      J := TJ.create(await(datenmodul.EasySQL('SELECT * from v_User where ID = ' + AID.ToString)));
      TfrmGruppenEdit(AForm).reTitel.lines.Text := J.Value('NICKNAME');
      TfrmGruppenEdit(AForm).reText.lines.Text  := J.Value('ABOUT');
      TfrmGruppenEdit(AForm).ID                 := J.integer('ID');
      TfrmGruppenEdit(AForm).Titelbild          := j.integer('TITEL_MEDIEN_ID');

      if j.Value('VON') > '' then TfrmGruppenEdit(AForm).edVon.Date := strtodate(j.Date2Str('VON'));
      if j.Value('BIS') > '' then TfrmGruppenEdit(AForm).edBis.Date := strtodate(j.Date2Str('BIS'));

      //Gruppenart für...
      TfrmGruppenEdit(AForm).cbZweck.ItemIndex := GetComboIndex(TfrmGruppenEdit(AForm).cbZweck, j.Value('KAT_ID'));
      //Sichtbar für...
      TfrmGruppenEdit(AForm).cbStatus.ItemIndex := GetComboIndex(TfrmGruppenEdit(AForm).cbStatus, j.Value('STATUS_ID'));

    end
    else begin
      Response := Await(
                   TfrmGruppenEdit(AForm).Client.RawInvokeAsync( 'IDBService.GruppenNew', [ATyp, TfrmGruppenEdit(AForm).FUser, '', '', -1]));

      TfrmGruppenEdit(AForm).ID := TJ.create(Response).Integer('ID');
      TfrmGruppenEdit(AForm).FNewID := TfrmGruppenEdit(AForm).ID;

      //Sichtbar für...
      TfrmGruppenEdit(AForm).cbStatus.ItemIndex := 1; // ...öffentlich
      //Zweck der Gruppe...
      TfrmGruppenEdit(AForm).cbZweck.ItemIndex := 0; //default- zwingt User zur Auswahl
    end;

    TfrmGruppenEdit(AForm).frVideoliste1.Init(TfrmGruppenEdit(AForm).FHolder, TfrmGruppenEdit(AForm).FUser, TfrmGruppenEdit(AForm).FAlbum, 0,0, 0, 0, -1, nil, false);
    TfrmGruppenEdit(AForm).frFotosliste1.Init(TfrmGruppenEdit(AForm).FHolder, TfrmGruppenEdit(AForm).FUser, TfrmGruppenEdit(AForm).FAlbum, 0,0, 0, TfrmGruppenEdit(AForm).Titelbild, -1, @TfrmGruppenEdit(AForm).SetTitelFoto, false);
  end;

Var
  frm:TfrmGruppenEdit;
  FConti:THtmlDiv;
begin

  FConti := THtmlDiv.Create(Mainform);
  FConti.Parent := Mainform;
  FConti.ElementClassName := 'editor_transparent';
  frm := TfrmGruppenEdit.CreateNew(FConti.ElementID, @AfterCreate);

  frm.FContainer := FConti;
  frm.FCallback  := ACallback;

end;



procedure TfrmGruppenEdit.btnSAClick(Sender: TObject);
Var
  j:TJ;
begin
  if await(Frage('Sicherheitseinweisung hinzufügen?')) = mrYes
  then begin
    j := TJ.create(await(datenmodul.EasySQL('SELECT ID from Medien2Holder ' +
                                            ' WHERE  HOLDER_TYP=' + _HT_Gruppe + ' AND ' +
                                                    'MEDIEN_TYP=' + inttostr(ord(medLektion)) + ' AND ' +
                                                    'HOLDER_ID =' + FID.ToString + ' AND ' +
                                                    'MEDIEN_ID = 2')));

    if j.hasValue = false
    then await(datenmodul.EasyInsert('Insert into Medien2Holder (HOLDER_TYP, MEDIEN_TYP, HOLDER_ID, MEDIEN_ID)' +
                                                   ' VALUES(' + _HT_Gruppe + ',' +
                                                              inttostr(ord(medLektion)) + ',' +
                                                              FID.ToString + ',' +'2)'));
  end;

end;

procedure TfrmGruppenEdit.btnSaveClick(Sender: TObject);
var
  sStatus, sZweck, s:string;
  J:TJ;
begin
  if reTitel.PlainText > ''
  then begin
     sStatus := cbStatus.values[cbStatus.ItemIndex];
     sZweck  := cbZweck.values[cbZweck.ItemIndex];

     s := 'UPDATE User SET NICKNAME = ''' + chkstr(reTitel.PlainText)  + ''', ' +
                          'VON = ''' + FormatDateTime( _DATE_SAVE, edVon.Date) + ''', ' +
                          'BIS = ''' + FormatDateTime( _DATE_SAVE, edBis.Date) + ''', ' +
                          'ABOUT = ''' + chkstr(reText.PlainText) + ''', ' +
                          'TITEL_MEDIEN_ID = ' + FTitelbild.tostring + ',' +
                          'AVATAR_MEDIEN_ID = ' + FTitelbild.tostring + ',' +
                          'STATUS_ID = ' + sStatus + ',' +
                          'KAT_ID = ' + sZweck +
           ' where ID = ' + FID.ToString;

     await(datenmodul.easyExec(s));

//     J := TJ.Create(await(datenmodul.EasySQL('Select ID from Friends where HOLDER = ' + FID.ToString + ' AND USER_ID = ' + mainform.ME.ID.ToString)));
//     if j.Value('ID') = ''
//     then await(datenmodul.EasyInsert('INSERT INTO Friends (HOLDER, USER, Status) Values(' + FID.ToString + ',' + mainform.ME.ID.ToString + ',' + '1)'));

     if assigned(FCallback)
     then FCallback(FID.ToString);

     close;
  end
  else begin
    Meldung('Die Gruppe hat keinen Namen. Gruppen ohne Namen können nicht gespeichert werden...');
  end;

end;

procedure TfrmGruppenEdit.SetTitelbild(value:integer);
begin
  FTitelBild := value;
  SetTitelFoto(value.ToString);
end;

procedure TfrmGruppenEdit.SetTitelFoto(s:string);
begin
  FTitelBild := s.ToInteger;
  img1.URL := await(datenmodul.GetMedia(s.tointeger, _SIZE2));
end;

procedure TfrmGruppenEdit.SetId(Value: integer);
begin
  FID := value;
end;

procedure TfrmGruppenEdit.btnCloseClick(Sender: TObject);
begin
  if FNewID > 0  //wenn dieser Dialog (GruppenEdit) zum Neuanlegen eines Albums aufgerufen wurde...
  then begin
    await(datenmodul.EasyExec('delete from Medien where ALBUM_ID = ' + FNewId.ToString));
    await(datenmodul.EasyExec('delete from user where ID = ' + FNewId.ToString));
  end;

  close;
end;

procedure TfrmGruppenEdit.btnWelcomePostClick(Sender: TObject);
begin
  TfrmTextEdit.Show('Begrüßungs-Post bearbeiten...', FID, _txt_Grp_welcome_post, nil);
end;

procedure TfrmGruppenEdit.WebButton1Click(Sender: TObject);
var
  LData:TEmailData;
  j:TJ;
  i:integer;
  JSObj: TJSObject;
  s, sTextID, JSString: string;
begin
  j := TJ.create(await(datenmodul.EasySQL('select ID from Texte where Holder = ' +  FID.ToString + ' AND TYP = ' + _txt_2_tages_mail.tostring )));

  if j.hasValue
  then begin

    Mainform.Wait := 1;

    sTextID := j.value('ID');

    j.Response := await(datenmodul.EasySQL('select distinct VORNAME, NICKNAME, NAME, user.ID, EMAIL ' +
                                           '  from friends ' +
                                           '  join user on (user.Id = Sender or User.id = Empfaenger) ' +
                                           ' Where (Sender = ' + FID.ToString + ' or Empfaenger = ' + FID.ToString + ') ' +
                                           '   and (User.id <> ' + FID.ToString + ')'));

    for i := 0 to j.Length -1 do
    begin
      j.Index := i;

    //test  s := 'thbordihn@yahoo.com';

      JSString:= '{"EMAIL":   "' + j.Value('EMAIL')   + '",' +
                  '"VORNAME": "' + j.Value('VORNAME') + '",' +
                  '"NAME": "'    + j.Value('NAME')    + '",' +
                  '"EVENT": "'   + reTitel.PlainText + '", ' +
                  '"TEXT_ID": '  + sTextID + ', ' +
                  '"URL": "'     + datenmodul.Connection.URL + '"' +
                  '}';

      JSObj:=TJSJSON.parseObject(JSString);

      Await( Client.RawInvokeAsync( 'IDBService.SendEMail',[JSObj]));
      break;
    end;

    Mainform.Wait := -1;

    await(Meldung('EMails verschickt'));
  end
  else await(Meldung('Kein EMail-Text vorhanden!'));

end;

procedure TfrmGruppenEdit.WebButton2Click(Sender: TObject);
begin
  TfrmSelection.Kurse(FID, _KA_Tutorial);
end;

procedure TfrmGruppenEdit.WebButton5Click(Sender: TObject);
begin
  TfrmTextEdit.Show('2-Tages-E-Mail bearbeiten...', FID, _txt_2_tages_mail, nil);
end;

procedure TfrmGruppenEdit.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
 FContainer.Free;
end;

procedure TfrmGruppenEdit.WebFormCreate(Sender: TObject);
Var
  el : TJSHTMLElement;
begin

  TControl(self).elementfont := efCSS;

  TControl(reTitel).elementPosition := epIgnore;
  el := TJSHTMLElement(THTMLDiv(reTitel).ElementHandle);
  el.style.removeProperty('position');
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-size');
  el.style.removeProperty('font-style');

  TControl(reText).elementPosition := epIgnore;
  el := TJSHTMLElement(THTMLDiv(reText).ElementHandle);
  el.style.removeProperty('position');
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-size');
  el.style.removeProperty('font-style');

end;

procedure TfrmGruppenEdit.img1Click(Sender: TObject);
begin
//  if Mainform.Meins
//  then
  TfrmMedienAuswahl.Fotos(FHolder, FHolder, FAlbum,0, 0, 0, 0, @SetTitelFoto, 2);

end;

procedure TfrmGruppenEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  WebHTMLDiv11 := THTMLDiv.Create(Self);
  btnClose := TImageControl.Create(Self);
  lblTitel := TLabel.Create(Self);
  WebHTMLDiv3 := THTMLDiv.Create(Self);
  WebHTMLDiv14 := THTMLDiv.Create(Self);
  img1 := TImageControl.Create(Self);
  WebHTMLDiv10 := THTMLDiv.Create(Self);
  divTitel := THTMLDiv.Create(Self);
  reTitel := TRichEdit.Create(Self);
  WebHTMLDiv13 := THTMLDiv.Create(Self);
  reText := TRichEdit.Create(Self);
  WebHTMLDiv9 := THTMLDiv.Create(Self);
  WebHTMLDiv67 := THTMLDiv.Create(Self);
  WebLabel28 := TLabel.Create(Self);
  WebHTMLDiv68 := THTMLDiv.Create(Self);
  cbZweck := TComboBox.Create(Self);
  WebHTMLDiv19 := THTMLDiv.Create(Self);
  WebHTMLDiv20 := THTMLDiv.Create(Self);
  WebHTMLDiv21 := THTMLDiv.Create(Self);
  edVon := TDateTimePicker.Create(Self);
  WebHTMLDiv22 := THTMLDiv.Create(Self);
  edBis := TDateTimePicker.Create(Self);
  WebHTMLDiv12 := THTMLDiv.Create(Self);
  btnWelcomePost := TButton.Create(Self);
  btnSA := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton5 := TButton.Create(Self);
  WebHTMLDiv8 := THTMLDiv.Create('frFotoslisteEdit');
  frFotosliste1 := TfrFotosliste.Create(Self);
  WebHTMLDiv17 := THTMLDiv.Create('E1');
  frVideoListe1 := TfrVideoListe.Create(Self);
  WebHTMLDiv7 := THTMLDiv.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  WebHTMLDiv5 := THTMLDiv.Create(Self);
  WebHTMLDiv6 := THTMLDiv.Create(Self);
  cbStatus := TComboBox.Create(Self);
  WebHTMLDiv4 := THTMLDiv.Create(Self);
  btnSave := TButton.Create(Self);
  btnCancel := TButton.Create(Self);
  Client := TXDataWebClient.Create(Self);

  frFotosliste1.LoadFromForm;
  frVideoListe1.LoadFromForm;
  WebHTMLDiv1.BeforeLoadDFMValues;
  WebHTMLDiv11.BeforeLoadDFMValues;
  btnClose.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  WebHTMLDiv3.BeforeLoadDFMValues;
  WebHTMLDiv14.BeforeLoadDFMValues;
  img1.BeforeLoadDFMValues;
  WebHTMLDiv10.BeforeLoadDFMValues;
  divTitel.BeforeLoadDFMValues;
  reTitel.BeforeLoadDFMValues;
  WebHTMLDiv13.BeforeLoadDFMValues;
  reText.BeforeLoadDFMValues;
  WebHTMLDiv9.BeforeLoadDFMValues;
  WebHTMLDiv67.BeforeLoadDFMValues;
  WebLabel28.BeforeLoadDFMValues;
  WebHTMLDiv68.BeforeLoadDFMValues;
  cbZweck.BeforeLoadDFMValues;
  WebHTMLDiv19.BeforeLoadDFMValues;
  WebHTMLDiv20.BeforeLoadDFMValues;
  WebHTMLDiv21.BeforeLoadDFMValues;
  edVon.BeforeLoadDFMValues;
  WebHTMLDiv22.BeforeLoadDFMValues;
  edBis.BeforeLoadDFMValues;
  WebHTMLDiv12.BeforeLoadDFMValues;
  btnWelcomePost.BeforeLoadDFMValues;
  btnSA.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebHTMLDiv8.BeforeLoadDFMValues;
  frFotosliste1.BeforeLoadDFMValues;
  frFotosliste1.WebHTMLDiv1.BeforeLoadDFMValues;
  frFotosliste1.divWrapper.BeforeLoadDFMValues;
  WebHTMLDiv17.BeforeLoadDFMValues;
  frVideoListe1.BeforeLoadDFMValues;
  frVideoListe1.WebHTMLDiv1.BeforeLoadDFMValues;
  frVideoListe1.divWrapper.BeforeLoadDFMValues;
  frVideoListe1.img.BeforeLoadDFMValues;
  frVideoListe1.Client.BeforeLoadDFMValues;
  WebHTMLDiv7.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  WebHTMLDiv5.BeforeLoadDFMValues;
  WebHTMLDiv6.BeforeLoadDFMValues;
  cbStatus.BeforeLoadDFMValues;
  WebHTMLDiv4.BeforeLoadDFMValues;
  btnSave.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frmGruppenEdit';
    Width := 838;
    Height := 803;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 8;
    WebHTMLDiv1.Top := 8;
    WebHTMLDiv1.Width := 817;
    WebHTMLDiv1.Height := 785;
    WebHTMLDiv1.ElementClassName := 'gruppen_edit_wrapper';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epRelative;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv11.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv11.Name := 'WebHTMLDiv11';
    WebHTMLDiv11.Left := 14;
    WebHTMLDiv11.Top := 10;
    WebHTMLDiv11.Width := 787;
    WebHTMLDiv11.Height := 41;
    WebHTMLDiv11.ElementClassName := 'me_headline';
    WebHTMLDiv11.HeightStyle := ssAuto;
    WebHTMLDiv11.WidthStyle := ssAuto;
    WebHTMLDiv11.ChildOrder := 1;
    WebHTMLDiv11.ElementPosition := epRelative;
    WebHTMLDiv11.ElementFont := efCSS;
    WebHTMLDiv11.Role := '';
    btnClose.SetParentComponent(WebHTMLDiv11);
    btnClose.Name := 'btnClose';
    btnClose.Left := 727;
    btnClose.Top := -3;
    btnClose.Width := 46;
    btnClose.Height := 44;
    btnClose.ElementClassName := 'albumedit_close';
    btnClose.HeightStyle := ssAuto;
    btnClose.WidthStyle := ssAuto;
    btnClose.HeightPercent := 100.000000000000000000;
    btnClose.WidthPercent := 100.000000000000000000;
    btnClose.Anchors := [];
    btnClose.ChildOrder := 1;
    btnClose.ElementFont := efCSS;
    btnClose.ElementPosition := epIgnore;
    SetEvent(btnClose, Self, 'OnClick', 'btnCloseClick');
    btnClose.Picture.LoadFromFile('uGruppenEdit.WebHTMLDiv11.btnClose.Picture.png');
    lblTitel.SetParentComponent(WebHTMLDiv11);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 16;
    lblTitel.Top := 9;
    lblTitel.Width := 35;
    lblTitel.Height := 15;
    lblTitel.Caption := 'lblTitel';
    lblTitel.ElementClassName := 'albumedit_titel';
    lblTitel.ElementFont := efCSS;
    lblTitel.ElementPosition := epIgnore;
    lblTitel.HeightStyle := ssAuto;
    lblTitel.HeightPercent := 100.000000000000000000;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.WidthPercent := 100.000000000000000000;
    WebHTMLDiv3.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv3.Name := 'WebHTMLDiv3';
    WebHTMLDiv3.Left := 14;
    WebHTMLDiv3.Top := 57;
    WebHTMLDiv3.Width := 787;
    WebHTMLDiv3.Height := 351;
    WebHTMLDiv3.ElementClassName := 'gruppen_wrapper';
    WebHTMLDiv3.HeightStyle := ssAuto;
    WebHTMLDiv3.WidthStyle := ssAuto;
    WebHTMLDiv3.ChildOrder := 2;
    WebHTMLDiv3.ElementPosition := epIgnore;
    WebHTMLDiv3.ElementFont := efCSS;
    WebHTMLDiv3.Role := '';
    WebHTMLDiv14.SetParentComponent(WebHTMLDiv3);
    WebHTMLDiv14.Name := 'WebHTMLDiv14';
    WebHTMLDiv14.Left := 15;
    WebHTMLDiv14.Top := 20;
    WebHTMLDiv14.Width := 379;
    WebHTMLDiv14.Height := 157;
    WebHTMLDiv14.ElementClassName := 'gruppen_container';
    WebHTMLDiv14.HeightStyle := ssAuto;
    WebHTMLDiv14.WidthStyle := ssAuto;
    WebHTMLDiv14.ChildOrder := 1;
    WebHTMLDiv14.ElementPosition := epIgnore;
    WebHTMLDiv14.ElementFont := efCSS;
    WebHTMLDiv14.Role := '';
    img1.SetParentComponent(WebHTMLDiv14);
    img1.Name := 'img1';
    img1.Left := 15;
    img1.Top := 19;
    img1.Width := 346;
    img1.Height := 118;
    img1.ElementClassName := 'gruppen_edit_img';
    img1.HeightStyle := ssAuto;
    img1.WidthStyle := ssAuto;
    img1.HeightPercent := 100.000000000000000000;
    img1.WidthPercent := 100.000000000000000000;
    img1.ChildOrder := 1;
    img1.ElementFont := efCSS;
    img1.ElementPosition := epIgnore;
    SetEvent(img1, Self, 'OnClick', 'img1Click');
    WebHTMLDiv10.SetParentComponent(WebHTMLDiv3);
    WebHTMLDiv10.Name := 'WebHTMLDiv10';
    WebHTMLDiv10.Left := 419;
    WebHTMLDiv10.Top := 20;
    WebHTMLDiv10.Width := 346;
    WebHTMLDiv10.Height := 157;
    WebHTMLDiv10.ElementClassName := 'gruppen_container';
    WebHTMLDiv10.HeightStyle := ssAuto;
    WebHTMLDiv10.WidthStyle := ssAuto;
    WebHTMLDiv10.ChildOrder := 2;
    WebHTMLDiv10.ElementPosition := epIgnore;
    WebHTMLDiv10.ElementFont := efCSS;
    WebHTMLDiv10.Role := '';
    divTitel.SetParentComponent(WebHTMLDiv10);
    divTitel.Name := 'divTitel';
    divTitel.Left := 16;
    divTitel.Top := 19;
    divTitel.Width := 321;
    divTitel.Height := 25;
    divTitel.ElementClassName := 'albumedit_titel_header';
    divTitel.HeightStyle := ssAuto;
    divTitel.WidthStyle := ssAuto;
    divTitel.ElementPosition := epIgnore;
    divTitel.ElementFont := efCSS;
    divTitel.HTML.BeginUpdate;
    try
      divTitel.HTML.Clear;
      divTitel.HTML.Add('Gruppen-Tiltel');
    finally
      divTitel.HTML.EndUpdate;
    end;
    divTitel.Role := '';
    reTitel.SetParentComponent(WebHTMLDiv10);
    reTitel.Name := 'reTitel';
    reTitel.Left := 16;
    reTitel.Top := 50;
    reTitel.Width := 321;
    reTitel.Height := 28;
    reTitel.ElementClassName := 'kursedit_titel';
    reTitel.HeightStyle := ssAuto;
    reTitel.WidthStyle := ssAuto;
    reTitel.BorderStyle := bsSingle;
    reTitel.ChildOrder := 1;
    reTitel.Color := clWhite;
    WebHTMLDiv13.SetParentComponent(WebHTMLDiv10);
    WebHTMLDiv13.Name := 'WebHTMLDiv13';
    WebHTMLDiv13.Left := 16;
    WebHTMLDiv13.Top := 84;
    WebHTMLDiv13.Width := 321;
    WebHTMLDiv13.Height := 29;
    WebHTMLDiv13.ElementClassName := 'albumedit_titel_header';
    WebHTMLDiv13.HeightStyle := ssAuto;
    WebHTMLDiv13.WidthStyle := ssAuto;
    WebHTMLDiv13.ChildOrder := 2;
    WebHTMLDiv13.ElementPosition := epIgnore;
    WebHTMLDiv13.ElementFont := efCSS;
    WebHTMLDiv13.HTML.BeginUpdate;
    try
      WebHTMLDiv13.HTML.Clear;
      WebHTMLDiv13.HTML.Add('Kurz-Beschreibung');
    finally
      WebHTMLDiv13.HTML.EndUpdate;
    end;
    WebHTMLDiv13.Role := '';
    reText.SetParentComponent(WebHTMLDiv10);
    reText.Name := 'reText';
    reText.Left := 16;
    reText.Top := 119;
    reText.Width := 321;
    reText.Height := 26;
    reText.ElementClassName := 'albumedit_input';
    reText.HeightStyle := ssAuto;
    reText.WidthStyle := ssAuto;
    reText.BorderStyle := bsSingle;
    reText.ChildOrder := 3;
    reText.Color := clWhite;
    WebHTMLDiv9.SetParentComponent(WebHTMLDiv3);
    WebHTMLDiv9.Name := 'WebHTMLDiv9';
    WebHTMLDiv9.Left := 13;
    WebHTMLDiv9.Top := 183;
    WebHTMLDiv9.Width := 381;
    WebHTMLDiv9.Height := 159;
    WebHTMLDiv9.ElementClassName := 'gruppen_container';
    WebHTMLDiv9.HeightStyle := ssAuto;
    WebHTMLDiv9.WidthStyle := ssAuto;
    WebHTMLDiv9.ChildOrder := 3;
    WebHTMLDiv9.ElementPosition := epIgnore;
    WebHTMLDiv9.ElementFont := efCSS;
    WebHTMLDiv9.Role := '';
    WebHTMLDiv67.SetParentComponent(WebHTMLDiv9);
    WebHTMLDiv67.Name := 'WebHTMLDiv67';
    WebHTMLDiv67.Left := 17;
    WebHTMLDiv67.Top := 15;
    WebHTMLDiv67.Width := 345;
    WebHTMLDiv67.Height := 40;
    WebHTMLDiv67.ElementClassName := 'profil_line';
    WebHTMLDiv67.HeightStyle := ssAuto;
    WebHTMLDiv67.WidthStyle := ssAuto;
    WebHTMLDiv67.ChildOrder := 1;
    WebHTMLDiv67.ElementPosition := epRelative;
    WebHTMLDiv67.ElementFont := efCSS;
    WebHTMLDiv67.Role := '';
    WebLabel28.SetParentComponent(WebHTMLDiv67);
    WebLabel28.Name := 'WebLabel28';
    WebLabel28.Left := 20;
    WebLabel28.Top := 11;
    WebLabel28.Width := 63;
    WebLabel28.Height := 15;
    WebLabel28.Caption := 'Gruppenart:';
    WebLabel28.ElementClassName := 'profil_label';
    WebLabel28.ElementFont := efCSS;
    WebLabel28.ElementPosition := epIgnore;
    WebLabel28.HeightStyle := ssAuto;
    WebLabel28.HeightPercent := 100.000000000000000000;
    WebLabel28.WidthStyle := ssAuto;
    WebLabel28.WidthPercent := 100.000000000000000000;
    WebHTMLDiv68.SetParentComponent(WebHTMLDiv67);
    WebHTMLDiv68.Name := 'WebHTMLDiv68';
    WebHTMLDiv68.Left := 136;
    WebHTMLDiv68.Top := 3;
    WebHTMLDiv68.Width := 190;
    WebHTMLDiv68.Height := 30;
    WebHTMLDiv68.ElementClassName := 'profil_edit_container';
    WebHTMLDiv68.HeightStyle := ssAuto;
    WebHTMLDiv68.WidthStyle := ssAuto;
    WebHTMLDiv68.ChildOrder := 2;
    WebHTMLDiv68.ElementPosition := epIgnore;
    WebHTMLDiv68.ElementFont := efCSS;
    WebHTMLDiv68.Role := '';
    cbZweck.SetParentComponent(WebHTMLDiv68);
    cbZweck.Name := 'cbZweck';
    cbZweck.Left := 10;
    cbZweck.Top := 3;
    cbZweck.Width := 60;
    cbZweck.Height := 23;
    cbZweck.ChildOrder := 1;
    cbZweck.ElementClassName := 'profil_edit';
    cbZweck.ElementFont := efCSS;
    cbZweck.ElementPosition := epIgnore;
    cbZweck.HeightStyle := ssAuto;
    cbZweck.HeightPercent := 100.000000000000000000;
    cbZweck.WidthStyle := ssAuto;
    cbZweck.WidthPercent := 100.000000000000000000;
    cbZweck.ItemIndex := -1;
    cbZweck.Items.BeginUpdate;
    try
      cbZweck.Items.Clear;
      cbZweck.Items.Add('');
      cbZweck.Items.Add('Herr');
      cbZweck.Items.Add('Frau');
    finally
      cbZweck.Items.EndUpdate;
    end;
    WebHTMLDiv19.SetParentComponent(WebHTMLDiv9);
    WebHTMLDiv19.Name := 'WebHTMLDiv19';
    WebHTMLDiv19.Left := 17;
    WebHTMLDiv19.Top := 61;
    WebHTMLDiv19.Width := 345;
    WebHTMLDiv19.Height := 41;
    WebHTMLDiv19.ElementClassName := 'albumedit_titel_header';
    WebHTMLDiv19.HeightStyle := ssAuto;
    WebHTMLDiv19.WidthStyle := ssAuto;
    WebHTMLDiv19.ChildOrder := 2;
    WebHTMLDiv19.ElementPosition := epIgnore;
    WebHTMLDiv19.ElementFont := efCSS;
    WebHTMLDiv19.HTML.BeginUpdate;
    try
      WebHTMLDiv19.HTML.Clear;
      WebHTMLDiv19.HTML.Add('Zeitraum:');
    finally
      WebHTMLDiv19.HTML.EndUpdate;
    end;
    WebHTMLDiv19.Role := '';
    WebHTMLDiv20.SetParentComponent(WebHTMLDiv9);
    WebHTMLDiv20.Name := 'WebHTMLDiv20';
    WebHTMLDiv20.Left := 17;
    WebHTMLDiv20.Top := 108;
    WebHTMLDiv20.Width := 345;
    WebHTMLDiv20.Height := 40;
    WebHTMLDiv20.ElementClassName := 'profil_line';
    WebHTMLDiv20.HeightStyle := ssAuto;
    WebHTMLDiv20.WidthStyle := ssAuto;
    WebHTMLDiv20.ChildOrder := 3;
    WebHTMLDiv20.ElementPosition := epIgnore;
    WebHTMLDiv20.ElementFont := efCSS;
    WebHTMLDiv20.Role := '';
    WebHTMLDiv21.SetParentComponent(WebHTMLDiv20);
    WebHTMLDiv21.Name := 'WebHTMLDiv21';
    WebHTMLDiv21.Left := 44;
    WebHTMLDiv21.Top := 7;
    WebHTMLDiv21.Width := 100;
    WebHTMLDiv21.Height := 30;
    WebHTMLDiv21.ElementClassName := 'gruppen_edit_container';
    WebHTMLDiv21.HeightStyle := ssAuto;
    WebHTMLDiv21.WidthStyle := ssAuto;
    WebHTMLDiv21.ChildOrder := 2;
    WebHTMLDiv21.ElementPosition := epIgnore;
    WebHTMLDiv21.ElementFont := efCSS;
    WebHTMLDiv21.Role := '';
    edVon.SetParentComponent(WebHTMLDiv21);
    edVon.Name := 'edVon';
    edVon.Left := 10;
    edVon.Top := 2;
    edVon.Width := 60;
    edVon.Height := 22;
    edVon.ElementClassName := 'profil_edit';
    edVon.HeightStyle := ssAuto;
    edVon.WidthStyle := ssAuto;
    edVon.BorderStyle := bsSingle;
    edVon.ChildOrder := 1;
    edVon.Color := clWhite;
    edVon.Date := 45280.619621018520000000;
    edVon.ElementFont := efCSS;
    edVon.ElementPosition := epRelative;
    edVon.Font.Charset := DEFAULT_CHARSET;
    edVon.Font.Color := clWindowText;
    edVon.Font.Height := -12;
    edVon.Font.Name := 'Segoe UI';
    edVon.Font.Style := [];
    edVon.ParentFont := False;
    edVon.Role := '';
    edVon.ShowSeconds := False;
    edVon.Text := '';
    WebHTMLDiv22.SetParentComponent(WebHTMLDiv20);
    WebHTMLDiv22.Name := 'WebHTMLDiv22';
    WebHTMLDiv22.Left := 206;
    WebHTMLDiv22.Top := 3;
    WebHTMLDiv22.Width := 100;
    WebHTMLDiv22.Height := 30;
    WebHTMLDiv22.ElementClassName := 'gruppen_edit_container';
    WebHTMLDiv22.HeightStyle := ssAuto;
    WebHTMLDiv22.WidthStyle := ssAuto;
    WebHTMLDiv22.ChildOrder := 2;
    WebHTMLDiv22.ElementPosition := epIgnore;
    WebHTMLDiv22.ElementFont := efCSS;
    WebHTMLDiv22.Role := '';
    edBis.SetParentComponent(WebHTMLDiv22);
    edBis.Name := 'edBis';
    edBis.Left := 10;
    edBis.Top := 2;
    edBis.Width := 60;
    edBis.Height := 22;
    edBis.ElementClassName := 'profil_edit';
    edBis.HeightStyle := ssAuto;
    edBis.WidthStyle := ssAuto;
    edBis.BorderStyle := bsSingle;
    edBis.ChildOrder := 1;
    edBis.Color := clWhite;
    edBis.Date := 45280.619621018520000000;
    edBis.ElementFont := efCSS;
    edBis.ElementPosition := epRelative;
    edBis.Font.Charset := DEFAULT_CHARSET;
    edBis.Font.Color := clWindowText;
    edBis.Font.Height := -12;
    edBis.Font.Name := 'Segoe UI';
    edBis.Font.Style := [];
    edBis.ParentFont := False;
    edBis.Role := '';
    edBis.ShowSeconds := False;
    edBis.Text := '';
    WebHTMLDiv12.SetParentComponent(WebHTMLDiv3);
    WebHTMLDiv12.Name := 'WebHTMLDiv12';
    WebHTMLDiv12.Left := 419;
    WebHTMLDiv12.Top := 183;
    WebHTMLDiv12.Width := 346;
    WebHTMLDiv12.Height := 157;
    WebHTMLDiv12.ElementClassName := 'gruppen_container';
    WebHTMLDiv12.HeightStyle := ssAuto;
    WebHTMLDiv12.WidthStyle := ssAuto;
    WebHTMLDiv12.ChildOrder := 3;
    WebHTMLDiv12.ElementPosition := epIgnore;
    WebHTMLDiv12.ElementFont := efCSS;
    WebHTMLDiv12.Role := '';
    btnWelcomePost.SetParentComponent(WebHTMLDiv12);
    btnWelcomePost.Name := 'btnWelcomePost';
    btnWelcomePost.Left := 19;
    btnWelcomePost.Top := 3;
    btnWelcomePost.Width := 150;
    btnWelcomePost.Height := 25;
    btnWelcomePost.Caption := 'Begr'#252#223'ungs-Post';
    btnWelcomePost.ElementFont := efCSS;
    btnWelcomePost.ElementPosition := epIgnore;
    btnWelcomePost.HeightStyle := ssAuto;
    btnWelcomePost.HeightPercent := 100.000000000000000000;
    btnWelcomePost.WidthStyle := ssAuto;
    btnWelcomePost.WidthPercent := 100.000000000000000000;
    SetEvent(btnWelcomePost, Self, 'OnClick', 'btnWelcomePostClick');
    btnSA.SetParentComponent(WebHTMLDiv12);
    btnSA.Name := 'btnSA';
    btnSA.Left := 19;
    btnSA.Top := 34;
    btnSA.Width := 150;
    btnSA.Height := 25;
    btnSA.Caption := 'Sicherheitseinweisung';
    btnSA.ChildOrder := 1;
    btnSA.ElementFont := efCSS;
    btnSA.ElementPosition := epIgnore;
    btnSA.HeightStyle := ssAuto;
    btnSA.HeightPercent := 100.000000000000000000;
    btnSA.WidthStyle := ssAuto;
    btnSA.WidthPercent := 100.000000000000000000;
    SetEvent(btnSA, Self, 'OnClick', 'btnSAClick');
    WebButton2.SetParentComponent(WebHTMLDiv12);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 19;
    WebButton2.Top := 65;
    WebButton2.Width := 150;
    WebButton2.Height := 25;
    WebButton2.Caption := 'Tutorials';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementFont := efCSS;
    WebButton2.ElementPosition := epIgnore;
    WebButton2.HeightStyle := ssAuto;
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthStyle := ssAuto;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton3.SetParentComponent(WebHTMLDiv12);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 19;
    WebButton3.Top := 96;
    WebButton3.Width := 150;
    WebButton3.Height := 25;
    WebButton3.Caption := 'Dokumente';
    WebButton3.ChildOrder := 1;
    WebButton3.ElementFont := efCSS;
    WebButton3.ElementPosition := epIgnore;
    WebButton3.HeightStyle := ssAuto;
    WebButton3.HeightPercent := 100.000000000000000000;
    WebButton3.WidthStyle := ssAuto;
    WebButton3.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton3, Self, 'OnClick', 'btnWelcomePostClick');
    WebButton4.SetParentComponent(WebHTMLDiv12);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 19;
    WebButton4.Top := 127;
    WebButton4.Width := 150;
    WebButton4.Height := 25;
    WebButton4.Caption := 'Blogs';
    WebButton4.ChildOrder := 1;
    WebButton4.ElementFont := efCSS;
    WebButton4.ElementPosition := epIgnore;
    WebButton4.HeightStyle := ssAuto;
    WebButton4.HeightPercent := 100.000000000000000000;
    WebButton4.WidthStyle := ssAuto;
    WebButton4.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton4, Self, 'OnClick', 'btnWelcomePostClick');
    WebButton1.SetParentComponent(WebHTMLDiv12);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 175;
    WebButton1.Top := 3;
    WebButton1.Width := 150;
    WebButton1.Height := 25;
    WebButton1.Caption := '2 Tages-E-Mail senden';
    WebButton1.ChildOrder := 5;
    WebButton1.ElementFont := efCSS;
    WebButton1.ElementPosition := epIgnore;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthStyle := ssAuto;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton5.SetParentComponent(WebHTMLDiv12);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 175;
    WebButton5.Top := 34;
    WebButton5.Width := 150;
    WebButton5.Height := 25;
    WebButton5.Caption := '2 Tages-E-Mail bearbeiten';
    WebButton5.ChildOrder := 6;
    WebButton5.ElementFont := efCSS;
    WebButton5.ElementPosition := epIgnore;
    WebButton5.HeightStyle := ssAuto;
    WebButton5.HeightPercent := 100.000000000000000000;
    WebButton5.WidthStyle := ssAuto;
    WebButton5.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    WebHTMLDiv8.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv8.Name := 'WebHTMLDiv8';
    WebHTMLDiv8.Left := 14;
    WebHTMLDiv8.Top := 431;
    WebHTMLDiv8.Width := 787;
    WebHTMLDiv8.Height := 90;
    WebHTMLDiv8.ElementClassName := 'ktext_img_container';
    WebHTMLDiv8.HeightStyle := ssAuto;
    WebHTMLDiv8.WidthStyle := ssAuto;
    WebHTMLDiv8.ChildOrder := 10;
    WebHTMLDiv8.ElementPosition := epIgnore;
    WebHTMLDiv8.ElementFont := efCSS;
    WebHTMLDiv8.Role := '';
    WebHTMLDiv8.Visible := False;
    frFotosliste1.SetParentComponent(WebHTMLDiv8);
    frFotosliste1.Name := 'frFotosliste1';
    frFotosliste1.Left := 6;
    frFotosliste1.Top := 0;
    frFotosliste1.Width := 309;
    frFotosliste1.Height := 325;
    frFotosliste1.Margins.Left := 0;
    frFotosliste1.Margins.Top := 0;
    frFotosliste1.Margins.Right := 0;
    frFotosliste1.Margins.Bottom := 0;
    frFotosliste1.TabOrder := 0;
    frFotosliste1.WebHTMLDiv1.Top := 11;
    frFotosliste1.WebHTMLDiv1.Height := 190;
    frFotosliste1.divWrapper.Height := 153;
    WebHTMLDiv17.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv17.Name := 'WebHTMLDiv17';
    WebHTMLDiv17.Left := 14;
    WebHTMLDiv17.Top := 532;
    WebHTMLDiv17.Width := 787;
    WebHTMLDiv17.Height := 75;
    WebHTMLDiv17.ElementClassName := 'ktext_img_container';
    WebHTMLDiv17.HeightStyle := ssAuto;
    WebHTMLDiv17.WidthStyle := ssAuto;
    WebHTMLDiv17.ChildOrder := 11;
    WebHTMLDiv17.ElementPosition := epIgnore;
    WebHTMLDiv17.ElementFont := efCSS;
    WebHTMLDiv17.Role := '';
    WebHTMLDiv17.Visible := False;
    frVideoListe1.SetParentComponent(WebHTMLDiv17);
    frVideoListe1.Name := 'frVideoListe1';
    frVideoListe1.Left := 13;
    frVideoListe1.Top := 0;
    frVideoListe1.Width := 301;
    frVideoListe1.Height := 172;
    frVideoListe1.Margins.Left := 0;
    frVideoListe1.Margins.Top := 0;
    frVideoListe1.Margins.Right := 0;
    frVideoListe1.Margins.Bottom := 0;
    frVideoListe1.TabOrder := 0;
    frVideoListe1.WebHTMLDiv1.Top := 16;
    frVideoListe1.WebHTMLDiv1.Height := 161;
    frVideoListe1.divWrapper.Left := 11;
    frVideoListe1.divWrapper.Width := 233;
    frVideoListe1.divWrapper.Height := 137;
    frVideoListe1.img.Left := 24;
    frVideoListe1.img.Top := 16;
    frVideoListe1.img.Width := 65;
    frVideoListe1.img.Height := 57;
    frVideoListe1.Client.Left := 16;
    frVideoListe1.Client.Top := 16;
    WebHTMLDiv7.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv7.Name := 'WebHTMLDiv7';
    WebHTMLDiv7.Left := 17;
    WebHTMLDiv7.Top := 618;
    WebHTMLDiv7.Width := 784;
    WebHTMLDiv7.Height := 154;
    WebHTMLDiv7.ElementClassName := 'albumedit_footer';
    WebHTMLDiv7.HeightStyle := ssAuto;
    WebHTMLDiv7.WidthStyle := ssAuto;
    WebHTMLDiv7.ChildOrder := 12;
    WebHTMLDiv7.ElementPosition := epRelative;
    WebHTMLDiv7.ElementFont := efCSS;
    WebHTMLDiv7.Role := '';
    WebHTMLDiv2.SetParentComponent(WebHTMLDiv7);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 19;
    WebHTMLDiv2.Top := 9;
    WebHTMLDiv2.Width := 398;
    WebHTMLDiv2.Height := 112;
    WebHTMLDiv2.ElementClassName := 'albumedit_combo_container';
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ElementPosition := epIgnore;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.Role := '';
    WebHTMLDiv5.SetParentComponent(WebHTMLDiv2);
    WebHTMLDiv5.Name := 'WebHTMLDiv5';
    WebHTMLDiv5.Left := 3;
    WebHTMLDiv5.Top := 3;
    WebHTMLDiv5.Width := 390;
    WebHTMLDiv5.Height := 41;
    WebHTMLDiv5.HeightStyle := ssAuto;
    WebHTMLDiv5.WidthStyle := ssAuto;
    WebHTMLDiv5.ChildOrder := 2;
    WebHTMLDiv5.ElementPosition := epIgnore;
    WebHTMLDiv5.ElementFont := efCSS;
    WebHTMLDiv5.HTML.BeginUpdate;
    try
      WebHTMLDiv5.HTML.Clear;
      WebHTMLDiv5.HTML.Add('f'#252'r wen ist die Gruppe sichtbar ?');
    finally
      WebHTMLDiv5.HTML.EndUpdate;
    end;
    WebHTMLDiv5.Role := '';
    WebHTMLDiv6.SetParentComponent(WebHTMLDiv2);
    WebHTMLDiv6.Name := 'WebHTMLDiv6';
    WebHTMLDiv6.Left := 3;
    WebHTMLDiv6.Top := 50;
    WebHTMLDiv6.Width := 334;
    WebHTMLDiv6.Height := 41;
    WebHTMLDiv6.HeightStyle := ssAuto;
    WebHTMLDiv6.WidthStyle := ssAuto;
    WebHTMLDiv6.ChildOrder := 2;
    WebHTMLDiv6.ElementPosition := epIgnore;
    WebHTMLDiv6.ElementFont := efCSS;
    WebHTMLDiv6.Role := '';
    cbStatus.SetParentComponent(WebHTMLDiv6);
    cbStatus.Name := 'cbStatus';
    cbStatus.Left := 3;
    cbStatus.Top := 3;
    cbStatus.Width := 273;
    cbStatus.Height := 23;
    cbStatus.ElementClassName := 'albumedit_combobox';
    cbStatus.ElementFont := efCSS;
    cbStatus.ElementPosition := epIgnore;
    cbStatus.HeightStyle := ssAuto;
    cbStatus.HeightPercent := 100.000000000000000000;
    cbStatus.Text := 'nur f'#252'r mich';
    cbStatus.WidthStyle := ssAuto;
    cbStatus.WidthPercent := 100.000000000000000000;
    cbStatus.ItemIndex := 0;
    cbStatus.Items.BeginUpdate;
    try
      cbStatus.Items.Clear;
      cbStatus.Items.Add('nur f'#252'r mich');
      cbStatus.Items.Add('meine Freunde');
      cbStatus.Items.Add('f'#252'r alle Mitglieder');
    finally
      cbStatus.Items.EndUpdate;
    end;
    WebHTMLDiv4.SetParentComponent(WebHTMLDiv7);
    WebHTMLDiv4.Name := 'WebHTMLDiv4';
    WebHTMLDiv4.Left := 423;
    WebHTMLDiv4.Top := 3;
    WebHTMLDiv4.Width := 334;
    WebHTMLDiv4.Height := 41;
    WebHTMLDiv4.ElementClassName := 'albumedit_btn_container';
    WebHTMLDiv4.HeightStyle := ssAuto;
    WebHTMLDiv4.WidthStyle := ssAuto;
    WebHTMLDiv4.ChildOrder := 1;
    WebHTMLDiv4.ElementPosition := epIgnore;
    WebHTMLDiv4.ElementFont := efCSS;
    WebHTMLDiv4.Role := '';
    btnSave.SetParentComponent(WebHTMLDiv4);
    btnSave.Name := 'btnSave';
    btnSave.Left := 9;
    btnSave.Top := 3;
    btnSave.Width := 144;
    btnSave.Height := 25;
    btnSave.Caption := 'Speichern';
    btnSave.ChildOrder := 1;
    btnSave.ElementClassName := 'editor_button';
    btnSave.ElementFont := efCSS;
    btnSave.ElementPosition := epIgnore;
    btnSave.HeightStyle := ssAuto;
    btnSave.HeightPercent := 100.000000000000000000;
    btnSave.WidthStyle := ssAuto;
    btnSave.WidthPercent := 100.000000000000000000;
    SetEvent(btnSave, Self, 'OnClick', 'btnSaveClick');
    btnCancel.SetParentComponent(WebHTMLDiv4);
    btnCancel.Name := 'btnCancel';
    btnCancel.Left := 159;
    btnCancel.Top := 3;
    btnCancel.Width := 144;
    btnCancel.Height := 25;
    btnCancel.Caption := 'Verwerfen';
    btnCancel.ChildOrder := 2;
    btnCancel.ElementClassName := 'editor_button';
    btnCancel.ElementFont := efCSS;
    btnCancel.ElementPosition := epIgnore;
    btnCancel.HeightStyle := ssAuto;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthStyle := ssAuto;
    btnCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCancel, Self, 'OnClick', 'btnCloseClick');
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 24;
    Client.Top := 64;
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    WebHTMLDiv11.AfterLoadDFMValues;
    btnClose.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    WebHTMLDiv3.AfterLoadDFMValues;
    WebHTMLDiv14.AfterLoadDFMValues;
    img1.AfterLoadDFMValues;
    WebHTMLDiv10.AfterLoadDFMValues;
    divTitel.AfterLoadDFMValues;
    reTitel.AfterLoadDFMValues;
    WebHTMLDiv13.AfterLoadDFMValues;
    reText.AfterLoadDFMValues;
    WebHTMLDiv9.AfterLoadDFMValues;
    WebHTMLDiv67.AfterLoadDFMValues;
    WebLabel28.AfterLoadDFMValues;
    WebHTMLDiv68.AfterLoadDFMValues;
    cbZweck.AfterLoadDFMValues;
    WebHTMLDiv19.AfterLoadDFMValues;
    WebHTMLDiv20.AfterLoadDFMValues;
    WebHTMLDiv21.AfterLoadDFMValues;
    edVon.AfterLoadDFMValues;
    WebHTMLDiv22.AfterLoadDFMValues;
    edBis.AfterLoadDFMValues;
    WebHTMLDiv12.AfterLoadDFMValues;
    btnWelcomePost.AfterLoadDFMValues;
    btnSA.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebHTMLDiv8.AfterLoadDFMValues;
    frFotosliste1.AfterLoadDFMValues;
    frFotosliste1.WebHTMLDiv1.AfterLoadDFMValues;
    frFotosliste1.divWrapper.AfterLoadDFMValues;
    WebHTMLDiv17.AfterLoadDFMValues;
    frVideoListe1.AfterLoadDFMValues;
    frVideoListe1.WebHTMLDiv1.AfterLoadDFMValues;
    frVideoListe1.divWrapper.AfterLoadDFMValues;
    frVideoListe1.img.AfterLoadDFMValues;
    frVideoListe1.Client.AfterLoadDFMValues;
    WebHTMLDiv7.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    WebHTMLDiv5.AfterLoadDFMValues;
    WebHTMLDiv6.AfterLoadDFMValues;
    cbStatus.AfterLoadDFMValues;
    WebHTMLDiv4.AfterLoadDFMValues;
    btnSave.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.

