unit uMsg;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, XData.Web.Client, Vcl.Controls, WEBLib.WebCtrls,
  Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Imaging.pngimage, WEBLib.ExtCtrls,
  Vcl.Imaging.jpeg, WEBLib.EditAutocomplete, utb, WEBLib.WebSocketClient;

type
  TfrmMsg = class(TForm)
    Client: TXDataWebClient;
    WebHTMLDiv1: THTMLDiv;
    WebHTMLDiv11: THTMLDiv;
    btnClose: TImageControl;
    WebHTMLDiv71: THTMLDiv;
    btnSend: TButton;
    WebHTMLDiv2: THTMLDiv;
    imgAvatar: TImageControl;
    divHolder: THTMLDiv;
    divCenter: THTMLDiv;
    edEingabe: TEdit;

    ChatClient: TSocketClient;
    procedure ChatClientMessageReceived(Sender: TObject; AMessage: string);
    [async] procedure btnSendClick(Sender: TObject); async;
    [async] procedure GetMsg(AHolder, AUser:integer); async;
    procedure btnCloseClick(Sender: TObject);
    procedure WebFormCloseQuery(Sender: TObject; var CanClose: Boolean);
  private
    FNickname:string;
    FLast,
    FHolder,
    FUser:integer;
    procedure WriteLine(AName, AText, Time:string);
  public
    [async] class procedure Exec(AHolder, AUser:integer); async;
  protected procedure LoadDFMValues; override; end;

var
  frmMsg: TfrmMsg;

implementation
  uses uMain, uDatenmodul;

{$R *.dfm}

class procedure TfrmMsg.Exec(AHolder, AUser:integer);

  [async] procedure AfterCreate(AForm: TObject); async;
  Var
    J:TJ;
    i:integer;
  begin
    TfrmMsg(AForm).FHolder := AHolder;
    TfrmMsg(AForm).FUser   := AUser;
    TfrmMsg(AForm).FLast   := 0;

    TfrmMsg(AForm).Chatclient.Port := 8888;
    TfrmMsg(AForm).Chatclient.HostName := 'LocalHost';
    TfrmMsg(AForm).Chatclient.Active := true;

    j := TJ.create(await(datenmodul.EasySQL('select AVATAR_MEDIEN_ID, NICKNAME ' +
                                            '  from User ' +
                                            ' where ID = ' + AHolder.ToString)));

    TfrmMsg(AForm).FNickname := J.Value('NICKNAME');
    TfrmMsg(AForm).divHolder.html.text := TfrmMsg(AForm).FNickname;
    TfrmMsg(AForm).imgAvatar.URL := await(datenmodul.GetMedia(J.Integer('AVATAR_MEDIEN_ID'), _Size3));

    TfrmMsg(AForm).GetMsg(TfrmMsg(AForm).FHolder, TfrmMsg(AForm).FUser);

  end;

var
  frm : TfrmMsg;
begin
  frm := TfrmMsg.CreateNew(Mainform.ElementID, @AfterCreate);

end;

procedure TfrmMsg.btnCloseClick(Sender: TObject);
var
  x:TfrmMsg;
begin
  x := self;
  showmessage(x.edEingabe.Text);
  x.close;
end;

procedure TfrmMsg.btnSendClick(Sender: TObject);
begin
  await(datenmodul.EasyInsert('insert into msg (Holder, User_ID, Text) ' +
               ' VALUES(' + FHolder.toString + ',' + FUser.ToSTring + ',''' + edEingabe.Text + ''')'));

  ChatClient.Send(Mainform.Holder.ID.tostring + '|' + Mainform.ME.ID.ToString + '|' + edEingabe.text);

  edEingabe.Text := '';
  edEingabe.SetFocus;
end;

procedure TfrmMsg.ChatClientMessageReceived(Sender: TObject; AMessage: string);
Var
  sHolder, sUser, sText, s:string;
begin
  s := aMessage;

  sHolder := copy(s,1, Pos('|', s)-1);
  s := copy(s,length(sHolder)+2,length(s));
  sUser := copy(s,1, Pos('|', s)-1);
  s := copy(s,length(sUser)+2,length(s));
  sText := copy(s,1, length(s));

  if ((FHolder.ToString = sHolder) and (FUser.ToString = sUser)) or
     ((FHolder.ToString = sUser)   and (FUser.ToString = sHolder))
  then begin
    if Mainform.ME.ID.tostring = sUser
    then writeLine('Du', sText,'')
    else writeLine(FNickname, sText,'');
  end;

end;


procedure TfrmMsg.GetMsg(AHolder, AUser:integer);
Var
  J:TJ;
  i:integer;
begin

//  j := TJ.create(await(datenmodul.EasySQL('select * from msg ' +
//                                          ' where ((Holder = ' + AHolder.ToString + ' AND USER_ID = ' + AUser.tostring + ') ' +
//                                          '    or  (Holder = ' + AUser.ToString   + ' AND USER_ID = ' + AHolder.tostring + ')) ' +
//                                          '   AND ID > ' + FLast.tostring)));

//  for i := 0 to j.length-1 do
//  begin
//    j.Index:=i;
//    if Mainform.ME.ID = j.Integer('USER_ID')
//    then writeLine('Du', j.Text('TEXT'),'')
//    else writeLine(FNickname, j.Text('TEXT'),'');
//    FLast := J.integer('ID');
//
//  end;

end;


procedure TfrmMsg.WebFormCloseQuery(Sender: TObject; var CanClose: Boolean);
begin
  showmessage('schließen');
end;

procedure TfrmMsg.writeline(AName, AText, Time:string);
var
  d:THTMLDiv;
begin

  d:=THTMLDiv.Create(self);
  d.Parent:= divCenter;
  d.ElementPosition := epRelative;
  d.ElementClassName := 'msg-line';
  d.HTML.Text := '<font size="3">' + AName+ ': </font>' + AText;
end;

procedure TfrmMsg.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  WebHTMLDiv11 := THTMLDiv.Create(Self);
  btnClose := TImageControl.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  imgAvatar := TImageControl.Create(Self);
  divHolder := THTMLDiv.Create(Self);
  WebHTMLDiv71 := THTMLDiv.Create(Self);
  btnSend := TButton.Create(Self);
  edEingabe := TEdit.Create(Self);
  divCenter := THTMLDiv.Create(Self);
  Client := TXDataWebClient.Create(Self);
  ChatClient := TSocketClient.Create(Self);

  WebHTMLDiv1.BeforeLoadDFMValues;
  WebHTMLDiv11.BeforeLoadDFMValues;
  btnClose.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  imgAvatar.BeforeLoadDFMValues;
  divHolder.BeforeLoadDFMValues;
  WebHTMLDiv71.BeforeLoadDFMValues;
  btnSend.BeforeLoadDFMValues;
  edEingabe.BeforeLoadDFMValues;
  divCenter.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  ChatClient.BeforeLoadDFMValues;
  try
    Name := 'frmMsg';
    Width := 397;
    Height := 444;
    ElementFont := efCSS;
    ElementPosition := epRelative;
    SetEvent(Self, 'OnCloseQuery', 'WebFormCloseQuery');
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 16;
    WebHTMLDiv1.Top := 8;
    WebHTMLDiv1.Width := 369;
    WebHTMLDiv1.Height := 425;
    WebHTMLDiv1.ElementClassName := 'msg';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv11.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv11.Name := 'WebHTMLDiv11';
    WebHTMLDiv11.Left := 15;
    WebHTMLDiv11.Top := 11;
    WebHTMLDiv11.Width := 334;
    WebHTMLDiv11.Height := 41;
    WebHTMLDiv11.ElementClassName := 'me_headline';
    WebHTMLDiv11.HeightStyle := ssAuto;
    WebHTMLDiv11.WidthStyle := ssAuto;
    WebHTMLDiv11.ElementPosition := epRelative;
    WebHTMLDiv11.ElementFont := efCSS;
    WebHTMLDiv11.Role := '';
    btnClose.SetParentComponent(WebHTMLDiv11);
    btnClose.Name := 'btnClose';
    btnClose.Left := 295;
    btnClose.Top := -3;
    btnClose.Width := 46;
    btnClose.Height := 44;
    btnClose.ElementClassName := 'albumedit_close';
    btnClose.HeightStyle := ssAuto;
    btnClose.WidthStyle := ssAuto;
    btnClose.HeightPercent := 100.000000000000000000;
    btnClose.WidthPercent := 100.000000000000000000;
    btnClose.Anchors := [];
    btnClose.ChildOrder := 1;
    btnClose.ElementFont := efCSS;
    btnClose.ElementPosition := epIgnore;
    SetEvent(btnClose, Self, 'OnClick', 'btnCloseClick');
    btnClose.Picture.LoadFromFile('uMsg.WebHTMLDiv11.btnClose.Picture.png');
    WebHTMLDiv2.SetParentComponent(WebHTMLDiv11);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 3;
    WebHTMLDiv2.Top := -8;
    WebHTMLDiv2.Width := 214;
    WebHTMLDiv2.Height := 49;
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ChildOrder := 1;
    WebHTMLDiv2.ElementPosition := epIgnore;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.Role := '';
    imgAvatar.SetParentComponent(WebHTMLDiv2);
    imgAvatar.Name := 'imgAvatar';
    imgAvatar.Left := -1;
    imgAvatar.Top := 5;
    imgAvatar.Width := 53;
    imgAvatar.Height := 41;
    imgAvatar.ElementClassName := 'main_content_avatar';
    imgAvatar.HeightStyle := ssAuto;
    imgAvatar.WidthStyle := ssAuto;
    imgAvatar.HeightPercent := 100.000000000000000000;
    imgAvatar.WidthPercent := 100.000000000000000000;
    imgAvatar.Anchors := [];
    imgAvatar.ElementFont := efCSS;
    imgAvatar.ElementPosition := epIgnore;
    imgAvatar.Picture.LoadFromFile('uMsg.WebHTMLDiv2.imgAvatar.Picture.jpg');
    divHolder.SetParentComponent(WebHTMLDiv2);
    divHolder.Name := 'divHolder';
    divHolder.Left := 58;
    divHolder.Top := 6;
    divHolder.Width := 137;
    divHolder.Height := 41;
    divHolder.HeightStyle := ssAuto;
    divHolder.WidthStyle := ssAuto;
    divHolder.ChildOrder := 1;
    divHolder.ElementPosition := epIgnore;
    divHolder.ElementFont := efCSS;
    divHolder.Role := '';
    WebHTMLDiv71.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv71.Name := 'WebHTMLDiv71';
    WebHTMLDiv71.Left := 15;
    WebHTMLDiv71.Top := 383;
    WebHTMLDiv71.Width := 334;
    WebHTMLDiv71.Height := 30;
    WebHTMLDiv71.ElementClassName := 'msg-edit-line';
    WebHTMLDiv71.HeightStyle := ssAuto;
    WebHTMLDiv71.WidthStyle := ssAuto;
    WebHTMLDiv71.ChildOrder := 2;
    WebHTMLDiv71.ElementPosition := epIgnore;
    WebHTMLDiv71.ElementFont := efCSS;
    WebHTMLDiv71.Role := '';
    btnSend.SetParentComponent(WebHTMLDiv71);
    btnSend.Name := 'btnSend';
    btnSend.Left := 280;
    btnSend.Top := 2;
    btnSend.Width := 40;
    btnSend.Height := 25;
    btnSend.Caption := '...';
    btnSend.ChildOrder := 1;
    btnSend.ElementClassName := 'msg-btn-punkte';
    btnSend.ElementFont := efCSS;
    btnSend.ElementPosition := epIgnore;
    btnSend.HeightStyle := ssAuto;
    btnSend.HeightPercent := 100.000000000000000000;
    btnSend.WidthStyle := ssAuto;
    btnSend.WidthPercent := 100.000000000000000000;
    SetEvent(btnSend, Self, 'OnClick', 'btnSendClick');
    edEingabe.SetParentComponent(WebHTMLDiv71);
    edEingabe.Name := 'edEingabe';
    edEingabe.Left := 3;
    edEingabe.Top := 3;
    edEingabe.Width := 121;
    edEingabe.Height := 22;
    edEingabe.ElementFont := efCSS;
    edEingabe.ElementPosition := epIgnore;
    edEingabe.HeightStyle := ssAuto;
    edEingabe.HeightPercent := 100.000000000000000000;
    edEingabe.Text := 'edEingabe';
    edEingabe.TextHint := 'schreibe eine Nachricht...';
    edEingabe.WidthStyle := ssAuto;
    edEingabe.WidthPercent := 100.000000000000000000;
    divCenter.SetParentComponent(WebHTMLDiv1);
    divCenter.Name := 'divCenter';
    divCenter.Left := 16;
    divCenter.Top := 56;
    divCenter.Width := 333;
    divCenter.Height := 321;
    divCenter.HeightStyle := ssAuto;
    divCenter.WidthStyle := ssAuto;
    divCenter.ChildOrder := 2;
    divCenter.ElementPosition := epIgnore;
    divCenter.ElementFont := efCSS;
    divCenter.Role := '';
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 40;
    Client.Top := 72;
    ChatClient.SetParentComponent(Self);
    ChatClient.Name := 'ChatClient';
    SetEvent(ChatClient, Self, 'OnMessageReceived', 'ChatClientMessageReceived');
    ChatClient.Left := 40;
    ChatClient.Top := 144;
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    WebHTMLDiv11.AfterLoadDFMValues;
    btnClose.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    imgAvatar.AfterLoadDFMValues;
    divHolder.AfterLoadDFMValues;
    WebHTMLDiv71.AfterLoadDFMValues;
    btnSend.AfterLoadDFMValues;
    edEingabe.AfterLoadDFMValues;
    divCenter.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
    ChatClient.AfterLoadDFMValues;
  end;
end;

end.