unit uPosts;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.WebCtrls, uGlobal,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, XData.Web.Client, utb, uPostEdit,
  WEBLib.ComCtrls, Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TfrmPosts = class(TForm)
    divContent: THTMLDiv;
    divMenu: THTMLDiv;
    Client: TXDataWebClient;
    btnTitel: TImageControl;
    lblTitel: THTMLDiv;
    divMore: THTMLDiv;
    btnPersSave: TButton;
    procedure WebFormCreate(Sender: TObject);
    [async] procedure btnNewClick(Sender: TObject); async;
    [async] procedure divMoreClick(Sender: TObject); async;
  private
    FLastID:integer;
    FParent : TForm;
    FHolder : integer;
    FErsterAufruf:boolean;
    procedure GoToBlogItem(AElementID:string);
    [async] procedure btnFixierenClick(Sender: TObject); async;
    [async] procedure CreatePosts(ANewId:integer; AResponse : Array of TXDataClientResponse); async;
    [async] procedure DeleteAll; async;
  public
    property Parent : TForm read FParent write FParent;
    property Holder : integer read FHolder write FHolder;
    [async] procedure Init(APostId:integer); async;
    procedure NewID(AId:string);
  protected procedure LoadDFMValues; override; end;

var
  frmPosts: TfrmPosts;

implementation
  Uses uDatenmodul, uMain, ufrPost, uMeldung;
{$R *.dfm}

procedure TfrmPosts.NewID(AId:string);
begin
  init(AId.ToInteger);
end;

procedure TfrmPosts.Init(APostID:integer);

  function SetTitel(AName:string; HHolder, AME:TME):string;
  begin

  end;

Var
  aRes: array of TXDataClientResponse;
  Response: TXDataClientResponse;
  i, id:integer;
  j:TJ;
  sName:string;
  fWait:THtmlDiv;
begin

  Mainform.Wait := 1;

  if (Mainform.Meins) and (mainform.Holder.Typ = _UT_User)
  then sName := 'DEINE '
  else begin

    sName := mainform.Holder.Nickname;
    if copy(sname,length(sName)-1,1) <> 's'
    then sName := sName + 's';
  end;

  lblTitel.HTML.Text := sName + ' CHRONIK';

  FHolder := Mainform.Holder.ID;
  divMenu.Visible := true;
  Response := Await( Client.RawInvokeAsync('IDBService.PostGet',[FHolder, Mainform.ME.ID,-1,_LIMIT_POST]));

  SetLength(aRes, 1);
  aRes[0] := Response;
  J := TJ.create(Response);

  if (j.hasValue)
  then begin
    id := J.Integer('USER_FIX');

    if id > 0
    then begin

      Response := Await( Client.RawInvokeAsync('IDBService.PostGetByID',[id]));
      SetLength(aRes,2);
      aRes[1] := aRes[0];
      aRes[0] := Response;
    end;
  end;
  j.Response := Response;
  await(DeleteAll);
  await(CreatePosts(APostId, aRes));
  Mainform.Wait := -1;

end;

procedure TfrmPosts.DeleteAll;
//Alle Beiträge löschen  - ToDo Beiträge in Liste damit sortiert werden kann
Var
  i:integer;
  o:TControl;
  s:string;
begin
  for i := self.ComponentCount -1 downto 0 do
  begin
    if self.Components[i] is TControl
    then begin
       o := TControl(self.Components[i]);
       s := o.ElementID;
       if (s.substring(0, 'beitrag'.length)  = 'beitrag') or
          (s.substring(0, 'beitrag'.length)  = 'menu')
       then o.Free;
    end;

  end;
end;

procedure TfrmPosts.CreatePosts(ANewId:integer; AResponse : Array of TXDataClientResponse);

  [async]
  function NewPost(Id, vor, post:string):TfrPost; async;
    //Legt einen neuen Beitrag an. Unterschieden wird ob "Ober-Eintrag"
    //oder Antwort auf "Ober-Eintrag"

    [async] function GetBeitragByID(AOwner:TComponent; ID:integer):TfrPOst; async;
    //sucht "TfrBeitrag" auf dem "AOwner" und gibt TfrBeitrag zurück
    //indem der Tag (wird beim erzeugen von TfrBeitrag zugewiesen)
    //von TfrmBeitrag mit der ID verglichen wird
    var
      i:integer;
    begin
      result := nil;

      for i := 0 to AOwner.ComponentCount -1 do
      begin
        if (AOwner.Components[i] is TfrPost) and
           (TfrPost(AOwner.Components[i]).ID = Id )
        then begin
          result := TfrPost(AOwner.Components[i]);
          Exit;
        end;
      end;

    end;

  Var
    o:TfrPost;
    iheight:integer;
    el: TJSHTMLElement;
    l : TLinkLabel;
  begin

    //prüfen ob es den Beitrag schon gibt
    o := await(GetBeitragByID(self, Id.ToInteger));

    if o = nil //Wenn nicht -  dann anlegen
    then begin
      result := TfrPost.Create(self);
      result.LoadFromForm;
    end
    else result := o;

    //Wenn es einen Vorgänger gibt, dann ist dieser der Parent ansonsten -
    //auf oberster Ebene anlegen.
    if vor.ToInteger > 0
    then begin
      //Vorgänger suchen
      o := await(GetBeitragByID(self, vor.ToInteger));

      if o <> nil //muss es geben sonst fehler
      then begin
        o.divComment.Visible := true;
        result.Parent := o.divComment;
        TControl(result).ElementClassName := 'beitrag_content_child';
        result.btnPin.visible := false;
      end
      else result.Id := -1;

    end
    else begin
      result.Parent := divContent;
      TControl(result).ElementClassName := 'beitrag_content_parent';
    end;

    TControl(result).ElementID := 'beitrag' + Id;
    TControl(result).elementPosition := epIgnore;
    el := TJSHTMLElement(THTMLDiv(result).ElementHandle);
    el.style.removeProperty('height');
    el.style.removeProperty('width');

  end;

 
  function HRef(ALink, AName:string):string;
  begin
    result := '<a href="' + Alink + '">'+ AName + '</a>';
 //   result := '<a href=''' + Alink + '''>'+ AName + '</a>';
 //   result := '<a href="#" id="#url#' + Alink + '">'+ AName + '</a>';
  end;

Var
  fr: TfrPost;
  k, j, i:integer;
  jImg, JSA : TJ;
  sHolder, sUser:string;
  aImages: array of string;
begin

  divMore.Visible := false;
  JImg := TJ.create;

  JSA := TJ.create(AResponse[0]);
  for j := 0 to length(aResponse) - 1 do
  begin
    JSA.Response := AResponse[j];

    for i := 0 to JSA.length-1 do
    begin
      JSA.Index := i;
      FLastID := JSA.Integer('ID');

      fr := await(NewPost( JSA.Value('ID'), JSA.Value('VOR'), JSA.Value('POST_ID')));

      if fr.Id = -1
      then continue;

      sUser   := HRef('USR='+ JSA.Value('USER_ID'), JSA.Value('USER_NICKNAME'));
      sHolder := HRef('USR='+ JSA.Value('HOLDER' ), JSA.Value('HOLDERNAME'));

      if Mainform.Me.id = JSA.Integer('USER_ID')
      then begin
        if Mainform.ME.ID = JSA.Integer('HOLDER')
        then fr.lblName.Caption  := 'Du schriebst an deine Pinnwand'
        else fr.lblName.Caption  := hRef('USR='+ JSA.Value('USER_ID'),'Du') +' schriebst an die Pinnwand von ' + sHolder;
      end
      else begin
        if JSA.Integer('USER_ID') = JSA.Integer('HOLDER')
        then fr.lblName.Caption := sUser + ' schrieb an ' + Gender(JSA.integer('ANREDE_ID'), 'seine') + ' Pinnwand'
        else fr.lblName.Caption := sUser + ' schrieb an die Pinnwand von ' + sHolder;
      end;

      fr.lblDatum.Caption   := TDatum.Get(JSA.Value('DATUM')).Zeitraum;
      fr.lblText.Caption    := '';

      fr.lblText.Caption    := JSA.Text('TEXT');
      fr.imgAvatar.URL      := await(Datenmodul.GetMedia(JSA.Integer('AVATAR_MEDIEN_ID'),3));
      fr.ID                 := JSA.Integer('ID');
      fr.PostId             := JSA.Integer('POST_ID');
      fr.ParentID           := JSA.Integer('VOR');
      fr.User               := JSA.Integer('USER_ID');
      fr.Holder             := JSA.Integer('HOLDER');
      fr.divPinned.Visible  := JSA.Integer('ID') = JSA.Integer('USER_FIX');
      fr.btnPin.OnClick     := btnFixierenClick;
      fr.imgPinned.OnClick  := btnFixierenClick;
      fr.btnEdit.OnClick    := btnNewClick;
      fr.btnComment.OnClick := btnNewClick;
      fr.btnDelete.Visible  := (fr.User = mainform.ME.ID);
      fr.btnPin.Visible     := Mainform.Meins;
      fr.btnEdit.Visible    := (fr.User = mainform.ME.ID);

      //Images anzeigen
      jImg.response := await(datenmodul.easySQL('select MEDIEN_ID from medien2Holder where MEDIEN_TYP = 1 AND HOLDER_TYP = ' + _HT_POST + ' AND HOLDER_ID = ' + fr.Id.ToString));
      if jImg.hasValue
      then begin
        setlength(aImages,jImg.Length -1);

        for k := 0 to jImg.Length -1 do
        begin
          jImg.Index := k;
          aImages[k] := jImg.value('MEDIEN_ID');
        end;

        await(ShowImages(fr.DivImg, aImages, Mainform.ME.hideFotos));
      end;

      await(GetLikeCount(fr.Id, integer(etPost), integer(ltLike), fr.divLikeWrapper, fr.divLikeUser, fr.divLikeCount));

    end;

  end;

  divMore.Visible := true;

  if ANewId > 0
  then begin
    GoToBlogItem('beitrag' + ANewId.ToString);
  end;

end;

procedure TfrmPosts.btnFixierenClick(Sender: TObject);
var
  o:TfrPost;
begin
  if FHolder = Mainform.ME.id
  then begin

    o := TfrPost(TControl(Sender).owner);

    if o.ParentID = 0
    then begin
      if Sender = o.btnPin
      then await(DatenModul.Update('USER','ID', Mainform.ME.id.toString, 'FIX', o.ID.ToString))
      else await(DatenModul.Update('USER','ID', Mainform.ME.id.toString, 'FIX', '0'));

      Init(o.Id); //Posts aktualisieren
    end
    else showmessage('Kommentare können nicht fixiert werden...');
  end;

end;

procedure TfrmPosts.divMoreClick(Sender: TObject);
Var
  aRes: array of TXDataClientResponse;
begin
  SetLength(aRes, 1);
  aRes[0] := Await( Client.RawInvokeAsync('IDBService.PostGet',[FHolder,FLastID, _LIMIT_POST]));
  CreatePosts(FLastID, aRes);
end;

procedure TfrmPosts.btnNewClick(Sender: TObject);
var
  o:TfrPost;
begin
  if Mainform.ME.Rechte.New[riPost]
  then begin
    if TControl(Sender).Name = 'btnTitel'  //komplett neuer Post
    then TfrmPostEdit.Post(FHolder, Mainform.ME.id,0, 0,  @NewID)
    else begin
      if TControl(Sender).Name = 'btnComment' //hat einen Vorgänger = Antwort auf einen bestehenden Post
      then begin
        o := TfrPost(TControl(sender).owner);
                    // Comment(AHolder,  AMe,            APost,    AParent:integer; ACallback:TCallback): integer;
        TfrmPostEdit.Comment(o.Holder, Mainform.ME.id, o.PostID, o.ID, @NewID);
      end
      else begin
        o := TfrPost(TControl(sender).owner);
        TfrmPostEdit.edit(o.ID,o.lblText.Caption, @NewID);
      end;
    end;
  end
  else meldung('Aufgrund deines Mitgliedstatus kannst Du leider keine neuen Beiträge verfassen...');
end;

procedure TfrmPosts.GoToBlogItem(AElementID:string);
begin
  asm
     document.getElementById(AElementID).scrollIntoView(false);
  end;
end;

procedure TfrmPosts.WebFormCreate(Sender: TObject);
var
  el: TJSHTMLElement;
begin
  inherited;
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.removeProperty('height');
  //beitrag_main_menu_wrapper
end;


procedure TfrmPosts.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divContent := THTMLDiv.Create('divPosts');
  divMenu := THTMLDiv.Create(Self);
  btnTitel := TImageControl.Create(Self);
  lblTitel := THTMLDiv.Create(Self);
  divMore := THTMLDiv.Create(Self);
  btnPersSave := TButton.Create(Self);
  Client := TXDataWebClient.Create(Self);

  divContent.BeforeLoadDFMValues;
  divMenu.BeforeLoadDFMValues;
  btnTitel.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  divMore.BeforeLoadDFMValues;
  btnPersSave.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frmPosts';
    Width := 819;
    Height := 481;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    divContent.SetParentComponent(Self);
    divContent.Name := 'divContent';
    divContent.Left := 8;
    divContent.Top := 8;
    divContent.Width := 797;
    divContent.Height := 385;
    divContent.ElementClassName := 'post_wrapper';
    divContent.HeightStyle := ssAuto;
    divContent.WidthStyle := ssAuto;
    divContent.ChildOrder := 1;
    divContent.ElementPosition := epIgnore;
    divContent.ElementFont := efCSS;
    divContent.Role := '';
    divMenu.SetParentComponent(divContent);
    divMenu.Name := 'divMenu';
    divMenu.Left := 3;
    divMenu.Top := 3;
    divMenu.Width := 791;
    divMenu.Height := 46;
    divMenu.ElementClassName := 'me_headline';
    divMenu.HeightStyle := ssAuto;
    divMenu.WidthStyle := ssAuto;
    divMenu.ElementPosition := epIgnore;
    divMenu.ElementFont := efCSS;
    divMenu.Role := '';
    divMenu.Visible := False;
    btnTitel.SetParentComponent(divMenu);
    btnTitel.Name := 'btnTitel';
    btnTitel.Left := 750;
    btnTitel.Top := -1;
    btnTitel.Width := 38;
    btnTitel.Height := 44;
    btnTitel.Hint := 'Schreibe einen neuen Beitrag oder f'#252'ge neue Fotos hinzu';
    btnTitel.ElementClassName := 'alben_menu_btn';
    btnTitel.HeightStyle := ssAuto;
    btnTitel.WidthStyle := ssAuto;
    btnTitel.HeightPercent := 100.000000000000000000;
    btnTitel.WidthPercent := 100.000000000000000000;
    btnTitel.Anchors := [];
    btnTitel.ChildOrder := 2;
    btnTitel.ElementFont := efCSS;
    btnTitel.ElementPosition := epIgnore;
    btnTitel.ShowHint := True;
    SetEvent(btnTitel, Self, 'OnClick', 'btnNewClick');
    btnTitel.Picture.LoadFromFile('uPosts.divMenu.btnTitel.Picture.png');
    lblTitel.SetParentComponent(divMenu);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 16;
    lblTitel.Top := 3;
    lblTitel.Width := 100;
    lblTitel.Height := 41;
    lblTitel.HeightStyle := ssAuto;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.ChildOrder := 1;
    lblTitel.ElementPosition := epIgnore;
    lblTitel.ElementFont := efCSS;
    lblTitel.Role := '';
    divMore.SetParentComponent(Self);
    divMore.Name := 'divMore';
    divMore.Left := 8;
    divMore.Top := 415;
    divMore.Width := 345;
    divMore.Height := 41;
    divMore.ElementClassName := 'editor-footer-div';
    divMore.HeightStyle := ssAuto;
    divMore.WidthStyle := ssAuto;
    divMore.ChildOrder := 50;
    divMore.ElementPosition := epIgnore;
    divMore.ElementFont := efCSS;
    divMore.Role := '';
    btnPersSave.SetParentComponent(divMore);
    btnPersSave.Name := 'btnPersSave';
    btnPersSave.Left := 25;
    btnPersSave.Top := 13;
    btnPersSave.Width := 144;
    btnPersSave.Height := 25;
    btnPersSave.Caption := 'Weiter';
    btnPersSave.ChildOrder := 1;
    btnPersSave.ElementClassName := 'start_button-lets-go';
    btnPersSave.ElementFont := efCSS;
    btnPersSave.ElementPosition := epIgnore;
    btnPersSave.HeightStyle := ssAuto;
    btnPersSave.HeightPercent := 100.000000000000000000;
    btnPersSave.WidthStyle := ssAuto;
    btnPersSave.WidthPercent := 100.000000000000000000;
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 59;
    Client.Top := 107;
  finally
    divContent.AfterLoadDFMValues;
    divMenu.AfterLoadDFMValues;
    btnTitel.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    divMore.AfterLoadDFMValues;
    btnPersSave.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.