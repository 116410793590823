unit uComment;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, XData.Web.Client, utb, uGlobal, WEBLib.WebCtrls,
  Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Forms,
  WEBLib.ComCtrls, WEBLib.Buttons, Vcl.Imaging.pngimage, Vcl.Controls,
  WEBLib.ExtCtrls, WEBLib.EditAutocomplete;

type
  TfrmComment = class(TForm)
    Client: TXDataWebClient;
    WebHTMLDiv1: THTMLDiv;
    WebHTMLDiv11: THTMLDiv;
    btnClose: TImageControl;
    lblTitel: TLabel;
    divHeader: THTMLDiv;
    EditButtons: TRichEditToolBar;
    reText: TRichEdit;
    divImg: THTMLDiv;
    WebHTMLDiv4: THTMLDiv;
    btnSave: TButton;
    btnCancel: TButton;
    btnFotosDelete: TBitBtn;
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure btnCancelClick(Sender: TObject);
    [async] procedure btnSaveClick(Sender: TObject); async;
    [async] procedure WebFormCloseQuery(Sender: TObject; var CanClose: Boolean); async;
    [async] procedure btnFotosDeleteClick(Sender: TObject); async;
  private
    FBlogTyp,
    FBlogKat,
    FLevel:integer;
    FVorschau:integer;
    FNewID:integer;
    FContainer:THtmlDiv;
    FVor, FHolder, FMe, FID, FMaster:integer;
    FTyp: TEditTyp;
    FCallback:TCallback;
    FText, FAktImage:string;
    FImages:array of string;
    FHashTags : array of TCheckBox;
    FRows:array of TBlogItem;
  public
//    class function Post(AHolder, AMe, APost, AParent:integer; ACallback:TCallback): integer;
//    class function Medien(AHolder, AMe, APost, AParent:integer; ACallback:TCallback): integer;
    class function Exec(AHolder, AMe, AID, AVor, AMaster, ATitelFoto:integer; ATitel, AText:string; ATyp: TEditTyp; ACallback:TCallback): integer;
//    class function Edit(APost:integer; AText:string; ACallback:TCallback): integer;
    class function Comment(AHolder, AMe, APost, AParent:integer; ACallback:TCallback): integer;
    [async] procedure ImageButtonClick(sender:TObject); async;
  protected procedure LoadDFMValues; override; end;

var
  frmComment: TfrmComment;

implementation
  uses uMain, uDatenModul, uMedienAuswahl;
{$R *.dfm}

class function TfrmComment.Comment(AHolder, AMe, APost, AParent:integer; ACallback:TCallback): integer;
begin
  Exec(AHolder, AMe, 0, APost, AParent, 0,'','', etBlog, ACallback);
end;

class function TfrmComment.Exec(AHolder, AMe, AID, AVor, AMaster, ATitelFoto:integer; ATitel, AText:string;  ATyp: TEditTyp; ACallback:TCallback): integer;

  [async]
  procedure AfterCreate(AForm: TObject); async;
  Var
    ht:string;
    j:TJ;
    l,k, i:integer;
    a:array of TBlogItem;
  begin
    TfrmComment(AForm).ElementHandle.style.removeProperty('position');
    TfrmComment(AForm).Parent := Mainform;
    TfrmComment(AForm).FHolder  := AHolder;
    TfrmComment(AForm).FMe      := AMe;
    TfrmComment(AForm).FID      := AID;
    TfrmComment(AForm).FMaster  := AMaster;
    TfrmComment(AForm).FVor     := AVor;
    TfrmComment(AForm).FTyp     := ATyp;
    TfrmComment(AForm).FText    := AText;
    TfrmComment(AForm).reText.Lines.Text  := AText;
    TfrmComment(AForm).editbuttons.ImageButton.OnClick := TfrmComment(AForm).ImageButtonClick;

    case ATyp of
      etBlog: ht := _HT_BLOG;
    end;

    if AVor > -1
    then TfrmComment(AForm).FImages := await(GetAndShowImages(TfrmComment(AForm).divImg, ht, AID.ToString, mainform.ME.HideFotos));

    TfrmComment(AForm).divImg.Visible := (length(TfrmComment(AForm).FImages) > 0);
    TfrmComment(AForm).btnFotosDelete.visible := TfrmComment(AForm).divImg.Visible;

    if (ATyp <> etFoto) and (ATyp <> etVideo)
    then TfrmComment(AForm).lblTitel.Caption := 'BEITRAG BEARBEITEN'
    else TfrmComment(AForm).lblTitel.Caption := 'Gib einen neuen Kommentar ein';

    TfrmComment(AForm).reText.setFocus;
  end;

Var
  frm:TfrmComment;
  FConti:THtmlDiv;
begin

  FConti := THtmlDiv.Create(Mainform);
  FConti.Parent := Mainform;
  FConti.ElementFont := efCSS;

  FConti.ElementClassName := 'editor_transparent';
  frm := TfrmComment.CreateNew(FConti.ElementID, @AfterCreate);

  frm.FContainer := FConti;
  frm.FCallback  := ACallback;

end;

procedure TfrmComment.ImageButtonClick(sender:TObject);
  [async]
  procedure InsertFoto(s:string); async;
  begin
    setlength(FImages, length(FImages) + 1);
    FImages[high(FImages)] := s;
    await(ShowImages(divImg, FImages, false));
    btnFotosDelete.visible := true;
  end;

begin
  TfrmMedienAuswahl.Fotos( Mainform.Holder.Id,
                           Mainform.ME.ID,
                           mainform.ME.Album,
                           0, 0, 0, 0,
                           @InsertFoto, 2);

end;

procedure TfrmComment.btnCancelClick(Sender: TObject);
begin
 close;
end;

procedure TfrmComment.btnFotosDeleteClick(Sender: TObject);
begin
  if await(Frage('Alle Fotos aus dem Beitrag entfernen?')) = mrYes
  then begin

    RemoveAllChildControls(divImg);
    SetLength(FImages,0);
    btnFotosDelete.visible := false;

  end;

end;

procedure TfrmComment.btnSaveClick(Sender: TObject);
var
  Response: TXDataClientResponse;
  j:TJ;
  iRow, k,i:integer;
  ht:string;
  aInt : array of integer;
  sKat:string;
begin
  case FTyp of
    etPost: ht := _HT_POST;
    etBlog: ht := _HT_BLOG;
  end;

  //gibt es Fotos oder Videos?
  //erstmal alle "alten" löschen
  await(datenmodul.EasyEXEC('DELETE from Medien2Holder ' +
                            ' WHERE HOLDER_TYP = ' + ht + ' AND ' +
                            '       HOLDER_ID  = ' + FID.tostring + ' AND ' +
                            '       MEDIEN_TYP = 1' ));

  //Gibt es Text oder Fotos?
  if (reText.PlainText > '') or (Length(FImages) > 0)
  then begin
    if FID = 0
    then begin
      // showmessage(FVor.ToString + ' - ' + FMaster.ToString);
      FID := await(datenmodul.EasyInsert('Insert into BLOG (STATUS, HOLDER, USER_ID, VOR, BLOG_ID, TEXT) ' +
                                                 ' VALUES (1, ' + FHolder.ToString + ',' + FME.ToString + ',' +
                                                                  FVor.ToString + ',' + FMaster.ToString + ',''' +
                                                                  chkStr(reText.GetContent) + ''')'));


      //await(datenmodul.EasyExec('Update BLOG set BLOG_ID = ID WHERE BLOG_ID = 0 AND ID = ' + FID.ToString));
    end
    else begin
      await(datenmodul.EasyExec('Update BLOG set TEXT = ''' + chkStr(reText.GetContent) + ''' WHERE ID = ' + FID.ToString));
    end;

    //...dann alle Fotos (inkl. evtl. neuer) wieder hinzufügen
    if Length(FImages) > 0
    then begin
      for i := 0 to length(FImages) - 1 do
      begin
        await(datenmodul.EasyInsert('Insert into Medien2Holder (Holder_Typ, Holder_ID, Medien_Typ, Medien_ID) ' +
                                    ' VALUES ( ' + ht + ',' + FID.ToString + ',1,' + FImages[i] + ')' ));
      end;
    end;

    if assigned(FCallback)
    then FCallBack(FID.ToString);

    close;
  end;
end;

procedure TfrmComment.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  FreeAndNil(FContainer);
end;

procedure TfrmComment.WebFormCloseQuery(Sender: TObject; var CanClose: Boolean);
begin
  canclose := true;
end;

procedure TfrmComment.WebFormCreate(Sender: TObject);
begin
  reText.ElementHandle.style.removeProperty('position');
end;

procedure TfrmComment.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  WebHTMLDiv11 := THTMLDiv.Create(Self);
  btnClose := TImageControl.Create(Self);
  lblTitel := TLabel.Create(Self);
  divHeader := THTMLDiv.Create(Self);
  EditButtons := TRichEditToolBar.Create('');
  btnFotosDelete := TBitBtn.Create(Self);
  reText := TRichEdit.Create(Self);
  divImg := THTMLDiv.Create(Self);
  WebHTMLDiv4 := THTMLDiv.Create(Self);
  btnSave := TButton.Create(Self);
  btnCancel := TButton.Create(Self);
  Client := TXDataWebClient.Create(Self);

  WebHTMLDiv1.BeforeLoadDFMValues;
  WebHTMLDiv11.BeforeLoadDFMValues;
  btnClose.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  divHeader.BeforeLoadDFMValues;
  EditButtons.BeforeLoadDFMValues;
  btnFotosDelete.BeforeLoadDFMValues;
  reText.BeforeLoadDFMValues;
  divImg.BeforeLoadDFMValues;
  WebHTMLDiv4.BeforeLoadDFMValues;
  btnSave.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frmComment';
    Width := 718;
    Height := 250;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCloseQuery', 'WebFormCloseQuery');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 8;
    WebHTMLDiv1.Top := 8;
    WebHTMLDiv1.Width := 697;
    WebHTMLDiv1.Height := 233;
    WebHTMLDiv1.ElementClassName := 'input_container';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv11.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv11.Name := 'WebHTMLDiv11';
    WebHTMLDiv11.Left := 23;
    WebHTMLDiv11.Top := 3;
    WebHTMLDiv11.Width := 671;
    WebHTMLDiv11.Height := 41;
    WebHTMLDiv11.ElementClassName := 'input_header';
    WebHTMLDiv11.HeightStyle := ssAuto;
    WebHTMLDiv11.WidthStyle := ssAuto;
    WebHTMLDiv11.ElementPosition := epRelative;
    WebHTMLDiv11.ElementFont := efCSS;
    WebHTMLDiv11.Role := '';
    btnClose.SetParentComponent(WebHTMLDiv11);
    btnClose.Name := 'btnClose';
    btnClose.Left := 616;
    btnClose.Top := -3;
    btnClose.Width := 46;
    btnClose.Height := 44;
    btnClose.ElementClassName := 'input_close';
    btnClose.HeightStyle := ssAuto;
    btnClose.WidthStyle := ssAuto;
    btnClose.HeightPercent := 100.000000000000000000;
    btnClose.WidthPercent := 100.000000000000000000;
    btnClose.Anchors := [];
    btnClose.ChildOrder := 1;
    btnClose.ElementFont := efCSS;
    btnClose.ElementPosition := epIgnore;
    SetEvent(btnClose, Self, 'OnClick', 'btnCancelClick');
    btnClose.Picture.LoadFromFile('uComment.WebHTMLDiv11.btnClose.Picture.png');
    lblTitel.SetParentComponent(WebHTMLDiv11);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 16;
    lblTitel.Top := 9;
    lblTitel.Width := 35;
    lblTitel.Height := 15;
    lblTitel.Caption := 'lblTitel';
    lblTitel.ElementLabelClassName := 'editor_titel';
    lblTitel.ElementFont := efCSS;
    lblTitel.HeightStyle := ssAuto;
    lblTitel.HeightPercent := 100.000000000000000000;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.WidthPercent := 100.000000000000000000;
    divHeader.SetParentComponent(WebHTMLDiv1);
    divHeader.Name := 'divHeader';
    divHeader.Left := 23;
    divHeader.Top := 50;
    divHeader.Width := 671;
    divHeader.Height := 42;
    divHeader.ElementClassName := 'kursedit_line';
    divHeader.HeightStyle := ssAuto;
    divHeader.WidthStyle := ssAuto;
    divHeader.ChildOrder := 1;
    divHeader.ElementPosition := epRelative;
    divHeader.ElementFont := efCSS;
    divHeader.Role := '';
    EditButtons.SetParentComponent(divHeader);
    EditButtons.Name := 'EditButtons';
    EditButtons.Left := 3;
    EditButtons.Top := 4;
    EditButtons.Width := 198;
    EditButtons.Height := 35;
    EditButtons.ElementClassName := 'editbuttons';
    EditButtons.HeightStyle := ssAuto;
    EditButtons.WidthStyle := ssAuto;
    EditButtons.Color := clBtnFace;
    EditButtons.ElementFont := efCSS;
    EditButtons.ElementPosition := epRelative;
    EditButtons.Hints.BeginUpdate;
    try
      EditButtons.Hints.Clear;
      EditButtons.Hints.Add('Bold');
      EditButtons.Hints.Add('Italic');
      EditButtons.Hints.Add('Underline');
      EditButtons.Hints.Add('Strike Throught');
      EditButtons.Hints.Add('Text Color');
      EditButtons.Hints.Add('Background Color');
      EditButtons.Hints.Add('Align lef');
      EditButtons.Hints.Add('Align center');
      EditButtons.Hints.Add('Align right');
      EditButtons.Hints.Add('Numbered list');
      EditButtons.Hints.Add('List');
    finally
      EditButtons.Hints.EndUpdate;
    end;
    EditButtons.RichEdit := reText;
    EditButtons.VisibleButtons := [reBold,reItalic,reUnderline,reStrikeThrough,reHyperlink,reImageInsert];
    btnFotosDelete.SetParentComponent(divHeader);
    btnFotosDelete.Name := 'btnFotosDelete';
    btnFotosDelete.Left := 480;
    btnFotosDelete.Top := 8;
    btnFotosDelete.Width := 80;
    btnFotosDelete.Height := 25;
    btnFotosDelete.Caption := 'Fotos l'#246'schen';
    btnFotosDelete.Color := clNone;
    btnFotosDelete.HeightPercent := 100.000000000000000000;
    btnFotosDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btnFotosDelete, Self, 'OnClick', 'btnFotosDeleteClick');
    reText.SetParentComponent(WebHTMLDiv1);
    reText.Name := 'reText';
    reText.Left := 23;
    reText.Top := 98;
    reText.Width := 671;
    reText.Height := 30;
    reText.ElementClassName := 'kursedit_input';
    reText.HeightStyle := ssAuto;
    reText.WidthStyle := ssAuto;
    reText.BorderStyle := bsSingle;
    reText.ChildOrder := 2;
    reText.Color := clWhite;
    divImg.SetParentComponent(WebHTMLDiv1);
    divImg.Name := 'divImg';
    divImg.Left := 23;
    divImg.Top := 134;
    divImg.Width := 671;
    divImg.Height := 38;
    divImg.ElementClassName := 'blog-edit-images-div';
    divImg.HeightStyle := ssAuto;
    divImg.WidthStyle := ssAuto;
    divImg.ChildOrder := 3;
    divImg.ElementPosition := epIgnore;
    divImg.ElementFont := efCSS;
    divImg.Role := '';
    WebHTMLDiv4.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv4.Name := 'WebHTMLDiv4';
    WebHTMLDiv4.Left := 17;
    WebHTMLDiv4.Top := 178;
    WebHTMLDiv4.Width := 671;
    WebHTMLDiv4.Height := 41;
    WebHTMLDiv4.ElementClassName := 'editor-footer-div';
    WebHTMLDiv4.HeightStyle := ssAuto;
    WebHTMLDiv4.WidthStyle := ssAuto;
    WebHTMLDiv4.ChildOrder := 4;
    WebHTMLDiv4.ElementPosition := epIgnore;
    WebHTMLDiv4.ElementFont := efCSS;
    WebHTMLDiv4.Role := '';
    btnSave.SetParentComponent(WebHTMLDiv4);
    btnSave.Name := 'btnSave';
    btnSave.Left := 9;
    btnSave.Top := 3;
    btnSave.Width := 144;
    btnSave.Height := 25;
    btnSave.Caption := 'Speichern';
    btnSave.ChildOrder := 1;
    btnSave.ElementClassName := 'start_button-lets-go';
    btnSave.ElementFont := efCSS;
    btnSave.ElementPosition := epIgnore;
    btnSave.HeightStyle := ssAuto;
    btnSave.HeightPercent := 100.000000000000000000;
    btnSave.WidthStyle := ssAuto;
    btnSave.WidthPercent := 100.000000000000000000;
    SetEvent(btnSave, Self, 'OnClick', 'btnSaveClick');
    btnCancel.SetParentComponent(WebHTMLDiv4);
    btnCancel.Name := 'btnCancel';
    btnCancel.Left := 159;
    btnCancel.Top := 5;
    btnCancel.Width := 144;
    btnCancel.Height := 25;
    btnCancel.Caption := 'Abbrechen';
    btnCancel.ChildOrder := 2;
    btnCancel.ElementClassName := 'start_button-lets-go';
    btnCancel.ElementFont := efCSS;
    btnCancel.ElementPosition := epIgnore;
    btnCancel.HeightStyle := ssAuto;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthStyle := ssAuto;
    btnCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCancel, Self, 'OnClick', 'btnCancelClick');
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 32;
    Client.Top := 128;
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    WebHTMLDiv11.AfterLoadDFMValues;
    btnClose.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    divHeader.AfterLoadDFMValues;
    EditButtons.AfterLoadDFMValues;
    btnFotosDelete.AfterLoadDFMValues;
    reText.AfterLoadDFMValues;
    divImg.AfterLoadDFMValues;
    WebHTMLDiv4.AfterLoadDFMValues;
    btnSave.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.
