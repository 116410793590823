unit uBlog;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.WebCtrls,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, XData.Web.Client, utb, uGlobal,
  WEBLib.ComCtrls, Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TfrmBlog = class(TForm)
    divContent: THTMLDiv;
    Client: TXDataWebClient;
    divMore: THTMLDiv;
    divHeader: THTMLDiv;
    WebHTMLDiv2: THTMLDiv;
    btnNew: TImageControl;
    lblTitel: THTMLDiv;
    divText: THTMLDiv;
    procedure WebFormCreate(Sender: TObject);
    [async] procedure btnNewClick(Sender: TObject); async;
    [async]
  private
    FBlogKat,
    FVor, FBlogID,
    FLastID:integer;
    FParent : TForm;
    FMe,
    FHolder : integer;
    FErsterAufruf:boolean;
    FContainer:TForm;
    FKapitel:TForm;
    FAllowReadMore:boolean;
    FCallback:TCallbackArray;
    procedure GoToBlogItem(AElementID:string);
    [async] procedure btnFixierenClick(Sender: TObject); async;
    [async] procedure CreateBlogs(ANewId:integer; AResponse :TXDataClientResponse); async;
    [async] procedure DeleteAll; async;
  public
    property Container:TForm read FContainer write FContainer;
    property Parent : TForm read FParent write FParent;
    property Holder : integer read FHolder write FHolder;
    property Me     : integer read FMe  write FMe;
    property Callback:TCallbackArray read FCallback write FCallback;
    [async]
    procedure Init(ABlogId:integer; AImgControl: TImageControl; ACallback:TCallbackArray; AAllowReadMore:boolean = true); async;
    procedure NewID(AId:string);
    procedure ReadMore(s:string);
  protected procedure LoadDFMValues; override; end;

var
  frmBlog: TfrmBlog;

implementation
  Uses uDatenmodul, uMain, ufrBlog, uBlogEdit;
{$R *.dfm}

//procedure TfrmBlog.Init(ABlogID:integer; AImgControl: TWebImageControl; AHashTag:string; AHashTagID:integer; AAllowReadMore:boolean = true);
procedure TfrmBlog.Init(ABlogID:integer; AImgControl: TImageControl; ACallback:TCallbackArray; AAllowReadMore:boolean = true);
Var
  Response: TXDataClientResponse;
  i, id:integer;
  j:TJ;
  a:array of string;
begin

  FBlogID := ABlogID;
  FHolder := Mainform.Holder.ID;
  FMe     := Mainform.ME.ID;
  FAllowReadMore := AAllowReadMore;
  FCallback := ACallback;

  J := TJ.create(Await( Client.RawInvokeAsync('IDBService.BlogGetByID',[ABlogID,0])));
  lblTitel.HTML.Text := j.Value('HEADER');
  divText.HTML.Text := j.Value('TEXT');
  AImgControl.URL := await(datenmodul.GetMedia(j.integer('MEDIEN_ID'), _Size1));

  divHeader.Visible := false;

  await(DeleteAll);
  await(CreateBlogs(FBlogId, j.Response));

end;

procedure TfrmBlog.ReadMore(s:string);
begin
end;

procedure TfrmBlog.NewID(AId:string);
begin
end;

procedure TfrmBlog.DeleteAll;
begin
end;

procedure TfrmBlog.CreateBlogs(ANewId:integer; AResponse :TXDataClientResponse);

  [async]
  function NewBlog(Id, vor, post:string):TfrBlog; async;
    //Legt einen neuen Beitrag an. Unterschieden wird ob "Ober-Eintrag"
    //oder Antwort auf "Ober-Eintrag"

    [async] function GetBeitragByID(AOwner:TComponent; ID:integer):TfrBlog; async;
    //sucht "TfrBeitrag" auf dem "AOwner" und gibt TfrBeitrag zurück
    //indem der Tag (wird beim erzeugen von TfrBeitrag zugewiesen)
    //von TfrmBeitrag mit der ID verglichen wird
    var
      i:integer;
    begin
      result := nil;

      for i := 0 to AOwner.ComponentCount -1 do
      begin
        if (AOwner.Components[i] is TfrBlog) and
           (TfrBlog(AOwner.Components[i]).ID = Id )
        then begin
          result := TfrBlog(AOwner.Components[i]);
          Exit;
        end;
      end;

    end;

  Var
    o:TfrBlog;
    iheight:integer;
    el: TJSHTMLElement;
    l : TLinkLabel;
  begin

    //prüfen ob es den Beitrag schon gibt
    o := await(GetBeitragByID(self, Id.ToInteger));

    if o = nil
    then begin
      result := TfrBlog.Create(self);
      result.LoadFromForm;
    end
    else result := o;

    //Wenn es einen Vorgänger gibt, dann ist dieser der Parent ansonsten -
    //auf oberster Ebene anlegen.
    if vor.ToInteger > 0
    then begin

      o := await(GetBeitragByID(self, vor.ToInteger));

      if o <> nil
      then begin
        o.divComment.Visible := true;
        result.Parent := o.divComment;
        TControl(result).ElementClassName := 'blog-content-child';
        result.btnPin.visible := false;
      end
      else begin //für den Fall dass "nur" der Beitrag, ohne "Hauptbeitrag" angezeigt werden soll...
        result.Parent := divContent;
        TControl(result).ElementClassName := 'blog-content-parent';
    //thb    TfrBlog(result).lblMainHeader.visible := true;
      end;

    end
    else begin
      result.Parent := divContent;
      TControl(result).ElementClassName := 'blog-content-parent';
    end;

//    TWebControl(result).ElementID := 'blog' + Id;
    TControl(result).elementPosition := epIgnore;
    el := TJSHTMLElement(THTMLDiv(result).ElementHandle);
    el.style.removeProperty('height');
    el.style.removeProperty('width');

  end;

Var
  fr:TfrBlog;
  k, i:integer;
  jImg, j : TJ;
  sText:string;
  aImages: array of string;
  lBlur:boolean;
begin
  divMore.Visible := false;
  JImg := TJ.create;
  j := TJ.create(AResponse);

  for i := 0 to j.length-1 do
  begin
    j.Index := i;
    FLastID := j.Integer('ID');

    fr := await(NewBlog( j.Value('ID'), j.Value('VOR'), j.Value('BLOG_ID')));

    if Mainform.Me.id = j.Integer('USER_ID')
    then fr.lblName.Caption  := 'Autor: Du'
    else fr.lblName.Caption  := 'Autor: ' + j.Value('NICKNAME');

//    if (j.Integer('MEDIEN_ID') > 0 ) and (i > 0)
//    then fr.imgTitel.url := await(datenmodul.GetMedia(j.Integer('MEDIEN_ID'),_SIZE2))
//    else fr.imgTitel.visible := false;

    fr.lblDatum.Caption       := 'erstellt: ' + TDatum.Get(j.Value('DATUM')).Zeitraum;
    fr.lblHeader.HTML.Text    := j.Text('HEADER');

    fr.Titel := j.Text('HEADER');

    fr.imgAvatar.URL      := await(Datenmodul.GetMedia(j.Integer('AVATAR_MEDIEN_ID'),_SIZE3));
    fr.ID                 := j.Integer('ID');
    fr.Blog               := j.Integer('BLOG_ID');
    fr.Level              := j.Integer('LEVEL');
    fr.BlogKat            := j.Integer('BLOGKAT_ID');
//    fr.BlogTyp            := j.Integer('BLOGKAT_ID');
    fr.Vor                := j.Integer('VOR');
    fr.User               := j.Integer('USER_ID');
    fr.Holder             := j.Integer('HOLDER');
    fr.MedienID           := j.Integer('MEDIEN_ID');
    fr.MainId             := j.Integer('MAIN_ID');
    fr.Sichtbar           := j.Integer('SICHTBAR');
    fr.Sichtbar_ab        := j.Value('SICHTBAR_AB');

    fr.btnPin.OnClick     := btnFixierenClick;
    fr.imgPinned.OnClick  := btnFixierenClick;
    //fr.btnNew.OnClick     := btnNewClick;
    fr.btnNew.Visible     := false; //(fr.User = mainform.ME.ID) and (fr.Blog = fr.ID);
    fr.btnDelete.Visible  := (fr.User = mainform.ME.ID);
    fr.btnEdit.Visible    := (fr.User = mainform.ME.ID);
    fr.btnPin.Visible     := false;
    fr.GoToParent         := @ReadMore;
    fr.GoToNextBlog       := FCallback;

    if (Mainform.ME.Gast = 1) and (fr.Sichtbar > 0)
    then lBlur := true
    else begin

      if (not Mainform.ME.Trainer)
      then lBlur := (fr.Sichtbar > 0) and (Mainform.ME.Rechte.Blur[TRechteIdx(ord(riTutorials))]);


      if Mainform.ME.Admin
      then lBlur := false;
    end;

    if fr.BlogKat > 0
    then aWait(ShowBlogContent(fr.lblText, j.Value('ID'), 3, lBlur, nil))
    else begin
      fr.lblTitel.Visible      := false;
      fr.lblMainHeader.Visible := false;
      fr.btnEdit.Visible       := false;
      fr.btnDelete.Visible     := false;
      fr.btnPin.Visible        := false;
      fr.lblViews.Visible      := false;

      await(GetAndShowImages(fr.divImg, _HT_BLOG, j.Value('ID'), mainform.ME.HideFotos));

      fr.lblText.HTML.Text := j.Value('TEXT');
    end;


    Mainform.SetBlur(fr.lblText,0, lBlur);
//    Mainform.SetBlur(fr.lblText,0, Mainform.ME.Rechte.Blur[TRechteIdx(ord(riTutorials))]);

    fr.lblViews.Caption := 'Aufrufe: ' + await(GetViewCount(_call_Blogs, '2', j.Value('ID')));

    await(GetLikeCount(fr.Id, integer(etBlog), integer(ltLike), fr.divLikeWrapper, fr.divLikeUser, fr.divLikeCount));

    if FAllowReadMore
    then fr.ReadMore := @ReadMore
    else fr.lblText.ElementClassName := 'beitrag_text';

    //Text verwischen
//    if mainform.ME.Rechte.Blur[riBlog]
//    then fr.lblText.ElementClassName := fr.lblText.ElementClassName + '_blur';
  end;

  divMore.Visible := true;

  if ANewId > 0
  then begin
    GoToBlogItem('beitrag' + ANewId.ToString);
  end;

end;

procedure TfrmBlog.btnFixierenClick(Sender: TObject);
begin
end;

procedure TfrmBlog.btnNewClick(Sender: TObject);
var
  o:TfrBlog;
begin
//  showmessage('Neu');
  if Mainform.ME.Rechte.New[riBlog]
  then begin
    if TControl(Sender).Name = 'btnTitel'  //komplett neuer Blog
    then TfrmBlogEdit.Blog(FHolder, Mainform.ME.id,0, 0,0,0,'','',0, 0, 0,0,'',@NewID)
    else begin //hat einen Vorgänger = Antwort auf einen bestehenden Blog
      o := TfrBlog(TControl(sender).owner);
      TfrmBlogEdit.Blog(o.Holder, Mainform.ME.id, o.ID, o.vor, o.Blog, o.MedienID, '','', o.level, o.BlogKat, o.BlogTyp, 0,'', @NewID);
    end;
  end
  else meldung('Aufgrund deines Mitgliedstatus kannst Du leider keinen neuen Blog anlegen...');
end;

procedure TfrmBlog.GoToBlogItem(AElementID:string);
begin
  asm
    document.getElementById(AElementID).scrollIntoView(false);
  end;
end;

procedure TfrmBlog.WebFormCreate(Sender: TObject);
var
  el: TJSHTMLElement;
begin
  inherited;
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.removeProperty('height');
  //beitrag_main_menu_wrapper
end;


procedure TfrmBlog.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divContent := THTMLDiv.Create(Self);
  divHeader := THTMLDiv.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  btnNew := TImageControl.Create(Self);
  lblTitel := THTMLDiv.Create(Self);
  divText := THTMLDiv.Create(Self);
  divMore := THTMLDiv.Create(Self);
  Client := TXDataWebClient.Create(Self);

  divContent.BeforeLoadDFMValues;
  divHeader.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  btnNew.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  divText.BeforeLoadDFMValues;
  divMore.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frmBlog';
    Width := 820;
    Height := 453;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    divContent.SetParentComponent(Self);
    divContent.Name := 'divContent';
    divContent.Left := 0;
    divContent.Top := 8;
    divContent.Width := 797;
    divContent.Height := 385;
    divContent.ElementClassName := 'blog_wrapper';
    divContent.HeightStyle := ssAuto;
    divContent.WidthStyle := ssAuto;
    divContent.ChildOrder := 1;
    divContent.ElementPosition := epIgnore;
    divContent.ElementFont := efCSS;
    divContent.Role := '';
    divHeader.SetParentComponent(divContent);
    divHeader.Name := 'divHeader';
    divHeader.Left := 8;
    divHeader.Top := 3;
    divHeader.Width := 766;
    divHeader.Height := 126;
    divHeader.HeightStyle := ssAuto;
    divHeader.WidthStyle := ssAuto;
    divHeader.ChildOrder := 1;
    divHeader.ElementPosition := epIgnore;
    divHeader.ElementFont := efCSS;
    divHeader.HTML.BeginUpdate;
    try
      divHeader.HTML.Clear;
      divHeader.HTML.Add('');
    finally
      divHeader.HTML.EndUpdate;
    end;
    divHeader.Role := '';
    WebHTMLDiv2.SetParentComponent(divHeader);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 3;
    WebHTMLDiv2.Top := 3;
    WebHTMLDiv2.Width := 602;
    WebHTMLDiv2.Height := 54;
    WebHTMLDiv2.ElementClassName := 'blog-headline';
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ChildOrder := 2;
    WebHTMLDiv2.ElementPosition := epIgnore;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.HTML.BeginUpdate;
    try
      WebHTMLDiv2.HTML.Clear;
      WebHTMLDiv2.HTML.Add('');
    finally
      WebHTMLDiv2.HTML.EndUpdate;
    end;
    WebHTMLDiv2.Role := '';
    btnNew.SetParentComponent(WebHTMLDiv2);
    btnNew.Name := 'btnNew';
    btnNew.Left := 553;
    btnNew.Top := 7;
    btnNew.Width := 38;
    btnNew.Height := 44;
    btnNew.ElementClassName := 'alben_menu_btn';
    btnNew.HeightStyle := ssAuto;
    btnNew.WidthStyle := ssAuto;
    btnNew.HeightPercent := 100.000000000000000000;
    btnNew.WidthPercent := 100.000000000000000000;
    btnNew.Anchors := [];
    btnNew.ChildOrder := 2;
    btnNew.ElementFont := efCSS;
    btnNew.ElementPosition := epIgnore;
    SetEvent(btnNew, Self, 'OnClick', 'btnNewClick');
    btnNew.Picture.LoadFromFile('uBlog.WebHTMLDiv2.btnNew.Picture.png');
    lblTitel.SetParentComponent(WebHTMLDiv2);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 16;
    lblTitel.Top := 3;
    lblTitel.Width := 100;
    lblTitel.Height := 41;
    lblTitel.HeightStyle := ssAuto;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.ChildOrder := 1;
    lblTitel.ElementPosition := epIgnore;
    lblTitel.ElementFont := efCSS;
    lblTitel.Role := '';
    divText.SetParentComponent(divHeader);
    divText.Name := 'divText';
    divText.Left := 0;
    divText.Top := 63;
    divText.Width := 602;
    divText.Height := 54;
    divText.ElementClassName := 'blog-headline-Text';
    divText.HeightStyle := ssAuto;
    divText.WidthStyle := ssAuto;
    divText.ChildOrder := 2;
    divText.ElementPosition := epIgnore;
    divText.ElementFont := efCSS;
    divText.Role := '';
    divMore.SetParentComponent(Self);
    divMore.Name := 'divMore';
    divMore.Left := 8;
    divMore.Top := 401;
    divMore.Width := 797;
    divMore.Height := 40;
    divMore.Margins.Top := 5;
    divMore.Margins.Bottom := 25;
    divMore.ElementClassName := 'showmore';
    divMore.HeightStyle := ssAuto;
    divMore.WidthStyle := ssAuto;
    divMore.ChildOrder := 2;
    divMore.ElementPosition := epRelative;
    divMore.ElementFont := efCSS;
    divMore.HTML.BeginUpdate;
    try
      divMore.HTML.Clear;
      divMore.HTML.Add('zeige mehr...');
    finally
      divMore.HTML.EndUpdate;
    end;
    divMore.Role := '';
    divMore.Visible := False;
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 19;
    Client.Top := 235;
  finally
    divContent.AfterLoadDFMValues;
    divHeader.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    btnNew.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    divText.AfterLoadDFMValues;
    divMore.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.

