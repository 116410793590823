unit ufrMyStuff;

interface

uses
  System.SysUtils, System.Variants, System.Classes,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, WEBLib.Controls, uTB, uGlobal,
  WEBLib.WebCtrls, Web,JS, Vcl.Imaging.jpeg, WEBLib.ExtCtrls, XData.Web.Client,
  WEBLib.Graphics, WEBLib.Forms;

type
  TfrMyStuff = class(TFrame)
    divWrapper: THTMLDiv;
    Client: TXDataWebClient;
    divAvatar: THTMLDiv;
    imgAvatar: TImageControl;
    divHeadline: THTMLDiv;
    divContent: THTMLDiv;
    divAboutContainer: THTMLDiv;
    lblAbout: THTMLDiv;
    divHobbiesContainer: THTMLDiv;
    WebHTMLDiv5: THTMLDiv;
    divHobbies: THTMLDiv;
    divReviereContainer: THTMLDiv;
    WebHTMLDiv3: THTMLDiv;
    divReviere: THTMLDiv;
    divExpertiseContainer: THTMLDiv;
    WebHTMLDiv4: THTMLDiv;
    divExpertise: THTMLDiv;
    divZeitraumContainer: THTMLDiv;
    WebHTMLDiv8: THTMLDiv;
    divZeitraum: THTMLDiv;
    divZweckContainer: THTMLDiv;
    WebHTMLDiv6: THTMLDiv;
    divZweck: THTMLDiv;
    divStatusContainer: THTMLDiv;
    WebHTMLDiv2: THTMLDiv;
    divStatus: THTMLDiv;
    divOrtContainer: THTMLDiv;
    WebHTMLDiv7: THTMLDiv;
    divOrt: THTMLDiv;
    divOrganizerContainer: THTMLDiv;
    divHolder: THTMLDiv;
    divOrganizer: THTMLDiv;
  protected
    procedure Loaded(); override;

  private
  public
    public constructor Create(AOwner:TComponent);Override;
    [async] procedure Init(AHolder:integer; AUeber:string = ''); async;
  protected procedure LoadDFMValues; override; end;

implementation
   Uses uDatenmodul, uMain;

{$R *.dfm}

constructor TfrMyStuff.Create(AOwner: TComponent);
begin
  inherited;
end;

procedure TfrMyStuff.Init(AHolder:integer; AUeber:string = '');
Var
  j:TJ;
  bFriend:boolean;
  sFieldStatus:string;
begin

  j := TJ.create(await(datenmodul.EasySQL('Select * from V_User where ID = ' + AHolder.tostring )));
  sFieldStatus := J.Value('FIELDSTATUS');

  Self.Parent.Visible := (AUeber > '') or (Mainform.ME.ID <> j.integer('ID'));
//  Self.Parent.Visible := (not Mainform.Meins);

  divStatusContainer.Visible := false;
  divZweckContainer.Visible := false;
  divZeitraumContainer.Visible := false;

  divWrapper.Visible := false;

  if AUeber = ''
  then begin
    if Mainform.ME.ID = j.integer('ID')
    then divHeadline.HTML.Text := 'Über mich'
    else divHeadline.HTML.Text := 'Über ' + j.Value('NICKNAME');
    divAvatar.Visible := false;
  end
  else begin
    divHeadline.HTML.Text := AUeber + ': ' + j.Value('NICKNAME');
    divHeadline.ElementHandle.style.setProperty('justify-content','center');
    divHeadline.ElementHandle.style.setProperty('display','flex');
    divHeadline.ElementHandle.style.setProperty('border-bottom-style','none');

    imgAvatar.URL := await(Datenmodul.GetMedia(j.integer('AVATAR_MEDIEN_ID'), _SIZE2));
    divAvatar.ElementHandle.style.setProperty('justify-content','center');
    divAvatar.ElementHandle.style.setProperty('display','flex');
    divAvatar.ElementHandle.style.setProperty('padding-top','10px');
  end;

  bFriend := await(mainform.isFriend);

  self.lblAbout.HTML.Text := j.Value('ABOUT');
  self.divAboutContainer.Visible := Mainform.ShowField(sFieldStatus,_ShowAbout, bFriend) and (j.Value('ABOUT') > '');
  self.divOrt.HTML.Text := j.Value('ORT');
  self.divOrtContainer.Visible := Mainform.ShowField(sFieldStatus,_ShowOrt, bFriend) and (j.Value('ORT') > '');

  await(FillHashTags(divHobbies,  AHolder.ToString, '', false, [],'1', false));
  await(FillHashTags(divReviere,  AHolder.ToString, '', false, [],'2', false));
  await(FillHashTags(divExpertise,AHolder.ToString, '', false, [],'3', false));

  //  tbCreateHashTags(self, divHobbies, j.Value('HOBBIES'), 'me_hashtag', nil);
//  divHobbiesContainer.Visible := Mainform.ShowField(sFieldStatus,_ShowHobbies, bFriend) and (j.Value('HOBBIES') > ',');
//  tbCreateHashTags(self, divReviere, j.Value('REVIERE'), 'me_hashtag', nil);
//  divReviereContainer.Visible := Mainform.ShowField(sFieldStatus,_ShowReviere, bFriend) and (j.Value('REVIERE') > ',');
//  tbCreateHashTags(self, divExpertise, j.Value('EXPERTISE'), 'me_hashtag', nil);
//  divExpertiseContainer.Visible := Mainform.ShowField(sFieldStatus,_ShowExpertise, bFriend) and (j.Value('EXPERTISE') > ',');

//  self.lblAbout.HTML.Text := Mainform.Holder.About;
//  self.divAboutContainer.Visible := Mainform.ShowField(_ShowAbout, bFriend) and (Mainform.Holder.About > '');
//
//  self.divOrt.HTML.Text := Mainform.Holder.Ort;
//  self.divOrtContainer.Visible := Mainform.ShowField(_ShowOrt, bFriend) and (Mainform.Holder.Ort > '');
//  tbCreateHashTags(self, divHobbies, Mainform.Holder.Hobbies, 'me_hashtag', nil);
//  divHobbiesContainer.Visible := Mainform.ShowField(_ShowHobbies, bFriend) and (Mainform.Holder.Hobbies > ',');
//  tbCreateHashTags(self, divReviere, Mainform.Holder.Reviere, 'me_hashtag', nil);
//  divReviereContainer.Visible := Mainform.ShowField(_ShowReviere, bFriend) and (Mainform.Holder.Reviere > ',');
//  tbCreateHashTags(self, divExpertise, Mainform.Holder.Expertise, 'me_hashtag', nil);
//  divExpertiseContainer.Visible := Mainform.ShowField(_ShowExpertise, bFriend) and (Mainform.Holder.Expertise > ',');

  divStatusContainer.Visible := false;
  divZweckContainer.Visible := false;
  divZeitraumContainer.Visible := false;
  divOrganizerContainer.Visible := false;

  if j.Integer('TYP') <> _UT_User //(also Gruppe oder Event)
  then begin
    //divHeadline.HTML.Text := Mainform.Holder.Nickname;
    j.Response := await (datenmodul.EasySQL('SELECT u.NICKNAME AS ORGANIZER, Land.TEXT as LAND, Ort.Text as ORT, Status.Text as STATUS, user.VON, user.BIS, ZWECK.Text as ZWECK ' +
                                            '  from User ' +
                                            '  Left join ZWECK on user.KAT_ID = ZWECK.ID ' +
                                            '  Left join status on user.STATUS_ID = status.ID ' +
                                            '  Left join Land on user.LAND_ID = Land.ID ' +
                                            '  Left join Ort on user.ORT_ID = Ort.ID ' +
                                            '  Left join User u on user.HOLDER_ID = u.ID ' +
                                            ' where User.ID = ' + AHolder.ToString));

    divOrt.HTML.Text := j.Value('LAND') + ', ' + j.Value('ORT');
    divOrtContainer.Visible := j.Value('ORT') > '';
    divStatus.HTML.Text := j.Value('STATUS');
    divStatusContainer.Visible := j.Value('STATUS') > '';
    divZweck.HTML.Text := j.Value('ZWECK');
    divZweckContainer.Visible := j.Value('ZWECK') > '';
    divZeitraum.HTML.Text := j.Date2Str('VON') + ' - ' + j.Date2Str('BIS');
    divZeitraumContainer.Visible := j.Value('VON') > '';

    if Mainform.Holder.Typ <> _UT_Gruppe
    then divOrganizer.HTML.Text := 'Gründer / Admin'
    else divOrganizer.HTML.Text := 'Veranstalter';

    divOrganizer.HTML.Text := j.Value('ORGANIZER');
    divOrganizerContainer.Visible := j.Value('ORGANIZER') > '';
    j.Free;
  end;

  divWrapper.Visible := true;
end;

procedure TfrMyStuff.Loaded();
var
  el: TJSHTMLElement;
begin
  inherited;

  TControl(self).ElementPosition := epIgnore;
  TControl(self).ElementID := 'frMyStuff';
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-style');
  el.style.removeProperty('font-size');
  el.style.removeProperty('user-select');

end;
procedure TfrMyStuff.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divWrapper := THTMLDiv.Create(Self);
  divAvatar := THTMLDiv.Create(Self);
  imgAvatar := TImageControl.Create(Self);
  divHeadline := THTMLDiv.Create(Self);
  divContent := THTMLDiv.Create(Self);
  divAboutContainer := THTMLDiv.Create(Self);
  lblAbout := THTMLDiv.Create(Self);
  divHobbiesContainer := THTMLDiv.Create(Self);
  WebHTMLDiv5 := THTMLDiv.Create(Self);
  divHobbies := THTMLDiv.Create(Self);
  divReviereContainer := THTMLDiv.Create(Self);
  WebHTMLDiv3 := THTMLDiv.Create(Self);
  divReviere := THTMLDiv.Create(Self);
  divExpertiseContainer := THTMLDiv.Create(Self);
  WebHTMLDiv4 := THTMLDiv.Create(Self);
  divExpertise := THTMLDiv.Create(Self);
  divZeitraumContainer := THTMLDiv.Create(Self);
  WebHTMLDiv8 := THTMLDiv.Create(Self);
  divZeitraum := THTMLDiv.Create(Self);
  divZweckContainer := THTMLDiv.Create(Self);
  WebHTMLDiv6 := THTMLDiv.Create(Self);
  divZweck := THTMLDiv.Create(Self);
  divStatusContainer := THTMLDiv.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  divStatus := THTMLDiv.Create(Self);
  divOrtContainer := THTMLDiv.Create(Self);
  WebHTMLDiv7 := THTMLDiv.Create(Self);
  divOrt := THTMLDiv.Create(Self);
  divOrganizerContainer := THTMLDiv.Create(Self);
  divHolder := THTMLDiv.Create(Self);
  divOrganizer := THTMLDiv.Create(Self);
  Client := TXDataWebClient.Create(Self);

  divWrapper.BeforeLoadDFMValues;
  divAvatar.BeforeLoadDFMValues;
  imgAvatar.BeforeLoadDFMValues;
  divHeadline.BeforeLoadDFMValues;
  divContent.BeforeLoadDFMValues;
  divAboutContainer.BeforeLoadDFMValues;
  lblAbout.BeforeLoadDFMValues;
  divHobbiesContainer.BeforeLoadDFMValues;
  WebHTMLDiv5.BeforeLoadDFMValues;
  divHobbies.BeforeLoadDFMValues;
  divReviereContainer.BeforeLoadDFMValues;
  WebHTMLDiv3.BeforeLoadDFMValues;
  divReviere.BeforeLoadDFMValues;
  divExpertiseContainer.BeforeLoadDFMValues;
  WebHTMLDiv4.BeforeLoadDFMValues;
  divExpertise.BeforeLoadDFMValues;
  divZeitraumContainer.BeforeLoadDFMValues;
  WebHTMLDiv8.BeforeLoadDFMValues;
  divZeitraum.BeforeLoadDFMValues;
  divZweckContainer.BeforeLoadDFMValues;
  WebHTMLDiv6.BeforeLoadDFMValues;
  divZweck.BeforeLoadDFMValues;
  divStatusContainer.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  divStatus.BeforeLoadDFMValues;
  divOrtContainer.BeforeLoadDFMValues;
  WebHTMLDiv7.BeforeLoadDFMValues;
  divOrt.BeforeLoadDFMValues;
  divOrganizerContainer.BeforeLoadDFMValues;
  divHolder.BeforeLoadDFMValues;
  divOrganizer.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frMyStuff';
    Left := 0;
    Top := 0;
    Width := 303;
    Height := 932;
    TabOrder := 0;
    divWrapper.SetParentComponent(Self);
    divWrapper.Name := 'divWrapper';
    divWrapper.Left := 3;
    divWrapper.Top := 6;
    divWrapper.Width := 286;
    divWrapper.Height := 915;
    divWrapper.ElementClassName := 'me_frame_container';
    divWrapper.HeightStyle := ssAuto;
    divWrapper.WidthStyle := ssAuto;
    divWrapper.ElementPosition := epIgnore;
    divWrapper.ElementFont := efCSS;
    divWrapper.Role := '';
    divAvatar.SetParentComponent(divWrapper);
    divAvatar.Name := 'divAvatar';
    divAvatar.Left := 15;
    divAvatar.Top := 14;
    divAvatar.Width := 257;
    divAvatar.Height := 113;
    divAvatar.HeightStyle := ssAuto;
    divAvatar.WidthStyle := ssAuto;
    divAvatar.ElementPosition := epIgnore;
    divAvatar.ElementFont := efCSS;
    divAvatar.Role := '';
    imgAvatar.SetParentComponent(divAvatar);
    imgAvatar.Name := 'imgAvatar';
    imgAvatar.Left := 16;
    imgAvatar.Top := 16;
    imgAvatar.Width := 214;
    imgAvatar.Height := 73;
    imgAvatar.ElementClassName := 'header_avatar';
    imgAvatar.HeightStyle := ssAuto;
    imgAvatar.WidthStyle := ssAuto;
    imgAvatar.HeightPercent := 100.000000000000000000;
    imgAvatar.WidthPercent := 100.000000000000000000;
    imgAvatar.ElementFont := efCSS;
    imgAvatar.ElementPosition := epIgnore;
    divHeadline.SetParentComponent(divWrapper);
    divHeadline.Name := 'divHeadline';
    divHeadline.Left := 15;
    divHeadline.Top := 133;
    divHeadline.Width := 257;
    divHeadline.Height := 49;
    divHeadline.ElementClassName := 'me_headline';
    divHeadline.HeightStyle := ssAuto;
    divHeadline.WidthStyle := ssAuto;
    divHeadline.ChildOrder := 1;
    divHeadline.ElementPosition := epRelative;
    divHeadline.ElementFont := efCSS;
    divHeadline.HTML.BeginUpdate;
    try
      divHeadline.HTML.Clear;
      divHeadline.HTML.Add(#220'ber mich');
      divHeadline.HTML.Add('');
    finally
      divHeadline.HTML.EndUpdate;
    end;
    divHeadline.Role := '';
    divContent.SetParentComponent(divWrapper);
    divContent.Name := 'divContent';
    divContent.Left := 15;
    divContent.Top := 185;
    divContent.Width := 257;
    divContent.Height := 720;
    divContent.ElementClassName := 'me_frame';
    divContent.HeightStyle := ssAuto;
    divContent.WidthStyle := ssAuto;
    divContent.ChildOrder := 2;
    divContent.ElementPosition := epIgnore;
    divContent.ElementFont := efCSS;
    divContent.HTML.BeginUpdate;
    try
      divContent.HTML.Clear;
      divContent.HTML.Add('');
    finally
      divContent.HTML.EndUpdate;
    end;
    divContent.Role := '';
    divAboutContainer.SetParentComponent(divContent);
    divAboutContainer.Name := 'divAboutContainer';
    divAboutContainer.Left := 3;
    divAboutContainer.Top := 3;
    divAboutContainer.Width := 230;
    divAboutContainer.Height := 50;
    divAboutContainer.ElementClassName := 'me_about_container';
    divAboutContainer.HeightStyle := ssAuto;
    divAboutContainer.WidthStyle := ssAuto;
    divAboutContainer.ChildOrder := 1;
    divAboutContainer.ElementPosition := epIgnore;
    divAboutContainer.ElementFont := efCSS;
    divAboutContainer.Role := '';
    lblAbout.SetParentComponent(divAboutContainer);
    lblAbout.Name := 'lblAbout';
    lblAbout.Left := 0;
    lblAbout.Top := 3;
    lblAbout.Width := 230;
    lblAbout.Height := 41;
    lblAbout.ElementClassName := 'me_about_text';
    lblAbout.HeightStyle := ssAuto;
    lblAbout.WidthStyle := ssAuto;
    lblAbout.ChildOrder := 4;
    lblAbout.ElementPosition := epIgnore;
    lblAbout.ElementFont := efCSS;
    lblAbout.HTML.BeginUpdate;
    try
      lblAbout.HTML.Clear;
      lblAbout.HTML.Add('');
    finally
      lblAbout.HTML.EndUpdate;
    end;
    lblAbout.Role := '';
    divHobbiesContainer.SetParentComponent(divContent);
    divHobbiesContainer.Name := 'divHobbiesContainer';
    divHobbiesContainer.Left := 3;
    divHobbiesContainer.Top := 59;
    divHobbiesContainer.Width := 230;
    divHobbiesContainer.Height := 82;
    divHobbiesContainer.ElementClassName := 'me_about_container';
    divHobbiesContainer.HeightStyle := ssAuto;
    divHobbiesContainer.WidthStyle := ssAuto;
    divHobbiesContainer.ChildOrder := 2;
    divHobbiesContainer.ElementPosition := epIgnore;
    divHobbiesContainer.ElementFont := efCSS;
    divHobbiesContainer.Role := '';
    WebHTMLDiv5.SetParentComponent(divHobbiesContainer);
    WebHTMLDiv5.Name := 'WebHTMLDiv5';
    WebHTMLDiv5.Left := 3;
    WebHTMLDiv5.Top := 3;
    WebHTMLDiv5.Width := 230;
    WebHTMLDiv5.Height := 34;
    WebHTMLDiv5.ElementClassName := 'me_about_header';
    WebHTMLDiv5.HeightStyle := ssAuto;
    WebHTMLDiv5.WidthStyle := ssAuto;
    WebHTMLDiv5.ChildOrder := 5;
    WebHTMLDiv5.ElementPosition := epIgnore;
    WebHTMLDiv5.ElementFont := efCSS;
    WebHTMLDiv5.HTML.BeginUpdate;
    try
      WebHTMLDiv5.HTML.Clear;
      WebHTMLDiv5.HTML.Add('Meine Hobbies');
    finally
      WebHTMLDiv5.HTML.EndUpdate;
    end;
    WebHTMLDiv5.Role := '';
    divHobbies.SetParentComponent(divHobbiesContainer);
    divHobbies.Name := 'divHobbies';
    divHobbies.Left := 0;
    divHobbies.Top := 43;
    divHobbies.Width := 230;
    divHobbies.Height := 34;
    divHobbies.ElementClassName := 'me_about_text';
    divHobbies.HeightStyle := ssAuto;
    divHobbies.WidthStyle := ssAuto;
    divHobbies.ChildOrder := 5;
    divHobbies.ElementPosition := epIgnore;
    divHobbies.ElementFont := efCSS;
    divHobbies.Role := '';
    divReviereContainer.SetParentComponent(divContent);
    divReviereContainer.Name := 'divReviereContainer';
    divReviereContainer.Left := 3;
    divReviereContainer.Top := 147;
    divReviereContainer.Width := 230;
    divReviereContainer.Height := 106;
    divReviereContainer.ElementClassName := 'me_about_container';
    divReviereContainer.HeightStyle := ssAuto;
    divReviereContainer.WidthStyle := ssAuto;
    divReviereContainer.ChildOrder := 3;
    divReviereContainer.ElementPosition := epIgnore;
    divReviereContainer.ElementFont := efCSS;
    divReviereContainer.Role := '';
    WebHTMLDiv3.SetParentComponent(divReviereContainer);
    WebHTMLDiv3.Name := 'WebHTMLDiv3';
    WebHTMLDiv3.Left := -3;
    WebHTMLDiv3.Top := 3;
    WebHTMLDiv3.Width := 230;
    WebHTMLDiv3.Height := 42;
    WebHTMLDiv3.ElementClassName := 'me_about_header';
    WebHTMLDiv3.HeightStyle := ssAuto;
    WebHTMLDiv3.WidthStyle := ssAuto;
    WebHTMLDiv3.ChildOrder := 6;
    WebHTMLDiv3.ElementPosition := epIgnore;
    WebHTMLDiv3.ElementFont := efCSS;
    WebHTMLDiv3.HTML.BeginUpdate;
    try
      WebHTMLDiv3.HTML.Clear;
      WebHTMLDiv3.HTML.Add('Meine Lieblings-Reviere');
    finally
      WebHTMLDiv3.HTML.EndUpdate;
    end;
    WebHTMLDiv3.Role := '';
    divReviere.SetParentComponent(divReviereContainer);
    divReviere.Name := 'divReviere';
    divReviere.Left := 0;
    divReviere.Top := 61;
    divReviere.Width := 230;
    divReviere.Height := 42;
    divReviere.ElementClassName := 'me_about_text';
    divReviere.HeightStyle := ssAuto;
    divReviere.WidthStyle := ssAuto;
    divReviere.ChildOrder := 6;
    divReviere.ElementPosition := epIgnore;
    divReviere.ElementFont := efCSS;
    divReviere.Role := '';
    divExpertiseContainer.SetParentComponent(divContent);
    divExpertiseContainer.Name := 'divExpertiseContainer';
    divExpertiseContainer.Left := 3;
    divExpertiseContainer.Top := 259;
    divExpertiseContainer.Width := 230;
    divExpertiseContainer.Height := 106;
    divExpertiseContainer.ElementClassName := 'me_about_container';
    divExpertiseContainer.HeightStyle := ssAuto;
    divExpertiseContainer.WidthStyle := ssAuto;
    divExpertiseContainer.ChildOrder := 4;
    divExpertiseContainer.ElementPosition := epIgnore;
    divExpertiseContainer.ElementFont := efCSS;
    divExpertiseContainer.Role := '';
    divExpertiseContainer.Visible := False;
    WebHTMLDiv4.SetParentComponent(divExpertiseContainer);
    WebHTMLDiv4.Name := 'WebHTMLDiv4';
    WebHTMLDiv4.Left := 3;
    WebHTMLDiv4.Top := 3;
    WebHTMLDiv4.Width := 230;
    WebHTMLDiv4.Height := 42;
    WebHTMLDiv4.ElementClassName := 'me_about_header';
    WebHTMLDiv4.HeightStyle := ssAuto;
    WebHTMLDiv4.WidthStyle := ssAuto;
    WebHTMLDiv4.ChildOrder := 6;
    WebHTMLDiv4.ElementPosition := epIgnore;
    WebHTMLDiv4.ElementFont := efCSS;
    WebHTMLDiv4.HTML.BeginUpdate;
    try
      WebHTMLDiv4.HTML.Clear;
      WebHTMLDiv4.HTML.Add('Meine Expertise');
    finally
      WebHTMLDiv4.HTML.EndUpdate;
    end;
    WebHTMLDiv4.Role := '';
    divExpertise.SetParentComponent(divExpertiseContainer);
    divExpertise.Name := 'divExpertise';
    divExpertise.Left := 0;
    divExpertise.Top := 61;
    divExpertise.Width := 230;
    divExpertise.Height := 42;
    divExpertise.ElementClassName := 'me_about_text';
    divExpertise.HeightStyle := ssAuto;
    divExpertise.WidthStyle := ssAuto;
    divExpertise.ChildOrder := 6;
    divExpertise.ElementPosition := epIgnore;
    divExpertise.ElementFont := efCSS;
    divExpertise.Role := '';
    divZeitraumContainer.SetParentComponent(divContent);
    divZeitraumContainer.Name := 'divZeitraumContainer';
    divZeitraumContainer.Left := 3;
    divZeitraumContainer.Top := 371;
    divZeitraumContainer.Width := 230;
    divZeitraumContainer.Height := 82;
    divZeitraumContainer.ElementClassName := 'me_about_container';
    divZeitraumContainer.HeightStyle := ssAuto;
    divZeitraumContainer.WidthStyle := ssAuto;
    divZeitraumContainer.ChildOrder := 5;
    divZeitraumContainer.ElementPosition := epIgnore;
    divZeitraumContainer.ElementFont := efCSS;
    divZeitraumContainer.Role := '';
    divZeitraumContainer.Visible := False;
    WebHTMLDiv8.SetParentComponent(divZeitraumContainer);
    WebHTMLDiv8.Name := 'WebHTMLDiv8';
    WebHTMLDiv8.Left := 13;
    WebHTMLDiv8.Top := 3;
    WebHTMLDiv8.Width := 230;
    WebHTMLDiv8.Height := 34;
    WebHTMLDiv8.ElementClassName := 'me_about_header';
    WebHTMLDiv8.HeightStyle := ssAuto;
    WebHTMLDiv8.WidthStyle := ssAuto;
    WebHTMLDiv8.ChildOrder := 5;
    WebHTMLDiv8.ElementPosition := epIgnore;
    WebHTMLDiv8.ElementFont := efCSS;
    WebHTMLDiv8.HTML.BeginUpdate;
    try
      WebHTMLDiv8.HTML.Clear;
      WebHTMLDiv8.HTML.Add('Zeitraum');
    finally
      WebHTMLDiv8.HTML.EndUpdate;
    end;
    WebHTMLDiv8.Role := '';
    divZeitraum.SetParentComponent(divZeitraumContainer);
    divZeitraum.Name := 'divZeitraum';
    divZeitraum.Left := 0;
    divZeitraum.Top := 43;
    divZeitraum.Width := 230;
    divZeitraum.Height := 34;
    divZeitraum.ElementClassName := 'me_about_text';
    divZeitraum.HeightStyle := ssAuto;
    divZeitraum.WidthStyle := ssAuto;
    divZeitraum.ChildOrder := 5;
    divZeitraum.ElementPosition := epIgnore;
    divZeitraum.ElementFont := efCSS;
    divZeitraum.Role := '';
    divZweckContainer.SetParentComponent(divContent);
    divZweckContainer.Name := 'divZweckContainer';
    divZweckContainer.Left := 3;
    divZweckContainer.Top := 547;
    divZweckContainer.Width := 230;
    divZweckContainer.Height := 82;
    divZweckContainer.ElementClassName := 'me_about_container';
    divZweckContainer.HeightStyle := ssAuto;
    divZweckContainer.WidthStyle := ssAuto;
    divZweckContainer.ChildOrder := 7;
    divZweckContainer.ElementPosition := epIgnore;
    divZweckContainer.ElementFont := efCSS;
    divZweckContainer.Role := '';
    divZweckContainer.Visible := False;
    WebHTMLDiv6.SetParentComponent(divZweckContainer);
    WebHTMLDiv6.Name := 'WebHTMLDiv6';
    WebHTMLDiv6.Left := 3;
    WebHTMLDiv6.Top := 3;
    WebHTMLDiv6.Width := 230;
    WebHTMLDiv6.Height := 34;
    WebHTMLDiv6.ElementClassName := 'me_about_header';
    WebHTMLDiv6.HeightStyle := ssAuto;
    WebHTMLDiv6.WidthStyle := ssAuto;
    WebHTMLDiv6.ChildOrder := 5;
    WebHTMLDiv6.ElementPosition := epIgnore;
    WebHTMLDiv6.ElementFont := efCSS;
    WebHTMLDiv6.HTML.BeginUpdate;
    try
      WebHTMLDiv6.HTML.Clear;
      WebHTMLDiv6.HTML.Add('Zweck');
    finally
      WebHTMLDiv6.HTML.EndUpdate;
    end;
    WebHTMLDiv6.Role := '';
    divZweck.SetParentComponent(divZweckContainer);
    divZweck.Name := 'divZweck';
    divZweck.Left := 0;
    divZweck.Top := 43;
    divZweck.Width := 230;
    divZweck.Height := 34;
    divZweck.ElementClassName := 'me_about_text';
    divZweck.HeightStyle := ssAuto;
    divZweck.WidthStyle := ssAuto;
    divZweck.ChildOrder := 5;
    divZweck.ElementPosition := epIgnore;
    divZweck.ElementFont := efCSS;
    divZweck.Role := '';
    divStatusContainer.SetParentComponent(divContent);
    divStatusContainer.Name := 'divStatusContainer';
    divStatusContainer.Left := 3;
    divStatusContainer.Top := 635;
    divStatusContainer.Width := 230;
    divStatusContainer.Height := 82;
    divStatusContainer.ElementClassName := 'me_about_container';
    divStatusContainer.HeightStyle := ssAuto;
    divStatusContainer.WidthStyle := ssAuto;
    divStatusContainer.ChildOrder := 8;
    divStatusContainer.ElementPosition := epIgnore;
    divStatusContainer.ElementFont := efCSS;
    divStatusContainer.Role := '';
    divStatusContainer.Visible := False;
    WebHTMLDiv2.SetParentComponent(divStatusContainer);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 3;
    WebHTMLDiv2.Top := 3;
    WebHTMLDiv2.Width := 230;
    WebHTMLDiv2.Height := 34;
    WebHTMLDiv2.ElementClassName := 'me_about_header';
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ChildOrder := 5;
    WebHTMLDiv2.ElementPosition := epIgnore;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.HTML.BeginUpdate;
    try
      WebHTMLDiv2.HTML.Clear;
      WebHTMLDiv2.HTML.Add('Privatsph'#228're');
    finally
      WebHTMLDiv2.HTML.EndUpdate;
    end;
    WebHTMLDiv2.Role := '';
    divStatus.SetParentComponent(divStatusContainer);
    divStatus.Name := 'divStatus';
    divStatus.Left := 0;
    divStatus.Top := 43;
    divStatus.Width := 230;
    divStatus.Height := 34;
    divStatus.ElementClassName := 'me_about_text';
    divStatus.HeightStyle := ssAuto;
    divStatus.WidthStyle := ssAuto;
    divStatus.ChildOrder := 5;
    divStatus.ElementPosition := epIgnore;
    divStatus.ElementFont := efCSS;
    divStatus.Role := '';
    divOrtContainer.SetParentComponent(divContent);
    divOrtContainer.Name := 'divOrtContainer';
    divOrtContainer.Left := 3;
    divOrtContainer.Top := 459;
    divOrtContainer.Width := 230;
    divOrtContainer.Height := 82;
    divOrtContainer.ElementClassName := 'me_about_container';
    divOrtContainer.HeightStyle := ssAuto;
    divOrtContainer.WidthStyle := ssAuto;
    divOrtContainer.ChildOrder := 6;
    divOrtContainer.ElementPosition := epIgnore;
    divOrtContainer.ElementFont := efCSS;
    divOrtContainer.Role := '';
    divOrtContainer.Visible := False;
    WebHTMLDiv7.SetParentComponent(divOrtContainer);
    WebHTMLDiv7.Name := 'WebHTMLDiv7';
    WebHTMLDiv7.Left := 3;
    WebHTMLDiv7.Top := 3;
    WebHTMLDiv7.Width := 230;
    WebHTMLDiv7.Height := 34;
    WebHTMLDiv7.ElementClassName := 'me_about_header';
    WebHTMLDiv7.HeightStyle := ssAuto;
    WebHTMLDiv7.WidthStyle := ssAuto;
    WebHTMLDiv7.ChildOrder := 5;
    WebHTMLDiv7.ElementPosition := epIgnore;
    WebHTMLDiv7.ElementFont := efCSS;
    WebHTMLDiv7.HTML.BeginUpdate;
    try
      WebHTMLDiv7.HTML.Clear;
      WebHTMLDiv7.HTML.Add('Ort');
    finally
      WebHTMLDiv7.HTML.EndUpdate;
    end;
    WebHTMLDiv7.Role := '';
    divOrt.SetParentComponent(divOrtContainer);
    divOrt.Name := 'divOrt';
    divOrt.Left := 0;
    divOrt.Top := 43;
    divOrt.Width := 230;
    divOrt.Height := 34;
    divOrt.ElementClassName := 'me_about_text';
    divOrt.HeightStyle := ssAuto;
    divOrt.WidthStyle := ssAuto;
    divOrt.ChildOrder := 5;
    divOrt.ElementPosition := epIgnore;
    divOrt.ElementFont := efCSS;
    divOrt.Role := '';
    divOrganizerContainer.SetParentComponent(divContent);
    divOrganizerContainer.Name := 'divOrganizerContainer';
    divOrganizerContainer.Left := 3;
    divOrganizerContainer.Top := 810;
    divOrganizerContainer.Width := 230;
    divOrganizerContainer.Height := 82;
    divOrganizerContainer.ElementClassName := 'me_about_container';
    divOrganizerContainer.HeightStyle := ssAuto;
    divOrganizerContainer.WidthStyle := ssAuto;
    divOrganizerContainer.ChildOrder := 8;
    divOrganizerContainer.ElementPosition := epIgnore;
    divOrganizerContainer.ElementFont := efCSS;
    divOrganizerContainer.Role := '';
    divOrganizerContainer.Visible := False;
    divHolder.SetParentComponent(divOrganizerContainer);
    divHolder.Name := 'divHolder';
    divHolder.Left := 3;
    divHolder.Top := 3;
    divHolder.Width := 230;
    divHolder.Height := 34;
    divHolder.ElementClassName := 'me_about_header';
    divHolder.HeightStyle := ssAuto;
    divHolder.WidthStyle := ssAuto;
    divHolder.ChildOrder := 5;
    divHolder.ElementPosition := epIgnore;
    divHolder.ElementFont := efCSS;
    divHolder.HTML.BeginUpdate;
    try
      divHolder.HTML.Clear;
      divHolder.HTML.Add('Veranstalter');
    finally
      divHolder.HTML.EndUpdate;
    end;
    divHolder.Role := '';
    divOrganizer.SetParentComponent(divOrganizerContainer);
    divOrganizer.Name := 'divOrganizer';
    divOrganizer.Left := 0;
    divOrganizer.Top := 43;
    divOrganizer.Width := 230;
    divOrganizer.Height := 34;
    divOrganizer.ElementClassName := 'me_about_text';
    divOrganizer.HeightStyle := ssAuto;
    divOrganizer.WidthStyle := ssAuto;
    divOrganizer.ChildOrder := 5;
    divOrganizer.ElementPosition := epIgnore;
    divOrganizer.ElementFont := efCSS;
    divOrganizer.Role := '';
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 19;
    Client.Top := 19;
  finally
    divWrapper.AfterLoadDFMValues;
    divAvatar.AfterLoadDFMValues;
    imgAvatar.AfterLoadDFMValues;
    divHeadline.AfterLoadDFMValues;
    divContent.AfterLoadDFMValues;
    divAboutContainer.AfterLoadDFMValues;
    lblAbout.AfterLoadDFMValues;
    divHobbiesContainer.AfterLoadDFMValues;
    WebHTMLDiv5.AfterLoadDFMValues;
    divHobbies.AfterLoadDFMValues;
    divReviereContainer.AfterLoadDFMValues;
    WebHTMLDiv3.AfterLoadDFMValues;
    divReviere.AfterLoadDFMValues;
    divExpertiseContainer.AfterLoadDFMValues;
    WebHTMLDiv4.AfterLoadDFMValues;
    divExpertise.AfterLoadDFMValues;
    divZeitraumContainer.AfterLoadDFMValues;
    WebHTMLDiv8.AfterLoadDFMValues;
    divZeitraum.AfterLoadDFMValues;
    divZweckContainer.AfterLoadDFMValues;
    WebHTMLDiv6.AfterLoadDFMValues;
    divZweck.AfterLoadDFMValues;
    divStatusContainer.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    divStatus.AfterLoadDFMValues;
    divOrtContainer.AfterLoadDFMValues;
    WebHTMLDiv7.AfterLoadDFMValues;
    divOrt.AfterLoadDFMValues;
    divOrganizerContainer.AfterLoadDFMValues;
    divHolder.AfterLoadDFMValues;
    divOrganizer.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.
