unit uCrypt;

interface
  uses Math;

function Crypt(const Text: string; Key: Integer): string;

implementation

function Crypt(const Text: string; Key: Integer): string;
var
  i: Integer;
begin
  SetLength(Result, Length(Text));

  for i:=1 to Length(Text) do
  begin
    if Text[i] > #31 then
      Result[i] := Chr(Ord(Text[i]) xor Key)
    else
      Result[i] := Text[i];
  end;
end;

end.
