unit uMeldung;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.ComCtrls,
  Vcl.Controls, WEBLib.WebCtrls, utb, uGlobal;

type
  TEditCollection = record
    Edit:TEdit;
    Caption:string;
    Required:boolean;
    Typ:integer;
  end;

type
  TFieldCollection = record
    Caption:string;
    Text:string;
    Required:boolean;
    Typ:integer;
  end;

type
  TfrmMeldung = class(TForm)
    divWrapper: THTMLDiv;
    divTitel: THTMLDiv;
    divCenter: THTMLDiv;
    pFooter: THTMLDiv;
    btnSave: TButton;
    btnCancel: TButton;
    procedure WebFormCreate(Sender: TObject);
    [async] procedure btnSaveClick(Sender: TObject); async;
    procedure btnCancelClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
  private
    FCollection: array of TEditCollection;
    FCallback:TCallbackArray;
    FContainer:THtmlDiv;
  public
   [async] class procedure Exec(ATitel, AText:string; ABtnText:array of string; ACallbackArray:TCallbackArray); async;
  protected procedure LoadDFMValues; override; end;

var
  frmMeldung: TfrmMeldung;

implementation
   uses uMain;
{$R *.dfm}

procedure TfrmMeldung.btnCancelClick(Sender: TObject);
begin
  if Assigned(FCallBack)
  then FCallBack(['2']);
  close;
end;

procedure TfrmMeldung.btnSaveClick(Sender: TObject);
begin
  if Assigned(FCallBack)
  then FCallBack(['1']);

  close;
end;

class procedure TfrmMeldung.Exec(ATitel, AText:string; ABtnText:array of string; ACallbackArray:TCallbackArray);

  [async] procedure AfterCreate(AForm: TObject); async;
  Var
    s:string;
    i:integer;
    divHeader:THTMLDiv;
    edText: TEdit;
  begin

    TfrmMeldung(AForm).ElementHandle.style.removeProperty('position');
    TfrmMeldung(AForm).divWrapper.ElementHandle.style.removeProperty('height');

    if ATitel > ''
    then TfrmMeldung(AForm).divTitel.HTMl.Text := ATitel;
    if AText > ''
    then TfrmMeldung(AForm).divCenter.HTMl.Text := AText;

    if length(aBtnText) > 0
    then TfrmMeldung(AForm).BtnSave.caption := aBtnText[0];
    if length(aBtnText) = 2
    then TfrmMeldung(AForm).BtnCancel.caption := aBtnText[1];

    TfrmMeldung(AForm).BtnSave.visible  := (length(aBtnText) >= 1);
    TfrmMeldung(AForm).BtnCancel.visible:= (length(aBtnText) >= 2);
  end;

Var
  frm:TfrmMeldung;
  FConti:THtmlDiv;
begin
  FConti := THtmlDiv.Create(Mainform);
  FConti.Parent := Mainform;
  FConti.ElementClassName := 'editor_transparent';
  FConti.HeightStyle:= ssAuto;
  FConti.WidthStyle := ssAuto;
  FConti.ElementFont := efCSS;
  FConti.ElementHandle.style.removeProperty('height');
  frm := TfrmMeldung.CreateNew(FConti.ElementID, @AfterCreate);
  frm.FContainer := FConti;
  frm.FCallback  := ACallbackArray;
end;

procedure TfrmMeldung.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  FreeAndNil(FContainer);
end;

procedure TfrmMeldung.WebFormCreate(Sender: TObject);
 var
  el: TJSHTMLElement;
begin
  inherited;
  TControl(self).ElementPosition := epIgnore;
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-style');
  el.style.removeProperty('font-size');
end;

procedure TfrmMeldung.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divWrapper := THTMLDiv.Create(Self);
  divTitel := THTMLDiv.Create(Self);
  divCenter := THTMLDiv.Create(Self);
  pFooter := THTMLDiv.Create(Self);
  btnSave := TButton.Create(Self);
  btnCancel := TButton.Create(Self);

  divWrapper.BeforeLoadDFMValues;
  divTitel.BeforeLoadDFMValues;
  divCenter.BeforeLoadDFMValues;
  pFooter.BeforeLoadDFMValues;
  btnSave.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  try
    Name := 'frmMeldung';
    Width := 490;
    Height := 349;
    ElementFont := efCSS;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    divWrapper.SetParentComponent(Self);
    divWrapper.Name := 'divWrapper';
    divWrapper.Left := 9;
    divWrapper.Top := 8;
    divWrapper.Width := 473;
    divWrapper.Height := 333;
    divWrapper.ElementClassName := 'editor-container';
    divWrapper.HeightStyle := ssAuto;
    divWrapper.WidthStyle := ssAuto;
    divWrapper.ElementPosition := epIgnore;
    divWrapper.ElementFont := efCSS;
    divWrapper.Role := '';
    divTitel.SetParentComponent(divWrapper);
    divTitel.Name := 'divTitel';
    divTitel.Left := 16;
    divTitel.Top := 11;
    divTitel.Width := 441;
    divTitel.Height := 38;
    divTitel.ElementClassName := 'edit-header';
    divTitel.HeightStyle := ssAuto;
    divTitel.WidthStyle := ssAuto;
    divTitel.ElementPosition := epIgnore;
    divTitel.ElementFont := efCSS;
    divTitel.HTML.BeginUpdate;
    try
      divTitel.HTML.Clear;
      divTitel.HTML.Add('Titel');
    finally
      divTitel.HTML.EndUpdate;
    end;
    divTitel.Role := '';
    divCenter.SetParentComponent(divWrapper);
    divCenter.Name := 'divCenter';
    divCenter.Left := 16;
    divCenter.Top := 55;
    divCenter.Width := 441;
    divCenter.Height := 177;
    divCenter.ElementClassName := 'meldung-center-container';
    divCenter.HeightStyle := ssAuto;
    divCenter.WidthStyle := ssAuto;
    divCenter.ChildOrder := 1;
    divCenter.ElementPosition := epIgnore;
    divCenter.ElementFont := efCSS;
    divCenter.Role := '';
    pFooter.SetParentComponent(divWrapper);
    pFooter.Name := 'pFooter';
    pFooter.Left := 16;
    pFooter.Top := 247;
    pFooter.Width := 441;
    pFooter.Height := 73;
    pFooter.ElementClassName := 'editor-footer-div';
    pFooter.HeightStyle := ssAuto;
    pFooter.WidthStyle := ssAuto;
    pFooter.ChildOrder := 5;
    pFooter.ElementPosition := epIgnore;
    pFooter.ElementFont := efCSS;
    pFooter.Role := '';
    btnSave.SetParentComponent(pFooter);
    btnSave.Name := 'btnSave';
    btnSave.Left := 170;
    btnSave.Top := 24;
    btnSave.Width := 96;
    btnSave.Height := 25;
    btnSave.Caption := 'Speichern';
    btnSave.ElementClassName := 'start_button-lets-go';
    btnSave.ElementFont := efCSS;
    btnSave.ElementPosition := epIgnore;
    btnSave.HeightStyle := ssAuto;
    btnSave.HeightPercent := 100.000000000000000000;
    btnSave.WidthStyle := ssAuto;
    btnSave.WidthPercent := 100.000000000000000000;
    SetEvent(btnSave, Self, 'OnClick', 'btnSaveClick');
    btnCancel.SetParentComponent(pFooter);
    btnCancel.Name := 'btnCancel';
    btnCancel.Left := 272;
    btnCancel.Top := 24;
    btnCancel.Width := 96;
    btnCancel.Height := 25;
    btnCancel.Caption := 'Abbrechen';
    btnCancel.ChildOrder := 2;
    btnCancel.ElementClassName := 'start_button-lets-go';
    btnCancel.ElementFont := efCSS;
    btnCancel.ElementPosition := epIgnore;
    btnCancel.HeightStyle := ssAuto;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthStyle := ssAuto;
    btnCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCancel, Self, 'OnClick', 'btnCancelClick');
  finally
    divWrapper.AfterLoadDFMValues;
    divTitel.AfterLoadDFMValues;
    divCenter.AfterLoadDFMValues;
    pFooter.AfterLoadDFMValues;
    btnSave.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
  end;
end;

end.