{*************************************************************************}
{                                                                         }
{ written by TMS Software                                                 }
{           copyright (c)  2023                                           }
{           Email : info@tmssoftware.com                                  }
{           Web : https://www.tmssoftware.com                             }
{                                                                         }
{ The source code is given as is. The author is not responsible           }
{ for any possible damage done due to the use of this code.               }
{ The component can be freely used in any application. The complete       }
{ source code remains property of the author and may not be distributed,  }
{ published, given or sold in any form as such. No parts of the source    }
{ code can be included in any other component or application without      }
{ written authorization of the author.                                    }
{*************************************************************************}

unit WEBLib.TMSFNCMemo;

{$I WEBLib.TMSFNCDefines.inc}

{$IFDEF WEBLIB}
{$DEFINE LCLWEBLIB}
{$DEFINE LCLWEBLIBTHREAD}
{$ENDIF}
{$IFDEF LCLLIB}
{$DEFINE LCLWEBLIB}
{$DEFINE LCLLIBTHREAD}
{$DEFINE LCLWEBLIBTHREAD}
{$ENDIF}

{$IFNDEF LCLWEBLIB}
{$HINTS OFF}
{$IF COMPILERVERSION <= 26}
{$DEFINE LCLWEBLIBTHREAD}
{$DEFINE LCLLIBTHREAD}
{$IFEND}
{$HINTS ON}
{$ENDIF}

interface

uses
  {$IFDEF MSWINDOWS}
  Windows,
  {$ENDIF}
  Classes, WEBLib.Forms, SysUtils, WEBLib.Dialogs, StrUtils, TypInfo
  {$IFDEF FMXLIB}
  , System.UITypes, Generics.Collections, System.Zip, IOUtils, FMX.Types
  {$ENDIF}
  {$IFDEF VCLLIB}
  , Vcl.Controls, Generics.Collections, System.Zip, IOUtils
  {$ENDIF}
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  , System.Types, System.JSON
  {$ENDIF}
  {$IFDEF LCLLIB}
  , fpjson, Controls, fgl, FileUtil, paszlib, zipper
  {$ENDIF}
  {$ENDIF}
  {$IFDEF WEBLIB}
  , WEBLIB.Controls, Web, WEBLIB.JSON, Generics.Collections, Types
  {$ENDIF}
  , WEBLib.TMSFNCWebBrowser, WEBLib.TMSFNCCustomWEBComponent,  WEBLib.TMSFNCCustomWEBControl,
  WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes, WEBLib.TMSFNCUtils, WEBLib.TMSFNCTypes,
  WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCPersistence, WEBLib.TMSFNCMemo.Consts, WEBLib.TMSFNCCloudBase;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 1; // Minor version Snr.
  REL_VER = 0; // Release nr.
  BLD_VER = 0; // Build nr.

  //v1.0.0.0 : First release
  //v1.0.0.1 : Fixed : Missing documentation link
  //         : Fixed : Resource extraction in dependent packages
  //         : Fixed : Issue with font initialization in VCL high DPI
  //         : Fixed : Threading issues on Lazarus
  //         : Fixed : WEB Core loading multiple instances
  //         : Fixed : Last item code completion list not rendering
  //         : Fixed : Drag and drop not automatically loading file / inserting text
  //         : Improved : AutoGlyphMargin property for automatically showing glyphmargin when adding breakpoints/ bookmarks
  //         : Improved : Automatic Drag and drop of files and text when not implemeting events
  //         : Fixed : LibraryLocation defaults to llOffline
  //         : Fixed : SetText String value too long in WEB Core
  //         : Fixed : Simplified ToJSONInternal of TTMSFNCCodeCompletionList
  //         : Improved : CommandPalette option to disable it
  //         : Improved : WordWrap options added
  //v1.0.0.2 : Improved : White border is gone in dark mode
  //v1.0.0.3 : Fixed : Issue with UTF8 encoding
  //v1.0.0.4 : Fixed : Issue with code completion and clipboard encoding
  //v1.1.0.0 : Improved : File extension - Language map
  //         : Improved : Multiple sources to switch between
  //v1.1.0.1 : Improved : UseInternalCodeCompletion property to allow the use of internal HTML, CSS & JS Code completion

type
  TTMSFNCCustomMemo = class;
  TTMSFNCMemoOptions = class;
  TTMSFNCMemoCodeCompletionItem = class;
  TTMSFNCMemoCodeCompletion = class;
  TTMSFNCMemoFont = class(TTMSFNCGraphicsFont);
  TTMSFNCMemoFindBehaviour = (fbDown, fbUp, fbAll);

  TTMSFNCMemoFindOptions = record
    SearchOnlyEditableRange: Boolean;
    SetSelect: Boolean;
    IsRegex: Boolean;
    MatchCase: Boolean;
    WordSeparators: String;
    CaptureMatches: Boolean;
    LimitResultCount: Integer;
  end;

  TTMSFNCMemoMatch = record
    Match: String;
    Position: Integer;
    Range: Integer;
  end;

  TTMSFNCMemoCaretPosition = record
    Line: Integer;
    Pos: Integer;
  end;

  TTMSFNCMemoLibraryLocation = (llOnline, llOffline);
  TTMSFNCMemoCursorPositionEvent = procedure(Sender: TObject; APosition: Integer) of object;
  TTMSFNCMemoSelectionEvent = procedure(Sender: TObject; SelStart, SelRange: Integer) of object;
  TTMSFNCMemoSuggestionEvent = procedure(Sender: TObject; Token: String;CustomCompletionList: TTMSFNCMemoCodeCompletion; Position: TTMSFNCMemoCaretPosition) of object;
  TTMSFNCMemoLanguage = (mlAbap, mlAes, mlApex, mlAzcli, mlBat, mlBicep, mlC, mlCameligo, mlClojure, mlCoffeeScript, mlCpp, mlCsharp, mlCsp, mlCSS, mlCypher, mlDart,
  mlDockerFile, mlEcl, mlElixir, mlFlow9, mlFreeMarker2, mlFsharp, mlGo, mlGraphql, mlHandlebars, mlHcl, mlHtml, mlIni, mlJava, mlJavascript, mlJSON, mlJulia, mlKotlin,
  mlLess, mlLexon, mlLiquid, mlLua, mlM3, mlMarkdown, mlMips, mlMsdax, mlMySQL, mlObjectiveC, mlPascal, mlPascalLigo, mlPerl, mlPgsql, mlPhp, mlPla, mlPlainText,
  mlPostiats, mlPowerquery, mlPowershell, mlProto, mlPug, mlPython, mlQsharp, mlR, mlRazor, mlRedis, mlRedshift, mlRestructuredText, mlRuby, mlRust, mlSb, mlScala, mlScheme,
  mlScss, mlShell, mlSol, mlSparql, mlSQL, mlSt, mlSwift, mlSystemVerilog, mlTcl, mlTwig, mlTypeScript, mlVb, mlVerilog, mlXml, mlYaml);

  TTMSFNCMemoSuggestionKind = (skText, skClass, skColor, skConstant, skContructor, skCustomColor, skEnum, skEnumMember, skEvent, skField, skFile, skFolder, skFunction,
  skInterface, skIssue, skKeyword, skMethod, skModule, skOperator, skProperty, skReference, skSnippet, skStruct, skTypeParameter, skUnit, skUser, skValue, skVariable);
  TTMSFNCMemoSuggestionInsertTextRule = (itrNone, itrInsertAsSnippet, itrKeepWhitespace);

  TTMSFNCMemoTheme = (mtVisualStudio, mtVisualStudioDark, mtHighContrastDark);
  TTMSFNCMemoLineHighLight = (hlNone, hlAll, hlLine, hlGutter);
  TTMSFNCMemoWhitepace = (mwNone, mwAll, mwBoundary, mwSelection, mwTrailing);

  TTMSFNCMemoMinimapSliderOptions = (soAlways, soMouseOver);
  TTMSFNCMemoMinimapSideOptions = (soRight, soLeft);
  TTMSFNCMemoMinimapSizeOptions = (soProportional, soFill, soFit, soActual);
  TTMSFNCMemoScrollbarOptions = (soAuto, soVisible, soHidden);
  TTMSFNCMemoQuickSuggestionMode = (smOn, smOff, smInline);
  TTMSFNCMemoDragObjectKind = (otText, otFile);

  {* TTMSFNCMemoThemeColors = class(TPersistent)
  published
    property Foreground: TColor;
    property ErrorForeground: TColor;
    property DescriptionForeground: TColor;
    property FocusBorder: TColor;
    property ContrastBorder: TColor;
    property ContrastActiveBorder: TColor;
    property SelectionBackground: TColor;
    property TextSeparatorForeground: TColor;
    property TextLinkForeground: TColor;
    property TextPreFormat: TColor;
    property TextBlockQuoteBackground: TColor;
    property TextBlockQuoteForeground: TColor;
    property TextBlockQuoteBorder: TColor;
    property TextCodeBlockBackground: TColor;
    property WidgetShadow: TColor;
    property InputBackground: TColor;
    property InputForeground: TColor;
    property InputBorder: TColor;
    property InputOptionActiveBorder: TColor;
    property InputPlaceholderForeground: TColor;
    property InputValidationInfoBackground: TColor;
    property InputValidationInfoBorder: TColor;
    property InputValidationWarningBackground: TColor;
    property InputValidationWarningBorder: TColor;
    property InputValidationErrorBackground: TColor;
    property InputValidationErrorBorder: TColor;
    property DropdownBackground: TColor;
    property DropdownForeground: TColor;
    property DropdownBorder: TColor;
    property ListFocusBackground: TColor;
    property ListFocusForeground: TColor;
    property ListActiveSelectionBackground: TColor;
    property ListActiveSelectionForeground: TColor;
    property ListInactiveSelectionBackground: TColor;
    property ListInactiveSelectionForeground: TColor;
    property ListHoverBackground: TColor;
    property ListHoverForeground: TColor;
    property ListDropBackground: TColor;
    property ListHighlightForeground: TColor;
    property PickerGroupForeground: TColor;
    property PickerGroupBorder: TColor;
    property ButtonForeground: TColor;
    property ButtonBackground: TColor;
    property HoverBackground: TColor;
    property BadgeBackground: TColor;
    property BadgeForeground: TColor;
    property ScrollbarShadow: TColor;
    property ScrollbarSliderBackground: TColor;
    property ScrollbackSliderHoverBackground: TColor;
    property ScrollbackSliderActiveBackground: TCOlor;
    property ProgressBarBackground: TColor;
    property EditorBackground: TColor;
    property EditorForeground: TColor;
    property EditorWidgetBackground: TColor;
    property EditorWidgetBorder: TColor;

  end;   *}

  TTMSFNCMemoDragFile = class(TPersistent)
  private
    FName: string;
    FMIMEType: string;
    FSize: Integer;
    FMemo: TTMSFNCCustomMemo;
    procedure SetMIMEType(const Value: string);
    procedure SetName(const Value: string);
    procedure SetSize(const Value: Integer);
  public
    constructor Create(AOwner: TTMSFNCCustomMemo); virtual;
    procedure Assign(Source: TPersistent); override;
    procedure Open;
  published
    property MIMEType: string read FMIMEType write SetMIMEType;
    property Name: string read FName write SetName;
    property Size: Integer read FSize write SetSize;
  end;

  TTMSFNCMemoDragFiles = class(TObjectList<TTMSFNCMemoDragFile>);

  TTMSFNCMemoDragObject = class(TPersistent)
  private
    FMemo: TTMSFNCCustomMemo;
    FKind: TTMSFNCMemoDragObjectKind;
    FText: string;
    FData: TTMSFNCMemoDragFiles;
    FPosition: TTMSFNCMemoCaretPosition;
    procedure SetKind(const Value: TTMSFNCMemoDragObjectKind);
    procedure SetText(const Value: string);
    procedure SetData(const Value: TTMSFNCMemoDragFiles);
  public
    constructor Create(AOwner: TTMSFNCCustomMemo); virtual;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property Kind: TTMSFNCMemoDragObjectKind read FKind write SetKind;
    property Text: string read FText write SetText;
    property Files: TTMSFNCMemoDragFiles read FData write SetData;
    property Position: TTMSFNCMemoCaretPosition read FPosition;
  end;

  TTMSFNCMemoLanguageFileExtensionMapItem = class(TCollectionItem)
  private
    FLanguage: TTMSFNCMemoLanguage;
    FExtension: string;
    procedure SetExtension(const Value: string);
    procedure SetLanguage(const Value: TTMSFNCMemoLanguage);
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
  published
    property Language: TTMSFNCMemoLanguage read FLanguage write SetLanguage default mlPascal;
    property Extension: string read FExtension write SetExtension;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCMemoLanguageFileExtensionMap = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCMemoLanguageFileExtensionMap = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCMemoLanguageFileExtensionMapItem>)
  {$ENDIF}
  private
    function GetItem(Index: Integer): TTMSFNCMemoLanguageFileExtensionMapItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCMemoLanguageFileExtensionMapItem);
  public
    function MatchLanguage(AExtension: string): Integer;
    function Add: TTMSFNCMemoLanguageFileExtensionMapItem; overload;
    function Insert(Index: Integer): TTMSFNCMemoLanguageFileExtensionMapItem;
    function Add(ALanguage: TTMSFNCMemoLanguage; AExtension: string): TTMSFNCMemoLanguageFileExtensionMapItem; overload;
    property Items[Index: Integer]: TTMSFNCMemoLanguageFileExtensionMapItem read GetItem write SetItem; default;
  end;

  TTMSFNCMemoCodeCompletionItem = class(TCollectionItem)
  private
    FName: string;
    FSortText: string;
    FFilterText: string;
    FKind: TTMSFNCMemoSuggestionKind;
    FInsertTextRules: TTMSFNCMemoSuggestionInsertTextRule;
    FInsertText: TStringList;
    FDocumentation: string;
    FPreselect: Boolean;
    procedure SetDocumentation(const Value: string);
    procedure SetFilterText(const Value: string);
    procedure SetInsertText(const Value: TStringList);
    procedure SetInsertTextRules(const Value: TTMSFNCMemoSuggestionInsertTextRule);
    procedure SetKind(const Value: TTMSFNCMemoSuggestionKind);
    procedure SetName(const Value: string);
    procedure SetPreselect(const Value: Boolean);
    procedure SetSortText(const Value: string);
  protected
    function ToJSONInternal: string;
  public
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property Name: string read FName write SetName;
    property Kind: TTMSFNCMemoSuggestionKind read FKind write SetKind default skText;
    property Documentation: string read FDocumentation write SetDocumentation;
    property InsertText: TStringList read FInsertText write SetInsertText;
    property InsertTextRules: TTMSFNCMemoSuggestionInsertTextRule read FInsertTextRules write SetInsertTextRules default itrNone;
    property Preselect: Boolean read FPreselect write SetPreselect default false;
    property FilterText: string read FFilterText write SetFilterText;
    property SortText: string read FSortText write SetSortText;
  end;

  {$IFDEF WEBLIB}
  TTMSFNCMemoCodeCompletion = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCMemoCodeCompletion = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCMemoCodeCompletionItem>)
  {$ENDIF}
  private
    function GetItem(Index: Integer): TTMSFNCMemoCodeCompletionItem;
    procedure SetItem(Index: Integer; const Value: TTMSFNCMemoCodeCompletionItem);
  protected
    function ToJSONInternal: string;
  public
    function Add: TTMSFNCMemoCodeCompletionItem; overload;
    function Insert(Index: Integer): TTMSFNCMemoCodeCompletionItem;
    property Items[Index: Integer]: TTMSFNCMemoCodeCompletionItem read GetItem write SetItem; default;
    {$IFNDEF WEBLIB}
    procedure LoadFromFile(AFileName: String);
    {$ENDIF}
    procedure LoadFromStream(AStream: TStream);
    procedure LoadFromStrings(AStrings: TStrings);
    function Add(AName: string; AKind: TTMSFNCMemoSuggestionKind = skText; AInsertText: string = ''; AInsertTextRules: TTMSFNCMemoSuggestionInsertTextRule = itrNone; ADocumentation: string = ''):TTMSFNCMemoCodeCompletionItem; overload;
  end;

  TTMSFNCMemoLines = class(TStringList)
  private
    FReading: Boolean;
    FMemo: TTMSFNCCustomMemo;
    FTextLength: Integer;
    FTextChanged: Boolean;
    FUpdating: Boolean;
    procedure NotifyChanges;
  protected
    function GetTextStr: string; override;
    function Get(Index: Integer): string; override;
    procedure SetTextStr(const Value: string); override;
    procedure Put(Index: Integer; const S: string); override;
    procedure InsertItem(Index: Integer; const S: string; AObject: TObject); override;
    procedure SetUpdateState(Updating: Boolean); override;
  public
    constructor Create(Memo: TTMSFNCCustomMemo);
    procedure Assign(Source: TPersistent); override;
    procedure Clear; override;
    {$IFNDEF LCLWEBLIB}
    function AddObject(const S: string; AObject: TObject): Integer; override;
    procedure InsertObject(Index: Integer; const S: string; AObject: TObject); override;
    {$ENDIF}
    procedure Delete(Index: Integer); override;
    procedure Exchange(Index1, Index2: Integer); override;
  end;

  TTMSFNCMemoQuickSuggestion = class(TPersistent)
  private
    FOther: TTMSFNCMemoQuickSuggestionMode;
    FStrings: TTMSFNCMemoQuickSuggestionMode;
    FComments: TTMSFNCMemoQuickSuggestionMode;
    FOptions: TTMSFNCMemoOptions;
    procedure SetComments(const Value: TTMSFNCMemoQuickSuggestionMode);
    procedure SetOther(const Value: TTMSFNCMemoQuickSuggestionMode);
    procedure SetStrings(const Value: TTMSFNCMemoQuickSuggestionMode);
  public
    constructor Create(AOptions: TTMSFNCMemoOptions);
    procedure Assign(Source: TPersistent); override;
    function ToJSString: String;
  published
    property Strings: TTMSFNCMemoQuickSuggestionMode read FStrings write SetStrings default smOff;
    property Comments: TTMSFNCMemoQuickSuggestionMode read FComments write SetComments default smOff;
    property Other: TTMSFNCMemoQuickSuggestionMode read FOther write SetOther default smOn;
  end;

  TTMSFNCMemoScrollOptions = class(TPersistent)
  private
    FHorizontal: TTMSFNCMemoScrollbarOptions;
    FHorizontalScrollbarSize: Integer;
    FVertical: TTMSFNCMemoScrollbarOptions;
    FVerticalScrollbarSize: Integer;
    FHandleMouseWheel: Boolean;
    FScrollByPage: Boolean;
    FOptions: TTMSFNCMemoOptions;
    procedure SetHandleMouseWheel(const Value: Boolean);
    procedure SetHorizontal(const Value: TTMSFNCMemoScrollbarOptions);
    procedure SetHorizontalScrollbarSize(const Value: Integer);
    procedure SetScrollByPage(const Value: Boolean);
    procedure SetVertical(const Value: TTMSFNCMemoScrollbarOptions);
    procedure SetVerticalScrollbarSize(const Value: Integer);
  public
    constructor Create(AOptions: TTMSFNCMemoOptions);
    procedure Assign(Source: TPersistent); override;
    function ToJSString: string;
  published
    property HandleMouseWheel: Boolean read FHandleMouseWheel write SetHandleMouseWheel default true;
    property Horizontal: TTMSFNCMemoScrollbarOptions read FHorizontal write SetHorizontal default soAuto;
    property HorizontalScrollbarSize: Integer read FHorizontalScrollbarSize write SetHorizontalScrollbarSize default 10;
    property ScrollByPage: Boolean read FScrollByPage write SetScrollByPage default false;
    property Vertical: TTMSFNCMemoScrollbarOptions read FVertical write SetVertical default soAuto;
    property VerticalScrollbarSize: Integer read FVerticalScrollbarSize write SetVerticalScrollbarSize default 10;
  end;

  TTMSFNCMemoMinimapOptions = class(TPersistent)
  private
    FEnabled: Boolean;
    FAutohide: Boolean;
    FShowSlider: TTMSFNCMemoMinimapSliderOptions;
    FRenderCharacters: Boolean;
    FSide: TTMSFNCMemoMinimapSideOptions;
    FSize: TTMSFNCMemoMinimapSizeOptions;
    FMaxColumn: Integer;
    FScale: Integer;
    FOptions: TTMSFNCMemoOptions;
    procedure SetAutohide(const Value: Boolean);
    procedure SetEnabled(const Value: Boolean);
    procedure SetMaxColumn(const Value: Integer);
    procedure SetRenderCharacters(const Value: Boolean);
    procedure SetScale(const Value: Integer);
    procedure SetShowSlider(const Value: TTMSFNCMemoMinimapSliderOptions);
    procedure SetSide(const Value: TTMSFNCMemoMinimapSideOptions);
    procedure SetSize(const Value: TTMSFNCMemoMinimapSizeOptions);
  public
    constructor Create(AOptions: TTMSFNCMemoOptions);
    procedure Assign(Source: TPersistent); override;
    function ToJSString: string;
  published
    property Autohide: Boolean read FAutohide write SetAutohide default false;
    property Enabled: Boolean read FEnabled write SetEnabled default true;
    property MaxColumn: Integer read FMaxColumn write SetMaxColumn default 120;
    property RenderCharacters: Boolean read FRenderCharacters write SetRenderCharacters default true;
    property Scale: Integer read FScale write SetScale default 1;
    property ShowSlider: TTMSFNCMemoMinimapSliderOptions read FShowSlider write SetShowSlider default soMouseOver;
    property Side: TTMSFNCMemoMinimapSideOptions read FSide write SetSide default soRight;
    property Size: TTMSFNCMemoMinimapSizeOptions read FSize write SetSize default soActual;
  end;

  TTMSFNCMemoWordWrapType = (wwtOn, wwtOff, wwtWordWrapColumn, wwtBounded);

  TTMSFNCMemoOptions = class(TPersistent)
  private
    FFormatOnPaste: Boolean;
    FLineNumbers: Boolean;
    FEmptySelectionClipboard: Boolean;
    FFoldingHighlights: Boolean;
    FMouseWheelZoom: Boolean;
    FRoundedSelection: Boolean;
    FFolding: Boolean;
    FIsReadOnly: Boolean;
    FGlyphMargin: Boolean;
    FDragAndDrop: Boolean;
    FMiniMap: TTMSFNCMemoMinimapOptions;
    FContextMenu: Boolean;
    FMemo: TTMSFNCCustomMemo;
    FLinks: Boolean;
    FFormatOnType: Boolean;
    FCodeLens: Boolean;
    FScrollbar: TTMSFNCMemoScrollOptions;
    FOverviewRulerLanes: integer;
    FSelectionHighlight: Boolean;
    FSelectOnLineNumbers: Boolean;
    FRenderLineHighlightOnlyWhenFocus: Boolean;
    FRenderLineHighlight: TTMSFNCMemoLineHighLight;
    FRenderWhiteSpace: TTMSFNCMemoWhitepace;
    FRenderIndentGuides: Boolean;
    FTabSize: integer;
    FFont: TTMSFNCMemoFont;
    FBlockSelection: Boolean;
    FQuickSuggestion: TTMSFNCMemoQuickSuggestion;
    FOverviewRulerBorder: Boolean;
    FAutoOpenLink: Boolean;
    FAutoGlyphMargin: Boolean;
    FCommandPalette: Boolean;
    FWordWrapColumn: Integer;
    FWordWrap: TTMSFNCMemoWordWrapType;
    FUseCustomCodeCompletion: boolean;
    procedure SetContextMenu(const Value: Boolean);
    procedure SetDragAndDrop(const Value: Boolean);
    procedure SetEmptySelectionClipboard(const Value: Boolean);
    procedure SetFolding(const Value: Boolean);
    procedure SetFoldingHighlights(const Value: Boolean);
    procedure SetFormatOnPaste(const Value: Boolean);
    procedure SetGlyphMargin(const Value: Boolean);
    procedure SetIsReadOnly(const Value: Boolean);
    procedure SetLineNumbers(const Value: Boolean);
    procedure SetMiniMap(const Value: TTMSFNCMemoMinimapOptions);
    procedure SetMouseWheelZoom(const Value: Boolean);
    procedure SetRoundedSelection(const Value: Boolean);
    procedure SetFormatOnType(const Value: Boolean);
    procedure SetLinks(const Value: Boolean);
    procedure SetCodeLens(const Value: Boolean);
    procedure SetScrollbar(const Value: TTMSFNCMemoScrollOptions);
    procedure SetOverviewRulerLanes(const Value: integer);
    procedure SetRenderIndentGuides(const Value: Boolean);
    procedure SetRenderLineHighlight(const Value: TTMSFNCMemoLineHighLight);
    procedure SetRenderLineHighlightOnlyWhenFocus(const Value: Boolean);
    procedure SetRenderWhiteSpace(const Value: TTMSFNCMemoWhitepace);
    procedure SetSelectionHighlight(const Value: Boolean);
    procedure SetSelectOnLineNumbers(const Value: Boolean);
    procedure SetTabSize(const Value: integer);
    procedure SetFont(const Value: TTMSFNCMemoFont);
    procedure SetBlockSelection(const Value: Boolean);
    procedure SetQuickSuggestion(const Value: TTMSFNCMemoQuickSuggestion);
    procedure SetOverviewRulerBorder(const Value: Boolean);
    procedure SetAutoOpenLink(const Value: Boolean);
    procedure SetAutoGlyphMargin(const Value: Boolean);
    procedure SetCommandPalette(const Value: Boolean);
    procedure SetWordWrap(const Value: TTMSFNCMemoWordWrapType);
    procedure SetWordWrapColumn(const Value: Integer);
    procedure SetUseCustomCodeCompletion(const Value: boolean);
  protected
    procedure FontChanged(Sender: TObject);
    procedure UpdateMinimapOptions;
    procedure UpdateScrollbarOptions;
    procedure UpdateQuickSuggestionOptions;
    property IsReadOnly: Boolean read FIsReadOnly write SetIsReadOnly default false;
    property Font: TTMSFNCMemoFont read FFont write SetFont;
  public
    constructor Create(AMemo: TTMSFNCCustomMemo);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
  published
    property LineNumbers: Boolean read FLineNumbers write SetLineNumbers default true;
    property RoundedSelection: Boolean read FRoundedSelection write SetRoundedSelection default false;
    property ContextMenu: Boolean read FContextMenu write SetContextMenu default true;
    property DragAndDrop: Boolean read FDragAndDrop write SetDragAndDrop default true;
    property EmptySelectionClipboard: Boolean read FEmptySelectionClipboard write SetEmptySelectionClipboard default false;
    property Folding: Boolean read FFolding write SetFolding default true;
    property FoldingHighlights: Boolean read FFoldingHighlights write SetFoldingHighlights default false;
    property FormatOnPaste: Boolean read FFormatOnPaste write SetFormatOnPaste default false;
    property GlyphMargin: Boolean read FGlyphMargin write SetGlyphMargin default false;
    property MiniMap: TTMSFNCMemoMinimapOptions read FMiniMap write SetMiniMap;
    property MouseWheelZoom: Boolean read FMouseWheelZoom write SetMouseWheelZoom default false;
    property Links: Boolean read FLinks write SetLinks default true;
    property FormatOnType: Boolean read FFormatOnType write SetFormatOnType default false;
    property CodeLens: Boolean read FCodeLens write SetCodeLens default true;
    property Scrollbar: TTMSFNCMemoScrollOptions read FScrollbar write SetScrollbar;
    property RenderIndentGuides: Boolean read FRenderIndentGuides write SetRenderIndentGuides default true;
    property RenderLineHighlight: TTMSFNCMemoLineHighLight read FRenderLineHighlight write SetRenderLineHighlight default hlNone;
    property RenderLineHighlightOnlyWhenFocus: Boolean read FRenderLineHighlightOnlyWhenFocus write SetRenderLineHighlightOnlyWhenFocus default false;
    property RenderWhiteSpace: TTMSFNCMemoWhitepace read FRenderWhiteSpace write SetRenderWhiteSpace default mwNone;
    property SelectOnLineNumbers: Boolean read FSelectOnLineNumbers write SetSelectOnLineNumbers default true;
    property SelectionHighlight: Boolean read FSelectionHighlight write SetSelectionHighlight default false;
    property OverviewRulerLanes: Integer read FOverviewRulerLanes write SetOverviewRulerLanes default 3;
    property OverviewRulerBorder: Boolean read FOverviewRulerBorder write SetOverviewRulerBorder default false;
    property TabSize: Integer read FTabSize write SetTabSize default 2;
    property BlockSelection: Boolean read FBlockSelection write SetBlockSelection default false;
    property QuickSuggestion: TTMSFNCMemoQuickSuggestion read FQuickSuggestion write SetQuickSuggestion;
    property AutoOpenLink: Boolean read FAutoOpenLink write SetAutoOpenLink default true;
    property AutoGlyphMargin: Boolean Read FAutoGlyphMargin write SetAutoGlyphMargin default true;
    property CommandPalette: Boolean read FCommandPalette write SetCommandPalette default true;
    property WordWrap: TTMSFNCMemoWordWrapType read FWordWrap write SetWordWrap;
    property WordWrapColumn: Integer read FWordWrapColumn write SetWordWrapColumn;
    property UseCustomCodeCompletion: boolean read FUseCustomCodeCompletion write SetUseCustomCodeCompletion default true;
  end;

  TTMSFNCMemoMatches = array of TTMSFNCMemoMatch;

  TTMSFNCMemoFindMatchesEvent = procedure(Sender: TObject; Matches: TTMSFNCMemoMatches) of object;
  TTMSFNCMemoFindMatchEvent = procedure(Sender: TObject; Match: TTMSFNCMemoMatch) of object;
  TTMSFNCMemoReplaceAllEvent = procedure(Sender: TObject; ASearchString, AReplaceString: string; AMatches: TTMSFNCMemoMatches) of object;
  TTMSFNCMemoReplaceMatchEvent = procedure(Sender: TObject; ASearchString, AReplaceString: string; AMatch: TTMSFNCMemoMatch) of object;
  TTMSFNCMemoGutterClickEvent = procedure(Sender: TObject; LineNumber: Integer) of object;
  TTMSFNCMemoURLClickEvent = procedure(Sender: TObject; AURL: string) of object;
  TTMSFNCMemoPasteEvent = procedure(Sender: TObject;var APasteValue: string; var AAllow: Boolean) of object;

  TTMSFNCMemoSource = class(TCollectionItem)
  private
    FLanguage: TTMSFNCMemoLanguage;
    FName: string;
    FLines: TStringList;
    FReadOnly: boolean;
    FBreakPoints: TStringList;
    FBookmarks: TStringList;
    FCaretPosition: TTMSFNCMemoCaretPosition;
    FLineHighlights: TStringList;
    procedure SetLanguage(const Value: TTMSFNCMemoLanguage);
    procedure SetLines(const Value: TStringList);
    procedure SetName(const Value: string);
    procedure SetReadOnly(const Value: boolean);
    procedure SetCaretPosition(const Value: TTMSFNCMemoCaretPosition);
  public
    constructor Create(ACollection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property BreakPoints: TStringList read FBreakPoints;
    property Bookmarks: TStringList read FBookmarks;
    property LineHighlights: TStringList read FLineHighlights;
    property CaretPosition: TTMSFNCMemoCaretPosition read FCaretPosition write SetCaretPosition;
    procedure SaveToFile(AFileName: string);
    procedure SaveToStream(AStream: TStream);
  published
    property Name: string read FName write SetName;
    property ReadOnly: boolean read FReadOnly write SetReadOnly;
    property Language: TTMSFNCMemoLanguage read FLanguage write SetLanguage;
    property Lines: TStringList read FLines write SetLines;
  end;

  TTMSFNCMemoSourcesChangedEvent = procedure(Source: TTMSFNCMemoSource) of object;

  {$IFDEF WEBLIB}
  TTMSFNCMemoSources = class(TTMSFNCOwnedCollection)
  {$ENDIF}
  {$IFNDEF WEBLIB}
  TTMSFNCMemoSources = class({$IFDEF LCLLIB}specialize {$ENDIF}TTMSFNCOwnedCollection<TTMSFNCMemoSource>)
  {$ENDIF}
  private
    function GetItem(Index: Integer): TTMSFNCMemoSource;
    procedure SetItem(Index: Integer; const Value: TTMSFNCMemoSource);
  public
    function Add: TTMSFNCMemoSource; overload;
    function Insert(Index: Integer): TTMSFNCMemoSource;
    property Items[Index: Integer]: TTMSFNCMemoSource read GetItem write SetItem; default;
    function Find(Name: string): TTMSFNCMemoSource; overload;
    function IndexOf(Name: string): Integer; overload;
    {$IFNDEF WEBLIB}
    function AddSourceFromFile(AFileName: string): TTMSFNCMemoSource; overload;
    function AddSourceFromFile(AFileName, AName: string): TTMSFNCMemoSource; overload;
    function AddSourceFromFile(AFileName, AName: string; ALanguage: TTMSFNCMemoLanguage): TTMSFNCMemoSource; overload;
    {$ENDIF}
    function AddSourceFromStream(AStream: TStream): TTMSFNCMemoSource; overload;
    function AddSourceFromStream(AStream: TStream; AName: string): TTMSFNCMemoSource; overload;
    function AddSourceFromStream(AStream: TStream; AName: string; ALanguage: TTMSFNCMemoLanguage): TTMSFNCMemoSource; overload;
    function AddSource(AText: string=''; ALanguage: TTMSFNCMemoLanguage = mlPascal;AName:string=''): TTMSFNCMemoSource; overload;
  end;

  TTMSFNCCustomMemo = class(TTMSFNCCustomWEBControl)
  private
    FLines: TTMSFNCMemoLines;
    FOnFindAll: TTMSFNCMemoFindMatchesEvent;
    FOnFindNext: TTMSFNCMemoFindMatchEvent;
    FInitialized: Boolean;
    FSelLength: Integer;
    FSelStart: Integer;
    FLanguages: TStringlist;
    FLanguage: TTMSFNCMemoLanguage;
    FTheme: TTMSFNCMemoTheme;
    FOnChangeTracking: TNotifyEvent;
    FOnChange: TNotifyEvent;
    FOptions: TTMSFNCMemoOptions;
    FWantTab: Boolean;
    FLibraryLocation: TTMSFNCMemoLibraryLocation;
    FOnFindPrevious: TTMSFNCMemoFindMatchEvent;
    FOnGutterClick: TTMSFNCMemoGutterClickEvent;
    FOnGutterRightClick: TTMSFNCMemoGutterClickEvent;
    FOnGutterMiddleClick: TTMSFNCMemoGutterClickEvent;
    FBreakpoints: TStringList;
    FBookmarks: TStringList;
    FLineHighlights: TStringList;
    FOnGetCodeCompletion: TTMSFNCMemoSuggestionEvent;
    FCompletionList: TTMSFNCMemoCodeCompletion;
    FOnLineNumberClick: TTMSFNCMemoGutterClickEvent;
    FOnLineNumberRightClick: TTMSFNCMemoGutterClickEvent;
    FOnLineNumberMiddleClick: TTMSFNCMemoGutterClickEvent;
    FOnGlyphMarginMiddleClick: TTMSFNCMemoGutterClickEvent;
    FOnGlyphMarginClick: TTMSFNCMemoGutterClickEvent;
    FOnGlyphMarginRightClick: TTMSFNCMemoGutterClickEvent;
    FOnURLClick: TTMSFNCMemoURLClickEvent;
    FOnPaste: TTMSFNCMemoPasteEvent;
    FOnReplaceNext: TTMSFNCMemoReplaceMatchEvent;
    FOnReplaceAll: TTMSFNCMemoReplaceAllEvent;
    FOnReplacePrevious: TTMSFNCMemoReplaceMatchEvent;
    FOnInitialized: TNotifyEvent;
    FLanguageFileExtensionsMap: TTMSFNCMemoLanguageFileExtensionMap;
    FActiveSource: Integer;
    FSources: TTMSFNCMemoSources;
    FSourceIDCount: Integer;
    procedure SetLines(const Value: TTMSFNCMemoLines);
    function ParseValue(AValue: string): string;
    procedure SetSelLength(const Value: Integer);
    procedure SetSelStart(const Value: Integer);
    function GetSelText: String;
    procedure SetSelText(const Value: String);
    function GetSelLength: Integer;
    function GetSelStart: Integer;
    procedure SetLanguage(const Value: TTMSFNCMemoLanguage);
    procedure SetTheme(const Value: TTMSFNCMemoTheme);
    function GetLines: TTMSFNCMemoLines;
    procedure SetOptions(const Value: TTMSFNCMemoOptions);
    procedure SetWantTab(const Value: Boolean);
    procedure SetLibraryLocation(const Value: TTMSFNCMemoLibraryLocation);
    function GetBreakPoint(Line: Integer): Boolean;
    function GetBreakPointInternal(Line: Integer): Integer;
    function GetBookmarksInternal(line: Integer): Integer;
    function GetLineHighlightInternal(line: Integer): Integer;
    procedure SetBreakPoint(Line: Integer; const Value: Boolean);
    function GetBreakpointCount: Integer;
    function GetText: string;
    procedure SetText(const Value: string);
    function GetReadonly: Boolean;
    procedure SetReadonly(const Value: Boolean);
    function GetFont: TTMSFNCMemoFont;
    procedure SetFont(const Value: TTMSFNCMemoFont);
    function GetBookmark(Line: Integer): Boolean;
    procedure SetBookmark(Line: Integer; const Value: Boolean);
    function GetBookmarksCount: Integer;
    function GetLineHighlight(Line: Integer): Boolean;
    function GetLineHighlightCount: Integer;
    procedure SetLineHighlight(Line: Integer; const Value: Boolean);
    procedure SetCompletionList(const Value: TTMSFNCMemoCodeCompletion);
    procedure SetLanguageFileExtensionsMap(const Value: TTMSFNCMemoLanguageFileExtensionMap);
    procedure SetActiveSource(const Value: Integer);
    procedure SetSources(const Value: TTMSFNCMemoSources);
    procedure SetSourceIDCount(const Value: Integer);
    function GetSourceIDCount: Integer;
  protected
    IsMouseEnter: Boolean;
    procedure ChangeDPIScale({%H-}M, {%H-}D: Integer); override;
    procedure OpenFile(AFile: TTMSFNCMemoDragFile);
    procedure HandleEvents(AEventName: string; ACustomData: string);
    procedure CallCustomEvent(AEventData: TTMSFNCCustomWEBControlEventData); override;
    procedure LoadLinks(AList: TTMSFNCCustomWEBControlLinksList); override;
    procedure InitializeHTML; override;
    procedure DoControlInitialized; override;
    procedure DoEnter; override;
    procedure DoExit; override;
    procedure DoPasteInternal(const Value: string);
    procedure DoPaste(var APasteValue: string; var AAllow: Boolean); virtual;
    procedure DoURLClickInternal(const Value: string);
    procedure DoURLClick(AURL: string); virtual;
    procedure DoFindInternal(Const Value: String);
    procedure DoFind(AMatches: TTMSFNCMemoMatches); virtual;
    procedure DoFindNextInternal(Const Value: String);
    procedure DoControlInitializedInternal(Const Value: string);
    procedure DoFindNext(AMatch: TTMSFNCMemoMatch); virtual;
    procedure DoFindPreviousInternal(const Value: String);
    procedure DoReplaceAllInternal(const Value: string);
    procedure DoReplaceNextInternal(const Value: string);
    procedure DoReplacePreviousInternal(const Value: string);
    procedure DoReplaceAll(ASearchString, AReplaceString: string; AMatches: TTMSFNCMemoMatches); virtual;
    procedure DoReplaceNext(ASearchString, AReplaceString: string; AMatch: TTMSFNCMemoMatch); virtual;
    procedure DoReplacePrevious(ASearchString, AReplaceString: string; AMatch: TTMSFNCMemoMatch); virtual;
    procedure DoFindPrevious(AMatch: TTMSFNCMemoMatch); virtual;
    procedure DoGutterClick(ALineNumber: Integer); virtual;
    procedure DoGutterMiddleClick(ALineNumber: Integer); virtual;
    procedure DoGutterRightClick(ALineNumber: Integer); virtual;
    procedure DoLineNumberRightClick(ALineNumber: Integer); virtual;
    procedure DoLineNumberClick(ALineNumber: Integer); virtual;
    procedure DoLineNumberMiddleClick(ALineNumber: Integer); virtual;
    procedure DoGlyphMarginClick(ALineNumber: Integer); virtual;
    procedure DoGlyphMarginMiddleClick(ALineNumber: Integer); virtual;
    procedure DoGlyphMarginRightClick(ALineNumber: Integer); virtual;
    procedure DoGetCodeCompletionInternal ;
    procedure DoGetCodeCompletion(Token: String;CustomCompletionList: TTMSFNCMemoCodeCompletion; Position: TTMSFNCMemoCaretPosition); virtual;
    procedure DoKeyPressed(var Key: Word); override;
    procedure LoadLanguages;
    procedure UpdateBreakpoints;
    procedure UpdateBookmarks;
    procedure UpdateLineHighlight;
    function GetLineCount: Integer;
    function GetLineContent(ALineNumber: Integer): string;
    function LineInsert(AIndex: Integer; AText: String): Integer;
    function GetValue: string; overload;
    procedure SetValue(AValue: String);
    procedure DeleteLine(AIndex: Integer);
    function GetCustomCSS: string; override;
    function GetBody: string; override;
    function GetVersion: string; override;
    function GetDocURL: string; override;
    function GetVersionID: Integer;
    function GetCustomFunctions: string; override;
    function GetWaitInitCondition: string; override;
    {$IFDEF WEBLIB}
    function GetWaitInitVariables: string; override;
    {$ENDIF}
    procedure SetPosition(APosition: TTMSFNCMemoCaretPosition);
    function GetPosition: TTMSFNCMemoCaretPosition;
    procedure SetSelection(ASelStart, ASelLength: Integer);
    procedure UpdateOption(AOption: string);
    procedure GetSelection;
    procedure DoChangeInternal;
    procedure DoChange; virtual;
    procedure DoChangeTrackingInternal;
    procedure DoChangeTracking; virtual;
    procedure DoMemoKeyUp(const data: string);
    procedure DoMemoKeyDown(const data: string);
    procedure DoMemoMouseDown(const data: string);
    procedure DoMemoMouseUp(const data: string);
    procedure DoMemoMouseMove(const data: string);
    procedure DoDragOver(const Data: string);
    procedure DoDragEnd(const Data: string);
    procedure DoDragLeave(const Data: string);
    procedure DoDragDrop(const Data: string);
    procedure DoDragEnter(const Data: string);
    procedure DoLoadFromURL(const ARequestResult: TTMSFNCCloudBaseRequestResult); virtual;
    function GetBreakPoints: TStringList;
    function GetValue(ASelStart, ASelLength: Integer): String; overload;
    property IsInitialized: Boolean read FInitialized;
    property Lines: TTMSFNCMemoLines read GetLines write SetLines;
    property OnFindNext: TTMSFNCMemoFindMatchEvent read FOnFindNext write FOnFindNext;
    property OnFindPrevious: TTMSFNCMemoFindMatchEvent read FOnFindPrevious write FOnFindPrevious;
    property OnFindAll: TTMSFNCMemoFindMatchesEvent read FOnFindAll write FOnFindAll;
    property Language: TTMSFNCMemoLanguage read FLanguage write SetLanguage default mlPascal;
    property Theme: TTMSFNCMemoTheme read FTheme write SetTheme default mtVisualStudio;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property OnChangeTracking: TNotifyEvent read FOnChangeTracking write FOnChangeTracking;
    property Options: TTMSFNCMemoOptions read FOptions write SetOptions;
    property WantTab: Boolean read FWantTab write SetWantTab default false;
    property LibraryLocation: TTMSFNCMemoLibraryLocation read FLibraryLocation write SetLibraryLocation default llOffline;
    property OnGutterClick: TTMSFNCMemoGutterClickEvent read FOnGutterClick write FOnGutterClick;
    property OnGutterMiddleClick: TTMSFNCMemoGutterClickEvent read FOnGutterMiddleClick write FOnGutterMiddleClick;
    property OnGutterRightClick: TTMSFNCMemoGutterClickEvent read FOnGutterRightClick write FOnGutterRightClick;
    property OnLineNumberClick: TTMSFNCMemoGutterClickEvent read FOnLineNumberClick write FOnLineNumberClick;
    property OnLineNumberMiddleClick: TTMSFNCMemoGutterClickEvent read FOnLineNumberMiddleClick write FOnLineNumberMiddleClick;
    property OnLineNumberRightClick: TTMSFNCMemoGutterClickEvent read FOnLineNumberRightClick write FOnLineNumberRightClick;
    property OnGlyphMarginClick: TTMSFNCMemoGutterClickEvent read FOnGlyphMarginClick write FOnGlyphMarginClick;
    property OnGlyphMarginMiddleClick: TTMSFNCMemoGutterClickEvent read FOnGlyphMarginMiddleClick write FOnGlyphMarginMiddleClick;
    property OnGlyphMarginRightClick: TTMSFNCMemoGutterClickEvent read FOnGlyphMarginRightClick write FOnGlyphMarginRightClick;
    property &Readonly: Boolean read GetReadonly write SetReadonly default false;
    property Font: TTMSFNCMemoFont read GetFont write SetFont;
    property OnGetCodeCompletion: TTMSFNCMemoSuggestionEvent read FOnGetCodeCompletion write FOnGetCodeCompletion;
    property CompletionList: TTMSFNCMemoCodeCompletion read FCompletionList write SetCompletionList;
    property OnLinkClick: TTMSFNCMemoURLClickEvent read FOnURLClick write FOnURLClick;
    property OnPaste: TTMSFNCMemoPasteEvent read FOnPaste write FOnPaste;
    property OnReplaceAll: TTMSFNCMemoReplaceAllEvent read FOnReplaceAll write FOnReplaceAll;
    property OnReplaceNext: TTMSFNCMemoReplaceMatchEvent read FOnReplaceNext write FOnReplaceNext;
    property OnReplacePrevious: TTMSFNCMemoReplaceMatchEvent read FOnReplacePrevious write FOnReplacePrevious;
    property OnInitialized: TNotifyEvent read FOnInitialized write FOnInitialized;
    property LanguageFileExtensionsMap: TTMSFNCMemoLanguageFileExtensionMap read FLanguageFileExtensionsMap write SetLanguageFileExtensionsMap;
    property Sources: TTMSFNCMemoSources read FSources write SetSources;
    property SourceIDCount: Integer read GetSourceIDCount write SetSourceIDCount;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    procedure Clear;
    function TextPosToPos(const APos: Integer): TTMSFNCMemoCaretPosition;
    function XYToPos(const X, Y: Integer): TTMSFNCMemoCaretPosition;
    function PosToTextPos(const APosition: TTMSFNCMemoCaretPosition): Integer;
    {$IFNDEF WEBLIB}
    function AddSourceFromFile(AFileName: string; AName: string = ''): TTMSFNCMemoSource;
    procedure AddSourcesFromFolder(AFolder: string);
    {$ENDIF}
    function AddSourceFromStream(AStream: TStream; AName: string = ''): TTMSFNCMemoSource;
    function FindSource(AName: string): TTMSFNCMemoSource;
    procedure RemoveSource(AName: string);
    procedure BeginUpdate; override;
    procedure EndUpdate; override;
    procedure CopyToClipBoard;
    procedure PasteFromClipBoard;
    procedure CutToClipBoard;
    procedure SelectAll;
    procedure Unselect;
    procedure Undo;
    procedure Redo;
    procedure FoldAll;
    procedure UnfoldAll;
    {$IFNDEF WEBLIB}
    procedure LoadFromFile(AFileName: string);
    {$ENDIF}
    procedure LoadFromURL(AURL: string);
    function GetWordAtPosition(APos: Integer): string; overload;
    function GetWordAtPosition: string; overload;
    procedure FormatDocument;
    procedure FormatSelection;
    procedure Find(ASearchString: string; AOptions: TTMSFNCMemoFindOptions; ABehaviour: TTMSFNCMemoFindBehaviour = fbDown); Overload;
    procedure Find(ASearchString: string; ABehaviour: TTMSFNCmemoFindBehaviour = fbDown); overload;
    procedure FindAll(ASearchString: string; AOptions: TTMSFNCMemoFindOptions); Overload;
    procedure FindAll(ASearchString: string); Overload;
    procedure FindAllInRange(ASearchString: string; ASelStart, ASelLength: Integer; AOptions: TTMSFNCMemoFindOptions); Overload;
    procedure FindAllInRange(ASearchString: string; ASelStart, ASelLength: Integer); Overload;
    procedure FindNext(ASearchString: string; AOptions: TTMSFNCMemoFindOptions); overload;
    procedure FindNext(ASearchString: string); overload;
    procedure FindPrevious(ASearchString: string; AOptions: TTMSFNCMemoFindOptions); overload;
    procedure FindPrevious(ASearchString: string); overload;
    procedure FindNext(ASearchString: string; APos: Integer; AOptions: TTMSFNCMemoFindOptions); overload;
    procedure FindNext(ASearchString: string; APos: Integer); overload;
    procedure FindPrevious(ASearchString: string; APos: Integer; AOptions: TTMSFNCMemoFindOptions); overload;
    procedure FindPrevious(ASearchString: string; APos: Integer); overload;
    procedure Replace(AStartPos, ALength: Integer; AReplaceString: string); overload;
    procedure Replace(AReplaceString: string); overload;
    procedure Replace(ASearchString, AReplaceString: string; AOptions: TTMSFNCMemoFindOptions; ABehaviour: TTMSFNCMemoFindBehaviour = fbDown); overload;
    procedure Replace(ASearchString, AReplaceString: string; ABehaviour: TTMSFNCMemoFindBehaviour = fbDown); overload;
    procedure DeleteSelection;
    procedure ReplaceNext(ASearchString, AReplaceString: string; AOptions: TTMSFNCMemoFindOptions); overload;
    procedure ReplaceNext(ASearchString, AReplaceString: string); overload;
    procedure ReplacePrevious(ASearchString, AReplaceString: string; AOptions: TTMSFNCMemoFindOptions); overload;
    procedure ReplacePrevious(ASearchString, AReplaceString: string); overload;
    procedure ReplaceAll(ASearchString, AReplaceString: string; AOptions: TTMSFNCMemoFindOptions); overload;
    procedure ReplaceAll(ASearchString, AReplaceString: string); overload;
    procedure ScrollToLine(ALineNumber: Integer);
    procedure ScrollToLineCenter(ALineNumber: Integer);
    property CaretPosition: TTMSFNCMemoCaretPosition read GetPosition write SetPosition;
    property SelStart: Integer read GetSelStart write SetSelStart;
    property SelLength: Integer read GetSelLength write SetSelLength;
    property SelText: String read GetSelText write SetSelText;
    property BreakPoints[Line: Integer]: Boolean read GetBreakPoint write SetBreakPoint;
    property Bookmarks[Line: Integer]: Boolean read GetBookmark write SetBookmark;
    property ActiveSource: Integer read FActiveSource write SetActiveSource;
    property BreakPointCount: Integer read GetBreakpointCount;
    property BookmarksCount: Integer read GetBookmarksCount;
    property LineHighlight[Line: Integer]: Boolean read GetLineHighlight write SetLineHighlight;
    property LineHighlightCount: Integer read GetLineHighlightCount;
    property Text: string read GetText write SetText;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCMemo = class(TTMSFNCCustomMemo)
  published
    property &Readonly;
    property Font;
    property Lines;
    property Theme;
    property OnFindNext;
    property OnFindPrevious;
    property OnFindAll;
    property Language;
    property OnChange;
    property OnChangeTracking;
    property Options;
    property WantTab;
    property CompletionList;
    property LibraryLocation;
    property OnGutterClick;
    property OnGutterMiddleClick;
    property OnGutterRightClick;
    property OnGlyphMarginClick;
    property OnGlyphMarginRightClick;
    property OnGlyphMarginMiddleClick;
    property OnLineNumberClick;
    property OnLineNumberRightClick;
    property OnLineNumberMiddleClick;
    property OnGetCodeCompletion;
    property OnLinkClick;
    property OnPaste;
    property OnReplaceAll;
    property OnReplaceNext;
    property OnReplacePrevious;
    property OnInitialized;
    property LanguageFileExtensionsMap;
    property ActiveSource;
    property Sources;
  end;

function DefaultFindOptions: TTMSFNCMemoFindOptions;

implementation

{$R TMSFNCMemo.res}

uses
  Math
{$IFNDEF LCLWEBLIB}
  ,WEBLib.Graphics, System.Threading
{$ENDIF}
  ;

type
  TCustomFormOpen = class(TCustomForm);

  {$IFDEF LCLWEBLIBTHREAD}
  TTaskRunEvent = procedure of object;
  TTaskRunEvent2 = procedure(Param1, Param2: string) of object;
  TTaskRunEvent3 = procedure(Param1, Param2: string; Param3: TTMSFNCMemoMatches) of object;
  TTaskRunEvent4 = procedure(Param1: TTMSFNCMemoMatches) of object;
  TTaskRunEvent5 = procedure(Param1, Param2: string; Param3: TTMSFNCMemoMatch) of object;
  TTaskRunEvent6 = procedure(Param1: TTMSFNCMemoMatch) of object;

  TTaskStatus = (Created, WaitingToRun, Running, Completed, WaitingForChildren, Canceled, Exception);

  ITask = interface
  ['{5A2BE42C-8E7B-468B-8D6A-2B27EAE87B55}']
    function GetStatus: TTaskStatus;
    procedure Start;
    procedure Cancel;
    property Status: TTaskStatus read GetStatus;
  end;

  {$IFDEF LCLLIBTHREAD}

  { TTaskWorkerThread }

  TTaskWorkerThread = class(TThread)
  private
    FParam1: string;
    FParam2: string;
    FParam3: TTMSFNCMemoMatches;
    FParam4: TTMSFNCMemoMatch;
    FRunEvent: TTaskRunEvent;
    FRunEvent2: TTaskRunEvent2;
    FRunEvent3: TTaskRunEvent3;
    FRunEvent4: TTaskRunEvent4;
    FRunEvent5: TTaskRunEvent5;
    FRunEvent6: TTaskRunEvent6;
  protected
    procedure Execute; override;
    procedure SyncEvents;
  public
    constructor Create(const ARunEvent: TTaskRunEvent; const ARunEvent2: TTaskRunEvent2; const ARunEvent3: TTaskRunEvent3; const ARunEvent4: TTaskRunEvent4;
      const ARunEvent5: TTaskRunEvent5; const ARunEvent6: TTaskRunEvent6; Param1, Param2: string; Param3: TTMSFNCMemoMatches; Param4: TTMSFNCMemoMatch);
  end;
  {$ENDIF}

  TTask = class(TInterfacedObject, ITask)
  private
    {$IFDEF LCLLIBTHREAD}
    FWorkerThread: TTaskWorkerThread;
    {$ENDIF}
    FTaskStatus: TTaskStatus;
    FRunEvent: TTaskRunEvent;
    function GetStatus: TTaskStatus;
  public
    constructor Create(const ARunEvent: TTaskRunEvent);
    destructor Destroy; override;
    procedure Start; virtual;
    procedure Cancel;
    property Status: TTaskStatus read GetStatus;
  end;

  { THandleEventsTask }

  TTask2 = class(TTask)
  private
    FParam1: string;
    FParam2: string;
    FRunEvent2: TTaskRunEvent2;
  public
    constructor Create(const ARunEvent: TTaskRunEvent2; Param1, Param2: string); reintroduce;
    procedure Start; override;
    destructor Destroy; override;
  end;

  TTask3 = class(TTask)
  private
    FParam1: string;
    FParam2: string;
    FParam3: TTMSFNCMemoMatches;
    FRunEvent3: TTaskRunEvent3;
  public
    constructor Create(const ARunEvent: TTaskRunEvent3; Param1, Param2: string; Param3: TTMSFNCMemoMatches); reintroduce;
    procedure Start; override;
    destructor Destroy; override;
  end;

  TTask4 = class(TTask)
  private
    FParam1: TTMSFNCMemoMatches;
    FRunEvent4: TTaskRunEvent4;
  public
    constructor Create(const ARunEvent: TTaskRunEvent4; Param1: TTMSFNCMemoMatches); reintroduce;
    procedure Start; override;
    destructor Destroy; override;
  end;

  TTask5 = class(TTask)
  private
    FParam3: TTMSFNCMemoMatch;
    FParam1: String;
    FParam2: string;
    FRunEvent5: TTaskRunEvent5;
  public
    constructor Create(const ARunEvent: TTaskRunEvent5; Param1, Param2: string; Param3: TTMSFNCMemoMatch); reintroduce;
    procedure Start; override;
    destructor Destroy; override;
  end;

  TTask6 = class(TTask)
  private
    FParam1: TTMSFNCMemoMatch;
    FRunEvent6: TTaskRunEvent6;
  public
    constructor Create(const ARunEvent: TTaskRunEvent6; Param1: TTMSFNCMemoMatch); reintroduce;
    procedure Start; override;
    destructor Destroy; override;
  end;
  {$ENDIF}

function DefaultFindOptions: TTMSFNCMemoFindOptions;
begin
  Result.SearchOnlyEditableRange := true;
  Result.SetSelect := true;
  Result.IsRegex := false;
  Result.MatchCase := false;
  Result.CaptureMatches := true;
  Result.WordSeparators := '';
  Result.LimitResultCount := -1;
end;

{ TTMSFNCCustomMemo }

{$IFNDEF WEBLIB}

function TTMSFNCCustomMemo.AddSourceFromFile(AFileName,
  AName: string): TTMSFNCMemoSource;
begin
  if AName.IsEmpty then
    Result := Sources.AddSourceFromFile(AFileName)
  else
    Result := Sources.AddSourceFromFile(AFileName, AName);
end;

procedure TTMSFNCCustomMemo.AddSourcesFromFolder(AFolder: string);
var
  SR: TSearchRec;

  procedure AddToList(s: string);
  begin
    AddSourceFromFile(s);
  end;

begin
  if FindFirst(AFolder,faAnyFile and not faDirectory,SR) = 0 then
  begin
    AddToList(ExtractFilePath(AFolder) + SR.Name);
    while SysUtils.FindNext(SR) = 0 do
      AddToList(ExtractFilePath(AFolder) + SR.Name);
  end;
  FindClose(SR);
end;

{$ENDIF}

function TTMSFNCCustomMemo.AddSourceFromStream(AStream: TStream;
  AName: string): TTMSFNCMemoSource;
begin
  if AName.IsEmpty then
    Result := Sources.AddSourceFromStream(AStream)
  else
    Result := Sources.AddSourceFromStream(AStream, AName);
end;

procedure TTMSFNCCustomMemo.Assign(Source: TPersistent);
var
  m: TTMSFNCCustomMemo;
begin
  if Source is TTMSFNCCustomMemo then
  begin
    m := Source as TTMSFNCCustomMemo;
    FLines.Assign(m.Lines);
    FOnFindAll := m.OnFindAll;
    FOnFindNext := m.FOnFindNext;
    FTheme := m.Theme;
    FLanguage := m.Language;
    FOptions.Assign(m.Options);
    FLanguageFileExtensionsMap.Assign(m.LanguageFileExtensionsMap);
    FSources.Assign(m.Sources);
    FActiveSource := m.ActiveSource;
    FBreakpoints.Assign(m.FBreakpoints);
    FBookmarks.Assign(m.FBookmarks);
    FLineHighlights.Assign(m.FLineHighlights);
  end
  else
    inherited;
end;

procedure TTMSFNCCustomMemo.BeginUpdate;
begin
  inherited;
  Lines.BeginUpdate;
end;

procedure TTMSFNCCustomMemo.HandleEvents(AEventName: string; ACustomData: string);
var
  f: TCustomForm;
  k: Word;
  {$IFDEF FMXLIB}
  c: Char;
  {$ENDIF}
  ss: TShiftState;
begin
  case IndexStr(AEventName,[MEMOCONTENTCHANGED, MEMOTRACKING, MEMOGETFOCUS, MEMOLOSEFOCUS, MEMODOKEYDOWN, MEMODOKEYUP, MEMODOMOUSEDOWN,
   MEMODOMOUSEUP, MEMODOMOUSELEAVE, MEMODOMOUSEMOVE, MEMOTABPRESSED, FETCHSUGGESTIONS, MEMOURLCLICK, FETCHPASTE, MEMODRAGLEAVE,
    MEMODRAGEND, MEMODRAGDROP, MEMODRAGENTER, MEMODRAGOVER]) of
     0: DoChangeInternal;
     1: DoChangeTrackingInternal;
     2:
     begin
       if Assigned(OnEnter) then
         OnEnter(Self);
     end;
     3:
     begin
       if Assigned(OnExit) then
         OnExit(Self);
     end;
     4: DoMemoKeyDown(ACustomData);
     5: DoMemoKeyUp(ACustomData);
     6: DoMemoMouseDown(ACustomData);
     7: DoMemoMouseUp(ACustomData);
     8:
     begin
       IsMouseEnter := false;
       if Assigned(OnMouseLeave) then
         OnMouseLeave(Self);
     end;
     9: DoMemoMouseMove(ACustomData);
     10:
     begin
       f := TTMSFNCUtils.GetParentForm(Self);
       if Assigned(f) then
       begin
         k := KEY_TAB;
         {$IFDEF FMXLIB}
         c := #0;
         {$ENDIF}
         ss := [];
         if ACustomData = 'true' then
           ss := [ssShift];

         {$IFDEF FMXLIB}
         TCustomFormOpen(f).KeyDown(k, c, ss);
         {$ELSE}
         TCustomFormOpen(f).KeyDown(k, ss);
         {$ENDIF}
       end;
     end;
     11: DoGetCodeCompletionInternal;
     12: DoURLClickInternal(ACustomData);
     13: DoPasteInternal(ACustomData);
     14: DoDragLeave(ACustomData);
     15: DoDragEnd(ACustomData);
     16: DoDragDrop(ACustomData);
     17: DoDragEnter(ACustomData);
     18: DoDragOver(ACustomData);
   end;
end;

procedure TTMSFNCCustomMemo.InitializeHTML;
begin
  EnableContextMenu := False;
  inherited;
end;

procedure TTMSFNCCustomMemo.CallCustomEvent(
  AEventData: TTMSFNCCustomWEBControlEventData);
var
  t: ITask;
  ev, cd: string;
begin
  inherited;
  if AEventData.EventName <> '' then
  begin
    ev := AEventData.EventName;
    cd := AEventData.CustomData;

    {$IFDEF LCLWEBLIBTHREAD}
    t := TTask2.Create(@HandleEvents, ev, cd);
    {$ELSE}
    t := TTask.Create(
    procedure
    begin
      TThread.Synchronize(TThread.CurrentThread,
      procedure
      begin
        HandleEvents(ev, cd);
      end);
    end
    );
   {$ENDIF}
    t.Start;
  end;
end;

procedure TTMSFNCCustomMemo.ChangeDPIScale(M, D: Integer);
begin
  inherited;
  if IsInitialized and not (csDesigning in ComponentState) then
    Exit;

  Font.Height := TTMSFNCUtils.MulDivInt(Font.Height, M, D);
end;

procedure TTMSFNCCustomMemo.DoChange;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

procedure TTMSFNCCustomMemo.DoChangeInternal;
var
  t: ITask;
begin
  if FLines.FReading or not (FLines.UpdateCount = 0) then
    Exit;

  {$IFDEF LCLWEBLIBTHREAD}
  t := TTask.Create(@DoChange);
  {$ELSE}
  t := TTask.Create(
  procedure
  begin
    TThread.Synchronize(TThread.CurrentThread,
    procedure
    begin
      DoChange;
    end);
  end
  );
 {$ENDIF}
  t.Start;
end;

procedure TTMSFNCCustomMemo.DoChangeTracking;
begin
  if Assigned(OnChangeTracking) then
    OnChangeTracking(Self);
end;

procedure TTMSFNCCustomMemo.DoChangeTrackingInternal;
var
  t: ITask;
begin
  if FLines.FReading or not (FLines.UpdateCount = 0) then
    Exit;

  {$IFDEF LCLWEBLIBTHREAD}
  t := TTask.Create(@DoChangeTracking);
  {$ELSE}
  t := TTask.Create(
  procedure
  begin
    TThread.Synchronize(TThread.CurrentThread,
    procedure
    begin
      DoChangeTracking;
    end);
  end
  );
 {$ENDIF}
  t.Start;
end;

procedure TTMSFNCCustomMemo.Clear;
begin
  SetValue('');
end;

procedure TTMSFNCCustomMemo.CopyToClipBoard;
begin
  ExecuteJavaScript(GetControlID + 'editor.trigger("CutToClipBoard", "editor.action.clipboardCopyAction")');
end;

constructor TTMSFNCCustomMemo.Create(AOwner: TComponent);
var
  {$IFNDEF WEBLIB}
  f: string;
  r: TResourceStream;
  {$ENDIF}
  {$IFNDEF LCLWEBLIB}
  z: TZipFile;
  {$ENDIF}
  {$IFDEF LCLLIB}
   z: TUnZipper;
  {$ENDIF}
begin
  inherited;
  FLibraryLocation := llOffline;
  FLanguageFileExtensionsMap := TTMSFNCMemoLanguageFileExtensionMap.Create(Self, TTMSFNCMemoLanguageFileExtensionMapItem);
  FLines := TTMSFNCMemoLines.Create(Self);
  FBreakpoints := TStringList.Create;
  FBreakPoints.Delimiter := ';';
  FBreakPoints.Duplicates := dupIgnore;
  FBookMarks := TStringList.Create;
  FBookMarks.Delimiter := ';';
  FBookMarks.Duplicates := dupIgnore;
  FLineHighlights := TStringList.Create;
  FLineHighlights.Delimiter := ';';
  FLineHighlights.Duplicates := dupIgnore;
  LoadLanguages;
  FLanguage := mlPascal;
  FTheme := mtVisualStudio;
  IsMouseEnter := false;
  FOptions := TTMSFNCMemoOptions.Create(Self);
  LocalFileAccess := true;
  FWantTab := false;
  FSources := TTMSFNCMemoSources.Create(Self, TTMSFNCMemoSource);
  FActiveSource := -1;
  FCompletionList := TTMSFNCMemoCodeCompletion.Create(Self, TTMSFNCMemoCodeCompletionItem	);

  if csDesigning in ComponentState then
    Exit;

  {$IFNDEF LCLWEBLIB}
  TDirectory.CreateDirectory(TTMSFNCUtils.AddBackslash(TTMSFNCUtils.GetTempPath) + 'ttmsfncmemo');
  {$ENDIF}
  {$IFDEF LCLLIB}
  CreateDir(TTMSFNCUtils.GetTempPath + 'ttmsfncmemo');
  {$ENDIF}

  {$IFNDEF WEBLIB}
  f := TTMSFNCUtils.AddBackslash(TTMSFNCUtils.AddBackslash(TTMSFNCUtils.GetTempPath) + 'ttmsfncmemo')  + 'tmsfncmemo.zip';
  r := TTMSFNCUtils.GetResourceStream('TTMSFNCMEMO_ZIP', HInstance);
  if Assigned(r) then
  begin
    try
      r.SaveToFile(f);
    finally
      r.Free;
    end;
  end;
  {$ENDIF}

  {$IFNDEF LCLWEBLIB}
  z := TZipFile.Create;
  try
    if z.IsValid(f) then
    begin
      z.Open(f, zmRead);
      z.ExtractAll(TTMSFNCUtils.AddBackslash(TTMSFNCUtils.AddBackslash(TTMSFNCUtils.GetTempPath)+ 'ttmsfncmemo'));
      z.Close;
    end;
  finally
    z.Free;
  end;
  {$ENDIF}
  {$IFDEF LCLLIB}
   z := TUnZipper.Create;
  try
    z.FileName := f;
    z.OutputPath := TTMSFNCUtils.GetTempPath+ 'ttmsfncmemo\';
    z.Examine;
    z.UnZipAllFiles;
  finally
    z.Free;
  end;
  {$ENDIF}
end;

procedure TTMSFNCCustomMemo.CutToClipBoard;
begin
  ExecuteJavaScript(GetControlID + 'editor.trigger("CutToClipBoard", "editor.action.clipboardCutAction")');
end;

procedure TTMSFNCCustomMemo.Unselect;
begin
  ExecuteJavaScript(GetControlID + 'Unselect()');
end;

procedure TTMSFNCCustomMemo.UpdateBookmarks;
begin
  if not FBookmarks.DelimitedText.Trim.IsEmpty then
    ExecuteJavascript(GetControlId + 'SetBookmarks(' + FBookmarks.DelimitedText.Trim  + ')');
end;

procedure TTMSFNCCustomMemo.UpdateBreakpoints;
begin
  if not FBreakpoints.DelimitedText.Trim.IsEmpty then
    ExecuteJavascript(GetControlId + 'SetBreakPoints("' + FBreakpoints.DelimitedText.Trim + '")');
end;

procedure TTMSFNCCustomMemo.UpdateLineHighlight;
begin
  if not FLineHighlights.DelimitedText.Trim.IsEmpty then
    ExecuteJavascript(GetControlId + 'SetLineHighlight(' + FLineHighlights.DelimitedText.Trim + ')');
end;

procedure TTMSFNCCustomMemo.UpdateOption(AOption: string);
begin
  if IsInitialized then
    ExecuteJavaScript(GetControlID + 'editor.updateOptions(' + AOption + ')');
end;

function TTMSFNCCustomMemo.XYToPos(const X,
  Y: Integer): TTMSFNCMemoCaretPosition;
var
  s: string;
  j: TJSONValue;
begin
  s := ExecuteJavaScriptSync('encodeURIComponent(JSON.stringify(' + GetControlID + 'editor.getTargetAtClientPoint(' + IntToStr(X) + ', ' + IntToStr(Y) + ')))');

  s := ParseValue(s);
  s := TTMSFNCUtils.URLDecode(s);

  j := TTMSFNCUtils.ParseJSON(s);
  if Assigned(j) then
  begin
    try
      Result.Line := j['position']['lineNumber'].AsInteger;
      Result.Pos := j['position']['column'].AsInteger;
    finally
      j.Free;
    end;
  end;
end;

procedure TTMSFNCCustomMemo.DeleteLine(AIndex: Integer);
begin
  ExecuteJavaScriptSync(GetControlID + 'DeleteLine(' + IntToStr(AIndex + 1) + ')');
end;

procedure TTMSFNCCustomMemo.DeleteSelection;
begin
  Replace('');
end;

destructor TTMSFNCCustomMemo.Destroy;
begin
  FLines.Free;
  FOptions.Free;
  FBreakpoints.Free;
  FLanguages.Free;
  FBookmarks.Free;
  FLineHighlights.Free;
  FCompletionList.Free;
  FLanguageFileExtensionsMap.Free;
  FSources.Free;
  inherited;
end;

procedure TTMSFNCCustomMemo.DoControlInitialized;
var
  t, l, w,s: string;
  ft: TTMSFNCMemoFont;
begin
  {$IFDEF WEBLIB}
  if IsInitialized then
    Exit;
  {$ENDIF}

  inherited;

  ft := TTMSFNCMemoFont.Create;
  try
    ft.Assign(Font);
    {$IFDEF CMNLIB}
    ft.Height := TTMSFNCUtils.MulDivInt(ft.Height, 96, 72);
    {$ENDIF}

    case FTheme of
      mtVisualStudio:
      begin
        {$IFNDEF WEBLIB}
        ExecuteJavascript('document.documentElement.style.setProperty("--tmsfncmemo-bg-theme", "#fff")');
        {$ENDIF}
        t := 'vs';
      end;
      mtVisualStudioDark:
      begin
        {$IFNDEF WEBLIB}
        ExecuteJavascript('document.documentElement.style.setProperty("--tmsfncmemo-bg-theme", "#1e1e1e")');
        {$ENDIF}
        t := 'vs-dark';
      end;
      mtHighContrastDark:
      begin
        {$IFNDEF WEBLIB}
        ExecuteJavascript('document.documentElement.style.setProperty("--tmsfncmemo-bg-theme", "#000")');
        {$ENDIF}
        t := 'hc-black';
      end;
    end;

    case Options.RenderWhiteSpace of
      mwNone: w := 'none';
      mwAll: w := 'all';
      mwBoundary: w := 'boundary';
      mwSelection: w := 'selection';
      mwTrailing: w := 'trailing';
    end;

    case Options.RenderLineHighlight of
      hlNone: l := 'none';
      hlAll: l := 'all';
      hlLine: l := 'line';
      hlGutter: l := 'gutter';
    end;

    case Options.WordWrap of
      wwtOn: s := '"on"';
      wwtOff: s := '"off"';
      wwtWordWrapColumn: s := '"wordWrapColumn"';
      wwtBounded: s := '"bounded"';
    end;

    ExecuteJavaScript(GetControlID + 'LoadMonaco("' +
            FLanguages[Integer(FLanguage)] + '","' +
            t + '", "' +
            TTMSFNCUtils.Encode64(Lines.Text) + '",' +
            BoolToStr(FOptions.LineNumbers, true).ToLower + ',' +
            BoolToStr(FOptions.FormatOnPaste, true).ToLower + ',' +
            BoolToStr(FOptions.EmptySelectionClipboard, true).ToLower + ',' +
            BoolToStr(FOptions.FoldingHighlights, true).ToLower + ',' +
            BoolToStr(FOptions.MouseWheelZoom, true).ToLower + ',' +
            BoolToStr(FOptions.RoundedSelection, true).ToLower + ',' +
            BoolToStr(FOptions.Folding, true).ToLower + ',' +
            BoolToStr(FOptions.IsReadOnly, true).ToLower + ',' +
            BoolToStr(FOptions.GlyphMargin, true).ToLower + ',' +
            BoolToStr(FOptions.DragAndDrop, true).ToLower + ',' +
            FOptions.MiniMap.ToJSString + ',' +
            BoolToStr(FOptions.ContextMenu, true).ToLower + ',' +
            BoolToStr(FOptions.CodeLens, true).ToLower + ',' +
            BoolToStr(FOptions.FormatOnType, true).ToLower + ',' +
            BoolToStr(FOptions.Links, true).ToLower + ',' +
            FOptions.Scrollbar.ToJSString + ',' +
            BoolToStr(FWantTab,true).ToLower + ',' +
            BoolToStr(FOptions.RenderIndentGuides,true).ToLower + ',' +
            '"' + l + '",' +
            BoolToStr(FOptions.RenderLineHighlightOnlyWhenFocus	,true).ToLower + ',' +
            '"' + w + '",' +
            BoolToStr(FOptions.SelectOnLineNumbers,true).ToLower + ',' +
            BoolToStr(FOptions.SelectionHighlight,true).ToLower + ',' +
            IntToStr(FOptions.OverviewRulerLanes) + ',' +
            IntToStr(FOptions.TabSize) +  ',' +
            '"' + ft.Name + '",' +
            FloatToStr(ft.Size) + ',' +
            BoolToStr(FOptions.BlockSelection,true).ToLower + ',' +
            '"' + FBreakpoints.DelimitedText.Trim	 +  '",' +
           '"' + FBookmarks.DelimitedText.Trim	 +  '",' +
            '"' + FLineHighlights.DelimitedText.Trim	 +  '",' +
            FOptions.QuickSuggestion.ToJSString +  ',' +
            BoolToStr(FOptions.OverviewRulerBorder, true).ToLower + ',' +
            s + ',' + IntToStr(FOptions.WordWrapColumn) + ',' +
            BoolToStr(FOptions.UseCustomCodeCompletion, true).ToLower + '' +
            ')', {$IFDEF LCLWEBLIB}@{$ENDIF}DoControlInitializedInternal);

  finally
    ft.Free;
  end;

  FInitialized := true;

  if (FActiveSource = -1) and (FSources.Count > 0)  then
    FActiveSource := 0;
end;

procedure TTMSFNCCustomMemo.DoControlInitializedInternal(const Value: string);
begin
  if Assigned(OnInitialized) then
    OnInitialized(Self);
end;

procedure TTMSFNCCustomMemo.DoDragDrop(const Data: string);
var
  j: TJSONValue;
  a: TJSONArray;
  x, y: Integer;
  s: string;
  i: Integer;
  {$IFDEF FMXLIB}
  d: TDragObject;
  p: TPointF;
  o: TDragOperation;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  l: Boolean;
  {$ENDIF}
  b: TTMSFNCMemoDragObject;
  f: TTMSFNCMemoDragFile;
begin
  j := TTMSFNCUtils.ParseJSON(Data);
  if Assigned(j) then
  begin
    try
      x := j['clientX'].AsInteger;
      y := j['clientY'].AsInteger;

      b := TTMSFNCMemoDragObject.Create(Self);
      try
        if Assigned(j['pos']) then
        begin
          b.FPosition.Line := j['pos']['lineNumber'].AsInteger;
          b.FPosition.Pos := j['pos']['column'].AsInteger;
        end;
        a :=  j['data'].AsArray;
        if Assigned(a) then
        begin
          for i := 0 to a.Count -1 do
          begin
            f := TTMSFNCMemoDragFile.Create(Self);
            f.Name := a[i]['fileName'].AsString;
            f.Size := a[i]['fileSize'].AsInteger;
            f.MIMEType := a[i]['MIMEType'].AsString;
            b.Files.Add(f);
          end;
        end;

        b.Text := j['text'].AsString;

        if b.Files.Count > 0 then
          b.Kind := TTMSFNCMemoDragObjectKind.otFile
        else
          b.Kind := TTMSFNCMemoDragObjectKind.otText;

        s := j['dragOperation'].AsString;
        {$IFDEF FMXLIB}
        p.Create(x, y);

        if s = 'none' then
          o := TDragOperation.None
        else if s = 'link' then
          o := TDragOperation.Link
        else if s = 'move' then
          o := TDragOperation.Move
        else if s = 'copy' then
          o := TDragOperation.Copy;

        d.Source := b;
        DragOver(d, p, o);

        if not (O = TDragOperation.None) then
        begin
          if Assigned(OnDragDrop) then
            OnDragDrop(Self, d, p)
          else if Options.DragAndDrop then
          begin
            case b.Kind of
              otText:
              begin
                Replace(PosToTextPos(XYToPos(x, y)), 0, b.Text);
              end;
              otFile: b.Files[0].Open;
            end;
          end;
        end;

        {$ENDIF}
        {$IFNDEF FMXLIB}
        l := true;

        if Assigned(OnDragOver) then
          OnDragOver(Self, b, x, y, dsDragMove, l);

        if l then
        begin
          if Assigned(OnDragDrop) then
            OnDragDrop(self, b, x, y)
          else if Options.DragAndDrop then
          begin
            case b.Kind of
              otText:
              begin
                Replace(PosToTextPos(XYToPos(x, y)), 1, b.Text);
              end;
              otFile: b.Files[0].Open;
            end;
          end;
        end;
        {$ENDIF}
      finally
        b.Free;
      end;
    finally
      j.Free;
    end;
  end;
end;

procedure TTMSFNCCustomMemo.DoDragEnd(const Data: string);
begin
  {$IFDEF FMXLIB}
  DragEnd;
  {$ENDIF}
end;

procedure TTMSFNCCustomMemo.DoDragEnter(const Data: string);
var
  j: TJSONValue;
  a: TJSONArray;
  x, y: Integer;
  s: string;
  i: Integer;
  {$IFDEF FMXLIB}
  d: TDragObject;
  p: TPointF;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  l: Boolean;
  {$ENDIF}
  b: TTMSFNCMemoDragObject;
  f: TTMSFNCMemoDragFile;
begin
  j := TTMSFNCUtils.ParseJSON(Data);
  if Assigned(j) then
  begin
    try
      x := j['clientX'].AsInteger;
      y := j['clientY'].AsInteger;

      b := TTMSFNCMemoDragObject.Create(Self);
      try
        if Assigned(j['pos']) then
        begin
          b.FPosition.Line := j['pos']['lineNumber'].AsInteger;
          b.FPosition.Pos := j['pos']['column'].AsInteger;
        end;
        a :=  j['data'].AsArray;
        if Assigned(a) then
        begin
          for i := 0 to a.Count -1 do
          begin
            f := TTMSFNCMemoDragFile.Create(Self);
            f.Name := a[i]['fileName'].AsString;
            f.Size := a[i]['fileSize'].AsInteger;
            f.MIMEType := a[i]['MIMEType'].AsString;
            b.Files.Add(f);
          end;
        end;

        b.Text := j['text'].AsString;

        if b.Files.Count > 0 then
          b.Kind := TTMSFNCMemoDragObjectKind.otFile
        else
          b.Kind := TTMSFNCMemoDragObjectKind.otText;

        s := j['dragOperation'].AsString;
        {$IFDEF FMXLIB}
        p.Create(x, y);

        d.Source := b;
        DragEnter(d, p);

        {$ENDIF}
        {$IFNDEF FMXLIB}
        l := true;
        DragOver(b, x, y, dsDragEnter, l);
        {$ENDIF}
      finally
        b.Free;
      end;
    finally
      j.Free;
    end;
  end;
end;

procedure TTMSFNCCustomMemo.DoDragLeave(const Data: string);
{$IFDEF VCLLIB}
var
  j: TJSONValue;
  a: TJSONArray;
  x, y: Integer;
  i: Integer;
  l: Boolean;
  b: TTMSFNCMemoDragObject;
  f: TTMSFNCMemoDragFile;
  {$ENDIF}
begin
{$IFDEF VCLLIB}
  j := TTMSFNCUtils.ParseJSON(Data);
  if Assigned(j) then
  begin
    try
      x := j['clientX'].AsInteger;
      y := j['clientY'].AsInteger;

      b := TTMSFNCMemoDragObject.Create(Self);
      try
        b.Text := j['text'].AsString;
        a :=  j['data'].AsArray;
        if Assigned(a) then
        begin
          for i := 0 to a.Count -1 do
          begin
            f := TTMSFNCMemoDragFile.Create(Self);
            f.Name := a[i]['fileName'].AsString;
            f.Size := a[i]['fileSize'].AsInteger;
            f.MIMEType := a[i]['MIMEType'].AsString;
            b.Files.Add(f);
          end;
        end;

        if b.Files.Count > 0 then
          b.Kind := TTMSFNCMemoDragObjectKind.otFile
        else
          b.Kind := TTMSFNCMemoDragObjectKind.otText;

        l := true;
        Dragover(b, x, y, dsDragMove, l);
    finally
        b.Free;
      end;
   finally
      j.Free;
    end;
  end;
  {$ENDIF}
  {$IFDEF FMXLIB}
  DragLeave;
  {$ENDIF}
end;

procedure TTMSFNCCustomMemo.DoDragOver(const Data: string);
var
  j: TJSONValue;
  a: TJSONArray;

  x, y: Integer;
  s: string;
  i: Integer;
  {$IFDEF FMXLIB}
  d: TDragObject;
  p: TPointF;
  o: TDragOperation;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  l: Boolean;
  {$ENDIF}
  b: TTMSFNCMemoDragObject;
  f: TTMSFNCMemoDragFile;
begin
  j := TTMSFNCUtils.ParseJSON(Data);
  if Assigned(j) then
  begin
    try
      x := j['clientX'].AsInteger;
      y := j['clientY'].AsInteger;

      b := TTMSFNCMemoDragObject.Create(Self);
      try
        if Assigned(j['pos']) then
        begin
          b.FPosition.Line := j['pos']['lineNumber'].AsInteger;
          b.FPosition.Pos := j['pos']['column'].AsInteger;
        end;
        a :=  j['data'].AsArray;
        if Assigned(a) then
        begin
          for i := 0 to a.Count -1 do
          begin
            f := TTMSFNCMemoDragFile.Create(Self);
            f.Name := a[i]['fileName'].AsString;
            f.Size := a[i]['fileSize'].AsInteger;
            f.MIMEType := a[i]['MIMEType'].AsString;
            b.Files.Add(f);
          end;
        end;

        b.Text := j['text'].AsString;

        if b.Files.Count > 0 then
          b.Kind := TTMSFNCMemoDragObjectKind.otFile
        else
          b.Kind := TTMSFNCMemoDragObjectKind.otText;

        s := j['dragOperation'].AsString;
        {$IFDEF FMXLIB}
        p.Create(x, y);

        if s = 'none' then
          o := TDragOperation.None
        else if s = 'link' then
          o := TDragOperation.Link
        else if s = 'move' then
          o := TDragOperation.Move
        else if s = 'copy' then
          o := TDragOperation.Copy;


        d.Source := b;
        Dragover(d, p, o);
        {$ENDIF}
        {$IFNDEF FMXLIB}
        l := true;
        if Assigned(OnDragOver) then
          OnDragOver(Self, b, x, y, dsDragMove, l);
        {$ENDIF}
      finally
        b.Free;
      end;
    finally
      j.Free;
    end;
  end;
end;

procedure TTMSFNCCustomMemo.DoEnter;
begin
  inherited;
  ExecuteJavascript(GetControlId + 'editor.focus()');
end;

procedure TTMSFNCCustomMemo.DoExit;
begin
  inherited;
end;

procedure TTMSFNCCustomMemo.DoFind(AMatches: TTMSFNCMemoMatches);
begin
  if Assigned(OnFindAll) then
    OnFindAll(Self, AMatches);
end;

procedure TTMSFNCCustomMemo.DoFindInternal(const Value: string);
var
  v: string;
  j: TJSONValue;
  JSON: TJSONArray;
  i: Integer;
  match: TTMSFNCMemoMatch;
  matches: TTMSFNCMemoMatches;
  t: ITask;
begin
  if Value = 'null' then
    Exit;

  v := Value;
  v := ParseValue(v);
  v := TTMSFNCUtils.URLDecode(v);
  j := TTMSFNCUtils.ParseJSON(v);

  if Assigned(j) then
  begin
    try
      if j is TJSONArray then
      begin
        JSON := j as TJSONArray;
        SetLength(matches, JSON.Count);
        for I := 0 to JSON.Count -1 do
        begin
          match.Position := JSON[i]['selStart'].AsInteger;
          match.Range := JSON[i]['selStop'].AsInteger - match.Position;
          match.Match := JSON[i]['matches'].AsArray.Items[0].AsString;

          matches[i] := match;
        end;

        {$IFDEF LCLWEBLIBTHREAD}
        t := TTask4.Create(@DoFind, matches);
        {$ELSE}
        t := TTask.Create(
        procedure
        begin
          TThread.Synchronize(TThread.CurrentThread,
          procedure
          begin
            DoFind(matches);
          end);
        end
        );
       {$ENDIF}
        t.Start;
      end;
    finally
      j.Free;
    end;
  end;
end;

procedure TTMSFNCCustomMemo.DoKeyPressed(var Key: Word);
begin
  inherited;
  if (Key = KEY_F5) then
    Key := 0;

  if not Options.CommandPalette and (Key = KEY_F1) then
    Key := 0;
end;

procedure TTMSFNCCustomMemo.DoLineNumberClick(ALineNumber: Integer);
begin
  if Assigned(OnLineNumberClick) then
    OnLineNumberClick(Self, ALineNumber);
end;

procedure TTMSFNCCustomMemo.DoLineNumberMiddleClick(ALineNumber: Integer);
begin
  if Assigned(OnLineNumberMiddleClick) then
    OnLineNumberMiddleClick(Self, ALineNumber);
end;

procedure TTMSFNCCustomMemo.DoLineNumberRightClick(ALineNumber: Integer);
begin
  if Assigned(OnLineNumberRightClick) then
    OnLineNumberRightClick(Self, ALineNumber);
end;

procedure TTMSFNCCustomMemo.DoMemoKeyDown(const data: string);
var
  j: TJSONValue;
  wd: Word;
  c: Char;
  s: TShiftState;
  i: Integer;
begin
  j := TTMSFNCUtils.ParseJSON(data);

  if assigned(j) then
  begin
    try
      wd := Word(j['keyCode'].AsInteger);
      if j['key'].AsString.Length = 1 then
        c := j['key'].AsString[1];

      for i := 0 to j['shiftState'].AsArray.Count -1 do
      begin
        if j['shiftState'].AsArray[i].AsString = 'ssShift' then
          Include(s, ssShift);

        if j['shiftState'].AsArray[i].AsString = 'ssCtrl' then
          Include(s, ssCtrl);

        if j['shiftState'].AsArray[i].AsString = 'ssAlt' then
          Include(s, ssAlt);
      end;
      {$IFDEF FMXLIB}
      if Assigned(OnKeyDown) then
        OnKeyDown(Self, wd, c, s);
      {$ELSE}
      if Assigned(OnKeyDown) then
        OnKeyDown(Self, wd, s);
      KeyPress(c);

      if Assigned(OnKeyPress) then
        OnKeyPress(Self, c);
      {$ENDIF}
    finally
      j.Free;
    end;
  end;
end;

procedure TTMSFNCCustomMemo.DoMemoKeyUp(const data: string);
var
  j: TJSONValue;
  wd: Word;
  {$IFDEF FMXLIB}
  c: Char;
  {$ENDIF}
  s: TShiftState;
  i: Integer;
begin
  j := TTMSFNCUtils.ParseJSON(data);
  if assigned(j) then
  begin
    try
      wd := Word(j['keyCode'].AsInteger);
      {$IFDEF FMXLIB}
      if j['key'].AsString.Length = 1 then
      c := j['key'].AsString[1];
      {$ENDIF}
      for i := 0 to j['shiftState'].AsArray.Count -1 do
      begin
        if j['shiftState'].AsArray[i].AsString = 'ssShift' then
          Include(s, ssShift);

        if j['shiftState'].AsArray[i].AsString = 'ssCtrl' then
          Include(s, ssCtrl);

        if j['shiftState'].AsArray[i].AsString = 'ssAlt' then
          Include(s, ssAlt);
      end;
      {$IFDEF FMXLIB}
      if Assigned(OnKeyUp) then
        OnKeyUp(Self, wd,c , s);
      {$ELSE}
      if Assigned(OnKeyUp) then
        OnKeyUp(Self, wd, s);
      {$ENDIF}
    finally
      j.Free;
    end;
  end;
end;

procedure TTMSFNCCustomMemo.DoMemoMouseDown(const data: string);
var
  j: TJSONValue;
  x, y: Integer;
  s: TShiftState;
  i: Integer;
  mb: TMouseButton;
  lineNo: Integer;
begin
  j := TTMSFNCUtils.ParseJSON(data);
  if Assigned(j) then
  begin
    try
      mb := TMouseButton.mbLeft;
      x := j['posx'].AsInteger;
      y := j['posy'].AsInteger;

      for i := 0 to j['shiftState'].AsArray.Count -1 do
      begin
        if j['shiftState'].AsArray[i].AsString = 'ssShift' then
          Include(s, ssShift);

        if j['shiftState'].AsArray[i].AsString = 'ssCtrl' then
          Include(s, ssCtrl);

        if j['shiftState'].AsArray[i].AsString = 'ssAlt' then
          Include(s, ssAlt);

        if j['shiftState'].AsArray[i].AsString = 'ssLeft' then
        begin
          Include(s, ssLeft);
          mb := TMouseButton.mbLeft;
        end;

        if j['shiftState'].AsArray[i].AsString = 'ssMiddle' then
        begin
          Include(s, ssMiddle);
          mb := TMouseButton.mbMiddle;
        end;

        if j['shiftState'].AsArray[i].AsString = 'ssRight' then
        begin
          Include(s, ssRight);
          mb := TMouseButton.mbRight;
        end;
      end;

      if j['gutterClick'].AsBoolean = true then
      begin
        lineNo:= j['lineNumber'].AsInteger;
        case mb of
          TMouseButton.mbLeft:
          begin
            DoGutterClick(lineNo);
            DoGlyphMarginClick(lineNo);
          end;
          TMouseButton.mbRight:
          begin
            DoGutterRightClick(lineNo);
            DoGlyphMarginRightClick(lineNo);
          end;
          TMouseButton.mbMiddle:
          begin
            DoGutterMiddleClick(lineNo);
            DoGlyphMarginMiddleClick(lineNo);
          end;
        end;
      end;

      if j['lineClick'].AsBoolean = true then
      begin
        lineNo:= j['lineNumber'].AsInteger;
        case mb of
          TMouseButton.mbLeft:
          begin
            DoGutterClick(lineNo);
            DoLineNumberClick(lineNo);
          end;
          TMouseButton.mbRight:
          begin
            DoGutterRightClick(lineNo);
            DoLineNumberRightClick(lineNo);
          end;
          TMouseButton.mbMiddle:
          begin
            DoGutterMiddleClick(lineNo);
            DoLineNumberMiddleClick(lineNo);
          end;
        end;
      end;

      if Assigned(OnMouseDown) then
        OnMouseDown(Self, mb, s, x, y);

    finally
      j.Free;
    end;
  end;
end;

procedure TTMSFNCCustomMemo.DoMemoMouseMove(const data: string);
var
  j: TJSONValue;
  x, y: Integer;
  s: TShiftState;
  i: Integer;
begin
  j := TTMSFNCUtils.ParseJSON(data);
  if Assigned(j) then
  begin
    try
      x := j['posx'].AsInteger;
      y := j['posy'].AsInteger;
      for i := 0 to j['shiftState'].AsArray.Count -1 do
      begin
        if j['shiftState'].AsArray[i].AsString = 'ssShift' then
          Include(s, ssShift);

        if j['shiftState'].AsArray[i].AsString = 'ssCtrl' then
          Include(s, ssCtrl);

        if j['shiftState'].AsArray[i].AsString = 'ssAlt' then
          Include(s, ssAlt);

        if j['shiftState'].AsArray[i].AsString = 'ssLeft' then
        begin
          Include(s, ssLeft);
        end;

        if j['shiftState'].AsArray[i].AsString = 'ssMiddle' then
        begin
          include(s, ssMiddle);
        end;

        if j['shiftState'].AsArray[i].AsString = 'ssRight' then
        begin
          Include(s, ssRight);
        end;
      end;

      if Assigned(OnMouseMove) then
        OnMouseMove(Self, s, x, y);

      if not IsMouseEnter then
      begin
        if Assigned(OnMouseEnter) then
          OnMouseEnter(Self);
        IsMouseEnter := true;
      end;
    finally
      j.Free;
    end;
  end;
end;

procedure TTMSFNCCustomMemo.DoMemoMouseUp(const data: string);
var
  j: TJSONValue;
  x, y: Integer;
  s: TShiftState;
  i: Integer;
  mb: TMouseButton;
begin
  j := TTMSFNCUtils.ParseJSON(data);
  if Assigned(j) then
  begin
    try
      x := j['posx'].AsInteger;
      y := j['posy'].AsInteger;
      mb := TMouseButton.mbLeft;
      for i := 0 to j['shiftState'].AsArray.Count -1 do
      begin
        if j['shiftState'].AsArray[i].AsString = 'ssShift' then
          Include(s, ssShift);

        if j['shiftState'].AsArray[i].AsString = 'ssCtrl' then
          Include(s, ssCtrl);

        if j['shiftState'].AsArray[i].AsString = 'ssAlt' then
          Include(s, ssAlt);

        if j['shiftState'].AsArray[i].AsString = 'ssLeft' then
        begin
          Include(s, ssLeft);
          mb := TMouseButton.mbLeft;
        end;

        if j['shiftState'].AsArray[i].AsString = 'ssMiddle' then
        begin
          Include(s, ssMiddle);
          mb := TMouseButton.mbMiddle;
        end;

        if j['shiftState'].AsArray[i].AsString = 'ssRight' then
        begin
          Include(s, ssRight);
          mb := TMouseButton.mbRight;
        end;
      end;

      Click;

      if Assigned(OnMouseUp) then
        OnMouseUp(Self, mb,s, x, y);
    finally
      j.Free;
    end;
  end;
end;

procedure TTMSFNCCustomMemo.DoPaste(var APasteValue: string; var AAllow: Boolean);
begin
  if Assigned(OnPaste) then
    OnPaste(Self, APasteValue, AAllow);
end;

procedure TTMSFNCCustomMemo.DoPasteInternal(const Value: string);
var
  j: TJSONValue;
  s: string;
  allow: Boolean;
begin
  j := TTMSFNCUtils.ParseJSON(Value);

  if Assigned(j) then
  begin
    try
      s := j.AsString;
      allow := true;

      DoPaste(s, allow);

      if allow then
        ExecuteJavascript(GetControlID + 'PasteResolve({text: "' + TTMSFNCUtils.Encode64(s) + '", allow: true})')
      else
        ExecuteJavascript(GetControlID + 'PasteResolve({text: "' + TTMSFNCUtils.Encode64(s) + '", allow: false})');

    finally
      j.Free;
    end;
  end;
end;

procedure TTMSFNCCustomMemo.DoReplaceAll(ASearchString, AReplaceString: string; AMatches: TTMSFNCMemoMatches);
begin
  if Assigned(OnReplaceAll) then
    OnReplaceAll(Self, ASearchString, AReplaceString, AMatches);
end;

procedure TTMSFNCCustomMemo.DoReplaceAllInternal(const Value: string);
var
  v: string;
  j: TJSONValue;
  ja: TJSONArray;
  i: Integer;
  match: TTMSFNCMemoMatch;
  matches: TTMSFNCMemoMatches;
  t: ITask;
begin
  if Value = 'null' then
    Exit;

  v := Value;
  v := ParseValue(v);
  v := TTMSFNCUtils.URLDecode(v);
  j := TTMSFNCUtils.ParseJSON(v);

  if Assigned(j) then
  begin
    try
      if j['m'] is TJSONArray then
      begin
        ja := j['m'].AsArray;
        SetLength(matches, ja.Count);
        for I := 0 to ja.Count -1 do
        begin
          match.Position := ja[i]['selStart'].AsInteger;
          match.Range := ja[i]['selStop'].AsInteger - match.Position;
          match.Match := ja[i]['matches'].AsArray.Items[0].AsString;

          matches[i] := match;
        end;

        {$IFDEF LCLWEBLIBTHREAD}
        t := TTask3.Create(@DoReplaceAll, j['o'].AsString, j['r'].AsString, matches);
        {$ELSE}
        t := TTask.Create(
        procedure
        begin
          TThread.Synchronize(TThread.CurrentThread,
          procedure
          begin
            DoReplaceAll(j['o'].AsString, j['r'].AsString, matches);
          end);
        end
        );
       {$ENDIF}
        t.Start;
      end;
    finally
      j.Free;
    end;
  end;
end;

procedure TTMSFNCCustomMemo.DoReplaceNext(ASearchString, AReplaceString: string; AMatch: TTMSFNCMemoMatch);
begin
  if Assigned(OnReplaceNext) then
    OnReplaceNext(Self, ASearchString, AReplaceString, AMatch);
end;

procedure TTMSFNCCustomMemo.DoReplaceNextInternal(const Value: string);
var
  j: TJSONValue;
  match: TTMSFNCMemoMatch;
  v: string;
  t: ITask;
begin
  if Value = 'null' then
    Exit;

  v := Value;
  v := ParseValue(v);
  v := TTMSFNCUtils.URLDecode(v);
  j := TTMSFNCUtils.ParseJSON(v);

  if Assigned(j) then
  begin
    try
      if assigned(j['m']) then
      begin
        match.Position := j['m']['selStart'].AsInteger;
        match.Range := j['m']['selStop'].AsInteger - match.Position;
        match.Match := j['m']['matches'].AsArray.Items[0].AsString;
      end;

      {$IFDEF LCLWEBLIBTHREAD}
      t := TTask5.Create(@DoReplaceNext, j['o'].AsString, j['r'].AsString, match);
      {$ELSE}
      t := TTask.Create(
      procedure
      begin
        TThread.Synchronize(TThread.CurrentThread,
        procedure
        begin
          DoReplaceNext(j['o'].AsString, j['r'].AsString, match);
        end);
      end
      );
     {$ENDIF}
      t.Start;
    finally
      j.Free;
    end;
  end;
end;

procedure TTMSFNCCustomMemo.DoReplacePrevious(ASearchString, AReplaceString: string; AMatch: TTMSFNCMemoMatch);
begin
  if Assigned(OnReplacePrevious) then
    OnReplacePrevious(Self, ASearchString, AReplaceString, AMatch);
end;

procedure TTMSFNCCustomMemo.DoReplacePreviousInternal(const Value: string);
var
  j: TJSONValue;
  match: TTMSFNCMemoMatch;
  v: string;
  t: ITask;
begin
  if Value = 'null' then
    Exit;

  v := Value;
  v := ParseValue(v);
  v := TTMSFNCUtils.URLDecode(v);
  j := TTMSFNCUtils.ParseJSON(v);
  if Assigned(j) then
  begin
    try
      if assigned(j['m']) then
      begin
        match.Position := j['m']['selStart'].AsInteger;
        match.Range := j['m']['selStop'].AsInteger - match.Position;
        match.Match := j['m']['matches'].AsArray.Items[0].AsString;
      end;

      {$IFDEF LCLWEBLIBTHREAD}
      t := TTask5.Create(@DoReplacePrevious, j['o'].AsString, j['r'].AsString, match);
      {$ELSE}
      t := TTask.Create(
      procedure
      begin
        TThread.Synchronize(TThread.CurrentThread,
        procedure
        begin
          DoReplacePrevious(j['o'].AsString, j['r'].AsString, match);
        end);
      end
      );
     {$ENDIF}
      t.Start;

    finally
      j.Free;
    end;
  end;
end;

procedure TTMSFNCCustomMemo.DoURLClick(AURL: string);
begin
  if Assigned(OnLinkClick) then
    OnLinkClick(Self, AURL);
end;

procedure TTMSFNCCustomMemo.DoURLClickInternal(const Value: string);
var
  j: TJSONValue;
begin
  j := TTMSFNCUtils.ParseJSON(Value);
  try
    if Options.AutoOpenLink then
      TTMSFNCUtils.OpenURL(j.AsString);

    if Assigned(j) then
      DoURLClick(j.AsString);
  finally
     j.Free;
  end;
end;

procedure TTMSFNCCustomMemo.EndUpdate;
begin
  inherited;
  Lines.EndUpdate;

  if (UpdateCount = 0) and IsInitialized then
  begin
    UpdateBreakpoints;
    UpdateBookmarks;
    UpdateLineHighlight;
  end;
end;

procedure TTMSFNCCustomMemo.DoFindNext(AMatch: TTMSFNCMemoMatch);
begin
  if Assigned(OnFindNext) then
    OnFindNext(Self, AMatch);
end;

procedure TTMSFNCCustomMemo.DoFindNextInternal(const Value: string);
var
  j: TJSONValue;
  match: TTMSFNCMemoMatch;
  v: string;
  t: ITask;
begin
  if Value = 'null' then
    Exit;

  v := Value;
  v := ParseValue(v);
  v := TTMSFNCUtils.URLDecode(v);
  j := TTMSFNCUtils.ParseJSON(v);

  if Assigned(j) then
  begin
    try
      match.Position := j['selStart'].AsInteger;
      match.Range := j['selStop'].AsInteger - match.Position;
      if j['matches'].AsArray <> nil then
        match.Match := j['matches'].AsArray.Items[0].AsString;

      {$IFDEF LCLWEBLIBTHREAD}
      t := TTask6.Create(@DoFindNext, match);
      {$ELSE}
      t := TTask.Create(
      procedure
      begin
        TThread.Synchronize(TThread.CurrentThread,
        procedure
        begin
          DoFindNext(match);
        end);
      end
      );
     {$ENDIF}
      t.Start;

    finally
      j.Free;
    end;
  end;
end;

procedure TTMSFNCCustomMemo.DoFindPrevious(AMatch: TTMSFNCMemoMatch);
begin
  if Assigned(OnFindPrevious) then
    OnFindNext(Self, AMatch);
end;

procedure TTMSFNCCustomMemo.DoFindPreviousInternal(const Value: string);
var
  j: TJSONValue;
  match: TTMSFNCMemoMatch;
  v: string;
  t: ITask;
begin
  if Value = 'null' then
    Exit;

  v := Value;
  v := ParseValue(v);
  v := TTMSFNCUtils.URLDecode(v);
  j := TTMSFNCUtils.ParseJSON(v);
  if Assigned(j) then
  begin
    try
      match.Position := j['selStart'].AsInteger;
      match.Range := j['selStop'].AsInteger - match.Position;
      match.Match := j['matches'].AsArray.Items[0].AsString;

      {$IFDEF LCLWEBLIBTHREAD}
      t := TTask6.Create(@DoFindPrevious, match);
      {$ELSE}
      t := TTask.Create(
      procedure
      begin
        TThread.Synchronize(TThread.CurrentThread,
        procedure
        begin
          DoFindPrevious(match);
        end);
      end
      );
     {$ENDIF}
      t.Start;

    finally
      j.Free;
    end;
  end;
end;

procedure TTMSFNCCustomMemo.DoGetCodeCompletionInternal;
var
  s: string;
  cc: TTMSFNCMemoCodeCompletion;
begin
  cc := TTMSFNCMemoCodeCompletion.Create(Self, TTMSFNCMemoCodeCompletionItem);
  try
    cc.Assign(FCompletionList);

    DoGetCodeCompletion(GetWordAtPosition, cc, GetPosition);

    s := cc.ToJSONInternal;

    ExecuteJavaScript(GETCONTROLID + 'SuggestionResolve(' + s +')') ;
  finally
    cc.Free;
  end;
end;

procedure TTMSFNCCustomMemo.DoGetCodeCompletion(Token: string;
  CustomCompletionList: TTMSFNCMemoCodeCompletion;
  Position: TTMSFNCMemoCaretPosition);
begin
  if Assigned(OnGetCodeCompletion) then
    OnGetCodeCompletion(Self, Token, CustomCompletionList, Position);
end;

procedure TTMSFNCCustomMemo.DoGlyphMarginClick(ALineNumber: Integer);
begin
  if Assigned(OnGlyphMarginClick) then
    OnGlyphMarginClick(Self, ALineNumber);
end;

procedure TTMSFNCCustomMemo.DoGlyphMarginMiddleClick(ALineNumber: Integer);
begin
  if Assigned(OnGlyphMarginMiddleClick) then
    OnGlyphMarginMiddleClick(Self, ALineNumber);
end;

procedure TTMSFNCCustomMemo.DoGlyphMarginRightClick(ALineNumber: Integer);
begin
  if Assigned(OnGlyphMarginRightClick) then
    OnGlyphMarginRightClick(Self, ALineNumber);
end;

procedure TTMSFNCCustomMemo.DoGutterClick(ALineNumber: Integer);
begin
  if Assigned(OnGutterClick) then
    OnGutterClick(Self, ALineNumber);
end;

procedure TTMSFNCCustomMemo.DoGutterMiddleClick(ALineNumber: Integer);
begin
  if Assigned(OnGutterMiddleClick) then
    OnGutterMiddleClick(Self, ALineNumber);
end;

procedure TTMSFNCCustomMemo.DoGutterRightClick(ALineNumber: Integer);
begin
  if Assigned(OnGutterRightClick) then
    OnGutterRightClick(Self, ALineNumber);
end;

procedure TTMSFNCCustomMemo.FindAll(ASearchString: string;
  AOptions: TTMSFNCMemoFindOptions);
var
  sep, lim: string;
begin
  if AOptions.WordSeparators.IsEmpty then
    sep := 'null'
  else
    sep := '"' + AOptions.WordSeparators + '"';

  if AOptions.LimitResultCount = -1 then
    lim := ''
  else
    lim := ', ' + IntToStr(AOptions.LimitResultCount);

  ExecuteJavascript('encodeURIComponent(JSON.stringify(' + GetControlID + 'FindMatches("' + ASearchString + '", '
                    + BoolToStr(AOptions.SearchOnlyEditableRange, true).ToLower + ', '
                    + BoolToStr(AOptions.IsRegex, true).ToLower + ', '
                    + BoolToStr(AOptions.MatchCase, true).ToLower + ', '
                    + sep + ', '
                    + BoolToStr(AOptions.CaptureMatches, true).ToLower
                    + lim + ' )))', {$IFDEF LCLWEBLIB}@{$ENDIF}DoFindInternal);
end;

procedure TTMSFNCCustomMemo.FindAllInRange(ASearchString: string; ASelStart,
  ASelLength: Integer; AOptions: TTMSFNCMemoFindOptions);
var
  sep, lim: string;
begin
  if AOptions.WordSeparators.IsEmpty then
    sep := 'null'
  else
    sep := '"' + AOptions.WordSeparators + '"';

  if AOptions.LimitResultCount = -1 then
    lim := ''
  else
    lim := ', ' + IntToStr(AOptions.LimitResultCount);

  ExecuteJavascript('encodeURIComponent(JSON.stringify(' + GetControlID + 'FindMatchesInRange("' + ASearchString + '",'
                   + IntToStr(ASelStart) + ','
                   + IntToStr(ASelStart+ ASelLength) + ', '
                   + BoolToStr(AOptions.IsRegex, true).ToLower + ', '
                   + BoolToStr(AOptions.MatchCase, true).ToLower + ', '
                   + sep + ', '
                   + BoolToStr(AOptions.CaptureMatches, true).ToLower
                   + lim
                   + ')))', {$IFDEF LCLWEBLIB}@{$ENDIF}DoFindInternal);
end;

procedure TTMSFNCCustomMemo.FindNext(ASearchString: string; APos: Integer;
  AOptions: TTMSFNCMemoFindOptions);
var
  sep: string;
begin
  if AOptions.WordSeparators.IsEmpty then
    sep := 'null'
  else
    sep := '"' + AOptions.WordSeparators + '"';

  ExecuteJavascript('encodeURIComponent(JSON.stringify(' + GetControlID + 'FindNextMatchAt("' + ASearchString + '", '
                    + IntToStr(APos) + ', '
                    + BoolToStr(AOptions.SetSelect).ToLower + ','
                    + BoolToStr(AOptions.IsRegex, true).ToLower + ', '
                    + BoolToStr(AOptions.MatchCase, true).ToLower + ', '
                    + sep + ', '
                    + BoolToStr(AOptions.CaptureMatches, true).ToLower
                    +')))', {$IFDEF LCLWEBLIB}@{$ENDIF}DoFindNextInternal);
end;

procedure TTMSFNCCustomMemo.FindNext(ASearchString: string;
  AOptions: TTMSFNCMemoFindOptions);
var
  sep: string;
begin
  if AOptions.WordSeparators.IsEmpty then
    sep := 'null'
  else
    sep := '"' + AOptions.WordSeparators + '"';

  ExecuteJavascript('encodeURIComponent(JSON.stringify(' + GetControlID + 'FindNextMatch("' + ASearchString + '",'
                    + BoolToStr(AOptions.SetSelect).ToLower + ','
                    + BoolToStr(AOptions.IsRegex, true).ToLower + ', '
                    + BoolToStr(AOptions.MatchCase, true).ToLower + ', '
                    + sep + ', '
                    + BoolToStr(AOptions.CaptureMatches, true).ToLower
                    +')))', {$IFDEF LCLWEBLIB}@{$ENDIF}DoFindNextInternal);
end;

procedure TTMSFNCCustomMemo.FindPrevious(ASearchString: string;
  AOptions: TTMSFNCMemoFindOptions);
var
  sep: string;
begin
  if AOptions.WordSeparators.IsEmpty then
    sep := 'null'
  else
    sep := '"' + AOptions.WordSeparators + '"';

  ExecuteJavascript('encodeURIComponent(JSON.stringify(' + GetControlID + 'FindPreviousMatch("' + ASearchString + '", '
                    + BoolToStr(AOptions.SetSelect).ToLower + ','
                    + BoolToStr(AOptions.IsRegex, true).ToLower + ', '
                    + BoolToStr(AOptions.MatchCase, true).ToLower + ', '
                    + sep + ', '
                    + BoolToStr(AOptions.CaptureMatches, true).ToLower
                    +')))', {$IFDEF LCLWEBLIB}@{$ENDIF}DoFindPreviousInternal);
end;

procedure TTMSFNCCustomMemo.FindPrevious(ASearchString: string; APos: Integer;
  AOptions: TTMSFNCMemoFindOptions);
var
  sep: string;
begin
  if AOptions.WordSeparators.IsEmpty then
    sep := 'null'
  else
    sep := '"' + AOptions.WordSeparators + '"';

  ExecuteJavascript('encodeURIComponent(JSON.stringify(' + GetControlID + 'FindPreviousMatchAt("' + ASearchString + '",'
                    + IntToStr(APos) + ', '
                    + BoolToStr(AOptions.SetSelect).ToLower + ','
                    + BoolToStr(AOptions.IsRegex, true).ToLower + ', '
                    + BoolToStr(AOptions.MatchCase, true).ToLower + ', '
                    + sep + ', '
                    + BoolToStr(AOptions.CaptureMatches, true).ToLower
                    +')))', {$IFDEF LCLWEBLIB}@{$ENDIF}DoFindPreviousInternal);
end;

procedure TTMSFNCCustomMemo.FoldAll;
begin
  ExecuteJavaScript(GetControlID + 'editor.trigger("fold", "editor.foldAll")');
end;

procedure TTMSFNCCustomMemo.FormatDocument;
begin
  ExecuteJavaScript(GetControlID + 'editor.trigger("editor", "editor.action.formatDocument")');
end;

procedure TTMSFNCCustomMemo.FormatSelection;
begin
  ExecuteJavaScript(GetControlID + 'editor.trigger("editor", "editor.action.formatSelection")');
end;

function TTMSFNCCustomMemo.GetBody: string;
begin
  Result := '<div id="' + GetControlID + 'container" class="container" style="height: 100%"></div>';
end;

function TTMSFNCCustomMemo.GetBookmark(Line: Integer): Boolean;
begin
  Result :=  GetBookmarksInternal(Line) > -1;
end;

function TTMSFNCCustomMemo.GetBookmarksCount: Integer;
begin
  Result := FBookmarks.Count;
end;

function TTMSFNCCustomMemo.GetBookmarksInternal(line: Integer): Integer;
begin
  Result := FBookmarks.IndexOf(IntToStr(Line));
end;

function TTMSFNCCustomMemo.GetBreakPointInternal(Line: Integer): Integer;
begin
  Result := FBreakPoints.IndexOf(IntToStr(Line));
end;

function TTMSFNCCustomMemo.GetBreakPoints: TStringList;
begin
  Result := FBreakpoints;
end;

function TTMSFNCCustomMemo.GetBreakPoint(Line: Integer): Boolean;
begin
  Result :=  GetBreakPointInternal(Line) > -1;
end;

function TTMSFNCCustomMemo.GetBreakpointCount: Integer;
begin
  Result := FBreakPoints.Count;
end;

function TTMSFNCCustomMemo.GetCustomCSS: string;
begin
  Result := {$IFNDEF WEBLIB}'body { margin: 0; padding: 2px;background-color: var(--tmsfncmemo-bg-theme); }' + LB + {$ENDIF}
            '.codicon {width: fit-content !important; min-width: 16px;z-index:999;}' + LB +
            '.codicon-debug-breakpoint:before{color: #e51400 !important;}' + LB +
            '[class^="codicon-"], [class*="codicon-"] {' + LB +
            ' font-family: "codicon"!important;' + LB +
            '}' + LB +
            '.codicon-custombookmark::after{content: "\eaab";color: #E4C809 !important;}' + LB +
            '.lineHighlight{background-color: #ed5555}' + LB +
            '.cgmr{justify-content: start !important; }';
end;

function TTMSFNCCustomMemo.GetCustomFunctions: string;
begin
  Result := LoadMemoScript(GetControlID, GetDefaultEventDataObject);
end;

function TTMSFNCCustomMemo.GetDocURL: string;
begin
  Result := 'https://download.tmssoftware.com/doc/tmsfncuipack/components/ttmsfncmemo/';
end;

function TTMSFNCCustomMemo.GetFont: TTMSFNCMemoFont;
begin
  Result := FOptions.Font;
end;

function TTMSFNCCustomMemo.GetLineContent(ALineNumber: Integer): string;
begin
  Result := TTMSFNCUtils.URLDecode(ParseValue(ExecuteJavaScriptSync('encodeURIComponent(' + GetControlID + 'editor.getModel().getLineContent(' + IntToStr(ALineNumber + 1) + '))')), false);
end;

function TTMSFNCCustomMemo.GetLineCount: Integer;
var
  i: Integer;
begin
  Result := 0;
  if TryStrToInt(ExecuteJavaScriptSync(GetControlID + 'editor.getModel().getLineCount()'), i) then
  begin
    Result := i;
    if (Result = 1) and (GetLineContent(0) = '') then
      Dec(Result);
  end;
end;

function TTMSFNCCustomMemo.GetLineHighlight(Line: Integer): Boolean;
begin
  Result := GetLineHighlightInternal(Line) > -1;
end;

function TTMSFNCCustomMemo.GetLineHighlightCount: Integer;
begin
  Result := FLineHighlights.Count;
end;

function TTMSFNCCustomMemo.GetLineHighlightInternal(line: Integer): Integer;
begin
  Result := FLineHighlights.IndexOf(IntToStr(Line));
end;

function TTMSFNCCustomMemo.GetLines: TTMSFNCMemoLines;
begin
  if not IsDesignTime then
  begin
    FLines.FReading := True;
    FLines.BeginUpdate;
    Result := FLines;
    FLines.EndUpdate;
    FLines.FReading := False;
  end
  else
    Result := FLines;
end;

function TTMSFNCCustomMemo.GetPosition: TTMSFNCMemoCaretPosition;
var
  s: string;
  j: TJSONValue;
begin
  s := ExecuteJavaScriptSync('encodeURIComponent(JSON.stringify(' + GetControlID + 'GetPosition()))');
  s := ParseValue(s);
  s := TTMSFNCUtils.URLDecode(s);
  j := TTMSFNCUtils.ParseJSON(s);
  if Assigned(j) then
  begin
    if Assigned(j) then
    begin
      try
        Result.Line := j['lineNumber'].AsInteger -1;
        Result.Pos := j['column'].AsInteger -1;
      finally
        j.Free;
      end;
    end;
  end;
end;

function TTMSFNCCustomMemo.GetReadonly: Boolean;
begin
  Result := Options.IsReadOnly
end;

procedure TTMSFNCCustomMemo.GetSelection;
var
  j: TJSONValue;
  s: string;
  i: Integer;
begin
  s := ExecuteJavaScriptSync('encodeURIComponent(JSON.stringify(' + GetControlID + 'GetSelection()))');
  s := ParseValue(s);
  s := TTMSFNCUtils.URLDecode(s);

  j := TTMSFNCUtils.ParseJSON(s);
  if Assigned(j) then
  begin
    try
      FSelStart := j['selStart'].AsInteger;
      i := j['selStop'].AsInteger;
      FSelLength := i - FSelStart;
    finally
      j.Free;
    end;
  end;
end;

function TTMSFNCCustomMemo.GetSelLength: Integer;
begin
  GetSelection;
  Result := FSelLength;
end;

function TTMSFNCCustomMemo.GetSelStart: Integer;
begin
  GetSelection;
  Result := FSelStart;
end;

function TTMSFNCCustomMemo.GetSelText: string;
begin
  Result := GetValue(SelStart, SelLength);
end;

function TTMSFNCCustomMemo.GetSourceIDCount: Integer;
begin
  Result := FSourceIDCount;
end;

function TTMSFNCCustomMemo.GetText: string;
begin
  Result := Lines.Text;
end;

function TTMSFNCCustomMemo.ParseValue(AValue: string): string;
begin
  Result := AValue;
  if Result = 'null' then
  begin
    Result := '';
    Exit;
  end;

  Result := StringReplace(Result, '[', '', [rfReplaceAll]);
  Result := StringReplace(Result, ']', '', [rfReplaceAll]);
  Result := StringReplace(Result, '"', '', [rfReplaceAll]);
end;

function TTMSFNCCustomMemo.GetValue: string;
var
  v: string;

  function TrimCharRight(s, CharsToTrim: string): string;
  var
    i, LengthOfS: integer;
  begin
    Result := s;
    LengthOfS := Length(s);
    for i := LengthOfS downto 1 do
      if Pos(s[i], CharsToTrim) = 0 then
      begin
        if i < LengthOfS then Delete(Result, i + 1, LengthOfS - i);
        Exit;
      end;
    Result := '';
  end;
begin
  v := ExecuteJavascriptSync(GetControlID + 'GetValue()');
  v := TTMSFNCUtils.URLDecode(ParseValue(v), false);
  Result := TrimCharRight(v, #0);;
end;

function TTMSFNCCustomMemo.GetValue(ASelStart, ASelLength: Integer): string;
begin
 Result := TTMSFNCUtils.URLDecode(ParseValue(ExecuteJavaScriptSync(GetControlID + 'GetValueAtRange(' + IntToStr(ASelStart) + ', ' + IntToStr(ASelStart + ASelLength) + ')')), false);
end;

function TTMSFNCCustomMemo.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

function TTMSFNCCustomMemo.GetVersionID: Integer;
var
  i: Integer;
begin
  Result := 0;
  if TryStrToInt(ExecuteJavascriptSync(GetControlID + 'editor.getModel().getVersionId()'), i) then
    Result := i;
end;

function TTMSFNCCustomMemo.GetWaitInitCondition: string;
begin
  {$IFDEF WEBLIB}
  Result := '!window.monaco || !e || !e2';
  {$ENDIF}
  {$IFNDEF WEBLIB}
  Result := '!window.monaco';
  {$ENDIF}
end;

{$IFDEF WEBLIB}
function TTMSFNCCustomMemo.GetWaitInitVariables: string;
begin
  Result :=
    '  var e = document.getElementById("' + ElementID + '");' + LB +
    '  var e2 = document.getElementById("' + GetControlID + 'container");';
end;
{$ENDIF}

function TTMSFNCCustomMemo.GetWordAtPosition: string;
begin
  Result := TTMSFNCUtils.URLDecode(ParseValue(ExecuteJavaScriptSync(GetControlID + 'GetWordAtCursor()')));
end;

function TTMSFNCCustomMemo.GetWordAtPosition(APos: Integer): string;
begin
  Result := TTMSFNCUtils.URLDecode(ParseValue(ExecuteJavaScriptSync(GetControlID + 'GetWordAtPosition(' + IntToStr(APos) + ')')));
end;

procedure TTMSFNCCustomMemo.LoadFromURL(AURL: string);
begin
  TTMSFNCCloudBase.DownloadFileFromURL(AURL,{$IFDEF LCLWEBLIB}@{$ENDIF}DoLoadFromURL);
end;

procedure TTMSFNCCustomMemo.DoLoadFromURL(const ARequestResult: TTMSFNCCloudBaseRequestResult);
var
  ext: string;
  i: Integer;
begin
  if not ARequestResult.Success then
    Exit;

  ext := ExtractFileExt(ARequestResult.GetURL);
  i := FLanguageFileExtensionsMap.MatchLanguage(ext);

  if i > -1 then
    Language := TTMSFNCMemoLanguage(i);

  ARequestResult.ResultStream.Position := 0;
  Lines.LoadFromStream(ARequestResult.ResultStream{$IFDEF WEBLIB}, TEncoding.Ansi{$ENDIF});
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCCustomMemo.LoadFromFile(AFileName: string);
var
  ext: string;
  i: Integer;
begin
  ext := ExtractFileExt(AFileName);
  i := LanguageFileExtensionsMap.MatchLanguage(ext);

  if i > -1 then
    Language := TTMSFNCMemoLanguage(i);

  Lines.LoadFromFile(AFileName);
end;
{$ENDIF}

procedure TTMSFNCCustomMemo.LoadLanguages;
begin
  FLanguages := TStringlist.Create;
  FLanguages.Add('abap');
  FLanguages.Add('aes');
  FLanguages.Add('apex');
  FLanguages.Add('azcli');
  FLanguages.Add('bat');
  FLanguageFileExtensionsMap.Add(mlBat, '.bat');
  FLanguages.Add('bicep');
  FLanguages.Add('c');
  FLanguages.Add('cameligo');
  FLanguages.Add('clojure');
  FLanguages.Add('coffeescript');
  FLanguages.Add('cpp');
  FLanguages.Add('csharp');
  FLanguageFileExtensionsMap.Add(mlCsharp, '.cs');
  FLanguages.Add('csp');
  FLanguages.Add('css');
  FLanguageFileExtensionsMap.Add(mlCss, '.css');
  FLanguages.Add('cypher');
  FLanguages.Add('dart');
  FLanguages.Add('dockerfile');
  FLanguages.Add('ecl');
  FLanguages.Add('elixir');
  FLanguages.Add('flow9');
  FLanguages.Add('freemarker2');
  FLanguages.Add('fsharp');
  FLanguages.Add('go');
  FLanguages.Add('graphql');
  FLanguages.Add('handlebars');
  FLanguages.Add('hcl');
  FLanguages.Add('html');
  FLanguageFileExtensionsMap.Add(mlHtml, '.html');
  FLanguages.Add('ini');
  FLanguages.Add('java');
  FLanguages.Add('javascript');
  FLanguageFileExtensionsMap.Add(mlJavascript, '.js');
  FLanguages.Add('json');
  FLanguageFileExtensionsMap.Add(mlJson, '.json');
  FLanguages.Add('julia');
  FLanguages.Add('kotlin');
  FLanguages.Add('less');
  FLanguages.Add('lexon');
  FLanguages.Add('liquid');
  FLanguages.Add('lua');
  FLanguages.Add('m3');
  FLanguages.Add('markdown');
  FLanguages.Add('mips');
  FLanguages.Add('msdax');
  FLanguages.Add('mysql');
  FLanguages.Add('objective-c');
  FLanguages.Add('pascal');
  FLanguageFileExtensionsMap.Add(mlPascal, '.pas');
  FLanguageFileExtensionsMap.Add(mlPascal, '.dpr');
  FLanguageFileExtensionsMap.Add(mlPascal, '.dfm');
  FLanguageFileExtensionsMap.Add(mlPascal, '.fmx');
  FLanguageFileExtensionsMap.Add(mlPascal, '.inc');
  FLanguageFileExtensionsMap.Add(mlPascal, '.dproj');
  FLanguages.Add('pascaligo');
  FLanguages.Add('perl');
  FLanguages.Add('pgsql');
  FLanguages.Add('php');
  FLanguages.Add('pla');
  FLanguages.Add('plaintext');
  FLanguageFileExtensionsMap.Add(mlPlaintext, '.txt');
  FLanguages.Add('postiats');
  FLanguages.Add('powerquery');
  FLanguages.Add('powershell');
  FLanguages.Add('proto');
  FLanguages.Add('pug');
  FLanguages.Add('python');
  FLanguages.Add('qsharp');
  FLanguages.Add('r');
  FLanguages.Add('razor');
  FLanguages.Add('redis');
  FLanguages.Add('redshift');
  FLanguages.Add('restructuredtext');
  FLanguages.Add('ruby');
  FLanguages.Add('rust');
  FLanguages.Add('sb');
  FLanguages.Add('scala');
  FLanguages.Add('scheme');
  FLanguages.Add('scss');
  FLanguages.Add('shell');
  FLanguages.Add('sol');
  FLanguages.Add('sparql');
  FLanguages.Add('sql');
  FLanguages.Add('st');
  FLanguages.Add('swift');
  FLanguages.Add('systemverilog');
  FLanguages.Add('tcl');
  FLanguages.Add('twig');
  FLanguages.Add('typescript');
  FLanguageFileExtensionsMap.Add(mlTypeScript, '.ts');
  FLanguages.Add('vb');
  FLanguages.Add('verilog');
  FLanguages.Add('xml');
  FLanguageFileExtensionsMap.Add(mlXml, '.xml');
  FLanguages.Add('yaml');
end;

{$IFDEF MSWINDOWS}
function EnumRCDataProc(hModule: THandle; lpszType, lpszName: PChar; lParam: NativeInt): Boolean; stdcall;
begin
  if Pos('TTMSFNCMEMO', lpszName) = 1 then
    TStrings(lParam).Add(lpszName);

  Result := True;
end;
{$ENDIF}

procedure TTMSFNCCustomMemo.LoadLinks(AList: TTMSFNCCustomWEBControlLinksList);
begin
  inherited;
  {$IFNDEF WEBLIB}
  if not (csDesigning in ComponentState) and (FLibraryLocation = llOffline) then
  begin
    AList.Add(TTMSFNCCustomWEBControlLink.CreateScript('file://' + TTMSFNCUtils.AddBackslash(TTMSFNCUtils.AddBackslash(TTMSFNCUtils.GetTempPath) + 'ttmsfncmemo') + 'main.js'));
  end
  else
  begin
  {$ENDIF}
    AList.Add(TTMSFNCCustomWEBControlLink.CreateScript('https://download.tmssoftware.com/fncwxpack/monaco/main.js'));
  {$IFNDEF WEBLIB}
  end;
  {$ENDIF}
end;

procedure TTMSFNCCustomMemo.OpenFile(AFile: TTMSFNCMemoDragFile);
begin
  ExecuteJavaScript(GetControlID +  'OpenFile("' + AFile.Name +  '")');
end;

procedure TTMSFNCCustomMemo.PasteFromClipBoard;
begin
  ExecuteJavaScript(GetControlID +  'editor.trigger("CutToClipBoard", "editor.action.clipboardPasteAction")');
end;

function TTMSFNCCustomMemo.PosToTextPos(
  const APosition: TTMSFNCMemoCaretPosition): Integer;
var
  i: Integer;
begin
  Result := 0;
  if TryStrToInt(ExecuteJavascriptSync(GetControlID + 'editor.getModel().getOffsetAt({lineNumber: ' + IntToStr(APosition.Line) + ', column: ' + IntToStr(APosition.Pos) + '})'),i) then
    Result := i;
end;

procedure TTMSFNCCustomMemo.Redo;
begin
  ExecuteJavaScript(GetControlID +  'editor.trigger("Redo", "redo")');
end;

procedure TTMSFNCCustomMemo.RemoveSource(AName: string);
begin
  Sources.Delete(Sources.IndexOf(AName));
end;

procedure TTMSFNCCustomMemo.Replace(AStartPos, ALength: Integer;
  AReplaceString: string);
begin
  if AStartPos = 0 then
    Inc(AStartPos);

  ExecuteJavascript(GetControlID + 'Replace(' + IntToStr(AStartPos) + ', ' + IntToStr(AStartPos + ALength) + ', "' + TTMSFNCUtils.URLEncode(AReplaceString) + '")');
end;

procedure TTMSFNCCustomMemo.Replace(AReplaceString: string);
begin
  ExecuteJavaScript(GetControlID + 'ReplaceCurrent("' + AReplaceString + '")');
end;

procedure TTMSFNCCustomMemo.Replace(ASearchString, AReplaceString: string;
  ABehaviour: TTMSFNCMemoFindBehaviour);
begin
  case ABehaviour of
    fbDown: ReplaceNext(ASearchString, AReplaceString);
    fbUp: ReplacePrevious(ASearchString, AReplaceString);
    fbAll: ReplaceAll(ASearchString, AReplaceString);
  end;
end;

procedure TTMSFNCCustomMemo.Replace(ASearchString, AReplaceString: string;
  AOptions: TTMSFNCMemoFindOptions; ABehaviour: TTMSFNCMemoFindBehaviour);
begin
  case ABehaviour of
    fbDown: ReplaceNext(ASearchString, AReplaceString, AOptions);
    fbUp: ReplacePrevious(ASearchString, AReplaceString, AOptions);
    fbAll: ReplaceAll(ASearchString, AReplaceString, AOptions);
  end;
end;

procedure TTMSFNCCustomMemo.ReplaceAll(ASearchString, AReplaceString: string);
begin
  ReplaceAll(ASearchString, AReplaceString, DefaultFindOptions);
end;

procedure TTMSFNCCustomMemo.ReplaceAll(ASearchString, AReplaceString: string;
  AOptions: TTMSFNCMemoFindOptions);
var
  sep: string;
begin
  if AOptions.WordSeparators.IsEmpty then
    sep := 'null'
  else
    sep := '"' + AOptions.WordSeparators + '"';

  ExecuteJavascript('encodeURIComponent(JSON.stringify(' + GetControlID + 'ReplaceAll("' + ASearchString + '", "' + AReplaceString + '", '
                    + BoolToStr(AOptions.SearchOnlyEditableRange, true).ToLower + ', '
                    + BoolToStr(AOptions.IsRegex, true).ToLower + ', '
                    + BoolToStr(AOptions.MatchCase, true).ToLower + ', '
                    + sep + ', '
                    + BoolToStr(AOptions.CaptureMatches, true).ToLower
                    + ' )))', {$IFDEF LCLWEBLIB}@{$ENDIF}DoReplaceAllInternal);
end;

procedure TTMSFNCCustomMemo.ReplaceNext(ASearchString, AReplaceString: string;
  AOptions: TTMSFNCMemoFindOptions);
var
  sep: string;
begin
  if AOptions.WordSeparators.IsEmpty then
    sep := 'null'
  else
    sep := '"' + AOptions.WordSeparators + '"';

  ExecuteJavascript('encodeURIComponent(JSON.stringify(' + GetControlID + 'ReplaceNext("' + ASearchString + '", "' + AReplaceString + '", '
                    + BoolToStr(AOptions.IsRegex, true).ToLower + ', '
                    + BoolToStr(AOptions.MatchCase, true).ToLower + ', '
                    + sep + ', '
                    + BoolToStr(AOptions.CaptureMatches, true).ToLower
                    +')))',{$IFDEF LCLWEBLIB}@{$ENDIF}DoReplaceNextInternal);
end;

procedure TTMSFNCCustomMemo.ReplacePrevious(ASearchString,
  AReplaceString: string; AOptions: TTMSFNCMemoFindOptions);
var
  sep: string;
begin
  if AOptions.WordSeparators.IsEmpty then
    sep := 'null'
  else
    sep := '"' + AOptions.WordSeparators + '"';

  ExecuteJavascript('encodeURIComponent(JSON.stringify(' + GetControlID + 'ReplacePrevious("' + ASearchString + '", "' + AReplaceString + '", '
                    + BoolToStr(AOptions.IsRegex, true).ToLower + ', '
                    + BoolToStr(AOptions.MatchCase, true).ToLower + ', '
                    + sep + ', '
                    + BoolToStr(AOptions.CaptureMatches, true).ToLower
                    +')))', {$IFDEF LCLWEBLIB}@{$ENDIF}DoReplacePreviousInternal);
end;

procedure TTMSFNCCustomMemo.ScrollToLine(ALineNumber: Integer);
begin
  ExecuteJavascript(GetControlID + 'editor.revealLine(' + IntToStr(ALineNumber) + ')');
end;

procedure TTMSFNCCustomMemo.ScrollToLineCenter(ALineNumber: Integer);
begin
  ExecuteJavascript(GetControlID + 'editor.revealLineInCenter(' + IntToStr(ALineNumber) + ')');
end;

procedure TTMSFNCCustomMemo.SelectAll;
begin
  ExecuteJavaScript(GetControlID +  'SelectAll()');
end;

procedure TTMSFNCCustomMemo.SetActiveSource(const Value: Integer);
var
  OldSource: Integer;
  i: TTMSFNCMemoSource;
begin
  if (FActiveSource = Value) or (Sources.Count = 0) then
    Exit;

  OldSource := FActiveSource;
  FActiveSource := Value;

  if (OldSource <> -1) and (Sources.Count > 1) then
  begin
    if not (csLoading in Componentstate) then
    begin
      i := Sources[OldSource];
      if Assigned(i) then
      begin
        i.Lines.Text := Lines.Text;
        i.FReadOnly := Readonly;
        i.FCaretPosition := GetPosition;
        i.FBreakPoints.Assign(FBreakpoints);
        i.FBookmarks.Assign(FBookmarks);
        i.FLineHighlights.Assign(FLineHighlights);
      end;

      i := Sources[FActiveSource];
      if Assigned(i) then
      begin
        Lines.Text := i.Lines.Text;
        ReadOnly := i.ReadOnly;
        FBreakPoints.Assign(i.FBreakPoints);
        FBookmarks.Assign(i.FBookmarks);
        FLineHighlights.Assign(i.FLineHighlights);
        SetPosition(i.CaretPosition);
        UpdateBreakpoints;
        UpdateBookmarks;
        UpdateLineHighlight;
      end;
    end;
  end
  else
  begin
    if not (csLoading in Componentstate) then
    begin
      i := Sources[FActiveSource];
      if Assigned(i) then
      begin
        i.Lines.Text := Lines.Text;
        i.FReadOnly := Readonly;
        i.FCaretPosition := GetPosition;
        i.FBreakPoints.Assign(FBreakpoints);
        i.FBookmarks.Assign(FBookmarks);
        i.FLineHighlights.Assign(FLineHighlights);
      end;
    end;
  end;
end;

procedure TTMSFNCCustomMemo.SetBookmark(Line: Integer; const Value: Boolean);
var
  i: Integer;
begin
  if Options.AutoGlyphMargin and not Options.GlyphMargin then
    Options.GlyphMargin := true;

  i := GetBookmarksInternal(Line);

  if (i = -1) And Value then
    FBookmarks.Add(IntToStr(Line))
  else if (i >= 0) and not Value then
    FBookmarks.Delete(i);

  if IsInitialized and (UpdateCount = 0) then
    UpdateBookmarks;
end;

procedure TTMSFNCCustomMemo.SetBreakPoint(Line: Integer; const Value: Boolean);
var
  i: Integer;
begin
  if Options.AutoGlyphMargin and not Options.GlyphMargin then
    Options.GlyphMargin := true;

  i := GetBreakPointInternal(Line);

  if (i = -1) And Value then
    FBreakPoints.Add(IntToStr(Line))
  else if (i >= 0) and not Value then
    FBreakPoints.Delete(i);

  if IsInitialized and (UpdateCount = 0) then
    UpdateBreakpoints;
end;

procedure TTMSFNCCustomMemo.SetFont(const Value: TTMSFNCMemoFont);
begin
  FOptions.Font.Assign(Value);
end;

procedure TTMSFNCCustomMemo.SetLanguage(const Value: TTMSFNCMemoLanguage);
begin
  FLanguage := Value;

  if not FInitialized then
    Exit;

  ExecuteJavascript(GetControlID + 'SetLanguage("' + FLanguages[Integer(FLanguage)] + '")');
end;

procedure TTMSFNCCustomMemo.SetLanguageFileExtensionsMap(
  const Value: TTMSFNCMemoLanguageFileExtensionMap);
begin
  FLanguageFileExtensionsMap.Assign(Value);
end;

procedure TTMSFNCCustomMemo.SetLibraryLocation(
  const Value: TTMSFNCMemoLibraryLocation);
begin
  FLibraryLocation := Value;
end;

procedure TTMSFNCCustomMemo.SetLineHighlight(Line: Integer;
  const Value: Boolean);
var
  i: Integer;
begin
  i := GetLineHighlightInternal(Line);
  if (i = -1) And Value then
    FLineHighlights.Add(IntToStr(Line))
  else if (i >= 0) and not Value then
    FLineHighlights.Delete(i);

  if IsInitialized and (UpdateCount = 0) then
    UpdateLineHighlight;
end;

procedure TTMSFNCCustomMemo.SetLines(const Value: TTMSFNCMemoLines);
begin
  FLines.Assign(Value);
end;

procedure TTMSFNCCustomMemo.SetOptions(const Value: TTMSFNCMemoOptions);
begin
  FOptions.Assign(Value);
end;

procedure TTMSFNCCustomMemo.SetPosition(APosition: TTMSFNCMemoCaretPosition);
begin
  ExecuteJavascriptSync(GetControlID + 'SetPosition(' + IntToStr(APosition.Line + 1) + ', ' + IntToStr(APosition.Pos + 1) +')');
end;

procedure TTMSFNCCustomMemo.SetReadonly(const Value: Boolean);
begin
  Options.IsReadOnly := Value;
end;

procedure TTMSFNCCustomMemo.SetSelection(ASelStart, ASelLength: Integer);
begin
  ExecuteJavascriptSync(GetControlID + 'SetSelect(' + IntToStr(ASelStart) + ',' + IntToStr(ASelStart + ASelLength) + ')');
end;

procedure TTMSFNCCustomMemo.SetSelLength(const Value: Integer);
begin
  FSelLength := Value;
  SetSelection(FSelStart, FSelLength);
end;

procedure TTMSFNCCustomMemo.SetSelStart(const Value: Integer);
begin
  FSelStart := Value;
  SetSelection(FSelStart, FSelLength);
end;

procedure TTMSFNCCustomMemo.SetSelText(const Value: string);
begin
  Replace(SelStart, SelLength, Value);
end;

procedure TTMSFNCCustomMemo.SetSourceIDCount(const Value: Integer);
begin
  FSourceIDCount := Value;
end;

procedure TTMSFNCCustomMemo.SetSources(const Value: TTMSFNCMemoSources);
begin
  FSources.Assign(Value);
  if (FSources.Count > 0) then
    FActiveSource := 0;
end;

procedure TTMSFNCCustomMemo.SetCompletionList(const Value: TTMSFNCMemoCodeCompletion);
begin
  FCompletionList.Assign(Value);
end;

procedure TTMSFNCCustomMemo.SetText(const Value: string);
begin
  if Lines.Text <> Value then
    Lines.Text := Value;
end;

procedure TTMSFNCCustomMemo.SetTheme(const Value: TTMSFNCMemoTheme);
begin
  FTheme := Value;

  if not FInitialized then
    Exit;

  case FTheme of
    mtVisualStudio:
    begin
      {$IFNDEF WEBLIB}
      ExecuteJavascript('document.documentElement.style.setProperty("--tmsfncmemo-bg-theme", "#fff")');
      {$ENDIF}
      ExecuteJavascript(GetControlID + 'setTheme("vs")');
    end;
    mtVisualStudioDark:
    begin
      {$IFNDEF WEBLIB}
      ExecuteJavascript('document.documentElement.style.setProperty("--tmsfncmemo-bg-theme", "#1e1e1e")');
      {$ENDIF}
      ExecuteJavascript(GetControlID + 'setTheme("vs-dark")');
    end;
    mtHighContrastDark:
    begin
      {$IFNDEF WEBLIB}
      ExecuteJavascript('document.documentElement.style.setProperty("--tmsfncmemo-bg-theme", "#000")');
      {$ENDIF}
      ExecuteJavascript(GetControlID + 'setTheme("hc-black")');
    end;
  end;
end;

procedure TTMSFNCCustomMemo.SetValue(AValue: string);
begin
  ExecuteJavascriptSync(GetControlID + 'SetValue("' + TTMSFNCUtils.Encode64(AValue) + '")');
end;

procedure TTMSFNCCustomMemo.SetWantTab(const Value: Boolean);
begin
  FWantTab := Value;
  if FInitialized then
    ExecuteJavascript(GetControlID + 'WantTab = ' + BoolToStr(FWantTab,true).ToLower);
end;

function TTMSFNCCustomMemo.TextPosToPos(
  const APos: Integer): TTMSFNCMemoCaretPosition;
var
  s: string;
  j: TJSONValue;
begin
  s := ExecuteJavascriptSync('encodeURIComponent(JSON.stringify(' + GetControlID + 'editor.getModel().getPositionAt(' + IntToStr(APos) + ')))');
  s := ParseValue(s);
  s := TTMSFNCUtils.URLDecode(s);

  j := TTMSFNCUtils.ParseJSON(s);
  if Assigned(j) then
  begin
    try
      Result.Line := j['lineNumber'].AsInteger;
      Result.Pos := j['column'].AsInteger;
    finally
      j.Free;
    end;
  end;
end;

procedure TTMSFNCCustomMemo.Undo;
begin
  ExecuteJavaScript(GetControlID +  'editor.trigger("Undo", "undo").run()');
end;

procedure TTMSFNCCustomMemo.UnfoldAll;
begin
  ExecuteJavaScript(GetControlID + 'editor.trigger("unfold", "editor.unfoldAll")');
end;

function TTMSFNCCustomMemo.LineInsert(AIndex: Integer; AText: string): Integer;
var
  a: Boolean;
  i: Integer;
begin
  i := GetLineCount;
  a := FLines.Count > i;

  Result := StrToInt(ExecuteJavaScriptSync(GetControlID + 'InsertLine(' + IntToStr(AIndex + 1) + ', "' + TTMSFNCUtils.Encode64(AText) + '", ' + BoolToStr(a, true).ToLower + ')'));
end;

procedure TTMSFNCCustomMemo.FindAllInRange(ASearchString: string; ASelStart,
  ASelLength: Integer);
begin
  FindAllInRange(ASearchString, ASelStart, ASelLength, DefaultFindOptions);
end;

procedure TTMSFNCCustomMemo.Find(ASearchString: string;
  ABehaviour: TTMSFNCmemoFindBehaviour);
begin
  case ABehaviour of
    fbDown: FindNext(ASearchString) ;
    fbUp: FindPrevious(ASearchString);
    fbAll: FindAll(ASearchString);
  end;
end;

procedure TTMSFNCCustomMemo.Find(ASearchString: string;
  AOptions: TTMSFNCMemoFindOptions; ABehaviour: TTMSFNCMemoFindBehaviour);
begin
  case ABehaviour of
    fbDown: FindNext(ASearchString, AOptions);
    fbUp: FindPrevious(ASearchString, AOptions);
    fbAll: FindAll(ASearchString, AOptions);
  end;
end;

procedure TTMSFNCCustomMemo.FindAll(ASearchString: string);
begin
  FindAll(ASearchString, DefaultFindOptions);
end;

procedure TTMSFNCCustomMemo.FindNext(ASearchString: string);
begin
  FindNext(ASearchString, DefaultFindOptions);
end;

procedure TTMSFNCCustomMemo.FindNext(ASearchString: string; APos: Integer);
begin
  FindNext(ASearchstring, APos, DefaultFindOptions);
end;

procedure TTMSFNCCustomMemo.FindPrevious(ASearchString: string);
begin
  FindPrevious(ASearchString, DefaultFindOptions);
end;

procedure TTMSFNCCustomMemo.FindPrevious(ASearchString: string; APos: Integer);
begin
  FindPrevious(ASearchString, APos, DefaultFindOptions);
end;

function TTMSFNCCustomMemo.FindSource(AName: string): TTMSFNCMemoSource;
begin
  Result := Sources.Find(AName);
end;

procedure TTMSFNCCustomMemo.ReplaceNext(ASearchString, AReplaceString: string);
begin
  ReplaceNext(ASearchString, AReplaceString, DefaultFindOptions);
end;

procedure TTMSFNCCustomMemo.ReplacePrevious(ASearchString,
  AReplaceString: string);
begin
  ReplacePrevious(ASearchString, AReplaceString, DefaultFindOptions);
end;

{ TTMSFNCMemoLines }

{$IFNDEF LCLWEBLIB}
function TTMSFNCMemoLines.AddObject(const S: string; AObject: TObject): Integer;
var
  Lines: TArray<string>;
  I: Integer;
  sl: TStringList;
begin
  if (csLoading in FMemo.Owner.ComponentState) or S.IsEmpty then
    Result := inherited
  else
  begin
    Result := -1;

    sl := TStringList.Create;
    try
      sl.Text := s;
      sl.LineBreak := sLineBreak;
      Lines := sl.ToStringArray;
    finally
      sl.Free;
    end;

    if Length(Lines) > 1 then
      BeginUpdate;
    try
      for I := 0 to Length(Lines) - 1 do
        Result := inherited AddObject(Lines[I], AObject);
    finally
      if Length(Lines) > 1 then
        EndUpdate;
    end;
  end;
end;
{$ENDIF}

procedure TTMSFNCMemoLines.Assign(Source: TPersistent);
begin
  if FMemo.FInitialized then
    FMemo.Clear;

  inherited;
end;

procedure TTMSFNCMemoLines.Clear;
begin
  FTextChanged := FTextLength > 0;
  inherited;
  FTextLength := 0;

  if FMemo.FInitialized and (UpdateCount = 0) then
    FMemo.Clear;

  NotifyChanges;
  FMemo.DoChangeInternal;
end;

constructor TTMSFNCMemoLines.Create(Memo: TTMSFNCCustomMemo);
begin
  inherited Create;
  FMemo := Memo;
end;

procedure TTMSFNCMemoLines.Delete(Index: Integer);
begin
  FTextLength := FTextLength - Strings[Index].Length;
  inherited;
  if FMemo.FInitialized and (UpdateCount = 0) then
    FMemo.DeleteLine(Index);
  FTextChanged := True;
  NotifyChanges;
end;

procedure TTMSFNCMemoLines.Exchange(Index1, Index2: Integer);
begin
  inherited;
  FTextChanged := True;
  NotifyChanges;
end;

function TTMSFNCMemoLines.Get(Index: Integer): string;
begin
  Result := inherited Get(Index);
end;

function TTMSFNCMemoLines.GetTextStr: string;
begin
  if FMemo.FInitialized and (UpdateCount = 0) and not (csLoading in FMemo.Owner.ComponentState) then
    Result := FMemo.GetValue
  else
  begin
    Result := inherited GetTextStr;
    Result := Result.Remove(Result.Length - LineBreak.Length, LineBreak.Length);
  end;
end;

procedure TTMSFNCMemoLines.InsertItem(Index: Integer; const S: string; AObject: TObject);
var
  LText: string;
begin
  LText := S;
  inherited InsertItem(Index, LText, AObject);
  FTextLength := FTextLength + LText.Length;
  if FMemo.FInitialized and (UpdateCount = 0) then
    FMemo.LineInsert(Index, LText);
  FTextChanged := True;
  NotifyChanges;
end;

{$IFNDEF LCLWEBLIB} 
procedure TTMSFNCMemoLines.InsertObject(Index: Integer; const S: string; AObject: TObject);
var
  Lines: TArray<string>;
  I: Integer;
  sl: TStringList;
begin
  if (csLoading in FMemo.Owner.ComponentState) or S.IsEmpty then
    inherited
  else
  begin
    sl := TStringList.Create;
    try
      sl.Text := s;
      sl.LineBreak := sLineBreak;
      Lines := sl.ToStringArray;
    finally
      sl.Free;
    end;

    if Length(Lines) > 1 then
      BeginUpdate;
    try
      for I := 0 to Length(Lines) - 1 do
        inherited InsertObject(Index + I, Lines[I], AObject);
    finally
      if Length(Lines) > 1 then
        EndUpdate;
    end;
  end;
end;
{$ENDIF}

procedure TTMSFNCMemoLines.NotifyChanges;
begin
  if FTextChanged and (UpdateCount = 0) then
  begin
    FTextChanged := False;
    FMemo.DoChangeTrackingInternal;
  end;
end;

procedure TTMSFNCMemoLines.Put(Index: Integer; const S: string);
var
  LText: string;
  LineLength: Integer;
begin
  LText := S;
  if LText <> Strings[Index] then
  begin
    LineLength := Strings[Index].Length;
    inherited Put(Index, LText);
    FTextLength := FTextLength - LineLength + LText.Length;
    if FMemo.FInitialized and (UpdateCount = 0) then
      FMemo.LineInsert(Index, LText);

    FTextChanged := True;
    NotifyChanges;
  end;
end;

procedure TTMSFNCMemoLines.SetTextStr(const Value: string);
var
  LText: string;
begin
  LText := Value;
  if LText.EndsWith(LineBreak) and not LText.IsEmpty then
    LText := LText + LineBreak;

  BeginUpdate;
  try
    inherited SetTextStr(LText);
  finally
    EndUpdate;
  end;

  FMemo.DoChangeInternal;
end;

procedure TTMSFNCMemoLines.SetUpdateState(Updating: Boolean);
var
  s: string;
begin
  inherited;

  if FUpdating then
    Exit;

  FUpdating := True;

  if FMemo.FInitialized and not (csLoading in FMemo.ComponentState) then
  begin
    if not Updating and not FReading then
    begin
      s := inherited GetTextStr;
      s := s.Remove(s.Length - LineBreak.Length, LineBreak.Length);
      FMemo.SetValue(s);
    end
    else if Updating then
      inherited SetTextStr(FMemo.GetValue);
  end;

  FUpdating := False;

  if not Updating then
    NotifyChanges;
end;

{ TTMSFNCMemoOptions }

procedure TTMSFNCMemoOptions.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCMemoOptions then
  begin
    FAutoGlyphMargin := (Source as TTMSFNCMemoOptions).AutoGlyphMargin;
    FormatOnPaste := (Source as TTMSFNCMemoOptions).FormatOnPaste;
    LineNumbers := (Source as TTMSFNCMemoOptions).LineNumbers;
    ContextMenu := (Source as TTMSFNCMemoOptions).ContextMenu;
    DragAndDrop := (Source as TTMSFNCMemoOptions).DragAndDrop;
    EmptySelectionClipboard := (Source as TTMSFNCMemoOptions).EmptySelectionClipboard;
    Folding := (Source as TTMSFNCMemoOptions).Folding;
    FoldingHighlights := (Source as TTMSFNCMemoOptions).FoldingHighlights;
    GlyphMargin := (Source as TTMSFNCMemoOptions).GlyphMargin;
    IsReadOnly := (Source as TTMSFNCMemoOptions).IsReadOnly;
    Minimap.Assign((Source as TTMSFNCMemoOptions).MiniMap);
    MouseWheelZoom := (Source as TTMSFNCMemoOptions).MouseWheelZoom;
    RoundedSelection := (Source as TTMSFNCMemoOptions).RoundedSelection;
    Links := (Source as TTMSFNCMemoOptions).Links;
    FormatOnType := (Source as TTMSFNCMemoOptions).FormatOnType;
    CodeLens := (Source as TTMSFNCMemoOptions).CodeLens;
    Scrollbar.Assign((Source as TTMSFNCMemoOptions).Scrollbar);
    FRenderLineHighlight := (Source as TTMSFNCMemoOptions).RenderLineHighlight;
    FRenderLineHighlightOnlyWhenFocus := (Source as TTMSFNCMemoOptions).RenderLineHighlightOnlyWhenFocus;
    FOverviewRulerLanes := (Source as TTMSFNCMemoOptions).OverviewRulerLanes;
    FRenderIndentGuides := (Source as TTMSFNCMemoOptions).RenderIndentGuides;
    FRenderWhiteSpace := (Source as TTMSFNCMemoOptions).RenderWhiteSpace;
    FSelectionHighlight := (Source as TTMSFNCMemoOptions).SelectionHighlight;
    FSelectOnLineNumbers := (Source as TTMSFNCMemoOptions).SelectOnLineNumbers;
    FTabSize := (Source as TTMSFNCMemoOptions).TabSize;
    FFont.Assign((Source as TTMSFNCMemoOptions).Font);
    FQuickSuggestion.Assign((Source as TTMSFNCMemoOptions).QuickSuggestion);
    FBlockSelection := (Source as TTMSFNCMemoOptions).BlockSelection;
    FOverviewRulerBorder := (Source as TTMSFNCMemoOptions).OverviewRulerBorder;
    FAutoOpenLink := (Source as TTMSFNCMemoOptions).AutoOpenLink;
    FCommandPalette := (Source as TTMSFNCMemoOptions).CommandPalette;
    FWordWrap := (Source as TTMSFNCMemoOptions).WordWrap;
    FWordWrapColumn := (Source as TTMSFNCMemoOptions).WordWrapColumn;
    FUseCustomCodeCompletion := (Source as TTMSFNCMemoOptions).UseCustomCodeCompletion;
  end
  else
    inherited;
end;

constructor TTMSFNCMemoOptions.Create(AMemo: TTMSFNCCustomMemo);
begin
  Inherited Create;
  FMemo := AMemo;
  FLineNumbers := true;
  FFormatOnPaste := false;
  FEmptySelectionClipboard := false;
  FFoldingHighlights := false;
  FMouseWheelZoom := false;
  FRoundedSelection := false;
  FFolding := true;
  FIsReadOnly := false;
  FGlyphMargin := false;
  FAutoGlyphMargin := true;
  FDragAndDrop := true;
  FMiniMap := TTMSFNCMemoMinimapOptions.Create(Self);
  FContextMenu := true;
  FLinks := true;
  FFormatOnType := false;
  FCodeLens := true;
  FScrollbar := TTMSFNCMemoScrollOptions.Create(Self);
  FRenderLineHighlight := hlNone;
  FRenderLineHighlightOnlyWhenFocus := false;
  FOverviewRulerLanes := 3;
  FRenderIndentGuides := true;
  FRenderWhiteSpace := mwNone;
  FSelectionHighlight := false;
  FSelectOnLineNumbers := true;
  FTabSize := 2;
  FFont := TTMSFNCMemoFont.Create;
  FFont.Name := 'Consolas';
  FFont.Height := -12;
  FFont.OnChanged := @FontChanged;
  FBlockSelection := false;
  FOverviewRulerBorder := false;
  FQuickSuggestion := TTMSFNCMemoQuickSuggestion.Create(Self);
  FAutoOpenlink := true;
  FCommandPalette := true;
  FWordWrap := wwtOff;
  FWordWrapColumn := 80;
  FUseCustomCodeCompletion := true;
end;

destructor TTMSFNCMemoOptions.Destroy;
begin
  FMiniMap.Free;
  FScrollbar.Free;
  FQuickSuggestion.Free;
  FFont.Free;
  inherited;
end;

procedure TTMSFNCMemoOptions.FontChanged(Sender: TObject);
var
  ft: TTMSFNCMemoFont;
begin
  ft := TTMSFNCMemoFont.Create;
  try
    ft.Assign(Font);
    {$IFDEF VCLLIB}
    ft.Height := TTMSFNCUtils.MulDivInt(ft.Height, 96, 72);
    {$ENDIF}
    FMemo.UpdateOption('{fontFamily: "' + ft.Name +  '", fontSize: ' + FloatToStr(ft.Size) + '}');
  finally
    ft.Free;
  end;
end;

procedure TTMSFNCMemoOptions.SetAutoGlyphMargin(const Value: Boolean);
begin
  FAutoGlyphMargin := Value;
end;

procedure TTMSFNCMemoOptions.SetAutoOpenLink(const Value: Boolean);
begin
  FAutoOpenLink := Value;
end;

procedure TTMSFNCMemoOptions.SetBlockSelection(const Value: Boolean);
begin
  FBlockSelection := Value;
  FMemo.UpdateOption('{columnSelection: ' + BoolToStr(FBlockSelection	, true).ToLower + '}')
end;

procedure TTMSFNCMemoOptions.SetCodeLens(const Value: Boolean);
begin
  FCodeLens := Value;
  FMemo.UpdateOption('{codeLens: ' + BoolToStr(FCodeLens, true).ToLower + '}')
end;

procedure TTMSFNCMemoOptions.SetCommandPalette(const Value: Boolean);
begin
  FCommandPalette := Value;
end;

procedure TTMSFNCMemoOptions.SetContextMenu(const Value: Boolean);
begin
  FContextMenu := Value;

  FMemo.UpdateOption('{contextMenu: ' + BoolToStr(FContextmenu, true).ToLower + '}')
end;

procedure TTMSFNCMemoOptions.SetDragAndDrop(const Value: Boolean);
begin
  FDragAndDrop := Value;
  FMemo.UpdateOption('{dragAndDrop: ' + BoolToStr(FDragAndDrop, true).ToLower + '}')
end;

procedure TTMSFNCMemoOptions.SetEmptySelectionClipboard(const Value: Boolean);
begin
  FEmptySelectionClipboard := Value;
  FMemo.UpdateOption('{emptySelectionClipboard: ' + BoolToStr(FEmptySelectionClipboard, true).ToLower + '}')
end;

procedure TTMSFNCMemoOptions.SetFolding(const Value: Boolean);
begin
  FFolding := Value;
  FMemo.UpdateOption('{folding: ' + BoolToStr(FFolding, true).ToLower + '}')
end;

procedure TTMSFNCMemoOptions.SetFoldingHighlights(const Value: Boolean);
begin
  FFoldingHighlights := Value;
  FMemo.UpdateOption('{foldingHighlight: ' + BoolToStr(FFoldingHighlights, true).ToLower + '}')
end;

procedure TTMSFNCMemoOptions.SetFont(const Value: TTMSFNCMemoFont);
begin
  FFont.Assign(Value);
end;

procedure TTMSFNCMemoOptions.SetFormatOnPaste(const Value: Boolean);
begin
  FFormatOnPaste := Value;
  FMemo.UpdateOption('{formatOnPaste: ' + BoolToStr(FFormatOnPaste, true).ToLower + '}')
end;

procedure TTMSFNCMemoOptions.SetFormatOnType(const Value: Boolean);
begin
  FFormatOnType := Value;
  FMemo.UpdateOption('{formatOnType: ' + BoolToStr(FFormatOnType, true).ToLower + '}')
end;

procedure TTMSFNCMemoOptions.SetGlyphMargin(const Value: Boolean);
begin
  FGlyphMargin := Value;
  FMemo.UpdateOption('{glyphMargin: ' + BoolToStr(FGlyphMargin, true).ToLower + '}')
end;

procedure TTMSFNCMemoOptions.SetIsReadOnly(const Value: Boolean);
begin
  FIsReadOnly := Value;
  FMemo.UpdateOption('{readOnly: ' + BoolToStr(FIsReadOnly, true).ToLower + '}')
end;

procedure TTMSFNCMemoOptions.SetLineNumbers(const Value: Boolean);
begin
  FLineNumbers := Value;
  FMemo.UpdateOption('{lineNumbers: ' + BoolToStr(FLineNumbers, true).ToLower + '}')
end;

procedure TTMSFNCMemoOptions.SetLinks(const Value: Boolean);
begin
  FLinks := Value;
  FMemo.UpdateOption('{links: ' + BoolToStr(FLinks, true).ToLower + '}')
end;

procedure TTMSFNCMemoOptions.SetMiniMap(const Value: TTMSFNCMemoMinimapOptions);
begin
  FMiniMap.Assign(Value);
end;

procedure TTMSFNCMemoOptions.SetMouseWheelZoom(const Value: Boolean);
begin
  FMouseWheelZoom := Value;
  FMemo.UpdateOption('{mouseWheelZoom: ' + BoolToStr(FMouseWheelZoom, true).ToLower + '}')
end;

procedure TTMSFNCMemoOptions.SetOverviewRulerBorder(const Value: Boolean);
begin
  FOverviewRulerBorder := Value;
  FMemo.UpdateOption('{overviewRulerBorder: ' + BoolToStr(FOverviewRulerBorder, true).ToLower + '}')
end;

procedure TTMSFNCMemoOptions.SetOverviewRulerLanes(const Value: Integer);
begin
  if Value < 0 then
    Exit;

  FOverviewRulerLanes := Value;
  FMemo.UpdateOption('{overviewRulerLanes: ' + IntToStr(FOverviewRulerLanes) + '}')
end;

procedure TTMSFNCMemoOptions.SetQuickSuggestion(
  const Value: TTMSFNCMemoQuickSuggestion);
begin
  FQuickSuggestion.Assign(Value);
end;

procedure TTMSFNCMemoOptions.SetRenderIndentGuides(const Value: Boolean);
begin
  FRenderIndentGuides := Value;
  FMemo.UpdateOption('{renderIndentGuides: ' + BoolToStr(FRenderIndentGuides, true).ToLower + '}')
end;

procedure TTMSFNCMemoOptions.SetRenderLineHighlight(
  const Value: TTMSFNCMemoLineHighLight);
var
  s: string;
begin
  FRenderLineHighlight := Value;

  case FRenderLineHighlight of
    hlNone: s := 'none';
    hlAll: s := 'all';
    hlLine: s := 'line';
    hlGutter: s := 'gutter';
  end;

  FMemo.UpdateOption('{renderLineHighlight: "' +s + '"}')
end;

procedure TTMSFNCMemoOptions.SetRenderLineHighlightOnlyWhenFocus(
  const Value: Boolean);
begin
  FRenderLineHighlightOnlyWhenFocus := Value;
  FMemo.UpdateOption('{renderLineHighlightOnlyWhenFocus: ' + BoolToStr(FRenderIndentGuides, true).ToLower + '}')
end;

procedure TTMSFNCMemoOptions.SetRenderWhiteSpace(
  const Value: TTMSFNCMemoWhitepace);
var
  s: string;
begin
  FRenderWhiteSpace := Value;

  case FRenderWhiteSpace of
    mwNone: s := 'none';
    mwAll: s := 'all';
    mwBoundary: s := 'boundary';
    mwSelection: s := 'selection';
    mwTrailing: s := 'trailing';
  end;

  FMemo.UpdateOption('{renderWhitespace: ' + s + '}')
end;

procedure TTMSFNCMemoOptions.SetRoundedSelection(const Value: Boolean);
begin
  FRoundedSelection := Value;
  FMemo.UpdateOption('{roundedSelection: ' + BoolToStr(FRoundedSelection, true).ToLower + '}')
end;

procedure TTMSFNCMemoOptions.SetScrollbar(
  const Value: TTMSFNCMemoScrollOptions);
begin
  FScrollbar.Assign(Value);
end;

procedure TTMSFNCMemoOptions.SetSelectionHighlight(const Value: Boolean);
begin
  FSelectionHighlight := Value;
  FMemo.UpdateOption('{selectionHighlight: ' + BoolToStr(FSelectionHighlight, true).ToLower + '}')
end;

procedure TTMSFNCMemoOptions.SetSelectOnLineNumbers(const Value: Boolean);
begin
  FSelectOnLineNumbers := Value;
  FMemo.UpdateOption('{selectOnLineNumbers: ' + BoolToStr(FSelectOnLineNumbers, true).ToLower + '}')
end;

procedure TTMSFNCMemoOptions.SetTabSize(const Value: Integer);
begin
  FTabSize := Value;

  FMemo.UpdateOption('{tabSize: ' + FTabSize.ToString + ' }');
end;

procedure TTMSFNCMemoOptions.SetUseCustomCodeCompletion(const Value: boolean);
begin
  FUseCustomCodeCompletion := Value;
end;

procedure TTMSFNCMemoOptions.SetWordWrap(const Value: TTMSFNCMemoWordWrapType);
var
  s: String;
begin
  FWordWrap := Value;

  case FWordWrap of
    wwtOn: s := '"on"';
    wwtOff: s := '"off"';
    wwtWordWrapColumn: s := '"wordWrapColumn"';
    wwtBounded: s := '"bounded"';
  end;

  FMemo.UpdateOption('{wordWrap: ' + s + ' }');
end;

procedure TTMSFNCMemoOptions.SetWordWrapColumn(const Value: Integer);
begin
  FWordWrapColumn := Value;

  FMemo.UpdateOption('{wordWrapColumn: ' + IntToStr(FWordWrapColumn) + '}');
end;

procedure TTMSFNCMemoOptions.UpdateMinimapOptions;
begin
  FMemo.UpdateOption('{minimap: ' + MiniMap.ToJSString + ' }');
end;

procedure TTMSFNCMemoOptions.UpdateQuickSuggestionOptions;
begin
  FMemo.UpdateOption('{quickSuggestions: ' + QuickSuggestion.ToJSString + ' }');
end;

procedure TTMSFNCMemoOptions.UpdateScrollbarOptions;
begin
  FMemo.UpdateOption('{scrollbar: ' + ScrollBar.ToJSString + ' }');
end;

{ TTMSFNCMemoMinimapOptions }

procedure TTMSFNCMemoMinimapOptions.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCMemoMinimapOptions then
  begin
    FAutohide := (Source as TTMSFNCMemoMinimapOptions).Autohide;
    FEnabled := (Source as TTMSFNCMemoMinimapOptions).Enabled;
    FShowSlider := (Source as TTMSFNCMemoMinimapOptions).ShowSlider;
    FRenderCharacters := (Source as TTMSFNCMemoMinimapOptions).RenderCharacters;
    FSide := (Source as TTMSFNCMemoMinimapOptions).Side;
    FSize := (Source as TTMSFNCMemoMinimapOptions).Size;
    FMaxColumn := (Source as TTMSFNCMemoMinimapOptions).MaxColumn;
    FScale := (Source as TTMSFNCMemoMinimapOptions).Scale;
    FOptions.UpdateMinimapOptions;
  end
  else
    inherited;
end;

constructor TTMSFNCMemoMinimapOptions.Create(AOptions: TTMSFNCMemoOptions);
begin
  FEnabled := true;
  FAutohide := false;
  FShowSlider := soMouseOver;
  FRenderCharacters := true;
  FSide := soRight;
  FSize := soActual;
  FMaxColumn := 120;
  FScale := 1;
  FOptions := AOptions;
end;

procedure TTMSFNCMemoMinimapOptions.SetAutohide(const Value: Boolean);
begin
  FAutohide := Value;
  FOptions.UpdateMinimapOptions;
end;

procedure TTMSFNCMemoMinimapOptions.SetEnabled(const Value: Boolean);
begin
  FEnabled := Value;
  FOptions.UpdateMinimapOptions;
end;

procedure TTMSFNCMemoMinimapOptions.SetMaxColumn(const Value: Integer);
begin
  FMaxColumn := Value;
  FOptions.UpdateMinimapOptions;
end;

procedure TTMSFNCMemoMinimapOptions.SetRenderCharacters(const Value: Boolean);
begin
  FRenderCharacters := Value;
  FOptions.UpdateMinimapOptions;
end;

procedure TTMSFNCMemoMinimapOptions.SetScale(const Value: Integer);
begin
  FScale := Value;
  FOptions.UpdateMinimapOptions;
end;

procedure TTMSFNCMemoMinimapOptions.SetShowSlider(
  const Value: TTMSFNCMemoMinimapSliderOptions);
begin
  FShowSlider := Value;
  FOptions.UpdateMinimapOptions;
end;

procedure TTMSFNCMemoMinimapOptions.SetSide(
  const Value: TTMSFNCMemoMinimapSideOptions);
begin
  FSide := Value;
  FOptions.UpdateMinimapOptions;
end;

procedure TTMSFNCMemoMinimapOptions.SetSize(
  const Value: TTMSFNCMemoMinimapSizeOptions);
begin
  FSize := Value;
  FOptions.UpdateMinimapOptions;
end;

function TTMSFNCMemoMinimapOptions.ToJSString: string;
var
  sslider, ssize, sside: string;
begin
  case ShowSlider of
    soAlways: sslider := 'always';
    soMouseOver: sslider := 'mouseOver';
  end;

  case Size of
    soProportional: ssize := 'proportional';
    soFill: ssize := 'fill';
    soFit: ssize := 'fit';
    soActual: ssize := 'actual';
  end;

  case Side of
    soRight: sside := 'right';
    soLeft: sside := 'left';
  end;

  Result := '{' +
    'enabled: ' + BoolToStr(Enabled, true).ToLower + ',' +
    'autohide: ' + BoolToStr(Autohide, true).ToLower + ',' +
    'maxColumn: ' + IntToStr(MaxColumn) + ',' +
    'renderCharacters: ' + BoolToStr(RenderCharacters, true).ToLower + ',' +
    'scale: ' + IntToStr(Scale) + ',' +
    'showSlider: "' + sslider + '",' +
    'side: "' + sside + '",' +
    'size: "' + ssize + '"' +
    '}';
end;

{ TTMSFNCMemoScrollOptions }

procedure TTMSFNCMemoScrollOptions.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCMemoScrollOptions then
  begin
    FHorizontal := (Source as TTMSFNCMemoScrollOptions).Horizontal;
    FHorizontalScrollbarSize := (Source as TTMSFNCMemoScrollOptions).HorizontalScrollbarSize;
    FVertical := (Source as TTMSFNCMemoScrollOptions).Vertical;
    FVerticalScrollbarSize := (Source as TTMSFNCMemoScrollOptions).VerticalScrollbarSize;
    FHandleMouseWheel := (Source as TTMSFNCMemoScrollOptions).HandleMouseWheel;
    FScrollByPage := (Source as TTMSFNCMemoScrollOptions).ScrollByPage;
    FOptions.UpdateScrollbarOptions;
  end
  else
    inherited;
end;

constructor TTMSFNCMemoScrollOptions.Create(AOptions: TTMSFNCMemoOptions);
begin
  FHorizontal := soAuto;
  FHorizontalScrollbarSize := 10;
  FVertical := soAuto;
  FVerticalScrollbarSize := 10;
  FHandleMouseWheel := true;
  FScrollByPage := false;
  FOptions := AOptions;
end;

procedure TTMSFNCMemoScrollOptions.SetHandleMouseWheel(const Value: Boolean);
begin
  FHandleMouseWheel := Value;
  FOptions.UpdateScrollbarOptions;
end;

procedure TTMSFNCMemoScrollOptions.SetHorizontal(
  const Value: TTMSFNCMemoScrollbarOptions);
begin
  FHorizontal := Value;
  FOptions.UpdateScrollbarOptions;
end;

procedure TTMSFNCMemoScrollOptions.SetHorizontalScrollbarSize(
  const Value: Integer);
begin
  FHorizontalScrollbarSize := Value;
  FOptions.UpdateScrollbarOptions;
end;

procedure TTMSFNCMemoScrollOptions.SetScrollByPage(const Value: Boolean);
begin
  FScrollByPage := Value;
  FOptions.UpdateScrollbarOptions;
end;

procedure TTMSFNCMemoScrollOptions.SetVertical(
  const Value: TTMSFNCMemoScrollbarOptions);
begin
  FVertical := Value;
  FOptions.UpdateScrollbarOptions;
end;

procedure TTMSFNCMemoScrollOptions.SetVerticalScrollbarSize(
  const Value: Integer);
begin
  FVerticalScrollbarSize := Value;
  FOptions.UpdateScrollbarOptions;
end;

function TTMSFNCMemoScrollOptions.ToJSString: string;
var
  hor, ver: string;
begin
  case Horizontal of
    soAuto: hor := 'auto';
    soVisible: hor := 'visible';
    soHidden: hor := 'hidden';
  end;

  case Vertical of
    soAuto: ver := 'auto';
    soVisible: ver := 'visible';
    soHidden: ver := 'hidden';
  end;

  Result := '{' +
            'horizontal: "' + hor + '",' +
            'horizontalScrollbarSize: ' + IntToStr(HorizontalScrollbarSize) + ',' +
            'vertical: "' + ver + '",' +
            'verticalScrollbarSize: ' + IntToStr(VerticalScrollbarSize) + ',' +
            'scrollByPage: ' + BoolToStr(ScrollByPage, true).ToLower + ',' +
            'handleMouseWheel: ' + BoolToStr(HandleMouseWheel, true).ToLower +
            '}';

end;

{ TTMSFNCMemoCodeCompletion }

function TTMSFNCMemoCodeCompletion.Add: TTMSFNCMemoCodeCompletionItem;
begin
  Result := TTMSFNCMemoCodeCompletionItem(inherited add);
end;

function TTMSFNCMemoCodeCompletion.Add(AName: string;
  AKind: TTMSFNCMemoSuggestionKind; AInsertText: string;
  AInsertTextRules: TTMSFNCMemoSuggestionInsertTextRule;
  ADocumentation: string): TTMSFNCMemoCodeCompletionItem;
begin
  Result := Self.Add;
  Result.Name := AName;
  Result.Kind := AKind;
  Result.InsertText.Text := AInsertText;
  Result.InsertTextRules := AInsertTextRules;
  Result.Documentation := ADocumentation;
end;

function TTMSFNCMemoCodeCompletion.GetItem(Index: Integer): TTMSFNCMemoCodeCompletionItem;
begin
  Result := TTMSFNCMemoCodeCompletionItem(inherited Items[Index]);
end;

function TTMSFNCMemoCodeCompletion.Insert(Index: Integer): TTMSFNCMemoCodeCompletionItem;
begin
  Result := TTMSFNCMemoCodeCompletionItem(inherited Insert(Index));
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCMemoCodeCompletion.LoadFromFile(AFileName: string);
var
  Stream: TStream;
begin
  Stream := TFileStream.Create(AFileName, fmOpenRead);
  try
    LoadFromStream(Stream);
  finally
    Stream.Free;
  end;
end;
{$ENDIF}

procedure TTMSFNCMemoCodeCompletion.LoadFromStream(AStream: TStream);
var
  List: TStringList;
begin
  List := TStringList.Create;
  BeginUpdate;
  try
    try
      Clear;
      List.LoadFromStream(AStream{$IFNDEF LCLLIB}, TEncoding.UTF8{$ENDIF});
      LoadFromStrings(List);
    finally
      EndUpdate;
      List.Free;
    end;
  except
  end;
end;

procedure TTMSFNCMemoCodeCompletion.LoadFromStrings(AStrings: TStrings);
var
  AItem: TTMSFNCMemoCodeCompletionItem;
  i: Integer;
begin
  for i := 0 to AStrings.Count - 1 do
  begin
    AItem := Add;
    if Assigned(AItem) then
    begin
      if (AStrings.Names[i] = '') then
        AItem.Name := AStrings[i]
      else
      begin
        AItem.Name := AStrings.Names[i];
        AItem.InsertText.Text := AStrings.ValueFromIndex[i];
      end;
    end;
  end;
end;

procedure TTMSFNCMemoCodeCompletion.SetItem(Index: Integer;
  const Value: TTMSFNCMemoCodeCompletionItem);
begin
  inherited SetItem(Index, Value);
end;

function TTMSFNCMemoCodeCompletion.ToJSONInternal: string;
var
  i: Integer;
begin
  Result := '[';

  for i := 0 to Count -1 do
  begin
    Result := Result + Items[i].ToJSONInternal;

    if i < Count - 1 then
      Result := Result + ',';
  end;

  Result := Result + ']';
end;


{ TTMSFNCMemoCodeCompletionItem }

procedure TTMSFNCMemoCodeCompletionItem.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCMemoCodeCompletionItem then
  begin
    FName := (Source as TTMSFNCMemoCodeCompletionItem).Name;
    FSortText := (Source as TTMSFNCMemoCodeCompletionItem).SortText;
    FFilterText := (Source as TTMSFNCMemoCodeCompletionItem).FilterText;
    FKind := (Source as TTMSFNCMemoCodeCompletionItem).Kind;
    FInsertTextRules := (Source as TTMSFNCMemoCodeCompletionItem).InsertTextRules;
    FInsertText.Assign((Source as TTMSFNCMemoCodeCompletionItem).InsertText);
    FDocumentation := (Source as TTMSFNCMemoCodeCompletionItem).Documentation;
    FPreselect := (Source as TTMSFNCMemoCodeCompletionItem).Preselect;
  end
  else
    inherited;

end;

constructor TTMSFNCMemoCodeCompletionItem.Create(ACollection: TCollection);
begin
  inherited;
  FInsertText := TStringList.Create;
  FInsertText.LineBreak := '\r\n';
end;

destructor TTMSFNCMemoCodeCompletionItem.Destroy;
begin
  FInsertText.Free;
  inherited;
end;

procedure TTMSFNCMemoCodeCompletionItem.SetDocumentation(const Value: string);
begin
  FDocumentation := Value;
end;

procedure TTMSFNCMemoCodeCompletionItem.SetFilterText(const Value: string);
begin
  FFilterText := Value;
end;

procedure TTMSFNCMemoCodeCompletionItem.SetInsertText(const Value: TStringList);
begin
  FInsertText.Assign(Value);
end;

procedure TTMSFNCMemoCodeCompletionItem.SetInsertTextRules(
  const Value: TTMSFNCMemoSuggestionInsertTextRule);
begin
  FInsertTextRules := Value;
end;

procedure TTMSFNCMemoCodeCompletionItem.SetKind(const Value: TTMSFNCMemoSuggestionKind);
begin
  FKind := Value;
end;

procedure TTMSFNCMemoCodeCompletionItem.SetName(const Value: string);
begin
  FName := Value;
end;

procedure TTMSFNCMemoCodeCompletionItem.SetPreselect(const Value: Boolean);
begin
  FPreselect := Value;
end;

procedure TTMSFNCMemoCodeCompletionItem.SetSortText(const Value: string);
begin
  FSortText := Value;
end;

function TTMSFNCMemoCodeCompletionItem.ToJSONInternal: string;
var
  s, it: string;
  i: Integer;

begin
  Result := '{';
  Result := Result + 'label: "' + TTMSFNCUtils.{$IFDEF LCLLIB}Encode64{$ELSE}EscapeString{$ENDIF}(Name) + '"';
  s := GetEnumName(TypeInfo(TTMSFNCMemoSuggestionKind), Integer(Kind));
  Delete(s,1,2);
  Result := Result + ',kind: monaco.languages.CompletionItemKind.' + s;
  it := '';
  for i := 0 to InsertText.Count -1 do
  begin
    it := it + TTMSFNCUtils.{$IFDEF LCLLIB}Encode64{$ELSE}EscapeString{$ENDIF}(InsertText[i]);
    if i < InsertText.Count -1 then
      it := it + '\r\n';
  end;

  if not InsertText.Text.IsEmpty then
    Result := Result + ',insertText: "' + it	+ '"'
  else
    Result := Result + ',insertText: "' + TTMSFNCUtils.{$IFDEF LCLLIB}Encode64{$ELSE}EscapeString{$ENDIF}(Name)	+ '"';

  if not Documentation.isEmpty then
    Result := Result + ',documentation: "' + TTMSFNCUtils.{$IFDEF LCLLIB}Encode64{$ELSE}EscapeString{$ENDIF}(Documentation) + '"';

  if not FilterText.IsEmpty then
    Result := Result + ',filterText: "' + TTMSFNCUtils.{$IFDEF LCLLIB}Encode64{$ELSE}EscapeString{$ENDIF}(FilterText)	 + '"';

  if not SortText.IsEmpty then
    Result := Result + ',sortText: "' + TTMSFNCUtils.{$IFDEF LCLLIB}Encode64{$ELSE}EscapeString{$ENDIF}(SortText) + '"';

  if InsertTextRules <> itrNone then
  begin
    s := GetEnumName(TypeInfo(TTMSFNCMemoSuggestionInsertTextRule), Integer(InsertTextRules));
    Delete(s,1,3);
    Result := Result + ', insertTextRules: monaco.languages.CompletionItemInsertTextRule.' + s;
  end;

  if Preselect then
    Result := Result + ', preselect: true';

  Result := Result + '}';
end;

{ TTMSFNCMemoQuickSuggestion }

procedure TTMSFNCMemoQuickSuggestion.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCMemoQuickSuggestion then
  begin
    FComments := (Source as TTMSFNCMemoQuickSuggestion).Comments;
    FStrings := (Source as TTMSFNCMemoQuickSuggestion).Strings;
    FOther := (Source as TTMSFNCMemoQuickSuggestion).Other;
    FOptions.UpdateQuickSuggestionOptions;
  end
  else
    inherited;
end;

constructor TTMSFNCMemoQuickSuggestion.Create(AOptions: TTMSFNCMemoOptions);
begin
  FOptions := AOptions;
  FComments := smOff;
  FStrings := smOff;
  FOther := smOn;
end;

procedure TTMSFNCMemoQuickSuggestion.SetComments(
  const Value: TTMSFNCMemoQuickSuggestionMode);
begin
  FComments := Value;
end;

procedure TTMSFNCMemoQuickSuggestion.SetOther(
  const Value: TTMSFNCMemoQuickSuggestionMode);
begin
  FOther := Value;
end;

procedure TTMSFNCMemoQuickSuggestion.SetStrings(
  const Value: TTMSFNCMemoQuickSuggestionMode);
begin
  FStrings := Value;
end;

function TTMSFNCMemoQuickSuggestion.ToJSString: string;
var
  s: string;
begin
  Result := '{';

  s := GetEnumName(TypeInfo(TTMSFNCMemoQuickSuggestionMode), Integer(Comments));
  Delete(s,1,2);
  Result := Result + 'comments: "' + s.ToLower + '",';

  s := GetEnumName(TypeInfo(TTMSFNCMemoQuickSuggestionMode), Integer(Strings));
  Delete(s,1,2);
  Result := Result + 'strings: "' + s.ToLower + '",';

  s := GetEnumName(TypeInfo(TTMSFNCMemoQuickSuggestionMode), Integer(Other));
  Delete(s,1,2);
  Result := Result + 'other: "' + s.ToLower + '"';

  Result := Result + '}';
end;

{ TTMSFNCMemoDragObject }

procedure TTMSFNCMemoDragObject.Assign(Source: TPersistent);
var
  I: Integer;
  dfc, df: TTMSFNCMemoDragFile;
begin
  if Source is TTMSFNCMemoDragObject then
  begin
    FKind := (Source as TTMSFNCMemoDragObject).Kind;
    FData.Clear;
    for I := 0 to (Source as TTMSFNCMemoDragObject).Files.Count - 1 do
    begin
      df := (Source as TTMSFNCMemoDragObject).Files[I];
      dfc := TTMSFNCMemoDragFile.Create(FMemo);
      dfc.Assign(df);
      FData.Add(dfc);
    end;
    FText := (Source as TTMSFNCMemoDragObject).Text;
    FPosition := (Source as TTMSFNCMemoDragObject).Position;
  end
  else
    inherited;
end;

constructor TTMSFNCMemoDragObject.Create(AOwner: TTMSFNCCustomMemo);
begin
  FMemo := AOwner;
  FData := TTMSFNCMemoDragFiles.Create;
end;

destructor TTMSFNCMemoDragObject.Destroy;
begin
  FData.Free;
  inherited;
end;

procedure TTMSFNCMemoDragObject.SetData(const Value: TTMSFNCMemoDragFiles);
begin
  FData := Value;
end;

procedure TTMSFNCMemoDragObject.SetKind(const Value: TTMSFNCMemoDragObjectKind);
begin
  FKind := Value;
end;

procedure TTMSFNCMemoDragObject.SetText(const Value: string);
begin
  FText := Value;
end;

{ TTMSFNCMemoDragFile }

procedure TTMSFNCMemoDragFile.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCMemoDragFile then
  begin
    FName := (Source as TTMSFNCMemoDragFile).Name;
    FSize := (Source as TTMSFNCMemoDragFile).Size;
    FMIMEType := (Source as TTMSFNCMemoDragFile).MIMEType;
  end
  else
    inherited;
end;

constructor TTMSFNCMemoDragFile.Create(AOwner: TTMSFNCCustomMemo);
begin
  FMemo := AOwner;
end;

procedure TTMSFNCMemoDragFile.Open;
begin
  FMemo.OpenFile(Self);
end;

procedure TTMSFNCMemoDragFile.SetMIMEType(const Value: string);
begin
  FMIMEType := Value;
end;

procedure TTMSFNCMemoDragFile.SetName(const Value: string);
begin
  FName := Value;
end;

procedure TTMSFNCMemoDragFile.SetSize(const Value: Integer);
begin
  FSize := Value;
end;

{$IFDEF LCLWEBLIBTHREAD}
constructor TTask6.Create(const ARunEvent: TTaskRunEvent6; Param1: TTMSFNCMemoMatch);
begin
  FTaskStatus := TTaskStatus.Created;
  FRunEvent6 := ARunEvent;
  FParam1 := Param1;
end;

procedure TTask6.Start;
begin
  {$IFDEF LCLLIBTHREAD}
  FWorkerThread := TTaskWorkerThread.Create(nil, nil, nil, nil, nil, FRunEvent6, '', '', nil, FParam1);
  {$ELSE}
  if Assigned(FRunEvent6) then
    FRunEvent6(FParam1);
  {$ENDIF}
  FTaskStatus := TTaskStatus.Running;
end;

destructor TTask6.Destroy;
begin
  FRunEvent6 := nil;
  inherited;
end;

constructor TTask5.Create(const ARunEvent: TTaskRunEvent5; Param1, Param2: string; Param3: TTMSFNCMemoMatch);
begin
  FTaskStatus := TTaskStatus.Created;
  FRunEvent5 := ARunEvent;
  FParam1 := Param1;
  FParam2 := Param2;
  FParam3 := Param3;
end;

procedure TTask5.Start;
begin
  {$IFDEF LCLLIBTHREAD}
  FWorkerThread := TTaskWorkerThread.Create(nil, nil, nil, nil, FRunEvent5, nil, FParam1, FParam2, nil, FParam3);
  {$ELSE}
  if Assigned(FRunEvent5) then
    FRunEvent5(FParam1, FParam2, FParam3);
  {$ENDIF}
  FTaskStatus := TTaskStatus.Running;
end;

destructor TTask5.Destroy;
begin
  FRunEvent5 := nil;
  inherited;
end;

constructor TTask4.Create(const ARunEvent: TTaskRunEvent4; Param1: TTMSFNCMemoMatches);
begin
  FTaskStatus := TTaskStatus.Created;
  FRunEvent4 := ARunEvent;
  FParam1 := Param1;
end;

procedure TTask4.Start;
var
  m: TTMSFNCMemoMatch;
begin
  {$IFDEF LCLLIBTHREAD}
  FWorkerThread := TTaskWorkerThread.Create(nil, nil, nil, FRunEvent4, nil, nil, '', '', FParam1, m);
  {$ELSE}
  if Assigned(FRunEvent4) then
    FRunEvent4(FParam1);
  {$ENDIF}
  FTaskStatus := TTaskStatus.Running;
end;

destructor TTask4.Destroy;
begin
  FRunEvent4 := nil;
  inherited;
end;

constructor TTask3.Create(const ARunEvent: TTaskRunEvent3; Param1, Param2: string; Param3: TTMSFNCMemoMatches);
begin
  FTaskStatus := TTaskStatus.Created;
  FRunEvent3 := ARunEvent;
  FParam1 := Param1;
  FParam2 := Param2;
  FParam3 := Param3;
end;

procedure TTask3.Start;
var
  m: TTMSFNCMemoMatch;
begin
  {$IFDEF LCLLIBTHREAD}
  FWorkerThread := TTaskWorkerThread.Create(nil, nil, FRunEvent3, nil, nil, nil, FParam1, FParam2, FParam3, m);
  {$ELSE}
  if Assigned(FRunEvent3) then
    FRunEvent3(FParam1, FParam2, FParam3);
  {$ENDIF}
  FTaskStatus := TTaskStatus.Running;
end;

destructor TTask3.Destroy;
begin
  FRunEvent3 := nil;
  inherited;
end;

constructor TTask2.Create(const ARunEvent: TTaskRunEvent2; Param1, Param2: string);
begin
  FTaskStatus := TTaskStatus.Created;
  FRunEvent2 := ARunEvent;
  FParam1 := Param1;
  FParam2 := Param2;
end;

procedure TTask2.Start;
var
  m: TTMSFNCMemoMatch;
begin
  {$IFDEF LCLLIBTHREAD}
  FWorkerThread := TTaskWorkerThread.Create(nil, FRunEvent2, nil, nil, nil, nil, FParam1, FParam2, nil, m);
  {$ELSE}
  if Assigned(FRunEvent2) then
    FRunEvent2(FParam1, FParam2);
  {$ENDIF}
  FTaskStatus := TTaskStatus.Running;
end;

destructor TTask2.Destroy;
begin
  FRunEvent2 := nil;
  inherited;
end;

constructor TTask.Create(const ARunEvent: TTaskRunEvent);
begin
  FTaskStatus := TTaskStatus.Created;
  FRunEvent := ARunEvent;
end;

destructor TTask.Destroy;
begin
  FRunEvent := nil;
  inherited;
end;

function TTask.GetStatus: TTaskStatus;
begin
  Result := FTaskStatus;
end;

procedure TTask.Cancel;
begin
  {$IFDEF LCLLIBTHREAD}
  FWorkerThread.Terminate;
  {$ENDIF}
  FTaskStatus := TTaskStatus.Canceled;
end;

procedure TTask.Start;
var
  m: TTMSFNCMemoMatch;
begin
  {$IFDEF LCLLIBTHREAD}
  FWorkerThread := TTaskWorkerThread.Create(FRunEvent, nil, nil, nil, nil, nil, '', '', nil, m);
  {$ELSE}
  if Assigned(FRunEvent) then
    FRunEvent();
  {$ENDIF}
  FTaskStatus := TTaskStatus.Running;
end;

{$IFDEF LCLLIBTHREAD}
constructor TTaskWorkerThread.Create(const ARunEvent: TTaskRunEvent; const ARunEvent2: TTaskRunEvent2; const ARunEvent3: TTaskRunEvent3; const ARunEvent4: TTaskRunEvent4;
  const ARunEvent5: TTaskRunEvent5; const ARunEvent6: TTaskRunEvent6;Param1, Param2: string; Param3: TTMSFNCMemoMatches; Param4: TTMSFNCMemoMatch);
begin
  inherited Create(False);
  FRunEvent := ARunEvent;
  FRunEvent2 := ARunEvent2;
  FRunEvent3 := ARunEvent3;
  FRunEvent4 := ARunEvent4;
  FRunEvent5 := ARunEvent5;
  FRunEvent6 := ARunEvent6;
  FParam1 := Param1;
  FParam2 := Param2;
  FParam3 := Param3;
  FParam4 := Param4;
  FreeOnTerminate := False;
end;

procedure TTaskWorkerThread.Execute;
begin
  TThread.Synchronize(TThread.CurrentThread, @SyncEvents);
end;

procedure TTaskWorkerThread.SyncEvents;
begin
  if Assigned(FRunEvent) then
    FRunEvent();
  if Assigned(FRunEvent2) then
    FRunEvent2(FParam1, FParam2);
  if Assigned(FRunEvent3) then
    FRunEvent3(FParam1, FParam2, FParam3);
  if Assigned(FRunEvent4) then
    FRunEvent4(FParam3);
  if Assigned(FRunEvent5) then
    FRunEvent5(FParam1, FParam2, FParam4);
  if Assigned(FRunEvent6) then
    FRunEvent6(FParam4);
end;
{$ENDIF}
{$ENDIF}

{ TTMSFNCMemoLanguageFileExtensionMapItem }

procedure TTMSFNCMemoLanguageFileExtensionMapItem.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCMemoLanguageFileExtensionMapItem then
  begin
    FExtension := (Source as TTMSFNCMemoLanguageFileExtensionMapItem).Extension;
    FLanguage := (Source as TTMSFNCMemoLanguageFileExtensionMapItem).Language;
  end
  else
    inherited;
end;

constructor TTMSFNCMemoLanguageFileExtensionMapItem.Create(ACollection: TCollection);
begin
  inherited;
  FLanguage := mlPascal;
end;

procedure TTMSFNCMemoLanguageFileExtensionMapItem.SetExtension(
  const Value: string);
begin
  FExtension := Value;
end;

procedure TTMSFNCMemoLanguageFileExtensionMapItem.SetLanguage(
  const Value: TTMSFNCMemoLanguage);
begin
  FLanguage := Value;
end;

{ TTMSFNCMemoLanguageFileExtensionMap }

function TTMSFNCMemoLanguageFileExtensionMap.Add: TTMSFNCMemoLanguageFileExtensionMapItem;
begin
  Result := TTMSFNCMemoLanguageFileExtensionMapItem(inherited Add);
end;

function TTMSFNCMemoLanguageFileExtensionMap.Add(ALanguage: TTMSFNCMemoLanguage; AExtension: string): TTMSFNCMemoLanguageFileExtensionMapItem;
begin
  Result := TTMSFNCMemoLanguageFileExtensionMapItem(inherited Add);
  Result.Language := ALanguage;
  Result.Extension := AExtension;
end;

function TTMSFNCMemoLanguageFileExtensionMap.MatchLanguage(
  AExtension: string): Integer;
var
  I: Integer;
begin
  Result := -1;
  for I := 0 to Count - 1 do
  begin
    if Items[i].Extension.ToLower = AExtension.ToLower then
      Result := Integer(Items[i].Language);
  end;
end;

function TTMSFNCMemoLanguageFileExtensionMap.GetItem(Index: Integer): TTMSFNCMemoLanguageFileExtensionMapItem;
begin
  Result := TTMSFNCMemoLanguageFileExtensionMapItem(inherited Items[Index]);
end;

function TTMSFNCMemoLanguageFileExtensionMap.Insert(Index: Integer): TTMSFNCMemoLanguageFileExtensionMapItem;
begin
  Result := TTMSFNCMemoLanguageFileExtensionMapItem(inherited Insert(Index));
end;

procedure TTMSFNCMemoLanguageFileExtensionMap.SetItem(Index: Integer;
  const Value: TTMSFNCMemoLanguageFileExtensionMapItem);
begin
  inherited SetItem(Index, Value);
end;

{ TTMSFNCMemoSource }

procedure TTMSFNCMemoSource.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCMemoSource then
  begin
    FLines.Assign((Source as TTMSFNCMemoSource).Lines);
    FLanguage := (Source as TTMSFNCMemoSource).Language;
    FName := (Source as TTMSFNCMemoSource).Name;
    FReadOnly := (Source as TTMSFNCMemoSource).ReadOnly;
  end
  else
    inherited;

end;

constructor TTMSFNCMemoSource.Create(ACollection: TCollection);
var
  c: TTMSFNCMemoSources;
  m: TTMSFNCCustomMemo;
begin
  inherited;
  FLines := TStringList.Create;
  FLanguage := mlPascal;
  FBreakPoints := TStringList.Create;
  FBreakPoints.Delimiter := ';';
  FBreakPoints.Duplicates := dupIgnore;
  FBookmarks := TStringList.Create;
  FBookmarks.Delimiter := ';';
  FBookmarks.Duplicates := dupIgnore;
  FLineHighlights := TStringList.Create;
  FLineHighlights.Delimiter := ';';
  FLineHighlights.Duplicates := dupIgnore;
  FReadOnly := False;

  if Collection is TTMSFNCMemoSources then
  begin
    c := Collection as TTMSFNCMemoSources;
    if c.Owner is TTMSFNCCustomMemo then
    begin
      m := c.Owner as TTMSFNCCustomMemo;
      FName := 'Source ' + IntToStr(m.SourceIDCount);
      Inc(m.FSourceIDCount);
      if m.ActiveSource = -1 then
        m.ActiveSource := 0;
    end;
  end;
end;

destructor TTMSFNCMemoSource.Destroy;
var
  i: Integer;
  c: TTMSFNCMemoSources;
  m: TTMSFNCCustomMemo;
begin
  FLines.Free;
  FBreakPoints.Free;
  FBookmarks.Free;
  FLineHighlights.Free;

  if Collection is TTMSFNCMemoSources then
  begin
    c := Collection as TTMSFNCMemoSources;
    if c.Owner is TTMSFNCCustomMemo then
    begin
      m := c.Owner as TTMSFNCCustomMemo;

      i := m.ActiveSource;

      inherited;

      i := Min(c.Count - 1, i);
      if (i >= 0) and (i <= c.Count - 1) then
        m.ActiveSource := i
      else
        m.ActiveSource := -1;

      if c.Count = 0 then
        m.SourceIDCount := 0;
    end;
  end
  else
   inherited;
end;

procedure TTMSFNCMemoSource.SaveToFile(AFileName: string);
begin
  Lines.SaveToFile(AFileName)
end;

procedure TTMSFNCMemoSource.SaveToStream(AStream: TStream);
begin
  Lines.SaveToStream(AStream);
end;

procedure TTMSFNCMemoSource.SetCaretPosition(
  const Value: TTMSFNCMemoCaretPosition);
begin
  FCaretPosition.Line := Value.Line;
  FCaretPosition.Pos := Value.Pos;
end;

procedure TTMSFNCMemoSource.SetLanguage(const Value: TTMSFNCMemoLanguage);
begin
  FLanguage := Value;
end;

procedure TTMSFNCMemoSource.SetLines(const Value: TStringList);
begin
  FLines := Value;
end;

procedure TTMSFNCMemoSource.SetName(const Value: string);
begin
  if FName <> Value then
    FName := Value;
end;

procedure TTMSFNCMemoSource.SetReadOnly(const Value: boolean);
begin
  FReadOnly := Value;
end;

{ TTMSFNCMemoSources }

function TTMSFNCMemoSources.Add: TTMSFNCMemoSource;
begin
  Result := TTMSFNCMemoSource(inherited add);
end;

{$IFNDEF WEBLIB}
function TTMSFNCMemoSources.AddSourceFromFile(AFileName, AName: string): TTMSFNCMemoSource;
begin
  Result := Add;
  Result.Lines.LoadFromFile(AFileName);
  Result.FName := AName;
end;

function TTMSFNCMemoSources.AddSourceFromFile(AFileName: string): TTMSFNCMemoSource;
begin
  Result := Add;
  Result.Lines.LoadFromFile(AFileName);
  Result.Name := '';
end;

function TTMSFNCMemoSources.AddSourceFromFile(AFileName, AName: string;
  ALanguage: TTMSFNCMemoLanguage): TTMSFNCMemoSource;
begin
  Result := Add;
  Result.Lines.LoadFromFile(AFileName);
  Result.Name := '';
  Result.Language := ALanguage;
end;
{$ENDIF}

function TTMSFNCMemoSources.AddSource(AText: string;
  ALanguage: TTMSFNCMemoLanguage; AName: string): TTMSFNCMemoSource;
begin
  Result := Add;
  Result.Name := AName;
  Result.Lines.Text := AText;
  Result.Language := ALanguage;
end;

function TTMSFNCMemoSources.AddSourceFromStream(
  AStream: TStream): TTMSFNCMemoSource;
begin
  Result := Add;
  Result.Lines.LoadFromStream(AStream{$IFNDEF LCLLIB}, TEncoding.UTF8{$ENDIF});
end;

function TTMSFNCMemoSources.AddSourceFromStream(AStream: TStream;
  AName: string): TTMSFNCMemoSource;
begin
  Result := AddSourceFromStream(AStream);
  Result.Name := AName;
end;

function TTMSFNCMemoSources.Find(Name: string): TTMSFNCMemoSource;
var
  I: Integer;
begin
  Result := nil;
  for I := 0 to Count -1 do
  begin
    if Items[i].Name = Name then
    begin
      Result := Items[i];
      Exit;
    end;
  end;
end;

function TTMSFNCMemoSources.GetItem(Index: Integer): TTMSFNCMemoSource;
begin
  Result := TTMSFNCMemoSource(inherited Items[Index]);
end;

function TTMSFNCMemoSources.IndexOf(Name: string): Integer;
var
  i: Integer;
begin
  Result := -1;
  for i := 0 to Count -1 do
  begin
    if Items[i].Name = Name then
    begin
      Result := i;
      Exit;
    end;
  end;
end;

function TTMSFNCMemoSources.Insert(Index: Integer): TTMSFNCMemoSource;
begin
  Result := TTMSFNCMemoSource(inherited Insert(Index));
end;

procedure TTMSFNCMemoSources.SetItem(Index: Integer;
  const Value: TTMSFNCMemoSource);
begin
  inherited SetItem(Index, Value);
end;

function TTMSFNCMemoSources.AddSourceFromStream(AStream: TStream; AName: string;
  ALanguage: TTMSFNCMemoLanguage): TTMSFNCMemoSource;
begin
  Result := AddSourceFromStream(AStream, AName);
  Result.Language := ALanguage;
end;

end.
