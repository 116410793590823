unit uBlogEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, XData.Web.Client, utb, uGlobal, WEBLib.WebCtrls,
  Vcl.StdCtrls, WEBLib.StdCtrls, ufrVideoListe, Vcl.Forms, ufrFotosListe,
  WEBLib.ComCtrls, WEBLib.Buttons, Vcl.Imaging.pngimage, Vcl.Controls,
  WEBLib.ExtCtrls, WEBLib.EditAutocomplete;

const
  _bt_Reise= 1;
  _bt_Food = 2;

type
  TfrmBlogEdit = class(TForm)
    Client: TXDataWebClient;
    WebHTMLDiv1: THTMLDiv;
    WebHTMLDiv11: THTMLDiv;
    btnClose: TImageControl;
    lblTitel: TLabel;
    WebHTMLDiv6: THTMLDiv;
    WebHTMLDiv7: THTMLDiv;
    divOben: THTMLDiv;
    divHeader: THTMLDiv;
    WebHTMLDiv2: THTMLDiv;
    EditButtons: TRichEditToolBar;
    divEdit: THTMLDiv;
    reText: TRichEdit;
    divImg: THTMLDiv;
    WebHTMLDiv3: THTMLDiv;
    WebHTMLDiv5: THTMLDiv;
    WebHTMLDiv4: THTMLDiv;
    btnSave: TButton;
    btnCancel: TButton;
    WebHTMLDiv8: THTMLDiv;
    edTitel: TEdit;
    WebLabel1: TLabel;
    cbKategorie: TComboBox;
    btnLosGehts: TSpeedButton;
    btnHashTag: TButton;
    divHashTags: THTMLDiv;
    divNeu: THTMLDiv;
    btn1: TSpeedButton;
    btn2: TSpeedButton;
    btn3: TSpeedButton;
    btn4: TSpeedButton;
    btnRezept: TSpeedButton;
    btnHeader: TSpeedButton;
    btnAnmerkung: TSpeedButton;
    btnVorschau: TSpeedButton;
    divFoto: THTMLDiv;
    WebHTMLDiv10: THTMLDiv;
    divSichtbar: THTMLDiv;
    WebHTMLDiv12: THTMLDiv;
    WebLabel2: TLabel;
    edSichtbar_ab: TDateTimePicker;
    WebLabel3: TLabel;
    cbSichtbar: TComboBox;
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure btnCancelClick(Sender: TObject);
    [async] procedure btnSaveClick(Sender: TObject); async;
    [async] procedure WebFormCloseQuery(Sender: TObject; var CanClose: Boolean); async;
    [async] procedure divFotoClick(Sender: TObject); async;
    procedure lblHashTagsClick(Sender: TObject);
    [async] procedure btnEditContent(Sender: TObject); async;
    procedure btn1Click(Sender: TObject);
    procedure btnVorschauClick(Sender: TObject);
    procedure btnRezeptClick(Sender: TObject);
    procedure btnAnmerkungClick(Sender: TObject);
    procedure VorschauBtnToggle(AStatus:integer);
    procedure btnHeaderClick(Sender: TObject);
    [async] procedure btnLosGehtsClick(Sender: TObject); async;
    procedure edTitelKeyDown(Sender: TObject; var Key: Word;
      Shift: TShiftState);
  private
    FBlogTyp,
    FBlogKat,
    FLevel:integer;
    FVorschau:integer;
    FNewID:integer;
    FContainer:THtmlDiv;
    FVor, FHolder, FMe, FID, FMaster:integer;
    FTyp: TEditTyp;
    FCallback:TCallback;
    FAktImage:string;
    FVideos, FImages:array of string;
    FHashTags : array of TCheckBox;
    FRows:array of TBlogItem;
  public
    class function Blog(AHolder, AMe, AID, AVor, ABlog, ATitelFoto:integer; ATitel, AText:string; ALevel, ABlogKat, ABlogTyp:integer; ASichtbar:integer; ASichtbar_AB:string; ACallback:TCallback): integer;
//    class function Post(AHolder, AMe, APost, AParent:integer; ACallback:TCallback): integer;
//    class function Medien(AHolder, AMe, APost, AParent:integer; ACallback:TCallback): integer;
    class function Exec(AHolder, AMe, AID, AVor, AMaster, ATitelFoto:integer; ATitel, AText:string; ALevel, ABlogKat, ABlogTyp:integer; ATyp: TEditTyp; ASichtbar:integer; ASichtbar_AB:string; ACallback:TCallback): integer;
    [async] procedure ImageClick(s:string); async;
  protected procedure LoadDFMValues; override; end;

var
  frmBlogEdit: TfrmBlogEdit;

implementation
  uses uMain, uDatenModul, uMedienAuswahl, uHashTags, uRichEdit;
{$R *.dfm}

class function TfrmBlogEdit.Blog(AHolder, AMe, AID, AVor, ABlog, ATitelFoto:integer; ATitel, AText:string; ALevel, ABlogKat, ABlogTyp:integer; ASichtbar:integer; ASichtbar_AB:string; ACallback:TCallback): integer;
begin
  Exec(AHolder, AMe, AID, AVor, ABlog, ATitelFoto, ATitel, AText, ALevel, ABlogKat, ABlogTyp, etBlog, ASichtbar, ASichtbar_AB, ACallback);
end;

//class function TfrmBlogEdit.Post(AHolder, AMe, APost, AParent:integer; ACallback:TCallback): integer;
//begin
//  Exec(AHolder, AMe, APost, -1, AParent, 0, '','', 0, 0, 0, etPost, ACallback);
//end;
//
//class function TfrmBlogEdit.Medien(AHolder, AMe, APost, AParent:integer; ACallback:TCallback): integer;
//begin
//  Exec(AHolder, AMe, APost, -1,AParent, 0, '','', 0, 0, 0, etFoto, ACallback);
//end;

class function TfrmBlogEdit.Exec(AHolder, AMe, AID, AVor, AMaster, ATitelFoto:integer; ATitel, AText:string; ALevel, ABlogKat, ABlogTyp:integer; ATyp: TEditTyp; ASichtbar:integer; ASichtbar_AB:string; ACallback:TCallback): integer;

  [async] procedure AfterCreate(AForm: TObject); async;
  Var
    j:TJ;
    l,k, i:integer;
    a:array of TBlogItem;
  begin

//    showmessage('Master: ' + AMaster.ToString);

    TfrmBlogEdit(AForm).ElementHandle.style.removeProperty('position');
    TfrmBlogEdit(AForm).Parent := Mainform;
    TfrmBlogEdit(AForm).FHolder  := AHolder;
    TfrmBlogEdit(AForm).FMe      := AMe;
    TfrmBlogEdit(AForm).FID      := AID;
    TfrmBlogEdit(AForm).FMaster  := AMaster;
    TfrmBlogEdit(AForm).FVor     := AVor;
    TfrmBlogEdit(AForm).FTyp     := ATyp;
    TfrmBlogEdit(AForm).FLevel   := ALevel;
    TfrmBlogEdit(AForm).FBlogKat := ABlogKat;
    TfrmBlogEdit(AForm).FBlogTyp := ABlogTyp;
    TfrmBlogEdit(AForm).edTitel.Text:= ATitel;
    TfrmBlogEdit(AForm).edTitel.elementhandle.style.setProperty('height','30px');
    TfrmBlogEdit(AForm).edTitel.elementhandle.style.setProperty('width','100%');
    TfrmBlogEdit(AForm).reText.Lines.Text  := AText;
    TfrmBlogEdit(AForm).divEdit.HTML.Text  := AText;
    TfrmBlogEdit(AForm).edTitel.visible    := (ATyp = etBlog);
    TfrmBlogEdit(AForm).divFoto.Tag        := ATitelFoto;
    TfrmBlogEdit(AForm).divSichtbar.visible := mainform.me.Admin;

//    showmessage(ASichtbar.ToString);
    if ASichtbar > 0
    then TfrmBlogEdit(AForm).cbSichtbar.ItemIndex := ASichtbar
    else TfrmBlogEdit(AForm).cbSichtbar.ItemIndex := 0;

    if ASichtbar_ab > ''
    then TfrmBlogEdit(AForm).edSichtbar_ab.DateTime := strtodate(ASichtbar_Ab);
  
    //Kategorieliste füllen
    await(tbFillCombobox(TfrmBlogEdit(AForm).cbKategorie, 'ID','TEXT', await(datenmodul.EasySQL('SELECT ID,TEXT from BlogKat WHERE VOR = 0 AND LEVEL = 1'))));
    TfrmBlogEdit(AForm).cbKategorie.ItemIndex := -1;
    for i := 0 to TfrmBlogEdit(AForm).cbKategorie.Items.Count-1 do
    begin
      //... und vorbelegen
      if TfrmBlogEdit(AForm).cbKategorie.values[i] = ABlogKat.ToString
      then begin
        TfrmBlogEdit(AForm).cbKategorie.ItemIndex := i;
        break;
      end;
    end;

    //Blog Typ holen
    j := TJ.create(await(datenmodul.EasySQL('SELECT TYP from BlogKat WHERE ID = ' + ABlogKat.ToString)));

    TfrmBlogEdit(AForm).FBlogTyp := j.Integer('TYP');

    if ATitelFoto > 0
    then begin
      TfrmBlogEdit(AForm).divFoto.HTML.Clear;
      TfrmBlogEdit(AForm).divFoto.ElementHandle.style.setProperty('background-image','url(' + await(datenmodul.GetMedia(ATitelFoto, _Size2)) + ')');
    end;

    TfrmBlogEdit(AForm).EditButtons.Visible := false;
//    TfrmPostEdit(AForm).FImages := await(GetAndShowImages(TfrmPostEdit(AForm).divImg, inttostr(integer(ATyp)), AID.ToString, mainform.ME.HideFotos));

    await(FillHashTags(TfrmBlogEdit(AForm).divHashTags, AID.ToString, ABlogKat.ToString, false, []));

    if (ATyp <> etFoto) and (ATyp <> etVideo)
    then TfrmBlogEdit(AForm).lblTitel.Caption := 'NEUEN BEITRAG EINGEBEN'
    else TfrmBlogEdit(AForm).lblTitel.Caption := 'Gib einen neuen Kommentar ein';

    //Inhalte in Array
    j.response := await(datenmodul.EasySQL('Select * from BlogContent where BLOG_ID = ' + AID.ToString + ' ORDER BY ROW, ID'));

    SetLength(TfrmBlogEdit(AForm).FRows, j.Length);

    for i := 0 to J.Length - 1 do
    begin
      j.Index := i;

      TfrmBlogEdit(AForm).FRows[i].ID        := i;
      TfrmBlogEdit(AForm).FRows[i].Row       := j.integer('ROW');
      TfrmBlogEdit(AForm).FRows[i].Text      := j.Value('TEXT');
      TfrmBlogEdit(AForm).FRows[i].Image     := j.integer('IMAGE');
      TfrmBlogEdit(AForm).FRows[i].Height    := j.Value('HEIGHT');
      TfrmBlogEdit(AForm).FRows[i].Width     := j.value('WIDTH');
      TfrmBlogEdit(AForm).FRows[i].CanDelete := j.isTrue('CANDELETE');
    end;

    if length(TfrmBlogEdit(AForm).FRows) > 0
    then begin

      if TfrmBlogEdit(AForm).FBlogTyp = _bt_Food
      then begin
        TfrmBlogEdit(AForm).btn1.Visible        := false;
        TfrmBlogEdit(AForm).btn2.Visible        := false;
        TfrmBlogEdit(AForm).btn3.Visible        := false;
        TfrmBlogEdit(AForm).btn4.Visible        := false;
        TfrmBlogEdit(AForm).btnRezept.Visible   := false;
        TfrmBlogEdit(AForm).btnAnmerkung.Visible:= true;
        TfrmBlogEdit(AForm).btnHeader.Visible   := false;

      end
      else begin
        TfrmBlogEdit(AForm).btnRezept.Visible   := false;
        TfrmBlogEdit(AForm).btnAnmerkung.Visible:= false;
        TfrmBlogEdit(AForm).btnHeader.Visible   := false;
      end;

      TfrmBlogEdit(AForm).divNeu.Visible := false;
      TfrmBlogEdit(AForm).VorschauBtnToggle(_Blog_Edit);
      Array2BlogRows(TfrmBlogEdit(AForm).divEdit,
                     TfrmBlogEdit(AForm).FRows,
                     _Blog_Edit, false,
                     @TfrmBlogEdit(AForm).btnEditContent);
    end
    else TfrmBlogEdit(AForm).divNeu.Visible := true;

  end;

Var
  frm:TfrmBlogEdit;
  FConti:THtmlDiv;
begin

  FConti := THtmlDiv.Create(Mainform);
  FConti.Parent := Mainform;
  FConti.ElementFont := efCSS;

  FConti.ElementClassName := 'editor_transparent';
  frm := TfrmBlogEdit.CreateNew(FConti.ElementID, @AfterCreate);

  frm.FContainer := FConti;
  frm.FCallback  := ACallback;

end;

procedure TfrmBlogEdit.btnRezeptClick(Sender: TObject);
begin
  if FBlogKat = 0
  then begin
    Meldung('Bevor du loslegst wähle eine Kategorie aus und bestätige deine Auswahl durch klicken auf den Button...');
    exit;
  end;

  if FBlogTyp = _bt_Food
  then begin

    btn1Click(btn2);
    FRows[high(FRows)-1].CanDelete := false;
    FRows[high(FRows)-1].Width := '35';
    FRows[high(FRows)-1].Text  := '<font size="5"><b>Zutaten für 4 Personen:</b></font>' +
                                  '<div><ul><li><span style="font-size: medium;">hier die Zutaten auflisten</span></li></ul></div>';

    FRows[high(FRows)-1].CanDelete := false;
    FRows[high(FRows)].Width := '65';
    FRows[high(FRows)].Text  := '<font size="5"><b>Zubereitung:</b></font><br><div></div>';

    VorschauBtnToggle(_Blog_Edit);

    Array2BlogRows(divEdit, FRows, _Blog_Edit, false, @btnEditContent);
  end;

end;

procedure TfrmBlogEdit.btnAnmerkungClick(Sender: TObject);
begin
  if FBlogKat = 0
  then begin
    Meldung('Bevor du loslegst wähle eine Kategorie aus und bestätige deine Auswahl durch klicken auf den Button...');
    exit;
  end;

  if FBlogTyp = _bt_Food
  then begin

    btn1Click(btn1);
    FRows[high(FRows)].Text  := '<font size="5"><b>Anmerkungen:</b></font><br><div></div>';
    VorschauBtnToggle(_Blog_Edit);

//    showmessage(inttostr(length(FRows)));
    Array2BlogRows(divEdit, FRows, _Blog_Edit, false, @btnEditContent);
  end;

end;

procedure TfrmBlogEdit.btn1Click(Sender: TObject);
Var
  iProzent, i, iLastRow, iOldCount, iCount:integer;
  a:array of TBlogItem;
begin

  if FBlogkat = 0
  then begin
    Meldung('Bevor du loslegst wähle eine Kategorie aus und bestätige deine Auswahl durch klicken auf den Button...');
    cbKategorie.Setfocus;
    exit;
  end;

  //wieviele "alte" Zeilen gibt es?
  iOldCount := length(FRows);

  //Wenn alte Zeilen, dann die Row-Nr. merken
  if iOldCount > 0
  then iLastRow := iOldCount //FRows[High(FRows)].Row
  else iLastRow := 0;

  //Wieviel neue?
  iCount := copy(TButton(sender).Name, 4,1).toInteger;

  //Temp-Array auf länge
  setlength(a, iCount);

  //Spaltenbreite aufteilen
  iProzent := 100 div iCount;

  //Tmp-Array mit default-Werten füllen.
  for i := 0 to iCount-1 do
  begin
    a[i].Row       := iLastRow + 1;
    a[i].ID        := iOldCount + i;
    a[i].Text      := '';
    a[i].width     := iProzent.tostring;
    a[i].height    := '100';
    a[i].CanDelete := true;

    Setlength(FRows,length(FRows) + 1);
    FRows[High(FRows)].Row      := a[i].Row;
    FRows[High(FRows)].ID       := a[i].ID;
    FRows[High(FRows)].Text     := a[i].Text;
    FRows[High(FRows)].Image    := a[i].Image;
    FRows[High(FRows)].Height   := a[i].Height;
    FRows[High(FRows)].Width    := a[i].Width;
    FRows[High(FRows)].CanDelete:= a[i].CanDelete;
  end;

  //Neue Zeile erstellen und Buttons anstelle der texte
  BlogRow(divEdit, a, _Blog_Edit, false, @btnEditContent);
end;

procedure TfrmBlogEdit.btnCancelClick(Sender: TObject);
begin
 close;
end;

procedure TfrmBlogEdit.btnSaveClick(Sender: TObject);
var
  Response: TXDataClientResponse;
  j:TJ;
  iRow, k,i:integer;
  ht:string;
  aInt : array of integer;
  sSichtbar, sSichtbar_ab, s,sKat:string;
begin

  if (divEdit.elementhandle.innerHtml > '') or (edTitel.Text > '') or (reText.PlainText > '') or (Length(FImages) > 0)
  then begin
    if (FBlogKat = 0)
    then begin
      await(Meldung('Das Feld "Kategorie" muss ausgefüllt werden...'));
      cbKategorie.setfocus;
      exit;
    end;

    if (edTitel.Text = '')
    then begin
      await(Meldung('Das Feld "Titel" muss ausgefüllt werden...'));
      edTitel.SetFocus;
      exit;
    end;

    sSichtbar    := cbSichtbar.itemindex.tostring;
    sSichtbar_ab := FormatDateTime(_DATE_SAVE, edSichtbar_ab.DateTime);

    sKat := FBlogKat.tostring; //cbKategorie.Values[cbKategorie.ItemIndex];

    if FID = 0
    then begin
      FMaster := 0;

      FID := await(datenmodul.EasyInsert('Insert into BLOG (LEVEL, STATUS, BLOGKAT_ID, HOLDER, USER_ID, MEDIEN_ID, VOR, BLOG_ID, HEADER, SICHTBAR_AB, SICHTBAR) ' +
                                               ' VALUES (2, 1, ' + sKat + ',' +
                                                             FHolder.ToString + ',' +
                                                             FME.ToString + ',' +
                                                             divFoto.Tag.ToString + ',' +
                                                             FVor.ToString + ',' +
                                                             FMaster.ToString + ',''' +
                                                             chkStr(edTitel.Text) + ''',''' +
                                                             sSichtbar_ab + ''',' +
                                                             sSichtbar + ')'));
      if FMaster = 0
      then await(datenmodul.EasyExec('Update BLOG set BLOG_ID = ID WHERE ID = ' + FID.ToString));
    end
    else begin
      await(datenmodul.EasyExec('Update BLOG set HEADER = ''' + chkStr(edTitel.Text) + ''',' +
                                                'MEDIEN_ID = ' + divFoto.Tag.ToString  + ',' +
                                                'BLOGKAT_ID = ' + sKat  + ',' +
                                                'SICHTBAR_AB = ''' + sSichtbar_ab + ''',' +
                                                'SICHTBAR = ' + sSichtbar +
                                ' WHERE ID = ' + FID.ToString));
    end;

    ht := _HT_Blog;

    //erst alle BlogItems löschen
    await(datenmodul.EasyExec('Delete from BlogContent where BLOG_ID = ' + FID.ToString));

    //... dann alle wieder einfügen...
    k := 0; iRow:=0;

    for i := 0 to length(FRows) - 1 do
    begin
//        showmessage(i.ToString + ':' + FRows[i].Text);
      if FRows[i].Row > 0
      then begin

        if k <> FRows[i].Row
        then begin
          k := FRows[i].Row;
          inc(iRow);
        end;
      //  showmessage(i.ToString);
        s :=  'Insert into BlogContent ' +
              ' (BLOG_ID, ROW, CANDELETE, TEXT, HEIGHT, WIDTH, IMAGE) ' +
              ' VALUES (' + FID.ToString + ','  +
                                iRow.ToString + ',' +
                                IIF(FRows[i].CanDelete,'1','0') + ',''' +
                                chkstr(FRows[i].Text) + ''',''' +
                                FRows[i].Height + ''',''' +
                                FRows[i].Width + ''',' +
                                FRows[i].Image.ToString + ')';

        await(datenmodul.EasyInsert(s));

      end;
    end;

    //gibt es Fotos oder Videos?
    //erstmal alle löschen
    await(datenmodul.EasyEXEC('DELETE from Medien2Holder ' +
                              ' WHERE HOLDER_TYP = ' + ht + ' AND ' +
                              '       HOLDER_ID  = ' + FID.tostring + ' AND ' +
                              '       MEDIEN_TYP = 1' ));

    //...dann alle wieder hinzufügen
    if Length(FImages) > 0
    then begin
      for i := 0 to length(FImages) - 1 do
      begin
        await(datenmodul.EasyInsert('Insert into Medien2Holder (Holder_Typ, Holder_ID, Medien_Typ, Medien_ID) ' +
                                    ' VALUES ( ' + ht + ',' + FID.ToString + ',1,' + FImages[i] + ')' ));
      end;
    end;

    //erstmal alle HashTags löschen
    await(datenmodul.EasyExec('DELETE from BlogX where TYP = 0 AND Blog_id = ' + FID.ToString));

    //HashTags speichern
    HashSaveStatus(divHashTags, aInt);
    for i := 0 to length(aInt) - 1 do
       await(datenmodul.EasyInsert('INSERT INTO BlogX (TYP, BLOG_ID, BLOGKAT_ID) ' +
                                   '           VALUES (0, ' + FID.ToString + ',' + aInt[i].tostring + ')'));

    if assigned(FCallback)
    then FCallBack(FID.ToString);

    close;
  end
  else begin
    Meldung('Beitrag wird nicht gepeichert - da er weder Text noch Medien enthält...');
  end;
end;

procedure TfrmBlogEdit.btnEditContent(Sender: TObject);

  function ExtrahiereBlock(arr: array of TBlogItem; Row: Integer): TArray<TBlogItem>;
  var
    i, blockStart, blockEnd: Integer;
  begin
    blockStart := -1;
    blockEnd := -1;

    // Finde den Start und das Ende des Blocks mit der angegebenen Row
    for i := Low(arr) to High(arr) do
    begin
      if (arr[i].Row = Row) and (blockStart = -1)
      then begin
        blockStart := i;
        blockEnd := i;
      end
      else  if arr[i].Row = Row
            then blockEnd := i;
    end;

    // Extrahiere den Block als ein separates Array
    if (blockStart <> -1) and (blockEnd <> -1)
    then begin
      SetLength(Result, blockEnd - blockStart + 1);
      for i := blockStart to blockEnd do
        Result[i - blockStart] := arr[i];
    end
    else SetLength(Result, 0); // Wenn kein Block gefunden wurde, gib ein leeres Array zurück
  end;

  procedure Einfuegen(var targetArr: array of TBlogItem; startIndex: Integer; sourceArr: array of TBlogItem);
  var
    i: Integer;
  begin
    for i := 0 to High(sourceArr) do
      targetArr[startIndex + i] := sourceArr[i];
  end;

//---------------
  [async]
  procedure Callback(a:array of string); async;
  const
    _RowIdx = 0;
  begin
    FRows[a[_RowIdx].ToInteger].Text  := a[1];
    FRows[a[_RowIdx].ToInteger].Image := a[2].ToInteger;
    FRows[a[_RowIdx].ToInteger].Height:= a[3];
    FRows[a[_RowIdx].ToInteger].Width := a[4];
  end;

var
  iStart, i,iRow:integer;
  sBtn:string;
  a1, a2: array of TBlogItem;
begin
  if TButton(Sender).Tag >= 0
  then begin
    TfrmRichEdit.Exec(TButton(Sender).Tag,
                      FRows[TButton(Sender).Tag].Text,
                      FRows[TButton(Sender).Tag].Image,
                      FRows[TButton(Sender).Tag].Height,
                      FRows[TButton(Sender).Tag].Width,
                      @Callback);
  end
  else begin

    sBtn := TButton(Sender).Name;
    if pos('btnDel',sBtn) = 1
    then sBtn := 'del'
    else  if pos('btnUp',sBtn) = 1
          then sBtn := 'up'
          else  if pos('btnDown',sBtn) = 1
          then sBtn := 'down';

    if (sBtn = 'del') and (await(Frage('Komplette Zeile löschen?')) = mrYes)
    then begin

      iRow := (TButton(Sender).Tag * -1);

      for i := length(FRows) -1 downto 0  do
      begin
        if (iRow = FRows[i].Row) or (iRow * -1 = FRows[i].Row )
        then begin
          FRows[i].Row := (1000 + FRows[i].Row);
          if FRows[i].Row > 1
          then FRows[i].Row := FRows[i].Row * -1;
        end;
      end;

      Array2BlogRows(divEdit, FRows, _Blog_Edit, false, @btnEditContent);

    end;

    if ((sBtn = 'up') or ((sBtn = 'down')))
    then begin

      iRow := (TButton(Sender).Tag * -1);

      //festellen wo der gesuchte Bereich im Array anfängt
      iStart := 0;

      for i := 0 to high(FRows) do
      begin
        if (iRow = FRows[i].Row) or (iRow * -1 = FRows[i].Row )
        then begin
          iStart := i;
          break;
        end;
      end;

      if sBtn = 'up'
      then a1 := ExtrahiereBlock(FRows, iRow - 1)  //vorgänger
      else a1 := ExtrahiereBlock(FRows, iRow + 1); //Nachfolger

      a2 := ExtrahiereBlock(FRows, iRow); //der gesuchte block

      for i := 0 to high(a1) do
      begin
        if sBtn = 'up' //vorgänger wird runter geschoben, also row +1
        then a1[i].row := a1[i].row + 1
        else a1[i].row := a1[i].row - 1
      end;

      for i := 0 to high(a2) do
      begin
        if sBtn = 'up' //vorgänger wird hoch geschoben, also row - 1
        then a2[i].row := a2[i].row - 1
        else a2[i].row := a2[i].row + 1;
      end;

      if sBtn = 'up'
      then begin
        //gesuchten Block ab der Startpunkt von Vorgänger einsetzen
        iStart := iStart - length(a1) ;
        Einfuegen(FRows, iStart, a2);
        //Vorgänger danach einsetzen
        iStart := iStart + length(a2) ;
        Einfuegen(FRows, iStart, a1);
      end
      else begin
        //gesuchten Block ab der Startpunkt von Vorgänger einsetzen
        iStart := iStart;
        Einfuegen(FRows, iStart, a1);
        //Vorgänger danach einsetzen
        iStart := iStart + length(a1) ;
        Einfuegen(FRows, iStart, a2);
      end;

      Array2BlogRows(divEdit, FRows, _Blog_Edit, false, @btnEditContent);

    end;

  end;
end;

procedure TfrmBlogEdit.divFotoClick(Sender: TObject);
  [async]
  procedure Callback(s:string); async;
  begin
    divFoto.HTML.Clear;
    divFoto.Tag := s.toInteger;
    divFoto.ElementHandle.style.setProperty('background-image','url(' + await(datenmodul.GetMedia(s.ToInteger, _SIze2)) + ')');
  end;

begin
  if FBlogkat = 0
  then begin
    Meldung('Bevor du loslegst wähle eine Kategorie aus und bestätige deine Auswahl durch klicken auf den Button...');
    cbKategorie.setfocus;
    exit;
  end;
  TfrmMedienAuswahl.Fotos(Mainform.Holder.ID, Mainform.ME.ID, Mainform.ME.Album, 0, 0, 0, 0, @CallBack, 2);
end;

procedure TfrmBlogEdit.ImageClick(s:string);
begin
end;

procedure TfrmBlogEdit.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  FreeAndNil(FContainer);
end;

procedure TfrmBlogEdit.WebFormCloseQuery(Sender: TObject; var CanClose: Boolean);
begin
  canclose := true;
end;

procedure TfrmBlogEdit.lblHashTagsClick(Sender: TObject);
  [async]
  procedure Callback(a:Array of string); async;
  Var
    i:integer;
    ai:array of integer;
  begin
    setlength(ai, length(a));
    for i := 0 to length(a) - 1 do
      ai[i] := a[i].ToInteger;

    await(RemoveAllChildControls(divHashTags));
    await(FillHashTags(divHashTags, FID.ToString, FBlogKat.ToString, false, ai));

  end;

var
  a:array of integer;
begin
  if FBlogKat = 0
  then begin
    Meldung('Bevor du loslegst wähle eine Kategorie aus und bestätige deine Auswahl durch klicken auf den Button...');
    cbKategorie.setfocus;
    exit;
  end;

  HashSaveStatus(divHashTags,a);
  TfrmHashTags.Exec( FBlogKat.ToString, FID.ToString, true, '', '', a, @Callback);
end;


procedure TfrmBlogEdit.WebFormCreate(Sender: TObject);
begin
  divFoto.ElementHandle.style.removeProperty('position');
  btnHashTag.ElementHandle.style.removeProperty('width');
  reText.ElementHandle.style.removeProperty('position');
  edTitel.ElementHandle.style.removeProperty('position');
  btn1.ElementHandle.style.removeProperty('vertical-align');
  btn2.ElementHandle.style.removeProperty('vertical-align');
  btn3.ElementHandle.style.removeProperty('vertical-align');
  btn4.ElementHandle.style.removeProperty('vertical-align');
end;

procedure TfrmBlogEdit.btnLosGehtsClick(Sender: TObject);
Var
  j:TJ;
begin
  FBlogKat := cbKategorie.Values[cbKategorie.ItemIndex].ToInteger;

  if FBlogKat = 0
  then begin
    Meldung('Bevor du loslegst wähle eine Kategorie aus und bestätige dein Auswahl durch klicken auf den Button...');
    cbKategorie.setfocus;
    exit;
  end;

  divNeu.visible := false;

  j := TJ.create(await(datenmodul.EasySQL('select TYP from BlogKat WHERE ID = '+ FBlogKat.ToString)));

  FBlogTyp := j.Integer('TYP');

  if length(FRows) = 0
  then begin
    if FBlogTyp = _bt_Food
    then begin
      btn1.Visible        := false;
      btn2.Visible        := false;
      btn3.Visible        := false;
      btn4.Visible        := false;
      btnRezept.Visible   := false;
      btnAnmerkung.Visible:= true;
      btnHeader.Visible   := false;

      //btnHeaderClick(btnHeader);
      btnRezeptClick(btnRezept);

    end
    else begin
      btnRezept.Visible   := false;
      btnAnmerkung.Visible:= false;
      btnHeader.Visible   := false;

      //btnHeaderClick(btnHeader);
    end;
  end;

end;

procedure TfrmBlogEdit.btnHeaderClick(Sender: TObject);
begin
  if FBlogKat = 0
  then begin
    Meldung('Bevor du loslegst wähle eine Kategorie aus...');
    cbKategorie.setfocus;
    exit;
  end;

  btn1Click(btn1);
  FRows[high(FRows)].CanDelete := false;
  FRows[high(FRows)].Text  := '<font size="5"><b>Hier die Überschrift eingeben</b></font>';

  VorschauBtnToggle(_Blog_Edit);

  Array2BlogRows(divEdit, FRows, _Blog_Edit, false, @btnEditContent);

end;

procedure TfrmBlogEdit.VorschauBtnToggle(AStatus:integer);
begin
  if AStatus > 0  //nicht togglen - einfach setzen
  then FVorschau := AStatus
  else begin
    if FVorschau = _Blog_Content
    then FVorschau := _Blog_Edit
    else FVorschau := _Blog_Content;
  end;

  if FVorschau = _blog_content
  then btnVorschau.MaterialGlyph := 'edit'
  else btnVorschau.MaterialGlyph := 'preview';

  //btnVorschau.Caption := IIF(FVorschau = _Blog_Content, 'Bearbeiten','Vorschau');
end;

procedure TfrmBlogEdit.btnVorschauClick(Sender: TObject);
begin
  if FBlogKat = 0
  then begin
    Meldung('Bevor du loslegst wähle eine Kategorie aus und bestätige deine Auswahl durch klicken auf den Button...');
    cbKategorie.setfocus;
    exit;
  end;

  VorschauBtnToggle(0);

  Array2BlogRows(divEdit,
                 FRows,
                 FVorschau, false,
                 @btnEditContent);
end;

procedure TfrmBlogEdit.edTitelKeyDown(Sender: TObject; var Key: Word;
  Shift: TShiftState);
begin
  if FBlogKat = 0
  then begin
    Meldung('Bevor du loslegst wähle eine Kategorie aus und bestätige deine Auswahl durch klicken auf den Button...');
    key :=0;
    cbKategorie.setfocus;
    exit;
  end;

end;

procedure TfrmBlogEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  WebHTMLDiv11 := THTMLDiv.Create(Self);
  btnClose := TImageControl.Create(Self);
  lblTitel := TLabel.Create(Self);
  WebHTMLDiv6 := THTMLDiv.Create(Self);
  WebHTMLDiv7 := THTMLDiv.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  cbKategorie := TComboBox.Create(Self);
  btnLosGehts := TSpeedButton.Create(Self);
  divOben := THTMLDiv.Create(Self);
  divFoto := THTMLDiv.Create(Self);
  WebHTMLDiv10 := THTMLDiv.Create(Self);
  divSichtbar := THTMLDiv.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  cbSichtbar := TComboBox.Create(Self);
  WebHTMLDiv12 := THTMLDiv.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  edSichtbar_ab := TDateTimePicker.Create(Self);
  divHeader := THTMLDiv.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  btn1 := TSpeedButton.Create(Self);
  btn2 := TSpeedButton.Create(Self);
  btn3 := TSpeedButton.Create(Self);
  btn4 := TSpeedButton.Create(Self);
  btnRezept := TSpeedButton.Create(Self);
  btnHeader := TSpeedButton.Create(Self);
  btnAnmerkung := TSpeedButton.Create(Self);
  btnVorschau := TSpeedButton.Create(Self);
  EditButtons := TRichEditToolBar.Create('');
  divEdit := THTMLDiv.Create(Self);
  divNeu := THTMLDiv.Create(Self);
  reText := TRichEdit.Create(Self);
  divImg := THTMLDiv.Create(Self);
  WebHTMLDiv3 := THTMLDiv.Create(Self);
  WebHTMLDiv5 := THTMLDiv.Create(Self);
  btnHashTag := TButton.Create(Self);
  divHashTags := THTMLDiv.Create(Self);
  WebHTMLDiv4 := THTMLDiv.Create(Self);
  btnSave := TButton.Create(Self);
  btnCancel := TButton.Create(Self);
  WebHTMLDiv8 := THTMLDiv.Create(Self);
  edTitel := TEdit.Create(Self);
  Client := TXDataWebClient.Create(Self);

  WebHTMLDiv1.BeforeLoadDFMValues;
  WebHTMLDiv11.BeforeLoadDFMValues;
  btnClose.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  WebHTMLDiv6.BeforeLoadDFMValues;
  WebHTMLDiv7.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  cbKategorie.BeforeLoadDFMValues;
  btnLosGehts.BeforeLoadDFMValues;
  divOben.BeforeLoadDFMValues;
  divFoto.BeforeLoadDFMValues;
  WebHTMLDiv10.BeforeLoadDFMValues;
  divSichtbar.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  cbSichtbar.BeforeLoadDFMValues;
  WebHTMLDiv12.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  edSichtbar_ab.BeforeLoadDFMValues;
  divHeader.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  btn1.BeforeLoadDFMValues;
  btn2.BeforeLoadDFMValues;
  btn3.BeforeLoadDFMValues;
  btn4.BeforeLoadDFMValues;
  btnRezept.BeforeLoadDFMValues;
  btnHeader.BeforeLoadDFMValues;
  btnAnmerkung.BeforeLoadDFMValues;
  btnVorschau.BeforeLoadDFMValues;
  EditButtons.BeforeLoadDFMValues;
  divEdit.BeforeLoadDFMValues;
  divNeu.BeforeLoadDFMValues;
  reText.BeforeLoadDFMValues;
  divImg.BeforeLoadDFMValues;
  WebHTMLDiv3.BeforeLoadDFMValues;
  WebHTMLDiv5.BeforeLoadDFMValues;
  btnHashTag.BeforeLoadDFMValues;
  divHashTags.BeforeLoadDFMValues;
  WebHTMLDiv4.BeforeLoadDFMValues;
  btnSave.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  WebHTMLDiv8.BeforeLoadDFMValues;
  edTitel.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frmBlogEdit';
    Width := 738;
    Height := 701;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCloseQuery', 'WebFormCloseQuery');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 8;
    WebHTMLDiv1.Top := 8;
    WebHTMLDiv1.Width := 721;
    WebHTMLDiv1.Height := 681;
    WebHTMLDiv1.ElementClassName := 'input_container';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv11.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv11.Name := 'WebHTMLDiv11';
    WebHTMLDiv11.Left := 26;
    WebHTMLDiv11.Top := 3;
    WebHTMLDiv11.Width := 671;
    WebHTMLDiv11.Height := 41;
    WebHTMLDiv11.ElementClassName := 'input_header';
    WebHTMLDiv11.HeightStyle := ssAuto;
    WebHTMLDiv11.WidthStyle := ssAuto;
    WebHTMLDiv11.ElementPosition := epRelative;
    WebHTMLDiv11.ElementFont := efCSS;
    WebHTMLDiv11.Role := '';
    btnClose.SetParentComponent(WebHTMLDiv11);
    btnClose.Name := 'btnClose';
    btnClose.Left := 616;
    btnClose.Top := -3;
    btnClose.Width := 46;
    btnClose.Height := 44;
    btnClose.ElementClassName := 'input_close';
    btnClose.HeightStyle := ssAuto;
    btnClose.WidthStyle := ssAuto;
    btnClose.HeightPercent := 100.000000000000000000;
    btnClose.WidthPercent := 100.000000000000000000;
    btnClose.Anchors := [];
    btnClose.ChildOrder := 1;
    btnClose.ElementFont := efCSS;
    btnClose.ElementPosition := epIgnore;
    SetEvent(btnClose, Self, 'OnClick', 'btnCancelClick');
    btnClose.Picture.LoadFromFile('uBlogEdit.WebHTMLDiv11.btnClose.Picture.png');
    lblTitel.SetParentComponent(WebHTMLDiv11);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 16;
    lblTitel.Top := 9;
    lblTitel.Width := 35;
    lblTitel.Height := 15;
    lblTitel.Caption := 'lblTitel';
    lblTitel.ElementLabelClassName := 'editor_titel';
    lblTitel.ElementFont := efCSS;
    lblTitel.HeightStyle := ssAuto;
    lblTitel.HeightPercent := 100.000000000000000000;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.WidthPercent := 100.000000000000000000;
    WebHTMLDiv6.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv6.Name := 'WebHTMLDiv6';
    WebHTMLDiv6.Left := 26;
    WebHTMLDiv6.Top := 50;
    WebHTMLDiv6.Width := 671;
    WebHTMLDiv6.Height := 41;
    WebHTMLDiv6.ElementClassName := 'kursedit_line';
    WebHTMLDiv6.HeightStyle := ssAuto;
    WebHTMLDiv6.WidthStyle := ssAuto;
    WebHTMLDiv6.ChildOrder := 1;
    WebHTMLDiv6.ElementPosition := epRelative;
    WebHTMLDiv6.ElementFont := efCSS;
    WebHTMLDiv6.Role := '';
    WebHTMLDiv7.SetParentComponent(WebHTMLDiv6);
    WebHTMLDiv7.Name := 'WebHTMLDiv7';
    WebHTMLDiv7.Left := 16;
    WebHTMLDiv7.Top := 0;
    WebHTMLDiv7.Width := 521;
    WebHTMLDiv7.Height := 41;
    WebHTMLDiv7.ElementClassName := 'blog-kategorie';
    WebHTMLDiv7.HeightStyle := ssAuto;
    WebHTMLDiv7.WidthStyle := ssAuto;
    WebHTMLDiv7.ChildOrder := 2;
    WebHTMLDiv7.ElementPosition := epIgnore;
    WebHTMLDiv7.ElementFont := efCSS;
    WebHTMLDiv7.Role := '';
    WebLabel1.SetParentComponent(WebHTMLDiv7);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 16;
    WebLabel1.Top := 17;
    WebLabel1.Width := 53;
    WebLabel1.Height := 15;
    WebLabel1.Caption := 'Kategorie:';
    WebLabel1.ElementLabelClassName := 'editor_titel';
    WebLabel1.ElementFont := efCSS;
    WebLabel1.ElementPosition := epIgnore;
    WebLabel1.HeightStyle := ssAuto;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthStyle := ssAuto;
    WebLabel1.WidthPercent := 100.000000000000000000;
    cbKategorie.SetParentComponent(WebHTMLDiv7);
    cbKategorie.Name := 'cbKategorie';
    cbKategorie.Left := 113;
    cbKategorie.Top := 15;
    cbKategorie.Width := 273;
    cbKategorie.Height := 23;
    cbKategorie.ChildOrder := 1;
    cbKategorie.ElementClassName := 'editor_combobox';
    cbKategorie.ElementFont := efCSS;
    cbKategorie.ElementPosition := epIgnore;
    cbKategorie.HeightStyle := ssAuto;
    cbKategorie.HeightPercent := 100.000000000000000000;
    cbKategorie.Text := 'cbKategorie';
    cbKategorie.WidthStyle := ssAuto;
    cbKategorie.WidthPercent := 100.000000000000000000;
    cbKategorie.ItemIndex := -1;
    btnLosGehts.SetParentComponent(WebHTMLDiv7);
    btnLosGehts.Name := 'btnLosGehts';
    btnLosGehts.Left := 436;
    btnLosGehts.Top := 6;
    btnLosGehts.Width := 85;
    btnLosGehts.Height := 35;
    btnLosGehts.Hint := 'klicken um die Bearbeitung zu starten';
    btnLosGehts.Caption := 'los geht'#39's';
    btnLosGehts.ChildOrder := 10;
    btnLosGehts.Color := clNone;
    btnLosGehts.ElementClassName := 'blog-btn';
    btnLosGehts.ElementFont := efCSS;
    btnLosGehts.ElementPosition := epRelative;
    btnLosGehts.HeightStyle := ssAuto;
    btnLosGehts.HeightPercent := 100.000000000000000000;
    btnLosGehts.MaterialGlyph := 'run_circle';
    btnLosGehts.MaterialGlyphSize := 35;
    btnLosGehts.ShowHint := True;
    btnLosGehts.TabOrder := 1;
    btnLosGehts.WidthStyle := ssAuto;
    btnLosGehts.WidthPercent := 100.000000000000000000;
    SetEvent(btnLosGehts, Self, 'OnClick', 'btnLosGehtsClick');
    divOben.SetParentComponent(WebHTMLDiv1);
    divOben.Name := 'divOben';
    divOben.Left := 26;
    divOben.Top := 97;
    divOben.Width := 671;
    divOben.Height := 152;
    divOben.ElementClassName := 'blog-oben';
    divOben.HeightStyle := ssAuto;
    divOben.WidthStyle := ssAuto;
    divOben.ChildOrder := 2;
    divOben.ElementPosition := epIgnore;
    divOben.ElementFont := efCSS;
    divOben.HTML.BeginUpdate;
    try
      divOben.HTML.Clear;
      divOben.HTML.Add('');
    finally
      divOben.HTML.EndUpdate;
    end;
    divOben.Role := '';
    divFoto.SetParentComponent(divOben);
    divFoto.Name := 'divFoto';
    divFoto.Left := 16;
    divFoto.Top := 8;
    divFoto.Width := 100;
    divFoto.Height := 41;
    divFoto.ElementClassName := 'blog-titel-foto';
    divFoto.HeightStyle := ssAuto;
    divFoto.WidthStyle := ssAuto;
    divFoto.ElementPosition := epIgnore;
    divFoto.ElementFont := efCSS;
    divFoto.HTML.BeginUpdate;
    try
      divFoto.HTML.Clear;
      divFoto.HTML.Add('Hier klicken um ein<br>Titel-Foto einzuf'#252'gen');
    finally
      divFoto.HTML.EndUpdate;
    end;
    divFoto.Role := '';
    SetEvent(divFoto, Self, 'OnClick', 'divFotoClick');
    WebHTMLDiv10.SetParentComponent(divOben);
    WebHTMLDiv10.Name := 'WebHTMLDiv10';
    WebHTMLDiv10.Left := 184;
    WebHTMLDiv10.Top := 8;
    WebHTMLDiv10.Width := 403;
    WebHTMLDiv10.Height := 138;
    WebHTMLDiv10.ElementClassName := 'blog-setup';
    WebHTMLDiv10.HeightStyle := ssAuto;
    WebHTMLDiv10.WidthStyle := ssAuto;
    WebHTMLDiv10.ChildOrder := 1;
    WebHTMLDiv10.ElementPosition := epIgnore;
    WebHTMLDiv10.ElementFont := efCSS;
    WebHTMLDiv10.HTML.BeginUpdate;
    try
      WebHTMLDiv10.HTML.Clear;
      WebHTMLDiv10.HTML.Add('');
    finally
      WebHTMLDiv10.HTML.EndUpdate;
    end;
    WebHTMLDiv10.Role := '';
    divSichtbar.SetParentComponent(WebHTMLDiv10);
    divSichtbar.Name := 'divSichtbar';
    divSichtbar.Left := 0;
    divSichtbar.Top := 3;
    divSichtbar.Width := 350;
    divSichtbar.Height := 55;
    divSichtbar.ElementClassName := 'blog-oben-edit-line';
    divSichtbar.HeightStyle := ssAuto;
    divSichtbar.WidthStyle := ssAuto;
    divSichtbar.ChildOrder := 1;
    divSichtbar.ElementPosition := epIgnore;
    divSichtbar.ElementFont := efCSS;
    divSichtbar.Role := '';
    WebLabel3.SetParentComponent(divSichtbar);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 17;
    WebLabel3.Top := 8;
    WebLabel3.Width := 61;
    WebLabel3.Height := 15;
    WebLabel3.Caption := 'Sichtbar f'#252'r';
    WebLabel3.ElementFont := efCSS;
    WebLabel3.ElementPosition := epIgnore;
    WebLabel3.HeightStyle := ssAuto;
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthStyle := ssAuto;
    WebLabel3.WidthPercent := 100.000000000000000000;
    cbSichtbar.SetParentComponent(divSichtbar);
    cbSichtbar.Name := 'cbSichtbar';
    cbSichtbar.Left := 20;
    cbSichtbar.Top := 29;
    cbSichtbar.Width := 273;
    cbSichtbar.Height := 23;
    cbSichtbar.ChildOrder := 1;
    cbSichtbar.ElementClassName := 'login_combobox';
    cbSichtbar.ElementFont := efCSS;
    cbSichtbar.ElementPosition := epIgnore;
    cbSichtbar.HeightStyle := ssAuto;
    cbSichtbar.HeightPercent := 100.000000000000000000;
    cbSichtbar.WidthStyle := ssAuto;
    cbSichtbar.WidthPercent := 100.000000000000000000;
    cbSichtbar.ItemIndex := -1;
    cbSichtbar.Items.BeginUpdate;
    try
      cbSichtbar.Items.Clear;
      cbSichtbar.Items.Add('jeden (inkl. Gast)');
      cbSichtbar.Items.Add('Mitglieder');
      cbSichtbar.Items.Add('Trainer');
      cbSichtbar.Items.Add('Admin');
    finally
      cbSichtbar.Items.EndUpdate;
    end;
    WebHTMLDiv12.SetParentComponent(WebHTMLDiv10);
    WebHTMLDiv12.Name := 'WebHTMLDiv12';
    WebHTMLDiv12.Left := 3;
    WebHTMLDiv12.Top := 64;
    WebHTMLDiv12.Width := 350;
    WebHTMLDiv12.Height := 57;
    WebHTMLDiv12.ElementClassName := 'blog-oben-edit-line';
    WebHTMLDiv12.HeightStyle := ssAuto;
    WebHTMLDiv12.WidthStyle := ssAuto;
    WebHTMLDiv12.ChildOrder := 1;
    WebHTMLDiv12.ElementPosition := epIgnore;
    WebHTMLDiv12.ElementFont := efCSS;
    WebHTMLDiv12.Role := '';
    WebLabel2.SetParentComponent(WebHTMLDiv12);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 14;
    WebLabel2.Top := 3;
    WebLabel2.Width := 68;
    WebLabel2.Height := 15;
    WebLabel2.Caption := 'Anzeigen ab:';
    WebLabel2.ElementFont := efCSS;
    WebLabel2.ElementPosition := epIgnore;
    WebLabel2.HeightStyle := ssAuto;
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthStyle := ssAuto;
    WebLabel2.WidthPercent := 100.000000000000000000;
    edSichtbar_ab.SetParentComponent(WebHTMLDiv12);
    edSichtbar_ab.Name := 'edSichtbar_ab';
    edSichtbar_ab.Left := 14;
    edSichtbar_ab.Top := 24;
    edSichtbar_ab.Width := 170;
    edSichtbar_ab.Height := 22;
    edSichtbar_ab.HeightStyle := ssAuto;
    edSichtbar_ab.WidthStyle := ssAuto;
    edSichtbar_ab.BorderStyle := bsSingle;
    edSichtbar_ab.ChildOrder := 1;
    edSichtbar_ab.Color := clWhite;
    edSichtbar_ab.Date := 45441.348805590280000000;
    edSichtbar_ab.ElementFont := efCSS;
    edSichtbar_ab.ElementPosition := epIgnore;
    edSichtbar_ab.Role := '';
    edSichtbar_ab.Text := '';
    divHeader.SetParentComponent(WebHTMLDiv1);
    divHeader.Name := 'divHeader';
    divHeader.Left := 20;
    divHeader.Top := 327;
    divHeader.Width := 671;
    divHeader.Height := 42;
    divHeader.ElementClassName := 'kursedit_line';
    divHeader.HeightStyle := ssAuto;
    divHeader.WidthStyle := ssAuto;
    divHeader.ChildOrder := 4;
    divHeader.ElementPosition := epRelative;
    divHeader.ElementFont := efCSS;
    divHeader.Role := '';
    WebHTMLDiv2.SetParentComponent(divHeader);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 207;
    WebHTMLDiv2.Top := 0;
    WebHTMLDiv2.Width := 440;
    WebHTMLDiv2.Height := 32;
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ChildOrder := 5;
    WebHTMLDiv2.ElementPosition := epIgnore;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.Role := '';
    btn1.SetParentComponent(WebHTMLDiv2);
    btn1.Name := 'btn1';
    btn1.Left := 3;
    btn1.Top := 3;
    btn1.Width := 33;
    btn1.Height := 24;
    btn1.Hint := 'neue Zeile ohnen Einteilung';
    btn1.Caption := '1';
    btn1.Color := clNone;
    btn1.ElementClassName := 'blog-btn';
    btn1.ElementFont := efCSS;
    btn1.ElementPosition := epRelative;
    btn1.HeightStyle := ssAuto;
    btn1.HeightPercent := 100.000000000000000000;
    btn1.ShowHint := True;
    btn1.TabOrder := 0;
    btn1.WidthStyle := ssAuto;
    btn1.WidthPercent := 100.000000000000000000;
    SetEvent(btn1, Self, 'OnClick', 'btn1Click');
    btn2.SetParentComponent(WebHTMLDiv2);
    btn2.Name := 'btn2';
    btn2.Left := 42;
    btn2.Top := 3;
    btn2.Width := 33;
    btn2.Height := 24;
    btn2.Hint := 'neue Zeile mit 2 Spalten';
    btn2.Caption := '2';
    btn2.ChildOrder := 2;
    btn2.Color := clNone;
    btn2.ElementClassName := 'blog-btn';
    btn2.ElementFont := efCSS;
    btn2.ElementPosition := epRelative;
    btn2.HeightStyle := ssAuto;
    btn2.HeightPercent := 100.000000000000000000;
    btn2.ShowHint := True;
    btn2.TabOrder := 1;
    btn2.WidthStyle := ssAuto;
    btn2.WidthPercent := 100.000000000000000000;
    SetEvent(btn2, Self, 'OnClick', 'btn1Click');
    btn3.SetParentComponent(WebHTMLDiv2);
    btn3.Name := 'btn3';
    btn3.Left := 81;
    btn3.Top := 3;
    btn3.Width := 33;
    btn3.Height := 24;
    btn3.Hint := 'Neue Zeile mit 3 Spalten';
    btn3.Caption := '3';
    btn3.ChildOrder := 2;
    btn3.Color := clNone;
    btn3.ElementClassName := 'blog-btn';
    btn3.ElementFont := efCSS;
    btn3.ElementPosition := epRelative;
    btn3.HeightStyle := ssAuto;
    btn3.HeightPercent := 100.000000000000000000;
    btn3.ShowHint := True;
    btn3.TabOrder := 2;
    btn3.WidthStyle := ssAuto;
    btn3.WidthPercent := 100.000000000000000000;
    SetEvent(btn3, Self, 'OnClick', 'btn1Click');
    btn4.SetParentComponent(WebHTMLDiv2);
    btn4.Name := 'btn4';
    btn4.Left := 120;
    btn4.Top := 3;
    btn4.Width := 33;
    btn4.Height := 24;
    btn4.Hint := 'Neue Zeile mit 4 Spalten';
    btn4.Caption := '4';
    btn4.ChildOrder := 4;
    btn4.Color := clNone;
    btn4.ElementClassName := 'blog-btn';
    btn4.ElementFont := efCSS;
    btn4.ElementPosition := epRelative;
    btn4.HeightStyle := ssAuto;
    btn4.HeightPercent := 100.000000000000000000;
    btn4.ShowHint := True;
    btn4.TabOrder := 3;
    btn4.WidthStyle := ssAuto;
    btn4.WidthPercent := 100.000000000000000000;
    SetEvent(btn4, Self, 'OnClick', 'btn1Click');
    btnRezept.SetParentComponent(WebHTMLDiv2);
    btnRezept.Name := 'btnRezept';
    btnRezept.Left := 159;
    btnRezept.Top := 3;
    btnRezept.Width := 21;
    btnRezept.Height := 24;
    btnRezept.Caption := 'Rezept';
    btnRezept.ChildOrder := 5;
    btnRezept.Color := clNone;
    btnRezept.ElementClassName := 'blog-btn';
    btnRezept.ElementFont := efCSS;
    btnRezept.ElementPosition := epRelative;
    btnRezept.HeightStyle := ssAuto;
    btnRezept.HeightPercent := 100.000000000000000000;
    btnRezept.TabOrder := 4;
    btnRezept.Visible := False;
    btnRezept.WidthStyle := ssAuto;
    btnRezept.WidthPercent := 100.000000000000000000;
    SetEvent(btnRezept, Self, 'OnClick', 'btnRezeptClick');
    btnHeader.SetParentComponent(WebHTMLDiv2);
    btnHeader.Name := 'btnHeader';
    btnHeader.Left := 186;
    btnHeader.Top := 3;
    btnHeader.Width := 21;
    btnHeader.Height := 24;
    btnHeader.Caption := #220'berschrift';
    btnHeader.ChildOrder := 6;
    btnHeader.Color := clNone;
    btnHeader.ElementClassName := 'blog-btn';
    btnHeader.ElementFont := efCSS;
    btnHeader.ElementPosition := epRelative;
    btnHeader.HeightStyle := ssAuto;
    btnHeader.HeightPercent := 100.000000000000000000;
    btnHeader.TabOrder := 5;
    btnHeader.Visible := False;
    btnHeader.WidthStyle := ssAuto;
    btnHeader.WidthPercent := 100.000000000000000000;
    SetEvent(btnHeader, Self, 'OnClick', 'btnHeaderClick');
    btnAnmerkung.SetParentComponent(WebHTMLDiv2);
    btnAnmerkung.Name := 'btnAnmerkung';
    btnAnmerkung.Left := 213;
    btnAnmerkung.Top := 3;
    btnAnmerkung.Width := 82;
    btnAnmerkung.Height := 24;
    btnAnmerkung.Hint := 'klicken um Baustein f'#252'r Anmerkungen zu erstellen...';
    btnAnmerkung.ChildOrder := 7;
    btnAnmerkung.Color := clNone;
    btnAnmerkung.ElementClassName := 'blog-btn';
    btnAnmerkung.ElementFont := efCSS;
    btnAnmerkung.ElementPosition := epRelative;
    btnAnmerkung.HeightStyle := ssAuto;
    btnAnmerkung.HeightPercent := 100.000000000000000000;
    btnAnmerkung.MaterialGlyph := 'add_comment';
    btnAnmerkung.MaterialGlyphSize := 35;
    btnAnmerkung.ShowHint := True;
    btnAnmerkung.TabOrder := 6;
    btnAnmerkung.WidthStyle := ssAuto;
    btnAnmerkung.WidthPercent := 100.000000000000000000;
    SetEvent(btnAnmerkung, Self, 'OnClick', 'btnAnmerkungClick');
    btnVorschau.SetParentComponent(WebHTMLDiv2);
    btnVorschau.Name := 'btnVorschau';
    btnVorschau.Left := 301;
    btnVorschau.Top := 3;
    btnVorschau.Width := 85;
    btnVorschau.Height := 24;
    btnVorschau.Hint := 'wechselt zwischen Vorschau- und Eingabe-Modus';
    btnVorschau.ChildOrder := 8;
    btnVorschau.Color := clNone;
    btnVorschau.ElementClassName := 'blog-btn';
    btnVorschau.ElementFont := efCSS;
    btnVorschau.ElementPosition := epRelative;
    btnVorschau.HeightStyle := ssAuto;
    btnVorschau.HeightPercent := 100.000000000000000000;
    btnVorschau.MaterialGlyph := 'edit';
    btnVorschau.MaterialGlyphSize := 35;
    btnVorschau.ShowHint := True;
    btnVorschau.TabOrder := 7;
    btnVorschau.WidthStyle := ssAuto;
    btnVorschau.WidthPercent := 100.000000000000000000;
    SetEvent(btnVorschau, Self, 'OnClick', 'btnVorschauClick');
    EditButtons.SetParentComponent(divHeader);
    EditButtons.Name := 'EditButtons';
    EditButtons.Left := 3;
    EditButtons.Top := 4;
    EditButtons.Width := 222;
    EditButtons.Height := 35;
    EditButtons.ElementClassName := 'editbuttons';
    EditButtons.HeightStyle := ssAuto;
    EditButtons.WidthStyle := ssAuto;
    EditButtons.Color := clBtnFace;
    EditButtons.ElementFont := efCSS;
    EditButtons.ElementPosition := epRelative;
    EditButtons.Hints.BeginUpdate;
    try
      EditButtons.Hints.Clear;
      EditButtons.Hints.Add('Bold');
      EditButtons.Hints.Add('Italic');
      EditButtons.Hints.Add('Underline');
      EditButtons.Hints.Add('Strike Throught');
      EditButtons.Hints.Add('Text Color');
      EditButtons.Hints.Add('Background Color');
      EditButtons.Hints.Add('Align lef');
      EditButtons.Hints.Add('Align center');
      EditButtons.Hints.Add('Align right');
      EditButtons.Hints.Add('Numbered list');
      EditButtons.Hints.Add('List');
    finally
      EditButtons.Hints.EndUpdate;
    end;
    EditButtons.RichEdit := reText;
    EditButtons.VisibleButtons := [reBold,reItalic,reUnderline,reHyperlink];
    divEdit.SetParentComponent(WebHTMLDiv1);
    divEdit.Name := 'divEdit';
    divEdit.Left := 20;
    divEdit.Top := 377;
    divEdit.Width := 671;
    divEdit.Height := 54;
    divEdit.ElementClassName := 'blog_input';
    divEdit.HeightStyle := ssAuto;
    divEdit.WidthStyle := ssAuto;
    divEdit.ChildOrder := 5;
    divEdit.ElementPosition := epIgnore;
    divEdit.ElementFont := efCSS;
    divEdit.Role := '';
    divNeu.SetParentComponent(divEdit);
    divNeu.Name := 'divNeu';
    divNeu.Left := 278;
    divNeu.Top := 9;
    divNeu.Width := 100;
    divNeu.Height := 41;
    divNeu.ElementClassName := 'help-div';
    divNeu.HeightStyle := ssAuto;
    divNeu.WidthStyle := ssAuto;
    divNeu.ElementPosition := epRelative;
    divNeu.ElementFont := efCSS;
    divNeu.HTML.BeginUpdate;
    try
      divNeu.HTML.Clear;
      divNeu.HTML.Add('<B>Kurzanleitung:</B>');
      divNeu.HTML.Add('');
      divNeu.HTML.Add('');
      divNeu.HTML.Add('<OL>');
      divNeu.HTML.Add('<LI>Kategorie w'#228'hlen: Klicke auf die gew'#252'nschte Kategorie und dann auf den Button  <i class="mdi mdi-run-circle"></i>  daneben.');
      divNeu.HTML.Add('<LI>Titelfoto ausw'#228'hlen: W'#228'hle ein Foto aus, das in Vorschauen und anderen Bereichen verwendet wird.');
      divNeu.HTML.Add('<LI>Blog-Titel eingeben: Gebe einen Titel ein der in Vorschauen und anderen Bereichen verwendet wird. ');
      divNeu.HTML.Add('<LI>Bereiche anlegen: Je nach Kategorie klicke auf Button 1, 2, 3 oder 4, um einen Bereich mit entsprechender Spaltenzahl zu erstellen.');
      divNeu.HTML.Add('<LI>Text oder Foto hinzuf'#252'gen: Klicke auf einen der durch Punkt 4 erzeugten Bereiche, um deinen Text zu bearbeiten oder ein Foto auszuw'#228'hlen.');
      divNeu.HTML.Add('<LI>Wiederholen: Wiederhole Schritt 4 beliebig oft, um weitere Bereiche hinzuzuf'#252'gen.');
      divNeu.HTML.Add('<LI>Bereiche verschieben: klicke auf Pfeil-Hoch bzw. Runter um einen Bereich zu verschieben.');
      divNeu.HTML.Add('<LI>Bereich einf'#252'gen: Wiederhole Schritt 3. Ein neuer Beitrag wird am Ende deines Beitragen angef'#252'gt. Verschiebe dann den Beitrag an die gew'#252'nschte Stelle.');
      divNeu.HTML.Add('<LI>Suchbegriffe zuweisen: Im unteren Bereich w'#228'hle den Button "Suchbegriffe zuweisen" aus und w'#228'hle passende Suchbegriffe f'#252'r deinen Beitrag ');
      divNeu.HTML.Add('aus oder lege neue an.');
      divNeu.HTML.Add('<LI>Um dir eine Vorschau deines Beitrages anzusehen, klicke einfach auf den Button mit dem <i class="mdi mdi-eye"></i> - Symbol, beim ');
      divNeu.HTML.Add('klicken auf das Stift-Symbol <i class="mdi mdi-eye"></i> - Symbol gelangst du wieder in den Eingaben-Modus');
      divNeu.HTML.Add('</OL>');
      divNeu.HTML.Add('');
      divNeu.HTML.Add('<B>Das war'#39's! Damit bist du bereit, Beitr'#228'ge zu erstellen, die von anderen Mitgliedern gut gefunden werden k'#246'nnen.</B>');
    finally
      divNeu.HTML.EndUpdate;
    end;
    divNeu.Role := '';
    reText.SetParentComponent(WebHTMLDiv1);
    reText.Name := 'reText';
    reText.Left := 20;
    reText.Top := 433;
    reText.Width := 671;
    reText.Height := 30;
    reText.ElementClassName := 'blog_input';
    reText.HeightStyle := ssAuto;
    reText.WidthStyle := ssAuto;
    reText.BorderStyle := bsSingle;
    reText.ChildOrder := 6;
    reText.Color := clWhite;
    reText.Visible := False;
    divImg.SetParentComponent(WebHTMLDiv1);
    divImg.Name := 'divImg';
    divImg.Left := 23;
    divImg.Top := 469;
    divImg.Width := 671;
    divImg.Height := 38;
    divImg.ElementClassName := 'blog-edit-images-div';
    divImg.HeightStyle := ssAuto;
    divImg.WidthStyle := ssAuto;
    divImg.ChildOrder := 7;
    divImg.ElementPosition := epIgnore;
    divImg.ElementFont := efCSS;
    divImg.Role := '';
    WebHTMLDiv3.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv3.Name := 'WebHTMLDiv3';
    WebHTMLDiv3.Left := 23;
    WebHTMLDiv3.Top := 513;
    WebHTMLDiv3.Width := 671;
    WebHTMLDiv3.Height := 106;
    WebHTMLDiv3.ElementClassName := 'blog-hashtag-wrapper';
    WebHTMLDiv3.HeightStyle := ssAuto;
    WebHTMLDiv3.WidthStyle := ssAuto;
    WebHTMLDiv3.ChildOrder := 8;
    WebHTMLDiv3.ElementPosition := epIgnore;
    WebHTMLDiv3.ElementFont := efCSS;
    WebHTMLDiv3.Role := '';
    WebHTMLDiv5.SetParentComponent(WebHTMLDiv3);
    WebHTMLDiv5.Name := 'WebHTMLDiv5';
    WebHTMLDiv5.Left := 3;
    WebHTMLDiv5.Top := 3;
    WebHTMLDiv5.Width := 494;
    WebHTMLDiv5.Height := 38;
    WebHTMLDiv5.ElementClassName := 'blog-hashtag-titel';
    WebHTMLDiv5.HeightStyle := ssAuto;
    WebHTMLDiv5.WidthStyle := ssAuto;
    WebHTMLDiv5.ElementPosition := epIgnore;
    WebHTMLDiv5.ElementFont := efCSS;
    WebHTMLDiv5.Role := '';
    SetEvent(WebHTMLDiv5, Self, 'OnClick', 'lblHashTagsClick');
    btnHashTag.SetParentComponent(WebHTMLDiv5);
    btnHashTag.Name := 'btnHashTag';
    btnHashTag.Left := 13;
    btnHashTag.Top := 10;
    btnHashTag.Width := 144;
    btnHashTag.Height := 25;
    btnHashTag.Caption := 'Suchbegriffe zuweisen';
    btnHashTag.ChildOrder := 1;
    btnHashTag.ElementClassName := 'blog-hashtag-button';
    btnHashTag.ElementFont := efCSS;
    btnHashTag.ElementPosition := epIgnore;
    btnHashTag.HeightStyle := ssAuto;
    btnHashTag.HeightPercent := 100.000000000000000000;
    btnHashTag.WidthStyle := ssAuto;
    btnHashTag.WidthPercent := 100.000000000000000000;
    SetEvent(btnHashTag, Self, 'OnClick', 'lblHashTagsClick');
    divHashTags.SetParentComponent(WebHTMLDiv3);
    divHashTags.Name := 'divHashTags';
    divHashTags.Left := 3;
    divHashTags.Top := 45;
    divHashTags.Width := 490;
    divHashTags.Height := 56;
    divHashTags.ElementClassName := 'blog-hashtag-div';
    divHashTags.HeightStyle := ssAuto;
    divHashTags.WidthStyle := ssAuto;
    divHashTags.ChildOrder := 1;
    divHashTags.ElementPosition := epIgnore;
    divHashTags.ElementFont := efCSS;
    divHashTags.Role := '';
    WebHTMLDiv4.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv4.Name := 'WebHTMLDiv4';
    WebHTMLDiv4.Left := 23;
    WebHTMLDiv4.Top := 625;
    WebHTMLDiv4.Width := 671;
    WebHTMLDiv4.Height := 41;
    WebHTMLDiv4.ElementClassName := 'editor-footer-div';
    WebHTMLDiv4.HeightStyle := ssAuto;
    WebHTMLDiv4.WidthStyle := ssAuto;
    WebHTMLDiv4.ChildOrder := 9;
    WebHTMLDiv4.ElementPosition := epIgnore;
    WebHTMLDiv4.ElementFont := efCSS;
    WebHTMLDiv4.Role := '';
    btnSave.SetParentComponent(WebHTMLDiv4);
    btnSave.Name := 'btnSave';
    btnSave.Left := 9;
    btnSave.Top := 3;
    btnSave.Width := 144;
    btnSave.Height := 25;
    btnSave.Caption := 'Speichern';
    btnSave.ChildOrder := 1;
    btnSave.ElementClassName := 'start_button-lets-go';
    btnSave.ElementFont := efCSS;
    btnSave.ElementPosition := epIgnore;
    btnSave.HeightStyle := ssAuto;
    btnSave.HeightPercent := 100.000000000000000000;
    btnSave.WidthStyle := ssAuto;
    btnSave.WidthPercent := 100.000000000000000000;
    SetEvent(btnSave, Self, 'OnClick', 'btnSaveClick');
    btnCancel.SetParentComponent(WebHTMLDiv4);
    btnCancel.Name := 'btnCancel';
    btnCancel.Left := 159;
    btnCancel.Top := 5;
    btnCancel.Width := 144;
    btnCancel.Height := 25;
    btnCancel.Caption := 'Abbrechen';
    btnCancel.ChildOrder := 2;
    btnCancel.ElementClassName := 'start_button-lets-go';
    btnCancel.ElementFont := efCSS;
    btnCancel.ElementPosition := epIgnore;
    btnCancel.HeightStyle := ssAuto;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthStyle := ssAuto;
    btnCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCancel, Self, 'OnClick', 'btnCancelClick');
    WebHTMLDiv8.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv8.Name := 'WebHTMLDiv8';
    WebHTMLDiv8.Left := 23;
    WebHTMLDiv8.Top := 278;
    WebHTMLDiv8.Width := 671;
    WebHTMLDiv8.Height := 43;
    WebHTMLDiv8.ElementClassName := 'kursedit_line';
    WebHTMLDiv8.HeightStyle := ssAuto;
    WebHTMLDiv8.WidthStyle := ssAuto;
    WebHTMLDiv8.ChildOrder := 3;
    WebHTMLDiv8.ElementPosition := epIgnore;
    WebHTMLDiv8.ElementFont := efCSS;
    WebHTMLDiv8.HTML.BeginUpdate;
    try
      WebHTMLDiv8.HTML.Clear;
      WebHTMLDiv8.HTML.Add('');
    finally
      WebHTMLDiv8.HTML.EndUpdate;
    end;
    WebHTMLDiv8.Role := '';
    SetEvent(WebHTMLDiv8, Self, 'OnClick', 'divFotoClick');
    edTitel.SetParentComponent(WebHTMLDiv8);
    edTitel.Name := 'edTitel';
    edTitel.Left := 12;
    edTitel.Top := 0;
    edTitel.Width := 121;
    edTitel.Height := 22;
    edTitel.ElementFont := efCSS;
    edTitel.ElementPosition := epIgnore;
    edTitel.HeightStyle := ssAuto;
    edTitel.HeightPercent := 100.000000000000000000;
    edTitel.ShowHint := True;
    edTitel.TextHint := 'hier Blog-Titel eingeben...';
    edTitel.WidthStyle := ssAuto;
    edTitel.WidthPercent := 100.000000000000000000;
    SetEvent(edTitel, Self, 'OnKeyDown', 'edTitelKeyDown');
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 24;
    Client.Top := 16;
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    WebHTMLDiv11.AfterLoadDFMValues;
    btnClose.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    WebHTMLDiv6.AfterLoadDFMValues;
    WebHTMLDiv7.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    cbKategorie.AfterLoadDFMValues;
    btnLosGehts.AfterLoadDFMValues;
    divOben.AfterLoadDFMValues;
    divFoto.AfterLoadDFMValues;
    WebHTMLDiv10.AfterLoadDFMValues;
    divSichtbar.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    cbSichtbar.AfterLoadDFMValues;
    WebHTMLDiv12.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    edSichtbar_ab.AfterLoadDFMValues;
    divHeader.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    btn1.AfterLoadDFMValues;
    btn2.AfterLoadDFMValues;
    btn3.AfterLoadDFMValues;
    btn4.AfterLoadDFMValues;
    btnRezept.AfterLoadDFMValues;
    btnHeader.AfterLoadDFMValues;
    btnAnmerkung.AfterLoadDFMValues;
    btnVorschau.AfterLoadDFMValues;
    EditButtons.AfterLoadDFMValues;
    divEdit.AfterLoadDFMValues;
    divNeu.AfterLoadDFMValues;
    reText.AfterLoadDFMValues;
    divImg.AfterLoadDFMValues;
    WebHTMLDiv3.AfterLoadDFMValues;
    WebHTMLDiv5.AfterLoadDFMValues;
    btnHashTag.AfterLoadDFMValues;
    divHashTags.AfterLoadDFMValues;
    WebHTMLDiv4.AfterLoadDFMValues;
    btnSave.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
    WebHTMLDiv8.AfterLoadDFMValues;
    edTitel.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.
