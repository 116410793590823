unit uStart;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.WebCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, utb, ufrAngebot, XData.Web.Client, uCrypt, System.DateUtils,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, Vcl.Menus, WEBLib.Menus,
  Vcl.Imaging.jpeg, WEBLib.EditAutocomplete, uGlobal;

type
  TfrmStart = class(TForm)
    WebHTMLDiv1: THTMLDiv;
    divStart: THTMLDiv;
    divAngebote: THTMLDiv;
    Client: TXDataWebClient;

    divBack: THTMLDiv;
    btnGotoLogin: TButton;
    divRegisterInfo: THTMLDiv;
    WebHTMLDiv2: THTMLDiv;
    WebHTMLDiv16: THTMLDiv;
    btnBack2Login: TButton;
    divByeBye1: THTMLDiv;
    WebHTMLDiv19: THTMLDiv;
    WebButton1: TButton;
    WebHTMLDiv9: THTMLDiv;
    divStartText: THTMLDiv;
    pLogin: THTMLDiv;
    WebHTMLDiv3: THTMLDiv;
    WebHTMLDiv5: THTMLDiv;
    divError1: THTMLDiv;
    edName1: TEdit;
    edPasswort5: TEdit;
    WebHTMLDiv87: THTMLDiv;
    chkInfoGruppen: TCheckBox;
    WebHTMLDiv82: THTMLDiv;
    WebLabel30: TLabel;
    WebHTMLDiv6: THTMLDiv;
    btnLogin1: TButton;
    pNew: THTMLDiv;
    WebHTMLDiv4: THTMLDiv;
    WebHTMLDiv7: THTMLDiv;
    WebHTMLDiv13: THTMLDiv;
    WebHTMLDiv8: THTMLDiv;
    btnRegistrieren: TButton;
    divNurMalGucken: THTMLDiv;
    WebHTMLDiv10: THTMLDiv;
    WebHTMLDiv11: THTMLDiv;
    edVorname: TEdit;
    edNachname: TEdit;
    edEmail: TEdit;
    WebHTMLDiv12: THTMLDiv;
    btnGast: TButton;
    WebHTMLDiv15: THTMLDiv;
    WebImageControl1: TImageControl;
    divstartseite: THTMLDiv;
    divLanding: THTMLDiv;
    divLosgehts: THTMLDiv;
    WebHTMLDiv17: THTMLDiv;
    WebHTMLDiv20: THTMLDiv;
    divGruende: THTMLDiv;
    WebHTMLDiv22: THTMLDiv;
    divGruende1: THTMLDiv;
    divMenue: THTMLDiv;
    mnuMainLeft: TMainMenu;
    SeglerPortalde1: TMenuItem;
    miGuteGruende: TMenuItem;
    miPreise: TMenuItem;
    WebHTMLDiv30: THTMLDiv;
    divMenuLeft: THTMLDiv;
    WebHTMLDiv31: THTMLDiv;
    WebHTMLDiv21: THTMLDiv;
    imgLogo: TImageControl;
    divGruende2: THTMLDiv;
    imgGruende1: TImageControl;
    WebHTMLDiv27: THTMLDiv;
    imgGruende2: TImageControl;
    WebHTMLDiv29: THTMLDiv;
    WebHTMLDiv25: THTMLDiv;
    WebHTMLDiv32: THTMLDiv;
    imgGruende3: TImageControl;
    WebHTMLDiv33: THTMLDiv;
    WebHTMLDiv34: THTMLDiv;
    imgGruende4: TImageControl;
    WebHTMLDiv35: THTMLDiv;
    WebHTMLDiv36: THTMLDiv;
    imgGruende5: TImageControl;
    WebHTMLDiv37: THTMLDiv;
    WebHTMLDiv38: THTMLDiv;
    imgGruende6: TImageControl;
    WebHTMLDiv39: THTMLDiv;
    divPreise: THTMLDiv;
    divPakete: THTMLDiv;
    WebHTMLDiv55: THTMLDiv;
    divLogin: THTMLDiv;
    divLogin1: THTMLDiv;
    WebHTMLDiv42: THTMLDiv;
    WebHTMLDiv43: THTMLDiv;
    WebButton6: TButton;
    WebHTMLDiv44: THTMLDiv;
    WebLabel15: TLabel;
    WebHTMLDiv45: THTMLDiv;
    WebLabel1: TLabel;
    WebHTMLDiv83: THTMLDiv;
    edPasswort: TEdit;
    WebHTMLDiv46: THTMLDiv;
    WebHTMLDiv47: THTMLDiv;
    lblNewPassword: TLabel;
    WebHTMLDiv48: THTMLDiv;
    WebLabel3: TLabel;
    divError: THTMLDiv;
    edName: TEdit;
    divByeBye: THTMLDiv;
    divByeByeX: THTMLDiv;
    WebHTMLDiv41: THTMLDiv;
    WebHTMLDiv49: THTMLDiv;
    WebButton3: TButton;
    divWartung: THTMLDiv;
    WebHTMLDiv18: THTMLDiv;
    WebHTMLDiv23: THTMLDiv;
    WebHTMLDiv24: THTMLDiv;
    WebButton4: TButton;
    miLosGehts: TButton;
    btnShowLoginDiv: TButton;
    WebHTMLDiv14: THTMLDiv;
    WebButton2: TButton;
    WebButton5: TButton;
    WebButton7: TButton;
    WebButton8: TButton;
    WebButton9: TButton;
    WebHTMLDiv26: THTMLDiv;
    WebButton12: TButton;
    WebButton13: TButton;
    Login1: TMenuItem;
    procedure edName1KeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    procedure chkInfoGruppenClick(Sender: TObject);
    procedure miGuteGruendeClick(Sender: TObject);
    procedure miPreiseClick(Sender: TObject);
    procedure btnShowLoginDivClick(Sender: TObject);
    procedure miLosGehtsClick(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure edPasswortKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
    [async] procedure  lblNewPasswordClick(Sender: TObject); async;
    [async] procedure btnRegistrierenClick(Sender: TObject); async;
    [async] procedure btnLoginClick(Sender: TObject); async;
    [async] procedure WebFormCreate(Sender: TObject); async;
    [async] procedure btnGastClick(Sender: TObject); async;
    [async] procedure CreatePakete; async;
    [async] procedure NeuesPasswortEMail(AData:TEMaiLData); async;
    procedure SeglerPortalde1Click(Sender: TObject);
  private
    FAktion:string;
  public
    [async] class function Login(AId:integer; AName, APasswort:string; AProc: TFormCreatedProc=nil):integer; async;
    [async] class function DirektLogin(AID:integer):integer; async;
    [async] class function CreateGast(AName, AVorname, AEmail:string):integer; async;
    property Aktion: string read FAktion write FAktion;
    procedure ShowRegisterInfo;
    procedure ShowByeBye;
  protected procedure LoadDFMValues; override; end;

var
  frmStart: TfrmStart;

implementation
   uses uDatenmodul, uMain, uMeldung;
{$R *.dfm}

class function TfrmStart.CreateGast(AName, AVorname, AEmail:string):integer;
Var
  j:TJ;
  m:TME;
begin
  J := TJ.create(await(datenmodul.EasySQL('Select * from v_user Where ID = 45')));
  m := TME.create;
  m.id        := J.Integer('ID');
  m.Name      := j.Value('NAME');
  m.Vorname   := j.Value('VORNAME');
  m.Ort       := j.Value('ORT');
  m.BLand     := j.Value('BLAND');
  m.About     := j.Value('ABOUT');
  m.Hobbies   := j.Value('HOBBIES');
  m.Reviere   := j.Value('REVIERE');
  m.Gast      := j.Integer('GAST');
  m.Rechte.ID := j.Integer('RECHTE_ID');
  m.Sichtbar  := 2;
  m.Avatar    := j.Integer('AVATAR_MEDIEN_ID');
  m.Album     := j.Integer('ALBUM_ID');
  m.Titel     := j.Integer('TITEL_MEDIEN_ID');
exit;
//  j.Response := await(datenmodul.EasySQL('insert into User ( ALBUM_ID, AVATAR_MEDIEN_ID, TITEL_MEDIEN_ID) ' +
//                                          '  VALUES(' + j.Value('ALBUM_ID') + ',' +
//                                                        j.Value('AVATAR_MEDIEN_ID') + ',' +
//                                                        j.Value('TITEL_MEDIEN_ID') + ')' ));


  await(datenmodul.EasyInsert('insert into Person (GAST, RECHTE_ID, HOBBIES, REVIERE, ABOUT, NAME, VORNAME, EMAIL, NICKNAME, PASSWORD, ALBUM_ID, AVATAR_MEDIEN_ID, TITEL_MEDIEN_ID) ' +
                                       '  VALUES(' + m.Gast.ToString + ',' +
                                                     m.Rechte.ID.ToString + ',' +
                                                     '''' + m.Hobbies + ''',' +
                                                     '''' + m.Reviere + ''',' +
                                                     '''' + m.About + ''',' +
                                                     '''' + aName + ''',' +
                                                     '''' + aVorname + ''',' +
                                                     '''' + aEMail + ''',' +
                                                     '''' + aVorname + aName + ''',' +
                                                     '''' + 'XYZ' + '''' +
                                                     j.Value('ALBUM_ID') + ',' +
                                                     j.Value('AVATAR_MEDIEN_ID') + ',' +
                                                     j.Value('TITEL_MEDIEN_ID') + ')'));
  result := J.Integer('ID');
  j.Free;
end;


class function TfrmStart.DirektLogin(AID:integer):integer;
begin
//  AID := await(TfrmStart.CreateGast('Gast','','Email@email.de'));
//  AId := await(TfrmStart.Login(AId, '',''));
//  if AId > 0
//  then begin
//    Mainform.Start(AId);
//   // datenmodul.System.init;
//  end;
end;


procedure TfrmStart.edName1KeyDown(Sender: TObject; var Key: Word;
  Shift: TShiftState);
begin
  if (key = vk_F2) and (ssCtrl in Shift)
  then begin
    edName.Text     := 'thbordihn@Yahoo.com';
    edPasswort.Text := 'SEjonjon66';
    btnloginclick(sender);
    key:=0;
  end;
  if (key = vk_F3) and (ssCtrl in Shift)
  then begin
    edName.Text     := 'dicke@sporedo.de';
    edPasswort.Text := 'Luna44265!';
    btnloginclick(sender);
    key:=0;
  end;

end;

procedure TfrmStart.edPasswortKeyDown(Sender: TObject; var Key: Word; Shift: TShiftState);
begin
  if key = vk_Return
  then btnLoginClick(self)
end;

class function TfrmStart.Login(AId:integer; AName, APasswort:string; AProc: TFormCreatedProc=nil):integer;

//  procedure CBMeldung(A:array of string);
//  begin
//    if a[0] = '1'
//    then Mainform.miMeinProfilClick(Mainform);
//  end;

var
  j:TJ;
begin

  result := -1;
//  await(datenmodul.Connection.OpenAsync);
  if (AName > '') and (APasswort > '')
  then J := TJ.create(await(datenmodul.EasySQL('Select * from v_user Where upper(EMAIL) = ''' + Uppercase(AName) + ''' and PASSWORD = ''' + APasswort + '''')))
  else  if (AId > 0)
        then J := TJ.create(await(datenmodul.EasySQL('Select * from v_user Where ID = ' + AID.ToString)));

  if J.Integer('ID') > 0
  then begin
    Mainform.ME.id       := J.Integer('ID');
    Mainform.ME.Name     := j.Value('NAME');
    Mainform.ME.Vorname  := j.Value('VORNAME');
    Mainform.ME.Nickname := j.Value('NICKNAME');
    Mainform.ME.Ort      := j.Value('ORT');
    Mainform.ME.BLand    := j.Value('BLAND');
    Mainform.ME.Avatar   := j.Integer('AVATAR_MEDIEN_ID');
    Mainform.ME.Album    := j.Integer('ALBUM_ID');
    Mainform.ME.Titel    := j.Integer('TITEL_MEDIEN_ID');
    mainform.ME.Gast     := j.Integer('GAST');
    Mainform.ME.Rechte.ID:= j.integer('RECHTE_ID');
    Mainform.ME.Typ      := j.Integer('TYP');
    Mainform.ME.Anrede   := j.Integer('ANREDE_ID');
    Mainform.ME.Status   := j.Integer('STATUS_ID');
    Mainform.ME.Admin    := j.isTrue('ADMIN');
    Mainform.ME.Trainer  := j.isTrue('TRAINER');
    Mainform.ME.Organizer:= j.isTrue('ORGANIZER');

    //Wenn keine Fotos eingestellt wurden kann man auch keine sehen
    j.Response := await(datenmodul.EasySQL('Select * from System'));

    if (mainform.ME.Avatar = j.integer('AVATAR_MEDIEN_ID')) or
       (mainform.ME.Titel  = j.integer('TITEL_MEDIEN_ID'))
    then begin
      mainform.ME.BlurFoto := 14;
      mainform.ME.hideFotos := true;
    end
    else begin
      mainform.ME.BlurFoto := 0;
      mainform.ME.hideFotos := false;
    end;
//    end;


//  showmessage('uStart: ' + Mainform.ME.Rechte.Access[riGruppen]);

//      Mainform.lblUserName.Html.Text := j.Value('VORNAME') + ' ' + j.Value('NAME');

//      if ckStay.Checked
//      then begin
//        setCookie('username', edName.Text);
//        setCookie('pw', edPasswort.Text);
//        setCookie('stay','1');
//      end
//      else begin
//        delCookie('username');
//        delCookie('pw');
//        delCookie('stay');
//      end;

    result := Mainform.ME.id;
    Mainform.Start(Mainform.ME.id);

   end;

   freeAndNil(j);
end;


procedure TfrmStart.miPreiseClick(Sender: TObject);
begin
  divByeBye.Visible  := false;
  divLanding.Visible := true;
  divLogin.Visible   := false;
  divLosgehts.Visible:= false;
  divGruende.Visible := false;
  divPreise.Visible  := true;

  asm
    document.getElementById('IDPreise').scrollIntoView(false);
  end;

end;

procedure TfrmStart.miGuteGruendeClick(Sender: TObject);
begin
  divByeBye.Visible  := false;
  divLanding.Visible := true;
  divLogin.Visible   := false;
  divLosgehts.Visible:= false;
  divGruende.Visible := true;
  divPreise.Visible  := false;

  asm
    document.getElementById('IDGruende').scrollIntoView(false);
  end;
end;

procedure TfrmStart.btnGastClick(Sender: TObject);
Var
  iID:integer;
  Response: TXDataClientResponse;

begin
  DatenModul.Connection.OpenAsync;
  meldung('Email-11');
//  await(datenmodul.EasySQL('select * from Angebote where aktiv = 1'));
//  response := TAwait.Exec<TXDataClientResponse>( Client.RawInvokeAsync( 'IDBService.UserGetByID',['1']));
//  response := TAwait.Exec<TXDataClientResponse>( Client.RawInvokeAsync( 'IDBService.UserGetByID',[2]));




// thb
//iID := await(TfrmStart.CreateGast(edName.Text,edVorname.text,edEmail.text));
//  iId := await(TfrmStart.Login(iId, '',''));
//  if iId > 0
//  then begin
//    Mainform.Start(iId);
//    //datenmodul.System.init;
//  end;
  meldung('Email-2');
end;

procedure TfrmStart.btnLoginClick(Sender: TObject);
begin
  if pos(';',edName.Text + edPasswort.Text) = 0
  then begin
    if (edName.Text = '') or (edPasswort.Text = '')
    then edName.Required := true
    else begin
      if await(login(0,edName.Text, edPasswort.Text)) > 0
      then begin
        divError.Visible := false;
//        Mainform.Start(Mainform.ME.id);
        close;
      end
      else divError.Visible := true;

    end;
  end;
end;

procedure TfrmStart.CreatePakete;
var
  JSObj: TJSObject;
  JSString:string;

  J : TJ;
  i:integer;
  f : TfrAngebot;
  res:TXDataClientResponse;
  el : TJSHTMLElement;
  lExists:boolean;
  s:string;
begin
  if FAktion = ''
  then FAktion := '0';

  res := await(datenmodul.EasySQL('Select * from Angebote where aktiv = 1 AND AKTION = ' + FAktion + ' Order by SEQ'));
  j := TJ.create(res);

  lExists := false;

  for i := 0 to self.ComponentCount - 1 do
  begin
    if pos('paket_', self.Components[i].Name) > 0
    then lExists := true;
  end;

  if (lExists = false)
  then begin

    for i := 0 to j.Length -1 do
    begin
      j.Index := i;
      f := TfrAngebot.create(self);
      f.LoadFromForm;
      f.parent := divPakete;

      //f.btnBuchen.visible := false;

      f.Name := 'paket_' + j.Value('ID');
      f.Zahlung := j.Value('ZAHLUNG');
      f.divTitel.HTML.Text := j.Value('TITEL');
      f.divText.HTML.Text := j.Value('TEXT');
      f.divPreis.HTML.Text := j.Value('PREIS');
//      f.divTitel.ElementHandle.style.setProperty('color',j.Value('COLOR'));
//      f.divContainer.ElementHandle.style.setProperty('border-color',j.Value('COLOR'));
//      f.btnBuchen.ElementHandle.style.setProperty('background-color',j.Value('COLOR'));
//      f.GotoLoginClick := btnGotoLoginClick;
      TControl(f).elementfont := efCSS;
      el := TJSHTMLElement(THTMLDiv(f).ElementHandle);
      el.style.removeProperty('overflow');
      el.style.removeProperty('width');
      el.style.removeProperty('height');
      el.style.removeProperty('position');

    end;
  end;

  freeAndNil(j);

end;

procedure TfrmStart.btnRegistrierenClick(Sender: TObject);

//  function XorStr(const Text: string; Key: Integer): string;
//  var
//    i: Integer;
//  begin
//    SetLength(Result, Length(Text));
//
//    for i:=1 to Length(Text) do
//    begin
//      if Text[i] > #31 then
//        Result[i] := Chr(Ord(Text[i]) xor Key)
//      else
//        Result[i] := Text[i];
//    end;
//  end;

var
  JSObj: TJSObject;
  JSString:string;

  J : TJ;
  i:integer;
  f : TfrAngebot;
  res:TXDataClientResponse;
  el : TJSHTMLElement;
  lExists:boolean;
  s:string;
begin
  res := await(datenmodul.EasySQL('select * from Angebote where aktiv = 1'));
  j := TJ.create(res);
  lExists := false;
  for i := 0 to self.ComponentCount - 1 do
  begin
    if pos('angebot_', self.Components[i].Name) > 0
    then lExists := true;
  end;

  if (lExists = false)
  then begin

    for i := 0 to j.Length -1 do
    begin
      j.Index := i;
      f := TfrAngebot.create(self);
      f.LoadFromForm;
      f.parent := divAngebote;
      f.Name := 'angebot_' + j.Value('ID');
      f.Zahlung := j.Value('ZAHLUNG');
      f.divTitel.HTML.Text := j.Value('TITEL');
      f.divText.HTML.Text := j.Value('TEXT');
      f.divPreis.HTML.Text := j.Value('PREIS');
      f.divTitel.ElementHandle.style.setProperty('color',j.Value('COLOR'));
      f.divContainer.ElementHandle.style.setProperty('border-color',j.Value('COLOR'));
      f.btnBuchen.ElementHandle.style.setProperty('background-color',j.Value('COLOR'));
//      f.GotoLoginClick := btnGotoLoginClick;
      TControl(f).elementfont := efCSS;
      el := TJSHTMLElement(THTMLDiv(f).ElementHandle);
      el.style.removeProperty('overflow');
      el.style.removeProperty('width');
      el.style.removeProperty('height');
      el.style.removeProperty('position');
    end;
  end;

  divAngebote.Visible := true;
  divStart.Visible := false;
  divBack.Visible := true;

  freeAndNil(j);

  JSString:= '{"EMAIL": "' + 'thbordihn@yahoo.com' + '", ' +
              '"TYP": "' + 'EMAIL_REGISTER' + '", ' +
              '"BETREFF": "' + '' + '", ' +
              '"TEXT": "'    + '' + '", ' +
              '"PLACEHOLDER":{ ' +
              '               "NAME": "' + 'Thorsten' + '", ' +
              '               "LOGIN": "'+ 'thbordihn@yahoo.com' + '", ' +
              '               "PASSWORT": "' + 'Passwort' + '",' +
             '                "CRYPTMAIL": "' + Crypt('thbordihn@yahoo.com',30) + '",' +
              '               "ID":' + '21' +
              '               }' +
              '}';


  JSObj:=TJSJSON.parseObject(JSString);
  res := Await( Client.RawInvokeAsync( 'IDBService.SendMail',[JSObj]));

end;

procedure TfrmStart.chkInfoGruppenClick(Sender: TObject);
begin

end;

//https://buy.stripe.com/dR615Y7Wm8b4asg4gg

procedure TfrmStart.ShowRegisterInfo;
begin
end;

procedure TfrmStart.SeglerPortalde1Click(Sender: TObject);
begin
  divByeBye.Visible  := false;
  divLogin.Visible   := false;
  divLanding.Visible := true;
  divLosgehts.Visible:= true;
  divGruende.Visible := false;
  divPreise.Visible  := false;

end;

procedure TfrmStart.ShowByeBye;
begin
  divLanding.Visible := false;
  divLogin.Visible := false;
  divByeBye.Visible:= true;
end;

procedure TfrmStart.miLosGehtsClick(Sender: TObject);
begin
  divByeBye.Visible  := false;
  divLogin.Visible   := false;
  divLanding.Visible := true;
  divLosgehts.Visible:= false;
  divGruende.Visible := true;
  divPreise.Visible  := false;

//  miGuteGruendeClick(sender);
end;

procedure TfrmStart.btnShowLoginDivClick(Sender: TObject);
begin
  divByeBye.Visible  := false;
  divLogin.Visible   := true;
  divLanding.Visible := true;
  divLosgehts.Visible:= false;
  divGruende.Visible := false;
  divPreise.Visible  := false;
end;

procedure TfrmStart.WebButton1Click(Sender: TObject);
begin
  divByeBye.Visible:= false;
  divLanding.Visible := false;
  divLogin.Visible := false;
end;

procedure TfrmStart.WebFormCreate(Sender: TObject);
begin

  if (not datenmodul.Connection.Connected)
  then begin

    divWartung.visible := true;
    divByeBye.Visible  := false;
    divLogin.Visible   := false;
    divLanding.Visible := false;
    divLosgehts.Visible:= false;
    divGruende.Visible := false;
    divPreise.Visible  := false;
    divMenue.Visible   := false;

    divStartSeite.Visible := true;
    exit;
  end
  else begin
    await(CreatePakete);
//    imgGruende1.url := await(datenmodul.GetMedia(1131,2));
//    imgGruende2.url := await(datenmodul.GetMedia(1099,2));
//    imgGruende3.url := await(datenmodul.GetMedia(1082,2));
//    imgGruende4.url := await(datenmodul.GetMedia(1089,2));
//    imgGruende5.url := await(datenmodul.GetMedia(1131,2));
//    imgGruende6.url := await(datenmodul.GetMedia(1131,2));
  end;

end;

procedure TfrmStart.lblNewPasswordClick(Sender: TObject);
var
  j : TJ;
  JSObj: TJSObject;
  JSString:string;
  sText, sPasswort:string;
  aDaten:array of string;
  LData:TEmailData;
begin

  if edName.Text = ''
  then await(Meldung('E-Mail-Adresse eingeben'))
  else begin

    j := TJ.create(await(datenmodul.EasySQL('select ID, VORNAME, NICKNAME,EMAIL from User where UPPER(EMAIL) = ''' + UpperCase(edName.Text) + '''')));

    if j.Value('ID') = ''
    then begin
      await(Meldung('Diese E-Mail-Adresse ist uns nicht bekannt...'));
      exit;
    end
    else begin
      LData.Text_ID := '7';
      LData.ID      := j.Value('ID');
      LData.Name    := j.Value('NAME');
      LData.Vorname := j.Value('VORNAME');
      LData.EMail   := j.Value('EMAIL');
      LData.Passwort:= MilliSecondOfTheDay(Now()).tostring;

      await(datenmodul.easyExec('Update User set PASSWORD = ''' + LData.Passwort + ''' where ID = ' + j.Value('ID')));

      await(NeuesPasswortEMail(LData));

      await(Meldung('Wir haben dein neues Passwort an deine E-Mail-Adresse geschickt.<br><br>Das kann einen Moment dauern.<br><br>Schaue ggfs. auch im Spam-Ordner nach. '));

    end;

  end;

end;

procedure TfrmStart.NeuesPasswortEMail(AData:TEMaiLData);
var
  JSObj: TJSObject;
  JSString: string;
begin

  JSString:= '{"EMAIL":   "' + AData.Email   + '",' +
              '"VORNAME": "' + AData.Vorname + '",' +
              '"NAME": "'    + AData.Name    + '",' +
              '"PASSWORT":"' + AData.Passwort+ '",' +
              '"TEXT_ID": '  + AData.Text_ID + ', ' +
              '"URL": "'     + datenmodul.Connection.URL + '", '+
              '"ANHANG":  ""}';

  JSObj:=TJSJSON.parseObject(JSString);

  Await( Client.RawInvokeAsync( 'IDBService.SendEMail',[JSObj]));

end;


procedure TfrmStart.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  divStart := THTMLDiv.Create(Self);
  WebHTMLDiv9 := THTMLDiv.Create(Self);
  pLogin := THTMLDiv.Create(Self);
  WebHTMLDiv3 := THTMLDiv.Create(Self);
  WebHTMLDiv5 := THTMLDiv.Create(Self);
  divError1 := THTMLDiv.Create(Self);
  edName1 := TEdit.Create(Self);
  edPasswort5 := TEdit.Create(Self);
  WebHTMLDiv87 := THTMLDiv.Create(Self);
  chkInfoGruppen := TCheckBox.Create(Self);
  WebHTMLDiv82 := THTMLDiv.Create(Self);
  WebLabel30 := TLabel.Create(Self);
  WebHTMLDiv6 := THTMLDiv.Create(Self);
  btnLogin1 := TButton.Create(Self);
  pNew := THTMLDiv.Create(Self);
  WebHTMLDiv4 := THTMLDiv.Create(Self);
  WebHTMLDiv7 := THTMLDiv.Create(Self);
  WebHTMLDiv13 := THTMLDiv.Create(Self);
  WebHTMLDiv8 := THTMLDiv.Create(Self);
  btnRegistrieren := TButton.Create(Self);
  divNurMalGucken := THTMLDiv.Create(Self);
  WebHTMLDiv10 := THTMLDiv.Create(Self);
  WebHTMLDiv11 := THTMLDiv.Create(Self);
  edVorname := TEdit.Create(Self);
  edNachname := TEdit.Create(Self);
  edEmail := TEdit.Create(Self);
  WebHTMLDiv12 := THTMLDiv.Create(Self);
  btnGast := TButton.Create(Self);
  divStartText := THTMLDiv.Create(Self);
  WebHTMLDiv15 := THTMLDiv.Create(Self);
  WebImageControl1 := TImageControl.Create(Self);
  divAngebote := THTMLDiv.Create(Self);
  divBack := THTMLDiv.Create(Self);
  btnGotoLogin := TButton.Create(Self);
  divRegisterInfo := THTMLDiv.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  WebHTMLDiv16 := THTMLDiv.Create(Self);
  btnBack2Login := TButton.Create(Self);
  divByeBye1 := THTMLDiv.Create(Self);
  WebHTMLDiv19 := THTMLDiv.Create(Self);
  WebButton1 := TButton.Create(Self);
  divstartseite := THTMLDiv.Create(Self);
  divLanding := THTMLDiv.Create(Self);
  divLosgehts := THTMLDiv.Create(Self);
  WebHTMLDiv17 := THTMLDiv.Create(Self);
  WebHTMLDiv20 := THTMLDiv.Create(Self);
  miLosGehts := TButton.Create(Self);
  btnShowLoginDiv := TButton.Create('login-btn');
  WebButton9 := TButton.Create(Self);
  divGruende := THTMLDiv.Create('IDGruende');
  WebHTMLDiv22 := THTMLDiv.Create(Self);
  divGruende1 := THTMLDiv.Create(Self);
  imgGruende1 := TImageControl.Create(Self);
  divGruende2 := THTMLDiv.Create(Self);
  WebHTMLDiv27 := THTMLDiv.Create(Self);
  imgGruende2 := TImageControl.Create(Self);
  WebHTMLDiv29 := THTMLDiv.Create(Self);
  WebHTMLDiv32 := THTMLDiv.Create(Self);
  imgGruende3 := TImageControl.Create(Self);
  WebHTMLDiv33 := THTMLDiv.Create(Self);
  WebHTMLDiv34 := THTMLDiv.Create(Self);
  imgGruende4 := TImageControl.Create(Self);
  WebHTMLDiv35 := THTMLDiv.Create(Self);
  WebHTMLDiv36 := THTMLDiv.Create(Self);
  imgGruende5 := TImageControl.Create(Self);
  WebHTMLDiv37 := THTMLDiv.Create(Self);
  WebHTMLDiv38 := THTMLDiv.Create(Self);
  imgGruende6 := TImageControl.Create(Self);
  WebHTMLDiv39 := THTMLDiv.Create(Self);
  WebHTMLDiv25 := THTMLDiv.Create(Self);
  WebHTMLDiv14 := THTMLDiv.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton5 := TButton.Create('login-btn');
  divPreise := THTMLDiv.Create('IDPreise');
  divPakete := THTMLDiv.Create(Self);
  WebHTMLDiv55 := THTMLDiv.Create(Self);
  WebHTMLDiv26 := THTMLDiv.Create(Self);
  WebButton12 := TButton.Create('login-btn');
  WebButton13 := TButton.Create(Self);
  divLogin := THTMLDiv.Create(Self);
  divLogin1 := THTMLDiv.Create(Self);
  WebHTMLDiv42 := THTMLDiv.Create(Self);
  WebHTMLDiv43 := THTMLDiv.Create(Self);
  WebButton6 := TButton.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebButton8 := TButton.Create(Self);
  WebHTMLDiv44 := THTMLDiv.Create(Self);
  WebLabel15 := TLabel.Create(Self);
  WebHTMLDiv46 := THTMLDiv.Create(Self);
  edName := TEdit.Create(Self);
  WebHTMLDiv45 := THTMLDiv.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebHTMLDiv83 := THTMLDiv.Create(Self);
  edPasswort := TEdit.Create(Self);
  WebHTMLDiv47 := THTMLDiv.Create(Self);
  lblNewPassword := TLabel.Create(Self);
  WebHTMLDiv48 := THTMLDiv.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  divError := THTMLDiv.Create(Self);
  divByeBye := THTMLDiv.Create(Self);
  divByeByeX := THTMLDiv.Create(Self);
  WebHTMLDiv41 := THTMLDiv.Create(Self);
  WebHTMLDiv49 := THTMLDiv.Create(Self);
  WebButton3 := TButton.Create(Self);
  divWartung := THTMLDiv.Create(Self);
  WebHTMLDiv18 := THTMLDiv.Create(Self);
  WebHTMLDiv23 := THTMLDiv.Create(Self);
  WebHTMLDiv24 := THTMLDiv.Create(Self);
  WebButton4 := TButton.Create(Self);
  divMenue := THTMLDiv.Create('xyz');
  WebHTMLDiv30 := THTMLDiv.Create(Self);
  divMenuLeft := THTMLDiv.Create(Self);
  WebHTMLDiv31 := THTMLDiv.Create(Self);
  WebHTMLDiv21 := THTMLDiv.Create(Self);
  imgLogo := TImageControl.Create(Self);
  Client := TXDataWebClient.Create(Self);
  mnuMainLeft := TMainMenu.Create(Self);
  SeglerPortalde1 := TMenuItem.Create(Self);
  miGuteGruende := TMenuItem.Create(Self);
  miPreise := TMenuItem.Create(Self);
  Login1 := TMenuItem.Create(Self);

  WebHTMLDiv1.BeforeLoadDFMValues;
  divStart.BeforeLoadDFMValues;
  WebHTMLDiv9.BeforeLoadDFMValues;
  pLogin.BeforeLoadDFMValues;
  WebHTMLDiv3.BeforeLoadDFMValues;
  WebHTMLDiv5.BeforeLoadDFMValues;
  divError1.BeforeLoadDFMValues;
  edName1.BeforeLoadDFMValues;
  edPasswort5.BeforeLoadDFMValues;
  WebHTMLDiv87.BeforeLoadDFMValues;
  chkInfoGruppen.BeforeLoadDFMValues;
  WebHTMLDiv82.BeforeLoadDFMValues;
  WebLabel30.BeforeLoadDFMValues;
  WebHTMLDiv6.BeforeLoadDFMValues;
  btnLogin1.BeforeLoadDFMValues;
  pNew.BeforeLoadDFMValues;
  WebHTMLDiv4.BeforeLoadDFMValues;
  WebHTMLDiv7.BeforeLoadDFMValues;
  WebHTMLDiv13.BeforeLoadDFMValues;
  WebHTMLDiv8.BeforeLoadDFMValues;
  btnRegistrieren.BeforeLoadDFMValues;
  divNurMalGucken.BeforeLoadDFMValues;
  WebHTMLDiv10.BeforeLoadDFMValues;
  WebHTMLDiv11.BeforeLoadDFMValues;
  edVorname.BeforeLoadDFMValues;
  edNachname.BeforeLoadDFMValues;
  edEmail.BeforeLoadDFMValues;
  WebHTMLDiv12.BeforeLoadDFMValues;
  btnGast.BeforeLoadDFMValues;
  divStartText.BeforeLoadDFMValues;
  WebHTMLDiv15.BeforeLoadDFMValues;
  WebImageControl1.BeforeLoadDFMValues;
  divAngebote.BeforeLoadDFMValues;
  divBack.BeforeLoadDFMValues;
  btnGotoLogin.BeforeLoadDFMValues;
  divRegisterInfo.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  WebHTMLDiv16.BeforeLoadDFMValues;
  btnBack2Login.BeforeLoadDFMValues;
  divByeBye1.BeforeLoadDFMValues;
  WebHTMLDiv19.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  divstartseite.BeforeLoadDFMValues;
  divLanding.BeforeLoadDFMValues;
  divLosgehts.BeforeLoadDFMValues;
  WebHTMLDiv17.BeforeLoadDFMValues;
  WebHTMLDiv20.BeforeLoadDFMValues;
  miLosGehts.BeforeLoadDFMValues;
  btnShowLoginDiv.BeforeLoadDFMValues;
  WebButton9.BeforeLoadDFMValues;
  divGruende.BeforeLoadDFMValues;
  WebHTMLDiv22.BeforeLoadDFMValues;
  divGruende1.BeforeLoadDFMValues;
  imgGruende1.BeforeLoadDFMValues;
  divGruende2.BeforeLoadDFMValues;
  WebHTMLDiv27.BeforeLoadDFMValues;
  imgGruende2.BeforeLoadDFMValues;
  WebHTMLDiv29.BeforeLoadDFMValues;
  WebHTMLDiv32.BeforeLoadDFMValues;
  imgGruende3.BeforeLoadDFMValues;
  WebHTMLDiv33.BeforeLoadDFMValues;
  WebHTMLDiv34.BeforeLoadDFMValues;
  imgGruende4.BeforeLoadDFMValues;
  WebHTMLDiv35.BeforeLoadDFMValues;
  WebHTMLDiv36.BeforeLoadDFMValues;
  imgGruende5.BeforeLoadDFMValues;
  WebHTMLDiv37.BeforeLoadDFMValues;
  WebHTMLDiv38.BeforeLoadDFMValues;
  imgGruende6.BeforeLoadDFMValues;
  WebHTMLDiv39.BeforeLoadDFMValues;
  WebHTMLDiv25.BeforeLoadDFMValues;
  WebHTMLDiv14.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  divPreise.BeforeLoadDFMValues;
  divPakete.BeforeLoadDFMValues;
  WebHTMLDiv55.BeforeLoadDFMValues;
  WebHTMLDiv26.BeforeLoadDFMValues;
  WebButton12.BeforeLoadDFMValues;
  WebButton13.BeforeLoadDFMValues;
  divLogin.BeforeLoadDFMValues;
  divLogin1.BeforeLoadDFMValues;
  WebHTMLDiv42.BeforeLoadDFMValues;
  WebHTMLDiv43.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebButton8.BeforeLoadDFMValues;
  WebHTMLDiv44.BeforeLoadDFMValues;
  WebLabel15.BeforeLoadDFMValues;
  WebHTMLDiv46.BeforeLoadDFMValues;
  edName.BeforeLoadDFMValues;
  WebHTMLDiv45.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebHTMLDiv83.BeforeLoadDFMValues;
  edPasswort.BeforeLoadDFMValues;
  WebHTMLDiv47.BeforeLoadDFMValues;
  lblNewPassword.BeforeLoadDFMValues;
  WebHTMLDiv48.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  divError.BeforeLoadDFMValues;
  divByeBye.BeforeLoadDFMValues;
  divByeByeX.BeforeLoadDFMValues;
  WebHTMLDiv41.BeforeLoadDFMValues;
  WebHTMLDiv49.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  divWartung.BeforeLoadDFMValues;
  WebHTMLDiv18.BeforeLoadDFMValues;
  WebHTMLDiv23.BeforeLoadDFMValues;
  WebHTMLDiv24.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  divMenue.BeforeLoadDFMValues;
  WebHTMLDiv30.BeforeLoadDFMValues;
  divMenuLeft.BeforeLoadDFMValues;
  WebHTMLDiv31.BeforeLoadDFMValues;
  WebHTMLDiv21.BeforeLoadDFMValues;
  imgLogo.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  mnuMainLeft.BeforeLoadDFMValues;
  SeglerPortalde1.BeforeLoadDFMValues;
  miGuteGruende.BeforeLoadDFMValues;
  miPreise.BeforeLoadDFMValues;
  Login1.BeforeLoadDFMValues;
  try
    Name := 'frmStart';
    Width := 1312;
    Height := 945;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    Menu := mnuMainLeft;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 11;
    WebHTMLDiv1.Top := 850;
    WebHTMLDiv1.Width := 431;
    WebHTMLDiv1.Height := 144;
    WebHTMLDiv1.ElementClassName := 'start_container';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv1.Visible := False;
    divStart.SetParentComponent(WebHTMLDiv1);
    divStart.Name := 'divStart';
    divStart.Left := 127;
    divStart.Top := 145;
    divStart.Width := 1249;
    divStart.Height := 668;
    divStart.ElementClassName := 'start_center';
    divStart.HeightStyle := ssAuto;
    divStart.WidthStyle := ssAuto;
    divStart.ChildOrder := 2;
    divStart.ElementPosition := epIgnore;
    divStart.ElementFont := efCSS;
    divStart.Role := '';
    WebHTMLDiv9.SetParentComponent(divStart);
    WebHTMLDiv9.Name := 'WebHTMLDiv9';
    WebHTMLDiv9.Left := 38;
    WebHTMLDiv9.Top := 165;
    WebHTMLDiv9.Width := 1091;
    WebHTMLDiv9.Height := 486;
    WebHTMLDiv9.ElementClassName := 'start_box';
    WebHTMLDiv9.HeightStyle := ssAuto;
    WebHTMLDiv9.WidthStyle := ssAuto;
    WebHTMLDiv9.ChildOrder := 5;
    WebHTMLDiv9.ElementPosition := epIgnore;
    WebHTMLDiv9.ElementFont := efCSS;
    WebHTMLDiv9.Role := '';
    pLogin.SetParentComponent(WebHTMLDiv9);
    pLogin.Name := 'pLogin';
    pLogin.Left := 51;
    pLogin.Top := 8;
    pLogin.Width := 385;
    pLogin.Height := 363;
    pLogin.ElementClassName := 'login_box_login';
    pLogin.HeightStyle := ssAuto;
    pLogin.WidthStyle := ssAuto;
    pLogin.ChildOrder := 1;
    pLogin.ElementPosition := epIgnore;
    pLogin.ElementFont := efCSS;
    pLogin.Role := '';
    WebHTMLDiv3.SetParentComponent(pLogin);
    WebHTMLDiv3.Name := 'WebHTMLDiv3';
    WebHTMLDiv3.Left := 3;
    WebHTMLDiv3.Top := 0;
    WebHTMLDiv3.Width := 366;
    WebHTMLDiv3.Height := 41;
    WebHTMLDiv3.ElementClassName := 'login_login';
    WebHTMLDiv3.HeightStyle := ssAuto;
    WebHTMLDiv3.WidthStyle := ssAuto;
    WebHTMLDiv3.ChildOrder := 7;
    WebHTMLDiv3.ElementPosition := epRelative;
    WebHTMLDiv3.ElementFont := efCSS;
    WebHTMLDiv3.HTML.BeginUpdate;
    try
      WebHTMLDiv3.HTML.Clear;
      WebHTMLDiv3.HTML.Add('ANMELDEN');
    finally
      WebHTMLDiv3.HTML.EndUpdate;
    end;
    WebHTMLDiv3.Role := '';
    WebHTMLDiv5.SetParentComponent(pLogin);
    WebHTMLDiv5.Name := 'WebHTMLDiv5';
    WebHTMLDiv5.Left := 16;
    WebHTMLDiv5.Top := 55;
    WebHTMLDiv5.Width := 366;
    WebHTMLDiv5.Height := 269;
    WebHTMLDiv5.ElementClassName := 'login_middle';
    WebHTMLDiv5.HeightStyle := ssAuto;
    WebHTMLDiv5.WidthStyle := ssAuto;
    WebHTMLDiv5.ChildOrder := 7;
    WebHTMLDiv5.ElementPosition := epIgnore;
    WebHTMLDiv5.ElementFont := efCSS;
    WebHTMLDiv5.Role := '';
    divError1.SetParentComponent(WebHTMLDiv5);
    divError1.Name := 'divError1';
    divError1.Left := 24;
    divError1.Top := 8;
    divError1.Width := 321;
    divError1.Height := 41;
    divError1.ElementClassName := 'error_container';
    divError1.HeightStyle := ssAuto;
    divError1.WidthStyle := ssAuto;
    divError1.ElementPosition := epRelative;
    divError1.ElementFont := efCSS;
    divError1.HTML.BeginUpdate;
    try
      divError1.HTML.Clear;
      divError1.HTML.Add('Anmeldename oder Passwort ist falsch...');
    finally
      divError1.HTML.EndUpdate;
    end;
    divError1.Role := '';
    divError1.Visible := False;
    edName1.SetParentComponent(WebHTMLDiv5);
    edName1.Name := 'edName1';
    edName1.Left := 21;
    edName1.Top := 63;
    edName1.Width := 116;
    edName1.Height := 34;
    edName1.AutoCompletion := acCCName;
    edName1.ChildOrder := 1;
    edName1.ElementClassName := 'login_input';
    edName1.ElementFont := efCSS;
    edName1.ElementPosition := epIgnore;
    edName1.HeightStyle := ssAuto;
    edName1.HeightPercent := 100.000000000000000000;
    edName1.Required := True;
    edName1.RequiredText := 'Name fehlt!';
    edName1.TextHint := 'Anmeldename eingeben';
    edName1.WidthStyle := ssAuto;
    edName1.WidthPercent := 100.000000000000000000;
    SetEvent(edName1, Self, 'OnKeyDown', 'edName1KeyDown');
    edPasswort5.SetParentComponent(WebHTMLDiv5);
    edPasswort5.Name := 'edPasswort5';
    edPasswort5.Left := 26;
    edPasswort5.Top := 103;
    edPasswort5.Width := 324;
    edPasswort5.Height := 33;
    edPasswort5.AutoCompletion := acCurrentPassword;
    edPasswort5.ChildOrder := 2;
    edPasswort5.ElementClassName := 'login_input';
    edPasswort5.ElementFont := efCSS;
    edPasswort5.ElementPosition := epIgnore;
    edPasswort5.HeightStyle := ssAuto;
    edPasswort5.HeightPercent := 100.000000000000000000;
    edPasswort5.PasswordChar := '*';
    edPasswort5.Required := True;
    edPasswort5.TextHint := 'Passwort eingeben';
    edPasswort5.WidthStyle := ssAuto;
    edPasswort5.WidthPercent := 100.000000000000000000;
    WebHTMLDiv87.SetParentComponent(WebHTMLDiv5);
    WebHTMLDiv87.Name := 'WebHTMLDiv87';
    WebHTMLDiv87.Left := 18;
    WebHTMLDiv87.Top := 158;
    WebHTMLDiv87.Width := 345;
    WebHTMLDiv87.Height := 40;
    WebHTMLDiv87.ElementClassName := 'profil_line';
    WebHTMLDiv87.HeightStyle := ssAuto;
    WebHTMLDiv87.WidthStyle := ssAuto;
    WebHTMLDiv87.ChildOrder := 3;
    WebHTMLDiv87.ElementPosition := epRelative;
    WebHTMLDiv87.ElementFont := efCSS;
    WebHTMLDiv87.Role := '';
    WebHTMLDiv87.Visible := False;
    chkInfoGruppen.SetParentComponent(WebHTMLDiv87);
    chkInfoGruppen.Name := 'chkInfoGruppen';
    chkInfoGruppen.Left := 22;
    chkInfoGruppen.Top := 3;
    chkInfoGruppen.Width := 305;
    chkInfoGruppen.Height := 22;
    chkInfoGruppen.Caption := 'angemeldet bleiben';
    chkInfoGruppen.ChildOrder := 1;
    chkInfoGruppen.ElementLabelClassName := 'login_label';
    chkInfoGruppen.ElementFont := efCSS;
    chkInfoGruppen.ElementPosition := epRelative;
    chkInfoGruppen.HeightStyle := ssAuto;
    chkInfoGruppen.HeightPercent := 100.000000000000000000;
    chkInfoGruppen.WidthStyle := ssAuto;
    chkInfoGruppen.WidthPercent := 100.000000000000000000;
    SetEvent(chkInfoGruppen, Self, 'OnClick', 'chkInfoGruppenClick');
    WebHTMLDiv82.SetParentComponent(WebHTMLDiv5);
    WebHTMLDiv82.Name := 'WebHTMLDiv82';
    WebHTMLDiv82.Left := 18;
    WebHTMLDiv82.Top := 204;
    WebHTMLDiv82.Width := 345;
    WebHTMLDiv82.Height := 36;
    WebHTMLDiv82.ElementClassName := 'profil_line';
    WebHTMLDiv82.HeightStyle := ssAuto;
    WebHTMLDiv82.WidthStyle := ssAuto;
    WebHTMLDiv82.ChildOrder := 4;
    WebHTMLDiv82.ElementPosition := epIgnore;
    WebHTMLDiv82.ElementFont := efCSS;
    WebHTMLDiv82.Role := '';
    WebLabel30.SetParentComponent(WebHTMLDiv82);
    WebLabel30.Name := 'WebLabel30';
    WebLabel30.Left := 20;
    WebLabel30.Top := 11;
    WebLabel30.Width := 111;
    WebLabel30.Height := 15;
    WebLabel30.Caption := 'Passwort vergessen...';
    WebLabel30.ElementClassName := 'login_label';
    WebLabel30.ElementFont := efCSS;
    WebLabel30.ElementPosition := epIgnore;
    WebLabel30.HeightStyle := ssAuto;
    WebLabel30.HeightPercent := 100.000000000000000000;
    WebLabel30.WidthStyle := ssAuto;
    WebLabel30.WidthPercent := 100.000000000000000000;
    WebHTMLDiv6.SetParentComponent(pLogin);
    WebHTMLDiv6.Name := 'WebHTMLDiv6';
    WebHTMLDiv6.Left := 3;
    WebHTMLDiv6.Top := 319;
    WebHTMLDiv6.Width := 366;
    WebHTMLDiv6.Height := 41;
    WebHTMLDiv6.ElementClassName := 'login_footer';
    WebHTMLDiv6.HeightStyle := ssAuto;
    WebHTMLDiv6.WidthStyle := ssAuto;
    WebHTMLDiv6.ChildOrder := 4;
    WebHTMLDiv6.ElementPosition := epIgnore;
    WebHTMLDiv6.ElementFont := efCSS;
    WebHTMLDiv6.Role := '';
    btnLogin1.SetParentComponent(WebHTMLDiv6);
    btnLogin1.Name := 'btnLogin1';
    btnLogin1.Left := 208;
    btnLogin1.Top := 5;
    btnLogin1.Width := 137;
    btnLogin1.Height := 33;
    btnLogin1.Caption := 'Login';
    btnLogin1.ChildOrder := 4;
    btnLogin1.ElementClassName := 'login_button_login';
    btnLogin1.ElementFont := efCSS;
    btnLogin1.ElementPosition := epIgnore;
    btnLogin1.HeightStyle := ssAuto;
    btnLogin1.HeightPercent := 100.000000000000000000;
    btnLogin1.WidthStyle := ssAuto;
    btnLogin1.WidthPercent := 100.000000000000000000;
    SetEvent(btnLogin1, Self, 'OnClick', 'btnLoginClick');
    pNew.SetParentComponent(WebHTMLDiv9);
    pNew.Name := 'pNew';
    pNew.Left := 410;
    pNew.Top := 36;
    pNew.Width := 340;
    pNew.Height := 363;
    pNew.ElementClassName := 'login_box_register';
    pNew.HeightStyle := ssAuto;
    pNew.WidthStyle := ssAuto;
    pNew.ChildOrder := 2;
    pNew.ElementPosition := epIgnore;
    pNew.ElementFont := efCSS;
    pNew.Role := '';
    WebHTMLDiv4.SetParentComponent(pNew);
    WebHTMLDiv4.Name := 'WebHTMLDiv4';
    WebHTMLDiv4.Left := 3;
    WebHTMLDiv4.Top := 0;
    WebHTMLDiv4.Width := 238;
    WebHTMLDiv4.Height := 41;
    WebHTMLDiv4.ElementClassName := 'login_register';
    WebHTMLDiv4.HeightStyle := ssAuto;
    WebHTMLDiv4.WidthStyle := ssAuto;
    WebHTMLDiv4.ChildOrder := 7;
    WebHTMLDiv4.ElementPosition := epIgnore;
    WebHTMLDiv4.ElementFont := efCSS;
    WebHTMLDiv4.HTML.BeginUpdate;
    try
      WebHTMLDiv4.HTML.Clear;
      WebHTMLDiv4.HTML.Add('REGISTRIEREN');
    finally
      WebHTMLDiv4.HTML.EndUpdate;
    end;
    WebHTMLDiv4.Role := '';
    WebHTMLDiv7.SetParentComponent(pNew);
    WebHTMLDiv7.Name := 'WebHTMLDiv7';
    WebHTMLDiv7.Left := 11;
    WebHTMLDiv7.Top := 47;
    WebHTMLDiv7.Width := 326;
    WebHTMLDiv7.Height := 65;
    WebHTMLDiv7.ElementClassName := 'login_middle';
    WebHTMLDiv7.HeightStyle := ssAuto;
    WebHTMLDiv7.WidthStyle := ssAuto;
    WebHTMLDiv7.ChildOrder := 7;
    WebHTMLDiv7.ElementPosition := epIgnore;
    WebHTMLDiv7.ElementFont := efCSS;
    WebHTMLDiv7.Role := '';
    WebHTMLDiv13.SetParentComponent(WebHTMLDiv7);
    WebHTMLDiv13.Name := 'WebHTMLDiv13';
    WebHTMLDiv13.Left := 3;
    WebHTMLDiv13.Top := 3;
    WebHTMLDiv13.Width := 273;
    WebHTMLDiv13.Height := 41;
    WebHTMLDiv13.HeightStyle := ssAuto;
    WebHTMLDiv13.WidthStyle := ssAuto;
    WebHTMLDiv13.ChildOrder := 1;
    WebHTMLDiv13.ElementPosition := epIgnore;
    WebHTMLDiv13.ElementFont := efCSS;
    WebHTMLDiv13.HTML.BeginUpdate;
    try
      WebHTMLDiv13.HTML.Clear;
      WebHTMLDiv13.HTML.Add('<B>Du bist noch nicht dabei?</B><BR><BR>');
      WebHTMLDiv13.HTML.Add('Dann registriere dich jetzt kostenlos und schnell.');
    finally
      WebHTMLDiv13.HTML.EndUpdate;
    end;
    WebHTMLDiv13.Role := '';
    WebHTMLDiv8.SetParentComponent(pNew);
    WebHTMLDiv8.Name := 'WebHTMLDiv8';
    WebHTMLDiv8.Left := -29;
    WebHTMLDiv8.Top := 142;
    WebHTMLDiv8.Width := 310;
    WebHTMLDiv8.Height := 41;
    WebHTMLDiv8.ElementClassName := 'login_footer';
    WebHTMLDiv8.HeightStyle := ssAuto;
    WebHTMLDiv8.WidthStyle := ssAuto;
    WebHTMLDiv8.ChildOrder := 4;
    WebHTMLDiv8.ElementPosition := epIgnore;
    WebHTMLDiv8.ElementFont := efCSS;
    WebHTMLDiv8.Role := '';
    btnRegistrieren.SetParentComponent(WebHTMLDiv8);
    btnRegistrieren.Name := 'btnRegistrieren';
    btnRegistrieren.Left := 176;
    btnRegistrieren.Top := 4;
    btnRegistrieren.Width := 121;
    btnRegistrieren.Height := 34;
    btnRegistrieren.Caption := 'Konto erstellen';
    btnRegistrieren.ChildOrder := 7;
    btnRegistrieren.ElementClassName := 'login_button_register';
    btnRegistrieren.ElementFont := efCSS;
    btnRegistrieren.ElementPosition := epIgnore;
    btnRegistrieren.HeightStyle := ssAuto;
    btnRegistrieren.HeightPercent := 100.000000000000000000;
    btnRegistrieren.WidthStyle := ssAuto;
    btnRegistrieren.WidthPercent := 100.000000000000000000;
    SetEvent(btnRegistrieren, Self, 'OnClick', 'btnRegistrierenClick');
    divNurMalGucken.SetParentComponent(WebHTMLDiv9);
    divNurMalGucken.Name := 'divNurMalGucken';
    divNurMalGucken.Left := 730;
    divNurMalGucken.Top := 36;
    divNurMalGucken.Width := 340;
    divNurMalGucken.Height := 363;
    divNurMalGucken.ElementClassName := 'login_box_test';
    divNurMalGucken.HeightStyle := ssAuto;
    divNurMalGucken.WidthStyle := ssAuto;
    divNurMalGucken.ChildOrder := 3;
    divNurMalGucken.ElementPosition := epIgnore;
    divNurMalGucken.ElementFont := efCSS;
    divNurMalGucken.Role := '';
    divNurMalGucken.Visible := False;
    WebHTMLDiv10.SetParentComponent(divNurMalGucken);
    WebHTMLDiv10.Name := 'WebHTMLDiv10';
    WebHTMLDiv10.Left := 3;
    WebHTMLDiv10.Top := 0;
    WebHTMLDiv10.Width := 238;
    WebHTMLDiv10.Height := 41;
    WebHTMLDiv10.ElementClassName := 'login_test';
    WebHTMLDiv10.HeightStyle := ssAuto;
    WebHTMLDiv10.WidthStyle := ssAuto;
    WebHTMLDiv10.ChildOrder := 7;
    WebHTMLDiv10.ElementPosition := epIgnore;
    WebHTMLDiv10.ElementFont := efCSS;
    WebHTMLDiv10.HTML.BeginUpdate;
    try
      WebHTMLDiv10.HTML.Clear;
      WebHTMLDiv10.HTML.Add('NUR MAL GUCKEN');
    finally
      WebHTMLDiv10.HTML.EndUpdate;
    end;
    WebHTMLDiv10.Role := '';
    WebHTMLDiv11.SetParentComponent(divNurMalGucken);
    WebHTMLDiv11.Name := 'WebHTMLDiv11';
    WebHTMLDiv11.Left := 3;
    WebHTMLDiv11.Top := 47;
    WebHTMLDiv11.Width := 326;
    WebHTMLDiv11.Height := 122;
    WebHTMLDiv11.ElementClassName := 'login_middle';
    WebHTMLDiv11.HeightStyle := ssAuto;
    WebHTMLDiv11.WidthStyle := ssAuto;
    WebHTMLDiv11.ChildOrder := 7;
    WebHTMLDiv11.ElementPosition := epIgnore;
    WebHTMLDiv11.ElementFont := efCSS;
    WebHTMLDiv11.Role := '';
    edVorname.SetParentComponent(WebHTMLDiv11);
    edVorname.Name := 'edVorname';
    edVorname.Left := 13;
    edVorname.Top := 3;
    edVorname.Width := 297;
    edVorname.Height := 22;
    edVorname.ChildOrder := 4;
    edVorname.ElementClassName := 'login_input';
    edVorname.ElementFont := efCSS;
    edVorname.ElementPosition := epIgnore;
    edVorname.HeightStyle := ssAuto;
    edVorname.HeightPercent := 100.000000000000000000;
    edVorname.Required := True;
    edVorname.RequiredText := 'Vorname fehlt!';
    edVorname.Text := 'Thorsten';
    edVorname.TextHint := 'Deinen Vornamen eingeben';
    edVorname.WidthStyle := ssAuto;
    edVorname.WidthPercent := 100.000000000000000000;
    edNachname.SetParentComponent(WebHTMLDiv11);
    edNachname.Name := 'edNachname';
    edNachname.Left := 13;
    edNachname.Top := 43;
    edNachname.Width := 297;
    edNachname.Height := 22;
    edNachname.ChildOrder := 4;
    edNachname.ElementClassName := 'login_input';
    edNachname.ElementFont := efCSS;
    edNachname.ElementPosition := epIgnore;
    edNachname.HeightStyle := ssAuto;
    edNachname.HeightPercent := 100.000000000000000000;
    edNachname.Required := True;
    edNachname.RequiredText := 'Dein Name fehlt!';
    edNachname.Text := 'Bordihn';
    edNachname.TextHint := 'Deinen Nachnamen eingeben';
    edNachname.WidthStyle := ssAuto;
    edNachname.WidthPercent := 100.000000000000000000;
    edEmail.SetParentComponent(WebHTMLDiv11);
    edEmail.Name := 'edEmail';
    edEmail.Left := 9;
    edEmail.Top := 83;
    edEmail.Width := 301;
    edEmail.Height := 22;
    edEmail.ChildOrder := 3;
    edEmail.ElementClassName := 'login_input';
    edEmail.ElementFont := efCSS;
    edEmail.ElementPosition := epIgnore;
    edEmail.HeightStyle := ssAuto;
    edEmail.HeightPercent := 100.000000000000000000;
    edEmail.Required := True;
    edEmail.Text := 'jbe-ge@gmx.de';
    edEmail.TextHint := 'E-Mail eingeben';
    edEmail.WidthStyle := ssAuto;
    edEmail.WidthPercent := 100.000000000000000000;
    WebHTMLDiv12.SetParentComponent(divNurMalGucken);
    WebHTMLDiv12.Name := 'WebHTMLDiv12';
    WebHTMLDiv12.Left := 11;
    WebHTMLDiv12.Top := 198;
    WebHTMLDiv12.Width := 310;
    WebHTMLDiv12.Height := 41;
    WebHTMLDiv12.ElementClassName := 'login_footer';
    WebHTMLDiv12.HeightStyle := ssAuto;
    WebHTMLDiv12.WidthStyle := ssAuto;
    WebHTMLDiv12.ChildOrder := 4;
    WebHTMLDiv12.ElementPosition := epIgnore;
    WebHTMLDiv12.ElementFont := efCSS;
    WebHTMLDiv12.Role := '';
    btnGast.SetParentComponent(WebHTMLDiv12);
    btnGast.Name := 'btnGast';
    btnGast.Left := 176;
    btnGast.Top := 3;
    btnGast.Width := 121;
    btnGast.Height := 34;
    btnGast.Caption := 'anmelden';
    btnGast.ChildOrder := 7;
    btnGast.ElementClassName := 'login_button_test';
    btnGast.ElementFont := efCSS;
    btnGast.ElementPosition := epIgnore;
    btnGast.HeightStyle := ssAuto;
    btnGast.HeightPercent := 100.000000000000000000;
    btnGast.WidthStyle := ssAuto;
    btnGast.WidthPercent := 100.000000000000000000;
    SetEvent(btnGast, Self, 'OnClick', 'btnGastClick');
    divStartText.SetParentComponent(divStart);
    divStartText.Name := 'divStartText';
    divStartText.Left := 1144;
    divStartText.Top := 165;
    divStartText.Width := 68;
    divStartText.Height := 41;
    divStartText.ElementClassName := 'start_text';
    divStartText.HeightStyle := ssAuto;
    divStartText.WidthStyle := ssAuto;
    divStartText.ChildOrder := 6;
    divStartText.ElementPosition := epIgnore;
    divStartText.ElementFont := efCSS;
    divStartText.HTML.BeginUpdate;
    try
      divStartText.HTML.Clear;
      divStartText.HTML.Add('<B><I>Entdecke die grenzenlose Welt des Segelns mit unserem exklusiven Seglerportal</I>!</B><br><br>');
      divStartText.HTML.Add(#55356#57098' <B>Gemeinschaft erleben:</B> Tauche ein in eine leidenschaftliche Gemeinschaft von Seglern weltweit. Teile deine Erfahrungen, erhalte wertvolle ');
      divStartText.HTML.Add('Ratschl'#228'ge und baue langanhaltende Freundschaften mit Gleichgesinnten auf.<br><br>');
      divStartText.HTML.Add(#55357#56538' <B>Wissensaustausch:</B> Werde Teil einer reichen Wissensquelle! Diskutiere in Foren, erweitere dein nautisches Know-how und profitiere von den ');
      divStartText.HTML.Add('Erfahrungen erfahrener Segler. Vom richtigen Umgang mit der Takelage bis hin zur Navigation bei Nacht '#8211' hier findest du alles, was du wissen ');
      divStartText.HTML.Add('musst.<br><br>');
      divStartText.HTML.Add(#55356#57225' <B>Veranstaltungen und Gruppen</B>: Nehme teil an aufregenden Events, Treffen und Regatten. Werde Mitglied in diversen Gruppen und entdecke ');
      divStartText.HTML.Add('neue Segelreviere, messe dich mit anderen Seglern und erlebe die Faszination des Segelns in bester Gesellschaft.<br><br>');
      divStartText.HTML.Add(#55357#57037#65039'<B> Exklusive Angebote</B>: Genie'#223'e exklusive Rabatte auf Chartergeb'#252'hren. Spare bares Geld und chartere Yachten in der ganzen Welt.<br><br>');
      divStartText.HTML.Add(#55357#56996' <B>Ressourcen und Infos:</B> Greife auf eine F'#252'lle von Ressourcen zu '#8211' von praxisorientierten Anleitungen bis hin zu Expertentipps. Halte dich stets ');
      divStartText.HTML.Add('auf dem neuesten Stand '#252'ber Trends, Technologien und Sicherheitsstandards.<br><br>');
      divStartText.HTML.Add(#55358#56605' <B>Gemeinschaftliche Solidarit'#228't:</B> In unserer Gemeinschaft stehen sich Segler in guten wie in schlechten Zeiten zur Seite. Tausche dich aus, ');
      divStartText.HTML.Add('erhalte Unterst'#252'tzung im Notfall und erlebe die Sicherheit einer starken, segelbegeisterten Gemeinschaft.');
      divStartText.HTML.Add('');
    finally
      divStartText.HTML.EndUpdate;
    end;
    divStartText.Role := '';
    WebHTMLDiv15.SetParentComponent(divStart);
    WebHTMLDiv15.Name := 'WebHTMLDiv15';
    WebHTMLDiv15.Left := 38;
    WebHTMLDiv15.Top := 16;
    WebHTMLDiv15.Width := 1179;
    WebHTMLDiv15.Height := 90;
    WebHTMLDiv15.HeightStyle := ssAuto;
    WebHTMLDiv15.WidthStyle := ssAuto;
    WebHTMLDiv15.ElementPosition := epRelative;
    WebHTMLDiv15.ElementFont := efCSS;
    WebHTMLDiv15.Role := '';
    WebImageControl1.SetParentComponent(WebHTMLDiv15);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 8;
    WebImageControl1.Top := 8;
    WebImageControl1.Width := 425;
    WebImageControl1.Height := 79;
    WebImageControl1.ElementClassName := 'start_logo';
    WebImageControl1.HeightStyle := ssAuto;
    WebImageControl1.WidthStyle := ssAuto;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 100.000000000000000000;
    WebImageControl1.ElementFont := efCSS;
    WebImageControl1.ElementPosition := epIgnore;
    WebImageControl1.Picture.LoadFromFile('uStart.WebHTMLDiv15.WebImageControl1.Picture.png');
    divAngebote.SetParentComponent(WebHTMLDiv1);
    divAngebote.Name := 'divAngebote';
    divAngebote.Left := 13;
    divAngebote.Top := 687;
    divAngebote.Width := 1249;
    divAngebote.Height := 50;
    divAngebote.ElementClassName := 'start_center';
    divAngebote.HeightStyle := ssAuto;
    divAngebote.WidthStyle := ssAuto;
    divAngebote.ChildOrder := 2;
    divAngebote.ElementPosition := epIgnore;
    divAngebote.ElementFont := efCSS;
    divAngebote.Role := '';
    divAngebote.Visible := False;
    divBack.SetParentComponent(WebHTMLDiv1);
    divBack.Name := 'divBack';
    divBack.Left := 13;
    divBack.Top := 743;
    divBack.Width := 1249;
    divBack.Height := 57;
    divBack.ElementClassName := 'login_back';
    divBack.HeightStyle := ssAuto;
    divBack.WidthStyle := ssAuto;
    divBack.ChildOrder := 2;
    divBack.ElementPosition := epRelative;
    divBack.ElementFont := efCSS;
    divBack.Role := '';
    divBack.Visible := False;
    btnGotoLogin.SetParentComponent(divBack);
    btnGotoLogin.Name := 'btnGotoLogin';
    btnGotoLogin.Left := 346;
    btnGotoLogin.Top := 16;
    btnGotoLogin.Width := 196;
    btnGotoLogin.Height := 25;
    btnGotoLogin.Caption := 'zur'#252'ck zur Anmeldung';
    btnGotoLogin.ElementClassName := 'login_button_back';
    btnGotoLogin.ElementFont := efCSS;
    btnGotoLogin.ElementPosition := epIgnore;
    btnGotoLogin.HeightStyle := ssAuto;
    btnGotoLogin.HeightPercent := 100.000000000000000000;
    btnGotoLogin.WidthStyle := ssAuto;
    btnGotoLogin.WidthPercent := 100.000000000000000000;
    divRegisterInfo.SetParentComponent(WebHTMLDiv1);
    divRegisterInfo.Name := 'divRegisterInfo';
    divRegisterInfo.Left := 42;
    divRegisterInfo.Top := 3;
    divRegisterInfo.Width := 1249;
    divRegisterInfo.Height := 139;
    divRegisterInfo.ElementClassName := 'register_info';
    divRegisterInfo.HeightStyle := ssAuto;
    divRegisterInfo.WidthStyle := ssAuto;
    divRegisterInfo.ChildOrder := 3;
    divRegisterInfo.ElementPosition := epRelative;
    divRegisterInfo.ElementFont := efCSS;
    divRegisterInfo.Role := '';
    divRegisterInfo.Visible := False;
    WebHTMLDiv2.SetParentComponent(divRegisterInfo);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 3;
    WebHTMLDiv2.Top := 35;
    WebHTMLDiv2.Width := 1249;
    WebHTMLDiv2.Height := 41;
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ChildOrder := 3;
    WebHTMLDiv2.ElementPosition := epRelative;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.HTML.BeginUpdate;
    try
      WebHTMLDiv2.HTML.Clear;
      WebHTMLDiv2.HTML.Add('<P align="center">Herzlich willkommen an Bord, <BR><BR>');
      WebHTMLDiv2.HTML.Add('');
      WebHTMLDiv2.HTML.Add('Wir freuen uns, dich als neues Mitglied in unserer Segler-Community begr'#252#223'en zu d'#252'rfen. Deine Entscheidung, Teil unserer Segelgemeinschaft zu ');
      WebHTMLDiv2.HTML.Add('werden, ');
      WebHTMLDiv2.HTML.Add('ist wie eine frische Brise, die unser Segelabenteuer bereichert ;-). <BR><BR>');
      WebHTMLDiv2.HTML.Add('');
      WebHTMLDiv2.HTML.Add('Hier auf Segler-Portal.de findest du Gleichgesinnte, erfahrene Segler und eine F'#252'lle an Ressourcen, um dein Segelwissen zu erweitern. Egal, ob du ein ');
      WebHTMLDiv2.HTML.Add('erfahrener Seemann bist oder gerade erst die Segel setzt, hier findest du eine offene und freundliche Gemeinschaft, die sich darauf freut, ihre ');
      WebHTMLDiv2.HTML.Add('Leidenschaft ');
      WebHTMLDiv2.HTML.Add('f'#252'r das Segeln mit dir zu teilen.<BR> <BR>');
      WebHTMLDiv2.HTML.Add('');
      WebHTMLDiv2.HTML.Add('Z'#246'gere nicht, dich aktiv in den Foren und Diskussionen einzubringen, Fragen zu stellen und deine Segelerlebnisse zu teilen. Wir sind hier, um ');
      WebHTMLDiv2.HTML.Add('voneinander ');
      WebHTMLDiv2.HTML.Add('zu lernen und gemeinsam die Faszination des Segelns zu erleben.<BR><BR>');
      WebHTMLDiv2.HTML.Add('');
      WebHTMLDiv2.HTML.Add('Mast- und Schotbruch f'#252'r deine zuk'#252'nftigen Segelabenteuer!! Wir freuen uns darauf, dich besser kennenzulernen und mit dir gemeinsam die Welt der ');
      WebHTMLDiv2.HTML.Add('Segel zu erkunden.<BR><BR>');
      WebHTMLDiv2.HTML.Add('');
      WebHTMLDiv2.HTML.Add('<B>Nach Deiner erfolgreichen Registrierung, haben wir dir eine EMail mit deinen pers'#246'nlichen Zugangsdaten gesendet.  Melde dich mit deinem Login ');
      WebHTMLDiv2.HTML.Add('und deinem Passwort an. Solltest du keine Email von uns erhalten haben, schau bitte sp'#228'ter noch einmal nach und pr'#252'fe auch den Spammordner deines ');
      WebHTMLDiv2.HTML.Add('EMail-Programms.');
      WebHTMLDiv2.HTML.Add('</B><br><br>');
      WebHTMLDiv2.HTML.Add('');
      WebHTMLDiv2.HTML.Add('Fair winds und immer eine handbreit Wasser unterm Kiel!<BR><BR><BR>');
      WebHTMLDiv2.HTML.Add('');
      WebHTMLDiv2.HTML.Add('<I>Dein Segler-Portal-Team</I></P>');
    finally
      WebHTMLDiv2.HTML.EndUpdate;
    end;
    WebHTMLDiv2.Role := '';
    WebHTMLDiv16.SetParentComponent(divRegisterInfo);
    WebHTMLDiv16.Name := 'WebHTMLDiv16';
    WebHTMLDiv16.Left := 8;
    WebHTMLDiv16.Top := 82;
    WebHTMLDiv16.Width := 310;
    WebHTMLDiv16.Height := 41;
    WebHTMLDiv16.ElementClassName := 'register_footer';
    WebHTMLDiv16.HeightStyle := ssAuto;
    WebHTMLDiv16.WidthStyle := ssAuto;
    WebHTMLDiv16.ChildOrder := 4;
    WebHTMLDiv16.ElementPosition := epIgnore;
    WebHTMLDiv16.ElementFont := efCSS;
    WebHTMLDiv16.Role := '';
    btnBack2Login.SetParentComponent(WebHTMLDiv16);
    btnBack2Login.Name := 'btnBack2Login';
    btnBack2Login.Left := 176;
    btnBack2Login.Top := 4;
    btnBack2Login.Width := 121;
    btnBack2Login.Height := 34;
    btnBack2Login.Caption := 'zur Anmeldung';
    btnBack2Login.ChildOrder := 7;
    btnBack2Login.ElementClassName := 'login_button_register';
    btnBack2Login.ElementFont := efCSS;
    btnBack2Login.ElementPosition := epIgnore;
    btnBack2Login.HeightStyle := ssAuto;
    btnBack2Login.HeightPercent := 100.000000000000000000;
    btnBack2Login.WidthStyle := ssAuto;
    btnBack2Login.WidthPercent := 100.000000000000000000;
    divByeBye1.SetParentComponent(WebHTMLDiv1);
    divByeBye1.Name := 'divByeBye1';
    divByeBye1.Left := 13;
    divByeBye1.Top := 880;
    divByeBye1.Width := 1249;
    divByeBye1.Height := 57;
    divByeBye1.ElementClassName := 'register_info';
    divByeBye1.HeightStyle := ssAuto;
    divByeBye1.WidthStyle := ssAuto;
    divByeBye1.ChildOrder := 3;
    divByeBye1.ElementPosition := epRelative;
    divByeBye1.ElementFont := efCSS;
    divByeBye1.Role := '';
    divByeBye1.Visible := False;
    WebHTMLDiv19.SetParentComponent(divByeBye1);
    WebHTMLDiv19.Name := 'WebHTMLDiv19';
    WebHTMLDiv19.Left := 182;
    WebHTMLDiv19.Top := 3;
    WebHTMLDiv19.Width := 310;
    WebHTMLDiv19.Height := 41;
    WebHTMLDiv19.ElementClassName := 'register_footer';
    WebHTMLDiv19.HeightStyle := ssAuto;
    WebHTMLDiv19.WidthStyle := ssAuto;
    WebHTMLDiv19.ChildOrder := 4;
    WebHTMLDiv19.ElementPosition := epIgnore;
    WebHTMLDiv19.ElementFont := efCSS;
    WebHTMLDiv19.Role := '';
    WebButton1.SetParentComponent(WebHTMLDiv19);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 176;
    WebButton1.Top := 3;
    WebButton1.Width := 121;
    WebButton1.Height := 34;
    WebButton1.Caption := 'bis zum n'#228'chsten mal...';
    WebButton1.ChildOrder := 7;
    WebButton1.ElementClassName := 'login_button_register';
    WebButton1.ElementFont := efCSS;
    WebButton1.ElementPosition := epIgnore;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthStyle := ssAuto;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    divstartseite.SetParentComponent(Self);
    divstartseite.Name := 'divstartseite';
    divstartseite.Left := 8;
    divstartseite.Top := 127;
    divstartseite.Width := 1278;
    divstartseite.Height := 698;
    divstartseite.ElementClassName := 'start_div';
    divstartseite.HeightStyle := ssAuto;
    divstartseite.WidthStyle := ssAuto;
    divstartseite.ChildOrder := 1;
    divstartseite.ElementPosition := epIgnore;
    divstartseite.ElementFont := efCSS;
    divstartseite.Role := '';
    divLanding.SetParentComponent(divstartseite);
    divLanding.Name := 'divLanding';
    divLanding.Left := 504;
    divLanding.Top := 13;
    divLanding.Width := 756;
    divLanding.Height := 674;
    divLanding.ElementClassName := 'start_client';
    divLanding.HeightStyle := ssAuto;
    divLanding.WidthStyle := ssAuto;
    divLanding.ChildOrder := 1;
    divLanding.ElementPosition := epIgnore;
    divLanding.ElementFont := efCSS;
    divLanding.Role := '';
    divLosgehts.SetParentComponent(divLanding);
    divLosgehts.Name := 'divLosgehts';
    divLosgehts.Left := 19;
    divLosgehts.Top := 3;
    divLosgehts.Width := 720;
    divLosgehts.Height := 112;
    divLosgehts.ElementClassName := 'start-text1';
    divLosgehts.HeightStyle := ssAuto;
    divLosgehts.WidthStyle := ssAuto;
    divLosgehts.ElementPosition := epIgnore;
    divLosgehts.ElementFont := efCSS;
    divLosgehts.Role := '';
    WebHTMLDiv17.SetParentComponent(divLosgehts);
    WebHTMLDiv17.Name := 'WebHTMLDiv17';
    WebHTMLDiv17.Left := 16;
    WebHTMLDiv17.Top := 8;
    WebHTMLDiv17.Width := 689;
    WebHTMLDiv17.Height := 49;
    WebHTMLDiv17.ElementClassName := 'start-header';
    WebHTMLDiv17.HeightStyle := ssAuto;
    WebHTMLDiv17.WidthStyle := ssAuto;
    WebHTMLDiv17.ElementPosition := epRelative;
    WebHTMLDiv17.ElementFont := efCSS;
    WebHTMLDiv17.HTML.BeginUpdate;
    try
      WebHTMLDiv17.HTML.Clear;
      WebHTMLDiv17.HTML.Add('<FONT  size="18" size="15"><B>Segler-Portal.de</B></FONT><br><br><p style="line-height: 1em;">All Inclusive:</p>');
      WebHTMLDiv17.HTML.Add('<p style="line-height: 1.3em;">Gemeinschaft, Gleichgesinnte finden, Social Media, Wissensaustausch, Lernplattform, Tutorials, Hilfen zur ');
      WebHTMLDiv17.HTML.Add('T'#246'rnvorbereitung, Sicherheitsanweisung, Veranstaltungen anlegen und teilnehmen, Gruppen bilden und teilnehmen</p>');
      WebHTMLDiv17.HTML.Add('');
    finally
      WebHTMLDiv17.HTML.EndUpdate;
    end;
    WebHTMLDiv17.Role := '';
    WebHTMLDiv20.SetParentComponent(divLosgehts);
    WebHTMLDiv20.Name := 'WebHTMLDiv20';
    WebHTMLDiv20.Left := 16;
    WebHTMLDiv20.Top := 63;
    WebHTMLDiv20.Width := 689;
    WebHTMLDiv20.Height := 41;
    WebHTMLDiv20.ElementClassName := 'start_button_div';
    WebHTMLDiv20.HeightStyle := ssAuto;
    WebHTMLDiv20.WidthStyle := ssAuto;
    WebHTMLDiv20.ChildOrder := 1;
    WebHTMLDiv20.ElementPosition := epRelative;
    WebHTMLDiv20.ElementFont := efCSS;
    WebHTMLDiv20.Role := '';
    miLosGehts.SetParentComponent(WebHTMLDiv20);
    miLosGehts.Name := 'miLosGehts';
    miLosGehts.Left := 21;
    miLosGehts.Top := 13;
    miLosGehts.Width := 96;
    miLosGehts.Height := 25;
    miLosGehts.Caption := '6 gute Gr'#252'nde';
    miLosGehts.ChildOrder := 1;
    miLosGehts.ElementClassName := 'start_button-lets-go';
    miLosGehts.ElementFont := efCSS;
    miLosGehts.ElementPosition := epIgnore;
    miLosGehts.HeightStyle := ssAuto;
    miLosGehts.HeightPercent := 100.000000000000000000;
    miLosGehts.WidthStyle := ssAuto;
    miLosGehts.WidthPercent := 100.000000000000000000;
    SetEvent(miLosGehts, Self, 'OnClick', 'miLosGehtsClick');
    btnShowLoginDiv.SetParentComponent(WebHTMLDiv20);
    btnShowLoginDiv.Name := 'btnShowLoginDiv';
    btnShowLoginDiv.Left := 225;
    btnShowLoginDiv.Top := 13;
    btnShowLoginDiv.Width := 96;
    btnShowLoginDiv.Height := 25;
    btnShowLoginDiv.Caption := 'Login';
    btnShowLoginDiv.ElementClassName := 'start_button-login';
    btnShowLoginDiv.ElementFont := efCSS;
    btnShowLoginDiv.ElementPosition := epIgnore;
    btnShowLoginDiv.HeightStyle := ssAuto;
    btnShowLoginDiv.HeightPercent := 100.000000000000000000;
    btnShowLoginDiv.WidthStyle := ssAuto;
    btnShowLoginDiv.WidthPercent := 100.000000000000000000;
    SetEvent(btnShowLoginDiv, Self, 'OnClick', 'btnShowLoginDivClick');
    WebButton9.SetParentComponent(WebHTMLDiv20);
    WebButton9.Name := 'WebButton9';
    WebButton9.Left := 123;
    WebButton9.Top := 13;
    WebButton9.Width := 96;
    WebButton9.Height := 25;
    WebButton9.Caption := 'Zugang';
    WebButton9.ChildOrder := 2;
    WebButton9.ElementClassName := 'start_button-lets-go';
    WebButton9.ElementFont := efCSS;
    WebButton9.ElementPosition := epIgnore;
    WebButton9.HeightStyle := ssAuto;
    WebButton9.HeightPercent := 100.000000000000000000;
    WebButton9.WidthStyle := ssAuto;
    WebButton9.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton9, Self, 'OnClick', 'miPreiseClick');
    divGruende.SetParentComponent(divLanding);
    divGruende.Name := 'divGruende';
    divGruende.Left := 19;
    divGruende.Top := 121;
    divGruende.Width := 721;
    divGruende.Height := 352;
    divGruende.ElementClassName := 'start-text2';
    divGruende.HeightStyle := ssAuto;
    divGruende.WidthStyle := ssAuto;
    divGruende.ChildOrder := 1;
    divGruende.ElementPosition := epIgnore;
    divGruende.ElementFont := efCSS;
    divGruende.Role := '';
    divGruende.Visible := False;
    WebHTMLDiv22.SetParentComponent(divGruende);
    WebHTMLDiv22.Name := 'WebHTMLDiv22';
    WebHTMLDiv22.Left := 16;
    WebHTMLDiv22.Top := 71;
    WebHTMLDiv22.Width := 689;
    WebHTMLDiv22.Height := 218;
    WebHTMLDiv22.ElementClassName := 'start-gruende-wrapper';
    WebHTMLDiv22.HeightStyle := ssAuto;
    WebHTMLDiv22.WidthStyle := ssAuto;
    WebHTMLDiv22.ChildOrder := 1;
    WebHTMLDiv22.ElementPosition := epRelative;
    WebHTMLDiv22.ElementFont := efCSS;
    WebHTMLDiv22.Role := '';
    divGruende1.SetParentComponent(WebHTMLDiv22);
    divGruende1.Name := 'divGruende1';
    divGruende1.Left := 3;
    divGruende1.Top := 27;
    divGruende1.Width := 265;
    divGruende1.Height := 118;
    divGruende1.ElementClassName := 'start_gruende';
    divGruende1.HeightStyle := ssAuto;
    divGruende1.WidthStyle := ssAuto;
    divGruende1.ElementPosition := epIgnore;
    divGruende1.ElementFont := efCSS;
    divGruende1.Role := '';
    imgGruende1.SetParentComponent(divGruende1);
    imgGruende1.Name := 'imgGruende1';
    imgGruende1.Left := -3;
    imgGruende1.Top := 8;
    imgGruende1.Width := 254;
    imgGruende1.Height := 89;
    imgGruende1.ElementClassName := 'start-gruende-foto';
    imgGruende1.HeightStyle := ssAuto;
    imgGruende1.WidthStyle := ssAuto;
    imgGruende1.HeightPercent := 100.000000000000000000;
    imgGruende1.WidthPercent := 100.000000000000000000;
    imgGruende1.ChildOrder := 1;
    imgGruende1.ElementFont := efCSS;
    imgGruende1.ElementPosition := epIgnore;
    divGruende2.SetParentComponent(divGruende1);
    divGruende2.Name := 'divGruende2';
    divGruende2.Left := 16;
    divGruende2.Top := 32;
    divGruende2.Width := 193;
    divGruende2.Height := 41;
    divGruende2.ElementClassName := 'start-gruende-text';
    divGruende2.HeightStyle := ssAuto;
    divGruende2.WidthStyle := ssAuto;
    divGruende2.ChildOrder := 1;
    divGruende2.ElementPosition := epIgnore;
    divGruende2.ElementFont := efCSS;
    divGruende2.HTML.BeginUpdate;
    try
      divGruende2.HTML.Clear;
      divGruende2.HTML.Add('<FONT size="5"><B>Gemeinschaft</B></FONT><br>');
      divGruende2.HTML.Add('<FONT  size="" size="3"><br>Social Media, chatten,  Freunde und Mitsegler finden, Tipps und Tricks von erfahrenen Seglern erhalten ...</FONT>');
      divGruende2.HTML.Add('');
    finally
      divGruende2.HTML.EndUpdate;
    end;
    divGruende2.Role := '';
    WebHTMLDiv27.SetParentComponent(WebHTMLDiv22);
    WebHTMLDiv27.Name := 'WebHTMLDiv27';
    WebHTMLDiv27.Left := 42;
    WebHTMLDiv27.Top := 106;
    WebHTMLDiv27.Width := 265;
    WebHTMLDiv27.Height := 87;
    WebHTMLDiv27.ElementClassName := 'start_gruende';
    WebHTMLDiv27.HeightStyle := ssAuto;
    WebHTMLDiv27.WidthStyle := ssAuto;
    WebHTMLDiv27.ChildOrder := 1;
    WebHTMLDiv27.ElementPosition := epIgnore;
    WebHTMLDiv27.ElementFont := efCSS;
    WebHTMLDiv27.Role := '';
    imgGruende2.SetParentComponent(WebHTMLDiv27);
    imgGruende2.Name := 'imgGruende2';
    imgGruende2.Left := 24;
    imgGruende2.Top := -11;
    imgGruende2.Width := 254;
    imgGruende2.Height := 88;
    imgGruende2.ElementClassName := 'start-gruende-foto';
    imgGruende2.HeightStyle := ssAuto;
    imgGruende2.WidthStyle := ssAuto;
    imgGruende2.HeightPercent := 100.000000000000000000;
    imgGruende2.WidthPercent := 100.000000000000000000;
    imgGruende2.ChildOrder := 1;
    imgGruende2.ElementFont := efCSS;
    imgGruende2.ElementPosition := epIgnore;
    WebHTMLDiv29.SetParentComponent(WebHTMLDiv27);
    WebHTMLDiv29.Name := 'WebHTMLDiv29';
    WebHTMLDiv29.Left := 42;
    WebHTMLDiv29.Top := 16;
    WebHTMLDiv29.Width := 193;
    WebHTMLDiv29.Height := 41;
    WebHTMLDiv29.ElementClassName := 'start-gruende-text';
    WebHTMLDiv29.HeightStyle := ssAuto;
    WebHTMLDiv29.WidthStyle := ssAuto;
    WebHTMLDiv29.ChildOrder := 1;
    WebHTMLDiv29.ElementPosition := epIgnore;
    WebHTMLDiv29.ElementFont := efCSS;
    WebHTMLDiv29.HTML.BeginUpdate;
    try
      WebHTMLDiv29.HTML.Clear;
      WebHTMLDiv29.HTML.Add('<FONT size="5"><B>Wissensaustausch</B></FONT><br>');
      WebHTMLDiv29.HTML.Add('<FONT  size="" size="3"><br>Tutorials und Blogs zu technischen Themen, Videos und Anleitungen um Reparaturen selbst durchzuf'#252'hren... </font>');
      WebHTMLDiv29.HTML.Add('');
    finally
      WebHTMLDiv29.HTML.EndUpdate;
    end;
    WebHTMLDiv29.Role := '';
    WebHTMLDiv32.SetParentComponent(WebHTMLDiv22);
    WebHTMLDiv32.Name := 'WebHTMLDiv32';
    WebHTMLDiv32.Left := 336;
    WebHTMLDiv32.Top := 106;
    WebHTMLDiv32.Width := 247;
    WebHTMLDiv32.Height := 103;
    WebHTMLDiv32.ElementClassName := 'start_gruende';
    WebHTMLDiv32.HeightStyle := ssAuto;
    WebHTMLDiv32.WidthStyle := ssAuto;
    WebHTMLDiv32.ChildOrder := 1;
    WebHTMLDiv32.ElementPosition := epIgnore;
    WebHTMLDiv32.ElementFont := efCSS;
    WebHTMLDiv32.Role := '';
    imgGruende3.SetParentComponent(WebHTMLDiv32);
    imgGruende3.Name := 'imgGruende3';
    imgGruende3.Left := 16;
    imgGruende3.Top := 3;
    imgGruende3.Width := 254;
    imgGruende3.Height := 94;
    imgGruende3.ElementClassName := 'start-gruende-foto';
    imgGruende3.HeightStyle := ssAuto;
    imgGruende3.WidthStyle := ssAuto;
    imgGruende3.HeightPercent := 100.000000000000000000;
    imgGruende3.WidthPercent := 100.000000000000000000;
    imgGruende3.ChildOrder := 1;
    imgGruende3.ElementFont := efCSS;
    imgGruende3.ElementPosition := epIgnore;
    WebHTMLDiv33.SetParentComponent(WebHTMLDiv32);
    WebHTMLDiv33.Name := 'WebHTMLDiv33';
    WebHTMLDiv33.Left := 29;
    WebHTMLDiv33.Top := 36;
    WebHTMLDiv33.Width := 193;
    WebHTMLDiv33.Height := 41;
    WebHTMLDiv33.ElementClassName := 'start-gruende-text';
    WebHTMLDiv33.HeightStyle := ssAuto;
    WebHTMLDiv33.WidthStyle := ssAuto;
    WebHTMLDiv33.ChildOrder := 1;
    WebHTMLDiv33.ElementPosition := epIgnore;
    WebHTMLDiv33.ElementFont := efCSS;
    WebHTMLDiv33.HTML.BeginUpdate;
    try
      WebHTMLDiv33.HTML.Clear;
      WebHTMLDiv33.HTML.Add('<FONT size="5"><B>Sicherheitseinweisung</B></FONT><br>');
      WebHTMLDiv33.HTML.Add('<FONT  size="" size="3"><br>mit vielen Videos und Fotos, spart enorm viel Zeit vor T'#246'rnbeginn,<br>');
      WebHTMLDiv33.HTML.Add('protokolliert die Themen der Sicherheitseinweisung,<br>');
      WebHTMLDiv33.HTML.Add('reduziert die Skipperhaftung durch schriftlichen Nachweis des besprochenen...</font>');
      WebHTMLDiv33.HTML.Add('');
      WebHTMLDiv33.HTML.Add('');
    finally
      WebHTMLDiv33.HTML.EndUpdate;
    end;
    WebHTMLDiv33.Role := '';
    WebHTMLDiv34.SetParentComponent(WebHTMLDiv22);
    WebHTMLDiv34.Name := 'WebHTMLDiv34';
    WebHTMLDiv34.Left := 283;
    WebHTMLDiv34.Top := 0;
    WebHTMLDiv34.Width := 177;
    WebHTMLDiv34.Height := 100;
    WebHTMLDiv34.ElementClassName := 'start_gruende';
    WebHTMLDiv34.HeightStyle := ssAuto;
    WebHTMLDiv34.WidthStyle := ssAuto;
    WebHTMLDiv34.ChildOrder := 1;
    WebHTMLDiv34.ElementPosition := epIgnore;
    WebHTMLDiv34.ElementFont := efCSS;
    WebHTMLDiv34.Role := '';
    imgGruende4.SetParentComponent(WebHTMLDiv34);
    imgGruende4.Name := 'imgGruende4';
    imgGruende4.Left := 11;
    imgGruende4.Top := -3;
    imgGruende4.Width := 158;
    imgGruende4.Height := 83;
    imgGruende4.ElementClassName := 'start-gruende-foto';
    imgGruende4.HeightStyle := ssAuto;
    imgGruende4.WidthStyle := ssAuto;
    imgGruende4.HeightPercent := 100.000000000000000000;
    imgGruende4.WidthPercent := 100.000000000000000000;
    imgGruende4.ChildOrder := 1;
    imgGruende4.ElementFont := efCSS;
    imgGruende4.ElementPosition := epIgnore;
    WebHTMLDiv35.SetParentComponent(WebHTMLDiv34);
    WebHTMLDiv35.Name := 'WebHTMLDiv35';
    WebHTMLDiv35.Left := 19;
    WebHTMLDiv35.Top := 16;
    WebHTMLDiv35.Width := 116;
    WebHTMLDiv35.Height := 41;
    WebHTMLDiv35.ElementClassName := 'start-gruende-text';
    WebHTMLDiv35.HeightStyle := ssAuto;
    WebHTMLDiv35.WidthStyle := ssAuto;
    WebHTMLDiv35.ChildOrder := 1;
    WebHTMLDiv35.ElementPosition := epIgnore;
    WebHTMLDiv35.ElementFont := efCSS;
    WebHTMLDiv35.HTML.BeginUpdate;
    try
      WebHTMLDiv35.HTML.Clear;
      WebHTMLDiv35.HTML.Add('<FONT size="5"><B>Seemannschaft</B></FONT><br>');
      WebHTMLDiv35.HTML.Add('<FONT  size="" size="3"><br>Lektionen, Tutorials,  Segel-Lexikon, mit vielen Videos und Bilder...</font>');
      WebHTMLDiv35.HTML.Add('');
      WebHTMLDiv35.HTML.Add('');
    finally
      WebHTMLDiv35.HTML.EndUpdate;
    end;
    WebHTMLDiv35.Role := '';
    WebHTMLDiv36.SetParentComponent(WebHTMLDiv22);
    WebHTMLDiv36.Name := 'WebHTMLDiv36';
    WebHTMLDiv36.Left := 313;
    WebHTMLDiv36.Top := 63;
    WebHTMLDiv36.Width := 265;
    WebHTMLDiv36.Height := 91;
    WebHTMLDiv36.ElementClassName := 'start_gruende';
    WebHTMLDiv36.HeightStyle := ssAuto;
    WebHTMLDiv36.WidthStyle := ssAuto;
    WebHTMLDiv36.ChildOrder := 1;
    WebHTMLDiv36.ElementPosition := epIgnore;
    WebHTMLDiv36.ElementFont := efCSS;
    WebHTMLDiv36.Role := '';
    imgGruende5.SetParentComponent(WebHTMLDiv36);
    imgGruende5.Name := 'imgGruende5';
    imgGruende5.Left := 16;
    imgGruende5.Top := 3;
    imgGruende5.Width := 254;
    imgGruende5.Height := 70;
    imgGruende5.ElementClassName := 'start-gruende-foto';
    imgGruende5.HeightStyle := ssAuto;
    imgGruende5.WidthStyle := ssAuto;
    imgGruende5.HeightPercent := 100.000000000000000000;
    imgGruende5.WidthPercent := 100.000000000000000000;
    imgGruende5.ChildOrder := 1;
    imgGruende5.ElementFont := efCSS;
    imgGruende5.ElementPosition := epIgnore;
    WebHTMLDiv37.SetParentComponent(WebHTMLDiv36);
    WebHTMLDiv37.Name := 'WebHTMLDiv37';
    WebHTMLDiv37.Left := 21;
    WebHTMLDiv37.Top := 17;
    WebHTMLDiv37.Width := 140;
    WebHTMLDiv37.Height := 41;
    WebHTMLDiv37.ElementClassName := 'start-gruende-text';
    WebHTMLDiv37.HeightStyle := ssAuto;
    WebHTMLDiv37.WidthStyle := ssAuto;
    WebHTMLDiv37.ChildOrder := 1;
    WebHTMLDiv37.ElementPosition := epIgnore;
    WebHTMLDiv37.ElementFont := efCSS;
    WebHTMLDiv37.HTML.BeginUpdate;
    try
      WebHTMLDiv37.HTML.Clear;
      WebHTMLDiv37.HTML.Add('<FONT size="5"><B>Gruppen & Events</B></FONT><br>');
      WebHTMLDiv37.HTML.Add('<FONT  size="" size="3"><br>werde Teil diverser Gruppen (z.B. Reisen, Technik u.a. ) und profitiere vom Schwarmwissen der Mitglieder. <br>');
      WebHTMLDiv37.HTML.Add('Nimm an Events (z.B. Segelt'#246'rns, Seminaren u.'#228'.)  teil oder erstelle eigene, '#246'ffentliche oder private Gruppen und Events und lade andere ');
      WebHTMLDiv37.HTML.Add('dazu ein...</font>');
      WebHTMLDiv37.HTML.Add('');
      WebHTMLDiv37.HTML.Add('');
    finally
      WebHTMLDiv37.HTML.EndUpdate;
    end;
    WebHTMLDiv37.Role := '';
    WebHTMLDiv38.SetParentComponent(WebHTMLDiv22);
    WebHTMLDiv38.Name := 'WebHTMLDiv38';
    WebHTMLDiv38.Left := 454;
    WebHTMLDiv38.Top := 18;
    WebHTMLDiv38.Width := 232;
    WebHTMLDiv38.Height := 118;
    WebHTMLDiv38.ElementClassName := 'start_gruende';
    WebHTMLDiv38.HeightStyle := ssAuto;
    WebHTMLDiv38.WidthStyle := ssAuto;
    WebHTMLDiv38.ChildOrder := 1;
    WebHTMLDiv38.ElementPosition := epIgnore;
    WebHTMLDiv38.ElementFont := efCSS;
    WebHTMLDiv38.Role := '';
    imgGruende6.SetParentComponent(WebHTMLDiv38);
    imgGruende6.Name := 'imgGruende6';
    imgGruende6.Left := 3;
    imgGruende6.Top := 4;
    imgGruende6.Width := 246;
    imgGruende6.Height := 82;
    imgGruende6.ElementClassName := 'start-gruende-foto';
    imgGruende6.HeightStyle := ssAuto;
    imgGruende6.WidthStyle := ssAuto;
    imgGruende6.HeightPercent := 100.000000000000000000;
    imgGruende6.WidthPercent := 100.000000000000000000;
    imgGruende6.ChildOrder := 1;
    imgGruende6.ElementFont := efCSS;
    imgGruende6.ElementPosition := epIgnore;
    WebHTMLDiv39.SetParentComponent(WebHTMLDiv38);
    WebHTMLDiv39.Name := 'WebHTMLDiv39';
    WebHTMLDiv39.Left := 20;
    WebHTMLDiv39.Top := 4;
    WebHTMLDiv39.Width := 193;
    WebHTMLDiv39.Height := 41;
    WebHTMLDiv39.ElementClassName := 'start-gruende-text';
    WebHTMLDiv39.HeightStyle := ssAuto;
    WebHTMLDiv39.WidthStyle := ssAuto;
    WebHTMLDiv39.ChildOrder := 1;
    WebHTMLDiv39.ElementPosition := epIgnore;
    WebHTMLDiv39.ElementFont := efCSS;
    WebHTMLDiv39.HTML.BeginUpdate;
    try
      WebHTMLDiv39.HTML.Clear;
      WebHTMLDiv39.HTML.Add('<FONT size="5"><B>Rabatte</B></FONT><br>');
      WebHTMLDiv39.HTML.Add('<FONT  size="" size="3"><br>erhalte Rabatte bei Eink'#228'ufen bei unseren Partner...</font>');
      WebHTMLDiv39.HTML.Add('');
      WebHTMLDiv39.HTML.Add('');
    finally
      WebHTMLDiv39.HTML.EndUpdate;
    end;
    WebHTMLDiv39.Role := '';
    WebHTMLDiv25.SetParentComponent(divGruende);
    WebHTMLDiv25.Name := 'WebHTMLDiv25';
    WebHTMLDiv25.Left := 16;
    WebHTMLDiv25.Top := 16;
    WebHTMLDiv25.Width := 689;
    WebHTMLDiv25.Height := 49;
    WebHTMLDiv25.ElementClassName := 'start-header';
    WebHTMLDiv25.HeightStyle := ssAuto;
    WebHTMLDiv25.WidthStyle := ssAuto;
    WebHTMLDiv25.ElementPosition := epRelative;
    WebHTMLDiv25.ElementFont := efCSS;
    WebHTMLDiv25.HTML.BeginUpdate;
    try
      WebHTMLDiv25.HTML.Clear;
      WebHTMLDiv25.HTML.Add('<FONT  size="15"><B>6 gute Gr'#252'nde</B></FONT>');
    finally
      WebHTMLDiv25.HTML.EndUpdate;
    end;
    WebHTMLDiv25.Role := '';
    WebHTMLDiv14.SetParentComponent(divGruende);
    WebHTMLDiv14.Name := 'WebHTMLDiv14';
    WebHTMLDiv14.Left := 16;
    WebHTMLDiv14.Top := 295;
    WebHTMLDiv14.Width := 689;
    WebHTMLDiv14.Height := 41;
    WebHTMLDiv14.ElementClassName := 'start_button_div';
    WebHTMLDiv14.HeightStyle := ssAuto;
    WebHTMLDiv14.WidthStyle := ssAuto;
    WebHTMLDiv14.ChildOrder := 2;
    WebHTMLDiv14.ElementPosition := epRelative;
    WebHTMLDiv14.ElementFont := efCSS;
    WebHTMLDiv14.Role := '';
    WebButton2.SetParentComponent(WebHTMLDiv14);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 14;
    WebButton2.Top := 13;
    WebButton2.Width := 96;
    WebButton2.Height := 25;
    WebButton2.Caption := 'Zugang';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementClassName := 'start_button-lets-go';
    WebButton2.ElementFont := efCSS;
    WebButton2.ElementPosition := epIgnore;
    WebButton2.HeightStyle := ssAuto;
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthStyle := ssAuto;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'miPreiseClick');
    WebButton5.SetParentComponent(WebHTMLDiv14);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 116;
    WebButton5.Top := 13;
    WebButton5.Width := 96;
    WebButton5.Height := 25;
    WebButton5.Caption := 'Login';
    WebButton5.ElementClassName := 'start_button-login';
    WebButton5.ElementFont := efCSS;
    WebButton5.ElementPosition := epIgnore;
    WebButton5.HeightStyle := ssAuto;
    WebButton5.HeightPercent := 100.000000000000000000;
    WebButton5.WidthStyle := ssAuto;
    WebButton5.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton5, Self, 'OnClick', 'btnShowLoginDivClick');
    divPreise.SetParentComponent(divLanding);
    divPreise.Name := 'divPreise';
    divPreise.Left := 19;
    divPreise.Top := 479;
    divPreise.Width := 721;
    divPreise.Height := 161;
    divPreise.ElementClassName := 'start-text3';
    divPreise.HeightStyle := ssAuto;
    divPreise.WidthStyle := ssAuto;
    divPreise.ChildOrder := 2;
    divPreise.ElementPosition := epIgnore;
    divPreise.ElementFont := efCSS;
    divPreise.Role := '';
    divPreise.Visible := False;
    divPakete.SetParentComponent(divPreise);
    divPakete.Name := 'divPakete';
    divPakete.Left := 16;
    divPakete.Top := 74;
    divPakete.Width := 689;
    divPakete.Height := 31;
    divPakete.ElementClassName := 'start-gruende-wrapper';
    divPakete.HeightStyle := ssAuto;
    divPakete.WidthStyle := ssAuto;
    divPakete.ChildOrder := 1;
    divPakete.ElementPosition := epRelative;
    divPakete.ElementFont := efCSS;
    divPakete.Role := '';
    WebHTMLDiv55.SetParentComponent(divPreise);
    WebHTMLDiv55.Name := 'WebHTMLDiv55';
    WebHTMLDiv55.Left := 16;
    WebHTMLDiv55.Top := 19;
    WebHTMLDiv55.Width := 689;
    WebHTMLDiv55.Height := 49;
    WebHTMLDiv55.ElementClassName := 'start-header';
    WebHTMLDiv55.HeightStyle := ssAuto;
    WebHTMLDiv55.WidthStyle := ssAuto;
    WebHTMLDiv55.ElementPosition := epRelative;
    WebHTMLDiv55.ElementFont := efCSS;
    WebHTMLDiv55.HTML.BeginUpdate;
    try
      WebHTMLDiv55.HTML.Clear;
      WebHTMLDiv55.HTML.Add('<FONT  size="15"><B>Mitglied werden</B></FONT><br><br>');
      WebHTMLDiv55.HTML.Add('<B>Du bist noch nicht dabei?</B><BR>');
      WebHTMLDiv55.HTML.Add('Dann registriere dich jetzt...');
      WebHTMLDiv55.HTML.Add('');
    finally
      WebHTMLDiv55.HTML.EndUpdate;
    end;
    WebHTMLDiv55.Role := '';
    WebHTMLDiv26.SetParentComponent(divPreise);
    WebHTMLDiv26.Name := 'WebHTMLDiv26';
    WebHTMLDiv26.Left := 16;
    WebHTMLDiv26.Top := 111;
    WebHTMLDiv26.Width := 689;
    WebHTMLDiv26.Height := 41;
    WebHTMLDiv26.ElementClassName := 'start_button_div';
    WebHTMLDiv26.HeightStyle := ssAuto;
    WebHTMLDiv26.WidthStyle := ssAuto;
    WebHTMLDiv26.ChildOrder := 2;
    WebHTMLDiv26.ElementPosition := epRelative;
    WebHTMLDiv26.ElementFont := efCSS;
    WebHTMLDiv26.Role := '';
    WebButton12.SetParentComponent(WebHTMLDiv26);
    WebButton12.Name := 'WebButton12';
    WebButton12.Left := 115;
    WebButton12.Top := 13;
    WebButton12.Width := 96;
    WebButton12.Height := 25;
    WebButton12.Caption := 'Login';
    WebButton12.ElementClassName := 'start_button-login';
    WebButton12.ElementFont := efCSS;
    WebButton12.ElementPosition := epIgnore;
    WebButton12.HeightStyle := ssAuto;
    WebButton12.HeightPercent := 100.000000000000000000;
    WebButton12.WidthStyle := ssAuto;
    WebButton12.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton12, Self, 'OnClick', 'btnShowLoginDivClick');
    WebButton13.SetParentComponent(WebHTMLDiv26);
    WebButton13.Name := 'WebButton13';
    WebButton13.Left := 13;
    WebButton13.Top := 13;
    WebButton13.Width := 96;
    WebButton13.Height := 25;
    WebButton13.Caption := '6 gute Gr'#252'nde';
    WebButton13.ChildOrder := 1;
    WebButton13.ElementClassName := 'start_button-lets-go';
    WebButton13.ElementFont := efCSS;
    WebButton13.ElementPosition := epIgnore;
    WebButton13.HeightStyle := ssAuto;
    WebButton13.HeightPercent := 100.000000000000000000;
    WebButton13.WidthStyle := ssAuto;
    WebButton13.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton13, Self, 'OnClick', 'miLosGehtsClick');
    divLogin.SetParentComponent(divstartseite);
    divLogin.Name := 'divLogin';
    divLogin.Left := 28;
    divLogin.Top := 18;
    divLogin.Width := 429;
    divLogin.Height := 360;
    divLogin.ElementClassName := 'start_client';
    divLogin.HeightStyle := ssAuto;
    divLogin.WidthStyle := ssAuto;
    divLogin.ChildOrder := 1;
    divLogin.ElementPosition := epIgnore;
    divLogin.ElementFont := efCSS;
    divLogin.Role := '';
    divLogin.Visible := False;
    divLogin1.SetParentComponent(divLogin);
    divLogin1.Name := 'divLogin1';
    divLogin1.Left := 3;
    divLogin1.Top := 3;
    divLogin1.Width := 398;
    divLogin1.Height := 342;
    divLogin1.ElementClassName := 'start-login-wrapper';
    divLogin1.HeightStyle := ssAuto;
    divLogin1.WidthStyle := ssAuto;
    divLogin1.ElementPosition := epIgnore;
    divLogin1.ElementFont := efCSS;
    divLogin1.Role := '';
    WebHTMLDiv42.SetParentComponent(divLogin1);
    WebHTMLDiv42.Name := 'WebHTMLDiv42';
    WebHTMLDiv42.Left := 16;
    WebHTMLDiv42.Top := 12;
    WebHTMLDiv42.Width := 369;
    WebHTMLDiv42.Height := 49;
    WebHTMLDiv42.ElementClassName := 'start-header';
    WebHTMLDiv42.HeightStyle := ssAuto;
    WebHTMLDiv42.WidthStyle := ssAuto;
    WebHTMLDiv42.ElementPosition := epRelative;
    WebHTMLDiv42.ElementFont := efCSS;
    WebHTMLDiv42.HTML.BeginUpdate;
    try
      WebHTMLDiv42.HTML.Clear;
      WebHTMLDiv42.HTML.Add('<FONT  size="18" size="15"><B>Segler-Portal.de</B></FONT>');
      WebHTMLDiv42.HTML.Add('');
    finally
      WebHTMLDiv42.HTML.EndUpdate;
    end;
    WebHTMLDiv42.Role := '';
    WebHTMLDiv43.SetParentComponent(divLogin1);
    WebHTMLDiv43.Name := 'WebHTMLDiv43';
    WebHTMLDiv43.Left := 16;
    WebHTMLDiv43.Top := 290;
    WebHTMLDiv43.Width := 369;
    WebHTMLDiv43.Height := 41;
    WebHTMLDiv43.ElementClassName := 'start-login-button-div';
    WebHTMLDiv43.HeightStyle := ssAuto;
    WebHTMLDiv43.WidthStyle := ssAuto;
    WebHTMLDiv43.ChildOrder := 6;
    WebHTMLDiv43.ElementPosition := epRelative;
    WebHTMLDiv43.ElementFont := efCSS;
    WebHTMLDiv43.Role := '';
    WebButton6.SetParentComponent(WebHTMLDiv43);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 207;
    WebButton6.Top := 13;
    WebButton6.Width := 96;
    WebButton6.Height := 25;
    WebButton6.Caption := 'Login';
    WebButton6.ElementClassName := 'start_button-login';
    WebButton6.ElementFont := efCSS;
    WebButton6.ElementPosition := epIgnore;
    WebButton6.HeightStyle := ssAuto;
    WebButton6.HeightPercent := 100.000000000000000000;
    WebButton6.WidthStyle := ssAuto;
    WebButton6.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton6, Self, 'OnClick', 'btnLoginClick');
    WebButton7.SetParentComponent(WebHTMLDiv43);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 3;
    WebButton7.Top := 13;
    WebButton7.Width := 96;
    WebButton7.Height := 25;
    WebButton7.Caption := '6 gute Gr'#252'nde';
    WebButton7.ChildOrder := 1;
    WebButton7.ElementClassName := 'start_button-lets-go';
    WebButton7.ElementFont := efCSS;
    WebButton7.ElementPosition := epIgnore;
    WebButton7.HeightStyle := ssAuto;
    WebButton7.HeightPercent := 100.000000000000000000;
    WebButton7.WidthStyle := ssAuto;
    WebButton7.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton7, Self, 'OnClick', 'miLosGehtsClick');
    WebButton8.SetParentComponent(WebHTMLDiv43);
    WebButton8.Name := 'WebButton8';
    WebButton8.Left := 105;
    WebButton8.Top := 13;
    WebButton8.Width := 96;
    WebButton8.Height := 25;
    WebButton8.Caption := 'Zugang';
    WebButton8.ChildOrder := 2;
    WebButton8.ElementClassName := 'start_button-lets-go';
    WebButton8.ElementFont := efCSS;
    WebButton8.ElementPosition := epIgnore;
    WebButton8.HeightStyle := ssAuto;
    WebButton8.HeightPercent := 100.000000000000000000;
    WebButton8.WidthStyle := ssAuto;
    WebButton8.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton8, Self, 'OnClick', 'miPreiseClick');
    WebHTMLDiv44.SetParentComponent(divLogin1);
    WebHTMLDiv44.Name := 'WebHTMLDiv44';
    WebHTMLDiv44.Left := 16;
    WebHTMLDiv44.Top := 160;
    WebHTMLDiv44.Width := 369;
    WebHTMLDiv44.Height := 40;
    WebHTMLDiv44.ElementClassName := 'start-login-line ';
    WebHTMLDiv44.HeightStyle := ssAuto;
    WebHTMLDiv44.WidthStyle := ssAuto;
    WebHTMLDiv44.ChildOrder := 3;
    WebHTMLDiv44.ElementPosition := epIgnore;
    WebHTMLDiv44.ElementFont := efCSS;
    WebHTMLDiv44.Role := '';
    WebLabel15.SetParentComponent(WebHTMLDiv44);
    WebLabel15.Name := 'WebLabel15';
    WebLabel15.Left := 20;
    WebLabel15.Top := 11;
    WebLabel15.Width := 37;
    WebLabel15.Height := 15;
    WebLabel15.Caption := 'E-Mail:';
    WebLabel15.ElementClassName := 'start-login-label';
    WebLabel15.ElementFont := efCSS;
    WebLabel15.ElementPosition := epIgnore;
    WebLabel15.HeightStyle := ssAuto;
    WebLabel15.HeightPercent := 100.000000000000000000;
    WebLabel15.WidthStyle := ssAuto;
    WebLabel15.WidthPercent := 100.000000000000000000;
    WebHTMLDiv46.SetParentComponent(WebHTMLDiv44);
    WebHTMLDiv46.Name := 'WebHTMLDiv46';
    WebHTMLDiv46.Left := 124;
    WebHTMLDiv46.Top := 4;
    WebHTMLDiv46.Width := 221;
    WebHTMLDiv46.Height := 30;
    WebHTMLDiv46.ElementClassName := 'start-login_edit_container';
    WebHTMLDiv46.HeightStyle := ssAuto;
    WebHTMLDiv46.WidthStyle := ssAuto;
    WebHTMLDiv46.ChildOrder := 2;
    WebHTMLDiv46.ElementPosition := epIgnore;
    WebHTMLDiv46.ElementFont := efCSS;
    WebHTMLDiv46.Role := '';
    edName.SetParentComponent(WebHTMLDiv46);
    edName.Name := 'edName';
    edName.Left := 21;
    edName.Top := -4;
    edName.Width := 116;
    edName.Height := 34;
    edName.AutoCompletion := acCCName;
    edName.ChildOrder := 1;
    edName.ElementClassName := 'start-login_edit';
    edName.ElementFont := efCSS;
    edName.ElementPosition := epIgnore;
    edName.HeightStyle := ssAuto;
    edName.HeightPercent := 100.000000000000000000;
    edName.Required := True;
    edName.RequiredText := 'Name fehlt!';
    edName.TextHint := 'E-Mail eingeben';
    edName.WidthStyle := ssAuto;
    edName.WidthPercent := 100.000000000000000000;
    SetEvent(edName, Self, 'OnKeyDown', 'edName1KeyDown');
    WebHTMLDiv45.SetParentComponent(divLogin1);
    WebHTMLDiv45.Name := 'WebHTMLDiv45';
    WebHTMLDiv45.Left := 16;
    WebHTMLDiv45.Top := 206;
    WebHTMLDiv45.Width := 369;
    WebHTMLDiv45.Height := 36;
    WebHTMLDiv45.ElementClassName := 'start-login-line ';
    WebHTMLDiv45.HeightStyle := ssAuto;
    WebHTMLDiv45.WidthStyle := ssAuto;
    WebHTMLDiv45.ChildOrder := 4;
    WebHTMLDiv45.ElementPosition := epIgnore;
    WebHTMLDiv45.ElementFont := efCSS;
    WebHTMLDiv45.Role := '';
    WebLabel1.SetParentComponent(WebHTMLDiv45);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 20;
    WebLabel1.Top := 11;
    WebLabel1.Width := 50;
    WebLabel1.Height := 15;
    WebLabel1.Caption := 'Passwort:';
    WebLabel1.ElementClassName := 'start-login-label';
    WebLabel1.ElementFont := efCSS;
    WebLabel1.ElementPosition := epIgnore;
    WebLabel1.HeightStyle := ssAuto;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthStyle := ssAuto;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebHTMLDiv83.SetParentComponent(WebHTMLDiv45);
    WebHTMLDiv83.Name := 'WebHTMLDiv83';
    WebHTMLDiv83.Left := 124;
    WebHTMLDiv83.Top := 3;
    WebHTMLDiv83.Width := 221;
    WebHTMLDiv83.Height := 30;
    WebHTMLDiv83.ElementClassName := 'start-login_edit_container';
    WebHTMLDiv83.HeightStyle := ssAuto;
    WebHTMLDiv83.WidthStyle := ssAuto;
    WebHTMLDiv83.ChildOrder := 2;
    WebHTMLDiv83.ElementPosition := epIgnore;
    WebHTMLDiv83.ElementFont := efCSS;
    WebHTMLDiv83.Role := '';
    edPasswort.SetParentComponent(WebHTMLDiv83);
    edPasswort.Name := 'edPasswort';
    edPasswort.Left := 10;
    edPasswort.Top := 5;
    edPasswort.Width := 121;
    edPasswort.Height := 22;
    edPasswort.AutoCompletion := acCurrentPassword;
    edPasswort.AutoFocus := True;
    edPasswort.ElementClassName := 'start-login_edit';
    edPasswort.ElementFont := efCSS;
    edPasswort.ElementPosition := epIgnore;
    edPasswort.HeightStyle := ssAuto;
    edPasswort.HeightPercent := 100.000000000000000000;
    edPasswort.PasswordChar := '*';
    edPasswort.Text := 'edPasswort';
    edPasswort.TextHint := 'Passwort eingeben';
    edPasswort.WidthStyle := ssAuto;
    edPasswort.WidthPercent := 100.000000000000000000;
    SetEvent(edPasswort, Self, 'OnKeyDown', 'edPasswortKeyDown');
    WebHTMLDiv47.SetParentComponent(divLogin1);
    WebHTMLDiv47.Name := 'WebHTMLDiv47';
    WebHTMLDiv47.Left := 16;
    WebHTMLDiv47.Top := 248;
    WebHTMLDiv47.Width := 369;
    WebHTMLDiv47.Height := 36;
    WebHTMLDiv47.ElementClassName := 'start-login-line ';
    WebHTMLDiv47.HeightStyle := ssAuto;
    WebHTMLDiv47.WidthStyle := ssAuto;
    WebHTMLDiv47.ChildOrder := 5;
    WebHTMLDiv47.ElementPosition := epIgnore;
    WebHTMLDiv47.ElementFont := efCSS;
    WebHTMLDiv47.Role := '';
    lblNewPassword.SetParentComponent(WebHTMLDiv47);
    lblNewPassword.Name := 'lblNewPassword';
    lblNewPassword.Left := 20;
    lblNewPassword.Top := 11;
    lblNewPassword.Width := 111;
    lblNewPassword.Height := 15;
    lblNewPassword.Caption := 'Passwort vergessen...';
    lblNewPassword.ElementLabelClassName := 'start-login-passwort-vergessen';
    lblNewPassword.ElementFont := efCSS;
    lblNewPassword.ElementPosition := epIgnore;
    lblNewPassword.HeightStyle := ssAuto;
    lblNewPassword.HeightPercent := 100.000000000000000000;
    lblNewPassword.WidthStyle := ssAuto;
    lblNewPassword.WidthPercent := 100.000000000000000000;
    SetEvent(lblNewPassword, Self, 'OnClick', 'lblNewPasswordClick');
    WebHTMLDiv48.SetParentComponent(divLogin1);
    WebHTMLDiv48.Name := 'WebHTMLDiv48';
    WebHTMLDiv48.Left := 16;
    WebHTMLDiv48.Top := 67;
    WebHTMLDiv48.Width := 369;
    WebHTMLDiv48.Height := 40;
    WebHTMLDiv48.ElementClassName := 'start-login-line ';
    WebHTMLDiv48.HeightStyle := ssAuto;
    WebHTMLDiv48.WidthStyle := ssAuto;
    WebHTMLDiv48.ChildOrder := 1;
    WebHTMLDiv48.ElementPosition := epIgnore;
    WebHTMLDiv48.ElementFont := efCSS;
    WebHTMLDiv48.Role := '';
    WebLabel3.SetParentComponent(WebHTMLDiv48);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 20;
    WebLabel3.Top := 11;
    WebLabel3.Width := 33;
    WebLabel3.Height := 15;
    WebLabel3.Caption := 'Login:';
    WebLabel3.ElementClassName := 'start-login-login';
    WebLabel3.ElementFont := efCSS;
    WebLabel3.ElementPosition := epIgnore;
    WebLabel3.HeightStyle := ssAuto;
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthStyle := ssAuto;
    WebLabel3.WidthPercent := 100.000000000000000000;
    divError.SetParentComponent(divLogin1);
    divError.Name := 'divError';
    divError.Left := 16;
    divError.Top := 113;
    divError.Width := 369;
    divError.Height := 41;
    divError.ElementClassName := 'error_container';
    divError.HeightStyle := ssAuto;
    divError.WidthStyle := ssAuto;
    divError.ChildOrder := 2;
    divError.ElementPosition := epRelative;
    divError.ElementFont := efCSS;
    divError.HTML.BeginUpdate;
    try
      divError.HTML.Clear;
      divError.HTML.Add('Anmeldename oder Passwort ist falsch...');
    finally
      divError.HTML.EndUpdate;
    end;
    divError.Role := '';
    divError.Visible := False;
    divByeBye.SetParentComponent(divstartseite);
    divByeBye.Name := 'divByeBye';
    divByeBye.Left := 28;
    divByeBye.Top := 384;
    divByeBye.Width := 429;
    divByeBye.Height := 145;
    divByeBye.ElementClassName := 'start_client';
    divByeBye.HeightStyle := ssAuto;
    divByeBye.WidthStyle := ssAuto;
    divByeBye.ChildOrder := 1;
    divByeBye.ElementPosition := epIgnore;
    divByeBye.ElementFont := efCSS;
    divByeBye.Role := '';
    divByeBye.Visible := False;
    divByeByeX.SetParentComponent(divByeBye);
    divByeByeX.Name := 'divByeByeX';
    divByeByeX.Left := 3;
    divByeByeX.Top := 3;
    divByeByeX.Width := 398;
    divByeByeX.Height := 126;
    divByeByeX.ElementClassName := 'start_text1';
    divByeByeX.HeightStyle := ssAuto;
    divByeByeX.WidthStyle := ssAuto;
    divByeByeX.ElementPosition := epIgnore;
    divByeByeX.ElementFont := efCSS;
    divByeByeX.Role := '';
    WebHTMLDiv41.SetParentComponent(divByeByeX);
    WebHTMLDiv41.Name := 'WebHTMLDiv41';
    WebHTMLDiv41.Left := 16;
    WebHTMLDiv41.Top := 12;
    WebHTMLDiv41.Width := 369;
    WebHTMLDiv41.Height := 49;
    WebHTMLDiv41.ElementClassName := 'start-header';
    WebHTMLDiv41.HeightStyle := ssAuto;
    WebHTMLDiv41.WidthStyle := ssAuto;
    WebHTMLDiv41.ElementPosition := epRelative;
    WebHTMLDiv41.ElementFont := efCSS;
    WebHTMLDiv41.HTML.BeginUpdate;
    try
      WebHTMLDiv41.HTML.Clear;
      WebHTMLDiv41.HTML.Add('<FONT  size="18" size="15"><B>Segler-Portal.de</B></FONT>');
      WebHTMLDiv41.HTML.Add('');
    finally
      WebHTMLDiv41.HTML.EndUpdate;
    end;
    WebHTMLDiv41.Role := '';
    WebHTMLDiv49.SetParentComponent(divByeByeX);
    WebHTMLDiv49.Name := 'WebHTMLDiv49';
    WebHTMLDiv49.Left := 16;
    WebHTMLDiv49.Top := 67;
    WebHTMLDiv49.Width := 369;
    WebHTMLDiv49.Height := 41;
    WebHTMLDiv49.ElementClassName := 'start-login-button-div';
    WebHTMLDiv49.HeightStyle := ssAuto;
    WebHTMLDiv49.WidthStyle := ssAuto;
    WebHTMLDiv49.ChildOrder := 6;
    WebHTMLDiv49.ElementPosition := epRelative;
    WebHTMLDiv49.ElementFont := efCSS;
    WebHTMLDiv49.Role := '';
    WebButton3.SetParentComponent(WebHTMLDiv49);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 194;
    WebButton3.Top := 13;
    WebButton3.Width := 96;
    WebButton3.Height := 25;
    WebButton3.Caption := 'bis zum n'#228'chsten mal...';
    WebButton3.ElementClassName := 'start_button-lets-go';
    WebButton3.ElementFont := efCSS;
    WebButton3.ElementPosition := epIgnore;
    WebButton3.HeightStyle := ssAuto;
    WebButton3.HeightPercent := 100.000000000000000000;
    WebButton3.WidthStyle := ssAuto;
    WebButton3.WidthPercent := 100.000000000000000000;
    divWartung.SetParentComponent(divstartseite);
    divWartung.Name := 'divWartung';
    divWartung.Left := 28;
    divWartung.Top := 535;
    divWartung.Width := 429;
    divWartung.Height := 138;
    divWartung.ElementClassName := 'start_client';
    divWartung.HeightStyle := ssAuto;
    divWartung.WidthStyle := ssAuto;
    divWartung.ChildOrder := 1;
    divWartung.ElementPosition := epIgnore;
    divWartung.ElementFont := efCSS;
    divWartung.Role := '';
    divWartung.Visible := False;
    WebHTMLDiv18.SetParentComponent(divWartung);
    WebHTMLDiv18.Name := 'WebHTMLDiv18';
    WebHTMLDiv18.Left := 3;
    WebHTMLDiv18.Top := 3;
    WebHTMLDiv18.Width := 398;
    WebHTMLDiv18.Height := 118;
    WebHTMLDiv18.ElementClassName := 'start_text1';
    WebHTMLDiv18.HeightStyle := ssAuto;
    WebHTMLDiv18.WidthStyle := ssAuto;
    WebHTMLDiv18.ElementPosition := epIgnore;
    WebHTMLDiv18.ElementFont := efCSS;
    WebHTMLDiv18.Role := '';
    WebHTMLDiv23.SetParentComponent(WebHTMLDiv18);
    WebHTMLDiv23.Name := 'WebHTMLDiv23';
    WebHTMLDiv23.Left := 16;
    WebHTMLDiv23.Top := 3;
    WebHTMLDiv23.Width := 369;
    WebHTMLDiv23.Height := 49;
    WebHTMLDiv23.ElementClassName := 'start-header';
    WebHTMLDiv23.HeightStyle := ssAuto;
    WebHTMLDiv23.WidthStyle := ssAuto;
    WebHTMLDiv23.ElementPosition := epRelative;
    WebHTMLDiv23.ElementFont := efCSS;
    WebHTMLDiv23.HTML.BeginUpdate;
    try
      WebHTMLDiv23.HTML.Clear;
      WebHTMLDiv23.HTML.Add('<FONT  size="18" size="15"><B>Segler-Portal.de</B><br><br></FONT>');
      WebHTMLDiv23.HTML.Add('');
      WebHTMLDiv23.HTML.Add('... ist im Moment nicht erreichbar. <br><br>');
      WebHTMLDiv23.HTML.Add('Wir arbeiten mit Hochdruck daran das Problem zu beheben.<br><br><br>');
      WebHTMLDiv23.HTML.Add('Vielen Dank f'#252'r dein Verst'#228'ndnis...<br><br>');
      WebHTMLDiv23.HTML.Add('');
    finally
      WebHTMLDiv23.HTML.EndUpdate;
    end;
    WebHTMLDiv23.Role := '';
    WebHTMLDiv24.SetParentComponent(WebHTMLDiv18);
    WebHTMLDiv24.Name := 'WebHTMLDiv24';
    WebHTMLDiv24.Left := 16;
    WebHTMLDiv24.Top := 58;
    WebHTMLDiv24.Width := 369;
    WebHTMLDiv24.Height := 41;
    WebHTMLDiv24.ElementClassName := 'start-login-button-div';
    WebHTMLDiv24.HeightStyle := ssAuto;
    WebHTMLDiv24.WidthStyle := ssAuto;
    WebHTMLDiv24.ChildOrder := 6;
    WebHTMLDiv24.ElementPosition := epRelative;
    WebHTMLDiv24.ElementFont := efCSS;
    WebHTMLDiv24.Role := '';
    WebHTMLDiv24.Visible := False;
    WebButton4.SetParentComponent(WebHTMLDiv24);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 194;
    WebButton4.Top := 13;
    WebButton4.Width := 96;
    WebButton4.Height := 25;
    WebButton4.Caption := 'OK';
    WebButton4.ElementClassName := 'start_button-lets-go';
    WebButton4.ElementFont := efCSS;
    WebButton4.ElementPosition := epIgnore;
    WebButton4.HeightStyle := ssAuto;
    WebButton4.HeightPercent := 100.000000000000000000;
    WebButton4.WidthStyle := ssAuto;
    WebButton4.WidthPercent := 100.000000000000000000;
    divMenue.SetParentComponent(Self);
    divMenue.Name := 'divMenue';
    divMenue.Left := 8;
    divMenue.Top := 8;
    divMenue.Width := 681;
    divMenue.Height := 113;
    divMenue.ElementClassName := 'start_top';
    divMenue.HeightStyle := ssAuto;
    divMenue.WidthStyle := ssAuto;
    divMenue.ElementPosition := epIgnore;
    divMenue.ElementFont := efCSS;
    divMenue.Role := '';
    WebHTMLDiv30.SetParentComponent(divMenue);
    WebHTMLDiv30.Name := 'WebHTMLDiv30';
    WebHTMLDiv30.Left := 3;
    WebHTMLDiv30.Top := 0;
    WebHTMLDiv30.Width := 670;
    WebHTMLDiv30.Height := 97;
    WebHTMLDiv30.ElementClassName := 'start_topnav';
    WebHTMLDiv30.HeightStyle := ssAuto;
    WebHTMLDiv30.WidthStyle := ssAuto;
    WebHTMLDiv30.ChildOrder := 2;
    WebHTMLDiv30.ElementPosition := epIgnore;
    WebHTMLDiv30.ElementFont := efCSS;
    WebHTMLDiv30.Role := '';
    divMenuLeft.SetParentComponent(WebHTMLDiv30);
    divMenuLeft.Name := 'divMenuLeft';
    divMenuLeft.Left := 176;
    divMenuLeft.Top := 3;
    divMenuLeft.Width := 241;
    divMenuLeft.Height := 76;
    divMenuLeft.ElementClassName := 'start_menue';
    divMenuLeft.HeightStyle := ssAuto;
    divMenuLeft.WidthStyle := ssAuto;
    divMenuLeft.ChildOrder := 1;
    divMenuLeft.ElementPosition := epIgnore;
    divMenuLeft.ElementFont := efCSS;
    divMenuLeft.Role := '';
    WebHTMLDiv31.SetParentComponent(WebHTMLDiv30);
    WebHTMLDiv31.Name := 'WebHTMLDiv31';
    WebHTMLDiv31.Left := 423;
    WebHTMLDiv31.Top := 3;
    WebHTMLDiv31.Width := 241;
    WebHTMLDiv31.Height := 76;
    WebHTMLDiv31.ElementClassName := 'start_menue';
    WebHTMLDiv31.HeightStyle := ssAuto;
    WebHTMLDiv31.WidthStyle := ssAuto;
    WebHTMLDiv31.ChildOrder := 3;
    WebHTMLDiv31.ElementPosition := epIgnore;
    WebHTMLDiv31.ElementFont := efCSS;
    WebHTMLDiv31.Role := '';
    WebHTMLDiv21.SetParentComponent(WebHTMLDiv30);
    WebHTMLDiv21.Name := 'WebHTMLDiv21';
    WebHTMLDiv21.Left := 53;
    WebHTMLDiv21.Top := 3;
    WebHTMLDiv21.Width := 117;
    WebHTMLDiv21.Height := 76;
    WebHTMLDiv21.ElementClassName := 'start_menue';
    WebHTMLDiv21.HeightStyle := ssAuto;
    WebHTMLDiv21.WidthStyle := ssAuto;
    WebHTMLDiv21.ElementPosition := epIgnore;
    WebHTMLDiv21.ElementFont := efCSS;
    WebHTMLDiv21.Role := '';
    imgLogo.SetParentComponent(WebHTMLDiv21);
    imgLogo.Name := 'imgLogo';
    imgLogo.Left := 11;
    imgLogo.Top := 11;
    imgLogo.Width := 73;
    imgLogo.Height := 65;
    imgLogo.ElementClassName := 'start_logo';
    imgLogo.HeightStyle := ssAuto;
    imgLogo.WidthStyle := ssAuto;
    imgLogo.HeightPercent := 100.000000000000000000;
    imgLogo.WidthPercent := 100.000000000000000000;
    imgLogo.ElementFont := efCSS;
    imgLogo.ElementPosition := epIgnore;
    imgLogo.Picture.LoadFromFile('uStart.WebHTMLDiv21.imgLogo.Picture.png');
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 24;
    Client.Top := 208;
    mnuMainLeft.SetParentComponent(Self);
    mnuMainLeft.Name := 'mnuMainLeft';
    mnuMainLeft.Appearance.BackgroundColor := clBackground;
    mnuMainLeft.Appearance.HamburgerMenu.Caption := 'Menu';
    mnuMainLeft.Appearance.HoverColor := clBlack;
    mnuMainLeft.Appearance.HoverFontColor := 17919;
    mnuMainLeft.Appearance.SubmenuIndicator := '';
    mnuMainLeft.Container := divMenuLeft;
    mnuMainLeft.ElementClassName := 'main_menu';
    mnuMainLeft.ElementItemClassName := 'main_label';
    mnuMainLeft.Font.Charset := DEFAULT_CHARSET;
    mnuMainLeft.Font.Color := clWindowText;
    mnuMainLeft.Font.Height := -12;
    mnuMainLeft.Font.Name := 'Segoe UI';
    mnuMainLeft.Font.Style := [];
    mnuMainLeft.Left := 203;
    mnuMainLeft.Top := 53;
    SeglerPortalde1.SetParentComponent(mnuMainLeft);
    SeglerPortalde1.Name := 'SeglerPortalde1';
    SeglerPortalde1.Caption := 'Segler-Portal.de';
    SetEvent(SeglerPortalde1, Self, 'OnClick', 'SeglerPortalde1Click');
    miGuteGruende.SetParentComponent(mnuMainLeft);
    miGuteGruende.Name := 'miGuteGruende';
    miGuteGruende.Caption := '6 gute Gr'#252'nde';
    SetEvent(miGuteGruende, Self, 'OnClick', 'miGuteGruendeClick');
    miPreise.SetParentComponent(mnuMainLeft);
    miPreise.Name := 'miPreise';
    miPreise.Caption := 'Zugang';
    SetEvent(miPreise, Self, 'OnClick', 'miPreiseClick');
    Login1.SetParentComponent(mnuMainLeft);
    Login1.Name := 'Login1';
    Login1.Caption := 'Login';
    SetEvent(Login1, Self, 'OnClick', 'btnShowLoginDivClick');
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    divStart.AfterLoadDFMValues;
    WebHTMLDiv9.AfterLoadDFMValues;
    pLogin.AfterLoadDFMValues;
    WebHTMLDiv3.AfterLoadDFMValues;
    WebHTMLDiv5.AfterLoadDFMValues;
    divError1.AfterLoadDFMValues;
    edName1.AfterLoadDFMValues;
    edPasswort5.AfterLoadDFMValues;
    WebHTMLDiv87.AfterLoadDFMValues;
    chkInfoGruppen.AfterLoadDFMValues;
    WebHTMLDiv82.AfterLoadDFMValues;
    WebLabel30.AfterLoadDFMValues;
    WebHTMLDiv6.AfterLoadDFMValues;
    btnLogin1.AfterLoadDFMValues;
    pNew.AfterLoadDFMValues;
    WebHTMLDiv4.AfterLoadDFMValues;
    WebHTMLDiv7.AfterLoadDFMValues;
    WebHTMLDiv13.AfterLoadDFMValues;
    WebHTMLDiv8.AfterLoadDFMValues;
    btnRegistrieren.AfterLoadDFMValues;
    divNurMalGucken.AfterLoadDFMValues;
    WebHTMLDiv10.AfterLoadDFMValues;
    WebHTMLDiv11.AfterLoadDFMValues;
    edVorname.AfterLoadDFMValues;
    edNachname.AfterLoadDFMValues;
    edEmail.AfterLoadDFMValues;
    WebHTMLDiv12.AfterLoadDFMValues;
    btnGast.AfterLoadDFMValues;
    divStartText.AfterLoadDFMValues;
    WebHTMLDiv15.AfterLoadDFMValues;
    WebImageControl1.AfterLoadDFMValues;
    divAngebote.AfterLoadDFMValues;
    divBack.AfterLoadDFMValues;
    btnGotoLogin.AfterLoadDFMValues;
    divRegisterInfo.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    WebHTMLDiv16.AfterLoadDFMValues;
    btnBack2Login.AfterLoadDFMValues;
    divByeBye1.AfterLoadDFMValues;
    WebHTMLDiv19.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    divstartseite.AfterLoadDFMValues;
    divLanding.AfterLoadDFMValues;
    divLosgehts.AfterLoadDFMValues;
    WebHTMLDiv17.AfterLoadDFMValues;
    WebHTMLDiv20.AfterLoadDFMValues;
    miLosGehts.AfterLoadDFMValues;
    btnShowLoginDiv.AfterLoadDFMValues;
    WebButton9.AfterLoadDFMValues;
    divGruende.AfterLoadDFMValues;
    WebHTMLDiv22.AfterLoadDFMValues;
    divGruende1.AfterLoadDFMValues;
    imgGruende1.AfterLoadDFMValues;
    divGruende2.AfterLoadDFMValues;
    WebHTMLDiv27.AfterLoadDFMValues;
    imgGruende2.AfterLoadDFMValues;
    WebHTMLDiv29.AfterLoadDFMValues;
    WebHTMLDiv32.AfterLoadDFMValues;
    imgGruende3.AfterLoadDFMValues;
    WebHTMLDiv33.AfterLoadDFMValues;
    WebHTMLDiv34.AfterLoadDFMValues;
    imgGruende4.AfterLoadDFMValues;
    WebHTMLDiv35.AfterLoadDFMValues;
    WebHTMLDiv36.AfterLoadDFMValues;
    imgGruende5.AfterLoadDFMValues;
    WebHTMLDiv37.AfterLoadDFMValues;
    WebHTMLDiv38.AfterLoadDFMValues;
    imgGruende6.AfterLoadDFMValues;
    WebHTMLDiv39.AfterLoadDFMValues;
    WebHTMLDiv25.AfterLoadDFMValues;
    WebHTMLDiv14.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    divPreise.AfterLoadDFMValues;
    divPakete.AfterLoadDFMValues;
    WebHTMLDiv55.AfterLoadDFMValues;
    WebHTMLDiv26.AfterLoadDFMValues;
    WebButton12.AfterLoadDFMValues;
    WebButton13.AfterLoadDFMValues;
    divLogin.AfterLoadDFMValues;
    divLogin1.AfterLoadDFMValues;
    WebHTMLDiv42.AfterLoadDFMValues;
    WebHTMLDiv43.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebButton8.AfterLoadDFMValues;
    WebHTMLDiv44.AfterLoadDFMValues;
    WebLabel15.AfterLoadDFMValues;
    WebHTMLDiv46.AfterLoadDFMValues;
    edName.AfterLoadDFMValues;
    WebHTMLDiv45.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebHTMLDiv83.AfterLoadDFMValues;
    edPasswort.AfterLoadDFMValues;
    WebHTMLDiv47.AfterLoadDFMValues;
    lblNewPassword.AfterLoadDFMValues;
    WebHTMLDiv48.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    divError.AfterLoadDFMValues;
    divByeBye.AfterLoadDFMValues;
    divByeByeX.AfterLoadDFMValues;
    WebHTMLDiv41.AfterLoadDFMValues;
    WebHTMLDiv49.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    divWartung.AfterLoadDFMValues;
    WebHTMLDiv18.AfterLoadDFMValues;
    WebHTMLDiv23.AfterLoadDFMValues;
    WebHTMLDiv24.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    divMenue.AfterLoadDFMValues;
    WebHTMLDiv30.AfterLoadDFMValues;
    divMenuLeft.AfterLoadDFMValues;
    WebHTMLDiv31.AfterLoadDFMValues;
    WebHTMLDiv21.AfterLoadDFMValues;
    imgLogo.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
    mnuMainLeft.AfterLoadDFMValues;
    SeglerPortalde1.AfterLoadDFMValues;
    miGuteGruende.AfterLoadDFMValues;
    miPreise.AfterLoadDFMValues;
    Login1.AfterLoadDFMValues;
  end;
end;

end.
