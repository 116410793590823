unit ufrComments;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.WebCtrls, uTB,
  Vcl.Imaging.pngimage, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.ExtCtrls,
  XData.Web.Client;

type
  TfrComments = class(TFrame)
    divContainer: THTMLDiv;
    Client: TXDataWebClient;
    [async]
    [async]
    divTitel: THTMLDiv;
    mTitel: TMemo;
    divText: THTMLDiv;
    divEdit: THTMLDiv;
    mText: TMemo;
    WebHTMLDiv3: THTMLDiv;
    divLikeWrapper: THTMLDiv;
    btnLike: TImageControl;
    divLikeCount: THTMLDiv;
    divlikeUser: THTMLDiv;
    divComments: THTMLDiv;
    btnNew: TImageControl;
    divRightMenu: THTMLDiv;
    btnTitel: TImageControl;
    lblText: TLinkLabel;
    procedure btnNewClick(Sender: TObject);
    [async]
    [async]procedure btnLikeClick(Sender: TObject); async;
    [async]
    procedure divTextMouseEnter(Sender: TObject); async;
    [async] procedure lblTextLinkClick(Sender: TObject; Link: string; LinkType: TSysLinkType); async;
  private
    FHolder, FMe, FPost_ID,
    FID, FVor:integer;
    FIsTitel:boolean;
    FCallback:TCallback;
  protected
    procedure Loaded(); override;
  public
    property Callback : TCallback read FCallback write FCallback;
    property IsTitel : boolean read FisTitel write FisTitel;
    property Holder : integer read FHolder write FHolder;
    property Me : integer read FMe write FMe;
    property ID : integer read FID write FID;
    property VOR : integer read FVOR write FVor;
    property Post_ID: integer read FPost_id write FPost_ID;
    [async]function Init(AHolder, AME, AID, AParent:integer; AText:string; Callback:TCallback):integer; async;
    [async]class function Add(AOwner, AParent:TControl; AHolder, AMe, APost_ID, AID, AVor:integer; AText:string; AFirst:boolean; ACallback:TCallback):TfrComments; async;
    procedure ShowSideBar;
  protected procedure LoadDFMValues; override; end;

var
  frComments: TfrComments;

implementation
   Uses uMain, uPostEdit, uDatenmodul;
{$R *.dfm}


procedure TfrComments.ShowSideBar;
Var
  i:integer;
  f:TfrComments;
begin

  for i := 0 to Self.Owner.ComponentCount -1 do
  begin

    if self.Owner.Components[i] is TfrComments
    then begin
       TfrComments(self.Owner.Components[i]).divRightMenu.Visible := false;
    end;
  end;
  divRightMenu.Visible := true;
end;

[async]class function TfrComments.Add(AOwner, AParent:TControl; AHolder, AMe, APost_ID, AID, AVor:integer; AText:string; AFirst:boolean; ACallback:TCallback):TfrComments; async;
var
  f:TfrComments;
  el : TJSHTMLElement;
begin
  f := TfrComments.Create(AOwner.Owner);
  f.LoadFromForm;
  f.parent := AParent;
  f.Name := 'Comment_' + AId.ToString;

  f.ID      := AID;
  f.VOR     := AVOR;
  f.Holder  := AHolder;
  f.Me      := AMe;
  f.Post_ID := APost_ID;
  f.Callback:= ACallback;
  f.lblText.caption := AText;
//  f.divText.html.Text := AText;
  f.btnTitel.visible := AFirst;

  if AFirst
  then f.divText.ElementClassName := 'comment_titel';

  el := TJSHTMLElement(THtmlDiv(f).ElementHandle);
  el.style.removeProperty('position');
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-style');
  el.style.removeProperty('font-size');

  await(GetLikeCount(f.Id, integer(etFoto), integer(ltLike), f.divLikeWrapper, f.divLikeUser, f.divLikeCount));

  AParent.Visible := true;
  result := f;
end;

function TfrComments.Init(AHolder, AME, AID, AParent:integer; AText:string; Callback:TCallback):integer;
Var
  Response : TXDataClientResponse;
  i:integer;
  j:TJ;
begin

  if AId = 0
  then begin
    Response := Await( Client.RawInvokeAsync( 'IDBService.BlogNew', [AHolder, AME, AID, AParent, '', AText]));

    result := TJ.create(Response).Integer('ID');
  end
  else Result := AID;

  FId := result;
  J := TJ.create(Await( Client.RawInvokeAsync('IDBService.GetBlogByID',[FId])));

end;

procedure TfrComments.lblTextLinkClick(Sender: TObject; Link: string; LinkType: TSysLinkType);
var s :string;
begin
  s := copy(link,pos('=',link) + 1);

  if assigned (FCallback)
  then FCallback('Link=' + s);

end;

procedure TfrComments.btnLikeClick(Sender: TObject);
Var
  i,j:integer;
begin
  i := await(datenmodul.LikeSet(FME, FID, integer(etFoto), integer(ltLike) ));
  GetLikeCount(FID, integer(etFoto), integer(ltLike), divLikeWrapper, divLikeUser, divLikeCount);
end;

procedure TfrComments.btnNewClick(Sender: TObject);
begin
  TfrmPostEdit.Medien(FHolder, FME, FPost_ID, FID, FCallback);
end;

procedure TfrComments.Loaded();
var
  el: TJSHTMLElement;
begin
  inherited;

  TControl(self).ElementPosition := epIgnore;
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-style');
  el.style.removeProperty('font-size');
end;

procedure TfrComments.divTextMouseEnter(Sender: TObject);
begin
  ShowSideBar;
end;

procedure TfrComments.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divContainer := THTMLDiv.Create(Self);
  divTitel := THTMLDiv.Create(Self);
  mTitel := TMemo.Create(Self);
  divRightMenu := THTMLDiv.Create(Self);
  btnTitel := TImageControl.Create(Self);
  divText := THTMLDiv.Create(Self);
  lblText := TLinkLabel.Create(Self);
  divEdit := THTMLDiv.Create(Self);
  mText := TMemo.Create(Self);
  WebHTMLDiv3 := THTMLDiv.Create(Self);
  btnNew := TImageControl.Create(Self);
  divLikeWrapper := THTMLDiv.Create(Self);
  btnLike := TImageControl.Create(Self);
  divLikeCount := THTMLDiv.Create(Self);
  divlikeUser := THTMLDiv.Create(Self);
  divComments := THTMLDiv.Create(Self);
  Client := TXDataWebClient.Create(Self);

  divContainer.BeforeLoadDFMValues;
  divTitel.BeforeLoadDFMValues;
  mTitel.BeforeLoadDFMValues;
  divRightMenu.BeforeLoadDFMValues;
  btnTitel.BeforeLoadDFMValues;
  divText.BeforeLoadDFMValues;
  lblText.BeforeLoadDFMValues;
  divEdit.BeforeLoadDFMValues;
  mText.BeforeLoadDFMValues;
  WebHTMLDiv3.BeforeLoadDFMValues;
  btnNew.BeforeLoadDFMValues;
  divLikeWrapper.BeforeLoadDFMValues;
  btnLike.BeforeLoadDFMValues;
  divLikeCount.BeforeLoadDFMValues;
  divlikeUser.BeforeLoadDFMValues;
  divComments.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frComments';
    Left := 0;
    Top := 0;
    Width := 460;
    Height := 372;
    TabOrder := 0;
    divContainer.SetParentComponent(Self);
    divContainer.Name := 'divContainer';
    divContainer.Left := 0;
    divContainer.Top := 3;
    divContainer.Width := 449;
    divContainer.Height := 358;
    divContainer.ElementClassName := 'comment_content';
    divContainer.HeightStyle := ssAuto;
    divContainer.WidthStyle := ssAuto;
    divContainer.ElementPosition := epIgnore;
    divContainer.ElementFont := efCSS;
    divContainer.Role := '';
    divTitel.SetParentComponent(divContainer);
    divTitel.Name := 'divTitel';
    divTitel.Left := 13;
    divTitel.Top := 17;
    divTitel.Width := 414;
    divTitel.Height := 101;
    divTitel.ElementClassName := 'slider_titel_text_container';
    divTitel.HeightStyle := ssAuto;
    divTitel.WidthStyle := ssAuto;
    divTitel.ChildOrder := 1;
    divTitel.ElementPosition := epIgnore;
    divTitel.ElementFont := efCSS;
    divTitel.Role := '';
    divTitel.Visible := False;
    mTitel.SetParentComponent(divTitel);
    mTitel.Name := 'mTitel';
    mTitel.Left := 0;
    mTitel.Top := 0;
    mTitel.Width := 280;
    mTitel.Height := 101;
    mTitel.AutoSize := False;
    mTitel.ElementFont := efCSS;
    mTitel.ElementPosition := epRelative;
    mTitel.HeightPercent := 100.000000000000000000;
    mTitel.Lines.BeginUpdate;
    try
      mTitel.Lines.Clear;
      mTitel.Lines.Add('mText');
    finally
      mTitel.Lines.EndUpdate;
    end;
    mTitel.SelLength := 0;
    mTitel.SelStart := 0;
    mTitel.WidthPercent := 100.000000000000000000;
    divRightMenu.SetParentComponent(divTitel);
    divRightMenu.Name := 'divRightMenu';
    divRightMenu.Left := 352;
    divRightMenu.Top := 3;
    divRightMenu.Width := 54;
    divRightMenu.Height := 74;
    divRightMenu.ElementClassName := 'comment_right';
    divRightMenu.HeightStyle := ssAuto;
    divRightMenu.WidthStyle := ssAuto;
    divRightMenu.ChildOrder := 1;
    divRightMenu.ElementPosition := epIgnore;
    divRightMenu.ElementFont := efCSS;
    divRightMenu.Role := '';
    btnTitel.SetParentComponent(divRightMenu);
    btnTitel.Name := 'btnTitel';
    btnTitel.Left := 13;
    btnTitel.Top := 12;
    btnTitel.Width := 38;
    btnTitel.Height := 44;
    btnTitel.ElementClassName := 'comment_menu_btn';
    btnTitel.HeightStyle := ssAuto;
    btnTitel.WidthStyle := ssAuto;
    btnTitel.HeightPercent := 100.000000000000000000;
    btnTitel.WidthPercent := 100.000000000000000000;
    btnTitel.Anchors := [];
    btnTitel.ChildOrder := 2;
    btnTitel.ElementFont := efCSS;
    btnTitel.ElementPosition := epIgnore;
    btnTitel.Picture.LoadFromFile('ufrComments.divRightMenu.btnTitel.Picture.png');
    divText.SetParentComponent(divContainer);
    divText.Name := 'divText';
    divText.Left := 13;
    divText.Top := 124;
    divText.Width := 414;
    divText.Height := 41;
    divText.ElementClassName := 'comment_text';
    divText.HeightStyle := ssAuto;
    divText.WidthStyle := ssAuto;
    divText.ChildOrder := 2;
    divText.ElementPosition := epIgnore;
    divText.ElementFont := efCSS;
    divText.Role := '';
    SetEvent(divText, Self, 'OnMouseEnter', 'divTextMouseEnter');
    lblText.SetParentComponent(divText);
    lblText.Name := 'lblText';
    lblText.Left := 16;
    lblText.Top := 8;
    lblText.Width := 34;
    lblText.Height := 15;
    lblText.ElementFont := efCSS;
    lblText.HeightStyle := ssAuto;
    lblText.HeightPercent := 100.000000000000000000;
    lblText.WidthPercent := 100.000000000000000000;
    SetEvent(lblText, Self, 'OnLinkClick', 'lblTextLinkClick');
    lblText.Caption := 'lblText';
    divEdit.SetParentComponent(divContainer);
    divEdit.Name := 'divEdit';
    divEdit.Left := 13;
    divEdit.Top := 171;
    divEdit.Width := 414;
    divEdit.Height := 50;
    divEdit.ElementClassName := 'comment_comment_container';
    divEdit.HeightStyle := ssAuto;
    divEdit.WidthStyle := ssAuto;
    divEdit.ChildOrder := 3;
    divEdit.ElementPosition := epIgnore;
    divEdit.ElementFont := efCSS;
    divEdit.Role := '';
    divEdit.Visible := False;
    mText.SetParentComponent(divEdit);
    mText.Name := 'mText';
    mText.Left := 3;
    mText.Top := 0;
    mText.Width := 222;
    mText.Height := 50;
    mText.AutoSize := False;
    mText.ElementFont := efCSS;
    mText.ElementPosition := epRelative;
    mText.HeightPercent := 100.000000000000000000;
    mText.Lines.BeginUpdate;
    try
      mText.Lines.Clear;
      mText.Lines.Add('mText');
    finally
      mText.Lines.EndUpdate;
    end;
    mText.SelLength := 0;
    mText.SelStart := 0;
    mText.WidthPercent := 100.000000000000000000;
    WebHTMLDiv3.SetParentComponent(divContainer);
    WebHTMLDiv3.Name := 'WebHTMLDiv3';
    WebHTMLDiv3.Left := 13;
    WebHTMLDiv3.Top := 227;
    WebHTMLDiv3.Width := 414;
    WebHTMLDiv3.Height := 48;
    WebHTMLDiv3.ElementClassName := 'comment_menu_container';
    WebHTMLDiv3.HeightStyle := ssAuto;
    WebHTMLDiv3.WidthStyle := ssAuto;
    WebHTMLDiv3.ChildOrder := 4;
    WebHTMLDiv3.ElementPosition := epIgnore;
    WebHTMLDiv3.ElementFont := efCSS;
    WebHTMLDiv3.Role := '';
    btnNew.SetParentComponent(WebHTMLDiv3);
    btnNew.Name := 'btnNew';
    btnNew.Left := 3;
    btnNew.Top := 4;
    btnNew.Width := 38;
    btnNew.Height := 44;
    btnNew.ElementClassName := 'comment_menu_btn';
    btnNew.HeightStyle := ssAuto;
    btnNew.WidthStyle := ssAuto;
    btnNew.HeightPercent := 100.000000000000000000;
    btnNew.WidthPercent := 100.000000000000000000;
    btnNew.Anchors := [];
    btnNew.ChildOrder := 1;
    btnNew.ElementFont := efCSS;
    btnNew.ElementPosition := epIgnore;
    SetEvent(btnNew, Self, 'OnClick', 'btnNewClick');
    btnNew.Picture.LoadFromFile('ufrComments.WebHTMLDiv3.btnNew.Picture.png');
    divLikeWrapper.SetParentComponent(WebHTMLDiv3);
    divLikeWrapper.Name := 'divLikeWrapper';
    divLikeWrapper.Left := 179;
    divLikeWrapper.Top := -8;
    divLikeWrapper.Width := 205;
    divLikeWrapper.Height := 56;
    divLikeWrapper.ElementClassName := 'like_wrapper';
    divLikeWrapper.HeightStyle := ssAuto;
    divLikeWrapper.WidthStyle := ssAuto;
    divLikeWrapper.ChildOrder := 6;
    divLikeWrapper.ElementPosition := epIgnore;
    divLikeWrapper.ElementFont := efCSS;
    divLikeWrapper.Role := '';
    btnLike.SetParentComponent(divLikeWrapper);
    btnLike.Name := 'btnLike';
    btnLike.Left := 67;
    btnLike.Top := 20;
    btnLike.Width := 34;
    btnLike.Height := 33;
    btnLike.ElementClassName := 'comment_menu_btn';
    btnLike.HeightStyle := ssAuto;
    btnLike.WidthStyle := ssAuto;
    btnLike.HeightPercent := 100.000000000000000000;
    btnLike.WidthPercent := 100.000000000000000000;
    btnLike.ChildOrder := 1;
    btnLike.ElementFont := efCSS;
    btnLike.ElementPosition := epIgnore;
    SetEvent(btnLike, Self, 'OnClick', 'btnLikeClick');
    btnLike.Picture.LoadFromFile('ufrComments.divLikeWrapper.btnLike.Picture.png');
    divLikeCount.SetParentComponent(divLikeWrapper);
    divLikeCount.Name := 'divLikeCount';
    divLikeCount.Left := 21;
    divLikeCount.Top := 12;
    divLikeCount.Width := 40;
    divLikeCount.Height := 41;
    divLikeCount.ElementClassName := 'like_count';
    divLikeCount.HeightStyle := ssAuto;
    divLikeCount.WidthStyle := ssAuto;
    divLikeCount.ChildOrder := 1;
    divLikeCount.ElementPosition := epIgnore;
    divLikeCount.ElementFont := efCSS;
    divLikeCount.Role := '';
    divlikeUser.SetParentComponent(divLikeWrapper);
    divlikeUser.Name := 'divlikeUser';
    divlikeUser.Left := 117;
    divlikeUser.Top := 15;
    divlikeUser.Width := 40;
    divlikeUser.Height := 41;
    divlikeUser.ElementClassName := 'like_count';
    divlikeUser.HeightStyle := ssAuto;
    divlikeUser.WidthStyle := ssAuto;
    divlikeUser.ChildOrder := 2;
    divlikeUser.ElementPosition := epIgnore;
    divlikeUser.ElementFont := efCSS;
    divlikeUser.Role := '';
    divComments.SetParentComponent(divContainer);
    divComments.Name := 'divComments';
    divComments.Left := 13;
    divComments.Top := 281;
    divComments.Width := 414;
    divComments.Height := 49;
    divComments.ElementClassName := 'comment_comment_container';
    divComments.HeightStyle := ssAuto;
    divComments.WidthStyle := ssAuto;
    divComments.ChildOrder := 5;
    divComments.ElementPosition := epIgnore;
    divComments.ElementFont := efCSS;
    divComments.Role := '';
    divComments.Visible := False;
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 19;
    Client.Top := 59;
  finally
    divContainer.AfterLoadDFMValues;
    divTitel.AfterLoadDFMValues;
    mTitel.AfterLoadDFMValues;
    divRightMenu.AfterLoadDFMValues;
    btnTitel.AfterLoadDFMValues;
    divText.AfterLoadDFMValues;
    lblText.AfterLoadDFMValues;
    divEdit.AfterLoadDFMValues;
    mText.AfterLoadDFMValues;
    WebHTMLDiv3.AfterLoadDFMValues;
    btnNew.AfterLoadDFMValues;
    divLikeWrapper.AfterLoadDFMValues;
    btnLike.AfterLoadDFMValues;
    divLikeCount.AfterLoadDFMValues;
    divlikeUser.AfterLoadDFMValues;
    divComments.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.
