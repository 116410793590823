unit uMain;

interface

uses
  system.SysUtils, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.WebCtrls, uTB,uGlobal,
  uMe, uMEKurse, uMEBlogs, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.ExtCtrls,
  Vcl.Imaging.jpeg, Vcl.Menus, WEBLib.JQCtrls, weblib.webtools, XData.Web.Client,
  ufrChat, WEBLib.EditAutocomplete, System.Classes, uCrypt, System.DateUtils,
  System.Variants, Vcl.Graphics, WEBLib.Menus, Vcl.Imaging.pngimage,
  WEBLib.WebSocketClient, system.StrUtils;

type
  TMainform = class(TForm)
    divMainCenter: THTMLDiv;
    Timer: TTimer;
    divStart: THTMLDiv;
    divMainHeader: THTMLDiv;
    openDlg: TOpenDialog;
    divMenuLeft: THTMLDiv;
    mnuMainLeft: TMainMenu;
    miSeemannschaft: TMenuItem;
    miLektionen: TMenuItem;
    miSegelWiki: TMenuItem;
    miCommunity: TMenuItem;
    miBlog: TMenuItem;
    miMitglieder: TMenuItem;
    miEvents: TMenuItem;
    miGruppen: TMenuItem;
    miVereine: TMenuItem;
    HandgegenKoje1: TMenuItem;
    miBlogNeueste: TMenuItem;
    miBlogNeu: TMenuItem;
    miMeineBlogs: TMenuItem;
    WebHTMLDiv1: THTMLDiv;
    imgLogo: TImageControl;
    SeglerPortalde1: TMenuItem;
    WebHTMLDiv7: THTMLDiv;
    WebHTMLDiv4: THTMLDiv;
    imgAvatar: TImageControl;
    BadgeFS: TBadge;
    btnUserName: TButton;
    miKonto: TMenuItem;
    miBackend: TMenuItem;
    miAGB: TMenuItem;
    miPaketeEdit: TMenuItem;
    miKurseEdit: TMenuItem;
    miIP: TMenuItem;
    miTutolials: TMenuItem;
    miHowTo: TMenuItem;
    miLogout: TMenuItem;
    miCodeEingeben: TMenuItem;
    N1: TMenuItem;
    divMainFooter: THTMLDiv;
    WebHTMLDiv8: THTMLDiv;
    WebHTMLDiv9: THTMLDiv;
    WebHTMLDiv10: THTMLDiv;
    WebHtmlDiv11: THTMLDiv;
    WebHTMLDiv12: THTMLDiv;
    WebHTMLDiv13: THTMLDiv;
    WebHTMLDiv14: THTMLDiv;
    WebHTMLDiv15: THTMLDiv;
    miMitgliederImportieren: TMenuItem;
    N2: TMenuItem;
    miAGBEdit: TMenuItem;
    miMessenger: TMenuItem;
    ChatClient: TSocketClient;
    miMeinProfil: TMenuItem;
    ListenProtokolle1: TMenuItem;
    miSicherheitseinweisung: TMenuItem;
    btnImpressum: THTMLDiv;
    btnAGB: THTMLDiv;
    MeinePos1: TMenuItem;
    T1: TMenuItem;
    T2: TMenuItem;
    procedure miKurseEditClick(Sender: TObject);
    procedure btnUserNameClick(Sender: TObject);
    procedure divMainFooterMouseLeave(Sender: TObject);
    procedure divMainFooterMouseEnter(Sender: TObject);
    procedure miBlogAlleClick(Sender: TObject);
    procedure miMemberClick(Sender: TObject);
    procedure miGruppenClick(Sender: TObject);
    procedure miEventsClick(Sender: TObject);
    procedure miHowToClick(Sender: TObject);
    procedure miCodeEingebenClick(Sender: TObject);
    procedure miAGBEditClick(Sender: TObject);
    procedure miMessengerClick(Sender: TObject);
    procedure ChatClientMessageReceived(Sender: TObject; AMessage: string);
    [async] procedure miLektionenClick(Sender: TObject); async;
    [async] procedure openDlgGetFileAsText(Sender: TObject; AFileIndex: Integer; AText: string); async;
    [async] procedure openDlgChange(Sender: TObject); async;
    [async] procedure importSkipperClick(Sender: TObject); async;
    [async] procedure miLogoutClick(Sender: TObject); async;
    [async] procedure miIPClick(Sender: TObject); async;
    [async] procedure WebFormCreate(Sender: TObject); async;
    [async] procedure TimerTimer(Sender: TObject); async;
    [async] procedure miMeinProfilClick(Sender: TObject); async;
    [async] procedure miSicherheitseinweisungClick(Sender: TObject); async;
    [async] procedure miAGBClick(Sender: TObject); async;
    procedure btnImpressumClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure MeinePos1Click(Sender: TObject);
  protected
  private
    FMaxNewFotos,
    FMaxNewVideos,
    FChat_Port: integer;
    FChat_Host:string;
    FChats:TChats;
    FForms : array of TForm;
    FWaitDiv:THtmlDiv;
    x:integer;
    FAktion,sHelp:string;
    FDummy:integer;
    FSize1,  FSize2, FSize3 :string;
    PRichtung, PUser, PHolder, pForm, pModul, pID, pFilter:string;
    FMe:TMe;
    FfrmMeKurse : TfrmMeKurse;
    FfrmMeBlogs : TfrmMeBlogs;
    FfrmMe : TfrmMe;
    FHolder:TME;
    FHolderID:integer;
    FAutoRefresh:integer;
    FCall,
    FWait:integer;
    procedure SetWait(AValue:integer);
    function  GetMeins:boolean;
    procedure HashTagClick(Sender: TObject);
    procedure SetHolderID(value:integer);
    function GetForm(Index: Integer): TForm;
    procedure SetForm(Index: Integer; Value: TForm);
    [async] procedure CreateHashTagMenu; async;
  public
    property Forms[index:integer]: TForm read GetForm write SetForm;
    property Chats:TChats read FChats write FChats;
    function ShowField(AFieldStatus:string; AFieldID: integer; AIsFriend:boolean):boolean;
    [async] function isFriend:boolean; async;
    procedure Start(AHolderID:Integer);
    property HolderID : Integer read FHolderID write SetHolderID;
    property ME:TMe read FMe write FMe;
    property Holder:TMe read FHolder write FHolder;
    property Meins: boolean read GetMeins;
    property frmMe : TfrmMe read FfrmMe write FfrmME;
    property AutoRefresh:integer read FAutoRefresh write FAutoRefresh;
    property Aktion:string read FAktion write FAktion;
    property Wait: integer read FWait write SetWait;
    property Call: integer read FCall write FCall;
    property MaxNewFotos:integer  read FMaxNewFotos write FMaxNewFotos;
    property MaxNewVideos:integer read FMaxNewVideos write FMaxNewVideos;
    procedure SetBlur(AElement:TControl; AHolder:integer; ABlur:boolean);
  protected procedure LoadDFMValues; override; end;

var
  Mainform: TMainform;

implementation
  Uses  uDatenmodul, uStart, uKursEdit, uModal, uProfil,
        uDocViewer, uTextEdit, uMsg, uPrint, uMeldung;

{$R *.dfm}

procedure TMainform.SetBlur(AElement:TControl; AHolder:integer; ABlur:boolean);
Var
  img : TImageControl;
  iFaktor:integer;
begin
//  showmessage((Mainform.ME.BlurFoto div 3).tostring);

  if (Mainform.ME.ID <> AHolder) and (ABlur)
  then begin

    if (AElement is TImageControl)
    then begin
      iFaktor := AElement.width div 100;
      if iFaktor < 3
      then iFaktor := 3;

      inc(iFaktor);
//      if AElement.width < 200
//      then AElement.elementhandle.style.setProperty('filter','blur(' + (Mainform.ME.BlurFoto div 3).tostring + 'px)')
      //else
      AElement.elementhandle.style.setProperty('filter','blur(' + iFaktor.tostring + 'px)');
    end
    else begin
      AElement.elementhandle.style.setProperty('background','linear-gradient(to top, #eee, #eee, #000)');
      AElement.elementhandle.style.setProperty('-webkit-background-clip','text');
      AElement.elementhandle.style.setProperty('-webkit-text-fill-color','transparent');
      AElement.elementhandle.style.setProperty('user-select','none');
    end;
  end
  else AElement.elementhandle.style.setProperty('filter','blur(0px)');
end;

function TMainform.GetForm(Index: Integer): TForm;
begin
  Result := FForms[Index];
end;

procedure TMainform.SetForm(Index: Integer; Value: TForm);
begin
  FForms[Index] := Value;
end;

procedure TMainform.SetWait(AValue:integer);
Var
  d : THTMLDiv;
begin
   FWait := FWait + AValue;
   if (FWait > 0) and (not assigned( FWaitDiv))
   then begin
      FWaitDiv := THtmlDiv.Create(Self);
      FWaitDiv.Parent := Mainform;
      FWaitDiv.ElementClassName := 'editor_transparent';
      FWaitDiv.ElementHandle.style.setProperty('z-index','999999');

      d := THTMLDiv.Create(Self);
      d.Parent := FWaitDiv;
      d.ElementPosition := epAbsolute;
      d.HeightStyle := ssAuto;
      d.WidthStyle := ssAuto;
      d.ElementHandle.style.setProperty('margin','0 auto');

      d.ElementClassName := 'loader';
   end;

   if assigned(FWaitDiv)
   then FWaitDiv.Visible := (FWait > 0);

end;

function TMainform.isFriend:boolean;
Var
  j:TJ;
begin
  j := TJ.create(await(datenmodul.EasySQL('SELECT ID from Friends where ' +
                                          ' (EMPFAENGER = ' + Holder.ID.ToString + ' OR SENDER = ' + Holder.ID.ToString + ') AND ' +
                                          ' (EMPFAENGER = ' + ME.ID.ToString + ' OR SENDER = ' + ME.ID.ToString + ')')));

  result := j.integer('ID') > 0;

  j.Free;

end;

procedure TMainform.MeinePos1Click(Sender: TObject);
begin
    asm
      function ermittlePosition() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(zeigePosition);
        } else {
           alert("Ihr Browser unterstützt keine Geolocation.");
        }
      }

      function zeigePosition(position) {

         alert( "Ihre Koordinaten sind:<br> Breite: " + position.coords.latitude +
          "<br>Länge: " + position.coords.longitude);
      }

      ermittlePosition()
    end;

end;

function TMainform.ShowField(AFieldStatus:string; AFieldID:integer; AIsFriend:boolean):boolean;
begin

  if (not Mainform.Meins)
  then begin
//    showmessage('meins1');
    case TFieldStatusIdx(strToInt(AFieldStatus[AFieldID])) of
      fsidxIch:        result := Mainform.Meins;
      fsidxFreunde:    result := AIsFriend;
      fsidxMitglieder: result := Mainform.Holder.Gast = 0;
      fsidxJeder:      result := true;
    end;

  end
  else begin
   // showmessage('meins2');

    result := true;
  end;

end;

procedure TMainform.SetHolderID(value:integer);

  [async]
  procedure exec(AHolderID:integer); async;

    [async]
    procedure AfterCreate(AForm: TObject); async;
    begin
      TfrmME(AForm).Parent := self;
      await(FfrmME.init(FHolder.id, pModul.ToInteger));
    end;

    [async]
    procedure AfterCreateKurse(AForm: TObject); async;
    begin
      TfrmMEKurse(AForm).Parent := self;
      TfrmMEKurse(AForm).init(pID.toInteger, pModul.ToInteger)
    end;

    [async] procedure AfterCreateBlogs(AForm: TObject); async;
    begin
      TfrmMEBlogs(AForm).Parent := self;
      TfrmMEBlogs(AForm).init(pID.toInteger, pModul.ToInteger, pFilter)
    end;

  Var
    j:TJ;
  begin

    mainform.Wait := 1;
    J := TJ.create(Await( datenmodul.Client.RawInvokeAsync( 'IDBService.HolderGetByID',[AHolderID])));
    if J.Integer('ID') > 0
    then begin
      //FHolder.UserState  := _US_FREUND;
      j.UserState        := FHolder.UserState;
      j.FieldState       := J.Value('FIELDSTATUS');
      FHolder.id         := J.Integer('ID');
      FHolder.Name       := j.Value('NAME');
      FHolder.Vorname    := j.Value('VORNAME');
      FHolder.Ort        := j.Value('ORT');
      FHolder.NickName   := j.Value('NICKNAME');
      FHolder.BLand      := j.Value('BLAND');
      FHolder.Gast       := j.Integer('GAST');
      FHolder.FieldStatus:= j.Value('FIELDSTATUS');
      FHolder.Avatar     := j.Integer('AVATAR_MEDIEN_ID');
      FHolder.AvatarPos  := j.Value('AVATAR_MEDIEN_POSITION');
      FHolder.Titel      := j.integer('TITEL_MEDIEN_ID');
      FHolder.TitelPos   := j.Value('TITEL_MEDIEN_POSITION');
      FHolder.Album      := j.Integer('ALBUM_ID');
      FHolder.Typ        := j.Integer('TYP');
      FHolder.Status     := j.Integer('STATUS_ID');
      FHolder.Holder     := j.Integer('HOLDER_ID');
      FHolder.About      := j.Value('ABOUT');
      FHolder.Hobbies    := j.Value('HOBBIES');
      FHolder.Reviere    := j.Value('REVIERE');
      FHolder.Expertise  := j.Value('EXPERTISE');
      FHolder.Admin      := j.isTrue('ADMIN');
      FHolder.Trainer    := j.isTrue('TRAINER');
      FHolder.Organizer  := j.isTrue('ORGANIZER');
      FHolder.EMail      := j.Value('EMAIL');
    end;

    case pForm of
     _Call_ME:    begin
                    if pModul.ToInteger = 0
                    then OpenURL(ME.ID.tostring,FHolder.id.tostring, _Call_ME,  '1','0') //Chronik
                    else FfrmMe := TfrmMe.CreateNew(divMainCenter.ElementID, @AfterCreate);
                  end;
     _Call_Kurse: begin
                    FfrmMeKurse := TfrmMeKurse.CreateNew(divMainCenter.ElementID, @AfterCreateKurse);
                  end;
     _Call_Blogs: begin
                    FfrmMeBlogs := TfrmMeBlogs.CreateNew(divMainCenter.ElementID, @AfterCreateBlogs);
                  end;
    end;

    freeAndNil(j);
    mainform.Wait := -1;

  end;

begin
  exec(Value);
end;

procedure TMainform.Start(AHolderID:Integer);
//wird von frmStart.login aucfgerufen...

  [async]
  procedure AfterCreate(AForm: TObject); async;
  begin
    //  await(FillWithHashTags(edHashTag));
//    TfrmME(AForm).Parent := self;

    if pHolder = '0'  //gibt es nur beim ersten Aufruf
    then HolderID := AHolderID
    else HolderID := pHolder.Tointeger;

    //  await(CreateHashTagMenu);
  end;

  [async]
  procedure exec(AME:TME); async;

    function tbPos(ASub, AString:string; ACount:integer):integer;
    var
      i,count:integer;
      s:string;
    begin
      count := 0;
      result:= 0;

      for I := 0 to length(AString) - 1 do
      begin
        if aString[i] = ASub
        then begin
          inc(count);
          if count = ACount
          then begin
            result := i;
            exit;
          end;
        end;
      end;
    end;

    procedure CBMeldung(A:array of string);
    begin
      if a[0] = '1'
      then miMeinProfilClick(self);
    end;

  Var
    j:TJ;
    JSObj: TJSObject;
    JSString:string;
    s:string;
    lHeute:boolean;
  begin
    mnuMainLeft.BeginUpdate;

    miBackend.Visible  := ME.Admin;
    miIP.Visible       := ME.Admin;
    miTutolials.Visible:= ME.Trainer;

    mnuMainLeft.EndUpdate;

    imgAvatar.URL := await(datenmodul.GetMedia(AME.Avatar,_SIZE3));

    j := TJ.create(await(datenmodul.easySQL('SELECT CASE ' +
                                            '         WHEN DATE(MAX(login)) = CURRENT_DATE THEN 1 ' +
                                            '         ELSE 0 ' +
                                            '        END AS HEUTE ' +
                                            '  from Login WHERE USER_ID = ' + ME.ID.ToString)));
    lHeute := j.isTrue('HEUTE');

    btnUserName.Caption := AME.Nickname;

    BadgeFS.Text := Inttostr(await(Datenmodul.FSCount(AME.ID, 0)));

    JSString := '{"PUSER": ' + AME.ID.ToString + ', "PLOGIN": 1, "PFORM":' + pForm + ', "PMODUL":' + pModul + ', "PHOLDER":' + pHolder + ', "PITEM":' + pID + '}';

    JSObj := TJSJSON.parseObject(JSString);
    Await(datenmodul.Client.RawInvokeAsync( 'IDBService.WriteLogin',[JSObj]));

    ChatClient.HostName := FChat_Host;
    ChatClient.Port     := FChat_Port;
    ChatClient.Active   := true;


    if lHeute = false //Nur beim ersten Aufruf
    then begin

      j.Response := await(datenmodul.easySQL('select STATUS from User where ID = ' + ME.ID.ToString));

      if (j.Integer('STATUS') = 1) and (pModul <> '9')
      then begin
        TfrmMeldung.Exec('Willkommen beim Segler-Portal!',
                   'Die E-Mail-Adresse, die du bei deiner Anmeldung verwendet hast, ' +
                   'wird bereits von einem anderen Mitglied genutzt.<br>' +
                   'Für unser Portal ist die Eindeutigkeit der E-Mail-Adresse sehr ' +
                   'wichtig, da wir nur auf diesem Wege mit dir in Kontakt treten können.<br>' +
                   'Bitte ändere deine E-Mail-Adresse umgehend, da du ansonsten viele Funktionen ' +
                   'nicht nutzen kannst.<br><br>' +
                   'Vielen Dank für dein Verständnis!',
                   ['Jetzt ändern'], @cbMeldung);
      end
      else begin
        if (ME.hideFotos) and (pModul <> '9')
        then TfrmMeldung.Exec('Willkommen beim Segler-Portal!',
                   'Bitte wähle ein Profil- und ein Titelbild aus.<br>' +
                   'Diese werden anderen Nutzern angezeigt, wenn du mit ihnen interagierst. ' +
                   'Bitte beachte, dass die Auswahl deines Profil- und Titelbildes essentiell ' +
                   'ist, um Fotos anderer Nutzer zu sehen und um selbst gesehen zu werden.<br><br>' +
                   'Vielen Dank für deine Mitarbeit!',
                   ['Jetzt ändern', 'Später ändern'], @cbMeldung);
        end;

    end;
  end;

Var
  J:TJ;
begin

  divStart.Visible := false;
  asm
    document.body.style.backgroundImage = 'none';
    document.body.style.backgroundColor = '#ECF1F5';
  end;
  divMainHeader.Visible := true;
  divMainCenter.Visible := true;
//  divMainFooter.Visible := true;
  Exec(ME);

  Timer.Interval := FAutoRefresh;
  Timer.Enabled := true;

  if pHolder = '0'  //gibt es nur beim ersten Aufruf
  then HolderID := AHolderID
  else HolderID := pHolder.Tointeger;

end;

procedure TMainform.miLektionenClick(Sender: TObject);
begin
  if Sender = miLektionen
  then openURL(mainform.ME.ID.ToString, mainform.ME.ID.ToString, _call_Kurse, _KA_Lektion.ToString,'0')
  else openURL(mainform.ME.ID.ToString, mainform.ME.ID.ToString, _call_Kurse, _KA_Tutorial.ToString,'0')
end;

procedure TMainform.miBlogAlleClick(Sender: TObject);
begin
  openURL(mainform.ME.ID.ToString, mainform.ME.ID.ToString, _call_Blogs, '1',TMenuItem(sender).Tag.tostring)
end;

procedure TMainform.btnUserNameClick(Sender: TObject);
begin
  openURL(ME.ID.ToString, ME.ID.ToString, _call_ME,  '1','0');
end;

procedure TMainform.miAGBClick(Sender: TObject);

  [async] procedure Callback(s:string); async;
  begin
    await(datenmodul.EasyExec('Update User set AGB = CURDATE() where ID = ' + Mainform.ME.ID.toString));
  end;

Var
  j:TJ;
begin
  if mainform.ME.ID = 0 // Noch nicht eingeloggt
  then TfrmDocViewer.Exec(0,7,[],nil)
  else begin
    j := TJ.create(await(datenmodul.EasySQL('SELECT AGB from User where ID = ' + Mainform.ME.ID.toString)));

    if j.Value('AGB') = '' //eingeloggt aber AGB noch nicht bestätigt
    then TfrmDocViewer.exec(0,7,['Ablehnen' ,'Annehmen'], @Callback)
    else TfrmDocViewer.Exec(0,7,[],nil); //eingeloggt und AGB bereits bestätigt
  end;
end;

procedure TMainform.miAGBEditClick(Sender: TObject);
begin
  TfrmTextEdit.Show( 'Texte bearbeiten', 0, 0,nil);
end;

procedure TMainform.miCodeEingebenClick(Sender: TObject);
Var
  d : THTMLDiv;
  FConti:THtmlDiv;
begin

  FConti := THtmlDiv.Create(Mainform);
  FConti.Parent := Mainform;
  FConti.ElementClassName := 'editor_transparent';

  d := THTMLDiv.Create(self);
  d.Parent := FConti;
  d.ElementPosition := epAbsolute;
  d.HeightStyle := ssAuto;
  d.WidthStyle := ssAuto;
  d.ElementHandle.style.setProperty('margin','0 auto');

  d.ElementClassName := 'loader';

end;

procedure TMainform.miEventsClick(Sender: TObject);
begin
  openUrl(mainform.ME.ID.ToString, mainform.ME.ID.ToString, _call_ME, '14','0');
end;

procedure TMainform.divMainFooterMouseEnter(Sender: TObject);
begin
  divMainFooter.Elementhandle.style.setProperty('height', '130px');
end;

procedure TMainform.divMainFooterMouseLeave(Sender: TObject);
begin
  divMainFooter.elementhandle.style.setProperty('height', '30px');
end;

procedure TMainform.miIPClick(Sender: TObject);

  function getDeviceID():string;
  begin
    asm
      function getDeviceID() {
          /* Für Android-Geräte */
          if (typeof window.device !== 'undefined' && typeof window.device.uuid !== 'undefined') {
              return window.device.uuid;
          }

          /* Für iOS-Geräte     */
          if (typeof window.device !== 'undefined' && typeof window.device.uuid !== 'undefined') {
              return window.device.uuid;
          }

/*          // Für Desktop-Computer (beispielsweise Windows)
          // Hier müssten spezifische Methoden implementiert werden, um auf Hardware-IDs zuzugreifen
          // Dies erfordert normalerweise besondere Berechtigungen oder Systemzugriff und ist nicht direkt über JavaScript möglich

          // Für Webbrowser (Fallback: Browser-Fingerprinting)
          // Hier könnten verschiedene Informationen über den Browser und das Gerät des Benutzers gesammelt werden, um einen Fingerprint zu erstellen
          // Dies ist jedoch weniger zuverlässig als eine echte Geräte-ID

          var fingerprint = createBrowserFingerprint(); // Annahme: Eine Funktion createBrowserFingerprint() ist definiert
          return fingerprint;
*/
          // Rückgabe einer Standard-ID, falls keine echte Geräte-ID gefunden wurde
          return 'unknown';
      }
    end;
  end;

  function GetLocalIPAddress: string;
  begin
    asm
      fetch('https://api.ipify.org/?format=json')
        .then(response => response.json())
        .then(data => {
          const ip = data.ip;
          alert('Meine IP-Adresse ist: ' + ip);
        })
        .catch(error => {
          console.error('Fehler beim Abrufen der IP-Adresse:', error);
        });
    end;
  end;

var
  Response: TXDataClientResponse;
  j : TJ;
begin

  showmessage(getDeviceID);

  GetlocalIPAddress;

  response := Await( datenmodul.Client.RawInvokeAsync( 'IDBService.GetUserIP',[]));

  j := TJ.create(response);
  showmessage(j.value('IP'));
  j.free;
end;

function TMainform.GetMeins:boolean;
begin
  //z.b. ein Album gehört mir wenn ich der Holder bin oder wenn es zu einer Gruppe gehört deren Holder ich bin
  result := ((FME.ID = FHolder.ID) or (FME.ID = FHolder.Holder))
end;

procedure TMainform.HashTagClick(Sender: TObject);
begin
end;

procedure TMainform.ChatClientMessageReceived(Sender: TObject; AMessage: string);
Var
  sHolder, sUser, sText, s:string;
begin
  s := aMessage;
//  showmessage(s);
  sHolder := copy(s,1, Pos('|', s)-1);
  s := copy(s,length(sHolder)+2,length(s));
  sUser := copy(s,1, Pos('|', s)-1);
  s := copy(s,length(sUser)+2,length(s));
  sText := copy(s,1, length(s));

  //eine neue Nachricht für mich? egal von wem, auch wenn ich nicht auf seiner Seite bin
  if (mainform.ME.id.ToString = sHolder)
  then begin
    if (not assigned(FChats))
    then FChats := TChats.create(self, ChatClient.HostName, ChatClient.Port);

    FChats.Add(sUser.ToInteger, Mainform.ME.Id, '');
  end;
end;

procedure TMainform.CreateHashTagMenu;
var
  j: TJ;
  i: integer;
  mi:TMenuItem;
begin

  j := TJ.create(await(datenmodul.EasySQL('Select ID, TEXT from blogKat where AKTIV = 1 AND LEVEL = 1')));

  mnuMainLeft.BeginUpdate;
  for i := 0 to j.length-1 do
  begin
    j.Index := i;
    mi := TMenuItem.Create(self);
    mi.Caption := j.Value('TEXT');
    mi.Tag := j.integer('ID');
    mi.OnClick := miBlogAlleClick;
    miBlog.Add(mi);
  end;
  mnuMainLeft.EndUpdate;

end;

procedure TMainform.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  datenmodul.Connection.Close;
  freeAndNil(datenmodul);
  action := cafree;
end;

procedure TMainform.WebFormCreate(Sender: TObject);

  [async] procedure AfterCreate(AForm: TObject); async;
  Var
    J:TJ;
  begin

    if (datenmodul.Connection.Connected=false)
    then begin

      TfrmStart(AForm).divWartung.visible := true;
      TfrmStart(AForm).divByeBye.Visible  := false;
      TfrmStart(AForm).divLogin.Visible   := false;
      TfrmStart(AForm).divLanding.Visible := false;
      TfrmStart(AForm).divLosgehts.Visible:= false;
      TfrmStart(AForm).divGruende.Visible := false;
      TfrmStart(AForm).divPreise.Visible  := false;
      TfrmStart(AForm).divMenue.Visible   := false;

      TfrmStart(AForm).divStartSeite.Visible := true;
      Wait := -1;

      exit;
    end;

    //Bestimmte Angebote (mitgliedschaften) z,B Weihnachsangebot
    if FAktion > ''
    then TfrmStart(AForm).Aktion := FAktion
    else TfrmStart(AForm).Aktion := '0';

    Wait := -1;

  end;

var
  el: TJSHTMLElement;
  j:TJ;
begin
  inherited;

  Wait:=1;

  try
    await(datenmodul.Connection.OpenAsync);

    el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
    el.style.removeProperty('height');

    el := TJSHTMLElement(divMainCenter.ElementHandle);
    el.style.removeProperty('height');

    FME := TME.Create;
    FHolder := TME.Create;

    divMainHeader.Visible := false;
    divMainCenter.Visible := false;

    divStart.Visible := GetQueryParam('p1') = '';
    self.Visible := GetQueryParam('p1') = '';

    CreateHashTagMenu;

    j := TJ.create(await(datenmodul.EasySQL('select CHAT_HOST, CHAT_PORT, AUTO_REFRESH, MAX_NEW_FOTOS, MAX_NEW_VIDEOS from System')));

    // 5 minuten = 1000*60*5 = 300000
    FAutoRefresh  := J.Integer('AUTO_REFRESH') * 60000;     //1 Minute
    FChat_Host    := J.Value('CHAT_HOST');
    FChat_Port    := J.Integer('CHAT_PORT');
    FMaxNewFotos  := J.Integer('MAX_NEW_FOTOS');
    FMaxNewVideos := J.Integer('MAX_NEW_VIDEOS');
    j.Free;

    pUser    := GetQueryParam('p1');
    pHolder  := GetQueryParam('p2');
    pForm    := GetQueryParam('p3');
    pModul   := GetQueryParam('p4');
    pID      := GetQueryParam('p5');
    pFilter  := GetQueryParam('p6');
    pRichtung:= GetQueryParam('p7');

    if pModul  = '' then pModul := '0';
    if pUser   = '' then pUser  := '0';
    if pID     = '' then pID    := '0';
    if pHolder = '' then pHolder:= '0';
    if pForm   = '' then pForm  := _Call_ME;

    TfrmStart.CreateNew(divStart.ElementID, @AfterCreate);

    if pUser.toInteger > 0
    then TfrmStart.Login(pUser.toInteger,'',''); //ruft nach Login Mainform.Start auf und setzt den Login-User
    self.Visible := true;
  finally
  end;

end;

procedure TMainform.TimerTimer(Sender: TObject);
Var
    JSObj: TJSObject;
    JSString:string;
begin
  if assigned(mainform.ME)
  then begin
    JSString := '{"PUSER": ' + mainform.ME.ID.ToString + ', "PLOGIN": 0, "PFORM":' + pForm + ', "PMODUL":' + pModul + ', "PHOLDER":' + pHolder + ', "PITEM":' + pID + '}';
    JSObj := TJSJSON.parseObject(JSString);
    await(datenmodul.Client.RawInvokeAsync( 'IDBService.WriteLogin',[JSObj]));
  end;

//  Chatclient.HostName := copy(datenmodul.Connection.URL,1,pos(datenmodul.Connection.URL,':') - 1) ;// 'http://www.segler-portal.de';
//  Chatclient.Port     := 8888;

  //Chatclient.Active   := true;
end;

procedure TMainform.miGruppenClick(Sender: TObject);
begin
  openUrl(mainform.ME.ID.ToString, mainform.ME.ID.ToString, _call_ME, '11','0');
end;

procedure TMainform.miHowToClick(Sender: TObject);
begin
  TfrmKursEdit.Exec(_KA_Tutorial);
end;

procedure TMainform.miMeinProfilClick(Sender: TObject);
begin
  openUrl(mainform.ME.ID.ToString, mainform.ME.ID.ToString, _call_ME, '9','0');
end;

procedure TMainform.miMemberClick(Sender: TObject);
begin
  openUrl(mainform.ME.ID.ToString, mainform.ME.ID.ToString, _call_ME, '10','0');
end;

procedure TMainform.miMessengerClick(Sender: TObject);
begin
  if (not assigned(FChats))
  then FChats := TChats.create(self, ChatClient.HostName, ChatClient.Port);

  FChats.Add(Mainform.Holder.Id, Mainform.ME.Id);
end;

procedure TMainform.miSicherheitseinweisungClick(Sender: TObject);
  procedure CB(s:string);
  begin
//    SHowmessage('CAllback');
  end;

Var
  J:TJ;
begin
  J := TJ.create(await(datenmodul.EasySQL('SELECT ID from Badge Where User_ID = ' + Mainform.ME.ID.ToString + ' AND ITEM_ID = 2')));
  if j.integer('ID') = 0
  then begin
    await(meldung('Das Protokoll kann erst gedruckt werden wenn du die Sicherheitseinweisung komplett durchgeführt hast.<br><br> ' +
                  'Du findest die Sicherheitseinweisung unter "Seemannschaft -> Sicherheitseinweisung".'));
  end
  else begin
    TPrint.printProtokoll(Mainform.ME.ID, Mainform.ME.Vorname + ' ' + Mainform.ME.Name, @cb);
  end;

end;

procedure TMainform.btnImpressumClick(Sender: TObject);
begin
 TfrmDocViewer.Exec(0, 6,[],nil);
end;

procedure TMainform.miKurseEditClick(Sender: TObject);
begin
  TfrmKursEdit.Exec(_KA_Lektion);
end;

procedure TMainform.importSkipperClick(Sender: TObject);
begin
  openDlg.OnGetFileAsText := openDlgGetFileAsText;
  openDlg.OnGetFileAsBase64  := nil;
  openDlg.Tag := 4;

  opendlg.Execute;
end;

procedure TMainform.openDlgChange(Sender: TObject);
Var
  i:integer;
begin
//  showmessage('Import-Art: ' + opendlg.Tag.ToString);

  for i := 0 to OpenDlg.Files.Count - 1 do
  begin
//    setlength(FDummy[i], 2);

    if assigned(openDlg.onGetFileAsText)
    then OpenDlg.Files[i].GetFileAsText;

    if assigned(openDlg.onGetFileAsBase64)
    then begin
      if pos('- Kopie',OpenDlg.Files[i].Name) = 0
      then begin
//         showmessage('Fileindex: -' + i.ToString + ' - ' + opendlg.Files[i].Name);
         await(OpenDlg.Files[i].GetFileAsBase64);
      end;
    end;
  end;

end;

procedure TMainform.openDlgGetFileAsText(Sender: TObject; AFileIndex: Integer; AText: string);
var
  sl, sl2: TStrings;
  i,k: integer;
  sAvatar, sTitel, sFieldstat, sPosition,
  sAdmin, sTrainer, sGruppeTitel,sOrganizer,sKat,
  sText3, sText, sID, sVor, sTyp, sVideo,sfoto, sText2,
  sUser, sGruppe, sAnrede, s, sOrt, sPost,
  sBis, sLand, sBLand, sDatum, sRechte,
  s3, s4, sBlogKatID, sBlogID:string;
  jSuchen, j:TJ;
  d:TDateTime;
  StartZeile, MaxKat :integer;
  _Extern, _Anrede, _Vorname,
  _Name,_Plz, _Ort, _Strasse,
  _BLand, _Land, _EMail,
  _GebDatum, _Gruppe, _Reise, _ReiseArt:integer;
  iChange, iDel,iNew, iTrainer, iGruppen:integer;

begin

  sl := System.Classes.TStringList.Create;

  sl2 := TStringList.Create;
  sl2.Delimiter := ',';
  sl2.StrictDelimiter := True;
  sl.Text := aText;

  j := TJ.create;

  _Extern  := 0;
  _Anrede  := 1;
  _Vorname := 2;
  _Name    := 3;
  _Plz     := 4;
  _Ort     := 5;
  _Strasse := 6;
  _BLand   := 7;
  _Land    := 8;
  _EMail   := 9;
  _GebDatum:= 10;
  _Gruppe  := 11;
  _Reise   := 12;
  _ReiseArt:= 13;

  StartZeile := 7;
  showmessage(sl[Startzeile]);
  sl2.DelimitedText := sl[Startzeile];
  if await(Frage( 'Extern: '    + sl2[_Extern]  + '<br>' +
                  'Anrede: '    + sl2[_Anrede]  + '<br>' +
                  'Vorname: '   + sl2[_Vorname] + '<br>' +
                  'Name:    '   + sl2[_Name]    + '<br>' +
                  'PLZ: '       + sl2[_Plz]     + '<br>' +
                  'Ort: '       + sl2[_Ort]     + '<br>' +
                  'Strasse: '   + sl2[_Strasse] + '<br>' +
                  'B-Land: '    + sl2[_BLand]   + '<br>' +
                  'Land: '      + sl2[_Land]    + '<br>' +
                  'E.Mail: '    + sl2[_EMail]   + '<br>' +
                  'Geb-Datum: ' + sl2[_GebDatum]+ '<br>' +
                  'Gruppe: '    + sl2[_Gruppe]  + '<br>' +
                  'Reise: '     + sl2[_Reise]   + '<br>' +
                  'Reise-Art: ' + sl2[_ReiseArt]+ '<br><br>' +
                  'Wenn ok, dann "Ja" klicken...' )) <> mrYes
  then begin
    await(Meldung('Import wurde abgebrochen...'));
    abort;
  end
  else begin
    if await(Frage((sl.Count - Startzeile).ToString + ' Mitglieder importieren?')) <> mrYes
    then abort;
  end;

  iChange:=0; iDel := 0; iNew := 0; iTrainer := 0; iGruppen:=0;

  j := TJ.create;
  jSuchen := TJ.create;

  j.Response := await(datenmodul.EasySQL('select AVATAR_MEDIEN_ID, TITEL_MEDIEN_ID, EVENT_MEDIEN_ID, EVENT_MEDIEN_POSITION, FIELDSTATUS, TEXT2, TEXT3, TEXT4 from SYSTEM' ));
  sAvatar    := j.Value('AVATAR_MEDIEN_ID');
  sTitel     := j.Value('TITEL_MEDIEN_ID');
  sFieldstat := j.Value('FIELDSTATUS');
  sFoto      := j.Value('EVENT_MEDIEN_ID');
  sPosition  := j.Value('EVENT_MEDIEN_POSITION');
  sText      := j.Value('TEXT2');
  sText2     := j.Value('TEXT3');
  sText3     := j.Value('TEXT4');

  j.Response := await(datenmodul.EasySQL('select ID from User where Admin = 1 AND typ = ' + _UT_User.ToString + ' and Nickname = ''SePo-Admin'''));
  sAdmin := j.Value('ID');

  //evtl. Admin anlegen
  if sAdmin = ''
  then begin

    j.Response := await(datenmodul.EasySQL('select ID from RECHTE2 where TEXT = ''Admin''' ));
    sRechte := j.Value('ID');
    if sRechte = ''
    then sRechte := await(datenmodul.EasyInsert('INSERT INTO RECHTE2 (BLOG, POST, FREUNDE, GRUPPEN, KURSE, PROFIL, ALBEN, EVENTS, TUTORIALS, TEXT) ' +
              ' VALUES(''21111'',''21111'',''21111'',''21111'',''21111'',''21111'',''21111'',''21111'',''21111'',''Admin'')')).ToString;

     sAdmin := await(datenmodul.EasyInsert('INSERT INTO User (Password, Admin, EMail, Typ, RECHTE_ID,  Nickname) ' +
            ' VALUES(''Admin'', 1, ''admin@segler-portal.de'',' +_UT_User.ToString + ',' + sRechte + ',''SePo-Admin'')')).ToString;
  end;

//            await(WelcomePost('22', sAdmin, '', sText2));
//            await(WelcomePost('46', sAdmin, '', sText2));

  //Organizer holen
  j.Response := await(datenmodul.EasySQL('select ID from User where ORGANIZER = 1 AND typ = ' + _UT_User.ToString + ' and Nickname = ''SPOREDO GmbH'''));
  sOrganizer := j.Value('ID');

  //evtl. Organisator anlegen
  if sOrganizer = ''
  then begin

    j.Response := await(datenmodul.EasySQL('select ID from RECHTE2 where TEXT = ''Admin''' ));
    sRechte := j.Value('ID');
    if sRechte = ''
    then sRechte := await(datenmodul.EasyInsert('INSERT INTO RECHTE2 (BLOG, POST, FREUNDE, GRUPPEN, KURSE, PROFIL, ALBEN, EVENTS, TEXT) ' +
              ' VALUES(''21111'',''21111'',''21111'',''21111'',''21111'',''21111'',''21111'',''21111'',''Admin'')')).ToString;

     sOrganizer := await(datenmodul.EasyInsert('INSERT INTO User (Password, ORGANIZER, EMail, Typ, RECHTE_ID,  Nickname) ' +
            ' VALUES(''sporedo'', 1, ''info@segler-portal.de'',' +_UT_User.ToString + ',' + sRechte + ',''SPOREDO GmbH'')')).ToString;
  end;


  Wait := 1;

  //Alle Zeilen der Datei durchlaufen
  for i := StartZeile to sl.Count - 1 do
  begin

    //einzelne Zeile holen
    sl2.DelimitedText := sl[i];

    if sl2[_Gruppe] = '0: LÖSCHEN'
    then begin
      jSuchen.Response := await(datenmodul.EasySQL('SELECT ID from User Where (Name    = ''' + sl2[_Name] + '''' +
                                                   '        and Vorname = ''' + sl2[_Vorname] + '''' +
                                                   '        and EMAIL   = ''' + sl2[_EMail] + ''')' +
                                                       ' or (EXTERN = ''' + sl2[_Extern] + ''')'));

      if jSuchen.Value('ID') > ''
      then begin

        await(datenmodul.EasyExec('DELETE from User Where ID = ' + jSuchen.Value('ID')));
        await(datenmodul.EasyExec('DELETE from Friends Where SENDER = ' + jSuchen.Value('ID') + ' OR ' +
                                                            'EMPFAENGER  = ' + jSuchen.Value('ID')));

      end;

      inc(iDel);

      continue;
    end;

    j.Response := await(datenmodul.EasySQL('select ID from sugg where typ = 1 and Text = ''' + sl2[_Anrede] + ''''));
    sAnrede := j.Value('ID');

    j.Response := await(datenmodul.EasySQL('select ID from LAND where Text = ''' + sl2[_Land] + ''''));
    sLand := j.Value('ID');
    if sLand = ''
    then sLand := await(datenmodul.EasyINSERT('INSERT INTO LAND (TEXT) VALUES(''' + sl2[_Land] + ''')')).ToString;

    j.Response := await(datenmodul.EasySQL('select ID from BLAND where LAND_ID = ' + sLand + ' AND Text = ''' + sl2[_BLand] + ''''));
    sBLand := j.Value('ID');
    if sBLand = ''
    then sBLand := await(datenmodul.EasyINSERT('INSERT INTO BLAND (LAND_ID, TEXT, KURZ) VALUES(' + sLand + ',''' + sl2[_Bland] + ''',''' + Uppercase(copy(sl2[_BLand],1,3)) + ''')')).ToString;

    j.Response := await(datenmodul.EasySQL('select ID from ORT where LAND_ID = ' + sLand + ' AND Text = ''' + sl2[_Ort] + ''''));
    sOrt := j.Value('ID');
    if sOrt = ''
    then sOrt := await(datenmodul.EasyINSERT('INSERT INTO ORT (LAND_ID, TEXT) VALUES(' + sLand + ', ''' + sl2[_Ort] + ''')')).ToString;

    j.Response := await(datenmodul.EasySQL('select ID from RECHTE2 where TEXT = ''' + sl2[_Gruppe] + '''' ));
    sRechte := j.Value('ID');
    if sRechte = ''
    then sRechte := await(datenmodul.EasyInsert('INSERT INTO RECHTE2 (BLOG, POST, FREUNDE, GRUPPEN, KURSE, PROFIL, ALBEN, EVENTS, TUTORIALS, TEXT) ' +
              ' VALUES(''21111'',''21111'',''21111'',''21111'',''21111'',''21111'',''21111'',''21111'',''21111'','''  + sl2[_Gruppe] + ''')')).ToString;

    if sl2[_Gruppe] = '2: SPOREDO-Trainer: AKTIV'
    then sTrainer := '1'
    else sTrainer := '0';

    sDatum := 'NULL';

    jSuchen.Response := await(datenmodul.EasySQL('Select ID from User Where (Name    = ''' + sl2[_Name] + '''' +
                                                                    '        and Vorname = ''' + sl2[_Vorname] + '''' +
                                                                    '        and EMAIL   = ''' + sl2[_EMail] + ''')' +
                                                                    ' or (EXTERN = ''' + sl2[_Extern] + ''')'));
    sUser := jSuchen.Value('ID');

    if sUser = ''
    then begin //Neuanlegen
      sUser := await(datenmodul.EasyInsert('INSERT INTO USER (ANREDE_ID, EXTERN, FIELDSTATUS, AVATAR_MEDIEN_ID, TITEL_MEDIEN_ID,PASSWORD, NICKNAME, VORNAME, NAME, PLZ, ORT_ID, STRASSE, BLAND_ID, LAND_ID, TRAINER, EMAIL, RECHTE_ID, GDATUM) ' +
                                             'VALUES('    + sAnrede      +   ',' +  //Anrede
                                                     '''' + sl2[_Extern]       + ''',' +  //Externe-Nr
                                                     '''' + sFieldStat   + ''',' +  //Fieldstatus
                                                            sAvatar      +   ',' +  //Avatar
                                                            sTitel       +   ',' +  //Titelbild
                                                     '''' + sl2[_Vorname]+ ''',' +  //Passwort = Vorname (nur für Import)
//                                                             '''' + Crypt(sl2[Start+1],30) + ''',' +  //Passwort
                                                     '''' + sl2[_Vorname] + ' ' + copy(sl2[_Name],1,1) + '.' + ''',' +  //Nickname
                                                     '''' + sl2[_Vorname] + ''',' +  //Vorname
                                                     '''' + sl2[_Name] + ''',' +  //name
                                                     '''' + sl2[_Plz] + ''',' +  //plz
                                                            sOrt         +   ',' +  //Ort
                                                     '''' + sl2[_Strasse] + ''',' +  //Strasse
                                                            sBLand       +   ',' +  //BLand
                                                            sLand        +   ',' +  //Land
                                                            sTrainer     +   ',' +  //Trainer
                                                     '''' + sl2[_EMail]  + ''',' +  //Email
                                                            sRechte      +   ',' +  //Rechte
                                                            sDatum       +   ')'    //Geb-Datum
                                   )).ToString;

      //Begrüßungstext vom Segler-Portal als Post
      await(WelcomePost(sUser, sAdmin, sl2[_Vorname], sText2));

      inc(iNew);

    end
    else begin //Ändern
      await(datenmodul.EasyExec('Update User Set TRAINER   = '   + sTrainer + ',' +
                                                'RECHTE_ID = '   + sRechte +
                                ' WHERE ID = '+ sUser));
      inc(iChange);
    end;

    //Gruppen bzw Events importieren/erstellen
    if sl2[_Reise] > ''
    then begin
      d := StrToDateTime(sl2[_Reise]);

      if YearOf(d) >= YearOf(Now)
      then begin

        d := StrToDateTime(sl2[_Reise]);
        sDatum := FormatDateTime( _DATE_SAVE, d);

        d := incDay(d,2); //Startdatum um 2 Tage erweitern (Wochenende Fr-So)
        sBis := FormatDateTime( _DATE_SAVE, d);

        j.Response := await(datenmodul.EasySQL('select ID from Land where Text = ''Niederlande'''));
        sLand := j.Value('ID');
        j.Response := await(datenmodul.EasySQL('select ID from Ort where Text = ''Lemmer'''));
        sOrt := j.Value('ID');
        j.Response := await(datenmodul.EasySQL('select ID from Zweck where Text = ''Hafentraining'''));
        sKat := j.Value('ID');

        sGruppeTitel := 'Training am ' + sl2[_Reise];

        //Gruppe / Event holen...
        j.Response := await(datenmodul.EasySQL('select ID from User where typ = ' + _UT_Gruppe.ToString + ' and Nickname = ''' + sGruppeTitel + ''''));
        sGruppe := j.Value('ID');

        if sGruppe = '' //...wenn nicht vorhanden - anlegen...
        then begin
           sGruppe := await(datenmodul.EasyInsert('INSERT INTO User (STATUS_ID, Password, KAT_ID, AVATAR_MEDIEN_ID, TITEL_MEDIEN_ID, TITEL_MEDIEN_POSITION, holder_ID, Land_ID, Ort_id, Typ, Nickname, VON, BIS) ' +
                  ' VALUES(3,'''', ' + sKat + ', ' + sFoto + ',' + sFoto + ',''' + sPosition + ''',' +sOrganizer + ',' + sLand + ',' + sOrt + ',' +_UT_Gruppe.ToString + ', ''' + sGruppeTitel  + ''',''' + sDatum + ''',''' + sBis + ''')')).ToString;

           //Begrüßungstext anpassen
           s := sText;
           j.Response := await(datenmodul.EasySQL('select TEXT from Ort where ID = ' + sOrt ));
           s := StringReplace(s, '%ORT%', j.Value('TEXT'), [rfReplaceAll]);
           j.Response := await(datenmodul.EasySQL('select TEXT from Zweck where ID = ' + sKat ));
           s := StringReplace(s, '%EVENT%', j.Value('TEXT'), [rfReplaceAll]);

           s := StringReplace(s, '%LINK%', 'GRP=' + sGruppe, [rfReplaceAll]);
           s := StringReplace(s, '%VON%', sl2[_Reise], [rfReplaceAll]);

           await(datenmodul.EasyExec('Update User Set MEMO = ''' + s + ''' where ID = ' + sGruppe));

           //Begrüßungstext der Gruppe als Post
           sPost := await(datenmodul.EasyInsert('Insert into Post (STATUS, HOLDER, USER_ID, TEXT) ' +
                     ' VALUES(1,' + sGruppe + ',' + sOrganizer + ',''' +  s + ''')')).ToString;

           await(datenmodul.EasyExec('UPDATE Post Set Post_ID = ' + sPost + ' WHERE ID = ' + sPost ));

           inc(iGruppen);
        end;

        //Mitglied mit Gruppe verbinden
        //Prüfen ob schon Mitglied der Gruppe
        jSuchen.Response := await(datenmodul.EasySQL('Select ID from Friends ' +
                                  ' where (EMPFAENGER = ' + sGruppe + ' and SENDER = ' + sUser + ') OR ' +
                                  '       (EMPFAENGER = ' + sUser + ' and SENDER = ' + sGruppe + ')'));

        //Wenn nicht hinzufügen..
        if jSuchen.Value('ID') = ''
        then begin
          await(datenmodul.EasyInsert('INSERT INTO Friends (Status, EMPFAENGER, SENDER, Angenommen) ' +
                  ' VALUES( 1,' + sUser + ', ' + sGruppe + ',''' + FormatDateTime( _DATE_SAVE, Now) + ''')' ));

          //--> Einladung per Email verschicken...
        end
      end;

    end;

    //Trainer-Gruppe
    if sTrainer = '1'
    then begin

      sGruppeTitel := 'SPOREDO-Trainer';

      //Gruppe / Event holen...
      j.Response := await(datenmodul.EasySQL('select ID from User where typ = ' + _UT_Gruppe.ToString + ' and Nickname = ''' + sGruppeTitel + ''''));
      sGruppe := j.Value('ID');

      if sGruppe = '' //...wenn nicht vorhanden - anlegen...
      then begin
         sGruppe := await(datenmodul.EasyInsert('INSERT INTO User (STATUS_ID, Password, KAT_ID, AVATAR_MEDIEN_ID, TITEL_MEDIEN_ID, TITEL_MEDIEN_POSITION, holder_ID, Land_ID, Ort_id, Typ, Nickname) ' +
                ' VALUES(3,'''', ' + sKat + ', ' + sFoto + ',' + sFoto + ',''' + sPosition + ''',' +sOrganizer + ',' + sLand + ',' + sOrt + ',' +_UT_Gruppe.ToString + ', ''' + sGruppeTitel  + ''')')).ToString;

         //Begrüßungstext anpassen
         s := sText3;
         j.Response := await(datenmodul.EasySQL('select TEXT from Ort where ID = ' + sOrt ));
         s := StringReplace(s, '%ORT%', j.Value('TEXT'), [rfReplaceAll]);
         j.Response := await(datenmodul.EasySQL('select TEXT from Zweck where ID = ' + sKat ));
         s := StringReplace(s, '%EVENT%', j.Value('TEXT'), [rfReplaceAll]);

         s := StringReplace(s, '%LINK%', 'GRP=' + sGruppe, [rfReplaceAll]);
         s := StringReplace(s, '%VON%', sl2[_Reise], [rfReplaceAll]);
         s := StringReplace(s, '%VORNAME%', sl2[_Vorname], [rfReplaceAll]);

         await(datenmodul.EasyExec('Update User Set MEMO = ''' + s + ''' where ID = ' + sGruppe));

         //Begrüßungstext der Gruppe als Post
         sPost := await(datenmodul.EasyInsert('Insert into Post (STATUS, HOLDER, USER_ID, TEXT) ' +
                   ' VALUES(1,' + sGruppe + ',' + sOrganizer + ',''' +  s + ''')')).ToString;

         await(datenmodul.EasyExec('UPDATE Post Set Post_ID = ' + sPost + ' WHERE ID = ' + sPost ));
      end;

      //Mitglied mit Gruppe verbinden
      //Schon Mitglied?
      jSuchen.Response := await(datenmodul.EasySQL('Select ID from Friends ' +
                           ' where (EMPFAENGER = ' + sGruppe + ' and SENDER = ' + sUser + ') OR ' +
                           '       (EMPFAENGER = ' + sUser + ' and SENDER = ' + sGruppe + ')'));

      //Wenn nicht, dann hinzufügen
      if jSuchen.Value('ID') = ''
      then begin
        await(datenmodul.EasyInsert('INSERT INTO Friends (Status, EMPFAENGER, SENDER, Angenommen) ' +
                    ' VALUES( 1,' + sUser + ', ' + sGruppe + ',''' + FormatDateTime( _DATE_SAVE, Now) + ''')' ));

          //--> Einladung per Email verschicken...

        inc(iTrainer);
      end;

    end
    else begin //kein Trainer
      sGruppeTitel := 'SPOREDO-Trainer';

      //Gruppe / Event holen...
      j.Response := await(datenmodul.EasySQL('select ID from User where typ = ' + _UT_Gruppe.ToString + ' and Nickname = ''' + sGruppeTitel + ''''));
      sGruppe := j.Value('ID');
      await(datenmodul.EasyExec('Delete from friends' +
                                ' where (EMPFAENGER = ' + sGruppe + ' AND SENDER = ' + sUser + ') OR ' +
                                '       (EMPFAENGER = ' + sUser + ' AND SENDER = ' + sGruppe + ')' ));
    end;

  end;
  j.Free;
  jSuchen.Free;

  wait := -1;

  await(meldung('Datensätze importiert: ' + (sl.Count - Startzeile).ToString + '<br><br>' +
                'Neue Mitglieder: ' + iNew.ToString + '<br>' +
                'Neue Trainer: ' + iTrainer.ToString + '<br>' +
                'Gelöschte Mitglieder: ' + iDel.ToString + '<br>' +
                'Mitglieder geändert: ' + iChange.ToString + '<br>' +
                'Neue Gruppen: ' + iGruppen.ToString));

  freeAndNil(j);

end;

procedure TMainform.miLogoutClick(Sender: TObject);

  procedure AfterCreate(AForm: TObject);
  begin
    TfrmStart(AForm).ShowByeBye;
  end;

begin
  divMainHeader.Visible := false;
  divMainCenter.Visible := false;
  divMainFooter.Visible := false;
  timer.Enabled := false;
  asm
    document.body.style.background = "url('bg.jpg') center center / cover no-repeat fixed";
  end;

  divStart.Visible := true;
  TfrmStart.CreateNew(divStart.ElementID, @AfterCreate);
end;

procedure TMainform.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divMainCenter := THTMLDiv.Create('divMainCenter');
  divStart := THTMLDiv.Create(Self);
  divMainHeader := THTMLDiv.Create('myTopnav');
  divMenuLeft := THTMLDiv.Create(Self);
  WebHTMLDiv1 := THTMLDiv.Create(Self);
  imgLogo := TImageControl.Create(Self);
  WebHTMLDiv7 := THTMLDiv.Create(Self);
  WebHTMLDiv4 := THTMLDiv.Create(Self);
  imgAvatar := TImageControl.Create(Self);
  BadgeFS := TBadge.Create(Self);
  btnUserName := TButton.Create(Self);
  divMainFooter := THTMLDiv.Create(Self);
  WebHTMLDiv8 := THTMLDiv.Create(Self);
  WebHTMLDiv9 := THTMLDiv.Create(Self);
  WebHTMLDiv10 := THTMLDiv.Create(Self);
  WebHtmlDiv11 := THTMLDiv.Create(Self);
  WebHTMLDiv12 := THTMLDiv.Create(Self);
  WebHTMLDiv13 := THTMLDiv.Create(Self);
  WebHTMLDiv14 := THTMLDiv.Create(Self);
  WebHTMLDiv15 := THTMLDiv.Create(Self);
  btnImpressum := THTMLDiv.Create(Self);
  btnAGB := THTMLDiv.Create(Self);
  Timer := TTimer.Create(Self);
  openDlg := TOpenDialog.Create(Self);
  mnuMainLeft := TMainMenu.Create(Self);
  SeglerPortalde1 := TMenuItem.Create(Self);
  miSeemannschaft := TMenuItem.Create(Self);
  miLektionen := TMenuItem.Create(Self);
  miTutolials := TMenuItem.Create(Self);
  miSegelWiki := TMenuItem.Create(Self);
  miCommunity := TMenuItem.Create(Self);
  miMitglieder := TMenuItem.Create(Self);
  miEvents := TMenuItem.Create(Self);
  miGruppen := TMenuItem.Create(Self);
  miVereine := TMenuItem.Create(Self);
  HandgegenKoje1 := TMenuItem.Create(Self);
  miBlog := TMenuItem.Create(Self);
  miBlogNeueste := TMenuItem.Create(Self);
  miBlogNeu := TMenuItem.Create(Self);
  miMeineBlogs := TMenuItem.Create(Self);
  miKonto := TMenuItem.Create(Self);
  miMeinProfil := TMenuItem.Create(Self);
  miAGB := TMenuItem.Create(Self);
  miIP := TMenuItem.Create(Self);
  MeinePos1 := TMenuItem.Create(Self);
  N1 := TMenuItem.Create(Self);
  miCodeEingeben := TMenuItem.Create(Self);
  ListenProtokolle1 := TMenuItem.Create(Self);
  miSicherheitseinweisung := TMenuItem.Create(Self);
  miBackend := TMenuItem.Create(Self);
  miPaketeEdit := TMenuItem.Create(Self);
  miKurseEdit := TMenuItem.Create(Self);
  miHowTo := TMenuItem.Create(Self);
  N2 := TMenuItem.Create(Self);
  miMitgliederImportieren := TMenuItem.Create(Self);
  miAGBEdit := TMenuItem.Create(Self);
  T1 := TMenuItem.Create(Self);
  T2 := TMenuItem.Create(Self);
  miLogout := TMenuItem.Create(Self);
  miMessenger := TMenuItem.Create(Self);
  ChatClient := TSocketClient.Create(Self);

  divMainCenter.BeforeLoadDFMValues;
  divStart.BeforeLoadDFMValues;
  divMainHeader.BeforeLoadDFMValues;
  divMenuLeft.BeforeLoadDFMValues;
  WebHTMLDiv1.BeforeLoadDFMValues;
  imgLogo.BeforeLoadDFMValues;
  WebHTMLDiv7.BeforeLoadDFMValues;
  WebHTMLDiv4.BeforeLoadDFMValues;
  imgAvatar.BeforeLoadDFMValues;
  BadgeFS.BeforeLoadDFMValues;
  btnUserName.BeforeLoadDFMValues;
  divMainFooter.BeforeLoadDFMValues;
  WebHTMLDiv8.BeforeLoadDFMValues;
  WebHTMLDiv9.BeforeLoadDFMValues;
  WebHTMLDiv10.BeforeLoadDFMValues;
  WebHtmlDiv11.BeforeLoadDFMValues;
  WebHTMLDiv12.BeforeLoadDFMValues;
  WebHTMLDiv13.BeforeLoadDFMValues;
  WebHTMLDiv14.BeforeLoadDFMValues;
  WebHTMLDiv15.BeforeLoadDFMValues;
  btnImpressum.BeforeLoadDFMValues;
  btnAGB.BeforeLoadDFMValues;
  Timer.BeforeLoadDFMValues;
  openDlg.BeforeLoadDFMValues;
  mnuMainLeft.BeforeLoadDFMValues;
  SeglerPortalde1.BeforeLoadDFMValues;
  miSeemannschaft.BeforeLoadDFMValues;
  miLektionen.BeforeLoadDFMValues;
  miTutolials.BeforeLoadDFMValues;
  miSegelWiki.BeforeLoadDFMValues;
  miCommunity.BeforeLoadDFMValues;
  miMitglieder.BeforeLoadDFMValues;
  miEvents.BeforeLoadDFMValues;
  miGruppen.BeforeLoadDFMValues;
  miVereine.BeforeLoadDFMValues;
  HandgegenKoje1.BeforeLoadDFMValues;
  miBlog.BeforeLoadDFMValues;
  miBlogNeueste.BeforeLoadDFMValues;
  miBlogNeu.BeforeLoadDFMValues;
  miMeineBlogs.BeforeLoadDFMValues;
  miKonto.BeforeLoadDFMValues;
  miMeinProfil.BeforeLoadDFMValues;
  miAGB.BeforeLoadDFMValues;
  miIP.BeforeLoadDFMValues;
  MeinePos1.BeforeLoadDFMValues;
  N1.BeforeLoadDFMValues;
  miCodeEingeben.BeforeLoadDFMValues;
  ListenProtokolle1.BeforeLoadDFMValues;
  miSicherheitseinweisung.BeforeLoadDFMValues;
  miBackend.BeforeLoadDFMValues;
  miPaketeEdit.BeforeLoadDFMValues;
  miKurseEdit.BeforeLoadDFMValues;
  miHowTo.BeforeLoadDFMValues;
  N2.BeforeLoadDFMValues;
  miMitgliederImportieren.BeforeLoadDFMValues;
  miAGBEdit.BeforeLoadDFMValues;
  T1.BeforeLoadDFMValues;
  T2.BeforeLoadDFMValues;
  miLogout.BeforeLoadDFMValues;
  miMessenger.BeforeLoadDFMValues;
  ChatClient.BeforeLoadDFMValues;
  try
    Name := 'Mainform';
    Width := 941;
    Height := 799;
    ElementFont := efCSS;
    Menu := mnuMainLeft;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    divMainCenter.SetParentComponent(Self);
    divMainCenter.Name := 'divMainCenter';
    divMainCenter.Left := 16;
    divMainCenter.Top := 135;
    divMainCenter.Width := 907;
    divMainCenter.Height := 506;
    divMainCenter.ElementClassName := 'start_div';
    divMainCenter.HeightStyle := ssAuto;
    divMainCenter.WidthStyle := ssAuto;
    divMainCenter.ChildOrder := 3;
    divMainCenter.ElementPosition := epIgnore;
    divMainCenter.ElementFont := efCSS;
    divMainCenter.Role := '';
    divStart.SetParentComponent(Self);
    divStart.Name := 'divStart';
    divStart.Left := 254;
    divStart.Top := 199;
    divStart.Width := 401;
    divStart.Height := 178;
    divStart.ElementClassName := 'main_start';
    divStart.HeightStyle := ssAuto;
    divStart.WidthStyle := ssAuto;
    divStart.ChildOrder := 3;
    divStart.ElementPosition := epIgnore;
    divStart.ElementFont := efCSS;
    divStart.Role := '';
    divStart.Visible := False;
    divMainHeader.SetParentComponent(Self);
    divMainHeader.Name := 'divMainHeader';
    divMainHeader.Left := 17;
    divMainHeader.Top := 8;
    divMainHeader.Width := 906;
    divMainHeader.Height := 113;
    divMainHeader.ElementClassName := 'topnav';
    divMainHeader.HeightStyle := ssAuto;
    divMainHeader.WidthStyle := ssAuto;
    divMainHeader.ChildOrder := 2;
    divMainHeader.ElementPosition := epIgnore;
    divMainHeader.ElementFont := efCSS;
    divMainHeader.Role := '';
    divMainHeader.Visible := False;
    divMenuLeft.SetParentComponent(divMainHeader);
    divMenuLeft.Name := 'divMenuLeft';
    divMenuLeft.Left := 258;
    divMenuLeft.Top := 16;
    divMenuLeft.Width := 241;
    divMenuLeft.Height := 76;
    divMenuLeft.ElementClassName := 'main_menu_left';
    divMenuLeft.HeightStyle := ssAuto;
    divMenuLeft.WidthStyle := ssAuto;
    divMenuLeft.ChildOrder := 2;
    divMenuLeft.ElementPosition := epIgnore;
    divMenuLeft.ElementFont := efCSS;
    divMenuLeft.Role := '';
    WebHTMLDiv1.SetParentComponent(divMainHeader);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 11;
    WebHTMLDiv1.Top := 16;
    WebHTMLDiv1.Width := 241;
    WebHTMLDiv1.Height := 76;
    WebHTMLDiv1.ElementClassName := 'main_menu_left';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ChildOrder := 1;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    imgLogo.SetParentComponent(WebHTMLDiv1);
    imgLogo.Name := 'imgLogo';
    imgLogo.Left := 3;
    imgLogo.Top := 3;
    imgLogo.Width := 73;
    imgLogo.Height := 65;
    imgLogo.ElementClassName := 'imglogo';
    imgLogo.HeightStyle := ssAuto;
    imgLogo.WidthStyle := ssAuto;
    imgLogo.HeightPercent := 100.000000000000000000;
    imgLogo.WidthPercent := 100.000000000000000000;
    imgLogo.ElementFont := efCSS;
    imgLogo.ElementPosition := epIgnore;
    imgLogo.Picture.LoadFromFile('uMain.WebHTMLDiv1.imgLogo.Picture.png');
    WebHTMLDiv7.SetParentComponent(divMainHeader);
    WebHTMLDiv7.Name := 'WebHTMLDiv7';
    WebHTMLDiv7.Left := 505;
    WebHTMLDiv7.Top := 19;
    WebHTMLDiv7.Width := 313;
    WebHTMLDiv7.Height := 81;
    WebHTMLDiv7.ElementClassName := 'floatright';
    WebHTMLDiv7.HeightStyle := ssAuto;
    WebHTMLDiv7.WidthStyle := ssAuto;
    WebHTMLDiv7.ChildOrder := 10;
    WebHTMLDiv7.ElementPosition := epIgnore;
    WebHTMLDiv7.ElementFont := efCSS;
    WebHTMLDiv7.Role := '';
    WebHTMLDiv4.SetParentComponent(WebHTMLDiv7);
    WebHTMLDiv4.Name := 'WebHTMLDiv4';
    WebHTMLDiv4.Left := 0;
    WebHTMLDiv4.Top := 3;
    WebHTMLDiv4.Width := 262;
    WebHTMLDiv4.Height := 44;
    WebHTMLDiv4.ElementClassName := 'main_right_wrapper';
    WebHTMLDiv4.HeightStyle := ssAuto;
    WebHTMLDiv4.WidthStyle := ssAuto;
    WebHTMLDiv4.ChildOrder := 1;
    WebHTMLDiv4.ElementPosition := epIgnore;
    WebHTMLDiv4.ElementFont := efCSS;
    WebHTMLDiv4.Role := '';
    imgAvatar.SetParentComponent(WebHTMLDiv4);
    imgAvatar.Name := 'imgAvatar';
    imgAvatar.Left := 3;
    imgAvatar.Top := 0;
    imgAvatar.Width := 53;
    imgAvatar.Height := 41;
    imgAvatar.ElementClassName := 'main_content_avatar';
    imgAvatar.HeightStyle := ssAuto;
    imgAvatar.WidthStyle := ssAuto;
    imgAvatar.HeightPercent := 100.000000000000000000;
    imgAvatar.WidthPercent := 100.000000000000000000;
    imgAvatar.Anchors := [];
    imgAvatar.ElementFont := efCSS;
    imgAvatar.ElementPosition := epIgnore;
    imgAvatar.Picture.LoadFromFile('uMain.WebHTMLDiv4.imgAvatar.Picture.jpg');
    BadgeFS.SetParentComponent(WebHTMLDiv4);
    BadgeFS.Name := 'BadgeFS';
    BadgeFS.Left := 229;
    BadgeFS.Top := 10;
    BadgeFS.Width := 20;
    BadgeFS.Height := 23;
    BadgeFS.HeightStyle := ssAuto;
    BadgeFS.WidthStyle := ssAuto;
    BadgeFS.ChildOrder := 3;
    BadgeFS.ElementFont := efCSS;
    BadgeFS.ElementPosition := epIgnore;
    BadgeFS.Text := '0';
    BadgeFS.Visible := False;
    btnUserName.SetParentComponent(WebHTMLDiv4);
    btnUserName.Name := 'btnUserName';
    btnUserName.Left := 62;
    btnUserName.Top := 3;
    btnUserName.Width := 96;
    btnUserName.Height := 25;
    btnUserName.Caption := 'btnUserName';
    btnUserName.ChildOrder := 1;
    btnUserName.ElementClassName := 'dropbtn';
    btnUserName.ElementFont := efCSS;
    btnUserName.ElementPosition := epIgnore;
    btnUserName.HeightStyle := ssAuto;
    btnUserName.HeightPercent := 100.000000000000000000;
    btnUserName.WidthStyle := ssAuto;
    btnUserName.WidthPercent := 100.000000000000000000;
    SetEvent(btnUserName, Self, 'OnClick', 'btnUserNameClick');
    divMainFooter.SetParentComponent(Self);
    divMainFooter.Name := 'divMainFooter';
    divMainFooter.Left := 17;
    divMainFooter.Top := 656;
    divMainFooter.Width := 906;
    divMainFooter.Height := 105;
    divMainFooter.ElementClassName := 'main_footer';
    divMainFooter.HeightStyle := ssAuto;
    divMainFooter.WidthStyle := ssAuto;
    divMainFooter.ChildOrder := 3;
    divMainFooter.ElementPosition := epIgnore;
    divMainFooter.ElementFont := efCSS;
    divMainFooter.Role := '';
    SetEvent(divMainFooter, Self, 'OnMouseEnter', 'divMainFooterMouseEnter');
    SetEvent(divMainFooter, Self, 'OnMouseLeave', 'divMainFooterMouseLeave');
    WebHTMLDiv8.SetParentComponent(divMainFooter);
    WebHTMLDiv8.Name := 'WebHTMLDiv8';
    WebHTMLDiv8.Left := 6;
    WebHTMLDiv8.Top := 3;
    WebHTMLDiv8.Width := 195;
    WebHTMLDiv8.Height := 112;
    WebHTMLDiv8.ElementClassName := 'footer_liste';
    WebHTMLDiv8.HeightStyle := ssAuto;
    WebHTMLDiv8.WidthStyle := ssAuto;
    WebHTMLDiv8.ChildOrder := 1;
    WebHTMLDiv8.ElementPosition := epIgnore;
    WebHTMLDiv8.ElementFont := efCSS;
    WebHTMLDiv8.Role := '';
    WebHTMLDiv8.Visible := False;
    WebHTMLDiv9.SetParentComponent(WebHTMLDiv8);
    WebHTMLDiv9.Name := 'WebHTMLDiv9';
    WebHTMLDiv9.Left := 3;
    WebHTMLDiv9.Top := 3;
    WebHTMLDiv9.Width := 190;
    WebHTMLDiv9.Height := 30;
    WebHTMLDiv9.ElementClassName := 'footer-titel';
    WebHTMLDiv9.HeightStyle := ssAuto;
    WebHTMLDiv9.WidthStyle := ssAuto;
    WebHTMLDiv9.ElementPosition := epIgnore;
    WebHTMLDiv9.ElementFont := efCSS;
    WebHTMLDiv9.HTML.BeginUpdate;
    try
      WebHTMLDiv9.HTML.Clear;
      WebHTMLDiv9.HTML.Add('<B>HILFE</B>');
    finally
      WebHTMLDiv9.HTML.EndUpdate;
    end;
    WebHTMLDiv9.Role := '';
    WebHTMLDiv10.SetParentComponent(WebHTMLDiv8);
    WebHTMLDiv10.Name := 'WebHTMLDiv10';
    WebHTMLDiv10.Left := 3;
    WebHTMLDiv10.Top := 39;
    WebHTMLDiv10.Width := 190;
    WebHTMLDiv10.Height := 70;
    WebHTMLDiv10.HeightStyle := ssAuto;
    WebHTMLDiv10.WidthStyle := ssAuto;
    WebHTMLDiv10.ChildOrder := 1;
    WebHTMLDiv10.ElementPosition := epIgnore;
    WebHTMLDiv10.ElementFont := efCSS;
    WebHTMLDiv10.HTML.BeginUpdate;
    try
      WebHTMLDiv10.HTML.Clear;
      WebHTMLDiv10.HTML.Add('<P align="left">');
      WebHTMLDiv10.HTML.Add('AnleitungenX<BR>');
      WebHTMLDiv10.HTML.Add('Support kontaktieren<BR>');
      WebHTMLDiv10.HTML.Add('Ideen-Portal');
    finally
      WebHTMLDiv10.HTML.EndUpdate;
    end;
    WebHTMLDiv10.Role := '';
    WebHtmlDiv11.SetParentComponent(divMainFooter);
    WebHtmlDiv11.Name := 'WebHtmlDiv11';
    WebHtmlDiv11.Left := 258;
    WebHtmlDiv11.Top := 3;
    WebHtmlDiv11.Width := 195;
    WebHtmlDiv11.Height := 112;
    WebHtmlDiv11.ElementClassName := 'footer_liste';
    WebHtmlDiv11.HeightStyle := ssAuto;
    WebHtmlDiv11.WidthStyle := ssAuto;
    WebHtmlDiv11.ChildOrder := 2;
    WebHtmlDiv11.ElementPosition := epIgnore;
    WebHtmlDiv11.ElementFont := efCSS;
    WebHtmlDiv11.Role := '';
    WebHtmlDiv11.Visible := False;
    WebHTMLDiv12.SetParentComponent(WebHtmlDiv11);
    WebHTMLDiv12.Name := 'WebHTMLDiv12';
    WebHTMLDiv12.Left := 2;
    WebHTMLDiv12.Top := 3;
    WebHTMLDiv12.Width := 190;
    WebHTMLDiv12.Height := 30;
    WebHTMLDiv12.ElementClassName := 'footer-titel';
    WebHTMLDiv12.HeightStyle := ssAuto;
    WebHTMLDiv12.WidthStyle := ssAuto;
    WebHTMLDiv12.ElementPosition := epIgnore;
    WebHTMLDiv12.ElementFont := efCSS;
    WebHTMLDiv12.HTML.BeginUpdate;
    try
      WebHTMLDiv12.HTML.Clear;
      WebHTMLDiv12.HTML.Add('<B>RECHTLICHES</B>');
    finally
      WebHTMLDiv12.HTML.EndUpdate;
    end;
    WebHTMLDiv12.Role := '';
    WebHTMLDiv13.SetParentComponent(WebHtmlDiv11);
    WebHTMLDiv13.Name := 'WebHTMLDiv13';
    WebHTMLDiv13.Left := 3;
    WebHTMLDiv13.Top := 39;
    WebHTMLDiv13.Width := 190;
    WebHTMLDiv13.Height := 70;
    WebHTMLDiv13.HeightStyle := ssAuto;
    WebHTMLDiv13.WidthStyle := ssAuto;
    WebHTMLDiv13.ChildOrder := 1;
    WebHTMLDiv13.ElementPosition := epIgnore;
    WebHTMLDiv13.ElementFont := efCSS;
    WebHTMLDiv13.HTML.BeginUpdate;
    try
      WebHTMLDiv13.HTML.Clear;
      WebHTMLDiv13.HTML.Add('<P align="left">');
      WebHTMLDiv13.HTML.Add('AGB'#39's<BR>');
      WebHTMLDiv13.HTML.Add('Datenschutz<BR>');
      WebHTMLDiv13.HTML.Add('Rechtliches');
      WebHTMLDiv13.HTML.Add('');
    finally
      WebHTMLDiv13.HTML.EndUpdate;
    end;
    WebHTMLDiv13.Role := '';
    WebHTMLDiv14.SetParentComponent(divMainFooter);
    WebHTMLDiv14.Name := 'WebHTMLDiv14';
    WebHTMLDiv14.Left := 654;
    WebHTMLDiv14.Top := 0;
    WebHTMLDiv14.Width := 195;
    WebHTMLDiv14.Height := 112;
    WebHTMLDiv14.ElementClassName := 'footer_liste';
    WebHTMLDiv14.HeightStyle := ssAuto;
    WebHTMLDiv14.WidthStyle := ssAuto;
    WebHTMLDiv14.ChildOrder := 3;
    WebHTMLDiv14.ElementPosition := epIgnore;
    WebHTMLDiv14.ElementFont := efCSS;
    WebHTMLDiv14.Role := '';
    WebHTMLDiv15.SetParentComponent(WebHTMLDiv14);
    WebHTMLDiv15.Name := 'WebHTMLDiv15';
    WebHTMLDiv15.Left := 3;
    WebHTMLDiv15.Top := 3;
    WebHTMLDiv15.Width := 190;
    WebHTMLDiv15.Height := 30;
    WebHTMLDiv15.ElementClassName := 'footer-titel';
    WebHTMLDiv15.HeightStyle := ssAuto;
    WebHTMLDiv15.WidthStyle := ssAuto;
    WebHTMLDiv15.ElementPosition := epIgnore;
    WebHTMLDiv15.ElementFont := efCSS;
    WebHTMLDiv15.HTML.BeginUpdate;
    try
      WebHTMLDiv15.HTML.Clear;
      WebHTMLDiv15.HTML.Add('<B>'#220'BER</B>');
    finally
      WebHTMLDiv15.HTML.EndUpdate;
    end;
    WebHTMLDiv15.Role := '';
    btnImpressum.SetParentComponent(WebHTMLDiv14);
    btnImpressum.Name := 'btnImpressum';
    btnImpressum.Left := 3;
    btnImpressum.Top := 39;
    btnImpressum.Width := 190;
    btnImpressum.Height := 30;
    btnImpressum.ElementClassName := 'footer-titel';
    btnImpressum.HeightStyle := ssAuto;
    btnImpressum.WidthStyle := ssAuto;
    btnImpressum.ChildOrder := 1;
    btnImpressum.ElementPosition := epIgnore;
    btnImpressum.ElementFont := efCSS;
    btnImpressum.HTML.BeginUpdate;
    try
      btnImpressum.HTML.Clear;
      btnImpressum.HTML.Add('Impressum');
    finally
      btnImpressum.HTML.EndUpdate;
    end;
    btnImpressum.Role := '';
    SetEvent(btnImpressum, Self, 'OnClick', 'btnImpressumClick');
    btnAGB.SetParentComponent(WebHTMLDiv14);
    btnAGB.Name := 'btnAGB';
    btnAGB.Left := 2;
    btnAGB.Top := 75;
    btnAGB.Width := 190;
    btnAGB.Height := 30;
    btnAGB.ElementClassName := 'footer-titel';
    btnAGB.HeightStyle := ssAuto;
    btnAGB.WidthStyle := ssAuto;
    btnAGB.ChildOrder := 1;
    btnAGB.ElementPosition := epIgnore;
    btnAGB.ElementFont := efCSS;
    btnAGB.HTML.BeginUpdate;
    try
      btnAGB.HTML.Clear;
      btnAGB.HTML.Add('AGB');
    finally
      btnAGB.HTML.EndUpdate;
    end;
    btnAGB.Role := '';
    SetEvent(btnAGB, Self, 'OnClick', 'miAGBClick');
    Timer.SetParentComponent(Self);
    Timer.Name := 'Timer';
    Timer.Enabled := False;
    Timer.Interval := 100000;
    SetEvent(Timer, Self, 'OnTimer', 'TimerTimer');
    Timer.Left := 64;
    Timer.Top := 224;
    openDlg.SetParentComponent(Self);
    openDlg.Name := 'openDlg';
    openDlg.MultiFile := True;
    SetEvent(openDlg, Self, 'OnChange', 'openDlgChange');
    openDlg.Left := 64;
    openDlg.Top := 296;
    mnuMainLeft.SetParentComponent(Self);
    mnuMainLeft.Name := 'mnuMainLeft';
    mnuMainLeft.Appearance.BackgroundColor := clBackground;
    mnuMainLeft.Appearance.HamburgerMenu.Caption := 'Menu';
    mnuMainLeft.Appearance.HoverColor := clBlack;
    mnuMainLeft.Appearance.HoverFontColor := 17919;
    mnuMainLeft.Appearance.SubmenuIndicator := '';
    mnuMainLeft.Container := divMenuLeft;
    mnuMainLeft.ElementClassName := 'main_menu';
    mnuMainLeft.ElementItemClassName := 'main_label';
    mnuMainLeft.Font.Charset := DEFAULT_CHARSET;
    mnuMainLeft.Font.Color := clWindowText;
    mnuMainLeft.Font.Height := -12;
    mnuMainLeft.Font.Name := 'Segoe UI';
    mnuMainLeft.Font.Style := [];
    mnuMainLeft.Left := 179;
    mnuMainLeft.Top := 125;
    SeglerPortalde1.SetParentComponent(mnuMainLeft);
    SeglerPortalde1.Name := 'SeglerPortalde1';
    SeglerPortalde1.Caption := 'Segler-Portal.de';
    SetEvent(SeglerPortalde1, Self, 'OnClick', 'btnUserNameClick');
    miSeemannschaft.SetParentComponent(mnuMainLeft);
    miSeemannschaft.Name := 'miSeemannschaft';
    miSeemannschaft.Caption := 'Seemannschaft';
    miLektionen.SetParentComponent(miSeemannschaft);
    miLektionen.Name := 'miLektionen';
    miLektionen.Caption := 'Sicherheitseinweisung';
    SetEvent(miLektionen, Self, 'OnClick', 'miLektionenClick');
    miTutolials.SetParentComponent(miSeemannschaft);
    miTutolials.Name := 'miTutolials';
    miTutolials.Caption := 'Anleitungen';
    SetEvent(miTutolials, Self, 'OnClick', 'miLektionenClick');
    miSegelWiki.SetParentComponent(miSeemannschaft);
    miSegelWiki.Name := 'miSegelWiki';
    miSegelWiki.Caption := 'Segel-Wiki';
    miSegelWiki.Visible := False;
    miCommunity.SetParentComponent(mnuMainLeft);
    miCommunity.Name := 'miCommunity';
    miCommunity.Caption := 'Community';
    miMitglieder.SetParentComponent(miCommunity);
    miMitglieder.Name := 'miMitglieder';
    miMitglieder.Caption := 'Mitglieder';
    SetEvent(miMitglieder, Self, 'OnClick', 'miMemberClick');
    miEvents.SetParentComponent(miCommunity);
    miEvents.Name := 'miEvents';
    miEvents.Caption := 'Veranstaltungen';
    SetEvent(miEvents, Self, 'OnClick', 'miEventsClick');
    miGruppen.SetParentComponent(miCommunity);
    miGruppen.Name := 'miGruppen';
    miGruppen.Caption := 'Gruppen';
    SetEvent(miGruppen, Self, 'OnClick', 'miGruppenClick');
    miVereine.SetParentComponent(miCommunity);
    miVereine.Name := 'miVereine';
    miVereine.Caption := 'Vereine';
    miVereine.Visible := False;
    HandgegenKoje1.SetParentComponent(miCommunity);
    HandgegenKoje1.Name := 'HandgegenKoje1';
    HandgegenKoje1.Caption := 'Hand gegen Koje';
    HandgegenKoje1.Visible := False;
    miBlog.SetParentComponent(mnuMainLeft);
    miBlog.Name := 'miBlog';
    miBlog.Caption := 'Blog';
    miBlogNeueste.SetParentComponent(miBlog);
    miBlogNeueste.Name := 'miBlogNeueste';
    miBlogNeueste.Caption := 'Neuste Blogs';
    miBlogNeueste.Visible := False;
    SetEvent(miBlogNeueste, Self, 'OnClick', 'miBlogAlleClick');
    miBlogNeu.SetParentComponent(miBlog);
    miBlogNeu.Name := 'miBlogNeu';
    miBlogNeu.Caption := 'Neuen Blog anlegen . . .';
    miBlogNeu.Visible := False;
    miMeineBlogs.SetParentComponent(miBlog);
    miMeineBlogs.Name := 'miMeineBlogs';
    miMeineBlogs.Caption := 'Meine Blogs';
    miMeineBlogs.Visible := False;
    SetEvent(miMeineBlogs, Self, 'OnClick', 'miBlogAlleClick');
    miKonto.SetParentComponent(mnuMainLeft);
    miKonto.Name := 'miKonto';
    miKonto.Caption := 'Konto';
    miMeinProfil.SetParentComponent(miKonto);
    miMeinProfil.Name := 'miMeinProfil';
    miMeinProfil.Caption := 'Mein Profil';
    SetEvent(miMeinProfil, Self, 'OnClick', 'miMeinProfilClick');
    miAGB.SetParentComponent(miKonto);
    miAGB.Name := 'miAGB';
    miAGB.Caption := 'Die AGB'#39's';
    miAGB.Visible := False;
    SetEvent(miAGB, Self, 'OnClick', 'miAGBClick');
    miIP.SetParentComponent(miKonto);
    miIP.Name := 'miIP';
    miIP.Caption := 'Meine IP';
    SetEvent(miIP, Self, 'OnClick', 'miIPClick');
    MeinePos1.SetParentComponent(miKonto);
    MeinePos1.Name := 'MeinePos1';
    MeinePos1.Caption := 'Meine Pos';
    SetEvent(MeinePos1, Self, 'OnClick', 'MeinePos1Click');
    N1.SetParentComponent(miKonto);
    N1.Name := 'N1';
    N1.Caption := '-';
    miCodeEingeben.SetParentComponent(miKonto);
    miCodeEingeben.Name := 'miCodeEingeben';
    miCodeEingeben.Caption := 'Code eingeben...';
    miCodeEingeben.Visible := False;
    SetEvent(miCodeEingeben, Self, 'OnClick', 'miCodeEingebenClick');
    ListenProtokolle1.SetParentComponent(miKonto);
    ListenProtokolle1.Name := 'ListenProtokolle1';
    ListenProtokolle1.Caption := 'Listen + Protokolle';
    miSicherheitseinweisung.SetParentComponent(ListenProtokolle1);
    miSicherheitseinweisung.Name := 'miSicherheitseinweisung';
    miSicherheitseinweisung.Caption := 'Sicherheitseinweisung';
    SetEvent(miSicherheitseinweisung, Self, 'OnClick', 'miSicherheitseinweisungClick');
    miBackend.SetParentComponent(mnuMainLeft);
    miBackend.Name := 'miBackend';
    miBackend.Caption := 'Back-End';
    miPaketeEdit.SetParentComponent(miBackend);
    miPaketeEdit.Name := 'miPaketeEdit';
    miPaketeEdit.Caption := 'Pakete bearbeiten...';
    miKurseEdit.SetParentComponent(miBackend);
    miKurseEdit.Name := 'miKurseEdit';
    miKurseEdit.Caption := 'Lektionen bearbeiten...';
    SetEvent(miKurseEdit, Self, 'OnClick', 'miKurseEditClick');
    miHowTo.SetParentComponent(miBackend);
    miHowTo.Name := 'miHowTo';
    miHowTo.Caption := 'Anleitungen bearbeiten...';
    SetEvent(miHowTo, Self, 'OnClick', 'miHowToClick');
    N2.SetParentComponent(miBackend);
    N2.Name := 'N2';
    N2.Caption := '-';
    miMitgliederImportieren.SetParentComponent(miBackend);
    miMitgliederImportieren.Name := 'miMitgliederImportieren';
    miMitgliederImportieren.Caption := 'Mitglieder importieren...';
    SetEvent(miMitgliederImportieren, Self, 'OnClick', 'importSkipperClick');
    miAGBEdit.SetParentComponent(miBackend);
    miAGBEdit.Name := 'miAGBEdit';
    miAGBEdit.Caption := 'Texte bearbeiten...';
    SetEvent(miAGBEdit, Self, 'OnClick', 'miAGBEditClick');
    T1.SetParentComponent(miBackend);
    T1.Name := 'T1';
    T1.Caption := 'T';
    T2.SetParentComponent(miBackend);
    T2.Name := 'T2';
    T2.Caption := 'EMail-Test';
    miLogout.SetParentComponent(mnuMainLeft);
    miLogout.Name := 'miLogout';
    miLogout.Caption := 'Abmelden';
    SetEvent(miLogout, Self, 'OnClick', 'miLogoutClick');
    miMessenger.SetParentComponent(mnuMainLeft);
    miMessenger.Name := 'miMessenger';
    miMessenger.Caption := 'Messenger';
    SetEvent(miMessenger, Self, 'OnClick', 'miMessengerClick');
    ChatClient.SetParentComponent(Self);
    ChatClient.Name := 'ChatClient';
    ChatClient.Port := 10100;
    ChatClient.HostName := 'www.segler-portal.de';
    SetEvent(ChatClient, Self, 'OnMessageReceived', 'ChatClientMessageReceived');
    ChatClient.Left := 64;
    ChatClient.Top := 136;
  finally
    divMainCenter.AfterLoadDFMValues;
    divStart.AfterLoadDFMValues;
    divMainHeader.AfterLoadDFMValues;
    divMenuLeft.AfterLoadDFMValues;
    WebHTMLDiv1.AfterLoadDFMValues;
    imgLogo.AfterLoadDFMValues;
    WebHTMLDiv7.AfterLoadDFMValues;
    WebHTMLDiv4.AfterLoadDFMValues;
    imgAvatar.AfterLoadDFMValues;
    BadgeFS.AfterLoadDFMValues;
    btnUserName.AfterLoadDFMValues;
    divMainFooter.AfterLoadDFMValues;
    WebHTMLDiv8.AfterLoadDFMValues;
    WebHTMLDiv9.AfterLoadDFMValues;
    WebHTMLDiv10.AfterLoadDFMValues;
    WebHtmlDiv11.AfterLoadDFMValues;
    WebHTMLDiv12.AfterLoadDFMValues;
    WebHTMLDiv13.AfterLoadDFMValues;
    WebHTMLDiv14.AfterLoadDFMValues;
    WebHTMLDiv15.AfterLoadDFMValues;
    btnImpressum.AfterLoadDFMValues;
    btnAGB.AfterLoadDFMValues;
    Timer.AfterLoadDFMValues;
    openDlg.AfterLoadDFMValues;
    mnuMainLeft.AfterLoadDFMValues;
    SeglerPortalde1.AfterLoadDFMValues;
    miSeemannschaft.AfterLoadDFMValues;
    miLektionen.AfterLoadDFMValues;
    miTutolials.AfterLoadDFMValues;
    miSegelWiki.AfterLoadDFMValues;
    miCommunity.AfterLoadDFMValues;
    miMitglieder.AfterLoadDFMValues;
    miEvents.AfterLoadDFMValues;
    miGruppen.AfterLoadDFMValues;
    miVereine.AfterLoadDFMValues;
    HandgegenKoje1.AfterLoadDFMValues;
    miBlog.AfterLoadDFMValues;
    miBlogNeueste.AfterLoadDFMValues;
    miBlogNeu.AfterLoadDFMValues;
    miMeineBlogs.AfterLoadDFMValues;
    miKonto.AfterLoadDFMValues;
    miMeinProfil.AfterLoadDFMValues;
    miAGB.AfterLoadDFMValues;
    miIP.AfterLoadDFMValues;
    MeinePos1.AfterLoadDFMValues;
    N1.AfterLoadDFMValues;
    miCodeEingeben.AfterLoadDFMValues;
    ListenProtokolle1.AfterLoadDFMValues;
    miSicherheitseinweisung.AfterLoadDFMValues;
    miBackend.AfterLoadDFMValues;
    miPaketeEdit.AfterLoadDFMValues;
    miKurseEdit.AfterLoadDFMValues;
    miHowTo.AfterLoadDFMValues;
    N2.AfterLoadDFMValues;
    miMitgliederImportieren.AfterLoadDFMValues;
    miAGBEdit.AfterLoadDFMValues;
    T1.AfterLoadDFMValues;
    T2.AfterLoadDFMValues;
    miLogout.AfterLoadDFMValues;
    miMessenger.AfterLoadDFMValues;
    ChatClient.AfterLoadDFMValues;
  end;
end;

end.


