unit uKursText;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ComCtrls, WEBLib.ExtCtrls,
  Vcl.StdCtrls, WEBLib.StdCtrls, Data.DB, XData.Web.JsonDataset, uTB,
  XData.Web.Dataset, XData.Web.Client, WEBLib.WebCtrls, Vcl.Imaging.jpeg,
  Vcl.Forms, Vcl.Imaging.pngimage, uKurse, ufrFotosListe, ufrVideoListe;

type
  TfrmKursText = class(TForm)
    Client: TXDataWebClient;
    divContent: THTMLDiv;
    lblTitel: THTMLDiv;
    lblText: THTMLDiv;
    divImages: THTMLDiv;
    divVideos: THTMLDiv;
    frFotosliste1: TfrFotosliste;
    pFooter: THTMLDiv;
    btnBack: TButton;
    btnDone: TButton;
    btnForward: TButton;
    divTransparent: THTMLDiv;
    btnBuchen: TButton;
    frVideoListe1: TfrVideoListe;

    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    [async] procedure btnDoneClick(Sender: TObject); async;
    [async] procedure btnBackClick(Sender: TObject); async;
    [async] procedure btnForwardClick(Sender: TObject); async;
    [async] procedure btnBuchenClick(Sender: TObject); async;
  private
    FJ:TJ;
    FHolder,
    FVor:integer;
    FID:integer;
    FContainer:TForm;
    FBtnPrior:TButton;
    [async] procedure Fill; async;
  public
    [async]procedure OpenSlider(s:string); async;
    property Container:TForm read FContainer write FContainer;
    [async] procedure Init(AID, AVor:integer; ABtnPrior:TButton; AAlle:boolean = true); async;
    property Id:integer read FId write FId;
  protected procedure LoadDFMValues; override; end;

var
  frmKursText: TfrmKursText;

implementation
  Uses uSlider, uME, uMain, uDatenmodul, uKursKapitel1, uKurs;

{$R *.dfm}

procedure TfrmKursText.Init(AID, AVor:integer; ABtnPrior:TButton; AAlle:boolean = true);
begin

  mainform.Wait := 1;
  FBtnPrior := ABtnPrior;

  if AAlle
  then FJ := TJ.create(Await( Client.RawInvokeAsync( 'IDBService.KurseNext',[AVOR, 0, Mainform.ME.ID])))
  else FJ := TJ.create(Await( Client.RawInvokeAsync( 'IDBService.KurseGetByID',[AID, Mainform.ME.ID])));

  lblTitel.Html.Text := '';
  TfrmME(FContainer).BreadCrumb.SetCaption(self, lblTitel, _KURSTEXT, FJ.Value('HEADER'));
  FJ.Index := FJ.GetIndex('ID', AId.ToString);

  await(Fill);

  mainform.Wait := -1;

end;

procedure TfrmKursText.OpenSlider(s:string);
begin
end;

procedure TfrmKursText.Fill;
Var
  j: TJ;
  i : integer;
begin

  btnDone.Enabled   := (FJ.Integer('DONE') = 0);

  if btnDone.Enabled = false
  then btnDone.ElementClassname := 'start-button-enabled'
  else btnDone.ElementClassname := 'start_button-lets-go';

  TfrmME(FContainer).BreadCrumb.SetCaption(_KURSTEXT, FJ.Value('HEADER'));

  lblText.HTML.Text := FJ.Value('TEXT');
  FID := FJ.Integer('ID');
  FVor:= FJ.Integer('VOR');

  i := await(GetKursLevel1(FID));
  j := TJ.create(await(datenmodul.EasySQL('select ID from kurse2rechte ' +
               ' where (RECHTE2_ID = ' + mainform.ME.Rechte.ID.ToString +
                   ' OR USER_ID=' + mainform.ME.ID.ToString+ ') AND KURSE_ID = ' + i.ToString)));

  if j.Value('ID') = ''
  then begin
    lblText.ElementHandle.style.setProperty('color','transparent');
    lblText.ElementHandle.style.setProperty('text-shadow','rgba(0, 0, 0, 0.85) 0px 0px 5px');
    lblText.ElementHandle.style.setProperty('user-select','none');
    lblText.ElementHandle.style.setProperty('font-weight','300');
    divTransparent.visible:= true;
    btnBuchen.Tag := FID;
    btnDone.enabled := false;

    btnDone.ElementClassname := 'start-button-enabled';

  end;

//  frFotosListe1.img.Visible := false;
  divImages.visible := (await(frFotosliste1.Init(mainform.Holder.ID, mainform.ME.ID, 0, 0, 0,FID, 0, -1,  nil, false, 0)) > 0);

//  frVideoListe1.img.Visible := false;
  divVideos.visible := (await(frVideoliste1.Init(mainform.Holder.ID, mainform.ME.ID, 0, 0, 0,FID, 0, -1,  nil, false, 0)) > 0);

  j.Free;

end;

procedure TfrmKursText.btnBackClick(Sender: TObject);
begin
  if FJ.Index > 0
  then begin
    FJ.Index := FJ.Index - 1;
    if FJ.Integer('UNTER') = 0
    then fill
    else begin
      if Assigned(FBtnPrior)
      then FBtnPrior.Click;
    end;
  end
  else begin
    if Assigned(FBtnPrior)
    then FBtnPrior.Click;
  end;
end;

procedure TfrmKursText.btnBuchenClick(Sender: TObject);
begin
  await(datenmodul.EasyInsert('insert into kurse2rechte (KURSE_ID, USER_ID) VALUES (' + inttostr(await(GetKursLevel1(FID))) + ',' + mainform.ME.ID.ToString + ')'));
//  lblText.ElementHandle.style.removeProperty('color');
//  lblText.ElementHandle.style.removeProperty('text-shadow');
//  lblText.ElementHandle.style.removeProperty('user-select');
  lblText.ElementClassName := 'kurstext_text';
//  lblText.ElementHandle.style.setProperty('font-weight','300');
  divTransparent.visible:= false;
end;

procedure TfrmKursText.btnDoneClick(Sender: TObject);
var
  Response: TXDataClientResponse;
  idx:integer;
begin

  Response := Await( Client.RawInvokeAsync( 'IDBService.KurseDone',[Mainform.ME.ID, FID]));
  //Index merken
  idx := FJ.Index;
  //Refreshen
//  FJ.Response := TAwait.Exec<TXDataClientResponse>( Client.RawInvokeAsync( 'IDBService.KurseGetByID',[FID, Mainform.ME.ID]));
  FJ.Response := Await( Client.RawInvokeAsync( 'IDBService.KurseNext',[FVor, 0, Mainform.ME.ID]));

  FJ.Index := idx;

  if assigned(TfrmME(FContainer).Kurse)
  then await(TfrmKurse(TfrmME(FContainer).Kurse).Refresh);

  if assigned(TfrmME(FContainer).Kurs)
  then await(TfrmKurs(TfrmME(FContainer).Kurs).Refresh);

  if assigned(TfrmME(FContainer).KursKapitel1)
  then await(TfrmKursKapitel1(TfrmME(FContainer).KursKapitel1).Refresh);

  if assigned(TfrmME(FContainer).KursKapitel2)
  then await(TfrmKursKapitel1(TfrmME(FContainer).KursKapitel2).Refresh);

  if assigned(TfrmME(FContainer).KursKapitel3)
  then await(TfrmKursKapitel1(TfrmME(FContainer).KursKapitel3).Refresh);

  btnDone.Enabled := false;
  //btnDone.ElementClassname := 'start_button-lets-go';
  btnDone.ElementClassname := 'start-button-enabled';

  btnForwardClick(sender);
end;

procedure TfrmKursText.btnForwardClick(Sender: TObject);
begin
  if FJ.Length-1 > FJ.Index
  then begin
    FJ.Index := FJ.Index + 1;
    if FJ.Integer('UNTER') = 0
    then fill
    else begin
      if Assigned(FBtnPrior)
      then FBtnPrior.Click;
    end;
  end
  else begin
    if Assigned(FBtnPrior)
    then FBtnPrior.Click;
  end;
end;

procedure TfrmKursText.WebFormCreate(Sender: TObject);
var
  el: TJSHTMLElement;
begin
  inherited;
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.removeProperty('height');
end;


procedure TfrmKursText.WebFormResize(Sender: TObject);
begin
  divTransparent.left  := 0;
  divTransparent.top   := lblText.Top;
  divTransparent.height:= lblText.Top - pFooter.Top;

  btnBuchen.Left := (divTransparent.Width - btnBuchen.Width) div 2;
end;

procedure TfrmKursText.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divContent := THTMLDiv.Create(Self);
  lblTitel := THTMLDiv.Create(Self);
  lblText := THTMLDiv.Create(Self);
  divImages := THTMLDiv.Create(Self);
  frFotosliste1 := TfrFotosliste.Create(Self);
  divVideos := THTMLDiv.Create(Self);
  frVideoListe1 := TfrVideoListe.Create(Self);
  pFooter := THTMLDiv.Create(Self);
  btnBack := TButton.Create(Self);
  btnDone := TButton.Create(Self);
  btnForward := TButton.Create(Self);
  divTransparent := THTMLDiv.Create(Self);
  btnBuchen := TButton.Create(Self);
  Client := TXDataWebClient.Create(Self);

  frFotosliste1.LoadFromForm;
  frVideoListe1.LoadFromForm;
  divContent.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  lblText.BeforeLoadDFMValues;
  divImages.BeforeLoadDFMValues;
  frFotosliste1.BeforeLoadDFMValues;
  frFotosliste1.WebHTMLDiv1.BeforeLoadDFMValues;
  frFotosliste1.divWrapper.BeforeLoadDFMValues;
  frFotosliste1.img.BeforeLoadDFMValues;
  frFotosliste1.Client.BeforeLoadDFMValues;
  divVideos.BeforeLoadDFMValues;
  frVideoListe1.BeforeLoadDFMValues;
  frVideoListe1.WebHTMLDiv1.BeforeLoadDFMValues;
  frVideoListe1.divWrapper.BeforeLoadDFMValues;
  frVideoListe1.img.BeforeLoadDFMValues;
  pFooter.BeforeLoadDFMValues;
  btnBack.BeforeLoadDFMValues;
  btnDone.BeforeLoadDFMValues;
  btnForward.BeforeLoadDFMValues;
  divTransparent.BeforeLoadDFMValues;
  btnBuchen.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frmKursText';
    Width := 981;
    Height := 986;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    divContent.SetParentComponent(Self);
    divContent.Name := 'divContent';
    divContent.Left := 0;
    divContent.Top := 0;
    divContent.Width := 961;
    divContent.Height := 986;
    divContent.ElementClassName := 'kurstext_content_wrapper';
    divContent.HeightStyle := ssAuto;
    divContent.WidthStyle := ssAuto;
    divContent.ChildOrder := 1;
    divContent.ElementPosition := epIgnore;
    divContent.ElementFont := efCSS;
    divContent.Role := '';
    lblTitel.SetParentComponent(divContent);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 2;
    lblTitel.Top := 2;
    lblTitel.Width := 774;
    lblTitel.Height := 30;
    lblTitel.ElementClassName := 'me_headline';
    lblTitel.HeightStyle := ssAuto;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.ChildOrder := 1;
    lblTitel.ElementPosition := epIgnore;
    lblTitel.ElementFont := efCSS;
    lblTitel.HTML.BeginUpdate;
    try
      lblTitel.HTML.Clear;
      lblTitel.HTML.Add(#220'berschrift');
    finally
      lblTitel.HTML.EndUpdate;
    end;
    lblTitel.Role := '';
    lblText.SetParentComponent(divContent);
    lblText.Name := 'lblText';
    lblText.Left := 3;
    lblText.Top := 51;
    lblText.Width := 774;
    lblText.Height := 94;
    lblText.ElementClassName := 'kurstext_text';
    lblText.HeightStyle := ssAuto;
    lblText.WidthStyle := ssAuto;
    lblText.ChildOrder := 2;
    lblText.ElementPosition := epIgnore;
    lblText.ElementFont := efCSS;
    lblText.HTML.BeginUpdate;
    try
      lblText.HTML.Clear;
      lblText.HTML.Add('Text');
    finally
      lblText.HTML.EndUpdate;
    end;
    lblText.Role := '';
    divImages.SetParentComponent(divContent);
    divImages.Name := 'divImages';
    divImages.Left := 74;
    divImages.Top := 511;
    divImages.Width := 773;
    divImages.Height := 155;
    divImages.ElementClassName := 'ktext_img_container';
    divImages.HeightStyle := ssAuto;
    divImages.WidthStyle := ssAuto;
    divImages.ChildOrder := 2;
    divImages.ElementPosition := epIgnore;
    divImages.ElementFont := efCSS;
    divImages.Role := '';
    frFotosliste1.SetParentComponent(divImages);
    frFotosliste1.Name := 'frFotosliste1';
    frFotosliste1.Left := 45;
    frFotosliste1.Top := 6;
    frFotosliste1.Width := 230;
    frFotosliste1.Height := 131;
    frFotosliste1.Margins.Left := 0;
    frFotosliste1.Margins.Top := 0;
    frFotosliste1.Margins.Right := 0;
    frFotosliste1.Margins.Bottom := 0;
    frFotosliste1.TabOrder := 0;
    frFotosliste1.WebHTMLDiv1.Left := 21;
    frFotosliste1.WebHTMLDiv1.Width := 206;
    frFotosliste1.WebHTMLDiv1.Height := 121;
    frFotosliste1.divWrapper.Left := 11;
    frFotosliste1.divWrapper.Top := 10;
    frFotosliste1.divWrapper.Width := 182;
    frFotosliste1.divWrapper.Height := 103;
    frFotosliste1.img.Top := 4;
    frFotosliste1.Client.Left := 16;
    frFotosliste1.Client.Top := 65520;
    divVideos.SetParentComponent(divContent);
    divVideos.Name := 'divVideos';
    divVideos.Left := 79;
    divVideos.Top := 688;
    divVideos.Width := 768;
    divVideos.Height := 177;
    divVideos.ElementClassName := 'ktext_img_container';
    divVideos.HeightStyle := ssAuto;
    divVideos.WidthStyle := ssAuto;
    divVideos.ChildOrder := 3;
    divVideos.ElementPosition := epIgnore;
    divVideos.ElementFont := efCSS;
    divVideos.Role := '';
    divVideos.Visible := False;
    frVideoListe1.SetParentComponent(divVideos);
    frVideoListe1.Name := 'frVideoListe1';
    frVideoListe1.Left := 11;
    frVideoListe1.Top := 20;
    frVideoListe1.Width := 279;
    frVideoListe1.Height := 149;
    frVideoListe1.Margins.Left := 0;
    frVideoListe1.Margins.Top := 0;
    frVideoListe1.Margins.Right := 0;
    frVideoListe1.Margins.Bottom := 0;
    frVideoListe1.TabOrder := 0;
    frVideoListe1.WebHTMLDiv1.Left := 11;
    frVideoListe1.WebHTMLDiv1.Width := 195;
    frVideoListe1.WebHTMLDiv1.Height := 129;
    frVideoListe1.divWrapper.Width := 166;
    frVideoListe1.img.Left := 8;
    frVideoListe1.img.Top := 8;
    frVideoListe1.img.Height := 74;
    pFooter.SetParentComponent(divContent);
    pFooter.Name := 'pFooter';
    pFooter.Left := 68;
    pFooter.Top := 879;
    pFooter.Width := 773;
    pFooter.Height := 73;
    pFooter.ElementClassName := 'editor-footer-div';
    pFooter.HeightStyle := ssAuto;
    pFooter.WidthStyle := ssAuto;
    pFooter.ChildOrder := 4;
    pFooter.ElementPosition := epIgnore;
    pFooter.ElementFont := efCSS;
    pFooter.Role := '';
    btnBack.SetParentComponent(pFooter);
    btnBack.Name := 'btnBack';
    btnBack.Left := 168;
    btnBack.Top := 24;
    btnBack.Width := 96;
    btnBack.Height := 25;
    btnBack.Caption := 'Vorherige';
    btnBack.ElementClassName := 'start_button-lets-go';
    btnBack.ElementFont := efCSS;
    btnBack.ElementPosition := epIgnore;
    btnBack.HeightStyle := ssAuto;
    btnBack.HeightPercent := 100.000000000000000000;
    btnBack.WidthStyle := ssAuto;
    btnBack.WidthPercent := 100.000000000000000000;
    SetEvent(btnBack, Self, 'OnClick', 'btnBackClick');
    btnDone.SetParentComponent(pFooter);
    btnDone.Name := 'btnDone';
    btnDone.Left := 352;
    btnDone.Top := 24;
    btnDone.Width := 96;
    btnDone.Height := 25;
    btnDone.Caption := 'Erledigt';
    btnDone.ChildOrder := 1;
    btnDone.ElementClassName := 'start_button-lets-go';
    btnDone.ElementFont := efCSS;
    btnDone.ElementPosition := epIgnore;
    btnDone.Enabled := False;
    btnDone.HeightStyle := ssAuto;
    btnDone.HeightPercent := 100.000000000000000000;
    btnDone.WidthStyle := ssAuto;
    btnDone.WidthPercent := 100.000000000000000000;
    SetEvent(btnDone, Self, 'OnClick', 'btnDoneClick');
    btnForward.SetParentComponent(pFooter);
    btnForward.Name := 'btnForward';
    btnForward.Left := 560;
    btnForward.Top := 24;
    btnForward.Width := 96;
    btnForward.Height := 25;
    btnForward.Caption := 'N'#228'chste';
    btnForward.ChildOrder := 2;
    btnForward.ElementClassName := 'start_button-lets-go';
    btnForward.ElementFont := efCSS;
    btnForward.ElementPosition := epIgnore;
    btnForward.HeightStyle := ssAuto;
    btnForward.HeightPercent := 100.000000000000000000;
    btnForward.WidthStyle := ssAuto;
    btnForward.WidthPercent := 100.000000000000000000;
    SetEvent(btnForward, Self, 'OnClick', 'btnForwardClick');
    divTransparent.SetParentComponent(divContent);
    divTransparent.Name := 'divTransparent';
    divTransparent.Left := 454;
    divTransparent.Top := 249;
    divTransparent.Width := 457;
    divTransparent.Height := 193;
    divTransparent.ElementClassName := 'blur';
    divTransparent.HeightStyle := ssAuto;
    divTransparent.WidthStyle := ssAuto;
    divTransparent.ElementPosition := epIgnore;
    divTransparent.ElementFont := efCSS;
    divTransparent.Role := '';
    divTransparent.Visible := False;
    btnBuchen.SetParentComponent(divTransparent);
    btnBuchen.Name := 'btnBuchen';
    btnBuchen.Left := 160;
    btnBuchen.Top := 80;
    btnBuchen.Width := 161;
    btnBuchen.Height := 25;
    btnBuchen.Caption := 'Diese Lektion buchen...';
    btnBuchen.ChildOrder := 1;
    btnBuchen.ElementClassName := 'btnBuchen';
    btnBuchen.ElementFont := efCSS;
    btnBuchen.ElementPosition := epIgnore;
    btnBuchen.HeightStyle := ssAuto;
    btnBuchen.HeightPercent := 100.000000000000000000;
    btnBuchen.WidthStyle := ssAuto;
    btnBuchen.WidthPercent := 100.000000000000000000;
    SetEvent(btnBuchen, Self, 'OnClick', 'btnBuchenClick');
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 16;
    Client.Top := 252;
  finally
    divContent.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    lblText.AfterLoadDFMValues;
    divImages.AfterLoadDFMValues;
    frFotosliste1.AfterLoadDFMValues;
    frFotosliste1.WebHTMLDiv1.AfterLoadDFMValues;
    frFotosliste1.divWrapper.AfterLoadDFMValues;
    frFotosliste1.img.AfterLoadDFMValues;
    frFotosliste1.Client.AfterLoadDFMValues;
    divVideos.AfterLoadDFMValues;
    frVideoListe1.AfterLoadDFMValues;
    frVideoListe1.WebHTMLDiv1.AfterLoadDFMValues;
    frVideoListe1.divWrapper.AfterLoadDFMValues;
    frVideoListe1.img.AfterLoadDFMValues;
    pFooter.AfterLoadDFMValues;
    btnBack.AfterLoadDFMValues;
    btnDone.AfterLoadDFMValues;
    btnForward.AfterLoadDFMValues;
    divTransparent.AfterLoadDFMValues;
    btnBuchen.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.

