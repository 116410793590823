unit uDocViewer;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, uTb, XData.Web.Client, Vcl.Controls,
  WEBLib.WebCtrls, Vcl.Imaging.pngimage, WEBLib.ExtCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.ComCtrls, Vcl.Forms, ufrFotosListe, ufrVideoListe,
  WEBLib.EditAutocomplete, VCL.TMSFNCTypes, VCL.TMSFNCUtils, VCL.TMSFNCGraphics,
  VCL.TMSFNCGraphicsTypes, VCL.TMSFNCCustomControl, VCL.TMSFNCWebBrowser,
  VCL.TMSFNCCustomWEBControl, VCL.TMSFNCWXPDFViewer;

type
  TfrmDocViewer = class(TForm)
    Client: TXDataWebClient;
    WebHTMLDiv1: THTMLDiv;
    WebHTMLDiv11: THTMLDiv;
    btnClose: TImageControl;
    lblTitel: TLabel;
    divDoc: THTMLDiv;
    pFooter: THTMLDiv;
    btnAction: TButton;
    btnCancel: TButton;
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure btnCloseClick(Sender: TObject);
    procedure btnActionClick(Sender: TObject);
  private
    FContainer:THtmlDiv;
    FID, FDocID, FMainID,
    FHolder, FMe, FParent:integer;
    FTyp: integer;
    FNewID:integer;
    FCallback:TCallback;
    FAktImage:string;
  public
    property Container:THtmlDiv read FContainer write FContainer;
    class function Exec(AHolder, ATyp: integer; AButton: array of string; ACallback:TCallback):integer;
    [async] procedure ImageClick(s:string); async;
  protected procedure LoadDFMValues; override; end;

var
  frmDocViewer: TfrmDocViewer;

implementation
    uses uMain, uDatenmodul;
{$R *.dfm}


procedure TfrmDocViewer.btnActionClick(Sender: TObject);
begin
  if assigned(FCallback)
  then FCallback(FDocID.tostring);
  Close;
end;

class function TfrmDocViewer.Exec(AHolder, ATyp: integer; AButton: array of string; ACallback:TCallback):integer;

  [async] procedure AfterCreate(AForm: TObject); async;
  Var
    Response: TXDataClientResponse;
    s:string;
    j:TJ;
    i:integer;
  begin
    j := TJ.create(await(datenmodul.EasySQL('Select * from Texte where Typ = ' + ATyp.tostring + ' AND HOLDER = ' + AHolder.ToString)));

    TfrmDocViewer(AForm).LblTitel.Caption := j.Value('TITEL');
    TfrmDocViewer(AForm).divDoc.HTML.Text := j.Value('TEXT');
    TfrmDocViewer(AForm).FCallback        := ACallback;
    TfrmDocViewer(AForm).FDocID           := ATyp;

    if length(AButton) > 0
    then TfrmDocViewer(AForm).btnCancel.caption := AButton[0];
    if length(AButton) >= 1
    then begin
      TfrmDocViewer(AForm).btnAction.caption := AButton[1];
      TfrmDocViewer(AForm).btnAction.visible := true;
    end;


  end;

Var
  frm:TfrmDocViewer;
  FConti:THtmlDiv;
begin
  FConti := THtmlDiv.Create(Mainform);
  FConti.Parent := Mainform;
  FConti.ElementClassName := 'editor_transparent';
  frm := TfrmDocViewer.CreateNew(FConti.ElementID, @AfterCreate);

  frm.FContainer := FConti;

end;

procedure TfrmDocViewer.ImageClick(s:string);
begin
end;

procedure TfrmDocViewer.btnCloseClick(Sender: TObject);
begin
  close;
end;

procedure TfrmDocViewer.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  FreeAndNil(FContainer);
end;

procedure TfrmDocViewer.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  WebHTMLDiv11 := THTMLDiv.Create(Self);
  btnClose := TImageControl.Create(Self);
  lblTitel := TLabel.Create(Self);
  divDoc := THTMLDiv.Create(Self);
  pFooter := THTMLDiv.Create(Self);
  btnAction := TButton.Create(Self);
  btnCancel := TButton.Create(Self);
  Client := TXDataWebClient.Create(Self);

  WebHTMLDiv1.BeforeLoadDFMValues;
  WebHTMLDiv11.BeforeLoadDFMValues;
  btnClose.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  divDoc.BeforeLoadDFMValues;
  pFooter.BeforeLoadDFMValues;
  btnAction.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frmDocViewer';
    Width := 633;
    Height := 505;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    Visible := True;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 8;
    WebHTMLDiv1.Top := 0;
    WebHTMLDiv1.Width := 617;
    WebHTMLDiv1.Height := 497;
    WebHTMLDiv1.ElementClassName := 'blogedit_container';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv11.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv11.Name := 'WebHTMLDiv11';
    WebHTMLDiv11.Left := 12;
    WebHTMLDiv11.Top := 6;
    WebHTMLDiv11.Width := 591;
    WebHTMLDiv11.Height := 41;
    WebHTMLDiv11.ElementClassName := 'input_header';
    WebHTMLDiv11.HeightStyle := ssAuto;
    WebHTMLDiv11.WidthStyle := ssAuto;
    WebHTMLDiv11.ElementPosition := epRelative;
    WebHTMLDiv11.ElementFont := efCSS;
    WebHTMLDiv11.Role := '';
    btnClose.SetParentComponent(WebHTMLDiv11);
    btnClose.Name := 'btnClose';
    btnClose.Left := 540;
    btnClose.Top := -3;
    btnClose.Width := 46;
    btnClose.Height := 44;
    btnClose.ElementClassName := 'input_close';
    btnClose.HeightStyle := ssAuto;
    btnClose.WidthStyle := ssAuto;
    btnClose.HeightPercent := 100.000000000000000000;
    btnClose.WidthPercent := 100.000000000000000000;
    btnClose.Anchors := [];
    btnClose.ChildOrder := 1;
    btnClose.ElementFont := efCSS;
    btnClose.ElementPosition := epIgnore;
    SetEvent(btnClose, Self, 'OnClick', 'btnCloseClick');
    btnClose.Picture.LoadFromFile('uDocViewer.WebHTMLDiv11.btnClose.Picture.png');
    lblTitel.SetParentComponent(WebHTMLDiv11);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 16;
    lblTitel.Top := 9;
    lblTitel.Width := 35;
    lblTitel.Height := 15;
    lblTitel.Caption := 'lblTitel';
    lblTitel.ElementLabelClassName := 'editor_titel';
    lblTitel.ElementFont := efCSS;
    lblTitel.HeightStyle := ssAuto;
    lblTitel.HeightPercent := 100.000000000000000000;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.WidthPercent := 100.000000000000000000;
    divDoc.SetParentComponent(WebHTMLDiv1);
    divDoc.Name := 'divDoc';
    divDoc.Left := 12;
    divDoc.Top := 53;
    divDoc.Width := 586;
    divDoc.Height := 396;
    divDoc.ElementClassName := 'docviewer-container';
    divDoc.HeightStyle := ssAuto;
    divDoc.WidthStyle := ssAuto;
    divDoc.ChildOrder := 1;
    divDoc.ElementPosition := epIgnore;
    divDoc.ElementFont := efCSS;
    divDoc.Role := '';
    pFooter.SetParentComponent(WebHTMLDiv1);
    pFooter.Name := 'pFooter';
    pFooter.Left := 12;
    pFooter.Top := 455;
    pFooter.Width := 586;
    pFooter.Height := 34;
    pFooter.ElementClassName := 'editor-footer-div';
    pFooter.HeightStyle := ssAuto;
    pFooter.WidthStyle := ssAuto;
    pFooter.ChildOrder := 5;
    pFooter.ElementPosition := epIgnore;
    pFooter.ElementFont := efCSS;
    pFooter.Role := '';
    btnAction.SetParentComponent(pFooter);
    btnAction.Name := 'btnAction';
    btnAction.Left := 170;
    btnAction.Top := 3;
    btnAction.Width := 96;
    btnAction.Height := 25;
    btnAction.Caption := 'Action';
    btnAction.ElementClassName := 'start_button-lets-go';
    btnAction.ElementFont := efCSS;
    btnAction.ElementPosition := epIgnore;
    btnAction.HeightStyle := ssAuto;
    btnAction.HeightPercent := 100.000000000000000000;
    btnAction.Visible := False;
    btnAction.WidthStyle := ssAuto;
    btnAction.WidthPercent := 100.000000000000000000;
    SetEvent(btnAction, Self, 'OnClick', 'btnActionClick');
    btnCancel.SetParentComponent(pFooter);
    btnCancel.Name := 'btnCancel';
    btnCancel.Left := 272;
    btnCancel.Top := 3;
    btnCancel.Width := 96;
    btnCancel.Height := 25;
    btnCancel.Caption := 'Schlie'#223'en';
    btnCancel.ChildOrder := 1;
    btnCancel.ElementClassName := 'start_button-lets-go';
    btnCancel.ElementFont := efCSS;
    btnCancel.ElementPosition := epIgnore;
    btnCancel.HeightStyle := ssAuto;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthStyle := ssAuto;
    btnCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCancel, Self, 'OnClick', 'btnCloseClick');
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 584;
    Client.Top := 64;
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    WebHTMLDiv11.AfterLoadDFMValues;
    btnClose.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    divDoc.AfterLoadDFMValues;
    pFooter.AfterLoadDFMValues;
    btnAction.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.
