unit uCode;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  XData.Web.Client, WEBLib.WebCtrls;


type

  TfrmCode = class(TForm)
    btnSave: TButton;
    WebButton2: TButton;
    edEingabe: TEdit;
    procedure WebButton2Click(Sender: TObject);
    [async] procedure btnSaveClick(Sender: TObject); async;
  private
    FResultValue:string;
    FUserId:integer;
    procedure SaveAlbum(AlbumName: string);
 public
    [async] class function show(AUser:integer; ACaption:string):string; async;
    property ResultValue:string read FResultValue write FResultValue;
    property UserId:integer read FUserId write FUserId;
  protected procedure LoadDFMValues; override; end;

var
  frmCode: TfrmCode;

implementation
{$R *.dfm}


class function TfrmCode.show(AUser:integer; ACaption:string):string;
var
  newform: TfrmCode;
begin

  newform := TfrmCode.Create(application.MainForm);
  newform.Caption := ACaption;
  newform.Popup := true;

  newform.Border := fbDialogSizeable;
  window.location.hash := ACaption;
  await(TfrmCode, newform.Load());

  try
    await(TModalResult, newform.Execute);
    result := TfrmCode(newform).edEingabe.Text;
  finally
    newform.Free;
  end;

end;

procedure TfrmCode.btnSaveClick(Sender: TObject);
begin
  close;
end;

procedure TfrmCode.SaveAlbum(AlbumName:string);
begin
end;

procedure TfrmCode.WebButton2Click(Sender: TObject);
begin
  Close;
end;

procedure TfrmCode.LoadDFMValues;
begin
  inherited LoadDFMValues;

  btnSave := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  edEingabe := TEdit.Create(Self);

  btnSave.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  edEingabe.BeforeLoadDFMValues;
  try
    Name := 'frmCode';
    Width := 566;
    Height := 118;
    btnSave.SetParentComponent(Self);
    btnSave.Name := 'btnSave';
    btnSave.Left := 328;
    btnSave.Top := 76;
    btnSave.Width := 96;
    btnSave.Height := 25;
    btnSave.Caption := 'Speichern';
    btnSave.ElementClassName := 'loader';
    btnSave.HeightPercent := 100.000000000000000000;
    btnSave.WidthPercent := 100.000000000000000000;
    SetEvent(btnSave, Self, 'OnClick', 'btnSaveClick');
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 448;
    WebButton2.Top := 76;
    WebButton2.Width := 96;
    WebButton2.Height := 25;
    WebButton2.Caption := 'Abbrechen';
    WebButton2.ChildOrder := 1;
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    edEingabe.SetParentComponent(Self);
    edEingabe.Name := 'edEingabe';
    edEingabe.Left := 24;
    edEingabe.Top := 8;
    edEingabe.Width := 520;
    edEingabe.Height := 62;
    edEingabe.ChildOrder := 3;
    edEingabe.HeightPercent := 100.000000000000000000;
    edEingabe.TextHint := 'hier Code eingeben...';
    edEingabe.WidthPercent := 100.000000000000000000;
  finally
    btnSave.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    edEingabe.AfterLoadDFMValues;
  end;
end;

end.
