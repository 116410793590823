unit ufrGruppenStuff;

interface

uses
  System.SysUtils, System.Variants, System.Classes,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, WEBLib.Controls, uTB,
  WEBLib.WebCtrls, Web,JS, Vcl.Imaging.jpeg, WEBLib.ExtCtrls, XData.Web.Client,
  WEBLib.Graphics, WEBLib.Forms;

type
  TfrGruppenStuff = class(TFrame)
    divWrapper: THTMLDiv;
    divContent: THTMLDiv;
    Client: TXDataWebClient;
    divAboutContainer: THTMLDiv;
    divHobbiesCOntainer: THTMLDiv;
    divReviereContainer: THTMLDiv;
    lblAbout: THTMLDiv;
    WebHTMLDiv5: THTMLDiv;
    divHobbies: THTMLDiv;
    WebHTMLDiv3: THTMLDiv;
    divReviere: THTMLDiv;
    WebHTMLDiv1: THTMLDiv;
    divExpertiseContainer: THTMLDiv;
    WebHTMLDiv4: THTMLDiv;
    divExpertise: THTMLDiv;
  protected
    procedure Loaded(); override;

  private
  public
    public constructor Create(AOwner:TComponent);Override;
    [async] procedure Init(AHolder:integer); async;
  protected procedure LoadDFMValues; override; end;

implementation
   Uses uDatenmodul, uMain;

{$R *.dfm}

constructor TfrGruppenStuff.Create(AOwner: TComponent);
begin
  inherited;
end;

procedure TfrGruppenStuff.Init(AHolder:integer);
begin
   divWrapper.Visible := false;
   self.lblAbout.HTML.Text := Mainform.Holder.About;
   self.divAboutContainer.Visible := Mainform.Holder.About > '';

   tbCreateHashTags(self, divHobbies, Mainform.Holder.Hobbies, 'me_hashtag', nil);
   divHobbiesContainer.Visible := Mainform.Holder.Hobbies > '';
   tbCreateHashTags(self, divReviere, Mainform.Holder.Reviere, 'me_hashtag', nil);
   divReviereContainer.Visible := Mainform.Holder.Reviere > '';
   tbCreateHashTags(self, divExpertise, Mainform.Holder.Expertise, 'me_hashtag', nil);
   divExpertiseContainer.Visible := Mainform.Holder.Expertise > '';

   divWrapper.Visible := true;
end;

procedure TfrGruppenStuff.Loaded();
var
  el: TJSHTMLElement;
begin
  inherited;

  TControl(self).ElementPosition := epIgnore;
  TControl(self).ElementID := 'frGruppenStuff';
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-style');
  el.style.removeProperty('font-size');
  el.style.removeProperty('user-select');

end;
procedure TfrGruppenStuff.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divWrapper := THTMLDiv.Create(Self);
  divContent := THTMLDiv.Create(Self);
  divAboutContainer := THTMLDiv.Create(Self);
  lblAbout := THTMLDiv.Create(Self);
  divHobbiesCOntainer := THTMLDiv.Create(Self);
  WebHTMLDiv5 := THTMLDiv.Create(Self);
  divHobbies := THTMLDiv.Create(Self);
  divReviereContainer := THTMLDiv.Create(Self);
  WebHTMLDiv3 := THTMLDiv.Create(Self);
  divReviere := THTMLDiv.Create(Self);
  divExpertiseContainer := THTMLDiv.Create(Self);
  WebHTMLDiv4 := THTMLDiv.Create(Self);
  divExpertise := THTMLDiv.Create(Self);
  WebHTMLDiv1 := THTMLDiv.Create(Self);
  Client := TXDataWebClient.Create(Self);

  divWrapper.BeforeLoadDFMValues;
  divContent.BeforeLoadDFMValues;
  divAboutContainer.BeforeLoadDFMValues;
  lblAbout.BeforeLoadDFMValues;
  divHobbiesCOntainer.BeforeLoadDFMValues;
  WebHTMLDiv5.BeforeLoadDFMValues;
  divHobbies.BeforeLoadDFMValues;
  divReviereContainer.BeforeLoadDFMValues;
  WebHTMLDiv3.BeforeLoadDFMValues;
  divReviere.BeforeLoadDFMValues;
  divExpertiseContainer.BeforeLoadDFMValues;
  WebHTMLDiv4.BeforeLoadDFMValues;
  divExpertise.BeforeLoadDFMValues;
  WebHTMLDiv1.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frGruppenStuff';
    Left := 0;
    Top := 0;
    Width := 319;
    Height := 554;
    TabOrder := 0;
    divWrapper.SetParentComponent(Self);
    divWrapper.Name := 'divWrapper';
    divWrapper.Left := 9;
    divWrapper.Top := 6;
    divWrapper.Width := 296;
    divWrapper.Height := 499;
    divWrapper.ElementClassName := 'me_frame_container';
    divWrapper.HeightStyle := ssAuto;
    divWrapper.WidthStyle := ssAuto;
    divWrapper.ElementPosition := epIgnore;
    divWrapper.ElementFont := efCSS;
    divWrapper.Role := '';
    divContent.SetParentComponent(divWrapper);
    divContent.Name := 'divContent';
    divContent.Left := 19;
    divContent.Top := 58;
    divContent.Width := 262;
    divContent.Height := 423;
    divContent.ElementClassName := 'me_frame';
    divContent.HeightStyle := ssAuto;
    divContent.WidthStyle := ssAuto;
    divContent.ChildOrder := 1;
    divContent.ElementPosition := epIgnore;
    divContent.ElementFont := efCSS;
    divContent.HTML.BeginUpdate;
    try
      divContent.HTML.Clear;
      divContent.HTML.Add('');
    finally
      divContent.HTML.EndUpdate;
    end;
    divContent.Role := '';
    divAboutContainer.SetParentComponent(divContent);
    divAboutContainer.Name := 'divAboutContainer';
    divAboutContainer.Left := 19;
    divAboutContainer.Top := 15;
    divAboutContainer.Width := 230;
    divAboutContainer.Height := 50;
    divAboutContainer.ElementClassName := 'me_about_container';
    divAboutContainer.HeightStyle := ssAuto;
    divAboutContainer.WidthStyle := ssAuto;
    divAboutContainer.ChildOrder := 4;
    divAboutContainer.ElementPosition := epIgnore;
    divAboutContainer.ElementFont := efCSS;
    divAboutContainer.Role := '';
    lblAbout.SetParentComponent(divAboutContainer);
    lblAbout.Name := 'lblAbout';
    lblAbout.Left := 0;
    lblAbout.Top := 3;
    lblAbout.Width := 230;
    lblAbout.Height := 41;
    lblAbout.ElementClassName := 'me_about_text';
    lblAbout.HeightStyle := ssAuto;
    lblAbout.WidthStyle := ssAuto;
    lblAbout.ChildOrder := 4;
    lblAbout.ElementPosition := epIgnore;
    lblAbout.ElementFont := efCSS;
    lblAbout.HTML.BeginUpdate;
    try
      lblAbout.HTML.Clear;
      lblAbout.HTML.Add('');
    finally
      lblAbout.HTML.EndUpdate;
    end;
    lblAbout.Role := '';
    divHobbiesCOntainer.SetParentComponent(divContent);
    divHobbiesCOntainer.Name := 'divHobbiesCOntainer';
    divHobbiesCOntainer.Left := 19;
    divHobbiesCOntainer.Top := 111;
    divHobbiesCOntainer.Width := 230;
    divHobbiesCOntainer.Height := 82;
    divHobbiesCOntainer.ElementClassName := 'me_about_container';
    divHobbiesCOntainer.HeightStyle := ssAuto;
    divHobbiesCOntainer.WidthStyle := ssAuto;
    divHobbiesCOntainer.ChildOrder := 5;
    divHobbiesCOntainer.ElementPosition := epIgnore;
    divHobbiesCOntainer.ElementFont := efCSS;
    divHobbiesCOntainer.Role := '';
    WebHTMLDiv5.SetParentComponent(divHobbiesCOntainer);
    WebHTMLDiv5.Name := 'WebHTMLDiv5';
    WebHTMLDiv5.Left := 3;
    WebHTMLDiv5.Top := 3;
    WebHTMLDiv5.Width := 230;
    WebHTMLDiv5.Height := 34;
    WebHTMLDiv5.ElementClassName := 'me_about_header';
    WebHTMLDiv5.HeightStyle := ssAuto;
    WebHTMLDiv5.WidthStyle := ssAuto;
    WebHTMLDiv5.ChildOrder := 5;
    WebHTMLDiv5.ElementPosition := epIgnore;
    WebHTMLDiv5.ElementFont := efCSS;
    WebHTMLDiv5.HTML.BeginUpdate;
    try
      WebHTMLDiv5.HTML.Clear;
      WebHTMLDiv5.HTML.Add('Meine Hobbies');
    finally
      WebHTMLDiv5.HTML.EndUpdate;
    end;
    WebHTMLDiv5.Role := '';
    divHobbies.SetParentComponent(divHobbiesCOntainer);
    divHobbies.Name := 'divHobbies';
    divHobbies.Left := 0;
    divHobbies.Top := 43;
    divHobbies.Width := 230;
    divHobbies.Height := 34;
    divHobbies.ElementClassName := 'me_about_text';
    divHobbies.HeightStyle := ssAuto;
    divHobbies.WidthStyle := ssAuto;
    divHobbies.ChildOrder := 5;
    divHobbies.ElementPosition := epIgnore;
    divHobbies.ElementFont := efCSS;
    divHobbies.Role := '';
    divReviereContainer.SetParentComponent(divContent);
    divReviereContainer.Name := 'divReviereContainer';
    divReviereContainer.Left := 22;
    divReviereContainer.Top := 199;
    divReviereContainer.Width := 230;
    divReviereContainer.Height := 106;
    divReviereContainer.ElementClassName := 'me_about_container';
    divReviereContainer.HeightStyle := ssAuto;
    divReviereContainer.WidthStyle := ssAuto;
    divReviereContainer.ChildOrder := 6;
    divReviereContainer.ElementPosition := epIgnore;
    divReviereContainer.ElementFont := efCSS;
    divReviereContainer.Role := '';
    WebHTMLDiv3.SetParentComponent(divReviereContainer);
    WebHTMLDiv3.Name := 'WebHTMLDiv3';
    WebHTMLDiv3.Left := -22;
    WebHTMLDiv3.Top := 0;
    WebHTMLDiv3.Width := 230;
    WebHTMLDiv3.Height := 42;
    WebHTMLDiv3.ElementClassName := 'me_about_header';
    WebHTMLDiv3.HeightStyle := ssAuto;
    WebHTMLDiv3.WidthStyle := ssAuto;
    WebHTMLDiv3.ChildOrder := 6;
    WebHTMLDiv3.ElementPosition := epIgnore;
    WebHTMLDiv3.ElementFont := efCSS;
    WebHTMLDiv3.HTML.BeginUpdate;
    try
      WebHTMLDiv3.HTML.Clear;
      WebHTMLDiv3.HTML.Add('Meine Lieblings-Reviere');
    finally
      WebHTMLDiv3.HTML.EndUpdate;
    end;
    WebHTMLDiv3.Role := '';
    divReviere.SetParentComponent(divReviereContainer);
    divReviere.Name := 'divReviere';
    divReviere.Left := 0;
    divReviere.Top := 61;
    divReviere.Width := 230;
    divReviere.Height := 42;
    divReviere.ElementClassName := 'me_about_text';
    divReviere.HeightStyle := ssAuto;
    divReviere.WidthStyle := ssAuto;
    divReviere.ChildOrder := 6;
    divReviere.ElementPosition := epIgnore;
    divReviere.ElementFont := efCSS;
    divReviere.Role := '';
    divExpertiseContainer.SetParentComponent(divContent);
    divExpertiseContainer.Name := 'divExpertiseContainer';
    divExpertiseContainer.Left := 19;
    divExpertiseContainer.Top := 311;
    divExpertiseContainer.Width := 230;
    divExpertiseContainer.Height := 106;
    divExpertiseContainer.ElementClassName := 'me_about_container';
    divExpertiseContainer.HeightStyle := ssAuto;
    divExpertiseContainer.WidthStyle := ssAuto;
    divExpertiseContainer.ChildOrder := 6;
    divExpertiseContainer.ElementPosition := epIgnore;
    divExpertiseContainer.ElementFont := efCSS;
    divExpertiseContainer.Role := '';
    WebHTMLDiv4.SetParentComponent(divExpertiseContainer);
    WebHTMLDiv4.Name := 'WebHTMLDiv4';
    WebHTMLDiv4.Left := -22;
    WebHTMLDiv4.Top := 0;
    WebHTMLDiv4.Width := 230;
    WebHTMLDiv4.Height := 42;
    WebHTMLDiv4.ElementClassName := 'me_about_header';
    WebHTMLDiv4.HeightStyle := ssAuto;
    WebHTMLDiv4.WidthStyle := ssAuto;
    WebHTMLDiv4.ChildOrder := 6;
    WebHTMLDiv4.ElementPosition := epIgnore;
    WebHTMLDiv4.ElementFont := efCSS;
    WebHTMLDiv4.HTML.BeginUpdate;
    try
      WebHTMLDiv4.HTML.Clear;
      WebHTMLDiv4.HTML.Add('Meine Expertise');
    finally
      WebHTMLDiv4.HTML.EndUpdate;
    end;
    WebHTMLDiv4.Role := '';
    divExpertise.SetParentComponent(divExpertiseContainer);
    divExpertise.Name := 'divExpertise';
    divExpertise.Left := 0;
    divExpertise.Top := 61;
    divExpertise.Width := 230;
    divExpertise.Height := 42;
    divExpertise.ElementClassName := 'me_about_text';
    divExpertise.HeightStyle := ssAuto;
    divExpertise.WidthStyle := ssAuto;
    divExpertise.ChildOrder := 6;
    divExpertise.ElementPosition := epIgnore;
    divExpertise.ElementFont := efCSS;
    divExpertise.Role := '';
    WebHTMLDiv1.SetParentComponent(divWrapper);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 19;
    WebHTMLDiv1.Top := 3;
    WebHTMLDiv1.Width := 209;
    WebHTMLDiv1.Height := 49;
    WebHTMLDiv1.ElementClassName := 'me_headline';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epRelative;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.HTML.BeginUpdate;
    try
      WebHTMLDiv1.HTML.Clear;
      WebHTMLDiv1.HTML.Add(#220'ber mich');
      WebHTMLDiv1.HTML.Add('');
    finally
      WebHTMLDiv1.HTML.EndUpdate;
    end;
    WebHTMLDiv1.Role := '';
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 19;
    Client.Top := 19;
  finally
    divWrapper.AfterLoadDFMValues;
    divContent.AfterLoadDFMValues;
    divAboutContainer.AfterLoadDFMValues;
    lblAbout.AfterLoadDFMValues;
    divHobbiesCOntainer.AfterLoadDFMValues;
    WebHTMLDiv5.AfterLoadDFMValues;
    divHobbies.AfterLoadDFMValues;
    divReviereContainer.AfterLoadDFMValues;
    WebHTMLDiv3.AfterLoadDFMValues;
    divReviere.AfterLoadDFMValues;
    divExpertiseContainer.AfterLoadDFMValues;
    WebHTMLDiv4.AfterLoadDFMValues;
    divExpertise.AfterLoadDFMValues;
    WebHTMLDiv1.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.
