unit uKurse;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ComCtrls, WEBLib.ExtCtrls,
  Vcl.StdCtrls, WEBLib.StdCtrls, Data.DB, XData.Web.JsonDataset,  uTB,
  XData.Web.Dataset, XData.Web.Client, Vcl.Imaging.jpeg, WEBLib.WebCtrls,
  uKurs, ufrKurs,  ufrKurse;

type
  TfrmKurse = class(TForm)
    Client: TXDataWebClient;
    divContent: THTMLDiv;
    lblTitel: THTMLDiv;
    procedure btnKursClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    FKurse,
    FKurs,
    FKursInhalt,
    FKursText: TForm;
    FKursArt,
    FKursID,
    FHolder:integer;
    FContainer:TForm;
    [async] procedure KursListe(AResponse: TXDataClientResponse; APfad:array of string); async;

  public
    property Container:TForm read FContainer write FContainer;
    property Kurs: TForm read FKurs write FKurs;
    property KursInhalt: TForm read FKursInhalt write FKursInhalt;
    property KursText : TForm read FKursText write FKursText;
    property KursArt : Integer read FKursArt write FKursArt;
//    property Holder:integer read FHolder write FHolder;
    [async] procedure Init(AKursID, AKursArt:integer); async;
    [async] procedure Refresh; async;
  protected procedure LoadDFMValues; override; end;


var
  frmKurse: TfrmKurse;

implementation
  Uses uDatenmodul, uMain, uME;
{$R *.dfm}

procedure TfrmKurse.Refresh;
Var
  Response: TXDataClientResponse;
  i,k:integer;
  j: TJ;
begin
  j := TJ.create(Await( Client.RawInvokeAsync('IDBService.KurseGet',[ FKursID, FHolder,100, FKursArt, Mainform.ME.Trainer])));

  for k := 0 to j.length-1 do
  begin

    j.index := k;

    for i := 0 to self.ComponentCount -1  do
    begin
      if self.components[i] is TfrKurse
      then begin
        if TfrKurse(self.components[i]).Kurs = J.Integer('ID')
        then begin
//          TfrKurse(self.components[i]).imgDone.visible    := (j.integer('DONE')> 0) and (j.integer('DONE') >= J.integer('SUB'));
          TfrKurse(self.components[i]).lblTitel.Html.Text := J.Value('HEADER') + IIF(J.integer('SUB') > 0,  '  (' + J.Value('DONE') + ' / ' + J.Value('SUB') + ')','');
//          showmessage('Kurse-refresh 4');
//          exit;
        end;
      end;

    end;

  end;

end;

procedure TfrmKurse.Init(AKursID, AKursArt:integer);
Var
  Response: TXDataClientResponse;
  j:TJ;
  sOpen:string;
  a:array of string;
  i:integer;
  sSQL:string;
begin
  lblTitel.Html.Text := '';

  if AKursArt =_KA_Lektion
  then TfrmME(FContainer).BreadCrumb.SetCaption(self, lblTitel, _KURSE, 'Lektionen')
  else TfrmME(FContainer).BreadCrumb.SetCaption(self, lblTitel, _KURSE, 'Anleitungen');

  FHolder  := Mainform.ME.ID;
  FKursArt := AKursArt;
  FKursID  := AKursID;

  mainform.Wait := 1;

  //Kurs direkt "anspringen" und den Pfad dahin merken
  if AKursID > 0
  then begin
    Setlength(a, 1);
    a[0] := AKursID.ToString;
    while true do
    begin

      j := TJ.create(await(datenmodul.EasySQL('select VOR from Kurse where id = ' + a[Length(a) -1])));
      if j.Value('VOR') > ''
      then begin
        Setlength(a,length(a) + 1);
        a[Length(a) -1] := j.Value('VOR');
      end
      else begin
        //letzten Vorgänger entfernen...
        setlength(a,length(a)-2);
        break;
      end;
    end;
  end
  else j := TJ.create;

  //Überschrift ("Lektionen" bzw. "Anleitungen") aus der Tabelle holen
  j.Response := await(datenmodul.EasySQL('select ID from Kurse Where VOR = 0 AND ART = ' + AKursArt.tostring + ' limit 1'));
  //alle Kurse zur Überschrift holen (entweder 'Lektionen' oder 'Anleitungen')
  Response := Await( Client.RawInvokeAsync('IDBService.KurseGet',[ j.Integer('ID'), FHolder,100, AKursArt, Mainform.ME.Trainer ]));

  freeAndNil(j);

  await(KursListe(Response, a));
  mainform.Wait := -1;

end;

procedure TfrmKurse.btnKursClick(Sender: TObject);

  procedure AfterCreate(AForm: TObject);
  begin
//    TfrmKurs(AForm).Parent := TWebControl(self).parent;
    TfrmKurs(AForm).Container := FContainer;
    TfrmKurs(AForm).init(TfrKurse(TControl(sender).owner).Kurs, TfrKurse(TControl(sender).owner).Pfad );
  end;

var
  o:THtmlDiv;
  f: TForm;
begin
  if Assigned(TfrmME(FContainer).Kurs)
  then begin
    f := TfrmME(FContainer).Kurs;
    freeAndNil(f);
  end;

  o := TfrmME(FContainer).BreadCrumb.DivToFront( _KURS);

  TfrmME(FContainer).Kurs := TfrmKurs.CreateNew(o.ElementID, @AfterCreate);
end;

procedure TfrmKurse.KursListe(AResponse: TXDataClientResponse; APfad:array of string);
Var
  j,j2:TJ;
  f: TfrKurse;
  i:integer;
  s:string;
  el: TJSHTMLElement;
  Res: TXDataClientResponse;
  FOpen:TFrKurse;
begin

  j := TJ.create(AResponse);

  divContent.BeginUpdate;
  for i := 0 to j.Length -1 do
  begin
    j.Index := i;

    f := TfrKurse.Create(divContent.Owner);
    f.Parent := divContent;
    f.LoadFromForm;
    f.imgHeader.url := await(datenmodul.GetMedia(J.Integer('MEDIEN_ID'),_SIZE2));
    f.imgHeader.ElementHandle.style.setProperty('object-position', j.Value('MEDIEN_POSITION'));
    f.lblTitel.OnClick := btnKursClick;

    TControl(f).ElementID := 'kurse' + J.Value('ID');
    TControl(f).elementPosition := epIgnore;
    TControl(f).elementClassName:= 'kurse_container';

    el := TJSHTMLElement(THTMLDiv(f).ElementHandle);
    el.style.removeProperty('height');
    el.style.removeProperty('width');

    f.Kurs := J.Integer('ID');

//    f.imgDone.visible    := (j.integer('DONE')> 0) and (j.integer('DONE') >= J.integer('SUB'));
    f.lblTitel.Html.Text := J.Value('HEADER') + '  (' + J.Value('DONE') + ' / ' + J.Value('SUB') + ')';
    f.lblText.Html.Text  := J.Value('TEXT');


    if (length(APfad) > 0) and (f.Kurs = StrToInt(APfad[length(APfad)-1]))
    then begin
       FOpen := f;
       SetLength(APfad, length(APfad)-1);
       FOpen.Pfad := APfad;
    end;

  end;

  if assigned(FOpen)
  then begin
    btnKursClick(fOpen.lblTitel);
  end;

  divContent.EndUpdate;

end;

procedure TfrmKurse.WebFormCreate(Sender: TObject);
var
  el: TJSHTMLElement;
begin
  inherited;
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.removeProperty('height');
end;


procedure TfrmKurse.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divContent := THTMLDiv.Create('divKurse');
  lblTitel := THTMLDiv.Create(Self);
  Client := TXDataWebClient.Create(Self);

  divContent.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frmKurse';
    Width := 830;
    Height := 420;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    divContent.SetParentComponent(Self);
    divContent.Name := 'divContent';
    divContent.Left := 8;
    divContent.Top := 0;
    divContent.Width := 797;
    divContent.Height := 385;
    divContent.ElementClassName := 'kurse_wrapper';
    divContent.HeightStyle := ssAuto;
    divContent.WidthStyle := ssAuto;
    divContent.ChildOrder := 1;
    divContent.ElementPosition := epIgnore;
    divContent.ElementFont := efCSS;
    divContent.Role := '';
    lblTitel.SetParentComponent(divContent);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 2;
    lblTitel.Top := 2;
    lblTitel.Width := 774;
    lblTitel.Height := 30;
    lblTitel.ElementClassName := 'me_headline';
    lblTitel.HeightStyle := ssAuto;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.ChildOrder := 2;
    lblTitel.ElementPosition := epIgnore;
    lblTitel.ElementFont := efCSS;
    lblTitel.HTML.BeginUpdate;
    try
      lblTitel.HTML.Clear;
      lblTitel.HTML.Add('KURS - '#220'BERSICHT');
    finally
      lblTitel.HTML.EndUpdate;
    end;
    lblTitel.Role := '';
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 32;
    Client.Top := 260;
  finally
    divContent.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.
