unit ufrHeader;

interface

uses
  System.SysUtils, System.Variants, System.Classes,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, WEBLib.Controls,
  WEBLib.WebCtrls, Vcl.Imaging.jpeg, WEBLib.ExtCtrls, JS, web, uTB, uGlobal,
  Vcl.Imaging.pngimage, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls,System.Types,
  WEBLib.ComCtrls, XData.Web.Client, Vcl.Menus, WEBLib.Menus, WEBLib.Buttons, ufrImgPosition;

type
  TfrHeader = class(TFrame)
    divWrapper: THTMLDiv;
    imgPageTitel: TImageControl;
    divUser: THTMLDiv;
    imgPageAvatar: TImageControl;
    lblUserName: THTMLDiv;
    divHeaderLeft: THTMLDiv;
    divHeaderRight: THTMLDiv;
    lblName: TLinkLabel;
    lblOrt: TLinkLabel;
    client: TXDataWebClient;
    btnFreunde: TButton;
    btnChronik: TButton;
    btnGruppen: TButton;
    btnBeitreten: TButton;
    btnAlben: TButton;
    btnMessanger: TButton;
    btnEvents: TButton;
    mnuCampus: TPopupMenu;
    miLektionen: TMenuItem;
    miTutorials: TMenuItem;
    btnEdit: TButton;
    divOnlinePunkt: THTMLDiv;
    divOnlineText: THTMLDiv;
    Timer: TTimer;
    btnAnfragen: TButton;
    btnAddFoto: TButton;
    procedure imgPageTitelClick(Sender: TObject);
    procedure imgPageAvatarClick(Sender: TObject);
    procedure imgPageTitelMouseEnter(Sender: TObject);
    procedure imgPageAvatarMouseEnter(Sender: TObject);
    procedure btnFreundeMouseEnter(Sender: TObject);
    procedure btnFreundeMouseLeave(Sender: TObject);
    procedure btnFreundeClick(Sender: TObject);
    procedure btnMessangerClick(Sender: TObject);
    procedure btnEditClick(Sender: TObject);
    [async] procedure TimerTimer(Sender: TObject); async;
    [async] procedure SetTitel(s: string); async;
    [async] procedure SetAvatar(s: string); async;
    [async] procedure miBeitretenClick(Sender: TObject); async;
    procedure btnAddFotoClick(Sender: TObject);
    procedure btnChronikClick(Sender: TObject);
    procedure btnGruppenClick(Sender: TObject);
    procedure btnAlbenClick(Sender: TObject);
    procedure btnEventsClick(Sender: TObject);
  protected
    procedure Loaded(); override;
  private
    FPosition:TfrImgPosition;
  public
    public constructor Create(AOwner:TComponent);Override;
    [async] procedure Init(AHolder:integer); async;
    procedure SetActiveButton(AButton:TButton);
    [async] procedure SetAnfrageBtn; async;
  protected procedure LoadDFMValues; override; end;

implementation
   Uses uDatenmodul, uMain, uSlider, uMedienAuswahl, uGruppenEdit, uSelection;

{$R *.dfm}

procedure TfrHeader.miBeitretenClick(Sender: TObject);
//Reaktion auf Klick auf miBeitreten aus Sicht des angemeldeten Users.
//miBeitreten.Tag wird beim Initialisieren abhänging vom Freundschaft-Status
//zwischen Holder und User gesetzt
var
  sFrage:string;
begin
//showmessage('beitreten');
  case btnBeitreten.tag of
    _FSA_Add:     begin
                    if Mainform.Meins
                    then TfrmSelection.Gruppe(Mainform.Holder.ID)
                    else begin
                      //ich möchte einer Gruppe beitreten bzw. eine Freundschaftsanfrage stellen
                      if Mainform.Holder.Status = 1 //Ohne Rückfrage Anfrage annehmen (offene Gruppe)
                      then begin
                        await(datenmodul.EasyExec('Insert into friends (STATUS, EMPFAENGER, SENDER, ANGENOMMEN) Values (1,' + Mainform.Holder.ID.toString + ',' + Mainform.ME.ID.toString + ',''' + FormatDateTime(_DATETIME_SAVE, Now()) + ''')'));
                        if Mainform.Holder.Typ = _UT_GRUPPE
                        then Meldung('Herzlich willkommen in der Gruppe ' + mainform.Holder.Nickname)
                        else Meldung('Du bist jetzt mit ' + mainform.Holder.Nickname + ' befreundet...');
                      end
                      else begin //Angefragter muß die Anfrage bestätigen...  geschlossene Gruppe
                        if Mainform.Holder.Holder <> Mainform.ME.ID
                        then begin
                          await(datenmodul.EasyExec('Insert into friends (EMPFAENGER, SENDER) Values (' + Mainform.Holder.ID.toString + ',' + Mainform.ME.ID.toString + ')'));
                          if Mainform.Holder.Typ = _UT_GRUPPE
                          then Meldung('Deine Anfrage wurde an den Administrator dieser Gruppe weitergeleitet.')
                          else Meldung('Deine Freundschaftsanfrage wurde an ' + mainform.Holder.Nickname + ' weitergeleitet...');

                          Anfragemail(_ET_Freundschaftsanfrage, Mainform.Holder.ID.ToString, Mainform.Holder.NAME, Mainform.Holder.VORNAME, Mainform.Holder.EMail, Mainform.Holder.Nickname, '', '');

                        end;
                      end;
                    end;
                  end;

    _FSA_Pending: begin //Die Anfrage wurde noch nicht bestätigt -> ich ziehe sie zurück
                    if aWait(Frage('Bist du sicher, dass du deine Anfrage zurückziehen möchtest?')) = mrYes
                    then begin
                      await(datenmodul.EasyExec('Delete from friends ' +
                                    ' WHERE (EMPFAENGER  = ' + Mainform.Holder.ID.toString + ' AND SENDER = ' + Mainform.ME.ID.toString + ')' +
                                    '    OR (SENDER = ' + Mainform.Holder.ID.toString + ' AND EMPFAENGER  = ' + Mainform.ME.ID.toString + ')'));
                      Meldung('Deine Anfrage wurde zurückgenommen...');
                    end;
                  end;

    _FSA_Commit: begin //Die Anfrage wurde an mich gestellt, ich habe noch nicht bestätigt -> ich bestätige
                    await(datenmodul.EasyExec('Update friends Set STATUS = 1 WHERE EMPFAENGER = ' + Mainform.ME.ID.toString + ' AND SENDER = ' + Mainform.Holder.ID.toString ));
                    if Mainform.Holder.Typ = _UT_GRUPPE
                    then Meldung('Herzlich willkommen in der Gruppe ' + mainform.Holder.Nickname)
                    else Meldung('Du bist jetzt mit ' + mainform.Holder.Nickname + ' befreundet...');
                 end;

    _FSA_Drop:    begin //Anfrage ist bestätigt -> Ich möchte austreten
                    if Mainform.Holder.Holder <> mainform.ME.id
                    then begin

                      if Mainform.Holder.Typ = _UT_GRUPPE
                      then sFrage := 'Bist du sicher, dass du die Gruppe ' + Mainform.Holder.Nickname +' verlassen möchtest?'
                      else sFrage := 'Bist du sicher, dass du nicht mehr mit ' + Mainform.Holder.Nickname + ' befreundet sein möchtest?';

                      if aWait(Frage(sFrage)) = mrYes
                      then begin

                        await(datenmodul.EasyExec('Delete from friends ' +
                                      ' WHERE (EMPFAENGER  = ' + Mainform.Holder.ID.toString + ' AND SENDER = ' + Mainform.ME.ID.toString + ')' +
                                      '    OR (SENDER = ' + Mainform.Holder.ID.toString + ' AND EMPFAENGER  = ' + Mainform.ME.ID.toString + ')'));
                        if Mainform.Holder.Typ = _UT_GRUPPE
                        then Meldung('Du bist jetzt nicht mehr Mitglied dieser Gruppe.')
                        else Meldung('Du bist jetzt nicht mehr mit ' + mainform.Holder.Nickname + ' befreundet...');
                      end;
                    end
                    else begin
                      await(Meldung('Als Admin dieser Gruppe und kannst du sie nicht verlassen...'));
                      abort;
                    end;
                  end;
  end;

  Mainform.HolderID := Mainform.Holder.ID;

end;

procedure TfrHeader.btnMessangerClick(Sender: TObject);
var
  pt: TPoint;
  LButton: TButton;

begin
//  LButton := Sender as TWebButton;
//
//  if Assigned(LButton) then
//  begin
//    //pt := self.clienttoscreen(Point(LButton.left,LButton.top));
//    pt.Y := pt.Y + LButton.height;
//    mnuCampus.popup(LButton.Parent.Left + LButton.Left, LButton.Parent.Top +  LButton.Top + LButton.Height)
//  end;
//  pt := TWebButton(Sender).ClientToScreen(Point(0,0));
//  pt.X := pt.X;// + TWebButton(Sender).width;
//  pt.Y := pt.Y + (TWebButton(sender).Height);
//  mnuFreunde.popup(pt.x,pt.y)

//  if (not assigned(FChats))
//  then FChats := TChats.create(self, ChatClient.HostName, ChatClient.Port);
//
//  FChats.Add(Mainform.Holder.Id, Mainform.ME.Id);


end;

procedure TfrHeader.btnAddFotoClick(Sender: TObject);
begin
  TfrmMedienAuswahl.Fotos(Mainform.Holder.id, mainform.ME.id, Mainform.ME.Album, 0, 0, 0, 0, nil, 2);
end;

procedure TfrHeader.btnAlbenClick(Sender: TObject);
begin
  OpenURL(Mainform.ME.ID.ToString, Mainform.Holder.ID.ToString, _call_ME,  '6','0');
end;

procedure TfrHeader.btnChronikClick(Sender: TObject);
begin
  OpenURL(Mainform.ME.ID.ToString, Mainform.Holder.ID.ToString, _call_ME,  '1','0');
end;

procedure TfrHeader.btnEditClick(Sender: TObject);
begin
  TfrmGruppenEdit.exec( Mainform.Holder.ID, Mainform.Holder.typ,
                        Mainform.Holder.id, Mainform.ME.id,
                        Mainform.Holder.Album , nil);
end;

procedure TfrHeader.btnEventsClick(Sender: TObject);
begin
  OpenURL(Mainform.ME.ID.ToString, Mainform.Holder.ID.ToString, _call_ME,  '3','0');
end;

procedure TfrHeader.btnFreundeClick(Sender: TObject);
begin
  if sender = btnAnfragen
  then OpenURL(Mainform.ME.ID.ToString, Mainform.Holder.ID.ToString, _call_ME,  '8','0')
  else OpenURL(Mainform.ME.ID.ToString, Mainform.Holder.ID.ToString, _call_ME,  '7','0');
end;

procedure TfrHeader.btnGruppenClick(Sender: TObject);
begin
  OpenURL(Mainform.ME.ID.ToString, Mainform.Holder.ID.ToString, _call_ME,  '2','0');
end;

procedure TfrHeader.btnFreundeMouseEnter(Sender: TObject);
begin
  TButton(sender).ElementHandle.style.setProperty('color','var(--color_underline)');
end;

procedure TfrHeader.btnFreundeMouseLeave(Sender: TObject);
begin
  TButton(sender).ElementHandle.style.removeProperty('color');
end;

constructor TfrHeader.Create(AOwner: TComponent);
begin
inherited;
end;

procedure TfrHeader.SetActiveButton(AButton:TButton);
begin
   SetAnfrageBtn;

   btnChronik.ElementClassName  := 'header_btn_inactive';
   btnAlben.ElementClassName    := 'header_btn_inactive';
   btnFreunde.ElementClassName  := 'header_btn_inactive';
   btnMessanger.ElementClassName:= 'header_btn_inactive';
   btnGruppen.ElementClassName  := 'header_btn_inactive';
   btnEvents.ElementClassName   := 'header_btn_inactive';
   btnEdit.ElementClassName     := 'header_btn_inactive';

   if AButton <> nil
   then AButton.ElementClassName := 'header_btn_active';

end;

procedure TfrHeader.SetTitel(s: string);
begin
  if Mainform.Meins
  then begin
    await(datenmodul.EasyEXEC('update User set TITEL_MEDIEN_ID = ' + s + ' where ID = ' + Mainform.Holder.ID.tostring));
    mainform.Holder.Titel := s.ToInteger;
    imgPageTitel.URL := datenmodul.Connection.URL + '/DBService/GetImage?Mediaid='+ s +'&Size=1';

    Mainform.Holder.Album := TJ.create(await(datenmodul.EasySQL('Select ALBUM_ID FROM User where ID = ' + Mainform.Holder.ID.tostring))).integer('ALBUM_ID');
  end;
end;

procedure TfrHeader.TimerTimer(Sender: TObject);
Var
  j:TJ;
begin
  if not mainform.Meins
  then begin
    j := TJ.create(await(datenmodul.EasySQL('Select LOGOUT from Login left join user on user.LOGIN_ID = Login.ID where user.ID = ' + Mainform.Holder.id.ToString )));
    divOnlineText.Visible := isOnline(J.value('LOGOUT'));
    j.Free;
  end
  else begin
    SetAnfrageBtn;
    divOnlineText.Visible := false;
  end;
end;

procedure TfrHeader.SetAvatar(s: string);
begin
  if Mainform.Meins
  then begin
    await(datenmodul.EasyEXEC('update User set AVATAR_MEDIEN_ID = ' + s + ' where ID = ' + Mainform.Holder.ID.tostring));

    mainform.ME.Avatar := s.ToInteger;
    imgPageAvatar.URL  := await(Datenmodul.GetMedia(s.tointeger,1));
    Mainform.ME.Album := TJ.create(await(datenmodul.EasySQL('Select ALBUM_ID FROM User where ID = ' + Mainform.Holder.ID.tostring))).integer('ALBUM_ID');
  end;
end;

procedure TfrHeader.imgPageAvatarClick(Sender: TObject);
begin
  if Mainform.Meins
  then TfrmMedienAuswahl.Fotos(Mainform.Holder.ID, Mainform.ME.id, Mainform.ME.Album,0, 0, 0, 0,@SetAvatar, 2);
end;

procedure TfrHeader.imgPageAvatarMouseEnter(Sender: TObject);
begin
  if Mainform.Meins
  then begin
    if assigned(FPosition)
    then FPosition.Free;

     FPosition := TfrImgPosition.show(self, divUser,  imgPageAvatar, 'USER', 'AVATAR_MEDIEN_POSITION', Mainform.Holder.ID.tostring);
  end;
end;

procedure TfrHeader.imgPageTitelClick(Sender: TObject);
begin
  if Mainform.Meins
  then TfrmMedienAuswahl.Fotos(Mainform.Holder.ID, Mainform.Holder.id, Mainform.Holder.Album,0, 0, 0,0, @SetTitel, 2);
end;

procedure TfrHeader.imgPageTitelMouseEnter(Sender: TObject);
begin
  if Mainform.Meins
  then begin
    if assigned(FPosition)
    then FPosition.Free;

    FPosition := TfrImgPosition.show(self, divWrapper, imgPageTitel, 'USER', 'TITEL_MEDIEN_POSITION', Mainform.Holder.ID.tostring);
  end;
end;

procedure TfrHeader.Init(AHolder:integer);
Var
  el: TJSHTMLElement;
  j:TJ;
begin
  divWrapper.Visible := false;
  case mainform.Holder.typ of
    _UT_Gruppe: begin
                  //lblName.Caption := '<a href="User=21" >' + mainform.Holder.Nickname + '</a>';
                  lblName.Caption := mainform.Holder.Nickname;
                  lblOrt.Caption  := mainform.Holder.About;
                  btnFreunde.Caption := 'Mitglieder';

                  //Status abhänging von der Verbindung zwischen ME und Holder
                  btnBeitreten.Tag := await(Connected(AHolder, Mainform.ME.ID));
                  case btnBeitreten.Tag of
                    _FSA_Add    : begin
                                    if Mainform.Meins
                                    then btnBeitreten.Caption := 'Mitglieder einladen'
                                    else btnBeitreten.Caption := 'Gruppe beitreten';
                                  end;
                    _FSA_Pending: btnBeitreten.Caption := 'Anfrage ausstehend';
                    _FSA_Drop   : btnBeitreten.Caption := 'Gruppe verlassen';
                  end;

//                  mnuFriends.BeginUpdate;
                  btnBeitreten.Visible := mainform.ME.ID <> AHolder ;
                  btnGruppen.Visible := false;
//                  mnuLeft.EndUpdate;
                end;
    _UT_Event : begin
                  lblName.Caption := mainform.Holder.Nickname;
                  lblOrt.Caption  := mainform.Holder.About;
                  btnFreunde.Caption := 'Teilnehmer';

                  //Status abhänging von der Verbindung zwischen ME und Holder
                  btnBeitreten.Tag := await(Connected(AHolder, Mainform.ME.ID));
                  case btnBeitreten.Tag of
                    _FSA_Add    : btnBeitreten.Caption := 'teilnehmen';
                    _FSA_Pending: btnBeitreten.Caption := 'Anfrage ausstehend';
                    _FSA_Drop   : btnBeitreten.Caption := 'nicht mehr teilnehmen';
                  end;

//                  mnuLeft.BeginUpdate;
                  btnBeitreten.Visible := mainform.ME.ID <> AHolder ;
                  btnGruppen.Visible := false;
//                  mnuLeft.EndUpdate;
                end
    else        begin
                  lblName.Caption := mainform.Holder.Nickname;
                  lblOrt.Caption  := mainform.Holder.Ort + ' ' + mainform.Holder.BLand;

                  //Status abhänging von der Verbindung zwischen ME und Holder
                  btnBeitreten.Tag := await(Connected(AHolder, Mainform.ME.ID));

                  case btnBeitreten.Tag of
                    _FSA_ADD    : btnBeitreten.Caption := 'als Freund hinzufügen';
                    _FSA_Pending: btnBeitreten.Caption := 'Anfrage ausstehend';
                    _FSA_Commit : btnBeitreten.Caption := 'Anfrage bestätigen';
                    _FSA_Drop   : btnBeitreten.Caption := 'als Freund entfernen';
                  end;

//                  mnuLeft.BeginUpdate;
                  btnFreunde.Caption := 'Freunde';
                  btnBeitreten.Visible := (btnBeitreten.Tag <> -1);
                  btnGruppen.visible := Mainform.Meins;
//                  mnuLeft.EndUpdate;
                end;
  end;

  btnEdit.Visible := ((mainform.Holder.typ = _UT_Gruppe) OR
                      (mainform.Holder.typ = _UT_EVENT)) AND
                      (mainform.Meins);

  Timer.Interval := mainform.AutoRefresh;
  Timer.Enabled:=true;
  timertimer(nil);

//  mnuRight.BeginUpdate;
  btnMessanger.visible := Mainform.Meins;
  btnEvents.visible    := Mainform.Meins;
//  mnuRight.EndUpdate;

  imgPageAvatar.URL := await(Datenmodul.GetMedia(mainform.Holder.Avatar, _SIZE2));
  imgPageAvatar.ElementHandle.style.setProperty('object-position',mainform.Holder.AvatarPos);
  mainform.SetBlur(imgPageAvatar, Mainform.Holder.ID, Mainform.ME.HideFotos  );
//
  imgPageTitel.URL  := await(Datenmodul.GetMedia(mainform.Holder.Titel, _SIZE1));
  imgPageTitel.ElementHandle.style.setProperty('object-position',mainform.Holder.TitelPos);
  mainform.SetBlur(imgPageTitel, Mainform.Holder.ID, Mainform.ME.HideFotos);

  el := TJSHTMLElement(lblName.ElementHandle);
  el.style.removeProperty('top');
  el.style.removeProperty('left');
  el.style.removeProperty('position');

  el := TJSHTMLElement(lblOrt.ElementHandle);
  el.style.removeProperty('top');
  el.style.removeProperty('left');
  el.style.removeProperty('position');

  divWrapper.Visible := true;
end;

procedure TfrHeader.SetAnfrageBtn;
Var
  j:TJ;
begin
  btnAnfragen.visible := false;
  if Mainform.Meins
  then begin
    j:=TJ.create(await(datenmodul.EasySQL('Select count(ID) as ANZ from friends where Status = 0 AND EMPFAENGER = ' + Mainform.Holder.ID.ToString)));
    if j.Integer('ANZ') > 0
    then begin
      btnAnfragen.Caption := 'Anfragen (' + j.Value('ANZ') + ')';
      btnAnfragen.visible := true;
    end;
  end;
end;

procedure TfrHeader.Loaded();
var
  el: TJSHTMLElement;
begin
  inherited;
  TControl(self).ElementPosition := epIgnore;
  TControl(self).ElementID := 'frHeader';
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.SetProperty('background-color', 'var(--color_background)');
  el.style.setProperty('height','400px');
  el.style.setProperty('overflow','visible');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-style');
  el.style.removeProperty('font-size');

  el := TJSHTMLElement(lblName.ElementHandle);
  el.style.removeProperty('top');
  el.style.removeProperty('left');
  el.style.removeProperty('position');

  el := TJSHTMLElement(lblOrt.ElementHandle);
  el.style.removeProperty('top');
  el.style.removeProperty('left');
  el.style.removeProperty('position');
end;

procedure TfrHeader.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divWrapper := THTMLDiv.Create('divheader');
  imgPageTitel := TImageControl.Create('header_image');
  divUser := THTMLDiv.Create('divheader_user');
  imgPageAvatar := TImageControl.Create(Self);
  lblUserName := THTMLDiv.Create(Self);
  lblName := TLinkLabel.Create(Self);
  lblOrt := TLinkLabel.Create(Self);
  divOnlinePunkt := THTMLDiv.Create(Self);
  divHeaderLeft := THTMLDiv.Create('divHeaderLeft');
  btnFreunde := TButton.Create(Self);
  btnChronik := TButton.Create(Self);
  btnGruppen := TButton.Create(Self);
  btnBeitreten := TButton.Create(Self);
  btnEdit := TButton.Create(Self);
  btnAnfragen := TButton.Create(Self);
  divHeaderRight := THTMLDiv.Create('divHeaderRight');
  btnAlben := TButton.Create(Self);
  btnMessanger := TButton.Create(Self);
  btnEvents := TButton.Create(Self);
  btnAddFoto := TButton.Create(Self);
  divOnlineText := THTMLDiv.Create(Self);
  client := TXDataWebClient.Create(Self);
  mnuCampus := TPopupMenu.Create(Self);
  miLektionen := TMenuItem.Create(Self);
  miTutorials := TMenuItem.Create(Self);
  Timer := TTimer.Create(Self);

  divWrapper.BeforeLoadDFMValues;
  imgPageTitel.BeforeLoadDFMValues;
  divUser.BeforeLoadDFMValues;
  imgPageAvatar.BeforeLoadDFMValues;
  lblUserName.BeforeLoadDFMValues;
  lblName.BeforeLoadDFMValues;
  lblOrt.BeforeLoadDFMValues;
  divOnlinePunkt.BeforeLoadDFMValues;
  divHeaderLeft.BeforeLoadDFMValues;
  btnFreunde.BeforeLoadDFMValues;
  btnChronik.BeforeLoadDFMValues;
  btnGruppen.BeforeLoadDFMValues;
  btnBeitreten.BeforeLoadDFMValues;
  btnEdit.BeforeLoadDFMValues;
  btnAnfragen.BeforeLoadDFMValues;
  divHeaderRight.BeforeLoadDFMValues;
  btnAlben.BeforeLoadDFMValues;
  btnMessanger.BeforeLoadDFMValues;
  btnEvents.BeforeLoadDFMValues;
  btnAddFoto.BeforeLoadDFMValues;
  divOnlineText.BeforeLoadDFMValues;
  client.BeforeLoadDFMValues;
  mnuCampus.BeforeLoadDFMValues;
  miLektionen.BeforeLoadDFMValues;
  miTutorials.BeforeLoadDFMValues;
  Timer.BeforeLoadDFMValues;
  try
    Name := 'frHeader';
    Left := 0;
    Top := 0;
    Width := 1187;
    Height := 338;
    TabOrder := 0;
    divWrapper.SetParentComponent(Self);
    divWrapper.Name := 'divWrapper';
    divWrapper.Left := 3;
    divWrapper.Top := 0;
    divWrapper.Width := 1118;
    divWrapper.Height := 313;
    divWrapper.HeightStyle := ssAuto;
    divWrapper.WidthStyle := ssAuto;
    divWrapper.ChildOrder := 1;
    divWrapper.ElementPosition := epRelative;
    divWrapper.ElementFont := efCSS;
    divWrapper.Role := '';
    divWrapper.Visible := False;
    imgPageTitel.SetParentComponent(divWrapper);
    imgPageTitel.Name := 'imgPageTitel';
    imgPageTitel.Left := 366;
    imgPageTitel.Top := 0;
    imgPageTitel.Width := 372;
    imgPageTitel.Height := 121;
    imgPageTitel.ElementClassName := 'header_image';
    imgPageTitel.HeightStyle := ssAuto;
    imgPageTitel.WidthStyle := ssAuto;
    imgPageTitel.HeightPercent := 100.000000000000000000;
    imgPageTitel.WidthPercent := 100.000000000000000000;
    imgPageTitel.ChildOrder := 1;
    imgPageTitel.ElementFont := efCSS;
    imgPageTitel.ElementPosition := epIgnore;
    SetEvent(imgPageTitel, Self, 'OnClick', 'imgPageTitelClick');
    SetEvent(imgPageTitel, Self, 'OnMouseEnter', 'imgPageTitelMouseEnter');
    divUser.SetParentComponent(divWrapper);
    divUser.Name := 'divUser';
    divUser.Left := 465;
    divUser.Top := 113;
    divUser.Width := 185;
    divUser.Height := 178;
    divUser.ElementClassName := 'header_user';
    divUser.HeightStyle := ssAuto;
    divUser.WidthStyle := ssAuto;
    divUser.ChildOrder := 1;
    divUser.ElementPosition := epIgnore;
    divUser.ElementFont := efCSS;
    divUser.HTML.BeginUpdate;
    try
      divUser.HTML.Clear;
      divUser.HTML.Add('');
    finally
      divUser.HTML.EndUpdate;
    end;
    divUser.Role := '';
    imgPageAvatar.SetParentComponent(divUser);
    imgPageAvatar.Name := 'imgPageAvatar';
    imgPageAvatar.Left := 49;
    imgPageAvatar.Top := 3;
    imgPageAvatar.Width := 100;
    imgPageAvatar.Height := 57;
    imgPageAvatar.ElementClassName := 'header_avatar';
    imgPageAvatar.HeightStyle := ssAuto;
    imgPageAvatar.WidthStyle := ssAuto;
    imgPageAvatar.HeightPercent := 100.000000000000000000;
    imgPageAvatar.WidthPercent := 100.000000000000000000;
    imgPageAvatar.ElementFont := efCSS;
    imgPageAvatar.ElementPosition := epIgnore;
    SetEvent(imgPageAvatar, Self, 'OnClick', 'imgPageAvatarClick');
    SetEvent(imgPageAvatar, Self, 'OnMouseEnter', 'imgPageAvatarMouseEnter');
    lblUserName.SetParentComponent(divUser);
    lblUserName.Name := 'lblUserName';
    lblUserName.Left := 16;
    lblUserName.Top := 66;
    lblUserName.Width := 161;
    lblUserName.Height := 103;
    lblUserName.ElementClassName := 'header_user_name';
    lblUserName.HeightStyle := ssAuto;
    lblUserName.WidthStyle := ssAuto;
    lblUserName.ChildOrder := 3;
    lblUserName.ElementPosition := epIgnore;
    lblUserName.ElementFont := efCSS;
    lblUserName.HTML.BeginUpdate;
    try
      lblUserName.HTML.Clear;
      lblUserName.HTML.Add('');
    finally
      lblUserName.HTML.EndUpdate;
    end;
    lblUserName.Role := '';
    lblName.SetParentComponent(lblUserName);
    lblName.Name := 'lblName';
    lblName.Left := 3;
    lblName.Top := 17;
    lblName.Width := 126;
    lblName.Height := 25;
    lblName.Alignment := taCenter;
    lblName.AutoSize := False;
    lblName.ElementClassName := 'header_user_link';
    lblName.ElementFont := efCSS;
    lblName.HeightStyle := ssAuto;
    lblName.WidthStyle := ssAuto;
    lblName.HeightPercent := 100.000000000000000000;
    lblName.WidthPercent := 100.000000000000000000;
    lblName.Caption := 'linklabel';
    lblOrt.SetParentComponent(lblUserName);
    lblOrt.Name := 'lblOrt';
    lblOrt.Left := 3;
    lblOrt.Top := 64;
    lblOrt.Width := 155;
    lblOrt.Height := 36;
    lblOrt.Alignment := taCenter;
    lblOrt.AutoSize := False;
    lblOrt.ElementClassName := 'header_user_link';
    lblOrt.ElementFont := efCSS;
    lblOrt.WordWrap := True;
    lblOrt.HeightStyle := ssAuto;
    lblOrt.WidthStyle := ssAuto;
    lblOrt.HeightPercent := 100.000000000000000000;
    lblOrt.WidthPercent := 100.000000000000000000;
    lblOrt.Caption := 'Gelsenkirchen, NRW';
    divOnlinePunkt.SetParentComponent(divUser);
    divOnlinePunkt.Name := 'divOnlinePunkt';
    divOnlinePunkt.Left := 82;
    divOnlinePunkt.Top := 14;
    divOnlinePunkt.Width := 100;
    divOnlinePunkt.Height := 41;
    divOnlinePunkt.ElementClassName := 'header-online-punkt';
    divOnlinePunkt.HeightStyle := ssAuto;
    divOnlinePunkt.WidthStyle := ssAuto;
    divOnlinePunkt.ChildOrder := 4;
    divOnlinePunkt.ElementPosition := epIgnore;
    divOnlinePunkt.ElementFont := efCSS;
    divOnlinePunkt.HTML.BeginUpdate;
    try
      divOnlinePunkt.HTML.Clear;
      divOnlinePunkt.HTML.Add('');
    finally
      divOnlinePunkt.HTML.EndUpdate;
    end;
    divOnlinePunkt.Role := '';
    divOnlinePunkt.Visible := False;
    divHeaderLeft.SetParentComponent(divWrapper);
    divHeaderLeft.Name := 'divHeaderLeft';
    divHeaderLeft.Left := 32;
    divHeaderLeft.Top := 146;
    divHeaderLeft.Width := 348;
    divHeaderLeft.Height := 145;
    divHeaderLeft.ElementClassName := 'header_menu_left';
    divHeaderLeft.HeightStyle := ssAuto;
    divHeaderLeft.WidthStyle := ssAuto;
    divHeaderLeft.ChildOrder := 3;
    divHeaderLeft.ElementPosition := epIgnore;
    divHeaderLeft.ElementFont := efCSS;
    divHeaderLeft.Role := '';
    btnFreunde.SetParentComponent(divHeaderLeft);
    btnFreunde.Name := 'btnFreunde';
    btnFreunde.Left := 0;
    btnFreunde.Top := 13;
    btnFreunde.Width := 96;
    btnFreunde.Height := 25;
    btnFreunde.Caption := 'Freunde';
    btnFreunde.ElementClassName := 'header_btn_inactive';
    btnFreunde.ElementFont := efCSS;
    btnFreunde.ElementPosition := epIgnore;
    btnFreunde.HeightStyle := ssAuto;
    btnFreunde.HeightPercent := 100.000000000000000000;
    btnFreunde.WidthStyle := ssAuto;
    btnFreunde.WidthPercent := 100.000000000000000000;
    SetEvent(btnFreunde, Self, 'OnClick', 'btnFreundeClick');
    SetEvent(btnFreunde, Self, 'OnMouseEnter', 'btnFreundeMouseEnter');
    SetEvent(btnFreunde, Self, 'OnMouseLeave', 'btnFreundeMouseLeave');
    btnChronik.SetParentComponent(divHeaderLeft);
    btnChronik.Name := 'btnChronik';
    btnChronik.Left := 249;
    btnChronik.Top := 24;
    btnChronik.Width := 96;
    btnChronik.Height := 25;
    btnChronik.Caption := 'Chronik';
    btnChronik.ChildOrder := 1;
    btnChronik.ElementClassName := 'header_btn_inactive';
    btnChronik.ElementFont := efCSS;
    btnChronik.ElementPosition := epIgnore;
    btnChronik.HeightStyle := ssAuto;
    btnChronik.HeightPercent := 100.000000000000000000;
    btnChronik.WidthStyle := ssAuto;
    btnChronik.WidthPercent := 100.000000000000000000;
    SetEvent(btnChronik, Self, 'OnClick', 'btnChronikClick');
    SetEvent(btnChronik, Self, 'OnMouseEnter', 'btnFreundeMouseEnter');
    SetEvent(btnChronik, Self, 'OnMouseLeave', 'btnFreundeMouseLeave');
    btnGruppen.SetParentComponent(divHeaderLeft);
    btnGruppen.Name := 'btnGruppen';
    btnGruppen.Left := 249;
    btnGruppen.Top := 55;
    btnGruppen.Width := 96;
    btnGruppen.Height := 25;
    btnGruppen.Caption := 'Gruppen';
    btnGruppen.ChildOrder := 3;
    btnGruppen.ElementClassName := 'header_btn_inactive';
    btnGruppen.ElementFont := efCSS;
    btnGruppen.ElementPosition := epIgnore;
    btnGruppen.HeightStyle := ssAuto;
    btnGruppen.HeightPercent := 100.000000000000000000;
    btnGruppen.WidthStyle := ssAuto;
    btnGruppen.WidthPercent := 100.000000000000000000;
    SetEvent(btnGruppen, Self, 'OnClick', 'btnGruppenClick');
    SetEvent(btnGruppen, Self, 'OnMouseEnter', 'btnFreundeMouseEnter');
    SetEvent(btnGruppen, Self, 'OnMouseLeave', 'btnFreundeMouseLeave');
    btnBeitreten.SetParentComponent(divHeaderLeft);
    btnBeitreten.Name := 'btnBeitreten';
    btnBeitreten.Left := 249;
    btnBeitreten.Top := 86;
    btnBeitreten.Width := 96;
    btnBeitreten.Height := 25;
    btnBeitreten.Caption := 'Beitreten';
    btnBeitreten.ChildOrder := 3;
    btnBeitreten.ElementClassName := 'header_btn_inactive';
    btnBeitreten.ElementFont := efCSS;
    btnBeitreten.ElementPosition := epIgnore;
    btnBeitreten.HeightStyle := ssAuto;
    btnBeitreten.HeightPercent := 100.000000000000000000;
    btnBeitreten.WidthStyle := ssAuto;
    btnBeitreten.WidthPercent := 100.000000000000000000;
    SetEvent(btnBeitreten, Self, 'OnClick', 'miBeitretenClick');
    SetEvent(btnBeitreten, Self, 'OnMouseEnter', 'btnFreundeMouseEnter');
    SetEvent(btnBeitreten, Self, 'OnMouseLeave', 'btnFreundeMouseLeave');
    btnEdit.SetParentComponent(divHeaderLeft);
    btnEdit.Name := 'btnEdit';
    btnEdit.Left := 249;
    btnEdit.Top := 117;
    btnEdit.Width := 96;
    btnEdit.Height := 25;
    btnEdit.Caption := 'bearbeiten';
    btnEdit.ChildOrder := 5;
    btnEdit.ElementClassName := 'header_btn_inactive';
    btnEdit.ElementFont := efCSS;
    btnEdit.ElementPosition := epIgnore;
    btnEdit.HeightStyle := ssAuto;
    btnEdit.HeightPercent := 100.000000000000000000;
    btnEdit.WidthStyle := ssAuto;
    btnEdit.WidthPercent := 100.000000000000000000;
    SetEvent(btnEdit, Self, 'OnClick', 'btnEditClick');
    SetEvent(btnEdit, Self, 'OnMouseEnter', 'btnFreundeMouseEnter');
    SetEvent(btnEdit, Self, 'OnMouseLeave', 'btnFreundeMouseLeave');
    btnAnfragen.SetParentComponent(divHeaderLeft);
    btnAnfragen.Name := 'btnAnfragen';
    btnAnfragen.Left := 0;
    btnAnfragen.Top := 44;
    btnAnfragen.Width := 96;
    btnAnfragen.Height := 25;
    btnAnfragen.Caption := 'Anfragen';
    btnAnfragen.ChildOrder := 1;
    btnAnfragen.ElementClassName := 'header-btn-badge';
    btnAnfragen.ElementFont := efCSS;
    btnAnfragen.ElementPosition := epIgnore;
    btnAnfragen.HeightStyle := ssAuto;
    btnAnfragen.HeightPercent := 100.000000000000000000;
    btnAnfragen.WidthStyle := ssAuto;
    btnAnfragen.WidthPercent := 100.000000000000000000;
    SetEvent(btnAnfragen, Self, 'OnClick', 'btnFreundeClick');
    SetEvent(btnAnfragen, Self, 'OnMouseEnter', 'btnFreundeMouseEnter');
    SetEvent(btnAnfragen, Self, 'OnMouseLeave', 'btnFreundeMouseLeave');
    divHeaderRight.SetParentComponent(divWrapper);
    divHeaderRight.Name := 'divHeaderRight';
    divHeaderRight.Left := 732;
    divHeaderRight.Top := 146;
    divHeaderRight.Width := 352;
    divHeaderRight.Height := 145;
    divHeaderRight.ElementClassName := 'header_menu_right';
    divHeaderRight.HeightStyle := ssAuto;
    divHeaderRight.WidthStyle := ssAuto;
    divHeaderRight.ChildOrder := 3;
    divHeaderRight.ElementPosition := epIgnore;
    divHeaderRight.ElementFont := efCSS;
    divHeaderRight.Role := '';
    btnAlben.SetParentComponent(divHeaderRight);
    btnAlben.Name := 'btnAlben';
    btnAlben.Left := 3;
    btnAlben.Top := 65;
    btnAlben.Width := 96;
    btnAlben.Height := 25;
    btnAlben.Caption := 'Alben';
    btnAlben.ElementClassName := 'header_btn_inactive';
    btnAlben.ElementFont := efCSS;
    btnAlben.ElementPosition := epIgnore;
    btnAlben.HeightStyle := ssAuto;
    btnAlben.HeightPercent := 100.000000000000000000;
    btnAlben.WidthStyle := ssAuto;
    btnAlben.WidthPercent := 100.000000000000000000;
    SetEvent(btnAlben, Self, 'OnClick', 'btnAlbenClick');
    SetEvent(btnAlben, Self, 'OnMouseEnter', 'btnFreundeMouseEnter');
    SetEvent(btnAlben, Self, 'OnMouseLeave', 'btnFreundeMouseLeave');
    btnMessanger.SetParentComponent(divHeaderRight);
    btnMessanger.Name := 'btnMessanger';
    btnMessanger.Left := 3;
    btnMessanger.Top := 34;
    btnMessanger.Width := 96;
    btnMessanger.Height := 25;
    btnMessanger.Caption := 'Nachricht schreiben';
    btnMessanger.ChildOrder := 1;
    btnMessanger.ElementClassName := 'header_btn_inactive';
    btnMessanger.ElementFont := efCSS;
    btnMessanger.ElementPosition := epIgnore;
    btnMessanger.HeightStyle := ssAuto;
    btnMessanger.HeightPercent := 100.000000000000000000;
    btnMessanger.PopupMenu := mnuCampus;
    btnMessanger.WidthStyle := ssAuto;
    btnMessanger.WidthPercent := 100.000000000000000000;
    SetEvent(btnMessanger, Self, 'OnClick', 'btnMessangerClick');
    SetEvent(btnMessanger, Self, 'OnMouseEnter', 'btnFreundeMouseEnter');
    SetEvent(btnMessanger, Self, 'OnMouseLeave', 'btnFreundeMouseLeave');
    btnEvents.SetParentComponent(divHeaderRight);
    btnEvents.Name := 'btnEvents';
    btnEvents.Left := 3;
    btnEvents.Top := 3;
    btnEvents.Width := 96;
    btnEvents.Height := 25;
    btnEvents.Caption := 'Veranstaltungen';
    btnEvents.ChildOrder := 2;
    btnEvents.ElementClassName := 'header_btn_inactive';
    btnEvents.ElementFont := efCSS;
    btnEvents.ElementPosition := epIgnore;
    btnEvents.HeightStyle := ssAuto;
    btnEvents.HeightPercent := 100.000000000000000000;
    btnEvents.WidthStyle := ssAuto;
    btnEvents.WidthPercent := 100.000000000000000000;
    SetEvent(btnEvents, Self, 'OnClick', 'btnEventsClick');
    SetEvent(btnEvents, Self, 'OnMouseEnter', 'btnFreundeMouseEnter');
    SetEvent(btnEvents, Self, 'OnMouseLeave', 'btnFreundeMouseLeave');
    btnAddFoto.SetParentComponent(divHeaderRight);
    btnAddFoto.Name := 'btnAddFoto';
    btnAddFoto.Left := 3;
    btnAddFoto.Top := 96;
    btnAddFoto.Width := 96;
    btnAddFoto.Height := 25;
    btnAddFoto.Caption := 'Foto hinzuf'#252'gen';
    btnAddFoto.ChildOrder := 3;
    btnAddFoto.ElementClassName := 'header_btn_inactive';
    btnAddFoto.ElementFont := efCSS;
    btnAddFoto.ElementPosition := epIgnore;
    btnAddFoto.HeightStyle := ssAuto;
    btnAddFoto.HeightPercent := 100.000000000000000000;
    btnAddFoto.WidthStyle := ssAuto;
    btnAddFoto.WidthPercent := 100.000000000000000000;
    SetEvent(btnAddFoto, Self, 'OnClick', 'btnAddFotoClick');
    SetEvent(btnAddFoto, Self, 'OnMouseEnter', 'btnFreundeMouseEnter');
    SetEvent(btnAddFoto, Self, 'OnMouseLeave', 'btnFreundeMouseLeave');
    divOnlineText.SetParentComponent(divWrapper);
    divOnlineText.Name := 'divOnlineText';
    divOnlineText.Left := 940;
    divOnlineText.Top := 32;
    divOnlineText.Width := 100;
    divOnlineText.Height := 41;
    divOnlineText.ElementClassName := 'header-online-text';
    divOnlineText.HeightStyle := ssAuto;
    divOnlineText.WidthStyle := ssAuto;
    divOnlineText.ChildOrder := 4;
    divOnlineText.ElementPosition := epRelative;
    divOnlineText.ElementFont := efCSS;
    divOnlineText.HTML.BeginUpdate;
    try
      divOnlineText.HTML.Clear;
      divOnlineText.HTML.Add('online');
    finally
      divOnlineText.HTML.EndUpdate;
    end;
    divOnlineText.Role := '';
    divOnlineText.Visible := False;
    client.SetParentComponent(Self);
    client.Name := 'client';
    client.Connection := DatenModul.Connection;
    client.Left := 51;
    client.Top := 59;
    mnuCampus.SetParentComponent(Self);
    mnuCampus.Name := 'mnuCampus';
    mnuCampus.Appearance.HamburgerMenu.Caption := 'Menu';
    mnuCampus.Appearance.SubmenuIndicator := '&#9658;';
    mnuCampus.ElementClassName := 'menu_sub';
    mnuCampus.Font.Charset := DEFAULT_CHARSET;
    mnuCampus.Font.Color := clWindowText;
    mnuCampus.Font.Height := -12;
    mnuCampus.Font.Name := 'Segoe UI';
    mnuCampus.Font.Style := [];
    mnuCampus.Left := 763;
    mnuCampus.Top := 61;
    miLektionen.SetParentComponent(mnuCampus);
    miLektionen.Name := 'miLektionen';
    miLektionen.Caption := 'Lektionen';
    miTutorials.SetParentComponent(mnuCampus);
    miTutorials.Name := 'miTutorials';
    miTutorials.Caption := 'Anleitungen';
    Timer.SetParentComponent(Self);
    Timer.Name := 'Timer';
    SetEvent(Timer, Self, 'OnTimer', 'TimerTimer');
    Timer.Left := 195;
    Timer.Top := 91;
  finally
    divWrapper.AfterLoadDFMValues;
    imgPageTitel.AfterLoadDFMValues;
    divUser.AfterLoadDFMValues;
    imgPageAvatar.AfterLoadDFMValues;
    lblUserName.AfterLoadDFMValues;
    lblName.AfterLoadDFMValues;
    lblOrt.AfterLoadDFMValues;
    divOnlinePunkt.AfterLoadDFMValues;
    divHeaderLeft.AfterLoadDFMValues;
    btnFreunde.AfterLoadDFMValues;
    btnChronik.AfterLoadDFMValues;
    btnGruppen.AfterLoadDFMValues;
    btnBeitreten.AfterLoadDFMValues;
    btnEdit.AfterLoadDFMValues;
    btnAnfragen.AfterLoadDFMValues;
    divHeaderRight.AfterLoadDFMValues;
    btnAlben.AfterLoadDFMValues;
    btnMessanger.AfterLoadDFMValues;
    btnEvents.AfterLoadDFMValues;
    btnAddFoto.AfterLoadDFMValues;
    divOnlineText.AfterLoadDFMValues;
    client.AfterLoadDFMValues;
    mnuCampus.AfterLoadDFMValues;
    miLektionen.AfterLoadDFMValues;
    miTutorials.AfterLoadDFMValues;
    Timer.AfterLoadDFMValues;
  end;
end;

end.
