unit uTipAuswahl;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  WEBLib.WebCtrls, uTb, XData.Web.Client, WEBLib.TreeNodes, Vcl.ComCtrls,
  WEBLib.ComCtrls;

type
  TfrmTipAuswahl = class(TForm)
    Client: TXDataWebClient;
    WebHTMLDiv2: THTMLDiv;
    divFooter: THTMLDiv;
    btnOK: TButton;
    WebHTMLDiv5: THTMLDiv;
    wtv: TTreeView;
    WebHTMLDiv3: THTMLDiv;
    reTitel: TRichEdit;
    reText2: TRichEdit;
    WebHTMLDiv4: THTMLDiv;
    btnSave: TButton;
    btnNew: TButton;
    WebButton1: TButton;
    reText: TMemo;
    procedure btnOKClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormCreate(Sender: TObject);
    [async] procedure wtvClickNode(Sender: TObject; ANode: TTreeNode); async;
    procedure btnNewClick(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    [async] procedure btnSaveClick(Sender: TObject); async;
    procedure reText2Change(Sender: TObject);
  private
    FCallback:TCallback;
    FContainer:THtmlDiv;
    FID:integer;
  public
    procedure Callback(s:string);
    [async] class function Exec(ACallback:TCallback): integer; async;
  protected procedure LoadDFMValues; override; end;

var
  frmTipAuswahl: TfrmTipAuswahl;
    WebHTMLDiv1: THTMLDiv;
    divFooter: THTMLDiv;
    WebButton1: TButton;

implementation
   uses uMain, uDatenmodul;
{$R *.dfm}

class function TfrmTipAuswahl.Exec(ACallback:TCallback): integer;

  [async] procedure AfterCreate(AForm: TObject); async;
  Var
    Response: TXDataClientResponse;
    s:string;
    J:TJ;
    i:integer;
    tn,sn,wn: TTreeNode;
    l1,l2,l3,l4,l5:TTreeNode;

  begin
    mainform.Wait := 1;
    TfrmTipAuswahl(AForm).ElementHandle.style.removeProperty('position');
    TfrmTipAuswahl(AForm).Parent   := Mainform;

    TfrmTipAuswahl(AForm).wtv.BeginUpdate;

    j := TJ.create(await(datenmodul.EasySQL('Select * from wiki order by TITEL')));
    for i := 0 to j.Length - 1 do
    begin
      j.index := i;
      l1 := TfrmTipAuswahl(AForm).wtv.Items.Add(nil, j.value('TITEL'));
      l1.Data := TNodeData.Create(j.integer('ID'));
    end;

    TfrmTipAuswahl(AForm).wtv.EndUpdate;

    mainform.Wait := -1;
  end;

Var
  frm:TfrmTipAuswahl;
  FConti:THtmlDiv;
begin
  FConti := THtmlDiv.Create(Mainform);
  FConti.Parent := Mainform;
  FConti.ElementClassName := 'editor_transparent';
  FConti.HeightStyle:= ssAuto;
  FConti.WidthStyle := ssAuto;
  FConti.ElementFont := efCSS;
  FConti.ElementHandle.style.removeProperty('height');
  frm := TfrmTipAuswahl.CreateNew(FConti.ElementID, @AfterCreate);
  frm.FContainer := FConti;
  frm.FCallback  := ACallback;
end;

procedure TfrmTipAuswahl.Callback(s:string);
begin
  //
end;

procedure TfrmTipAuswahl.btnNewClick(Sender: TObject);
begin
  FID := 0;
  reTitel.Lines.Text := '';
  reText.Lines.Text  := '';
end;

procedure TfrmTipAuswahl.btnOKClick(Sender: TObject);
begin
  if assigned(FCallback)
  then FCallback(FId.tostring);
  close;
end;

procedure TfrmTipAuswahl.btnSaveClick(Sender: TObject);
begin
  if FID > 0
  then await(datenmodul.EasyExec('Update Wiki ' +
                                 ' Set TITEL = ''' + reTitel.GetContent + ''', ' +
                                 '      TEXT = ''' + reText.Text + ''' ' +
                                 ' Where ID = ' + FId.tostring))
  else FID := await(datenmodul.EasyInsert('INSERT INTO Wiki (TITEL, TEXT) ' +
                ' VALUES ( ''' + reTitel.GetContent + ''', ''' + reText.text + ''')'));
  if assigned(FCallback)
  then FCallback(FId.tostring);
  close;

end;

procedure TfrmTipAuswahl.WebButton1Click(Sender: TObject);
begin
  close;
end;

procedure TfrmTipAuswahl.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
 FContainer.free;
end;

procedure TfrmTipAuswahl.WebFormCreate(Sender: TObject);
Var
  el : TJSHTMLElement;
begin
  TControl(self).elementfont := efCSS;

  TControl(reTitel).elementPosition := epIgnore;
  el := TJSHTMLElement(THTMLDiv(reTitel).ElementHandle);
  el.style.removeProperty('position');
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-size');
  el.style.removeProperty('font-style');

  TControl(reText).elementPosition := epIgnore;
  el := TJSHTMLElement(THTMLDiv(reText).ElementHandle);
  el.style.removeProperty('position');
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-size');
  el.style.removeProperty('font-style');


end;

procedure TfrmTipAuswahl.wtvClickNode(Sender: TObject; ANode: TTreeNode);
Var
  j:TJ;
  NodeData: TNodeData;
  idx:integer;
begin
  NodeData := TNodeData(ANode.Data);
  idx := NodeData.IndexHint;
  j := TJ.create(await(datenmodul.EasySQL('SELECT * from WIKI where ID = ' + idx.ToString)));
  FId := j.Integer('ID');
  reTitel.Lines.Text := j.Value('TITEL');
  reText.Lines.Text := j.Value('TEXT');
end;

procedure TfrmTipAuswahl.reText2Change(Sender: TObject);
begin
//  if Clipboard.HasFormat(CF_TEXT) then
//  begin
//    reTExt.Lines.Text := LowerCase(Clipboard.AsText);
//    Action := True;
//  end;
end;

procedure TfrmTipAuswahl.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  WebHTMLDiv5 := THTMLDiv.Create(Self);
  wtv := TTreeView.Create(Self);
  WebHTMLDiv3 := THTMLDiv.Create(Self);
  reTitel := TRichEdit.Create(Self);
  reText2 := TRichEdit.Create(Self);
  WebHTMLDiv4 := THTMLDiv.Create(Self);
  btnSave := TButton.Create(Self);
  btnNew := TButton.Create(Self);
  reText := TMemo.Create(Self);
  divFooter := THTMLDiv.Create(Self);
  btnOK := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);
  Client := TXDataWebClient.Create(Self);

  WebHTMLDiv1.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  WebHTMLDiv5.BeforeLoadDFMValues;
  wtv.BeforeLoadDFMValues;
  WebHTMLDiv3.BeforeLoadDFMValues;
  reTitel.BeforeLoadDFMValues;
  reText2.BeforeLoadDFMValues;
  WebHTMLDiv4.BeforeLoadDFMValues;
  btnSave.BeforeLoadDFMValues;
  btnNew.BeforeLoadDFMValues;
  reText.BeforeLoadDFMValues;
  divFooter.BeforeLoadDFMValues;
  btnOK.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frmTipAuswahl';
    Width := 1191;
    Height := 739;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 8;
    WebHTMLDiv1.Top := 8;
    WebHTMLDiv1.Width := 841;
    WebHTMLDiv1.Height := 649;
    WebHTMLDiv1.ElementClassName := 'tip-auswahl-container';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv2.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 32;
    WebHTMLDiv2.Top := 27;
    WebHTMLDiv2.Width := 582;
    WebHTMLDiv2.Height := 297;
    WebHTMLDiv2.ElementClassName := 'tip-container';
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ChildOrder := 1;
    WebHTMLDiv2.ElementPosition := epIgnore;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.Role := '';
    WebHTMLDiv5.SetParentComponent(WebHTMLDiv2);
    WebHTMLDiv5.Name := 'WebHTMLDiv5';
    WebHTMLDiv5.Left := 9;
    WebHTMLDiv5.Top := 16;
    WebHTMLDiv5.Width := 297;
    WebHTMLDiv5.Height := 265;
    WebHTMLDiv5.ElementClassName := 'tip-left';
    WebHTMLDiv5.HeightStyle := ssAuto;
    WebHTMLDiv5.WidthStyle := ssAuto;
    WebHTMLDiv5.ElementPosition := epIgnore;
    WebHTMLDiv5.ElementFont := efCSS;
    WebHTMLDiv5.Role := '';
    wtv.SetParentComponent(WebHTMLDiv5);
    wtv.Name := 'wtv';
    wtv.Left := 3;
    wtv.Top := 35;
    wtv.Width := 122;
    wtv.Height := 144;
    wtv.ElementClassName := 'tree';
    wtv.ElementPosition := epRelative;
    wtv.ElementFont := efCSS;
    wtv.HeightStyle := ssAuto;
    wtv.HeightPercent := 100.000000000000000000;
    wtv.WidthPercent := 100.000000000000000000;
    SetEvent(wtv, Self, 'OnClickNode', 'wtvClickNode');
    WebHTMLDiv3.SetParentComponent(WebHTMLDiv2);
    WebHTMLDiv3.Name := 'WebHTMLDiv3';
    WebHTMLDiv3.Left := 312;
    WebHTMLDiv3.Top := 51;
    WebHTMLDiv3.Width := 273;
    WebHTMLDiv3.Height := 249;
    WebHTMLDiv3.ElementClassName := 'tip-right';
    WebHTMLDiv3.HeightStyle := ssAuto;
    WebHTMLDiv3.WidthStyle := ssAuto;
    WebHTMLDiv3.ChildOrder := 1;
    WebHTMLDiv3.ElementPosition := epIgnore;
    WebHTMLDiv3.ElementFont := efCSS;
    WebHTMLDiv3.Role := '';
    reTitel.SetParentComponent(WebHTMLDiv3);
    reTitel.Name := 'reTitel';
    reTitel.Left := 3;
    reTitel.Top := 3;
    reTitel.Width := 246;
    reTitel.Height := 46;
    reTitel.ElementClassName := 'kursedit_titel';
    reTitel.HeightStyle := ssAuto;
    reTitel.WidthStyle := ssAuto;
    reTitel.BorderStyle := bsSingle;
    reTitel.ChildOrder := 1;
    reTitel.Color := clWhite;
    reText2.SetParentComponent(WebHTMLDiv3);
    reText2.Name := 'reText2';
    reText2.Left := 192;
    reText2.Top := 128;
    reText2.Width := 57;
    reText2.Height := 59;
    reText2.ElementClassName := 'kursedit_input';
    reText2.HeightStyle := ssAuto;
    reText2.WidthStyle := ssAuto;
    reText2.BorderStyle := bsSingle;
    reText2.ChildOrder := 2;
    reText2.Color := clWhite;
    SetEvent(reText2, Self, 'OnChange', 'reText2Change');
    WebHTMLDiv4.SetParentComponent(WebHTMLDiv3);
    WebHTMLDiv4.Name := 'WebHTMLDiv4';
    WebHTMLDiv4.Left := 3;
    WebHTMLDiv4.Top := 193;
    WebHTMLDiv4.Width := 241;
    WebHTMLDiv4.Height := 41;
    WebHTMLDiv4.ElementClassName := 'medien-auswahl-button-div';
    WebHTMLDiv4.HeightStyle := ssAuto;
    WebHTMLDiv4.WidthStyle := ssAuto;
    WebHTMLDiv4.ChildOrder := 6;
    WebHTMLDiv4.ElementPosition := epRelative;
    WebHTMLDiv4.ElementFont := efCSS;
    WebHTMLDiv4.Role := '';
    btnSave.SetParentComponent(WebHTMLDiv4);
    btnSave.Name := 'btnSave';
    btnSave.Left := 111;
    btnSave.Top := 3;
    btnSave.Width := 96;
    btnSave.Height := 25;
    btnSave.Caption := 'Speichern';
    btnSave.ChildOrder := 1;
    btnSave.ElementClassName := 'start_button-lets-go';
    btnSave.ElementFont := efCSS;
    btnSave.ElementPosition := epIgnore;
    btnSave.HeightStyle := ssAuto;
    btnSave.HeightPercent := 100.000000000000000000;
    btnSave.WidthStyle := ssAuto;
    btnSave.WidthPercent := 100.000000000000000000;
    SetEvent(btnSave, Self, 'OnClick', 'btnSaveClick');
    btnNew.SetParentComponent(WebHTMLDiv4);
    btnNew.Name := 'btnNew';
    btnNew.Left := 9;
    btnNew.Top := 3;
    btnNew.Width := 96;
    btnNew.Height := 25;
    btnNew.Caption := 'Neu';
    btnNew.ElementClassName := 'start_button-lets-go';
    btnNew.ElementFont := efCSS;
    btnNew.ElementPosition := epIgnore;
    btnNew.HeightStyle := ssAuto;
    btnNew.HeightPercent := 100.000000000000000000;
    btnNew.WidthStyle := ssAuto;
    btnNew.WidthPercent := 100.000000000000000000;
    SetEvent(btnNew, Self, 'OnClick', 'btnNewClick');
    reText.SetParentComponent(WebHTMLDiv3);
    reText.Name := 'reText';
    reText.Left := 8;
    reText.Top := 56;
    reText.Width := 185;
    reText.Height := 89;
    reText.AutoSize := False;
    reText.ElementClassName := 'kursedit_input';
    reText.HeightPercent := 100.000000000000000000;
    reText.Lines.BeginUpdate;
    try
      reText.Lines.Clear;
      reText.Lines.Add('reText');
    finally
      reText.Lines.EndUpdate;
    end;
    reText.SelLength := 0;
    reText.SelStart := 0;
    reText.WidthPercent := 100.000000000000000000;
    divFooter.SetParentComponent(WebHTMLDiv1);
    divFooter.Name := 'divFooter';
    divFooter.Left := 32;
    divFooter.Top := 544;
    divFooter.Width := 582;
    divFooter.Height := 41;
    divFooter.ElementClassName := 'medien-auswahl-button-div';
    divFooter.HeightStyle := ssAuto;
    divFooter.WidthStyle := ssAuto;
    divFooter.ChildOrder := 6;
    divFooter.ElementPosition := epRelative;
    divFooter.ElementFont := efCSS;
    divFooter.Role := '';
    btnOK.SetParentComponent(divFooter);
    btnOK.Name := 'btnOK';
    btnOK.Left := 111;
    btnOK.Top := 3;
    btnOK.Width := 96;
    btnOK.Height := 25;
    btnOK.Caption := 'Ausw'#228'hlen';
    btnOK.ChildOrder := 1;
    btnOK.ElementClassName := 'start_button-lets-go';
    btnOK.ElementFont := efCSS;
    btnOK.ElementPosition := epIgnore;
    btnOK.HeightStyle := ssAuto;
    btnOK.HeightPercent := 100.000000000000000000;
    btnOK.WidthStyle := ssAuto;
    btnOK.WidthPercent := 100.000000000000000000;
    SetEvent(btnOK, Self, 'OnClick', 'btnOKClick');
    WebButton1.SetParentComponent(divFooter);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 213;
    WebButton1.Top := 3;
    WebButton1.Width := 96;
    WebButton1.Height := 25;
    WebButton1.Caption := 'Abbrechen';
    WebButton1.ChildOrder := 1;
    WebButton1.ElementClassName := 'start_button-lets-go';
    WebButton1.ElementFont := efCSS;
    WebButton1.ElementPosition := epIgnore;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthStyle := ssAuto;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Left := 216;
    Client.Top := 48;
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    WebHTMLDiv5.AfterLoadDFMValues;
    wtv.AfterLoadDFMValues;
    WebHTMLDiv3.AfterLoadDFMValues;
    reTitel.AfterLoadDFMValues;
    reText2.AfterLoadDFMValues;
    WebHTMLDiv4.AfterLoadDFMValues;
    btnSave.AfterLoadDFMValues;
    btnNew.AfterLoadDFMValues;
    reText.AfterLoadDFMValues;
    divFooter.AfterLoadDFMValues;
    btnOK.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.