unit ufrAngebot;

interface

uses
  System.SysUtils, System.Variants, System.Classes,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.StdCtrls,  uGlobal,
  WEBLib.StdCtrls, WEBLib.Controls, WEBLib.WebCtrls, uTB, System.DateUtils,
  WEBLib.Graphics, WEBLib.Forms, XData.Web.Client, JS, WEBLib.ExtCtrls;

type
  TfrAngebot = class(TFrame)
    divContainer: THTMLDiv;
    divTitel: THTMLDiv;
    divText: THTMLDiv;
    divPreis: THTMLDiv;
    WebHTMLDiv22: THTMLDiv;
    btnBuchen: TButton;
    Client: TXDataWebClient;

    [async] procedure GastRegisterEMail(aDaten:Array of string); async;
    [async] procedure btnBuchenClick(Sender: TObject); async;
  private
    FZahlung:string;
    FGotoLoginClick : TNotifyEvent;
  published
    property Zahlung : string read FZahlung write FZahlung;
    property GotoLoginClick : TNotifyEvent read FGotoLoginClick write FGotoLoginClick;
  protected procedure LoadDFMValues; override; end;

implementation
  uses uMain, uEdit, uDatenmodul;
{$R *.dfm}

procedure TfrAngebot.btnBuchenClick(Sender: TObject);

  [async]
  procedure Callback(a:array of string); async;
  var
    jSys, j:TJ;
    i:integer;
  begin

    for i := 0 to length(a) - 1 do
    begin
      if a[i] = ''
      then exit;
    end;

    jSys := TJ.create(await(datenmodul.EasySql('SELECT * from System')));
    setlength(a,5);
    a[3] := MilliSecondOfTheDay(Now()).tostring;

    a[4] := await(datenmodul.EasyInsert(
                     'insert into USER (GAST, RECHTE_ID, NAME, VORNAME, EMAIL, NICKNAME, ' +
                     '                  PASSWORD, AVATAR_MEDIEN_ID, TITEL_MEDIEN_ID) ' +
                     '  VALUES(1,' + jSys.Value('GAST_RECHTE_ID')  + ',' +
                               '''' + a[0] + ''',' +
                               '''' + a[1] + ''',' +
                               '''' + a[2] + ''',' +
                               '''' + a[1] + ' ' + copy(a[0],1,1) + '.' + ''',' +
                               '''' + a[3] + ''',' +
                               jSys.Value('AVATAR_MEDIEN_ID') + ',' +
                               jSys.Value('TITEL_MEDIEN_ID') + ')')).ToString;
    await(GastRegisterEMail(a));
    await(Meldung('Wir haben dir deine Anmeldedaten an deine E-Mail-Adresse geschickt.<br><br>Das kann einen Moment dauern.<br><br>Schaue ggfs. auch im Spam-Ordner nach. '));
  end;

Var
  x:string;
  a:array[0..2] of TFieldCollection;
begin
  if FZahlung > ''
  then begin

    x := FZahlung;
    asm
      window.open(x, '_blank');
    end;

    if assigned(FGotoLoginClick)
    then FGotoLoginClick(sender);
  end
  else begin

    a[0].Caption := 'Name';    a[0].Text := ''; a[0].Required:= true; a[0].Typ := 0;
    a[1].Caption := 'Vorname'; a[1].Text := ''; a[1].Required:= true; a[1].Typ := 0;
    a[2].Caption := 'E-Mail';  a[2].Text := ''; a[2].Required:= true; a[2].Typ := 1;

    TfrmEditor.Exec('Herzlich willkommen beim Segler-Portal.<br><br>Bitte fülle die Felder ' +
                    'vollständig aus und klicke dann auf abschicken. Wir senden Dir dann eine E-Mail ' +
                    'mit deinen Zugangsdaten...', 'Abschicken...', a, @Callback);
  end;

end;

procedure TfrAngebot.GastRegisterEMail(aDaten:Array of string);
var
  j : TJ;
  JSObj: TJSObject;
  JSString, sImage,
  sText:string;
begin
  sText := '8';
  JSString:= '{"EMAIL":   "' + aDaten[2] + '",' +
              '"VORNAME": "' + aDaten[1] + '",' +
              '"NAME": "'    + aDaten[0] + '",' +
              '"PASSWORT":"' + aDaten[3] + '",' +
              '"TEXT_ID": '  + sText     + ', ' +
              '"EVENT": "'   + ''        + '", '+
              '"LOGIN": "'   + ''        + '", '+
              '"URL": "'     + datenmodul.Connection.URL + '", '+
              '"ANHANG":  ""}';

  JSObj:=TJSJSON.parseObject(JSString);

  Await( Client.RawInvokeAsync( 'IDBService.SendEMail',[JSObj]));
end;

procedure TfrAngebot.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divContainer := THTMLDiv.Create(Self);
  divTitel := THTMLDiv.Create(Self);
  divText := THTMLDiv.Create(Self);
  divPreis := THTMLDiv.Create(Self);
  WebHTMLDiv22 := THTMLDiv.Create(Self);
  btnBuchen := TButton.Create(Self);
  Client := TXDataWebClient.Create(Self);

  divContainer.BeforeLoadDFMValues;
  divTitel.BeforeLoadDFMValues;
  divText.BeforeLoadDFMValues;
  divPreis.BeforeLoadDFMValues;
  WebHTMLDiv22.BeforeLoadDFMValues;
  btnBuchen.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frAngebot';
    Left := 0;
    Top := 0;
    Width := 369;
    Height := 329;
    AutoSize := True;
    TabOrder := 0;
    divContainer.SetParentComponent(Self);
    divContainer.Name := 'divContainer';
    divContainer.Left := 0;
    divContainer.Top := 0;
    divContainer.Width := 369;
    divContainer.Height := 329;
    divContainer.ElementClassName := 'start-paket-box';
    divContainer.HeightStyle := ssAuto;
    divContainer.WidthStyle := ssAuto;
    divContainer.ChildOrder := 1;
    divContainer.ElementPosition := epIgnore;
    divContainer.ElementFont := efCSS;
    divContainer.Role := '';
    divTitel.SetParentComponent(divContainer);
    divTitel.Name := 'divTitel';
    divTitel.Left := 3;
    divTitel.Top := 0;
    divTitel.Width := 366;
    divTitel.Height := 41;
    divTitel.ElementClassName := 'login_login';
    divTitel.HeightStyle := ssAuto;
    divTitel.WidthStyle := ssAuto;
    divTitel.ChildOrder := 1;
    divTitel.ElementPosition := epRelative;
    divTitel.ElementFont := efCSS;
    divTitel.HTML.BeginUpdate;
    try
      divTitel.HTML.Clear;
      divTitel.HTML.Add('Gast');
    finally
      divTitel.HTML.EndUpdate;
    end;
    divTitel.Role := '';
    divTitel.Visible := False;
    divText.SetParentComponent(divContainer);
    divText.Name := 'divText';
    divText.Left := 3;
    divText.Top := 47;
    divText.Width := 366;
    divText.Height := 146;
    divText.ElementClassName := 'start-preise-text';
    divText.HeightStyle := ssAuto;
    divText.WidthStyle := ssAuto;
    divText.ChildOrder := 2;
    divText.ElementPosition := epIgnore;
    divText.ElementFont := efCSS;
    divText.HTML.BeginUpdate;
    try
      divText.HTML.Clear;
      divText.HTML.Add('Freundschaftanfragen aktiv versenden<br>');
      divText.HTML.Add('Beitr'#228'ge erstellen<br>');
      divText.HTML.Add('Auf Veranstaltungen antworten<br>');
      divText.HTML.Add('Gruppen beitreten<br>');
      divText.HTML.Add('Threads erstellen<br>');
      divText.HTML.Add('An Umfragen teilnehmen<br><br>');
      divText.HTML.Add('Zugang zum Kurs<br>');
      divText.HTML.Add('<FONT size="9"><B>"Sicherheiteinweisung"</B></FONT>');
      divText.HTML.Add('');
      divText.HTML.Add('');
      divText.HTML.Add('');
    finally
      divText.HTML.EndUpdate;
    end;
    divText.Role := '';
    divPreis.SetParentComponent(divContainer);
    divPreis.Name := 'divPreis';
    divPreis.Left := 3;
    divPreis.Top := 193;
    divPreis.Width := 366;
    divPreis.Height := 90;
    divPreis.ElementClassName := 'start-preise-preis';
    divPreis.HeightStyle := ssAuto;
    divPreis.WidthStyle := ssAuto;
    divPreis.ChildOrder := 3;
    divPreis.ElementPosition := epIgnore;
    divPreis.ElementFont := efCSS;
    divPreis.HTML.BeginUpdate;
    try
      divPreis.HTML.Clear;
      divPreis.HTML.Add('');
      divPreis.HTML.Add('');
      divPreis.HTML.Add('');
      divPreis.HTML.Add('');
    finally
      divPreis.HTML.EndUpdate;
    end;
    divPreis.Role := '';
    WebHTMLDiv22.SetParentComponent(divContainer);
    WebHTMLDiv22.Name := 'WebHTMLDiv22';
    WebHTMLDiv22.Left := 3;
    WebHTMLDiv22.Top := 289;
    WebHTMLDiv22.Width := 366;
    WebHTMLDiv22.Height := 41;
    WebHTMLDiv22.ElementClassName := 'login_footer';
    WebHTMLDiv22.HeightStyle := ssAuto;
    WebHTMLDiv22.WidthStyle := ssAuto;
    WebHTMLDiv22.ChildOrder := 4;
    WebHTMLDiv22.ElementPosition := epIgnore;
    WebHTMLDiv22.ElementFont := efCSS;
    WebHTMLDiv22.Role := '';
    btnBuchen.SetParentComponent(WebHTMLDiv22);
    btnBuchen.Name := 'btnBuchen';
    btnBuchen.Left := 112;
    btnBuchen.Top := 0;
    btnBuchen.Width := 137;
    btnBuchen.Height := 33;
    btnBuchen.Caption := 'buchen';
    btnBuchen.ChildOrder := 4;
    btnBuchen.ElementClassName := 'start_button-lets-go';
    btnBuchen.ElementFont := efCSS;
    btnBuchen.ElementPosition := epIgnore;
    btnBuchen.HeightStyle := ssAuto;
    btnBuchen.HeightPercent := 100.000000000000000000;
    btnBuchen.WidthStyle := ssAuto;
    btnBuchen.WidthPercent := 100.000000000000000000;
    SetEvent(btnBuchen, Self, 'OnClick', 'btnBuchenClick');
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 99;
    Client.Top := 159;
  finally
    divContainer.AfterLoadDFMValues;
    divTitel.AfterLoadDFMValues;
    divText.AfterLoadDFMValues;
    divPreis.AfterLoadDFMValues;
    WebHTMLDiv22.AfterLoadDFMValues;
    btnBuchen.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.
