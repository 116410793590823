unit uMeBlogs;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Forms, WEBLib.WebCtrls, Vcl.Imaging.jpeg, WEBLib.ExtCtrls, WEBLib.Lists,
  WEBLib.TreeNodes, Vcl.ComCtrls, WEBLib.ComCtrls, XData.Web.Client, utb,
  uGlobal, ufrBlogThumb, Vcl.Imaging.pngimage, uBlog, ufrMyStuff, ufrSuchen;

type
  TfrmMEBlogs = class(TForm)
    WebHTMLDiv1: THTMLDiv;
    divWrapper: THTMLDiv;
    imgPageTitel: TImageControl;
    divMain: THTMLDiv;
    divLeft: THTMLDiv;
    divMECenter: THTMLDiv;
    divRight: THTMLDiv;
    Client: TXDataWebClient;
    divHeader: THTMLDiv;
    divBlogs: THTMLDiv;
    WebHTMLDiv2: THTMLDiv;
    btnTitel: TImageControl;
    lblTitel: THTMLDiv;
    divText: THTMLDiv;
    [async] procedure btnTitelClick(Sender: TObject); async;
    divMyStuff: THTMLDiv;
    frMyStuff1: TfrMyStuff;
    divHashTags: THTMLDiv;
    divNixDa: THTMLDiv;
    procedure WebFormCreate(Sender: TObject);
    procedure SetFilter(sender:TObject);
    function  CreateFilter:string;
    function  StringToWhere(const AString: string): string;
   private
    FJ:TJ;
    FBlog : TfrmBlog;
    FItems: Array of TfrBlogThumb;

    FMedienID, FBlogID,
    FMainID, FID, FBlogKat,
    FParent, FUser, FHolder :integer;
  public
    [async] procedure GotoNextBlog(a:array of string); async;
    [async] procedure NewBlog(s:string); async;
    [async] procedure ShowBlog(s:string); async;
    [async] procedure Auswahl(s:string); async;
    [async] procedure init(AID, AModul:integer; AFilter:string); async;
  protected procedure LoadDFMValues; override; end;

var
  frmMEBlogs: TfrmMEBlogs;

implementation
   uses  uDatenmodul, uMain, uBlogEdit;
{$R *.dfm}

function TfrmMEBlogs.StringToWhere(const AString: string): string;
var
  Parts: TArray<string>;
  i: Integer;
begin
  // Den String in Teile zerlegen
  Parts := AString.Split([',']);

  // Result initialisieren
  Result := '';

  for i := 0 to Length(Parts) - 1 do
  begin
    if Parts[i].ToInteger <> -1
    then Result := Result + Format(' AND (Blog.ID IN (SELECT Blog_ID FROM BlogX WHERE BlogKat_id = %s))', [Parts[i]]);
  end;
end;

procedure TfrmMEBlogs.init(AID, AModul:integer; AFilter:string);

  function StringToArray(const AString: string): TArray<Integer>;
  var
    Parts: TArray<string>;
    i: Integer;
  begin
    // Den String in Teile zerlegen
    Parts := AString.Split([',']);

    // Array-Größe anpassen
    SetLength(Result, Length(Parts));

    // Jeden Teilstring in eine Zahl umwandeln und im Ergebnis-Array speichern
    for i := 0 to High(Parts) do
      Result[i] := StrToInt(Parts[i]);
  end;

  [async]
  procedure AfterCreate(AForm: TObject); async;
  Var
    j:TJ;
  begin
    j := TJ.create(await(datenmodul.EasySQL('select HOLDER from Blog where ID = ' + AID.ToString)));
    divMyStuff.visible:= true;
    await(frMyStuff1.Init(j.integer('HOLDER'), 'Autor'));
    TfrmBlog(AForm).init(AID, imgPageTitel, @GotoNextBlog, false);
//    TfrmBlog(AForm).init(AID, imgPageTitel,'',0, false);
  end;

var
  j : TJ;
  i:integer;
  frm:TfrBlogThumb;
  cb:TCallback;
  sFilter, sSql:string;
  aChecked:TArray<integer>;
begin

  //zeigt eine Übersicht aller Blogs einer Kategorie an

  divMyStuff.visible:= false;

  if AModul = 1
  then begin
//showmessage('1: ' + FBlogKat.ToString + ' - ' + AID.tostring);

    mainform.Wait := 1;
    //"Kategorie-Infos" holen
    if AID > 0
    then j := TJ.create(await(datenmodul.EasySQL('select * from Blog Where LEVEL = 1 AND BLOGKAT_ID = ' + AID.ToString )))
    else j := TJ.create(await(datenmodul.EasySQL('select * from Blog limit 1')));

    imgPageTitel.URL := await(datenmodul.GetMedia(j.integer('MEDIEN_ID'), _Size1));

    FID     := j.integer('ID');
    FBlogKat:= j.integer('BLOGKAT_ID');
    FMainID := j.integer('MAIN_ID');
    FBlogID := j.integer('BLOG_ID');
    FParent := j.integer('ID');
//showmessage('3: ' + FBlogKat.ToString);

    lblTitel.HTML.Text := j.Value('HEADER');
    divText.HTML.Text := j.Value('TEXT');

    //Filter setzen

    sFilter := '((SICHTBAR_AB <= NOW()) or ' +
               ' (HOLDER = ' + Mainform.Holder.id.tostring + ')) AND ';

    //alle Blogs der Kategorie, abhänging vom Filter holen
    sSQL := 'select blog.* from Blog Where ' + sFilter + ' blog.Level = 2 AND blog.BLOGKAT_ID = ' + AID.ToString;

//    showmessage(sSQL);

    if AFilter > ''
    then begin
      //wenn Filter, dann nur die Blogs die dem Filter entsprechen
      sSQl := sSQl + StringToWhere(AFilter);
      aChecked := StringToArray(AFilter);
    end;
//showmessage('4: ' + sSQL);

    j := TJ.create(await(datenmodul.EasySQL( sSQL )));
    if j.hasValue
    then begin
//showmessage('5: ' + sSQL);
      divNixDa.Visible := false;
      setlength(FItems,j.Length-1);

      for i := 0 to j.Length-1 do
      begin
        j.Index := i;
        if j.integer('LEVEL') = 1
        then frm := TfrBlogThumb.add(divBlogs, j.integer('ID'), j.integer('VOR'), @Auswahl)
        else frm := TfrBlogThumb.add(divBlogs, j.integer('ID'), j.integer('ID'), @ShowBlog);

        frm.lblHeader.HTML.Text := j.Value('HEADER');
        frm.lblText.HTML.Text   := j.Value('TEXT');

        frm.img.URL := await(datenmodul.GetMedia(j.integer('MEDIEN_ID'), _Size2));;

        FItems[i] := frm;
      end;
    end
    else divNixDa.Visible := true;
//showmessage('6: ' + FBlogKat.ToString);

    FillHashTags(divHashTags, '0', FBlogKat.ToString, true,  aChecked, '', true, 0, @SetFilter);

    mainform.Wait := -1;
  end
  else begin //... der tatsächliche Blog

    if length(AFilter) > 0
    then aChecked := StringToArray(AFilter);

    j := TJ.create(await(datenmodul.EasySQL('select BLOGKAT_ID from Blog where ID = ' + AID.ToString)));
    FBlogKat := j.Integer('BLOGKAT_ID');
    FillHashTags(divHashTags, '0', FBlogKat.ToString, true,  aChecked, '', true, 0, @SetFilter);

    TfrmBlog.CreateNew(divMECenter.ElementID, @AfterCreate);
  end;

end;

function TfrmMEBlogs.CreateFilter:string;
var
  i,k:integer;
  s: String;
begin
 for i := 0 to divHashTags.ControlCount -1 do
 begin
   if (divHashTags.Controls[i] is THTMLDiv)
   then begin
     for k := 0 to THTMLDiv(divHashTags.Controls[i]).controlCount -1 do
     begin
      if THTMLDiv(divHashTags.Controls[i]).Controls[k] is TCheckBox
      then begin
        if TCheckBox(THTMLDiv(divHashTags.Controls[i]).Controls[k]).checked
        then s := s + ',' + TCheckBox(THTMLDiv(divHashTags.Controls[i]).Controls[k]).Tag.ToString;
     end;
   end;
  end;
 end;

 if pos(',',s) = 1
 then s := copy(s,2,length(s));

 result := s;

 //openURL(mainform.ME.ID.ToString, mainform.ME.ID.ToString, _call_Blogs, '1',FBlogKat.ToString, s)

end;

procedure TfrmMEBlogs.SetFilter(sender:TObject);
var
  s: String;
begin

 s := CreateFilter;
 openURL(mainform.ME.ID.ToString, mainform.ME.ID.ToString, _call_Blogs, '1',FBlogKat.ToString, s)

end;

procedure TfrmMEBlogs.Auswahl(s:string);
Var
  sFilter:string;
begin
  sFilter := CreateFilter;
  openURL(mainform.ME.ID.ToString, mainform.ME.ID.ToString, _call_Blogs, '1',s, sFilter)
end;

procedure TfrmMEBlogs.GotoNextBlog(a:array of string);
Var
  sSQl, sFilter:string;
  j:TJ;
begin
  sFilter := CreateFilter;
  sSQL := StringToWhere(sFilter);
  if a[1] = '+'
  then sSQL := 'select ID from Blog where ID > ' + a[0] + ' AND BLOGKAT_ID = ' + FBlogKat.ToString + sSQL + ' ORDER BY ID'
  else sSQL := 'select ID from Blog where ID < ' + a[0] + ' AND BLOGKAT_ID = ' + FBlogKat.ToString + sSQL + ' ORDER BY ID desc';

  j := TJ.create(await(datenmodul.EasySQL(sSQL)));

  openURL(Mainform.ME.ID.ToString, Mainform.Holder.ID.ToString, _Call_Blogs, '2', j.value('ID'), sFilter);
end;

procedure TfrmMEBlogs.ShowBlog(s:string);
Var
  sFilter:string;
begin
  sFilter := CreateFilter;
  openURL(Mainform.ME.ID.ToString, Mainform.Holder.ID.ToString, _Call_Blogs, '2',s, sFilter);
end;

procedure TfrmMEBlogs.WebFormCreate(Sender: TObject);
begin
  divMECenter.ElementHandle.style.removeProperty('height');
end;

procedure TfrmMEBlogs.btnTitelClick(Sender: TObject);
begin
  if Mainform.ME.Rechte.New[riBlog]
  then TfrmBlogEdit.Blog(mainform.Holder.ID, Mainform.ME.id, 0, FID, FBlogID, 0, '','', -1, 0, 0, 0,'',nil)
  else meldung('Aufgrund deines Mitgliedstatus kannst Du leider keinen neuen Blog anlegen...');
end;

procedure TfrmMEBlogs.NewBlog(s:string);
begin
  Showmessage('Neuer Blog - aktualisieren...');
end;


procedure TfrmMEBlogs.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  divWrapper := THTMLDiv.Create('divheader');
  imgPageTitel := TImageControl.Create('header_image');
  divMain := THTMLDiv.Create('divmain');
  divLeft := THTMLDiv.Create('blogleft');
  divMyStuff := THTMLDiv.Create(Self);
  frMyStuff1 := TfrMyStuff.Create(Self);
  divHashTags := THTMLDiv.Create(Self);
  divMECenter := THTMLDiv.Create(Self);
  divHeader := THTMLDiv.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  btnTitel := TImageControl.Create(Self);
  lblTitel := THTMLDiv.Create(Self);
  divText := THTMLDiv.Create(Self);
  divBlogs := THTMLDiv.Create(Self);
  divNixDa := THTMLDiv.Create(Self);
  divRight := THTMLDiv.Create('divright');
  Client := TXDataWebClient.Create(Self);

  frMyStuff1.LoadFromForm;
  WebHTMLDiv1.BeforeLoadDFMValues;
  divWrapper.BeforeLoadDFMValues;
  imgPageTitel.BeforeLoadDFMValues;
  divMain.BeforeLoadDFMValues;
  divLeft.BeforeLoadDFMValues;
  divMyStuff.BeforeLoadDFMValues;
  frMyStuff1.BeforeLoadDFMValues;
  frMyStuff1.divWrapper.BeforeLoadDFMValues;
  frMyStuff1.divHeadline.BeforeLoadDFMValues;
  divHashTags.BeforeLoadDFMValues;
  divMECenter.BeforeLoadDFMValues;
  divHeader.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  btnTitel.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  divText.BeforeLoadDFMValues;
  divBlogs.BeforeLoadDFMValues;
  divNixDa.BeforeLoadDFMValues;
  divRight.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frmMEBlogs';
    Width := 1216;
    Height := 971;
    ElementFont := efCSS;
    Visible := True;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 8;
    WebHTMLDiv1.Top := 8;
    WebHTMLDiv1.Width := 1193;
    WebHTMLDiv1.Height := 217;
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epRelative;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    divWrapper.SetParentComponent(WebHTMLDiv1);
    divWrapper.Name := 'divWrapper';
    divWrapper.Left := 21;
    divWrapper.Top := 16;
    divWrapper.Width := 1118;
    divWrapper.Height := 177;
    divWrapper.HeightStyle := ssAuto;
    divWrapper.WidthStyle := ssAuto;
    divWrapper.ChildOrder := 1;
    divWrapper.ElementPosition := epRelative;
    divWrapper.ElementFont := efCSS;
    divWrapper.Role := '';
    imgPageTitel.SetParentComponent(divWrapper);
    imgPageTitel.Name := 'imgPageTitel';
    imgPageTitel.Left := 366;
    imgPageTitel.Top := 24;
    imgPageTitel.Width := 372;
    imgPageTitel.Height := 121;
    imgPageTitel.ElementClassName := 'header_image';
    imgPageTitel.HeightStyle := ssAuto;
    imgPageTitel.WidthStyle := ssAuto;
    imgPageTitel.HeightPercent := 100.000000000000000000;
    imgPageTitel.WidthPercent := 100.000000000000000000;
    imgPageTitel.ChildOrder := 1;
    imgPageTitel.ElementFont := efCSS;
    imgPageTitel.ElementPosition := epIgnore;
    divMain.SetParentComponent(Self);
    divMain.Name := 'divMain';
    divMain.Left := 8;
    divMain.Top := 231;
    divMain.Width := 1193;
    divMain.Height := 730;
    divMain.ElementClassName := 'me_main';
    divMain.HeightStyle := ssAuto;
    divMain.WidthStyle := ssAuto;
    divMain.ChildOrder := 1;
    divMain.ElementPosition := epIgnore;
    divMain.ElementFont := efCSS;
    divMain.Role := '';
    divLeft.SetParentComponent(divMain);
    divLeft.Name := 'divLeft';
    divLeft.Left := 21;
    divLeft.Top := 21;
    divLeft.Width := 316;
    divLeft.Height := 668;
    divLeft.ElementClassName := 'blog-left';
    divLeft.HeightStyle := ssAuto;
    divLeft.WidthStyle := ssAuto;
    divLeft.ElementPosition := epIgnore;
    divLeft.ElementFont := efCSS;
    divLeft.Role := '';
    divMyStuff.SetParentComponent(divLeft);
    divMyStuff.Name := 'divMyStuff';
    divMyStuff.Left := 13;
    divMyStuff.Top := 19;
    divMyStuff.Width := 292;
    divMyStuff.Height := 382;
    divMyStuff.HeightStyle := ssAuto;
    divMyStuff.WidthStyle := ssAuto;
    divMyStuff.ChildOrder := 1;
    divMyStuff.ElementPosition := epIgnore;
    divMyStuff.ElementFont := efCSS;
    divMyStuff.Role := '';
    frMyStuff1.SetParentComponent(divMyStuff);
    frMyStuff1.Name := 'frMyStuff1';
    frMyStuff1.Left := -27;
    frMyStuff1.Top := 0;
    frMyStuff1.Width := 303;
    frMyStuff1.Height := 932;
    frMyStuff1.TabOrder := 0;
    frMyStuff1.divHeadline.Left := 21;
    frMyStuff1.divHeadline.Top := 130;
    divHashTags.SetParentComponent(divLeft);
    divHashTags.Name := 'divHashTags';
    divHashTags.Left := 66;
    divHashTags.Top := 504;
    divHashTags.Width := 209;
    divHashTags.Height := 140;
    divHashTags.ElementClassName := 'blog-hashtag-div-left';
    divHashTags.HeightStyle := ssAuto;
    divHashTags.WidthStyle := ssAuto;
    divHashTags.ChildOrder := 1;
    divHashTags.ElementPosition := epIgnore;
    divHashTags.ElementFont := efCSS;
    divHashTags.Role := '';
    divMECenter.SetParentComponent(divMain);
    divMECenter.Name := 'divMECenter';
    divMECenter.Left := 343;
    divMECenter.Top := 21;
    divMECenter.Width := 754;
    divMECenter.Height := 665;
    divMECenter.ElementClassName := 'blog-center';
    divMECenter.HeightStyle := ssAuto;
    divMECenter.WidthStyle := ssAuto;
    divMECenter.ChildOrder := 1;
    divMECenter.ElementPosition := epIgnore;
    divMECenter.ElementFont := efCSS;
    divMECenter.Role := '';
    divHeader.SetParentComponent(divMECenter);
    divHeader.Name := 'divHeader';
    divHeader.Left := 24;
    divHeader.Top := 19;
    divHeader.Width := 602;
    divHeader.Height := 158;
    divHeader.HeightStyle := ssAuto;
    divHeader.WidthStyle := ssAuto;
    divHeader.ElementPosition := epIgnore;
    divHeader.ElementFont := efCSS;
    divHeader.HTML.BeginUpdate;
    try
      divHeader.HTML.Clear;
      divHeader.HTML.Add('');
    finally
      divHeader.HTML.EndUpdate;
    end;
    divHeader.Role := '';
    WebHTMLDiv2.SetParentComponent(divHeader);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 3;
    WebHTMLDiv2.Top := 3;
    WebHTMLDiv2.Width := 602;
    WebHTMLDiv2.Height := 54;
    WebHTMLDiv2.ElementClassName := 'blog-headline';
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ChildOrder := 2;
    WebHTMLDiv2.ElementPosition := epIgnore;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.HTML.BeginUpdate;
    try
      WebHTMLDiv2.HTML.Clear;
      WebHTMLDiv2.HTML.Add('');
    finally
      WebHTMLDiv2.HTML.EndUpdate;
    end;
    WebHTMLDiv2.Role := '';
    btnTitel.SetParentComponent(WebHTMLDiv2);
    btnTitel.Name := 'btnTitel';
    btnTitel.Left := 558;
    btnTitel.Top := -1;
    btnTitel.Width := 38;
    btnTitel.Height := 44;
    btnTitel.Hint := 'hier klicken um neuen Beitrag zu schreiben';
    btnTitel.ElementClassName := 'alben_menu_btn';
    btnTitel.HeightStyle := ssAuto;
    btnTitel.WidthStyle := ssAuto;
    btnTitel.HeightPercent := 100.000000000000000000;
    btnTitel.WidthPercent := 100.000000000000000000;
    btnTitel.Anchors := [];
    btnTitel.ChildOrder := 2;
    btnTitel.ElementFont := efCSS;
    btnTitel.ElementPosition := epIgnore;
    btnTitel.ShowHint := True;
    SetEvent(btnTitel, Self, 'OnClick', 'btnTitelClick');
    btnTitel.Picture.LoadFromFile('uMeBlogs.WebHTMLDiv2.btnTitel.Picture.png');
    lblTitel.SetParentComponent(WebHTMLDiv2);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 16;
    lblTitel.Top := 3;
    lblTitel.Width := 100;
    lblTitel.Height := 41;
    lblTitel.HeightStyle := ssAuto;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.ChildOrder := 1;
    lblTitel.ElementPosition := epIgnore;
    lblTitel.ElementFont := efCSS;
    lblTitel.Role := '';
    divText.SetParentComponent(divHeader);
    divText.Name := 'divText';
    divText.Left := 0;
    divText.Top := 76;
    divText.Width := 602;
    divText.Height := 54;
    divText.ElementClassName := 'blog-headline-Text';
    divText.HeightStyle := ssAuto;
    divText.WidthStyle := ssAuto;
    divText.ChildOrder := 2;
    divText.ElementPosition := epIgnore;
    divText.ElementFont := efCSS;
    divText.HTML.BeginUpdate;
    try
      divText.HTML.Clear;
      divText.HTML.Add('');
      divText.HTML.Add('Testtext');
    finally
      divText.HTML.EndUpdate;
    end;
    divText.Role := '';
    divBlogs.SetParentComponent(divMECenter);
    divBlogs.Name := 'divBlogs';
    divBlogs.Left := 24;
    divBlogs.Top := 296;
    divBlogs.Width := 607;
    divBlogs.Height := 302;
    divBlogs.ElementClassName := 'blog-wrapper';
    divBlogs.HeightStyle := ssAuto;
    divBlogs.WidthStyle := ssAuto;
    divBlogs.ChildOrder := 1;
    divBlogs.ElementPosition := epIgnore;
    divBlogs.ElementFont := efCSS;
    divBlogs.Role := '';
    divNixDa.SetParentComponent(divBlogs);
    divNixDa.Name := 'divNixDa';
    divNixDa.Left := 246;
    divNixDa.Top := 49;
    divNixDa.Width := 100;
    divNixDa.Height := 41;
    divNixDa.ElementClassName := 'help-div';
    divNixDa.HeightStyle := ssAuto;
    divNixDa.WidthStyle := ssAuto;
    divNixDa.ElementPosition := epRelative;
    divNixDa.ElementFont := efCSS;
    divNixDa.HTML.BeginUpdate;
    try
      divNixDa.HTML.Clear;
      divNixDa.HTML.Add('<FONT size="9">Leider kann ich keine Beitr'#228'ge mit deinen Filterbedigungen finden. <br><br>Passe die Filterbedingungen an oder du k'#246'nntest ');
      divNixDa.HTML.Add('den ersten Beitrag schreiben.</FONT>');
    finally
      divNixDa.HTML.EndUpdate;
    end;
    divNixDa.Role := '';
    divNixDa.Visible := False;
    divRight.SetParentComponent(divMain);
    divRight.Name := 'divRight';
    divRight.Left := 1103;
    divRight.Top := 24;
    divRight.Width := 74;
    divRight.Height := 665;
    divRight.ElementClassName := 'me_right';
    divRight.HeightStyle := ssAuto;
    divRight.WidthStyle := ssAuto;
    divRight.ChildOrder := 2;
    divRight.ElementPosition := epIgnore;
    divRight.ElementFont := efCSS;
    divRight.Role := '';
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 56;
    Client.Top := 60;
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    divWrapper.AfterLoadDFMValues;
    imgPageTitel.AfterLoadDFMValues;
    divMain.AfterLoadDFMValues;
    divLeft.AfterLoadDFMValues;
    divMyStuff.AfterLoadDFMValues;
    frMyStuff1.AfterLoadDFMValues;
    frMyStuff1.divWrapper.AfterLoadDFMValues;
    frMyStuff1.divHeadline.AfterLoadDFMValues;
    divHashTags.AfterLoadDFMValues;
    divMECenter.AfterLoadDFMValues;
    divHeader.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    btnTitel.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    divText.AfterLoadDFMValues;
    divBlogs.AfterLoadDFMValues;
    divNixDa.AfterLoadDFMValues;
    divRight.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.
