unit uAlbumEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.WebCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Imaging.pngimage, WEBLib.ExtCtrls, XData.Web.Client,
  Vcl.Forms, ufrFotosListe, WEBLib.ComCtrls, utb, ufrVideoListe;

type
  TfrmAlbumEdit = class(TForm)
    WebHTMLDiv1: THTMLDiv;
    WebHTMLDiv11: THTMLDiv;
    btnClose: TImageControl;
    lblTitel: TLabel;
    Client: TXDataWebClient;
    WebHTMLDiv3: THTMLDiv;
    WebHTMLDiv7: THTMLDiv;
    WebHTMLDiv2: THTMLDiv;
    WebHTMLDiv4: THTMLDiv;
    btnSave: TButton;
    btnCancel: TButton;
    WebHTMLDiv5: THTMLDiv;
    WebHTMLDiv6: THTMLDiv;
    cbStatus: TComboBox;
    WebHTMLDiv9: THTMLDiv;
    WebHTMLDiv12: THTMLDiv;
    img1: TImageControl;
    WebHTMLDiv10: THTMLDiv;
    WebHTMLDiv8: THTMLDiv;
    frFotosliste1: TfrFotosliste;
    WebHTMLDiv17: THTMLDiv;
    frVideoListe1: TfrVideoListe;
    divTitel: THTMLDiv;
    reTitel: TRichEdit;
    WebHTMLDiv13: THTMLDiv;
    reText: TRichEdit;
    procedure WebFormCreate(Sender: TObject);
    [async] procedure btnSaveClick(Sender: TObject); async;
    [async] procedure btnCloseClick(Sender: TObject); async;
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
  private
    FTitelbild:integer;
    FCallback:TCallback;
    FContainer:THtmlDiv;
    FID, FNewID:integer;
    procedure SetId(Value:integer);
    procedure SetTitelbild(Value:integer);
//    [async] procedure GetItem(AID:integer);
//    [async] procedure FillCombobox(Acb:TWebComboBox; AID:integer);
  public
    [async] procedure SetTitelFoto(s:string); async;
    property ID:integer read FID write SetID;
    property Titelbild:integer read FTitelbild write SetTitelbild;
    property Container:THtmlDiv read FContainer write FContainer;
    class function Exec(AALbumID:integer; ACallback:TCallback):integer;
  protected procedure LoadDFMValues; override; end;

var
  frmAlbumEdit: TfrmAlbumEdit;

implementation
  uses uDatenmodul, uMain;

{$R *.dfm}

procedure TfrmAlbumEdit.btnSaveClick(Sender: TObject);
var
  sStatus, s:string;
begin
  if reTitel.PlainText > ''
  then begin
     sStatus := cbStatus.ItemIndex.ToString;

     s := 'UPDATE Alben SET TITEL = ''' + chkstr(reTitel.PlainText)  +
                            ''', TEXT = ''' + chkstr(reText.PlainText) + ''', STANDARD_MEDIA_ID = ' +
                            FTitelbild.tostring + ', TYP = 1, SICHTBAR = ' + sStatus +
           ' where ID = ' + FID.ToString;

     await(datenmodul.easyExec(s));

     if Mainform.Holder.Album = 0
     then Mainform.Holder.Album := FID;

     if assigned(FCallback)
     then FCallback(FID.ToString);

     close;
  end
  else begin
    Meldung('Das Album hat keinen Namen. Alben ohne Namen können nicht gespeichert werden...');
  end;

end;

procedure TfrmAlbumEdit.SetTitelbild(value:integer);
begin
  FTitelBild := value;
  SetTitelFoto(value.ToString);
end;

class function TfrmAlbumEdit.Exec(AALbumID:integer; ACallback:TCallback): integer;

  [async] procedure AfterCreate(AForm: TObject); async;
  Var
    Response: TXDataClientResponse;
    J:TJ;
  begin
    TfrmAlbumEdit(AForm).ElementHandle.style.removeProperty('position');
    TfrmAlbumEdit(AForm).Parent := Mainform;
    TfrmAlbumEdit(AForm).lblTitel.Caption := 'ALBUM BEARBEITEN';

    tbFillCombobox(TfrmAlbumEdit(AForm).cbStatus, 'ID','TEXT',await(datenmodul.EasySQL('Select ID, TEXT from Status')));

    TfrmAlbumEdit(AForm).FNewID := 0;

    if AAlbumID > 0
    then begin
      J := TJ.create(await(datenmodul.EasySQL('SELECT * from Alben where ID = ' + AAlbumID.ToString)));
      TfrmAlbumEdit(AForm).reTitel.lines.Text := J.Value('TITEL');
      TfrmAlbumEdit(AForm).reText.lines.Text  := J.Value('TEXT');
      TfrmAlbumEdit(AForm).ID                 := J.integer('ID');
      TfrmAlbumEdit(AForm).Titelbild          := J.integer('STANDARD_MEDIA_ID');

      //Sichtbar für...
      TfrmAlbumEdit(AForm).cbStatus.ItemIndex := GetComboIndex(TfrmAlbumEdit(AForm).cbStatus, j.Value('SICHTBAR'));

    end
    else begin
      Response := Await(
                   TfrmAlbumEdit(AForm).Client.RawInvokeAsync( 'IDBService.AlbumNew', [Mainform.Holder.ID, '', '', 0]));

      TfrmAlbumEdit(AForm).ID := TJ.create(Response).Integer('ID');
      TfrmAlbumEdit(AForm).FNewID := TfrmAlbumEdit(AForm).ID;

      //Sichtbar für...
      TfrmAlbumEdit(AForm).cbStatus.ItemIndex := 1; // ...Öffentlich
    end;
//                                            Init(AHolder,                  AMe, AAlbumID,              APostID, ABlogID, AKursID, AStart, AStatus: integer; AImageClick:TCallback; ADoClick:boolean = true; AAllowAddImage:boolean = true; AShowMarked:boolean=false):integer;
    TfrmAlbumEdit(AForm).frVideoliste1.Init(mainform.Holder.ID, mainform.ME.ID, TfrmAlbumEdit(AForm).ID, 0,0, 0, 0, -1, nil, false, 2, false);
    TfrmAlbumEdit(AForm).frFotosliste1.Init(mainform.Holder.ID, mainform.ME.ID, TfrmAlbumEdit(AForm).ID, 0,0, 0, TfrmAlbumEdit(AForm).Titelbild, -1, @TfrmAlbumEdit(AForm).SetTitelFoto, false, 2, false);
  end;

Var
  frm:TfrmAlbumEdit;
  FConti:THtmlDiv;
begin
  FConti := THtmlDiv.Create(Mainform);
  FConti.Parent := Mainform;
  FConti.ElementClassName := 'editor_transparent';
  frm := TfrmAlbumEdit.CreateNew(FConti.ElementID, @AfterCreate);

  frm.FContainer := FConti;
  frm.FCallback  := ACallback;

end;

procedure TfrmAlbumEdit.SetTitelFoto(s:string);
begin
  FTitelBild := s.ToInteger;
  img1.URL := await(datenmodul.GetMedia(s.tointeger, _SIZE2));
end;


procedure TfrmAlbumEdit.SetId(Value: integer);
begin
  FID := value;
end;

procedure TfrmAlbumEdit.btnCloseClick(Sender: TObject);
begin
  if FNewID > 0  //wenn dieser Dialog zum Neuanlegen eines Albums aufgerufen wurde...
  then begin
    await(datenmodul.EasyExec('delete from Medien where ALBUM_ID = ' + FNewId.ToString));
    await(datenmodul.EasyExec('delete from Alben where ID = ' + FNewId.ToString));
  end;

  close;
end;


procedure TfrmAlbumEdit.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
 FContainer.Free;
end;

procedure TfrmAlbumEdit.WebFormCreate(Sender: TObject);
Var
  el : TJSHTMLElement;
begin

  TControl(self).elementfont := efCSS;

  TControl(reTitel).elementPosition := epIgnore;
  el := TJSHTMLElement(THTMLDiv(reTitel).ElementHandle);
  el.style.removeProperty('position');
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-size');
  el.style.removeProperty('font-style');

  TControl(reText).elementPosition := epIgnore;
  el := TJSHTMLElement(THTMLDiv(reText).ElementHandle);
  el.style.removeProperty('position');
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-size');
  el.style.removeProperty('font-style');

end;

procedure TfrmAlbumEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  WebHTMLDiv11 := THTMLDiv.Create(Self);
  btnClose := TImageControl.Create(Self);
  lblTitel := TLabel.Create(Self);
  WebHTMLDiv3 := THTMLDiv.Create(Self);
  WebHTMLDiv9 := THTMLDiv.Create(Self);
  WebHTMLDiv12 := THTMLDiv.Create(Self);
  img1 := TImageControl.Create(Self);
  WebHTMLDiv10 := THTMLDiv.Create(Self);
  divTitel := THTMLDiv.Create(Self);
  reTitel := TRichEdit.Create(Self);
  WebHTMLDiv13 := THTMLDiv.Create(Self);
  reText := TRichEdit.Create(Self);
  WebHTMLDiv8 := THTMLDiv.Create('frFotoslisteEdit');
  frFotosliste1 := TfrFotosliste.Create(Self);
  WebHTMLDiv17 := THTMLDiv.Create('E1');
  frVideoListe1 := TfrVideoListe.Create(Self);
  WebHTMLDiv7 := THTMLDiv.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  WebHTMLDiv5 := THTMLDiv.Create(Self);
  WebHTMLDiv6 := THTMLDiv.Create(Self);
  cbStatus := TComboBox.Create(Self);
  WebHTMLDiv4 := THTMLDiv.Create(Self);
  btnSave := TButton.Create(Self);
  btnCancel := TButton.Create(Self);
  Client := TXDataWebClient.Create(Self);

  frFotosliste1.LoadFromForm;
  frVideoListe1.LoadFromForm;
  WebHTMLDiv1.BeforeLoadDFMValues;
  WebHTMLDiv11.BeforeLoadDFMValues;
  btnClose.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  WebHTMLDiv3.BeforeLoadDFMValues;
  WebHTMLDiv9.BeforeLoadDFMValues;
  WebHTMLDiv12.BeforeLoadDFMValues;
  img1.BeforeLoadDFMValues;
  WebHTMLDiv10.BeforeLoadDFMValues;
  divTitel.BeforeLoadDFMValues;
  reTitel.BeforeLoadDFMValues;
  WebHTMLDiv13.BeforeLoadDFMValues;
  reText.BeforeLoadDFMValues;
  WebHTMLDiv8.BeforeLoadDFMValues;
  frFotosliste1.BeforeLoadDFMValues;
  frFotosliste1.WebHTMLDiv1.BeforeLoadDFMValues;
  frFotosliste1.divWrapper.BeforeLoadDFMValues;
  WebHTMLDiv17.BeforeLoadDFMValues;
  frVideoListe1.BeforeLoadDFMValues;
  frVideoListe1.WebHTMLDiv1.BeforeLoadDFMValues;
  frVideoListe1.divWrapper.BeforeLoadDFMValues;
  frVideoListe1.img.BeforeLoadDFMValues;
  frVideoListe1.Client.BeforeLoadDFMValues;
  WebHTMLDiv7.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  WebHTMLDiv5.BeforeLoadDFMValues;
  WebHTMLDiv6.BeforeLoadDFMValues;
  cbStatus.BeforeLoadDFMValues;
  WebHTMLDiv4.BeforeLoadDFMValues;
  btnSave.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frmAlbumEdit';
    Width := 1297;
    Height := 955;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 8;
    WebHTMLDiv1.Top := 8;
    WebHTMLDiv1.Width := 889;
    WebHTMLDiv1.Height := 937;
    WebHTMLDiv1.ElementClassName := 'albumedit_container';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epRelative;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv11.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv11.Name := 'WebHTMLDiv11';
    WebHTMLDiv11.Left := 14;
    WebHTMLDiv11.Top := 3;
    WebHTMLDiv11.Width := 857;
    WebHTMLDiv11.Height := 41;
    WebHTMLDiv11.ElementClassName := 'albumedit_header';
    WebHTMLDiv11.HeightStyle := ssAuto;
    WebHTMLDiv11.WidthStyle := ssAuto;
    WebHTMLDiv11.ChildOrder := 1;
    WebHTMLDiv11.ElementPosition := epRelative;
    WebHTMLDiv11.ElementFont := efCSS;
    WebHTMLDiv11.Role := '';
    btnClose.SetParentComponent(WebHTMLDiv11);
    btnClose.Name := 'btnClose';
    btnClose.Left := 794;
    btnClose.Top := -3;
    btnClose.Width := 46;
    btnClose.Height := 44;
    btnClose.ElementClassName := 'albumedit_close';
    btnClose.HeightStyle := ssAuto;
    btnClose.WidthStyle := ssAuto;
    btnClose.HeightPercent := 100.000000000000000000;
    btnClose.WidthPercent := 100.000000000000000000;
    btnClose.Anchors := [];
    btnClose.ChildOrder := 1;
    btnClose.ElementFont := efCSS;
    btnClose.ElementPosition := epIgnore;
    SetEvent(btnClose, Self, 'OnClick', 'btnCloseClick');
    btnClose.Picture.LoadFromFile('uAlbumEdit.WebHTMLDiv11.btnClose.Picture.png');
    lblTitel.SetParentComponent(WebHTMLDiv11);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 16;
    lblTitel.Top := 9;
    lblTitel.Width := 35;
    lblTitel.Height := 15;
    lblTitel.Caption := 'lblTitel';
    lblTitel.ElementClassName := 'albumedit_titel';
    lblTitel.ElementFont := efCSS;
    lblTitel.ElementPosition := epIgnore;
    lblTitel.HeightStyle := ssAuto;
    lblTitel.HeightPercent := 100.000000000000000000;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.WidthPercent := 100.000000000000000000;
    WebHTMLDiv3.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv3.Name := 'WebHTMLDiv3';
    WebHTMLDiv3.Left := 14;
    WebHTMLDiv3.Top := 50;
    WebHTMLDiv3.Width := 857;
    WebHTMLDiv3.Height := 711;
    WebHTMLDiv3.ElementClassName := 'kursedit_text_container';
    WebHTMLDiv3.HeightStyle := ssAuto;
    WebHTMLDiv3.WidthStyle := ssAuto;
    WebHTMLDiv3.ChildOrder := 3;
    WebHTMLDiv3.ElementPosition := epRelative;
    WebHTMLDiv3.ElementFont := efCSS;
    WebHTMLDiv3.Role := '';
    WebHTMLDiv9.SetParentComponent(WebHTMLDiv3);
    WebHTMLDiv9.Name := 'WebHTMLDiv9';
    WebHTMLDiv9.Left := 16;
    WebHTMLDiv9.Top := 16;
    WebHTMLDiv9.Width := 812;
    WebHTMLDiv9.Height := 209;
    WebHTMLDiv9.HeightStyle := ssAuto;
    WebHTMLDiv9.WidthStyle := ssAuto;
    WebHTMLDiv9.ChildOrder := 1;
    WebHTMLDiv9.ElementPosition := epIgnore;
    WebHTMLDiv9.ElementFont := efCSS;
    WebHTMLDiv9.Role := '';
    WebHTMLDiv12.SetParentComponent(WebHTMLDiv9);
    WebHTMLDiv12.Name := 'WebHTMLDiv12';
    WebHTMLDiv12.Left := 3;
    WebHTMLDiv12.Top := 15;
    WebHTMLDiv12.Width := 790;
    WebHTMLDiv12.Height := 178;
    WebHTMLDiv12.ElementClassName := 'albumedit_titel_container';
    WebHTMLDiv12.HeightStyle := ssAuto;
    WebHTMLDiv12.WidthStyle := ssAuto;
    WebHTMLDiv12.ChildOrder := 3;
    WebHTMLDiv12.ElementPosition := epIgnore;
    WebHTMLDiv12.ElementFont := efCSS;
    WebHTMLDiv12.Role := '';
    img1.SetParentComponent(WebHTMLDiv12);
    img1.Name := 'img1';
    img1.Left := 3;
    img1.Top := 3;
    img1.Width := 100;
    img1.Height := 65;
    img1.ElementClassName := 'albumedit_img';
    img1.HeightStyle := ssAuto;
    img1.WidthStyle := ssAuto;
    img1.HeightPercent := 100.000000000000000000;
    img1.WidthPercent := 100.000000000000000000;
    img1.ChildOrder := 1;
    img1.ElementFont := efCSS;
    img1.ElementPosition := epIgnore;
    WebHTMLDiv10.SetParentComponent(WebHTMLDiv12);
    WebHTMLDiv10.Name := 'WebHTMLDiv10';
    WebHTMLDiv10.Left := 249;
    WebHTMLDiv10.Top := 3;
    WebHTMLDiv10.Width := 417;
    WebHTMLDiv10.Height := 172;
    WebHTMLDiv10.ElementClassName := 'albumedit_titel_texte_container';
    WebHTMLDiv10.HeightStyle := ssAuto;
    WebHTMLDiv10.WidthStyle := ssAuto;
    WebHTMLDiv10.ChildOrder := 3;
    WebHTMLDiv10.ElementPosition := epIgnore;
    WebHTMLDiv10.ElementFont := efCSS;
    WebHTMLDiv10.Role := '';
    divTitel.SetParentComponent(WebHTMLDiv10);
    divTitel.Name := 'divTitel';
    divTitel.Left := 3;
    divTitel.Top := 0;
    divTitel.Width := 227;
    divTitel.Height := 41;
    divTitel.ElementClassName := 'albumedit_titel_header';
    divTitel.HeightStyle := ssAuto;
    divTitel.WidthStyle := ssAuto;
    divTitel.ElementPosition := epIgnore;
    divTitel.ElementFont := efCSS;
    divTitel.HTML.BeginUpdate;
    try
      divTitel.HTML.Clear;
      divTitel.HTML.Add('Album-Tiltel');
    finally
      divTitel.HTML.EndUpdate;
    end;
    divTitel.Role := '';
    reTitel.SetParentComponent(WebHTMLDiv10);
    reTitel.Name := 'reTitel';
    reTitel.Left := 3;
    reTitel.Top := 41;
    reTitel.Width := 325;
    reTitel.Height := 36;
    reTitel.ElementClassName := 'kursedit_titel';
    reTitel.HeightStyle := ssAuto;
    reTitel.WidthStyle := ssAuto;
    reTitel.BorderStyle := bsSingle;
    reTitel.ChildOrder := 1;
    reTitel.Color := clWhite;
    WebHTMLDiv13.SetParentComponent(WebHTMLDiv10);
    WebHTMLDiv13.Name := 'WebHTMLDiv13';
    WebHTMLDiv13.Left := 3;
    WebHTMLDiv13.Top := 67;
    WebHTMLDiv13.Width := 227;
    WebHTMLDiv13.Height := 41;
    WebHTMLDiv13.ElementClassName := 'albumedit_titel_header';
    WebHTMLDiv13.HeightStyle := ssAuto;
    WebHTMLDiv13.WidthStyle := ssAuto;
    WebHTMLDiv13.ChildOrder := 2;
    WebHTMLDiv13.ElementPosition := epIgnore;
    WebHTMLDiv13.ElementFont := efCSS;
    WebHTMLDiv13.HTML.BeginUpdate;
    try
      WebHTMLDiv13.HTML.Clear;
      WebHTMLDiv13.HTML.Add('Kurz-Beschreibung');
    finally
      WebHTMLDiv13.HTML.EndUpdate;
    end;
    WebHTMLDiv13.Role := '';
    reText.SetParentComponent(WebHTMLDiv10);
    reText.Name := 'reText';
    reText.Left := 3;
    reText.Top := 103;
    reText.Width := 325;
    reText.Height := 66;
    reText.ElementClassName := 'albumedit_input';
    reText.HeightStyle := ssAuto;
    reText.WidthStyle := ssAuto;
    reText.BorderStyle := bsSingle;
    reText.ChildOrder := 3;
    reText.Color := clWhite;
    WebHTMLDiv8.SetParentComponent(WebHTMLDiv3);
    WebHTMLDiv8.Name := 'WebHTMLDiv8';
    WebHTMLDiv8.Left := 16;
    WebHTMLDiv8.Top := 263;
    WebHTMLDiv8.Width := 825;
    WebHTMLDiv8.Height := 217;
    WebHTMLDiv8.ElementClassName := 'ktext_img_container';
    WebHTMLDiv8.HeightStyle := ssAuto;
    WebHTMLDiv8.WidthStyle := ssAuto;
    WebHTMLDiv8.ChildOrder := 2;
    WebHTMLDiv8.ElementPosition := epIgnore;
    WebHTMLDiv8.ElementFont := efCSS;
    WebHTMLDiv8.Role := '';
    frFotosliste1.SetParentComponent(WebHTMLDiv8);
    frFotosliste1.Name := 'frFotosliste1';
    frFotosliste1.Left := 6;
    frFotosliste1.Top := 0;
    frFotosliste1.Width := 309;
    frFotosliste1.Height := 325;
    frFotosliste1.Margins.Left := 0;
    frFotosliste1.Margins.Top := 0;
    frFotosliste1.Margins.Right := 0;
    frFotosliste1.Margins.Bottom := 0;
    frFotosliste1.TabOrder := 0;
    frFotosliste1.WebHTMLDiv1.Top := 11;
    frFotosliste1.WebHTMLDiv1.Height := 190;
    frFotosliste1.divWrapper.Height := 153;
    WebHTMLDiv17.SetParentComponent(WebHTMLDiv3);
    WebHTMLDiv17.Name := 'WebHTMLDiv17';
    WebHTMLDiv17.Left := 16;
    WebHTMLDiv17.Top := 494;
    WebHTMLDiv17.Width := 825;
    WebHTMLDiv17.Height := 190;
    WebHTMLDiv17.ElementClassName := 'ktext_img_container';
    WebHTMLDiv17.HeightStyle := ssAuto;
    WebHTMLDiv17.WidthStyle := ssAuto;
    WebHTMLDiv17.ChildOrder := 3;
    WebHTMLDiv17.ElementPosition := epIgnore;
    WebHTMLDiv17.ElementFont := efCSS;
    WebHTMLDiv17.Role := '';
    frVideoListe1.SetParentComponent(WebHTMLDiv17);
    frVideoListe1.Name := 'frVideoListe1';
    frVideoListe1.Left := 13;
    frVideoListe1.Top := 0;
    frVideoListe1.Width := 301;
    frVideoListe1.Height := 172;
    frVideoListe1.Margins.Left := 0;
    frVideoListe1.Margins.Top := 0;
    frVideoListe1.Margins.Right := 0;
    frVideoListe1.Margins.Bottom := 0;
    frVideoListe1.TabOrder := 0;
    frVideoListe1.WebHTMLDiv1.Top := 16;
    frVideoListe1.WebHTMLDiv1.Height := 161;
    frVideoListe1.divWrapper.Left := 11;
    frVideoListe1.divWrapper.Width := 233;
    frVideoListe1.divWrapper.Height := 137;
    frVideoListe1.img.Left := 24;
    frVideoListe1.img.Top := 16;
    frVideoListe1.img.Width := 65;
    frVideoListe1.img.Height := 57;
    frVideoListe1.Client.Left := 16;
    frVideoListe1.Client.Top := 16;
    WebHTMLDiv7.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv7.Name := 'WebHTMLDiv7';
    WebHTMLDiv7.Left := 14;
    WebHTMLDiv7.Top := 767;
    WebHTMLDiv7.Width := 857;
    WebHTMLDiv7.Height := 154;
    WebHTMLDiv7.ElementClassName := 'albumedit_footer';
    WebHTMLDiv7.HeightStyle := ssAuto;
    WebHTMLDiv7.WidthStyle := ssAuto;
    WebHTMLDiv7.ChildOrder := 4;
    WebHTMLDiv7.ElementPosition := epRelative;
    WebHTMLDiv7.ElementFont := efCSS;
    WebHTMLDiv7.Role := '';
    WebHTMLDiv2.SetParentComponent(WebHTMLDiv7);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.Left := 19;
    WebHTMLDiv2.Top := 9;
    WebHTMLDiv2.Width := 434;
    WebHTMLDiv2.Height := 112;
    WebHTMLDiv2.ElementClassName := 'albumedit_combo_container';
    WebHTMLDiv2.HeightStyle := ssAuto;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.ElementPosition := epIgnore;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.Role := '';
    WebHTMLDiv5.SetParentComponent(WebHTMLDiv2);
    WebHTMLDiv5.Name := 'WebHTMLDiv5';
    WebHTMLDiv5.Left := 3;
    WebHTMLDiv5.Top := 3;
    WebHTMLDiv5.Width := 390;
    WebHTMLDiv5.Height := 41;
    WebHTMLDiv5.HeightStyle := ssAuto;
    WebHTMLDiv5.WidthStyle := ssAuto;
    WebHTMLDiv5.ChildOrder := 2;
    WebHTMLDiv5.ElementPosition := epIgnore;
    WebHTMLDiv5.ElementFont := efCSS;
    WebHTMLDiv5.HTML.BeginUpdate;
    try
      WebHTMLDiv5.HTML.Clear;
      WebHTMLDiv5.HTML.Add('f'#252'r wen ist das Album sichtbar ?');
    finally
      WebHTMLDiv5.HTML.EndUpdate;
    end;
    WebHTMLDiv5.Role := '';
    WebHTMLDiv6.SetParentComponent(WebHTMLDiv2);
    WebHTMLDiv6.Name := 'WebHTMLDiv6';
    WebHTMLDiv6.Left := 3;
    WebHTMLDiv6.Top := 50;
    WebHTMLDiv6.Width := 334;
    WebHTMLDiv6.Height := 41;
    WebHTMLDiv6.HeightStyle := ssAuto;
    WebHTMLDiv6.WidthStyle := ssAuto;
    WebHTMLDiv6.ChildOrder := 2;
    WebHTMLDiv6.ElementPosition := epIgnore;
    WebHTMLDiv6.ElementFont := efCSS;
    WebHTMLDiv6.Role := '';
    cbStatus.SetParentComponent(WebHTMLDiv6);
    cbStatus.Name := 'cbStatus';
    cbStatus.Left := 3;
    cbStatus.Top := 3;
    cbStatus.Width := 273;
    cbStatus.Height := 23;
    cbStatus.ElementClassName := 'albumedit_combobox';
    cbStatus.ElementFont := efCSS;
    cbStatus.ElementPosition := epIgnore;
    cbStatus.HeightStyle := ssAuto;
    cbStatus.HeightPercent := 100.000000000000000000;
    cbStatus.Text := 'nur f'#252'r mich';
    cbStatus.WidthStyle := ssAuto;
    cbStatus.WidthPercent := 100.000000000000000000;
    cbStatus.ItemIndex := 0;
    cbStatus.Items.BeginUpdate;
    try
      cbStatus.Items.Clear;
      cbStatus.Items.Add('nur f'#252'r mich');
      cbStatus.Items.Add('meine Freunde');
      cbStatus.Items.Add('f'#252'r alle Mitglieder');
    finally
      cbStatus.Items.EndUpdate;
    end;
    WebHTMLDiv4.SetParentComponent(WebHTMLDiv7);
    WebHTMLDiv4.Name := 'WebHTMLDiv4';
    WebHTMLDiv4.Left := 459;
    WebHTMLDiv4.Top := 9;
    WebHTMLDiv4.Width := 334;
    WebHTMLDiv4.Height := 41;
    WebHTMLDiv4.ElementClassName := 'albumedit_btn_container';
    WebHTMLDiv4.HeightStyle := ssAuto;
    WebHTMLDiv4.WidthStyle := ssAuto;
    WebHTMLDiv4.ChildOrder := 1;
    WebHTMLDiv4.ElementPosition := epIgnore;
    WebHTMLDiv4.ElementFont := efCSS;
    WebHTMLDiv4.Role := '';
    btnSave.SetParentComponent(WebHTMLDiv4);
    btnSave.Name := 'btnSave';
    btnSave.Left := 9;
    btnSave.Top := 3;
    btnSave.Width := 144;
    btnSave.Height := 25;
    btnSave.Caption := 'Speichern';
    btnSave.ChildOrder := 1;
    btnSave.ElementClassName := 'editor_button';
    btnSave.ElementFont := efCSS;
    btnSave.ElementPosition := epIgnore;
    btnSave.HeightStyle := ssAuto;
    btnSave.HeightPercent := 100.000000000000000000;
    btnSave.WidthStyle := ssAuto;
    btnSave.WidthPercent := 100.000000000000000000;
    SetEvent(btnSave, Self, 'OnClick', 'btnSaveClick');
    btnCancel.SetParentComponent(WebHTMLDiv4);
    btnCancel.Name := 'btnCancel';
    btnCancel.Left := 159;
    btnCancel.Top := 3;
    btnCancel.Width := 144;
    btnCancel.Height := 25;
    btnCancel.Caption := 'Verwerfen';
    btnCancel.ChildOrder := 2;
    btnCancel.ElementClassName := 'editor_button';
    btnCancel.ElementFont := efCSS;
    btnCancel.ElementPosition := epIgnore;
    btnCancel.HeightStyle := ssAuto;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthStyle := ssAuto;
    btnCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCancel, Self, 'OnClick', 'btnCloseClick');
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 24;
    Client.Top := 64;
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    WebHTMLDiv11.AfterLoadDFMValues;
    btnClose.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    WebHTMLDiv3.AfterLoadDFMValues;
    WebHTMLDiv9.AfterLoadDFMValues;
    WebHTMLDiv12.AfterLoadDFMValues;
    img1.AfterLoadDFMValues;
    WebHTMLDiv10.AfterLoadDFMValues;
    divTitel.AfterLoadDFMValues;
    reTitel.AfterLoadDFMValues;
    WebHTMLDiv13.AfterLoadDFMValues;
    reText.AfterLoadDFMValues;
    WebHTMLDiv8.AfterLoadDFMValues;
    frFotosliste1.AfterLoadDFMValues;
    frFotosliste1.WebHTMLDiv1.AfterLoadDFMValues;
    frFotosliste1.divWrapper.AfterLoadDFMValues;
    WebHTMLDiv17.AfterLoadDFMValues;
    frVideoListe1.AfterLoadDFMValues;
    frVideoListe1.WebHTMLDiv1.AfterLoadDFMValues;
    frVideoListe1.divWrapper.AfterLoadDFMValues;
    frVideoListe1.img.AfterLoadDFMValues;
    frVideoListe1.Client.AfterLoadDFMValues;
    WebHTMLDiv7.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    WebHTMLDiv5.AfterLoadDFMValues;
    WebHTMLDiv6.AfterLoadDFMValues;
    cbStatus.AfterLoadDFMValues;
    WebHTMLDiv4.AfterLoadDFMValues;
    btnSave.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.
