{*************************************************************************}
{                                                                         }
{ written by TMS Software                                                 }
{           copyright (c)  2023                                           }
{           Email : info@tmssoftware.com                                  }
{           Web : https://www.tmssoftware.com                             }
{                                                                         }
{ The source code is given as is. The author is not responsible           }
{ for any possible damage done due to the use of this code.               }
{ The component can be freely used in any application. The complete       }
{ source code remains property of the author and may not be distributed,  }
{ published, given or sold in any form as such. No parts of the source    }
{ code can be included in any other component or application without      }
{ written authorization of the author.                                    }
{*************************************************************************}

unit WEBLib.TMSFNCMemo.Consts;

{$I WEBLib.TMSFNCDefines.inc}

interface

const
  LB = #13;
  MEMOCONTENTCHANGED = 'OnContentChanged';
  MEMOTRACKING = 'OnChangeTracking';
  MEMOATTEMPTREADONLY = 'OnAttemptReadOnly';
  MEMOLOSEFOCUS = 'OnLoseFocus';
  MEMOCHANGEPOSITION = 'OnChangePosition';
  MEMOCHANGESELECTION = 'OnChangeSelection';
  MEMOCHANGEHIDDENAREAS = 'OnChangeHiddenAreas';
  MEMOGETFOCUS = 'OnGetFocus';
  MEMOPASTE = 'OnPaste';
  MEMOSCROLLCHANGE = 'OnScrollChange';
  MEMODOKEYDOWN = 'OnKeyDown';
  MEMODOKEYUP = 'OnKeyUp';
  MEMODOMOUSEDOWN = 'OnMouseDown';
  MEMODOMOUSELEAVE = 'OnMouseLeave';
  MEMODOMOUSEMOVE = 'OnMouseMove';
  MEMODOMOUSEUP = 'OnMouseUp';
  MEMOTABPRESSED = 'OnMemoTabPressed';
  MEMOURLCLICK = 'OnMemoURLClick';
  FETCHSUGGESTIONS = 'DoFetchSuggestions';
  FETCHPASTE = 'DoFetchPaste';
  MEMODRAGLEAVE = 'DoDragLeave';
  MEMODRAG = 'DoDrag';
  MEMODRAGDROP = 'DoDragDrop';
  MEMODRAGEND = 'DoDragEnd';
  MEMODRAGENTER = 'DoDragEnter';
  MEMODRAGOVER = 'DoDragOver';
  MEMODRAGSTART = 'DoDragStart';


function LoadMemoScript(AControlID, ADataObject: String): String;

implementation

function LoadmemoScript(AControlID, ADataObject: String): String;
begin
   Result :=  'var ' + AControlID + 'editor;' + LB +
              'var ' + AControlID + 'Initialized = false;' + LB +
              'var ' + AControlID + 'WantTab = false;' + LB +
              'var ' + AControlID + 'Caret;' + LB +
              'var ' + AControlID + 'Breakpoints = null;' + LB +
              'var ' + AControlID + 'Bookmarks = null;' + LB +
              'var ' + AControlID + 'SuggestionResolve = null;' + LB +
              'var ' + AControlID + 'PasteResolve = null;' + LB +
              'var ' + AControlID + 'Suggestions = [];' + LB +
              'var ' + AControlID + 'Highlights = null;' + LB +
              'var ' + AControlID + 'DragCounter = 0;' + LB +
              'var ' + AControlID + 'DragOverResolve = null;' + LB +
              'var ' + AControlID + 'DropFiles = [];' + LB +
              'var ' + AControlID + 'DragData;' + LB +
              'var ' + AControlID + 'DragDataReady;' + LB +
              'var ' + AControlID + 'AllowDrop = true;' + LB +
              'var ' + AControlID + 'Tabs = [];' + LB +
              'function ' + AControlID + 'LoadMonaco(lang, theme, val, lineNumbers, formatOnPaste,' +
              ' emptySelectionClipboard, foldingHighlights, mouseWheelZoom, roundedSelection, folding,' +
              ' readOnly, glyphMargin, dragAndDrop, miniMap, contextMenu, codeLens, formatOnType, links, scrollbar, tab,' +
              ' indentGuides, lineHighlight, lhOnFocus, whitespace, selectOnLineNumbers, selectionHighlight, overviewRulerLanes, tabsize,ff, fs, cs, bp, bm, hl, qs, orb, ww, wwc, ccc' +
              ') {' + LB +
              ' debugger;' + LB +
              '  if (ccc) {' + LB +
              '   window.monaco.languages.registerCompletionItemProvider(lang, {' + LB +
              '     provideCompletionItems: async () => {' + LB +
              '     var a = await ' + AControlID + 'FetchSuggestions();' + LB +
              {$IFDEF LCLLIB}
              '     a.forEach(j => {' + LB +
              '         if (j.label){' + LB +
              '           j.label = decodeURIComponent(escape(window.atob(j.label)));' + LB +
              '         }' + LB +
              '         if (j.insertText){' + LB +
              '           j.insertText = decodeURIComponent(escape(window.atob(j.insertText)));' + LB +
              '         }' + LB +
              '         if (j.documentation){' + LB +
              '           j.documentation = decodeURIComponent(escape(window.atob(j.documentation)));' + LB +
              '         }' + LB +
              '         if (j.filterText){' + LB +
              '           j.filterText = decodeURIComponent(escape(window.atob(j.filterText)));' + LB +
              '         }' + LB +
              '         if (j.sortText){' + LB +
              '           j.sortText = decodeURIComponent(escape(window.atob(j.sortText)));' + LB +
              '         }' + LB +
              '       });' + LB +
              {$ENDIF}
              '       return { suggestions: a };' + LB +
              '     },' + LB +

              '   });' + LB +
              '  }' + LB +
              ' ' + AControlID + 'editor = window.monaco.editor.create(document.getElementById("' + AControlID + 'container"), {' + LB +
              '   value: decodeURIComponent(escape(window.atob(val))),' + LB +
              '   language: lang,'+ LB +
              '   theme: theme,'+ LB +
              '   automaticLayout: true,' + LB +
              '   folding: folding,' + LB +
              '   contextmenu: contextMenu,' + LB +
              '   readOnly: readOnly,' + LB +
              '   formatOnPaste: formatOnPaste,' + LB +
              '   emptySelectionClipboard: emptySelectionClipboard,' + LB +
              '   foldingHighlight: foldingHighlights,' + LB +
              '   mouseWheelZoom: mouseWheelZoom,' + LB +
              '   roundedSelection: roundedSelection,' + LB +
              '   glyphMargin: glyphMargin,' + LB +
              '   minimap: miniMap,' + LB +
              '   formatOnType: formatOnType,' + LB +
              '   codeLens: codeLens,' + LB +
              '   links: links,' + LB +
              '   lineNumbers: lineNumbers,' + LB +
              '   scrollbar: scrollbar,' + LB +
              '   renderIndentGuides: indentGuides,' + LB +
              '   renderLineHighlight: lineHighlight,' + LB +
              '   renderLineHighlightOnlyWhenFocus: lhOnFocus,' + LB +
              '   renderWhitespace: whitespace,' + LB +
              '   selectOnLineNumbers: selectOnLineNumbers,' + LB +
              '   selectionHighlight: selectionHighlight,' + LB +
              '   overviewRulerLanes: overviewRulerLanes,' + LB +
              '   tabSize: tabsize,' + LB +
              '   fontFamily: ff,' + LB +
              '   fontSize: fs,' + LB +
              '   columnSelection: cs,' + LB +
              '   quickSuggestions: qs,' + LB +
              '   overviewRulerBorder: orb,' + LB +
              '   wordWrap: ww,' + LB +
              '   wordWrapColumn: wwc' + LB +
              ' });' + LB +
              ' ' + AControlID + 'Tabs.push(' + AControlID + 'editor.getModel());' + LB +
              ' console.log(' + AControlID + 'Tabs);' + lB+
              ' ' + AControlID + 'WantTab = tab;' + LB +
              ' ' + AControlID + 'Initialized = true;' + LB +
              '   var dec = bp.split(";");' +
              '   var deco = [];' + LB +
              '   dec.forEach(i => {' + LB +
              '     if (i != "") {' + LB +
              '       var j = { ' + LB +
              '         range: {startLineNumber: parseInt(i) + 1,startColumn: 1,endLineNumber: parseInt(i) + 1,endColumn: 1},' +  LB +
              '         options: {isWholeLine: true, className: "breakPoint", glyphMarginClassName: "codicon codicon-debug-breakpoint", stickiness: 1, description:"breakpoint"}'+ LB +
              '       };' + LB +
              '       deco.push(j);' + LB +
              '     }' + LB +
              '   });' + LB +
              ' ' + AControlID + 'Breakpoints = ' + AControlID + 'editor.createDecorationsCollection(deco);' + LB +
              '   dec = bm.split(";");' +
              '   deco = [];' + LB +
              '   dec.forEach(i => {' + LB +
              '     if (i != "") {' + LB +
              '       var j = { ' + LB +
              '         range: {startLineNumber: parseInt(i) + 1,startColumn: 1,endLineNumber: parseInt(i) + 1,endColumn: 1},' +  LB +
              '         options: {isWholeLine: true, className: "", glyphMarginClassName: "codicon codicon-custombookmark", stickiness: 1, description:"bookmark"}'+ LB +
              '       };' + LB +
              '       deco.push(j);' + LB +
              '     }' + LB +
              '   });' + LB +
              ' ' + AControlID + 'Bookmarks = ' + AControlID + 'editor.createDecorationsCollection(deco);' + LB +
              '   dec = hl.split(";");' +
              '   deco = [];' + LB +
              '   dec.forEach(i => {' + LB +
              '     if (i != "") {' + LB +
              '       var j = { ' + LB +
              '         range: {startLineNumber: parseInt(i) + 1,startColumn: 1,endLineNumber: parseInt(i) + 1,endColumn: 1},' +  LB +
              '         options: {isWholeLine: true, className: "", glyphMarginClassName: "lineHighlight", stickiness: 1, description:"lineHighlight"}'+ LB +
              '       };' + LB +
              '       deco.push(j);' + LB +
              '     }' + LB +
              '   });' + LB +
              ' ' + AControlID + 'Highlights = ' + AControlID + 'editor.createDecorationsCollection(deco);' + LB +
              ' ' + AControlID + 'editor.getContribution("editor.linkDetector").openerService._defaultExternalOpener.openExternal = function(url) { ' + LB +
              '   ' + AControlID + 'SendEventData("' + MEMOURLCLICK + '", url);' + LB +
              ' };' + LB +
              ' ' + ACOntrolID + 'editor.onDidChangeModelContent((event) => {' + LB +
              '   var change = false;' + LB +
              '   event.changes.forEach((c) => { if (c.text.trim() === "\r\n") { change = true; } });' + LB +
              '    if (change) {' + LB +
              '      ' + AControlID + 'SendEventData("' + MEMOCONTENTCHANGED + '", true);' + LB +
              '    } else {' + LB +
              '      ' + AControlID + 'SendEventData("' + MEMOTRACKING + '", true);' + LB +
              '    }' + LB +
              ' });' + LB +
              ' ' + AControlID + 'editor.getContainerDomNode().addEventListener("paste", async (event) => {' + LB +
              '   event.preventDefault();' + LB +
              '   event.stopPropagation();' + LB +
              '   t = event.clipboardData.getData("text");' + LB +
              '   console.log(t);' + LB +
              '   var allowPaste = await ' + AControlID + 'CheckPaste(t);' + LB +
              '   if (allowPaste.allow) {' + LB +
              '     var range = ' + AControlID + 'editor.getSelection();' + LB +
              '     ' + AControlID + 'editor.executeEdits("", [' + LB +
              '       { range: range, text: decodeURIComponent(escape(window.atob(allowPaste.text)))}' + LB +
              '     ]);' + LB +
              '     range = ' + AControlID + 'editor.getSelection();' + LB +
              '     ' + AControlID + 'editor.setPosition({lineNumber: range.endLineNumber, column: range.endColumn});' + LB +
              '     ' + AControlID + 'editor.pushUndoStop();' + LB +
              '   }' + LB +
              ' }, {capture: true});' + LB +
              ' ' + AControlID + 'editor.getContainerDomNode().addEventListener("drop", (event) => {' + LB +
              '   ' + AControlID + 'DragCounter = 0;' + LB +
              '   event.preventDefault();' + LB +
              '   event.stopPropagation();' + LB +
              '   var data = [];' + LB +
              '   var text = "";' + LB +
              '   ' + AControlID + 'DropFiles.clear;' + LB +
              '   if (event.dataTransfer.items) {' + LB +
              '      for (var i = 0; i < event.dataTransfer.items.length; i++) {' + LB +
              '       if (event.dataTransfer.items[i].kind === "file") {' + LB +
              '         const file = event.dataTransfer.items[i].getAsFile();' + LB +
              '         ' + AControlID + 'DropFiles.push(file);' + LB +
               '        var f = {' + LB +
              '           fileName: file.name,' + LB +
              '           fileSize: file.size,' + LB +
              '           MIMEType: file.type' + LB +
              '         }' + LB +
              '         data.push(f);' + LB +
              '       } else {' + LB +
              '         text = event.dataTransfer.getData("text");' + LB +
              '       }' + LB +
              '     };' + LB +
              '   } else {' + LB +
              '     for (var i = 0; i < event.dataTransfer.files.length; i++) {' + LB +
              '       ' + AControlID + 'DropFiles.push(event.dataTransfer.files[i]);' + LB +
              '        var f = {' + LB +
              '           fileName: file.name,' + LB +
              '           fileSize: file.size,' + LB +
              '           MIMEType: file.type' + LB +
              '         }' + LB +
              '         data.push(f);' + LB +
              '     };' + LB +
              '   }' + LB +
              '   var pos = ' + AControlID + 'editor.getTargetAtClientPoint(event.clientX, event.clientY).position;' + LB +
              '   pos.lineNumber--;' + LB +
              '   pos.column--;' + LB +
              '   var d = {' + LB +
              '     clientX: event.clientX,' + LB +
              '     clientY: event.clientY,' + LB +
              '     data: data, ' + LB +
              '     text: text,' + LB +
              '     pos: pos' + LB +
              '   }' + LB +
              '   ' + AControlID + 'SendEventData("' + MEMODRAGDROP + '", d);' + LB +
              ' }, true);' + LB +
//              ' ' + AControlID + 'editor.getContainerDomNode().addEventListener("drag", (event) => {' + LB +
//              '   event.preventDefault();' + LB +
//              '   event.stopPropagation();' + LB +
//              '   ' + AControlID + 'SendEventData("' + MEMODRAG + '",true);' + LB +
//              ' }, true);' + LB +
              ' ' + AControlID + 'editor.getContainerDomNode().addEventListener("dragend", (event) => {' + LB +
              '   event.preventDefault();' + LB +
              '   event.stopPropagation();' + LB +
              '   ' + AControlID + 'SendEventData("' + MEMODRAGEND + '",true);' + LB +
              ' }, true);' + LB +
              ' ' + AControlID + 'editor.getContainerDomNode().addEventListener("dragenter", (event) => {' + LB +
              '   event.preventDefault();' + LB +
              '   event.stopImmediatePropagation();' + LB +
              '   ' + AControlID + 'DragCounter++;' + LB +
              '   if (' + AControlID + 'DragCounter > 1) { return };' + LB +
              '   var data = [];' + LB +
              '   var text = "";' + LB +
              '   if (event.dataTransfer.items) {' + LB +
              '      for (var i = 0; i < event.dataTransfer.items.length; i++) {' + LB +
              '       if (event.dataTransfer.items[i].kind === "file") {' + LB +
              '        var f = {' + LB +
              '           fileName: "",' + LB +
              '           fileSize: 0,' + LB +
              '           MIMEType: event.dataTransfer.items[i].type' + LB +
              '         }' + LB +
              '         data.push(f);' + LB +
              '       } else {' + LB +
              '         text = event.dataTransfer.getData("text");' + LB +
              '       }' + LB +
              '     };' + LB +
              '   } else {' + LB +
              '     for (var i = 0; i < event.dataTransfer.files.length; i++) {' + LB +
              '        var f = {' + LB +
              '           fileName: "",' + LB +
              '           fileSize: 0,' + LB +
              '           MIMEType: event.dataTransfer.files[i].type' + LB +
              '         }' + LB +
              '         data.push(f);' + LB +
              '     };' + LB +
              '   }' + LB +
              '   var pos = ' + AControlID + 'editor.getTargetAtClientPoint(event.clientX, event.clientY).position;' + LB +
              '   pos.lineNumber--;' + LB +
              '   pos.column--;' + LB +
              '   var d = {' + LB +
              '     clientX: event.clientX,' + LB +
              '     clientY: event.clientY,' + LB +
              '     data: data, ' + LB +
              '     text: text,' + LB +
              '     pos: pos' + LB +
              '   }' + LB +
              '   ' + AControlID + 'SendEventData("' + MEMODRAGENTER + '", d);' + LB +
              ' }, true);' + LB +
              ' ' + AControlID + 'editor.getContainerDomNode().addEventListener("dragleave", (event) => {' + LB +
              '   event.preventDefault();' + LB +
              '   event.stopImmediatePropagation();' + LB +
              '   ' + AControlID + 'DragCounter--;' + LB +
              '   if (' + AControlID + 'DragCounter > 0) { return };' + LB +
              '   var data = [];' + LB +
              '   var text = "";' + LB +
              '   if (event.dataTransfer.items) {' + LB +
              '      for (var i = 0; i < event.dataTransfer.items.length; i++) {' + LB +
              '       if (event.dataTransfer.items[i].kind === "file") {' + LB +
              '        var f = {' + LB +
              '           fileName: "",' + LB +
              '           fileSize: 0,' + LB +
              '           MIMEType: event.dataTransfer.items[i].type' + LB +
              '         }' + LB +
              '         data.push(f);' + LB +
              '       } else {' + LB +
              '         text = event.dataTransfer.getData("text");' + LB +
              '       }' + LB +
              '     };' + LB +
              '   } else {' + LB +
              '     for (var i = 0; i < event.dataTransfer.files.length; i++) {' + LB +
              '        var f = {' + LB +
              '           fileName: "",' + LB +
              '           fileSize: 0,' + LB +
              '           MIMEType: event.dataTransfer.files[i].type' + LB +
              '         }' + LB +
              '         data.push(f);' + LB +
              '     };' + LB +
              '   }' + LB +
              '   var d = {' + LB +
              '     clientX: event.clientX,' + LB +
              '     clientY: event.clientY,' + LB +
              '     data: data, ' + LB +
              '     text: text,' + LB +
              '   }' + LB +
              '   ' + AControlID + 'SendEventData("' + MEMODRAGLEAVE + '",d);' + LB +
              ' }, true);' + LB +
              ' ' + AControlID + 'editor.getContainerDomNode().addEventListener("dragover", (event) => {' + LB +
              '   event.preventDefault();' + LB +
              '   event.stopPropagation();' + LB +
              '   var data = [];' + LB +
              '   var text = "";' + LB +
              '   if (event.dataTransfer.items) {' + LB +
              '      for (var i = 0; i < event.dataTransfer.items.length; i++) {' + LB +
              '       if (event.dataTransfer.items[i].kind === "file") {' + LB +
              '        var f = {' + LB +
              '           fileName: "",' + LB +
              '           fileSize: 0,' + LB +
              '           MIMEType: event.dataTransfer.items[i].type' + LB +
              '         }' + LB +
              '         data.push(f);' + LB +
              '       } else {' + LB +
              '         text = event.dataTransfer.getData("text");' + LB +
              '       }' + LB +
              '     };' + LB +
              '   } else {' + LB +
              '     for (var i = 0; i < event.dataTransfer.files.length; i++) {' + LB +
              '        var f = {' + LB +
              '           fileName: "",' + LB +
              '           fileSize: 0,' + LB +
              '           MIMEType: event.dataTransfer.files[i].type' + LB +
              '         }' + LB +
              '         data.push(f);' + LB +
              '     };' + LB +
              '   }' + LB +
              '   var pos = ' + AControlID + 'editor.getTargetAtClientPoint(event.clientX, event.clientY).position;' + LB +
              '   pos.lineNumber--;' + LB +
              '   pos.column--;' + LB +
              '   var d = {' + LB +
              '     clientX: event.clientX,' + LB +
              '     clientY: event.clientY,' + LB +
              '     data: data, ' + LB +
              '     text: text,' + LB +
              '     pos: pos' + LB +
              '   }' + LB +
              '   ' + AControlID + 'SendEventData("' + MEMODRAGOVER + '",d);' + LB +
              ' }, true);' + LB +
              ' ' + AControlID + 'editor.onContextMenu(() => {' + LB +
              '  setTimeout(() => {' + LB +
              '    const shadowroot = document.querySelector(".shadow-root-host")?.shadowRoot;' + LB +
              '    if (shadowroot) {     ' + LB +
              '        const li = shadowroot.querySelectorAll(' + LB +
              '          ".action-item"' + LB +
              '        );' + LB +
              '        if (navigator.userAgent.search("Firefox") > -1) { return }' + LB +
              '        var f = false;' + LB +
              '        li.forEach((el) => {' + LB +
              '            if (f) {return}' + LB +
              '            const a = el.querySelector(''[aria-label="Paste"]'');' + LB +
              '            if (a) {' + LB +
              '                el.querySelector(''a'')' + LB +
              '                ?.querySelectorAll(''span'')' + LB +
              '                .forEach((s) => s.remove());' + LB +
              '                 el.querySelector(''a'')?.remove();' + LB +
              '                f = true;' + LB +
              '            }' + LB +
              '        })' + LB +
              '    }' + LB +
              '  }, 0);' + LB +
              '});' + LB +
              '' + LB +
              '' + AControlID + 'editor.addAction({' + LB +
              '  id: "editor.action.clipboardPasteAction",' + LB +
              '  label: "Paste",' + LB +
              '  contextMenuGroupId: "9_cutcopypaste",' + LB +
              '  contextMenuOrder: 4,' + LB +
              '  run: (editor) => {' + LB +
              '    navigator.clipboard.readText().then(async (text) => {' + LB +
              '     var allowPaste = await ' + AControlID + 'CheckPaste(text);' + LB +
              '     if (allowPaste.allow) {' + LB +
              '     var range = ' + AControlID + 'editor.getSelection();' + LB +
              '     ' + AControlID + 'editor.executeEdits("", [' + LB +
              '       { range: range, text: allowPaste.text }' + LB +
              '     ]);' + LB +
              '     range = ' + AControlID + 'editor.getSelection();' + LB +
              '     ' + AControlID + 'editor.setPosition({lineNumber: range.endLineNumber, column: range.endColumn});' + LB +
              '     ' + AControlID + 'editor.pushUndoStop();' + LB +
              '   }' + LB +
              '    })' + LB +
              '  }' + LB +
              '})' + LB +
              ' ' + ACOntrolID + 'editor.onDidBlurEditorText((event) => {' + LB +
              '    ' + AControlID + 'SendEventData("' + MEMOLOSEFOCUS + '", true);' + LB +
              ' });' + LB +
              ' ' + ACOntrolID + 'editor.onDidFocusEditorText((event) => {' + LB +
              '    ' + AControlID + 'SendEventData("' + MEMOGETFOCUS + '", true);' + LB +
              ' });' + LB +
              ' ' + ACOntrolID + 'editor.onDidPaste((event) => {' + LB +
              '    ' + AControlID + 'SendEventData("' + MEMOPASTE + '", event);' + LB +
              ' });' + LB +
              ' ' + ACOntrolID + 'editor.onDidScrollChange((event) => {' + LB +
              '    ' + AControlID + 'SendEventData("' + MEMOSCROLLCHANGE + '", event);' + LB +
              ' });' + LB +
              ' ' + ACOntrolID + 'editor.onKeyDown((event) => {' + LB +
              '   if ((event.browserEvent.keyCode == 9) && (' + AControlID + 'WantTab)) {' + LB +
              '     ' + AControlID + 'SendEventData("' + MEMOTABPRESSED + '", event.shiftKey);' + LB +
              '     document.activeElement.blur();' + LB +
              '   }' + LB +
              '    var ShiftState = [];' + LB +
              '    if (event.altKey) { ShiftState.push("ssAlt")};' + LB +
              '    if (event.ctrlKey) { ShiftState.push("ssCtrl")};' + LB +
              '    if (event.shiftKey) { ShiftState.push("ssShift")};' + LB +
              '    var data = {' + LB +
              '       keyCode: event.browserEvent.keyCode,' + LB +
              '       key: event.browserEvent.key,' + LB +
              '       shiftState: ShiftState' + LB +
              '    };' + LB +
              '    ' + AControlID + 'SendEventData("' + MEMODOKEYDOWN + '", data);' + LB +
              ' });' + LB +
              ' ' + ACOntrolID + 'editor.onKeyUp((event) => {' + LB +
              '    var ShiftState = [];' + LB +
              '    if (event.altKey) { ShiftState.push("ssAlt")};' + LB +
              '    if (event.ctrlKey) { ShiftState.push("ssCtrl")};' + LB +
              '    if (event.shiftKey) { ShiftState.push("ssShift")};' + LB +
              '    var data = {' + LB +
              '       keyCode: event.browserEvent.keyCode,' + LB +
              '       key: event.browserEvent.key,' + LB +
              '       shiftState: ShiftState' + LB +
              '    };' + LB +
              '    ' + AControlID + 'SendEventData("' + MEMODOKEYUP + '", data);' + LB +
              ' });' + LB +
              ' ' + ACOntrolID + 'editor.onMouseDown((e) => {' + LB +
              '    var ShiftState = [];' + LB +
              '    var gutterClick = false;' + LB +
              '    var lineClick = false;' + LB +
              '    var lineNumber = -1;' + LB +
              '    if (e.event.altKey) { ShiftState.push("ssAlt")};' + LB +
              '    if (e.event.ctrlKey) { ShiftState.push("ssCtrl")};' + LB +
              '    if (e.event.shiftKey) { ShiftState.push("ssShift")};' + LB +
              '    if (e.event.leftButton) { ShiftState.push("ssLeft")};' + LB +
              '    if (e.event.middleButton) { ShiftState.push("ssMiddle")};' + LB +
              '    if (e.event.rightButton) { ShiftState.push("ssRight")};' + LB +
              '    if (e.target.type == window.monaco.editor.MouseTargetType.GUTTER_GLYPH_MARGIN) {gutterClick = true};' + LB +
              '    if (e.target.type == window.monaco.editor.MouseTargetType.GUTTER_LINE_NUMBERS) {lineClick = true};' + LB +
              '    if (e.target.position != null){' + LB +
              '       lineNumber = e.target.position.lineNumber - 1;' + LB +
              '    }' + LB +
              '    var data = {' + LB +
              '       posx: e.event.posx,' + LB +
              '       posy: e.event.posy,' + LB +
              '       shiftState: ShiftState,' + LB +
              '       gutterClick: gutterClick,' + LB +
              '       lineClick: lineClick,' + LB +
              '       lineNumber: lineNumber' + LB +
              '    };' + LB +
              '    ' + AControlID + 'SendEventData("' + MEMODOMOUSEDOWN + '", data);' + LB +
              ' });' + LB +
               ' ' + ACOntrolID + 'editor.onMouseLeave((event) => {' + LB +
              '    ' + AControlID + 'SendEventData("' + MEMODOMOUSELEAVE + '", true);' + LB +
              ' });' + LB +
              ' ' + ACOntrolID + 'editor.onMouseMove((e) => {' + LB +
              '    var ShiftState = [];' + LB +
              '    if (e.event.altKey) { ShiftState.push("ssAlt")};' + LB +
              '    if (e.event.ctrlKey) { ShiftState.push("ssCtrl")};' + LB +
              '    if (e.event.shiftKey) { ShiftState.push("ssShift")};' + LB +
              '    if (e.event.leftButton) { ShiftState.push("ssLeft")};' + LB +
              '    if (e.event.middleButton) { ShiftState.push("ssMiddle")};' + LB +
              '    if (e.event.rightButton) { ShiftState.push("ssRight")};' + LB +
              '    var data = {' + LB +
              '       posx: e.event.posx,' + LB +
              '       posy: e.event.posy,' + LB +
              '       shiftState: ShiftState' + LB +
              '    };' + LB +
              '    ' + AControlID + 'SendEventData("' + MEMODOMOUSEMOVE + '", data);' + LB +
              ' });' + LB +
              ' ' + ACOntrolID + 'editor.onMouseUp((e) => {' + LB +
              '    var ShiftState = [];' + LB +
              '    if (e.event.altKey) { ShiftState.push("ssAlt")};' + LB +
              '    if (e.event.ctrlKey) { ShiftState.push("ssCtrl")};' + LB +
              '    if (e.event.shiftKey) { ShiftState.push("ssShift")};' + LB +
              '    if (e.event.leftButton) { ShiftState.push("ssLeft")};' + LB +
              '    if (e.event.middleButton) { ShiftState.push("ssMiddle")};' + LB +
              '    if (e.event.rightButton) { ShiftState.push("ssRight")};' + LB +
              '    var data = {' + LB +
              '       posx: e.event.posx,' + LB +
              '       posy: e.event.posy,' + LB +
              '       shiftState: ShiftState' + LB +
              '    };' + LB +
              '    ' + AControlID + 'SendEventData("' + MEMODOMOUSEUP + '", data);' + LB +
              ' });' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'FetchSuggestions(){' + LB +
              '  return new Promise( (resolve, reject) =>  {' + LB +
              '   ' + AControlID + 'SuggestionResolve = resolve;' + LB +
              '   ' + AControlID + 'SendEventData("' + FETCHSUGGESTIONS + '", true)' + LB +
              '  });' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'CheckPaste(text){' + LB +
              '  return new Promise( (resolve, reject) =>  {' + LB +
              '   ' + AControlID + 'PasteResolve = resolve;' + LB +
              '   ' + AControlID + 'SendEventData("' + FETCHPASTE + '", text)' + LB +
              '  });' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'setSuggestions(sug){' + LB +
              '   console.log(sug);' + LB +
              '  ' + AControlID + 'Suggestions = sug' + LB +
              ' console.log(' + AControlID + 'Suggestions);' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'GetPosition() {' + LB +
              ' if (' + AControlID + 'Initialized) {' + LB +
              '  return ' + AControlID + 'editor.getPosition();' + LB +
              ' }' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'SetLanguage(lang) {' + LB +
              ' if (' + AControlID + 'Initialized) {' + LB +
              '   var model = ' + AControlID + 'editor.getModel();' + LB +
              '   window.monaco.languages.registerCompletionItemProvider(lang, {' + LB +
              '     provideCompletionItems: async () => {' + LB +
              '     ' + AControlID + 'Suggestions = await ' + AControlID + 'FetchSuggestions();' + LB +
              '       return { suggestions: ' + AControlID + 'Suggestions };' + LB +
              '     }' + LB +
              '   });' + LB +
              '   window.monaco.editor.setModelLanguage(model, lang);' + LB +
              ' }' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'setTheme(theme) {' + LB +
              '   window.monaco.editor.setTheme(theme);' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'SetSelect(selStart, selStop) {' + LB +
              ' if (' + AControlID + 'Initialized) {' + LB +
              '   const startPos = ' + AControlID + 'editor.getModel().getPositionAt(selStart);' + LB +
              '   const stopPos = ' + AControlID + 'editor.getModel().getPositionAt(selStop);' + LB +
              '   ' + AControlID + 'editor.setSelection({' + LB +
              '     startColumn: startPos.column,' + LB +
              '     startLineNumber: startPos.lineNumber,' + LB +
              '     endColumn: stopPos.column,' + LB +
              '     endLineNumber: stopPos.lineNumber' + LB +
              '   });' + LB +
              ' }' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'InsertLine(index, s, a) {' + LB +
              ' if (' + AControlID + 'Initialized) {' + LB +
              '   s = decodeURIComponent(escape(window.atob(s)));' + LB +
              '   var range;' + LB +
              '   var t = "";' + LB +
              '   if (a){' + LB +
              '     range = new window.monaco.Range(index, 1, index, 1);' + LB +
              '     if (index > ' + AControlID + 'editor.getModel().getLineCount()) {' + LB +
              '       t =  "\r\n" + s;' + LB +
              '     }else{ ' + LB +
              '       t =  s + "\r\n";' + LB +
              '     }' + LB +
              '   }else{' + LB +
              '     t = s' + LB +
              '     range = new window.monaco.Range(index, 1, index, ' + AControlID + 'editor.getModel().getLineContent(index).length + 1);' + LB +
              '   }' + LB +
              '   var sel = ' + AControlID + 'editor.getSelection();' + LB +
              '   ' + AControlID + 'editor.executeEdits("append", [{range: range, text: t}], () => null );' + LB +
              '   ' + AControlID + 'editor.pushUndoStop();' + LB +
              '   ' + AControlID + 'editor.setSelection(sel);' + LB +
              '   return index + 1;' +
              ' }else { return 0 }' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'DeleteLine(index) {' + LB +
              ' if (' + AControlID + 'Initialized) {' + LB +
              '   const range = new window.monaco.Range(index , 0, index +1, 0);' + LB +
              '   ' + AControlID + 'editor.executeEdits("append", [{range: range, text: null}]);' + LB +
              '   ' + AControlID + 'editor.pushUndoStop();' + LB +
              '   return index + 1;' +
              ' } else { return 0 }' + LB +
              '}' + LB + LB +
              'function ' + AControlID +'GetSelection() {' + LB +
              ' if(' + AControlID + 'Initialized) {' + LB +
              '   var sel = ' + AControlID + 'editor.getSelection();' + LB +
              '   var startPos = new window.monaco.Position(  sel.startLineNumber,  sel.startColumn );' + LB +
              '   var endPos = new window.monaco.Position(  sel.endLineNumber,  sel.endColumn );' + LB +
              '   var selStart = ' + AControlID + 'editor.getModel().getOffsetAt(startPos);' + LB +
              '   var selStop = ' + AControlID + 'editor.getModel().getOffsetAt(endPos);' + LB +
              '   var d = {selStart: selStart, selStop: selStop};' + LB +
              '   return d.ToJSON;' + LB +
              ' }' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'SelectAll() {' + LB +
              ' if (' + AControlID + 'Initialized) {' + LB +
              '   var range = ' + AControlID + 'editor.getModel().getFullModelRange();' + LB +
              '   ' + AControlID + 'editor.setSelection(range)' + LB +
              ' }' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'SetPosition(line, pos) {' +
              ' if (' + AControlID + 'Initialized) {' + LB +
              '   var posi = new window.monaco.Position(line, pos);' + LB +
              '   ' + AControlID + 'editor.setPosition(posi);' + LB +
              ' }' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'SetValue(value) {' + LB +
              ' if (' + AControlID + 'Initialized) {' + LB +
              '   value = decodeURIComponent(escape(window.atob(value)));' + LB +
              '   ' + AControlID + 'editor.setValue(value);' + LB +
              '   ' + AControlID + 'editor.pushUndoStop();' + LB +
              '   ' + AControlID + 'editor.setScrollPosition({scrollTop: 0});' + LB +
              ' }' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'GetValue(){' + LB +
              ' if (' + AControlID + 'Initialized) {' + LB +
              '   return encodeURIComponent(' + AControlID + 'editor.getValue());' + LB +
              ' }' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'GetWordAtPosition(position){' + LB +
              ' var pos = ' + AControlID + 'editor.getModel().getPositionAt(position);' + LB +
              ' var w = ' + AControlID + 'editor.getModel().getWordAtPosition(pos); ' + LB +
              ' if (w === null) { return "" }' + LB +
              ' return encodeURIComponent(w.word);' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'GetWordAtCursor(){' + LB +
              ' var pos = ' + AControlID + 'editor.getPosition();' + LB +
              ' var w = ' + AControlID + 'editor.getModel().getWordAtPosition(pos); ' + LB +
              ' if (w === null) { return "" }' + LB +
              ' return encodeURIComponent(w.word);' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'GetValueAtRange(selStart, selStop){' + LB +
              ' if (' + AControlID + 'Initialized) {' + LB +
              '   var startPos = ' + AControlID + 'editor.getModel().getPositionAt(selStart);' + LB +
              '   var stopPos = ' + AControlID + 'editor.getModel().getPositionAt(selStop);' + LB +
              '   var range = {startColumn: startPos.column, startLineNumber: startPos.lineNumber, endColumn: stopPos.column, endLineNumber: stopPos.lineNumber};' + LB +
              '   return encodeURIComponent(' + AControlID + 'editor.getModel().getValueInRange(range));' + LB +
              ' }' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'FindMatches(s, searchOnlyEditableRange, isRegex, matchCase, wordSeparators, captureMatches, limitResultCount){' + LB +
              ' if (limitResultCount === undefined) {' + LB +
              '   var matches = ' + AControlID + 'editor.getModel().findMatches(s, searchOnlyEditableRange, isRegex, matchCase, wordSeparators, captureMatches);' + LB +
              ' } else  {' + LB +
              '   var matches = ' + AControlID + 'editor.getModel().findMatches(s, searchOnlyEditableRange, isRegex, matchCase, wordSeparators, captureMatches, limitResultCount);' + LB +
              ' }' + LB +
              ' matches.forEach(function(match) {'  + LB +
              '   match.selStart = ' + AControlID + 'editor.getModel().getOffsetAt({lineNumber: match.range.startLineNumber, column: match.range.startColumn});'  + LB +
              '   match.selStop = ' + AControlID + 'editor.getModel().getOffsetAt({lineNumber: match.range.endLineNumber, column: match.range.endColumn});' + LB +
              ' });' + LB +
              ' return matches;' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'FindMatchesInRange(s, selStart, selStop, isRegex, matchCase, wordSeparators, captureMatches, limitResultCount){' + LB +
              ' const startPos = ' + AControlID + 'editor.getModel().getPositionAt(selStart);' + LB +
              ' const stopPos = ' + AControlID + 'editor.getModel().getPositionAt(selStop);' + LB +
              ' var range = {' + LB +
              '     startColumn: startPos.column,' + LB +
              '     startLineNumber: startPos.lineNumber,' + LB +
              '     endColumn: stopPos.column,' + LB +
              '     endLineNumber: stopPos.lineNumber' + LB +
              ' };' + LB +
               ' if (limitResultCount === undefined) {' + LB +
              '   var matches = ' + AControlID + 'editor.getModel().findMatches(s, range, isRegex, matchCase, wordSeparators, captureMatches);' + LB +
              ' } else  {' + LB +
              '   var matches = ' + AControlID + 'editor.getModel().findMatches(s,range, isRegex, matchCase, wordSeparators, captureMatches, limitResultCount);' + LB +
              ' }' + LB +
              ' matches.forEach(function(match) {'  + LB +
              '   match.selStart = ' + AControlID + 'editor.getModel().getOffsetAt({lineNumber: match.range.startLineNumber, column: match.range.startColumn});'  + LB +
              '   match.selStop = ' + AControlID + 'editor.getModel().getOffsetAt({lineNumber: match.range.endLineNumber, column: match.range.endColumn});' + LB +
              ' });' + LB +
              ' return matches;' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'FindNextMatch(s, selection, isRegEx, matchCase, wordSeparators, captureMatches){' + LB +
              ' var match = ' + AControlID + 'editor.getModel().findNextMatch(s,' + AControlID + 'editor.getPosition(), isRegEx, matchCase, wordSeparators, captureMatches);' + LB +
              ' match.selStart = ' + AControlID + 'editor.getModel().getOffsetAt({lineNumber: match.range.startLineNumber, column: match.range.startColumn});'  + LB +
              ' match.selStop = ' + AControlID + 'editor.getModel().getOffsetAt({lineNumber: match.range.endLineNumber, column: match.range.endColumn});' + LB +
              ' if (selection) {' + LB +
              '   ' + AControlID + 'editor.setSelection(match.range);' + LB +
              ' };' + LB +
              ' return match;' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'FindPreviousMatch(s, selection, isRegEx, matchCase, wordSeparators, captureMatches){' + LB +
              ' var match = ' + AControlID + 'editor.getModel().findPreviousMatch(s,' + AControlID + 'editor.getPosition(), isRegEx, matchCase, wordSeparators, captureMatches);' + LB +
              ' match.selStart = ' + AControlID + 'editor.getModel().getOffsetAt({lineNumber: match.range.startLineNumber, column: match.range.startColumn});'  + LB +
              ' match.selStop = ' + AControlID + 'editor.getModel().getOffsetAt({lineNumber: match.range.endLineNumber, column: match.range.endColumn});' + LB +
              ' if (selection) {' + LB +
              '   ' + AControlID + 'editor.setSelection(match.range);' + LB +
              ' };' + LB +
              ' return match;' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'FindNextMatchAt(s, pos, selection, isRegEx, matchCase, wordSeparators, captureMatches){' + LB +
              ' var match = ' + AControlID + 'editor.getModel().findNextMatch(s,' + AControlID + 'editor.getModel().getPositionAt(pos), isRegEx, matchCase, wordSeparators, captureMatches);' + LB +
              ' match.selStart = ' + AControlID + 'editor.getModel().getOffsetAt({lineNumber: match.range.startLineNumber, column: match.range.startColumn});'  + LB +
              ' match.selStop = ' + AControlID + 'editor.getModel().getOffsetAt({lineNumber: match.range.endLineNumber, column: match.range.endColumn});' + LB +
              ' if (selection) {' + LB +
              '   ' + AControlID + 'editor.setSelection(match.range);' + LB +
              ' };' + LB +
              ' return match;' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'FindPreviousMatchAt(s, pos, selection, isRegEx, matchCase, wordSeparators, captureMatches){' + LB +
              ' var match = ' + AControlID + 'editor.getModel().findPreviousMatch(s,' + AControlID + 'editor.getModel().getPositionAt(pos), isRegEx, matchCase, wordSeparators, captureMatches);' + LB +
              ' match.selStart = ' + AControlID + 'editor.getModel().getOffsetAt({lineNumber: match.range.startLineNumber, column: match.range.startColumn});'  + LB +
              ' match.selStop = ' + AControlID + 'editor.getModel().getOffsetAt({lineNumber: match.range.endLineNumber, column: match.range.endColumn});' + LB +
              ' if (selection) {' + LB +
              '   ' + AControlID + 'editor.setSelection(match.range);' + LB +
              ' };' + LB +
              ' return match;' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'Replace(selStart, selStop, replaceString) {' + LB +
              ' replaceString = decodeURIComponent(replaceString);' + LB +
              ' const startPos = ' + AControlID + 'editor.getModel().getPositionAt(selStart);' + LB +
              ' const stopPos = ' + AControlID + 'editor.getModel().getPositionAt(selStop);' + LB +
              ' var range = {' + LB +
              '     startColumn: startPos.column,' + LB +
              '     startLineNumber: startPos.lineNumber,' + LB +
              '     endColumn: stopPos.column,' + LB +
              '     endLineNumber: stopPos.lineNumber' + LB +
              ' };' + LB +
              ' ' + AControlID + 'editor.executeEdits("", [' + LB +
              '   { range: range, text: replaceString }' + LB +
              ' ]);' + LB +
              '   ' + AControlID + 'editor.pushUndoStop();' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'ReplaceNext(s, replaceString, isRegEx, matchCase, wordSeparators, captureMatches){' + LB +
              ' var match = ' + AControlID + 'editor.getModel().findNextMatch(s,' + AControlID + 'editor.getPosition(), isRegEx, matchCase, wordSeparators, captureMatches);' + LB +
              '   ' + AControlID + 'editor.setSelection(match.range);' + LB +
              ' ' + AControlID + 'editor.executeEdits("", [' + LB +
              '   { range: match.range, text: replaceString }' + LB +
              ' ]);' + LB +
              '   ' + AControlID + 'editor.pushUndoStop();' + LB +
              ' match.selStart = ' + AControlID + 'editor.getModel().getOffsetAt({lineNumber: match.range.startLineNumber, column: match.range.startColumn});'  + LB +
              ' match.selStop = ' + AControlID + 'editor.getModel().getOffsetAt({lineNumber: match.range.endLineNumber, column: match.range.endColumn});' + LB +
              '   return {o: s, r: replaceString, m: match};' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'ReplaceCurrent(replaceString){' + LB +
              '  var range = ' + AControlID + 'editor.getSelection();' + LB +
              ' ' + AControlID + 'editor.executeEdits("", [' + LB +
              '   { range: range, text: replaceString }' + LB +
              ' ]);' + LB +
              '   ' + AControlID + 'editor.pushUndoStop();' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'ReplacePrevious(s, replaceString, isRegEx, matchCase, wordSeparators, captureMatches){' + LB +
              ' var match = ' + AControlID + 'editor.getModel().findPreviousMatch(s,' + AControlID + 'editor.getPosition(), isRegEx, matchCase, wordSeparators, captureMatches);' + LB +
              '   ' + AControlID + 'editor.setSelection(match.range);' + LB +
              ' ' + AControlID + 'editor.executeEdits("", [' + LB +
              '   { range: match.range, text: replaceString }' + LB +
              ' ]);' + LB +
              '   ' + AControlID + 'editor.pushUndoStop();' + LB +
              ' match.selStart = ' + AControlID + 'editor.getModel().getOffsetAt({lineNumber: match.range.startLineNumber, column: match.range.startColumn});'  + LB +
              ' match.selStop = ' + AControlID + 'editor.getModel().getOffsetAt({lineNumber: match.range.endLineNumber, column: match.range.endColumn});' + LB +
              '   return {o: s, r: replaceString, m: match};' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'ReplaceAll(s, replaceString, searchOnlyEditableRange, isRegex, matchCase, wordSeparators, captureMatches){' + LB +
              '  var matches = ' + AControlID + 'editor.getModel().findMatches(s, searchOnlyEditableRange, isRegex, matchCase, wordSeparators, captureMatches);' + LB +
              '  var edits = [];' + LB +
              '  matches.forEach( match => {' + LB +
              '   edits.push({range: match.range, text: replaceString});' + LB +
              '  });' + LB +
              ' ' + AControlID + 'editor.executeEdits("", edits);' + LB +
              '   ' + AControlID + 'editor.pushUndoStop();' + LB +
              ' matches.forEach(function(match) {'  + LB +
              '   match.selStart = ' + AControlID + 'editor.getModel().getOffsetAt({lineNumber: match.range.startLineNumber, column: match.range.startColumn});'  + LB +
              '   match.selStop = ' + AControlID + 'editor.getModel().getOffsetAt({lineNumber: match.range.endLineNumber, column: match.range.endColumn});' + LB +
              ' });' + LB +
              ' return {o: s, r: replaceString, m: matches};' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'SetBreakPoints(decorations) {' + LB +
              '   var dec = decorations.split(";");' +
              '   var deco = [];' + LB +
              '   dec.forEach(i => {' + LB +
              '     if (i != "") {' + LB +
              '       var j = { ' + LB +
              '         range: {startLineNumber: parseInt(i) + 1,startColumn: 1,endLineNumber: parseInt(i) + 1,endColumn: 1},' +  LB +
              '         options: {isWholeLine: true, className: "breakPoint", glyphMarginClassName: "codicon codicon-debug-breakpoint", stickiness: 1, description:"breakpoint"}'+ LB +
              '       };' + LB +
              '       deco.push(j);' + LB +
              '     }' + LB +
              '   });' + LB +
              '   ' + AControlID + 'Breakpoints.set(deco);' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'SetBookmarks(decorations) {' + LB +
              '   var dec = decorations.split(";");' +
              '   var deco = [];' + LB +
              '   dec.forEach(i => {' + LB +
              '     if (i != "") {' + LB +
              '       var j = { ' + LB +
              '         range: {startLineNumber: parseInt(i) + 1,startColumn: 1,endLineNumber: parseInt(i) + 1,endColumn: 1},' +  LB +
              '         options: {isWholeLine: true, className: "", glyphMarginClassName: "codicon codicon-custombookmark", stickiness: 1, description:"bookmark"}'+ LB +
              '       };' + LB +
              '       deco.push(j);' + LB +
              '     }' + LB +
              '   });' + LB +
              ' ' + AControlID + 'Bookmarks.set(deco);' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'SetLineHighlight(decorations) {' + LB +
              '   var dec = decorations.split(";");' +
              '   var deco = [];' + LB +
              '   dec.forEach(i => {' + LB +
              '     if (i != "") {' + LB +
              '       var j = { ' + LB +
              '         range: {startLineNumber: parseInt(i) + 1,startColumn: 1,endLineNumber: parseInt(i) + 1,endColumn: 1},' +  LB +
              '         options: {isWholeLine: true, className: "lineHighlight", stickiness: 1, description:"lineHighlight"}'+ LB +
              '       };' + LB +
              '       deco.push(j);' + LB +
              '     }' + LB +
              '   });' + LB +
              ' ' + AControlID + 'Highlights.set(deco);' + LB +
              '}' + LB + LB +
              'function ' + AControlID + 'OpenFile(f) {' +
              ' var fi = null;' + LB +
              ' for (var i = 0; i < ' + AControlID + 'DropFiles.length; i++) {' + LB +
              '   if (' + AControlID + 'DropFiles[i].name === f) {' + LB +
              '     fi = ' + AControlID + 'DropFiles[i];' + LB +
              '     var fr = new FileReader();' + LB +
              '     fr.readAsText(fi);' + LB +
              '     fr.onloadend = function() {' + LB +
              '       ' + AControlID + 'editor.setValue(fr.result);' + LB +
              '     }' + LB +
              '     return;' + LB +
              '   }' + LB +
              ' }' + LB +
              '}' + LB +
              'function ' + AControlID + 'SendEventData(EventName, Data) {' + LB +
              '  var o = ' + ADataObject + ';' + LB +
              '  o["EventName"] = EventName;' + LB +
              '  var customdata = Data;' + LB +
              '  var cd = encodeURIComponent(JSON.stringify(customdata))' + LB +
              '  ' + AControlID + 'sendObjectMessage(JSON.stringify(o), cd);' + LB +
              '}';
end;

end.

