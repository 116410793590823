unit uMedienAuswahl;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Forms, ufrFotosListe, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Imaging.pngimage, Vcl.Controls, WEBLib.ExtCtrls,
  WEBLib.WebCtrls, utb, XData.Web.Client, ufrFotosThumb, ufrVideoListe;

type
  TfrmMedienAuswahl = class(TForm)
    WebHTMLDiv1: THTMLDiv;
    divFotos: THTMLDiv;
    frFotosliste1: TfrFotosliste;
    Client: TXDataWebClient;
    divVideos: THTMLDiv;
    frVideoListe1: TfrVideoListe;
    divFooter: THTMLDiv;
    WebButton1: TButton;
    OpenDlg: TOpenDialog;
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebButton1Click(Sender: TObject);
    [async] procedure imgLoaded(Sender: TObject); async;
    procedure OpenDlgChange(Sender: TObject);
    procedure OpenDlgGetFileAsBase64(Sender: TObject; AFileIndex: Integer;
      ABase64: string);
    procedure frFotosliste1imgClick(Sender: TObject);
  private
    FCaption, FSize1, FSize2, FSize3 :string;
    Fimg: TImageControl;
    FStatus, FNewID:integer;
    FEdit:boolean;
    FCallback:TCallback;
    FContainer:THtmlDiv;
    FOwner : THTMLDiv;
    FAlbumID, FPostId, FBlogID, FKursID:integer;
    FMedienID:integer;
    FHolder, FMe:integer;
    bTitel:boolean;
    FID:integer;
  public
    [async] procedure Callback(s:string); async;
    class function Fotos(AHolder, AMe, AAlbumID, APostID, ABlogID, AKursID, AStartID:integer; ACallback:TCallback; AAddImage:integer): integer;
    class function Videos(AHolder, AMe, AAlbumID, APostID, ABlogID, AKursID, AStartID:integer; ACallback:TCallback; AAddImage:integer): integer;
  protected procedure LoadDFMValues; override; end;

var
  frmMedienAuswahl: TfrmMedienAuswahl;

implementation
  uses uDatenmodul, uMain, uEdit ;

{$R *.dfm}
class function TfrmMedienAuswahl.Videos(AHolder, AMe, AAlbumID, APostID, ABlogID, AKursID, AStartID:integer; ACallback:TCallback; AAddImage:integer): integer;

  [async] procedure AfterCreate(AForm: TObject); async;
  Var
    Response: TXDataClientResponse;
    s:string;
    J:TJ;
  begin
    mainform.Wait := 1;
    TfrmMedienAuswahl(AForm).divFotos.Visible := false;
    TfrmMedienAuswahl(AForm).ElementHandle.style.removeProperty('position');
    TfrmMedienAuswahl(AForm).Parent   := Mainform;
    TfrmMedienAuswahl(AForm).FHolder  := AHolder;
    TfrmMedienAuswahl(AForm).FMe      := AMe;
    TfrmMedienAuswahl(AForm).FAlbumID := AAlbumID;
    TfrmMedienAuswahl(AForm).FBlogID  := ABlogID;
    TfrmMedienAuswahl(AForm).FPostID  := APostID;
    TfrmMedienAuswahl(AForm).FKursID  := AKursID;
    TfrmMedienAuswahl(AForm).FCallback:= ACallback;
    TfrmMedienAuswahl(AForm).frVideoliste1.img.elementhandle.style.setProperty('filter', 'invert(1)');
    await(TfrmMedienAuswahl(AForm).frVideoListe1.Init(mainform.Holder.ID, mainform.ME.ID,  AAlbumID, APostID, ABlogID, AKursID, AStartID, 1, @TfrmMedienAuswahl(AForm).Callback, false, AAddImage));
    TfrmMedienAuswahl(AForm).divFooter.Visible := true;
    mainform.Wait := -1;
  end;

Var
  frm:TfrmMedienAuswahl;
  FConti:THtmlDiv;
begin
  FConti := THtmlDiv.Create(Mainform);
  FConti.Parent := Mainform;
  FConti.ElementClassName := 'editor_transparent';
  FConti.HeightStyle:= ssAuto;
  FConti.WidthStyle := ssAuto;
  FConti.ElementFont := efCSS;
  FConti.ElementHandle.style.removeProperty('height');
  frm := TfrmMedienAuswahl.CreateNew(FConti.ElementID, @AfterCreate);
  frm.FContainer := FConti;
  frm.FCallback  := ACallback;
end;

class function TfrmMedienAuswahl.Fotos(AHolder, AMe, AAlbumID, APostID, ABlogID, AKursID, AStartID:integer; ACallback:TCallback; AAddImage:integer): integer;

  [async] procedure AfterCreate(AForm: TObject); async;
  Var
    Response: TXDataClientResponse;
    s:string;
    J:TJ;
  begin
    mainform.Wait := 1;
    TfrmMedienAuswahl(AForm).divVideos.Visible := false;

    TfrmMedienAuswahl(AForm).ElementHandle.style.removeProperty('position');
    TfrmMedienAuswahl(AForm).Parent   := Mainform;
    TfrmMedienAuswahl(AForm).FHolder  := AHolder;
    TfrmMedienAuswahl(AForm).FMe      := AMe;
    TfrmMedienAuswahl(AForm).FAlbumID := AAlbumID;
    TfrmMedienAuswahl(AForm).FBlogID  := ABlogID;
    TfrmMedienAuswahl(AForm).FPostID  := APostID;
    TfrmMedienAuswahl(AForm).FKursID  := AKursID;
    TfrmMedienAuswahl(AForm).FCallback:= ACallback;
    TfrmMedienAuswahl(AForm).frFotosliste1.img.elementhandle.style.setProperty('filter', 'invert(1)');
    await(TfrmMedienAuswahl(AForm).frFotosliste1.Init(AHolder, AMe,  AAlbumID, APostID, ABlogID, AKursID, AStartID, 1, @TfrmMedienAuswahl(AForm).Callback, false, AAddImage));
//    await(TfrmMedienAuswahl(AForm).frFotosliste1.Init(mainform.Holder.ID, mainform.ME.ID,  AAlbumID, APostID, ABlogID, AKursID, AStartID, 1, @TfrmMedienAuswahl(AForm).Callback, false, AAllowAddImage));
    TfrmMedienAuswahl(AForm).divFooter.Visible := true;
    mainform.Wait := -1;
  end;

Var
  frm:TfrmMedienAuswahl;
  FConti:THtmlDiv;
begin
  FConti := THtmlDiv.Create(Mainform);
  FConti.Parent := Mainform;
  FConti.ElementClassName := 'editor_transparent';
  FConti.HeightStyle:= ssAuto;
  FConti.WidthStyle := ssAuto;
  FConti.ElementFont := efCSS;
  FConti.ElementHandle.style.removeProperty('height');
  frm := TfrmMedienAuswahl.CreateNew(FConti.ElementID, @AfterCreate);
  frm.FContainer := FConti;
  frm.FCallback  := ACallback;
end;

procedure TfrmMedienAuswahl.Callback(s:string);
begin
  if assigned(FCallback)
  then begin
    await(FCallback(s));
  end;

  close;
end;

procedure TfrmMedienAuswahl.imgLoaded(Sender: TObject);
//wird aufgerufen wenn neues Foto von der Festplatte geladen wird
  [async]
  procedure Callback1(a:array of string); async;
  begin
    if a[0] + a[1] > ''
    then await(datenmodul.easyExec('Update MEDIEN Set Titel = ''' + a[0] + ''', Text = ''' + a[1] + ''' where ID = ' + FNewID.tostring));
  end;

var
  i:integer;
  sName:string;
  a:array[0..1] of TFieldCollection;
begin
  Mainform.Wait := 1;
  sName:= OpenDlg.Files[0].Name;
  sName := sName;
  //Foto speichern
  FNewID := await(SavePicture(FHolder, FAlbumId, FPostID, FBlogID, FKursID, integer(medFoto), FStatus,  FImg, sName, FSize1, FSize2, FSize3));
  if FNewID > 0
  then begin
    a[0].Caption := 'Fotoname';    a[0].Text := sName; a[0].Required:= true;  a[0].Typ := 0; ; a[0].max_length := 50;
    a[1].Caption := 'Beschreibung';a[1].Text := '';    a[1].Required:= false; a[1].Typ := 0;
    TfrmEditor.Exec('Gib uns ein paar Infos zu deinem Foto', 'Speichern...', a, @Callback1);

    //Neuen Thumb anlegen an die Liste...
    await(frFotosListe1.AddThumb(FNewID));
    //...Neuen Thumb auswählen
    //thb ThumbClick(FNewID.ToString);
  end;
  Mainform.Wait := -1;
end;

procedure TfrmMedienAuswahl.OpenDlgChange(Sender: TObject);
begin
  OpenDlg.Files[0].GetFileAsBase64;
end;

procedure TfrmMedienAuswahl.OpenDlgGetFileAsBase64(Sender: TObject;
  AFileIndex: Integer; ABase64: string);
begin
  FImg.URL := 'data:image/png;base64,' + ABase64;
end;

procedure TfrmMedienAuswahl.frFotosliste1imgClick(Sender: TObject);
begin
  if not assigned(FImg)
  then begin
    Fimg := TImageControl.Create(self);
    Fimg.Parent := self;
    Fimg.Visible := false;
    Fimg.OnLoaded := imgLoaded;
  end;

  FImg.Tag := 0;
  OpenDlg.Execute;
end;

procedure TfrmMedienAuswahl.WebButton1Click(Sender: TObject);
begin
  close;
end;

procedure TfrmMedienAuswahl.WebFormClose(Sender: TObject;  var Action: TCloseAction);
begin
 FContainer.free;
end;

procedure TfrmMedienAuswahl.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  divFotos := THTMLDiv.Create(Self);
  frFotosliste1 := TfrFotosliste.Create(Self);
  divVideos := THTMLDiv.Create(Self);
  frVideoListe1 := TfrVideoListe.Create(Self);
  divFooter := THTMLDiv.Create(Self);
  WebButton1 := TButton.Create(Self);
  Client := TXDataWebClient.Create(Self);
  OpenDlg := TOpenDialog.Create(Self);

  frFotosliste1.LoadFromForm;
  frVideoListe1.LoadFromForm;
  WebHTMLDiv1.BeforeLoadDFMValues;
  divFotos.BeforeLoadDFMValues;
  frFotosliste1.BeforeLoadDFMValues;
  frFotosliste1.WebHTMLDiv1.BeforeLoadDFMValues;
  frFotosliste1.divWrapper.BeforeLoadDFMValues;
  frFotosliste1.img.BeforeLoadDFMValues;
  divVideos.BeforeLoadDFMValues;
  frVideoListe1.BeforeLoadDFMValues;
  frVideoListe1.WebHTMLDiv1.BeforeLoadDFMValues;
  frVideoListe1.divWrapper.BeforeLoadDFMValues;
  frVideoListe1.img.BeforeLoadDFMValues;
  divFooter.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  OpenDlg.BeforeLoadDFMValues;
  try
    Name := 'frmMedienAuswahl';
    Width := 904;
    Height := 617;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    Visible := True;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 8;
    WebHTMLDiv1.Top := 8;
    WebHTMLDiv1.Width := 873;
    WebHTMLDiv1.Height := 513;
    WebHTMLDiv1.ElementClassName := 'medien-auswahl-container';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    divFotos.SetParentComponent(WebHTMLDiv1);
    divFotos.Name := 'divFotos';
    divFotos.Left := 19;
    divFotos.Top := 20;
    divFotos.Width := 838;
    divFotos.Height := 205;
    divFotos.HeightStyle := ssAuto;
    divFotos.WidthStyle := ssAuto;
    divFotos.ChildOrder := 2;
    divFotos.ElementPosition := epIgnore;
    divFotos.ElementFont := efCSS;
    divFotos.Role := '';
    frFotosliste1.SetParentComponent(divFotos);
    frFotosliste1.Name := 'frFotosliste1';
    frFotosliste1.Left := 22;
    frFotosliste1.Top := 10;
    frFotosliste1.Width := 282;
    frFotosliste1.Height := 183;
    frFotosliste1.Margins.Left := 0;
    frFotosliste1.Margins.Top := 0;
    frFotosliste1.Margins.Right := 0;
    frFotosliste1.Margins.Bottom := 0;
    frFotosliste1.TabOrder := 0;
    frFotosliste1.WebHTMLDiv1.Width := 206;
    frFotosliste1.WebHTMLDiv1.Height := 121;
    frFotosliste1.divWrapper.Left := 11;
    frFotosliste1.divWrapper.Top := 10;
    frFotosliste1.divWrapper.Width := 182;
    frFotosliste1.divWrapper.Height := 103;
    frFotosliste1.divWrapper.ElementClassName := 'medien-auswahl-content-wrapper';
    frFotosliste1.img.Top := 4;
    SetEvent(frFotosliste1.img, Self, 'OnClick', 'frFotosliste1imgClick');
    divVideos.SetParentComponent(WebHTMLDiv1);
    divVideos.Name := 'divVideos';
    divVideos.Left := 19;
    divVideos.Top := 231;
    divVideos.Width := 838;
    divVideos.Height := 218;
    divVideos.HeightStyle := ssAuto;
    divVideos.WidthStyle := ssAuto;
    divVideos.ChildOrder := 2;
    divVideos.ElementPosition := epIgnore;
    divVideos.ElementFont := efCSS;
    divVideos.Role := '';
    frVideoListe1.SetParentComponent(divVideos);
    frVideoListe1.Name := 'frVideoListe1';
    frVideoListe1.Left := 0;
    frVideoListe1.Top := 24;
    frVideoListe1.Width := 321;
    frVideoListe1.Height := 153;
    frVideoListe1.Margins.Left := 0;
    frVideoListe1.Margins.Top := 0;
    frVideoListe1.Margins.Right := 0;
    frVideoListe1.Margins.Bottom := 0;
    frVideoListe1.TabOrder := 0;
    frVideoListe1.WebHTMLDiv1.Left := 25;
    frVideoListe1.WebHTMLDiv1.Width := 206;
    frVideoListe1.WebHTMLDiv1.Height := 77;
    frVideoListe1.divWrapper.Left := 5;
    frVideoListe1.divWrapper.Width := 182;
    frVideoListe1.divWrapper.Height := 73;
    frVideoListe1.divWrapper.ElementClassName := 'medien-auswahl-content-wrapper';
    frVideoListe1.img.Left := 0;
    frVideoListe1.img.Top := 8;
    frVideoListe1.img.Width := 73;
    frVideoListe1.img.Height := 50;
    divFooter.SetParentComponent(WebHTMLDiv1);
    divFooter.Name := 'divFooter';
    divFooter.Left := 19;
    divFooter.Top := 455;
    divFooter.Width := 838;
    divFooter.Height := 41;
    divFooter.ElementClassName := 'medien-auswahl-button-div';
    divFooter.HeightStyle := ssAuto;
    divFooter.WidthStyle := ssAuto;
    divFooter.ChildOrder := 6;
    divFooter.ElementPosition := epRelative;
    divFooter.ElementFont := efCSS;
    divFooter.Role := '';
    WebButton1.SetParentComponent(divFooter);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 95;
    WebButton1.Top := 3;
    WebButton1.Width := 96;
    WebButton1.Height := 25;
    WebButton1.Caption := 'Schlie'#223'en';
    WebButton1.ChildOrder := 1;
    WebButton1.ElementClassName := 'start_button-lets-go';
    WebButton1.ElementFont := efCSS;
    WebButton1.ElementPosition := epIgnore;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthStyle := ssAuto;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 432;
    Client.Top := 120;
    OpenDlg.SetParentComponent(Self);
    OpenDlg.Name := 'OpenDlg';
    OpenDlg.Accept := '.jpg, .png';
    SetEvent(OpenDlg, Self, 'OnChange', 'OpenDlgChange');
    SetEvent(OpenDlg, Self, 'OnGetFileAsBase64', 'OpenDlgGetFileAsBase64');
    OpenDlg.Left := 48;
    OpenDlg.Top := 368;
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    divFotos.AfterLoadDFMValues;
    frFotosliste1.AfterLoadDFMValues;
    frFotosliste1.WebHTMLDiv1.AfterLoadDFMValues;
    frFotosliste1.divWrapper.AfterLoadDFMValues;
    frFotosliste1.img.AfterLoadDFMValues;
    divVideos.AfterLoadDFMValues;
    frVideoListe1.AfterLoadDFMValues;
    frVideoListe1.WebHTMLDiv1.AfterLoadDFMValues;
    frVideoListe1.divWrapper.AfterLoadDFMValues;
    frVideoListe1.img.AfterLoadDFMValues;
    divFooter.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
    OpenDlg.AfterLoadDFMValues;
  end;
end;

end.


