unit uFreunde;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.WebCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.ExtCtrls, Vcl.Forms, uTB, uGlobal, Vcl.Imaging.pngimage,
  ufrFreundeListe;

type
  TfrmFreunde = class(TForm)
    divAlbenContent: THTMLDiv;
    divHeader: THTMLDiv;
    btnTitel: TImageControl;
    lblTitel: THTMLDiv;
    frFreundeListe1: TfrFreundeListe;
    procedure WebFormCreate(Sender: TObject);
    procedure btnTitelClick(Sender: TObject);
  private
    FHolder : integer;
    FUser   :integer;
    FParent : TForm;
  public
    property Holder : integer read FHolder write FHolder;
    [async] procedure Init(AStatus:integer); async;
    property Parent : TForm read FParent write FParent;
  protected procedure LoadDFMValues; override; end;

implementation
   Uses uDatenmodul, uMain, uSelection;

{$R *.dfm}

procedure TfrmFreunde.btnTitelClick(Sender: TObject);
begin
  if Mainform.ME.Rechte.New[riFreunde]
  then TfrmSelection.Freunde(mainform.Holder.ID)
  else meldung('Aufgrund deines Mitgliedstatus kannst Du leider keine neuen Freunde anlegen...');
end;

procedure TfrmFreunde.Init(AStatus:integer);
Var
  iHolder:integer;
  sName:string;
begin

  if AStatus = _FS_Alle
  then begin
    lblTitel.HTML.Text := 'MITGLIEDER';
    iHolder := 0; //Wenn iHolder = 0, dann werden alle SePo-Mitglieder angezeigt
  end
  else begin

    if Mainform.ME.ID = mainform.Holder.ID
    then sName := 'DEINE '
    else begin
      sName := mainform.Holder.NickName;
      if copy(sname,length(sName)-1,1) <> 's'
      then sName := sName + 's';
    end;

    case Mainform.Holder.Typ of
      _UT_User:   if AStatus = _FS_FREUNDE
                  then lblTitel.HTML.Text := sName + ' FREUNDE'
                  else lblTitel.HTML.Text := sName + ' ANFRAGEN';
      _UT_Gruppe: lblTitel.HTML.Text := sName + ' MITGLIEDER';
      _UT_Event:  lblTitel.HTML.Text := sName + ' TEILNEHMER';
    end;

    iHolder := Mainform.Holder.ID;

  end;
  mainform.Wait := 1;
  await(frFreundeliste1.Init(Mainform.Holder.Typ, iHolder, Mainform.ME.id, AStatus));
  mainform.Wait := -1;

end;

procedure TfrmFreunde.WebFormCreate(Sender: TObject);
var
  el: TJSHTMLElement;
begin
  inherited;

  TControl(self).ElementPosition := epIgnore;
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-style');
  el.style.removeProperty('font-size');
end;

procedure TfrmFreunde.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divAlbenContent := THTMLDiv.Create(Self);
  divHeader := THTMLDiv.Create(Self);
  btnTitel := TImageControl.Create(Self);
  lblTitel := THTMLDiv.Create(Self);
  frFreundeListe1 := TfrFreundeListe.Create(Self);

  frFreundeListe1.LoadFromForm;
  divAlbenContent.BeforeLoadDFMValues;
  divHeader.BeforeLoadDFMValues;
  btnTitel.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  frFreundeListe1.BeforeLoadDFMValues;
  try
    Name := 'frmFreunde';
    Width := 885;
    Height := 417;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    divAlbenContent.SetParentComponent(Self);
    divAlbenContent.Name := 'divAlbenContent';
    divAlbenContent.Left := 8;
    divAlbenContent.Top := 8;
    divAlbenContent.Width := 797;
    divAlbenContent.Height := 385;
    divAlbenContent.ElementClassName := 'alben_wrapper';
    divAlbenContent.HeightStyle := ssAuto;
    divAlbenContent.WidthStyle := ssAuto;
    divAlbenContent.ChildOrder := 1;
    divAlbenContent.ElementPosition := epIgnore;
    divAlbenContent.ElementFont := efCSS;
    divAlbenContent.Role := '';
    divHeader.SetParentComponent(divAlbenContent);
    divHeader.Name := 'divHeader';
    divHeader.Left := 2;
    divHeader.Top := 2;
    divHeader.Width := 774;
    divHeader.Height := 63;
    divHeader.ElementClassName := 'me_headline';
    divHeader.HeightStyle := ssAuto;
    divHeader.WidthStyle := ssAuto;
    divHeader.ChildOrder := 2;
    divHeader.ElementPosition := epIgnore;
    divHeader.ElementFont := efCSS;
    divHeader.HTML.BeginUpdate;
    try
      divHeader.HTML.Clear;
      divHeader.HTML.Add('');
    finally
      divHeader.HTML.EndUpdate;
    end;
    divHeader.Role := '';
    btnTitel.SetParentComponent(divHeader);
    btnTitel.Name := 'btnTitel';
    btnTitel.Left := 723;
    btnTitel.Top := 3;
    btnTitel.Width := 38;
    btnTitel.Height := 44;
    btnTitel.ElementClassName := 'alben_menu_btn';
    btnTitel.HeightStyle := ssAuto;
    btnTitel.WidthStyle := ssAuto;
    btnTitel.HeightPercent := 100.000000000000000000;
    btnTitel.WidthPercent := 100.000000000000000000;
    btnTitel.Anchors := [];
    btnTitel.ChildOrder := 2;
    btnTitel.ElementFont := efCSS;
    btnTitel.ElementPosition := epIgnore;
    SetEvent(btnTitel, Self, 'OnClick', 'btnTitelClick');
    btnTitel.Picture.LoadFromFile('uFreunde.divHeader.btnTitel.Picture.png');
    lblTitel.SetParentComponent(divHeader);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 16;
    lblTitel.Top := 3;
    lblTitel.Width := 100;
    lblTitel.Height := 41;
    lblTitel.HeightStyle := ssAuto;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.ChildOrder := 1;
    lblTitel.ElementPosition := epIgnore;
    lblTitel.ElementFont := efCSS;
    lblTitel.Role := '';
    frFreundeListe1.SetParentComponent(divAlbenContent);
    frFreundeListe1.Name := 'frFreundeListe1';
    frFreundeListe1.Left := 18;
    frFreundeListe1.Top := 68;
    frFreundeListe1.Width := 553;
    frFreundeListe1.Height := 264;
    frFreundeListe1.Margins.Left := 0;
    frFreundeListe1.Margins.Top := 0;
    frFreundeListe1.Margins.Right := 0;
    frFreundeListe1.Margins.Bottom := 0;
    frFreundeListe1.TabOrder := 1;
  finally
    divAlbenContent.AfterLoadDFMValues;
    divHeader.AfterLoadDFMValues;
    btnTitel.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    frFreundeListe1.AfterLoadDFMValues;
  end;
end;

end.
