unit uTextEdit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, uTb, XData.Web.Client, Vcl.Controls,
  WEBLib.WebCtrls, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Imaging.pngimage,
  WEBLib.ExtCtrls, WEBLib.Buttons, WEBLib.ComCtrls, uGlobal;

const
  _txt_new_password_mail  =  1;
  _txt_Gast_mail          =  2;
  _txt_GRP_Einladung_mail =  3;
  _txt_FS_Anfrage_mail    =  4;
  _txt_welcome_mail       =  5;
  _txt_2_tages_mail       =  8;
  _txt_Grp_welcome_post   =  9;
  _txt_SePo_welcome_post  = 10;

type
  TfrmTextEdit = class(TForm)
    WebHTMLDiv1: THTMLDiv;
    WebHTMLDiv11: THTMLDiv;
    btnClose: TImageControl;
    lblTitel: TLabel;
    divChoice: THTMLDiv;
    cbItems: TComboBox;
    WebHTMLDiv12: THTMLDiv;
    btnEdit1: TButton;
    btnNew1: TButton;
    btnDelete1: TButton;
    divHeader: THTMLDiv;
    EditButtons: TRichEditToolBar;
    WebHTMLDiv49: THTMLDiv;
    WebButton1: TButton;
    WebButton2: TButton;
    WebHTMLDiv3: THTMLDiv;
    reTitel: TRichEdit;
    WebHTMLDiv24: THTMLDiv;
    reText: TRichEdit;
    Memo: TMemo;
    spHtml: TSpeedButton;
    btnSysText: TSpeedButton;
    procedure btnCloseClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    [async] procedure WebButton1Click(Sender: TObject); async;
    [async] procedure WebButton2Click(Sender: TObject); async;
    procedure WebFormCreate(Sender: TObject);
    [async] procedure btnEdit1Click(Sender: TObject); async;
    procedure btnNew1Click(Sender: TObject);
    procedure spHtmlClick(Sender: TObject);
    procedure reTextEnter(Sender: TObject);
    [async] procedure btnSysTextClick(Sender: TObject); async;
  private
    FCallback:TCallback;
    FContainer:THtmlDiv;
    FNewID:integer;
    FTyp, FID, FHolder:integer;
  public
    [async] class function Show(ATitel:string; AHolder, ATyp:integer; ACallback:TCallback):integer; async;
  protected procedure LoadDFMValues; override; end;

var
  frmTextEdit: TfrmTextEdit;

implementation
  uses uMain, uDatenmodul, uPrint;
{$R *.dfm}

procedure TfrmTextEdit.btnEdit1Click(Sender: TObject);
Var
  j:TJ;
  sIdx:string;
begin
  sIdx := cbItems.values[cbItems.ItemIndex];
  FID := sIdx.ToInteger;
  j := TJ.create(await(datenmodul.EasySQL('select * from texte where ID = ' + sIdx)));

  reTitel.lines.Text:= J.Value('TITEL');
  reText.lines.Text := J.Value('TEXT');
  Memo.Text         := J.Value('TEXT');
end;

procedure TfrmTextEdit.btnNew1Click(Sender: TObject);
begin
  reTitel.lines.Text:= '';
  reText.lines.Text := '';
  FID := -1;
end;

procedure TfrmTextEdit.btnSysTextClick(Sender: TObject);
Var
  j:TJ;
begin
  j := TJ.create(await(datenmodul.EasySQL('select * from texte where HOLDER = 0 AND TYP = ' + FTyp.tostring)));

  if j.HasValue
  then begin
    reTitel.lines.Text:= J.Value('TITEL');
    reText.lines.Text := J.Value('TEXT');
    Memo.Text         := J.Value('TEXT');
  end;

end;

procedure TfrmTextEdit.reTextEnter(Sender: TObject);
begin
  EditButtons.RichEdit := TRichEdit(Sender);
end;

class function TfrmTextEdit.Show(ATitel:string; AHolder, ATyp:integer; ACallback:TCallback):integer;

  [async] procedure AfterCreate(AForm: TObject); async;
  Var
    Response: TXDataClientResponse;
    J:TJ;
    i:integer;
  begin
    TfrmTextEdit(AForm).lblTitel.Caption := ATitel;
    TfrmTextEdit(AForm).ElementHandle.style.removeProperty('position');
    TfrmTextEdit(AForm).Parent := Mainform;

    TfrmTextEdit(AForm).FTyp   := ATyp;
    TfrmTextEdit(AForm).FHolder:= AHolder;

    if ATyp = 0 //Alle Texte des Holder anzeigen
    then j := TJ.create(await(datenmodul.EasySQL('select * from texte where HOLDER = ' + AHolder.toString)))
    else j := TJ.create(await(datenmodul.EasySQL('select * from texte where HOLDER = ' + AHolder.toString + ' AND TYP = ' + ATyp.tostring)));

    await(tbFillCombobox(TfrmTextEdit(AForm).cbItems, 'ID', 'TITEL', j.Response ));

    TfrmTextEdit(AForm).cbItems.ItemIndex := 0;

    //Auswahl nur anzeigen bei systemrelevanten Texten
    TfrmTextEdit(AForm).divChoice.Visible := (ATyp = 0) and (AHolder = 0);

    if (not j.hasValue)
    then TfrmTextEdit(AForm).btnNew1Click(TfrmTextEdit(AForm).btnNew1)
    else TfrmTextEdit(AForm).btnEdit1Click(TfrmTextEdit(AForm).btnEdit1);

  end;

Var
  frm:TfrmTextEdit;
  FConti:THtmlDiv;
begin

  FConti := THtmlDiv.Create(Mainform);
  FConti.Parent := Mainform;
  FConti.ElementClassName := 'editor_transparent';
  frm := TfrmTextEdit.CreateNew(FConti.ElementID, @AfterCreate);

  frm.FContainer := FConti;
  frm.FCallback  := ACallback;

end;

procedure TfrmTextEdit.btnCloseClick(Sender: TObject);
begin
  close;
end;

procedure TfrmTextEdit.WebButton1Click(Sender: TObject);
Var
  j:TJ;
  i:integer;
  s, sTitel, sText:string;
  JSObj: TJSObject;
begin
  reText.lines.Text := trim(reText.GetContent);
  Memo.Text         := trim(Memo.Text);
  sTitel            := trim(reTitel.GetContent);

  if reText.visible
  then sText := reText.GetContent
  else sText := Memo.Text;

  if ((sTitel > '') or (sText > ''))
  then begin

    if FID = -1
    then begin
      s := 'INSERT INTO Texte (HOLDER, TYP, TITEL, TEXT) ' +
                'VALUES (' + FHolder.ToString + ',' + FTyp.ToString + ',''' + sTitel + ''',''' + sText + ''')';
    end
    else begin
      s := 'UPDATE Texte SET TITEL = ''' + sTitel + ''', ' +
                            ' TEXT = ''' + sText + '''' +
                            ' WHERE ID = ' + FID.tostring;

    end;
    sText := str2JSON(s);

//    sText := '{"sql": "' + sText + '"}';
//    JSObj:=TJSJSON.parseObject(sText);
//    TAwait.Exec<TXDataClientResponse>( datenmodul.Client.RawInvokeAsync( 'IDBService.JSExec',[JSObj]));

//    showmessage('Stringlänge: ' + inttostr(length(sText)));
    await(datenmodul.EasyExec(sText));

    await(Meldung('Text wurde gespeichert...'));

    j := TJ.create(await(datenmodul.EasySQL('select * from texte')));

    tbFillCombobox(cbItems, 'ID', 'TITEL', j.Response );

    for I := 0 to cbitems.items.Count - 1 do
    begin
      if cbItems.Values[i] = FID.tostring
      then begin
        cbItems.ItemIndex := i;
        exit;
      end;
    end;

  end;

end;

procedure TfrmTextEdit.WebButton2Click(Sender: TObject);
begin
  close;

 // TPrint.PrintProtokoll(Mainform.ME.ID, Mainform.ME.Vorname + ' ' + Mainform.ME.Name);
end;

procedure TfrmTextEdit.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  FContainer.Free;
end;

procedure TfrmTextEdit.WebFormCreate(Sender: TObject);
Var
  el : TJSHTMLElement;
begin
  TControl(self).elementfont := efCSS;

  TControl(reTitel).elementPosition := epIgnore;
  el := TJSHTMLElement(THTMLDiv(reTitel).ElementHandle);
  el.style.removeProperty('position');
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-size');
  el.style.removeProperty('font-style');

  TControl(reText).elementPosition := epIgnore;
  el := TJSHTMLElement(THTMLDiv(reText).ElementHandle);
  el.style.removeProperty('position');
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-size');
  el.style.removeProperty('font-style');

end;

procedure TfrmTextEdit.spHtmlClick(Sender: TObject);
begin

  if memo.Visible
  then begin
    reText.Lines := memo.lines;
    memo.visible := false;
    reText.visible := true;
  end
  else begin
    memo.Text := reText.GetContent;
    memo.visible := true;
    reText.visible := false;
  end;
end;

procedure TfrmTextEdit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebHTMLDiv1 := THTMLDiv.Create(Self);
  WebHTMLDiv11 := THTMLDiv.Create(Self);
  btnClose := TImageControl.Create(Self);
  lblTitel := TLabel.Create(Self);
  divChoice := THTMLDiv.Create(Self);
  cbItems := TComboBox.Create(Self);
  WebHTMLDiv12 := THTMLDiv.Create(Self);
  btnEdit1 := TButton.Create(Self);
  btnNew1 := TButton.Create(Self);
  btnDelete1 := TButton.Create(Self);
  divHeader := THTMLDiv.Create(Self);
  EditButtons := TRichEditToolBar.Create('');
  spHtml := TSpeedButton.Create(Self);
  btnSysText := TSpeedButton.Create(Self);
  WebHTMLDiv49 := THTMLDiv.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebHTMLDiv3 := THTMLDiv.Create(Self);
  reTitel := TRichEdit.Create(Self);
  WebHTMLDiv24 := THTMLDiv.Create(Self);
  reText := TRichEdit.Create(Self);
  Memo := TMemo.Create(Self);

  WebHTMLDiv1.BeforeLoadDFMValues;
  WebHTMLDiv11.BeforeLoadDFMValues;
  btnClose.BeforeLoadDFMValues;
  lblTitel.BeforeLoadDFMValues;
  divChoice.BeforeLoadDFMValues;
  cbItems.BeforeLoadDFMValues;
  WebHTMLDiv12.BeforeLoadDFMValues;
  btnEdit1.BeforeLoadDFMValues;
  btnNew1.BeforeLoadDFMValues;
  btnDelete1.BeforeLoadDFMValues;
  divHeader.BeforeLoadDFMValues;
  EditButtons.BeforeLoadDFMValues;
  spHtml.BeforeLoadDFMValues;
  btnSysText.BeforeLoadDFMValues;
  WebHTMLDiv49.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebHTMLDiv3.BeforeLoadDFMValues;
  reTitel.BeforeLoadDFMValues;
  WebHTMLDiv24.BeforeLoadDFMValues;
  reText.BeforeLoadDFMValues;
  Memo.BeforeLoadDFMValues;
  try
    Name := 'frmTextEdit';
    Width := 694;
    Height := 444;
    ElementFont := efCSS;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    WebHTMLDiv1.SetParentComponent(Self);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 8;
    WebHTMLDiv1.Top := 8;
    WebHTMLDiv1.Width := 673;
    WebHTMLDiv1.Height := 425;
    WebHTMLDiv1.ElementClassName := 'kursedit_container';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ElementPosition := epRelative;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv11.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv11.Name := 'WebHTMLDiv11';
    WebHTMLDiv11.Left := 19;
    WebHTMLDiv11.Top := 6;
    WebHTMLDiv11.Width := 638;
    WebHTMLDiv11.Height := 41;
    WebHTMLDiv11.ElementClassName := 'textedit-headline';
    WebHTMLDiv11.HeightStyle := ssAuto;
    WebHTMLDiv11.WidthStyle := ssAuto;
    WebHTMLDiv11.ElementPosition := epRelative;
    WebHTMLDiv11.ElementFont := efCSS;
    WebHTMLDiv11.Role := '';
    btnClose.SetParentComponent(WebHTMLDiv11);
    btnClose.Name := 'btnClose';
    btnClose.Left := 585;
    btnClose.Top := -3;
    btnClose.Width := 46;
    btnClose.Height := 44;
    btnClose.ElementClassName := 'albumedit_close';
    btnClose.HeightStyle := ssAuto;
    btnClose.WidthStyle := ssAuto;
    btnClose.HeightPercent := 100.000000000000000000;
    btnClose.WidthPercent := 100.000000000000000000;
    btnClose.Anchors := [];
    btnClose.ChildOrder := 1;
    btnClose.ElementFont := efCSS;
    btnClose.ElementPosition := epIgnore;
    SetEvent(btnClose, Self, 'OnClick', 'btnCloseClick');
    btnClose.Picture.LoadFromFile('uTextEdit.WebHTMLDiv11.btnClose.Picture.png');
    lblTitel.SetParentComponent(WebHTMLDiv11);
    lblTitel.Name := 'lblTitel';
    lblTitel.Left := 16;
    lblTitel.Top := 9;
    lblTitel.Width := 35;
    lblTitel.Height := 15;
    lblTitel.Caption := 'lblTitel';
    lblTitel.ElementClassName := 'albumedit_titel';
    lblTitel.ElementFont := efCSS;
    lblTitel.ElementPosition := epIgnore;
    lblTitel.HeightStyle := ssAuto;
    lblTitel.HeightPercent := 100.000000000000000000;
    lblTitel.WidthStyle := ssAuto;
    lblTitel.WidthPercent := 100.000000000000000000;
    divChoice.SetParentComponent(WebHTMLDiv1);
    divChoice.Name := 'divChoice';
    divChoice.Left := 19;
    divChoice.Top := 53;
    divChoice.Width := 638;
    divChoice.Height := 41;
    divChoice.ElementClassName := 'kursedit_line';
    divChoice.HeightStyle := ssAuto;
    divChoice.WidthStyle := ssAuto;
    divChoice.ChildOrder := 1;
    divChoice.ElementPosition := epRelative;
    divChoice.ElementFont := efCSS;
    divChoice.Role := '';
    cbItems.SetParentComponent(divChoice);
    cbItems.Name := 'cbItems';
    cbItems.Left := 12;
    cbItems.Top := 3;
    cbItems.Width := 273;
    cbItems.Height := 23;
    cbItems.ElementClassName := 'editor_combobox';
    cbItems.ElementFont := efCSS;
    cbItems.ElementPosition := epIgnore;
    cbItems.HeightStyle := ssAuto;
    cbItems.HeightPercent := 100.000000000000000000;
    cbItems.Text := 'cbItems';
    cbItems.WidthStyle := ssAuto;
    cbItems.WidthPercent := 100.000000000000000000;
    cbItems.ItemIndex := -1;
    WebHTMLDiv12.SetParentComponent(divChoice);
    WebHTMLDiv12.Name := 'WebHTMLDiv12';
    WebHTMLDiv12.Left := 325;
    WebHTMLDiv12.Top := 3;
    WebHTMLDiv12.Width := 310;
    WebHTMLDiv12.Height := 35;
    WebHTMLDiv12.ElementClassName := 'kursedit_btn_container';
    WebHTMLDiv12.HeightStyle := ssAuto;
    WebHTMLDiv12.WidthStyle := ssAuto;
    WebHTMLDiv12.ChildOrder := 3;
    WebHTMLDiv12.ElementPosition := epIgnore;
    WebHTMLDiv12.ElementFont := efCSS;
    WebHTMLDiv12.Role := '';
    btnEdit1.SetParentComponent(WebHTMLDiv12);
    btnEdit1.Name := 'btnEdit1';
    btnEdit1.Left := 3;
    btnEdit1.Top := 3;
    btnEdit1.Width := 94;
    btnEdit1.Height := 25;
    btnEdit1.Caption := 'Bearbeiten';
    btnEdit1.ChildOrder := 1;
    btnEdit1.ElementClassName := 'editor_button';
    btnEdit1.ElementFont := efCSS;
    btnEdit1.ElementPosition := epIgnore;
    btnEdit1.HeightStyle := ssAuto;
    btnEdit1.HeightPercent := 100.000000000000000000;
    btnEdit1.WidthStyle := ssAuto;
    btnEdit1.WidthPercent := 100.000000000000000000;
    SetEvent(btnEdit1, Self, 'OnClick', 'btnEdit1Click');
    btnNew1.SetParentComponent(WebHTMLDiv12);
    btnNew1.Name := 'btnNew1';
    btnNew1.Left := 103;
    btnNew1.Top := 3;
    btnNew1.Width := 94;
    btnNew1.Height := 25;
    btnNew1.Caption := 'Neu';
    btnNew1.ChildOrder := 2;
    btnNew1.ElementClassName := 'editor_button';
    btnNew1.ElementFont := efCSS;
    btnNew1.ElementPosition := epIgnore;
    btnNew1.HeightStyle := ssAuto;
    btnNew1.HeightPercent := 100.000000000000000000;
    btnNew1.WidthStyle := ssAuto;
    btnNew1.WidthPercent := 100.000000000000000000;
    SetEvent(btnNew1, Self, 'OnClick', 'btnNew1Click');
    btnDelete1.SetParentComponent(WebHTMLDiv12);
    btnDelete1.Name := 'btnDelete1';
    btnDelete1.Left := 203;
    btnDelete1.Top := 3;
    btnDelete1.Width := 94;
    btnDelete1.Height := 25;
    btnDelete1.Caption := 'L'#246'schen';
    btnDelete1.ChildOrder := 3;
    btnDelete1.ElementClassName := 'editor_button';
    btnDelete1.ElementFont := efCSS;
    btnDelete1.ElementPosition := epIgnore;
    btnDelete1.HeightStyle := ssAuto;
    btnDelete1.HeightPercent := 100.000000000000000000;
    btnDelete1.WidthStyle := ssAuto;
    btnDelete1.WidthPercent := 100.000000000000000000;
    divHeader.SetParentComponent(WebHTMLDiv1);
    divHeader.Name := 'divHeader';
    divHeader.Left := 19;
    divHeader.Top := 100;
    divHeader.Width := 638;
    divHeader.Height := 42;
    divHeader.ElementClassName := 'kursedit_line';
    divHeader.HeightStyle := ssAuto;
    divHeader.WidthStyle := ssAuto;
    divHeader.ChildOrder := 2;
    divHeader.ElementPosition := epRelative;
    divHeader.ElementFont := efCSS;
    divHeader.Role := '';
    EditButtons.SetParentComponent(divHeader);
    EditButtons.Name := 'EditButtons';
    EditButtons.Left := 3;
    EditButtons.Top := 3;
    EditButtons.Width := 357;
    EditButtons.Height := 35;
    EditButtons.ElementClassName := '';
    EditButtons.HeightStyle := ssAuto;
    EditButtons.WidthStyle := ssAuto;
    EditButtons.Color := clBtnFace;
    EditButtons.ElementFont := efCSS;
    EditButtons.ElementPosition := epRelative;
    EditButtons.Hints.BeginUpdate;
    try
      EditButtons.Hints.Clear;
      EditButtons.Hints.Add('Bold');
      EditButtons.Hints.Add('Italic');
      EditButtons.Hints.Add('Underline');
      EditButtons.Hints.Add('Strike Throught');
      EditButtons.Hints.Add('Text Color');
      EditButtons.Hints.Add('Background Color');
      EditButtons.Hints.Add('Align lef');
      EditButtons.Hints.Add('Align center');
      EditButtons.Hints.Add('Align right');
      EditButtons.Hints.Add('Numbered list');
      EditButtons.Hints.Add('List');
    finally
      EditButtons.Hints.EndUpdate;
    end;
    EditButtons.RichEdit := reText;
    spHtml.SetParentComponent(divHeader);
    spHtml.Name := 'spHtml';
    spHtml.Left := 468;
    spHtml.Top := 3;
    spHtml.Width := 54;
    spHtml.Height := 31;
    spHtml.Caption := 'HTML';
    spHtml.Color := clNone;
    spHtml.ElementFont := efCSS;
    spHtml.ElementPosition := epRelative;
    spHtml.HeightPercent := 100.000000000000000000;
    spHtml.TabOrder := 1;
    spHtml.WidthPercent := 100.000000000000000000;
    SetEvent(spHtml, Self, 'OnClick', 'spHtmlClick');
    btnSysText.SetParentComponent(divHeader);
    btnSysText.Name := 'btnSysText';
    btnSysText.Left := 528;
    btnSysText.Top := 3;
    btnSysText.Width := 107;
    btnSysText.Height := 31;
    btnSysText.Caption := 'Systemtext einf'#252'gen';
    btnSysText.Color := clNone;
    btnSysText.ElementFont := efCSS;
    btnSysText.ElementPosition := epRelative;
    btnSysText.HeightPercent := 100.000000000000000000;
    btnSysText.TabOrder := 2;
    btnSysText.WidthPercent := 100.000000000000000000;
    SetEvent(btnSysText, Self, 'OnClick', 'btnSysTextClick');
    WebHTMLDiv49.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv49.Name := 'WebHTMLDiv49';
    WebHTMLDiv49.Left := 19;
    WebHTMLDiv49.Top := 367;
    WebHTMLDiv49.Width := 638;
    WebHTMLDiv49.Height := 41;
    WebHTMLDiv49.ElementClassName := 'textedit-footer';
    WebHTMLDiv49.HeightStyle := ssAuto;
    WebHTMLDiv49.WidthStyle := ssAuto;
    WebHTMLDiv49.ChildOrder := 5;
    WebHTMLDiv49.ElementPosition := epRelative;
    WebHTMLDiv49.ElementFont := efCSS;
    WebHTMLDiv49.Role := '';
    WebButton1.SetParentComponent(WebHTMLDiv49);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 95;
    WebButton1.Top := 3;
    WebButton1.Width := 96;
    WebButton1.Height := 25;
    WebButton1.Caption := 'Speichern';
    WebButton1.ChildOrder := 1;
    WebButton1.ElementClassName := 'start_button-lets-go';
    WebButton1.ElementFont := efCSS;
    WebButton1.ElementPosition := epIgnore;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthStyle := ssAuto;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(WebHTMLDiv49);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 197;
    WebButton2.Top := 3;
    WebButton2.Width := 96;
    WebButton2.Height := 25;
    WebButton2.Caption := 'Verwerfen';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementClassName := 'start_button-lets-go';
    WebButton2.ElementFont := efCSS;
    WebButton2.ElementPosition := epIgnore;
    WebButton2.HeightStyle := ssAuto;
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthStyle := ssAuto;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebHTMLDiv3.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv3.Name := 'WebHTMLDiv3';
    WebHTMLDiv3.Left := 19;
    WebHTMLDiv3.Top := 148;
    WebHTMLDiv3.Width := 638;
    WebHTMLDiv3.Height := 213;
    WebHTMLDiv3.ElementClassName := 'kursedit_text_container';
    WebHTMLDiv3.HeightStyle := ssAuto;
    WebHTMLDiv3.WidthStyle := ssAuto;
    WebHTMLDiv3.ChildOrder := 3;
    WebHTMLDiv3.ElementPosition := epRelative;
    WebHTMLDiv3.ElementFont := efCSS;
    WebHTMLDiv3.Role := '';
    reTitel.SetParentComponent(WebHTMLDiv3);
    reTitel.Name := 'reTitel';
    reTitel.Left := 16;
    reTitel.Top := 10;
    reTitel.Width := 606;
    reTitel.Height := 46;
    reTitel.ElementClassName := 'kursedit_titel';
    reTitel.HeightStyle := ssAuto;
    reTitel.WidthStyle := ssAuto;
    reTitel.BorderStyle := bsSingle;
    reTitel.ChildOrder := 1;
    reTitel.Color := clWhite;
    WebHTMLDiv24.SetParentComponent(WebHTMLDiv3);
    WebHTMLDiv24.Name := 'WebHTMLDiv24';
    WebHTMLDiv24.Left := 10;
    WebHTMLDiv24.Top := 62;
    WebHTMLDiv24.Width := 612;
    WebHTMLDiv24.Height := 133;
    WebHTMLDiv24.HeightStyle := ssAuto;
    WebHTMLDiv24.WidthStyle := ssAuto;
    WebHTMLDiv24.ChildOrder := 2;
    WebHTMLDiv24.ElementPosition := epRelative;
    WebHTMLDiv24.ElementFont := efCSS;
    WebHTMLDiv24.Role := '';
    reText.SetParentComponent(WebHTMLDiv24);
    reText.Name := 'reText';
    reText.Left := 418;
    reText.Top := 0;
    reText.Width := 191;
    reText.Height := 122;
    reText.ElementClassName := 'kursedit_input';
    reText.HeightStyle := ssAuto;
    reText.WidthStyle := ssAuto;
    reText.BorderStyle := bsSingle;
    reText.ChildOrder := 2;
    reText.Color := clWhite;
    SetEvent(reText, Self, 'OnEnter', 'reTextEnter');
    Memo.SetParentComponent(WebHTMLDiv24);
    Memo.Name := 'Memo';
    Memo.Left := 16;
    Memo.Top := 3;
    Memo.Width := 396;
    Memo.Height := 106;
    Memo.AutoSize := False;
    Memo.ChildOrder := 2;
    Memo.ElementClassName := 'kursedit_input';
    Memo.ElementFont := efCSS;
    Memo.ElementPosition := epIgnore;
    Memo.HeightStyle := ssAuto;
    Memo.HeightPercent := 100.000000000000000000;
    Memo.Lines.BeginUpdate;
    try
      Memo.Lines.Clear;
      Memo.Lines.Add('Memo');
    finally
      Memo.Lines.EndUpdate;
    end;
    Memo.SelLength := 0;
    Memo.SelStart := 0;
    Memo.Visible := False;
    Memo.WantTabs := True;
    Memo.WidthStyle := ssAuto;
    Memo.WidthPercent := 100.000000000000000000;
  finally
    WebHTMLDiv1.AfterLoadDFMValues;
    WebHTMLDiv11.AfterLoadDFMValues;
    btnClose.AfterLoadDFMValues;
    lblTitel.AfterLoadDFMValues;
    divChoice.AfterLoadDFMValues;
    cbItems.AfterLoadDFMValues;
    WebHTMLDiv12.AfterLoadDFMValues;
    btnEdit1.AfterLoadDFMValues;
    btnNew1.AfterLoadDFMValues;
    btnDelete1.AfterLoadDFMValues;
    divHeader.AfterLoadDFMValues;
    EditButtons.AfterLoadDFMValues;
    spHtml.AfterLoadDFMValues;
    btnSysText.AfterLoadDFMValues;
    WebHTMLDiv49.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebHTMLDiv3.AfterLoadDFMValues;
    reTitel.AfterLoadDFMValues;
    WebHTMLDiv24.AfterLoadDFMValues;
    reText.AfterLoadDFMValues;
    Memo.AfterLoadDFMValues;
  end;
end;

end.
