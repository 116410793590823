unit ufrBlogThumb;

interface

uses
  System.SysUtils, System.Variants, System.Classes,web, uGlobal,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.Imaging.pngimage,
  Vcl.Imaging.jpeg, WEBLib.Controls, WEBLib.ExtCtrls, WEBLib.WebCtrls, utb,
  WEBLib.Dialogs, js, XData.Web.Client, WEBLib.Graphics, WEBLib.Forms;

type
  TfrBlogThumb = class(TFrame)
    p0: THTMLDiv;
    img: TImageControl;
    lblHeader: THTMLDiv;
    lblText: THTMLDiv;
    divMenu: THTMLDiv;
    btnNew: TImageControl;
    btnFoto: TImageControl;
    btnVideo: TImageControl;
    btnDelete: TImageControl;
    procedure lblHeaderClick(Sender: TObject);
  protected
  private
    FVor,
    FBlogId:integer;
    FCallback:TCallback;
  public
    class function Add(AParent:TCustomControl; ABlogID, AVor:integer; ACallback:TCallback):TfrBlogThumb;

  protected procedure LoadDFMValues; override; end;

implementation
   uses uMain, uDatenmodul, uGruppenEdit, uSlider;
{$R *.dfm}

//class function TfrBlogThumb.Add(AParent:TWebCustomControl; AEventId, AHolder, AUser:integer; AName, AText, AFotos, AVideos, AStandard:string):TfrEventsThumb;
class function TfrBlogThumb.Add(AParent:TCustomControl; ABlogID, AVor:integer; ACallback:TCallback):TfrBlogThumb;
var
  el: TJSHTMLElement;
begin
  result := TfrBlogThumb.Create(AParent.owner);
  result.LoadFromForm;

  result.parent   := AParent;
  result.FCallback:= ACallback;
  result.FBlogID  := ABlogID;
  result.FVor     := AVor;

  TControl(result).elementPosition := epIgnore;
  THTMlDiv(result).ElementClassName := 'event_frame';

  el := TJSHTMLElement(THTMLDiv(result).ElementHandle);
  el.style.removeProperty('overflow');
  el.style.removeProperty('height');
  el.style.removeProperty('width');
  el.style.removeProperty('font-family');
  el.style.removeProperty('font-style');
  el.style.removeProperty('font-size');

end;

procedure TfrBlogThumb.lblHeaderClick(Sender: TObject);
begin
  if assigned(FCallBack)
  then FCallBack( FBlogID.ToString );

end;

procedure TfrBlogThumb.LoadDFMValues;
begin
  inherited LoadDFMValues;

  p0 := THTMLDiv.Create(Self);
  img := TImageControl.Create(Self);
  lblHeader := THTMLDiv.Create(Self);
  lblText := THTMLDiv.Create(Self);
  divMenu := THTMLDiv.Create(Self);
  btnNew := TImageControl.Create(Self);
  btnFoto := TImageControl.Create(Self);
  btnVideo := TImageControl.Create(Self);
  btnDelete := TImageControl.Create(Self);

  p0.BeforeLoadDFMValues;
  img.BeforeLoadDFMValues;
  lblHeader.BeforeLoadDFMValues;
  lblText.BeforeLoadDFMValues;
  divMenu.BeforeLoadDFMValues;
  btnNew.BeforeLoadDFMValues;
  btnFoto.BeforeLoadDFMValues;
  btnVideo.BeforeLoadDFMValues;
  btnDelete.BeforeLoadDFMValues;
  try
    Name := 'frBlogThumb';
    Left := 0;
    Top := 0;
    Width := 291;
    Height := 387;
    TabOrder := 0;
    p0.SetParentComponent(Self);
    p0.Name := 'p0';
    p0.Left := 3;
    p0.Top := 3;
    p0.Width := 281;
    p0.Height := 375;
    p0.ElementClassName := 'blog-content';
    p0.HeightStyle := ssAuto;
    p0.WidthStyle := ssAuto;
    p0.ChildOrder := 1;
    p0.ElementPosition := epIgnore;
    p0.ElementFont := efCSS;
    p0.Role := '';
    img.SetParentComponent(p0);
    img.Name := 'img';
    img.Left := 18;
    img.Top := 19;
    img.Width := 249;
    img.Height := 134;
    img.ElementClassName := 'blog-content-foto';
    img.HeightStyle := ssAuto;
    img.WidthStyle := ssAuto;
    img.HeightPercent := 100.000000000000000000;
    img.WidthPercent := 100.000000000000000000;
    img.ElementFont := efCSS;
    img.ElementPosition := epIgnore;
    lblHeader.SetParentComponent(p0);
    lblHeader.Name := 'lblHeader';
    lblHeader.Left := 18;
    lblHeader.Top := 159;
    lblHeader.Width := 249;
    lblHeader.Height := 50;
    lblHeader.ElementClassName := 'blog-content-header';
    lblHeader.HeightStyle := ssAuto;
    lblHeader.WidthStyle := ssAuto;
    lblHeader.ChildOrder := 3;
    lblHeader.ElementPosition := epIgnore;
    lblHeader.ElementFont := efCSS;
    lblHeader.HTML.BeginUpdate;
    try
      lblHeader.HTML.Clear;
      lblHeader.HTML.Add('Kroatien Sommer 2023');
    finally
      lblHeader.HTML.EndUpdate;
    end;
    lblHeader.Role := '';
    SetEvent(lblHeader, Self, 'OnClick', 'lblHeaderClick');
    lblText.SetParentComponent(p0);
    lblText.Name := 'lblText';
    lblText.Left := 18;
    lblText.Top := 215;
    lblText.Width := 247;
    lblText.Height := 82;
    lblText.ElementClassName := 'blog-content-text';
    lblText.HeightStyle := ssAuto;
    lblText.WidthStyle := ssAuto;
    lblText.ChildOrder := 3;
    lblText.ElementPosition := epIgnore;
    lblText.ElementFont := efCSS;
    lblText.HTML.BeginUpdate;
    try
      lblText.HTML.Clear;
      lblText.HTML.Add('Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam ');
      lblText.HTML.Add('voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. ');
      lblText.HTML.Add('Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam ');
      lblText.HTML.Add('voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.');
    finally
      lblText.HTML.EndUpdate;
    end;
    lblText.Role := '';
    divMenu.SetParentComponent(p0);
    divMenu.Name := 'divMenu';
    divMenu.Left := 18;
    divMenu.Top := 303;
    divMenu.Width := 249;
    divMenu.Height := 57;
    divMenu.ElementClassName := 'alben_footer';
    divMenu.HeightStyle := ssAuto;
    divMenu.WidthStyle := ssAuto;
    divMenu.ChildOrder := 5;
    divMenu.ElementPosition := epIgnore;
    divMenu.ElementFont := efCSS;
    divMenu.Role := '';
    divMenu.Visible := False;
    btnNew.SetParentComponent(divMenu);
    btnNew.Name := 'btnNew';
    btnNew.Left := 9;
    btnNew.Top := 0;
    btnNew.Width := 38;
    btnNew.Height := 44;
    btnNew.ElementClassName := 'blk_side_menu_btn';
    btnNew.HeightStyle := ssAuto;
    btnNew.WidthStyle := ssAuto;
    btnNew.HeightPercent := 100.000000000000000000;
    btnNew.WidthPercent := 100.000000000000000000;
    btnNew.Anchors := [];
    btnNew.ElementFont := efCSS;
    btnNew.ElementPosition := epIgnore;
    btnNew.Picture.LoadFromFile('ufrBlogThumb.divMenu.btnNew.Picture.png');
    btnFoto.SetParentComponent(divMenu);
    btnFoto.Name := 'btnFoto';
    btnFoto.Left := 53;
    btnFoto.Top := 0;
    btnFoto.Width := 46;
    btnFoto.Height := 44;
    btnFoto.ElementClassName := 'blk_side_menu_btn';
    btnFoto.HeightStyle := ssAuto;
    btnFoto.WidthStyle := ssAuto;
    btnFoto.HeightPercent := 100.000000000000000000;
    btnFoto.WidthPercent := 100.000000000000000000;
    btnFoto.Anchors := [];
    btnFoto.ChildOrder := 1;
    btnFoto.ElementFont := efCSS;
    btnFoto.ElementPosition := epIgnore;
    btnFoto.Picture.LoadFromFile('ufrBlogThumb.divMenu.btnFoto.Picture.png');
    btnVideo.SetParentComponent(divMenu);
    btnVideo.Name := 'btnVideo';
    btnVideo.Left := 105;
    btnVideo.Top := 3;
    btnVideo.Width := 46;
    btnVideo.Height := 44;
    btnVideo.ElementClassName := 'blk_side_menu_btn';
    btnVideo.HeightStyle := ssAuto;
    btnVideo.WidthStyle := ssAuto;
    btnVideo.HeightPercent := 100.000000000000000000;
    btnVideo.WidthPercent := 100.000000000000000000;
    btnVideo.Anchors := [];
    btnVideo.ChildOrder := 2;
    btnVideo.ElementFont := efCSS;
    btnVideo.ElementPosition := epIgnore;
    btnVideo.Picture.LoadFromFile('ufrBlogThumb.divMenu.btnVideo.Picture.png');
    btnDelete.SetParentComponent(divMenu);
    btnDelete.Name := 'btnDelete';
    btnDelete.Left := 170;
    btnDelete.Top := 3;
    btnDelete.Width := 46;
    btnDelete.Height := 44;
    btnDelete.ElementClassName := 'blk_side_menu_btn';
    btnDelete.HeightStyle := ssAuto;
    btnDelete.WidthStyle := ssAuto;
    btnDelete.HeightPercent := 100.000000000000000000;
    btnDelete.WidthPercent := 100.000000000000000000;
    btnDelete.Anchors := [];
    btnDelete.ChildOrder := 2;
    btnDelete.ElementFont := efCSS;
    btnDelete.ElementPosition := epIgnore;
    btnDelete.Picture.LoadFromFile('ufrBlogThumb.divMenu.btnDelete.Picture.png');
  finally
    p0.AfterLoadDFMValues;
    img.AfterLoadDFMValues;
    lblHeader.AfterLoadDFMValues;
    lblText.AfterLoadDFMValues;
    divMenu.AfterLoadDFMValues;
    btnNew.AfterLoadDFMValues;
    btnFoto.AfterLoadDFMValues;
    btnVideo.AfterLoadDFMValues;
    btnDelete.AfterLoadDFMValues;
  end;
end;

end.
