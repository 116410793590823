{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2021 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCWXPDFViewer;

{$I WEBLib.TMSFNCDefines.inc}

interface

uses
  Classes, WEBLib.Forms, SysUtils, WEBLib.Dialogs, StrUtils, Types, Math
  {$IFDEF FMXLIB}
  ,UITypes
  {$ENDIF}
  {$IFDEF VCLLIB}
  ,Vcl.Controls
  {$ENDIF}
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  ,System.JSON, System.Generics.Collections, IOutils
  {$ENDIF}
  {$IFDEF LCLLIB}
  , fpjson, Controls
  {$ENDIF}
  {$ENDIF}
  {$IFDEF WEBLIB}
  ,WEBLIB.Controls, Web, Contnrs, WebLib.JSON
  {$ENDIF}
  ,WEBLib.TMSFNCCommonJSFiles
  ,WEBLib.TMSFNCWebBrowser, WEBLib.TMSFNCCustomWEBControl
  ,WEBLib.TMSFNCGraphics
  ,WEBLib.TMSFNCGraphicsTypes
  ,WEBLib.TMSFNCUtils
  ,WEBLib.TMSFNCTypes
  ,WEBLib.TMSFNCCustomControl
  ,WEBLib.TMSFNCWXPDFViewer.Common
  ,WEBLib.TMSFNCWXCommon;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 4; // Release nr.
  BLD_VER = 1; // Build nr.

  // version history
  // v1.0.0.0 : first release
  // v1.0.0.1 : Fixed : Issue with offline library location
  // v1.0.0.2 : Fixed : Issue with hyperlink positions
  //          : Fixed : Issue with LibraryLocation
  // v1.0.0.3 : Fixed : Issue with offline LibraryLocation
  // v1.0.1.0 : Improved : Added GenerateThumbnails(StartPage, EndPage) overload to generate a range thumbnails
  //          : Fixed : Issue on macOS target
  // v1.0.1.1 : Improved : Handling of OnMouseMove event
  //          : Fixed : Issue with OnPageRendered event
  // v1.0.2.0 : New : Support for password protected PDFs
  // v1.0.2.1 : Improved : Sharpness of PDF at 100% scaling
  // v1.0.3.0 : New : Added FitToPage method
  // v1.0.4.0 : New : Added GetFormFields method and OnGetFormFields event
  // v1.0.4.1 : Imrpoved : FitToPage in WEB

type
  TTMSFNCWXPDFViewerLoadError = procedure(Sender: TObject; ErrorMsg: string) of object;
  TTMSFNCWXPDFViewerLoaded = procedure(Sender: TObject; NumPages: Integer) of object;
  TTMSFNCWXPDFViewerGenerateThumbnail = procedure(Sender: TObject; NumPage: Integer; Base64Img: string) of object;
  TTMSFNCWXPDFViewerGenerateImagePage = procedure(Sender: TObject; ImgWidth, ImgHeight: Integer; Base64ImgPage: string) of object;
  TTMSFNCWXPDFViewerHyperlinkClick = procedure(Sender: TObject; Id, LinkType, LinkUrl: string) of object;
  TTMSFNCWXPDFViewerFoundSearchText = procedure(Sender: TObject; PageNumber: Integer; TextFound: string) of object;
  TTMSFNCWXPDFViewerThumbnailProgressEvent = procedure(Sender: TObject; APosition, ASize: Integer) of object;
  TTMSFNCWXPDFViewerSearchTextFinishEvent = procedure(Sender: TObject; AFirst, ACount: Integer) of object;
  TTMSFNCWXPDFViewerGetPageTextEvent = procedure(Sender: TObject; AText: string) of object;
  TTMSFNCWXPDFViewerNavigatePageEvent = procedure(Sender: TObject; APage: Integer) of object;
  TTMSFNCWXPDFViewerGetFormFieldsEvent = procedure(Sender: TObject; AFormFields: string) of object;

  TTMSFNCWXPDFViewerRotationAngle = (ANGLE_0, ANGLE_90, ANGLE_180, ANGLE_270, ANGLE_DEFAULT);

  { TTMSFNCWXCustomPDFViewer }

  TTMSFNCWXCustomPDFViewer = class(TTMSFNCCustomWEBControl)
  private
    FLibraryLocation: TTMSFNCWXLibraryLocation;
    FPageCount: Integer;
    FZoom: Single;
    FOnLoadError: TTMSFNCWXPDFViewerLoadError;
    FOnLoaded: TTMSFNCWXPDFViewerLoaded;
    FActivePage: Integer;
    FOnPageRendered: TNotifyEvent;
    FOnPageLoaded: TNotifyEvent;
    FFileLoaded: Boolean;
    FFileUrl: string;
    FFileName: string;
    FFileBase64: string;
    FPDFRotationAngle: TTMSFNCWXPDFViewerRotationAngle;
    FOnGenerateThumbnail: TTMSFNCWXPDFViewerGenerateThumbnail;
    FOnHyperlinkClick: TTMSFNCWXPDFViewerHyperlinkClick;
    FOnFoundSearchText: TTMSFNCWXPDFViewerFoundSearchText;
    FThumbnailSize: Integer;
    FOnGenerateImagePage: TTMSFNCWXPDFViewerGenerateImagePage;
    FOnThumbnailProgress: TTMSFNCWXPDFViewerThumbnailProgressEvent;
    FOnViewerInitialized: TNotifyEvent;
    FOnNotFoundSearchText: TNotifyEvent;
    FOnSearchTextFinished: TTMSFNCWXPDFViewerSearchTextFinishEvent;
    FCalculateThumbnails: Boolean;
    FOnGetPageText: TTMSFNCWXPDFViewerGetPageTextEvent;
    FOnShowPage: TTMSFNCWXPDFViewerNavigatePageEvent;
    FOnGetFormFields: TTMSFNCWXPDFViewerGetFormFieldsEvent;
    FPassword: string;
    FPageWidth: Single;
    FPageHeight: Single;
    FPageFit: Boolean;
    procedure SetActivePage(const Value: Integer);
    procedure SetLibraryLocation(const Value: TTMSFNCWXLibraryLocation);
    procedure SetZoom(const Value: Single);
    function FileOk: Boolean;
    procedure SetPDFRotationAngle(const Value: TTMSFNCWXPDFViewerRotationAngle);
    procedure ShowPDFFile;
    procedure SetThumbnailSize(const Value: Integer);
  protected
    function GetDocURL: string; override;
    procedure DoControlInitialized; override;
    procedure Initialized; override;
    function GetCustomCSS: string; override;
    function GetCustomFunctions: string; override;
    function GetBody: string; override;
    {$IFDEF WEBLIB}
    function GetWaitInitVariables: string; override;
    {$ENDIF}
    function GetWaitInitCondition: string; override;
    function GetWaitInitElseStatement: string; override;
    procedure LoadLinks(AList: TTMSFNCCustomWEBControlLinksList); override;
    procedure CallCustomEvent(AEventData: TTMSFNCCustomWEBControlEventData); override;
    procedure DoHandlePageLoadEvent; virtual;
    procedure DoHandlePDFLoadEvent(ACustomData: string); virtual;
    procedure DoHandlePageRenderEvent; virtual;
    procedure DoHandlePageSizeEvent(ACustomData: string; ARender: Boolean); virtual;
    procedure DoHandleLoadErrorEvent(ACustomData: string); virtual;
    procedure DoHandleGenerateThumbnail(ACustomData: string); virtual;
    procedure DoHandleHyperlinkClick(ACustomData: string); virtual;
    procedure DoHandleMouseDownEvent(ACustomData: string); virtual;
    procedure DoHandleMouseMoveEvent(ACustomData: string); virtual;
    procedure DoHandleMouseUpEvent(ACustomData: string); virtual;
    procedure DoHandleFoundSearchText(ACustomData: string); virtual;
    procedure DoHandleGenerateImagePage(ACustomData: string); virtual;
    procedure DoHandleThumbnailProgress(ACustomData: string); virtual;
    procedure DoHandleNotFoundSearchText; virtual;
    procedure DoHandleSearchTextFinished(ACustomData: string); virtual;
    procedure DoHandleBrowserScroll(ACustomData: string); virtual;
    procedure DoHandleGetPageString(ACustomData: string); virtual;
    procedure DoHandleGetFormFields(ACustomData: string); virtual;
    property CalculateThumbnails: Boolean read FCalculateThumbnails write FCalculateThumbnails default False;
    property PageCount: Integer read FPageCount;
    property LibraryLocation: TTMSFNCWXLibraryLocation read FLibraryLocation write SetLibraryLocation default llOnline;
    property ActivePage: Integer read FActivePage write SetActivePage default 1;
    property Zoom: Single read FZoom write SetZoom;
    property PDFRotationAngle: TTMSFNCWXPDFViewerRotationAngle read FPDFRotationAngle write SetPDFRotationAngle default ANGLE_0;
    property ThumbnailSize: Integer read FThumbnailSize write SetThumbnailSize default 96;
    property Password: string read FPassword write FPassword;
    property OnLoaded: TTMSFNCWXPDFViewerLoaded read FOnLoaded write FOnLoaded;
    property OnPageLoaded: TNotifyEvent read FOnPageLoaded write FOnPageLoaded;
    property OnPageRendered: TNotifyEvent read FOnPageRendered write FOnPageRendered;
    property OnLoadError: TTMSFNCWXPDFViewerLoadError read FOnLoadError write FOnLoadError;
    property OnGenerateThumbnail: TTMSFNCWXPDFViewerGenerateThumbnail read FOnGenerateThumbnail write FOnGenerateThumbnail;
    property OnGenerateImagePage: TTMSFNCWXPDFViewerGenerateImagePage read FOnGenerateImagePage write FOnGenerateImagePage;
    property OnHyperlinkClick: TTMSFNCWXPDFViewerHyperlinkClick read FOnHyperlinkClick write FOnHyperlinkClick;
    property OnFoundSearchText: TTMSFNCWXPDFViewerFoundSearchText read FOnFoundSearchText write FOnFoundSearchText;
    property OnThumbnailProgress: TTMSFNCWXPDFViewerThumbnailProgressEvent read FOnThumbnailProgress write FOnThumbnailProgress;
    property OnViewerInitialized: TNotifyEvent read FOnViewerInitialized write FOnViewerInitialized;
    property OnNotFoundSearchText: TNotifyEvent read FOnNotFoundSearchText write FOnNotFoundSearchText;
    property OnSearchTextFinished: TTMSFNCWXPDFViewerSearchTextFinishEvent read FOnSearchTextFinished write FOnSearchTextFinished;
    property OnGetPageText: TTMSFNCWXPDFViewerGetPageTextEvent read FOnGetPageText write FOnGetPageText;
    property OnShowPage: TTMSFNCWXPDFViewerNavigatePageEvent read FOnShowPage write FOnShowPage;
    property OnGetFormFields: TTMSFNCWXPDFViewerGetFormFieldsEvent read FOnGetFormFields write FOnGetFormFields;
    //property FormFields: TJSONArray read FFormFields;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure FitToPage(AHeight: Boolean = True; AWidth: Boolean = False);
    procedure NextPage;
    procedure PreviousPage;
    procedure ShowPage(AIndex: Integer);
    procedure ZoomIn;
    procedure ZoomOut;
    procedure TurnAngleLeft;
    procedure TurnAngleRight;
    procedure GenerateImageActualPage;
    procedure GenerateThumbnails; overload; 
    procedure GenerateThumbnails(StartPage, EndPage: Integer); overload;
    procedure SearchText(TextToSearch: string);
    procedure LoadFromUrl(FileUrl: string; PageNumber: Integer = 1; AZoom: Single = 1; RotateAngle: TTMSFNCWXPDFViewerRotationAngle = ANGLE_DEFAULT);
    {$IFNDEF WEBLIB}
    procedure LoadFromFile(FileName: string; PageNumber: Integer = 1; AZoom: Single = 1; RotateAngle: TTMSFNCWXPDFViewerRotationAngle = ANGLE_DEFAULT);
    {$ENDIF}
    procedure LoadFromBase64(FileBase64: string; PageNumber: Integer = 1; AZoom: Single = 1; RotateAngle: TTMSFNCWXPDFViewerRotationAngle = ANGLE_DEFAULT);
    procedure SavePageTextAsJSON(AFileName: string; APageNumber: Integer = -1);
    procedure GetPageAsText(APageNumber: Integer = -1);
    procedure GetFormFields;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  [FNCJSLibReferenceAttribute(
    'https://code.jquery.com/jquery-3.5.1.min.js;' +
    'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.min.js;' +
    'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js;' +
    'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf_viewer.min.js')]
  {$ENDIF}
  TTMSFNCWXPDFViewer = class(TTMSFNCWXCustomPDFViewer)
  published
    property CalculateThumbnails;
    property PageCount;
    property LibraryLocation;
    property ActivePage;
    property Zoom;
    property PDFRotationAngle;
    property ThumbnailSize;
    property Password;
    property OnLoaded;
    property OnPageLoaded;
    property OnPageRendered;
    property OnLoadError;
    property OnGenerateThumbnail;
    property OnGenerateImagePage;
    property OnHyperlinkClick;
    property OnFoundSearchText;
    property OnThumbnailProgress;
    property OnViewerInitialized;
    property OnNotFoundSearchText;
    property OnSearchTextFinished;
    property OnGetPageText;
    property OnGetFormFields;
  end;

var
  TMSFNCWXPDFViewer: TTMSFNCWXPDFViewer;

implementation


{$R TMSFNCWXPDFViewer.res}

{ TTMSFNCWXPDFViewer }

function TTMSFNCWXCustomPDFViewer.GetBody: string;
begin
  Result := StringReplace(PDFVIEWERHTMLCODE, CONTROLID, GetControlID, [rfReplaceAll]);
end;

function TTMSFNCWXCustomPDFViewer.GetCustomCSS: string;
begin
  Result := StringReplace(PDFVIEWERCSS, CONTROLID, GetControlID, [rfReplaceAll]);
end;

function TTMSFNCWXCustomPDFViewer.GetCustomFunctions: string;
  function GetReplacedJSCode(ACode: string): string;
  begin
    Result := StringReplace(ACode, CONTROLID, GetControlID, [rfReplaceAll]);
    Result := StringReplace(Result, DATA_OBJECT, GetDefaultEventDataObject, [rfReplaceAll]);
  end;
begin
  Result := StringReplace(PDFVIEWERJSVAR, CONTROLID, GetControlID, [rfReplaceAll]) +
    StringReplace(GetReplacedJSCode(PDFVIEWERJSMAKETHUMB),THUMBNAILSIZETAG,inttostr(FThumbnailSize), [rfReplaceAll]) +
    GetReplacedJSCode(PDFVIEWERJSIMAGEPAGE) +
    GetReplacedJSCode(PDFVIEWERJSGETFORMFIELDS) +
    GetReplacedJSCode(PDFVIEWERJSSEARCHTEXT) +
    GetReplacedJSCode(PDFVIEWERJSSHOWPDFURL) +
    GetReplacedJSCode(PDFVIEWERJSSHOWPDFBASE64) +
    GetReplacedJSCode(PDFVIEWERJSGENERATETHUMBNAILS) +
    GetReplacedJSCode(PDFVIEWERJSGENERATETHUMBNAILRANGE) + 
    GetReplacedJSCode(PDFVIEWERJSGETPAGEJSON) +
    {$IFNDEF WEBLIB}
    GetReplacedJSCode(PDFVIEWERMOUSEZOOM) +
    {$ENDIF}
    GetReplacedJSCode(PDFVIEWERJSSHOWPAGE);
end;

function TTMSFNCWXCustomPDFViewer.GetDocURL: string;
begin
  Result := 'https://download.tmssoftware.com/doc/tmsfncwxpack/components/ttmsfncwxpdfviewer/';
end;

procedure TTMSFNCWXCustomPDFViewer.GetFormFields;
Var
  JSText: string;
begin
  JSText := GetControlID + 'getFormFields();';
  ExecuteJavaScript(JSText);
End;

procedure TTMSFNCWXCustomPDFViewer.GetPageAsText(APageNumber: Integer);
var
  JSText: string;
  pg: Integer;
begin
  if APageNumber = -1 then
    pg := ActivePage
  else
    pg := APageNumber;

  JSText := GetControlID + 'GetPageString(' + IntToStr(pg) + ', false);';
  ExecuteJavascript(JSText);
end;

function TTMSFNCWXCustomPDFViewer.GetWaitInitCondition: string;
begin
  {$IFDEF WEBLIB}
  Result := '!window.pdfjsLib || !window.pdfjsViewer || !window.$ || !e || !e2 || !window.$(''#' + GetControlID + '_pdf-main-container'')';
  {$ENDIF}
  {$IFNDEF WEBLIB}
  Result := '!window.pdfjsLib || !window.pdfjsViewer || !window.$ || !window.$(''#' + GetControlID + ''')';
  {$ENDIF}
end;

function TTMSFNCWXCustomPDFViewer.GetWaitInitElseStatement: string;
var
  StrActivePage: string;
  StrZoom: string;
  StrRotateAngle: string;
begin
  if IsDesigning then
  begin
    FFileLoaded := False;
    StrActivePage := IntToStr(1);
    StrZoom := FormatFloat('#.##', 1);
    StrZoom := ReplaceStr(StrZoom, ',', '.');
    StrRotateAngle := '0';
    Result := '    ' + GetControlID + 'showPDFBase64("' + DEFAULT_PDF_BASE64 + '",' + StrActivePage + ',' + StrZoom + ',' + StrRotateAngle + ');';
  end;
end;

{$IFDEF WEBLIB}
function TTMSFNCWXCustomPDFViewer.GetWaitInitVariables: string;
begin
  Result :=
    '  var e = document.getElementById("' + ElementID + '");' + LB +
    '  var e2 = document.getElementById("' + GetControlID + '_pdf-main-container");';
end;
{$ENDIF}

procedure TTMSFNCWXCustomPDFViewer.CallCustomEvent(
  AEventData: TTMSFNCCustomWEBControlEventData);
begin
  inherited;

  if AEventData.EventName <> '' then
  begin
    case IndexStr(AEventData.EventName, [ONPAGELOADEDEVENT, ONPDFLOADEDEVENT,
      ONPAGERENDEREDEVENT, ONPDFLOADERROREVENT, ONGENERATETHUMBNAILEVENT,
      ONHYPERLINKCLICKEVENT, ONMOUSEDOWNEVENT, ONMOUSEUPEVENT, 'ONFOUNDSEARCHTEXT',
      'ONGENERATEIMAGEPAGE', 'ONTHUMBNAILPROGRESSEVENT',
      'ONNOTFOUNDSEARCHTEXT', 'ONFOUNDALLSEARCHTEXT', 'ONBROWSERSCROLLEVENT',
      'ONPDFPAGEGETSTRING', 'ONMOUSEMOVEEVENT', 'ONPDFPAGESIZES','ONGETFORMFIELDS']) of
      0: DoHandlePageLoadEvent;
      1: DoHandlePDFLoadEvent(AEventData.CustomData);
      2: DoHandlePageSizeEvent(AEventData.CustomData, True);
      3: DoHandleLoadErrorEvent(AEventData.CustomData);
      4: DoHandleGenerateThumbnail(AEventData.CustomData);
      5: DoHandleHyperlinkClick(AEventData.CustomData);
      6: DoHandleMouseDownEvent(AEventData.CustomData);
      7: DoHandleMouseUpEvent(AEventData.CustomData);
      8: DoHandleFoundSearchText(AEventData.CustomData);
      9: DoHandleGenerateImagePage(AEventData.CustomData);
      10: DoHandleThumbnailProgress(AEventData.CustomData);
      11: DoHandleNotFoundSearchText;
      12: DoHandleSearchTextFinished(AEventData.CustomData);
      13: DoHandleBrowserScroll(AEventData.CustomData);
      14: DoHandleGetPageString(AEventData.CustomData);
      15: DoHandleMouseMoveEvent(AEventData.CustomData);
      16: DoHandlePageSizeEvent(AEventData.CustomData, False);
      17: DoHandleGetFormFields(AEventData.CustomData);
    end;
  end;
end;

constructor TTMSFNCWXCustomPDFViewer.Create(AOwner: TComponent);
begin
  FPageFit := False;
  FPageWidth := 0;
  FPageHeight := 0;
  FActivePage := 1;
  FPageCount := 0;
  FZoom := 1;
  FFileUrl := '';
  FFileName := '';
  FFileBase64 := '';
  FThumbnailSize := 96;
  FFileLoaded := False;
  FPDFRotationAngle := TTMSFNCWXPDFViewerRotationAngle(0);
  FLibraryLocation := llOnline;
  FCalculateThumbnails := False;
  inherited;
end;

procedure TTMSFNCWXCustomPDFViewer.Initialized;
begin
  inherited;
  InitializeHTML;
end;

procedure TTMSFNCWXCustomPDFViewer.NextPage;
begin
  if FileOk and FFileLoaded and (FActivePage < FPageCount) then
  begin
    Inc(FActivePage);
    ShowPage(FActivePage);
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.PreviousPage;
begin
  if FileOk and FFileLoaded and (FActivePage > 1) then
  begin
    Dec(FActivePage);
    ShowPage(FActivePage);
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.LoadFromBase64(FileBase64: string;
  PageNumber: Integer; AZoom: Single; RotateAngle: TTMSFNCWXPDFViewerRotationAngle);
begin
  if FileBase64 <> '' then
  begin
    FFileLoaded := False;
    FFileName := '';
    FFileBase64 := FileBase64;
    FFileUrl := '';
    FActivePage := PageNumber;
    FZoom := Zoom;
    FPDFRotationAngle := RotateAngle;
    ShowPDFFile;
  end;
end;

{$IFNDEF WEBLIB}
procedure TTMSFNCWXCustomPDFViewer.LoadFromFile(FileName: string; PageNumber: Integer;
  AZoom: Single; RotateAngle: TTMSFNCWXPDFViewerRotationAngle);
var
  Base64File: string;
begin
  if (FileName <> '') and (FileExists(FileName)) then
  begin
    Base64File := TTMSFNCUtils.FileToBase64(FileName);
    FFileLoaded := False;
    FFileName := FileName;
    FFileBase64 := Base64File;
    FFileUrl := '';
    FActivePage := PageNumber;
    FZoom := Zoom;
    FPDFRotationAngle := RotateAngle;
    ShowPDFFile;
  end;
end;
{$ENDIF}

procedure TTMSFNCWXCustomPDFViewer.LoadFromUrl(FileUrl: string; PageNumber: Integer;
  AZoom: Single; RotateAngle: TTMSFNCWXPDFViewerRotationAngle);
begin
  If FileUrl <> '' then
  begin
    FFileLoaded := False;
    FFileName := '';
    FFileBase64 := '';
    FFileUrl := FileUrl;
    FActivePage := PageNumber;
    FZoom := Zoom;
    FPDFRotationAngle := RotateAngle;
    ShowPDFFile;
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.LoadLinks(AList: TTMSFNCCustomWEBControlLinksList);
begin
  inherited;
  {$IFNDEF WEBLIB}
  if not IsDesigning and (FLibraryLocation = llOffline) then
  begin
    AList.Add(TTMSFNCCustomWEBControlLink.Create(mlkScript, '', '', '', '', LoadResourceFile('JQUERY_JS'), False, False));
    AList.Add(TTMSFNCCustomWEBControlLink.Create(mlkScript, '', '', '', '', LoadResourceFile('PDF_JS'), False, False));
    AList.Add(TTMSFNCCustomWEBControlLink.Create(mlkScript, '', '', '', '', LoadResourceFile('PDF_WORKER_JS'), False, False));
    AList.Add(TTMSFNCCustomWEBControlLink.Create(mlkScript, '', '', '', '', LoadResourceFile('PDF_VIEWER_JS'), False, False));
  end
  else
  begin
  {$ENDIF}
    AList.Add(TTMSFNCCustomWEBControlLink.CreateScript('https://code.jquery.com/jquery-3.5.1.min.js'));
    AList.Add(TTMSFNCCustomWEBControlLink.CreateScript('https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.min.js'));
    AList.Add(TTMSFNCCustomWEBControlLink.CreateScript('https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js'));
    AList.Add(TTMSFNCCustomWEBControlLink.CreateScript('https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf_viewer.min.js'));
  {$IFNDEF WEBLIB}
  end;
  {$ENDIF}
end;

procedure TTMSFNCWXCustomPDFViewer.ShowPage(AIndex: Integer);
var
  zoomStr, angleStr, jsText, tm: string;
begin
  if FileOk and FFileLoaded and (AIndex <= FPageCount) and (AIndex > 0) then
  begin
    FActivePage := AIndex;
    zoomStr := FormatFloat('#.##', FZoom);
    zoomStr := ReplaceStr(zoomStr, ',', '.');
    angleStr := 'undefined';
    case FPDFRotationAngle of
      ANGLE_0: angleStr := '0';
      ANGLE_90: angleStr := '90';
      ANGLE_180: angleStr := '180';
      ANGLE_270: angleStr := '270';
    end;

    tm := 'true';
    if FPageFit then
      tm := 'false';

    jsText := GetControlID + 'showPage(' + IntToStr(FActivePage) + ',' + zoomStr + ',' + angleStr + ',' + tm + ');';
    ExecuteJavascript(jsText);

    if Assigned(OnShowPage) then
      OnShowPage(Self, FActivePage);
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.ShowPDFFile;
var
  JSText: string;
  StrActivePage: string;
  StrZoom: string;
  StrRotateAngle: string;
  StrCalcThumb: string;
  pw: string;
begin
  StrActivePage := IntToStr(FActivePage);
  StrZoom := FormatFloat('#.##', FZoom);
  StrZoom := ReplaceStr(StrZoom, ',', '.');

  StrRotateAngle := 'undefined';
  case FPDFRotationAngle of
    ANGLE_0: StrRotateAngle := '0';
    ANGLE_90: StrRotateAngle := '90';
    ANGLE_180: StrRotateAngle := '180';
    ANGLE_270: StrRotateAngle := '270';
  end;

  if FCalculateThumbnails then
    StrCalcThumb := 'true'
  else
    StrCalcThumb := 'false';

  pw := '';
  if FPassword <> '' then
    pw := ', "' + FPassword + '"';

  if FFileBase64<>'' then
    JSText := GetControlID + 'showPDFBase64("' + FFileBase64 + '",' + StrActivePage + ',' + StrZoom + ',' + StrRotateAngle + ',' + StrCalcThumb + pw + ');'
  else
    JSText := GetControlID + 'showPDFUrl("' + FFileUrl + '",' + StrActivePage + ',' + StrZoom + ',' + StrRotateAngle + ',' + StrCalcThumb + pw + ');';

  ExecuteJavascript(JSText);
end;

destructor TTMSFNCWXCustomPDFViewer.Destroy;
begin
  inherited;
end;

procedure TTMSFNCWXCustomPDFViewer.DoControlInitialized;
begin
  inherited;
  if Assigned(OnViewerInitialized) then
    OnViewerInitialized(Self);
end;

procedure TTMSFNCWXCustomPDFViewer.DoHandleBrowserScroll(ACustomData: string);
var
  js: TJSONValue;
  LResult: TJSONArray;
  st: TShiftState;
  b: Boolean;
begin
  js := TTMSFNCUtils.ParseJSON(ACustomData);
  try
    LResult := js as TJSONArray;
    st := [];
    if TTMSFNCUtils.IsJSONTrue(LResult.Items[0]) then
      st := st + [ssAlt];
    if TTMSFNCUtils.IsJSONTrue(LResult.Items[1]) then
      st := st + [ssCtrl];
      if TTMSFNCUtils.IsJSONTrue(LResult.Items[2]) then
      st := st + [ssShift];
    {$IFDEF FMXLIB}
    if Assigned(OnMouseWheel) then
      OnMousewheel(Self, st, StrToInt(LResult.Items[3].Value), b);
    {$ENDIF}
    {$IFNDEF FMXLIB}
    if StrToInt(LResult.Items[3].Value) > 0 then
    begin
      if Assigned(OnMouseWheelUp) then
        OnMouseWheelUp(Self, st, Point(0, 0), b);
    end
    else
    begin
      if Assigned(OnMouseWheelDown) then
        OnMouseWheelDown(Self, st, Point(0, 0), b);
    end;
    {$ENDIF}
  finally
    js.Free;
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.DoHandleFoundSearchText(ACustomData: string);
var
  js: TJSONValue;
  LResult: TJSONArray;
begin
  js := TTMSFNCUtils.ParseJSON(ACustomData);
  try
    LResult := js as TJSONArray;
    if Assigned(OnFoundSearchText) then
      OnFoundSearchText(Self, StrToInt(LResult.Items[0].Value), LResult.Items[1].Value);
  finally
    js.Free;
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.DoHandleGenerateImagePage(ACustomData: string);
var
  js: TJSONValue;
  LResult: TJSONArray;
begin
  js := TTMSFNCUtils.ParseJSON(ACustomData);
  try
    LResult := js as TJSONArray;
    if Assigned(OnGenerateImagePage) then
      OnGenerateImagePage(Self, StrtoInt(LResult.Items[0].Value), StrtoInt(LResult.Items[1].Value), LResult.Items[2].Value);
  finally
    js.Free;
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.DoHandleGenerateThumbnail(ACustomData: string);
var
  js: TJSONValue;
  LResult: TJSONArray;
begin
  js := TTMSFNCUtils.ParseJSON(ACustomData);
  try
    LResult := js as TJSONArray;
    if Assigned(OnGenerateThumbnail) then
      OnGenerateThumbnail(Self, StrToInt(LResult.Items[0].Value), LResult.Items[1].Value);
  finally
    js.Free;
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.DoHandleGetFormFields(ACustomData: string);
begin
  if Assigned(OnGetFormFields) then
    OnGetFormFields(Self, ACustomData);
end;

procedure TTMSFNCWXCustomPDFViewer.DoHandleGetPageString(ACustomData: string);
var
  js: TJSONValue;
  LResult: TJSONArray;
  sl: TStringList;
begin
  js := TTMSFNCUtils.ParseJSON(ACustomData);
  try
    LResult := js as TJSONArray;
    if LResult.Items[1].Value = 'true' then
    begin
      sl := TStringList.Create;
      try
        sl.Text := LResult.Items[0].Value;
        sl.SaveToFile(LResult.Items[2].Value);
      finally
        sl.Free;
      end;
    end
    else
    begin
      if Assigned(OnGetPageText) then
        OnGetPageText(Self, LResult.Items[0].Value);
    end;
  finally
    js.Free;
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.DoHandleHyperlinkClick(ACustomData: string);
var
  js: TJSONValue;
  LResult: TJSONArray;
begin
  js := TTMSFNCUtils.ParseJSON(ACustomData);
  try
    LResult := js as TJSONArray;
    if Assigned(OnHyperlinkClick) then
      OnHyperlinkClick(Self, LResult.Items[0].Value, LResult.Items[1].Value, LResult.Items[2].Value);
  finally
    js.Free;
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.DoHandleLoadErrorEvent(ACustomData: string);
var
  js: TJSONValue;
  LResult: TJSONArray;
begin
  js := TTMSFNCUtils.ParseJSON(ACustomData);
  try
    LResult := js as TJSONArray;
    if Assigned(OnLoadError) then
      OnLoadError(Self, LResult.Items[0].Value);
  finally
    js.Free;
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.DoHandleMouseDownEvent(ACustomData: string);
var
  js: TJSONValue;
  LResult: TJSONArray;
  Button: TMouseButton;
  Shift: TShiftState;
  X, Y: Single;
begin
  js := TTMSFNCUtils.ParseJSON(ACustomData);
  try
    LResult := js as TJSONArray;
    Button := TMouseButton.mbLeft;
    case StrToInt(LResult.Items[0].Value) of
      0: Button := TMouseButton.mbLeft;
      1: Button := TMouseButton.mbMiddle;
      2: Button := TMouseButton.mbRight;
    end;
    Shift := [ssShift, ssAlt, ssCtrl];
    if LResult.Items[1].Value = 'false' then
      Exclude(Shift, ssShift);
    if LResult.Items[2].Value = 'false' then
      Exclude(Shift, ssAlt);
    if LResult.Items[3].Value = 'false' then
      Exclude(Shift, ssCtrl);
    X := JSStringToFloat(LResult.Items[4].Value);
    Y := JSStringToFloat(LResult.Items[5].Value);
    {$IFDEF FMXLIB}
    if Assigned(OnMouseDown) then
      OnMouseDown(Self, Button, Shift, X, Y);
    {$ELSE}
    if Assigned(OnMouseDown) then
      OnMouseDown(Self, Button, Shift, Trunc(X), Trunc(Y));
    {$ENDIF}
  finally
    js.Free;
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.DoHandleMouseMoveEvent(ACustomData: string);
var
  js: TJSONValue;
  LResult: TJSONArray;
  Shift: TShiftState;
  X, Y: Single;
begin
  js := TTMSFNCUtils.ParseJSON(ACustomData);
  try
    LResult := js as TJSONArray;
    Shift := [ssShift, ssAlt, ssCtrl];
    if LResult.Items[1].Value = 'false' then
      Exclude(Shift, ssShift);
    if LResult.Items[2].Value = 'false' then
      Exclude(Shift, ssAlt);
    if LResult.Items[3].Value = 'false' then
      Exclude(Shift, ssCtrl);
    X := JSStringToFloat(LResult.Items[4].Value);
    Y := JSStringToFloat(LResult.Items[5].Value);
    {$IFDEF FMXLIB}
    if Assigned(OnMouseMove) then
      OnMouseMove(Self, Shift, X, Y);
    {$ELSE}
    if Assigned(OnMouseMove) then
      OnMouseMove(Self, Shift, Trunc(X), Trunc(Y));
    {$ENDIF}
  finally
    js.Free;
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.DoHandleMouseUpEvent(ACustomData: string);
var
  js: TJSONValue;
  LResult: TJSONArray;
  Button: TMouseButton;
  Shift: TShiftState;
  X, Y: Single;
begin
  js := TTMSFNCUtils.ParseJSON(ACustomData);
  try
    LResult := js as TJSONArray;
    Button := TMouseButton.mbLeft;
    case StrToInt(LResult.Items[0].Value) of
      0: Button := TMouseButton.mbLeft;
      1: Button := TMouseButton.mbMiddle;
      2: Button := TMouseButton.mbRight;
    end;
    Shift := [ssShift, ssAlt, ssCtrl];
    if LResult.Items[1].Value = 'false' then
      Exclude(Shift, ssShift);
    if LResult.Items[2].Value = 'false' then
      Exclude(Shift, ssAlt);
    if LResult.Items[3].Value = 'false' then
      Exclude(Shift, ssCtrl);
    X := JSStringToFloat(LResult.Items[4].Value);
    Y := JSStringToFloat(LResult.Items[5].Value);
    {$IFDEF FMXLIB}
    if Assigned(OnMouseUp) then
      OnMouseUp(Self, Button, Shift, X, Y);
    {$ELSE}
    if Assigned(OnMouseUp) then
      OnMouseUp(Self, Button, Shift, Trunc(X), Trunc(Y));
    {$ENDIF}
  finally
    js.Free;
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.DoHandleNotFoundSearchText;
begin
  if Assigned(OnNotFoundSearchText) then
    OnNotFoundSearchText(Self);
end;

procedure TTMSFNCWXCustomPDFViewer.DoHandlePageLoadEvent;
begin
  if Assigned(OnPageLoaded) then
    OnPageLoaded(Self);
end;

procedure TTMSFNCWXCustomPDFViewer.DoHandlePageRenderEvent;
begin
  if Assigned(OnPageRendered) then
    OnPageRendered(Self);
end;

procedure TTMSFNCWXCustomPDFViewer.DoHandlePageSizeEvent(
  ACustomData: string; ARender: Boolean);
var
  js: TJSONValue;
  LResult: TJSONArray;
begin
  js := TTMSFNCUtils.ParseJSON(ACustomData);
  try
    LResult := js as TJSONArray;
    FPageWidth := JSStringToFloat(LResult.Items[0].Value);
    FPageHeight := JSStringToFloat(LResult.Items[1].Value);
  finally
    js.Free;
  end;

  if ARender then
    DoHandlePageRenderEvent;
end;

procedure TTMSFNCWXCustomPDFViewer.DoHandlePDFLoadEvent(ACustomData: string);
var
  js: TJSONValue;
  LResult: TJSONArray;
begin
  js := TTMSFNCUtils.ParseJSON(ACustomData);
  try
    LResult := js as TJSONArray;
    FFileLoaded := True;
    FPageCount := StrToInt(LResult.Items[0].Value);
    if Assigned(OnLoaded) then
      OnLoaded(Self, FPageCount);
  finally
    js.Free;
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.DoHandleSearchTextFinished(ACustomData: string);
var
  js: TJSONValue;
  LResult: TJSONArray;
begin
  js := TTMSFNCUtils.ParseJSON(ACustomData);
  try
    LResult := js as TJSONArray;
    if Assigned(OnSearchTextFinished) then
      OnSearchTextFinished(Self, StrToInt(LResult.Items[1].Value), StrtoInt(LResult.Items[0].Value));
  finally
    js.Free;
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.DoHandleThumbnailProgress(ACustomData: string);
var
  js: TJSONValue;
  LResult: TJSONArray;
begin
  js := TTMSFNCUtils.ParseJSON(ACustomData);
  try
    LResult := js as TJSONArray;
    if Assigned(OnThumbnailProgress) then
      OnThumbnailProgress(Self, StrToInt(LResult.Items[0].Value), StrtoInt(LResult.Items[1].Value));
  finally
    js.Free;
  end;
end;

function TTMSFNCWXCustomPDFViewer.FileOk: Boolean;
begin
  Result := (FFileBase64 <> '') or (FFileUrl <> '');
end;

procedure TTMSFNCWXCustomPDFViewer.FitToPage(AHeight: Boolean; AWidth: Boolean);
var
  wzv, hzv: Single;
begin
  FPageFit := True;
  {$IFNDEF WEBLIB}
  hzv := Zoom * Height / FPageHeight / (1 - (0.21 * TTMSFNCUtils.GetDPIScale(Self)));
  wzv := Zoom * Width / FPageWidth / (1 - (0.21 * TTMSFNCUtils.GetDPIScale(Self)));
  //Zoom := Zoom * Height / FPageHeight / (1 - (0.21 * TTMSFNCUtils.GetDPIScale(Self)));
  {$ENDIF}
  {$IFDEF WEBLIB}
  hzv := Zoom * Height / FPageHeight / (1 - (0.22 * window.devicePixelRatio));
  wzv := Zoom * Width / FPageWidth / (1 - (0.22 * window.devicePixelRatio));
  //Zoom := Zoom * Height / FPageHeight / (1 - (0.22 * window.devicePixelRatio));
  {$ENDIF}
  if AHeight and AWidth then
    Zoom := Min(wzv, hzv)
  else if AHeight then
    Zoom := hzv
  else if AWidth then
    Zoom := wzv;

  FPageFit := False;
end;

procedure TTMSFNCWXCustomPDFViewer.GenerateImageActualPage;
var
  JSText: string;
begin
  JSText := GetControlID + 'GenerateImageActualPage();';
  ExecuteJavascript(JSText);
end;

procedure TTMSFNCWXCustomPDFViewer.GenerateThumbnails;
begin
  if (not CalculateThumbnails) or (not ControlInitialized) then
    Exit;

  ExecuteJavaScript(GetControlID + 'generateThumbnails()');
end;

procedure TTMSFNCWXCustomPDFViewer.GenerateThumbnails(StartPage,
  EndPage: Integer);
begin
  if (not ControlInitialized) then
    Exit;

  ExecuteJavaScript(GetControlID + 'generateThumbnailRange(' + IntToStr(StartPage) + ',' + IntToStr(EndPage) + ')');
end;

procedure TTMSFNCWXCustomPDFViewer.SavePageTextAsJSON(AFileName: string;
  APageNumber: Integer);
var
  JSText: string;
  pg: Integer;
begin
  if APageNumber = -1 then
    pg := ActivePage
  else
    pg := APageNumber;

  JSText := GetControlID + 'GetPageString(' + IntToStr(pg) + ', true, "' + AFileName + '");';
  ExecuteJavascript(JSText);
end;

procedure TTMSFNCWXCustomPDFViewer.SearchText(TextToSearch: string);
var
  JSText: string;
begin
  JSText := GetControlID + 'SearchText("' + TextToSearch + '");';
  ExecuteJavascript(JSText);
end;

procedure TTMSFNCWXCustomPDFViewer.SetActivePage(const Value: Integer);
begin
  if FActivePage <> Value then
  begin
    FActivePage := Value;
    ShowPage(FActivePage);
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.SetLibraryLocation(
  const Value: TTMSFNCWXLibraryLocation);
begin
  FLibraryLocation := Value;
end;

procedure TTMSFNCWXCustomPDFViewer.SetPDFRotationAngle(const Value: TTMSFNCWXPDFViewerRotationAngle);
begin
  if FPDFRotationAngle <> Value then
  begin
    FPDFRotationAngle := Value;
    ShowPage(FActivePage);
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.SetThumbnailSize(const Value: Integer);
begin
  if FThumbnailSize <> Value then
    FThumbnailSize := Value;
end;

procedure TTMSFNCWXCustomPDFViewer.SetZoom(const Value: Single);
begin
  if FZoom <> Value then
  begin
    FZoom := Value;
    ShowPage(FActivePage);
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.TurnAngleLeft;
begin
  if FileOk and FFileLoaded then
  begin
    case FPDFRotationAngle of
      ANGLE_0: FPDFRotationAngle := ANGLE_270;
      ANGLE_90: FPDFRotationAngle := ANGLE_0;
      ANGLE_180: FPDFRotationAngle := ANGLE_90;
      ANGLE_270: FPDFRotationAngle := ANGLE_180;
    end;
    ShowPage(FActivePage);
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.TurnAngleRight;
begin
  if FileOk and FFileLoaded then
  begin
    case FPDFRotationAngle of
      ANGLE_0: FPDFRotationAngle := ANGLE_90;
      ANGLE_90: FPDFRotationAngle := ANGLE_180;
      ANGLE_180: FPDFRotationAngle := ANGLE_270;
      ANGLE_270: FPDFRotationAngle := ANGLE_0;
    end;
    ShowPage(FActivePage);
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.ZoomIn;
begin
  if FileOk and FFileLoaded then
  begin
    FZoom := FZoom + 0.25;
    ShowPage(FActivePage);
  end;
end;

procedure TTMSFNCWXCustomPDFViewer.ZoomOut;
begin
  if FileOk and FFileLoaded and (FZoom > 0.25) then
  begin
    FZoom := FZoom - 0.25;
    ShowPage(FActivePage);
  end;
end;

end.

