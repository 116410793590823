unit ufrFreundeWG;

interface

uses
  System.SysUtils, System.Variants, System.Classes,JS,Web, DateUtils,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.Imaging.jpeg,
  WEBLib.Controls, WEBLib.ExtCtrls, WEBLib.WebCtrls, Data.DB, uTB, uGlobal,
  XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Client, WEBLib.CDS,
  WEBLib.Graphics, WEBLib.Forms, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.Lists;

type
  TfrFreundeWG = class(TFrame)
    divWrapper: THTMLDiv;
    divHeader: THTMLDiv;
    Client: TXDataWebClient;
    divContent: THTMLDiv;
    wl: TListControl;
    [async]
    Timer: TTimer;
    procedure wlItemClick(Sender: TObject; AListItem: TListItem);
    procedure TimerTimer(Sender: TObject);
  private
  protected
    procedure Loaded(); override;
  public
    public constructor Create(AOwner:TComponent);Override;
    [async] procedure Init; async;
    [async] class function New(AOwner, AParent:TControl):TfrFreundeWG; async;
  protected procedure LoadDFMValues; override; end;

implementation
   Uses uMain, uDatenmodul, uBlog;
{$R *.dfm}

class function TfrFreundeWG.New(AOwner, AParent:TControl):TfrFreundeWG;
Var
  d:THTMLDiv;
begin
////  showmessage('1');
//  d := TWebHTMLDiv.create(AOwner);
//  d.Parent := AParent;
//
//  d.ElementHandle.style.setProperty('position', 'relative');
//  d.ElementHandle.style.setProperty('Background-Color', 'green');
//  d.ElementHandle.style.setProperty('min-width', '300px');
//  d.ElementHandle.style.setProperty('min-height', '300px');
//  d.ElementHandle.style.removeProperty('Font-family');
////
////
////showmessage('11');

//  result := TfrFreundeWG.Create(AParent);
//  result.LoadFromForm;
//  result.Parent := AParent;
//  await(result.Init);

end;

constructor TfrFreundeWG.Create(AOwner: TComponent);
begin
  inherited;
end;

procedure TfrFreundeWG.Init;
Var
  Response: TXDataClientResponse;
  i, id:integer;
  img:TImageControl;
  J:TJ;
  client1 : TXDataWebClient;
  d:THTMLDiv;
  li: TListItem;
  sSql, s,s2:string;
  dt:TDateTime;
begin
  timer.Interval := mainform.AutoRefresh;

  case Mainform.Holder.Typ of
      _UT_Gruppe: divHeader.HTML.Text := 'Mitglieder';
      _UT_Event : divHeader.HTML.Text := 'Teilnehmer';
      else        divHeader.HTML.Text := 'Freunde';
  end;

  sSql := 'SeLECT DISTINCT u.*' +
          '  FROM friends f ' +
          '  JOIN v_user u ON (u.id = IF(f.EMPFAENGER = :Empfaenger, f.SENDER, f.EMPFAENGER)) AND (TYP = ' + _UT_User.tostring + ')' +
          ' WHERE (f.EMPFAENGER = :Empfaenger OR f.SENDER = :Empfaenger) ' +
          '   AND f.status = 1';

  sSQl := StringReplace(sSQl, ':Empfaenger', Mainform.Holder.ID.ToString, [rfReplaceAll]);

  j := TJ.create(await(datenmodul.easySQL(sSQL)));

  divContent.ElementHandle.style.setProperty('font-size', '75%');

  wl.Items.Clear;
  for i := 0 to J.Length -1 do
  begin
    j.index := i;
    li := wl.Items.Add;
    li.Text := j.Text('NICKNAME');

    if j.Value('LOGOUT') > ''
    then begin
      if isOnline(j.DateTime('LOGOUT'))
      then li.Text := li.Text + '<font size="1"><font color="orangered"> (online)</font>'
      else li.Text := li.Text + '<font size="1"><font color="black"> (zul. online: ' + TDatum.Get(j.DateTime('LOGOUT')).Zeitraum + ')</font>';

    end;

    li.Tag  := j.integer('ID');
  end;

  self.Parent.Visible := (wl.Items.Count > 0);

end;

procedure TfrFreundeWG.Loaded();
var
  el: TJSHTMLElement;
begin
  inherited;

  TControl(self).ElementPosition := epIgnore;
  TControl(self).ElementID := 'frFriends';
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.setProperty('height','');
  el.style.setProperty('width','');
  el.style.setProperty('font-family','');
  el.style.setProperty('font-style','');
  el.style.setProperty('font-size','');

  //init;
end;

procedure TfrFreundeWG.TimerTimer(Sender: TObject);
begin
  init;
end;

procedure TfrFreundeWG.wlItemClick(Sender: TObject; AListItem: TListItem);
begin
//meldung(AListItem.Tag.ToString);
  openURL(mainform.ME.ID.ToString, AListItem.Tag.ToString, _call_ME,  '1','0');
//  Mainform.HolderID := AListItem.Tag;
//  TfrmBlog.Show(_BLOGKAPITEL, AListItem.Tag, '', 0,false, Mainform.frmME);
end;

procedure TfrFreundeWG.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divWrapper := THTMLDiv.Create(Self);
  divHeader := THTMLDiv.Create(Self);
  divContent := THTMLDiv.Create(Self);
  wl := TListControl.Create(Self);
  Client := TXDataWebClient.Create(Self);
  Timer := TTimer.Create(Self);

  divWrapper.BeforeLoadDFMValues;
  divHeader.BeforeLoadDFMValues;
  divContent.BeforeLoadDFMValues;
  wl.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  Timer.BeforeLoadDFMValues;
  try
    Name := 'frFreundeWG';
    Left := 0;
    Top := 0;
    Width := 346;
    Height := 116;
    Margins.Left := 0;
    Margins.Top := 0;
    Margins.Right := 0;
    Margins.Bottom := 0;
    TabOrder := 0;
    divWrapper.SetParentComponent(Self);
    divWrapper.Name := 'divWrapper';
    divWrapper.Left := 3;
    divWrapper.Top := 0;
    divWrapper.Width := 334;
    divWrapper.Height := 105;
    divWrapper.ElementClassName := 'me_frame_container';
    divWrapper.HeightStyle := ssAuto;
    divWrapper.WidthStyle := ssAuto;
    divWrapper.ChildOrder := 9;
    divWrapper.ElementPosition := epIgnore;
    divWrapper.ElementFont := efCSS;
    divWrapper.Role := '';
    divHeader.SetParentComponent(divWrapper);
    divHeader.Name := 'divHeader';
    divHeader.Left := 3;
    divHeader.Top := 3;
    divHeader.Width := 318;
    divHeader.Height := 49;
    divHeader.ElementClassName := 'me_headline';
    divHeader.HeightStyle := ssAuto;
    divHeader.WidthStyle := ssAuto;
    divHeader.ChildOrder := 1;
    divHeader.ElementPosition := epRelative;
    divHeader.ElementFont := efCSS;
    divHeader.HTML.BeginUpdate;
    try
      divHeader.HTML.Clear;
      divHeader.HTML.Add('Meine Freunde');
      divHeader.HTML.Add('');
    finally
      divHeader.HTML.EndUpdate;
    end;
    divHeader.Role := '';
    divContent.SetParentComponent(divWrapper);
    divContent.Name := 'divContent';
    divContent.Left := 3;
    divContent.Top := 58;
    divContent.Width := 318;
    divContent.Height := 311;
    divContent.ElementClassName := 'me_frame';
    divContent.HeightStyle := ssAuto;
    divContent.WidthStyle := ssAuto;
    divContent.ChildOrder := 1;
    divContent.ElementPosition := epIgnore;
    divContent.ElementFont := efCSS;
    divContent.Role := '';
    wl.SetParentComponent(divContent);
    wl.Name := 'wl';
    wl.Left := 16;
    wl.Top := 16;
    wl.Width := 300;
    wl.Height := 169;
    wl.HeightStyle := ssAuto;
    wl.WidthStyle := ssAuto;
    wl.HeightPercent := 100.000000000000000000;
    wl.WidthPercent := 100.000000000000000000;
    wl.ElementFont := efCSS;
    wl.ElementPosition := epIgnore;
    wl.Items.Clear;
    with wl.Items.Add do
    begin
    end;
    SetEvent(wl, Self, 'OnItemClick', 'wlItemClick');
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 16;
    Client.Top := 12;
    Timer.SetParentComponent(Self);
    Timer.Name := 'Timer';
    Timer.Interval := 600000;
    SetEvent(Timer, Self, 'OnTimer', 'TimerTimer');
    Timer.Left := 136;
    Timer.Top := 72;
  finally
    divWrapper.AfterLoadDFMValues;
    divHeader.AfterLoadDFMValues;
    divContent.AfterLoadDFMValues;
    wl.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
    Timer.AfterLoadDFMValues;
  end;
end;

end.
