unit ufrAlbumListe;

interface

uses
  System.SysUtils, System.Variants, System.Classes,JS,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.Imaging.jpeg,
  WEBLib.Controls, WEBLib.ExtCtrls, WEBLib.WebCtrls, Data.DB,
  XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Client,
  Vcl.Imaging.pngimage, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.CSS,
  Web, WEBLib.Graphics, WEBLib.ComCtrls, system.Types, uTB,
  Vcl.Menus, WEBLib.Menus, WEBLib.Dialogs, WEBLib.Forms;

type
  TfrAlbumListe = class(TFrame)
    Client: TXDataWebClient;

    divWrapper: THTMLDiv;
    [async]
    WebHTMLDiv1: THTMLDiv;
    WebHTMLDiv3: THTMLDiv;
    imgNewAlbum: TImageControl; procedure imgNewAlbumClick(Sender: TObject);
  protected
    procedure Loaded(); override;
  private
    FHolder,
    FUser:integer;
    FParentID:integer;
    FCollapsedHeight:integer;
    procedure SetUserID(const Value: integer);
  public
    procedure Update(s:string);
    [async] procedure HeaderClick(Sender: TObject); async;
    [async] procedure Init(AHolder, AUser: integer); async;
    property User: integer read FUser write FUser;
    property Holder: integer read FHolder write FHolder;
    property ParentID: integer read FParentID write FParentID;
    property CollapsedHeight: integer read FCollapsedHeight write FCollapsedHeight;
  protected procedure LoadDFMValues; override; end;

implementation
   uses uDatenmodul, uMain, ufrAlbumThumb, uAlbumEdit;
{$R *.dfm}


procedure TfrAlbumliste.Init(AHolder, AUser: integer);
Var
  i:integer;
  Response: TXDataClientResponse;
  J:TJ;
begin
  FUser   := AUser;
  FHolder := AHolder;

  j := TJ.create(Await( Client.RawInvokeAsync('IDBService.AlbenGet',[FHolder])));

  if j.isEmpty
  then begin
    await(datenmodul.EasyInsert('INSERT into Alben (Typ, Holder, Titel, Text) values (1,' + FHolder.ToString + ',''Chronik'',''Hier finden sich alle Medien die keinem speziellen Album zugeordnet wurden...'')'));
    j.Response := Await( Client.RawInvokeAsync('IDBService.AlbenGet',[FHolder]));
  end;

  for i := 0 to j.length-1 do
  begin
    j.Index := i;

    TfrAlbumThumb.Add(divWrapper, J.Integer('ID'), AHolder, AUser,  J.Value('TITEL'), J.Value('TEXT'), J.Value('FOTOS'), J.Value('VIDEOS'), J.Value('SIZE2') );
  end;


end;

procedure TfrAlbumListe.Update(s:string);
begin
  meldung(s);
end;

procedure TfrAlbumListe.SetUserID(const Value: integer);
begin
  FUser := Value;
//  init(FUserId, FAlbumID, FBlockID);
end;

procedure TfrAlbumListe.Loaded();
var
  el: TJSHTMLElement;
begin
  inherited;
  TControl(self).ElementPosition := epIgnore;
  TControl(self).ElementID := 'frAlbumliste';
  el := TJSHTMLElement(THTMLDiv(self).ElementHandle);
  el.style.setProperty('height','');
  el.style.setProperty('width','');
  el.style.setProperty('font-family','');
  el.style.setProperty('font-style','');
  el.style.setProperty('font-size','');
end;

procedure TfrAlbumListe.imgNewAlbumClick(Sender: TObject);
begin
  TfrmAlbumEdit.Exec(0, @Update);
end;

procedure TfrAlbumliste.HeaderClick(Sender: TObject);
begin
end;

procedure TfrAlbumListe.LoadDFMValues;
begin
  inherited LoadDFMValues;

  divWrapper := THTMLDiv.Create(Self);
  WebHTMLDiv1 := THTMLDiv.Create(Self);
  WebHTMLDiv3 := THTMLDiv.Create(Self);
  imgNewAlbum := TImageControl.Create(Self);
  Client := TXDataWebClient.Create(Self);

  divWrapper.BeforeLoadDFMValues;
  WebHTMLDiv1.BeforeLoadDFMValues;
  WebHTMLDiv3.BeforeLoadDFMValues;
  imgNewAlbum.BeforeLoadDFMValues;
  Client.BeforeLoadDFMValues;
  try
    Name := 'frAlbumListe';
    Left := 0;
    Top := 0;
    Width := 553;
    Height := 264;
    Margins.Left := 0;
    Margins.Top := 0;
    Margins.Right := 0;
    Margins.Bottom := 0;
    TabOrder := 0;
    divWrapper.SetParentComponent(Self);
    divWrapper.Name := 'divWrapper';
    divWrapper.Left := 3;
    divWrapper.Top := 8;
    divWrapper.Width := 534;
    divWrapper.Height := 241;
    divWrapper.ElementClassName := 'alben_content_wrapper';
    divWrapper.HeightStyle := ssAuto;
    divWrapper.WidthStyle := ssAuto;
    divWrapper.ChildOrder := 1;
    divWrapper.ElementPosition := epIgnore;
    divWrapper.ElementFont := efCSS;
    divWrapper.Role := '';
    WebHTMLDiv1.SetParentComponent(divWrapper);
    WebHTMLDiv1.Name := 'WebHTMLDiv1';
    WebHTMLDiv1.Left := 16;
    WebHTMLDiv1.Top := 16;
    WebHTMLDiv1.Width := 217;
    WebHTMLDiv1.Height := 209;
    WebHTMLDiv1.ElementClassName := 'alben_frame';
    WebHTMLDiv1.HeightStyle := ssAuto;
    WebHTMLDiv1.WidthStyle := ssAuto;
    WebHTMLDiv1.ChildOrder := 1;
    WebHTMLDiv1.ElementPosition := epIgnore;
    WebHTMLDiv1.ElementFont := efCSS;
    WebHTMLDiv1.Role := '';
    WebHTMLDiv1.Visible := False;
    WebHTMLDiv3.SetParentComponent(WebHTMLDiv1);
    WebHTMLDiv3.Name := 'WebHTMLDiv3';
    WebHTMLDiv3.Left := 16;
    WebHTMLDiv3.Top := 13;
    WebHTMLDiv3.Width := 177;
    WebHTMLDiv3.Height := 180;
    WebHTMLDiv3.ElementClassName := 'alben_content';
    WebHTMLDiv3.HeightStyle := ssAuto;
    WebHTMLDiv3.WidthStyle := ssAuto;
    WebHTMLDiv3.ChildOrder := 2;
    WebHTMLDiv3.ElementPosition := epIgnore;
    WebHTMLDiv3.ElementFont := efCSS;
    WebHTMLDiv3.HTML.BeginUpdate;
    try
      WebHTMLDiv3.HTML.Clear;
      WebHTMLDiv3.HTML.Add('');
    finally
      WebHTMLDiv3.HTML.EndUpdate;
    end;
    WebHTMLDiv3.Role := '';
    SetEvent(WebHTMLDiv3, Self, 'OnClick', 'imgNewAlbumClick');
    imgNewAlbum.SetParentComponent(WebHTMLDiv3);
    imgNewAlbum.Name := 'imgNewAlbum';
    imgNewAlbum.Left := 35;
    imgNewAlbum.Top := 35;
    imgNewAlbum.Width := 102;
    imgNewAlbum.Height := 78;
    imgNewAlbum.ElementClassName := 'alben_foto';
    imgNewAlbum.HeightStyle := ssAuto;
    imgNewAlbum.WidthStyle := ssAuto;
    imgNewAlbum.HeightPercent := 100.000000000000000000;
    imgNewAlbum.WidthPercent := 100.000000000000000000;
    imgNewAlbum.ChildOrder := 1;
    imgNewAlbum.ElementFont := efCSS;
    imgNewAlbum.ElementPosition := epIgnore;
    SetEvent(imgNewAlbum, Self, 'OnClick', 'imgNewAlbumClick');
    imgNewAlbum.Picture.LoadFromFile('ufrAlbumListe.WebHTMLDiv3.imgNewAlbum.Picture.png');
    Client.SetParentComponent(Self);
    Client.Name := 'Client';
    Client.Connection := DatenModul.Connection;
    Client.Left := 112;
    Client.Top := 16;
  finally
    divWrapper.AfterLoadDFMValues;
    WebHTMLDiv1.AfterLoadDFMValues;
    WebHTMLDiv3.AfterLoadDFMValues;
    imgNewAlbum.AfterLoadDFMValues;
    Client.AfterLoadDFMValues;
  end;
end;

end.

